import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../../components/widgets';
import fieldVisibilityRules from '../../../selectors/reportsConfig/fieldVisibilityRules';
import css from '../DataDestinationShelf.css';
import { getCohortFields, getSelectedOptions } from '../../customShemaUtils';

const MANDATORY_COHORT_PERIODS = ['1d', '7d'];
const MANDATORY_COHORT_METRICS = ['unified_revenue'];
const ALLOWED_COHORT_PERIODS = [1, 7, 3, 14, 30];
const DISALLOWED_COHORT_METRICS = ['roi'];

const cohortPeriodsVisibility = ({ period_id: period }) => ALLOWED_COHORT_PERIODS.includes(period);

const cohortMetricsVisibility = (field, isAdmin, userData) =>
    fieldVisibilityRules.unifiedReport(field, isAdmin, userData, true) &&
    !DISALLOWED_COHORT_METRICS.includes(field.name);

export default function UnifiedCustomSchemaFields({ readonly, selected, getOptions, onChange }) {
    const dimensionsOptions = getOptions('custom_dimensions', fieldVisibilityRules.unifiedReport);
    const eventsOptions = getOptions('customEvents', fieldVisibilityRules.unifiedReport);
    const metricsOptions = getOptions('cohortMetrics', cohortMetricsVisibility, MANDATORY_COHORT_METRICS);
    const periodsOptions = getOptions('periods', cohortPeriodsVisibility, MANDATORY_COHORT_PERIODS);

    const [metrics, setMetrics] = useState([]);
    const [periods, setPeriods] = useState([]);

    useEffect(() => {
        const {
            selectedMetrics = metricsOptions.filter(({ value }) => MANDATORY_COHORT_METRICS.includes(value)),
            selectedPeriods = periodsOptions.filter(({ value }) => MANDATORY_COHORT_PERIODS.includes(value)),
        } = getSelectedOptions(readonly, selected, dimensionsOptions, eventsOptions, metricsOptions, periodsOptions);

        setMetrics(selectedMetrics);
        setPeriods(selectedPeriods);
    }, [readonly, selected, getOptions]);

    useEffect(() => {
        if (readonly) {
            return;
        }

        const cohortFields = getCohortFields([...metrics], periods);

        onChange({ ...cohortFields });
    }, [periods, metrics]);

    const autocompleteProps = {
        disabled: readonly,
        clearable: true,
        searchable: true,
        containerClass: css.field,
    };

    return (
        <>
            <AutoCompleteField
                value={metrics}
                label="STATIC.COHORT_METRICS"
                options={metricsOptions}
                onChange={setMetrics}
                onInputChange={setMetrics}
                {...autocompleteProps}
            />
            <AutoCompleteField
                value={periods}
                label="STATIC.COHORT_PERIODS"
                options={periodsOptions}
                onChange={setPeriods}
                onInputChange={setPeriods}
                {...autocompleteProps}
            />
        </>
    );
}

UnifiedCustomSchemaFields.propTypes = {
    readonly: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
};

UnifiedCustomSchemaFields.defaultProps = {
    readonly: false,
    selected: [],
};
