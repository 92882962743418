import { useCallback } from 'react';
import { useRequest } from 'utils/customHooks';

import APIKeysService from './service';

export const useResetAPIKey = (shouldRun, onCleanup) => {
    const KeysAPI = new APIKeysService();
    return useRequest(
        useCallback(KeysAPI.resetAPIKey, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        {}
    );
};
