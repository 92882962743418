import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { capitalize } from '../../../utils/strings';
import SingularButton from '../../../components/widgets/SingularButton';
import welcomePageCss from './sdkConfigurationWelcomePage.css';
import { Label } from '../../../components/widgets';
import Underline from '../undrline';
import { getTextWidth } from '../../../utils/general';
import WelcomeSvg from '../../../resources/svg/welcome.svg';

const firstNameFont = '600 22px open-sans';

const SDKConfigurationWelcome = ({ firstName, onStartButtonClick, onSkipButtonClick, translate }) => {
    const capFirstName = capitalize(firstName);
    const welcomeText = `${firstName ? `${capFirstName}, ` : ''}${translate(
        'STATIC.PAGES.SDK_CONFIGURATION.WELCOME_TEXT'
    )}`;

    return (
        <div className={welcomePageCss.welcomePage}>
            <div className={welcomePageCss.welcomeTextContainer}>
                <Label text={welcomeText} className={welcomePageCss.welcomeText} />
                <Underline
                    textLength={getTextWidth(capFirstName, firstNameFont)}
                    className={welcomePageCss.underline}
                />
            </div>
            <Label text="STATIC.PAGES.SDK_CONFIGURATION.TOP_TEXT_1" className={welcomePageCss.text1} />
            <Label text="STATIC.PAGES.SDK_CONFIGURATION.TOP_TEXT_2" />
            <WelcomeSvg className={welcomePageCss.welcomeSvg} />
            <div className={welcomePageCss.buttonContainer}>
                <SingularButton
                    onClick={onSkipButtonClick}
                    text="STATIC.PAGES.SDK_CONFIGURATION.SKIP_WIZARD_BUTTON"
                    type="flat"
                />
                <SingularButton
                    onClick={onStartButtonClick}
                    className={welcomePageCss.letsStartButton}
                    text="STATIC.PAGES.SDK_CONFIGURATION.START_WIZARD_BUTTON"
                />
            </div>
        </div>
    );
};

SDKConfigurationWelcome.propTypes = {
    onStartButtonClick: PropTypes.func.isRequired,
    onSkipButtonClick: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
};

export default withLocalize(SDKConfigurationWelcome);
