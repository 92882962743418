import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import css from './EditShelf.css';
import WizardFooter from '../../widgets/WizardFooter';
import { Shelf, WarningMessage } from '../../widgets';
import TimePeriod from './TimePeriod';
import Settings from './Settings';
import Section from './Section';
import FilterFields from '../../../containers/FilterFields';
import { getAdminModeEnabled, getIsUserAdmin, getUserData } from '../../../selectors/user';
import reportsConfig from '../../../reducers/reportsConfig/index';
import { reportTypes } from '../../../utils/reports';
import { downloadOrExportReport, isShelfOpen, setActionType } from '../../../actions/reports';
import { RunReportButton, DownloadReportButton } from '../../../containers/widgets';
import EditIconButton from '../../../resources/svg/edit_icon_button.svg';
import ClearShelfButton from './ClearShelfButton';
import AdminModeButton from './AdminModeButton';
import { FIELD_SECTION } from './consts';
import { shouldShowDownloadReport } from '../../../selectors/reports';

export default function EditShelf({ open, reportType }) {
    const downloadReportOrigin = 'edit_report_shelf';
    const [selectedFields, setSelectedFields] = useState([]);
    const dispatch = useDispatch();
    const adminMode = useSelector(state => getAdminModeEnabled(state));
    const isUserAdmin = useSelector(state => getIsUserAdmin(state));
    const userData = useSelector(state => getUserData(state));
    const validationError = useSelector(state => state[reportType]?.validationError);
    const reportConfig = reportsConfig.find(config => config.type === reportType);
    const allFields = reportType === reportTypes.mainReport ? reportConfig.shelf.regular : reportConfig.shelf;
    const isQueryRunning = useSelector(state => state[reportType]?.queryRunning);
    const showDownloadReportWarning = useSelector(state => shouldShowDownloadReport(reportType, 'dimensions')(state));
    const onCloseShelf = () => dispatch(setActionType(isShelfOpen(false), reportType));

    useLayoutEffect(() => {
        !validationError && isQueryRunning && onCloseShelf();
    }, [validationError, isQueryRunning]);

    return (
        <Shelf
            open={open}
            shelfSize="xlarge"
            headerText="Edit Report"
            HeaderImage={EditIconButton}
            headerImageClassName={css.editShelfIcon}
            headerClassName={css.editShelfHeader}
            enterAnimationDisabled={false}
            contentContainerClass={classNames(css.editShelfContent, {
                [css.editShelfContentWithWarning]: validationError || showDownloadReportWarning,
            })}
            onClose={onCloseShelf}
        >
            <div className={css.content}>
                <div className={css.topShelf}>
                    <TimePeriod reportType={reportType} />
                    <div>
                        <ClearShelfButton reportType={reportType} onClearShelf={() => setSelectedFields([])} />
                        {isUserAdmin && (
                            <div style={{ marginTop: '10px' }}>
                                <AdminModeButton isAdminMode={adminMode} />
                            </div>
                        )}
                    </div>
                </div>
                <Section index={1} showDivider title={FIELD_SECTION.FILTERS}>
                    <FilterFields reportType={reportType} />
                </Section>
                <Settings
                    allFields={allFields}
                    setSelectedFields={setSelectedFields}
                    selectedFields={selectedFields}
                    reportType={reportType}
                    adminMode={adminMode}
                    userData={userData}
                    onCloseShelf={onCloseShelf}
                />
            </div>
            {validationError ? (
                <WarningMessage
                    className={css.warningMessage}
                    type="error"
                    message={validationError}
                    showIcon={false}
                />
            ) : null}
            {!validationError && showDownloadReportWarning ? (
                <WarningMessage
                    className={css.warningMessage}
                    message="STATIC.PAGES.REPORTS.DOWNLOAD_REPORT_MESSAGE"
                    buttonText="STATIC.PAGES.REPORTS.DOWNLOAD_REPORT_BUTTON"
                    buttonAction={() => {
                        dispatch(
                            setActionType(
                                downloadOrExportReport(reportType, reportType, downloadReportOrigin),
                                reportType
                            )
                        );
                    }}
                    type="info"
                    showIcon={false}
                />
            ) : null}
            <WizardFooter
                buttons={[
                    <DownloadReportButton reportType={reportType} origin={downloadReportOrigin} />,
                    <RunReportButton reportType={reportType} />,
                ]}
            />
        </Shelf>
    );
}

EditShelf.propTypes = {
    reportType: PropTypes.string,
    open: PropTypes.bool,
};

EditShelf.defaultProps = {
    reportType: '',
    open: false,
};
