import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './EmptyState.css';
import Label from './Label';
import EmptyStateIcon from '../../resources/svg/empty_state.svg';
import EmptyStateIconNoDevice from '../../resources/svg/empty_state_happy.svg';
import EmptyStateNoChartData from '../../resources/svg/empty_states_no_chart_data.svg';
import EmptyStateNoDataSource from '../../../../dashboard/img/icons/no_data_source.svg';
import EmptyStateStartAdding from '../../../../dashboard/img/icons/editing_dude.svg';

const icons = {
    default: <EmptyStateIcon className={css.icon} />,
    noDevice: <EmptyStateIconNoDevice className={css.icon} />,
    happyPage: <EmptyStateIconNoDevice className={css.icon} />,
    noChartData: <EmptyStateNoChartData className={css.icon} />,
    noDataSource: <EmptyStateNoDataSource className={css.icon} />,
    startAdding: <EmptyStateStartAdding className={css.icon} />,
};

export const EMPTY_STATE_TEST_ID = 'empty-state';

class EmptyState extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
        const { onActionTriggered } = this.props;
        if (!e.target || !e.target.attributes || !e.target.attributes['data-action']) {
            return;
        }
        const actionName = e.target.attributes['data-action'].value;
        const actionArgs = e.target.attributes['data-action-args']
            ? e.target.attributes['data-action-args'].value.split(',')
            : [];
        onActionTriggered(actionName, ...actionArgs);
    }

    render() {
        const {
            style,
            header,
            headerStyle,
            subHeader,
            headerTextReplace,
            subHeaderTextReplace,
            icon,
            customIcon,
            containerClass,
            children,
        } = this.props;
        return (
            <div
                data-testid={EMPTY_STATE_TEST_ID}
                className={classNames(css.container, containerClass)}
                style={style}
                onClick={this.handleClick}
            >
                {customIcon || icons[icon]}
                <div className={css.textContainer}>
                    <Label
                        style={{ margin: 20, color: '#191E21', fontSize: 16, fonWeight: 400, ...headerStyle }}
                        text={header}
                        textReplace={headerTextReplace}
                    />
                    {subHeader ? (
                        <Label
                            type="h4"
                            text={subHeader}
                            textReplace={subHeaderTextReplace}
                            style={{
                                marginTop: '10',
                                lineHeight: '1.8',
                                color: '#515864',
                            }}
                        />
                    ) : null}
                    {children}
                </div>
            </div>
        );
    }
}

EmptyState.propTypes = {
    style: PropTypes.objectOf(PropTypes.any),
    header: PropTypes.string,
    headerStyle: PropTypes.objectOf(PropTypes.any),
    headerTextReplace: PropTypes.objectOf(PropTypes.any),
    subHeader: PropTypes.string,
    subHeaderTextReplace: PropTypes.objectOf(PropTypes.any),
    icon: PropTypes.string,
    customIcon: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
    onActionTriggered: PropTypes.func,
};

EmptyState.defaultProps = {
    style: {},
    header: '',
    headerStyle: {},
    headerTextReplace: {},
    subHeader: '',
    subHeaderTextReplace: {},
    icon: 'default',
    customIcon: null,
    containerClass: '',
    onActionTriggered: () => {},
};

export default EmptyState;
