import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import OverflowingText from './OverflowingText';
import css from './AutoCompleteSingleValue.css';
import CheckMarkIcon from '../../resources/icons/check_mark.svg';
import Icon, { IconSizes, IconVariants } from '../foundations/Icon';

const COPY_TIMEOUT = 3000;

const AutoCompleteSingleValue = ({ className, selectProps, data, isDisabled, cx, getStyles, ...props }) => {
    const { isCopiable, getOptionLabel } = selectProps;

    const [copied, setCopied] = useState(false);

    const onCopyClicked = e => {
        e.stopPropagation();

        // copy to clipboard
        navigator.clipboard.writeText(getOptionLabel(data));

        setCopied(true);
        setTimeout(() => setCopied(false), COPY_TIMEOUT);
    };

    return (
        <div
            style={getStyles('singleValue', props)}
            className={cx({ 'single-value': true, 'single-value--is-disabled': isDisabled }, css.singleValue)}
        >
            <div className={css.value}>
                {data.icon && <div className={classNames(css.icon, { [css.disabled]: isDisabled })}>{data.icon}</div>}
                <OverflowingText>{getOptionLabel(data)}</OverflowingText>
            </div>
            {isCopiable &&
                !isDisabled &&
                (copied ? (
                    <CheckMarkIcon className={css.checkmark} />
                ) : (
                    <Icon name="copy" size={IconSizes.SMALL} variant={IconVariants.LIGHT} onMouseDown={onCopyClicked} />
                ))}
        </div>
    );
};

AutoCompleteSingleValue.propTypes = {
    className: PropTypes.string,
    selectProps: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.objectOf(PropTypes.any),
    isDisabled: PropTypes.bool,
    cx: PropTypes.func,
    getStyles: PropTypes.func,
};

AutoCompleteSingleValue.defaultProps = {
    className: '',
    selectProps: {},
    data: {},
    isDisabled: false,
    cx: () => {},
    getStyles: () => {},
};

export default AutoCompleteSingleValue;
