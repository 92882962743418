import React from 'react';
import PropTypes from 'prop-types';

function AttributionLandingPage({ userData }) {
    const { first_name, last_name, company, email, is_impersonator } = userData;
    const linkParams = `?first_name=${first_name}&last_name=${last_name}&company=${company}&email=${email}&is_impersonate=${is_impersonator}`;
    const iframeSrc = `https://www.singular.net/lp/in-app-attribution-lp/${
        Object.keys(userData).length ? linkParams : ''
    }`;
    return (
        <div style={{ height: '100%' }}>
            <iframe title="attribution" width="100%" height="100%" style={{ border: 'none' }} src={iframeSrc} />
        </div>
    );
}

AttributionLandingPage.propsTypes = {
    userData: PropTypes.object,
    last_name: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
};

AttributionLandingPage.defaultProps = {
    userData: {},
};

export default AttributionLandingPage;
