import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik-2';
import { useLazyQuery } from '@apollo/client';
import { withLocalize } from 'react-localize-redux';
import css from './DuplicateAppSelection.css';
import { partnerShape, siteShape, templateShape } from '../types';
import { GET_EVENTS } from '../../queries';
import Label from '../../../components/widgets/Label';
import DuplicateFromIcon from '../../../resources/svg/copy-from.svg';
import DuplicateToIcon from '../../../resources/svg/copy-to.svg';
import AppAutocomplete from './AppAutocomplete';
import { MessageTypes } from '../../../teamManagement/components/WizardWarningMessage';

const getDuplicatedPostbacks = (postbacks, events) => {
    if (!postbacks || !events) return null;

    // get duplicated site event postbacks by event name
    const duplicatedPostbacks = postbacks
        .map(({ eventId, eventName, ...postback }) => {
            const duplicatedEvent = events.find(event => event.eventName === eventName);
            return duplicatedEvent ? { ...postback, eventId: duplicatedEvent.id } : null;
        })
        .filter(Boolean);

    return duplicatedPostbacks.length ? duplicatedPostbacks : null;
};

function DuplicateAppSelection({
    duplicatedSite,
    partner,
    sites,
    postbackTemplates,
    onChange: onChangeProp,
    onWarning,
    translate,
}) {
    const { values, setValues } = useFormikContext();
    const [getEvents, getEventsResponse] = useLazyQuery(GET_EVENTS);

    const filteredSites = sites.filter(site => site.site_id !== duplicatedSite.site_id);

    useEffect(() => {
        const { called, loading, data } = getEventsResponse;
        if (!called || loading) return;

        setValues({
            ...values,
            customEventPostbacks: getDuplicatedPostbacks(values.customEventPostbacks, data.events),
            revenueEventPostbacks: getDuplicatedPostbacks(values.revenueEventPostbacks, data.events),
        });

        const siteEventNames = data.events?.map(({ eventName }) => eventName);
        const duplicatedSiteEventNames = [
            ...(values.customEventPostbacks || []),
            ...(values.revenueEventPostbacks || []),
        ].map(({ eventName }) => eventName);

        const notDuplicatedEvents = duplicatedSiteEventNames.filter(event => !siteEventNames.includes(event));
        if (notDuplicatedEvents.length > 0) {
            onWarning({
                type: MessageTypes.WARNING,
                message: translate('STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.NOT_DUPLICATED_EVENTS_WARNING', {
                    events: notDuplicatedEvents.join(', '),
                }),
            });
        }
    }, [getEventsResponse.data]);

    const onChange = ({ value }) => {
        if (values.site === value) return;

        // don't reset form values in case of duplicate
        setValues({ ...values, site: value });
        getEvents({ variables: { siteId: value } });

        onChangeProp(value);
    };

    return (
        <div className={css.container}>
            <div className={css.duplicatedSite}>
                <Label
                    className={css.label}
                    iconClassName={css.icon}
                    icon={DuplicateFromIcon}
                    text="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.DUPLICATE_FROM_APP_LABEL"
                />
                <img src={duplicatedSite.site_icon} />
                <div>{duplicatedSite.fullName}</div>
            </div>
            <div className={css.duplicateToSite}>
                <Label
                    className={css.label}
                    iconClassName={css.icon}
                    icon={DuplicateToIcon}
                    text="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.DUPLICATE_TO_APP_LABEL"
                />
                <AppAutocomplete
                    partner={partner}
                    sites={filteredSites}
                    disabled={!!values.site}
                    postbackTemplates={postbackTemplates}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

DuplicateAppSelection.propTypes = {
    duplicatedSite: siteShape.isRequired,
    partner: partnerShape.isRequired,
    sites: PropTypes.arrayOf(siteShape),
    postbackTemplates: PropTypes.arrayOf(templateShape),
    onChange: PropTypes.func,
    onWarning: PropTypes.func,
    translate: PropTypes.func.isRequired,
};

DuplicateAppSelection.defaultProps = {
    sites: [],
    postbackTemplates: [],
    onChange: () => {},
    onWarning: () => {},
};

export default withLocalize(DuplicateAppSelection);
