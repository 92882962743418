import {
    COARSE_VALUE_UNUSED,
    COARSE_VALUES_AMOUNT,
    EventsModelTypes,
    RevenueTypeDisplayNames,
    ZERO_CONVERSION_VALUE_FIXED_EVENT,
} from '../../consts';
import css from '../modelTypeForms/RevenueConversionModelForm.css';
import TableCellDropdown from '../../../components/widgets/TableCellDropdown';
import { getSdkEventsValues } from '../../utils';

export const getEventsRevenueValuesList = (sdkEvents, values) => {
    const revenueValues = Object.entries(RevenueTypeDisplayNames).map(([name, display_name]) => ({
        isRevenue: true,
        name,
        display_name,
    }));

    const { apsalarEventIds } = values;

    // get events items for events that have not already selected.
    const sdkEventsValues = getSdkEventsValues(sdkEvents)
        .filter(({ id }) => !apsalarEventIds?.includes(id))
        .map(({ id, name }) => ({
            isEvent: true,
            name: id,
            display_name: name,
        }));

    const revenueSector = {
        name: 'revenueSector',
        display_name: 'Revenue',
        disabled: true,
        divider: true,
        isRevenue: true,
    };
    const eventsSector = {
        name: 'eventsSector',
        display_name: 'Events',
        disabled: true,
        divider: true,
        isEvent: true,
    };

    let items = [revenueSector, ...revenueValues];

    if (sdkEventsValues.length) {
        items = items.concat([eventsSector, ...sdkEventsValues]);
    }

    return items;
};

export const onEventsRevenueValueSelection = (values, value, rowIndex, revenueBucketSize) => {
    const { name, isEvent, isRevenue } = value;
    const { apsalarEventIds, buckets } = values;
    const updatedFields = {};

    const newEvents = [...(apsalarEventIds || [])];
    const newBuckets = [...(buckets || [])];

    // In case the value is event.
    if (isEvent) {
        updatedFields.eventsType = EventsModelTypes.FUNNEL;

        // In case of changing a revenue selection to event.
        if (rowIndex >= newEvents.length) {
            newBuckets.pop();
            updatedFields.buckets = newBuckets;

            if (!newBuckets.length) {
                updatedFields.revenueType = null;
            }
        }

        newEvents[rowIndex] = name;
        updatedFields.apsalarEventIds = newEvents;
    }
    // In case the value is revenue.
    else if (isRevenue) {
        updatedFields.revenueType = name;

        // In case of changing an event selection to revenue.
        if (rowIndex < newEvents.length) {
            // Deleting all events selected from the rowIndex and bellow.
            // When changing to revenue, all rows bellow will be revenue too.
            const deleteEventsCount = newEvents.length - rowIndex;
            newEvents.splice(rowIndex, deleteEventsCount);
            updatedFields.apsalarEventIds = newEvents;

            if (!newEvents.length) {
                updatedFields.eventsType = null;
            }

            // Recover revenue buckets in case of changing a value from event to revenue.
            for (let i = 0; i < deleteEventsCount; i++) {
                if (newBuckets.length) {
                    newBuckets.push(newBuckets[newBuckets.length - 1] + revenueBucketSize);
                } else {
                    newBuckets.push(0);
                }
            }
        }

        if (!newBuckets.length) {
            newBuckets.push(0);
        }

        updatedFields.buckets = newBuckets;
    }

    return updatedFields;
};

const getSelectedValue = (rowIndex, sdkEvents, values) => {
    const { apsalarEventIds, revenueType } = values;

    if (apsalarEventIds && apsalarEventIds[rowIndex] !== undefined) {
        const event = sdkEvents[apsalarEventIds[rowIndex]];

        if (!event) return undefined;

        return {
            name: event.id,
            display_name: event.displayName,
        };
    }

    return { name: revenueType, display_name: RevenueTypeDisplayNames[revenueType] };
};

export const getEventsRevenueColumnDef = (
    values,
    setValues,
    multiCoarseMapping,
    sdkEvents,
    revenueBucketSize,
    isCustomFunnel
) => ({
    displayName: 'STATIC.PAGES.SKADNETWORK.EVENTS_REVENUE_FILTER_LABEL',
    className: css.tableHeader,
    headerAlign: 'center',
    cellComponent: TableCellDropdown,
    headerWidth: isCustomFunnel ? '250px' : '340px',
    cellProps: {
        dropdownContainerStyle: { width: isCustomFunnel ? '230px' : '320px' },
        wrapperStyle: {
            width: '100%',
        },
        selectedContainerStyle: {
            padding: '0 10px',
            height: '28px',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        getValuesList: rowIndex => {
            let valuesList = getEventsRevenueValuesList(sdkEvents, values);

            if (isCustomFunnel && rowIndex === 1) {
                valuesList = valuesList.filter(v => v.isEvent);
            }

            return valuesList;
        },
        getSelected: (value, rowIndex) => {
            if (isCustomFunnel && rowIndex === 0) {
                return { name: ZERO_CONVERSION_VALUE_FIXED_EVENT, display_name: ZERO_CONVERSION_VALUE_FIXED_EVENT };
            }

            return getSelectedValue(rowIndex, sdkEvents, values);
        },
        getIsRowReadOnly: rowIndex => {
            const isReadOnly = rowIndex > (values.apsalarEventIds?.length || 0);

            if (isCustomFunnel) {
                return rowIndex === 0 || isReadOnly;
            }

            return isReadOnly;
        },
        onValueSelection: (value, id, rowIndex) => {
            const updatedFields = onEventsRevenueValueSelection(values, value, rowIndex, revenueBucketSize);

            if (isCustomFunnel) {
                const { buckets = [] } = updatedFields;

                if (buckets.length === 1) {
                    const { customMapping = {} } = values;
                    const newCustomMapping = { ...customMapping };
                    const previousIndex = (rowIndex - 1).toString();
                    const currentIndex = rowIndex.toString();
                    const previousCoarse = parseInt(newCustomMapping[previousIndex], 10);
                    const newCoarse = Math.min(previousCoarse + 1, COARSE_VALUES_AMOUNT - 1);

                    newCustomMapping[currentIndex] = newCoarse.toString();

                    if (!multiCoarseMapping) {
                        newCustomMapping[currentIndex] = COARSE_VALUE_UNUSED.toString();
                    }
                    updatedFields.customMapping = newCustomMapping;
                }
            }

            setValues({
                ...values,
                ...updatedFields,
            });
        },
    },
});
