import { schema } from 'normalizr';
import { LINK_TYPE_MOBILE_CUSTOM, LINK_TYPE_MOBILE_WEB_TO_APP } from '../linkManagement/linkTypes';
import EmailIcon from '../resources/svg/custom_source_email.svg';
import SMSIcon from '../resources/svg/custom_source_sms.svg';
import CrosspromoIcon from '../resources/svg/custom_source_corsspromo.svg';
import SocialIcon from '../resources/svg/custom_source_social.svg';
import CustomIcon from '../resources/svg/customize.svg';

export const SOURCE_NAME_MOBILE_WEB_TO_APP = 'Mobile Web to App';

const attributionSource = new schema.Entity('sources');
export const attributionSourceSchema = new schema.Array(attributionSource);

const sourceLogoToElement = {
    social: SocialIcon,
    SMS: SMSIcon,
    Email: EmailIcon,
    crosspromo: CrosspromoIcon,
    custom: CustomIcon,
    mobile_web_to_app: CustomIcon,
};

export const IP_PROBABILISTIC_SUPPORT_OPTIONS = {
    NEVER: 'never',
    OPTIONAL: 'optional',
    RECOMMENDED: 'recommended',
    MANDATORY: 'mandatory',
};

const CUSTOM_SOURCES_LIST = [
    {
        icon: 'social',
        name: 'social',
        display_name: 'Social',
        singular_links_ready: true,
        support_clicks: true,
        ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
    },
    {
        icon: 'SMS',
        name: 'SMS',
        display_name: 'SMS',
        singular_links_ready: true,
        support_clicks: true,
        ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
    },
    {
        icon: 'Email',
        name: 'Email',
        display_name: 'Email',
        singular_links_ready: true,
        support_clicks: true,
        ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
    },
    {
        icon: 'crosspromo',
        name: 'crosspromo',
        display_name: 'Crosspromo',
        singular_links_ready: true,
        support_clicks: true,
        ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
    },
    {
        icon: 'custom',
        name: 'custom',
        display_name: 'Custom',
        singular_links_ready: true,
        support_clicks: true,
        ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
    },
];

const MOBILE_WEB_TO_APP_SOURCES_LIST = [
    {
        icon: 'mobile_web_to_app',
        name: SOURCE_NAME_MOBILE_WEB_TO_APP,
        display_name: SOURCE_NAME_MOBILE_WEB_TO_APP,
        singular_links_ready: true,
        support_clicks: true,
        ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
    },
];

export function getPredefinedSourcesList(linkType) {
    if (linkType === LINK_TYPE_MOBILE_CUSTOM) {
        return CUSTOM_SOURCES_LIST;
    }

    if (linkType === LINK_TYPE_MOBILE_WEB_TO_APP) {
        return MOBILE_WEB_TO_APP_SOURCES_LIST;
    }

    return [];
}

export const CUSTOM_SOURCES_NAMES = CUSTOM_SOURCES_LIST.map(cs => cs.name);
export const CUSTOM_SOURCES_DISPLAYED_NAMES = CUSTOM_SOURCES_LIST.map(cs => cs.display_name);
export const CUSTOM_SOURCE_LOGO_MAP = CUSTOM_SOURCES_LIST.reduce((total, source) => {
    total[source.name] = source.icon;
    return total;
}, {});

export const getCustomSourceLogo = logo => {
    if (CUSTOM_SOURCES_NAMES.includes(logo) || MOBILE_WEB_TO_APP_SOURCES_LIST.includes(logo)) {
        return sourceLogoToElement[logo];
    }
    return '';
};
