import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import css from './page.css';
import { SingularButton } from '../components/widgets';
import ReferralsIcon from '../resources/svg/referrals_icon.svg';

function PartnersLandingPage({ partnerName = '', openDialog = () => {} }) {
    return (
        <div className={css.container}>
            <div className={css.header}>
                <Translate id="STATIC.PAGES.PARTNERS_PORTAL.LANDING_PAGE_HEADER" data={{ partnerName }} />
            </div>
            <div className={css.subHeader}>
                <Translate id="STATIC.PAGES.PARTNERS_PORTAL.LANDING_PAGE_SUB_HEADER" />
            </div>
            <ReferralsIcon />
            <SingularButton className={css.button} level="important" onClick={openDialog}>
                <Translate id="STATIC.PAGES.PARTNERS_PORTAL.LANDING_PAGE_BUTTON" />
            </SingularButton>
        </div>
    );
}

export default PartnersLandingPage;
