import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RevenueType from './RevenueType';
import { getModelTypeData, getNumberOfRevenueSlots } from '../../../utils';
import { MIN_EVENTS_SLOTS, ModelKinds, NUMBER_OF_BITS } from '../../../consts';
import { calculateUsedMixedSlots, getMaxAvailableSlotsForSubType } from '../../modelTypeForms/MixedFormUtils';
import ModelForm from './ModelForm';
import { conversionModelTypeShape, modelErrorsShape, sdkEventsShape, typesListShape } from '../types';
import EventsType from './EventsType';
import { getUsedSlotsByEventType } from '../eventsUtils';

function MixedType({ values, sdkEvents, onChange, errors, currencySymbol, typesList, maxSlots }) {
    const [revenueMaxSlots, setRevenueMaxSlots] = useState();
    const [eventsMaxSlots, setEventsMaxSlots] = useState();
    const { eventsType, isCustomRevenue, numberOfRevenueBuckets, buckets, apsalarEventIds: eventIds } = values;

    useEffect(() => {
        const isEngagement = eventsType === ModelKinds.ENGAGEMENT;
        const revenueSlots = getNumberOfRevenueSlots(isCustomRevenue, numberOfRevenueBuckets, buckets);

        const bitsByType = {
            [ModelKinds.REVENUE]: revenueSlots,
            [eventsType]: isEngagement
                ? maxSlots - revenueSlots
                : getUsedSlotsByEventType(eventsType, eventIds, eventsMaxSlots).length || MIN_EVENTS_SLOTS,
        };

        const [newRevenueMaxSlots, newEventsMaxSlots] = [
            getMaxAvailableSlotsForSubType(ModelKinds.REVENUE, eventsType, bitsByType, eventIds, maxSlots),
            getMaxAvailableSlotsForSubType(eventsType, ModelKinds.REVENUE, bitsByType, eventIds, maxSlots),
        ];

        setRevenueMaxSlots(newRevenueMaxSlots);
        setEventsMaxSlots(newEventsMaxSlots);
        onChange({ usedSlots: calculateUsedMixedSlots(values, newEventsMaxSlots, maxSlots) });
    }, [isCustomRevenue, buckets, numberOfRevenueBuckets, eventIds, maxSlots]);

    if (!revenueMaxSlots || !eventsMaxSlots) {
        return null;
    }

    return (
        <>
            <ModelForm typeData={getModelTypeData(typesList, eventsType)}>
                <EventsType
                    onChange={onChange}
                    sdkEvents={sdkEvents}
                    errors={errors}
                    values={values}
                    maxSlots={eventsMaxSlots}
                />
            </ModelForm>
            <ModelForm typeData={getModelTypeData(typesList, ModelKinds.REVENUE)}>
                <RevenueType
                    onChange={onChange}
                    values={values}
                    errors={errors}
                    currencySymbol={currencySymbol}
                    maxSlots={revenueMaxSlots}
                />
            </ModelForm>
        </>
    );
}

MixedType.propTypes = {
    values: conversionModelTypeShape.isRequired,
    sdkEvents: PropTypes.objectOf(sdkEventsShape).isRequired,
    onChange: PropTypes.func,
    errors: modelErrorsShape,
    currencySymbol: PropTypes.string,
    typesList: PropTypes.arrayOf(typesListShape).isRequired,
    maxSlots: PropTypes.number,
};
MixedType.defaultProps = {
    onChange: () => {},
    errors: {},
    currencySymbol: '$',
    maxSlots: NUMBER_OF_BITS,
};

export default MixedType;
