import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Handsontable from 'handsontable';
import { withLocalize } from 'react-localize-redux';
import { HotColumn, HotTable } from '@handsontable/react';
import '../../../node_modules/handsontable/dist/handsontable.full.css';

import { DROPDOWN_TYPES, FIELD_TYPES } from 'governance/consts';
import cloneDeep from 'lodash/cloneDeep';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import UpIcon from '../../resources/svg/sort_up.svg';
import DownIcon from '../../resources/svg/sort_down.svg';
import css from '../governance.css';
import { Checkbox, Label } from '../../components/widgets';
import EditableTitle from '../../components/widgets/EditableTitle';
import { FIELD_FRAGMENT, SCHEMA_FRAGMENT } from '../queries';
import client from '../../apollo';
import TagInput from '../../components/widgets/TagInput';
import Tooltip from '../../components/widgets/Tooltip';
import AutoComplete from '../../components/widgets/AutoComplete';
import { computePattern, isAssetFieldType } from '../utils';
import TextField from '../../components/widgets/TextField';

let focusFieldIndexAfterMove = null;

function Field({ schemaId, fieldIndex, field, error, translate, firstField, lastField }) {
    function changeFieldValue(key, value) {
        // Read schema from cache
        const schema = client.readFragment({
            id: `SchemaNode:${schemaId}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
        });

        // Update the field object
        const _field = { ...schema.fields[fieldIndex] };
        _field[key] = value;
        if (key === 'values') {
            _field.default = _field.default.filter(x => value.includes(x));
        } else if (key === 'kind') {
            _field.default = [];
        }
        const newSchema = update(schema, {
            fields: {
                [fieldIndex]: {
                    $set: _field,
                },
            },
        });

        // Recompute pattern
        newSchema.pattern = computePattern(newSchema.fields);

        // Write schema
        client.writeFragment({
            id: `SchemaNode:${schemaId}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
            data: newSchema,
        });
    }

    function deleteFieldFromCache() {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Are you sure you want to delete this field?')) {
            return;
        }

        const schema = client.readFragment({
            id: `SchemaNode:${schemaId}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
        });
        const newFields = schema.fields.slice();
        newFields.splice(fieldIndex, 1);
        client.writeFragment({
            id: `SchemaNode:${schemaId}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
            data: {
                ...schema,
                fields: newFields,
                pattern: computePattern(newFields),
            },
        });
    }

    function moveFieldToPosition(newIndex) {
        focusFieldIndexAfterMove = newIndex;
        const schema = client.readFragment({
            id: `SchemaNode:${schemaId}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
        });
        const newFields = schema.fields.slice();
        newFields.splice(newIndex, 0, newFields.splice(fieldIndex, 1)[0]);
        client.writeFragment({
            id: `SchemaNode:${schemaId}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
            data: {
                ...schema,
                fields: newFields,
                pattern: computePattern(newFields),
            },
        });
    }

    const fieldDiv = useRef(null);
    useEffect(() => {
        if (focusFieldIndexAfterMove === fieldIndex) {
            fieldDiv.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            focusFieldIndexAfterMove = null;
        }
    }, [focusFieldIndexAfterMove]);

    const translatedFieldTypes = useMemo(
        () => FIELD_TYPES.map(({ value, label }) => ({ value, label: translate(label) })),
        [FIELD_TYPES]
    );

    const hotData = useMemo(() => {
        // let clonedValues = cloneDeep(field.values);
        // if (clonedValues.filter((x) => !x.code || !x.value).length === 0) {
        //     clonedValues = [...clonedValues, {code: '', value: ''}];
        // }
        return [...cloneDeep(field.values), { code: '', value: '' }];
    }, [field.values]);

    const hotTableComponent = useRef(null);

    return (
        <div ref={fieldDiv} style={{ backgroundColor: '#f8f8f8', marginBottom: 20 }}>
            <div className={css.governanceFieldHeader}>
                <span style={{ fontSize: 16, marginRight: 10 }}>Field Name:</span>
                <div style={{ flex: 1, width: 313, marginRight: 20 }}>
                    <EditableTitle
                        placeholder={translate('STATIC.PAGES.GOVERNANCE.SCHEMAS.FIELD_NAME_PLACEHOLDER')}
                        value={field.name}
                        onTextChanged={name => changeFieldValue('name', name)}
                        containerStyle={{ marginTop: 1 }}
                    />
                </div>
                <div>
                    {!firstField && (
                        <Tooltip interactive titleTranslationKey="STATIC.PAGES.GOVERNANCE.SCHEMAS.MOVE_FIELD_UP">
                            <UpIcon className={css.upDownButton} onClick={() => moveFieldToPosition(fieldIndex - 1)} />
                        </Tooltip>
                    )}
                    {!lastField && (
                        <Tooltip interactive titleTranslationKey="STATIC.PAGES.GOVERNANCE.SCHEMAS.MOVE_FIELD_DOWN">
                            <DownIcon
                                className={css.upDownButton}
                                onClick={() => moveFieldToPosition(fieldIndex + 1)}
                            />
                        </Tooltip>
                    )}
                    <XIcon onClick={deleteFieldFromCache} className={css.xButton} />
                </div>
            </div>

            <div style={{ padding: 20 }}>
                <Label
                    text="STATIC.PAGES.GOVERNANCE.FIELD.FIELD_TYPE"
                    style={{ paddingTop: 10, paddingBottom: 10, fontSize: '13px', fontWeight: 300 }}
                />
                <AutoComplete
                    // containerStyle={{ maxWidth: '850px', zIndex: 1000 }}
                    containerStyle={{ maxWidth: '850px' }}
                    isCreatable={false}
                    controlledValue={
                        !field.kind ? translatedFieldTypes[0] : translatedFieldTypes.find(x => x.value === field.kind)
                    }
                    onChange={({ value }) => {
                        changeFieldValue('kind', value);

                        // Changing to asset type - these fields are always hidden
                        if (isAssetFieldType(value)) {
                            changeFieldValue('hidden', true);
                        }

                        // Changing from an asset type to a non-asset type, mark as non-hidden
                        else if (isAssetFieldType(field.kind) && !isAssetFieldType(value)) {
                            changeFieldValue('hidden', false);
                        }
                    }}
                    selectOptions={{
                        isSearchable: false,
                        options: translatedFieldTypes,
                    }}
                />
                {DROPDOWN_TYPES.includes(field.kind) && (
                    <Fragment>
                        <Label
                            text="STATIC.PAGES.GOVERNANCE.FIELD.ALLOWED_VALUES"
                            style={{ paddingTop: 10, paddingBottom: 10, fontSize: '13px', fontWeight: 300 }}
                        />
                        <HotTable
                            id="hot"
                            data={hotData}
                            ref={hotTableComponent}
                            colHeaders
                            stretchH="all"
                            licenseKey="non-commercial-and-evaluation"
                            height="200px"
                            width="850px"
                            manualColumnResize
                            afterChange={changes => {
                                if (!changes) return;
                                changeFieldValue(
                                    'values',
                                    hotTableComponent.current.hotInstance
                                        .getSourceData()
                                        .filter(({ code, value }) => code || value)
                                        .map(({ code, value }) => ({ value, code: code || value }))
                                );
                            }}
                            selectionMode="range"
                        >
                            <HotColumn data="value" title="Value" />
                            <HotColumn data="code" title="Shortened Value" />
                        </HotTable>
                    </Fragment>
                )}
                {!isAssetFieldType(field.kind) && (
                    <Fragment>
                        <Label
                            text="STATIC.PAGES.GOVERNANCE.FIELD.DEFAULT_VALUES"
                            style={{ paddingTop: 10, paddingBottom: 10, fontSize: '13px', fontWeight: 300 }}
                        />
                        {field.kind === Handsontable.cellTypes.autocomplete.CELL_TYPE && (
                            <AutoComplete
                                // containerStyle={{ width: '850px', zIndex: 1001 }}
                                containerStyle={{ width: '850px' }}
                                isCreatable={false}
                                controlledValue={
                                    field.default.length > 0
                                        ? { label: field.default[0].value, value: field.default[0].code }
                                        : null
                                }
                                onChange={option => {
                                    changeFieldValue('default', [{ code: option.value, value: option.label }]);
                                }}
                                selectOptions={{
                                    isSearchable: false,
                                    options: field.values.map(({ code, value }) => ({
                                        label: value,
                                        value: code,
                                    })),
                                }}
                            />
                        )}
                        {field.kind === 'tag' && (
                            <TagInput
                                placeholder="STATIC.PAGES.GOVERNANCE.FIELD.DEFAULT_VALUES"
                                containerStyle={{ width: '850px' }}
                                tags={field.default.map(({ code, value }) => ({ value: code, label: value }))}
                                creatable={false}
                                suggestions={field.values.map(({ code, value }) => ({ value: code, label: value }))}
                                onChange={tags => {
                                    changeFieldValue(
                                        'default',
                                        tags.map(x => ({ code: x.value, value: x.label }))
                                    );
                                }}
                            />
                        )}
                        {field.kind === 'text' && (
                            <TextField
                                placeholder={translate('STATIC.PAGES.GOVERNANCE.FIELD.DEFAULT_VALUES')}
                                containerStyle={{ width: '850px' }}
                                value={field.default.length > 0 ? field.default[0].value : ''}
                                onChange={newText => {
                                    changeFieldValue('default', [{ code: newText, value: newText }]);
                                }}
                            />
                        )}
                    </Fragment>
                )}
                <br />
                <Tooltip
                    interactive
                    position="top-start"
                    title={translate('STATIC.PAGES.GOVERNANCE.FIELD.OPTIONAL_TOOLIP')}
                >
                    <Checkbox
                        label={translate('STATIC.PAGES.GOVERNANCE.FIELD.OPTIONAL')}
                        size="small"
                        checked={field.optional}
                        onChange={x => {
                            changeFieldValue('optional', x);
                        }}
                    />
                </Tooltip>
                <br />
                <Tooltip
                    interactive
                    position="top-start"
                    title={translate('STATIC.PAGES.GOVERNANCE.FIELD.HIDDEN_TOOLTIP')}
                >
                    <Checkbox
                        label={translate('STATIC.PAGES.GOVERNANCE.FIELD.HIDDEN')}
                        size="small"
                        checked={field.hidden}
                        onChange={x => {
                            changeFieldValue('hidden', x);
                        }}
                        disabled={isAssetFieldType(field.kind)}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

Field.propTypes = {
    schemaId: PropTypes.string.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    field: PropTypes.shape({
        guid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        kind: PropTypes.oneOf(FIELD_TYPES.map(x => x.value)),
        values: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                value: PropTypes.string,
            })
        ).isRequired,
        default: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                value: PropTypes.string,
            })
        ).isRequired,
        optional: PropTypes.bool,
        hidden: PropTypes.bool,
    }).isRequired,
    translate: PropTypes.func.isRequired,
    firstField: PropTypes.bool.isRequired,
    lastField: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

Field.defaultProps = {
    error: '',
};

Field.fragments = {
    field: FIELD_FRAGMENT,
};

export default withLocalize(Field);
