export const getSelectedSection = (sections, pathname) => {
    return sections.find(section => {
        return (
            section.link === pathname ||
            section.elements.find(subSection => {
                return subSection.link === pathname;
            })
        );
    });
};

export const getMenuElement = (sections, pathname) => {
    const selectedSection = getSelectedSection(sections, pathname);

    if (!selectedSection) return null;

    if (selectedSection.link === pathname) return selectedSection;

    const { elements } = selectedSection;
    return elements ? elements.find(el => el.link === pathname) : null;
};
