import { gql } from '@apollo/client';

const UPLOAD_FRAGMENT = gql`
    fragment UploadStatus on UploadNode {
        id
        name
        status
        eventsProcessed
        totalEvents
        created
        modified
        filePath
        failures
    }
`;

const READ_UPLOADS_STATUS = gql`
    {
        uploads {
            ...UploadStatus
        }
    }
    ${UPLOAD_FRAGMENT}
`;

export { READ_UPLOADS_STATUS };
