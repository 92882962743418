import React from 'react';

import css from './Edit.pcss';
import { FILTER_TYPES, INNER_LOGIC, NUM_OPS, FILTER_LABELS, TIMES } from '../consts';
import { Dropdown, AutoCompleteField, TextField } from '../../components/widgets';
import PlusIcon from '../../resources/svg/plus.svg';
import XIcon from '../../resources/svg/x.svg';

export default class Condition extends React.Component {
    render() {
        const {
            index,
            cIndex,
            filter,
            condition,
            metadata,
            editFilter,
            addCondition,
            editCondition,
            deleteCondition,
            apps,
            invalid,
        } = this.props;
        const isRevenue = filter.filter_type === FILTER_TYPES.REVENUE;
        const options = metadata ? (isRevenue ? metadata.revenueOptions : metadata.eventOptions) : [];
        return (
            <div className={css.condition}>
                <div className={css.flexRow}>
                    {cIndex !== 0 && (
                        <Dropdown
                            containerClass={`${css.conditionDropdown} ${css.blueDropdown}`}
                            popperClass={css.conditionDropdownPopper}
                            selected={{ name: filter.inner_logic, display_name: filter.inner_logic }}
                            onSelection={(s) => {
                                editFilter(index, 'inner_logic', s.name);
                            }}
                            items={[
                                { name: INNER_LOGIC.and, display_name: INNER_LOGIC.and },
                                { name: INNER_LOGIC.or, display_name: INNER_LOGIC.or },
                            ]}
                        />
                    )}
                    <div className={`${css.labelGroup} ${css.eventBlock}`}>
                        <div className={css.label}>{FILTER_LABELS[filter.filter_type]}</div>
                        <AutoCompleteField
                            value={condition.event}
                            options={options}
                            isMulti={false}
                            onChange={(e) => {
                                editCondition(index, cIndex, 'event', e);
                            }}
                            disabled={!apps.length}
                            placeholder={!apps.length ? 'Select Apps Above to Populate Events' : 'Select an Event'}
                            hasError={invalid && !condition.event}
                        />
                    </div>
                </div>
                <div className={css.flexRow} style={{ marginTop: 16 }}>
                    <div className={css.labelGroup}>
                        <div className={css.label}>is</div>
                        <Dropdown
                            containerClass={`${css.conditionDropdown} ${css.op}`}
                            popperClass={css.conditionDropdownPopper}
                            selected={{ name: condition.op, display_name: NUM_OPS[condition.op] }}
                            onSelection={(s) => {
                                editCondition(index, cIndex, 'op', s.name);
                            }}
                            items={Object.keys(NUM_OPS).map((o) => ({
                                name: o,
                                display_name: NUM_OPS[o],
                            }))}
                        />
                    </div>
                    <div className={css.labelGroup}>
                        <div className={css.label}>
                            {filter.filter_type === FILTER_TYPES.REVENUE && metadata ? metadata.currency : ' '}
                        </div>
                        <TextField
                            type={condition.op === 'eq' ? 'text' : 'number'}
                            value={condition.valueA}
                            onChange={(val) => {
                                editCondition(index, cIndex, 'valueA', val);
                            }}
                            error={invalid && !condition.valueA}
                            containerClass={css.conditionTextField}
                            debounce={200}
                            inputConfig={{
                                min: 0,
                            }}
                        />
                    </div>
                    {condition.op === 'bt' && (
                        <div className={css.labelGroup}>
                            <div className={css.label}>and</div>
                            <TextField
                                type="number"
                                value={condition.valueB}
                                onChange={(val) => {
                                    editCondition(index, cIndex, 'valueB', val);
                                }}
                                error={invalid && !condition.valueB}
                                containerClass={css.conditionTextField}
                                debounce={200}
                                inputConfig={{
                                    min: 0,
                                }}
                            />
                        </div>
                    )}
                    <div className={css.labelGroup}>
                        <div className={css.label}>in the last</div>
                        <Dropdown
                            containerClass={css.conditionDropdown}
                            popperClass={css.conditionDropdownPopper}
                            selected={{ name: condition.time, display_name: TIMES[condition.time] }}
                            onSelection={(s) => {
                                editCondition(index, cIndex, 'time', +s.name);
                            }}
                            items={Object.keys(TIMES).map((t) => ({
                                name: t,
                                display_name: TIMES[t],
                            }))}
                        />
                    </div>
                    <div className={css.conditionButtons}>
                        {filter.conditions.length > 1 && (
                            <XIcon
                                className={css.x}
                                onClick={() => {
                                    deleteCondition(index, cIndex);
                                }}
                            />
                        )}
                        <PlusIcon
                            className={css.plus}
                            onClick={() => {
                                addCondition(index);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
