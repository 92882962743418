import { EVENT_SLOT_GROUP_TYPE, EVENT_TYPES } from '../utils/consts';
import { parseSearchParams } from '../utils/url';
import { EmptyStateTasks } from '../components/partials/EmptyState/utils';

export const NEW_EVENT_ID = 'newEvent';
export const SKAN_REPORTING_PAGE_FLAG = 'skan_reporting_analytics';
export const calculatedMetricsPerCategory = {};

export const isOrgUsingSkanReportingFlag = orgFlags => {
    if (orgFlags && orgFlags.includes(SKAN_REPORTING_PAGE_FLAG)) {
        return true;
    }
    return false;
};

export const isNewEvent = customEvent => {
    return customEvent.id === NEW_EVENT_ID;
};

export const searchCustomEvents = (customEvents, searchTerm) => {
    return customEvents.filter(customEvent => {
        const { name } = customEvent;

        return (
            isNewEvent(customEvent) ||
            name
                .toLowerCase()
                .replace(/ /g, '')
                .includes(searchTerm.toLowerCase().replace(/ /g, ''))
        );
    });
};

export const searchEventSections = (eventsSections, searchTerm) => {
    const filteredEventSections = {};
    Object.keys(eventsSections).forEach(eventType => {
        filteredEventSections[eventType] = eventsSections[eventType].filter(customEvent => {
            const { name } = customEvent;

            return name
                .toLowerCase()
                .replace(/ /g, '')
                .includes(searchTerm.toLowerCase().replace(/ /g, ''));
        });
    });
    return filteredEventSections;
};

export const getEventSlotGroupTypes = (eventSlotAnalysisTypes, isOrgUnified, isOrgUsingSkanReporting) => {
    const groupTypes = [...(eventSlotAnalysisTypes || [])];

    if (isOrgUnified || isOrgUsingSkanReporting) {
        if (!groupTypes.length) {
            groupTypes.push(EVENT_SLOT_GROUP_TYPE.BASIC);
        }

        groupTypes.push(EVENT_SLOT_GROUP_TYPE.SKAN);
    }

    return groupTypes;
};

export const getEventSlotGroupType = eventSlot => {
    if (eventSlot.is_skan) {
        return EVENT_SLOT_GROUP_TYPE.SKAN;
    } else if (!eventSlot.analysis_type) {
        return EVENT_SLOT_GROUP_TYPE.BASIC;
    } else {
        return eventSlot.analysis_type;
    }
};

export const parseCalculatedMetricsOptions = (calculatedMetricsOptionsStore, retEvent, activeGroupType) => {
    return calculatedMetricsOptionsStore
        .filter(cm => {
            let shouldShow = true;
            const activeGroupTypeMetricsToShow = calculatedMetricsPerCategory[activeGroupType];

            if (activeGroupTypeMetricsToShow) {
                shouldShow = activeGroupTypeMetricsToShow.includes(cm.name);
            }

            return shouldShow;
        })
        .map(cm => {
            const copy = { ...cm };
            const storeValue = retEvent.calculated_metrics.find(item => item.name === cm.name);
            if (storeValue) {
                copy.value = storeValue.value;
            }
            return copy;
        });
};

export const getCustomEventsGroupTitle = (allAppsChecked, activeGroupType) => {
    if (activeGroupType === EVENT_SLOT_GROUP_TYPE.CONVERSION) {
        return 'STATIC.PAGES.CUSTOM_EVENTS.CONVERSION_EVENTS_CONT';
    } else if (activeGroupType === EVENT_SLOT_GROUP_TYPE.COHORT) {
        return 'STATIC.PAGES.CUSTOM_EVENTS.COHORT_EVENTS_CONT';
    } else if (allAppsChecked) {
        return 'STATIC.PAGES.CUSTOM_EVENTS.EVENTS_CONT';
    } else {
        return 'STATIC.PAGES.CUSTOM_EVENTS.APP_SPECIFIC_CONT';
    }
};

export const getActiveGroupTypeFromPathParams = (pathParamsJson, eventSlotGroupTypes) => {
    return eventSlotGroupTypes.find(groupType => {
        return groupType.includes(pathParamsJson.t);
    });
};

export const extractTageFromCustomEvent = (customEvent, uanId) => {
    if (!customEvent.tags || !customEvent.tags[uanId]) {
        return [];
    } else {
        return customEvent.tags[uanId].map(item => {
            return {
                label: item.display_name,
                value: item.display_name,
                deleteEnabled: !!item.deleteEnabled,
            };
        });
    }
};

/**
 *
 * @param searchParamsString - search params from url. the page supports deep linking to a specific tab. for example:'#/singular-events?t=conversion'
 * @param eventSlotGroupTypes - the available analysisType tabs for the user (enabled via org option - 'event_slot_analysis_types') or cohort events + skadnetwork events tabs
 * @returns {*}
 */
export const getInitialGroupTypeCategory = (searchParamsString, eventSlotGroupTypes) => {
    const pathParamsJson = parseSearchParams(searchParamsString);

    return getActiveGroupTypeFromPathParams(pathParamsJson, eventSlotGroupTypes) || eventSlotGroupTypes[0];
};

export const showCohortEvents = eventSlotAnalysisTypesForOrg => {
    return (
        eventSlotAnalysisTypesForOrg.length === 0 || eventSlotAnalysisTypesForOrg.includes(EVENT_SLOT_GROUP_TYPE.COHORT)
    );
};

export const showConversionEvents = eventSlotAnalysisTypesForOrg => {
    return eventSlotAnalysisTypesForOrg.includes(EVENT_SLOT_GROUP_TYPE.CONVERSION);
};

export const fillEmptyStateTabDetail = (title, subTitle, firstTask, secondTask, showEmptyState) => {
    return {
        title,
        subTitle,
        firstTask,
        secondTask,
        showEmptyState,
    };
};

const formatRelatedListItem = (dependencyName, items, translate) => {
    return items.length ? `<li>${translate(dependencyName)}: ${items.join(', ')}</li>` : '';
};

const getHeader = (isEventStandard, translate) => {
    let header = '';
    if (isEventStandard) {
        header = `<p>${translate('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.STANDARD_EVENT_MESSAGE')}</p>`;
    }
    return header.concat(`<p>
            ${translate('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.MESSAGE_HEADER')}    
        </p>`);
};

export const formatMessageEventWithRelatedItems = (eventRelatedItems, translate, isEventStandard) => {
    return `
            ${getHeader(isEventStandard, translate)}
            <ul>
                ${formatRelatedListItem(
                    'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.METRICS',
                    eventRelatedItems.customMetrics,
                    translate
                )}
                ${formatRelatedListItem(
                    'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.BOOKMARKS',
                    eventRelatedItems.bookmarks,
                    translate
                )}
                ${formatRelatedListItem(
                    'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.ANONYMOUS_REPORTS',
                    eventRelatedItems.anonymousReports,
                    translate
                )}
                ${
                    eventRelatedItems.dashboards.length
                        ? `<li>${translate(
                              'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.DASHBOARDS'
                          )}: ${eventRelatedItems.dashboards
                              .map(
                                  ({ dashboardName, widgetName }) =>
                                      `${translate(
                                          'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.DASHBOARD'
                                      )} ${dashboardName} - ${translate(
                                          'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.WIDGET'
                                      )} ${widgetName}`
                              )
                              .join(', ')}</li>`
                        : ''
                }
            </ul>
            <p>
                ${translate('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.MESSAGE_FOOTER')}
            </p>`;
};

export const isAddingEventEnabled = (emptyStateData, emptyStatePerTab) => {
    return (
        emptyStateData.tasks[EmptyStateTasks.SDK_INTEGRATED] ||
        emptyStateData.tasks[EmptyStateTasks.DATA_CONNECTOR_ADDED] ||
        !emptyStatePerTab.showEmptyState
    );
};

export const splitEventsByTypes = events => {
    const standard = [];
    const custom = [];
    const builtIn = [];

    events.forEach(event => {
        if (event.is_locked) {
            builtIn.push(event);
        } else if (event.is_standard) {
            standard.push(event);
        } else {
            custom.push(event);
        }
    });

    return {
        [EVENT_TYPES.STANDARD]: standard,
        [EVENT_TYPES.CUSTOM]: custom,
        [EVENT_TYPES.BUILT_IN]: builtIn,
    };
};
