import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AppSelection from 'teamManagement/containers/AppSelection';
import MetricsSelection from 'teamManagement/containers/MetricsSelection';
import RoleSelection from 'teamManagement/containers/RoleSelection';
import ScreenSelection from 'teamManagement/containers/ScreenSelection';
import SourceSelection from 'teamManagement/containers/SourceSelection';
import SummaryView from 'teamManagement/containers/SummaryView';
import UserRegistrationForm from 'teamManagement/containers/UserRegistrationForm';
import AgencyRegistrationForm from 'teamManagement/containers/AgencySelectionForm';
import GenericDimensionSelection from 'teamManagement/containers/GenericDimensionSelection';
import { Wizard, Shelf } from 'components/widgets';
import { shelfSteps, wizardModes, RESTRICTED_ROLES, ROLE_STANDARD } from 'teamManagement/utils';

function TeamManagementShelf({
    mode,
    role,
    permissionDimensions,
    wizardSteps,
    editedMember,
    shelf,
    onCloseShelf,
    onWizardFinish,
    onWizardActionTypeChanged,
}) {
    const [activeStep, setActiveStep] = useState('');

    useDeepCompareEffect(() => {
        const wizardStep =
            mode === wizardModes.edit && RESTRICTED_ROLES.includes(editedMember.role.type)
                ? wizardSteps[wizardSteps.length - 1]
                : wizardSteps[0];

        setActiveStep(wizardStep);
    }, [wizardSteps, mode, editedMember]);

    const _onWizardPageChanged = (_, currentStep, actionType = '') => {
        setActiveStep(currentStep.id);
        onWizardActionTypeChanged(actionType);
    };

    const WIZARD_STEPS_DATA = {
        [shelfSteps.roleSelection]: {
            element: RoleSelection,
            title: 'Role Selection',
        },
        [shelfSteps.userDetails]: {
            element: UserRegistrationForm,
            title: 'User Details',
        },
        [shelfSteps.agencyDetails]: {
            element: AgencyRegistrationForm,
            title: 'Agency Details',
        },
        [shelfSteps.appSelection]: {
            element: AppSelection,
            title: 'App Selection',
        },
        [shelfSteps.sourceSelection]: {
            element: SourceSelection,
            title: 'Source Selection',
        },
        [shelfSteps.metricsSelection]: {
            element: MetricsSelection,
            title: 'Metrics Selection',
        },
        [shelfSteps.screenSelection]: {
            element: ScreenSelection,
            title: 'Screen Selection',
        },
        [shelfSteps.review]: {
            element: SummaryView,
            title: 'Review',
            elementProps: {
                onEditClicked: id => _onWizardPageChanged(id, id, wizardModes.edit),
            },
        },
    };

    const wizardStepsElements = useMemo(() => {
        return wizardSteps.map(id => {
            const step = WIZARD_STEPS_DATA[id];

            if (step) {
                const { element: StepElement, title, elementProps } = step;
                return <StepElement id={id} key={id} label={title} {...elementProps} />;
            }

            const dimension = Object.values(permissionDimensions).find(d => d.name === id);
            const { name, displayName, values } = dimension;

            return (
                <GenericDimensionSelection
                    id={name}
                    key={name}
                    dimension={name}
                    displayName={displayName}
                    values={values}
                    label={`${displayName} Selection`}
                />
            );
        });
    }, [wizardSteps]);

    const showStepper = RESTRICTED_ROLES.includes(role) && activeStep !== shelfSteps.roleSelection;
    const shelfSize = activeStep === shelfSteps.roleSelection ? 'medium' : 'large';

    return (
        <Shelf {...shelf} shelfSize={shelfSize} onClose={onCloseShelf}>
            <Wizard
                onWizardFinish={onWizardFinish}
                onWizardCancel={onCloseShelf}
                onWizardPageChanged={_onWizardPageChanged}
                showStepper={showStepper}
                activeStep={activeStep}
            >
                {wizardStepsElements}
            </Wizard>
        </Shelf>
    );
}

TeamManagementShelf.propTypes = {
    wizardSteps: PropTypes.arrayOf(PropTypes.string),
    mode: PropTypes.string,
    role: PropTypes.number,
    shelf: PropTypes.objectOf(PropTypes.any),
    editedMember: PropTypes.objectOf(PropTypes.any),
    permissionDimensions: PropTypes.objectOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    displayName: PropTypes.string.isRequired,
                })
            ).isRequired,
            selected: PropTypes.shape({
                values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
                addFuture: PropTypes.bool,
            }).isRequired,
        })
    ),
    onWizardFinish: PropTypes.func,
    onCloseShelf: PropTypes.func,
    onWizardActionTypeChanged: PropTypes.func,
};

TeamManagementShelf.defaultProps = {
    wizardSteps: [],
    mode: '',
    role: ROLE_STANDARD,
    shelf: { open: false, shelfSize: 'medium' },
    editedMember: {},
    permissionDimensions: {},
    onCloseShelf: () => {},
    onWizardFinish: () => {},
    onWizardActionTypeChanged: () => {},
};

export default withLocalize(TeamManagementShelf);
