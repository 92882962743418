import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

import { CHART_TYPES } from '../PivotChart/consts';
import {
    GRID_COLS,
    GRID_ROW_HEIGHT,
    GRID_MARGIN,
    GRID_WIDGET_TYPES,
    OPERATOR_TYPES,
    GRID_WIDTH_OFFSPACE,
} from './consts';
import { calculateGridWidgetHeight, shouldDisplayUnenriched } from './utils';
import css from './dashboard.css';
import { ACTION_TYPES, WIDGET_TYPE_TO_EMPTY_STATE_COMPONENT } from '../ChartWidgetWrapper/consts';
import chartWidgetWrapperCss from '../ChartWidgetWrapper/ChartWidgetWrapper.css';
import ChartWidgetWrapper from '../ChartWidgetWrapper';
import { getDisplayUnenriched } from '../../../selectors/user';

function DashboardGrid({
    dashboard,
    startDate,
    endDate,
    startDate2,
    endDate2,
    forceRefreshCounter,
    widgets,
    globalFilters,
    updateWidgets,
    cloneWidget,
    deleteWidget,
    resizable,
    width,
    updatingGraphQL,
    onEditWidget,
    dispatchWidgetsLoading,
    dispatchWidgetVersions,
    compare,
    onGridLayoutChange,
    gridStyle,
    isQuickReportGrid,
    quickReportType,
    rowHeight,
    periods,
}) {
    const userDisplayUnenriched = useSelector(state => getDisplayUnenriched(state));

    const gridItems = useMemo(() => {
        if (isQuickReportGrid && globalFilters.length === 0) {
            return widgets.map(widget => {
                const { gridParams } = widget;
                const EmptyStateClass = WIDGET_TYPE_TO_EMPTY_STATE_COMPONENT[widget.type] || ChartWidgetWrapper;

                return (
                    <div key={gridParams.i} data-grid={gridParams} className={css.gridWidget}>
                        <EmptyStateClass
                            widgetTitle={widget.name}
                            widgetType={widget.type}
                            widgetDataType={widget.dataType}
                            showNoData
                            allowedActions={[]}
                            showError={false}
                            key={widget.i}
                            showSpinner={false}
                        />
                    </div>
                );
            });
        } else {
            return widgets.map(widget => {
                const { gridParams } = widget;
                const GridWidgetClass = GRID_WIDGET_TYPES[widget.type];
                return (
                    <div key={gridParams.i} data-grid={gridParams} className={css.gridWidget}>
                        <GridWidgetClass
                            widget={widget}
                            globalFilters={globalFilters}
                            dispatchWidgetVersions={dispatchWidgetVersions}
                            dispatchWidgetsLoading={dispatchWidgetsLoading}
                            forceRefreshCounter={forceRefreshCounter}
                            dashboardId={dashboard.id}
                            startDate={startDate}
                            endDate={endDate}
                            startDate2={startDate2}
                            endDate2={endDate2}
                            compare={compare}
                            onMenuClick={() => onEditWidget(widget)}
                            onCloneClick={() => cloneWidget(gridParams.i)}
                            onDeleteClick={() => deleteWidget(gridParams.i)}
                            onUpdateWidget={(newWidget, changeType) => {
                                const newWidgets = widgets.map(x => (x === widget ? newWidget : x));
                                updateWidgets(newWidgets, newWidget, changeType);
                            }}
                            height={calculateGridWidgetHeight(gridParams.h, rowHeight)}
                            updatingGraphQL={updatingGraphQL}
                            allowedActions={isQuickReportGrid ? [] : Object.values(ACTION_TYPES)}
                            displayUnenriched={shouldDisplayUnenriched(userDisplayUnenriched, quickReportType)}
                            periods={periods}
                        />
                    </div>
                );
            });
        }
    }, [
        widgets,
        dashboard.id,
        startDate,
        endDate,
        startDate2,
        endDate2,
        compare,
        forceRefreshCounter,
        globalFilters,
        periods,
    ]);

    if (width === null) {
        return null;
    }

    return (
        <GridLayout
            style={gridStyle}
            margin={[GRID_MARGIN, GRID_MARGIN]}
            isDraggable={resizable}
            isResizable={resizable}
            width={width - GRID_WIDTH_OFFSPACE}
            rowHeight={rowHeight}
            autoSize={false}
            onLayoutChange={onGridLayoutChange}
            compactType={resizable ? 'vertical' : null}
            // allows the top bar in the widget draggable and keep it css style
            draggableHandle={`.${chartWidgetWrapperCss.gridWidgetHeader}`}
            cols={GRID_COLS}
        >
            {gridItems}
        </GridLayout>
    );
}

DashboardGrid.propTypes = {
    dashboard: PropTypes.objectOf(PropTypes.any).isRequired,
    widgets: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOf([
                'table',
                'pivot',
                'metricCards',
                'bubble',
                'heatMapTable',
                'variwide',
                ...CHART_TYPES.map(x => x.name),
            ]).isRequired,
            query: PropTypes.shape({
                dimensions: PropTypes.arrayOf(PropTypes.string),
                metrics: PropTypes.arrayOf(PropTypes.string),
                filters: PropTypes.arrayOf(
                    PropTypes.shape({
                        field: PropTypes.string.isRequired,
                        operator: PropTypes.oneOf(OPERATOR_TYPES.map(x => x.name)).isRequired,
                        values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
                    })
                ).isRequired,
            }).isRequired,
            options: PropTypes.shape({
                rows: PropTypes.arrayOf(PropTypes.string),
                columns: PropTypes.arrayOf(PropTypes.string),
            }).isRequired,
            heatMapOptions: PropTypes.shape({
                heatColumn: PropTypes.string,
                isHeatColumnCustomMetric: PropTypes.bool,
            }),
            gridParams: PropTypes.shape({
                i: PropTypes.string.isRequired,
                x: PropTypes.number.isRequired,
                y: PropTypes.number.isRequired,
                w: PropTypes.number.isRequired,
                h: PropTypes.number.isRequired,
            }).isRequired,
        })
    ).isRequired,
    globalFilters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            operator: PropTypes.oneOf(OPERATOR_TYPES.map(x => x.name)).isRequired,
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        })
    ),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate2: PropTypes.string,
    endDate2: PropTypes.string,
    updateWidgets: PropTypes.func,
    cloneWidget: PropTypes.func,
    deleteWidget: PropTypes.func,
    dispatchWidgetsLoading: PropTypes.func,
    dispatchWidgetVersions: PropTypes.func,
    resizable: PropTypes.bool,
    width: PropTypes.number,
    updatingGraphQL: PropTypes.bool,
    forceRefreshCounter: PropTypes.number,
    onEditWidget: PropTypes.func,
    onGridLayoutChange: PropTypes.func,
    compare: PropTypes.bool,
    gridStyle: PropTypes.objectOf(PropTypes.any),
    isQuickReportGrid: PropTypes.bool,
    quickReportType: PropTypes.string,
    rowHeight: PropTypes.number,
    periods: PropTypes.arrayOf(PropTypes.any),
};

DashboardGrid.defaultProps = {
    startDate2: null,
    endDate2: null,
    updateWidgets: () => {},
    cloneWidget: () => {},
    deleteWidget: () => {},
    resizable: true,
    width: null,
    globalFilters: [],
    updatingGraphQL: false,
    forceRefreshCounter: 0,
    onEditWidget: null,
    dispatchWidgetsLoading: () => {},
    dispatchWidgetVersions: () => {},
    onGridLayoutChange: () => {},
    compare: false,
    gridStyle: {},
    isQuickReportGrid: false,
    quickReportType: null,
    rowHeight: GRID_ROW_HEIGHT,
    periods: [],
};

export default memo(DashboardGrid);
