import { capitalize } from 'utils/general';
import {
    END_MARKDOWN,
    LEFT_TAG,
    RIGHT_TAG,
    SDK_KEY_PLACEHOLDER,
    SDK_TOKEN_PLACEHOLDER,
    START_MARKDOWN,
} from '../consts';
import { IOS_NATIVE, SDK_INTEGRATION_SECTIONS, SWIFT } from '../../consts';

const {
    IMPORT_LIBRARY,
    ATT_PROMPT,
    SKAN_ENABLED,
    DEEP_LINKING,
    CUSTOM_USER_ID,
    INITIALIZATION,
    USER_ACTIVITY,
    HANDLING_DEEPLINKS,
    CREATE_A_CONFIGURATION_OBJECT,
} = SDK_INTEGRATION_SECTIONS;

const ATT_PROMPT_DELAY = `${START_MARKDOWN(ATT_PROMPT)}
        // Set a 300 sec delay before initialization to wait for the user's ATT response
        config.waitForTrackingAuthorizationWithTimeoutInterval = 300;
    ${END_MARKDOWN}`;

const DEEP_LINKING_HANDLER_METHOD = `${START_MARKDOWN(DEEP_LINKING)}
    func handleDeeplink(params: SingularLinkParams?) {
        // Get Deeplink data from Singular Link
        let deeplink = params?.getDeepLink()
        let passthrough = params?.getPassthrough()
        let isDeferredDeeplink = params?.isDeferred()
        let urlParams = params?.getURLParameters()
        // Add deep link handling code here
    }
    ${END_MARKDOWN}`;

const DEEP_LINKING_HANDLER_METHOD_ASSIGNMENT = `${START_MARKDOWN(DEEP_LINKING)}
        // Set a handler method for deep links
        config.singularLinksHandler = { params in
            if let params = params {
                self.handleDeeplink(params: params) 
            }
        }
    ${END_MARKDOWN}`;

const HANDLING_DEEPLINKS_METHOD = `${START_MARKDOWN(
    HANDLING_DEEPLINKS
)}func scene(_ scene: UIScene, openURLContexts URLContexts: Set${LEFT_TAG}UIOpenURLContext${RIGHT_TAG}>) {
        // Starts a new Singular session on cold start from deeplink scheme
        if let config = getConfig() {
            config.openUrl = URLContexts.first?.url
            Singular.start(config)
        }
    }${END_MARKDOWN}`;

const getCodeSnippet = (sdkConfiguration = {}) => {
    return `${START_MARKDOWN(IMPORT_LIBRARY)}import Singular${END_MARKDOWN}
${START_MARKDOWN(INITIALIZATION)}
class SceneDelegate: UIResponder, UIWindowSceneDelegate {
    func scene(_ scene: UIScene, willConnectTo session: UISceneSession, options connectionOptions: UIScene.ConnectionOptions) {
        if let userActivity = connectionOptions.userActivities.first, let config = self.getConfig() {
            // Starts a new session when the user opens the app using a Singular Link while it was in the background
            config.userActivity = userActivity            
            Singular.start(config)
        }

        NotificationCenter.default.addObserver(self, selector: #selector(didBecomeActiveNotification), name: UIApplication.didBecomeActiveNotification, object: nil)
    }${END_MARKDOWN}
    
    ${START_MARKDOWN(CREATE_A_CONFIGURATION_OBJECT)}func getConfig() -> SingularConfig? {
        // Create the config object with the SDK Key and SDK Secret
        guard let config = SingularConfig(apiKey:"{{${SDK_KEY_PLACEHOLDER}}}", andSecret:"{{${SDK_TOKEN_PLACEHOLDER}}}") else {
            return nil
        }${END_MARKDOWN}
        ${sdkConfiguration[ATT_PROMPT] ? ATT_PROMPT_DELAY : ''}
        // Enable SKAdNetwork in Managed Mode
        ${START_MARKDOWN(SKAN_ENABLED)}config.skAdNetworkEnabled = true${END_MARKDOWN}

        // Get the current conversion value tracked by the Singular SDK.
        config.conversionValuesUpdatedCallback = { conversionValue, coarse, lock in
            // Here you have access to the latest conversion value
        };
        
        ${START_MARKDOWN(CUSTOM_USER_ID)}Singular.setCustomUserId("custom_user_id")${END_MARKDOWN}
        ${sdkConfiguration[DEEP_LINKING] ? DEEP_LINKING_HANDLER_METHOD_ASSIGNMENT : ''}
        return config
    }
    ${sdkConfiguration[DEEP_LINKING] ? DEEP_LINKING_HANDLER_METHOD : ''}
    @objc func didBecomeActiveNotification() {
        // Request user consent to use the Advertising Identifier (idfa)
        if #available(iOS 14, *) {
            ATTrackingManager.requestTrackingAuthorization { status in
            }
        }
    }
    
    ${START_MARKDOWN(USER_ACTIVITY)}func scene(_ scene: UIScene, continue userActivity: NSUserActivity) {
        // Starts a new session when the user opens the app using a Singular Link while it was in the background
        if let config = getConfig() {
            config.userActivity = userActivity            
            Singular.start(config)
        }
    }${END_MARKDOWN}
    ${sdkConfiguration[DEEP_LINKING] ? HANDLING_DEEPLINKS_METHOD : ''}
}
`;
};

const getSceneDelegateSwiftSnippet = sdkConfiguration => {
    return {
        markedCode: getCodeSnippet(sdkConfiguration[IOS_NATIVE]),
        codeLanguage: SWIFT,
        placeholders: { [SDK_KEY_PLACEHOLDER]: '', [SDK_TOKEN_PLACEHOLDER]: '' },
        customTitle: capitalize(SWIFT),
    };
};

export default getSceneDelegateSwiftSnippet;
