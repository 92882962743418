import API from './api';

let instance = null;
const api = new API();
const URL_UANS = 'api/org_networks';
const URL_AGENCIES = '/api/agencies';
const URL_OS = '/api/os';

export default class DataSources {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getBaseSources() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_UANS, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value.adnetworks);
                    }
                    return resolve(response.value.adnetworks);
                })
                .catch(reject);
        });
    }

    getAgencies() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_AGENCIES, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    getOS() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_OS, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }
}
