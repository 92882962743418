export const CHECKOUT_URL = 'react/plan-upgrade';
export const ACCOUNT_PLAN_URL = 'react/account-plan';
export const PAYMENT_STATUS_SUCCESS = 'complete';

export const STRIPE_PUBLISHABLE_KEY = (() => {
    const productionApp = 'app.singular.net';
    const TEST_STRIPE_PUBLISHABLE_KEY =
        'pk_test_51MU8k9LvaTtZk24GMWkqRksWDthG0qSlK6MMyRJfURytbrDiJaafbhabuwBRcQ5D4ddHpAnQHRdu2GnXNtx91udp00AZpCFB6I';
    const PRODUCTION_STRIPE_PUBLISHABLE_KEY =
        'pk_live_51MU8k9LvaTtZk24GmVHCCAaksZ6ZbkASHoR5xaFT8xpnY1dY7TVLjMwSavDf1BKsFyYtIsS5Q8vi245y6Md61aRL00kZhrpZVI';

    if (window.location.hostname === productionApp) {
        return PRODUCTION_STRIPE_PUBLISHABLE_KEY;
    }
    return TEST_STRIPE_PUBLISHABLE_KEY;
})();

export const CHECKOUT_ENABLED_TIERS = ['FREE'];
