import { ANDROID_NATIVE, IOS_NATIVE } from '../../../consts';
import css from './AppSiteUpdateForm.css';

const FORM_TRANSLATE_KEY = 'STATIC.PAGES.SDK.SDK_PLANNING.APP_SITE_UPDATE_FORM';

const FIELDS_ITEMS_BY_PLATFORM = {
    [IOS_NATIVE]: [
        {
            name: 'ios_universal_link_team_id',
            label: `${FORM_TRANSLATE_KEY}.${IOS_NATIVE}.TEAM_ID`,
            placeholder: `${FORM_TRANSLATE_KEY}.${IOS_NATIVE}.TEAM_ID_PLACEHOLDER`,
        },
        {
            name: 'app_scheme',
            label: `${FORM_TRANSLATE_KEY}.${IOS_NATIVE}.APP_SCHEME`,
            placeholder: `${FORM_TRANSLATE_KEY}.${IOS_NATIVE}.APP_SCHEME_PLACEHOLDER`,
        },
    ],
    [ANDROID_NATIVE]: [
        {
            name: 'app_signing_certificate',
            label: `${FORM_TRANSLATE_KEY}.${ANDROID_NATIVE}.APP_LINKS`,
            placeholder: `${FORM_TRANSLATE_KEY}.${ANDROID_NATIVE}.APP_LINKS_PLACEHOLDER`,
        },
        {
            name: 'app_scheme',
            label: `${FORM_TRANSLATE_KEY}.${ANDROID_NATIVE}.APP_SCHEME`,
            placeholder: `${FORM_TRANSLATE_KEY}.${ANDROID_NATIVE}.APP_SCHEME_PLACEHOLDER`,
            tooltip: `${FORM_TRANSLATE_KEY}.${ANDROID_NATIVE}.APP_SCHEME_TOOLTIP`,
            tooltipClassName: css.tooltip,
        },
    ],
};

export { FORM_TRANSLATE_KEY, FIELDS_ITEMS_BY_PLATFORM };
