import {
    MAX_SUB_TYPE_SLOTS_ON_INIT,
    MIN_ENGAGEMENT_SLOTS,
    MIN_EVENTS_SLOTS,
    MIXED_SLOTS_COLOR,
    ModelKinds,
    SLOTS_SIGNS,
} from '../../consts';
import { engagementModelBitsPerEvent, getUsedSlotsByEventType } from '../skan4/eventsUtils';
import { getEventSlotName, getNumberOfRevenueSlots } from '../../utils';

const getEventNameByType = (eventsType, index) => {
    return eventsType === ModelKinds.FUNNEL ? SLOTS_SIGNS[eventsType] : getEventSlotName(index);
};

export const calculateUsedMixedSlots = (values, maxEventSlots, maxSlots) => {
    const { isCustomRevenue, numberOfRevenueBuckets, buckets, apsalarEventIds: eventIds, eventsType } = values;
    const isEngagement = eventsType === ModelKinds.ENGAGEMENT;

    const revenueSlotsNumber = getNumberOfRevenueSlots(isCustomRevenue, numberOfRevenueBuckets, buckets);
    const eventsSlotsNumber = isEngagement
        ? maxSlots - revenueSlotsNumber
        : getUsedSlotsByEventType(eventsType, eventIds, maxEventSlots).length || MIN_EVENTS_SLOTS;

    if (revenueSlotsNumber + eventsSlotsNumber > maxSlots) return [];

    const eventSlotColor = MIXED_SLOTS_COLOR[0];
    const revenueSlotColor = MIXED_SLOTS_COLOR[1];

    let eventsSlots = [];

    if (eventIds.length) {
        if (isEngagement) {
            const bitsPerEvent = engagementModelBitsPerEvent(eventIds.length, maxEventSlots);

            for (let i = 0; i < eventIds.length; i++) {
                for (let j = 0; j < bitsPerEvent; j++) {
                    eventsSlots.push({
                        text: getEventNameByType(eventsType, i),
                        color: eventSlotColor,
                    });
                }
            }
        } else {
            eventsSlots = Array(eventsSlotsNumber)
                .fill()
                .map((_, i) => {
                    return { text: getEventNameByType(eventsType, i), color: eventSlotColor };
                });
        }
    }

    return eventsSlots.concat(
        Array(revenueSlotsNumber).fill({ text: SLOTS_SIGNS[ModelKinds.REVENUE], color: revenueSlotColor })
    );
};

export const getMaxAvailableSlotsForSubType = (subType, otherSubType, bitsByType, eventIds, maxSlots) => {
    if (otherSubType === ModelKinds.ENGAGEMENT) {
        return maxSlots - Math.max(eventIds.length, MIN_ENGAGEMENT_SLOTS);
    }

    return bitsByType[subType] ? maxSlots - bitsByType[otherSubType] : MAX_SUB_TYPE_SLOTS_ON_INIT;
};
