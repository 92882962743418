import { connect } from 'react-redux';
import {
    cleanMessages,
    closeShelfCalled,
    createNewMember,
    deleteMemberClicked,
    managePermissionsCalled,
    resendInvitation,
    wizardFinished,
    wizardActionTypeChanged,
    activeMemberTypeChange,
} from 'teamManagement/actions';
import TeamManagement from 'teamManagement/page';
import { getTeamManagementPage } from 'teamManagement/selectors';

const mapStateToProps = state => {
    return getTeamManagementPage(state);
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteMember: (...args) => {
            dispatch(deleteMemberClicked(...args));
        },
        onDeleteBtnClicked: (...args) => {
            dispatch(cleanMessages(...args));
        },
        onResendInvitation: (...args) => {
            dispatch(resendInvitation(...args));
        },
        onManagePermissions: (...args) => {
            dispatch(cleanMessages());
            dispatch(managePermissionsCalled(...args));
        },
        onCreateNewMember: (...args) => {
            dispatch(cleanMessages());
            dispatch(createNewMember(...args));
        },
        onWizardFinish: (...args) => {
            dispatch(wizardFinished(...args));
        },
        onCloseShelf: (...args) => {
            dispatch(closeShelfCalled(...args));
        },
        onWizardActionTypeChanged: (...args) => {
            dispatch(wizardActionTypeChanged(...args));
        },
        onActiveMemberTypeChange: activeMemberType => {
            dispatch(cleanMessages());
            dispatch(activeMemberTypeChange(activeMemberType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagement);
