export const SET_GENERAL_ERROR = 'action.app_loading_state.set.msg';
export const SET_IS_LOADING = 'action.app_loading_state.set.is_loading';

export const setGeneralError = (errorMsg = 'STATIC.GENERAL_ERROR_DIALOG_TEXT') => ({
    type: SET_GENERAL_ERROR,
    errorMsg,
});

export const clearGeneralError = () => ({
    type: SET_GENERAL_ERROR,
    errorMsg: undefined,
});

export const setIsLoading = isLoading => ({
    type: SET_IS_LOADING,
    isLoading,
});
