import { route } from '../utils/routerUtils';
import { TOUR_LINK_IDS } from '../utils/consts';
import { OS } from '../utils/OS';
import Apps from './homepageContentBody/apps';
import BasicSetupIntro from './homepageContentBody/basicSetupIntro';
import DataConnectors from './homepageContentBody/dataConnectors';
import InviteUsers from './homepageContentBody/inviteUsers';

import SdkIntegrationIntro from './homepageContentBody/sdkIntegrationIntro';
import SdkKeysAndDocs from './homepageContentBody/sdkKeysAndDocs';
import SdkTestBasicEvents from './homepageContentBody/sdkTestBasicEvents';
import SdkTestRevenueEvents from './homepageContentBody/sdkTestRevenueEvents';
import SdkTestAdvancedEvents from './homepageContentBody/sdkTestAdvancedEvents';

import ReportingIntegrationsIntro from './homepageContentBody/reportingIntegrationsIntro';
import ReportingAPI from './homepageContentBody/reportingAPI';
import BIPostbacks from './homepageContentBody/BIPostbacks';

import RunCampaignsIntro from './homepageContentBody/runCampaignsIntro';
import GetStartedAdwords from './homepageContentBody/getStartedAdwords';
import GetStartedFacebook from './homepageContentBody/getStartedFacebook';
import PartnerConfig from './homepageContentBody/partnerConfig';
import ManageLinks from './homepageContentBody/manageLinks';
import RunBasicReport from './homepageContentBody/runBasicReport';
import RunCreativesReport from './homepageContentBody/runCreativesReport';
import RunROIReport from './homepageContentBody/runROIReport';
import CustomEvents from './homepageContentBody/customEvents';
import ExportLogs from './homepageContentBody/exportLogs';
import CustomDimensions from './homepageContentBody/customDimensions';
import Bookmarks from './homepageContentBody/bookmarks';
import CreativeTags from './homepageContentBody/creativeTags';
import {
    IS_RUNNING_APPLE_SEARCH_ADS_CAMPAIGNS,
    IS_RUNNING_FACEBOOK_CAMPAIGNS,
    IS_RUNNING_GOOGLE_ADS_CAMPAIGNS,
    IS_RUNNING_INFLUENCER_CAMPAIGNS,
    IS_RUNNING_SKAN_CAMPAIGNS,
    IS_USING_DEEP_LINKING_TRACKING_LINKS,
    IS_USING_TRACKING_LINKS_FOR_EMAIL_CAMPAIGNS,
    IS_USING_TRACKING_LINKS_FOR_SMS_CAMPAIGNS,
} from '../onboardingGuide/components/sdkConfiguration/consts';

export const UNIFIED_SET_UP = 'UNIFIED_SET_UP';
export const ADMON_UNIFIED_SET_UP = 'ADMON_UNIFIED_SET_UP';
export const BASIC_SET_UP_CATEGORY = 'BASIC_SET_UP_CATEGORY';
export const BASIC_SET_UP_INTRO = 'BASIC_SET_UP_INTRO';
export const APPS = 'APPS';
export const DATA_CONNECTORS = 'DATA_CONNECTORS';
export const SET_UP_GOOGLE = 'SET_UP_GOOGLE';
export const SET_UP_FACEBOOK = 'SET_UP_FACEBOOK';
export const SET_UP_APPLE_SEARCH_ADS = 'SET_UP_APPLE_SEARCH_ADS';
export const INVITE_USERS = 'INVITE_USERS';

export const SETUP_YOUR_APPS = 'SETUP_YOUR_APPS';
export const SETUP_YOUR_SOURCES = 'SETUP_YOUR_SOURCES';
export const AUDIT_REPORT = 'AUDIT_REPORT';
export const SETUP_TASKS = 'SETUP_TASKS';
export const DEBUGGING_TOOLS = 'DEBUGGING_TOOLS';
export const ADVANCED_SETUP = 'ADVANCED_SETUP';

export const VALIDATE_SETUP = 'VALIDATE_SETUP';

export const SDK_INTEGRATION = 'SDK_INTEGRATION';
export const SDK_CONFIGURATION = 'SDK_CONFIGURATION';
export const SDK_INTEGRATION_SUBCATEGORY = 'SDK_INTEGRATION_SUBCATEGORY';
export const SDK_INTEGRATION_INTRO = 'SDK_INTEGRATION_INTRO';
export const SDK_KEYS_AND_DOCUMENTATION = 'SDK_KEYS_AND_DOCUMENTATION';
export const SDK_CONSOLE_TESTING = 'SDK_CONSOLE_TESTING';
export const SDK_CONSOLE_BASIC_EVENTS = 'SDK_CONSOLE_BASIC_EVENTS';
export const SDK_CONSOLE_REVENUE_EVENTS = 'SDK_CONSOLE_REVENUE_EVENTS';
export const SDK_CONSOLE_ADVANCED_EVENTS = 'SDK_CONSOLE_ADVANCED_EVENTS';

export const WEB_SDK_INTEGRATION = 'WEB_SDK_INTEGRATION';

export const REPORTING_INTEGRATIONS_CATEGORY = 'REPORTING_INTEGRATIONS_CATEGORY';
export const REPORTING_INTEGRATIONS_INTRO = 'REPORTING_INTEGRATIONS_INTRO';
export const REPORTING_API = 'REPORTING_API';
export const BI_POSTBACKS = 'BI_POSTBACKS';

export const SET_UP_CAMPAIGNS_CATEGORY = 'SET_UP_CAMPAIGNS_CATEGORY';
export const SET_UP_CAMPAIGNS_INTRO = 'SET_UP_CAMPAIGNS_INTRO';
export const GET_STARTED_ADWORDS = 'GET_STARTED_ADWORDS';
export const GET_STARTED_FACEBOOK = 'GET_STARTED_FACEBOOK';
export const PARTNER_CONFIGURATION_SUBCATEGORY = 'PARTNER_CONFIGURATION_SUBCATEGORY';
export const PARTNER_CONFIGURATION = 'PARTNER_CONFIGURATION';
export const MANAGE_LINKS = 'MANAGE_LINKS';
export const CUSTOM_SOURCES_LINKS = 'CUSTOM_SOURCES_LINKS';
export const TEST_LINKS = 'TEST_LINKS';
export const DEEP_LINKING = 'DEEP_LINKING';

export const VIEW_DATA_CATEGORY = 'VIEW_DATA_CATEGORY';
export const REPORTS = 'REPORTS';
export const REPORTS_AND_PIVOTS = 'REPORTS_AND_PIVOTS';
export const ADVANCED_REPORTING = 'ADVANCED_REPORTING';
export const RUN_REPORT = 'RUN_REPORT';
export const VISUALIZE_CAMPAIGNS = 'VISUALIZE_CAMPAIGNS';
export const RUN_ROI_REPORT = 'RUN_ROI_REPORT';
export const RUN_CREATIVE_REPORT = 'RUN_CREATIVE_REPORT';
export const RUN_ADDITIONAL_REPORT = 'RUN_ADDITIONAL_REPORT';
export const ETL = 'ETL';

export const EXPORT_DATA = 'EXPORT_DATA';
export const EXPLORE_OTHER_WAYS = 'EXPLORE_OTHER_WAYS';
export const EXPORT_LOGS = 'EXPORT_LOGS';

export const ADVANCED_SETUP_CATEGORY = 'ADVANCED_SETUP_CATEGORY';
export const COHORTED_EVENTS = 'COHORTED_EVENTS';
export const CUSTOM_DIMENSIONS = 'CUSTOM_DIMENSIONS';
export const BOOKMARKS = 'BOOKMARKS';
export const CREATIVE_TAGS = 'CREATIVE_TAGS';

export const SKAN_CATEGORY = 'SKAN_CATEGORY';
export const SKAN_INTRO = 'SKAN_INTRO';
export const SKAN_FACEBOOK = 'SKAN_FACEBOOK';
export const SKAN_ADWORDS = 'SKAN_ADWORDS';
export const SKAN_CONVERSION_MODEL = 'SKAN_CONVERSION_MODEL';
export const SKAN_PROBABILISTIC = 'SKAN_PROBABILISTIC';
export const SKAN_REPORTING = 'SKAN_REPORTING';

export const ADMON_CATEGORY = 'ADMON_CATEGORY';
export const ADMON_INTRO = 'ADMON_INTRO';
export const ADMON_ANALYZE = 'ADMON_ANALYZE';
export const ADMON_SETUP_REVENUE = 'ADMON_SETUP_REVENUE';
export const ADMON_EXPORT = 'ADMON_EXPORT';

export const LAST_MENU_ITEM_VISITED_LOCAL_STORAGE_KEY = 'lastMenuItemVisited';
export const LAST_MENU_ITEM_VISITED_TIMESPAMT_LOCAL_STORAGE_KEY = 'lastMenuItemVisitedTimestamp';
export const LAST_INNER_ITEM_VISITED_LOCAL_STORAGE_KEY = 'lastInnerItemIdxVisited';
export const LAST_INNER_ITEM_VISITED_TIMESTAMP_LOCAL_STORAGE_KEY = 'lastMenuItemVisitedTimestamp';
export const LAST_PAGE_PARAMS_LOCAL_STORAGE_KEY = 'lastPageParams';

export const PAGE_NAME_FOR_MIXPANEL = 'Onboarding';
export const UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL = 'Onboarding V2';

export const USER_MARK_AS_DONE_ALLOWED_SECTIONS = [
    COHORTED_EVENTS,
    SKAN_CONVERSION_MODEL,
    MANAGE_LINKS,
    WEB_SDK_INTEGRATION,
];

const SDK_QUESTION_MENU_ITEM_MAPPING = {
    [SKAN_CONVERSION_MODEL]: IS_RUNNING_SKAN_CAMPAIGNS,
    [SET_UP_FACEBOOK]: IS_RUNNING_FACEBOOK_CAMPAIGNS,
    [SET_UP_APPLE_SEARCH_ADS]: IS_RUNNING_APPLE_SEARCH_ADS_CAMPAIGNS,
    [SET_UP_GOOGLE]: IS_RUNNING_GOOGLE_ADS_CAMPAIGNS,
    [DEEP_LINKING]: IS_USING_DEEP_LINKING_TRACKING_LINKS,
    [CUSTOM_SOURCES_LINKS]: [
        IS_USING_TRACKING_LINKS_FOR_EMAIL_CAMPAIGNS,
        IS_USING_TRACKING_LINKS_FOR_SMS_CAMPAIGNS,
        IS_RUNNING_INFLUENCER_CAMPAIGNS,
    ],
};

const SDK_PLATFORM_MENU_ITEM_MAPPING = {
    [WEB_SDK_INTEGRATION]: [OS.WEB],
};

export const basicUnifiedMenuItems = [
    SDK_CONFIGURATION,
    APPS,
    SDK_INTEGRATION,
    SDK_CONSOLE_TESTING,
    WEB_SDK_INTEGRATION,
    COHORTED_EVENTS,
    PARTNER_CONFIGURATION,
    DATA_CONNECTORS,
    SKAN_CONVERSION_MODEL,
    MANAGE_LINKS,
    SET_UP_GOOGLE,
    SET_UP_FACEBOOK,
    SET_UP_APPLE_SEARCH_ADS,
    DEBUGGING_TOOLS,
    VALIDATE_SETUP,
    TEST_LINKS,
    ADVANCED_SETUP,
    CUSTOM_SOURCES_LINKS,
    DEEP_LINKING,
    EXPORT_DATA,
    RUN_REPORT,
];

const filterItemsBySdkAnswers = (items, sdkConfigAnswers, sdkConfigAnswersPlatforms) => {
    return items.filter(menuItem => {
        const existingMapping = SDK_QUESTION_MENU_ITEM_MAPPING[menuItem];
        const existingPlatformMapping = SDK_PLATFORM_MENU_ITEM_MAPPING[menuItem];

        const shouldAppearBySDKQuestion =
            !existingMapping ||
            sdkConfigAnswers[existingMapping] ||
            existingMapping.find?.(sdkQuestion => sdkConfigAnswers[sdkQuestion]);

        const shouldAppearByPlatformSelection =
            !existingPlatformMapping ||
            existingPlatformMapping.find?.(platform => sdkConfigAnswersPlatforms.includes(platform));

        return shouldAppearBySDKQuestion && shouldAppearByPlatformSelection;
    });
};

const getFilteredMenuItemsBySdkConfig = (menuItemsOrder, sdkConfigAnswers, sdkConfigAnswersPlatforms) => {
    return menuItemsOrder.map(categoryConfig => ({
        ...categoryConfig,
        items: filterItemsBySdkAnswers(categoryConfig.items, sdkConfigAnswers, sdkConfigAnswersPlatforms),
    }));
};

const hideProgressBarTasks = [SDK_CONFIGURATION];

const admonUnifiedMenuItems = [...basicUnifiedMenuItems];
// setting admon section one element before the last
admonUnifiedMenuItems.splice(basicUnifiedMenuItems.length - 1, 0, ADMON_INTRO);

const unifiedMenuItemsOrder = {
    UNIFIED_SET_UP: [
        {
            categoryId: UNIFIED_SET_UP,
            showCompletedTaskCheckbox: true,
            items: basicUnifiedMenuItems,
        },
    ],
    ADMON_UNIFIED_SET_UP: [
        {
            categoryId: ADMON_UNIFIED_SET_UP,
            showCompletedTaskCheckbox: true,
            items: admonUnifiedMenuItems,
        },
    ],
};

const analyticsMenuItemsOrder = [
    {
        categoryId: BASIC_SET_UP_CATEGORY,
        items: [BASIC_SET_UP_INTRO, DATA_CONNECTORS, INVITE_USERS],
    },
    {
        categoryId: VIEW_DATA_CATEGORY,
        items: [RUN_REPORT],
    },
    {
        categoryId: ADVANCED_SETUP_CATEGORY,
        items: [CUSTOM_DIMENSIONS, BOOKMARKS],
    },
];

export const shouldDisplayItem = itemConfig => !itemConfig.hidden && !itemConfig.hideForRestricted;

export const getFirstItemIdInCategory = (menuItems, menuItemsConfig, categoryIdToFind) => {
    let itemIdToReturn = null;

    menuItems.forEach(({ categoryId, items }) => {
        if (categoryIdToFind === categoryId) {
            [itemIdToReturn] = items.filter(
                item => !menuItemsConfig[item].isItemsHeader && shouldDisplayItem(menuItemsConfig[item])
            );
        }
    });

    return itemIdToReturn;
};

export const getCategoryIdByItemId = (menuItems, itemId) => {
    let categoryIdToReturn = null;

    menuItems.forEach(({ categoryId, items }) => {
        if (items.indexOf(itemId) >= 0) {
            categoryIdToReturn = categoryId;
        }
    });

    return categoryIdToReturn;
};

/**
 *
 * @param path
 * @param taskId is an inner id used to identify from which task the user is redirected
 */
export const redirect = (path, taskId) => {
    if (taskId) {
        sessionStorage.setItem('tourId', taskId);
    }
    route(path);
};

export const parseSdkConfigAnswers = sdkConfigAnswers => {
    return Object.keys(sdkConfigAnswers).reduce((accum, key) => {
        accum.platforms = accum.platforms ? [...accum.platforms, key] : [key];
        const platformAnswers = sdkConfigAnswers[key];
        const returnedAnswers = { ...accum };

        Object.keys(platformAnswers).forEach(answerKey => {
            returnedAnswers[answerKey] = platformAnswers[answerKey] || accum[answerKey];
        });
        return returnedAnswers;
    }, {});
};

export const getMenuItemsOrder = homepageData => {
    const { apsalarEnabled, isAdMonEnabled, sdkConfigAnswers, sdkConfigAnswersPlatforms } = homepageData;

    if (apsalarEnabled === null) return [{ categoryId: 0, items: [] }];

    let menuItems;

    if (!apsalarEnabled) {
        menuItems = analyticsMenuItemsOrder;
    } else if (isAdMonEnabled) {
        menuItems = unifiedMenuItemsOrder.ADMON_UNIFIED_SET_UP;
    } else {
        menuItems = unifiedMenuItemsOrder.UNIFIED_SET_UP;
    }

    if (sdkConfigAnswers) {
        menuItems = getFilteredMenuItemsBySdkConfig(
            menuItems,
            parseSdkConfigAnswers(sdkConfigAnswers),
            sdkConfigAnswersPlatforms
        );
    }

    return menuItems;
};

export const showOnboardingProgressBar = (onboardingProgressBarData, activeTask = null) => {
    return (
        onboardingProgressBarData &&
        onboardingProgressBarData.doneTasks !== onboardingProgressBarData.totalTasks &&
        !hideProgressBarTasks.includes(activeTask)
    );
};

const analyticsMediaSrcMapping = {
    [BASIC_SET_UP_INTRO]: '/static/dashboard/img/homepageImages/basicSetup/BS-no_progress_bar.png',
    [INVITE_USERS]: '/static/dashboard/img/homepageImages/basicSetup/BS-no_progress_bar.png',
    [RUN_REPORT]: '/static/dashboard/img/homepageImages/viewData/VD-no_progress_bar.png',
    [CUSTOM_DIMENSIONS]: '/static/dashboard/img/homepageImages/advancedSetup/AS-no_progress_bar.png',
    [BOOKMARKS]: '/static/dashboard/img/homepageImages/advancedSetup/AS-no_progress_bar.png',
};

export const getMenuItemsConfig = (homepageData, isRestricted = false) => {
    const { uans, appCount, completedTasks, lockedTasks, apsalarEnabled, isEtlEnabled } = homepageData;
    const isUanLoginError = !!uans.find(uan => !uan.is_last_login_successful);
    const menuItemsConfig = {
        [SDK_CONFIGURATION]: {
            hideForRestricted: isRestricted,
            showHeader: false,
            showFooter: false,
            numberedTask: true,
            onboardingGuideCategory: SETUP_TASKS,
            contentContainerStyle: { margin: '25px auto', padding: 0 },
        },
        [DEBUGGING_TOOLS]: {
            isItemsHeader: true,
            shouldNotShowCheckbox: true,
            icon: '/static/dashboard_react/src/resources/svg/Debugging.svg',
        },
        [ADVANCED_SETUP]: {
            isItemsHeader: true,
            shouldNotShowCheckbox: true,
            icon: 'dashboard/static/dashboard_react/src/resources/svg/Advanced.svg',
        },
        [AUDIT_REPORT]: {
            isItemsHeader: true,
            shouldNotShowCheckbox: true,
        },
        [BASIC_SET_UP_INTRO]: {
            nextItemId: APPS,
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/basicSetup/BS-1.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: isApsalarEnabled => {
                return BasicSetupIntro(isApsalarEnabled);
            },
        },
        [APPS]: {
            buttonOnClick: () => {
                return redirect('apps', TOUR_LINK_IDS.apps);
            },
            badgeText: appCount > 0 ? `${appCount} added` : undefined,
            mediaSrcType: 'video',
            mediaSrc: 'https://www.youtube.com/embed/a1gVhpPkDTY',
            ContentBodyComponent: Apps,
            onboardingGuideShowTour: true,
            onboardingGuideMediaSrcType: 'video',
            onboardingGuideMediaSrc: 'https://www.youtube.com/embed/a1gVhpPkDTY',
            numberedTask: true,
            onboardingGuideCategory: SETUP_TASKS,
        },
        [DATA_CONNECTORS]: {
            mediaSrcType: 'video',
            mediaSrc: 'https://www.youtube.com/embed/6CGX5C9wid4',
            buttonOnClick: () => {
                return redirect('react/data-sources', TOUR_LINK_IDS.dataConnectors);
            },
            lockTooltip:
                appCount === 0 ? "Once you've added your apps you can start adding data connectors" : undefined,
            error: isUanLoginError ? 'Action Required' : undefined,
            badgeText: uans.length > 0 ? `${uans.length} added` : undefined,
            ContentBodyComponent: isApsalarEnabled => {
                return DataConnectors(isApsalarEnabled);
            },
            onboardingGuideMediaSrcType: 'video',
            onboardingGuideMediaSrc: 'https://www.youtube.com/embed/6CGX5C9wid4',
            numberedTask: true,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/data-sources', TOUR_LINK_IDS.dataConnectors);
            },
            onboardingGuideCategory: SETUP_TASKS,
        },
        [SET_UP_FACEBOOK]: {
            numberedTask: true,
            onboardingGuideMediaSrcType: 'video',
            onboardingGuideMediaSrc: 'https://player.vimeo.com/video/1039355869?autoplay=1',
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/data-sources?id=345', TOUR_LINK_IDS.dataConnectors);
            },
            onboardingGuideCategory: SETUP_TASKS,
        },
        [SET_UP_GOOGLE]: {
            numberedTask: true,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/data-sources', TOUR_LINK_IDS.dataConnectors);
            },
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/basicSetup/dataConnectorsAdwords.png',
            onboardingGuideCategory: SETUP_TASKS,
        },
        [SET_UP_APPLE_SEARCH_ADS]: {
            numberedTask: true,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return route('react/data-sources', { id: 1530 });
            },
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/basicSetup/dataConnectorsApple.png',
            onboardingGuideCategory: SETUP_TASKS,
        },
        [VALIDATE_SETUP]: {
            shouldNotShowCheckbox: true,
            onboardingGuideCategory: DEBUGGING_TOOLS,
        },
        [INVITE_USERS]: {
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/basicSetup/BS-4.png',
            buttonOnClick: () => {
                return redirect('react/team');
            },
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: InviteUsers,
        },
        [SDK_INTEGRATION_INTRO]: {
            nextItemId: SDK_KEYS_AND_DOCUMENTATION,
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/SDKIntegration/SI-1.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: SdkIntegrationIntro,
        },
        [SDK_INTEGRATION_SUBCATEGORY]: {
            onboardingGuideSubSections: [SDK_KEYS_AND_DOCUMENTATION, SDK_CONSOLE_TESTING, SDK_CONSOLE_BASIC_EVENTS],
            onboardingGuideCompletedTaskName: 'SDK_INTEGRATED',
        },
        [SDK_INTEGRATION]: {
            onboardingGuideButtonOnClick: () => {
                return redirect('react/sdk_integration');
            },
            onboardingGuideShowTour: true,
            lockTooltip: "This task will unlock once you've added your apps via the Apps Page",
            buttonOnClick: () => {
                window.open('https://support.singular.net/hc/en-us/categories/360002441132', '_blank');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/SDKIntegration/SI-2.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: SdkKeysAndDocs,
            numberedTask: true,
            onboardingGuideCompletedTaskName: 'SDK_INTEGRATED',
            onboardingGuideCategory: SETUP_TASKS,
        },
        [SDK_CONSOLE_TESTING]: {
            onboardingGuideButtonOnClick: () => {
                return redirect('react/testing-console');
            },
            onboardingGuideShowTour: true,
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/basicSetup/eventsToTest.png',
            onboardingGuideMediaSrcType: 'img',
            numberedTask: true,
            onboardingGuideCategory: SETUP_TASKS,
        },
        [WEB_SDK_INTEGRATION]: {
            onboardingGuideCategory: SETUP_TASKS,
            numberedTask: true,
        },
        [SDK_CONSOLE_BASIC_EVENTS]: {
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            buttonOnClick: () => {
                return redirect('react/testing-console', 'basic');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/SDKIntegration/SI-4.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: SdkTestBasicEvents,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/testing-console');
            },
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/basicSetup/eventsToTest.png',
            onboardingGuideMediaSrcType: 'img',
            numberedTask: true,
        },
        [SDK_CONSOLE_REVENUE_EVENTS]: {
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            buttonOnClick: () => {
                return redirect('react/testing-console', 'revenue');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/SDKIntegration/SI-5.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: SdkTestRevenueEvents,
        },
        [SDK_CONSOLE_ADVANCED_EVENTS]: {
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            buttonOnClick: () => {
                return redirect('react/testing-console', 'advanced');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/SDKIntegration/SI-6.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: SdkTestAdvancedEvents,
        },
        [REPORTING_INTEGRATIONS_INTRO]: {
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/reportingIntegrations/RI-1.png',
            shouldSetCompletedOnClick: true,
            nextItemId: REPORTING_API,
            ContentBodyComponent: ReportingIntegrationsIntro,
        },
        [REPORTING_API]: {
            buttonOnClick: () => {
                window.open('https://support.singular.net/hc/en-us/articles/360045245692-Reporting-API-Reference');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/reportingIntegrations/RI-2.png',
            shouldSetCompletedOnClick: true,
            lockTooltip: 'This task will unlock once you have successfully added a Data Connector',
            ContentBodyComponent: ReportingAPI,
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/viewData/runReport.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/api');
            },
        },
        [BI_POSTBACKS]: {
            buttonOnClick: () => {
                return redirect('react/partner-config', 'bi_postbacks');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/reportingIntegrations/RI-3.png',
            shouldSetCompletedOnClick: true,
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            ContentBodyComponent: BIPostbacks,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/partner-config', TOUR_LINK_IDS.biPostbacks);
            },
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/viewData/runReport.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [SET_UP_CAMPAIGNS_INTRO]: {
            nextItemId: GET_STARTED_ADWORDS,
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/setupCampaigns/SC-1.png',
            shouldSetCompletedOnClick: true,
            ContentBodyComponent: RunCampaignsIntro,
        },
        [GET_STARTED_ADWORDS]: {
            buttonOnClick: () => {
                return redirect('react/partner-config', 'adwords');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/setupCampaigns/SC-2.png',
            shouldSetCompletedOnClick: true,
            lockTooltip: "This task will unlock once you've added your apps via the Apps page",
            ContentBodyComponent: GetStartedAdwords,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/partner-config', TOUR_LINK_IDS.partnerConfiguration);
            },
            onboardingGuideMediaSrc: 'static/dashboard/img/onboardingGuideImages/basicSetup/partnerConfAdwords.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [GET_STARTED_FACEBOOK]: {
            buttonOnClick: () => {
                return redirect('react/partner-config', 'fb');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/setupCampaigns/SC-3.png',
            shouldSetCompletedOnClick: true,
            lockTooltip: "This task will unlock once you've added your apps via the Apps page",
            ContentBodyComponent: GetStartedFacebook,
            onboardingGuideMediaSrc: 'static/dashboard/img/onboardingGuideImages/basicSetup/partnerConfFacebook.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [PARTNER_CONFIGURATION_SUBCATEGORY]: {
            onboardingGuideSubSections: [PARTNER_CONFIGURATION, GET_STARTED_FACEBOOK, GET_STARTED_ADWORDS],
            onboardingGuideCompletedTaskName: PARTNER_CONFIGURATION,
        },
        [PARTNER_CONFIGURATION]: {
            buttonOnClick: () => {
                return redirect('react/partner-config', 'settings');
            },
            onboardingGuideButtonOnClick: () => {
                return redirect('react/partner-config', 'settings');
            },
            onboardingGuideShowTour: true,
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/setupCampaigns/SC-4.png',
            ContentBodyComponent: PartnerConfig,
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/basicSetup/partnerConfiguration.png',
            onboardingGuideMediaSrcType: 'img',
            numberedTask: true,
            onboardingGuideCategory: SETUP_TASKS,
        },
        [MANAGE_LINKS]: {
            buttonOnClick: () => {
                return redirect('react/link-management', TOUR_LINK_IDS.manageLinks);
            },
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/setupCampaigns/SC-5.png',
            ContentBodyComponent: ManageLinks,
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/setupCampaigns/manageLinks.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            numberedTask: true,
            onboardingGuideCategory: SETUP_TASKS,
        },
        [CUSTOM_SOURCES_LINKS]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/setupCampaigns/customSources.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/link-management'); // no tour, only redirect
            },
            shouldNotShowCheckbox: true,
            onboardingGuideCategory: ADVANCED_SETUP,
        },
        [VISUALIZE_CAMPAIGNS]: {
            onboardingGuideMediaSrc:
                '/static/dashboard/img/onboardingGuideImages/setupCampaigns/visualizeCampaigns.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return route('react/dashboard', { new: 'true' }); // will open a new dashboard
            },
        },
        [TEST_LINKS]: {
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('export-logs', TOUR_LINK_IDS.exportLogs);
            },
            shouldNotShowCheckbox: true,
            onboardingGuideCategory: DEBUGGING_TOOLS,
        },
        [DEEP_LINKING]: {
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/link-management', TOUR_LINK_IDS.manageLinks);
            },
            shouldNotShowCheckbox: true,
            onboardingGuideCategory: ADVANCED_SETUP,
        },
        [ETL]: {
            hidden: !isEtlEnabled,
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('data-destinations', TOUR_LINK_IDS.dataDestinations);
            },
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/viewData/runReport.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [RUN_REPORT]: {
            buttonOnClick: () => {
                return redirect('react/reports', TOUR_LINK_IDS.reports);
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/viewData/VD-1.png',
            lockTooltip: 'This task will unlock once you have successfully added a Data Connector',
            ContentBodyComponent: isApsalarEnabled => {
                return RunBasicReport(isApsalarEnabled);
            },
            onboardingGuideShowTour: true,
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/setupCampaigns/runReport.png',
            onboardingGuideMediaSrcType: 'img',
            shouldNotShowCheckbox: true,
            onboardingGuideCategory: ADVANCED_SETUP,
        },
        [RUN_ROI_REPORT]: {
            buttonOnClick: () => {
                return redirect('react/reports', 'roi');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/viewData/VD-2.png',
            lockTooltip:
                'This task will unlock once you have successfully added a Data Connector and integrated the Singular SDK',
            ContentBodyComponent: RunROIReport,
        },
        [RUN_CREATIVE_REPORT]: {
            buttonOnClick: () => {
                return redirect('creatives', TOUR_LINK_IDS.creativeReports);
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/viewData/VD-3.png',
            lockTooltip: 'This task will unlock once you have successfully added a Data Connector',
            ContentBodyComponent: RunCreativesReport,
            onboardingGuideShowTour: true,
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/viewData/runReport.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [EXPORT_LOGS]: {
            buttonOnClick: () => {
                return redirect('export-logs');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/viewData/VD-4.png',
            shouldSetCompletedOnClick: true,
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps.",
            ContentBodyComponent: ExportLogs,
        },
        [COHORTED_EVENTS]: {
            buttonOnClick: () => {
                return redirect('react/singular-events', TOUR_LINK_IDS.customEvents);
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/advancedSetup/AS-1.png',
            lockTooltip: "This task will unlock once you've integrated the Singular SDK to one of your apps",
            ContentBodyComponent: CustomEvents,
            onboardingGuideShowTour: true,
            onboardingGuideMediaSrc: 'https://vimeo.com/showcase/9069321/embed?autoplay=1',
            onboardingGuideMediaSrcType: 'video',
            onboardingGuideCategory: SETUP_TASKS,
            numberedTask: true,
        },
        [REPORTS]: {
            isItemsHeader: true,
        },
        [REPORTS_AND_PIVOTS]: {
            onboardingGuideMediaSrc: 'https://vimeo.com/showcase/8962880/embed?autoplay=1',
            onboardingGuideMediaSrcType: 'video',
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/reports', TOUR_LINK_IDS.reports);
            },
        },
        [RUN_ADDITIONAL_REPORT]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/viewData/runReport.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [EXPORT_DATA]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/viewData/runReport.png',
            onboardingGuideMediaSrcType: 'img',
            shouldNotShowCheckbox: true,
            onboardingGuideCategory: ADVANCED_SETUP,
        },
        [CUSTOM_DIMENSIONS]: {
            buttonOnClick: () => {
                return redirect('react/custom-dimensions', TOUR_LINK_IDS.customDimensions);
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/advancedSetup/AS-2.png',
            lockTooltip: 'This task will unlock once you have successfully added a Data Connector',
            ContentBodyComponent: CustomDimensions,
            onboardingGuideShowTour: true,
        },
        [BOOKMARKS]: {
            buttonOnClick: () => {
                return redirect('react/reports', 'bookmarks');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/advancedSetup/AS-3.png',
            lockTooltip: 'This task will unlock once you have successfully added a Data Connector',
            ContentBodyComponent: Bookmarks,
        },
        [CREATIVE_TAGS]: {
            buttonOnClick: () => {
                return redirect('creativetags');
            },
            mediaSrcType: 'img',
            mediaSrc: '/static/dashboard/img/homepageImages/advancedSetup/AS-4.png',
            shouldSetCompletedOnClick: true,
            lockTooltip: 'This task will unlock once you have successfully added a Data Connector',
            ContentBodyComponent: CreativeTags,
        },
        [SKAN_INTRO]: {
            onboardingGuideSubSections: [SKAN_INTRO, SKAN_FACEBOOK, SKAN_ADWORDS],
            onboardingGuideMediaSrc: 'https://player.vimeo.com/video/449563044?loop=1&autoplay=1&wmode=opaque',
            onboardingGuideMediaSrcType: 'video',
        },
        [SKAN_FACEBOOK]: {},
        [SKAN_ADWORDS]: {},
        [SKAN_CONVERSION_MODEL]: {
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/skadnetwork', TOUR_LINK_IDS.conversionModels);
            },
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/skan/conversionModel.png',
            onboardingGuideMediaSrcType: 'img',
            numberedTask: true,
            onboardingGuideCategory: SETUP_TASKS,
        },
        [SKAN_PROBABILISTIC]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/skan/probabilisticAttribution.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/link-management');
            },
        },
        [SKAN_REPORTING]: {
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/skadnetwork_summary_report', TOUR_LINK_IDS.skanReport);
            },
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/skan/skanReport.png',
            onboardingGuideMediaSrcType: 'img',
        },
        [ADMON_INTRO]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/admon/admon_intro.png',
            onboardingGuideMediaSrcType: 'img',
            shouldNotShowCheckbox: true,
            onboardingGuideShowTour: true,
            onboardingGuideButtonOnClick: () => {
                return redirect('react/data-sources', TOUR_LINK_IDS.dataConnectors);
            },
        },
        [ADMON_ANALYZE]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/admon/admon_analytics.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/data-sources');
            },
        },
        [ADMON_SETUP_REVENUE]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/admon/admon_roi_sdk.png',
            onboardingGuideMediaSrcType: 'img',
            onboardingGuideShowTour: true,
            buttonOnClick: () => {
                return redirect('react/reports');
            },
        },
        [ADMON_EXPORT]: {
            onboardingGuideMediaSrc: '/static/dashboard/img/onboardingGuideImages/admon/admon_export.png',
            onboardingGuideMediaSrcType: 'img',
        },
    };
    const menuItemsOrder = getMenuItemsOrder(homepageData);

    menuItemsOrder.forEach(({ categoryId, items }) => {
        menuItemsConfig[categoryId] = menuItemsConfig[categoryId] || {};
        items.forEach(item => {
            const itemConfig = menuItemsConfig[item] || {};

            itemConfig.completed =
                (itemConfig.onboardingGuideCompletedTaskName &&
                    !!completedTasks[itemConfig.onboardingGuideCompletedTaskName]) ||
                (!itemConfig.onboardingGuideCompletedTaskName && !!completedTasks[item]);
            itemConfig.locked = !!lockedTasks[item];
            if (!apsalarEnabled && analyticsMediaSrcMapping[item]) {
                itemConfig.mediaSrc = analyticsMediaSrcMapping[item];
            }
        });

        menuItemsConfig[categoryId].completed = items.reduce((completed, item) => {
            const itemConfig = menuItemsConfig[item] || {};

            return completed && (itemConfig.isIntro || itemConfig.completed);
        }, true);
    });
    if (!apsalarEnabled) {
        menuItemsConfig[BASIC_SET_UP_INTRO].nextItemId = DATA_CONNECTORS;
    }

    return menuItemsConfig;
};

export const getCurrentDateTime = () => {
    return new Date().getTime();
};

export const setLastVisitedMenuItem = itemId => {
    sessionStorage.setItem(LAST_MENU_ITEM_VISITED_LOCAL_STORAGE_KEY, itemId);
    sessionStorage.setItem(LAST_MENU_ITEM_VISITED_TIMESPAMT_LOCAL_STORAGE_KEY, getCurrentDateTime().toString());
};

export const getLastVisitedMenuItem = () => {
    const lastVisitedMenuItem = sessionStorage.getItem(LAST_MENU_ITEM_VISITED_LOCAL_STORAGE_KEY);
    // tmp bug fix for invalid values in sessionStorage, can be removed after 07/09/21
    return lastVisitedMenuItem !== '-1' ? lastVisitedMenuItem : null;
};

export const setLastVisitedInnerItemIndex = idx => {
    sessionStorage.setItem(LAST_INNER_ITEM_VISITED_LOCAL_STORAGE_KEY, idx);
    sessionStorage.setItem(LAST_INNER_ITEM_VISITED_TIMESTAMP_LOCAL_STORAGE_KEY, getCurrentDateTime().toString());
};

export const getLastVisitedInnerItemIndex = () => {
    const idx = sessionStorage.getItem(LAST_INNER_ITEM_VISITED_LOCAL_STORAGE_KEY);
    return idx && parseInt(idx, 10);
};

export const getLastVisitedTimestampMenuItem = () => {
    return sessionStorage.getItem(LAST_MENU_ITEM_VISITED_TIMESPAMT_LOCAL_STORAGE_KEY);
};

export const getLastVisitedInnerItemTimestampMenuItem = () => {
    return sessionStorage.getItem(LAST_INNER_ITEM_VISITED_TIMESTAMP_LOCAL_STORAGE_KEY);
};

const getLastVisitedPageParams = () => {
    return sessionStorage.getItem(LAST_PAGE_PARAMS_LOCAL_STORAGE_KEY);
};

export const setLastVisitedPageParams = apsalarEnabled => {
    return sessionStorage.setItem(LAST_PAGE_PARAMS_LOCAL_STORAGE_KEY, apsalarEnabled);
};

const comparePageParams = apsalarEnabled => {
    // will fix bugs when impersonating users jumping from onboarding guide to homepage where menu item is invalid
    return ![null, undefined].includes(apsalarEnabled) && apsalarEnabled.toString() === getLastVisitedPageParams();
};

export const trackMarkAsDoneClick = taskName => {
    window.mixpanel.track(`${UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL}: Mark as Done`, {
        taskName,
    });
};

export const trackTimeSpentOnTask = (
    lastTaskId = getLastVisitedMenuItem(),
    lastVisitedTimestamp = getLastVisitedTimestampMenuItem(),
    pageName = PAGE_NAME_FOR_MIXPANEL
) => {
    window.mixpanel.track(`${pageName}: Time Spent On Task`, {
        taskId: lastTaskId,
        timeSpentInSeconds: parseInt(lastVisitedTimestamp, 10) / 1000,
    });
};

export const trackTaskButtonClick = (
    taskId,
    nextTaskId,
    guideName,
    pageName = PAGE_NAME_FOR_MIXPANEL,
    buttonName = taskId
) => {
    window.mixpanel.track(`${pageName}: Click on Task Button`, {
        taskId,
        destinationTask: nextTaskId,
        guideName,
        buttonName,
    });
};

export const trackTaskClick = (taskId, nextTaskId, guideName, pageName = PAGE_NAME_FOR_MIXPANEL) => {
    window.mixpanel.track(`${pageName}: Click on Task From Menu`, {
        taskId,
        destinationTask: nextTaskId,
        guideName,
    });
};

export const trackCategoryClick = (activeCategoryId, clickedCategoryId, pageName = PAGE_NAME_FOR_MIXPANEL) => {
    window.mixpanel.track(`${pageName}: Click on Guide`, {
        activeGuide: activeCategoryId,
        clickedGuide: clickedCategoryId,
    });
};

export const getDefaultActiveItem = (menuItems, menuItemsConfig, apsalarEnabled) => {
    const menuNonHeaderItems =
        menuItems.length > 0 &&
        menuItems[0].items &&
        menuItems[0].items.filter(
            item => !menuItemsConfig[item].isItemsHeader && shouldDisplayItem(menuItemsConfig[item])
        );
    const firstItem = menuNonHeaderItems && menuNonHeaderItems[0];
    const lastVisitedMenuItem = getLastVisitedMenuItem();
    const defaultActiveItemId =
        comparePageParams(apsalarEnabled) &&
        lastVisitedMenuItem &&
        shouldDisplayItem(menuItemsConfig[lastVisitedMenuItem])
            ? lastVisitedMenuItem
            : firstItem;

    let defaultActiveCategoryId = null;

    if (defaultActiveItemId) {
        defaultActiveCategoryId = getCategoryIdByItemId(menuItems, defaultActiveItemId);
    }

    return { defaultActiveItemId, defaultActiveCategoryId };
};

export const parseHomepageHref = (href, taskId) => {
    return `${href}?onboardingTaskId=${taskId}`;
};
