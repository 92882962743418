import React, { Fragment, useCallback, useRef } from 'react';
import CodeMirror from 'react-codemirror';
import { CSSLint } from 'csslint';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/mode/css/css';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import * as Yup from 'yup';
import classnames from 'classnames';
import { getMaxLengthErrMsg } from 'banners/utils';
import { useFormikContext } from 'formik-2';
import css from './CssEditor.css';

// required by codemirror for css syntax validation
window.CSSLint = CSSLint;

export const CSS_VALIDATION_SCHEMA = {
    css: Yup.string().max(100000, getMaxLengthErrMsg('CSS', 100000)),
};

export default function CssEditor() {
    const { values, touched, errors, setValues, setTouched } = useFormikContext();
    const editorRef = useRef(null);

    const _onChange = useCallback(
        debounce(
            cssContent => {
                // if there are no syntax errors
                if (!editorRef.current.codeMirror.state.lint.marked.length) {
                    setValues({ ...values, css: cssContent });
                }
            },
            1000,
            { trailing: true }
        ),
        []
    );

    const _onFocusChanged = useCallback(
        // CodeMirror encapsulate the change events, so we need to manually trigger them, for 'touched' logic
        () => {
            setTouched({ ...touched, css: true }, true);
        },
        []
    );

    return (
        <Fragment>
            <div className={css.description}>
                <Translate id="STATIC.PAGES.BANNERS.BANNER_SHELF.CSS_EDITOR_DESCRIPTION" />
            </div>
            <CodeMirror
                name="css"
                ref={editorRef}
                value={values.css}
                className={classnames(css.editor, {
                    [css.errorIndicator]: touched.css && errors.css,
                })}
                onChange={_onChange}
                onFocusChange={_onFocusChanged}
                options={{
                    lineNumbers: true,
                    smartIndent: false,
                    indentWithTabs: true,
                    mode: 'css',
                    gutters: ['CodeMirror-lint-markers'],
                    lint: true,
                }}
            />
            {touched.css && errors.css && <div className={css.errorMsg}>{errors.css}</div>}
        </Fragment>
    );
}

CssEditor.propTypes = {
    values: PropTypes.objectOf(PropTypes.any),
    setValues: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
    setTouched: PropTypes.func,
};

CssEditor.defaultProps = {
    values: {},
    setValues: () => {},
    errors: {},
    touched: {},
    setTouched: () => {},
};
