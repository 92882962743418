import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './codeBlockTabs.css';
import { CODE_BLOCK_SNIPPETS_LANGUAGES } from './consts';

const CodeBlockTabs = ({ selectedLanguage, languageItems, darkTheme, handleOnLanguageChange }) => {
    const [activeTab, setActiveTab] = useState(selectedLanguage);

    const handleClick = selectedTab => {
        if (!Array.isArray(languageItems) && !languageItems?.length) return;

        const clickedTab = languageItems.find(tab => tab.codeLanguage === selectedTab.codeLanguage);
        setActiveTab(clickedTab?.codeLanguage);
        try {
            handleOnLanguageChange && handleOnLanguageChange(clickedTab);
        } catch (e) {}
    };

    return (
        <>
            {languageItems.map(tab => {
                return (
                    <button
                        key={`button-${tab.codeLanguage}`}
                        type="button"
                        className={classnames(css.codeLanguageButton, {
                            [css.codeLanguageButtonSelectedDark]: activeTab === tab.codeLanguage && darkTheme,
                            [css.codeLanguageButtonSelectedLight]: activeTab === tab.codeLanguage && !darkTheme,
                        })}
                        onClick={() => handleClick(tab)}
                    >
                        {tab.displayTabTitle}
                    </button>
                );
            })}
        </>
    );
};

CodeBlockTabs.propTypes = {
    languageItems: PropTypes.arrayOf(
        PropTypes.shape({
            codeLanguage: PropTypes.oneOf(Object.values(CODE_BLOCK_SNIPPETS_LANGUAGES)),
            displayTabTitle: PropTypes.string,
        })
    ),
    darkTheme: PropTypes.bool,
    handleOnLanguageChange: PropTypes.func,
    selectedLanguage: PropTypes.oneOf(Object.values(CODE_BLOCK_SNIPPETS_LANGUAGES)).isRequired,
};

CodeBlockTabs.defaultProps = {
    languageItems: undefined,
    darkTheme: true,
    handleOnLanguageChange: undefined,
};

export default CodeBlockTabs;
