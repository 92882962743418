import React from 'react';
import PropTypes from 'prop-types';
import VirtualList from 'react-tiny-virtual-list';
import LinkRowItem from './LinkRowItem';
import css from './LinkList.css';

class LinkList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.itemSize = 40;
        this.space = 2;
    }

    render() {
        const { links, onEditClick, onDuplicateClicked, onArchiveClicked, showAgenciesLinks } = this.props;
        const visibleItems = Math.min(links.length, 8);
        const spaces = links.length ? (visibleItems - 1) * this.space : 0;
        const height = links.length ? visibleItems * this.itemSize + spaces : 0;

        return (
            <div className={css.container}>
                <VirtualList
                    width="100%"
                    height={height}
                    itemCount={links.length || 1}
                    itemSize={index => {
                        let ret = this.itemSize;
                        if (index > 0) {
                            ret += this.space;
                        }
                        return ret;
                    }}
                    renderItem={({ index, style }) => {
                        const link = links[index];
                        return (
                            <div className={css.row} key={`${link.tag_id}_${link.platform}`} style={style}>
                                <LinkRowItem
                                    {...link}
                                    linksCount={links.length}
                                    isLast={index === links.length - 1}
                                    onEditClick={onEditClick}
                                    onDuplicateClicked={onDuplicateClicked}
                                    onArchiveClicked={onArchiveClicked}
                                    showAgencyLabel={showAgenciesLinks}
                                />
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}

LinkList.propTypes = {
    links: PropTypes.arrayOf(PropTypes.any),
    onEditClick: PropTypes.func,
    onDuplicateClicked: PropTypes.func,
    onArchiveClicked: PropTypes.func,
    showAgenciesLinks: PropTypes.bool,
};

LinkList.defaultProps = {
    links: [],
    onEditClick: () => {},
    onDuplicateClicked: () => {},
    onArchiveClicked: () => {},
    showAgenciesLinks: false,
};

export default LinkList;
