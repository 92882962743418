import moment from 'moment';

export const sortAlphabetically = (string1 = '', string2 = '', isDescending = false, caseSensitive = true) => {
    string1 = string1 || '';
    string2 = string2 || '';

    if (!caseSensitive) {
        string1 = string1.toLowerCase();
        string2 = string2.toLowerCase();
    }

    const compare = string1.localeCompare(string2);

    return isDescending ? compare * -1 : compare;
};

export const sortAlphabeticallyLowecase = (str1, str2, isDescending = false) => {
    return sortAlphabetically(str1.toLowerCase(), str2.toLowerCase(), isDescending);
};

export const sortAlphabeticallyByDisplayNameLowecase = (obj1, obj2, isDescending = false) => {
    return sortAlphabeticallyLowecase(obj1.display_name, obj2.display_name, isDescending);
};

export const sortAlphabeticallyByLabelLowecase = (obj1, obj2, isDescending = false) => {
    return sortAlphabeticallyLowecase(obj1.label, obj2.label, isDescending);
};

export const sortNumeric = (num1, num2, isAscending) => {
    let compare;

    if (num1 === undefined) {
        compare = -1;
    } else if (num2 === undefined) {
        compare = 1;
    } else {
        compare = num1 - num2;
    }

    return isAscending ? compare * -1 : compare;
};

export const sortBool = (bool1, bool2, isAscending) => {
    let compare;

    if (bool1 === bool2) {
        compare = 0;
    } else if (bool1) {
        compare = -1;
    } else {
        compare = 1;
    }

    return isAscending ? compare * -1 : compare;
};

export const sortDate = (date1, date2, isAscending) => {
    let compare;

    if (!moment(date1).isValid() && !moment(date2).isValid()) {
        return 1;
    } else if (!moment(date1).isValid()) {
        compare = 1;
    } else if (!moment(date2).isValid()) {
        compare = -1;
    } else {
        compare = moment(date1).diff(moment(date2));
    }

    return isAscending ? compare * -1 : compare;
};

export const sortByType = {
    string: sortAlphabetically,
    number: sortNumeric,
    bool: sortBool,
    date: sortDate,
};
