import React from 'react';
import { useQuery } from '@apollo/client';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import CustomEventPostbacks from './events/CustomEventPostbacks';
import RevenueEventPostbacks from './events/RevenueEventPostbacks';
import { GET_EVENTS } from '../../queries';
import {
    ParameterTypes,
    partnerShape,
    siteShape,
    TemplateTypes,
    PostbackWindowStartingPoint,
    organizationOptionsShape,
} from '../types';
import { Spinner } from '../../../components/widgets';
import PartnerAdditionalInformation from './PartnerAdditionalInformation';
import { getParametersValidationSchema } from './PartnerParameters';
import { getUsedParameters } from './utils';

const POSTBACK_WINDOW_RANGE_ERROR =
    'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.EVENT_POSTBACKS_TABLE.POSTBACK_WINDOW_RANGE_ERROR';

export const MIN_POSTBACK_WINDOW = 1;
export const MAX_POSTBACK_WINDOW = 99;

const getPostbackValidationSchema = (partner, platform, templateType) =>
    Yup.object().shape({
        ...getParametersValidationSchema(getUsedParameters(partner, platform, templateType, [ParameterTypes.POSTBACK])),
        eventId: Yup.string()
            .nullable()
            .required('STATIC.REQUIRED_FIELD'),
        postbackWindow: Yup.number()
            .nullable()
            .when('postbackWindowStartingPoint', {
                is: windowStartingPoint => windowStartingPoint !== PostbackWindowStartingPoint.UNLIMITED,
                then: Yup.number()
                    .required('STATIC.REQUIRED_FIELD')
                    .min(MIN_POSTBACK_WINDOW, POSTBACK_WINDOW_RANGE_ERROR)
                    .max(MAX_POSTBACK_WINDOW, POSTBACK_WINDOW_RANGE_ERROR),
            }),
    });

export const getEventsSettingsValidationSchema = (partner, platform) => ({
    customEventPostbacks: Yup.array()
        .of(getPostbackValidationSchema(partner, platform, TemplateTypes.CUSTOM))
        .nullable(),
    revenueEventPostbacks: Yup.array()
        .of(getPostbackValidationSchema(partner, platform, TemplateTypes.REVENUE))
        .nullable(),
});

function EventsSettings({ partner, site, isReadonly, orgOptions }) {
    const eventsResponse = useQuery(GET_EVENTS, { variables: { siteId: site.site_id } });
    const templates = partner.postbackTemplates.filter(template => template.platform === site.platform);

    if (eventsResponse.loading) {
        return <Spinner show expanded />;
    }

    const { events = [] } = eventsResponse.data || {};

    const eventPostbacksProps = { events, partner, templates, site, isReadonly, orgOptions };

    return (
        <>
            <RevenueEventPostbacks {...eventPostbacksProps} />
            <CustomEventPostbacks {...eventPostbacksProps} />
            <PartnerAdditionalInformation partner={partner} />
        </>
    );
}

EventsSettings.propTypes = {
    partner: partnerShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    site: siteShape.isRequired,
    isReadonly: PropTypes.bool,
};

EventsSettings.defaultProps = {
    isReadonly: false,
};

export default EventsSettings;
