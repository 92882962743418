import { toast as _toast, style } from 'react-toastify';
import { fonts } from '../global/utils';

const DEFAULT_OPTIONS = {
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    pauseOnHover: true,
};

style({
    fontFamily: fonts,
    BOTTOM_RIGHT: {
        bottom: '1em',
        right: '10px',
    },
});

const toast = (content, options) => _toast(content, { ...DEFAULT_OPTIONS, ...options });
const error = (content, options) => _toast.error(content, { ...DEFAULT_OPTIONS, ...options });
const warn = (content, options) => _toast.warn(content, { ...DEFAULT_OPTIONS, ...options });
const warning = (content, options) => _toast.warning(content, { ...DEFAULT_OPTIONS, ...options });
const info = (content, options) => _toast.info(content, { ...DEFAULT_OPTIONS, ...options });
const success = (content, options) => _toast.success(content, { ...DEFAULT_OPTIONS, ...options });

export default {
    ..._toast,
    toast,
    error,
    warn,
    warning,
    info,
    success,
    DEFAULT_OPTIONS,
};
