import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import * as Yup from 'yup';
import { getMaxLengthErrMsg } from 'banners/utils';
import { useFormikContext } from 'formik-2';
import { AutoCompleteFieldEnhanced, DropdownFieldEnhanced, FormField } from '../../../../utils/components/formUtils';
import { BannerPlacement, EMPTY_OPTIONS } from '../../../consts';
import css from './Info.css';

export const INFO_VALIDATION_SCHEMA = {
    name: Yup.string()
        .matches(/^.*\S+.*$/, { message: 'Name can not be empty' })
        .matches(/\s*(?:\S\s*){3,}$/, { message: 'Name must have at least 3 characters' })
        .max(100, getMaxLengthErrMsg('Name', 100))
        .required('Required'),
    description: Yup.string()
        .max(100, getMaxLengthErrMsg('Description', 100))
        .nullable(),
};

export default function Info() {
    const { values, touched, errors } = useFormikContext();

    const placementOptions = Object.keys(BannerPlacement).map(option => ({
        name: BannerPlacement[option],
        display_name: `STATIC.PAGES.BANNERS.BANNER_SHELF.${option}`,
    }));

    return (
        <Fragment>
            <div className={css.description}>
                <Translate id="STATIC.PAGES.BANNERS.BANNER_SHELF.INFO_DESCRIPTION" />
            </div>
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="name"
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.NAME_LABEL"
                    labelTooltip="STATIC.PAGES.BANNERS.BANNER_SHELF.NAME_TOOLTIP"
                    isMulti={false}
                    options={EMPTY_OPTIONS}
                    searchable
                    mandatory
                    placeholder="STATIC.PAGES.BANNERS.BANNER_SHELF.NAME_PLACEHOLDER"
                    error={touched.name && errors.name}
                    value={values.name}
                    debounceTime={700}
                />
            </FormField>
            <FormField>
                <DropdownFieldEnhanced
                    name="placement"
                    items={placementOptions}
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.PLACEMENT_LABEL"
                    selected={values.placement}
                />
            </FormField>
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="description"
                    value={values.description}
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.DESCRIPTION_LABEL"
                    isMulti={false}
                    options={EMPTY_OPTIONS}
                    error={touched.description && errors.description}
                    searchable
                    placeholder="STATIC.PAGES.BANNERS.BANNER_SHELF.DESCRIPTION_PLACEHOLDER"
                    debounceTime={700}
                />
            </FormField>
        </Fragment>
    );
}

Info.propTypes = {
    values: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
    errors: PropTypes.objectOf(PropTypes.any),
};

Info.defaultProps = {
    values: {},
    touched: {},
    errors: {},
};
