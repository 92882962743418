export const ERROR = 'web.tracking.error';
export const UPDATE_STATE = 'web.tracking.update.state';
export const UPDATE_ATTRIBUTE = 'web.tracking.update.attribute';
export const UPDATE_TARGET = 'web.tracking.update.target';
export const UPDATE_GENERAL_FIELD = 'web.tracking.update.general.field';
export const GENERATE_LINK = 'web.tracking.generate.link';
export const GENERATE_LINK_STARTED = 'web.tracking.generate.link.started';
export const GENERATE_LINK_DONE = 'web.tracking.generate.link.done';
export const FETCH_META = 'web.tracking.fetch.meta';
export const FETCH_META_DONE = 'web.tracking.fetch.meta.done';
export const PARTNER_DISCOVER = 'web.tracking.partner.discover';
export const PARTNER_DISCOVER_STARTED = 'web.tracking.partner.discover.started';
export const PARTNER_DISCOVER_DONE = 'web.tracking.partner.discover.done';
export const TOGGLE_INSTRUCTIONS_SHELF = 'web.tracking.toggle.instructions.shelf';
export const RE_ENABLED_TOGGLE = 'web.tracking.re.enabled.toggle';

export const fetchMeta = () => {
    return {
        type: FETCH_META,
    };
};

export const fetchMetaDone = data => {
    return {
        type: FETCH_META_DONE,
        data,
    };
};

export const updateState = newState => {
    return {
        type: UPDATE_STATE,
        stateOverride: newState,
    };
};

export const updateAttribute = (attribute, value) => {
    return {
        type: UPDATE_ATTRIBUTE,
        attribute,
        value,
    };
};

export const updateTarget = selection => {
    return {
        type: UPDATE_TARGET,
        selection,
    };
};

export const updateGeneralField = (field, key, selection) => {
    return {
        type: UPDATE_GENERAL_FIELD,
        field,
        key,
        selection,
    };
};

export const partnerDiscover = partnerId => {
    return {
        type: PARTNER_DISCOVER,
        partnerId,
    };
};

export const partnerDiscoverStarted = utmParams => {
    return {
        type: PARTNER_DISCOVER_STARTED,
        utmParams,
    };
};

export const partnerDiscoverDone = (attributesMeta, attributes, instructions) => {
    return {
        type: PARTNER_DISCOVER_DONE,
        attributesMeta,
        attributes,
        instructions,
    };
};

export const generateLink = (url, attributes, targetId, partnerId, landingPage, reEnabled) => {
    return {
        type: GENERATE_LINK,
        url,
        attributes,
        targetId,
        partnerId,
        landingPage,
        reEnabled,
    };
};

export const generateLinkStarted = () => {
    return {
        type: GENERATE_LINK_STARTED,
    };
};

export const generateLinkDone = trackingLink => {
    return {
        type: GENERATE_LINK_DONE,
        trackingLink,
    };
};

export const error = (text = 'Unexpected Error') => {
    return {
        type: ERROR,
        text,
    };
};

export const toggleInstructionsShelf = () => {
    return {
        type: TOGGLE_INSTRUCTIONS_SHELF,
    };
};

export const onReToggleChanged = () => {
    return {
        type: RE_ENABLED_TOGGLE,
    };
};
