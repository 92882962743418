import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import flatten from 'lodash/flatten';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import 'react-select/dist/react-select.css';
import classnames from 'classnames';
import { components } from 'react-select';
import uniqBy from 'lodash/uniqBy';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import css from './TagInput.css';
import TagInputSuggestion from './TagInputSuggestion';
import SelectMenuList from './SelectMenuList';
import SelectGroup from './SelectGroup';
import Select from './Select';
import { invalidValue } from '../../customDimensions/consts';

export const Tag = React.memo(props => {
    const {
        data: { label, deleteEnabled, invalid },
        removeProps: { onClick },
        selectProps: { tagLockAlt = '' },
    } = props;
    const showDelete = deleteEnabled === true || deleteEnabled === undefined;

    return (
        <div
            className={css.tagContainer}
            style={{ border: invalid ? '1px solid #E64D5F' : '' }}
            title={!showDelete ? tagLockAlt : ''}
        >
            <div className={css.tagLabel}>{label}</div>
            {showDelete ? (
                <div
                    className={css.tagX}
                    role="button"
                    onClick={e => {
                        e.stopPropagation();
                        onClick();
                    }}
                    onMouseDown={e => {
                        e.stopPropagation();
                    }}
                    tabIndex={0}
                >
                    <XIcon className={css.clearIcon} />
                </div>
            ) : (
                <FontAwesomeIcon className={css.tagLock} icon={faLock} />
            )}
        </div>
    );
});

Tag.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    removeProps: PropTypes.objectOf(PropTypes.any),
    selectProps: PropTypes.objectOf(PropTypes.any),
};

Tag.defaultProps = {
    data: { label: '', value: '' },
    removeProps: {
        onClick: () => {},
    },
    selectProps: {},
};

function Input(props) {
    const { selectProps } = props;
    const { onPaste } = selectProps;
    const { Input: SelectInput } = components;
    return <SelectInput {...props} onPaste={onPaste} />;
}

Input.propTypes = {
    selectProps: PropTypes.objectOf(PropTypes.any),
};
Input.defaultProps = {
    selectProps: {},
};

const getComponentsOverride = (hideDropdownIndicator, virtualScrolling, allowPasteInput) => {
    const defaultOverride = {
        Option: TagInputSuggestion,
        MultiValue: Tag,
        ClearIndicator: null,
    };

    if (hideDropdownIndicator) {
        defaultOverride.DropdownIndicator = null;
        defaultOverride.IndicatorSeparator = null;
    }

    if (virtualScrolling) {
        defaultOverride.MenuList = SelectMenuList;
        defaultOverride.Group = SelectGroup;
    }
    if (allowPasteInput) {
        defaultOverride.Input = Input;
    }

    return defaultOverride;
};

function TagInput({
    ariaLabel,
    virtualScrolling,
    suggestions,
    tags,
    disabled,
    containerStyle,
    placeholder,
    tagLockAlt,
    error,
    creatable,
    openMenuOnClick,
    formatCreateLabel,
    isValidNewOption,
    onTagAdded,
    onTagRemoved,
    onChange,
    allowPasteInput,
    tagError,
    className: customClass,
    hideDropdownIndicator,
    dataTestId,
    disableOptions,
}) {
    const [blockAction, setBlockAction] = useState(false);

    const selectRef = useRef(null);

    const arrowRenderer = useCallback(() => {
        return <span />;
    }, []);

    const placeholderElement = useMemo(() => {
        return <Translate id={placeholder} />;
    }, [placeholder]);

    const formatStringSuggestions = currSuggestions => {
        if (!currSuggestions) {
            return null;
        }

        return currSuggestions.map(s => {
            if (typeof s === 'string') {
                return { value: s, label: s, isDisabled: disableOptions };
            }
            return { ...s, isDisabled: s.isDisabled || disableOptions };
        });
    };

    const formatTags = currTags => {
        return currTags.map(tag => {
            if (typeof tag === 'string') {
                return suggestions.find(suggestion => suggestion.value === tag);
            }

            const { text } = tag;

            if (text) {
                if (text.includes('<invalid>') || 'invalid' in tag) {
                    return { label: text, value: text, invalid: true };
                }
                return { label: text, value: text };
            }

            return tag;
        });
    };

    const [formattedTags, setFormattedTags] = useState([]);
    const [formattedSuggestion, setFormattedSuggestion] = useState([]);

    useEffect(() => {
        const newFormattedTags = formatTags(tags);

        if (newFormattedTags && JSON.stringify(newFormattedTags) !== JSON.stringify(formattedTags) && !blockAction) {
            setFormattedTags(newFormattedTags);
        }
    }, [tags]);

    const componentsOverride = getComponentsOverride(hideDropdownIndicator, virtualScrolling, allowPasteInput);

    useEffect(() => {
        const newFormattedSuggestions = formatStringSuggestions(suggestions, disableOptions);

        if (
            newFormattedSuggestions &&
            JSON.stringify(newFormattedSuggestions) !== JSON.stringify(formattedSuggestion)
        ) {
            setFormattedSuggestion(newFormattedSuggestions);
        }
    }, [suggestions, disableOptions]);

    const handleSelectChange = (currentTags, actionData) => {
        const { removedValue } = actionData || {};

        if (removedValue?.deleteEnabled === false) {
            setBlockAction(true);
            return;
        }

        setBlockAction(false);

        const flattened = flatten(currentTags);

        for (const tag of flattened) {
            if (!formattedTags.find(t => t.label === tag.label)) {
                onTagAdded(tag.label);
            }
        }

        for (const tag of formattedTags) {
            if (!flattened.find(t => t.label === tag.label)) {
                onTagRemoved(tag.label);
            }
        }

        onChange(currentTags);
    };

    const getOrCreateTag = value => {
        return formattedSuggestion.some(x => x.value === value)
            ? formattedSuggestion.find(x => x.value === value)
            : { value, label: value };
    };

    const formatInvalidTag = tag => {
        const value = tag.value.replace(invalidValue, '').trim();
        return { value, label: value };
    };

    const onPaste = event => {
        if (allowPasteInput) {
            const pastedItems = event.clipboardData.getData('text').split(',');
            if (pastedItems.length > 1) {
                const parsedItems = pastedItems.map(value => getOrCreateTag(value.trim()));
                const formattedTagsFix = formattedTags.map(tag => formatInvalidTag(tag));
                const values = uniqBy([...formattedTagsFix, ...parsedItems], 'value');
                handleSelectChange(values);
                selectRef.current.inputRef.blur();
            }
        }
    };

    const SelectClass = !creatable ? Select : Creatable;

    return (
        <div
            className={classnames(css.container, 'fs-ignore-rage-clicks', {
                [customClass]: !!customClass,
            })}
            style={{
                ...containerStyle,
            }}
            data-testid={dataTestId}
        >
            <SelectClass
                ref={selectRef}
                aria-label={ariaLabel}
                className={classnames(css.singularSelect, { [css.error]: error || tagError })}
                arrowRenderer={arrowRenderer}
                clearable={false}
                formatCreateLabel={formatCreateLabel}
                isValidNewOption={isValidNewOption}
                isDisabled={disabled}
                isMulti
                onChange={handleSelectChange}
                options={formattedSuggestion}
                placeholder={placeholderElement}
                value={formattedTags}
                escapeClearsValue={false}
                classNamePrefix="Select"
                components={componentsOverride}
                tagLockAlt={tagLockAlt}
                openMenuOnClick={openMenuOnClick}
                onPaste={onPaste}
            />
        </div>
    );
}

TagInput.propTypes = {
    ariaLabel: PropTypes.string,
    onTagRemoved: PropTypes.func,
    onTagAdded: PropTypes.func,
    onChange: PropTypes.func,
    suggestions: PropTypes.arrayOf(PropTypes.any),
    disabled: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.any),
    placeholder: PropTypes.string,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    tagLockAlt: PropTypes.string,
    error: PropTypes.string,
    tagError: PropTypes.bool,
    virtualScrolling: PropTypes.bool,
    creatable: PropTypes.bool,
    openMenuOnClick: PropTypes.bool,
    formatCreateLabel: PropTypes.func,
    isValidNewOption: PropTypes.func,
    allowPasteInput: PropTypes.bool,
    hideDropdownIndicator: PropTypes.bool,
    dataTestId: PropTypes.string,
    disableOptions: PropTypes.bool,
};

TagInput.defaultProps = {
    ariaLabel: '',
    onTagRemoved: () => {},
    onTagAdded: () => {},
    onChange: () => {},
    suggestions: [],
    disabled: false,
    tags: [],
    placeholder: 'STATIC.PLACEHOLDERS.SELECT_FILTER',
    containerStyle: {},
    className: '',
    tagLockAlt: '',
    error: null,
    tagError: null,
    virtualScrolling: false, // Note: virtual scrolling will not work properly when there are grouped items.
    creatable: false,
    openMenuOnClick: true,
    formatCreateLabel: () => {},
    isValidNewOption: undefined,
    allowPasteInput: false,
    hideDropdownIndicator: true,
    disableOptions: false,
};

export default TagInput;
