import { ModelKinds } from '../../../../consts';
import RevenueType from '../RevenueType';
import MixedType from '../MixedType';
import EventsType from '../EventsType';
import FunnelType from '../FunnelType';

const MODEL_COMPONENTS = {
    [ModelKinds.REVENUE]: RevenueType,
    [ModelKinds.MIXED]: MixedType,
    [ModelKinds.CONVERSION_EVENTS]: EventsType,
    [ModelKinds.FUNNEL]: FunnelType,
    [ModelKinds.ENGAGEMENT]: EventsType,
};

export const getModelComponent = modelKind => {
    return MODEL_COMPONENTS[modelKind];
};

export const SUPPORTED_MODEL_KINDS = Object.keys(MODEL_COMPONENTS);
