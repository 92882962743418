import update from 'immutability-helper';
import { normalize } from 'normalizr';

import { UPDATE_APPS_DATA, UPDATE_ATTRIBUTION_APPS_DATA, UPDATE_FAVOURITE_APPS_FOR_USER } from '../actions/apps';
import { appsSchema, attributionAppsSchema } from '../utils/apps';

const initialState = {
    attributionAppsData: {},
    appsData: {},
    favouriteApps: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ATTRIBUTION_APPS_DATA: {
            const normalized = normalize(action.data.apps, attributionAppsSchema);
            return update(state, {
                attributionAppsData: {
                    $set: normalized,
                },
            });
        }
        case UPDATE_APPS_DATA: {
            const normalized = normalize(action.data.apps, appsSchema);
            return update(state, {
                appsData: {
                    $set: normalized,
                },
            });
        }
        case UPDATE_FAVOURITE_APPS_FOR_USER: {
            const favouriteApps = action.apps;
            return update(state, {
                favouriteApps: {
                    $set: favouriteApps,
                },
            });
        }
        default:
            return state;
    }
};
