import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';
import Tooltip from './Tooltip';
import css from './TableCellWithSubTextAndIcon.css';

const TableCellWithSubTextAndIcon = ({ elements, className }) => (
    <td>
        <div className={classNames(css.tableCell, className)}>
            {elements.map(element => (
                <>
                    {element?.prefixIcons?.length &&
                        element.prefixIcons.map(e => {
                            return typeof e.elem === 'function'
                                ? React.createElement(e.elem, { className: e.className, key: e.elem })
                                : e.elem;
                        })}
                    <Tooltip
                        titleTranslationKey={element.cellTooltipKey}
                        titleTranslationData={element.cellTooltipData}
                        title={element.cellTooltip}
                        disabled={!(element.cellTooltip || element.cellTooltipKey)}
                        key={element.title}
                    >
                        <div className={classNames(css.labels, element.containerClassName)} key={element.title}>
                            <div className={element.titleClassName}>
                                <Tooltip
                                    titleTranslationKey={element.headerTooltipKey}
                                    titleTranslationData={element.headerTooltipData}
                                    title={element.headerTooltip}
                                    disabled={!(element.headerTooltip || element.headerTooltipKey)}
                                >
                                    <Highlighter
                                        highlightClassName={css.highlighted}
                                        searchWords={[element.filter]}
                                        textToHighlight={element.title || ''}
                                        autoEscape
                                    />
                                </Tooltip>
                            </div>
                            <div className={classNames(css.subTextContainer, element.subTextClassName)}>
                                <Tooltip
                                    titleTranslationKey={element.subTextTooltipKey}
                                    titleTranslationData={element.subTextTooltipData}
                                    title={element.subTextTooltip}
                                    disabled={!(element.subTextTooltip || element.subTextTooltipKey)}
                                >
                                    <Highlighter
                                        highlightClassName={css.highlighted}
                                        searchWords={[element.filter]}
                                        textToHighlight={element.subText || ''}
                                        autoEscape
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </Tooltip>
                </>
            ))}
        </div>
    </td>
);

TableCellWithSubTextAndIcon.propTypes = {
    elements: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            titleClassName: PropTypes.string,
            subText: PropTypes.string,
            filter: PropTypes.string,
            containerClassName: PropTypes.string,
            subTextClassName: PropTypes.string,
            prefixIcons: PropTypes.arrayOf(PropTypes.any),
        })
    ),
    className: PropTypes.string,
};
TableCellWithSubTextAndIcon.defaultProps = {
    elements: [],
    className: null,
};
export default TableCellWithSubTextAndIcon;
