import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Homepage from './homepage';
import OnboardingGuide from '../onboardingGuide/onboardingGuide';

function HomepageGeneric(props) {
    const { apsalarEnabled } = props;
    return apsalarEnabled ? <OnboardingGuide {...props} /> : <Homepage {...props} />;
}

HomepageGeneric.propTypes = {
    apsalarEnabled: PropTypes.bool.isRequired,
};

export default withLocalize(HomepageGeneric);
