import { useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { isObjectEmpty } from './general';

export const useLocalStorageState = (
    key,
    defaultValue = '',
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) => {
    const [state, setState] = useState(() => {
        const valueInLocalStorage = window.localStorage.getItem(key);
        if (valueInLocalStorage) {
            return deserialize(valueInLocalStorage);
        }
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            window.localStorage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        window.localStorage.setItem(key, serialize(state));
    }, [key, state, serialize]);

    return [state, setState];
};

export const useScrollingIntoView = (dependencies = [], scrollRef) => {
    useEffect(() => {
        const element = scrollRef.current;
        const isDeps = !!dependencies.find(dep => !isObjectEmpty(dep));

        if (element && isDeps) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, dependencies);
};

export const usePollingEffect = (asyncCallback, dependencies = [], { interval = 10000, onCleanUp = () => {} } = {}) => {
    const [dead, kill] = useState(true);
    const timeoutIdRef = useRef(null);

    const forceCallback = async () => {
        await asyncCallback();
    };

    useEffect(() => {
        let stopped = false;
        if (dead) {
            return;
        }
        (async function pollingCallback() {
            try {
                await asyncCallback();
            } finally {
                // Set timeout after it finished, unless stopped
                timeoutIdRef.current = !stopped && setTimeout(pollingCallback, interval);
            }
        })();
        // Clean up if dependencies change
        return () => {
            stopped = true; // prevent racing conditions
            clearTimeout(timeoutIdRef.current);
            onCleanUp();
        };
    }, [...dependencies, interval, dead]);

    return {
        isPollingStopped: dead,
        killPoll: () => kill(true),
        respawnPoll: () => kill(false),
        forceCallback,
    };
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
};

export const useRequest = (fetchFunction, validateRequest = () => true, onCleanup = () => true, ...args) => {
    const [response, setResponse] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                setResponse(await fetchFunction(...args));
            } catch (responseError) {
                setError(responseError);
            } finally {
                setIsLoading(false);
            }
        };
        validateRequest() && fetchData();
        return onCleanup;
    }, args);
    return { ...response, error, isLoading };
};

const DEFAULT_OUTSIDE_EVENTS = [{ name: 'mousedown' }, { name: 'scroll', useCapture: true }];

export const useOutsideEventHandler = (ref, handlerFunc, eventTypes = DEFAULT_OUTSIDE_EVENTS) => {
    useEffect(() => {
        function handleEventOutside(event) {
            const refs = (Array.isArray(ref) ? ref : [ref]).filter(({ current }) => !!current);
            if (refs.length && !refs.some(currRef => currRef.current.contains(event.target))) {
                handlerFunc();
            }
        }
        // Bind the event listener
        for (const eventType of eventTypes) {
            const useCapture = eventType.useCapture || false;
            document.addEventListener(eventType.name, handleEventOutside, useCapture);
        }
        return () => {
            // Unbind the event listener on clean up
            for (const eventType of eventTypes) {
                const useCapture = eventType.useCapture || false;
                document.removeEventListener(eventType.name, handleEventOutside, useCapture);
            }
        };
    }, [handlerFunc, ref, eventTypes]);
};

export function useFetch(fetchFunction) {
    const [response, setResponse] = useState({});

    const fetch = (...args) => {
        const fetchData = async () => {
            setResponse({ isLoading: true });
            try {
                const data = await fetchFunction(...args);
                setResponse({ data, isLoading: false });
            } catch (error) {
                setResponse({ error, isLoading: false });
            }
        };

        fetchData();
    };

    return [response, fetch];
}

// fix the Warning - cannot perform a React state update on an unmounted component
export const useSafeDispatch = dispatch => {
    const mountedRef = useRef(false);

    useLayoutEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, [dispatch]);

    return useCallback(
        (...args) => {
            if (mountedRef.current) {
                dispatch(...args);
            }
        },
        [dispatch]
    );
};

export const useAsync = (initialState = {}) => {
    const [state, unsafeSetState] = useState({
        isLoading: null,
        data: null,
        error: null,
        ...initialState,
    });

    const setState = useSafeDispatch(unsafeSetState);

    const run = useCallback(
        async promise => {
            setState({ isLoading: true });
            try {
                const data = await promise;
                setState({ data, isLoading: false });
            } catch (error) {
                setState({ error, isLoading: false });
            }
        },
        [setState]
    );

    return [state, run];
};

export const useGridResize = () => {
    const [width, setWidth] = useState(null);
    const onGridResize = useCallback(
        size => {
            if (width !== size.width) {
                setWidth(size.width);
            }
        },
        [width]
    );
    return [width, onGridResize];
};
