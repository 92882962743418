import { ParameterTypes, PostbackWindowStartingPoint, SupportTypes, TemplateTypes } from '../types';
import { PARTNER_CONFIGURATION_EVENT_PREFIX, trackMixpanelEvent } from '../../../utils/general';

export const trackPartnerConfigMixpanelEvent = (eventName, partner, site = null, params = null) => {
    trackMixpanelEvent(PARTNER_CONFIGURATION_EVENT_PREFIX, eventName, {
        ...(partner ? { partnerName: partner.displayName } : {}),
        ...(site ? { platform: site.platform } : {}),
        ...(params || {}),
    });
};

export const getUsedParameters = (partner, platform, templateType, parameterTypes) => {
    const supportedParametersIds = new Set(
        partner.postbackTemplates
            .filter(template => template.platform === platform && template.type === templateType)
            .map(({ parameters }) => parameters)
            .flat()
    );

    return partner.parameters.filter(({ id, type }) => supportedParametersIds.has(id) && parameterTypes.includes(type));
};

const parseAttributionPostbackToServer = postback => {
    if (!postback?.selected) return null;

    const { sendAll, sendViewThrough, campaignRegex, postbackUri, customByUser } = postback;

    return {
        sendAll,
        sendViewThrough,
        campaignRegex: sendAll ? null : campaignRegex,
        postbackUri: customByUser ? postbackUri : null,
        customByUser,
    };
};

const parseEventPostbackToServer = postback => {
    const {
        eventId,
        eventName: _,
        postbackWindow,
        postbackWindowStartingPoint,
        sendAll,
        sendRevenue,
        campaignRegex,
        postbackUri,
        customByUser,
        disabled: __,
        ...params
    } = postback;

    return {
        eventId,
        sendAll,
        installWindow: postbackWindowStartingPoint === PostbackWindowStartingPoint.INSTALL ? postbackWindow : null,
        clickWindow: postbackWindowStartingPoint === PostbackWindowStartingPoint.TOUCHPOINT ? postbackWindow : null,
        parameters: params,
        sendRevenue,
        campaignRegex: sendAll ? null : campaignRegex,
        postbackUri: customByUser ? postbackUri : null,
        customByUser,
    };
};

export const parsePartnerConfigurationToServer = (partner, site, partnerConfiguration, agencyId) => {
    const {
        site: _,
        install,
        reengagement,
        fraudEnabled,
        customEventPostbacks,
        revenueEventPostbacks,
        geoRestrictions,
        restrictLds,
        ldsDefault,
        anonymizePostbacks,
        windows,
        ...parameters
    } = partnerConfiguration;

    return {
        partner: partner.id,
        longname: site.longname,
        platform: site.platform,
        ldsOptions: {
            restrictLds,
            ldsDefault,
            anonymizePostbacks,
        },
        parameters,
        postbacks: {
            fraudEnabled,
            install: parseAttributionPostbackToServer(install),
            reengagement: parseAttributionPostbackToServer(reengagement),
            customEvents: customEventPostbacks?.map(parseEventPostbackToServer) || [],
            revenueEvents: revenueEventPostbacks?.map(parseEventPostbackToServer) || [],
        },
        geoRestrictions,
        windows,
        agencyId,
    };
};

const parseAttributionPostbackFromServer = postback => {
    if (!postback) return { selected: false };

    const { sendAll, sendViewThrough, campaignRegex, postbackUri, customByUser } = postback;

    return {
        selected: true,
        sendAll,
        sendViewThrough,
        campaignRegex,
        postbackUri,
        customByUser,
    };
};

const parseEventPostbackFromServer = (postback, eventTemplateParams) => {
    if (!postback) return null;

    const {
        eventId,
        eventName,
        installWindow,
        clickWindow,
        sendAll,
        parameters,
        sendRevenue,
        campaignRegex,
        postbackUri,
        customByUser,
    } = postback;

    let postbackWindow = null;
    let postbackWindowStartingPoint = PostbackWindowStartingPoint.UNLIMITED;

    if (installWindow) {
        postbackWindow = installWindow;
        postbackWindowStartingPoint = sendAll ? postbackWindowStartingPoint : PostbackWindowStartingPoint.INSTALL;
    } else if (clickWindow) {
        postbackWindow = clickWindow;
        postbackWindowStartingPoint = sendAll ? postbackWindowStartingPoint : PostbackWindowStartingPoint.TOUCHPOINT;
    }

    // if we have defined pb param without value, add it
    const templateParameters = eventTemplateParams?.reduce((previousParam, nextParam) => {
        return { ...previousParam, ...(!parameters.hasOwnProperty(nextParam.name) && { [nextParam.name]: null }) };
    }, {});

    return {
        eventId,
        eventName,
        sendAll,
        postbackWindow,
        postbackWindowStartingPoint,
        sendRevenue,
        campaignRegex,
        postbackUri,
        customByUser,
        ...parameters,
        ...templateParameters,
    };
};

export const parsePartnerConfigurationFromServer = (partnerConfiguration, partner, platform) => {
    const { siteId, parameters, postbacks, geoRestrictions, ldsOptions, windows } = partnerConfiguration;
    const { install, reengagement, revenueEvents, customEvents, fraudEnabled } = postbacks;

    const revenueTemplateParams = partner
        ? getUsedParameters(partner, platform, TemplateTypes.REVENUE, [ParameterTypes.POSTBACK])
        : [];
    const customTemplateParams = partner
        ? getUsedParameters(partner, platform, TemplateTypes.CUSTOM, [ParameterTypes.POSTBACK])
        : [];

    return {
        site: siteId,
        ...(parameters || {}),
        install: parseAttributionPostbackFromServer(install),
        reengagement: parseAttributionPostbackFromServer(reengagement),
        customEventPostbacks: customEvents?.length
            ? customEvents.map(event => parseEventPostbackFromServer(event, customTemplateParams))
            : null,
        revenueEventPostbacks: revenueEvents?.length
            ? revenueEvents.map(event => parseEventPostbackFromServer(event, revenueTemplateParams))
            : null,
        fraudEnabled,
        geoRestrictions,
        restrictLds: ldsOptions.restrictLds,
        ldsDefault: ldsOptions.ldsDefault,
        anonymizePostbacks: ldsOptions.anonymizePostbacks,
        windows,
    };
};

export const getSendAllEventsTooltip = (sendAllSupport, isReadonly) => {
    if (sendAllSupport === SupportTypes.MANDATORY && !isReadonly)
        return 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SEND_ALL_EVENTS_MANDATORY';
    if (sendAllSupport === SupportTypes.NEVER && !isReadonly)
        return 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SEND_ALL_EVENTS_NOT_SUPPORTED';

    return null;
};

export const isDisabled = (value, readOnly) =>
    value === SupportTypes.NEVER || value === SupportTypes.MANDATORY || readOnly;

export const orgSupportAdvancedPostbacksFeatures = orgOptions => {
    const {
        trackerCampaignNameFilterSupported = false,
        advancedPostbackEditingSupported = false,
        flexiblePostbackSupported = false,
    } = orgOptions;
    return trackerCampaignNameFilterSupported || advancedPostbackEditingSupported || flexiblePostbackSupported;
};

export const getTemplate = (partner, sitePlatform, templateType) =>
    partner.postbackTemplates.find(({ type, platform }) => type === templateType && platform === sitePlatform);

export const getPostbackSupported = (installTemplate, postbackSupportType) => {
    return installTemplate ? postbackSupportType : SupportTypes.NEVER;
};
