import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <span>Now that you’ve integrated the SDK, you can start setting up your campaigns. In the next steps, you will:</span>
            </p>
            <ul>
                <li>
                    <span>Configure Google Ads and Facebook attribution settings</span>
                </li>
                <li>
                    <span>Configure attribution settings for any other partners</span>
                </li>
                <li>
                    <span>Generate tracking links</span>
                </li>
            </ul>
            <p>
                <a href="https://support.singular.net/hc/en-us/articles/115000526963-Introduction-to-Singular-Attribution" target="_blank">Learn more about Singular attribution</a>
            </p>
        </div>
    );
}
