import update from 'immutability-helper';
import {
    SHOW_PASSWORD_MODAL,
    HIDE_PASSWORD_MODAL,
    UPDATE_ANONYMOUS_ID,
    UPDATE_REPORT_NAME,
    GENERATE_ANONYMOUS_LIST,
    TOGGLE_DATE_SELECTION,
    UPDATE_CREATOR,
    UPDATE_PASSWORD,
    UPDATE_ANONYMOUS_ERROR,
} from '../actions/anonymous';

const initialState = {
    password: '',
    secretId: '',
    creator: '',
    reportName: '',
    error: '',
    showPasswordModal: false,
    showDateSelection: false,
    anonymousList: [],
};

const anonymous = (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_ANONYMOUS_LIST: {
            return update(state, {
                anonymousList: {
                    $set: action.anonymousList,
                },
            });
        }
        case SHOW_PASSWORD_MODAL: {
            return update(state, {
                showPasswordModal: {
                    $set: true,
                },
            });
        }
        case UPDATE_REPORT_NAME: {
            return update(state, {
                reportName: {
                    $set: action.reportName,
                },
            });
        }
        case HIDE_PASSWORD_MODAL: {
            return update(state, {
                showPasswordModal: {
                    $set: false,
                },
            });
        }
        case UPDATE_ANONYMOUS_ID: {
            return update(state, {
                secretId: {
                    $set: action.secretId,
                },
            });
        }
        case UPDATE_CREATOR: {
            return update(state, {
                creator: {
                    $set: action.creator,
                },
            });
        }
        case TOGGLE_DATE_SELECTION: {
            return update(state, {
                showDateSelection: {
                    $set: action.show || !state.showDateSelection,
                },
            });
        }
        case UPDATE_PASSWORD: {
            return update(state, {
                password: {
                    $set: action.password,
                },
            });
        }
        case UPDATE_ANONYMOUS_ERROR: {
            return update(state, {
                error: {
                    $set: action.msg,
                },
            });
        }
        default:
            return state;
    }
};

export default anonymous;
