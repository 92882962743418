// see https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/

import React, { createContext, useState } from 'react';

export const store = createContext({});
const initialState = {
    global: true,
    usage: [],
};

// eslint-disable-next-line react/prop-types
export const StateProvider = ({ children }) => {
    const [state, setState] = useState(initialState);
    return <store.Provider value={{ state, setState }}>{children}</store.Provider>;
};

// const StateProvider = ({ children }) => {
//     const [state, dispatch] = useReducer((_state, action) => {
//         switch (action.type) {
//             case 'action description':
//                 return _state;
//             // const newState = // do something with the action
//             // return newState;
//             default:
//                 throw new Error();
//         }
//     }, initialState);
//
//     return <store.Provider value={{ state, dispatch }}>{children}</store.Provider>;
// };
