import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MESSAGE_ONCLICK_SUFFIX, MESSAGE_ONCLICK_INNER_PREFIX, MESSAGE_ONCLICK_INNER_SUFFIX } from '../../utils/consts';
import css from './TableCell.css';
import TooltipIcon from '../../resources/svg/tooltip.svg';
import Tooltip from './Tooltip';
import CellLoader from './CellLoader';

const createClickableTag = (clickableText, onClick) => <a onClick={onClick}> {clickableText} </a>;

export const getClickableText = (cellValue, onTextClick, row) => {
    const clickableActionsRegex = new RegExp(`(.+?${MESSAGE_ONCLICK_SUFFIX})`, 'g');
    const clickableActionsGroups = cellValue.match(clickableActionsRegex);

    return (
        clickableActionsGroups &&
        clickableActionsGroups.map(groupValue => {
            const clickablePropsRegex = new RegExp(
                `(.*)${MESSAGE_ONCLICK_INNER_PREFIX}(.+)${MESSAGE_ONCLICK_INNER_SUFFIX}(.+)${MESSAGE_ONCLICK_SUFFIX}(.*)`
            );
            const result = clickablePropsRegex.exec(groupValue);
            if (onTextClick && result) {
                const preOnClickText = result[1];
                const action = result[2];
                const clickableText = result[3];
                const postOnClickText = result[4];
                return (
                    <span key={groupValue}>
                        {preOnClickText}
                        {createClickableTag(clickableText, () => onTextClick(action, row))}
                        {postOnClickText}
                    </span>
                );
            }
            return null;
        })
    );
};

export const formatCell = (cellValue, displayFormat) => {
    if (cellValue === undefined || cellValue === null || cellValue === 'nan') {
        cellValue = 'N/A';
    }

    if (displayFormat && cellValue !== 'N/A' && cellValue !== '') {
        switch (displayFormat.type) {
            case 'number':
                return cellValue.toLocaleString(undefined, {
                    minimumFractionDigits: displayFormat.precision,
                    maximumFractionDigits: displayFormat.precision,
                });
            case 'currency':
                return `${displayFormat.symbol}${cellValue.toLocaleString(undefined, {
                    minimumFractionDigits: displayFormat.precision,
                    maximumFractionDigits: displayFormat.precision,
                })}`;
            case 'percentage':
                return `${(cellValue * 100).toFixed(displayFormat.precision)}%`;
            default:
                return cellValue;
        }
    } else {
        return displayFormat ? displayFormat.NAValue || cellValue : cellValue;
    }
};

const TableCell = ({
    className,
    containerStyle,
    color,
    value,
    tdStyle,
    format,
    onTextClick,
    row,
    rightCellElement,
    colSpan,
    tooltipText,
    disabled,
    isLoading,
    containerClassName,
    dataTestId,
    loaderClass,
}) => {
    const style = {
        padding: '5px 0',
        display: 'inline-block',
        float: format && ['number', 'currency', 'percentage'].includes(format.type) ? 'right' : null,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    };
    const clickableCellText = value && getClickableText(value, onTextClick, row);
    return (
        <td
            className={className}
            style={{
                ...containerStyle,
                ...tdStyle,
                color,
            }}
            colSpan={colSpan}
            data-testid={dataTestId}
        >
            <div
                className={classNames(containerClassName, {
                    [css.tableCellWithRightElement]: rightCellElement,
                    [css.disabled]: disabled,
                })}
            >
                {isLoading ? (
                    <CellLoader loaderClass={loaderClass} />
                ) : (
                    <>
                        {clickableCellText ? (
                            <span style={style}> {clickableCellText} </span>
                        ) : (
                            <span
                                style={style}
                                dangerouslySetInnerHTML={{ __html: formatCell(value, format, onTextClick) }}
                            />
                        )}
                        {rightCellElement ? (
                            <div className={css.rightAreaSection}>
                                {rightCellElement.icon}
                                <span className={css.rightAreaText}>{rightCellElement.text}</span>
                            </div>
                        ) : null}
                        {tooltipText && (
                            <Tooltip titleTranslationKey={tooltipText}>
                                <TooltipIcon className={css.tooltipIcon} />
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </td>
    );
};

TableCell.propsTypes = {
    disabled: PropTypes.bool,
    onTextClick: PropTypes.func,
    rightCellElement: PropTypes.objectOf(
        PropTypes.shape({
            text: PropTypes.string,
            icon: PropTypes.element,
        })
    ),
    containerClassName: PropTypes.string,
    dataTestId: PropTypes.string,
    loaderClass: PropTypes.string,
};

TableCell.defaultProps = {
    disabled: false,
    tdStyle: {},
    format: null,
    onTextClick: null,
    rightCellElement: null,
    containerClassName: '',
    dataTestId: '',
};

export default TableCell;
