export const arrayToDict = (array, mappingFunc) => Object.assign({}, ...array.map(mappingFunc));

export const createArrayOfObjectsFromKeys = (keys, getObjByKey) => {
    return keys.reduce((acc, key) => {
        acc.push(getObjByKey(key));
        return acc;
    }, []);
};

export const listEqual = (list1, list2) => {
    // This is a deep check as the lists might contain lists
    if (list1.length !== list2.length) return false;
    return list1.every((value, index) => value === list2[index]);
};
