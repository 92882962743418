import Papa from 'papaparse';
import { getImpersonationFromQuery } from './url';

const CSV_SUFFIX = '.csv';

function getExportDatesStr(dates, isCompare) {
    const periods = [];
    periods.unshift(`${dates.start_date}-${dates.end_date}`);
    if (isCompare) {
        periods.unshift(`${dates.start_date_2}-${dates.end_date_2}`);
    }
    return periods.join(' vs ');
}

export const getExportWidgetFileName = (widget_name, dates, isCompare) => {
    const dates_str = getExportDatesStr(dates, isCompare);

    return `${widget_name} ${dates_str}${CSV_SUFFIX}`;
};

export const getExportFileName = (timeBreakdown, dates, isCompare) => {
    let timeBreakdownUse = timeBreakdown;
    timeBreakdownUse = timeBreakdownUse === 'all' || timeBreakdownUse === 'day' ? 'dai' : timeBreakdownUse;

    const dates_str = getExportDatesStr(dates, isCompare);

    return `Advertiser ${timeBreakdownUse}ly report ${dates_str}${CSV_SUFFIX}`;
};

export function openExportLogsWatchWindow(downloadWindow, username, logType, apiType, taskId, snowflake) {
    let url = `/logs/download?log_type=${logType}&api_type=${apiType}&task_id=${taskId}&snowflake=${snowflake}`;
    const impersonationUsername = getImpersonationFromQuery();
    if (impersonationUsername) {
        url = `${url}&user=${decodeURIComponent(impersonationUsername)}`;
    }
    const impersonatedOrganization = getImpersonationFromQuery('customer_id');
    if (impersonatedOrganization) {
        url = `${url}&customer_id=${impersonatedOrganization}`;
    }

    if (!downloadWindow) {
        return window.open(url, '_blank');
    } else {
        downloadWindow.location.href = url;
        return downloadWindow;
    }
}

export const download = (fileName, data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.click();
};

export const convertToCsv = (fields, data) => Papa.unparse({ fields, data });
