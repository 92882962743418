import React from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './CreativeCardData.css';
import { CreativeMultiValuedDimensionsType, CreativeType } from '../../types';
import { MetricsMap } from '../../metricsUtils';
import OSIcon from '../OSIcon/OSIcon';
import ImpressionsIcon from '../../../resources/icons/metrics/impressions.svg';
import ClicksIcon from '../../../resources/icons/metrics/clicks.svg';
import InstallsIcon from '../../../resources/icons/metrics/installs.svg';
import CostIcon from '../../../resources/icons/metrics/cost.svg';
import { AutoCompleteOptionType } from '../../../components/widgets/AutoCompleteField';
import { getFormattingFunction } from './metricDisplayUtils';

export const DIMENSIONS_LOADING_SKELETON_TEST_ID = 'loading-dimensions-skeleton';

const MAX_METRICS_IN_ROW = 3;

export const METRIC_ICONS = {
    [MetricsMap.CUSTOM_IMPRESSIONS.value]: ImpressionsIcon,
    [MetricsMap.CUSTOM_CLICKS.value]: ClicksIcon,
    [MetricsMap.CUSTOM_INSTALLS.value]: InstallsIcon,
    [MetricsMap.COST.value]: CostIcon,
};

const SMALL_METRIC_ICONS = [MetricsMap.CUSTOM_CLICKS.value, MetricsMap.CUSTOM_INSTALLS.value, MetricsMap.COST.value];

const CreativeCardData = ({ osList, metrics, isLoadingDimensions, selectedMetrics }) => {
    return (
        <div className={css.container}>
            <div className={css.metrics} style={{ gridTemplateColumns: `repeat(${MAX_METRICS_IN_ROW}, 1fr)` }}>
                {selectedMetrics.map(({ value, label }) => {
                    const MetricIcon = METRIC_ICONS[value];
                    const metricValue = metrics[value];
                    const formattingFunction = getFormattingFunction(value);
                    const formattedValue = formattingFunction(metricValue);
                    const formattedFullValue = formattingFunction(metricValue, true);

                    return (
                        <Tooltip key={value} title={`${label}: ${formattedFullValue}`}>
                            <div className={css.metric}>
                                {MetricIcon ? (
                                    <MetricIcon
                                        title={label}
                                        className={classNames(css.metricIcon, {
                                            [css.smallIcon]: SMALL_METRIC_ICONS.includes(value),
                                        })}
                                    />
                                ) : (
                                    <div className={css.metricLabel}>{label}:</div>
                                )}
                                <div>{formattedValue}</div>
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
            <div className={css.osListContainer}>
                {isLoadingDimensions ? (
                    <Skeleton
                        variant="circular"
                        className={css.osIconContainer}
                        data-testid={DIMENSIONS_LOADING_SKELETON_TEST_ID}
                    />
                ) : (
                    osList.map(osValue => (
                        <div key={osValue} className={css.osIconContainer}>
                            <OSIcon osValue={osValue} className={css.osIcon} />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

CreativeCardData.propTypes = {
    osList: CreativeMultiValuedDimensionsType.osList,
    metrics: CreativeType.metrics,
    isLoadingDimensions: PropTypes.bool,
    selectedMetrics: PropTypes.arrayOf(AutoCompleteOptionType),
};

CreativeCardData.defaultProps = {
    metrics: {},
    osList: [],
    isLoadingDimensions: false,
    selectedMetrics: [],
};

export default CreativeCardData;
