import { useMemo } from 'react';
import { DataTypes, WIDGET_DATA_TYPES } from './widgetDataTypes';
import { sortAlphabeticallyByDisplayNameLowecase, sortAlphabeticallyByLabelLowecase } from '../utils/sortUtil';
import { FIELD_NAME_SEPARATOR, requestParams, TIME_BREAKDOWN_PSEUDO_DIMENSIONS } from './utils';
import { CPE_POSTFIX, ECVR_POSTFIX, FIELDS_TYPES, CROSS_DEVICE_ATTRIBUTION_OPTIONS } from './consts';

const SKAN_PERIOD_ID = -2;

function getFieldName(name, requestParam) {
    if (!requestParam) {
        return name;
    }

    return `${requestParam}${FIELD_NAME_SEPARATOR}${name}`;
}

function mapToGroupedOptions(optionGroups, hiddenFields) {
    return optionGroups.flatMap(({ name: groupName, displayName: groupDisplayName, sort, items, requestParam }) => {
        if (!items.length || (hiddenFields && hiddenFields.includes(groupName))) {
            return [];
        }

        const mappedItems = items.map(({ name: itemName, display_name: itemDisplayName, values }) => {
            const fieldName = getFieldName(itemName, requestParam);
            return {
                name: fieldName,
                display_name: itemDisplayName,
                values,
                value: fieldName,
                label: itemDisplayName,
                group: groupName,
            };
        });

        if (sort) {
            mappedItems.sort(sortAlphabeticallyByLabelLowecase);
        }

        mappedItems.unshift({
            name: groupName,
            display_name: groupDisplayName,
            value: groupName,
            label: groupDisplayName,
            divider: true,
            isDisabled: true,
        });

        return mappedItems;
    });
}

function createCohortMertics(metrics, periods) {
    return metrics.sort(sortAlphabeticallyByDisplayNameLowecase).flatMap(metric => {
        return periods.flatMap(period => {
            return {
                name: `${metric.name}__${period.name}`,
                display_name: `${metric.display_name} ${period.display_name}`,
                visible: true,
            };
        });
    });
}

function filterFields(fields, type, dataType, adminMode, userData) {
    return fields[type].filter(field => {
        field.actual_type = type;
        return dataType.visibility(field, adminMode, userData);
    });
}

const useParseDimsMetricsAndFilters = (apiFields, adminMode, userData, dataTypeName) => {
    const dataType = WIDGET_DATA_TYPES[dataTypeName];
    const allDimensions = useMemo(() => {
        if (!apiFields) {
            return [];
        }

        return mapToGroupedOptions(
            [
                {
                    name: FIELDS_TYPES.TIME_DIMENSIONS,
                    displayName: 'Time',
                    sort: false,
                    items: TIME_BREAKDOWN_PSEUDO_DIMENSIONS,
                },
                {
                    name: FIELDS_TYPES.DIMENSIONS,
                    displayName: 'Dimensions',
                    sort: true,
                    items: filterFields(apiFields, 'dimensions', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.TECHNICAL_SPECS_DIMENSIONS,
                    displayName: 'Technical Specs Dimensions',
                    sort: true,
                    items: filterFields(apiFields, 'technicalSpecsDimensions', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.SOURCE_DIMENSIONS,
                    displayName: 'Source Dimensions',
                    sort: true,
                    items: filterFields(apiFields, 'sourceDimensions', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.SKAN_CONVERSION_DIMENSIONS,
                    displayName: 'SKAN Conversion Dimensions',
                    sort: true,
                    items: filterFields(apiFields, 'skanConversionDimensions', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.CUSTOM_DIMENSIONS,
                    displayName: 'Custom Dimensions',
                    sort: true,
                    items: filterFields(apiFields, 'custom_dimensions', dataType, adminMode, userData),
                },
            ],
            dataType.hiddenFields
        );
    }, [apiFields, dataType]);

    const crossDeviceDataTypes = useMemo(() => {
        if (dataTypeName !== DataTypes.CROSS_DEVICE) {
            return [];
        }
        return mapToGroupedOptions(CROSS_DEVICE_ATTRIBUTION_OPTIONS);
    }, [dataType]);

    const unifiedDataTypes = useMemo(() => {
        if (!apiFields || dataTypeName !== DataTypes.UNIFIED) {
            return [];
        }

        return mapToGroupedOptions(
            [
                {
                    name: FIELDS_TYPES.UNIFIED_DATA_TYPES,
                    displayName: 'Data Type',
                    requestParam: requestParams.UNIFIED_DATA_TYPE,
                    sort: false,
                    items: filterFields(apiFields, 'dataTypeFields', dataType, adminMode, userData),
                },
            ],
            dataType.hiddenFields
        );
    }, [apiFields, dataType]);

    const allMetrics = useMemo(() => {
        if (!apiFields) {
            return [];
        }

        let { periods } = apiFields;
        const { unifiedCohortPeriods } = apiFields;
        const isSkanDataType = dataType.name === DataTypes.SKAN;
        const isSkanRawDataType = dataType.name === DataTypes.SKAN_RAW;
        const isUnifiedDataType = dataType.name === DataTypes.UNIFIED;
        let customEvents = [];
        let customEventsName;

        if (isSkanDataType) {
            periods = periods
                .filter(period => period.period_id === SKAN_PERIOD_ID)
                .map(period => {
                    return { ...period, display_name: '' };
                });
            customEventsName = 'SKAN Events';

            apiFields.skanCustomEvents.forEach(event => {
                if (!event.is_skan) {
                    return;
                }

                customEvents.push(event);
                customEvents.push({
                    ...event,
                    name: `${event.name}__${CPE_POSTFIX}`,
                    display_name: `${event.display_name} ${CPE_POSTFIX}`,
                });
                customEvents.push({
                    ...event,
                    name: `${event.name}__${ECVR_POSTFIX}`,
                    display_name: `${event.display_name} ${ECVR_POSTFIX}`,
                });
            });
        } else if (isSkanRawDataType) {
            customEvents = [];
        } else {
            customEventsName = 'Custom Events';
            customEvents = filterFields(apiFields, 'customEvents', dataType, adminMode, userData);
        }

        return mapToGroupedOptions(
            [
                {
                    name: FIELDS_TYPES.METRICS,
                    displayName: isUnifiedDataType ? 'Pre Install Metrics' : 'Metrics',
                    sort: true,
                    items: filterFields(apiFields, 'metrics', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.UNIFIED_METRIC_GROUP,
                    displayName: 'Metrics',
                    sort: false,
                    requestParam: requestParams.UNIFIED_METRIC_GROUP,
                    items: isUnifiedDataType
                        ? filterFields(apiFields, 'metricGroupFields', dataType, adminMode, userData)
                        : [],
                },
                {
                    name: FIELDS_TYPES.UNIFIED_COHORT_METRICS,
                    displayName: 'Cohort Metrics',
                    sort: false,
                    requestParam: requestParams.UNIFIED_COHORT_METRICS,
                    items: isUnifiedDataType
                        ? createCohortMertics(
                              filterFields(apiFields, 'cohortMetricGroupFields', dataType, adminMode, userData),
                              unifiedCohortPeriods
                          )
                        : [],
                },
                {
                    name: FIELDS_TYPES.APP_METRICS,
                    displayName: 'App Metrics',
                    sort: false,
                    items: filterFields(apiFields, 'appMetrics', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.SOURCE_METRICS,
                    displayName: isSkanDataType ? 'Tracker Metrics' : 'Source Metrics',
                    sort: false,
                    items: filterFields(apiFields, 'discrepancyMetrics', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.WEB_METRICS,
                    displayName: 'Web Metrics',
                    sort: false,
                    items: filterFields(apiFields, 'webMetrics', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.CROSS_DEVICE_METRICS,
                    displayName: 'Cross Device Metrics',
                    sort: false,
                    items: filterFields(apiFields, 'crossDeviceMetrics', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.COHORT_METRICS,
                    displayName: 'Cohort Metrics',
                    sort: false,
                    items: createCohortMertics(
                        filterFields(apiFields, 'cohortMetrics', dataType, adminMode, userData),
                        periods
                    ),
                },
                {
                    name: FIELDS_TYPES.CUSTOM_EVENTS,
                    displayName: customEventsName,
                    sort: false,
                    items: createCohortMertics(customEvents, periods),
                },
                {
                    name: FIELDS_TYPES.CONVERSION_EVENTS,
                    displayName: 'Conversion Events',
                    sort: false,
                    items: filterFields(apiFields, 'conversionEvents', dataType, adminMode, userData),
                },
                {
                    name: FIELDS_TYPES.SKAN_MODELED_METRICS,
                    displayName: 'Modeled Metrics',
                    sort: false,
                    items: (isSkanDataType || isSkanRawDataType
                        ? filterFields(apiFields, 'skanModeledMetrics', dataType, adminMode, userData)
                        : []
                    ).map(metric => {
                        return { ...metric, display_name: `Modeled ${metric.display_name}` };
                    }),
                },
                {
                    name: FIELDS_TYPES.SKAN_MODELED_EVENTS,
                    displayName: 'Modeled SKAN Events',
                    requestParam: requestParams.MODELED_SKAN_EVENTS,
                    sort: false,
                    items: filterFields(
                        apiFields,
                        'skanModeledCustomEvents',
                        dataType,
                        adminMode,
                        userData
                    ).map(event => ({ ...event, display_name: `Modeled ${event.display_name}` })),
                },
            ],
            dataType.hiddenFields
        );
    }, [apiFields, dataType]);

    const allFilters = useMemo(() => {
        if (!apiFields) {
            return [];
        }

        const availableFilters = filterFields(apiFields, 'filters', dataType, adminMode, userData);

        return mapToGroupedOptions(
            [
                {
                    name: FIELDS_TYPES.DIMENSIONS,
                    displayName: 'Dimensions',
                    sort: true,
                    items: availableFilters.filter(filter => !filter.dynamic_dimension),
                },
                {
                    name: FIELDS_TYPES.CUSTOM_DIMENSIONS,
                    displayName: 'Custom Dimensions',
                    sort: true,
                    items: availableFilters.filter(filter => filter.dynamic_dimension),
                },
            ],
            dataType.hiddenFields
        );
    }, [apiFields, dataType]);

    return [allDimensions, allMetrics, allFilters, unifiedDataTypes, crossDeviceDataTypes];
};

export default useParseDimsMetricsAndFilters;
