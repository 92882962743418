import PropTypes from 'prop-types';
import React from 'react';
import { DropdownField, TextField } from '../../../../../components/widgets';

const SegmentField = ({ field, onChange, fieldValue, disabled }) => {
    switch (field.ui_type) {
        case 'dropdown':
            return (
                <DropdownField
                    key={field.name}
                    disabled={disabled}
                    label={field.display}
                    items={field.choices.map(c => ({
                        name: c.name,
                        display_name: c.display,
                    }))}
                    selected={fieldValue || null}
                    onChange={value => onChange(field.name, value)}
                />
            );
        case 'text':
        case 'secret':
        default:
            return (
                <TextField
                    key={field.name}
                    label={field.display}
                    disabled={disabled}
                    debounce={400}
                    value={fieldValue || ''}
                    password={field.ui_type === 'secret'}
                    onChange={value => onChange(field.name, value)}
                    autoCompleteOff
                    id={`${field.name}_field`}
                />
            );
    }
};

SegmentField.propTypes = {
    field: PropTypes.shape({
        ui_type: PropTypes.oneOf(['text', 'secret', 'dropdown']),
        name: PropTypes.string,
        choices: PropTypes.arrayOf(
            PropTypes.shape({
                display: PropTypes.string,
                name: PropTypes.string,
            })
        ),
        display: PropTypes.string,
    }),
    onChange: PropTypes.func,
    fieldValue: PropTypes.string,
    disabled: PropTypes.bool,
};

export default SegmentField;
