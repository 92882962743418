import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <span>Use our API to pull your marketing data. You need an API key, which you can retrieve from the </span><a
                href="https://app.singular.net/#/react/api" target="_blank"><span
            >Singular API console</span></a><span>. Then review the API documentation in the </span><a
                href="https://developers.singular.net/v2.0/reference#reporting"
                target="_blank"><span>developer’s portal</span></a><span
            >.&nbsp;</span>
            </p>
            <p>
                <strong>Tip</strong><span>: To ensure you get your data promptly, set up your queries with daily filters and any other filters (like app, platform, country, etc) to reduce the amount of data returned in one call.&nbsp;</span>
            </p>
            <p>
                <span>You can also review the status of your data by querying the data availability endpoint. This endpoint will determine whether for a given day, data is available for each of your data sources. This data can then be used to determine whether to pull data.</span>
            </p>
        </div>
    );
}
