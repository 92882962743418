import { getEventsRevenueColumnDef } from '../customTableUtils';
import css from '../../modelTypeForms/RevenueConversionModelForm.css';
import {
    COARSE_VALUE_UNUSED,
    COARSE_VALUES_WITH_UNUSED,
    DECIMAL_PLACES,
    FINE_BUCKET_DEFAULT_SIZE,
} from '../../../consts';
import { getCoarseValueColumnDef } from '../ConversionModelShelfStep1CoarseTable';
import { getCoarseValueByIndex } from '../../../utils';
import { getTotalRevenueColumnDef } from './RevenueTable';
import TableCellText from '../../../../components/widgets/TableCellText';

const getStaticFineValueColumnDef = actionsConfig => ({
    displayName: `STATIC.PAGES.SKADNETWORK.VALUE`,
    className: css.tableHeader,
    headerAlign: 'center',
    name: 'fineValue',
    headerWidth: actionsConfig ? '200px' : '55px',
    cellComponent: TableCellText,
    cellValues: ['isEditable', 'index'],
    cellProps: {
        className: css.tableCellCenter,
        showRowActions: !!actionsConfig,
        ...(actionsConfig || {}),
        tdStyle: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            height: '45px',
        },
    },
});

const scrollElementDown = element => {
    setTimeout(() => {
        if (element) {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth',
            });
        }
    });
};

const onAddRow = (rowIndex, apsalarEventIds, buckets, customMapping, setValues, multiCoarseMapping) => {
    const newEvents = [...apsalarEventIds];
    const newBuckets = [...buckets];
    const newCustomMapping = { ...customMapping };

    if (newBuckets.length) {
        const lastBucket = newBuckets[newBuckets.length - 1];
        newBuckets.push(lastBucket + FINE_BUCKET_DEFAULT_SIZE);
    } else {
        newEvents.push(null);
    }

    const previousIndex = rowIndex.toString();
    const currentIndex = (rowIndex + 1).toString();

    newCustomMapping[currentIndex] = newCustomMapping[previousIndex];

    if (!multiCoarseMapping) {
        newCustomMapping[currentIndex] = COARSE_VALUE_UNUSED.toString();
    }

    setValues({
        customMapping: newCustomMapping,
        buckets: newBuckets,
        apsalarEventIds: newEvents,
    });
};

const onDeleteRow = (rowIndex, apsalarEventIds, buckets, customMapping, setValues) => {
    const newCustomMapping = {};

    Object.entries(customMapping).forEach(([cvIndex, coarseValue]) => {
        let currIndex = parseInt(cvIndex, 10);

        if (rowIndex === currIndex) {
            return;
        } else if (currIndex > rowIndex) {
            currIndex -= 1;
        }

        newCustomMapping[currIndex.toString()] = coarseValue;
    });

    const newEvents = [...apsalarEventIds];
    const newBuckets = [...buckets];

    if (rowIndex < newEvents.length) {
        newEvents.splice(rowIndex, 1);
    } else {
        const bucketIndex = buckets.length === 1 ? 0 : rowIndex - newEvents.length + 1;

        newBuckets.splice(bucketIndex, 1);
    }

    setValues({
        customMapping: newCustomMapping,
        buckets: newBuckets,
        apsalarEventIds: newEvents,
    });
};

export const getTableMetadata = (
    values,
    setValues,
    sdkEvents,
    shelfElement,
    multiCoarseMapping,
    maxConversionValue,
    isMultiStep
) => {
    const { apsalarEventIds = [], buckets = [], customMapping = {} } = values;
    const eventsAmount = apsalarEventIds.length;
    const bucketsAmount = buckets.length;
    const elementsAmount = eventsAmount + bucketsAmount;

    const actionsConfig = {
        getIsShowAdd: rowIndex => {
            return rowIndex === elementsAmount - 1;
        },
        getIsAddDisabled: () => {
            return apsalarEventIds[apsalarEventIds.length - 1] === null || elementsAmount === maxConversionValue;
        },
        getIsDeleteDisabled: rowIndex => {
            return elementsAmount <= 2 || (eventsAmount === 2 && bucketsAmount > 0 && rowIndex === eventsAmount - 1);
        },
        getIsShowDelete: rowIndex => {
            return (
                rowIndex !== 0 && (rowIndex !== elementsAmount - 1 || rowIndex < eventsAmount || bucketsAmount === 1)
            );
        },
        onAdd: rowIndex => {
            onAddRow(rowIndex, apsalarEventIds, buckets, customMapping, setValues, multiCoarseMapping);
            scrollElementDown(shelfElement);
        },
        onDelete: rowIndex => {
            onDeleteRow(rowIndex, apsalarEventIds, buckets, customMapping, setValues);
        },
        getSelected: value => {
            if (value) return value;

            return {
                name: COARSE_VALUE_UNUSED,
                display_name: COARSE_VALUES_WITH_UNUSED[COARSE_VALUE_UNUSED],
            };
        },
    };

    const staticFineValueColumnDef = getStaticFineValueColumnDef(isMultiStep ? null : actionsConfig);
    const eventsRevenueColumnDef = getEventsRevenueColumnDef(
        values,
        setValues,
        multiCoarseMapping,
        sdkEvents,
        FINE_BUCKET_DEFAULT_SIZE,
        true
    );
    const totalRevenueColumnDef = getTotalRevenueColumnDef(values, setValues, false, true);

    if (isMultiStep) {
        const coarseValueColumnDef = getCoarseValueColumnDef(
            values,
            setValues,
            multiCoarseMapping,
            true,
            actionsConfig
        );

        return [staticFineValueColumnDef, eventsRevenueColumnDef, totalRevenueColumnDef, coarseValueColumnDef];
    }

    return [eventsRevenueColumnDef, totalRevenueColumnDef, staticFineValueColumnDef];
};

const getDefaultRow = (index, currency, customMapping, customMappingErrors, isMultiStep) => {
    const translationArgs = { currency };

    return {
        index,
        translationArgs,
        fineValue: (isMultiStep ? index : index + 1).toString(),
        isEditable: true,
        coarseValue: getCoarseValueByIndex(customMapping, index),
        coarseValueError: customMappingErrors[index]?.errorCode,
    };
};

export const getTableData = (values, errors, multiCoarseMapping, isMultiStep) => {
    const { buckets, currency, apsalarEventIds, customMapping } = values;
    const { customMapping: customMappingErrors = {}, buckets: bucketsErrors = {} } = errors;

    const bucketData = buckets?.map(value => (value ? Number(parseFloat(value).toFixed(DECIMAL_PLACES)) : value)) || [];
    const eventsData = apsalarEventIds || [];

    const tableRows = [];
    let index = 0;

    tableRows.push({
        ...getDefaultRow(index, currency, customMapping, customMappingErrors, isMultiStep),
        coarseValueDisabled: multiCoarseMapping,
        isHidden: true,
    });

    eventsData
        .filter(e => !!e)
        .forEach(() => {
            index += 1;

            tableRows.push({
                ...getDefaultRow(index, currency, customMapping, customMappingErrors, isMultiStep),
                isHidden: true,
            });
        });

    bucketData.forEach((bucket, bucketIndex) => {
        index += 1;

        const from = bucket;
        const nextBucket = bucketData[bucketIndex + 1];
        const to = nextBucket === undefined ? Infinity : nextBucket;

        tableRows.push({
            ...getDefaultRow(index, currency, customMapping, customMappingErrors, isMultiStep),
            from,
            to,
            isHidden: false,
            isEditable: true,
            error: bucketsErrors[bucketIndex]?.errorMsg,
        });
    });

    if (eventsData.length > 1 && eventsData[eventsData.length - 1] === null) {
        index += 1;

        tableRows.push({
            ...getDefaultRow(index, currency, customMapping, customMappingErrors, isMultiStep),
            isHidden: true,
        });
    }

    tableRows[tableRows.length - 1].isEditable = true;

    return tableRows;
};
