import API from '../services/api';

const api = new API();
const UPGRADE_REQUEST_URL = 'api/upgrade_request';

export default class TopPanelService {
    updateUpgradeRequested(email, firstName, lastName, company, tier) {
        return api.getJson(UPGRADE_REQUEST_URL, {
            method: 'POST',
            body: {
                email,
                first_name: firstName,
                last_name: lastName,
                company,
                tier,
            },
        });
    }
}
