import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AutoCompleteField } from '../../components/widgets';
import css from './TopArea.css';
import { changeAppsFilter } from '../actions';
import { getSearching } from '../selectors';

const EMPTY_OPTIONS = [];

function TopArea({ search }) {
    const dispatch = useDispatch();
    const onAppFilterChanged = value => dispatch(changeAppsFilter(value));
    const searching = useSelector(state => getSearching(state));

    return (
        <div className={css.topArea}>
            <AutoCompleteField
                containerClass={css.appSearch}
                onInputChange={onAppFilterChanged}
                onChange={onAppFilterChanged}
                value={search}
                placeholder="STATIC.PAGES.BANNERS.APP_SEARCH_PLACEHOLDER"
                searchable
                clearable={!searching}
                controlled
                loading={searching}
                isMulti={false}
                debounceTime={500}
                options={EMPTY_OPTIONS}
            />
        </div>
    );
}

TopArea.propTypes = {
    search: PropTypes.string,
};

TopArea.defaultProps = {
    search: '',
};

export default TopArea;
