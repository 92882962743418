import update from 'immutability-helper';

const initialState = {
    app: undefined,
    source: '',
    customName: '',
    campaignName: '',
    ios: {
        label: 'iOS',
        fallback: '',
        deeplink: '',
        deferred: false,
        universalLink: '',
    },
    android: {
        label: 'Android',
        fallback: '',
        deeplink: '',
        deferred: false,
        universalLink: '',
    },
    web: {
        label: 'Other Platforms',
        fallback: '',
    },
    showError: false,
    errorMessage: '',
    trackingLink: '',
};

const createSmartLink = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default createSmartLink;
