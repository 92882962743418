import { ANDROID_NATIVE, SDK_INTEGRATION_SECTIONS, JAVA, KOTLIN } from '../../consts';
import { END_MARKDOWN, SDK_KEY_PLACEHOLDER, SDK_TOKEN_PLACEHOLDER, START_MARKDOWN } from '../consts';

const {
    IMPORT_LIBRARY,
    DEEP_LINKING,
    CUSTOM_USER_ID,
    INITIATE_THE_CODE,
    CREATE_A_CONFIGURATION_OBJECT,
} = SDK_INTEGRATION_SECTIONS;

const JAVA_DEEP_LINKING_METHOD = `${START_MARKDOWN(DEEP_LINKING)}
                .withSingularLink(getIntent(), new SingularLinkHandler() {
                    @Override
                    public void onResolved(SingularLinkParams singularLinkParams) {
                        String deeplink = params.getDeeplink();
                        String passthrough = params.getPassthrough();
                        boolean isDeferred = params.isDeferred();
        
                        // Add deep link handling code here
                    }
                });${END_MARKDOWN}`;

const getJavaCodeSnippet = (sdkConfiguration = {}) => {
    return `${START_MARKDOWN(IMPORT_LIBRARY)}import com.singular.sdk.*;${END_MARKDOWN}

public class MainActivity extends AppCompatActivity {

    @Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);

        ${START_MARKDOWN(
            CREATE_A_CONFIGURATION_OBJECT
        )}SingularConfig singularConfig = getSingularConfig();${END_MARKDOWN}
        ${START_MARKDOWN(INITIATE_THE_CODE)}Singular.init(this, singularConfig);${END_MARKDOWN}
    }

    ${START_MARKDOWN(CREATE_A_CONFIGURATION_OBJECT)}SingularConfig getSingularConfig() {
        SingularConfig config = new SingularConfig("{{${SDK_KEY_PLACEHOLDER}}}", "{{${SDK_TOKEN_PLACEHOLDER}}}")${END_MARKDOWN};
        ${sdkConfiguration[DEEP_LINKING] ? JAVA_DEEP_LINKING_METHOD : ''}
        ${START_MARKDOWN(CUSTOM_USER_ID)}config.withCustomUserId("custom_user_id")${END_MARKDOWN};
        return config;
    }
}`;
};

const getAndroidConfigureTheSDKJavaSnippet = (sdkConfiguration = {}) => {
    return {
        markedCode: getJavaCodeSnippet(sdkConfiguration[ANDROID_NATIVE]),
        codeLanguage: JAVA,
        placeholders: { [SDK_KEY_PLACEHOLDER]: '', [SDK_TOKEN_PLACEHOLDER]: '' },
    };
};

const KOTLIN_DEEP_LINKING_METHOD = `${START_MARKDOWN(DEEP_LINKING)}
        config.withSingularLink(intent) { singularLinkParams ->
            val deeplink = singularLinkParams.deeplink
            val passthrough = singularLinkParams.passthrough
            val isDeferred = singularLinkParams.isDeferred
            var urlParameters = singularLinkParams.urlParameters

            // Add deep link handling code here
        }${END_MARKDOWN}`;

const getKotlinCodeSnippet = (sdkConfiguration = {}) => {
    return `${START_MARKDOWN(IMPORT_LIBRARY)}import com.singular.sdk.*${END_MARKDOWN}

class MainActivity : AppCompatActivity() {
    
    override fun onCreate(savedInstanceState: Bundle?) {
        super.onCreate(savedInstanceState)

        ${START_MARKDOWN(CREATE_A_CONFIGURATION_OBJECT)}var singularConfig = getSingularConfig()${END_MARKDOWN}
        ${START_MARKDOWN(INITIATE_THE_CODE)}Singular.init(applicationContext, singularConfig)${END_MARKDOWN}
    ${START_MARKDOWN(CREATE_A_CONFIGURATION_OBJECT)}}

    private fun getSingularConfig(): SingularConfig {
        val config = SingularConfig("{{${SDK_KEY_PLACEHOLDER}}}", "{{${SDK_TOKEN_PLACEHOLDER}}}")${END_MARKDOWN}
        ${sdkConfiguration[DEEP_LINKING] ? KOTLIN_DEEP_LINKING_METHOD : ''}
        ${START_MARKDOWN(CUSTOM_USER_ID)}config.withCustomUserId("custom user id")${END_MARKDOWN}
        return config
    }
}`;
};

const getAndroidConfigureTheSDKKotlinSnippet = (sdkConfiguration = {}) => {
    return {
        markedCode: getKotlinCodeSnippet(sdkConfiguration[ANDROID_NATIVE]),
        codeLanguage: KOTLIN,
        placeholders: { [SDK_KEY_PLACEHOLDER]: '', [SDK_TOKEN_PLACEHOLDER]: '' },
    };
};

export { getAndroidConfigureTheSDKJavaSnippet, getAndroidConfigureTheSDKKotlinSnippet };
