import { all, call, put, select } from 'redux-saga/effects';
import { getFieldsForType, getFieldsInitiated } from '../selectors/fields';

import { Fields, Logger, Reports } from '../services';
import reportConfigs from '../reducers/reportsConfig';
import { fetchedFields, updatedDimensions, updatedField, updatedFields, updatedFilters } from '../actions/fields';
import {
    clearReportFilters,
    dateChanged,
    disableReportStateChanged,
    reportsFieldClickedBulk,
    setActionType,
} from '../actions/reports';
import { getUserData } from '../selectors/user';
import { getDefaultCompareDates } from '../utils/regional';
import rules from '../selectors/reportsConfig/fieldVisibilityRules';
import fieldDefaultRules from '../selectors/reportsConfig/fieldDefaultingRules';
import { REGULAR_MODE } from '../components/pages/reports/consts';
import {
    NETWORKS_SPECIAL_DISPLAY_NAME_MAPPING,
    NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING,
} from '../utils/adNetworks';
import { isObjectEmpty } from '../utils/general';

const reportTypes = reportConfigs.map(config => config.type);
const fieldsAPI = new Fields();
const reportsAPI = new Reports();
const log = new Logger('[sagas][fields]');

const getReportShelfFieldTypes = (reportType, reportMode) => {
    const reportConfig = reportConfigs.find(config => config.type === reportType);
    const reportConfigFieldsShelf = reportConfig.shelf[reportMode] || reportConfig.shelf;
    return reportConfigFieldsShelf.map(shelf => shelf.type);
};

function* updateReportsPage(fields, specificReportTypes, reportMode = REGULAR_MODE) {
    const runReportTypes = specificReportTypes.length ? specificReportTypes : reportTypes;
    const userData = yield select(getUserData);

    for (const reportType of runReportTypes) {
        const reportShelfFieldTypes = getReportShelfFieldTypes(reportType, reportMode);
        Object.keys(fields).forEach(key => {
            fields[key] = fields[key]
                ? fields[key]
                      .filter(
                          field =>
                              reportShelfFieldTypes.includes(field.actual_type) &&
                              fieldDefaultRules[reportType](field, userData.adminMode, reportMode) &&
                              (rules[reportType](field, userData.adminMode, userData, false) ||
                                  fieldDefaultRules.force(field))
                      )
                      .map(field => field.name)
                : [];
        });
        yield put(setActionType(reportsFieldClickedBulk(fields), reportType));
    }
}

export function* updateAllFields(force) {
    try {
        const { value: allFields } = yield call(fieldsAPI.updateAllFields, force);
        yield put(updatedFields(allFields));
    } catch (error) {
        log.error('Error: Failed to update all fields');
    }
}

export function* updateFilterFields() {
    try {
        const { value: filterFields } = yield call(fieldsAPI.updateFilterFields);
        yield put(updatedFilters(filterFields));
    } catch (error) {
        log.error('Error: Failed to update filter fields');
    }
}

export function* updateSourcesFieldsAvailability() {
    try {
        const { value: fieldsAvailabilityByDimension } = yield call(fieldsAPI.updateSourcesFieldsAvailability);

        if (isObjectEmpty(fieldsAvailabilityByDimension)) return;

        const networksDisplayNameToSpecialDisplayName = {};
        Object.entries(NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING).forEach(([name, displayName]) => {
            networksDisplayNameToSpecialDisplayName[displayName] = NETWORKS_SPECIAL_DISPLAY_NAME_MAPPING[name];
        });

        const dimensions = (yield select(getFieldsForType('dimensions'))).map(dim => {
            const dimensionName = dim.name.toLowerCase();

            const sources = (fieldsAvailabilityByDimension[dimensionName] || []).map(source => {
                return networksDisplayNameToSpecialDisplayName[source] || source;
            });

            return {
                ...dim,
                available_sources: sources,
            };
        });

        yield put(updatedDimensions(dimensions));
    } catch (error) {
        log.error('Error: Failed to update filter fields');
    }
}

function* updateTagGroups() {
    try {
        const response = yield call(fieldsAPI.updateTagGroups, {});
        yield put(updatedField('tagGroups', response));
    } catch (error) {
        log.error('Error: failed to update tag groups');
    }
}

function* updateBookmarks() {
    try {
        const response = yield call(fieldsAPI.updateBookmarks, {});
        yield put(updatedField('bookmarks', response.bookmarks));
    } catch (error) {
        log.error('Error: failed to update bookmarks');
    }
}

function* updateReportInitState(specificReportTypes) {
    try {
        const response = yield call(reportsAPI.updateReportInitState, {});
        const { disable_report_state: disableReportState } = response;

        for (const reportType of specificReportTypes) {
            yield put(setActionType(disableReportStateChanged(disableReportState), reportType));
        }
    } catch (error) {
        log.error('Error: failed to update report init state');
    }
}

function* updateReportDates(specificReportTypes) {
    try {
        const response = yield call(reportsAPI.updateReportDates, {});
        yield put(updatedField('dates', response));
        const { end_date, start_date } = response;

        const dates = {
            end_date,
            start_date,
            ...getDefaultCompareDates(start_date, end_date),
        };
        for (const reportType of specificReportTypes) {
            yield put(setActionType(dateChanged(dates), reportType));
        }
    } catch (error) {
        log.error('Error: failed to update report dates');
    }
}

function* updateFiltersDefault(reportType) {
    yield put(setActionType(clearReportFilters(), reportType));
}

function* updateGoalsList() {
    try {
        const response = yield call(fieldsAPI.updateGoalsList, {});
        yield put(updatedField('goals', response));
    } catch (error) {
        log.error('Error: failed to update goals metrics');
    }
}

function* updateReportsPageDefaults(specificReportTypes) {
    const fieldList = [
        'dimensions',
        'governanceDimensions',
        'custom_dimensions',
        'skan_redownloads_dimensions',
        'skan_validated_dimensions',
        'skanCustomEvents',
        'confidenceIntervalFlag',
        'skanConversionDimensions',
        'periods',
        'cohortMetrics',
        'xorgCohortMetrics',
        'metrics',
        'xorgMetrics',
        'appMetrics',
        'webMetrics',
        'utmParamsFields',
        'crossDeviceMetrics',
        'discrepancyMetrics',
        'dataTypeFields',
        'metricGroupFields',
        'cohortMetricGroupFields',
        'goalsMetrics',
        'goalsForecastMetrics',
        'skanModeledMetrics',
        'skanModeledCohortMetrics',
        'skanModeledCohortPeriods',
        'unifiedCohortPeriods',
        'customEvents',
        'timeBreakdowns',
        'skanDateDimensionName',
        'sourceAttributionType',
        'withDruidAppend',
        'druidAggregatedDataSourcesMode',
        'crossDeviceDemoCohortType',
        'crossDeviceCohortType',
        'adMonDAUMetrics',
        'socialEngagementMetrics',
    ];
    const fieldsDict = {};
    for (const field of fieldList) {
        fieldsDict[field] = yield select(getFieldsForType(field));
    }

    yield call(updateReportInitState, specificReportTypes);
    yield call(updateReportDates, specificReportTypes);
    yield call(updateReportsPage, fieldsDict, specificReportTypes);
    yield call(updateFiltersDefault, specificReportTypes);
}

function* updateFieldsHandler(selectDefaults = false, specificReportTypes = [], force = false) {
    try {
        const initiated = yield select(getFieldsInitiated);

        if (!initiated || force) {
            yield all([call(updateTagGroups), call(updateBookmarks), call(updateAllFields), call(updateGoalsList)]);
        }

        if (selectDefaults) {
            yield call(updateReportsPageDefaults, specificReportTypes);
        }

        yield put(fetchedFields());
    } catch (e) {
        console.log(e);
    }
}

function* updateFilterFieldsHandler() {
    try {
        yield call(updateFilterFields);
    } catch (e) {
        console.log(e);
    }
}

function* updateSourcesFieldsAvailabilityHandler() {
    try {
        yield call(updateSourcesFieldsAvailability);
    } catch (e) {
        console.log(e);
    }
}

export { updateFieldsHandler, updateFilterFieldsHandler, updateSourcesFieldsAvailabilityHandler, updateReportsPage };
