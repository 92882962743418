import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import languages from '../utils/languages'; // Note: when importing this file, .babelrc of the dashboard will be used due to babelrc lookup.
import ImpersonateOrgDialog from '../partnersLandingPage/impersonateOrgDialog';
import ChangePasswordDialog from '../containers/reportsWidgets/ChangePasswordDialog';
import Sidenav from '../sidenav/components/sidenav';
import { getIsOrgAdmin, getIsBlocked } from '../selectors/user';
import BlockedOrgPage from './partials/BlockedOrgPage';

function Main({ initialize, addTranslationForLanguage, setActiveLanguage, sections, shouldHideSidenav, children }) {
    const isBlocked = useSelector(getIsBlocked);
    const isOrgAdmin = useSelector(getIsOrgAdmin);

    useEffect(() => {
        const langs = Object.keys(languages).map(key => ({ name: key, code: key }));
        initialize({
            languages: langs,
            translation: languages,
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: 'en',
                onMissingTranslation: ({ translationId, defaultTranslation }) => {
                    let defaultTranslationText = defaultTranslation;

                    if (defaultTranslation.props && defaultTranslation.props.dangerouslySetInnerHTML) {
                        defaultTranslationText = defaultTranslation.props.dangerouslySetInnerHTML.__html;
                    }
                    return defaultTranslation ===
                        "No default translation found! Ensure you've added translations for your default langauge."
                        ? translationId
                        : defaultTranslationText;
                },
            },
        });

        langs.forEach(({ code }) => {
            addTranslationForLanguage(languages[code], code);
        });

        window.addEventListener('languageChanged', eventData => {
            setActiveLanguage(eventData.detail.code);
            moment.locale(eventData.detail.code);
        });
    }, []);

    return (
        <>
            <Sidenav sections={sections} shouldHideSidenav={shouldHideSidenav}>
                <React.Fragment>
                    {isBlocked && !isOrgAdmin ? <BlockedOrgPage /> : null}
                    {children}
                    {createPortal(<ImpersonateOrgDialog />, document.getElementById('impersonateOrgDialog'))}
                    {createPortal(<ChangePasswordDialog />, document.getElementById('changePasswordDialog'))}
                </React.Fragment>
            </Sidenav>
        </>
    );
}

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    sections: PropTypes.arrayOf(PropTypes.object),
    initialize: PropTypes.func,
    addTranslationForLanguage: PropTypes.func,
    setActiveLanguage: PropTypes.func,
    shouldHideSidenav: PropTypes.bool,
};

Main.defaultProps = {
    initialize: () => {},
    sections: [],
    addTranslationForLanguage: () => {},
    setActiveLanguage: () => {},
    shouldHideSidenav: false,
};

export default withLocalize(Main);
