import { connect } from 'react-redux';
import { getSingularLinksDomainsData } from '../selectors';
import SingularLinksDomains from '../components/manageDomainsShelf/SingularLinksDomains';
import { archiveButtonClicked, createSubDomain, favouriteButtonClicked, closeSubDomainsShelf } from '../actions';

const mapDispatchToProps = dispatch => {
    return {
        onCreateClicked: (...args) => {
            dispatch(createSubDomain(...args));
        },
        onFavouriteClicked: (...args) => {
            dispatch(favouriteButtonClicked(...args));
        },
        onArchiveClicked: (...args) => {
            dispatch(archiveButtonClicked(...args));
        },
        onCloseShelf: () => {
            dispatch(closeSubDomainsShelf());
        },
    };
};

export default connect(state => getSingularLinksDomainsData(state), mapDispatchToProps)(SingularLinksDomains);
