import API from '../services/api';

let instance = null;
const api = new API();
const URL_GET_NAVIGATION = '/api/users/self/navigation';

export default class SidenavService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getNavigation() {
        return api.getJson(URL_GET_NAVIGATION, {
            method: 'GET',
            credentials: 'include',
            params: {},
            cache: true,
        });
    }
}
