import React from 'react';
import fieldVisibilityRules from '../selectors/reportsConfig/fieldVisibilityRules';
import {
    AdMonetization,
    FraudRejected,
    FraudSuspicious,
    FraudProtected,
    Reports,
    SkanReport,
    SkanSummaryReport,
} from '../services';
import { FIELDS_TYPES, SKAN_DATE_DIMENSION_NAMES } from './consts';

import ReportsIcon from '../resources/svg/dashboards/data_types/reports.svg';
import SkanIcon from '../resources/svg/dashboards/data_types/skan.svg';
import SkanRawIcon from '../resources/svg/dashboards/data_types/skan_raw.svg';
import CreativesIcon from '../resources/icons/outline_image.svg';
import FraudRejectedIcon from '../resources/svg/dashboards/data_types/fraud_rejected.svg';
import FraudSuspiciousIcon from '../resources/svg/dashboards/data_types/fraud_suspicious.svg';
import FraudProtectedIcon from '../resources/svg/dashboards/data_types/fraud_protected.svg';
import UnifiedIcon from '../resources/svg/dashboards/data_types/unified.svg';
import AdmonIcon from '../resources/svg/dashboards/data_types/admon.svg';
import CrossDeviceIcon from '../resources/svg/dashboards/data_types/cross_device.svg';
import UnifiedReport from '../services/unifiedReport';
import css from './dashboard.css';

export const DataTypes = {
    REPORTS: 'reports',
    SKAN_RAW: 'skan_raw',
    SKAN: 'skan',
    CREATIVES: 'creatives',
    FRAUD_REJECTED: 'fraud_rejected',
    FRAUD_SUSPICIOUS: 'fraud_suspicious',
    FRAUD_PROTECTED: 'fraud_protected',
    ADMON: 'admon',
    CROSS_DEVICE: 'cross_device',
    UNIFIED: 'unified',
};

export const RESTRICTION_TYPE = Object.freeze({
    BLACKLIST: 'blacklist',
    WHITELIST: 'whitelist',
});

export const WIDGET_DATA_TYPES = {
    [DataTypes.REPORTS]: {
        name: DataTypes.REPORTS,
        display_name: 'Reports',
        visibility: fieldVisibilityRules.reports,
        icon: <ReportsIcon />,
        service: Reports,
        fields: {
            query_type: 'reports',
        },
    },
    [DataTypes.UNIFIED]: {
        name: DataTypes.UNIFIED,
        display_name: 'Unified Report',
        visibility: fieldVisibilityRules.unifiedReport,
        icon: <UnifiedIcon />,
        service: UnifiedReport,
        fields: {
            query_type: 'unifiedReport',
            is_unified_report: true,
            skan_date_dimension_name: SKAN_DATE_DIMENSION_NAMES.ESTIMATED_INSTALL_DATE,
            confidence_interval_flag: ['true'],
            skan_validated_dimensions: ['true'],
            source_attribution_type: ['skan'],
        },
        hiddenFields: [
            FIELDS_TYPES.CUSTOM_EVENTS,
            FIELDS_TYPES.CONVERSION_EVENTS,
            FIELDS_TYPES.SKAN_MODELED_EVENTS,
            FIELDS_TYPES.SOURCE_METRICS,
            FIELDS_TYPES.COHORT_METRICS,
        ],
    },
    [DataTypes.SKAN_RAW]: {
        name: DataTypes.SKAN_RAW,
        display_name: 'SKAdNetwork Raw',
        visibility: fieldVisibilityRules.skan,
        feature_flag: 'skan_reporting_attribution',
        icon: <SkanRawIcon />,
        service: SkanReport,
        fields: {
            is_skan: true,
            query_type: 'skan',
            confidence_interval_flag: ['false'],
            skan_validated_dimensions: ['true'],
            skan_redownloads_dimensions: ['show_both'],
            source_attribution_type: ['skan'],
        },
        restrictions: {
            [RESTRICTION_TYPE.BLACKLIST]: [
                {
                    dimensions: ['country_field', 'skan_publisher_id'],
                    metrics: [FIELDS_TYPES.SKAN_MODELED_METRICS],
                },
            ],
            [RESTRICTION_TYPE.WHITELIST]: [
                {
                    dimensions: ['skan_unified_conversion_value'],
                    metrics: ['modeled_conversion_value_count'],
                },
            ],
            names: {
                country_field: 'Country',
                skan_publisher_id: 'SKAN Publisher ID',
                modeled_conversion_value_count: 'Modeled Conversion Value Count',
                skan_unified_conversion_value: 'Conversion Value',
                [FIELDS_TYPES.SKAN_MODELED_METRICS]: 'Modeled SKAN Metrics',
            },
        },
    },
    [DataTypes.SKAN]: {
        name: DataTypes.SKAN,
        display_name: 'SKAdNetwork',
        visibility: fieldVisibilityRules.skanSummary,
        feature_flag: 'skan_reporting_analytics',
        icon: <SkanIcon />,
        service: SkanSummaryReport,
        fields: {
            is_skan_summary: true,
            query_type: 'skanSummary',
            confidence_interval_flag: ['false'],
            skan_validated_dimensions: ['true'],
            skan_redownloads_dimensions: ['show_both'],
            source_attribution_type: ['skan'],
            cohort_periods: ['actual'],
        },
        restrictions: {
            [RESTRICTION_TYPE.BLACKLIST]: [
                {
                    dimensions: ['country_field', 'skan_unified_publisher_id'],
                    metrics: [FIELDS_TYPES.SKAN_MODELED_METRICS, FIELDS_TYPES.SKAN_MODELED_EVENTS],
                },
            ],
            names: {
                country_field: 'Country',
                skan_unified_publisher_id: 'Publisher Site ID',
                [FIELDS_TYPES.SKAN_MODELED_METRICS]: 'Modeled SKAN Metrics',
                [FIELDS_TYPES.SKAN_MODELED_EVENTS]: 'Modeled SKAN Events',
            },
        },
    },
    [DataTypes.CREATIVES]: {
        name: DataTypes.CREATIVES,
        display_name: 'Creatives',
        visibility: fieldVisibilityRules.creatives,
        icon: <CreativesIcon className={css.creativesIcon} />,
        service: Reports,
        fields: {
            is_creative_reporting_query: true,
            displayNonGraphical: false,
            fuzzy: false,
            show_creative_type: false,
        },
    },
    [DataTypes.ADMON]: {
        name: DataTypes.ADMON,
        display_name: 'Ad Monetization',
        visibility: fieldVisibilityRules.adMonetization,
        feature_flag: 'ad_monitization',
        icon: <AdmonIcon />,
        service: AdMonetization,
        fields: {
            is_admon_report: true,
            query_type: 'adMonetization',
        },
        hiddenFields: [FIELDS_TYPES.CUSTOM_EVENTS, FIELDS_TYPES.CONVERSION_EVENTS],
    },
    [DataTypes.CROSS_DEVICE]: {
        name: DataTypes.CROSS_DEVICE,
        display_name: 'Cross Device',
        visibility: fieldVisibilityRules.crossDevice,
        feature_flag: 'cross-device',
        icon: <CrossDeviceIcon />,
        service: Reports,
        fields: {
            cross_device_cohort_type: ['user'],
            query_type: 'crossDevice',
        },
        hiddenFields: [FIELDS_TYPES.SOURCE_DIMENSIONS, FIELDS_TYPES.SOURCE_METRICS],
    },
    [DataTypes.FRAUD_REJECTED]: {
        name: DataTypes.FRAUD_REJECTED,
        display_name: 'Fraud Rejected',
        visibility: fieldVisibilityRules.fraudRejected,
        feature_flag: 'fraud_reject',
        icon: <FraudRejectedIcon />,
        service: FraudRejected,
        fields: {
            is_fraud: true,
            query_type: 'fraudRejected',
        },
        hiddenFields: [FIELDS_TYPES.SOURCE_DIMENSIONS, FIELDS_TYPES.CUSTOM_EVENTS],
    },
    [DataTypes.FRAUD_SUSPICIOUS]: {
        name: DataTypes.FRAUD_SUSPICIOUS,
        display_name: 'Fraud Suspicious',
        visibility: fieldVisibilityRules.fraudSuspicious,
        feature_flag: 'fraud_reject',
        icon: <FraudSuspiciousIcon />,
        service: FraudSuspicious,
        fields: {
            is_fraud: true,
            query_type: 'fraudSuspicious',
        },
        hiddenFields: [FIELDS_TYPES.SOURCE_DIMENSIONS],
    },
    [DataTypes.FRAUD_PROTECTED]: {
        name: DataTypes.FRAUD_PROTECTED,
        display_name: 'Fraud Protected',
        visibility: fieldVisibilityRules.fraudProtected,
        feature_flag: 'fraud_reject',
        icon: <FraudProtectedIcon />,
        service: FraudProtected,
        fields: {
            is_fraud: true,
            query_type: 'fraudProtected',
        },
        hiddenFields: [FIELDS_TYPES.SOURCE_DIMENSIONS],
    },
};
