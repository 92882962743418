import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '../../resources/svg/info.svg';
import GlassesIcon from '../../resources/svg/glasses.svg';
import CheckMark from '../../resources/icons/check_mark.svg';
import DataTypeTooltip from './DataTypeTooltip';
import Tooltip from './Tooltip';
import css from './CheckableField.css';
import { MAX_SOURCES_TO_DISPLAY_ON_TOOLTIP } from '../../fieldsAvailability/consts';

function CheckableField(props) {
    const {
        itemId,
        groupType,
        field,
        style,
        hideIcon,
        icon,
        persistentIcon,
        showInFieldsAvailability,
        translate,
        availableSources,
        selectedSources,
        shouldShowDisabled,
        onFieldsAvailabilityClick,
        onClick,
        fieldClassName,
        checkedFieldClassName,
        checkMarkOnSelected,
        dataTypeFields,
    } = props;

    const { display_name: displayName, checked, tooltip, actual_type: actualType, visible, name } = field;

    const handleClick = () => {
        onClick(field);
    };

    const handleFieldsAvailabilityClick = () => {
        onFieldsAvailabilityClick(name);
    };

    const getTooltipTitle = (currentAvailableSources, currentSelectedSources, currentShouldShowDisabled) => {
        const noSources = (
            <div>
                <div className="bolder-text">Not available for filtered sources</div>
                <div>Click Dimensions Availability for full source list</div>
            </div>
        );

        if (currentShouldShowDisabled) {
            return noSources;
        }

        if (currentAvailableSources && currentSelectedSources && currentSelectedSources.length) {
            currentSelectedSources = currentSelectedSources.filter(selectedSource =>
                currentAvailableSources.includes(selectedSource)
            );

            if (!currentSelectedSources.length) {
                return noSources;
            }
        }

        const isAvailableSources = !!(currentAvailableSources && currentAvailableSources.length);
        const isSelectedSources = !!(currentSelectedSources && currentSelectedSources.length);

        if (isAvailableSources && !isSelectedSources) {
            return (
                <div>
                    <div>Click Dimensions Availability for full source list</div>
                </div>
            );
        }

        if (!isAvailableSources && !isSelectedSources) {
            return (
                <div>
                    <div>No available sources for this dimension</div>
                </div>
            );
        }

        let allSources = null;

        if (isSelectedSources) {
            allSources = currentSelectedSources;
        } else if (isAvailableSources) {
            allSources = currentAvailableSources;
        }

        if (allSources) {
            const sources = [];

            for (const source in allSources) {
                if (sources.length === MAX_SOURCES_TO_DISPLAY_ON_TOOLTIP) {
                    break;
                }
                sources.push(allSources[source]);
            }

            let sourcesText = sources.join(', ');

            const restSourcesAmount = allSources.length - sources.length;

            if (restSourcesAmount > 0) {
                sourcesText += ' (+{0})'.format(restSourcesAmount);
            }

            return (
                <div>
                    <div className="bolder-text">Available for: {sourcesText}</div>
                    <div>Click Dimensions Availability for full source list</div>
                </div>
            );
        }

        return noSources;
    };

    const showFieldsAvailabilityIcon = showInFieldsAvailability(actualType, visible, name);

    const additionalProps = {
        style,
        ...(itemId ? { id: itemId } : {}),
    };

    return (
        <button
            type="button"
            className={classNames(css.container, fieldClassName, {
                [css.checked]: checked,
                [checkedFieldClassName]: checked,
                [css.disabled]: !checked && shouldShowDisabled,
                [css.single]: groupType === 'single',
            })}
            onClick={handleClick}
            {...additionalProps}
        >
            <div className={`${css.buttonInner} ${persistentIcon ? css.persistentIcon : ''}`}>
                {(checked && !hideIcon) || persistentIcon ? icon : null}
                <div className={css.buttonText} title={translate(displayName)}>
                    <Translate id={displayName} />
                </div>
                {checkMarkOnSelected && checked && <CheckMark className={css.checkMark} />}
                {showFieldsAvailabilityIcon && (
                    <Tooltip
                        title={getTooltipTitle(availableSources, selectedSources, shouldShowDisabled)}
                        wrapperClass={css.tooltipWrapper}
                    >
                        <GlassesIcon className={css.glassesIcon} onClick={handleFieldsAvailabilityClick} />
                    </Tooltip>
                )}
                {!showFieldsAvailabilityIcon && tooltip && (
                    <Tooltip title={tooltip} wrapperClass={css.tooltipWrapper}>
                        <InfoIcon className={css.infoIcon} />
                    </Tooltip>
                )}
                {!showFieldsAvailabilityIcon &&
                    field.dynamic_data_type_tooltip &&
                    !!Object.keys(field.dynamic_data_type_tooltip).length && (
                        <DataTypeTooltip
                            field={field}
                            dataTypeFields={dataTypeFields}
                            wrapperClass={css.tooltipWrapper}
                        >
                            <InfoIcon className={css.infoIcon} />
                        </DataTypeTooltip>
                    )}
            </div>
        </button>
    );
}

CheckableField.propTypes = {
    itemId: PropTypes.string,
    field: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    showInFieldsAvailability: PropTypes.func,
    translate: PropTypes.func,
    onFieldsAvailabilityClick: PropTypes.func,
    style: PropTypes.objectOf(PropTypes.any),
    hideIcon: PropTypes.bool,
    icon: PropTypes.element,
    persistentIcon: PropTypes.bool,
    groupType: PropTypes.oneOf(['single', 'multi']),
    availableSources: PropTypes.arrayOf(PropTypes.any),
    selectedSources: PropTypes.arrayOf(PropTypes.any),
    dataTypeFields: PropTypes.arrayOf(PropTypes.any),
    shouldShowDisabled: PropTypes.bool,
    fieldClassName: PropTypes.string,
    checkedFieldClassName: PropTypes.string,
    checkMarkOnSelected: PropTypes.bool,
};

CheckableField.defaultProps = {
    itemId: null,
    field: {
        display_name: '',
        checked: false,
        tooltip: false,
        actual_type: null,
        visible: false,
        name: '',
    },
    onClick: () => {},
    showInFieldsAvailability: () => {},
    translate: () => {},
    onFieldsAvailabilityClick: () => {},
    style: {},
    hideIcon: true,
    persistentIcon: false,
    icon: (
        <CheckIcon
            style={{
                width: 14,
                height: 14,
                marginRight: 4,
                verticalAlign: 'middle',
            }}
            color="#FFFFFF"
        />
    ),
    groupType: 'multi',
    availableSources: [],
    selectedSources: [],
    dataTypeFields: [],
    shouldShowDisabled: false,
    fieldClassName: '',
    checkedFieldClassName: '',
    checkMarkOnSelected: false,
};

export default withLocalize(CheckableField);
