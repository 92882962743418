import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import { useModelKinds } from '../../hooks';
import Dropdown from '../../../components/widgets/Dropdown';
import TextField from '../../../components/widgets/TextField';
import Label from '../../../components/widgets/Label';
import css from '../ConversionModelShelf.css';
import shelfTopAreaCss from '../ConversionModelShelfTopArea.css';
import UsedSlots from '../../../components/widgets/UsedSlots';
import {
    CREATE_MODE,
    EDIT_MODE,
    MAX_MEASUREMENT_PERIOD,
    MIN_ENGAGEMENT_SLOTS,
    MODEL_NAME_MAX_LENGTH,
    ModelKinds,
    NUMBER_OF_BITS,
    RETENTION_SLOTS_COLOR,
    RETENTION_SLOTS_TEXT,
    SLOTS_SIGNS,
} from '../../consts';
import {
    getModelKindName,
    getModelTypeData,
    getModelTypes,
    getRetentionBits,
    getUsedSlots,
    isSkan3CompatibilityMode,
} from '../../utils';
import ModelForm from './modelTypes/ModelForm';
import { getModelComponent, SUPPORTED_MODEL_KINDS } from './modelTypes/Factories/ModelTypeFactory';
import { conversionModelTypeShape, modelErrorsShape, sdkEventsShape } from './types';
import SkanTimersRadioButtons from './SkanTimersRadioButtons';
import { SUPPORTED_MIXED_MODEL_COMPONENT } from './modelTypes/Factories/MixedModelTypeFactory';
import Slider from '../../../components/widgets/Slider';

const SLIDER_TEXT_OVERRIDES = Object.fromEntries(
    Array.from({ length: MAX_MEASUREMENT_PERIOD }, (_, i) => [i + 1, `${i + 1} day${i === 0 ? '' : 's'}`])
);

function ConversionModelShelfStep1({
    onChange,
    isAdminMode,
    values,
    errors,
    currencySymbol,
    sdkEvents,
    setModelKind,
    modelName,
    isUpgradeMode,
    isSkan3Mode,
    shelfElement,
    multiCoarseMapping,
    isMultiStep,
}) {
    const {
        usedSlots,
        modelType,
        revenueType,
        eventsType,
        measurementPeriod,
        conversionModelGroupId,
        compatibilityMode,
        apsalarEventIds,
    } = values;

    const [sliderMaximalValue, setSliderMaximalValue] = useState(MAX_MEASUREMENT_PERIOD);
    const [slots, setSlots] = useState([]);
    const typesList = useRef(getModelTypes()).current;

    const modelKindName = getModelKindName(modelType, revenueType, eventsType);
    const SelectedModelComponent = getModelComponent(modelKindName);
    const modelKinds = useModelKinds(
        typesList,
        modelKindName,
        isAdminMode,
        SUPPORTED_MODEL_KINDS,
        SUPPORTED_MIXED_MODEL_COMPONENT
    );
    const retentionBits = isSkan3CompatibilityMode(compatibilityMode) ? getRetentionBits(measurementPeriod) : 0;
    const maxSlots = NUMBER_OF_BITS - retentionBits;

    const handleModelKindSelect = ({ display_name: displayName }) => {
        setModelKind(modelKinds.find(modelKindDescription => displayName === modelKindDescription.display_name));
    };

    const showModelContent = !!modelKindName;

    useEffect(() => {
        const calculatedSlots = getUsedSlots(
            modelKindName,
            measurementPeriod,
            usedSlots,
            isSkan3CompatibilityMode(compatibilityMode)
        );
        setSlots(calculatedSlots);
    }, [modelKindName, measurementPeriod, usedSlots, compatibilityMode]);

    useEffect(() => {
        const usedSlotsAmount = slots.filter(({ text }) => text !== RETENTION_SLOTS_TEXT).length;
        let availableSlots = NUMBER_OF_BITS - usedSlotsAmount;

        if (eventsType === ModelKinds.ENGAGEMENT) {
            const revenueSlots = slots.filter(({ text }) => text === SLOTS_SIGNS[ModelKinds.REVENUE]).length;
            const eventsAmount = apsalarEventIds?.length || 0;

            if (!eventsAmount) {
                availableSlots -= MIN_ENGAGEMENT_SLOTS;
            } else {
                const availableSlotsForEvents = NUMBER_OF_BITS - revenueSlots;
                const useSlotsForEvents = Math.max(eventsAmount, MIN_ENGAGEMENT_SLOTS);
                availableSlots = Math.max(availableSlotsForEvents - useSlotsForEvents, 0);
            }
        }

        setSliderMaximalValue(Math.min(MAX_MEASUREMENT_PERIOD, Math.pow(2, availableSlots)));
    }, [slots, apsalarEventIds]);

    const isSkan3Shelf = isSkan3Mode && !isUpgradeMode;
    const shouldShowSlider = sliderMaximalValue > 1;

    return (
        <div className={css.page}>
            {isMultiStep && (
                <div className={css.shelfInfoBox}>
                    <Label text="STATIC.PAGES.SKADNETWORK.SHELF_P1_HEADER_TITLE" className={css.shelfHeaderTitle} />
                    <Translate id="STATIC.PAGES.SKADNETWORK.SHELF_P1_HEADER_TEXT" className={css.shelfInfoBoxText} />
                </div>
            )}
            {showModelContent && (
                <div className={css.shelfInfoBox}>
                    <div
                        className={classNames(css.slotsArea, {
                            [css.noSliderSector]: !shouldShowSlider,
                        })}
                    >
                        <div>
                            <Label text="STATIC.PAGES.SKADNETWORK.USING_SLOTS" type="fieldLabel" />
                            <div className={shelfTopAreaCss.usedSlotsContainer}>
                                <UsedSlots size={NUMBER_OF_BITS} slots={slots} />
                            </div>
                        </div>
                        {isSkan3Shelf && (
                            <div
                                className={classNames(css.measurementSlider, {
                                    [css.noSliderSector]: !shouldShowSlider,
                                })}
                            >
                                <Label
                                    text="STATIC.PAGES.SKADNETWORK.MEASUREMENT_PERIOD"
                                    textReplace={{ days: SLIDER_TEXT_OVERRIDES[measurementPeriod] }}
                                    type="fieldLabel"
                                    style={shouldShowSlider ? {} : { paddingBottom: 0 }}
                                />
                                {shouldShowSlider && (
                                    <Slider
                                        min={1}
                                        max={sliderMaximalValue}
                                        showValue={false}
                                        marginBottom={0}
                                        value={measurementPeriod}
                                        extraSteps={[3, 5].filter(step => step < sliderMaximalValue)}
                                        onChange={period => {
                                            onChange({ measurementPeriod: period });
                                        }}
                                        color={RETENTION_SLOTS_COLOR}
                                        stepsOverride={SLIDER_TEXT_OVERRIDES}
                                        markLabelClassName={css.sliderMarkLabel}
                                        debounceTime={50}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {!isSkan3Shelf && (
                        <SkanTimersRadioButtons
                            onChange={onChange}
                            measurementPeriod={measurementPeriod}
                            mode={conversionModelGroupId ? EDIT_MODE : CREATE_MODE}
                            compatibilityMode={compatibilityMode}
                        />
                    )}
                </div>
            )}
            <div className={css.shelfInfoBox}>
                {!isUpgradeMode && (
                    <div className={css.shelfInputBox}>
                        <Label type="fieldLabel" text="STATIC.PAGES.SKADNETWORK.SHELF_MODEL_TYPE_LABEL" mandatory />
                        <Dropdown
                            containerClass={css.shelfInput}
                            wrapperClass={css.shelfInput}
                            items={modelKinds}
                            selected={getModelTypeData(modelKinds, modelKindName, eventsType)}
                            onSelection={handleModelKindSelect}
                            error={errors.modelType}
                            valueField="display_name"
                        />
                    </div>
                )}
                <div className={css.shelfInputBox}>
                    <TextField
                        label="STATIC.PAGES.SKADNETWORK.SHELF_MODEL_NAME_LABEL"
                        clearable
                        mandatory
                        maxLength={MODEL_NAME_MAX_LENGTH}
                        value={modelName}
                        debounce={500}
                        onChange={newModelName => onChange({ modelGroupName: newModelName })}
                        containerClass={css.shelfInput}
                        placeholder="STATIC.PAGES.SKADNETWORK.NAME_PLACEHOLDER"
                        error={errors.modelGroupName && `STATIC.PAGES.SKADNETWORK.${errors.modelGroupName.errorCode}`}
                        autoCompleteOff
                    />
                </div>
            </div>
            {showModelContent && (
                <ModelForm typeData={getModelTypeData(typesList, modelKindName, eventsType)}>
                    <SelectedModelComponent
                        onChange={onChange}
                        isAdminMode={isAdminMode}
                        values={values}
                        errors={errors}
                        currencySymbol={currencySymbol}
                        sdkEvents={sdkEvents}
                        typesList={typesList}
                        shelfElement={shelfElement}
                        multiCoarseMapping={multiCoarseMapping}
                        maxSlots={maxSlots}
                        isMultiStep={isMultiStep}
                    />
                </ModelForm>
            )}
        </div>
    );
}

ConversionModelShelfStep1.propTypes = {
    values: conversionModelTypeShape,
    onChange: PropTypes.func.isRequired,
    isAdminMode: PropTypes.bool.isRequired,
    errors: modelErrorsShape,
    currencySymbol: PropTypes.string,
    sdkEvents: PropTypes.objectOf(sdkEventsShape),
    setModelKind: PropTypes.func,
    modelName: PropTypes.string,
    isUpgradeMode: PropTypes.bool,
    isSkan3Mode: PropTypes.bool,
    shelfElement: PropTypes.instanceOf(Element),
    multiCoarseMapping: PropTypes.bool.isRequired,
    isMultiStep: PropTypes.bool,
};

ConversionModelShelfStep1.defaultProps = {
    values: {},
    errors: {},
    currencySymbol: undefined,
    sdkEvents: {},
    setModelKind: () => {},
    modelName: '',
    isUpgradeMode: false,
    isSkan3Mode: false,
    shelfElement: null,
    isMultiStep: false,
};

export default ConversionModelShelfStep1;
