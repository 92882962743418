import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useMutation, useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import { useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import CustomDashboardGrid, { getNewWidgetPosition } from './customDashboardGrid';
import { PageWrapper, PopupTypes } from '../components/partials';
import ResizeSensor from '../components/partials/resize_sensor';
import { useGridResize, useLocalStorageState } from '../utils/customHooks';
import { CLONE_DASHBOARD, CREATE_DASHBOARD, DELETE_DASHBOARD, READ_DASHBOARDS, UPDATE_DASHBOARD } from './queries';
import { CircularPlusButton, HelpMenu, ThreeLoadingDots, Toggle } from '../components/widgets';
import {
    dashboardPageName,
    getUniqueName,
    last7Days,
    trackDashboardsMixpanelEvent,
    trackDashboardsWidgetOpenedMixpanelEvent,
    trackDashboardsWidgetUpdateMixpanelEvent,
    WIDGET_CHANGE_TYPES,
} from './utils';
import EditableTitle from '../components/widgets/EditableTitle';
import css from './dashboard.css';
import { uuidv4 } from '../governance/utils';
import { useFetchAllFields, useFetchDisableReports, useFetchFilterFields } from './hooks';
import TrashIcon from '../resources/svg/trash.svg';
import Show from '../resources/svg/eye_open.svg';
import Hide from '../resources/svg/eye_close.svg';
import Tooltip from '../components/widgets/Tooltip';
import GeneralPopup from '../components/partials/GeneralPopup';
import EmptyState from '../components/partials/EmptyState/EmptyState';
import { getEmptyStateConfig } from '../reducers/reportsConfig/baseReportConfig';
import { STATIC } from '../../../dashboard/js/locale/en';
import NewTagInput from '../components/widgets/NewTagInput';
import { getTimePeriodByDates } from '../utils/regional';
import Icon, { IconSizes, IconVariants } from '../components/foundations/Icon';

const NEW_DASHBOARD_TITLE = 'New Dashboard';

function Dashboard({ translate, emptyStateOrgData, dateLimit, onCloseReportsEmptyState }) {
    const { loading, error, data, refetch } = useQuery(READ_DASHBOARDS);
    const [searchParams, setSearchParams] = useSearchParams();

    const dashboards = useMemo(() => {
        if (loading || error || !data || !data.dashboards) {
            return [];
        }

        return sortBy(data.dashboards, d => d.created);
    }, [loading, error, data]);

    const [activeDashboard, setActiveDashboard] = useLocalStorageState('Dashboard.active_dashboard', dashboards[0], {
        serialize: dashboard => JSON.stringify(dashboard?.id),
        deserialize: dashboardId => dashboards.find(x => x.id === JSON.parse(dashboardId)) ?? dashboards[0],
    });

    // a flag to stop unnecessary rendering and sending queries when we're about to navigate away to another dashboard.
    const [shouldRender, setShouldRender] = useState(false);

    const [apiFields, setApiFields] = useState(null);

    const { value: fieldsResponse } = useFetchAllFields();
    const { value: filterFieldsResponse } = useFetchFilterFields();

    useEffect(() => {
        if (fieldsResponse && filterFieldsResponse) {
            setApiFields({
                ...fieldsResponse,
                filters: filterFieldsResponse,
            });
        }
    }, [fieldsResponse, filterFieldsResponse]);

    const [originalDashboardName, setOriginalDashboardName] = useState('');

    const parsedRouting = useMemo(() => {
        const activeDashboardId = searchParams.get('active-dashboard');

        return {
            // unescape url Param ('%3d' -> '='), but keep +, because it's a legit base64 character
            navigatedDashboardId: activeDashboardId ? unescape(activeDashboardId).replaceAll(' ', '+') : null,
            createNew: searchParams.get('new') === 'true',
            useDefault: searchParams.get('default') === 'true',
        };
    }, [searchParams]);

    const navigateToDashboard = newDashboard => {
        if (!newDashboard) {
            return;
        }

        setActiveDashboard(newDashboard);
        setSearchParams({ 'active-dashboard': escape(newDashboard.id).replace('/', '%2F') });

        setOriginalDashboardName(newDashboard.name);
        trackDashboardsMixpanelEvent('Viewed Dashboard', {
            dashboard_name: newDashboard.name,
            is_default: newDashboard.id === dashboards[0]?.id,
        });
    };

    useEffect(() => {
        const currDashboard = dashboards.find(({ id }) => id === parsedRouting.navigatedDashboardId);

        if (!currDashboard && dashboards.length) {
            navigateToDashboard(dashboards[0]);
        } else if (currDashboard && !activeDashboard) {
            setShouldRender(true);
            navigateToDashboard(currDashboard);
        }
    }, [dashboards]);

    const onNewDashboardCreated = (newDashboard, isCloned) => {
        trackDashboardsMixpanelEvent('New Dashboard Created', {
            dashboard_name: newDashboard.name,
            is_cloned: isCloned,
        });

        refetch();
        navigateToDashboard(newDashboard);
    };

    // Prevent multiple clicks from creating the same Dashboard
    const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);
    const [createDashboard] = useMutation(CREATE_DASHBOARD, {
        onCompleted({ createDashboard: newDashboard }) {
            onNewDashboardCreated(newDashboard, false);
            setIsCreatingDashboard(false);
        },
    });
    const handleRouting = () => {
        if (parsedRouting.createNew) {
            if (!isCreatingDashboard) {
                setIsCreatingDashboard(true);
                // noinspection JSIgnoredPromiseFromCall
                createDashboard({
                    variables: {
                        name: getUniqueName(dashboards, NEW_DASHBOARD_TITLE),
                        shared: true,
                        startDate: last7Days,
                        endDate: moment().format('YYYY-MM-DD'),
                    },
                });
            }
            return true;
        }

        if (parsedRouting.useDefault) {
            navigateToDashboard(dashboards[0]);
            return true;
        }

        if (!parsedRouting.navigatedDashboardId) {
            navigateToDashboard(activeDashboard);
            return true;
        }

        if (!activeDashboard || parsedRouting.navigatedDashboardId !== activeDashboard.id) {
            const dashboardFromUrl = dashboards.find(x => x.id === parsedRouting.navigatedDashboardId);

            if (dashboardFromUrl) {
                navigateToDashboard(dashboardFromUrl);
            } else if (dashboards.length) {
                navigateToDashboard(dashboards[0]);
            }

            return true;
        }

        return false;
    };

    useEffect(() => {
        const causedRoutingChange = handleRouting();

        if (causedRoutingChange) {
            setShouldRender(false);
            return;
        }

        setShouldRender(true);
    }, [searchParams]);

    function getDashboardNameError(name) {
        if (!shouldRender) {
            return '';
        }

        const dashboardName = name || activeDashboard.name;
        if (!dashboardName) {
            return 'must not be empty';
        }

        if (dashboards.some(d => d.id !== activeDashboard.id && d.name === dashboardName)) {
            return 'A dashboard with that name already exists';
        }

        return '';
    }

    // Memoize the dashboard widgets based on the retrieved dashboard object
    const widgets = useMemo(() => {
        if (!shouldRender || !activeDashboard) {
            return [];
        }

        return activeDashboard.widgets.map(widget => ({
            ...widget,
            options: {
                ...widget.options,
                rows: widget.options.rows.filter(x => widget.query.dimensions.includes(x)),
                columns: widget.options.columns.filter(x => widget.query.dimensions.includes(x)),
            },
        }));
    }, [shouldRender, activeDashboard]);

    const [cloneDashboard] = useMutation(CLONE_DASHBOARD, {
        onCompleted({ cloneDashboard: newDashboard }) {
            onNewDashboardCreated(newDashboard, true);
        },
    });

    const [deleteDashboard] = useMutation(DELETE_DASHBOARD, {
        onCompleted() {
            const { id, name } = activeDashboard;

            trackDashboardsMixpanelEvent('Deleted Dashboard', {
                dashboard_name: name,
            });

            const oldIndex = dashboards.findIndex(d => d.id === id);
            refetch();

            if (dashboards.length <= 1) {
                return;
            }

            const newIndex = oldIndex === 0 ? 1 : oldIndex - 1;
            navigateToDashboard(dashboards[newIndex]);
        },
    });

    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    const [updatingGraphQL, setUpdatingGraphQL] = useState(false);
    const [updateDashboard] = useMutation(UPDATE_DASHBOARD, {
        update: () => {
            refetch();
            setUpdatingGraphQL(false);
        },
    });

    const debouncedUpdateDashboard = useMemo(() => debounce(updateDashboard, 100), [updateDashboard]);

    // Callback on grid changes, persists back to server
    const onUpdateWidgets = useCallback(
        (newWidgets, widget, changeType) => {
            if (isEqual(widgets, newWidgets)) {
                return;
            }

            setUpdatingGraphQL(true);
            const newDashboard = { ...activeDashboard, widgets: newWidgets };
            setActiveDashboard(newDashboard);
            debouncedUpdateDashboard({ variables: newDashboard });
            if (changeType !== WIDGET_CHANGE_TYPES.LAYOUT) {
                trackDashboardsWidgetUpdateMixpanelEvent({
                    dashboard: activeDashboard,
                    widget,
                    changeType,
                });
            }
            if (changeType === WIDGET_CHANGE_TYPES.ADDED || changeType === WIDGET_CHANGE_TYPES.EDITED) {
                trackDashboardsWidgetOpenedMixpanelEvent({
                    dashboard: activeDashboard,
                    widget,
                    changeType,
                    isSaved: true,
                });
            }
        },
        [activeDashboard]
    );

    const [width, onGridResize] = useGridResize();
    const dashboardNameErrorMessage = useMemo(getDashboardNameError, [shouldRender, activeDashboard, dashboards]);

    const setDashboardName = useCallback(
        name => {
            const newDashboard = { ...activeDashboard, name };
            setActiveDashboard(newDashboard);
        },
        [activeDashboard]
    );

    const updateDashboardName = useCallback(() => {
        if (!getDashboardNameError(activeDashboard.name)) {
            setUpdatingGraphQL(true);
            debouncedUpdateDashboard({ variables: activeDashboard });
            setOriginalDashboardName(activeDashboard.name);
        } else {
            setDashboardName(originalDashboardName);
        }
    }, [shouldRender, activeDashboard, dashboardNameErrorMessage]);

    const [deletedItem, setDeletedItem] = useState(null);

    const { disable_report_state: disableReportState = null } = useFetchDisableReports();

    // Private / Public Toggle State
    const [toggleText, setToggleText] = useState(STATIC.PAGES.DASHBOARD.PUBLIC_DASHBOARD);
    const [toolTipText, setToolTipText] = useState('STATIC.PAGES.DASHBOARD.ACTIONS.MAKE_PRIVATE');
    const shared = activeDashboard && activeDashboard.shared;
    const isPreconfigured = activeDashboard && activeDashboard.isPreconfigured;
    const isCreator = activeDashboard && activeDashboard.isCreator;
    const [changeToggle, setChangeToggle] = useState(false);

    const changePublicPrivate = () => {
        const newDashboard = { ...activeDashboard, shared: !activeDashboard.shared };
        setActiveDashboard(newDashboard);
        debouncedUpdateDashboard({ variables: newDashboard });
        setChangeToggle(false);
        const eventName = newDashboard.shared ? 'Clicked Public' : 'Clicked Private';
        trackDashboardsMixpanelEvent(eventName, {
            dashboard_name: newDashboard.name,
        });
    };
    //
    // Date Picker Changed
    const changeDatePicker = useCallback(
        (startDate, endDate) => {
            // We need this because some of our clients use other languages
            // and we want to save the time period in english in our backend
            const timePeriodInEnglish = getTimePeriodByDates(startDate, endDate);
            const newDashboard = {
                ...activeDashboard,
                startDate,
                endDate,
                timePeriod: timePeriodInEnglish,
            };
            setActiveDashboard(newDashboard);
            debouncedUpdateDashboard({ variables: newDashboard });
        },
        [activeDashboard]
    );

    useEffect(() => {
        if (activeDashboard && shouldRender) {
            setToggleText(shared ? STATIC.PAGES.DASHBOARD.PUBLIC_DASHBOARD : STATIC.PAGES.DASHBOARD.PRIVATE_DASHBOARD);
            if (shared) {
                setToolTipText(
                    isCreator
                        ? 'STATIC.PAGES.DASHBOARD.ACTIONS.MAKE_PRIVATE'
                        : 'STATIC.PAGES.DASHBOARD.ACTIONS.DISABLED_TOOLTIP'
                );
            } else {
                setToolTipText('STATIC.PAGES.DASHBOARD.ACTIONS.MAKE_PUBLIC');
            }
        }
    }, [activeDashboard, shouldRender]);

    const emptyStateDashboardData = getEmptyStateConfig(dashboardPageName);
    return (
        <div>
            <div className={css.dashboardHeader}>
                <div>
                    <Translate id="STATIC.PAGES.DASHBOARD.SELECT_DASHBOARD" />
                </div>
                <div className={css.dashboardSelection}>
                    <NewTagInput
                        options={dashboards.map(x => ({ value: x.id, label: x.name }))}
                        containerStyle={{ width: '100%' }}
                        tags={activeDashboard && { label: activeDashboard.name, value: activeDashboard.id }}
                        onChange={option => {
                            navigateToDashboard(dashboards.find(x => x.id === option.value));
                        }}
                        isMulti={false}
                        separator={false}
                    />
                </div>
                <div className={css.actionButtons}>
                    <Tooltip interactive titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.NEW_DASHBOARD">
                        <CircularPlusButton
                            className={css.addDashboardIcon}
                            disabled={!!disableReportState || isCreatingDashboard}
                            onClick={() => {
                                setIsCreatingDashboard(true);
                                createDashboard({
                                    variables: {
                                        name: getUniqueName(dashboards, NEW_DASHBOARD_TITLE),
                                        shared: true,
                                        startDate: last7Days,
                                        endDate: moment().format('YYYY-MM-DD'),
                                    },
                                });
                            }}
                        />
                    </Tooltip>

                    <Tooltip interactive titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.CLONE">
                        <Icon
                            name="copy"
                            size={IconSizes.SMALL}
                            variant={IconVariants.LIGHT}
                            className={css.copyDashboardIcon}
                            onClick={() => {
                                cloneDashboard({
                                    variables: {
                                        id: activeDashboard.id,
                                        name: getUniqueName(dashboards, activeDashboard.name),
                                        shared: true,
                                        startDate: activeDashboard.startDate,
                                        endDate: activeDashboard.endDate,
                                        timePeriod: activeDashboard.timePeriod,
                                    },
                                });
                            }}
                        />
                    </Tooltip>

                    <Tooltip interactive titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.DELETE">
                        <TrashIcon
                            className={css.deleteDashboardIcon}
                            onClick={useCallback(() => {
                                setDeletedItem({ id: activeDashboard.id, type: 'dashboard' });
                            }, [activeDashboard])}
                        />
                    </Tooltip>

                    {!isPreconfigured && (
                        <div className={css.togglePrivatePublic}>
                            <Tooltip interactive titleTranslationKey={toolTipText}>
                                <Toggle
                                    disabled={!isCreator}
                                    className={css.toggleButton}
                                    onToggle={() => {
                                        setChangeToggle(true);
                                    }}
                                    onChange={() => {
                                        setChangeToggle(true);
                                    }}
                                    checked={shared}
                                />
                            </Tooltip>
                            {shared ? (
                                <Show
                                    className={classNames(css.eyeButton, { [css.eyeButtonDisabled]: !isCreator })}
                                    onClick={() => {
                                        if (isCreator) {
                                            setChangeToggle(true);
                                        }
                                    }}
                                />
                            ) : (
                                <Hide className={css.eyeButton} onClick={() => setChangeToggle(true)} />
                            )}
                            <div className={css.toggleText}>{toggleText}</div>
                        </div>
                    )}
                </div>
            </div>

            <PageWrapper>
                <div style={{ marginTop: '-15px' }}>
                    <HelpMenu faqLink="https://support.singular.net/hc/en-us/articles/206241026-Dashboard-FAQ" />
                    <div style={{ marginTop: '15px' }}>
                        <EditableTitle
                            placeholder={translate('STATIC.PAGE_HEADERS.DASHBOARD')}
                            value={activeDashboard ? activeDashboard.name : ''}
                            onTextChanged={setDashboardName}
                            onTextChangeComplete={updateDashboardName}
                            error={dashboardNameErrorMessage}
                        />
                    </div>
                </div>
                {emptyStateOrgData && emptyStateOrgData.show_empty_state_reports && (
                    <div className={css.emptyState}>
                        <EmptyState
                            titleText={emptyStateDashboardData.titleText}
                            subTitleText={emptyStateDashboardData.subTitleText}
                            tasksTodo={emptyStateDashboardData.tasks}
                            tasksDone={emptyStateOrgData.tasks}
                            showCloseIcon={emptyStateDashboardData.tasks.some(
                                task => emptyStateOrgData.tasks[task.topic]
                            )}
                            onCloseEmptyState={onCloseReportsEmptyState}
                            pageName="STATIC.PAGE_HEADERS.DASHBOARD"
                        />
                    </div>
                )}

                <ResizeSensor onResize={onGridResize} />
                {loading && (
                    <div>
                        Loading
                        <ThreeLoadingDots />
                    </div>
                )}

                <GeneralPopup
                    open={!!deletedItem}
                    type={PopupTypes.DELETE}
                    title={
                        !deletedItem ? '' : `STATIC.PAGES.DASHBOARD.DELETE_${deletedItem.type.toUpperCase()}_WARNING`
                    }
                    text="STATIC.PAGES.DASHBOARD.DELETE_IS_PERMANENT_WARNING"
                    acceptText="STATIC.PAGES.DASHBOARD.ACTIONS.DELETE_CONFIRM"
                    rejectText="STATIC.PAGES.ALERTS_V2.DELETE_MODAL.REJECT_BUTTON_TEXT"
                    onAccept={useCallback(() => {
                        const { id, type } = deletedItem;
                        setDeletedItem(null);

                        if (type === 'dashboard') {
                            deleteDashboard({ variables: { id } });
                            return;
                        }

                        const filteredWidgets = widgets.filter(x => x.gridParams.i !== id);
                        onUpdateWidgets(
                            filteredWidgets,
                            widgets.find(w => w.gridParams.i === id),
                            WIDGET_CHANGE_TYPES.DELETED
                        );
                    }, [deletedItem])}
                    onReject={useCallback(() => setDeletedItem(null), [])}
                    showSpinner={false}
                />

                <GeneralPopup
                    open={changeToggle}
                    type={PopupTypes.INFO_WITH_CANCEL}
                    title={`STATIC.PAGES.DASHBOARD.CHANGE_TO_${shared ? 'PRIVATE' : 'PUBLIC'}_TITLE`}
                    text={`STATIC.PAGES.DASHBOARD.CHANGE_TO_${shared ? 'PRIVATE' : 'PUBLIC'}_SUB_TITLE`}
                    acceptText={`STATIC.PAGES.DASHBOARD.MAKE_${shared ? 'PRIVATE' : 'PUBLIC'}`}
                    rejectText="STATIC.PAGES.ALERTS_V2.DELETE_MODAL.REJECT_BUTTON_TEXT"
                    onAccept={changePublicPrivate}
                    onReject={() => {
                        setChangeToggle(false);
                    }}
                    showSpinner={false}
                />

                {error && <div>Error loading dashboard...</div>}
                {activeDashboard && (
                    <CustomDashboardGrid
                        key={activeDashboard.id}
                        dashboard={activeDashboard}
                        widgets={widgets}
                        updateWidgets={onUpdateWidgets}
                        cloneWidget={i => {
                            const clonedWidget = cloneDeep(widgets.find(x => x.gridParams.i === i));
                            clonedWidget.name = getUniqueName(widgets, clonedWidget.name);
                            clonedWidget.gridParams.i = `new.widget.${uuidv4()}`;

                            const [x, y] = getNewWidgetPosition(widgets, clonedWidget);
                            clonedWidget.gridParams.x = x;
                            clonedWidget.gridParams.y = y;

                            onUpdateWidgets([...widgets, clonedWidget], clonedWidget, WIDGET_CHANGE_TYPES.CLONED);
                        }}
                        deleteWidget={i => {
                            setDeletedItem({ id: i, type: 'widget' });
                        }}
                        apiFields={apiFields}
                        width={width}
                        disableReportState={!!disableReportState}
                        updatingGraphQL={updatingGraphQL}
                        dateLimit={dateLimit}
                        updateDate={changeDatePicker}
                    />
                )}
            </PageWrapper>
        </div>
    );
}

Dashboard.propTypes = {
    translate: PropTypes.func.isRequired,
    emptyStateOrgData: PropTypes.objectOf(PropTypes.any),
    dateLimit: PropTypes.objectOf(PropTypes.any),
    onCloseReportsEmptyState: PropTypes.func,
};

Dashboard.defaultProps = {
    emptyStateOrgData: {},
    dateLimit: undefined,
    onCloseReportsEmptyState: () => {},
};

export default withLocalize(Dashboard);
