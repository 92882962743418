import { connect } from 'react-redux';
import { getTranslate } from '../../selectors/locale';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        translate: getTranslate(state),
    };
};

const withTranslate = Component => {
    return connect(mapStateToProps, null, null, { forwardRef: true })(Component);
};

// connects a component to the store, enriching the props with translate function
// use this in cases where a component which uses translations is outside the Main component.
export default withTranslate;
