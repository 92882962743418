import API from './api';

const api = new API();
let instance = null;

const BASE_URL = '/api/attribution/tracking_links';

export default class TrackingLinksAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getTrackingLinks() {
        return new Promise((resolve, reject) => {
            api.getJson(BASE_URL, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    editCampaignName(campaignID, campaignName, campaignData, appID) {
        return new Promise((resolve, reject) => {
            api.getJson(BASE_URL, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    action: 'edit',
                    campaign_id: campaignID,
                    campaign_name: campaignName,
                    campaign: campaignData,
                    app_id: appID,
                }),
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    deleteCampaign(campaign) {
        return new Promise((resolve, reject) => {
            api.getJson(BASE_URL, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    action: 'delete',
                    campaign_id: campaign,
                }),
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
