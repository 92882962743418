import React from 'react';
import { Translate } from 'react-localize-redux';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import css from './TutorialSteps.css';

const StepBox = React.memo(({ number, step }) => (
    <div className={css.stepBox}>
        <div className={css.stepNumber}>{number}</div>
        <div className={css.text}>
            <Translate id={step} />
        </div>
    </div>
));

StepBox.propTypes = {
    number: PropTypes.number.isRequired,
    step: PropTypes.string,
};

const TutorialSteps = ({ title, icon, steps }) => {
    return (
        <div className={css.container}>
            {icon}
            <Typography className={css.title}>
                <Translate id={title} />
            </Typography>
            <div className={css.stepsContainer}>
                {steps.map((step, index) => (
                    <StepBox key={step} step={step} number={index + 1} />
                ))}
            </div>
        </div>
    );
};

TutorialSteps.propTypes = {
    title: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.element,
};

TutorialSteps.defaultProps = {
    steps: [],
};

export default TutorialSteps;
