import { reportTypes } from '../../utils/reports';
import {
    getBaseConfig,
    shelfCohortMetrics,
    shelfCustomEvents,
    shelfDimensions,
    shelfMetrics,
    shelfTimeBreakdowns,
} from './baseReportConfig';
import { shelfPeriods } from './shelfPeriods';

const type = reportTypes.fraudProtected;
const path = '/react/fraud/protected';

const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.FRAUD_PROTECTED');

export default {
    ...basicConfig,
    shelf: [shelfDimensions, shelfMetrics, shelfCohortMetrics, shelfCustomEvents, shelfPeriods, shelfTimeBreakdowns],
    path,
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/360003121852-Fraud-Prevention-Reporting',
        },
    },
};
