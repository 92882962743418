import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const PieChart = ({ title, data }) => {
    const options = {
        chart: {
            type: 'pie',
            backgroundColor: null,
            borderRadius: 8,
            width: 524,
            height: 164,
            spacingBottom: 0,
            spacingTop: 0,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: title,
            align: 'center',
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#555',
            },
            y: 24,
            x: 18,
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            y: 36,
            x: -100,
            labelFormatter() {
                return `<div style="display: flex; align-items: center; justify-content: space-between; width: 190px; margin-bottom: 6px;">
                            <span style="display: flex; align-items: center;">
                                <span style="width: 5px; height: 10px; background-color: ${this.color}; 
                                    border-radius: 50%; margin-right: 8px;"></span> 
                                <span style="font-weight: 400;">${this.name}</span>
                            </span>
                            <span><b>${this.y}%</b></span>
                        </div>`;
            },
            useHTML: true,
            itemStyle: {
                fontSize: '12px',
                color: '#555',
            },
            symbolPadding: 0,
            symbolWidth: 0,
            itemMarginBottom: 6,
        },
        series: [
            {
                name: 'Percentage',
                colorByPoint: true,
                data,
            },
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

PieChart.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            string: PropTypes.string,
        })
    ),
};
export default PieChart;
