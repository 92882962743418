import update from 'immutability-helper';
import { ATHENA_REALTIME_QUERY_START, ATHENA_REALTIME_QUERY_FINISHED, UNLOAD } from './actions';

const initialState = {
    showSpinner: false,
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ATHENA_REALTIME_QUERY_FINISHED: {
            return update(state, {
                showSpinner: { $set: false },
                data: { $set: action.data },
            });
        }
        case ATHENA_REALTIME_QUERY_START: {
            return update(state, {
                showSpinner: {
                    $set: true,
                },
            });
        }
        case UNLOAD:
            return update(state, {
                showSpinner: false,
                data: { $set: [] },
            });
        default:
            return state;
    }
};
