import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import {
    partnerShape,
    eventShape,
    siteShape,
    templateShape,
    TemplateTypes,
    organizationOptionsShape,
} from '../../types';
import css from './EventsDataSection.css';
import EventPostbacksTable from './EventPostbacksTable';
import { getKeyByValue } from '../../../../global/utils';

function EventPostbacksData({
    partner,
    site,
    template,
    events,
    configuredEvents,
    extraTableCells,
    postbackExtraFields,
    tableDataTestId,
    templateType,
    setPostbacks,
    setTouched,
    errors,
    touched,
    isReadonly,
    orgOptions,
}) {
    const templateTypeValue = getKeyByValue(TemplateTypes, templateType);
    const missingTemplateText = `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.NOT_SUPPORTED_${templateTypeValue}_POSTBACKS`;
    const missingEventsText = `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.NOT_RECEIVING_${templateTypeValue}_EVENTS`;

    return (
        <>
            {configuredEvents && template && events.length > 0 ? (
                <>
                    <EventPostbacksTable
                        eventsPostbacks={configuredEvents}
                        partner={partner}
                        events={events}
                        template={template}
                        setPostbacks={setPostbacks}
                        setTouched={setTouched}
                        extraTableCells={extraTableCells}
                        postbackExtraFields={postbackExtraFields}
                        tableDataTestId={tableDataTestId}
                        errors={errors}
                        touched={touched}
                        isReadonly={isReadonly}
                        orgOptions={orgOptions}
                    />
                    <div className={css.comment}>
                        <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.AUTO_GENERATED_EVENTS_NOTE" />
                    </div>
                </>
            ) : null}
            {template ? null : (
                <div className={css.comment}>
                    <Translate
                        id={missingTemplateText}
                        data={{ partnerName: partner.displayName, platform: site.platform }}
                    />
                </div>
            )}
            {events.length > 0 ? null : (
                <div className={css.comment}>
                    <Translate id={missingEventsText} />
                </div>
            )}
        </>
    );
}

EventPostbacksData.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    template: templateShape,
    events: PropTypes.arrayOf(eventShape),
    configuredEvents: PropTypes.arrayOf(PropTypes.object),
    setPostbacks: PropTypes.func,
    extraTableCells: PropTypes.arrayOf(PropTypes.object),
    postbackExtraFields: PropTypes.object,
    tableDataTestId: PropTypes.string,
    templateType: PropTypes.oneOf(Object.values(TemplateTypes)),
    setTouched: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.object),
    touched: PropTypes.arrayOf(PropTypes.object),
    isReadonly: PropTypes.bool,
};

EventPostbacksData.defaultProps = {
    template: null,
    events: [],
    configuredEvents: null,
    setPostbacks: () => {},
    extraTableCells: [],
    postbackExtraFields: {},
    tableDataTestId: '',
    templateType: null,
    setTouched: () => {},
    errors: [],
    touched: [],
    isReadonly: false,
};

export default EventPostbacksData;
