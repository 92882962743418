import { connect } from 'react-redux';
import DimensionDrilldownPopover from '../../components/reportsWidgets/DimensionDrilldownPopover';
import getCurrentPath from '../../selectors/routing';
import { getAvailableFiltersForType, getAvailableDimensionsForDrilldown } from '../../selectors/reports';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        selectedFilters: state.reports.filters,
        dimensionList: getAvailableDimensionsForDrilldown('reports')(state),
        reportSelectedFields: state.reports.selectedFields,
        availableFilters: getAvailableFiltersForType('reports')(state),
        currentPath: getCurrentPath(state),
    };
};

export default connect(mapStateToProps)(DimensionDrilldownPopover);
