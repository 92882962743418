export const SIDENAV_WIDTH = 230;
export const SIDENAV_SLIM_WIDTH = 60;
export const HIDE_SIDEBAR_ON_WIDTH = 1280;

export const TAGS = Object.freeze({
    NEW: 'New',
    BETA: 'Beta',
    TRIAL: 'Trial',
});

export const MENU_SECTIONS_MIXPANEL_PREFIX = 'Menu Sections';
export const MENU_SECTION_CLICKED_MIXPANEL_EVENT_NAME = 'Section clicked';

export const HOMEPAGE_SECTION_NAME = 'home';
