import { connect } from 'react-redux';
import * as actions from 'customDimensions/actions';
import { createCustomDimensionValueSelector } from 'customDimensions/selectors';
import CustomDimensionValue from 'customDimensions/components/CustomDimensionValue';

const mapStateToProps = (state, ownProps) => {
    return createCustomDimensionValueSelector(ownProps.id)(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onConditionAdded: parentId => {
            dispatch(actions.conditionAdded(parentId));
        },
        onConditionRemoved: id => {
            dispatch(actions.conditionRemoved(id));
        },
        onConditionTagAdded: (id, value) => {
            dispatch(actions.conditionTagAdded(id, value));
        },
        onConditionTagRemoved: (id, value) => {
            dispatch(actions.conditionTagRemoved(id, value));
        },
        onConditionValueChanged: (id, value) => {
            dispatch(actions.conditionValueChanged(id, value));
        },
        onConditionDimensionChanged: (id, value) => {
            dispatch(actions.conditionDimensionChanged(id, value));
        },
        onConditionOperatorChanged: (id, value) => {
            dispatch(actions.conditionOperatorChanged(id, value));
        },
        onRuleAdded: parentId => {
            dispatch(actions.ruleAdded(parentId));
        },
        onRuleRemoved: id => {
            dispatch(actions.ruleRemoved(id));
        },
        onValueRemoved: id => {
            dispatch(actions.valueRemoved(id));
        },
        onValueChanged: (id, value) => {
            dispatch(actions.valueNameChanged(id, value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDimensionValue);
