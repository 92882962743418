import { connect } from 'react-redux';
import { getEditShelf } from '../selectors';
import {
    editCancel,
    editSave,
    editDetails,
    addFilter,
    editFilter,
    deleteFilter,
    addCondition,
    editCondition,
    deleteCondition,
    validateSegment,
} from '../actions';
import Edit from '../components/Edit';
import * as consts from '../consts';

const mapDispatchToProps = dispatch => {
    return {
        onEditCancel: (...args) => {
            dispatch(editCancel(...args));
        },
        onEditConfirm: (id, segment) => {
            dispatch(validateSegment(true));
            dispatch(editSave(id, segment));
        },
        onEditDetails: (...args) => {
            dispatch(editDetails(...args));
            dispatch(validateSegment());
        },
        onAddFilter: filterType => {
            const condition = Object.assign({}, consts.DEFAULT_CONDITIONS[filterType]);
            const filter = {
                filter_type: filterType,
                outer_logic: 'include',
                inner_logic: consts.INNER_LOGIC.and,
                conditions: [condition],
            };
            dispatch(addFilter(filter));
        },
        onEditFilter: (...args) => {
            dispatch(editFilter(...args));
            dispatch(validateSegment());
        },
        onDeleteFilter: (...args) => {
            dispatch(deleteFilter(...args));
            dispatch(validateSegment());
        },
        onAddCondition: (index, filterType) => {
            const condition = Object.assign({}, consts.DEFAULT_CONDITIONS[filterType]);
            dispatch(addCondition(index, condition, filterType));
        },
        onEditCondition: (...args) => {
            dispatch(editCondition(...args));
            dispatch(validateSegment());
        },
        onDeleteCondition: (...args) => {
            dispatch(deleteCondition(...args));
            dispatch(validateSegment());
        },
    };
};

export default connect(state => getEditShelf(state), mapDispatchToProps)(Edit);
