import React, { useEffect, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import posed, { PoseGroup } from 'react-pose';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AutoComplete, SingularButton } from '../../components/widgets';
import css from './TopArea.css';
import WhitePlus from '../../resources/svg/plus_white.svg';
import { useAsync, useOutsideEventHandler } from '../../utils/customHooks';
import WarningMessage, { MessageTypes } from '../../teamManagement/components/WizardWarningMessage';
import PartnerConfigService from '../service';
import DownloadIcon from '../../resources/icons/download.svg';
import { convertToCsv, download } from '../../utils/export';
import { getAdminModeEnabled, isUserAdNetwork } from '../../selectors/user';
import Filters, { FilterOptions } from './Filters';
import { trackPartnerConfigMixpanelEvent } from './shelf/utils';

const api = new PartnerConfigService();

const PartnerSelectionAnimationItem = posed.div({
    preEnterPose: {
        x: -20,
        opacity: 0,
        transition: { duration: 150 },
    },
    enter: {
        x: 0,
        opacity: 1,
        transition: {
            x: { type: 'tween', duration: 300, ease: 'anticipate' },
        },
    },
    exit: {
        x: 20,
        opacity: 0,
        transition: { duration: 150 },
    },
});

const EXPORT_ALL_FIELDS = [
    'Partner',
    'Agency',
    'App',
    'Bundle id',
    'Site Public ID',
    'OS',
    'Send view through Postbacks',
    'Click-through attribution lookback window',
    'Postback Type',
    'Event Lookback Type',
    'Event Lookback Window',
    'Event SDK name',
    'Event Partner Name',
    'Attributed Only',
    'Postback URL',
    'Created',
    'Updated',
    'Is Postback URL customized',
    'Tracker Name Filter',
    'Limited Data Sharing restriction',
    'Limited Data Sharing Default',
    'Country Limitation type',
    'Country Limitation',
];

export const PARTNER_SELECTION_DATA_TEST_ID = 'partner-selection';

// we need a variable since the linter changes setFocus={true} => setFocus
const FOCUS_ON_AUTOCOMPLETE = true;

const getExportAllFileName = () => `singular_postbacks_${new Date().toISOString()}.csv`;

export default function TopArea({ partnersOptions, filter, filterLoading, onAddSiteForPartner, onFilterChanged }) {
    const partnerSelectionRef = useRef(null);
    const [exportAllResponse, run] = useAsync();
    const [partnerSelectionOpen, setPartnerSelectionOpen] = useState(false);
    const isAdminMode = useSelector(state => getAdminModeEnabled(state));
    const isPartner = useSelector(state => isUserAdNetwork(state));
    const exportAllStartTime = useRef(null);

    useOutsideEventHandler(partnerSelectionRef, () => setPartnerSelectionOpen(false), [{ name: 'mousedown' }]);

    const { data: exportAllData, isLoading: isLoadingExportAll, error: exportAllError } = exportAllResponse;

    useEffect(() => {
        if (isLoadingExportAll) {
            exportAllStartTime.current = performance.now();
        }

        if (exportAllData) {
            const fileName = getExportAllFileName();
            const fields = [...EXPORT_ALL_FIELDS, ...(isAdminMode ? ['Rule ID'] : [])];
            const csvData = convertToCsv(fields, exportAllData);
            download(fileName, csvData);

            trackPartnerConfigMixpanelEvent('Export Button Clicked', null, null, {
                durationInMilliseconds: performance.now() - exportAllStartTime.current,
            });
        }
    }, [exportAllResponse]);

    const onExportClick = () => run(api.exportAll());

    const onAddPartner = ({ id, label }) => {
        const mixpanelParams = { partner: label, button: 'Add Partner' };
        trackPartnerConfigMixpanelEvent('Add Clicked', null, null, mixpanelParams);

        onAddSiteForPartner(id);
    };

    return (
        <div>
            <div className={css.topArea}>
                <Filters filter={filter} filterLoading={filterLoading} onFilterChanged={onFilterChanged} />
                <div className={css.actionsContainer} ref={partnerSelectionRef}>
                    <SingularButton
                        type="secondary"
                        className={css.export}
                        showSpinner={!!isLoadingExportAll}
                        Icon={DownloadIcon}
                        iconClass={css.downloadIcon}
                        onClick={onExportClick}
                    >
                        <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.EXPORT_ALL" />
                    </SingularButton>
                    {isPartner ? null : (
                        <SingularButton Icon={WhitePlus} onClick={() => setPartnerSelectionOpen(!partnerSelectionOpen)}>
                            <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.ADD_PARTNER" />
                        </SingularButton>
                    )}
                    <PoseGroup animateOnMount>
                        {partnerSelectionOpen && (
                            <PartnerSelectionAnimationItem key="partnerSelection">
                                <AutoComplete
                                    searchable
                                    dataTestId={PARTNER_SELECTION_DATA_TEST_ID}
                                    setFocus={FOCUS_ON_AUTOCOMPLETE}
                                    containerClass={css.newPartnerInput}
                                    // prevent the apps table sticky header from hiding the list dropdown
                                    selectOptions={{ options: partnersOptions, usePortal: true }}
                                    onChange={onAddPartner}
                                />
                            </PartnerSelectionAnimationItem>
                        )}
                    </PoseGroup>
                </div>
            </div>
            <WarningMessage
                type={MessageTypes.ERROR}
                show={!!exportAllError}
                duration={1000}
                message="STATIC.PAGES.PARTNER_CONFIGURATION.EXPORT_ALL_FAILURE_MESSAGE"
            />
        </div>
    );
}

TopArea.propTypes = {
    filter: PropTypes.shape({
        text: PropTypes.string,
        option: PropTypes.oneOf(Object.values(FilterOptions)),
    }),
    partnersOptions: PropTypes.arrayOf(PropTypes.any),
    filterLoading: PropTypes.bool,
    onAddSiteForPartner: PropTypes.func.isRequired,
    onFilterChanged: PropTypes.func.isRequired,
};

TopArea.defaultProps = {
    filter: null,
    partnersOptions: [],
    filterLoading: false,
};
