import React from 'react';
import PropTypes from 'prop-types';

export default function BasicSetupIntro(props) {
    const { isApsalarEnabled } = props;
    return (
        <div>
            <p>
                <span>This page will walk you through setting up your Singular account, running campaigns, and viewing reports.</span>
            </p>
            <p>
                <span>We’ll show you how to:</span>
            </p>
            {isApsalarEnabled && (
                <ul>
                    <li>
                        <span>Add your apps and Data Connectors to start analyzing all your campaigns</span>
                    </li>
                    <li>
                        <span>Activate mobile attribution by integrating the Singular SDK&nbsp;</span>
                    </li>
                    <li>
                        <span>Set up campaigns with Singular tracking links</span>
                    </li>
                    <li>
                        <span>Run reports that combine campaign data and attribution stats to calculate ROI and performance</span>
                    </li>
                </ul>
            )}
            {!isApsalarEnabled && (
                <ul>
                    <li>
                        <span>Add your Data Connectors to start analyzing all your campaigns</span>
                    </li>
                    <li>
                        <span>Customize your reports by setting up rules to help you normalize and group your data</span>
                    </li>
                    <li>
                        <span>Bookmark reports for easy access</span>
                    </li>
                </ul>
            )}
        </div>
    );
}

BasicSetupIntro.propTypes = {
    isApsalarEnabled: PropTypes.bool,
};

BasicSetupIntro.defaultProps = {
    isApsalarEnabled: undefined,
};
