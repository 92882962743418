import { reportBase } from './reportsBase';
import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';

export default {
    type: reportTypes.skan,
    fields: [
        ...reportBase.fields,
        'skanConversionDimensions',
        'skan_redownloads_dimensions',
        'skan_validated_dimensions',
        'confidenceIntervalFlag',
        'skanDateDimensionName',
        'withDruidAppend',
    ],
    visibleRule: rules[reportTypes.skan],
    dataSelector: {
        ...reportBase.dataSelector,
        skanConversionDimensions: 'skanConversionDimensions',
        skanDateDimensionName: 'skanDateDimensionName',
        skan_redownloads_dimensions: 'skan_redownloads_dimensions',
        showRedownloadsDimensionInReport: 'showRedownloadsDimensionInReport',
        skan_validated_dimensions: 'skan_validated_dimensions',
        confidenceIntervalFlag: 'confidenceIntervalFlag',
        showValidatedDimensionInReport: 'showValidatedDimensionInReport',
        tableDataFormatted: 'reportData',
        chart: 'chart',
        chartDataFormatted: 'chartData',
        openFieldsAvailability: 'openFieldsAvailability',
        fieldsAvailabilityFieldName: 'fieldsAvailabilityFieldName',
        withDruidAppend: 'withDruidAppend',
    },
    isSkan: true,
    sourceAttributionType: ['skan'],
};
