export const loadGoogleSDK = (onLoad) => {

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://accounts.google.com/gsi/client";
        js.setAttribute('async', '')
        js.setAttribute('defer', '')
        if (onLoad) {
            js.onload = () => onLoad();
        }
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
};
