import React, { Fragment } from 'react';
import {
    withHighcharts,
    HighchartsChart,
    Chart,
    Title,
    Legend,
    XAxis,
    YAxis,
    LineSeries,
    ColumnSeries,
    Tooltip,
} from 'react-jsx-highcharts';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import debounce from 'lodash/debounce';
import { Translate, withLocalize } from 'react-localize-redux';
import moment from 'moment';
import { Tooltip as Tippy } from 'react-tippy';
import css from './LineChart.css';
import Toggle from '../widgets/Toggle';
import Spinner from '../widgets/Spinner';
import { normalizeTimeBreakDown, isFullSpanPeriod } from '../../utils/regional';
import { colorLuminance } from '../../utils/colors';
import { fonts, generateArrayOfSize } from '../../global/utils';
import PlusIcon from '../../resources/svg/icon_chart_add_metric.svg';
import MinusIcon from '../../resources/svg/icon_chart_remove_metric.svg';
import { Dropdown, EmptyState } from '../widgets';
import { trackMixpanelEvent, REPORTS_EVENT_PREFIX } from '../../utils/general';

import {
    lineColors,
    valueFormatter,
    dashStyles,
    tooltipFormatter,
    legendFormatter,
    dashStylesCSS,
    tooltipPositioner,
    metricsPriority,
} from '../../utils/charts';

Highcharts.setOptions({
    lang: {
        thousandsSep: ',',
    },
});

const _isVisible = (item) => {
    return !('visible' in item) || item.visible;
};

const colors = {
    text: '#AAAAAA',
    secondaryText: '#AAAAAA',
    textLighter: '#CCCCCC',
    darkText: '#515864',
    axis: '#AAAAAA',
    title: '#777D84',
    background: '#FFFFFF',
    tooltipBackground: '#FFFFFF',
    primary: '#00a1ff',
    inactive: '#E1E1E1',
};

let breakdown;

const formatSingleXlabel = (value, isFirst, axis) => {
    const date = moment(value, 'YYYY-MM-DD');
    let ret = date.date();
    if (isFirst) {
        ret = `${ret}<br/>${date.format('MMMM')}`;
    } else {
        const index = axis.categories.indexOf(value);
        const prev = axis.categories[index - 1];
        const prevMonth = moment(prev).month();
        const currentMonth = moment(value).month();
        if (prevMonth !== currentMonth) {
            ret = `${ret}<br/>${date.format('MMMM')}`;
        }
    }
    return ret;
};

const styles = {
    metricTooltip: {
        style: {
            boxShadow: 'rgba(68, 75, 87, 0.25) -2px 2px 12px 0px',
            border: 'none',
            marginTop: '-3px',
            fontSize: '14px',
            fontWeight: 300,
            color: '#000000',
            borderRadius: '3px',
            cursor: 'default',
        },
    },
    highchartsChart: {
        plotOptions: {
            column: {
                stacking: 'normal',
                brightness: 0.3,
                tooltip: {
                    distance: 16,
                },
            },
            series: {
                events: {
                    mouseOver() {
                        trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Hover on point');
                    },
                },
            },
        },
    },
    chart: {
        style: {
            fontFamily: fonts,
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 7,
        alignTicks: true,
        backgroundColor: colors.background,
        responsive: {
            rules: [],
        },
    },
    legend: {
        itemStyle: {
            color: colors.secondaryText,
            fontSize: '14px',
            fontWeight: '400',
        },
        itemHiddenStyle: {
            color: colors.textLighter,
        },
        navigation: {
            activeColor: colors.primary,
            inactiveColor: colors.inactive,
        },
        floating: false,
        itemMarginBottom: 10,
        backgroundColor: colors.background,
        align: 'right',
        verticalAlign: 'middle',
        x: 0,
        y: 0,
        padding: 10,
        layout: 'vertical',
        useHTML: true,
        labelFormatter: legendFormatter(css),
        symbolWidth: 0.001,
        symbolRadius: 0.001,
        symbolHeight: 0.001,
        symbolPadding: 0,
        squareSymbol: false,
        maxHeight: 280,
    },
    tooltip: {
        backgroundColor: colors.tooltipBackground,
        shadow: false,
        style: {
            boxShadow: '0 0 3px 1px rgba(0,0,0,0.2)',
        },
        formatter: tooltipFormatter(css, breakdown),
        useHTML: true,
        padding: 0,
        borderWidth: 0,
        shared: true,
        positioner: tooltipPositioner,
    },
    title: {
        style: {
            color: colors.darkText,
            fontSize: '16px',
            fontWeight: 500,
            maxWidth: '340px',
            whiteSpace: 'normal',
        },
        align: 'left',
        margin: 40,
        x: 0,
        useHTML: true,
    },
    xAxis: {
        tickLength: 0,
        labels: {
            style: {
                color: colors.text,
                fontSize: '12px',
                fontWeight: 400,
                textAlign: 'right',
            },
            formatter() {
                // eslint-disable-line object-shorthand
                if (this.value && this.value.includes('...')) {
                    // Handle x label with dates span
                    const split = this.value.split(' ... ');
                    return `<div class="${css.xLabelHolder} ${
                        isFullSpanPeriod(breakdown, split[0], split[1]) ? '' : css.partial
                    }">
                                <span class="${css.xLabelItem}">
                                    ${formatSingleXlabel(split[0], true, this.axis)}
                                </span>
                                <span class="${css.xLabelItem}" style="margin: 0 2px"> - </span> 
                                <span class="${css.xLabelItem}">
                                    ${formatSingleXlabel(split[1], true, this.axis)}
                                </span>
                            </div>`;
                }
                this.breakdown = breakdown;
                return formatSingleXlabel(this.value, this.isFirst, this.axis);
            },
            overflow: 'justify',
            useHTML: true,
        },
        tickmarkPlacement: 'on',
        crosshair: {
            color: '#CCCCCC',
            dashStyle: 'Solid',
            snap: true,
            width: 1,
            zIndex: 2,
        },
        lineColor: '#E1E1E1',
    },
    yAxis: {
        gridLineColor: '#EBECED',
    },
    types: {
        line: {
            classType: LineSeries,
            markerEnabled: true,
            props: {
                zIndex: 2,
                animation: {
                    duration: 500,
                },
            },
            brightness: 0,
        },
        bar: {
            classType: ColumnSeries,
            markerEnabled: false,
            props: {
                zIndex: 1,
                maxPointWidth: 100,
                animation: false,
                stacking: 'normal',
            },
            dashStyle: dashStyles[0],
            dashStyleCSS: dashStylesCSS[0],
            brightness: 0.1,
        },
    },
};

class LineChart extends React.Component {
    constructor(props) {
        super(props);

        const normalizedMetrics = this._normalizeMetrics(props);
        this.state = {
            metrics: normalizedMetrics,
            xData: this._getXAxis(normalizedMetrics, props),
            lines: this.props.chart.lines,
            showLegend: this.props.showLegend,
        };
        this.toggleLegend = this._toggleLegend.bind(this);
        this.getTitle = this._getTitle.bind(this);
        this.handleLinesChanged = this._handleLinesChanged.bind(this);
        this.updateLinesChanged = debounce(
            () => {
                this.props.onLinesChanged(this.state.lines);
            },
            1000,
            { trailing: true }
        );
        this.hiddenItems = new Set();
        this.loadedReported = false;
        this.metricsLimitReached = false;
        breakdown = props.timeBreakdown;
        styles.tooltip.formatter = tooltipFormatter(css, breakdown);
    }

    componentDidMount() {
        this._setMidContainerMinWidth();
    }

    componentWillReceiveProps(nextProps) {
        const normalizedMetrics = this._normalizeMetrics(nextProps);
        const currentKeys = Object.keys(this.props.chartData);
        const nextKeys = Object.keys(nextProps.chartData);
        const currentXAxis = this._getXAxis(this.state.metrics, this.props);
        const nextXAxis = this._getXAxis(normalizedMetrics, nextProps);
        const currentDimensions = Object.keys(this.props.chartData.dimensions);
        const nextDimensions = Object.keys(nextProps.chartData.dimensions);
        breakdown = nextProps.timeBreakdown;
        styles.tooltip.formatter = tooltipFormatter(css, breakdown);
        if (
            currentKeys.length !== nextKeys.length ||
            currentXAxis.length !== nextXAxis.length ||
            currentDimensions.length !== nextDimensions.length
        ) {
            this.hiddenItems = new Set();
            this.loadedReported = false;
            this.setState({
                metrics: normalizedMetrics,
                xData: nextXAxis,
            });
        }
    }

    componentDidUpdate() {
        this._setMidContainerMinWidth();
    }

    _setMidContainerMinWidth() {
        if (!this.metricsBar || !this.metricsBar.children || !this.metricsBar.children.length) {
            return;
        }
        const first = this.metricsBar.children[0].getBoundingClientRect();
        const last = this.metricsBar.children[Math.min(this.metricsBar.children.length - 1, 5)].getBoundingClientRect();
        const minWidth = Math.max(last.left - first.left + last.width + 48, 650);
        this.midContainer.style.minWidth = `${minWidth}px`;
    }

    _normalizeMetrics(props) {
        const {
            chartData,
            chartData: { orgOptions = {} },
            chart: { selectedMetrics },
        } = props || this.props;
        let selectedCount = 0;
        // In case the limit is one, `numberOfSelectedMetricsOnInit` will be one. Otherwise, it'll be one less than the limit.
        // (BY PRODUCT DECISION)
        const numberOfSelectedMetricsOnInit = Math.max(orgOptions.metricsLimit - 1, 1);
        const mappedMetrics = chartData.metrics.map((metric) => {
            const metricCopy = { ...metric };
            metricCopy.lines = this._getLines(metricCopy, props);
            metricCopy.empty = !this._calculatedDataLength([metricCopy]);
            metricCopy.formatter = valueFormatter(metricCopy);
            metricCopy.yAxisFormatter = valueFormatter(metricCopy, true);
            metricCopy.shown = false;
            metricCopy.visible = selectedMetrics.includes(metricCopy.name) && !metricCopy.empty;
            // Metrics that are prioritized (metricsPriority) will be shown first, based on their location on that list.
            // Others will be shown in order of appeareance in the chart data.
            metricCopy.priority = metricsPriority.includes(metricCopy.name)
                ? metricsPriority.indexOf(metricCopy.name)
                : metricsPriority.length;

            if (metricCopy.visible) {
                selectedCount++;
            }
            return metricCopy;
        });
        const metricsToToggle = Math.max(numberOfSelectedMetricsOnInit - selectedCount, 0); // The amount remaining to fill in the limit.
        mappedMetrics
            .filter((m) => !m.empty && !m.visible) // From all the non empty and non visible (selected) fields
            .sort((a, b) => a.priority - b.priority) // Sorted by their priority (non prioritized will come in order of their appeareance in mappedMetrics
            .slice(0, metricsToToggle) // Fill in the limit
            .forEach((m) => {
                m.visible = true;
            });
        return mappedMetrics;
    }

    _toggleLegend() {
        const { showLegend } = this.state;
        this.setState({ showLegend: !showLegend });
        requestAnimationFrame(() => {
            this.props.onChartLegendToggle();
        });
    }

    _setCurrentMetric(metric, icon) {
        const {
            chartData: { orgOptions },
            onMetricSelected,
        } = this.props;
        const newMetrics = [...this.state.metrics];
        const metricIndex = newMetrics.findIndex((cMetric) => cMetric.name === metric.name);
        const visibleMetrics = newMetrics.filter((cMetric) => _isVisible(cMetric));
        // check if the selected metric is the only selected metric
        if (_isVisible(newMetrics[metricIndex]) && visibleMetrics.length === 1) {
            return;
        }
        // check if the max metrics limit was reached
        if (
            _isVisible(newMetrics[metricIndex]) ||
            orgOptions.metricsLimit === 1 ||
            visibleMetrics.length < orgOptions.metricsLimit
        ) {
            // if a metrics selection was made, instead of metric addition
            if (!icon || orgOptions.metricsLimit === 1) {
                for (let i = 0; i < visibleMetrics.length; i++) {
                    const metricCopy = { ...visibleMetrics[i] };
                    metricCopy.visible = false;
                    const visibleMetricIndex = newMetrics.findIndex((cMetric) => cMetric.name === metricCopy.name);
                    newMetrics.splice(visibleMetricIndex, 1, metricCopy);
                }
            }
            const metricCopy = { ...newMetrics[metricIndex] };
            metricCopy.visible = !metricCopy.visible;
            newMetrics.splice(metricIndex, 1, metricCopy);
            this.metricsLimitReached =
                newMetrics.filter((cMetric) => _isVisible(cMetric)).length >= orgOptions.metricsLimit;
            setTimeout(() => {
                onMetricSelected(metricCopy, this._getChartParams());
            }, 50);

            this.setState({
                metrics: newMetrics,
            });
        }
    }

    _getLines(metric, inProps) {
        const { chartData } = inProps || this.props;
        const itemsMap = {};
        const metricDates = Object.keys(chartData[metric.name]).sort();
        const dates = this._enumerateBetweenDates(metricDates[0], metricDates[metricDates.length - 1]);
        if (!dates.length) {
            return [
                {
                    id: `fake_${metric.name}`,
                    name: metric.name,
                    hash: `fake_${metric.name}`,
                    data: ['N/A'],
                    formatter: valueFormatter(metric),
                },
            ];
        }
        const dimensionKeys = Object.keys(chartData.dimensions);
        let counter = 0;
        for (const date of dates) {
            const items = chartData[metric.name][date];
            dimensionKeys.forEach((dim) => {
                const itemValue = items && items[dim] && items[dim] !== 'N/A' ? items[dim] : null;
                const itemTrans = chartData.dimensions[dim];
                if (!itemsMap[dim]) {
                    itemsMap[dim] = {};
                }
                if (!itemsMap[dim].data) {
                    itemsMap[dim].data = [];
                }
                itemsMap[dim].id = `${dim}_${metric.name}`;
                itemsMap[dim].name = itemTrans;
                itemsMap[dim].hash = dim;
                if (!itemsMap[dim].xValue) itemsMap[dim].xValue = date;
                itemsMap[dim].data.push({
                    y: itemValue,
                    name: `item_${dim}_${counter}`,
                });
                itemsMap[dim].formatter = valueFormatter(metric);
            });
            counter++;
        }

        return Object.values(itemsMap).map((item) => {
            // go over all item data and check if there are no direct neighbors
            // if there aren't any neighbors make the marker visible
            item.data = item.data.map((dataObj, index) => {
                if (dataObj && this._isAlone(item.data, index)) {
                    dataObj.marker = { enabled: true };
                }
                return dataObj;
            });
            return item;
        });
    }

    _isAlone(data, index) {
        return (!data[index + 1] || !data[index + 1].y) && (!data[index - 1] || !data[index - 1].y);
    }

    _enumerateDaysBetweenDates(startDate, endDate) {
        const dates = [];

        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');

        do {
            dates.push(currDate.clone().format('YYYY-MM-DD'));
        } while (currDate.add(1, 'days').diff(lastDate) <= 0);

        return dates;
    }

    _enumerateSpansBetweenDates(startDate, endDate) {
        const { timeBreakdown } = this.props;
        const dates = [];

        if (!startDate || !endDate) {
            return dates;
        }

        const currDate = moment(startDate.split(' ... ')[0]).startOf('day');
        const end = moment(endDate.split(' ... ')[1]).startOf('day');
        do {
            const prev = currDate.format('YYYY-MM-DD');
            currDate.endOf(timeBreakdown);
            const next = currDate.diff(end) > 0 ? end.format('YYYY-MM-DD') : currDate.format('YYYY-MM-DD');
            dates.push(`${prev} ... ${next}`);
            currDate.add(1, 'day');
        } while (currDate.diff(end) <= 0);

        return dates;
    }

    _enumerateBetweenDates(startDate, endDate) {
        const { timeBreakdown } = this.props;
        if (timeBreakdown !== 'day') {
            return this._enumerateSpansBetweenDates(startDate, endDate);
        } else {
            return this._enumerateDaysBetweenDates(startDate, endDate);
        }
    }

    _getXAxis(metrics, inProps) {
        const { chartData, chart } = inProps || this.props;
        let dates = [];
        if (metrics[0] && metrics[0].name) {
            const metricsDates = Object.keys(chartData[metrics[0].name]).sort();
            dates = this._enumerateBetweenDates(metricsDates[0], metricsDates[metricsDates.length - 1]);
            if (!dates.length) {
                dates = this._enumerateBetweenDates(chart.request.start_date, chart.request.end_date);
            }
        }
        return dates;
    }

    _isMetricSelected(cMetric) {
        const { metrics } = this.state;
        const foundMetric = metrics.find((metric) => cMetric.name === metric.name);
        return foundMetric && _isVisible(foundMetric);
    }

    _getAxisOptions(metric) {
        const { metrics } = this.state;
        const chartType = metric.chart_type || 'line';
        const visibleMetrics = metrics.filter((cMetric) => _isVisible(cMetric));
        const visibleMetricsLine = visibleMetrics.filter(
            (cMetric) => !cMetric.chart_type || cMetric.chart_type === 'line'
        );
        const metricIndex = visibleMetrics.findIndex((cMetric) => cMetric.name === metric.name);
        const metricLineIndex = visibleMetricsLine.findIndex((cMetric) => cMetric.name === metric.name);
        const seriesStyle = styles.types[chartType];
        return {
            dashStyle: seriesStyle.dashStyle || dashStyles[metricLineIndex],
            dashStyleCSS: seriesStyle.dashStyleCSS || dashStylesCSS[metricLineIndex],
            opposite: metricIndex % 2 === 1,
            first: visibleMetrics[0],
            currentIndex: metricIndex,
            seriesStyle,
        };
    }

    _getTopLines() {
        const { chartData } = this.props;
        const { lines } = this.state;
        if (chartData.dimensionsSorted === undefined) {
            return Object.keys(chartData.dimensions);
        }
        return chartData.dimensionsSorted.slice(0, lines);
    }

    _getTitle() {
        const { metrics } = this.state;
        const { timeBreakdown } = this.props;
        const visibleMetrics = metrics.filter((metric) => _isVisible(metric));
        if (!visibleMetrics || !visibleMetrics.length) {
            return '';
        }
        let ret = visibleMetrics.reduce((total, current, index) => {
            let separator = ', ';
            if (index === visibleMetrics.length - 1) {
                separator = '';
            }
            return `${total} ${current.display_name}${separator} `;
        }, '');
        ret = ret.replace(/,([^,]*)$/, ' and$1');
        ret = (
            <Translate
                id="STATIC.REPORT_CHART_TITLE_2"
                data={{
                    metrics: ret,
                    time: normalizeTimeBreakDown(timeBreakdown),
                }}
            />
        );
        return ret;
    }

    _handleLinesChanged(val) {
        let value = val;
        try {
            value = parseInt(value, 10);
        } catch (e) {}
        if (typeof value !== 'number' || value > 15 || value < 1) {
            return;
        }
        this.setState({
            lines: val,
        });
        this.updateLinesChanged();
    }

    _calculatedDataLength(metrics) {
        return metrics
            .map((metric) => metric.lines)
            .reduce((total, item) => total.concat(item), [])
            .reduce((total, item) => total.concat(item.data), [])
            .filter((item) => item && item.y !== 'N/A' && item.y !== null).length;
    }

    _getMetricBarIcon(visibleMetrics, isSelected, orgOptions, disabled) {
        let ret = null;
        if (orgOptions.metricsLimit === 1 || disabled) {
            ret = null;
        } else if (visibleMetrics.length === orgOptions.metricsLimit) {
            ret = isSelected ? <MinusIcon /> : null;
        } else if (visibleMetrics.length <= 1) {
            ret = isSelected ? null : <PlusIcon />;
        } else if (visibleMetrics.length > 1) {
            ret = isSelected ? <MinusIcon /> : <PlusIcon />;
        }
        return ret;
    }

    _updateMetricProps(metric, props) {
        const newMetrics = [...this.state.metrics];
        const metricCopy = { ...metric, ...props };
        const metricIndex = newMetrics.findIndex((iMetric) => iMetric.name === metricCopy.name);
        newMetrics.splice(metricIndex, 1, metricCopy);
        this.setState({
            metrics: newMetrics,
        });
    }

    _getChartParams() {
        const { chartData } = this.props;
        const { metrics, lines } = this.state;
        return {
            title: this._getTitle(),
            metrics: metrics.map(({ name, display_name, format, shown, visible, empty }) => {
                return {
                    name,
                    display_name,
                    format,
                    shown,
                    visible,
                    empty,
                };
            }),
            num_ref_rows: lines,
            raw_data: chartData,
        };
    }

    render() {
        const {
            chartData,
            chartData: { orgOptions },
            chart: { showError: storeShowError },
            translate,
            className,
            showTopLinesInput,
            onMouseEnter,
            onMouseLeave,
            numOfTableRows,
            spinnerStyle,
        } = this.props;
        const { metrics, xData, lines, showLegend } = this.state;
        const renderMetrics = metrics.filter((metric) => _isVisible(metric) || metric.shown);
        const renderLabels = this._getTopLines();
        const visibleMetrics = metrics.filter((metric) => _isVisible(metric));
        const showError = !this._calculatedDataLength(visibleMetrics) || storeShowError;
        const showSpinner = !metrics.length && !storeShowError;
        if (!showSpinner && !this.loadedReported) {
            this.loadedReported = true;
            setTimeout(() => {
                this.props.onChartLoaded(this._getChartParams());
            });
        }
        return (
            <div className={`${css.container} ${className}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div
                    className={css.midContainer}
                    ref={(el) => {
                        this.midContainer = el;
                    }}
                >
                    <Spinner expanded show={showSpinner} style={spinnerStyle} />
                    {showSpinner ? <div className={css.metricsBarPlaceholder} /> : null}
                    {!showSpinner
                        ? [
                            <div
                                key="chart_metrics_bar"
                                className={`${css.metricsbar} ${metrics.length === 0 ? css.noMetrics : ''}`}
                                ref={(el) => {
                                      this.metricsBar = el;
                                  }}
                              >
                                {metrics.map((cMetric) => {
                                      const isSelected = this._isMetricSelected(cMetric);
                                      const options = this._getAxisOptions(cMetric);
                                      const { empty } = cMetric;
                                      return (
                                          <button
                                              key={cMetric.name}
                                              onClick={() => {
                                                  if (empty) {
                                                      return;
                                                  }
                                                  this._setCurrentMetric(cMetric, true);
                                              }}
                                              className={`${css.metricButton} ${isSelected ? css.selected : ''} ${
                                                  empty ? css.disabled : ''
                                              }`}
                                          >
                                              <div className={css.metricButtonIcon}>
                                                  {this._getMetricBarIcon(
                                                      visibleMetrics,
                                                      isSelected,
                                                      orgOptions,
                                                      empty
                                                  )}
                                              </div>
                                              <Tippy
                                                  title={translate(
                                                      empty
                                                          ? 'STATIC.PAGES.REPORTS.CHART_METRIC_NO_DATA'
                                                          : 'STATIC.PAGES.REPORTS.CHART_MAX_REACHED',
                                                      { max: chartData.orgOptions.metricsLimit }
                                                  )}
                                                  position="bottom"
                                                  popperOptions={{
                                                      modifiers: {
                                                          preventOverflow: { enabled: false },
                                                          hide: { enabled: false },
                                                      },
                                                  }}
                                                  inertia
                                                  animation="scale"
                                                  distance={10}
                                                  theme="light"
                                                  size="big"
                                                  trigger={empty ? 'mouseenter click' : 'click'}
                                                  disabled={(!empty && !this.metricsLimitReached) || isSelected}
                                              >
                                                  <span className={css.metricButtonLabel}>{cMetric.display_name}</span>
                                              </Tippy>
                                              {isSelected ? (
                                                  <div className={css.metricButtonBorder}>
                                                      <svg style={{ width: '100%', height: '3px' }}>
                                                          <line
                                                              x1="0%"
                                                              y1="0%"
                                                              x2="100%"
                                                              y2="0%"
                                                              style={{
                                                                  stroke: colors.primary,
                                                                  strokeWidth: 6,
                                                                  strokeDasharray: options.dashStyleCSS,
                                                              }}
                                                          />
                                                      </svg>
                                                  </div>
                                              ) : null}
                                          </button>
                                      );
                                  })}
                            </div>,
                              !showError ? (
                                  <div className={css.topbarContainer} key="chart_topbar_container">
                                      <div className={css.topbarTitle}>{this._getTitle()}</div>
                                      <div className={css.topbarItems}>
                                          {showTopLinesInput && (
                                              <Fragment>
                                                  <div className={css.numberOfLinesContainer}>
                                                      <span className={css.numberOfLinesLabel}>
                                                          <Translate id="STATIC.SHOW_CHART_TOP_ROWS3" />
                                                      </span>
                                                      <Dropdown
                                                          containerClass={css.numberOfLinesDropdown}
                                                          popperClass={css.numberOfLinesDropdown}
                                                          selected={{
                                                              name: Math.min(lines, numOfTableRows),
                                                              display_name: Math.min(lines, numOfTableRows),
                                                          }}
                                                          onSelection={(s) => {
                                                              this.handleLinesChanged(s.name);
                                                          }}
                                                          items={generateArrayOfSize(Math.min(15, numOfTableRows)).map(
                                                              (i) => {
                                                                  return { name: i + 1, display_name: i + 1 };
                                                              }
                                                          )}
                                                      />
                                                  </div>
                                                  <span className={css.spacerVertical} />
                                              </Fragment>
                                          )}
                                          <Toggle
                                              label={<Translate id="STATIC.BUTTONS.LEGEND_TOGGLE" />}
                                              onToggle={this.toggleLegend}
                                              style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                              labelStyle={{
                                                  color: colors.darkText,
                                                  fontWeight: 300,
                                                  fontSize: '15px',
                                                  margin: '2px 0 0 0',
                                              }}
                                              iconStyle={{ paddingLeft: 0 }}
                                              checked={showLegend && !showError}
                                              disabled={showError}
                                          />
                                      </div>
                                  </div>
                              ) : null,
                          ]
                        : null}
                    <HighchartsChart className={css.highcartsContainer} {...styles.highchartsChart}>
                        {showError && !showSpinner ? (
                            <div className={css.noDataContainer}>
                                <EmptyState icon="noChartData" header="STATIC.PAGES.REPORTS.CHART_NO_DATA" />
                            </div>
                        ) : null}

                        <Chart {...styles.chart} />

                        {!showSpinner
                            ? [
                                  <XAxis
                                    key="chart_xAxis"
                                    categories={xData}
                                    max={xData.length - 1}
                                    {...styles.xAxis}
                                  />,
                                  <Legend {...styles.legend} key="chart_legend" enabled={showLegend && !showError} />,
                              ]
                            : null}

                        <Tooltip {...styles.tooltip} />

                        {renderMetrics.map((metric) => {
                            metric.shown = true;
                            const metricLines = _isVisible(metric)
                                ? metric.lines.filter(({ hash }) => renderLabels.includes(hash))
                                : [];
                            const options = this._getAxisOptions(metric);
                            return (
                                <YAxis
                                    key={metric.name}
                                    id={metric.display_name}
                                    labels={{
                                        style: {
                                            color: colors.text,
                                            fontSize: '12px',
                                            fontWeight: 400,
                                        },
                                        formatter: metric.yAxisFormatter,
                                    }}
                                    opposite={options.opposite}
                                    title={{
                                        text:
                                            _isVisible(metric) && visibleMetrics.length > 1
                                                ? metric.display_name
                                                : null,
                                        style: {
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: colors.darkText,
                                        },
                                    }}
                                    {...styles.yAxis}
                                >
                                    {metricLines.map((line, lineIndex) => {
                                        const seriesBrightness =
                                            options.seriesStyle.brightness + Math.floor(lineIndex / 10) * 0.3;
                                        const seriesColor = colorLuminance(
                                            lineColors[lineIndex % 10],
                                            seriesBrightness
                                        );
                                        return (
                                            <options.seriesStyle.classType
                                                id={line.id}
                                                key={line.id}
                                                stack={`${line.xValue}_${metric.name}`}
                                                marker={{
                                                    enabled: line.data.length <= 1,
                                                    symbol: 'circle',
                                                    fillColor: '#ffffff',
                                                    lineColor: null,
                                                    lineWidth: 1,
                                                    radius: options.seriesStyle.markerEnabled ? 2 : 0,
                                                }}
                                                states={{
                                                    hover: {
                                                        enabled: true,
                                                        halo: {
                                                            size: 1,
                                                        },
                                                        lineWidthPlus: 0,
                                                    },
                                                }}
                                                shadow={{
                                                    color: '#F7F7F7',
                                                    offsetX: 0,
                                                    offsetY: 0,
                                                    width: 3,
                                                    opacity: 0.7,
                                                }}
                                                linkedTo={
                                                    options.currentIndex !== 0
                                                        ? `${line.hash}_${options.first.name}`
                                                        : undefined
                                                }
                                                dashStyle={options.dashStyle}
                                                color={seriesColor}
                                                visible={_isVisible(metric) && !this.hiddenItems.has(line.hash)}
                                                events={{
                                                    legendItemClick(e) {
                                                        if (this.visible) {
                                                            const points = this.chart.series;
                                                            const visPoint = points.filter((point) => point.visible)
                                                                .length;
                                                            if (visPoint <= visibleMetrics.length) {
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    },
                                                    hide: () => {
                                                        this.hiddenItems.add(line.hash);
                                                    },
                                                    show: () => {
                                                        this.hiddenItems.delete(line.hash);
                                                    },
                                                }}
                                                {...options.seriesStyle.props}
                                                {...line}
                                            />
                                        );
                                    })}
                                </YAxis>
                            );
                        })}
                    </HighchartsChart>
                </div>
            </div>
        );
    }
}

LineChart.propTypes = {
    showLegend: PropTypes.bool,
    timeBreakdown: PropTypes.string,
    onLinesChanged: PropTypes.func,
    onChartLegendToggle: PropTypes.func,
    onMetricSelected: PropTypes.func,
    onChartLoaded: PropTypes.func,
    chartData: PropTypes.objectOf(PropTypes.any),
    chart: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
    className: PropTypes.string,
    showTopLinesInput: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    numOfTableRows: PropTypes.number,
    spinnerStyle: PropTypes.objectOf(PropTypes.any),
};

LineChart.defaultProps = {
    showLegend: false,
    timeBreakdown: 'day',
    onLinesChanged: () => {},
    onChartLegendToggle: () => {},
    onChartLoaded: () => {},
    onMetricSelected: () => {},
    chartData: {},
    chart: {},
    translate: (str) => str,
    className: '',
    showTopLinesInput: true,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    numOfTableRows: 0,
    spinnerStyle: {},
};

export default withHighcharts(withLocalize(LineChart), Highcharts);