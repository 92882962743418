import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { trackMixpanelEvent } from 'utils/general';
import css from '../style.css';
import TextField from '../../components/widgets/TextField';
import SingularButton from '../../components/widgets/SingularButton';
import Tooltip from '../../components/widgets/Tooltip';
import TooltipIcon from '../../../../dashboard/img/icons/tooltip.svg';
import WarningMessage from '../../components/widgets/WarningMessage';

import {
    getFormValidations,
    isValidForm,
    EMAIL_FIELD,
    FULL_NAME_FIELD,
    PASSWORD_FIELD,
    COVID_UTM_CAMPAIGN,
    getInvalidFields,
} from '../utils';
import { SIGN_UP_FREE_TIER_IMG, SIGN_UP_UNIFIED_TIER_IMG, SIGN_UP_MIXPANEL_PREFIX } from '../consts';
import ShowPasswordIcon from '../../resources/svg/visible.svg';
import HidePasswordIcon from '../../resources/svg/hide.svg';
import GoogleAuthButton from '../../components/widgets/GoogleAuthButton';
import SingularLogoIcon from '../../resources/svg/singular_flat_logo_white.svg';

let pageLoadTime;

function SignupForm({ onSubmitNextPage, onGoogleSignIn, errorCode, isLoading, allBlackListEmails }) {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState(window.signupEmail);
    const [password, setPassword] = useState('');
    const [passwordTextFieldType, setPasswordTextFieldType] = useState('password');
    const [validations, setValidations] = useState({
        [FULL_NAME_FIELD]: '',
        [EMAIL_FIELD]: '',
        [PASSWORD_FIELD]: '',
    });

    const clearValidation = (validationKey) => {
        setValidations({ ...validations, [validationKey]: null });
    };

    const onSignupClick = () => {
        const newValidations = getFormValidations(fullName, email, password, allBlackListEmails);

        setValidations(newValidations);

        if (isValidForm(newValidations)) {
            trackMixpanelEvent(SIGN_UP_MIXPANEL_PREFIX, 'Submit First Page', {
                signup_email: email,
                time_to_submit: (new Date() - pageLoadTime) / 1000,
                success: 'true',
            });
            onSubmitNextPage({ fullName, email, password });
        } else {
            trackMixpanelEvent(SIGN_UP_MIXPANEL_PREFIX, 'Submit First Page', {
                signup_email: email,
                time_to_submit: (new Date() - pageLoadTime) / 1000,
                client_errors: getInvalidFields(newValidations).join(', '),
                success: 'false',
            });
        }
    };
    const onGoogleClick = accessToken => {
        trackMixpanelEvent(SIGN_UP_MIXPANEL_PREFIX, 'Continue With Google', {
            time_to_submit: (new Date() - pageLoadTime) / 1000,
        });
        onGoogleSignIn(accessToken);
    };

    useEffect(() => {
        pageLoadTime = new Date();
    }, []);

    // TODO create custom hook to clear validations
    useEffect(() => {
        clearValidation(FULL_NAME_FIELD);
    }, [fullName]);

    useEffect(() => {
        clearValidation(EMAIL_FIELD);
    }, [email]);

    useEffect(() => {
        clearValidation(PASSWORD_FIELD);
    }, [password]);

    const togglePasswordTextFieldType = () => {
        setPasswordTextFieldType(passwordTextFieldType === 'password' ? 'text' : 'password');
    };

    const onFullNameChange = (value) => {
        setFullName(value);
    };

    const onEmailChange = (value) => {
        setEmail(value);
    };

    const onPasswordChange = (value) => {
        setPassword(value);
    };

    const campaignConfigs = {
        covid: {
            trialPeriodDays: 180,
            disclaimer: 'STATIC.PAGES.SIGNUP.SIGNUP_FORM_COVID_DISCLAIMER_TEXT',
        },
        default: {
            trialPeriodDays: 30,
            disclaimer: '',
        },
    };

    const getCampaignConfig = (utmCampaign, url) => {
        const params = new URLSearchParams(url.split('?')[1]);
        const codeParam = params.get('code');
        if (utmCampaign === COVID_UTM_CAMPAIGN || (codeParam && codeParam.toLowerCase() === COVID_UTM_CAMPAIGN)) {
            return campaignConfigs.covid;
        } else {
            return campaignConfigs.default;
        }
    };

    const { trialPeriodDays, disclaimer } = getCampaignConfig(window.utmCampaign, document.URL);
    const { isFreeTier } = window;
    const iframeImage = isFreeTier ? SIGN_UP_FREE_TIER_IMG : SIGN_UP_UNIFIED_TIER_IMG;

    return (
        <div className={classNames(css.signupForm)}>
            <div className={css.iframeMarketing}>
                <iframe title="logos" width="100%" height="100%" src={iframeImage} sandbox="" />
            </div>
            <div className={css.rightSizeForm}>
                <div className={css.signupFormInner}>
                    <div className={css.singularFormTopSection}>
                        <SingularLogoIcon className={css.singularIcon} />
                        <div className={classNames(css.singularFormTitle, 'showInNonMobile')}>
                            <Translate id={`STATIC.PAGES.SIGNUP.SIGNUP_FORM_TITLE_${isFreeTier ? 'FREE' : 'TRIAL'}`} />
                        </div>
                        <div className={classNames(css.singularFormTitle, 'showInMobile')}>
                            <Translate
                                id={`STATIC.PAGES.SIGNUP.MOBILE.SIGNUP_FORM_TITLE_${isFreeTier ? 'FREE' : 'TRIAL'}`}
                            />
                        </div>
                        <div className={css.singularFormBody}>
                            <div className={css.singularFormSubtitle}>
                                <span className={css.bold}>
                                    <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_NO_CREDIT_CARD" />
                                </span>
                                <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SUBTITLE" />
                                <span
                                    onClick={() =>
                                        trackMixpanelEvent(
                                            SIGN_UP_MIXPANEL_PREFIX,
                                            'Sign Up Page: Get a partner account'
                                        )
                                    }
                                >
                                    <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SUBTITLE_LINK" />
                                </span>
                            </div>
                            <WarningMessage
                                show={!!errorCode}
                                showIcon={false}
                                showTypeIcon
                                type="error"
                                message={`STATIC.PAGES.SIGNUP.${errorCode}`}
                            />
                            <GoogleAuthButton
                                onAuthSuccess={accessToken => {
                                    onGoogleClick(accessToken);
                                }}
                                onAuthError={() => {}}
                                isLoading={isLoading}
                                className={css.googleSignupButton}
                                text="STATIC.PAGES.SIGNUP.CONTINUE_WITH_GOOGLE"
                            />
                            <div className={css.authTypesSeparator}>Or</div>
                            <TextField
                                inputConfig={{ maxLength: 61 }}
                                error={validations.fullName}
                                value={fullName}
                                containerClass={css.textField}
                                label="STATIC.PAGES.SIGNUP.SIGNUP_FORM_NAME_FIELD_LABEL"
                                onChange={onFullNameChange}
                                disabled={isLoading}
                            />
                            <TextField
                                inputConfig={{ maxLength: 70 }}
                                error={validations.email}
                                value={email}
                                containerClass={css.textField}
                                label="STATIC.PAGES.SIGNUP.SIGNUP_FORM_EMAIL_FIELD_LABEL"
                                onChange={onEmailChange}
                                disabled={isLoading}
                            />
                            <div className={css.passwordField}>
                                <Tooltip
                                    interactive
                                    wrapperClass={classNames(css.tooltip, css.passwordTooltip)}
                                    titleTranslationKey="STATIC.PAGES.SIGNUP.SIGNUP_FORM_PASSWORD_TOOLTIP"
                                >
                                    <TooltipIcon />
                                </Tooltip>
                                {password && passwordTextFieldType === 'password' && (
                                    <Tooltip
                                        interactive
                                        titleTranslationKey="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SHOW_PASSWORD_TOOLTIP"
                                        style={{ position: 'absolute', right: 0 }}
                                        position="left"
                                    >
                                        <ShowPasswordIcon
                                            className={css.showPasswordIcon}
                                            onClick={togglePasswordTextFieldType}
                                        />
                                    </Tooltip>
                                )}
                                {password && passwordTextFieldType === 'text' && (
                                    <HidePasswordIcon
                                        className={css.hidePasswordIcon}
                                        onClick={togglePasswordTextFieldType}
                                    />
                                )}
                                <Tooltip
                                    interactive
                                    titleTranslationKey="STATIC.PAGES.SIGNUP.SIGNUP_FORM_PASSWORD_TOOLTIP"
                                    trigger="click"
                                    position="top-start"
                                >
                                    <TextField
                                        inputConfig={{ maxLength: 50 }}
                                        error={validations.password}
                                        value={password}
                                        containerClass={css.textField}
                                        label="STATIC.PAGES.SIGNUP.SIGNUP_FORM_PASSWORD_FIELD_LABEL"
                                        onChange={onPasswordChange}
                                        type={passwordTextFieldType}
                                        disabled={isLoading}
                                    />
                                </Tooltip>
                            </div>
                            <div className={css.submit}>
                                <SingularButton
                                    onClick={onSignupClick}
                                    type="primary"
                                    level="level1"
                                    text="STATIC.PAGES.SIGNUP.SIGNUP_BUTTON"
                                    showSpinner={isLoading}
                                    className={css.submitButton}
                                    textClassName={css.submitButtonText}
                                />
                            </div>
                            <div className={css.termsText}>
                                <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_TERMS_TEXT" />
                                {disclaimer != null && <Translate id={disclaimer} />}
                            </div>
                            <div className={classNames(css.footerSeparator)} />
                            <div className={css.loginLink}>
                                <Translate id="STATIC.PAGES.SIGNUP.LOGIN_LINK" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

SignupForm.propTypes = {
    onSubmitNextPage: PropTypes.func.isRequired,
    onGoogleSignIn: PropTypes.func.isRequired,
    errorCode: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    allBlackListEmails: PropTypes.arrayOf(PropTypes.object),
};

SignupForm.defaultProps = {
    errorCode: '',
    allBlackListEmails: {},
};

export default SignupForm;
