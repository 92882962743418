import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import * as Yup from 'yup';
import { CUSTOM_SOURCE_LOGO_MAP, IP_PROBABILISTIC_SUPPORT_OPTIONS } from '../utils/sources';
import { generateGuid } from '../global/utils';
import { getImpersonationFromQuery } from '../utils/url';
import {
    LINK_TYPE_LEGACY,
    LINK_TYPE_MOBILE_CUSTOM,
    LINK_TYPE_MOBILE_PARTNER,
    LINK_TYPE_MOBILE_WEB_TO_APP,
} from './linkTypes';
import { getIsAgency } from '../selectors/user';
import { OS } from '../utils/OS';

const SHOULD_RELOAD_LINKS_TTL = 60 * 60 * 1000 * 5;

export const LINK_DISPLAY_NAME_MOBILE_WEB_TO_APP = 'Mobile Web To App';
export const LINK_NAME_MOBILE_WEB_TO_APP = 'MobileWebToApp';
export const CUSTOM_SOURCE_NAME_FIELD = 'partner';
export const CUSTOM_SOURCE_NAME_KEY = 'custom';

export const STATUS_ERROR = 'error';
export const STATUS_WARNING = 'warning';
export const STATUS_OK = 'ok';
export const STATUS_INFO = 'info';
export const STATUS_NONE = 'none';

export const URL_PARAMS = ['click_url', 'impression_url', 'tracking_tag'];

export const STATUSES = [STATUS_ERROR, STATUS_WARNING, STATUS_OK, STATUS_INFO, STATUS_NONE];

export const SMTYPE_OWNED_MOBILE_WEB_TO_APP = '3';

const linkTypeMap = {
    [LINK_NAME_MOBILE_WEB_TO_APP]: LINK_TYPE_MOBILE_WEB_TO_APP,
    SmartLink: LINK_TYPE_MOBILE_CUSTOM,
    Link: LINK_TYPE_MOBILE_PARTNER,
};

export const VISIBLE_LINK_TYPES = [
    LINK_TYPE_MOBILE_CUSTOM,
    LINK_TYPE_MOBILE_PARTNER,
    LINK_TYPE_MOBILE_WEB_TO_APP,
    LINK_TYPE_LEGACY,
];

export const LINKS_FILTER_TYPE = {
    LINKS_MANUAL_FILTER: 'Manual',
    LINKS_API_FILTER: 'Api',
    LINKS_NO_FILTER: 'No Filter',
};

export const FORBIDDEN_EXTRA_PARAMS_VALUES = ['_dl', '_ios_dl', '_android_dl'];

export const SAME_AS_DEEPLINK_VALUE = 'deeplinkValue';
export const SAME_AS_DEEPLINK_LABEL = '< Same as deep link >';

export const SAME_AS_DEEPLINK_OBJECT = {
    value: SAME_AS_DEEPLINK_VALUE,
    label: SAME_AS_DEEPLINK_LABEL,
};

const attributionSettingsNormalizeMap = {
    ct_window: 'clickthroughWindow',
    ctf_window: 'clickthroughFPWindow',
    rec_window: 'reWindow',
    vt_iponly_install_ctv_window: 'ctvWindow',
    vt_iponly_install_ctv_prioritization_window: 'ctvPriorityWindow',
    ia_window: 'iaWindow',
    vt_window: 'viewthroughWindow',
    vtf_window: 'viewthroughFPWindow',
};

const partnerWindowsNormalizeMap = {
    installClickWindowId: 'clickthroughWindow',
    installClickWindowIp: 'clickthroughFPWindow',
    installViewWindowId: 'viewthroughWindow',
    installViewWindowIp: 'viewthroughFPWindow',
    reengagementClickWindowId: 'reWindow',
    ctvViewWindow: 'ctvWindow',
    ctvPrioritizationWindow: 'ctvPriorityWindow',
    reengagementInactivityWindow: 'iaWindow',
};

const ctvWindowsNormalizeMap = {
    vt_iponly_install_ctv_window: 'ctvWindow',
    vt_iponly_install_ctv_prioritization_window: 'ctvPriorityWindow',
};

export const nonMobilePlatform = 'nonMobile';
export const pcConsolePlatforms = ['PC', 'Xbox', 'Playstation', 'Nintendo', 'MetaQuest', 'CTV'];
export const nonMobilePlatforms = pcConsolePlatforms;
export const allNonMobileSitesPlatform = 'other';

export const getPlatformPrefix = platform =>
    nonMobilePlatforms.includes(platform) || platform === allNonMobileSitesPlatform
        ? nonMobilePlatform
        : platform.toLowerCase();

export const buildUrlWithAgencyIdParam = (url, agencyId) => {
    const urlWithParams = new URL(url);
    urlWithParams.searchParams.append('agency_id', agencyId);
    return decodeURI(urlWithParams.href);
};

export const WINDOWS_FIELDS_NAMES = Object.values(attributionSettingsNormalizeMap);
export const CTV_WINDOWS_FIELDS_NAMES = Object.values(ctvWindowsNormalizeMap);

export const getLinkUrlParams = link => {
    if (!link.agency_id) {
        return {};
    }

    return URL_PARAMS.reduce((total, param) => {
        if (link[param]) {
            return { ...total, [param]: buildUrlWithAgencyIdParam(link[param], link.agency_id) };
        }
        return total;
    }, {});
};

const normalizeAttributionSettings = current => {
    return Object.entries(current).reduce((final, [key, val]) => {
        final[attributionSettingsNormalizeMap[key]] = val;
        return final;
    }, {});
};

export const normalizeLink = link => {
    const linkUrlParams = getLinkUrlParams(link);
    return {
        ...link,
        ...linkUrlParams,
        type: linkTypeMap[link.link_type],
        configured:
            pcConsolePlatforms.includes(link.platform) || link.platform === allNonMobileSitesPlatform
                ? { pcConsole: true }
                : {
                      android: link.platform === OS.ANDROID,
                      ios: link.platform === OS.IOS,
                  },
        partner_logo: link.partner_logo || CUSTOM_SOURCE_LOGO_MAP[link.partner] || CUSTOM_SOURCE_LOGO_MAP.custom,
        createdDisplay: link.created ? moment(link.created * 1000).format('lll') : '',
        modifiedDisplay: link.modified ? moment(link.modified * 1000).format('lll') : '',
        attribution_settings: normalizeAttributionSettings(link.attribution_settings || {}),
    };
};

export const normalizeLinks = linksList => {
    return {
        linkIdsList: uniq(linksList.map(link => link.tag_id)),
        linksData: linksList.reduce((total, link) => {
            const newLink = normalizeLink(link);
            const existingLink = total[link.tag_id];
            if (!existingLink) {
                total[link.tag_id] = newLink;
            } else {
                total[link.tag_id] = {
                    ...existingLink,
                    configured: Object.keys(existingLink.configured).reduce((configuredCombined, currentPlatform) => {
                        configuredCombined[currentPlatform] =
                            existingLink.configured[currentPlatform] || newLink.configured[currentPlatform];
                        return configuredCombined;
                    }, {}),
                    platform: 'both',
                    destination: undefined,
                    destinations: [
                        { platform: existingLink.platform, ...existingLink.destination },
                        { platform: newLink.platform, ...newLink.destination },
                    ],
                    longname: [existingLink.longname, newLink.longname],
                };
            }
            return total;
        }, {}),
    };
};

export function isCustomLink(linkType) {
    return linkType === LINK_TYPE_MOBILE_CUSTOM || linkType === LINK_TYPE_MOBILE_WEB_TO_APP;
}

export const denormalizeLinks = (linksIdsList, linksData) => {
    return linksIdsList.map(linkId => {
        return linksData[linkId];
    });
};

export const nonMobileSitesFilter = (platform, linkPlatform) => {
    return (
        !nonMobilePlatforms.includes(platform) || (nonMobilePlatforms.includes(platform) && platform === linkPlatform)
    );
};

export const getAlphaSortFunc = (objKey = 'name') => {
    return (a, b) => {
        if (a[objKey].toLowerCase() < b[objKey].toLowerCase()) {
            return -1;
        }
        if (a[objKey].toLowerCase() > b[objKey].toLowerCase()) {
            return 1;
        }
        return 0;
    };
};

const sortLinksByModifiedAndLegacy = links => {
    return links.sort((a, b) => {
        if (a?.absolute_link_type === 'legacy' && b?.absolute_link_type !== 'legacy') {
            return 1;
        }

        if (b?.absolute_link_type === 'legacy' && a?.absolute_link_type !== 'legacy') {
            return -1;
        }

        return b.modified - a.modified;
    });
};

export const groupLinksByPartner = (linksList, openPartners) => {
    const groupByPartner = groupBy(linksList, LINK_TYPE_MOBILE_PARTNER);
    return Object.entries(groupByPartner)
        .map(([name, links]) => {
            const sortedLinks = sortLinksByModifiedAndLegacy(links);
            return {
                name,
                logo: sortedLinks[0].partner_logo,
                links: sortedLinks,
                expanded: openPartners.includes(name),
            };
        })
        .sort(getAlphaSortFunc());
};

export const LINK_TYPE_OPTIONS = [
    { display_name: 'Partner', name: LINK_TYPE_MOBILE_PARTNER, regular: true },
    { display_name: 'Custom Source', name: LINK_TYPE_MOBILE_CUSTOM, regular: true },
    { display_name: LINK_DISPLAY_NAME_MOBILE_WEB_TO_APP, name: LINK_TYPE_MOBILE_WEB_TO_APP, regular: true },
    { display_name: 'Legacy', name: 'legacy', regular: false },
];

export const PlatformTrackingTypes = {
    MOBILE: 'mobile',
    PC_CONSOLE: 'pc_console',
};

const regularTypes = LINK_TYPE_OPTIONS.filter(o => o.regular).map(o => o.name);

export const defaultExtraParamsRow = {
    field: { name: 'default', display_name: 'default' },
    value: '',
    required: false,
    isSourceExtraParam: false,
    key: generateGuid(),
};
export const defaultExtraParams = [defaultExtraParamsRow];

// visibility Rules
export const isImpersonatedByPartnerRule = () => !!getImpersonationFromQuery('customer_id') && !getIsAgency;
export const linkTypeChosenRule = values => !!values.linkType && regularTypes.includes(values.linkType);
export const legacyLinkTypeChosenRule = values => !!values.linkType && values.linkType === 'legacy';
export const notOverrideWindowsRule = values => !values.overrideWindows || isImpersonatedByPartnerRule();
export const clickThroughAvailableRule = values => values.clickThroughLink;
export const viewThroughLinkAvailableRule = values => values.viewThroughLink;
export const reEnabledRule = values => !!values.reEnabled;
export const iosWarningRule = (values, shelfData) => !!shelfData.iosWarning;
export const notIOSWarningRule = (values, shelfData) => !shelfData.iosWarning;
export const androidWarningRule = (values, shelfData) => !!shelfData.androidWarning;
export const notAndroidWarningRule = (values, shelfData) => !shelfData.androidWarning;
export const nonMobileWarningRule = (values, shelfData) => !!shelfData.nonMobileWarning;
export const notNonMobileWarningRule = (values, shelfData) => !shelfData.nonMobileWarning;
export const nonMobileSiteChosenRule = values =>
    !!values.nonMobileDestinationSite && values.nonMobileDestinationSite.value;

export const partnerTypeRule = values => values.linkType && values.linkType === LINK_TYPE_MOBILE_PARTNER;
export const notPartnerTypeRule = values => values.linkType && values.linkType !== LINK_TYPE_MOBILE_PARTNER;
export const customSourceChosen = values =>
    values.linkType === LINK_TYPE_MOBILE_CUSTOM && values.source && values.source.value === LINK_TYPE_MOBILE_CUSTOM;
export const customLinkTypeChosen = values => values.linkType === LINK_TYPE_MOBILE_CUSTOM;
export const androidChecked = (fieldValues, shelfData) =>
    fieldValues.platformTrackingType === PlatformTrackingTypes.MOBILE &&
    (!!fieldValues.androidLabel || !!shelfData.androidLabel);
export const androidSiteChosenRule = values => !!values.androidDestinationSite;
export const iosChecked = (fieldValues, shelfData) =>
    fieldValues.platformTrackingType === PlatformTrackingTypes.MOBILE &&
    (!!fieldValues.iosLabel || !!shelfData.iosLabel);
export const iosProbabilisticNotChecked = (fieldValues, shelfData) =>
    !fieldValues?.probabilisticAttributionToggle && !shelfData?.probabilisticAttributionToggle;
export const iosProbabilisticAttributionChecked = (fieldValues, shelfData) =>
    !!fieldValues?.ipProbabilisticAttributionToggle || !!shelfData?.ipProbabilisticAttributionToggle;
export const iosSiteChosenRule = values => !!values.iosDestinationSite;
export const appOnePlatformConfiguredRule = (values, shelfData) => {
    return !(
        shelfData.iosSites &&
        shelfData.iosSites.length &&
        shelfData.androidSites &&
        shelfData.androidSites.length
    );
};
export const singlePlatformRule = (values, shelfData) => {
    const notPartnerSupported = shelfData.partnerData && !shelfData.partnerData.support_multi_platform;
    const appContainsBothPlatforms = !appOnePlatformConfiguredRule(values, shelfData);
    return appContainsBothPlatforms && notPartnerSupported;
};
export const singularLinksReadyRule = values => values.linkId || !values.source || values.source.singular_links_ready;
export const notSingularLinksReadyRule = values =>
    !values.linkId && values.source && !values.source.singular_links_ready;
export const clicksSupportedPartnerRule = values => values.linkId || !values.source || values.source.support_clicks;
export const notClicksSupportedPartnerRule = (values, { androidSites, iosSites }) =>
    !values.linkId && values.source && !values.source.support_clicks && (androidSites.length || iosSites.length);
export const onlyOneTypeOfSites = (values, { androidSites, iosSites, nonMobileSites }) => {
    return (
        (!androidSites.length && !iosSites.length) ||
        (values.source && !values.source.support_clicks && nonMobileSites.length) ||
        ((androidSites.length || iosSites.length) && !nonMobileSites.length)
    );
};

export const mobileSupportedAppRule = (values, { androidSites, iosSites }) => androidSites?.length || iosSites?.length;
const mobileSupportedRule = (values, shelfData) =>
    clicksSupportedPartnerRule(values) && mobileSupportedAppRule(values, shelfData);

export const pcConsoleSupportedSourceRule = values =>
    values.linkType === LINK_TYPE_MOBILE_CUSTOM ||
    (values.linkType === LINK_TYPE_MOBILE_PARTNER && values.source?.support_pc_console);
export const pcConsoleSupportedAppRule = (values, shelfData) =>
    shelfData.nonMobileSites.some(site => pcConsolePlatforms.includes(site.platform));
export const pcConsoleSupportedRule = (values, shelfData) =>
    pcConsoleSupportedSourceRule(values) && pcConsoleSupportedAppRule(values, shelfData);

export const notPCConsoleSupportedRule = (values, { nonMobileSites }) => {
    return (
        !isCustomLink(values.linkType) &&
        values.source &&
        !values.source?.support_pc_console &&
        // show this error only for app that has PC/Console site, because it is not relevant for mobile only clients
        nonMobileSites.length > 0
    );
};
export const singularLinkSupportedRule = (values, shelfData) =>
    singularLinksReadyRule(values) &&
    (mobileSupportedRule(values, shelfData) || pcConsoleSupportedRule(values, shelfData));

export const ipProbabilisticSupportedRule = values =>
    values.source &&
    values.source.ip_probabilistic_support_type !== null &&
    values.source.ip_probabilistic_support_type !== IP_PROBABILISTIC_SUPPORT_OPTIONS.NEVER;
export const ipProbabilisticMandatoryRule = values =>
    values.source &&
    values.source.ip_probabilistic_support_type !== null &&
    values.source.ip_probabilistic_support_type === IP_PROBABILISTIC_SUPPORT_OPTIONS.MANDATORY;
export const multiPlatformWindowsConflictRule = values =>
    values.multiPlatformWindowsConflict && notOverrideWindowsRule(values);
export const notMultiPlatformWindowsConflictRule = values => !multiPlatformWindowsConflictRule(values);
export const customLinkTypeOrRequiredParams = values =>
    customLinkTypeChosen(values) || values.linkParams.some(param => param.required);
export const isMobileWebToAppLinkRule = values => values.linkType === LINK_TYPE_MOBILE_WEB_TO_APP;
export const isMobileLinkRule = values => values.platformTrackingType === PlatformTrackingTypes.MOBILE;
export const isNonMobileLinkRule = values => values.platformTrackingType !== PlatformTrackingTypes.MOBILE;

// disabled rules
export const sourceAndCampaignNameRule = values =>
    !(values.source && typeof values.source === 'object' && values.campaignName);

export const customSourceNameRule = (values, categories) => {
    let ret = false;
    const mainCategory = categories && categories.find(c => c.name === 'main');
    if (mainCategory && mainCategory.fields && mainCategory.fields.find(f => f.name === 'customSourceName')) {
        ret = !values.customSourceName;
    }
    return ret;
};

export const iosSitesNotAvailableRule = (values, shelfData) => {
    const { partnerData } = shelfData;
    if (
        !(shelfData.iosSites && shelfData.iosSites.length) ||
        !(shelfData.androidSites && shelfData.androidSites.length)
    ) {
        return true;
    } else if (partnerData && !partnerData.support_multi_platform && values.androidLabel) {
        return true;
    }
    return false;
};

export const androidSitesNotAvailableRule = (values, shelfData) => {
    const { partnerData } = shelfData;
    if (
        !(shelfData.iosSites && shelfData.iosSites.length) ||
        !(shelfData.androidSites && shelfData.androidSites.length)
    ) {
        return true;
    } else if (partnerData && !partnerData.support_multi_platform && values.iosLabel) {
        return true;
    }
    return false;
};

export const androidDeeplinkExistRule = values => !values.androidDestinationDeeplink;
export const iosDeeplinkExistRule = values => !values.iosDestinationDeeplink;
export const ctvDisabled = values => !values.ipProbabilisticAttributionToggle || notOverrideWindowsRule(values);
export const ctvEnabled = values => values.ipProbabilisticAttributionToggle && !notOverrideWindowsRule(values);

export const initialFieldValues = {
    linkType: '',
    source: '',
    campaignName: '',
    customSourceName: '',
    platformTrackingType: PlatformTrackingTypes.MOBILE,
    iosLabel: false,
    probabilisticAttributionToggle: false,
    iosWarning: '',
    iosDestinationRedirect: '',
    iosDestinationDDL: '',
    iosDestinationDeeplink: null,
    androidLabel: false,
    androidDestinationRedirect: '',
    androidDestinationDDL: '',
    androidDestinationDeeplink: null,
    destinationFallback: '',
    reEnabled: false,
    ipProbabilisticAttributionToggle: false,
    overrideWindows: false,
    clickthroughWindow: 7,
    clickthroughFPWindow: 24,
    viewthroughWindow: 24,
    viewthroughFPWindow: 8,
    reWindow: 7,
    ctvWindow: 24,
    ctvPriorityWindow: 0,
    // REMOVING IA_WINDOW // iaWindow: 1,
    clickThroughLink: '',
    clickThroughShortLink: '',
    showShortLink: false,
    viewThroughLink: '',
    linkId: '',
    multiPlatformWindowsConflict: false,
};

export const initialCategoryDefs = {
    linkTypeCategory: {
        collapseable: false,
        label: '',
        collapsed: false,
        disabled: true,
    },
    main: {
        collapseable: false,
        label: '',
        collapsed: false,
        disabled: true,
    },
    linkSettings: {
        collapseable: true,
        label: 'STATIC.PAGES.MANAGE_LINKS.LINK_SETTINGS_GROUP_LABEL',
        collapsed: true,
        disabled: true,
        disabledRules: [sourceAndCampaignNameRule, customSourceNameRule],
    },
    attributionSettings: {
        collapseable: true,
        label: 'STATIC.PAGES.MANAGE_LINKS.ATTRIBUTION_SETTINGS_GROUP_LABEL',
        collapsed: true,
        disabled: true,
        disabledRules: [sourceAndCampaignNameRule, customSourceNameRule],
    },
    linkParamsCategory: {
        collapseable: true,
        label: 'STATIC.PAGES.MANAGE_LINKS.LINK_PARAMETERS_GROUP_LABEL',
        collapsed: true,
        disabled: true,
        disabledRules: [sourceAndCampaignNameRule, customSourceNameRule],
    },
    linkSummary: {
        collapseable: true,
        label: 'STATIC.PAGES.MANAGE_LINKS.LINK_SUMMARY_GROUP_LABEL',
        collapsed: false,
        disabled: false,
    },
};

export const getTotalCountFromSummary = (summary, externalUUID = null) =>
    Object.entries(summary)
        .filter(([k, v]) => {
            if (!externalUUID) {
                return true;
            }
            return k === externalUUID;
        })
        .reduce((total, [k, current]) => total + current.partner_count + current.custom_count, 0);

// converting partner config windows to ui values
export const normalizePartnerWindows = (attrWindows, fieldsDefs) => {
    return Object.entries(attrWindows).reduce((total, [k, v]) => {
        const normalizedKey = partnerWindowsNormalizeMap[k];
        if (normalizedKey != null && v != null) {
            // note that this line creates a coupling you should consider when updating / using new fields from support api
            // for each VALUE (normalized name) in partnerWindowsNormalizeMap there needs to be a
            // corresponding (case-sensitive) key in fieldsDefs that is provided (ex. ./reducers.js:619)
            total[normalizedKey] = (v * 3600) / fieldsDefs[normalizedKey].normalizeValue;
        }
        return total;
    }, {});
};

// getting field defs to be updated if a window > max value
export const getWindowsUpdatedDefs = (attrWindows, fieldsDefs) => {
    return Object.entries(attrWindows).reduce((total, [k, v]) => {
        const def = fieldsDefs[k];
        if (v > def.max) {
            total.push({
                name: k,
                keys: [
                    { key: 'disabled', value: true },
                    { key: 'overrides', value: { max: v, extraSteps: [] } },
                ],
            });
        }
        return total;
    }, []);
};

export const getWindowsDefaultDefs = () => {
    return Object.values(attributionSettingsNormalizeMap).map(windowKey => ({
        name: windowKey,
        keys: [
            { key: 'disabled', value: false },
            { key: 'overrides', value: null },
        ],
    }));
};

export const getOverrideWindowsToggleLabel = (isPartnerLink, sourceObj = { label: '' }, translate = val => val) => {
    let labelVal = 'STATIC.PAGES.MANAGE_LINKS.OVERRIDE_ATTRIBUTION_WINDOW_CUSTOM_LABEL';
    let infoMessageVal = '';
    if (isPartnerLink) {
        labelVal = translate('STATIC.PAGES.MANAGE_LINKS.OVERRIDE_ATTRIBUTION_WINDOW_PARTNER_LABEL', {
            partnerName: sourceObj.label,
        });
        infoMessageVal = translate('STATIC.PAGES.MANAGE_LINKS.OVERRIDE_ATTRIBUTION_WINDOW_PARTNER_INFO');
    }
    return {
        name: 'overrideWindows',
        keys: [
            { key: 'label', value: labelVal },
            { key: 'infoMessage', value: infoMessageVal },
        ],
    };
};

export const getIpProbabilisticToggleInfo = (isPartnerLink, translate = val => val) => {
    let infoMessageVal = 'STATIC.PAGES.MANAGE_LINKS.IP_PROBABILISTIC_INFO_CUSTOM';
    if (isPartnerLink) {
        infoMessageVal = translate('STATIC.PAGES.MANAGE_LINKS.IP_PROBABILISTIC_INFO_PARTNER');
    }
    return {
        name: 'ipProbabilisticAttributionToggle',
        keys: [{ key: 'infoMessage', value: infoMessageVal }],
    };
};

const privacyTooltips = {
    ios: {
        true: {
            false: {
                clickDeterministicTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_FOR_MOBILE_APP_TOOLTIP',
                clickFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_FOR_CUSTOM_SOURCE_TOOLTIP',
                viewDeterministicTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_FOR_MOBILE_APP_TOOLTIP',
                viewFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_FOR_CUSTOM_SOURCE_TOOLTIP',
                clickFingerprintTooltipDisabled: false,
                viewFingerprintTooltipDisabled: false,
            },
        },
        false: {
            true: {
                clickDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_WHEN_USER_OPTED_INTO_TRACKING_TOOLTIP',
                clickFingerprintTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_WHEN_PARTNER_MOBILE_WEB_AND_USER_OPTED_INTO_TRACKING_TOOLTIP',
                viewDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_WHEN_USER_OPTED_INTO_TRACKING_TOOLTIP',
                viewFingerprintTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_WHEN_PARTNER_MOBILE_WEB_AND_USER_OPTED_INTO_TRACKING_TOOLTIP',
                clickFingerprintTooltipDisabled: false,
                viewFingerprintTooltipDisabled: false,
            },
            false: {
                clickDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_WHEN_USER_OPTED_INTO_TRACKING_IN_BOTH_PUBLISHER_AND_TARGET_APP_TOOLTIP',
                clickFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_WHY_IS_THIS_DISABLED_TOOLTIP',
                viewDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_WHEN_USER_OPTED_INTO_TRACKING_IN_BOTH_PUBLISHER_AND_TARGET_APP_TOOLTIP',
                viewFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_WHY_IS_THIS_DISABLED_TOOLTIP',
                clickFingerprintTooltipDisabled: false, // true post ios 14.5
                viewFingerprintTooltipDisabled: false, // true post ios 14.5
            },
        },
    },
    multi: {
        true: {
            false: {
                clickDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_BUT_IOS_LIMITED_TO_CERTAIN_SCENARIOS_TOOLTIP',
                clickFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_AND_IOS_TOOLTIP',
                viewDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_BUT_IOS_LIMITED_TO_CERTAIN_SCENARIOS_TOOLTIP',
                viewFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_AND_IOS_TOOLTIP',
                clickFingerprintTooltipDisabled: false,
                viewFingerprintTooltipDisabled: false,
            },
        },
        false: {
            true: {
                clickDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_BUT_IOS_DEPENDS_ON_USER_OPTED_IN_TRACKING_TOOLTIP',
                clickFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_TOOLTIP',
                viewDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_BUT_IOS_DEPENDS_ON_USER_OPTED_IN_TRACKING_TOOLTIP',
                viewFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_TOOLTIP',
                clickFingerprintTooltipDisabled: false,
                viewFingerprintTooltipDisabled: false,
            },
            false: {
                clickDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_BUT_IOS_DEPENDS_ON_USER_OPTED_IN_TRACKING_TOOLTIP',
                clickFingerprintTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_AND_NOT_ON_IOS_TOOLTIP',
                viewDeterministicTooltip:
                    'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_BUT_IOS_DEPENDS_ON_USER_OPTED_IN_TRACKING_TOOLTIP',
                viewFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.PRIVACY_AVAILABLE_ON_ANDROID_AND_NOT_ON_IOS_TOOLTIP',
                clickFingerprintTooltipDisabled: false,
                viewFingerprintTooltipDisabled: false,
            },
        },
    },
    other: {
        false: {
            false: {
                clickDeterministicTooltip: 'STATIC.PAGES.MANAGE_LINKS.DETERMINISTIC_CLICK_WINDOW_TOOLTIP',
                clickFingerprintTooltip: '',
                viewDeterministicTooltip: 'STATIC.PAGES.MANAGE_LINKS.DETERMINISTIC_VIEW_WINDOW_TOOLTIP',
                viewFingerprintTooltip: 'STATIC.PAGES.MANAGE_LINKS.VT_FINGERPRINT_WINDOW_TOOLTIP',
                clickFingerprintTooltipDisabled: false,
                viewFingerprintTooltipDisabled: false,
            },
        },
    },
};

export const getAttPrivacyTooltips = (isCustomSource, partnerSupportsMobileWeb, linkTypePlatform) => {
    let customSource = isCustomSource;
    let supportsMobileWeb = partnerSupportsMobileWeb;
    let platform = linkTypePlatform;

    if (platform !== 'ios' && platform !== 'multi') {
        platform = 'other';
        customSource = false;
        supportsMobileWeb = false;
    }

    return [
        {
            name: 'clickthroughWindow',
            keys: [
                {
                    key: 'labelTooltip',
                    value: privacyTooltips[platform][customSource][supportsMobileWeb].clickDeterministicTooltip,
                },
                { key: 'disabled', value: false },
            ],
        },
        {
            name: 'clickthroughFPWindow',
            keys: [
                {
                    key: 'labelTooltip',
                    value: privacyTooltips[platform][customSource][supportsMobileWeb].clickFingerprintTooltip,
                },
                {
                    key: 'disabled',
                    value: privacyTooltips[platform][customSource][supportsMobileWeb].clickFingerprintTooltipDisabled,
                },
            ],
        },
        {
            name: 'viewthroughWindow',
            keys: [
                {
                    key: 'labelTooltip',
                    value: privacyTooltips[platform][customSource][supportsMobileWeb].viewDeterministicTooltip,
                },
                { key: 'disabled', value: false },
            ],
        },
        {
            name: 'viewthroughFPWindow',
            keys: [
                {
                    key: 'labelTooltip',
                    value: privacyTooltips[platform][customSource][supportsMobileWeb].viewFingerprintTooltip,
                },
                {
                    key: 'disabled',
                    value: privacyTooltips[platform][customSource][supportsMobileWeb].viewFingerprintTooltipDisabled,
                },
            ],
        },
    ];
};

export const getClicksNotSupportedSubLabel = (source, translate = val => val) => {
    const value = translate('STATIC.PAGES.MANAGE_LINKS.SINGULAR_LINK_NOT_SUPPORTED_PARTNER_LABEL', {
        partnerName: source.label,
        linkType: 'Mobile',
    });

    return {
        name: 'clicksNotSupported',
        keys: [
            { key: 'label', value: !source.support_clicks ? value : '' },
            { key: 'subLabel', value: !source.support_clicks ? source.description || '' : '' },
        ],
    };
};

export const getPCConsoleNotSupportedSubLabel = (source, translate = val => val) => {
    const value = translate('STATIC.PAGES.MANAGE_LINKS.SINGULAR_LINK_NOT_SUPPORTED_PARTNER_LABEL', {
        partnerName: source.label,
        linkType: 'PC/Console',
    });

    return {
        name: 'pcConsoleNotSupported',
        keys: [{ key: 'label', value: !source.support_pc_console ? value : '' }],
    };
};

// Taken from RFC 2396 (modified slightly, to prevent schema only)
// YUP has a few problems in URL verification
//  * Allows implicit schema
//  * Doesn't allow { and }
//  * Doesn't allow domain only (e.g. details or localhost)
//  * Only allows http, https and ftp schemas
const URL_REGEX = /^(([a-z.\-]+):)\/\/([^?#]+)([?]([^#]*))?(#(.*))?$/i;
const SCHEMA_REGEX = /^[a-z.\-]+$/i;

export function getRedirectUrlValidator(additionalSchemas = []) {
    if (additionalSchemas.some(name => !SCHEMA_REGEX.test(name))) {
        throw new Error(`Invalid additionalSchemas=${additionalSchemas}`);
    }

    const supportedSchemas = ['http', 'https', ...additionalSchemas];
    const schemaRegex = new RegExp(`^(${supportedSchemas.join('|')})://`, 'i');

    return Yup.lazy(value => {
        const validator = Yup.string()
            .required('Required')
            .matches(schemaRegex, {
                message: `Must start with ${supportedSchemas.join('/')}`,
            })
            .matches(URL_REGEX, {
                message: 'Not a URL',
            });

        if (typeof value !== 'object') {
            return validator;
        }

        return Yup.object({
            value: validator,
        }).noUnknown(false);
    });
}

export const shouldReloadLinks = (linksForApps, linksMetadata, appId, appsFilter) => {
    return (
        !linksForApps ||
        !linksMetadata ||
        !linksMetadata[appId] ||
        (appsFilter || '') !== linksMetadata[appId].filter ||
        new Date().getTime() - linksMetadata[appId].timestamp > SHOULD_RELOAD_LINKS_TTL
    );
};

export const handleLinksUpdate = (appId, apps, links, stateLinksPerAppId, stateLinksMetadata, stateAppsFilter) => {
    const groupedLinksByApp = groupBy(links, 'app_id');
    let totalLinksData = {};
    const linksPerAppId = {};
    const linksMetadata = {};
    let useApps = apps;
    if (!apps || !apps.length) {
        useApps = [appId];
    }
    // useApps is the list of apps that the returned links belong to.
    // We want to update the relevant links per each app
    useApps.forEach(appIdFound => {
        const appLinks = groupedLinksByApp[appIdFound] || [];
        const { linksData, linkIdsList = [] } = normalizeLinks(appLinks);
        totalLinksData = { ...totalLinksData, ...linksData };
        let existingLinksIdsList = [];
        if (!shouldReloadLinks(stateLinksPerAppId[appIdFound], stateLinksMetadata, appIdFound, stateAppsFilter)) {
            // In case no reload is required we concat to the previously fetched links.
            // This will mostly be done upon update / create when refetching the list.
            existingLinksIdsList = stateLinksPerAppId[appIdFound] || existingLinksIdsList;
        }
        const newLinksIdsList = Array.from(new Set(existingLinksIdsList.concat(linkIdsList)));
        // Per each appId we keep a list of link ids relevant to it.
        linksPerAppId[appIdFound] = {
            $set: newLinksIdsList,
        };
        // Per each appId we keep the last result timestamp and the filter that was used during the fetch
        // This will be used later to understand if a re-fetch is required.
        linksMetadata[appIdFound] = {
            timestamp: new Date().getTime(),
            filter: stateAppsFilter || '',
        };
    });
    return {
        linksPerAppId,
        totalLinksData,
        linksMetadata,
    };
};
