import OSIcon from '../resources/icons/creatives/os_dimension.svg';
import TypeIcon from '../resources/icons/creatives/type_dimension.svg';

export const DimensionCategory = {
    GENERAL_DIMENSIONS: 'Creative Dimensions',
    AUDIO: 'Audio',
    TEXT: 'Text',
    STYLE: 'Basic Ad Style',
    ELEMENTS: 'Elements',
    CHARACTER: 'Character',
    OTHER: 'Other',
};

export const DimensionCategoryToColor = {
    [DimensionCategory.GENERAL_DIMENSIONS]: 'var(--gray700)',
    [DimensionCategory.AUDIO]: 'var(--orange300)',
    [DimensionCategory.TEXT]: 'var(--orange400)',
    [DimensionCategory.STYLE]: 'var(--purple300)',
    [DimensionCategory.ELEMENTS]: 'var(--blue400)',
    [DimensionCategory.CHARACTER]: 'var(--pink200)',
    [DimensionCategory.OTHER]: 'var(--gray400)',
};

export const GeneralDimensions = {
    TYPE: 'Creative Type',
    OS: 'OS',
};

const creativeWithUpdatedOsList = (creative, osList) => ({
    ...creative,
    multiValuedDimensions: { ...creative.multiValuedDimensions, osList },
});

export const GeneralDimensionsConfig = {
    [GeneralDimensions.OS]: {
        creativeField: 'osList',
        icon: OSIcon,
        filterBy: (creatives, filterValues) => {
            if (!filterValues) return creatives.map(creative => creativeWithUpdatedOsList(creative, []));
            if (filterValues.length === 0) return creatives;

            return creatives
                .filter(({ multiValuedDimensions }) => multiValuedDimensions.osList)
                .map(creative =>
                    creativeWithUpdatedOsList(
                        creative,
                        creative.multiValuedDimensions.osList.filter(os => filterValues.includes(os))
                    )
                )
                .filter(({ multiValuedDimensions }) => multiValuedDimensions.osList.length);
        },
    },
    [GeneralDimensions.TYPE]: {
        creativeField: 'type',
        icon: TypeIcon,
        filterBy: (creatives, filterValues) => {
            if (!filterValues) return creatives.map(creative => ({ ...creative, showType: false }));
            if (filterValues.length === 0) return creatives;

            return creatives.filter(({ type }) => filterValues.includes(type));
        },
    },
};
