import { call, put, take, takeEvery, all, select, fork, delay } from 'redux-saga/effects';
import LinkManagementAPI from './service';
import OldTrackingLinksAPI from '../services/trackingLinks';
import Logger from '../services/logger';
import AppsAPI from '../services/apps';
import SourcesAPI from '../services/sources';
import {
    load,
    unload,
    selectApp,
    updateLinksForApp,
    generateLinkSuccess,
    generateLinkSuccessAfter,
    setShelfError,
    formUpdate,
    createSubDomainSuccess,
    createSubDomainError,
    sourceConfigResult,
    linkClickedFinish,
    formFieldsDefsChanged,
    updateLinksSummary,
    updateLegacyLinksSummary,
    favouriteButtonFinished,
    archiveButtonFinished,
    fieldValuesChanged,
    updateSingularLinksSourcesData,
    updateSourcesConfigurationData,
    updatePartnersConfigData,
    updateSelectedPartnerId,
    oldLinksToggleContinue,
    updateLinkData,
    shelfGroupClicked,
    changeAppsFilterFinished,
    APP_SELECTED,
    FORM_UPDATED,
    GENERATE_LINK,
    SUB_DOMAINS_CREATE,
    FAVOURITE_BUTTON_CLICKED,
    ARCHIVE_BUTTON_CLICKED,
    UPDATE_LINK,
    ARCHIVE_LINK_CLICKED,
    UN_ARCHIVE_LINK_CLICKED,
    OLD_LINKS_TOGGLE,
    APP_FILTER_CHANGED,
    optionalExtraParamsResult,
    partnerExpandClick,
    API_LINKS_TOGGLE,
    showApiLinksFilterChangedContinue,
    updateApiLinksForApp,
    LINK_CLICKED,
} from './actions';

import { updateAttributionAppsData } from '../actions/apps';
import {
    getGenerateLinkData,
    getLinkData,
    getAndoridSites,
    getIOSSites,
    getNonMobileSites,
    getSelectedAppId,
    getFieldsDefs,
    getCategoryFields,
    getShelfValues,
    getCategoryDefs,
    getShowOldLinksSelectedApp,
    getSelectAppExternalUUID,
    getShowOldLinks,
    getAppsFilter,
    getPartnersConfig,
    getSelectedPartnerId,
    getApps,
    getLinksMetaData,
    getLinksPerAppId,
    getLinksList,
    getShelfSources,
    getShowApiLinksSelectedApp,
    getApiLinksPerAppId,
    getApiLinksMetaData,
    getLinksData,
} from './selectors';
import { getSingularLinksSourcesList, getSourceConfigurations } from '../selectors/sources';
import {
    initialFieldValues,
    defaultExtraParamsRow,
    normalizePartnerWindows,
    getWindowsUpdatedDefs,
    getWindowsDefaultDefs,
    SAME_AS_DEEPLINK_OBJECT,
    WINDOWS_FIELDS_NAMES,
    getOverrideWindowsToggleLabel,
    getAttPrivacyTooltips,
    getClicksNotSupportedSubLabel,
    getPCConsoleNotSupportedSubLabel,
    shouldReloadLinks,
    groupLinksByPartner,
    isCustomLink,
    LINK_DISPLAY_NAME_MOBILE_WEB_TO_APP,
    SMTYPE_OWNED_MOBILE_WEB_TO_APP,
    LINKS_FILTER_TYPE,
    getIpProbabilisticToggleInfo,
    CTV_WINDOWS_FIELDS_NAMES,
    PlatformTrackingTypes,
    getPlatformPrefix,
    allNonMobileSitesPlatform,
    isNonMobileLinkRule,
    normalizeLinks,
    normalizeLink,
} from './utils';
import { LINK_TYPE_MOBILE_CUSTOM, LINK_TYPE_MOBILE_PARTNER, LINK_TYPE_MOBILE_WEB_TO_APP } from './linkTypes';
import { getTranslate } from '../selectors/locale';
import { IP_PROBABILISTIC_SUPPORT_OPTIONS, SOURCE_NAME_MOBILE_WEB_TO_APP } from '../utils/sources';
import { getPartnerAppWindows, getPartnerConfigurationsForApp, getPartnerDetails } from './queries';
import { getUserData } from '../selectors/user';

const logger = new Logger('[linkManagement:saga]');

const api = new LinkManagementAPI();
const appsApi = new AppsAPI();
const sourcesApi = new SourcesAPI();
const oldApi = new OldTrackingLinksAPI();

function* loadLinksSummary() {
    try {
        const linksSummaryResponse = yield call(api.getLinksSummary);
        yield put(updateLinksSummary(linksSummaryResponse));
    } catch (e) {
        yield put(updateLinksSummary({}));
    }
}

function* tryGetPartnerConfigurationsForApp(partnerId, longname, platform) {
    try {
        return yield call(getPartnerConfigurationsForApp, partnerId, longname, platform);
    } catch (err) {
        return null;
    }
}

function* getLegacyLinksSummary() {
    let summaryResponse = {};
    try {
        summaryResponse = yield call(api.getLegacyLinksSummary);
    } catch (e) {
        logger.debug(`Failed to get legacy summary, error: ${e}`);
    }
    return summaryResponse;
}

function* loadLegacyLinksSummary() {
    try {
        const legacyLinksSummaryResponse = yield call(getLegacyLinksSummary);
        yield put(updateLegacyLinksSummary(legacyLinksSummaryResponse));
    } catch (e) {
        yield put(updateLegacyLinksSummary({}));
    }
}

const isNonMobileLink = (nonMobileSites, androidSites, iosSites, source = null) => {
    return !!(
        nonMobileSites?.length > 0 &&
        ((!androidSites?.length && !iosSites?.length) || (source && !source.support_clicks))
    );
};

function* onload({ payload: { search = '' } }) {
    try {
        const [
            appsResponse,
            sourcesResponse,
            domainsResponse,
            attrConfiguration,
            optionalExtraParams,
            legacyLinksSummary,
        ] = yield all([
            call(appsApi.getAttributionApps),
            call(sourcesApi.getSingularLinksSources),
            call(api.getDomains),
            call(sourcesApi.getAttributionConfiguration),
            call(api.getOptionalExtraParams),
            call(getLegacyLinksSummary),
        ]);
        logger.debug('got apps response: ', appsResponse);
        logger.debug('got attribution sources response: ', sourcesResponse);
        logger.debug('got domains response: ', domainsResponse);
        logger.debug('got attrConfiguration response: ', attrConfiguration);
        logger.debug('got getLegacyLinksSummary response: ', legacyLinksSummary);

        let appsFilter = '';
        if (search) {
            const searchParams = new URLSearchParams(search);
            appsFilter = searchParams.get('search') || appsFilter;
        }
        yield put(updateSingularLinksSourcesData(sourcesResponse));
        yield put(
            load(
                domainsResponse.domains,
                domainsResponse.dns_zones,
                domainsResponse.domains_limit,
                attrConfiguration.configuration.singular_links_config,
                appsFilter
            )
        );
        yield put(updateLegacyLinksSummary(legacyLinksSummary));
        yield put(optionalExtraParamsResult(optionalExtraParams));
        if (appsResponse && appsResponse.apps && appsResponse.apps.length) {
            yield put(updateAttributionAppsData(appsResponse));
        } else {
            yield put(updateLinksForApp('', []));
        }
        yield delay(1000);
        yield fork(loadLinksSummary);
    } catch (e) {
        logger.debug('Error while loading the page', e);
    }
}

function* destroy() {
    yield put(unload());
}

function* forceAppSelected(appId) {
    yield put(selectApp(appId));
}

function* postAppSelected() {
    // check if there is only one partner in the results, if so auto expand it
    const newLinksList = yield select(getLinksList);
    const groupedLinks = groupLinksByPartner(newLinksList, []);
    if (groupedLinks && groupedLinks.length === 1) {
        yield put(partnerExpandClick(groupedLinks[0].name));
    }
}

const getLinkFormValues = linkData => {
    const attributionSettings = {
        clickthroughWindow: initialFieldValues.clickthroughWindow,
        clickthroughFPWindow: initialFieldValues.clickthroughFPWindow,
        viewthroughWindow: initialFieldValues.viewthroughWindow,
        viewthroughFPWindow: initialFieldValues.viewthroughFPWindow,
        reWindow: initialFieldValues.reWindow,
        ctvWindow: initialFieldValues.ctvWindow,
        ctvPriorityWindow: initialFieldValues.ctvPriorityWindow,
        ipProbabilisticAttributionToggle: linkData.ip_probabilistic,
        // REMOVING IA_WINDOW // iaWindow: initialFieldValues.iaWindow,
        ...(linkData.attribution_settings || {}),
    };

    return {
        linkType: linkData.type,
        source: linkData.partner,
        campaignName: linkData.campaign_name,
        platformTrackingType: linkData.sites.nonMobileDestinationSite
            ? PlatformTrackingTypes.PC_CONSOLE
            : PlatformTrackingTypes.MOBILE,
        overrideWindows: linkData.override_windows,
        clickThroughLink: linkData.click_url,
        clickThroughShortLink: linkData.click_short_url,
        viewThroughLink: linkData.impression_url,
        reEnabled: linkData.re_enabled,
        customSourceName: linkData.customSourceName,
        iosLabel: linkData.iosLabel,
        androidLabel: linkData.androidLabel,
        subDomain: linkData.domain ? `${linkData.domain.subdomain}.${linkData.domain.dns_zone}` : '',
        probabilisticAttributionToggle:
            linkData.extra_params.find(entry => entry?.field?.label === '_smtype')?.value ===
            SMTYPE_OWNED_MOBILE_WEB_TO_APP,
        linkId: linkData.tag_id,
        linkParams: linkData.extra_params,
        showShortLink: linkData.show_short_link || false,
        ...linkData.destinations,
        ...linkData.sites,
        ...attributionSettings,
    };
};

function* handleAppSelected({ appId, forceReload = false }) {
    try {
        const showOldLinks = yield select(getShowOldLinksSelectedApp);
        const searchStr = yield select(getAppsFilter) || '';
        const linksMetadata = yield select(getLinksMetaData) || {};
        const linksPerAppId = yield select(getLinksPerAppId) || {};
        const prevSelectedAppId = yield select(getSelectedAppId);
        let useAppId = appId;
        if (!appId) {
            useAppId = prevSelectedAppId;
        }
        if (!useAppId) {
            return;
        }

        if (!(shouldReloadLinks(linksPerAppId[useAppId], linksMetadata, useAppId, searchStr) || forceReload)) {
            yield call(postAppSelected);
            return;
        }

        const [trackingLinksResponse, suggestionsResponse] = yield all([
            call(api.getLinksForApp, useAppId, showOldLinks, searchStr, false),
            call(api.getSuggestionsForApp, useAppId),
        ]);

        const { tracking_links: trackingLinks, apps: appsFilterResult } = trackingLinksResponse;
        const { suggestions } = suggestionsResponse;


        yield put(updateLinksForApp(useAppId, trackingLinks, suggestions, appsFilterResult));
        const appsList = yield select(getApps);

        if (
            appsFilterResult &&
            appsFilterResult.length &&
            appsList &&
            appsList.every(app => app.id !== prevSelectedAppId)
        ) {
            // in case the reasult does not contain the previous selected app, auto-select the first app
            yield fork(forceAppSelected, appsList[0].id);
        } else {
            yield call(postAppSelected);
        }
    } catch (e) {
        logger.debug(`Error while fetching links for: ${appId}`, e);
    }
}

function* handleFilterChanged(action) {
    try {
        const { value } = action;
        if (!value || value.length >= 3) {
            yield call(handleAppSelected, action);
        }
        yield put(changeAppsFilterFinished());
    } catch (e) {
        logger.debug(`Error while filtering links: `, e);
    }
}

function* handleOldLinksToggle() {
    const selectedAppId = yield select(getSelectedAppId);
    const selectedAppExternalUUID = yield select(getSelectAppExternalUUID);
    yield put(oldLinksToggleContinue(selectedAppExternalUUID));
    const currentOldLinksState = yield select(getShowOldLinks);
    yield call(api.saveConfiguration, 'old_links_state', currentOldLinksState);
    yield put(selectApp(selectedAppId, true));
}

function* handleShowApiLinks() {
    const selectedAppId = yield select(getSelectedAppId);
    const selectedAppExternalUUID = yield select(getSelectAppExternalUUID);
    const showApiLinks = yield select(getShowApiLinksSelectedApp);
    const linksMetadata = yield select(getApiLinksMetaData) || {};
    const apiLinksPerAppId = yield select(getApiLinksPerAppId) || {};
    const searchStr = yield select(getAppsFilter) || '';

    if (showApiLinks || !shouldReloadLinks(apiLinksPerAppId[selectedAppId], linksMetadata, selectedAppId, searchStr)) {
        yield put(showApiLinksFilterChangedContinue(selectedAppExternalUUID));
        return;
    }
    const res = yield call(
        api.getLinksForApp,
        selectedAppId,
        false,
        searchStr,
        false,
        LINKS_FILTER_TYPE.LINKS_API_FILTER
    );
    yield put(updateApiLinksForApp(selectedAppId, res.tracking_links, res.apps));
    yield put(showApiLinksFilterChangedContinue(selectedAppExternalUUID));
}

const getWarningMessageFromConfig = config => {
    const message = config
        ? 'STATIC.PAGES.MANAGE_LINKS.SOURCE_CONFIGURED_INFO'
        : 'STATIC.PAGES.MANAGE_LINKS.SOURCE_NOT_CONFIGURED_WARNING';
    const type = config ? 'info' : 'error';
    const buttonText = config ? 'STATIC.BUTTONS.EDIT' : 'STATIC.PAGES.GENERATE_TRACKING_LINK.CONFIG';

    return {
        message,
        type,
        buttonText,
    };
};

function* setPartnerDefaultWindows(source, siteIds) {
    logger.debug('inside setPartnerDefaultWindows with sourceName: ', source.name, ' siteIds: ', siteIds);

    const allSites = yield all([
        ...(yield select(getAndoridSites)),
        ...(yield select(getIOSSites)),
        ...(yield select(getNonMobileSites)),
    ]);

    if (siteIds.length > 1) {
        yield put(
            fieldValuesChanged({
                multiPlatformWindowsConflict: true,
            })
        );
        return Promise.resolve();
    }

    const siteId = siteIds[0];
    const sourceConfigurations = yield select(getSourceConfigurations);

    if (sourceConfigurations && sourceConfigurations[`${source.name}_${siteId}`]) {
        const { platform, longname } = allSites.find(({ value }) => value === siteId);
        const attrWindows = yield call(getPartnerAppWindows, source.id, longname, platform);
        const fieldsDefs = yield select(getFieldsDefs);

        if (attrWindows) {
            const normalizedWindows = normalizePartnerWindows(attrWindows, fieldsDefs);
            const fieldsToUpdate = getWindowsUpdatedDefs(normalizedWindows, fieldsDefs);
            logger.debug('setPartnerDefaultWindows, fieldsToUpdate: ', fieldsToUpdate);
            if (fieldsToUpdate.length) {
                yield put(formFieldsDefsChanged(fieldsToUpdate));
            }
            yield put(
                fieldValuesChanged({
                    ...normalizedWindows,
                    multiPlatformWindowsConflict: false,
                })
            );
        }
    }

    return Promise.resolve();
}

function* setAttPrivacyTooltips(values) {
    let linkTypePlatform = '';
    if (values.iosLabel && values.androidLabel) {
        linkTypePlatform = 'multi';
    } else if (values.iosLabel) {
        linkTypePlatform = 'ios';
    } else if (values.androidLabel) {
        linkTypePlatform = 'android';
    }

    let partnerSupportsMobileWeb = false;

    const selectedPartnerId = yield select(getSelectedPartnerId);
    const partnersConfig = yield select(getPartnersConfig);
    const isCustomSource = values.linkType === 'custom';

    if (!isCustomSource && selectedPartnerId && partnersConfig) {
        partnerSupportsMobileWeb = partnersConfig.mobileWebSupport;
    }

    const tooltipsData = getAttPrivacyTooltips(isCustomSource, partnerSupportsMobileWeb, linkTypePlatform);
    yield put(formFieldsDefsChanged(tooltipsData));
}

function* setCustomDefaultWindows() {
    const windowsValues = WINDOWS_FIELDS_NAMES.reduce((total, windowName) => {
        total[windowName] = initialFieldValues[windowName];
        return total;
    }, {});
    yield put(
        fieldValuesChanged({
            ...windowsValues,
            multiPlatformWindowsConflict: false,
        })
    );
}

function* setCtvDefaultWindows() {
    const windowsValues = CTV_WINDOWS_FIELDS_NAMES.reduce((total, windowName) => {
        total[windowName] = initialFieldValues[windowName];
        return total;
    }, {});
    yield put(
        fieldValuesChanged({
            ...windowsValues,
            multiPlatformWindowsConflict: false,
        })
    );
}

function* handlePartnerChanged(values) {
    const newLink = !values.linkId;
    const translate = yield select(getTranslate);
    yield put(
        formFieldsDefsChanged([
            {
                name: 'reEnabled',
                keys: [{ key: 'disabled', value: true }],
            },
            getOverrideWindowsToggleLabel(true, values.source ? values.source : { label: '' }, translate),
            getClicksNotSupportedSubLabel(values.source || {}, translate),
            getPCConsoleNotSupportedSubLabel(values.source || {}, translate),
            getIpProbabilisticToggleInfo(true, translate),
            ...getWindowsDefaultDefs(),
        ])
    );

    const [androidSites, iosSites, nonMobileSites] = yield all([
        yield select(getAndoridSites),
        yield select(getIOSSites),
        yield select(getNonMobileSites),
    ]);

    const sourcesList = yield select(getSingularLinksSourcesList);
    const source = sourcesList.find(s => s.name === values.source.value);
    if (newLink) {
        yield put(fieldValuesChanged({ reEnabled: false }));
        // when creating a new link for partner - use probabilistic support type of partner to determine default value
        const newLinkIpProbabilisticValue =
            source.ip_probabilistic_support_type === IP_PROBABILISTIC_SUPPORT_OPTIONS.RECOMMENDED ||
            source.ip_probabilistic_support_type === IP_PROBABILISTIC_SUPPORT_OPTIONS.MANDATORY;
        yield put(fieldValuesChanged({ ipProbabilisticAttributionToggle: newLinkIpProbabilisticValue }));
        const nonMobileLink = isNonMobileLink(nonMobileSites, androidSites, iosSites, source);
        yield put(
            fieldValuesChanged({
                platformTrackingType: nonMobileLink ? PlatformTrackingTypes.PC_CONSOLE : PlatformTrackingTypes.MOBILE,
            })
        );
    }
    logger.debug(
        `android sites: ${JSON.stringify(androidSites)}, ios sites: ${JSON.stringify(
            iosSites
        )}, source: ${JSON.stringify(source)}`
    );

    yield put(updateSelectedPartnerId(source.partner_id));

    const partner = yield call(getPartnerDetails, source.id);

    yield put(updatePartnersConfigData(partner));

    yield call(setAttPrivacyTooltips, values);

    const allSites = [...iosSites, ...androidSites, ...nonMobileSites].filter(site => site.value !== null);

    const configurations = yield all(
        allSites.map(site => {
            return call(tryGetPartnerConfigurationsForApp, source.id, site.longname, site.platform);
        })
    );

    yield all(
        allSites.map((site, i) => {
            return put(
                updateSourcesConfigurationData(source.name, site.value, {
                    ...configurations[i],
                    warningMessage: getWarningMessageFromConfig(configurations[i]),
                })
            );
        })
    );
}

function* handlePartnerSiteChange(values) {
    const sourcesList = yield select(getSingularLinksSourcesList);
    const source = sourcesList.find(s => s.name === values.source.value);
    const partnersConfig = yield select(getPartnersConfig);
    const usedSites = [];
    const newLink = !values.linkId;

    if (source) {
        if (values.iosDestinationSite && values.iosDestinationSite.value && values.iosLabel) {
            usedSites.push(values.iosDestinationSite.value);
        }

        if (values.androidDestinationSite && values.androidDestinationSite.value && values.androidLabel) {
            usedSites.push(values.androidDestinationSite.value);
        }
        if (
            values.nonMobileDestinationSite &&
            values.nonMobileDestinationSite.value &&
            values.nonMobileDestinationSite.platform !== allNonMobileSitesPlatform
        ) {
            usedSites.push(values.nonMobileDestinationSite.value);
        }

        yield put(sourceConfigResult({ reengagementSupport: partnersConfig.reengagementSupport }, newLink));

        // update partner windows if:
        // 1. in generate link mode
        // 2. overrideWindows toggle is off
        if ((!values.linkId || !values.overrideWindows) && usedSites.length) {
            yield call(setPartnerDefaultWindows, source, usedSites);
        }
    }
}

function* handleIOSDeepLinkChanged(values) {
    const { source, iosDestinationSite, iosDestinationDeeplink } = values;
    // If nothing is selected yet, or App Scheme is configured - we're ok
    if (!source || !source.value || !iosDestinationSite || !iosDestinationSite.value || iosDestinationSite.appScheme) {
        return;
    }
    const configurations = yield select(getSourceConfigurations);
    const iosConfiguration = configurations[`${source.value}_${iosDestinationSite.value}`] || {};
    let warningMessage = iosConfiguration.warningMessage || null;
    if (iosDestinationDeeplink) {
        warningMessage = {
            message: 'STATIC.PAGES.MANAGE_LINKS.IOS_DEEPLINK_WITHOUT_SCHEME_INFO',
            type: 'warning',
            buttonText: '',
        };
    }
    yield put(
        updateSourcesConfigurationData(source.value, iosDestinationSite.value, {
            ...iosConfiguration,
            warningMessage,
        })
    );
}

function* handleSiteChanged(values) {
    const fieldsToUpdate = [];
    const { iosDestinationSite, androidDestinationSite } = values;
    const userData = yield select(getUserData);
    const iosDestinationDeeplinkConf = {
        name: 'iosDestinationDeeplink',
        keys: [],
    };
    const androidDestinationDeeplinkConf = {
        name: 'androidDestinationDeeplink',
        keys: [],
    };

    if (iosDestinationSite && iosDestinationSite.value && !iosDestinationSite.iosTeamId) {
        iosDestinationDeeplinkConf.keys = [
            { key: 'disabled', value: true },
            {
                key: 'placeholder',
                value: 'STATIC.PAGES.GENERATE_TRACKING_LINK.PLACEHOLDER.MISSING_OS_SPECIFIC_VALUE_REACT',
            },
            { key: 'placeholderData', value: { value: 'team ID' } },
            { key: 'labelTooltip', value: 'STATIC.PAGES.MANAGE_LINKS.DEEPLINK_REQUIREMENTS_TOOLTIP' },
        ];
    } else {
        iosDestinationDeeplinkConf.keys = [
            {
                key: 'placeholder',
                value: 'STATIC.PAGES.MANAGE_LINKS.SITE_DEEPLINK_PLACEHOLDER',
            },
            { key: 'labelTooltip', value: '' },
        ];
    }

    if (
        androidDestinationSite &&
        androidDestinationSite.value &&
        !androidDestinationSite.appScheme &&
        !androidDestinationSite.appLinksSigningCertificate
    ) {
        androidDestinationDeeplinkConf.keys = [
            { key: 'disabled', value: true },
            {
                key: 'placeholder',
                value: 'STATIC.PAGES.GENERATE_TRACKING_LINK.PLACEHOLDER.MISSING_OS_SPECIFIC_VALUE_REACT',
            },
            {
                key: 'placeholderData',
                value: { value: userData.android_app_links_enabled ? 'Android App Links certificate' : 'app scheme' },
            },
            { key: 'labelTooltip', value: 'STATIC.PAGES.MANAGE_LINKS.DEEPLINK_REQUIREMENTS_TOOLTIP' },
        ];
    } else {
        androidDestinationDeeplinkConf.keys = [
            {
                key: 'placeholder',
                value: 'STATIC.PAGES.MANAGE_LINKS.SITE_DEEPLINK_PLACEHOLDER',
            },
            { key: 'labelTooltip', value: '' },
        ];
    }

    fieldsToUpdate.push(iosDestinationDeeplinkConf);
    fieldsToUpdate.push(androidDestinationDeeplinkConf);

    if (fieldsToUpdate.length) {
        yield put(formFieldsDefsChanged(fieldsToUpdate));
    }
}

function* handleLinkTypeChanged(field, values) {
    if (field.prevValue === values.linkType) {
        return Promise.resolve();
    }
    const { linkType } = values;
    const reEnabledDefs = {
        name: 'reEnabled',
        keys: [],
    };

    let fieldsDefsChanged = [reEnabledDefs];

    logger.debug('link type changed: resetting form');
    const updateFieldValues = {
        ...initialFieldValues,
        linkType,
    };

    const [androidSites, iosSites, nonMobileSites] = yield all([
        yield select(getAndoridSites),
        yield select(getIOSSites),
        yield select(getNonMobileSites),
    ]);
    const nonMobileLink = isNonMobileLink(nonMobileSites, androidSites, iosSites);
    updateFieldValues.platformTrackingType = nonMobileLink
        ? PlatformTrackingTypes.PC_CONSOLE
        : PlatformTrackingTypes.MOBILE;

    if (linkType === LINK_TYPE_MOBILE_WEB_TO_APP) {
        const sourcesList = yield select(getShelfSources);

        updateFieldValues.campaignName = LINK_DISPLAY_NAME_MOBILE_WEB_TO_APP;
        updateFieldValues.source = sourcesList[0];
    }

    // set initial value which depends on the link type
    updateFieldValues.probabilisticAttributionToggle =
        linkType === LINK_TYPE_MOBILE_CUSTOM || linkType === LINK_TYPE_MOBILE_WEB_TO_APP;

    yield call(setAttPrivacyTooltips, values);
    yield put(fieldValuesChanged(updateFieldValues, true));

    if (isCustomLink(linkType)) {
        reEnabledDefs.keys.push({ key: 'infoMessage', value: 'STATIC.PAGES.MANAGE_LINKS.RE_ENABLE_CUSTOM_INFO' });
        fieldsDefsChanged.push(getOverrideWindowsToggleLabel(false));
        fieldsDefsChanged.push(getIpProbabilisticToggleInfo(false));
    } else if (linkType === LINK_TYPE_MOBILE_PARTNER) {
        reEnabledDefs.keys.push({ key: 'infoMessage', value: 'STATIC.PAGES.MANAGE_LINKS.RE_ENABLE_PARTNER_INFO' });
        fieldsDefsChanged.push(getIpProbabilisticToggleInfo(true));
    } else {
        fieldsDefsChanged = null;
    }

    if (fieldsDefsChanged) {
        yield put(formFieldsDefsChanged(fieldsDefsChanged));
    }

    return Promise.resolve();
}

function* handleShelfDeeplinkChanged(field, values, formDirty) {
    const disabledKey = {
        key: 'disabled',
        value: true,
    };
    const reEnabledDef = {
        name: 'reEnabled',
        keys: [disabledKey],
    };
    const valuesCopy = { ...values };
    if (valuesCopy.linkId && formDirty) {
        yield put(setShelfError('STATIC.PAGES.MANAGE_LINKS.UPDATE_REQUIRED_WARNING', 'warning'));
    } else {
        yield put(setShelfError('', 'warning'));
    }
    const platform = field.name.replace('DestinationDeeplink', '');
    if (!valuesCopy[`${platform}DestinationDDL`] && valuesCopy[`${platform}DestinationDeeplink`]) {
        valuesCopy[`${platform}DestinationDDL`] = SAME_AS_DEEPLINK_OBJECT;
    }
    if (!valuesCopy[`${platform}DestinationDeeplink`]) {
        valuesCopy[`${platform}DestinationDDL`] = '';
    }
    if (!valuesCopy.linkType || !isCustomLink(valuesCopy.linkType)) {
        yield put(fieldValuesChanged(valuesCopy));
        return Promise.resolve();
    }
    if (valuesCopy.androidDestinationDeeplink || valuesCopy.iosDestinationDeeplink) {
        disabledKey.value = false;
    }
    yield put(formFieldsDefsChanged([reEnabledDef]));
    yield put(fieldValuesChanged(valuesCopy));
    yield* handleIOSDeepLinkChanged(valuesCopy);

    return Promise.resolve();
}

function* handleShowShortLinkToggleChanged(values) {
    try {
        if (values.showShortLink && !values.clickThroughShortLink) {
            const shortLink = yield call(api.generateShortLink, values.linkId);
            yield put(fieldValuesChanged({ clickThroughShortLink: shortLink }));
        }
    } catch ({ msg, extra }) {
        logger.debug(`Error generating short link for link id: ${JSON.stringify(values)}, err: ${msg}`);
        let errMessage = 'STATIC.PAGES.GENERATE_TRACKING_LINK.GENERATING_SHORT_LINK_ERROR';
        if (extra) {
            errMessage = extra;
        }
        yield put(setShelfError(errMessage, 'error'));
    }
    return Promise.resolve();
}

function* handleMainCategoryField(field, values) {
    const categoryDefs = yield select(getCategoryDefs);
    if (!categoryDefs.linkSettings.collapsed) {
        return Promise.resolve();
    }
    const { categories, fieldValues } = yield select(getShelfValues);
    const linkSettingsDisabled = categoryDefs.linkSettings.disabledRules.reduce(
        (t, rule) => t || rule(values, categories),
        false
    );
    if (!linkSettingsDisabled) {
        yield put(shelfGroupClicked('linkSettings', values));
        if (fieldValues.linkParams.some(param => param.required)) {
            yield put(shelfGroupClicked('linkParamsCategory', values));
        }
    }
    return Promise.resolve();
}

function* handleOverrideWindowsToggleChanged(values) {
    const {
        overrideWindows,
        source: vSource,
        iosDestinationSite,
        androidDestinationSite,
        androidLabel,
        iosLabel,
    } = values;
    logger.debug('source: ', vSource);
    if (!overrideWindows) {
        if (typeof values.source === 'object' && values.linkType === LINK_TYPE_MOBILE_PARTNER) {
            const sourcesList = yield select(getSingularLinksSourcesList);
            const source = sourcesList.find(s => s.name === vSource.value);
            const usedSites = [];
            if (iosLabel && iosDestinationSite && iosDestinationSite.value) {
                usedSites.push(iosDestinationSite.value);
            }
            if (androidLabel && androidDestinationSite && androidDestinationSite.value) {
                usedSites.push(androidDestinationSite.value);
            }
            if (usedSites.length) {
                yield call(setPartnerDefaultWindows, source, usedSites);
            }
        } else {
            yield call(setCustomDefaultWindows);
        }
    }
}

function* handleCtvWindowsToggleChanged(values) {
    const { ipProbabilisticAttributionToggle } = values;
    if (!ipProbabilisticAttributionToggle) {
        yield call(setCtvDefaultWindows);
    }
}

function* handleShelfFormUpdate({ field, values, oldLink, formDirty }) {
    if (oldLink) {
        return Promise.resolve();
    }
    if (field.name === 'source' && typeof values.source === 'object' && values.linkType === LINK_TYPE_MOBILE_PARTNER) {
        yield call(handlePartnerChanged, values);
    }
    if (
        field.name === 'source' &&
        typeof values.source === 'object' &&
        isCustomLink(values.linkType) &&
        !values.linkId
    ) {
        // when creating a new custom link - set ipProbabilistic toggle value based on the custom link source support
        const newLinkIpProbabilisticValue =
            values.source.ip_probabilistic_support_type === IP_PROBABILISTIC_SUPPORT_OPTIONS.RECOMMENDED ||
            values.source.ip_probabilistic_support_type === IP_PROBABILISTIC_SUPPORT_OPTIONS.MANDATORY;
        yield put(fieldValuesChanged({ ipProbabilisticAttributionToggle: newLinkIpProbabilisticValue }));
    }
    if (
        [
            'source',
            'iosDestinationSite',
            'androidDestinationSite',
            'androidLabel',
            'iosLabel',
            'nonMobileDestinationSite',
        ].includes(field.name)
    ) {
        if (values.linkType === LINK_TYPE_MOBILE_PARTNER) {
            yield call(handlePartnerSiteChange, values);
        }
        yield call(handleSiteChanged, values);
        yield* handleIOSDeepLinkChanged(values);
    }
    if (field.name === 'linkType') {
        yield call(handleLinkTypeChanged, field, values);
    }
    if (field.name.includes('DestinationDeeplink')) {
        yield call(handleShelfDeeplinkChanged, field, values, formDirty);
    }
    if (field.name === 'showShortLink') {
        yield call(handleShowShortLinkToggleChanged, values);
    }
    if (field.name === 'overrideWindows') {
        yield call(handleOverrideWindowsToggleChanged, values);
    }
    if (field.name === 'ipProbabilisticAttributionToggle') {
        yield call(handleCtvWindowsToggleChanged, values);
    }

    if (field.name === 'iosLabel' || field.name === 'androidLabel') {
        yield call(setAttPrivacyTooltips, values);
    }

    const categoryFields = yield select(getCategoryFields);
    if (categoryFields.main.includes(field.name) && !values.linkId) {
        yield call(handleMainCategoryField, field, values);
    }
    return Promise.resolve();
}

const getObjectOrString = (item, key = 'label') => {
    if (typeof item === 'string') {
        return item;
    }
    return item[key];
};

const getDDLValue = (values, platformLower) => {
    const ddlValue = getObjectOrString(values[`${platformLower}DestinationDDL`], 'value');
    if (ddlValue === SAME_AS_DEEPLINK_OBJECT.value) {
        return getObjectOrString(values[`${platformLower}DestinationDeeplink`]);
    }
    return ddlValue;
};

const getRedirectionsForPlatform = (platform, values) => {
    const platformPrefix = getPlatformPrefix(platform);

    if (!values[`${platformPrefix}DestinationSite`] || !values[`${platformPrefix}Label`]) {
        return null;
    }

    return {
        redirect_url: getObjectOrString(values[`${platformPrefix}DestinationRedirect`]),
        deeplink_url: values[`${platformPrefix}DestinationDeeplink`]
            ? getObjectOrString(values[`${platformPrefix}DestinationDeeplink`])
            : null,
        ddl_url: values[`${platformPrefix}DestinationDDL`] ? getDDLValue(values, platformPrefix) : null,
    };
};

const getAttributionSettings = (values, fieldDefs) => {
    const {
        clickthroughWindow,
        clickthroughFPWindow,
        viewthroughWindow,
        viewthroughFPWindow,
        reWindow,
        ctvWindow,
        ctvPriorityWindow,
        // REMOVING IA_WINDOW // iaWindow,
        overrideWindows,
    } = values;
    return overrideWindows
        ? {
              ct_window: clickthroughWindow * fieldDefs.clickthroughWindow.normalizeValue,
              ctf_window: clickthroughFPWindow * fieldDefs.clickthroughFPWindow.normalizeValue,
              vt_window: viewthroughWindow * fieldDefs.viewthroughWindow.normalizeValue,
              vtf_window: viewthroughFPWindow * fieldDefs.viewthroughFPWindow.normalizeValue,
              rec_window: reWindow * fieldDefs.reWindow.normalizeValue,
              vt_iponly_install_ctv_window: ctvWindow * fieldDefs.ctvWindow.normalizeValue,
              vt_iponly_install_ctv_prioritization_window:
                  ctvPriorityWindow * fieldDefs.ctvPriorityWindow.normalizeValue,
              ia_window: null, // REMOVING IA_WINDOW // iaWindow * fieldDefs.iaWindow.normalizeValue,
          }
        : null;
};

const validateGenerateLink = linkData => {
    if (!linkData.ios_redirections && !linkData.android_redirections && !linkData.non_mobile_redirect_url) {
        throw {
            msg: 'Platform destination redirects were not configured',
            extra: 'STATIC.PAGES.GENERATE_TRACKING_LINK.TRACKING_LINK_ERROR_MISSING_PLATFORM',
        };
    }
    return '';
};

const getOSSiteId = (formValues, platform) => {
    const platformLower = platform.toLowerCase();
    if (!formValues[`${platformLower}Label`]) {
        return null;
    }
    return getObjectOrString(formValues[`${platformLower}DestinationSite`], 'value') || null;
};

function* handleLinkGenerated() {
    yield delay(2000);
    yield put(generateLinkSuccessAfter('done'));
}

function* prepareLinkData(formValues) {
    const selectedData = yield select(getGenerateLinkData);
    const selectedDomainData = selectedData.domains.find(domain => domain.full_domain === formValues.subDomain);

    let linkName = formValues.campaignName;
    let sourceName = formValues.source.value;

    if (sourceName === LINK_TYPE_MOBILE_CUSTOM) {
        sourceName = formValues.customSourceName;
    } else if (formValues.linkType === LINK_TYPE_MOBILE_WEB_TO_APP) {
        linkName = LINK_DISPLAY_NAME_MOBILE_WEB_TO_APP;
        sourceName = SOURCE_NAME_MOBILE_WEB_TO_APP;
    }
    const linkExtraParams = !formValues.linkParams
        ? []
        : formValues.linkParams.reduce((paramsMap, param) => {
              if (param.field !== defaultExtraParamsRow.field && param.field.value) {
                  paramsMap[param.field.value] = param.value;
              }
              return paramsMap;
          }, {});

    // pass _smtype=3 through link extra params by default, for ios only
    if (formValues.iosLabel && formValues.probabilisticAttributionToggle) {
        linkExtraParams._smtype = SMTYPE_OWNED_MOBILE_WEB_TO_APP;
    }

    const data = {
        link_type: formValues.linkType,
        source: getObjectOrString(sourceName, 'value'),
        link_name: linkName,
        is_custom: isCustomLink(formValues.linkType),
        attribution_settings: getAttributionSettings(formValues, selectedData.fieldDefs, sourceName),
        fallback: getObjectOrString(formValues.destinationFallback),
        android_redirections: getRedirectionsForPlatform('Android', formValues),
        ios_redirections: getRedirectionsForPlatform('iOS', formValues),
        non_mobile_redirect_url: getObjectOrString(formValues.nonMobileDestinationRedirect),
        re_enabled: formValues.reEnabled,
        ios_site_id: getOSSiteId(formValues, 'iOS') || null,
        android_site_id: getOSSiteId(formValues, 'Android') || null,
        non_mobile_apps_longname: getObjectOrString(formValues.nonMobileDestinationSite, 'longname'),
        non_mobile_site_id: isNonMobileLinkRule(formValues)
            ? getObjectOrString(formValues.nonMobileDestinationSite, 'value')
            : null,
        app_id: selectedData.app.id,
        dns_zone: selectedDomainData.dns_zone,
        subdomain: selectedDomainData.subdomain,
        extra_params: linkExtraParams,
        show_short_link: formValues.showShortLink,
        override_windows: formValues.overrideWindows,
        ip_probabilistic: formValues.ipProbabilisticAttributionToggle,
    };
    yield call(validateGenerateLink, data);
    return data;
}

function* handleArchiveLinkClicked({ linkId, singularLink = true, campaignId = linkId }, archived = true) {
    try {
        const appId = yield select(getSelectedAppId);
        let response;
        if (!singularLink) {
            response = yield call(oldApi.deleteCampaign.bind(api), campaignId);
            yield put(updateLinkData(linkId, { status: 'archived' }));
            yield fork(loadLegacyLinksSummary);
        } else {
            response = yield call(api.archiveLink.bind(api), linkId, archived);
            logger.debug(`Archive Link response: ${JSON.stringify(response)}`);
            yield put(updateLinksForApp(appId, response.tracking_links));
            yield fork(loadLinksSummary);
        }
    } catch (e) {
        logger.debug(`Archive Link error: ${JSON.stringify(e)}`);
    }
}

function* handleGenerateLink({ formValues }) {
    logger.debug(`generate link called with form values: ${JSON.stringify(formValues)}`);
    try {
        const data = yield call(prepareLinkData, formValues);
        logger.debug(`generate link calling api with: ${JSON.stringify(data)}`);
        const response = yield call(api.updateOrCreateLink, data);
        logger.debug(`Generating link response: ${JSON.stringify(response)}`);
        yield put(generateLinkSuccess(response, data));
        if (response && response.existing_tags && response.existing_tags.status === 'archived') {
            yield put(setShelfError('The requested link is archived', 'warning', 'Un-Archive', 'unArchiveClicked'));
        }
        yield fork(handleLinkGenerated);
        yield fork(handleAppSelected, { appId: data.app_id, forceReload: true });
        yield fork(loadLinksSummary);
    } catch (e) {
        logger.debug(
            `Error generating link called with form values: ${JSON.stringify(formValues)}, err: ${JSON.stringify(e)}`
        );
        let errMessage = 'STATIC.PAGES.GENERATE_TRACKING_LINK.GENERATING_TRACKING_LINK_ERROR';
        if (e && e.extra) {
            errMessage = e.extra;
        }
        yield put(setShelfError(errMessage, 'error'));
    }
}

function* handleUpdateLink({ formValues }) {
    logger.debug(`update link called with form values: ${JSON.stringify(formValues)}`);
    try {
        const data = yield call(prepareLinkData, formValues);
        data.link_id = formValues.linkId;

        logger.debug(`update link calling api with: ${JSON.stringify(data)}`);
        const response = yield call(api.updateOrCreateLink, data);
        logger.debug(`Updating link response: ${response}`);
        yield put(generateLinkSuccess(response, data, true));
        if (response.tracking_links?.length > 0 && response.tracking_links[0].is_api_link) {
            yield put(updateApiLinksForApp(data.app_id, response.tracking_links));
        } else {
            yield put(updateLinksForApp(data.app_id, response.tracking_links));
        }

        const { linksData } = normalizeLinks(response.tracking_links);
        const linkData = yield select(getLinkData(linksData[data.link_id]));

        yield put(
            fieldValuesChanged({
                clickThroughLink: linkData.click_url,
                viewThroughLink: linkData.impression_url,
            })
        );
        yield fork(handleLinkGenerated);
    } catch ({ msg, extra }) {
        logger.debug(
            `Error updating link called with form values: ${JSON.stringify(formValues)}, err: ${msg} extra: ${extra}`
        );

        let errMessage = 'STATIC.PAGES.GENERATE_TRACKING_LINK.GENERATING_TRACKING_LINK_ERROR';
        if (extra) {
            errMessage = extra;
        }
        yield put(setShelfError(errMessage, 'error'));
    }
}

function* handleLinkClicked({ linkId, readOnly, isDuplicate = false, isLegacyLink = false }) {
    let linkData;
    const appId = yield select(getSelectedAppId);

    try {
        if (isLegacyLink) {
            const linkResponse = yield select(getLinksData);
            linkData = yield select(getLinkData(normalizeLink(linkResponse[linkId])));
        } else {
            const linkResponse = yield call(api.getLink, appId, linkId);
            const { linksData } = normalizeLinks(linkResponse.tracking_links);
            linkData = yield select(getLinkData(linksData[linkId]));
        }
    } catch (e) {
        yield put(setShelfError('STATIC.PAGES.GENERATE_TRACKING_LINK.GETTING_TRACKING_LINK_ERROR', 'error'));
        return;
    }

    const formValues = getLinkFormValues(linkData);

    if (isDuplicate) {
        formValues.campaignName += '__COPY';
        formValues.clickThroughLink = '';
        formValues.viewThroughLink = '';
        formValues.linkId = '';
        formValues.showShortLink = false;
        formValues.clickThroughShortLink = '';
    }

    yield put(formUpdate({ name: 'source' }, formValues, !linkData.singular_link));
    yield put(linkClickedFinish(!linkData.singular_link, formValues, readOnly));

    if (linkData.fieldsToUpdate && linkData.fieldsToUpdate.length) {
        yield put(formFieldsDefsChanged(linkData.fieldsToUpdate));
    }
}

function* handleCreateSubDomain({ subDomain, dnsZone }) {
    try {
        const response = yield call(api.createDomain, subDomain, dnsZone);
        logger.debug(`Create SubDomain response: ${JSON.stringify(response)}`);
        const domainsResponse = yield call(api.getDomains);
        const successMessage = response.exists
            ? 'STATIC.PAGES.MANAGE_LINKS.UNARCHIVE_SUB_DOMAIN_SUCCESS_MESSAGE'
            : 'STATIC.PAGES.MANAGE_LINKS.CREATE_SUB_DOMAIN_SUCCESS_MESSAGE';
        yield put(createSubDomainSuccess(domainsResponse.domains, domainsResponse.dns_zones, successMessage));
    } catch (e) {
        logger.debug(`Create SubDomain error: ${JSON.stringify(e)}`);
        let errorMessage = `There was an error creating ${subDomain} sub-domain`;
        if (e && e.extra && e.extra.error_code) {
            const errorCode = e.extra.error_code;
            if (errorCode === 300) {
                errorMessage = `Sub-domain “${subDomain}” already exists!`;
            } else if (errorCode === 304) {
                errorMessage = 'Reached maximal number of domains allowed';
            }
        }
        yield put(createSubDomainError(errorMessage));
    }
}

function* handleFavouriteSubDomain({ subdomain, zone, favourite }) {
    try {
        const response = yield call(api.favouriteDomain.bind(api), subdomain, zone, !favourite);
        logger.debug(`Favourite SubDomain response: ${JSON.stringify(response)}`);
        yield put(favouriteButtonFinished(response));
    } catch (e) {
        logger.debug(`Favourite SubDomain error: ${JSON.stringify(e)}`);
    }
}

function* handleArchiveSubDomain({ subdomain, zone }) {
    try {
        const response = yield call(api.archiveDomain.bind(api), subdomain, zone);
        logger.debug(`Archive SubDomain response: ${JSON.stringify(response)}`);
        yield put(archiveButtonFinished(response));
    } catch (e) {
        logger.debug(`Archive SubDomain error: ${JSON.stringify(e)}`);
    }
}

/* ---------------------------- */
/* ------- Watchers ----------- */

/* ---------------------------- */

function* watchAppSelected() {
    yield takeEvery(APP_SELECTED, handleAppSelected);
}

function* watchShelfFormUpdate() {
    yield takeEvery(FORM_UPDATED, handleShelfFormUpdate);
}

function* watchGenerateLink() {
    yield takeEvery(GENERATE_LINK, handleGenerateLink);
}

function* watchLinkClicked() {
    yield takeEvery(LINK_CLICKED, handleLinkClicked);
}

function* watchArchiveLinkClicked() {
    yield takeEvery(ARCHIVE_LINK_CLICKED, handleArchiveLinkClicked);
}

function* watchUnArchiveLinkClicked() {
    while (true) {
        const action = yield take(UN_ARCHIVE_LINK_CLICKED);
        yield call(handleArchiveLinkClicked, action, false);
        yield put(setShelfError('', 'error', '', ''));
    }
}

function* watchCreateSubDomain() {
    yield takeEvery(SUB_DOMAINS_CREATE, handleCreateSubDomain);
}

function* watchFavouriteSubDomain() {
    yield takeEvery(FAVOURITE_BUTTON_CLICKED, handleFavouriteSubDomain);
}

function* watchArchiveSubDomain() {
    yield takeEvery(ARCHIVE_BUTTON_CLICKED, handleArchiveSubDomain);
}

function* watchUpdateLink() {
    yield takeEvery(UPDATE_LINK, handleUpdateLink);
}

function* watchOldLinksToggle() {
    yield takeEvery(OLD_LINKS_TOGGLE, handleOldLinksToggle);
}

function* watchAppsFilterChange() {
    yield takeEvery(APP_FILTER_CHANGED, handleFilterChanged);
}

function* watchApiLinksToggle() {
    yield takeEvery(API_LINKS_TOGGLE, handleShowApiLinks);
}

const watchers = [
    watchAppSelected,
    watchShelfFormUpdate,
    watchGenerateLink,
    watchLinkClicked,
    watchCreateSubDomain,
    watchFavouriteSubDomain,
    watchArchiveSubDomain,
    watchUpdateLink,
    watchArchiveLinkClicked,
    watchUnArchiveLinkClicked,
    watchOldLinksToggle,
    watchAppsFilterChange,
    watchApiLinksToggle,
];

export { onload, destroy, watchers };
