import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Dialog, Box } from '@mui/material';
import css from './MuiDialog.css';
import CrossIcon from '../../resources/icons/cross.svg';

const MuiDialog = ({ title, onClose, open, children, paperClass }) => {
    return (
        <Dialog onClose={onClose} open={open} PaperProps={{ className: paperClass }}>
            <Box className={css.title}>
                <Translate id={title} />
                <CrossIcon className={css.crossIcon} onClick={onClose} />
            </Box>
            {children}
        </Dialog>
    );
};

MuiDialog.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    children: PropTypes.element,
    paperClass: PropTypes.string,
};

MuiDialog.defaultProps = {
    onClose: () => {},
    open: false,
    children: null,
    paperClass: '',
};

export default MuiDialog;
