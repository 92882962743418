import { connect } from 'react-redux';
import { getPage } from './selectors';
import {
    search,
    editStart,
    editCancel,
    editSave,
    editChange,
    toggleAdAccount,
    editSegmentStart,
    editSegmentChange,
    editSegmentSave,
    editSegmentCancel,
    manualRun,
} from './actions';
import AudienceDistributions from './page';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSearch: (...args) => {
            dispatch(search(...args));
        },
        onEditStart: (...args) => {
            dispatch(editStart(...args));
        },
        onEditCancel: (...args) => {
            dispatch(editCancel(...args));
        },
        onEditConfirm: (...args) => {
            dispatch(editSave(...args));
        },
        onEditChange: (...args) => {
            dispatch(editChange(...args));
        },
        onToggleAdAccount: (...args) => {
            dispatch(toggleAdAccount(...args));
        },
        onEditSegmentStart: (...args) => {
            dispatch(editSegmentStart(...args));
        },
        onEditSegmentChange: (...args) => {
            dispatch(editSegmentChange(...args));
        },
        onEditSegmentSave: (...args) => {
            dispatch(editSegmentSave(...args));
        },
        onEditSegmentCancel: (...args) => {
            dispatch(editSegmentCancel(...args));
        },
        onManualRun: (...args) => {
            dispatch(manualRun(...args));
        },
    };
};

export default connect(state => getPage(state), mapDispatchToProps)(AudienceDistributions);
