import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { getCurrencySymbol } from 'utils/currencyUtils';
import withTranslate from '../../containers/widgets/withTranslate';
import ContractIcon from '../../resources/icons/contract.svg';
import HourglassIcon from '../../resources/icons/hourglass.svg';
import TimezoneIcon from '../../resources/icons/timezone.svg';
import CurrencyIcon from '../../resources/icons/currency.svg';

import CalendarIcon from '../../resources/icons/calendar.svg';
import {
    ARR_CURRENCY,
    DATA_BADGE_MAP,
    DATA_TEST_ID_AD_SPEND_CAP,
    DATA_TEST_ID_CAP,
    DATA_TEST_ID_CURRENCY,
    DATA_TEST_ID_END_DATE,
    DATA_TEST_ID_ORG_PLAN_DETAILS,
    DATA_TEST_ID_PLAN_PRICE,
    DATA_TEST_ID_START_DATE,
    DATA_TEST_ID_TIMEZONE,
} from '../consts';
import { getOrgPlanDetailsBadgeText, shouldShowPlanPrice } from '../utils';
import { getUserData } from '../../selectors/user';
import css from './OrgPlanDetails.css';
import { Label } from '../../components/widgets';

const OrgPlanDetails = ({
    timezone,
    currency,
    planPrice,
    installCap,
    adSpendCap,
    contractStartDate,
    contractEndDate,
    translate,
}) => {
    const {
        organization_tier: tier = '',
        is_paying_customer: isPaying,
        is_org_admin: isOrgAdmin,
    } = useSelector(state => getUserData(state));

    return (
        <div data-testid={DATA_TEST_ID_ORG_PLAN_DETAILS} className={css.container}>
            <div className={css.header}>
                <div className={css.title}>
                    <Translate id="STATIC.PAGES.ACCOUNT_PLAN.PLAN_DETAILS" />
                </div>
                <div
                    className={classNames(css.tierBadge, css[`${tier.toLowerCase()}`])}
                    data-testid={DATA_BADGE_MAP[tier]}
                >
                    {translate(getOrgPlanDetailsBadgeText(tier))}
                </div>
            </div>
            <div className={css.divider} />
            <div className={css.capInfoContainer}>
                <div className={css.capInfoBox}>
                    <ContractIcon className={css.capInfoIcon} />
                    {installCap ? (
                        <div className={css.capInfoDetails}>
                            <div className={css.cap} data-testid={DATA_TEST_ID_CAP}>
                                {`${installCap.toLocaleString()} `}
                                <Translate id={`STATIC.PAGES.ACCOUNT_PLAN.${isPaying ? 'PAID' : 'FREE'}_INSTALLS`} />
                            </div>
                            <Label
                                className={css.capInfoText}
                                text={
                                    isPaying
                                        ? 'STATIC.PAGES.ACCOUNT_PLAN.INSTALL_CAP'
                                        : 'STATIC.PAGES.ACCOUNT_PLAN.INSTALL_CAP_FREE'
                                }
                                tooltip="STATIC.PAGES.ACCOUNT_PLAN.TOOLTIPS.PACING_CARD"
                            />
                        </div>
                    ) : (
                        <div className={css.capInfoDetails} data-testid={DATA_TEST_ID_AD_SPEND_CAP}>
                            <div className={css.cap}>
                                {getCurrencySymbol(ARR_CURRENCY)}
                                {adSpendCap.toLocaleString()} {ARR_CURRENCY}
                            </div>
                            <div className={css.capInfoText}>
                                <Translate id="STATIC.PAGES.ACCOUNT_PLAN.AD_SPEND_CAP" />
                            </div>
                        </div>
                    )}
                </div>
                {shouldShowPlanPrice(tier, isOrgAdmin) && (
                    <div className={css.capInfoBox}>
                        <ContractIcon className={css.capInfoIcon} />
                        <div className={css.capInfoDetails} data-testid={DATA_TEST_ID_PLAN_PRICE}>
                            <div className={css.planPrice}>
                                {getCurrencySymbol(ARR_CURRENCY)}
                                {planPrice.toLocaleString()} {ARR_CURRENCY}
                            </div>
                            <div className={css.capInfoText}>
                                <Translate id="STATIC.PAGES.ACCOUNT_PLAN.PLAN_PRICE" />
                            </div>
                        </div>
                    </div>
                )}
                <div className={css.capInfoBox}>
                    <HourglassIcon className={css.capInfoIcon} />
                    <div className={css.capInfoDetails} data-testid={DATA_TEST_ID_END_DATE}>
                        <div className={css.endDate}>{contractEndDate} </div>
                        <div className={css.capInfoText}>Expiration Date</div>
                    </div>
                </div>
            </div>
            <div className={css.moreInfo}>
                <div className={css.moreInfoTitle}>More info:</div>
                <div className={css.localeInfo}>
                    <div className={css.timezone} data-testid={DATA_TEST_ID_TIMEZONE}>
                        <TimezoneIcon className={css.timezoneIcon} />
                        <Label type="sideLabel" text="STATIC.PAGES.ACCOUNT_PLAN.TIMEZONE" />: {timezone}
                    </div>
                    <div className={css.currency} data-testid={DATA_TEST_ID_CURRENCY}>
                        <CurrencyIcon className={css.currencyIcon} />
                        <Label type="sideLabel" text="STATIC.PAGES.ACCOUNT_PLAN.CURRENCY" />: {currency}
                    </div>
                </div>
                <div className={css.startDate} data-testid={DATA_TEST_ID_START_DATE}>
                    <CalendarIcon className={css.startDateIcon} />
                    <Label type="sideLabel" text="STATIC.PAGES.ACCOUNT_PLAN.START_DATE" />: {contractStartDate}
                    <Label tooltip="STATIC.PAGES.ACCOUNT_PLAN.TOOLTIPS.PLAN_DETAILS_START_DATE" />
                </div>
            </div>
        </div>
    );
};

OrgPlanDetails.propTypes = {
    timezone: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    contractStartDate: PropTypes.string.isRequired,
    contractEndDate: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    planPrice: PropTypes.number,
    installCap: PropTypes.number,
    adSpendCap: PropTypes.number,
};

OrgPlanDetails.defaultProps = {
    translate: () => {},
};

export default withTranslate(OrgPlanDetails);
