import { METRIC_FIELD_NAMES } from '../utils/consts';

export const OPERATOR_TYPES = [
    {
        name: 'in',
        display_name: 'include',
        actual_type: 'filterOperators',
    },
    {
        name: 'not in',
        display_name: 'exclude',
        actual_type: 'filterOperators',
    },
];

export const CPE_POSTFIX = 'CPE';
export const ECVR_POSTFIX = 'ECVR';

export const FIELDS_TYPES = {
    TIME_DIMENSIONS: '__time_dimensions__',
    TECHNICAL_SPECS_DIMENSIONS: '__technical_specs_dimensions__',
    DIMENSIONS: '__dimensions__',
    SOURCE_DIMENSIONS: '__source_dimensions__',
    SKAN_CONVERSION_DIMENSIONS: '__skan_conversion_dimensions__',
    CUSTOM_DIMENSIONS: '__custom_dimensions__',
    METRICS: '__metrics__',
    APP_METRICS: '__app_metrics__',
    SOURCE_METRICS: '__source_metrics__',
    SKAN_MODELED_METRICS: '__skan_modeled_metrics__',
    SKAN_MODELED_EVENTS: '__skan_modeled_events__',
    WEB_METRICS: '__web_metrics__',
    CROSS_DEVICE_METRICS: '__cross_device_metrics__',
    COHORT_METRICS: '__cohort_metrics__',
    CUSTOM_EVENTS: '__custom_events__',
    CONVERSION_EVENTS: '__conversion_events__',
    UNIFIED_METRIC_GROUP: '__unified_metric_group__',
    UNIFIED_DATA_TYPES: '__unified_data_types__',
    UNIFIED_COHORT_METRICS: '__unified_cohort_metrics__',
    SKAN_DATE_TYPES: '__skan_data_types__',
};

export const METRICS_CARDS_DATA = {
    [METRIC_FIELD_NAMES.COST]: { displayName: 'Cost' },
    [METRIC_FIELD_NAMES.CUSTOM_INSTALLS]: { displayName: 'Installs' },
    [METRIC_FIELD_NAMES.CUSTOM_IMPRESSIONS]: { displayName: 'Impressions' },
    [METRIC_FIELD_NAMES.REVENUE]: { displayName: 'Revenue', cohortPeriod: '7d' },
    [METRIC_FIELD_NAMES.ROI]: { displayName: 'ROI', cohortPeriod: '7d' },
};

export const METRIC_CARDS_ORDER = [
    METRIC_FIELD_NAMES.COST,
    METRIC_FIELD_NAMES.CUSTOM_INSTALLS,
    METRIC_FIELD_NAMES.CUSTOM_IMPRESSIONS,
    METRIC_FIELD_NAMES.REVENUE,
    METRIC_FIELD_NAMES.ROI,
];

export const REPORT_TYPES = {
    REPORT: 'report',
    PIVOT: 'pivot',
};

export const SKAN_DATE_DIMENSION_NAMES = {
    POSTBACK_DATE: 'skan_postback_date',
    ESTIMATED_INSTALL_DATE: 'estimated_install_date',
};

export const CROSS_DEVICE_ATTRIBUTION_OPTIONS = [
    {
        name: 'cross_device_cohort_type',
        displayName: 'Cross Device Cohort',
        requestParam: 'cross_device_cohort_type',
        items: [
            {
                name: 'device',
                display_name: 'Device',
                is_default: true,
            },
            {
                name: 'user',
                display_name: 'User',
            },
        ],
    },
];

export const IGNORED_DATA_TYPES = CROSS_DEVICE_ATTRIBUTION_OPTIONS.map(option => option.name);
