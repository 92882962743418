import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as ComponentWidgets from '../widgets';
import * as ContainerWidgets from '../../containers/widgets';

const Components = {
    component: {
        ...ComponentWidgets,
    },
    container: {
        ...ContainerWidgets,
    },
};

class HeaderComponentWrapper extends React.Component {
    constructor(props) {
        super(props);
        const mapStateToProps = (state) => {
            const stateProps = props.selector ? props.selector(state) : {};
            return {
                ...props.props,
                ...stateProps,
            };
        };

        const mapDispatchToProps = (dispatch) => {
            return props.actions.reduce((total, current) => {
                total[current.actionName] = (...args) => {
                    if (!current.action) {
                        return;
                    }
                    let actions = current.action;
                    if (typeof current.action === 'function') {
                        actions = [current.action];
                    }
                    for (const actionCreator of actions) {
                        const action = actionCreator(...args);
                        if (!action) {
                            return;
                        }
                        dispatch(action);
                    }
                };
                return total;
            }, {});
        };
        this.CreatedContainer = connect(
            mapStateToProps,
            mapDispatchToProps
        )(Components[props.classType][props.componentClass]);
    }

    render() {
        return <this.CreatedContainer />;
    }
}

HeaderComponentWrapper.propTypes = {
    props: PropTypes.objectOf(PropTypes.any),
    componentClass: PropTypes.string,
    classType: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.object),
    selector: PropTypes.func,
};

HeaderComponentWrapper.defaultProps = {
    props: {},
    componentClass: '',
    classType: 'component',
    actions: [],
    selector: () => {},
};

export default HeaderComponentWrapper;
