import { connect } from 'react-redux';
import { onCloseReportsEmptyState } from '../actions/onboarding';
import Dashboard from './dashboard';
import { getUserData } from '../selectors/user';
import { getEmptyStateOrgData } from '../selectors/onboarding';

const mapStateToProps = state => {
    return {
        emptyStateOrgData: getEmptyStateOrgData(state),
        dateLimit: getUserData(state).dashboard_date_limit,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseReportsEmptyState: () => dispatch(onCloseReportsEmptyState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
