import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../../components/widgets';
import { arrayToDict } from '../../../utils/arrays';
import fieldVisibilityRules from '../../../selectors/reportsConfig/fieldVisibilityRules';
import css from '../DataDestinationShelf.css';
import { getCohortFields, getFieldDisplayName, getSelectedOptions } from '../../customShemaUtils';

const MANDATORY_COHORT_PERIODS = ['actual', '1d', '7d'];
const DISALLOWED_COHORT_PERIODS_NAMES = ['p1'];
const MANDATORY_COHORT_METRICS = ['revenue'];
const ALLOWED_COHORT_PERIODS = [-2, 1, 7, 3, 14, 30];
const DISALLOWED_COHORT_METRICS = ['roi', 'arpu'];

const cohortPeriodsVisibility = ({ period_id: period, name }) =>
    ALLOWED_COHORT_PERIODS.includes(period) && !DISALLOWED_COHORT_PERIODS_NAMES.includes(name);

const cohortMetricsVisibility = (field, isAdmin, userData) =>
    fieldVisibilityRules.reports(field, isAdmin, userData, true) && !DISALLOWED_COHORT_METRICS.includes(field.name);

export default function TrackerCustomSchemaFields({ readonly, selected, getOptions, onChange }) {
    const dimensionsOptions = getOptions('custom_dimensions', fieldVisibilityRules.reports);
    const eventsOptions = getOptions('customEvents', fieldVisibilityRules.reports);
    const metricsOptions = getOptions('cohortMetrics', cohortMetricsVisibility, MANDATORY_COHORT_METRICS);
    const periodsOptions = getOptions('periods', cohortPeriodsVisibility, MANDATORY_COHORT_PERIODS);

    const [dimensions, setDimensions] = useState([]);
    const [events, setEvents] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [periods, setPeriods] = useState([]);

    useEffect(() => {
        const {
            selectedDimensions = [],
            selectedEvents = [],
            selectedMetrics = metricsOptions.filter(({ value }) => MANDATORY_COHORT_METRICS.includes(value)),
            selectedPeriods = periodsOptions.filter(({ value }) => MANDATORY_COHORT_PERIODS.includes(value)),
        } = getSelectedOptions(readonly, selected, dimensionsOptions, eventsOptions, metricsOptions, periodsOptions);

        setDimensions(selectedDimensions);
        setEvents(selectedEvents);
        setMetrics(selectedMetrics);
        setPeriods(selectedPeriods);
    }, [readonly, selected, getOptions]);

    useEffect(() => {
        if (readonly) {
            return;
        }

        const cohortFields = getCohortFields([...events, ...metrics], periods);
        const dimensionsFields = arrayToDict(dimensions, ({ value, label }) => ({
            [value]: getFieldDisplayName(label),
        }));

        onChange({ ...cohortFields, ...dimensionsFields });
    }, [dimensions, periods, metrics, events]);

    const autocompleteProps = {
        disabled: readonly,
        clearable: true,
        searchable: true,
        containerClass: css.field,
    };

    return (
        <>
            <AutoCompleteField
                value={dimensions}
                label="STATIC.CUSTOM_DIMENSIONS"
                options={dimensionsOptions}
                onChange={setDimensions}
                onInputChange={setDimensions}
                {...autocompleteProps}
            />
            <AutoCompleteField
                value={events}
                label="STATIC.COHORT_EVENTS"
                options={eventsOptions}
                onChange={setEvents}
                onInputChange={setEvents}
                {...autocompleteProps}
            />
            <AutoCompleteField
                value={metrics}
                label="STATIC.COHORT_METRICS"
                options={metricsOptions}
                onChange={setMetrics}
                onInputChange={setMetrics}
                {...autocompleteProps}
            />
            <AutoCompleteField
                value={periods}
                label="STATIC.COHORT_PERIODS"
                options={periodsOptions}
                onChange={setPeriods}
                onInputChange={setPeriods}
                {...autocompleteProps}
            />
        </>
    );
}

TrackerCustomSchemaFields.propTypes = {
    readonly: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
};

TrackerCustomSchemaFields.defaultProps = {
    readonly: false,
    selected: [],
};
