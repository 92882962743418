import Reports from './reports';
import API from './api';
import { defaultReportQueryParams } from '../utils/reports';

const api = new API();

const URL_UNIFIED_REPORTING_DATA = '/api/get_unified_report_data';
const URL_GET_DATA_TYPES_TO_METRIC_NAME = '/api/get_unified_data_types_to_metric_name';

let instance = null;

class UnifiedReport {
    constructor() {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    runQuery(params, options) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_UNIFIED_REPORTING_DATA, {
                method: 'POST',
                credentials: 'include',
                body: {
                    ...defaultReportQueryParams,
                    ...params,
                },
                cache: false,
                unique: true,
                ...options,
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject(Reports.ERROR_RUN_QUERY);
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    getDataTypesToMetricName() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_GET_DATA_TYPES_TO_METRIC_NAME, {
                method: 'GET',
                credentials: 'include',
                cache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default UnifiedReport;
