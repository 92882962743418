import { cohorts, reportBase } from './reportsBase';
import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';

import { SORTING_INDEX_MAP, SORTING_INDEX_TO_DISPLAY_NAME } from '../../components/pages/reports/consts';

export default {
    type: reportTypes.unifiedReport,
    fields: [
        ...reportBase.fields,
        'skanDateDimensionName',
        'discrepancyMetrics',
        ...cohorts.fields,
        'dataTypeFields',
        'metricGroupFields',
        'cohortMetricGroupFields',
        'skanCustomEvents',
    ],
    visibleRule: rules[reportTypes.unifiedReport],
    dataSelector: {
        ...reportBase.dataSelector,
        skanDateDimensionName: 'skanDateDimensionName',
        dataTypeFields: 'dataTypeFields',
        metricGroupFields: 'metricGroupFields',
        cohortMetricGroupFields: 'cohortMetricGroupFields',
        metrics: 'metrics',
        skanCustomEvents: 'skanCustomEvents',
        discrepancyMetrics: 'discrepancyMetrics',
        ...cohorts.dataSelector,
        tableDataFormatted: 'reportData',
        selectedFields: 'selectedFields',
        reportWarning: 'reportWarning',
    },
    sourceAttributionType: ['skan'],
    isUnifiedReport: true,
    skanDateDimensionName: 'estimated_install_date',
    confidenceIntervalFlag: ['true'],
    skanValidatedDimensions: ['true'],
    customColumnsToolPanelConfig: {
        getGroupId: colDef => colDef.sortingKey,
        getGroupName: groupId => SORTING_INDEX_TO_DISPLAY_NAME[groupId],
    },
    colDefClassRule: colDef => {
        const { unified_report_type: unifiedReportType } = colDef;
        return unifiedReportType ? `col_type__metric__${unifiedReportType}` : '';
    },
    getSortingKey: colDef => {
        const { unified_report_type: unifiedReportType } = colDef;

        return unifiedReportType ? SORTING_INDEX_MAP[unifiedReportType] : undefined;
    },
    getExportHeader: colDef => {
        const { sortingKey, headerName } = colDef;

        if (!sortingKey || !SORTING_INDEX_TO_DISPLAY_NAME[sortingKey]) {
            return headerName;
        }

        return `${SORTING_INDEX_TO_DISPLAY_NAME[sortingKey]} ${headerName}`;
    },
};
