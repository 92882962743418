import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import posed, { PoseGroup } from 'react-pose';
import { Translate } from 'react-localize-redux';
import css from './LinkEditorShelf.css';
import {
    DropdownField,
    Toggle,
    Slider,
    SingularButton,
    WizardFooter,
    WarningMessage,
    Checkbox,
    QuestionMark,
    Label,
    QRCodeWidget,
    CopyAreaWithQRCode,
    Spinner,
    AutoCompleteField,
} from '../../components/widgets';
import WizardWarningMessage from '../../teamManagement/components/WizardWarningMessage';
import ShelfGroup from '../../components/widgets/ShelfGroup';
import Legacy from '../containers/Legacy';
import CopyArea from '../containers/CopyAreaCont';
import OtherIcon from '../../resources/svg/icon_mobile.svg';
import Line from '../../resources/svg/line.svg';
import LinkParams from './LinkParams';
import { OSIcons } from '../../utils/OS';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => {
            return i * 50;
        },
    },
    exit: {
        opacity: 0,
        y: 20,
        transition: {
            y: {
                duration: 150,
            },
            opacity: {
                duration: 50,
            },
        },
    },
});

const getIconElement = os => {
    const IconElement = OSIcons[os] || OtherIcon;
    return <IconElement className={css.osIcon} />;
};

const Separator = () => {
    return <div className={css.separator} />;
};

const RegularGroup = ({ children }) => {
    return <div>{children}</div>;
};

const OSLabel = ({
    icon,
    label,
    hideCheckbox = false,
    checked = false,
    disabled = false,
    onChange = () => {},
    labelTooltip = null,
    labelTooltipStyle = {},
}) => {
    return (
        <div className={`${css.osLabel} ${disabled && !hideCheckbox && !checked ? css.disabled : ''}`}>
            {!hideCheckbox ? (
                <Checkbox
                    size="medium"
                    className={css.osLabelCheckbox}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
            ) : null}
            {getIconElement(icon)}
            <span className={css.osLabelText}>
                <Translate id={label} />
            </span>
            {labelTooltip ? (
                <div className={css.osLabelTooltip}>
                    <QuestionMark message={labelTooltip} interactive contentStyle={labelTooltipStyle} />
                </div>
            ) : null}
        </div>
    );
};

const LineLoader = () => {
    return (
        <div className={css.lineLoaderContainer}>
            <Line />
        </div>
    );
};

const ToggleExtended = ({ infoMessage, labelTooltipStyle = {}, ...props }) => {
    return (
        <div className={css.toggleExtendedContainer}>
            <Toggle {...props} style={{ width: 'auto' }} />
            {infoMessage ? (
                <div className={css.toggleExtendedInfo}>
                    <QuestionMark message={infoMessage} interactive contentStyle={labelTooltipStyle} />
                </div>
            ) : null}
        </div>
    );
};

const fieldsMap = {
    DropdownField: {
        FieldClass: DropdownField,
        onChangeFunc: ['onChange'],
        valueProp: 'selected',
        onBlurFunc: 'onBlur',
    },
    AutoCompleteField: {
        FieldClass: AutoCompleteField,
        onChangeFunc: ['onInputChange', 'onChange'],
        valueProp: 'value',
        onBlurFunc: 'onBlur',
    },
    Separator: {
        FieldClass: Separator,
        onChangeFunc: [],
    },
    OSLabel: {
        FieldClass: OSLabel,
        onChangeFunc: ['onChange'],
        valueProp: 'checked',
    },
    Toggle: {
        FieldClass: ToggleExtended,
        onChangeFunc: ['onToggle'],
        valueProp: 'checked',
    },
    Slider: {
        FieldClass: Slider,
        onChangeFunc: ['onChange'],
        valueProp: 'value',
    },
    CopyArea: {
        FieldClass: CopyArea,
        onChangeFunc: [],
        valueProp: 'content',
    },
    CopyAreaWithQRCode: {
        FieldClass: CopyAreaWithQRCode,
        onChangeFunc: [],
        valueProp: 'content',
    },
    QRCodeWidget: {
        FieldClass: QRCodeWidget,
        onChangeFunc: [],
        valueProp: 'value',
    },
    WarningMessage: {
        FieldClass: WarningMessage,
        onChangeFunc: [],
        valueProp: '',
    },
    Legacy: {
        FieldClass: Legacy,
        onChangeFunc: [],
        valueProp: '',
    },
    LineLoader: {
        FieldClass: LineLoader,
        onChangeFunc: [],
        valueProp: '',
    },
    LinkParams: {
        FieldClass: LinkParams,
        onChangeFunc: ['onUpdate'],
        valueProp: 'rows',
    },
    Label: {
        FieldClass: Label,
        onChangeFunc: [],
        valueProp: '',
    },
};

export const DisplayFormikState = props => (
    <div style={{ margin: '1rem 0' }}>
        <h3 style={{ fontFamily: 'monospace' }} />
        <pre
            style={{
                background: '#f6f8fa',
                fontSize: '16px',
                padding: '10px',
            }}
        >
            <strong>props</strong> = {JSON.stringify(props, null, 2)}
        </pre>
    </div>
);

function LinkEditorShelf({ validationSchema, shelf, fieldValues, onFormDirtyChanged, ...props }) {
    const warningAction = props[shelf.errorButtonAction] ? props[shelf.errorButtonAction] : () => {};

    if (shelf.loading) {
        return <Spinner show expanded />;
    }

    return (
        <div className={css.container}>
            <WizardWarningMessage
                show={!!shelf.error}
                showIcon={false}
                message={shelf.error}
                type={shelf.errorType}
                buttonAction={() => {
                    warningAction(fieldValues);
                }}
                buttonText={shelf.errorButtonText}
            />
            {!fieldValues.linkType && shelf.error ? null : ( // don't display form when error accord on get link
                <div className={css.contentContainer}>
                    <Label type="shelfHeader" text="STATIC.PAGES.MANAGE_LINKS.SHELF_TITLE" />
                    <Formik
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                actions.setSubmitting(false);
                            }, 1000);
                        }}
                        initialValues={fieldValues}
                        validationSchema={validationSchema}
                        render={formikProps => (
                            <MyForm
                                onFormDirtyChanged={onFormDirtyChanged}
                                fieldValues={fieldValues}
                                shelf={shelf}
                                {...formikProps}
                                {...props}
                            />
                        )}
                    />
                </div>
            )}
        </div>
    );
}

class MyForm extends React.Component {
    constructor(props) {
        super(props);
        this.dirtyFields = new Set();
        this.onGroupClicked = this._onGroupClicked.bind(this);
        this.setTouched = this._setTouched.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.fieldValues !== nextProps.fieldValues) {
            const resetFields = this._getAllResetFields(nextProps.categories);
            const resetFieldChange = resetFields.some(fieldName => {
                return this.props.fieldValues[fieldName] !== nextProps.fieldValues[fieldName];
            });
            if (resetFieldChange) {
                this.dirtyFields.clear();
            }
            // decide how to update Formik on your own
            const newValues = Object.entries(nextProps.fieldValues).reduce((total, [key, value]) => {
                total[key] = value;
                if (this.dirtyFields.has(key)) {
                    total[key] = this.props.values[key];
                }
                return total;
            }, {});
            this.props.setValues(newValues);
            this.dirtyFields.clear();
        }
        if (this.props.categories !== nextProps.categories) {
            const allPropsFields = this._getAllPropsFields();
            const allNextPropsFields = this._getAllFields(nextProps.categories);
            if (allPropsFields.length !== allNextPropsFields.length) {
                setTimeout(() => {
                    const lastElementName = allNextPropsFields[allNextPropsFields.length - 1];
                    const lastElement = this[`field_${lastElementName}`];
                    if (lastElement) {
                        lastElement.scrollIntoView();
                    }
                }, 50);
            }
        }
        // if the saved flag was on (meaning generate finished) and was turned off. reset the form
        if (this.props.shelf.saved && !nextProps.shelf.saved) {
            nextProps.handleReset();
        }
        if ((nextProps.dirty && !this.props.dirty) || (!nextProps.dirty && this.props.dirty)) {
            this.props.onFormDirtyChanged(nextProps.dirty);
        }
    }

    _isGroupDisabled(group, values) {
        if (!group.disabledRules) {
            return group.disabled;
        }
        const { categories } = this.props;
        return group.disabledRules.reduce((t, rule) => {
            return t || rule(values, categories);
        }, false);
    }

    _getErrorFormatted(error) {
        if (typeof error === 'object') {
            return Object.values(error).pop();
        }
        return error;
    }

    _onGroupClicked(groupName) {
        const { onGroupClicked, values, categories } = this.props;
        const currentCategory = categories.find(cat => cat.name === groupName);
        if (currentCategory.collapsed) {
            setTimeout(() => {
                const categoryElement = this[`category_${groupName}`];
                if (categoryElement) {
                    categoryElement.scrollIntoView();
                }
            }, 150);
        }
        onGroupClicked(groupName, values);
    }

    _getAllResetFields(categories) {
        return categories.reduce((total, category) => {
            total.push(...category.fields.filter(f => f.resetField).map(f => f.name));
            return total;
        }, []);
    }

    _getAllFields(categories) {
        return categories.reduce((total, category) => {
            total.push(...category.fields.map(f => f.name));
            return total;
        }, []);
    }

    _getAllPropsFields() {
        const { categories } = this.props;
        return this._getAllFields(categories);
    }

    _setTouched(fieldName) {
        // Set all previous fields as touched.
        // This is for cases where fields are invalid and were skipped by the user.
        const { setTouched, categories, touched } = this.props;
        if (touched[fieldName]) {
            return;
        }
        const allFields = this._getAllPropsFields();
        const fieldsTouched = allFields
            .slice(0, allFields.findIndex(fName => fName === fieldName) + 1)
            .reduce((total, fName) => {
                total[fName] = true;
                return total;
            }, {});
        setTouched(fieldsTouched);
    }

    render() {
        const {
            values,
            touched,
            dirty,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            categories,
            onGroupClicked,
            onFormUpdate,
            onCloseShelf,
            onGenerate,
            onUpdate,
            shelf,
        } = this.props;
        const hasErrors = !dirty || !!Object.keys(errors).length;

        const buttonDefs = {
            cancel: {
                text: <Translate id="STATIC.BUTTONS.CANCEL" />,
                disabled: false,
                type: 'flat',
                onClick: onCloseShelf,
            },
            generate: {
                text: <Translate id="STATIC.PAGES.MANAGE_LINKS.GENERATE_BUTTON" />,
                type: 'primary',
                onClick: () => {
                    onGenerate(values);
                },
                disabled: !shelf.working && hasErrors,
            },
            done: {
                text: <Translate id="STATIC.BUTTONS.DONE" />,
                type: 'primary',
                onClick: onCloseShelf,
                onDirty: 'update',
                disabled: false,
            },
            update: {
                text: <Translate id="STATIC.PAGES.MANAGE_LINKS.UPDATE_BUTTON" />,
                type: 'primary',
                onClick: () => {
                    onUpdate(values);
                },
                disabled: !shelf.working && hasErrors,
            },
        };

        let actionButton = buttonDefs[shelf.actionButton];
        if (dirty && actionButton.onDirty && !shelf.readOnly) {
            actionButton = buttonDefs[actionButton.onDirty];
        }

        return (
            <form onSubmit={handleSubmit}>
                {categories.map(category => {
                    const GroupClass = category.collapseable ? ShelfGroup : RegularGroup;
                    const isGroupDisabled = this._isGroupDisabled(category, values);
                    return (
                        <div
                            className={css.groupItemContainer}
                            key={category.name}
                            ref={el => {
                                this[`category_${category.name}`] = el;
                            }}
                        >
                            {category.isLoading ? (
                                <Spinner show className={css.groupItemSpinner} />
                            ) : (
                                <GroupClass
                                    {...category}
                                    disabled={isGroupDisabled}
                                    onGroupClicked={this.onGroupClicked}
                                >
                                    <PoseGroup animateOnMount>
                                        {category.fields.map(field => {
                                            const genericChangeFunc = value => {
                                                const { values: propValues } = this.props;
                                                const oldValue = propValues[field.name];
                                                let useValue = value;
                                                if (useValue === null) {
                                                    useValue = '';
                                                }
                                                if (typeof useValue === 'object' && 'target' in useValue) {
                                                    useValue = useValue.target.checked;
                                                }
                                                setFieldValue(field.name, useValue);
                                                this.setTouched(field.name);
                                                field.prevValue = oldValue;
                                                if (field.updateOnChange) {
                                                    onFormUpdate(
                                                        field,
                                                        {
                                                            ...propValues,
                                                            [field.name]: useValue,
                                                        },
                                                        false,
                                                        this.props.dirty
                                                    );
                                                } else {
                                                    this.dirtyFields.add(field.name);
                                                }
                                            };
                                            const { FieldClass, onChangeFunc, onBlurFunc, valueProp } = fieldsMap[
                                                field.component
                                            ];
                                            const fieldProps = field.componentData;
                                            onChangeFunc.forEach(func => {
                                                fieldProps[func] = genericChangeFunc;
                                            });
                                            fieldProps[onBlurFunc] = () => {
                                                this.setTouched(field.name);
                                            };
                                            fieldProps[valueProp] = values[field.name];
                                            return (
                                                <AnimationItem key={field.name}>
                                                    <div
                                                        ref={el => {
                                                            this[`field_${field.name}`] = el;
                                                        }}
                                                    >
                                                        <FieldClass
                                                            {...fieldProps}
                                                            error={
                                                                (touched[field.name] || values.linkId) &&
                                                                this._getErrorFormatted(errors[field.name])
                                                            }
                                                        />
                                                    </div>
                                                </AnimationItem>
                                            );
                                        })}
                                    </PoseGroup>
                                </GroupClass>
                            )}
                        </div>
                    );
                })}
                {/* <DisplayFormikState {...{ touched, dirty, errors, values }} />*/}
                <WizardFooter
                    buttons={[
                        <SingularButton {...buttonDefs.cancel}>{buttonDefs.cancel.text}</SingularButton>,
                        <SingularButton
                            {...actionButton}
                            showSpinner={(shelf.working && !shelf.saved) || categories.some(x => x.isLoading)}
                            showV={shelf.saved && !shelf.working}
                        >
                            {actionButton.text}
                        </SingularButton>,
                    ]}
                />
            </form>
        );
    }
}

LinkEditorShelf.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    onGroupClicked: PropTypes.func,
    onFormUpdate: PropTypes.func,
    onCloseShelf: PropTypes.func,
    validationSchema: PropTypes.any,
    shelf: PropTypes.any,
    fieldValues: PropTypes.any,
    fetchFormData: PropTypes.any,
    onFormDirtyChanged: PropTypes.func,
};

LinkEditorShelf.defaultProps = {
    categories: [],
    fetchFormData: {},
    onGroupClicked: () => {},
    onFormUpdate: () => {},
    onCloseShelf: () => {},
    onFormDirtyChanged: () => {},
};

export default LinkEditorShelf;
