import { useCallback } from 'react';
import SDKKeysService from '../service';
import { useRequest } from '../../utils/customHooks';

const useGetSDKKeys = () => {
    const SDKKeysAPI = new SDKKeysService();
    return useRequest(useCallback(SDKKeysAPI.getSDKKeys, []), () => true);
};

export default useGetSDKKeys;
