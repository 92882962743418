import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import css from '../partials/Grid.css';
import LineLoader from '../../resources/svg/line.svg';
import { getTotalsStatus } from '../../selectors/reports';
import { TOTALS_STATUS } from '../pages/reports/consts';

const TotalsCell = ({ valueFormatted, isLoading }) =>
    isLoading ? (
        <div className={css.loadingLineContainer} style={{ width: `${Math.random() * (90 - 40) + 20}%` }}>
            <LineLoader />
        </div>
    ) : (
        <span>{valueFormatted}</span>
    );

TotalsCell.propTypes = {
    valueFormatted: PropTypes.string,
    isLoading: PropTypes.bool,
};

TotalsCell.defaultProps = {
    valueFormatted: undefined,
    isLoading: false,
};

const getMapStateToProps = reportType => state => ({
    isLoading: getTotalsStatus(reportType)(state) === TOTALS_STATUS.PENDING,
});

export default reportType => connect(getMapStateToProps(reportType), null, null, { forwardRef: true })(TotalsCell);
