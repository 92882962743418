import React from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import pluralize from 'pluralize';
import Tooltip from './Tooltip';
import css from './EventsItemContent.css';
import WarningMessage from './WarningMessage';
import TagsInput from './TagInput';
import Checkbox from './Checkbox';
import QuestionMark from './QuestionMark';
import Toggle from './Toggle';
import { EVENT_SLOT_GROUP_TYPE } from '../../utils/consts';
import { extractTageFromCustomEvent } from '../../customEvents/utils';

const MAX_DEFAULT_UANS = 3;

const translateUniqueToggleToText = isUnique => {
    return isUnique ? 'Unique' : 'Count';
};

const extractUANEventObj = (eventName, events) => {
    let found = null;
    events.some((event) => {
        if (event.options) {
            found = event.options.find(opt => {
                return opt.display_name === eventName;
            });
        } else if (event.display_name === eventName) {
            found = event;
        }
        return found;
    });
    return found;
};

const getEventSuggestions = events => {
    // handle grouped case and regular case
    // remove archived uan events from suggestions (if they are already configured keep them)
    return events
        .filter(e => e.options || !e.is_archived)
        .map(e => {
            if (e.options) {
                return {
                    ...e,
                    options: e.options
                        .filter(opt => !opt.is_archived)
                        .map(opt => ({
                            label: opt.display_name,
                            value: opt.display_name,
                        })),
                };
            }
            return {
                label: e.display_name,
                value: e.display_name,
            };
        });
};

class EventsItemContent extends React.Component {
    constructor(props) {
        super(props);
        const defaultExpanded = [];
        if (props.uans && props.uans.length) {
            defaultExpanded.push(props.uans[0].uan_id); // set the first uan open
        }
        this.state = {
            expandedUANs: defaultExpanded,
            showMoreUans: false,
        };
        this.onUANHeaderClick = this._onUANHeaderClick.bind(this);
        this.onSeeMoreToggle = this._onSeeMoreToggle.bind(this);
    }

    componentDidUpdate() {
        const { uniqueToggleDisabled } = this.props;
        // this is a have to remove title=undefined when setting react-tippy disabled=true
        if (!uniqueToggleDisabled && this.uniqueToggleTooltip && this.uniqueToggleTooltip.tooltipDOM) {
            this.uniqueToggleTooltip.tooltipDOM.removeAttribute('title');
        }
    }

    _onUANHeaderClick(uan) {
        if (!uan.events.length) {
            return;
        }
        const newExpandedUans = [...this.state.expandedUANs];
        const uanIndex = newExpandedUans.findIndex(uanId => uanId === uan.uan_id);
        if (uanIndex === -1) {
            newExpandedUans.push(uan.uan_id);
        } else {
            newExpandedUans.splice(uanIndex, 1);
        }
        this.setState({
            expandedUANs: newExpandedUans,
        });
    }

    _onSeeMoreToggle() {
        this.setState({
            showMoreUans: !this.state.showMoreUans,
        });
    }

    render() {
        const {
            uans,
            calculatedMetricsOptions,
            customEvent,
            warnings,
            onUANEventAdded,
            onUANEventRemoved,
            onToggleCalculatedMetric,
            onUniqueToggle,
            saving,
            saveError,
            translate,
            uniqueToggleDisabled,
            activeGroupType,
            readOnly,
        } = this.props;
        const { showMoreUans } = this.state;

        const orderedUans = uans.sort(function(a, b) {
            if (a.uan_id in customEvent.events_by_uan && !(b.uan_id in customEvent.events_by_uan)) {
                return -1;
            } else if (!(a.uan_id in customEvent.events_by_uan) && b.uan_id in customEvent.events_by_uan) {
                return 1;
            }
            return a.source_name.localeCompare(b.source_name);
        });

        const showUans = showMoreUans ? orderedUans : orderedUans.slice(0, MAX_DEFAULT_UANS);
        const uniqueToggleTooltip = translate('STATIC.PAGES.CUSTOM_EVENTS.UNIQUE_TOGGLE_DISABLE_INFO', {
            eventType: translateUniqueToggleToText(customEvent.is_unique),
        });
        return (
            <div className={css.container}>
                {!customEvent.is_mixed &&
                activeGroupType !== EVENT_SLOT_GROUP_TYPE.CONVERSION &&
                activeGroupType !== EVENT_SLOT_GROUP_TYPE.SKAN ? (
                    <div className={css.uniqueToggleContainer}>
                        <div className={css.uniqueToggleHolder}>
                            <Tooltip
                                title={uniqueToggleTooltip}
                                position="top"
                                inertiaf
                                theme="light"
                                size="big"
                                disabled={!uniqueToggleDisabled || readOnly}
                                ref={el => {
                                    this.uniqueToggleTooltip = el;
                                }}
                            >
                                <Toggle
                                    onToggle={() => {
                                        onUniqueToggle(customEvent);
                                    }}
                                    checked={customEvent.is_unique}
                                    disabled={uniqueToggleDisabled || readOnly}
                                    trackSwitchedStyle={{
                                        backgroundColor: '#3A92E5',
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <div className={css.uniqueToggleLabel}>
                            <Translate id="STATIC.PAGES.CUSTOM_EVENTS.UNIQUE_TOGGLE_LABEL" />
                        </div>
                        <QuestionMark message="STATIC.PAGES.CUSTOM_EVENTS.UNIQUE_TOGGLE_INFO" />
                    </div>
                ) : null}
                <div className={css.title}>
                    <Translate
                        id={
                            customEvent.is_unique
                                ? 'STATIC.PAGES.CUSTOM_EVENTS.MAP_TO_REACT_UNIQUE'
                                : 'STATIC.PAGES.CUSTOM_EVENTS.MAP_TO_REACT'
                        }
                    />
                </div>
                <div className={css.uansContainer}>
                    {showUans.map((uan, index) => {
                        const {
                            source_icon: sourceIcon,
                            source_name: sourceName,
                            uan_username: uanUsername,
                            events,
                            uan_id: uanId,
                            suggestions = [],
                        } = uan;
                        const expanded =
                            this.state.expandedUANs.includes(uanId) && // uan was expanded by the user
                            !saving && // during save, we close all uans
                            events.length; // no uan events for this uan

                        const tags = extractTageFromCustomEvent(customEvent, uanId);
                        const eventSuggestions = getEventSuggestions(events);
                        return [
                            <div className={`${css.uanContainer} ${expanded ? css.expanded : ''}`} key={uan.uan_id}>
                                <div
                                    className={css.uanHeader}
                                    onClick={() => {
                                        this.onUANHeaderClick(uan);
                                    }}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {sourceIcon && (
                                        <div className={`${css.uanIconContainer} ${css.uanHeaderItem}`}>
                                            <img className={`${css.uanIcon}`} src={sourceIcon} alt="logo" />
                                        </div>
                                    )}
                                    <div className={`${css.uanName} ${css.uanHeaderItem}`}>{sourceName}</div>
                                    <div className={`${css.uanUserName} ${css.uanHeaderItem}`}>{uanUsername}</div>
                                    <div className={`${css.uanEventsCount} ${css.uanHeaderItem}`}>
                                        {uan.events.length
                                            ? `(${tags.length} ${pluralize('event', tags.length)})`
                                            : `(No ${translateUniqueToggleToText(customEvent.is_unique)} events)`}
                                    </div>
                                    <div className={`${css.arrow} ${css.uanHeaderItem}`} />
                                </div>
                                {expanded ? (
                                    <div className={css.uansInputContainer}>
                                        {activeGroupType === EVENT_SLOT_GROUP_TYPE.SKAN ? (
                                            <>
                                                {tags.map(({ label }) => (
                                                    <span className={css.uanEventText}>{label}</span>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <TagsInput
                                                    suggestions={eventSuggestions}
                                                    tags={tags}
                                                    containerStyle={{
                                                        width: '100%',
                                                    }}
                                                    onTagAdded={eventName => {
                                                        const tagItem = extractUANEventObj(eventName, uan.events);
                                                        onUANEventAdded(tagItem, customEvent, uan);
                                                    }}
                                                    onTagRemoved={eventName => {
                                                        const tagItem = extractUANEventObj(eventName, uan.events);
                                                        onUANEventRemoved(tagItem, customEvent, uan);
                                                    }}
                                                    tagLockAlt={translate(
                                                        'STATIC.PAGES.CUSTOM_EVENTS.LOCKED_UAN_EVENT_ALT'
                                                    )}
                                                    virtualScrolling
                                                    disabled={readOnly}
                                                />
                                                {suggestions.length && !readOnly ? (
                                                    <div className={css.suggestionsContainer}>
                                                        <div className={css.suggestionsLabel}>Suggested Events:</div>
                                                        <div>
                                                            {suggestions.map(suggestion => (
                                                                <div
                                                                    key={`suggestion_${uan.uan_id}_${suggestion.id}`}
                                                                    role="button"
                                                                    className={css.suggestion}
                                                                    onClick={() => {
                                                                        onUANEventAdded(suggestion, customEvent, uan);
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    {suggestion.display_name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                ) : null}
                            </div>,
                            index !== showUans.length - 1 ? (
                                <div key={`divider_${uan.uan_id}`} className={css.divider} />
                            ) : null,
                        ];
                    })}
                    {uans.length > MAX_DEFAULT_UANS ? (
                        <div className={css.seeMoreToggle} role="button" onClick={this.onSeeMoreToggle} tabIndex={0}>
                            {showMoreUans ? 'See less' : `See all ${uans.length} data sources...`}
                        </div>
                    ) : null}
                </div>
                {warnings.length ? (
                    <div className={css.warningContainer}>
                        {warnings.map((warning, index) => {
                            return (
                                <WarningMessage
                                    key={`warning_${warning.message}`}
                                    message={warning.message}
                                    showIcon={false}
                                    containerStyle={{
                                        marginTop: index > 0 ? '10px' : '0',
                                    }}
                                    type={warning.type}
                                />
                            );
                        })}
                    </div>
                ) : null}
                <div className={css.calculatedMetricsContainer}>
                    <div className={css.calculatedMetricsTitle}>
                        <span className={css.calculatedMetricsTitleText}>Add calculated metric based on event:</span>
                        <QuestionMark
                            message={`STATIC.PAGES.CUSTOM_EVENTS.HELP_TOOLTIP${
                                activeGroupType === EVENT_SLOT_GROUP_TYPE.SKAN ? `_SKAN` : ''
                            }`}
                            contentStyle={{
                                width: 200,
                            }}
                        />
                    </div>
                    <div>
                        {calculatedMetricsOptions.map(cm => {
                            return (
                                <div key={`quick_option_${customEvent.id}_${cm.name}`}>
                                    <Checkbox
                                        label={cm.display_name}
                                        id={cm.name}
                                        checked={cm.value}
                                        onChange={value => {
                                            onToggleCalculatedMetric(cm, customEvent, value);
                                        }}
                                        disabled={saving || readOnly}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {saveError ? (
                        <WarningMessage
                            message={saveError}
                            showIcon={false}
                            type="error"
                            containerStyle={{
                                marginTop: '20px',
                            }}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

EventsItemContent.propTypes = {
    uans: PropTypes.arrayOf(PropTypes.any),
    calculatedMetricsOptions: PropTypes.arrayOf(PropTypes.any),
    customEvent: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
    onUANEventAdded: PropTypes.func,
    onUANEventRemoved: PropTypes.func,
    onUniqueToggle: PropTypes.func,
    onToggleCalculatedMetric: PropTypes.func,
    warnings: PropTypes.arrayOf(PropTypes.any),
    saving: PropTypes.bool,
    saveError: PropTypes.string,
    uniqueToggleDisabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    activeGroupType: PropTypes.string,
};

EventsItemContent.defaultProps = {
    uans: [],
    calculatedMetricsOptions: [],
    customEvent: {},
    translate: () => {},
    onUANEventAdded: () => {},
    onUANEventRemoved: () => {},
    onUniqueToggle: () => {},
    onToggleCalculatedMetric: () => {},
    warnings: [],
    saving: false,
    saveError: '',
    uniqueToggleDisabled: false,
    readOnly: false,
    activeGroupType: undefined,
};

export default withLocalize(EventsItemContent);
