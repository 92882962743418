import React from 'react';
import PropTypes from 'prop-types';

import { EXPAND_ARROW, COLLAPSE_ARROW } from '../../../utils/trackingLinks';
import Campaign from './Campaign';
import ArchiveIcon from '../../../resources/svg/archive_grey.svg';

const Network = (props) => {
    const { campaigns, css } = props;
    return (
        <div className={css.NetworkPanel}>
            <div className={`${css.NetworkHeading} ${css.Expand}`}>
                <div
                    className={`${css.NetworkName} ${css.Expand}`}
                    onClick={() => {
                        props.toggleExpand(props.app_id, props.network);
                    }}
                >
                    {props.expanded ? COLLAPSE_ARROW : EXPAND_ARROW}
                </div>
                <div
                    className={`${css.NetworkName} ${css.TextWrapper}`}
                    onClick={() => {
                        props.toggleExpand(props.app_id, props.network);
                    }}
                >
                    <span>{props.linkType === 'SmartLink' ? `${props.network} - Smart Link` : props.network}</span>
                    <span className={css.NetworkPanelText}>
                        {`(${Object.keys(campaigns).length} ${
                            Object.keys(campaigns).length > 1 ? 'Campaigns' : 'Campaign'
                        })`}
                    </span>
                    <span
                        className={css.deleteButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.deleteClicked(Object.keys(props.campaigns));
                        }}
                        style={{ marginLeft: '20px', verticalAlign: 'top' }}
                    >
                        <ArchiveIcon
                            style={{
                                height: '15px',
                                width: '15px',
                                marginTop: '3px',
                            }}
                        />
                    </span>
                </div>
            </div>
            {props.expanded && (
                <div className={css.CampaignsContainer}>
                    {Object.keys(campaigns)
                        .sort()
                        .map((campaignID) => (
                            <Campaign
                                css={css}
                                network={props.network}
                                app_id={props.app_id}
                                key={`campaign_${campaignID}`}
                                campaign_id={campaignID}
                                campaign={campaigns[campaignID].campaign_name}
                                data={campaigns[campaignID]}
                                expanded={props.expandedCampaigns.indexOf(campaignID) > -1}
                                toggleExpand={props.toggleExpandCampaign}
                                deleteClicked={props.deleteClicked}
                                editCampaignName={props.editCampaignName}
                                editClicked={props.editClicked}
                                editingCampaign={props.editingCampaign}
                                enabledCampaignEditing={props.enabledCampaignEditing}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

Network.propTypes = {
    campaigns: PropTypes.objectOf(PropTypes.any),
    css: PropTypes.objectOf(PropTypes.any),
    enabledCampaignEditing: PropTypes.bool,
    expanded: PropTypes.bool,
    expandedCampaigns: PropTypes.arrayOf(PropTypes.any),
    toggleExpand: PropTypes.func,
    app_id: PropTypes.number,
    network: PropTypes.string,
    linkType: PropTypes.string,
    toggleExpandCampaign: PropTypes.func,
    deleteClicked: PropTypes.func,
    editCampaignName: PropTypes.func,
    editClicked: PropTypes.func,
    editingCampaign: PropTypes.string,
};

Network.defaultProps = {
    campaigns: {},
    css: {},
    enabledCampaignEditing: false,
    expanded: false,
    expandedCampaigns: [],
    toggleExpand: () => {},
    app_id: null,
    network: '',
    linkType: '',
    toggleExpandCampaign: () => {},
    deleteClicked: () => {},
    editCampaignName: () => {},
    editClicked: () => {},
    editingCampaign: '',
};

export default Network;
