import { sortAlphabetically } from './sortUtil';
import { TIKTOK_PARTNER_NAME, SNAPCHAT_PARTNER_NAME } from '../partnerConfiguration/consts';

export const NETWORKS_SPECIAL_DISPLAY_NAME_MAPPING = {
    adwords: 'AdWords (Google Ads)',
    doubleclickdcm: 'Double Click DCM (Google - CM360)',
    doubleclickdbm: 'Double Click DBM (Google - DVI360)',
    searchads360: 'Search Ads 360 (Google - SA360)',
    verizon: 'Verizon Media (Yahoo DSP)',
};

export const NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING = {
    adwords: 'AdWords',
    doubleclickdcm: 'Double Click DCM',
    doubleclickdbm: 'Double Click DBM',
    searchads360: 'Search Ads 360',
    verizon: 'Verizon Media',
};

export const getAdnetworkDisplayName = (name, displayName) =>
    NETWORKS_SPECIAL_DISPLAY_NAME_MAPPING[name] || displayName?.trim();

const formatAdNetworkObject = (id, name, displayName, logoPath, disabled) => ({
    id,
    value: name,
    logoPath,
    label: getAdnetworkDisplayName(name, displayName),
    isDisabled: disabled,
});

export const getAdNetworkOptions = adNetworks => {
    return Object.keys(adNetworks)
        .map(key => {
            const adNetwork = adNetworks[key];
            return formatAdNetworkObject(
                adNetwork.id,
                adNetwork.name,
                adNetwork.display_name,
                adNetwork.logo_path,
                adNetwork.disabled
            );
        })
        .sort(({ label: label1 }, { label: label2 }) => sortAlphabetically(label1, label2))
        .sort(a => (a.isDisabled ? 1 : -1));
};

export const getPartnersOptions = partners => {
    return partners
        .map(({ id, name, displayName, logoPath }) => formatAdNetworkObject(id, name, displayName, logoPath))
        .sort(({ label: label1 }, { label: label2 }) => sortAlphabetically(label1, label2));
};

export const getUseNewShelfOption = (organizationOptions, partner) => {
    const {
        newPartnerConfigShelf = false,
        newTiktokPartnerConfigShelf = false,
        newSnapchatPartnerConfigShelf = false,
    } = organizationOptions || {};
    // this flow is temporary and will be removed once all clients will be migrated to new TikTok shelf
    if (partner.name === TIKTOK_PARTNER_NAME) {
        return newTiktokPartnerConfigShelf && partner.options.new_partner_config_shelf !== false;
    } else if (partner.name === SNAPCHAT_PARTNER_NAME) {
        return newSnapchatPartnerConfigShelf && partner.options.new_partner_config_shelf !== false;
    }
    return newPartnerConfigShelf && partner.options.new_partner_config_shelf !== false;
};
