import { getInfoSectionKey, getMediatorParsedName } from '../../../utils';
import { SDK_INTEGRATION_SECTIONS } from '../../../consts';
import { SELECT_MEDIATION_PLATFORM } from '../../../../../onboardingGuide/components/sdkConfiguration/consts';

const { AD_MONETIZATION } = SDK_INTEGRATION_SECTIONS;

const getSelectedMediator = (sdkConfigForPlanning, selectedTargetPlatform) =>
    sdkConfigForPlanning[selectedTargetPlatform][getInfoSectionKey(AD_MONETIZATION)];
export const findMediatorItem = (mediatorChoices, sdkConfigForPlanning, selectedTargetPlatform) => {
    return mediatorChoices.find(
        mediator =>
            mediator.name === getMediatorParsedName(getSelectedMediator(sdkConfigForPlanning, selectedTargetPlatform))
    );
};

export const getMediatorChoices = sdkConfigChoicesMetadata => {
    return sdkConfigChoicesMetadata[SELECT_MEDIATION_PLATFORM].map(choice => {
        const mediatorParsedName = getMediatorParsedName(choice);
        return { display_name: choice, name: mediatorParsedName };
    });
};
