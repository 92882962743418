import axios from 'axios';
import moment from 'moment';
import Logger from './logger';
import { generateGuid } from '../global/utils';

const NodeCache = require('node-cache');

const apiCache = new NodeCache({ stdTTL: 60 });
let instance = null;
const baseUrl = '';
const logger = new Logger('[api]');
const { CancelToken } = axios;

const DOWNLOADED_FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
const DOWNLOAD_FILE_TYPE = 'any-type-of-file';

class TasksHandler {
    constructor() {
        this.pending = [];
    }

    static uniqueEndPointName(url, method, channel, suffix) {
        // Channel is string appended to the TaskHandler's key.
        // Adding different channels to requests of the same url will make sure they'll run concurently
        return `${method.toLowerCase()}~${url.toLowerCase()}:${channel}${suffix && `:${suffix}`}`;
    }

    addTask(url, method, channel, unique, suffix) {
        const id = unique ? TasksHandler.uniqueEndPointName(url, method, channel, suffix) : generateGuid();
        logger.debug(`[TaskHandler]: adding task ${id}`);
        const tokenSource = CancelToken.source();
        if (unique) {
            const pendingRequest = this.getTask(id);
            if (pendingRequest) {
                this.cancelTask(pendingRequest);
            }
        }
        this.pending.push({
            tokenSource,
            id,
            timestamp: new Date().getTime(),
        });
        return { token: tokenSource.token, id };
    }

    removeTask(id) {
        const index = this.pending.findIndex(p => p.id === id);
        if (index !== -1) {
            const task = this.pending[index];
            const timespan = moment.utc(new Date().getTime() - task.timestamp).format('HH:mm:ss.SSS');
            this.pending.splice(index, 1);
            logger.debug(`[TaskHandler]: removed task ${id}, took: ${timespan}`);
        }
    }

    cancelTask(task) {
        logger.debug(`[TaskHandler]: canceling task ${task.id}`);
        task.canceled = true;
        task.tokenSource.cancel(API.CANCELED_REQUEST.message);
    }

    getTask(id) {
        return this.pending.find(p => p.id === id);
    }
}

const csrfSafeMethod = method => {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
};

const getCookie = name => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

export const csrftoken = getCookie('csrftoken');
const taskHandler = new TasksHandler();

// ------------- impersonation stuff -------------
function getImpersonationFromQuery(paramKey = 'user') {
    const reg = new RegExp(`${paramKey}=([^&]+)`);
    const impersonationQueryParam = reg.exec(window.location.search);
    if (impersonationQueryParam === null) {
        return null;
    }
    return impersonationQueryParam[1];
}
// ---------- end of impersonation stuff ---------

class API {
    static get ERROR_NO_URL() {
        return new Error('No url supplied');
    }

    static get ERROR_NOT_A_JSON() {
        return new Error('Response is not a JSON');
    }

    static get ERROR_GENERAL() {
        return new Error('there was an error while processing the request');
    }

    static get CANCELED_REQUEST() {
        return {
            message: 'Warning: request canceled',
        };
    }

    constructor() {
        // This is an old way to create singleton from ESM classes.
        // If you change the behavior here - make sure that you add the listener only once!
        if (!instance) {
            instance = this;
            window.addEventListener('addCache', e => {
                const eventData = e;
                if (!eventData || !eventData.detail || eventData.detail.creator === 'react') {
                    return;
                }
                if (eventData.detail && eventData.detail.key && eventData.detail.val) {
                    apiCache.set(eventData.detail.key, eventData.detail.val);
                    // logger.log('react addCache', eventData.detail);
                }
            });
            window.addEventListener('invalidateCache', e => {
                const eventData = e;
                if (!eventData || !eventData.detail || eventData.detail.creator === 'react') {
                    return;
                }
                if (eventData.detail && eventData.detail.key) {
                    apiCache.del(eventData.detail.key);
                    logger.log('react invalidateCache', eventData.detail);
                }
            });
            this.impersonationUsername = getImpersonationFromQuery('user');
            this.impersonatedOrganization = getImpersonationFromQuery('customer_id');
        }
        return instance;
    }

    // IMPORTANT: this function is deprecated. Use getJson with legacyStatus=True.
    basicRequest(url, method = 'GET', body = null, disableBrowserCache = false) {
        return new Promise((resolve, reject) => {
            this.getJson(url, {
                method,
                credentials: 'include',
                unique: true,
                body,
                disableBrowserCache,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getFile(url = '', options = {}) {
        return this.getJson(url, {
            ...options,
            expectedContentType: DOWNLOAD_FILE_TYPE,
        });
    }

    getJson(url = '', options = {}) {
        if (!url) {
            return Promise.reject(API.ERROR_NO_URL);
        }

        return this._makeRequest(url, options, () => {
            const {
                method = 'GET',
                headers: rawHeaders = {},
                // the request payload (will be used only with a POST request)
                body = {},
                // the query params that will be appended to the url (will be used *mostly* for GET requests, but not necessarily)
                params = null,
                // if true, return_status !=0 will reject.
                legacyStatus = false,
                // will activate axios cache
                cache = false,
                // will disable the browser's cache
                disableBrowserCache = false,
                // reject only with response data
                fixErr = false,
                // unique and channel will be used for requests that are unique (meaning, can't repeat),
                // and repeating them will cause the previous request to cancel. To check uniqueness, we'll see if a request with the same (url & method & channel) exist
                channel = '',
                unique = false,
                expectedContentType = 'json',
                suffix = null,
            } = options;

            return new Promise((resolve, reject) => {
                let requestUrl = this._addBaseUrl(url);

                if (params && method === 'GET') {
                    requestUrl = this._buildURL(requestUrl, params);
                }

                // add location to HTTP headers
                let headers = {
                    ...rawHeaders,
                    'X-browser-location': window.location.href,
                };

                if (!csrfSafeMethod(method)) {
                    headers = {
                        ...headers,
                        'X-CSRFToken': csrftoken,
                    };
                }
                if (this.impersonationUsername !== null && this.impersonationUsername.length > 0) {
                    headers = {
                        ...headers,
                        'X-Impersonation-User': this.impersonationUsername,
                    };
                }

                if (this.impersonatedOrganization) {
                    headers = {
                        ...headers,
                        'X-Impersonated-Org': this.impersonatedOrganization,
                    };
                }

                const organization = sessionStorage.getItem('x-organization');

                if (organization) {
                    headers = {
                        ...headers,
                        'X-Org': organization,
                    };
                }

                if (disableBrowserCache) {
                    headers = {
                        ...headers,
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                    };
                }

                // normalize request to axios request
                const { token, id: taskId } = taskHandler.addTask(url, method, channel, unique, suffix);
                const request = this._getAxiosRequest(requestUrl, { method, headers, body, params }, token);
                // save request start time for mixpanel logging
                request.startTime = Date.now();

                axios(request)
                    .then(response => {
                        const contentType = response.headers['content-type'];
                        if (
                            contentType &&
                            expectedContentType === 'json' &&
                            !contentType.includes('application/json')
                        ) {
                            return Promise.reject(API.ERROR_NOT_A_JSON);
                        }
                        if (legacyStatus && response.data.status !== 0) {
                            return reject(response.data);
                        }

                        let { data } = response;

                        if (expectedContentType === DOWNLOAD_FILE_TYPE) {
                            data = {
                                contents: data,
                                filename: this._parseDownloadedFileName(response),
                            };
                        }

                        if (cache) {
                            this._addCache(requestUrl, data);
                        }

                        return resolve(data);
                    })
                    .catch(err => {
                        if (err) {
                            if (err.message === API.ERROR_NOT_A_JSON.message) {
                                reject(err);
                                return;
                            }
                            if (err.message === API.CANCELED_REQUEST.message) {
                                logger.warn(`${err.message} of ${requestUrl}`);
                                return;
                            }
                            if (fixErr && err.response.status >= 400) {
                                let retErr = err;
                                if (err.response && err.response.data) {
                                    retErr = err.response.data;
                                }
                                reject(retErr);
                                return;
                            }
                        }
                        reject(API.ERROR_GENERAL);
                    })
                    .finally(() => {
                        taskHandler.removeTask(taskId);
                    });
            });
        });
    }

    _parseDownloadedFileName(response) {
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const matches = DOWNLOADED_FILENAME_REGEX.exec(disposition);
            if (matches && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }

        return null;
    }

    request(props) {
        return fetch(this._addBaseUrl(props.url), props);
    }

    _getAxiosRequest(url, { method = 'GET', body = {}, ...request }, cancelToken) {
        return {
            url,
            cancelToken,
            method: method.toLowerCase(),
            data: body,
            ...request,
        };
    }

    _addBaseUrl(url) {
        return url.indexOf(baseUrl) !== -1 ? url : `${baseUrl}${url}`;
    }

    _buildURL(url, params) {
        const queryString = Object.keys(params)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join('&');
        return `${url}?${queryString}`;
    }

    _makeRequest(url, options = {}, action = () => {}) {
        if (options.forceRefresh) {
            this._invalidate(url);
        }
        const cacheVal = apiCache.get(url);
        if (cacheVal) {
            // logger.log(`react found cache value for ${url}`);
            let retVal = cacheVal;
            try {
                retVal = JSON.parse(cacheVal);
            } catch (e) {}
            return Promise.resolve(retVal);
        } else {
            return action.call(this, url, options);
        }
    }

    _addCache(key, val) {
        let storeVal = val;
        if (typeof val === 'object') {
            storeVal = JSON.stringify(val);
        }
        apiCache.set(key, storeVal);
        this._syncCache('addCache', {
            key,
            val: storeVal,
        });
    }

    _invalidate(url) {
        apiCache.del(url);
        this._syncCache('invalidateCache', {
            key: url,
        });
    }

    _syncCache(type, data) {
        const sendObject = {
            detail: Object.assign({}, data, { creator: 'react' }),
        };
        const event = new CustomEvent(type, sendObject);
        window.dispatchEvent(event);
    }
}

export default API;
