import React from 'react';
import { XAxis } from 'react-jsx-highcharts';
import PropTypes from 'prop-types';
import css from '../styles.css';
import { getEmptyCategoryRange } from '../utils';

const ChartXAxis = ({
    id,
    showCrosshair,
    categories,
    formatter,
    lineWidth,
    emptyCategoryIndexes,
    gridLineWidth,
    gridLineColor,
    enableLabels,
    rotation,
}) => {
    const plotBands = emptyCategoryIndexes.map((index) => {
        const { from, to } = getEmptyCategoryRange(index);

        return {
            color: '#ebebeb',
            from,
            to,
            label: {
                align: 'center',
                text:
                    '<div class="noDataForCategory">' +
                    '<img src="static/dashboard/img/icons/icon-no-data.svg"/>' +
                    '<span>We have no data for this app</span></div>',
                useHTML: true,
            },
        };
    });

    return (
        <XAxis
            gridLineWidth={gridLineWidth}
            gridLineColor={gridLineColor}
            plotBands={plotBands}
            id={id}
            tickWidth={0}
            lineWidth={lineWidth}
            categories={categories}
            crosshair={showCrosshair ? { width: 1, color: '#fff', zIndex: 10, className: css.crosshair } : false}
            labels={{
                enabled: enableLabels,
                autoRotation: false,
                formatter,
                useHTML: !!formatter,
                rotation,
            }}
        />
    );
};

ChartXAxis.propTypes = {
    id: PropTypes.string.isRequired,
    showCrosshair: PropTypes.bool,
    formatter: PropTypes.func,
    lineWidth: PropTypes.number,
    emptyCategoryIndexes: PropTypes.arrayOf(PropTypes.number),
    enableLabels: PropTypes.bool,
    gridLineWidth: PropTypes.number,
    gridLineColor: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.any),
    rotation: PropTypes.number,
};

ChartXAxis.defaultProps = {
    showCrosshair: false,
    formatter: undefined,
    lineWidth: 0,
    emptyCategoryIndexes: [],
    gridLineWidth: undefined,
    gridLineColor: undefined,
    enableLabels: true,
    categories: undefined,
    rotation: 0,
};

export default ChartXAxis;
