import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import WizardFooter from 'components/widgets/WizardFooter';
import Wizardable from 'components/widgets/Wizardable';
import css from 'teamManagement/components/RoleSelection.css';
import WarningMessage from 'teamManagement/components/WizardWarningMessage';
import { ROLE_ORG_ADMIN, ROLE_RESTRICTED, ROLE_STANDARD, ROLES, wizardModes } from 'teamManagement/utils';

class RoleSelection extends Wizardable {
    constructor(props) {
        super(props);
        // override the default button def for finish button
        this.buttonDefs.finish.text = props.translate('STATIC.PAGES.TEAM_MEMBERS.WIZARD_UPDATE_PERMISSIONS_BUTTON');
        this.initialRole = props.role;
        this.onRoleSelection = props.onRoleSelection.bind(this);
    }

    render() {
        const { role, mode, editedMember, showRestrictedMode } = this.props;
        const title = mode === wizardModes.edit ? 'ROLE_SELECTION_MANAGE_USER' : 'ROLE_SELECTION_CREATE_USER_TITLE';
        const userName = (editedMember && editedMember.name) || '';
        const roles = showRestrictedMode ? ROLES : ROLES.filter((r) => r.id !== ROLE_RESTRICTED);
        return (
            <React.Fragment>
                <WarningMessage
                    show={role === ROLE_ORG_ADMIN && mode === wizardModes.edit && this.initialRole !== ROLE_ORG_ADMIN}
                    message="STATIC.PAGES.TEAM_MEMBERS.CHANGING_TO_ADMIN_WARNING"
                />
                <div className={css.page}>
                    <div className={css.headline}>
                        <div className={css.preamble}>
                            <Translate id={`STATIC.PAGES.TEAM_MEMBERS.${title}`} data={{ userName }} />
                        </div>
                    </div>
                    <form>
                        {roles.map((r) => (
                            <div className={css.roleItem} key={r.id}>
                                <input
                                    type="radio"
                                    id={r.id}
                                    name="role"
                                    checked={r.id === role}
                                    onChange={() => this.onRoleSelection(r.id)}
                                />
                                <label htmlFor={r.id} className={css.textContainer}>
                                    <div className={css.roleType}>{r.label}</div>
                                    <div className={css.roleDescription}>{r.description}</div>
                                </label>
                            </div>
                        ))}
                    </form>
                    <WizardFooter buttons={this.getButtons()} />
                </div>
            </React.Fragment>
        );
    }
}
RoleSelection.propTypes = {
    onRoleSelection: PropTypes.func,
    mode: PropTypes.string,
    role: PropTypes.number,
};

RoleSelection.defaultProps = {
    onRoleSelection: () => {},
    mode: '',
    role: ROLE_STANDARD,
};

export default RoleSelection;
