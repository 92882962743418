import React from 'react';
import PropTypes from 'prop-types';
import css from './MultiFields.css';
import { CheckableField } from '../../widgets';

export default function MultiFields({ items, onItemClick }) {
    return (
        <div className={css.fieldsContainer}>
            {items.map(item => (
                <div className={css.field} key={`${item.name}_${item.id}`}>
                    <CheckableField
                        groupType="single"
                        field={item}
                        {...item}
                        onClick={onItemClick}
                        hideIcon
                        fieldClassName={css.checkableField}
                        checkedFieldClassName={css.checkedField}
                    />
                </div>
            ))}
        </div>
    );
}

MultiFields.propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    onItemClick: PropTypes.func,
};

MultiFields.defaultProps = {
    items: [],
    onItemClick: () => {},
};
