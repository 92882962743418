import { connect } from 'react-redux';
import SourceSelection from '../components/SourceSelection';
import { getSourceSelectionPage } from '../selectors';
import { addFutureChanged, changeAllDataSourceSelection, changeDataSourceSelection } from '../actions';

const mapStateToProps = (state, ownProps) => {
    return getSourceSelectionPage(state);
};

const mapDispatchToProps = dispatch => {
    return {
        onAllChange: (allSources, value) => dispatch(changeAllDataSourceSelection(allSources, value)),
        onChange: (id, value) => dispatch(changeDataSourceSelection(id, value)),
        enableAddFuture: () => dispatch(addFutureChanged('dataSourceSelectionForm', true, true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SourceSelection);
