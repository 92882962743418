import update from 'immutability-helper';
import {
    SET_ADMIN_MODE,
    UPDATE_USER_DATA,
    SET_API_KEY,
    SET_ORGANIZATIONS,
    OPEN_IMPERSONATE_DIALOG,
    CLOSE_IMPERSONATE_DIALOG,
    OPEN_CHANGE_PASSWORD_DIALOG,
    CLOSE_CHANGE_PASSWORD_DIALOG,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCEEDED,
    CHANGE_PASSWORD_FAILED,
    TOGGLE_IMPERSONATE_DIALOG_LOADING,
    TOGGLE_TRIAL_STATUS_PANEL,
    SET_IS_USER_SLACK_AUTH,
} from '../actions/user';

const initialState = {
    adminMode: false,
    data: {},
    organizations: [],
    impersonateOrgDialog: {
        open: false,
        loading: true,
    },
    changePasswordDialog: {
        open: false,
        saving: false,
        success: false,
        error: '',
    },
    showTrialStatusPanel: false,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADMIN_MODE: {
            return update(state, {
                adminMode: {
                    $set: action.enabled,
                },
            });
        }
        case UPDATE_USER_DATA: {
            return update(state, {
                data: {
                    $set: action.data,
                },
            });
        }
        case SET_API_KEY: {
            return update(state, {
                data: {
                    api_key: {
                        $set: action.api_key,
                    },
                },
            });
        }
        case SET_ORGANIZATIONS: {
            return update(state, {
                organizations: {
                    $set: action.organizations,
                },
            });
        }
        case OPEN_IMPERSONATE_DIALOG: {
            return update(state, {
                impersonateOrgDialog: {
                    open: {
                        $set: true,
                    },
                },
            });
        }
        case CLOSE_IMPERSONATE_DIALOG: {
            return update(state, {
                impersonateOrgDialog: {
                    open: {
                        $set: false,
                    },
                },
            });
        }
        case TOGGLE_IMPERSONATE_DIALOG_LOADING: {
            return update(state, {
                impersonateOrgDialog: {
                    loading: {
                        $set: action.loading,
                    },
                },
            });
        }
        case TOGGLE_TRIAL_STATUS_PANEL: {
            const newState = update(state, {
                showTrialStatusPanel: {
                    $set: action.show,
                },
            });
            if (action.trialRealDaysLeft) {
                newState.trialRealDaysLeft = action.trialRealDaysLeft;
            }

            if (action.trialDaysLeftToDisplay) {
                newState.trialDaysLeftToDisplay = action.trialDaysLeftToDisplay;
            }

            return newState;
        }
        case OPEN_CHANGE_PASSWORD_DIALOG: {
            return update(state, {
                changePasswordDialog: {
                    open: {
                        $set: true,
                    },
                    saving: {
                        $set: false,
                    },
                    success: {
                        $set: false,
                    },
                    error: {
                        $set: '',
                    },
                },
            });
        }
        case CLOSE_CHANGE_PASSWORD_DIALOG: {
            return update(state, {
                changePasswordDialog: {
                    open: {
                        $set: false,
                    },
                    saving: {
                        $set: false,
                    },
                    success: {
                        $set: false,
                    },
                    error: {
                        $set: '',
                    },
                },
            });
        }
        case CHANGE_PASSWORD: {
            return update(state, {
                changePasswordDialog: {
                    saving: {
                        $set: true,
                    },
                    success: {
                        $set: false,
                    },
                    error: {
                        $set: '',
                    },
                },
            });
        }
        case CHANGE_PASSWORD_SUCCEEDED: {
            return update(state, {
                changePasswordDialog: {
                    success: {
                        $set: true,
                    },
                    saving: {
                        $set: false,
                    },
                },
            });
        }
        case CHANGE_PASSWORD_FAILED: {
            return update(state, {
                changePasswordDialog: {
                    saving: {
                        $set: false,
                    },
                    success: {
                        $set: false,
                    },
                    error: {
                        $set: action.error || 'internal error',
                    },
                },
            });
        }
        case SET_IS_USER_SLACK_AUTH: {
            return update(state, {
                data: {
                    is_slack_auth: {
                        $set: action.isUserSlackAuth,
                    },
                },
            });
        }
        default:
            return state;
    }
};

export default user;
