import { ModelTypes } from '../../consts';
import { SKAN_PLUS_STATUSES } from '../../utils';

export const getOptimizedModelsData = (optimizedModels, modelType, revenueType, revenueBuckets, measurementPeriod) => {
    const currentOptimizedModel = optimizedModels.find(
        ({
            revenue_type: optimizedRevenueType,
            measurement_period: optimizedMeasurementPeriod,
            optimized_conversion_model: optimizedBuckets,
        }) =>
            modelType === ModelTypes.REVENUE &&
            revenueType === optimizedRevenueType &&
            measurementPeriod === optimizedMeasurementPeriod &&
            revenueBuckets.length === optimizedBuckets.length &&
            JSON.stringify(revenueBuckets) === JSON.stringify(optimizedBuckets)
    );

    const { optimized_model_improvement: currentOptimizedModelImprovement = 0 } = currentOptimizedModel || {};

    const relevantModels = optimizedModels.filter(
        ({ revenue_type: optimizedRevenueType, measurement_period: optimizedMeasurementPeriod }) =>
            modelType === ModelTypes.REVENUE &&
            revenueType === optimizedRevenueType &&
            measurementPeriod === optimizedMeasurementPeriod
    );

    if (relevantModels.length === 0) return { status: SKAN_PLUS_STATUSES.NO_OPTIMIZATION_MODEL, optimizedModels: [] };

    const improvedModels = relevantModels.filter(
        ({ optimized_model_improvement: optimizedModelImprovement }) =>
            optimizedModelImprovement > currentOptimizedModelImprovement
    );

    return {
        status: improvedModels.length ? SKAN_PLUS_STATUSES.UNOPTIMIZED_MODEL : SKAN_PLUS_STATUSES.OPTIMIZED_MODEL,
        optimizedModels: improvedModels,
    };
};
