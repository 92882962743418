import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import css from './BookmarkPopover.css';
import GenericPopover from './GenericPopover';
import SingularButton from '../widgets/SingularButton';
import Datepicker from '../widgets/Datepicker';
import Dropdown from '../widgets/Dropdown';
import Checkbox from '../widgets/Checkbox';
import { datePickerTimes, getSecondDatePickerRanges } from '../../utils/regional';

function BookmarkPopover(props) {
    const {
        type,
        show,
        compareMode,
        saveNewCopy,
        error,
        translate,
        instanceId,
        updatedInstanceId,
        onInnerShowChanged,
        onSaveBookmark,
        bookmarkData,
        timePeriod: _timePeriod,
        share: _share,
        days: _days,
        daysEnd: _daysEnd,
        startDate: _startDate,
        endDate: _endDate,
        startDate2: _startDate2,
        endDate2: _endDate2,
    } = props;

    const customDate = { display_name: translate('STATIC.BUTTONS.CUSTOM'), name: 'STATIC.BUTTONS.CUSTOM' };

    const getTimes = () => {
        const timesValues = Object.keys(datePickerTimes).map(time => ({ display_name: translate(time), name: time }));
        timesValues.splice(0, 1);
        timesValues.push(customDate);

        return timesValues;
    };

    const getTimesCompare = () => {
        const timesCompareValue = Object.keys(getSecondDatePickerRanges(_startDate, _endDate)).map(time => ({
            display_name: translate(time),
            name: time,
        }));

        timesCompareValue.push(customDate);

        return timesCompareValue;
    };

    const [timePeriod, setTimePeriod] = useState(_timePeriod);
    const [share, setShare] = useState(_share);
    const [name, setName] = useState('');
    const [selectedRolling, setSelectedRolling] = useState('');
    const [selectedRollingCompare, setSelectedRollingCompare] = useState('');
    const [days, setDays] = useState(_days);
    const [daysEnd, setDaysEnd] = useState(_daysEnd);
    const [currentStartDate, setCurrentStartDate] = useState(_startDate);
    const [currentEndDate, setCurrentEndDate] = useState(_endDate);
    const [currentStartDate2, setCurrentStartDate2] = useState(_startDate2);
    const [currentEndDate2, setCurrentEndDate2] = useState(_endDate2);

    const [times] = useState(getTimes());
    const [timesCompare] = useState(getTimesCompare());

    const selectedRollingObj = times.find(time => time.name === selectedRolling);
    const selectedRollingCompareObj = timesCompare.find(time => time.name === selectedRollingCompare);
    const errorToShow = instanceId === updatedInstanceId ? error : null;

    useEffect(() => {
        setCurrentStartDate(_startDate);
    }, [_startDate]);

    useEffect(() => {
        setCurrentEndDate(_endDate);
    }, [_endDate]);

    useEffect(() => {
        setCurrentStartDate2(_startDate2);
    }, [_startDate2]);

    useEffect(() => {
        setCurrentEndDate2(_endDate2);
    }, [_endDate2]);

    useEffect(() => {
        if (error && !show && instanceId === updatedInstanceId) {
            onInnerShowChanged(true);
        }
    }, [error, updatedInstanceId]);

    useEffect(() => {
        if (!times.length) {
            return;
        }

        if (!bookmarkData) {
            setName('');
            setSelectedRolling(times[0].name);
            setSelectedRollingCompare(timesCompare[0].name);
        } else {
            const {
                name: bookmarkName,
                is_shared: isShared,
                time_period_type: timePeriodType,
                absolute_start_date: absoluteStartDate,
                absolute_end_date: absoluteEndDate,
                relative_days_back: relativeDaysBack,
                report_type: reportType,
                relative_comparison_period: relativeComparisonPeriod,
                compare_absolute_start_date: compareAbsoluteStartDate,
                compare_absolute_end_date: compareAbsoluteEndDate,
                comparison_days_gap: comparisonDaysGap,
                time_period: bookmarkTimePeriod,
            } = bookmarkData;

            let displayName = bookmarkName;

            if (saveNewCopy && displayName) {
                const regexp = new RegExp('\\((\\d+)\\)$');
                const match = regexp.exec(displayName);

                if (match) {
                    const newNameSuffix = parseInt(match[1], 10) + 1;
                    displayName = displayName.replace(match[0], `(${newNameSuffix})`);
                } else {
                    displayName = `${displayName} (1)`;
                }
            }

            const newSelectedRolling = (times.find(time => time.display_name === bookmarkTimePeriod) || times[0]).name;
            const newSelectedRollingCompare = (
                timesCompare.find(time => time.display_name === relativeComparisonPeriod) || times[0]
            ).name;

            setName(displayName);
            setShare(isShared);
            setTimePeriod(timePeriodType);
            setCurrentStartDate(absoluteStartDate);
            setCurrentEndDate(absoluteEndDate);
            setDays(relativeDaysBack);
            setSelectedRolling(newSelectedRolling);

            if (reportType === 'compare') {
                setDaysEnd(comparisonDaysGap);
                setCurrentStartDate2(compareAbsoluteStartDate);
                setCurrentEndDate2(compareAbsoluteEndDate);
                setSelectedRollingCompare(newSelectedRollingCompare);
            }
        }
    }, [bookmarkData, saveNewCopy]);

    const handleCheckboxClicked = () => {
        setShare(!share);
    };

    const handleNameChanged = e => {
        setName(e.target.value);
    };

    const handleSaveButtonClicked = () => {
        const data = {
            type,
            query: {},
            name,
            time_period: translate(selectedRolling, null, { language: 'en' }),
            relative_comparison_period: null,
            is_shared: share,
            time_period_type: timePeriod,
            relative_days_back: days,
            comparison_days_gap: 1,
            report_type: 'basic',
            creator: '',
            absolute_start_date: currentStartDate,
            absolute_end_date: currentEndDate,
            saveNewCopy,
            instanceId,
        };
        if (compareMode) {
            Object.assign(data, {
                report_type: 'compare',
                compare_absolute_start_date: currentStartDate2,
                compare_absolute_end_date: currentEndDate2,
                relative_comparison_period: translate(selectedRollingCompare, null, { language: 'en' }),
                comparison_days_gap: daysEnd,
            });
        }
        onSaveBookmark(data, onInnerShowChanged);
    };

    const handleTimePeriodChanged = e => {
        setTimePeriod(e.target.value);
    };

    const handleStaticSelection = (selected, setter = setSelectedRolling) => {
        setter(selected.name);
    };

    const handleDaysChanged = (e, setter = setDays) => {
        if (e.target.value < 1) {
            return;
        }

        setter(e.target.value);
    };

    const handleDatesChanged = (dates, isSecond = false) => {
        if (!isSecond) {
            setCurrentStartDate(dates.start_date);
            setCurrentEndDate(dates.end_date);
        } else {
            setCurrentStartDate2(dates.start_date);
            setCurrentEndDate2(dates.end_date);
        }
    };

    return (
        <GenericPopover show={show} onInnerShowChanged={onInnerShowChanged}>
            <div
                className={css.container}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div>
                    <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.TITLE" />
                </div>
                <input className={css.nameInput} type="text" value={name} onChange={handleNameChanged} />
                <div className={css.bookmarkRow}>
                    <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.TIME_PERIOD" />
                </div>
                <form
                    style={{
                        margin: 0,
                    }}
                >
                    <label className={css.radioLabel} htmlFor="static">
                        <input
                            onChange={handleTimePeriodChanged}
                            type="radio"
                            id={`static_${instanceId}`}
                            name="timePeriod"
                            value="relative"
                            checked={timePeriod === 'relative'}
                        />
                        <span>
                            <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.ROLLING_PERIOD" />
                        </span>
                    </label>
                    <label className={css.radioLabel} htmlFor="rolling">
                        <input
                            onChange={handleTimePeriodChanged}
                            type="radio"
                            id={`rolling_${instanceId}`}
                            name="timePeriod"
                            value="absolute"
                            checked={timePeriod === 'absolute'}
                        />
                        <span>
                            <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.STATIC_PERIOD" />
                        </span>
                    </label>
                </form>
                <div className={css.dateSelectionContainer}>
                    {timePeriod === 'absolute' ? (
                        <Datepicker
                            containerStyle={{
                                width: '100%',
                            }}
                            startDate={currentStartDate || _startDate}
                            endDate={currentEndDate || _endDate}
                            dateFormat="ll"
                            opens="left"
                            onApply={handleDatesChanged}
                            id={`bookmark_dp_1_${instanceId}`}
                        />
                    ) : (
                        <Dropdown
                            containerStyle={{
                                width: '100%',
                            }}
                            items={times}
                            selected={selectedRollingObj}
                            onSelection={handleStaticSelection}
                            id={`bookmark_dp_2_${instanceId}`}
                        />
                    )}
                </div>
                {timePeriod === 'relative' && selectedRolling === 'STATIC.BUTTONS.CUSTOM' ? (
                    <div className={css.numOfDaysContainer}>
                        <input className={css.numOfDaysInput} type="number" value={days} onChange={handleDaysChanged} />
                        <div>
                            <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.LAST_DAYS_BACK_2" />
                        </div>
                    </div>
                ) : null}
                {compareMode ? (
                    <div
                        className={css.dateSelectionContainer}
                        style={{
                            marginTop: 0,
                        }}
                    >
                        <div className={css.bookmarkRow}>
                            <Translate id="STATIC.BUTTONS.DATEPICKER.PREVIOUS_PERIOD" />
                        </div>
                        {timePeriod === 'absolute' ? (
                            <Datepicker
                                containerStyle={{
                                    width: '100%',
                                }}
                                startDate={currentStartDate2 || _startDate2}
                                endDate={currentEndDate2 || _endDate2}
                                dateFormat="ll"
                                opens="left"
                                onApply={dates => {
                                    handleDatesChanged(dates, true);
                                }}
                            />
                        ) : (
                            <Dropdown
                                containerStyle={{
                                    width: '100%',
                                }}
                                items={timesCompare}
                                selected={selectedRollingCompareObj}
                                onSelection={selected => {
                                    handleStaticSelection(selected, setSelectedRollingCompare);
                                }}
                            />
                        )}
                    </div>
                ) : null}
                {timePeriod === 'relative' && selectedRollingCompare === 'STATIC.BUTTONS.CUSTOM' ? (
                    <div className={css.numOfDaysContainer}>
                        <div>
                            <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.COMPARISON_GAP_1" />
                        </div>
                        <input
                            className={css.numOfDaysInput}
                            style={{
                                marginLeft: '6px',
                            }}
                            type="number"
                            value={daysEnd}
                            onChange={e => {
                                handleDaysChanged(e, setDaysEnd);
                            }}
                        />
                        <div>
                            <Translate id="STATIC.PAGES.REPORTS.BOOKMARKS.COMPARISON_GAP_2" />
                        </div>
                    </div>
                ) : null}
                <div className={css.shareContainer}>
                    <Checkbox
                        label={translate('STATIC.PAGES.REPORTS.BOOKMARKS.PUBLIC')}
                        className={css.shareCheckbox}
                        type="checkbox"
                        checked={share}
                        onChange={handleCheckboxClicked}
                    />
                </div>
                {errorToShow ? <div className={css.error}>{errorToShow}</div> : null}
                <div className={css.buttonsContainer}>
                    <SingularButton
                        className={css.button}
                        type="primary"
                        level="level1"
                        text="STATIC.BUTTONS.SAVE"
                        onClick={handleSaveButtonClicked}
                    />
                </div>
            </div>
        </GenericPopover>
    );
}

BookmarkPopover.propTypes = {
    type: PropTypes.string,
    show: PropTypes.bool,
    timePeriod: PropTypes.string,
    translate: PropTypes.func,
    share: PropTypes.bool,
    days: PropTypes.number,
    daysEnd: PropTypes.number,
    onSaveBookmark: PropTypes.func,
    onInnerShowChanged: PropTypes.func,
    compareMode: PropTypes.bool,
    startDate: PropTypes.string,
    startDate2: PropTypes.string,
    endDate: PropTypes.string,
    endDate2: PropTypes.string,
    bookmarkData: PropTypes.objectOf(PropTypes.any),
    error: PropTypes.string,
    instanceId: PropTypes.string,
    updatedInstanceId: PropTypes.string,
    saveNewCopy: PropTypes.bool,
};

BookmarkPopover.defaultProps = {
    type: null,
    show: false,
    timePeriod: 'absolute',
    translate: () => {},
    share: false,
    days: 7,
    daysEnd: 1,
    onSaveBookmark: () => {},
    onInnerShowChanged: () => {},
    compareMode: false,
    startDate: '',
    startDate2: '',
    endDate: '',
    endDate2: '',
    bookmarkData: null,
    error: '',
    instanceId: null,
    updatedInstanceId: null,
    saveNewCopy: undefined,
};

export default withLocalize(BookmarkPopover);
