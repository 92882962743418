export const OLD_PARTNER_CONFIG_URL = 'partner-config';
export const BACK_TO_PARTNER_ROUTE_PARAM = 'backToPartner';
export const BACK_TO_FILTER_ROUTE_PARAM = 'backToFilter';
export const OPEN_USER_CONFIGURATION_MODAL = 'openUserConfiguration';

export const FAQ_LINKS = {
    faqLink: 'https://support.singular.net/hc/en-us/articles/115000483946-Partner-Configuration-FAQ',
};
export const FACEBOOK_PARTNER_NAME = 'facebook';
export const TIKTOK_PARTNER_NAME = 'tiktok_san';
export const SNAPCHAT_PARTNER_NAME = 'snapchat';
export const APPLE_SEARCH_ADS_PARTNER_NAME = 'applesearchads_adservices';

const AMAZON_PARTNER_NAME = 'amazonads';
const YAHOO_GEMINI_PARTNER_NAME = 'yahoogemini';
const YAHOO_JAPAN_PARTNER_NAME = 'yahoo_japan_sponsored_search';
export const HOTSTAR_NAME = 'HotStar';
export const SAN_PARTNERS = [
    FACEBOOK_PARTNER_NAME,
    TIKTOK_PARTNER_NAME,
    SNAPCHAT_PARTNER_NAME,
    AMAZON_PARTNER_NAME,
    YAHOO_GEMINI_PARTNER_NAME,
    APPLE_SEARCH_ADS_PARTNER_NAME,
    YAHOO_JAPAN_PARTNER_NAME,
];
