import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import css from '../style.css';
import SingularButton from '../../components/widgets/SingularButton';

import SingularLogoIcon from '../../resources/svg/singular_flat_logo_white.svg';
import TextField from '../../components/widgets/TextField';
import GoogleAuthButton from '../../components/widgets/GoogleAuthButton';
import {
    COMPANY_NAME,
    CURRENCY,
    getInvalidFields,
    getSecondFormValidations,
    HAS_MOBILE_APP,
    IS_AGENCY,
    COMPANY_STAGE,
    isValidForm,
    TIMEZONE,
    USER_ROLE,
    COUNTRY,
    getUserTimezone,
    isTimezoneMatchingCountry,
    RECAPTCHA_TOKEN, getRecaptchaSignupSiteKey,
} from '../utils';
import NewTagInput from '../../components/widgets/NewTagInput';
import WarningMessage from '../../components/widgets/WarningMessage';
import { trackMixpanelEvent } from '../../utils/general';
import { SIGN_UP_MIXPANEL_PREFIX } from '../consts';

let pageLoadTime;

function SignupFormSecondPage({
    email,
    fullName,
    password,
    currencies,
    timeZones,
    onSubmitClick,
    isLoading,
    onCompleteGoogleSignUp,
    signupWithGoogle,
    errorCode,
    translate,
    countries,
}) {
    const [currency, setCurrency] = useState('');
    const [timezone, setTimezone] = useState('');
    const [country, setCountry] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [hasMobileApp, setHasMobileApp] = useState('');
    const [companyStage, setCompanyStage] = useState('');
    const [isAgency, setIsAgency] = useState({
        label: translate('STATIC.PAGES.SIGNUP.AGENCY_OPTIONS.NO'),
        value: 'no',
    });
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(getRecaptchaSignupSiteKey());
    const [validations, setValidations] = useState({
        [CURRENCY]: '',
        [TIMEZONE]: '',
        [COMPANY_NAME]: '',
        [USER_ROLE]: '',
        [HAS_MOBILE_APP]: '',
        [COMPANY_STAGE]: '',
        [IS_AGENCY]: '',
        [COUNTRY]: '',
        [RECAPTCHA_TOKEN]: '',
    });

    const onSignupClick = () => {
        const newValidations = getSecondFormValidations(
            currency,
            timezone,
            companyName,
            userRole,
            hasMobileApp,
            companyStage,
            isAgency,
            country,
            recaptchaToken,
            signupWithGoogle
        );
        setValidations(newValidations);
        if (isValidForm(newValidations)) {
            signupWithGoogle
                ? onCompleteGoogleSignUp({
                      currency,
                      timezone,
                      companyName,
                      userRole,
                      hasMobileApp,
                      companyStage,
                      isAgency,
                      country,
                  })
                : onSubmitClick({
                      fullName,
                      email,
                      password,
                      currency,
                      timezone,
                      companyName,
                      userRole,
                      hasMobileApp,
                      companyStage,
                      isAgency,
                      pageLoadTime,
                      country,
                      recaptchaToken,
                  });
        } else {
            window.mixpanel.track('sign_up_submit', {
                signup_email: email,
                time_to_submit: (new Date() - pageLoadTime) / 1000,
                client_errors: getInvalidFields(newValidations).join(', '),
                success: 'false',
            });
        }
    };

    const onCurrencySelected = _currency => {
        setCurrency(_currency);
    };
    const onTimeZonesSelected = _timeZone => {
        setTimezone(_timeZone);
    };
    const onCountrySelected = _country => {
        setCountry(_country);
        const userTimezone = getUserTimezone();
        if (isTimezoneMatchingCountry(userTimezone, _country.name)) {
            const maybeTimezone = timeZones?.find(tz => tz.name === userTimezone);
            if (maybeTimezone) {
                setTimezone(maybeTimezone);
            }
        }
    };
    const onCompanyNameChange = _companyName => {
        setCompanyName(_companyName);
    };
    const onUserRoleSelected = _userRole => {
        setUserRole(_userRole);
    };
    const onHasMobileAppSelected = _hasMobileApp => {
        setHasMobileApp(_hasMobileApp);
    };
    const onCompanyStageSelected = _companyStage => {
        setCompanyStage(_companyStage);
    };
    const onIsAgencySelected = _isAgency => {
        setIsAgency(_isAgency);
    };
    useEffect(() => {
        pageLoadTime = new Date();
    }, []);

    useEffect(() => {
        if (window.country) {
            const maybeCountry = countries?.find(c => c.display_name === window.country);
            if (maybeCountry) {
                onCountrySelected(maybeCountry);
            }
        }
    }, [countries]);

    const onReCaptchaChange = _recaptchaToken => {
        setRecaptchaToken(_recaptchaToken);
    };
    const clearValidation = validationKey => {
        setValidations({ ...validations, [validationKey]: null });
    };
    const isValid = () => {
        const newValidations = getSecondFormValidations(
            currency,
            timezone,
            companyName,
            userRole,
            hasMobileApp,
            country
        );
        setValidations(newValidations);
        return isValidForm(newValidations);
    };

    useEffect(() => {
        clearValidation(CURRENCY);
    }, [currency]);
    useEffect(() => {
        clearValidation(TIMEZONE);
    }, [timezone]);
    useEffect(() => {
        clearValidation(COMPANY_NAME);
    }, [companyName]);
    useEffect(() => {
        clearValidation(USER_ROLE);
    }, [userRole]);
    useEffect(() => {
        clearValidation(HAS_MOBILE_APP);
    }, [hasMobileApp]);
    useEffect(() => {
        clearValidation(COUNTRY);
    }, [country]);
    useEffect(() => {
        setRecaptchaSiteKey(getRecaptchaSignupSiteKey());
    }, []);

    const companyStageSignupOptions = [
        {
            label: translate('STATIC.PAGES.SIGNUP.COMPANY_STAGE_SIGNUP_OPTIONS.PRE_LAUNCH'),
            value: 'pre_launch',
        },
        {
            label: translate('STATIC.PAGES.SIGNUP.COMPANY_STAGE_SIGNUP_OPTIONS.BETA_LAUNCH'),
            value: 'beta_launch',
        },
        {
            label: translate('STATIC.PAGES.SIGNUP.COMPANY_STAGE_SIGNUP_OPTIONS.SMALL_BUDGET'),
            value: 'small_budget',
        },
        {
            label: translate('STATIC.PAGES.SIGNUP.COMPANY_STAGE_SIGNUP_OPTIONS.GROWTH_MODE'),
            value: 'growth_mode',
        },
        {
            label: translate('STATIC.PAGES.SIGNUP.COMPANY_STAGE_SIGNUP_OPTIONS.OTHER'),
            value: 'other',
        },
    ];

    const isAgencyOptions = [
        {
            label: translate('STATIC.PAGES.SIGNUP.AGENCY_OPTIONS.YES'),
            value: 'yes',
        },
        {
            label: translate('STATIC.PAGES.SIGNUP.AGENCY_OPTIONS.NO'),
            value: 'no',
        },
    ];

    const hasMobileAppOptions = [
        {
            display_name: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.YES_AND_LIVE'),
            label: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.YES_AND_LIVE'),
            name: 'yes',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.YES_NOT_LIVE'),
            label: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.YES_NOT_LIVE'),
            name: 'yes_not_live',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.NO_WEB_ONLY'),
            label: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.NO_WEB_ONLY'),
            name: 'no_web_only',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.OTHER'),
            label: translate('STATIC.PAGES.SIGNUP.MOBILE_APP_OPTIONS.OTHER'),
            name: 'other',
        },
    ];

    const userRoleOptions = [
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.CEO'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.CEO'),
            name: 'ceo',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.CTO'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.CTO'),
            name: 'cto',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.MARKETING_LEAD'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.MARKETING_LEAD'),
            name: 'marketing_lead',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.MARKETING_UA'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.MARKETING_UA'),
            name: 'marketing_ua',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.PRODUCT'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.PRODUCT'),
            name: 'product',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.FOUNDER'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.FOUNDER'),
            name: 'founder',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.SALES'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.SALES'),
            name: 'sales',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.DEVELOPER'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.DEVELOPER'),
            name: 'developer',
        },
        {
            display_name: translate('STATIC.PAGES.SIGNUP.USER_ROLES.OTHER'),
            label: translate('STATIC.PAGES.SIGNUP.USER_ROLES.OTHER'),
            name: 'other',
        },
    ];

    // Google Signup Flow or Fill Form flow
    function SubmitFormButton() {
        return signupWithGoogle ? (
            <GoogleAuthButton
                onSubmit={onSignupClick}
                onAuthSuccess={() => {}}
                onAuthError={() => {}}
                isValid={isValid}
                isLoading={isLoading}
                className={css.googleSignupButton}
                text="STATIC.PAGES.SIGNUP.FINISH_SIGNUP_WITH_GOOGLE"
                trackingParams={{ origin: 'sign_up', signup_email: email }}
            />
        ) : (
            <div className={css.submit}>
                <SingularButton
                    onClick={onSignupClick}
                    type="primary"
                    level="level1"
                    text="STATIC.PAGES.SIGNUP.SIGNUP_FINISH_BUTTON"
                    showSpinner={isLoading}
                    className={css.submitButton}
                    textClassName={css.submitButtonText}
                />
            </div>
        );
    }

    // Changes the Arrow color of the Select element of React
    const dropdownIndicatorStyles = (base, state) => {
        const changes = {
            svg: {
                fill: '#515864 !important',
            },
        };
        return Object.assign(base, changes);
    };

    const getSortedUserRoleOptions = useMemo(() => {
        // Make sure that the "other" option always appears last in the dropdown
        return userRoleOptions.sort((a, b) => {
            if (a.name === 'other') return 1;
            if (b.name === 'other') return -1;
            return a.display_name.localeCompare(b.display_name);
        });
    }, []);

    return (
        <div className={classNames(css.signupForm)}>
            <div className={css.signupFormInner}>
                <div className={css.singularFormTopSection}>
                    <SingularLogoIcon className={css.singularIcon} />
                    <div className={classNames(css.singularFormTitleSecondPage, 'showInNonMobile')}>
                        <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SECOND_PAGE_TITLE" />
                    </div>
                    <div className={classNames(css.singularFormTitle, 'showInMobile')}>
                        <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SECOND_PAGE_TITLE" />
                    </div>
                    <div className={css.singularFormBodySecondPage}>
                        <WarningMessage
                            show={!!errorCode}
                            showIcon={false}
                            showTypeIcon
                            type="error"
                            message={`STATIC.PAGES.SIGNUP.${errorCode}`}
                            className={css.warningMessage}
                        />
                        <div className={css.dropDown}>
                            Preferred Currency
                            <NewTagInput
                                placeholder="Select a currency"
                                containerStyle={{ width: '100%' }}
                                tags={currency}
                                options={currencies}
                                className={css.formDropdown}
                                onChange={onCurrencySelected}
                                isMulti={false}
                                error={validations.currency}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>
                        <div className={css.dropDown}>
                            Country
                            <NewTagInput
                                placeholder="Select a country"
                                containerStyle={{ width: '100%' }}
                                tags={country}
                                options={countries}
                                className={css.formDropdown}
                                onChange={onCountrySelected}
                                isMulti={false}
                                error={validations.country}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>
                        <div className={css.dropDown}>
                            Timezone
                            <NewTagInput
                                placeholder="Select a timezone"
                                containerStyle={{ width: '100%' }}
                                tags={timezone}
                                options={timeZones}
                                className={css.formDropdown}
                                onChange={onTimeZonesSelected}
                                isMulti={false}
                                error={validations.timezone}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>

                        <TextField
                            inputConfig={{ maxLength: 70 }}
                            error={validations.companyName}
                            value={companyName}
                            containerClass={css.textField}
                            label="STATIC.PAGES.SIGNUP.SIGNUP_FORM_WHATS_COMPANY_NAME"
                            placeholder="Enter your company name"
                            inputClassName={css.placeholder}
                            onChange={onCompanyNameChange}
                            disabled={isLoading}
                        />

                        <div className={css.dropDown}>
                            What's your role?
                            <NewTagInput
                                placeholder="Select"
                                containerStyle={{ width: '100%' }}
                                tags={userRole}
                                options={getSortedUserRoleOptions}
                                className={css.formDropdown}
                                onChange={onUserRoleSelected}
                                isMulti={false}
                                error={validations.userRole}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>
                        <div className={css.dropDown}>
                            Do you have an app?
                            <NewTagInput
                                placeholder="Select"
                                containerStyle={{ width: '100%' }}
                                tags={hasMobileApp}
                                options={hasMobileAppOptions}
                                className={css.formDropdown}
                                onChange={onHasMobileAppSelected}
                                isMulti={false}
                                error={validations.hasMobileApp}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                isSearchable={false}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>
                        <div className={css.dropDown}>
                            <Translate id="STATIC.PAGES.SIGNUP.COMPANY_STAGE_SIGNUP_QUESTION" />
                            <NewTagInput
                                placeholder="Select"
                                containerStyle={{ width: '100%' }}
                                tags={companyStage}
                                options={companyStageSignupOptions}
                                className={css.formDropdown}
                                onChange={onCompanyStageSelected}
                                isMulti={false}
                                error={validations[COMPANY_STAGE]}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                isSearchable={false}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>
                        <div className={css.dropDown}>
                            <Translate id="STATIC.PAGES.SIGNUP.AGENCY_QUESTION" />
                            <NewTagInput
                                placeholder="Select"
                                containerStyle={{ width: '100%' }}
                                tags={isAgency}
                                options={isAgencyOptions}
                                className={css.formDropdown}
                                onChange={onIsAgencySelected}
                                isMulti={false}
                                error={validations[IS_AGENCY]}
                                dropdownIndicatorStyles={dropdownIndicatorStyles}
                                isSearchable={false}
                                placeholderClass={css.placeholderStyle}
                                separator={false}
                            />
                        </div>
                        {!signupWithGoogle && (
                            <div className={css.recaptcha}>
                                <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onReCaptchaChange} />
                                {validations[RECAPTCHA_TOKEN] && (
                                    <div className={css.errorMessage}>
                                        <Translate id={validations[RECAPTCHA_TOKEN]} />
                                    </div>
                                )}
                            </div>
                        )}
                        <SubmitFormButton />
                    </div>
                    <div className={css.footerText}>
                        <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SUBTITLE" />
                        <span
                            onClick={() =>
                                trackMixpanelEvent(SIGN_UP_MIXPANEL_PREFIX, 'Sign Up Page: Get a partner account')
                            }
                        >
                            <Translate id="STATIC.PAGES.SIGNUP.SIGNUP_FORM_SUBTITLE_LINK" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

SignupFormSecondPage.propTypes = {
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    currencies: PropTypes.arrayOf(PropTypes.object),
    timeZones: PropTypes.arrayOf(PropTypes.object),
    countries: PropTypes.arrayOf(PropTypes.object),
    onSubmitClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCompleteGoogleSignUp: PropTypes.func.isRequired,
    signupWithGoogle: PropTypes.bool,
    translate: PropTypes.func,
    errorCode: PropTypes.string,
};

SignupFormSecondPage.defaultProps = {
    currencies: {},
    timeZones: {},
    signupWithGoogle: false,
    translate: () => {},
    errorCode: '',
};

export default SignupFormSecondPage;
