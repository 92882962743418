import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Skeleton, Tooltip } from '@mui/material';
import classNames from 'classnames';

import Fade from '@mui/material/Fade';
import css from './CreativeCard.css';
import { getCreativeSizeRatio } from '../../creativeSizingUtils';
import { CreativeType } from '../../types';
import CreativeCardData from '../creativeCardData/CreativeCardData';
import CreativeAsset from '../creativeAsset/CreativeAsset';
import Tag from '../../../components/widgets/Tag';
import { DimensionCategoryToColor } from '../../dimensionsUtils';
import { getAdminModeEnabled } from '../../../selectors/user';
import OverflowingText from '../../../components/widgets/OverflowingText';
import { AutoCompleteOptionType } from '../../../components/widgets/AutoCompleteField';

export const SKELETON_LOADING_TEST_ID = 'loadingSkeleton';
export const MAX_CREATIVE_CARD_HEIGHT = 800;

export const calculateElementOnHover = textList => {
    if (!textList?.length) return null;

    const isOnlyMainText = textList.length === 1;
    const formattedText = isOnlyMainText ? textList[0] : [...textList].sort((a, b) => a.length - b.length)[0];
    return (
        <>
            <OverflowingText hidePreview className={classNames(css.mainText, { [css.onlyMainText]: isOnlyMainText })}>
                {formattedText}
            </OverflowingText>
            {!isOnlyMainText && <div className={css.moreText}>{`(+${textList.length - 1} more)`}</div>}
        </>
    );
};

const CreativeCard = React.forwardRef(
    (
        {
            url,
            type,
            width,
            height,
            imageHash,
            multiValuedDimensions,
            metrics,
            selectedMetrics,
            aiTags,
            isLoadingDimensions,
            containerClass,
            onClick,
            showType,
        },
        ref
    ) => {
        const assetRef = useRef();

        const isAdminMode = useSelector(getAdminModeEnabled);

        const [isLoading, setIsLoading] = useState(true);
        const [assetWidth, setAssetWidth] = useState(width);
        const [assetHeight, setAssetHeight] = useState(height);

        const onError = async () => {
            setIsLoading(false);
        };

        const onLoad = () => {
            setIsLoading(false);
            if (!assetWidth || !assetHeight) {
                setAssetWidth(assetRef.current.clientWidth);
                setAssetHeight(assetRef.current.clientHeight);
            }
        };

        const onCopyClick = (e, text) => {
            e.stopPropagation();
            navigator.clipboard.writeText(text);
        };

        const aspectRatio = useMemo(() => getCreativeSizeRatio(assetWidth, assetHeight), [assetWidth, assetHeight]);
        const creativeStyle = { aspectRatio };

        const isPlaceHolder = !url;
        const showAsset = !isPlaceHolder && !isLoading;

        const { assetNameList, creativeNameList } = multiValuedDimensions || {};

        const elementOnHover = calculateElementOnHover(assetNameList) ?? calculateElementOnHover(creativeNameList);

        return (
            <div
                ref={ref}
                className={classNames(css.container, containerClass)}
                data-testid={url}
                style={{ maxHeight: MAX_CREATIVE_CARD_HEIGHT }}
            >
                {!showAsset && (
                    <>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            className={css.assetSkeleton}
                            data-testid={SKELETON_LOADING_TEST_ID}
                            style={creativeStyle} // For testing purposes, we need to use the 'style' property.
                            sx={creativeStyle} // MUI overrides the 'style' property, requiring us to use 'sx'.
                        />
                        <Skeleton variant="text" className={css.metricsSkeleton} />
                        <Skeleton variant="circular" className={css.osSkeleton} />
                    </>
                )}
                <Fade in={showAsset} style={{ transitionDuration: '1s' }}>
                    <div
                        onClick={() => onClick(imageHash)}
                        className={classNames(css.creativeCard, { [css.show]: showAsset })}
                    >
                        <div className={css.creativeAssetContainer}>
                            {elementOnHover && (
                                <div className={css.assetHover}>
                                    <div className={css.hoverTextContainer}>{elementOnHover}</div>
                                </div>
                            )}
                            <CreativeAsset
                                aspectRatio={aspectRatio}
                                onError={onError}
                                type={type}
                                ref={assetRef}
                                url={url}
                                onLoad={onLoad}
                                showCreativeTypeIndication={showType}
                            />
                        </div>
                        {!!aiTags.length && aiTags.some(value => value.tags.length) && (
                            <div className={css.tags}>
                                {aiTags.map(({ tags, category, dimensionName }) =>
                                    tags.map(tag => (
                                        <Tooltip key={`${dimensionName}-${tag}`} title={`Dimension: ${dimensionName}`}>
                                            <span>
                                                <Tag value={tag} color={DimensionCategoryToColor[category]} />
                                            </span>
                                        </Tooltip>
                                    ))
                                )}
                            </div>
                        )}
                        <CreativeCardData
                            osList={multiValuedDimensions?.osList || []}
                            metrics={metrics}
                            selectedMetrics={selectedMetrics}
                            isLoadingDimensions={isLoadingDimensions}
                        />
                        {isAdminMode && (
                            <div className={css.adminAdditionalCreativeInfo}>
                                <OverflowingText
                                    onClick={e => onCopyClick(e, imageHash)}
                                    className={css.adminDataSection}
                                >
                                    Hash: {imageHash}
                                </OverflowingText>
                                <OverflowingText onClick={e => onCopyClick(e, url)} className={css.adminDataSection}>
                                    URL: {url}
                                </OverflowingText>
                            </div>
                        )}
                    </div>
                </Fade>
            </div>
        );
    }
);

const DimensionTagsProp = PropTypes.shape({
    dimensionName: PropTypes.string,
    category: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
});

CreativeCard.propTypes = {
    ...CreativeType,
    showType: PropTypes.bool,
    onClick: PropTypes.func,
    containerClass: PropTypes.string,
    selectedMetrics: PropTypes.arrayOf(AutoCompleteOptionType),
    aiTags: PropTypes.arrayOf(DimensionTagsProp),
};

CreativeCard.defaultProps = {
    onClick: () => {},
    containerClass: '',
    aiTags: [],
    showType: true,
};

export default CreativeCard;
