import React from 'react';
import { withStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import XIcon from '../../resources/svg/x.svg';

const styles = () => ({
    scrollPaper: {
        alignItems: 'flex-start',
    },
    paperScrollBody: {
        marginTop: '20vh',
        width: 570,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        backgroundColor: '#ffffff',
        padding: '35px 40px',
        verticalAlign: 'top',
        borderRadius: '7px',
    },
});

function SmallDialog({ children, open, classes, onClose, onEscapePress, showX }) {
    const _onClose = (event, reason) => {
        if (reason === 'escapeKeyDown' && onEscapePress) {
            onEscapePress();
        } else {
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth={false}
            classes={classes}
            TransitionComponent={Slide}
            scroll="body"
            onClose={_onClose}
        >
            {showX && (
                <XIcon
                    title="Exit"
                    style={{ position: 'absolute', right: '20px', top: '20px', cursor: 'pointer' }}
                    onClick={onClose}
                />
            )}
            {children}
        </Dialog>
    );
}

SmallDialog.propTypes = {
    open: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func,
    onEscapePress: PropTypes.func,
    showX: PropTypes.bool,
};

SmallDialog.defaultProps = {
    open: false,
    classes: {},
    onClose: () => {},
    onEscapePress: null,
    showX: true,
};

export default withStyles(styles)(SmallDialog);
