import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import commonCss from './CreativeAsset.css';
import ImageIcon from '../../../resources/icons/image.svg';
import { CreativeTypes } from '../../types';

const ImageAsset = React.forwardRef(({ type, url, onLoad, onError, showCreativeTypeIndication }, ref) => {
    return (
        <>
            {showCreativeTypeIndication &&
                (type === CreativeTypes.GIF ? (
                    <div className={commonCss.creativeType}>GIF</div>
                ) : (
                    <ImageIcon
                        title={CreativeTypes.IMAGE}
                        className={classNames(commonCss.creativeType, commonCss.image)}
                    />
                ))}
            <img ref={ref} src={url} alt={url} className={commonCss.creativeAsset} onLoad={onLoad} onError={onError} />
        </>
    );
});

ImageAsset.propTypes = {
    type: PropTypes.string,
    url: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    showCreativeTypeIndication: PropTypes.bool,
};

export default ImageAsset;
