import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import { getAdminModeEnabled } from '../selectors/user';
import { SEGMENT_STATUSES } from './consts';

export const getAudienceSegments = state => state.audienceSegments;
export const getEditInvalidMsg = state => state.audienceSegments.editInvalidMsg;
export const getSegments = state => state.audienceSegments.segments;
export const getSearch = state => state.audienceSegments.search;
export const getSort = state => state.audienceSegments.sort;
export const getMetaData = state => state.audienceSegments.metadata;
export const getEdit = state => state.audienceSegments.edit;
export const getEditId = state => state.audienceSegments.editId;
export const getDeleteId = state => state.audienceSegments.deleteId;
export const getTemplates = state => state.audienceSegments.templates;
export const getActiveSegmentsLimit = state => state.audienceSegments.activeSegmentsLimit;
export const getLoading = state => state.audienceSegments.loading;
export const getEditDirty = state => state.audienceSegments.editDirty;
export const getAdmin = state => state.audienceSegments.admin;
export const getAdminId = state => state.audienceSegments.adminId;
export const getSaving = state => state.audienceSegments.saving;
export const getSharingSegmentId = state => state.audienceSegments.sharingSegmentId;
export const getSharingLinks = state => state.audienceSegments.sharingLinks;

export const getSegmentValid = createSelector([getEditInvalidMsg], editInvalidMsg => {
    return !editInvalidMsg;
});

const getFilteredSegments = createSelector([getSegments, getSearch], (segments, search) => {
    let filteredSegments = segments ? [...segments] : [];
    if (search) {
        const q = search.toLowerCase();
        filteredSegments = filteredSegments.filter(s => s.name.toLowerCase().includes(q));
    }
    return filteredSegments;
});

const getActiveSegmentsCount = createSelector([getSegments], segments => {
    const activeSegments = segments || [];
    return activeSegments.filter(segment => segment.status && segment.status !== SEGMENT_STATUSES.INACTIVE).length;
});

const getSortedFilteredSegments = createSelector([getFilteredSegments, getSort], (segments, sort) => {
    const { col } = sort;
    if (sort.type === 'string') {
        segments.sort((a, b) => {
            if (!b[col]) return -1;
            if (!a[col]) return 1;
            return a[col].localeCompare(b[col]);
        });
    } else if (sort.type === 'number') {
        segments.sort((a, b) => {
            if (!b[col]) return -1;
            if (!a[col]) return 1;
            return a[col] - b[col];
        });
    }
    if (!sort.ascending) {
        segments.reverse();
    }
    return segments;
});

const parseAppEventKey = (key, longnameMapping) => {
    const [platform, longname] = key.split('/');
    const appName = longnameMapping && longnameMapping.name;
    const isProductionApp = longnameMapping && longnameMapping.is_production;

    return { platform, longname, appName, isProductionApp };
};

const getFormattedMetaData = createSelector([getMetaData, getEdit], (metadata, edit) => {
    if (!metadata || !edit) {
        return metadata;
    }

    const metadataCopy = { ...metadata };

    metadataCopy.appOptions = [];
    metadataCopy.eventOptions = [];
    metadataCopy.revenueOptions = [];

    const platformAppNameDuplicates = groupBy(Object.entries(metadataCopy.app_name_mapping), ([key, val]) => {
        const { platform, appName } = parseAppEventKey(key, val);
        return `${platform}/${appName}`;
    });

    const relevantAppKeys = edit.apps
        .map(app => app.value)
        .concat(
            Object.keys(metadataCopy.app_name_mapping).filter(key => metadataCopy.app_name_mapping[key].is_production)
        );
    [...new Set(relevantAppKeys)].forEach(appEventKey => {
        const { platform, longname, appName, isProductionApp } = parseAppEventKey(
            appEventKey,
            metadataCopy.app_name_mapping[appEventKey]
        );
        let appLabel = `(${platform}) ${appName || longname}`;
        const platformAppNameDuplicateKey = platformAppNameDuplicates[`${platform}/${appName}`];
        if (platformAppNameDuplicateKey && platformAppNameDuplicateKey.length > 1) {
            appLabel = `(${platform}) ${appName} [${longname}]`;
        }
        if (!isProductionApp || !appName) {
            appLabel += ' (deleted)';
        }
        metadataCopy.appOptions.push({ value: appEventKey, label: appLabel });

        if (edit.apps.map(app => app.value).includes(appEventKey)) {
            Object.keys(metadataCopy.app_event[appEventKey]).forEach(appEventValue => {
                const option = {
                    value: `${appEventKey}/${appEventValue}`,
                    label: `${appLabel} ${appEventValue}`,
                };
                metadataCopy.eventOptions.push(option);
                if (metadataCopy.app_event[appEventKey][appEventValue].revenue) {
                    metadataCopy.revenueOptions.push(option);
                }
            });
        }
    });

    metadataCopy.deviceOptions = [];
    for (const make in metadataCopy.device_make_model) {
        metadataCopy.device_make_model[make].forEach(model => {
            metadataCopy.deviceOptions.push({
                value: `${make}/${model}`,
                label: `(${make}) ${model}`,
            });
        });
    }
    metadataCopy.osOptions = [];
    for (const os in metadataCopy.os_version) {
        metadataCopy.os_version[os].forEach(version => {
            metadataCopy.osOptions.push({
                value: `${os}/${version}`,
                label: `(${os}) ${version}`,
            });
        });
    }
    metadataCopy.locationOptions = metadataCopy.countries.map(c => ({
        value: c.iso,
        label: c.printable_name,
    }));

    metadataCopy.citiesOptions = metadataCopy.cities.map(city => ({
        label: city.name,
        value: city.name,
    }));

    return metadataCopy;
});

export const getPage = createSelector(
    [
        getSortedFilteredSegments,
        getAdminModeEnabled,
        getSort,
        getTemplates,
        getActiveSegmentsLimit,
        getActiveSegmentsCount,
        getLoading,
        getSearch,
        getEditId,
        getDeleteId,
        getAdmin,
        getAdminId,
        getSaving,
    ],
    (
        filteredSegments,
        adminMode,
        sort,
        templates,
        activeSegmentsLimit,
        activeSegmentsCount,
        loading,
        search,
        editId,
        deleteId,
        admin,
        adminId,
        saving
    ) => {
        return {
            segments: filteredSegments,
            adminMode,
            sort,
            templates,
            activeSegmentsLimit,
            activeSegmentsCount,
            loading,
            search,
            editId,
            deleteId,
            admin,
            adminId,
            saving,
        };
    }
);

export const getEditShelf = createSelector(
    [getEdit, getSaving, getEditDirty, getFormattedMetaData, getEditInvalidMsg, getEditId],
    (edit, saving, editDirty, metadata, editInvalidMsg, editId) => {
        return {
            edit,
            saving,
            editDirty,
            metadata,
            editInvalidMsg,
            editId,
        };
    }
);

export const getSharingSegment = createSelector([getSharingSegmentId, getSegments], (id, segments) => {
    return id && segments && segments.find(seg => seg.id === id);
});

export const getSharingOptionsShelf = createSelector([getSharingSegment, getSharingLinks], (segment, links) => {
    return {
        segment,
        links,
    };
});
