import React, { useCallback, useState } from 'react';
import DataConnectorsAPI from './service';
import DataSourcesAPI from '../services/dataSources';
import DataSourcesService from './service';
import { useRequest } from '../utils/customHooks';

export const useFetch = (fetchFunction, ...args) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                setResponse(await fetchFunction(...args));
                setIsLoading(false);
            } catch (responseError) {
                setError(responseError);
            }
        };
        fetchData();
    }, args);
    return { ...response, error, isLoading };
};

export const useFetchAccountSourceMapping = adnId => {
    const dataConnectorsAPI = new DataConnectorsAPI();

    return useFetch(dataConnectorsAPI.getAccountSourceMapping, adnId);
};

export const useFetchAgencies = () => {
    const dataSourcesAPI = new DataSourcesAPI();

    return useFetch(dataSourcesAPI.getAgencies, false);
};

export const useFetchMonth = (uanId, monthYear, shouldFetchMonth, onCleanup) => {
    const dataSourcesService = new DataSourcesService();
    return useRequest(
        useCallback(dataSourcesService.getDataAvailability, [shouldFetchMonth]),
        () => {
            return shouldFetchMonth;
        },
        onCleanup,
        { uanId, monthYear }
    );
};
