import { JAVA, KOTLIN } from '../../consts';

const getJavaCodeSnippet = () => {
    return `// Example 1: Standard event, no attributes
Singular.event(Events.sngSubscribe.toString());

// Example 2: Standard event, with standard attributes
Singular.event(Events.sngTutorialComplete.toString(),
    Attributes.sngAttrContent.toString(), "Spanish",
    Attributes.sngAttrContentId.toString(), 52,
    Attributes.sngAttrContentType.toString(), "video",
    Attributes.sngAttrSuccess.toString(), 46
);

// Example 3: Standard event with the standard attributes in a JSONobject format.
JSONObject att = new JSONObject();
try {
    att.put(Attributes.sngAttrContent.toString(), "Telugu");
    att.put(Attributes.sngAttrContentId.toString(), 32);
    att.put(Attributes.sngAttrContentType.toString(), "video");
    att.put(Attributes.sngAttrSuccess.toString(), 92);
} catch (JSONException e) {
    e.printStackTrace(); // Or log the exception
}
Singular.eventJSON(Events.sngTutorialComplete.toString(), att);

// Example 4: Custom event, without attributes
Singular.event("SignUp");

// Example 5: Custom event, with a custom attribute
Singular.event("Bonus Points Earned", "Points", 500);

//Example 6: Custom event with custom attributes in a JSONobject format.
JSONObject att = new JSONObject();
try {
    att.put("Points", 500);
    att.put("score", 650);
} catch (JSONException e) {
    e.printStackTrace(); // Or log the exception
}
Singular.eventJSON("Bonus Points Earned", att);`;
};

const getAndroidAndEventsJavaSnippet = () => {
    return {
        markedCode: getJavaCodeSnippet(),
        codeLanguage: JAVA,
        placeholders: {},
    };
};

const getKotlinCodeSnippet = () => {
    return `// Example 1: Standard event, no attributes
Singular.event(Events.sngSubscribe.toString())

/// Example 2: Standard event, with standard attributes
Singular.event(Events.sngTutorialComplete.toString(),
    Attributes.sngAttrContent.toString(), "Telugu",
    Attributes.sngAttrContentId.toString(), "32",
    Attributes.sngAttrContentType.toString(), "video",
    Attributes.sngAttrSuccess.toString(), "yes"
)

// Example 3: Standard event with the standard attributes in a JSONobject format
val att = JSONObject().apply {
    put(Attributes.sngAttrContent.toString(), "Telugu")
    put(Attributes.sngAttrContentId.toString(), 32)
    put(Attributes.sngAttrContentType.toString(), "video")
    put(Attributes.sngAttrSuccess.toString(), 75)
}
Singular.eventJSON(Events.sngTutorialComplete.toString(), att)


//Example 4: Custom event, without attributes
Singular.event("SignUp")

// Example 5: Custom event, with a custom attribute
Singular.event("Bonus Points Earned", "Points", 500)

//Example 6: Custom event with custom attributes in a JSONobject format
val att = JSONObject().apply {
    put("Points", 500)
    put("score",650)
}
Singular.eventJSON("Bonus Points Earned", att)`;
};

const getAndroidAndEventsKotlinSnippet = () => {
    return {
        markedCode: getKotlinCodeSnippet(),
        codeLanguage: KOTLIN,
        placeholders: {},
    };
};

export { getAndroidAndEventsJavaSnippet, getAndroidAndEventsKotlinSnippet };
