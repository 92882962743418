import { connect } from 'react-redux';
import { getUserRegistrationFormPage } from 'teamManagement/selectors';
import { updateUserRegistrationForm } from 'teamManagement/actions';
import UserRegistrationForm from 'teamManagement/components/UserRegistrationForm';

const mapStateToProps = (state, ownProps) => {
    return getUserRegistrationFormPage(state);
};

const mapDispatchToProps = dispatch => {
    return {
        onFieldsUpdated: fields => {
            dispatch(updateUserRegistrationForm(fields));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserRegistrationForm);
