import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Shelf } from '../../components/widgets';
import Spinner from '../../components/widgets/Spinner';
import GoogleSheetShelf from './GoogleSheetShelf';
import DataDestinationsShelf from './DataDestinationShelf';
import { getNormalizedDestinationFields } from '../utils';
import { useAsync } from '../../utils/customHooks';
import DataSourcesService from '../service';

const DataSourcesAPI = new DataSourcesService();

const SCHEMA_FIELD_NAME = 'etl_source_config.preset';
const GOOGLE_SHEETS = 'google_sheets';

const getRelevantShelf = authType => {
    if (authType === GOOGLE_SHEETS) {
        return GoogleSheetShelf;
    } else {
        return DataDestinationsShelf;
    }
};

export default function DataDestinationsShelfEnv({ uan, adnetwork, onClose, networkData, translate, adminMode, ...shelf }) {
    const [dataDestinationResponse, run] = useAsync();
    const { data, isLoading } = dataDestinationResponse;
    const isEditMode = !!uan?.uan_id;
    const dataDestination = isEditMode ? data?.uans?.[uan.uan_id] : data?.adnetworks?.[adnetwork.name];
    const fields = getNormalizedDestinationFields(dataDestination, isEditMode);
    const schemaField = fields?.find(({ name }) => name === SCHEMA_FIELD_NAME);

    if (dataDestination && fields) {
        dataDestination.fields = fields;
    }

    const CurrentDestinationShelf = getRelevantShelf(networkData?.auth_type);
    const { display_name: displayName, logo_path: logo } = uan || adnetwork || {};

    useEffect(async () => {
        if (isEditMode) {
            // Fetch existing data destination fields
            await run(DataSourcesAPI.getUanData(uan.uan_id));
        } else {
            // Fetch adnetwork fields
            await run(DataSourcesAPI.getAdNetworks(true, adnetwork.id));
        }
    }, [uan, adnetwork, run]);

    return (
        <Shelf
            {...shelf}
            shelfSize="medium"
            headerText={`STATIC.PAGES.DATA_DESTINATIONS.SHELF.${isEditMode ? 'EDIT' : 'ADD'}_HEADER`}
            headerData={{ uan_name: displayName || '' }}
            headerImagePath={logo}
            closeOnClickOutside={false}
            onClose={onClose}
        >
            {isLoading && <Spinner show />}
            {!isLoading && (
                <CurrentDestinationShelf
                    schemaField={schemaField}
                    networkData={networkData}
                    adnetwork={adnetwork}
                    uan={uan}
                    isEditMode={isEditMode}
                    dataDestination={dataDestination}
                    translate={translate}
                    adminMode={adminMode}
                    onClose={onClose}
                />
            )}
        </Shelf>
    );
}

DataDestinationsShelfEnv.propTypes = {
    uan: PropTypes.shape({
        uan_id: PropTypes.number,
    }),
    adnetwork: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
    }),
    networkData: PropTypes.shape({
        auth_type: PropTypes.string,
    }),
    translate: PropTypes.func,
    adminMode: PropTypes.bool,
    onClose: PropTypes.func,
};

DataDestinationsShelfEnv.defaultProps = {
    uan: {},
    adnetwork: {},
    networkData: {},
    translate: () => '',
    adminMode: false,
    onClose: () => {},
};
