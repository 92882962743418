import { connect } from 'react-redux';
import { getPage } from './selectors';
import * as actions from './actions';
import LinkManagement from './page';

const mapDispatchToProps = dispatch => {
    return {
        onAppSelected: (...args) => {
            dispatch(actions.selectApp(...args));
        },
        onAppFilterChanged: (...args) => {
            dispatch(actions.changeAppsFilter(...args));
        },
        onExpandClick: (...args) => {
            dispatch(actions.partnerExpandClick(...args));
        },
        onEditClick: (linkId, readOnly, isLegacyLink) => {
            dispatch(actions.linkClicked(linkId, readOnly, isLegacyLink));
        },
        onDuplicateClicked: (linkId, readOnly) => {
            dispatch(actions.linkClicked(linkId, readOnly, false, true));
        },
        onArchiveClicked: (...args) => {
            dispatch(actions.archiveClicked(...args));
        },
        onCreateLinkClicked: () => {
            dispatch(actions.createLinkClicked());
        },
        onCloseShelf: (...args) => {
            dispatch(actions.closeShelf(...args));
        },
        onCloseSubDomainsShelf: (...args) => {
            dispatch(actions.closeSubDomainsShelf(...args));
        },
        onTopButtonClicked: (...args) => {
            dispatch(actions.topButtonClicked(...args));
        },
        onOldLinksFilterChanged: (...args) => {
            dispatch(actions.oldLinksToggle(...args));
        },
        onShowApiLinksFilterChanged: (...args) => {
            dispatch(actions.showApiLinksFilterChanged(...args));
        },
        onAgenciesLinksFilterChanged: (...args) => {
            dispatch(actions.agenciesLinksToggle(...args));
        },
        onEmptyStateAction: (actionName, ...args) => {
            if (!actions[actionName]) {
                return;
            }
            dispatch(actions[actionName](...args));
        },
    };
};

export default connect(state => getPage(state), mapDispatchToProps)(LinkManagement);
