import React from 'react';

export default function customEvents() {
    return (
        <div>
            <p>
                <strong>What: </strong><span>You can review events by looking at the user-level data (via the export logs) or define which events you want to see aggregated cohort data in your reports. Some events are added automatically; like retention and revenue but you can also configure additional events in the Events page.&nbsp;</span>
            </p>
            <p>
                <strong>Why: </strong><span> Once you’ve defined the events you’d like to analyze you can then optimize campaigns to whatever event you see fit. For example; analyze which campaigns brings in users that are finishing tutorials by Day 1 or make a first deposit.&nbsp;</span>
            </p>
            <p>
                <strong>Note: </strong><span> Data about post-install events will be cohort-based, meaning it will be aggregated based on the date in which the user installed the app. See </span>
                <a href="https://support.singular.net/hc/en-us/articles/205046069-Reports-FAQ#what_are_cohort_metrics_periods" target="_blank">What are Cohort Metrics?</a><span> for more information.&nbsp; </span>
            </p>
        </div>
    );
}
