import { connect } from 'react-redux';
import { getGoalsDropdown } from '../../selectors/reports';
import { Dropdown } from '../../components/widgets';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGoalsDropdown(ownProps.reportType)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...ownProps,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
