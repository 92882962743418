import { ANDROID_NATIVE, IOS_NATIVE, SDK_INTEGRATION_SECTIONS } from '../../consts';
import getSceneDelegateSwiftSnippet from '../iOS/sceneDelegate';
import { CODE_SNIPPETS } from '../consts';
import {
    getAndroidConfigureTheSDKJavaSnippet,
    getAndroidConfigureTheSDKKotlinSnippet,
} from '../android/AndroidConfigureTheSDK';
import getEventsSwiftSnippet from '../iOS/events';
import { getAndroidAndEventsJavaSnippet, getAndroidAndEventsKotlinSnippet } from '../android/eventsAndroid';
import {
    getAndroidRevenueWithoutPurchaseObjectJavaCodeSnippet,
    getAndroidRevenueWithoutPurchaseObjectKotlinCodeSnippet,
    getAndroidRevenueWithPurchaseObjectJavaCodeSnippet,
    getAndroidRevenueWithPurchaseObjectKotlinCodeSnippet,
} from '../android/revenueAndroid';
import { getIosSwiftRevenueInAppPurchaseSnippet, getIosSwiftRevenueNonInAppPurchaseSnippet } from '../iOS/revenue';
import {
    getSwiftAdMonAdMobSnippet,
    getSwiftAdMonApplovinSnippet,
    getSwiftAdMonOtherSnippet,
    getSwiftAdMonTradPlusSnippet,
    getSwiftAdMonUnitySnippet,
} from '../iOS/AdMonetiztion';
import {
    getJavaAdMonAdMobSnippet,
    getJavaAdMonApplovinSnippet,
    getJavaAdMonOtherSnippet,
    getJavaAdMonTradPlusSnippet,
    getJavaAdMonUnitySnippet,
} from '../android/adMonetizationJava';
import {
    getKotlinAdMonAdMobSnippet,
    getKotlinAdMonApplovinSnippet,
    getKotlinAdMonOtherSnippet,
    getKotlinAdMonTradPlusSnippet,
    getKotlinAdMonUnitySnippet,
} from '../android/adMonetizationKotlin';

const {
    ATT_PROMPT,
    SKAN_ENABLED,
    CUSTOM_USER_ID,
    GET_SDK_FILES,
    HANDLING_DEEPLINKS,
    IMPORT_LIBRARY,
    INITIALIZATION,
    USER_ACTIVITY,
    CREATE_A_CONFIGURATION_OBJECT,
    INITIATE_THE_CODE,
    CONFIGURE_THE_SDK,
    DEEP_LINKING,
    ANDROID_MANIFEST_SETTINGS,
    EVENTS,
    ADD_EVENTS,
    REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT,
    REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT,
    REVENUE_ANDROID_WITH_PURCHASE_RECEIPT,
    REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT,
} = SDK_INTEGRATION_SECTIONS;

const CODE_SNIPPETS_SECTIONS_SWITCHER = {
    [IOS_NATIVE]: {
        [CONFIGURE_THE_SDK]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [IMPORT_LIBRARY]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [CREATE_A_CONFIGURATION_OBJECT]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [ATT_PROMPT]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [SKAN_ENABLED]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [DEEP_LINKING]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [CUSTOM_USER_ID]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [INITIATE_THE_CODE]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [INITIALIZATION]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [USER_ACTIVITY]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [HANDLING_DEEPLINKS]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [EVENTS]: CODE_SNIPPETS.EVENTS_CODE_SNIPPET,
        [ADD_EVENTS]: CODE_SNIPPETS.EVENTS_CODE_SNIPPET,
        [REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT]: CODE_SNIPPETS.REVENUE_IN_APP_PURCHASE_CODE_SNIPPET,
        [REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT]: CODE_SNIPPETS.REVENUE_NON_IN_APP_PURCHASE_CODE_SNIPPET,
    },
    [ANDROID_NATIVE]: {
        [GET_SDK_FILES]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [ANDROID_MANIFEST_SETTINGS]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [CONFIGURE_THE_SDK]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [IMPORT_LIBRARY]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [CREATE_A_CONFIGURATION_OBJECT]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [DEEP_LINKING]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [CUSTOM_USER_ID]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [INITIATE_THE_CODE]: CODE_SNIPPETS.CONFIG_THE_SDK,
        [EVENTS]: CODE_SNIPPETS.EVENTS_CODE_SNIPPET,
        [ADD_EVENTS]: CODE_SNIPPETS.EVENTS_CODE_SNIPPET,
        [REVENUE_ANDROID_WITH_PURCHASE_RECEIPT]: CODE_SNIPPETS.REVENUE_WITH_PURCHASE_OBJECT_CODE_SNIPPET,
        [REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT]: CODE_SNIPPETS.REVENUE_WITHOUT_PURCHASE_OBJECT_CODE_SNIPPET,
    },
};
const CODE_SNIPPETS_GETTERS = {
    [IOS_NATIVE]: {
        [CODE_SNIPPETS.CONFIG_THE_SDK]: [getSceneDelegateSwiftSnippet],
        [CODE_SNIPPETS.EVENTS_CODE_SNIPPET]: [getEventsSwiftSnippet],
        [CODE_SNIPPETS.REVENUE_IN_APP_PURCHASE_CODE_SNIPPET]: [getIosSwiftRevenueInAppPurchaseSnippet],
        [CODE_SNIPPETS.REVENUE_NON_IN_APP_PURCHASE_CODE_SNIPPET]: [getIosSwiftRevenueNonInAppPurchaseSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_ADMOB]: [getSwiftAdMonAdMobSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN]: [getSwiftAdMonApplovinSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_UNITY]: [getSwiftAdMonUnitySnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS]: [getSwiftAdMonTradPlusSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_OTHER]: [getSwiftAdMonOtherSnippet],
    },
    [ANDROID_NATIVE]: {
        [CODE_SNIPPETS.CONFIG_THE_SDK]: [getAndroidConfigureTheSDKJavaSnippet, getAndroidConfigureTheSDKKotlinSnippet],
        [CODE_SNIPPETS.EVENTS_CODE_SNIPPET]: [getAndroidAndEventsJavaSnippet, getAndroidAndEventsKotlinSnippet],
        [CODE_SNIPPETS.REVENUE_WITH_PURCHASE_OBJECT_CODE_SNIPPET]: [
            getAndroidRevenueWithPurchaseObjectJavaCodeSnippet,
            getAndroidRevenueWithPurchaseObjectKotlinCodeSnippet,
        ],
        [CODE_SNIPPETS.REVENUE_WITHOUT_PURCHASE_OBJECT_CODE_SNIPPET]: [
            getAndroidRevenueWithoutPurchaseObjectJavaCodeSnippet,
            getAndroidRevenueWithoutPurchaseObjectKotlinCodeSnippet,
        ],
        [CODE_SNIPPETS.AD_MONETIZATION_ADMOB]: [getJavaAdMonAdMobSnippet, getKotlinAdMonAdMobSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN]: [getJavaAdMonApplovinSnippet, getKotlinAdMonApplovinSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_UNITY]: [getJavaAdMonUnitySnippet, getKotlinAdMonUnitySnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS]: [getJavaAdMonTradPlusSnippet, getKotlinAdMonTradPlusSnippet],
        [CODE_SNIPPETS.AD_MONETIZATION_OTHER]: [getJavaAdMonOtherSnippet, getKotlinAdMonOtherSnippet],
    },
};

export { CODE_SNIPPETS_SECTIONS_SWITCHER, CODE_SNIPPETS_GETTERS };
