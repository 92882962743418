import { call, put, take, fork } from 'redux-saga/effects';
import {
    EDIT_CAMPAIGN_NAME_CONFIRMED,
    DELETE_CAMPAIGN_CONFIRMED,
    editCampaignNameSuccess,
    editCampaignNameFailed,
    deleteCampaignSuccess,
    deleteCampaignFailed,
    fetchMetaDone,
    fetchMetaFailed,
} from '../../actions/trackingLinks';

import TrackingLinksAPI from '../../services/trackingLinks';

const api = new TrackingLinksAPI();

function* updateTrackingLinksData() {
    try {
        const response = yield call(api.getTrackingLinks, {});
        yield put(fetchMetaDone(response));
    } catch (error) {
        yield put(fetchMetaFailed(error));
    }
}

function* editCampaignName(action) {
    const { campaignID, campaignName, campaignData, appID } = action;
    try {
        const response = yield call(api.editCampaignName, campaignID, campaignName, campaignData, appID);
        yield put(editCampaignNameSuccess(campaignID, campaignName, response));
    } catch (error) {
        yield put(editCampaignNameFailed(error));
    }
}

function* deleteCampaign(action) {
    const { campaign } = action;
    try {
        const response = yield call(api.deleteCampaign, campaign);
        yield put(deleteCampaignSuccess(campaign, response));
    } catch (error) {
        yield put(deleteCampaignFailed(error));
    }
}

// ---------------------------------/
//      WATCHERS
// ---------------------------------/

function* onload() {
    yield call(updateTrackingLinksData);
}

function* watchEditCampaignName() {
    while (true) {
        const action = yield take(EDIT_CAMPAIGN_NAME_CONFIRMED);
        yield fork(editCampaignName, action);
    }
}

function* watchDeleteCampaign() {
    while (true) {
        const action = yield take(DELETE_CAMPAIGN_CONFIRMED);
        yield fork(deleteCampaign, action);
    }
}

const watchers = [watchEditCampaignName, watchDeleteCampaign];

export { onload, watchers };
