import React, { useState, useEffect, Fragment } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { shouldFetchAllFields, parseFieldsAvailabilityResponse } from '../utils';
import FieldsAvailabilityFilters from './fieldsAvailabilityFilters';
import css from '../styles.css';
import SourcesAvailability from './sourcesAvailability';
import DimensionsAvailability from './dimensionsAvailability';
import {
    AVAILABILITY_PARAMETER_OPTION__DIMENSION,
    AVAILABILITY_PARAMETER_OPTION__SOURCE,
    FIELDS_AVAILABILITY_MIXPANEL_PREFIX,
} from '../consts';
import { useFetchFieldsAvailability } from '../hooks';
import EmptyState from './emptyState';
import Spinner from '../../components/widgets/Spinner';
import { trackMixpanelEvent } from '../../utils/general';
import { GeneralPopup } from '../../components/partials';

const FieldsAvailability = ({ availabilityParameter, fetchAllFields, fields, adnetworkName, fieldName, onClose }) => {
    const [selectedAvailabilityParameter, setSelectedAvailabilityParameter] = useState(availabilityParameter);
    const [selectedAvailabilityParameterValue, setSelectedAvailabilityParameterValue] = useState(adnetworkName || fieldName);
    const [isLoadingFields, setIsLoadingFields] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const {
        sourcesOptions = [],
        dimensionsOptions = [],
        availabilityBySource = {},
        availabilityByDimension = {},
        sourcesDict = {},
        fieldsDict = {},
        isLoading: isLoadingFieldsAvailability,
        error,
    } = parseFieldsAvailabilityResponse(useFetchFieldsAvailability(), fields);
    const isLoading = isLoadingFields || isLoadingFieldsAvailability;
    const isEmptyState =
        !isLoading && !Object.keys(availabilityBySource).length && !Object.keys(availabilityByDimension).length;

    useEffect(() => {
        setShowErrorPopup(!!error);
    }, [error]);

    useEffect(() => {
        trackMixpanelEvent(FIELDS_AVAILABILITY_MIXPANEL_PREFIX, 'Shelf Opened', {
            filterType: selectedAvailabilityParameter,
            filterValue: selectedAvailabilityParameterValue,
        });
    }, []);

    useEffect(() => {
        if (shouldFetchAllFields(fields)) {
            setIsLoadingFields(true);
            fetchAllFields();
        }
        if (fields.length > 0) {
            setIsLoadingFields(false);
        }
    }, [fields]);

    const onFiltersChange = (_availabilityParameter, availabilityParameterValue) => {
        trackMixpanelEvent(FIELDS_AVAILABILITY_MIXPANEL_PREFIX, 'Filters Changed', {
            filterType: _availabilityParameter,
            filterValue: availabilityParameterValue,
        });

        setSelectedAvailabilityParameter(_availabilityParameter);
        setSelectedAvailabilityParameterValue(availabilityParameterValue);
    };

    return (
        <div className={css.container}>
            <div className={css.subHeader}>
                <Translate id="STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.SUBHEADER_TEXT"/>
            </div>
            {<Spinner show={isLoading} expanded />}
            {!isLoading && (
                <Fragment>
                    <div className={css.filters}>
                        <FieldsAvailabilityFilters
                            sourcesOptions={sourcesOptions}
                            dimensionsOptions={dimensionsOptions}
                            onChange={onFiltersChange}
                            availabilityParameter={selectedAvailabilityParameter}
                            availabilityParameterValue={selectedAvailabilityParameterValue}
                            disabled={isEmptyState}
                        />
                    </div>
                    <div className={css.body}>
                        {selectedAvailabilityParameter === AVAILABILITY_PARAMETER_OPTION__DIMENSION.name &&
                            availabilityBySource &&
                            selectedAvailabilityParameterValue &&
                            fieldsDict[selectedAvailabilityParameterValue] && (
                                <SourcesAvailability
                                    field={fieldsDict[selectedAvailabilityParameterValue]}
                                    availabilityBySource={availabilityBySource}
                                    sourcesDict={sourcesDict}
                                />
                            )}
                        {selectedAvailabilityParameter === AVAILABILITY_PARAMETER_OPTION__SOURCE.name &&
                            Object.keys(fieldsDict).length > 0 &&
                            availabilityByDimension &&
                            selectedAvailabilityParameterValue && (
                                <DimensionsAvailability
                                    availabilityByDimension={availabilityByDimension}
                                    source={selectedAvailabilityParameterValue}
                                    fieldsDict={fieldsDict}
                                />
                            )}
                    </div>
                    <GeneralPopup
                        open={showErrorPopup}
                        type="info"
                        headerStyle={{ fontSize: '18px', fontWeight: '600' }}
                        title="STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.ERROR_MODAL.TITLE"
                        text="STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.ERROR_MODAL.MESSAGE"
                        acceptText="STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.ERROR_MODAL.ACCEPT_BUTTON_TEXT"
                        onReject={() => setShowErrorPopup(false)}
                    />
                </Fragment>
            )}
            <EmptyState
                show={(isEmptyState || !selectedAvailabilityParameterValue) && !isLoading}
                emptyState={isEmptyState}
                onClose={onClose}
            />
        </div>
    );
};

FieldsAvailability.propTypes = {
    fetchAllFields: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.any),
    availabilityParameter: PropTypes.string,
    onClose: PropTypes.func,
};

FieldsAvailability.defaultProps = {
    fields: [],
    availabilityParameter: AVAILABILITY_PARAMETER_OPTION__SOURCE.name,
    onClose: () => {},
};

export default withLocalize(FieldsAvailability);
