import css from '../ConversionModelShelf.css';
import TableCellText from '../../../components/widgets/TableCellText';
import TableCellDropdown from '../../../components/widgets/TableCellDropdown';
import {
    COARSE_VALUE_HIGH,
    COARSE_VALUE_LOW,
    COARSE_VALUE_MEDIUM,
    COARSE_VALUE_UNUSED,
    COARSE_VALUES,
    COARSE_VALUES_WITH_UNUSED,
    ModelKinds,
    NO_EVENTS,
    NO_REVENUE,
    RevenueModelTypes,
    RevenueTypeTranslateNames,
    UNUSED,
    DECIMAL_PLACES,
    MAX_REVENUE_VALUE,
} from '../../consts';
import { DEFAULT_COARSE_P1_TOOLTIP_MODEL, parseCustomMapping } from '../../utils';
import ErrorTriangleIcon from '../../../resources/svg/icons/notification-warning.svg';
import WarningTriangleIcon from '../../../resources/svg/icons/warning.svg';
import TableCellNumericRange from '../../../components/widgets/TableCellNumericRange';
import { isObjectEmpty } from '../../../utils/general';

export const getConversionValueColumnDef = isMultiStep => ({
    displayName: `STATIC.PAGES.SKADNETWORK.${isMultiStep ? 'CONVERSION_VALUE' : 'BUCKET_NUMBER'}`,
    headerTooltip: isMultiStep ? 'STATIC.PAGES.SKADNETWORK.CONVERSION_BUCKET_COLUMN_TOOLTIP' : '',
    className: css.tableHeader,
    headerAlign: 'center',
    headerWidth: '220px',
    cellValues: [{ propName: 'value', dataKey: 'bucketNumber' }],
    cellComponent: TableCellText,
    cellProps: { className: css.tableHeader },
});

export const getEventsColumnDef = (isWideColumn, eventType) => ({
    displayName: 'STATIC.PAGES.SKADNETWORK.EVENTS',
    className: css.tableHeader,
    headerAlign: 'center',
    headerTooltip: DEFAULT_COARSE_P1_TOOLTIP_MODEL[eventType],
    cellValues: [{ propName: 'value', dataKey: 'events' }],
    cellComponent: TableCellText,
    cellProps: { className: isWideColumn ? css.eventsCellWide : css.eventsCell },
});

export const getCoarseValuesListSingleMapping = values => {
    const { customMapping } = values;
    const coarseValueUnused = COARSE_VALUE_UNUSED.toString();
    const selectedCoarseValues = new Set(Object.values(customMapping || {}));

    // Keep only valid coarse values
    if (selectedCoarseValues.has(coarseValueUnused)) {
        selectedCoarseValues.delete(coarseValueUnused);
    }

    // Filter already selected coarse values (if in edit mode and customMapping exists)
    return Object.keys(COARSE_VALUES_WITH_UNUSED)
        .filter(key => !selectedCoarseValues.has(key))
        .map(key => ({ name: key, display_name: COARSE_VALUES_WITH_UNUSED[key] }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const getCoarseValuesListMultiMapping = () => {
    return Object.keys(COARSE_VALUES).map(key => ({ name: key, display_name: COARSE_VALUES[key] }));
};

export const getCoarseValueColumnDef = (values, setValues, multiCoarseMapping, showRowActions, actionsConfig = {}) => ({
    displayName: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUE',
    className: css.tableHeader,
    headerAlign: 'center',
    cellComponent: TableCellDropdown,
    headerWidth: '180px',
    cellProps: {
        valuesList: multiCoarseMapping ? getCoarseValuesListMultiMapping() : getCoarseValuesListSingleMapping(values),
        dropdownContainerStyle: { width: '100px' },
        selectedContainerStyle: { padding: '3px 10px' },
        ...actionsConfig,
        onValueSelection: (value, id, rowIndex) => {
            const { customMapping } = values;
            const newCustomMapping = { ...customMapping };
            newCustomMapping[rowIndex] = value.name;
            setValues({ customMapping: newCustomMapping });
        },
        ErrorIcon: ErrorTriangleIcon,
        WarningIcon: WarningTriangleIcon,
        popperModifiers: {},
        showRowActions,
        tdStyle: {
            justifyContent: showRowActions ? 'left' : 'center',
            position: 'relative',
            zIndex: 'inherit',
            ...(showRowActions
                ? { display: 'flex', alignItems: 'center' }
                : { verticalAlign: 'middle', textAlign: 'center' }),
        },
    },
    cellValues: [
        'isEditable',
        { propName: 'selected', dataKey: 'coarseValue' },
        { propName: 'error', dataKey: 'coarseValueError' },
        { propName: 'warning', dataKey: 'coarseValueWarning' },
        { propName: 'disabled', dataKey: 'coarseValueDisabled' },
    ],
});

const getTotalRevenueColumnDef = (values, revenueType) => ({
    displayName: `STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.REVENUE_DISPLAY_NAME.${RevenueTypeTranslateNames[revenueType]}`,
    className: css.tableHeader,
    headerAlign: 'center',
    cellValues: ['from', 'to', 'isHidden', 'translationArgs', 'isEditable', 'index', 'error'],
    cellComponent: TableCellNumericRange,
    cellProps: {
        maxValue: MAX_REVENUE_VALUE,
        decimalPlaces: DECIMAL_PLACES,
        valueLabel: 'STATIC.PAGES.SKADNETWORK.REVENUE_VALUE_LABEL',
        getEmptyValueLabel: from => {
            return from === UNUSED ? UNUSED : NO_REVENUE;
        },
        isCellEmpty: (index, from, to) => {
            return (from === UNUSED) | (from === 0 && from === to);
        },
        classNames: [css.tableHeader, css.totalRevenue],
    },
});

export const getTableMetadata = (values, setValues, revenueType, eventsType, multiCoarseMapping) => {
    const metadata = [
        getConversionValueColumnDef(true),
        getEventsColumnDef(!revenueType, eventsType),
        getCoarseValueColumnDef(values, setValues, multiCoarseMapping, false),
    ];

    if (revenueType) {
        metadata.splice(2, 0, getTotalRevenueColumnDef(values, revenueType));
    }

    return metadata;
};

const parseEventNames = eventNames => {
    return eventNames.join(' \n');
};

const getListChunks = (list, size) =>
    Array.from(new Array(Math.ceil(list.length / size)), (_, i) => list.slice(i * size, i * size + size));

const getLowChunk = decodingTable => {
    const lowChunk = [];
    const decodingTableKeys = Object.keys(decodingTable);

    decodingTableKeys.forEach(cv => {
        const cvDecodingData = decodingTable[cv];
        cvDecodingData.forEach(data => {
            const { value, eventType } = data;

            if (Object.values(RevenueModelTypes).includes(eventType)) {
                const [from, to] = value;
                if (to === undefined && !(from > 0)) {
                    lowChunk.push(cv);
                }
            }
        });
    });

    return lowChunk.length ? lowChunk : [...Array(Math.ceil(decodingTableKeys.length / 3)).keys()];
};

export const calculateCustomMapping = (decodingTable, currentCustomMapping, multiCoarseMapping) => {
    let newCustomMapping = {};

    if (!multiCoarseMapping) {
        Object.keys(decodingTable).forEach(cv => {
            newCustomMapping[cv] = COARSE_VALUE_UNUSED.toString();
        });

        if (!isObjectEmpty(currentCustomMapping)) {
            return Object.assign({}, newCustomMapping, currentCustomMapping);
        }

        newCustomMapping[0] = COARSE_VALUE_LOW.toString();
        return newCustomMapping;
    }

    const mediumAndHighMapping = {
        [COARSE_VALUE_MEDIUM]: [],
        [COARSE_VALUE_HIGH]: [],
    };

    const lowChunks = getLowChunk(decodingTable);

    const lowAmount = lowChunks.length;
    const cvAmount = Object.keys(decodingTable).length;

    const elementsPerCoarse = Math.ceil((cvAmount - lowAmount) / Object.keys(mediumAndHighMapping).length);

    const cvGroups = getListChunks(
        [...Array(cvAmount - lowAmount).keys()].map(x => (x + lowAmount).toString()),
        elementsPerCoarse
    );

    Object.keys(mediumAndHighMapping).forEach((coarseValue, index) => {
        mediumAndHighMapping[coarseValue] = cvGroups[index] || [];
    });

    const customMapping = {
        [COARSE_VALUE_LOW]: lowChunks,
        ...mediumAndHighMapping,
    };

    newCustomMapping = parseCustomMapping(customMapping);

    return Object.assign({}, newCustomMapping, currentCustomMapping);
};

function shouldDisableCoarseValue(includesRevenue, parsedEventNames, multiCoarseMapping, from, to) {
    if (!multiCoarseMapping) {
        return false;
    }

    if (parsedEventNames === UNUSED) {
        return true;
    }

    if (includesRevenue) {
        return !parsedEventNames && from === 0 && to === 0;
    }

    return !parsedEventNames;
}

function getCoarseValue(unused, customMappingValue) {
    return !unused
        ? { name: customMappingValue, display_name: COARSE_VALUES_WITH_UNUSED[customMappingValue] }
        : { name: UNUSED, display_name: UNUSED };
}

export const getTableData = (decodingTable, customMapping, currency, multiCoarseMapping) => {
    const dataRecords = [];

    Object.keys(decodingTable).forEach(cv => {
        const cvDecodingData = decodingTable[cv];
        const eventNames = [];
        let from;
        let to;

        let includesRevenue = false;
        let unused = false;

        cvDecodingData.forEach(data => {
            const { value, eventType, event } = data;
            switch (eventType) {
                case RevenueModelTypes.IAP:
                case RevenueModelTypes.ADMON:
                case RevenueModelTypes.ALL:
                    includesRevenue = true;
                    [from, to] = value;

                    if (to === undefined) {
                        to = from > 0 ? Infinity : 0;
                    }
                    break;
                case ModelKinds.CONVERSION_EVENTS:
                    if (parseInt(value, 10) > 0) {
                        eventNames.push(event);
                    }
                    break;
                case ModelKinds.ENGAGEMENT:
                    if (parseInt(value, 10) > 0) {
                        eventNames.push(`${event} (${value})`);
                    }
                    break;
                case ModelKinds.FUNNEL:
                    eventNames.push(value);
                    break;
                case UNUSED:
                    unused = true;
                    break;
                default:
                    console.error(`The event type ${eventType} in the decoding table is invalid`);
            }
        });

        const parsedEventNames = parseEventNames(eventNames);
        const customMappingValue = customMapping[cv];
        const coarseValue = getCoarseValue(unused, customMappingValue);

        dataRecords.push({
            index: cv,
            bucketNumber: cv,
            events: !unused ? parsedEventNames || NO_EVENTS : UNUSED,
            from: !unused ? from : UNUSED,
            to,
            coarseValue,
            coarseValueDisabled: shouldDisableCoarseValue(
                includesRevenue,
                parsedEventNames,
                multiCoarseMapping,
                from,
                to
            ),
            translationArgs: {
                currency,
            },
            isEditable: false,
        });
    });

    return dataRecords;
};
