import React from 'react';
import UserConfigurationAppSitesSummary, { USER_CONFIGURATION_PARTNER_NAMES } from './UserConfigurationAppSitesSummary';
import { partnerShape } from '../types';
import BasePartnerAppSitesSummary from './BasePartnerAppSitesSummary';

export default function PartnerAppSitesSummaryFactory(props) {
    const { partner } = props;

    if (USER_CONFIGURATION_PARTNER_NAMES.includes(partner.name)) {
        return <UserConfigurationAppSitesSummary {...props} />;
    } else {
        return <BasePartnerAppSitesSummary {...props} />;
    }
}

PartnerAppSitesSummaryFactory.propTypes = {
    partner: partnerShape.isRequired,
};
