export const DA_MONTHS_BACK = 12;
export const DA_DATE_FORMAT_OPTIONS = { month: 'long', year: 'numeric' };
export const DA_DATE_TIME_FORMAT = 'MMM D, YYYY HH:mm A';
export const DA_DATE_FORMAT = 'MMM D, YYYY';
export const DA_EMPTY_LAST_UPDATE = '-';

export const ETL_UPGRADE_REQUESTED = 'singular-etl-upgrade-requested';

export const UPGRADE_BANNER_STEPS = {
    0: {
        label: `STATIC.BANNERS.ETL_UPGRADE.PRE_REQUEST_HEADER`,
        subLabel: `STATIC.BANNERS.ETL_UPGRADE.PRE_REQUEST_SUBHEADER`,
    },
    1: {
        label: `STATIC.BANNERS.ETL_UPGRADE.POST_REQUEST_HEADER`,
        subLabel: `STATIC.BANNERS.ETL_UPGRADE.POST_REQUEST_SUBHEADER`,
        showButtonV: true,
    },
};
export const UPGRADE_BANNER_MIXPANEL_EVENT = {
    prefix: 'Data Destinations',
    name: 'click upgrade',
};
