import { connect } from 'react-redux';
import GenericDimensionSelection from '../components/GenericDimensionSelection';
import { getGenericPermissionDimensionPage } from '../selectors';
import { setDimensionFilter, addFutureChanged } from '../actions';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        ...getGenericPermissionDimensionPage(ownProps.dimension)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setDimensionFilterValues: values => dispatch(setDimensionFilter(ownProps.dimension, values)),
        enableAddFuture: () => dispatch(addFutureChanged(ownProps.dimension, true, true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GenericDimensionSelection);
