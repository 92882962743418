export class FieldItem {
    constructor({
        label,
        items = [],
        visible = false,
        disabled = false,
        updateOnChange = false,
        visibilityRules = [],
        selector = null,
        validations = null,
        disabledRule = null,
        extra = null,
        labelTooltip = null,
        labelTooltipStyle = null,
        resetField = false,
        mandatory = false,
    }) {
        this._label = label;
        this._useItems = items;
        this._visible = visible;
        this._updateOnChange = updateOnChange;
        this._component = '';
        this._componentData = {};
        this._visibilityRules = visibilityRules;
        this._selector = selector;
        this._selectedItems = null;
        this._resetField = resetField;
        this._validations = validations;
        this._disabled = disabled;
        this._disabledRule = disabledRule;
        this._extra = extra;
        this._labelTooltip = labelTooltip;
        this._labelTooltipStyle = labelTooltipStyle || { textAlign: 'left' };
        this._mandatory = mandatory;
    }

    get items() {
        if (this._selector && this._selectedItems) {
            return this._selectedItems;
        }
        return this._useItems;
    }

    set componentData(data) {
        this._componentData = data;
    }

    get componentData() {
        return this._componentData;
    }

    get visibilityRules() {
        return this._visibilityRules;
    }

    set component(name) {
        this._component = name;
    }

    get component() {
        return this._component;
    }

    get visible() {
        return this._visible;
    }

    set selectedItems(items) {
        this._selectedItems = items;
    }

    get selector() {
        return this._selector;
    }

    get validations() {
        return this._validations;
    }

    set disabled(value) {
        this._disabled = value;
    }

    get disabled() {
        let ret = this._disabled;
        if (this._disabledRule) {
            ret = ret || this._disabledRule.call(null, ...(this._extra || [{}, {}]));
        }
        return ret;
    }

    set extra(value) {
        this._extra = value;
    }

    set labelTooltip(value) {
        this._labelTooltip = value;
    }

    set label(value) {
        this._label = value;
    }

    get json() {
        return {
            component: this._component,
            componentData: {
                label: this._label,
                items: this.items,
                onChange: () => {},
                ...this.componentData,
                disabled: this.disabled,
                labelTooltip: this._labelTooltip,
                labelTooltipStyle: this._labelTooltipStyle,
                mandatory: this._mandatory,
            },
            visible: this._visible,
            visibilityRules: this._visibilityRules,
            updateOnChange: this._updateOnChange,
            resetField: this._resetField,
        };
    }
}

export class DropdownItem extends FieldItem {
    constructor(args) {
        super(args);
        this.component = 'DropdownField';
        this._containerStyle = args.containerStyle || {};
        this._fieldContainerStyle = args.fieldContainerStyle || {};
        this._wrapperStyle = args.wrapperStyle || {};
    }

    get componentData() {
        return {
            containerStyle: this._containerStyle,
            wrapperStyle: this._wrapperStyle,
            fieldContainerStyle: this._fieldContainerStyle,
        };
    }
}

export class AutoCompleteItem extends FieldItem {
    constructor(args) {
        super(args);
        this._searchable = args.searchable;
        this._clearable = !!args.clearable;
        this._copiable = !!args.copiable;
        this._placeholder = args.placeholder || 'STATIC.PAGES.MANAGE_LINKS.TEXTFIELD_PLACEHOLDER';
        this._placeholderData = args.placeholderData || {};
        this._debounceTime = args.debounceTime || 300;
        this.component = 'AutoCompleteField';
    }

    set placeholder(value) {
        this._placeholder = value;
    }

    set placeholderData(value) {
        this._placeholderData = value;
    }

    get componentData() {
        return {
            options: this.items || [],
            items: undefined,
            isMulti: false,
            placeholder: this._placeholder,
            placeholderData: this._placeholderData,
            containerStyle: {
                marginBottom: '20px',
            },
            searchable: this._searchable,
            controlled: true,
            selectOptions: {
                isCopiable: this._copiable,
                isClearable: this._clearable,
            },
            debounceTime: this._debounceTime,
        };
    }
}

export class SeparatorItem extends FieldItem {
    constructor(args) {
        super(args);
        this.component = 'Separator';
    }
}

export class OSLabelItem extends FieldItem {
    constructor(args) {
        super(args);
        this.component = 'OSLabel';
        this._icon = args.icon;
        this._info = args.info || '';
        this._hideCheckbox = args.hideCheckbox || false;
    }

    get componentData() {
        return {
            icon: this._icon,
            hideCheckbox: this._hideCheckbox,
        };
    }
}

export class LegacyItem extends FieldItem {
    constructor(args) {
        super(args);
        this.component = 'Legacy';
        this._label = args.label || 'STATIC.PAGES.MANAGE_LINKS.LEGACY_LINKS_TEXT';
        this._subLabel = args.subLabel || '';
        this._showPartnerButton = 'showPartnerButton' in args ? args.showPartnerButton : true;
        this._showCustomButton = 'showCustomButton' in args ? args.showCustomButton : true;
    }

    set label(value) {
        this._label = value;
    }

    get label() {
        return this._label;
    }

    set subLabel(value) {
        this._subLabel = value;
    }

    get subLabel() {
        return this._subLabel;
    }

    get componentData() {
        return {
            label: this._label,
            subLabel: this._subLabel,
            showPartnerButton: this._showPartnerButton,
            showCustomButton: this._showCustomButton,
        };
    }
}

export class LinkParamsItem extends FieldItem {
    constructor(args) {
        super(args);
        this.component = 'LinkParams';
    }

    get componentData() {
        return {
            fields: this.items,
        };
    }
}

export class ToggleItem extends FieldItem {
    constructor(args) {
        super(args);
        this._infoMessage = args.infoMessage || '';
        this.component = 'Toggle';
    }

    set infoMessage(message) {
        this._infoMessage = message;
    }

    get componentData() {
        return {
            labelStyle: {
                fontWeight: 400,
                fontSize: '14px',
                margin: '4px 0 0 2px',
            },
            infoMessage: this._infoMessage,
        };
    }
}

export class SliderItem extends FieldItem {
    constructor(args) {
        super(args);
        this._min = args.min || 0;
        this._max = args.max || 30;
        this._extraSteps = args.extraSteps || [7];
        this._color = args.color || '#3088f4';
        this._stepsPrefix = args.stepsPrefix || 'day';
        this._normalizeValue = args.normalizeValue || 24 * 60 * 60;
        this._stepsOverride = this._min === 0 ? { 0: '0 (Off)' } : {};
        this._overrides = null;
        this.component = 'Slider';
    }

    get normalizeValue() {
        return this._normalizeValue;
    }

    get max() {
        return this._max;
    }

    get extraSteps() {
        return this._extraSteps;
    }

    set overrides(val) {
        this._overrides = val;
    }

    _getOverrideVal(prop) {
        return (this._overrides && this._overrides[prop]) || this[prop];
    }

    get componentData() {
        return {
            min: this._min,
            max: this._getOverrideVal('max'),
            extraSteps: this._getOverrideVal('extraSteps'),
            color: this._color,
            stepsPrefix: this._stepsPrefix,
            stepsOverride: this._stepsOverride,
        };
    }
}

export class CopyItem extends FieldItem {
    constructor(args) {
        super(args);
        this._containerStyle = args.containerStyle || {};
        this._type = args.type || 'large';
        this._label = args.label || '';
        this._copyText = args.copyText || 'Copy link';
        this._copiedText = args.copiedText || 'Link Copied';
        this._content = args.content || '';
        this.component = 'CopyArea';
    }

    get componentData() {
        return {
            containerStyle: this._containerStyle,
            type: this._type,
            label: this._label,
            copyText: this._copyText,
            copiedText: this._copiedText,
            content: this._content,
        };
    }
}

export class CopyWithQRCodeItem extends FieldItem {
    constructor(args) {
        super(args);
        this._containerStyle = args.containerStyle || {};
        this._type = args.type || 'large';
        this._label = args.label || '';
        this._copyText = args.copyText || 'Copy link';
        this._copiedText = args.copiedText || 'Link Copied';
        this._content = args.content || '';
        this._qrCodeLabel = args.qrCodeLabel || 'QR Code';
        this._qrCodeDownloadPNG = args.qrCodeDownloadPNG;
        this._qrCodeDownloadSVG = args.qrCodeDownloadSVG;
        this.component = 'CopyAreaWithQRCode';
    }

    get componentData() {
        return {
            containerStyle: this._containerStyle,
            type: this._type,
            label: this._label,
            copyText: this._copyText,
            copiedText: this._copiedText,
            content: this._content,
            qrCodeLabel: this._qrCodeLabel,
            qrCodeDownloadPNG: this._qrCodeDownloadPNG,
            qrCodeDownloadSVG: this._qrCodeDownloadSVG,
            qrCodeSize: this._qrCodeSize,
        };
    }
}

export class QRCodeItem extends FieldItem {
    constructor(args) {
        super(args);
        this._label = args.label || '';
        this._download = args.download || '';
        this._value = args.value || 'no-link-provided';
        this.component = 'QRCodeWidget';
    }

    get componentData() {
        return {
            label: this._label,
            download: this._download,
            value: this._value,
            size: this._size,
        };
    }
}

export class WarningItem extends FieldItem {
    constructor(args) {
        super(args);
        this._type = args.type || 'error';
        this._message = args.message || '';
        this.component = 'WarningMessage';
    }

    get componentData() {
        return {
            type: this._type,
            showIcon: false,
            containerStyle: {
                marginBottom: '20px',
            },
            buttonAction: () => {
                const win = window.open('', '_blank');
                setTimeout(() => {
                    win.location.href = `${window.location.origin}/${window.location.search}#/partner-config`;
                });
            },
            message: this._message,
            ...this._selectedItems,
        };
    }
}

export class LineLoaderItem extends FieldItem {
    constructor(args) {
        super(args);
        this.component = 'LineLoader';
    }
}

export class LabelItem extends FieldItem {
    constructor(args) {
        super(args);
        this._type = args.type || 'shelfHeader';
        this.component = 'Label';
    }

    get componentData() {
        return {
            type: this._type,
            text: this._label,
        };
    }
}
