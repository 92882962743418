import React from 'react';
import ShelfGroup from 'components/widgets/ShelfGroup';
import css from 'alerts/components/CustomAlertsShelf.css';
import { Translate } from 'react-localize-redux';
import Dropdown from 'components/widgets/Dropdown';
import { NEW_FILTER } from 'alerts/consts';
import TagInput from 'components/widgets/TagInput';
import XIcon from 'resources/svg/icon_remove_row.svg';
import PlusIcon from 'resources/svg/plus.svg';
import PropTypes from 'prop-types';

function getSuggestions(filter, dimensions) {
    if (Object.keys(filter).length === 0) {
        return null;
    }

    const dimension = dimensions.filter(dim => dim.name === filter.name)[0];

    if (!dimension || !dimension.values) {
        return null;
    }

    return dimension.values.map(dim => dim.display_name);
}

function FilterGroup({
    translate,
    dimensions,
    filterOperators,
    selectedFilters,
    validatedFiltersCount,
    onFiltersChanged,
}) {
    const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        if (selectOptions.length === 0) {
            return true;
        }

        return selectOptions.map(dimension => dimension.display_name).includes(inputValue);
    };

    const onDimensionChanged = (dimensionInfo, index) => {
        const newFilter = { ...dimensionInfo };
        newFilter.tags = [];
        newFilter.operator = selectedFilters[index].operator || filterOperators[0];

        const newFilters = [...selectedFilters];
        newFilters[index] = newFilter;
        onFiltersChanged(newFilters);
    };

    const onFilterOperatorsChanged = (operatorInfo, index) => {
        const newFilters = [...selectedFilters];
        newFilters[index].operator = operatorInfo;
        onFiltersChanged(newFilters);
    };

    const dimensionItems =
        selectedFilters.length > 0
            ? dimensions.filter(dimension => !selectedFilters.map(_filter => _filter.id).includes(dimension.id))
            : dimensions;

    const onTagAdded = (tag, index) => {
        const { values } = dimensions.filter(dimension => dimension.name === selectedFilters[index].name)[0];
        const tagInfo = values
            ? values.filter(dimension => dimension.display_name === tag)[0]
            : { name: tag, display_name: tag };

        const newFilters = [...selectedFilters];
        newFilters[index].tags = selectedFilters[index].tags ? [...selectedFilters[index].tags, tagInfo] : [tagInfo];
        onFiltersChanged(newFilters);
    };
    const onTagRemoved = (tag, index) => {
        const newFilters = [...selectedFilters];
        newFilters[index].tags = newFilters[index].tags.filter(dimension => dimension !== tag && dimension.display_name !== tag);
        onFiltersChanged(newFilters);
    };

    const onCreateTag = tag => {
        if (!tag) {
            return 'Enter value';
        }
        return tag;
    };

    const onRemoveFilter = indexToRemove => {
        let newFilters = [...selectedFilters];
        if (indexToRemove === 0) {
            newFilters[indexToRemove] = {};
        } else {
            newFilters = newFilters.filter((value, index) => indexToRemove !== index);
        }
        onFiltersChanged(newFilters);
    };
    const onAddFilter = () => {
        const newFilters = [...selectedFilters, {}];
        onFiltersChanged(newFilters);
    };

    return (
        <ShelfGroup
            className={css.shelfGroup}
            contentContainer={css.shelfGroupContent}
            label={`${translate(
                translate('STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.FILTER')
            )} (${validatedFiltersCount})`}
            labelContainer={css.shelfGroupLabelContainer}
        >
            <div className={css.sectionHeader}>
                <div className={css.shelfGroupInfo}>
                    <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.FILTER_INFO" />
                </div>
            </div>

            {selectedFilters.map((filter, index) => {
                return (
                    <div key={index} className={index > 0 ? css.filterSpacing : ''}>
                        <Dropdown
                            key={`dimension_${index}`}
                            items={dimensionItems}
                            wrapperStyle={{ width: '570px' }}
                            containerStyle={{ width: '100%' }}
                            selected={Object.keys(filter).length > 0 ? filter : NEW_FILTER.dimension}
                            selectedContainer={css.dropdown}
                            onSelection={dimensionInfo => onDimensionChanged(dimensionInfo, index)}
                        />
                        <div key={index} className={css.filter}>
                            <Dropdown
                                key={`operator_${index}`}
                                items={filterOperators}
                                wrapperStyle={{ marginRight: '20px' }}
                                selected={filter.operator}
                                onSelection={operatorInfo => onFilterOperatorsChanged(operatorInfo, index)}
                                disabled={!filter.name}
                            />
                            <TagInput
                                key={`values_${index}`}
                                tags={
                                    selectedFilters[index].tags
                                        ? selectedFilters[index].tags.map((dimension) =>
                                              typeof dimension === 'string'
                                                  ? {
                                                        label: dimension,
                                                        value: dimension,
                                                    }
                                                  : {
                                                        label: dimension.display_name,
                                                        value: dimension.display_name,
                                                        invalid: dimension.invalid,
                                                    }
                                          )
                                        : []
                                }
                                containerStyle={{ marginRight: '5px' }}
                                creatable
                                disabled={!Object.keys(filter).length > 0}
                                suggestions={getSuggestions(filter, dimensions)}
                                onTagAdded={tag => onTagAdded(tag, index)}
                                onTagRemoved={tag => onTagRemoved(tag, index)}
                                isValidNewOption={isValidNewOption}
                                placeholder="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.SELECT_VALUES"
                                virtualScrolling
                                formatCreateLabel={onCreateTag}
                            />
                            <div
                                className={css.clearIcon}
                                style={{ marginLeft: '8px', marginRight: '8px' }}
                                onClick={() => onRemoveFilter(index)}
                            >
                                <XIcon style={{ width: '10px' }} />
                            </div>
                            <div
                                className={css.plusIcon}
                                style={{ marginRight: '-15px' }}
                                onClick={() => onAddFilter(index)}
                            >
                                <PlusIcon style={{ width: '14px' }} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </ShelfGroup>
    );
}

FilterGroup.propTypes = {
    translate: PropTypes.func,
    dimensions: PropTypes.arrayOf(PropTypes.object),
    filterOperators: PropTypes.arrayOf(PropTypes.object),
    selectedFilters: PropTypes.arrayOf(PropTypes.object),
    validatedFiltersCount: PropTypes.number,
    onFiltersChanged: PropTypes.func,
};

FilterGroup.defaultProps = {
    translate: () => {},
    dimensions: [],
    filterOperators: [],
    selectedFilters: [],
    validatedFiltersCount: 0,
    onFiltersChanged: () => {},
};

export default FilterGroup;
