import React from 'react';

export default function manageLinks() {
    return (
        <div>
            <p>
                <strong>What: </strong><span> Create tracking links that you can embed in your campaigns.</span>
            </p>
            <p>
                <strong>Why: </strong><span> Tracking links let the Singular tracker know when a user clicks on one of your ads (or views an ad, if it’s an impression-based campaign). We use this information to attribute mobile app installs and re-engagements. Links can include additional parameters, such as the user’s device platform or the ad creative asset, which can be leveraged in your reporting.</span>
            </p>
            <p>
                <span>Singular lets you customize your tracking links by creating your own sub-domain, e.g. “myapp.sng.link”.</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/360030934212-Singular-Links-FAQ" target="_blank">See our Singular Links FAQ</a><span>.</span>
            </p>
        </div>
    );
}
