import API from './api';

const api = new API();
let instance = null;

class Events {
    constructor() {
        if (!instance) {
            this.types = {
                mp: this._logMixpanelEvent,
            };
            instance = this;
        }
        return instance;
    }

    /*
        allowed input:
        1. (event, param1, param2, param3) => will log only singular event
        2. ({<event_type> : arguments, ..}) => will log all event types
    */
    write(...args) {
        let newArgs = args[0];
        // in case the first argument is string = 'event_name' call all types with the same arguments.
        if (typeof args[0] === 'string') {
            newArgs = {};
            Object.keys(this.types).forEach(type => {
                newArgs[type] = args;
            });
        }
        for (const type in newArgs) {
            if (!this.types[type]) {
                continue;
            }
            this.types[type].apply(this, newArgs[type]);
        }
    }

    _logMixpanelEvent(event, params) {
        if (typeof mixpanel !== 'object') {
            return;
        }
        window.mixpanel.track(event, params);
    }

    getUanEvents() {
        return new Promise((resolve, reject) => {
            api.getJson('/logs/get_events', {
                method: 'GET',
                credentials: 'include',
                params: {},
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default Events;
