import API from '../services/api';

let instance = null;
const api = new API();

const PAYMENT_SESSION_URL = 'api/payments/sessions';

export default class CheckoutAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    startPaymentSession(priceId, quantity) {
        return api.getJson(PAYMENT_SESSION_URL, {
            method: 'POST',
            body: JSON.stringify({
                priceId,
                credentials: 'include',
                quantity,
            }),
        });
    }

    getSessionStatus(sessionId) {
        return api.getJson(`${PAYMENT_SESSION_URL}/${sessionId}`, {
            method: 'GET',
            credentials: 'include',
        });
    }

    onPaymentComplete(sessionId) {
        return api.getJson(`${PAYMENT_SESSION_URL}/${sessionId}/complete`, {
            method: 'PUT',
            credentials: 'include',
        });
    }
}
