import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import * as Yup from 'yup';
import css from './PartnerParameters.css';
import shelfCss from './PartnerConfigurationShelf.css';
import { partnerShape, ParameterTypes, TemplateTypes, ParameterDisplayTypes } from '../types';
import { TextField, Checkbox } from '../../../components/widgets';
import { DropdownFieldEnhanced } from '../../../utils/components/formUtils';
import { getTemplate, getUsedParameters } from './utils';

export const getParametersValidationSchema = parameters =>
    parameters.reduce(
        (total, { name }) => ({
            ...total,
            [name]: Yup.string()
                .nullable()
                .required('STATIC.REQUIRED_FIELD'),
        }),
        {}
    );

function PartnerParameters({ partner, platform, isReadonly }) {
    const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext();

    const parameters = getUsedParameters(
        partner,
        platform,
        getTemplate(partner, platform, TemplateTypes.INSTALL) ? TemplateTypes.INSTALL : TemplateTypes.CUSTOM,
        [ParameterTypes.ACCOUNT, ParameterTypes.APPLICATION]
    ).sort((a, b) => {
        return (a.displayIndex ?? Infinity) - (b.displayIndex ?? Infinity);
    });

    useEffect(() => {
        parameters.forEach(param => {
            if (!Object.keys(values).includes(param.name)) {
                setFieldValue(param.name, '');
            }

            if (param.displayType === ParameterDisplayTypes.CHECKBOX && values[param.name] === '') {
                setFieldValue(param.name, param.predefinedValues?.[0] === 'True' ? 'True' : 'False');
            }
        });
    }, [parameters]);

    if (!parameters.length) {
        return null;
    }

    const onTextFieldChange = (name, value) => {
        setFieldValue(name, value);
        setFieldTouched(name, true);
    };

    return (
        <>
            <div className={shelfCss.title}>
                <Translate
                    id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.PARTNER_PARAMETERS"
                    data={{ partnerName: partner.displayName }}
                />
            </div>
            {parameters.map(({ name, description, label, predefinedValues, displayType }) => {
                const props = {
                    key: name,
                    name,
                    label,
                    labelTooltip: description,
                    error: touched[name] && errors[name],
                    disabled: isReadonly,
                };

                switch (displayType) {
                    case ParameterDisplayTypes.DROPDOWN:
                        return (
                            <DropdownFieldEnhanced
                                {...props}
                                placeholder="STATIC.SELECT"
                                containerClass={css.parameterField}
                                items={predefinedValues?.map(value => ({ name: value, display_name: value }))}
                                selected={values[name]}
                            />
                        );
                    case ParameterDisplayTypes.CHECKBOX:
                        return (
                            <Checkbox
                                {...props}
                                checked={values[name] === 'True'}
                                onChange={checked => setFieldValue(name, checked ? 'True' : 'False')}
                                labelClass={css.checkboxLabelClass}
                            />
                        );
                    default:
                        return (
                            <TextField
                                {...props}
                                placeholder="STATIC.TYPE_IN"
                                containerClass={css.parameterField}
                                value={values[name]}
                                onChange={value => onTextFieldChange(name, value)}
                                debounce={500}
                            />
                        );
                }
            })}
        </>
    );
}

PartnerParameters.propTypes = {
    partner: partnerShape.isRequired,
    platform: PropTypes.string.isRequired,
    isReadonly: PropTypes.bool,
};

PartnerParameters.defaultProps = {
    isReadonly: false,
};

export default PartnerParameters;
