import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Tabs } from '../../../components/widgets';
import css from './ConversionModelsGroupContent.css';
import {
    P1_INDEX,
    PARTNER_ICON_PATH,
    POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
    POSTBACK_CONVERSION_VALUE_TYPE_FINE,
} from '../../consts';
import ConversionModelContent from './ConversionModelContent';
import { conversionModelTypeShape } from '../../components/skan4/types';
import { getModelMeasurementPeriod, getRelevantModel, isGroupEnabled } from '../../utils';
import WarningMessage, { MessageTypes } from '../../../teamManagement/components/WizardWarningMessage';
import { ModelsApiContext } from '../../hooks';
import ConversionModelInfo from './ConversionModelInfo';

function ConversionModelsCard({ conversionModels, siteId }) {
    const { isAdminMode, selectedAppMissingEvents: missingPartnersEvents } = useContext(ModelsApiContext);

    const [selectedConversionModel, setSelectedConversionModel] = useState(null);
    const [modelTabs, setModelTabs] = useState([]);

    const isEnabled = isGroupEnabled(conversionModels);

    useEffect(() => {
        setSelectedConversionModel(
            conversionModels.find(
                conversionModel => conversionModel.postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_FINE
            )
        );

        const p1CoarseModel = getRelevantModel(conversionModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_COARSE);

        setModelTabs(
            conversionModels
                .filter(conversionModel => conversionModel.id !== p1CoarseModel?.id)
                .map(conversionModel => ({
                    id: conversionModel.id,
                    label: 'STATIC.PAGES.SKADNETWORK.MODEL_SUMMARY.POSTBACK_LABEL',
                    labelData: { postbackIndex: conversionModel.postbackIndex + 1 },
                }))
        );
    }, [conversionModels]);

    const onTabSelect = useCallback(
        tabId => setSelectedConversionModel(conversionModels.find(conversionModel => conversionModel.id === tabId)),
        [conversionModels]
    );

    if (!selectedConversionModel || !modelTabs.length) {
        return null;
    }

    const showMeasurementPeriod =
        selectedConversionModel.postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_FINE;

    const measurementPeriod = getModelMeasurementPeriod(selectedConversionModel);
    const measurementPeriodDaysTitle = measurementPeriod === 1 ? 'MEASUREMENT_PERIOD_1_DAY' : 'MEASUREMENT_PERIOD_DAYS';

    const getPartnerIcon = partnerName => {
        return `${PARTNER_ICON_PATH}${partnerName.trim().toLowerCase()}.svg`;
    };

    const onPartnerClick = partnerId => {
        window.open(`https://app.singular.net/#/partner-config?source=${partnerId}&site=${siteId}`, '_blank');
    };

    const messageElement =
        !isEnabled || !missingPartnersEvents.length ? null : (
            <>
                <Translate id="STATIC.PAGES.SKADNETWORK.MISSING_PARTNER_EVENT_MAP_MESSAGE" />
                {missingPartnersEvents.map(partner => (
                    <div key={partner.name} className={css.partnerContainer}>
                        <img src={getPartnerIcon(partner.name)} />
                        <div className={css.partner} onClick={() => onPartnerClick(partner.id)}>
                            {partner.name}
                        </div>
                    </div>
                ))}
            </>
        );

    return (
        <>
            <WarningMessage
                show={!!messageElement}
                textContainerClass={css.warningMessage}
                duration={1000}
                type={MessageTypes.WARNING}
                message={messageElement}
            />
            {modelTabs.length > 1 && (
                <Tabs
                    tabs={modelTabs}
                    selected={selectedConversionModel.id}
                    onClick={onTabSelect}
                    tabClass={css.tab}
                    activeTabClass={css.activeTab}
                    containerClass={css.tabsContainer}
                />
            )}
            <div className={css.cardContent}>
                <div>
                    <ConversionModelContent conversionModel={selectedConversionModel} />
                    {isAdminMode && selectedConversionModel && (
                        <div className={css.modelInfo}>
                            <ConversionModelInfo model={selectedConversionModel} />
                        </div>
                    )}
                </div>
                <div className={css.rightSection}>
                    {showMeasurementPeriod && (
                        <div className={css.measurementPeriod}>
                            <Translate id="STATIC.PAGES.SKADNETWORK.MODEL_SUMMARY.MEASUREMENT_PERIOD" />
                            <div className={css.measurementPeriodDays}>
                                <Translate
                                    id={`STATIC.PAGES.SKADNETWORK.MODEL_SUMMARY.${measurementPeriodDaysTitle}`}
                                    data={{ measurementPeriod }}
                                />
                            </div>
                        </div>
                    )}
                    {selectedConversionModel?.lastUpdate && (
                        <div className={css.lastUpdate}>
                            <Translate
                                className={css.lastUpdate}
                                id="STATIC.PAGES.SKADNETWORK.LAST_UPDATE"
                                data={{ lastUpdate: selectedConversionModel?.lastUpdate }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

ConversionModelsCard.propTypes = {
    conversionModels: PropTypes.arrayOf(conversionModelTypeShape).isRequired,
    siteId: PropTypes.number,
};

ConversionModelsCard.defaultProps = {
    siteId: null,
};

export default ConversionModelsCard;
