import API from '../services/api';

let instance = null;
const api = new API();
export const URL_SDK_KEYS_PAGE = 'api/sdk_keys';

export default class SDKKeysService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getSDKKeys() {
        return api.getJson(URL_SDK_KEYS_PAGE, {
            method: 'GET',
        });
    }
}
