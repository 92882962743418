export const formatAutoCompleteObject = item => ({ value: item.name, label: item.display_name });
export const formatCampaignFilter = (campaignNameAndId, sourceValue) => [
    {
        field: 'source',
        operator: 'in',
        values: [sourceValue],
    },
    {
        operator: 'in',
        field: 'campaign_name_and_id',
        values: [campaignNameAndId],
        options: { no_lowercase: true },
    },
];
