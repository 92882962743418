import API from '../services/api';

let instance = null;
const api = new API();

const NIKE_API = 'api/nike_social_links';

export default class NikeSocialLinksService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    createSocialLink(app, webURL, cp, psn, androidRedirectToStore, iosRedirectToStore) {
        return new Promise((resolve, reject) => {
            return api
                .getJson(NIKE_API, {
                    method: 'POST',
                    body: {
                        app,
                        webURL,
                        cp,
                        psn,
                        androidRedirectToStore,
                        iosRedirectToStore,
                    },
                })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }
}
