import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { siteShape, partnerShape, organizationOptionsShape, TemplateTypes, ParameterTypes } from '../types';
import PartnerAdditionalInformation from './PartnerAdditionalInformation';
import AttributionWindowsFactory from './attributionWindows/AttributionWindowsFactory';
import { getParametersValidationSchema } from './PartnerParameters';
import { getTemplate, getUsedParameters } from './utils';
import PartnerSetupSection from './PartnerSetupSection';
import { getIsAgency } from '../../../selectors/user';
import AttributionPostbacksFactory from './attributionPostbacks/AttributionPostbacksFactory';

export const getAttributionSettingsValidationSchema = (partner, platform) => ({
    ...getParametersValidationSchema(
        !partner
            ? []
            : getUsedParameters(
                  partner,
                  platform,
                  getTemplate(partner, platform, TemplateTypes.INSTALL) ? TemplateTypes.INSTALL : TemplateTypes.CUSTOM,
                  [ParameterTypes.ACCOUNT, ParameterTypes.APPLICATION]
              )
    ),
});

function AttributionSettings({ partner, site, isReadonly, orgOptions }) {
    const isAgency = useSelector(state => getIsAgency(state));
    return (
        <>
            <PartnerSetupSection partner={partner} platform={site.platform} isReadonly={isReadonly} />
            <AttributionPostbacksFactory
                partner={partner}
                site={site}
                isReadonly={isReadonly}
                orgOptions={orgOptions}
            />
            <AttributionWindowsFactory
                partner={partner}
                site={site}
                isReadonly={isReadonly || isAgency}
                orgOptions={orgOptions}
            />
            <PartnerAdditionalInformation partner={partner} />
        </>
    );
}

AttributionSettings.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    isReadonly: PropTypes.bool,
};

AttributionSettings.defaultProps = {
    isReadonly: false,
};

export default AttributionSettings;
