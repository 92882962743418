import React from 'react';

const TableCellNumeric = (props) => {
    let { value } = props;
    value = +value;
    if (!value) {
        value = '--';
    } else if (value >= 1000000) {
        value = `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
        value = `${(value / 1000).toFixed(1)}K`;
    }
    return <td style={{ textAlign: 'right' }}>{value}</td>;
};

export default TableCellNumeric;
