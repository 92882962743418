import React from 'react';
import PropTypes from 'prop-types';
import SingularButton from './SingularButton';
import Tooltip from './Tooltip';
import ExportIcon from '../../resources/svg/export.svg';

function DownloadReportButton({ disableReportState, ...restProps }) {
    const actionButton = (
        <SingularButton
            type="secondary"
            level="level1"
            text="STATIC.BUTTONS.DOWNLOAD_CSV"
            Icon={ExportIcon}
            style={{
                marginRight: '5px',
                verticalAlign: 'bottom',
            }}
            {...restProps}
        />
    );

    if (disableReportState) {
        return (
            <Tooltip
                titleTranslationKey={`STATIC.PAGES.REPORTS.DISABLE_RUN_TOOLTIP_${disableReportState}`}
                style={{ display: 'inline-flex' }}
                interactive
            >
                {actionButton}
            </Tooltip>
        );
    } else {
        return actionButton;
    }
}

DownloadReportButton.propTypes = {
    disableReportState: PropTypes.string,
};

DownloadReportButton.defaultProps = {
    disableReportState: undefined,
};

export default DownloadReportButton;
