import { capitalize } from 'utils/general';
import { SWIFT } from '../../consts';

const getIosSwiftRevenueInAppPurchaseSnippet = () => {
    const codeSnippet = `// *** Get the SKPaymentTransaction* transaction object ***
let transaction:SKPaymentTransaction = ...
      
// Send a revenue event to Singular; with no product details
Singular.iapComplete(transaction)

// Send a revenue event to Singular; with product details
Singular.iapComplete(transaction)
  productName:"Reservation Fee",productCategory:"Fee", 
  productQuantity:1, productPrice:5.00)
      
// Send a transaction event to Singular with a custom event name
Singular.iapComplete(transaction, withName:"MyCustomRevenue")`;
    return {
        markedCode: codeSnippet,
        codeLanguage: SWIFT,
        placeholders: {},
        customTitle: capitalize(SWIFT),
    };
};

const getIosSwiftRevenueNonInAppPurchaseSnippet = () => {
    const codeSnippet = `// Revenue with no product details
Singular.revenue("USD",amount:1.99)
      
// Revenue with product details
Singular.revenue("EUR",amount:5.00, productSKU:"SKU1928375", 
  productName:"Reservation Fee",productCategory:"Fee", 
  productQuantity:1, productPrice:5.00)
      
// Send a Revenue Event with attributes in a dictionary
var dic: [AnyHashable : Any] = [:]
dic[ATTRIBUTE_SNG_ATTR_ITEM_DESCRIPTION] = "100% Organic Cotton Mixed Plaid Flannel Shirt"
dic[ATTRIBUTE_SNG_ATTR_ITEM_PRICE] = "$69.95"
dic[ATTRIBUTE_SNG_ATTR_RATING] = "5 Star"
dic[ATTRIBUTE_SNG_ATTR_SEARCH_STRING] = "Flannel Shirt"
Singular.revenue("USD", amount: 19.95, withAttributes: dic)

// Revenue with a custom name and no product details
Singular.customRevenue("MyCustomRevenue", currency:"USD", amount:1.99)
      
// Revenue with a custom name and with product details
Singular.customRevenue("MyCustomRevenue", currency:"EUR", amount:5.00, 
  productSKU:"SKU1928375", productName:"Reservation Fee", productCategory:"Fee", 
  productQuantity:1, productPrice:5.00)
      
// Send a Custom Revenue Event with attributes in a dictionary
var dic: [AnyHashable : Any] = [:]
dic[ATTRIBUTE_SNG_ATTR_ITEM_DESCRIPTION] = "100% Organic Cotton Mixed Plaid Flannel Shirt"
dic[ATTRIBUTE_SNG_ATTR_ITEM_PRICE] = "$69.95"
dic[ATTRIBUTE_SNG_ATTR_RATING] = "5 Star"
dic[ATTRIBUTE_SNG_ATTR_SEARCH_STRING] = "Flannel Shirt"
Singular.customRevenue("CustomRevenueWithArgsDic", currency: "USD", 
  amount: 44.99, withAttributes: dic)`;
    return {
        markedCode: codeSnippet,
        codeLanguage: SWIFT,
        placeholders: {},
        customTitle: capitalize(SWIFT),
    };
};

export { getIosSwiftRevenueInAppPurchaseSnippet, getIosSwiftRevenueNonInAppPurchaseSnippet };
