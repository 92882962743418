import React from 'react';
import { Translate } from 'react-localize-redux';
import css from './PaymentComplete.css';
import SuccessMan from '../../resources/assets/successMan.svg';

const PaymentComplete = () => {
    return (
        <div className={css.paymentCompleteContainer}>
            <div className={css.svgContainer}>
                <SuccessMan />
            </div>
            <div className={css.paymentCompleteHeader}>
                <Translate id="STATIC.PAGES.CHECKOUT.SUCCESS" />
            </div>
            <div className={css.paymentCompleteSubheader}>
                <Translate id="STATIC.PAGES.CHECKOUT.SUCCESS_MESSAGE" />
            </div>
        </div>
    );
};
export default PaymentComplete;
