import React from 'react';
import { YAxis } from 'react-jsx-highcharts';
import PropTypes from 'prop-types';

const ChartYAxis = ({
    id,
    titleText,
    children,
    min,
    max,
    startOnTick,
    endOnTick,
    labelsEnabled,
    visible,
    labelSuffix,
}) => {
    const title = titleText
        ? {
              title: {
                  enabled: true,
                  align: 'high',
                  offset: 0,
                  useHTML: true,
                  text: titleText,
                  rotation: 0,
                  y: -28,
                  x: 11,
              },
          }
        : {};
    return (
        <YAxis
            {...title}
            visible={visible}
            id={id}
            plotLines={[
                {
                    color: '#e1e1e1',
                    width: 1,
                    value: 0,
                    zIndex: 10,
                },
            ]}
            gridLineWidth={0}
            labels={{
                enabled: labelsEnabled,
                format: '{value}'.concat(labelSuffix),
            }}
            min={min}
            max={max}
            startOnTick={startOnTick}
            endOnTick={endOnTick}
            maxPadding={0}
        >
            {children}
        </YAxis>
    );
};

ChartYAxis.propTypes = {
    id: PropTypes.string.isRequired,
    titleText: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    startOnTick: PropTypes.bool,
    endOnTick: PropTypes.bool,
    labelsEnabled: PropTypes.bool,
    visible: PropTypes.bool,
    labelSuffix: PropTypes.string,
};

ChartYAxis.defaultProps = {
    titleText: undefined,
    min: undefined,
    max: undefined,
    startOnTick: false,
    endOnTick: false,
    labelsEnabled: true,
    visible: true,
    labelSuffix: '%',
};

export default ChartYAxis;
