import update from 'immutability-helper';
import * as actions from './actions';

export function updateState(state, action) {
    return Object.assign({}, state, action.stateOverride);
}

const initialState = {
    partners: [],
    targets: [],
    target: null,
    partnerId: null,
    url: null,
    landingPage: '',
    trackingLink: null,
    attributes: {},
    attributesMeta: [],
    instructions: '',
    reEnabled: false,
    hasPcConsoleApps: false,
    show: {
        fetchingMeta: true,
        fetchingPartner: false,
        generatingLink: false,
        trackingLink: false,
        instructionsShelf: false,
    },
};

const getDefaultUrl = target => {
    if (target !== null && target.urls.length === 1) {
        return target.urls[0].url;
    }
    return null;
};

const getDefaultLandingPage = target => {
    if (target !== null && target.landingPages.length === 1) {
        return target.landingPages[0];
    }
    return '';
};

const webTrackingLinks = (state = initialState, action) => {
    let target = null;

    switch (action.type) {
        case actions.UPDATE_STATE:
            return updateState(state, action);

        case actions.FETCH_META_DONE:
            if (action.data.targets.length === 1) {
                target = action.data.targets[0];
            }
            return update(state, {
                partners: { $set: action.data.partners },
                targets: { $set: action.data.targets },
                show: {
                    fetchingMeta: { $set: false },
                },
                url: { $set: getDefaultUrl(target) },
                landingPage: { $set: getDefaultLandingPage(target) },
                hasPcConsoleApps: { $set: action.data.has_pc_console_apps },
            });

        case actions.UPDATE_TARGET:
            target = action.selection;

            return update(state, {
                target: { $set: target },
                url: { $set: getDefaultUrl(target) },
                landingPage: { $set: getDefaultLandingPage(target) },
            });

        case actions.UPDATE_GENERAL_FIELD:
            const value = action.key ? action.selection[action.key] : action.selection;
            return update(state, {
                [action.field]: { $set: value },
            });

        case actions.UPDATE_ATTRIBUTE:
            return update(state, {
                attributes: {
                    [action.attribute]: { $set: action.value },
                },
            });

        case actions.PARTNER_DISCOVER_STARTED:
            return update(state, {
                utmParams: { $set: action.utmParams },
                show: {
                    fetchingPartner: { $set: true },
                },
            });

        case actions.PARTNER_DISCOVER_DONE:
            return update(state, {
                attributesMeta: { $set: action.attributesMeta },
                attributes: { $set: action.attributes },
                utmParams: { $set: action.utmParams },
                trackingLink: { $set: null },
                instructions: { $set: action.instructions },
                show: {
                    fetchingPartner: { $set: false },
                    trackingLink: { $set: false },
                },
            });

        case actions.GENERATE_LINK_STARTED:
            return update(state, {
                trackingLink: { $set: null },
                show: {
                    generatingLink: { $set: true },
                    trackingLink: { $set: false },
                },
            });

        case actions.GENERATE_LINK_DONE:
            return update(state, {
                trackingLink: { $set: action.trackingLink },
                show: {
                    generatingLink: { $set: false },
                    trackingLink: { $set: action.trackingLink !== null },
                },
            });

        case actions.TOGGLE_INSTRUCTIONS_SHELF:
            return update(state, {
                show: {
                    instructionsShelf: { $set: !state.show.instructionsShelf },
                },
            });

        case actions.RE_ENABLED_TOGGLE:
            return update(state, {
                reEnabled: { $set: !state.reEnabled },
            });

        default:
            return state;
    }
};

export default webTrackingLinks;
