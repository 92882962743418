import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangeIcon from 'resources/svg/calendar.svg';
import classNames from 'classnames';
import css from '../../../../../components/widgets/Datepicker.css';
import otherCss from './OverviewCalender.css';

const OverviewCalender = ({ endDate, startDate }) => {
    const formattedStartDate = moment(startDate).format('ll');
    const formattedEndDate = endDate ? moment(endDate).format('ll') : 'Ongoing';

    return (
        <div className={otherCss.container}>
            <button type="submit" className={classNames(css.buttonContainer, css.disabled)} disabled>
                <div className={classNames(css.iconContainer, { [css.disabled]: true })}>
                    <DateRangeIcon className={classNames(css.icon, { [css.disabled]: true })} />
                </div>
                <div className={classNames(css.dateRange, { [css.disabled]: true })}>
                    {startDate ? `${formattedStartDate} - ${formattedEndDate}` : ' '}
                </div>
            </button>
        </div>
    );
};

OverviewCalender.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default OverviewCalender;
