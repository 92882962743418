import { JAVA, KOTLIN } from '../../consts';

const getAndroidRevenueWithPurchaseObjectJavaCodeSnippet = () => {
    const codeSnippet = `// Send a revenue event to Singular + the purchase object
// that was received from the Google Billing Library 
Singular.revenue("USD", 5.50, purchase);

// Send a custom revenue event to Singular, passing the event 
// name and the purchase object
Singular.customRevenue("MyCustomRevenue", "USD", 
    5.50, purchase);`;

    return {
        markedCode: codeSnippet,
        codeLanguage: JAVA,
        placeholders: {},
    };
};
const getAndroidRevenueWithPurchaseObjectKotlinCodeSnippet = () => {
    const codeSnippet = `// Send a revenue event to Singular + the purchase object
// that was received from the Google Billing Library
Singular.revenue("USD", 5.50, purchase)


// Send a custom revenue event to Singular, passing the event 
// name and the purchase object
Singular.customRevenue("MyCustomRevenue", "USD", 5.50, purchase)`;

    return {
        markedCode: codeSnippet,
        codeLanguage: KOTLIN,
        placeholders: {},
    };
};

const getAndroidRevenueWithoutPurchaseObjectJavaCodeSnippet = () => {
    const codeSnippet = `// Revenue event without a receipt, without product details
Singular.revenue("USD", 5.50);

// Revenue event without a receipt, with product details
Singular.revenue("EUR", 5.00, "SKU1928375", "Reservation Fee", 
"Fee" , 1, 5.00);

// Custom Revenue event without a receipt, without product details
Singular.customRevenue("MyCustomRevenue", "USD", 5.50);

// Custom Revenue event without a receipt, with product details
Singular.customRevenue("MyCustomRevenue", "EUR", 5.00, 
"SKU1928375", "Reservation Fee", "Fee" , 1, 5.00);`;

    return {
        markedCode: codeSnippet,
        codeLanguage: JAVA,
        placeholders: {},
    };
};

const getAndroidRevenueWithoutPurchaseObjectKotlinCodeSnippet = () => {
    const codeSnippet = `// Revenue event without a receipt, without product details
Singular.revenue("USD", 5.50)

// Revenue event without a receipt, with product details
Singular.revenue("EUR", 5.00, "SKU1928375", "Reservation Fee", 
"Fee" , 1, 5.00)

// Custom Revenue event without a receipt, without product details
Singular.customRevenue("MyCustomRevenue", "USD", 5.50)

// Custom Revenue event without a receipt, with product details
Singular.customRevenue("MyCustomRevenue", "EUR", 5.00, 
"SKU1928375", "Reservation Fee", "Fee" , 1, 5.00)`;

    return {
        markedCode: codeSnippet,
        codeLanguage: KOTLIN,
        placeholders: {},
    };
};

export {
    getAndroidRevenueWithPurchaseObjectJavaCodeSnippet,
    getAndroidRevenueWithPurchaseObjectKotlinCodeSnippet,
    getAndroidRevenueWithoutPurchaseObjectJavaCodeSnippet,
    getAndroidRevenueWithoutPurchaseObjectKotlinCodeSnippet,
};
