import React, { useCallback, useEffect, useRef } from 'react';
import { useField } from 'formik-2';
import * as Yup from 'yup';
import classNames from 'classnames';
import { AutoCompleteField, DropdownField } from '../../components/widgets';
import css from './formUtils.css';

export const sortAutoCompleteOptions = (a, b) => {
    const aLabel = a.label.toLowerCase();
    const bLabel = b.label.toLowerCase();
    if (aLabel < bLabel) {
        return -1;
    }
    if (aLabel > bLabel) {
        return 1;
    }
    return 0;
};

export function useFieldHelper(setValues, initialValues, values) {
    const didMount = useRef(false);
    const convertToAutoComplete = useCallback((values, mandatory = []) => {
        return values.map(({ name, display_name }) => ({
            value: name,
            label: display_name,
            deleteEnabled: !mandatory.includes(name),
        }));
    }, []);

    const setInitialValues = () => {
        if (!didMount.current) {
            const valuesCopy = {
                ...initialValues,
                ...values,
            };

            Object.keys(valuesCopy).forEach(k => {
                if (valuesCopy && valuesCopy[k] && typeof valuesCopy[k] === 'object' && valuesCopy[k].value) {
                    valuesCopy[k] = valuesCopy[k].value;
                }
            });

            setValues?.(valuesCopy);
        }
    };

    useEffect(() => {
        if (!didMount.current) {
            setInitialValues();
            didMount.current = true;
        }
    });

    return [convertToAutoComplete, sortAutoCompleteOptions];
}

const withFormField = WrappedComponent => {
    function WithFormField({ name, onChange, ignoreOnInputChange, ...rest }) {
        const [field, meta, helpers] = useField(name);

        const onChangeHandler = (...args) => {
            if (!args || !args.length) {
                return;
            }
            let value = args[0];
            if (typeof value === 'object' && value && value.value) {
                value = value.value;
            }

            // Support TagInput with multiple select
            if (Array.isArray(value)) {
                value = value.map(item => {
                    if (typeof item === 'object' && item && item.value) {
                        return item.value;
                    }

                    return item;
                });
            }

            helpers.setValue(value, true);

            // Need timeout - otherwise validation will only finish when onBlur triggers
            setTimeout(() => helpers.setTouched(true));

            if (onChange) {
                onChange(...args);
            }
        };

        const onBlur = () => {
            helpers.setTouched(true);
        };
        return (
            <WrappedComponent
                {...rest}
                onChange={onChangeHandler}
                {...(!ignoreOnInputChange && { onInputChange: onChangeHandler })}
                onBlur={onBlur}
            />
        );
    }

    return WithFormField;
};

export const AutoCompleteFieldEnhanced = withFormField(AutoCompleteField);
export const DropdownFieldEnhanced = withFormField(DropdownField);

export function FormField({ className, children }) {
    return <div className={classNames(css.formField, className)}>{children}</div>;
}

Yup.addMethod(Yup.array, 'sorted', function(message, parser = a => a, minimalValue = 0) {
    return this.test('sorted', message, function(list) {
        if (list.length <= 1) {
            return true;
        }

        list = list.map(parser);
        let previousValue = minimalValue;

        for (let i = 0; i < list.length; ++i) {
            const value = list[i];
            if (value <= previousValue && (i !== 0 || previousValue !== value)) {
                return false;
            }

            previousValue = value;
        }

        return true;
    });
});

export function getUniqueUnusedOptions(options, used = []) {
    const usedLabels = new Set(used);

    // Could use filter, but mutable filter func is EVIL so gonna do a foreach
    const finalOptions = [];
    options.forEach(option => {
        const { label } = option;

        if (usedLabels.has(label)) {
            return;
        }

        finalOptions.push(option);
        usedLabels.add(label);
    });

    return finalOptions;
}
