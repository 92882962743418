import { Translate } from 'react-localize-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from '../PartnerConfigurationShelf.css';
import QuestionMark from '../../../../components/widgets/QuestionMark';
import { Slider, Toggle } from '../../../../components/widgets';
import { partnerShape, siteShape } from '../../types';
import { trackPartnerConfigMixpanelEvent } from '../utils';
import inactivityWindowCss from './InactivityWindow.css';

export const MAX_REENG_INACTIVITY_WINDOW = 90;
const MIN_INACTIVITY_WINDOW = 1;

const isIaWindowOverrideEnabled = (windowValue, siteIaWindow) => {
    return (siteIaWindow !== null && windowValue !== siteIaWindow / 24) || siteIaWindow === null;
};

function InactivityWindow({ site, isReadonly, setFieldValue, isMinZero, sliderProps, value, partner }) {
    const [iaWindowOverrideEnabled, setIaWindowOverrideEnabled] = useState(
        isIaWindowOverrideEnabled(value, site.app_inactivity_window)
    );

    useEffect(() => {
        if (!(value || value === 0)) return;
        setIaWindowOverrideEnabled(!!isIaWindowOverrideEnabled(value, site.app_inactivity_window));
    }, [value, site.app_inactivity_window]);

    const onIaWindowToggled = () => {
        setFieldValue(`windows.reengInactivityWindow`, Math.ceil(site.app_inactivity_window / 24));
        setIaWindowOverrideEnabled(!iaWindowOverrideEnabled);
    };

    function onInactivityWindowChange(newInactivityValue) {
        trackPartnerConfigMixpanelEvent('Inactivity window modified', partner, site, {
            newValue: newInactivityValue,
            oldValue: value,
        });
        setFieldValue('windows.reengInactivityWindow', newInactivityValue);
    }

    return (
        <>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.REENGAGEMENTS_INACTIVITY_WINDOW" />
                <QuestionMark
                    containerClassName={css.titleIcon}
                    message="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.REENG_INACTIVITY_WINDOW_TOOLTIP"
                    interactive
                />
                <Toggle
                    label={
                        <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.REENG_INACTIVITY_WINDOW_TOGGLE" />
                    }
                    disabled={isReadonly}
                    checked={iaWindowOverrideEnabled}
                    onToggle={onIaWindowToggled}
                    styleOverride={{ labelStyle: { color: '#515864', fontWeight: 300 } }}
                    className={css.toggleIaWindow}
                />
            </div>
            <Slider
                {...sliderProps}
                min={isMinZero ? 0 : MIN_INACTIVITY_WINDOW}
                max={MAX_REENG_INACTIVITY_WINDOW}
                labelClassName={inactivityWindowCss.inactivityWindowLabel}
                extraSteps={[15, 30, 45, 60, 75]}
                stepsOverride={{ [MAX_REENG_INACTIVITY_WINDOW]: `${MAX_REENG_INACTIVITY_WINDOW} days` }}
                value={value !== null ? value : Math.ceil(site.app_inactivity_window / 24)}
                labelTextClassName={css.onelineSliderTitle}
                onChange={val => onInactivityWindowChange(val)}
                label="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.REENG_INACTIVITY_WINDOW"
                color="var(--purple300)"
                disabled={isReadonly || !iaWindowOverrideEnabled}
            />
        </>
    );
}

InactivityWindow.propTypes = {
    isReadonly: PropTypes.bool,
    isMinZero: PropTypes.bool,
    sliderProps: PropTypes.object,
    partner: partnerShape.isRequired,
    value: PropTypes.number,
    setFieldValue: PropTypes.func,
    site: siteShape.isRequired,
};

InactivityWindow.defaultProps = {
    isReadonly: false,
    isMinZero: false,
};

export default InactivityWindow;
