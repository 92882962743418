import React from 'react';
import VirtualList from 'react-tiny-virtual-list';

class MenuList extends React.Component {
    constructor(props) {
        super(props);
        this.itemSize = 30;
    }

    _elementIsHidden(element) {
        let ret = false;
        const { parentNode } = element;
        const elementTop = parseInt(parentNode.style.top, 10);
        const elementBottom = elementTop + parentNode.offsetHeight;

        const containerNode = parentNode.parentNode.parentNode;
        const containerTop = containerNode.scrollTop;
        const containerBottom = containerTop + containerNode.offsetHeight;

        if (elementBottom <= containerTop || elementTop >= containerBottom) {
            ret = true;
        }
        return ret;
    }

    _flatGroups() {
        const { children = [] } = this.props;
        const flattened = [];
        const childrenArr = React.Children.toArray(children);
        for (const child of childrenArr) {
            flattened.push(child);
            if (
                (!child.props.type && child.props.children !== 'No options') ||
                (child.props.type !== 'option' && Array.isArray(child.props.children))
            ) {
                flattened.push(...child.props.children);
            }
        }
        return flattened;
    }

    render() {
        const childrenArr = this._flatGroups();
        const height = childrenArr.length ? Math.min(this.itemSize * childrenArr.length, 300) : this.itemSize;
        let scrollToIndex = null;
        const focused = childrenArr.findIndex((item) => item.props && item.props.isFocused);
        if (focused !== -1) {
            try {
                const element = document.querySelector(`#${childrenArr[focused].props.innerProps.id}`);
                if (!element) {
                    scrollToIndex = focused;
                } else if (this._elementIsHidden(element)) {
                    scrollToIndex = focused;
                }
            } catch (e) {
                console.warn('[MenuList] failed to calculate scrollIndex');
            }
        }
        return (
            <VirtualList
                width="100%"
                height={height}
                itemCount={childrenArr.length || 1}
                itemSize={this.itemSize}
                renderItem={({ index, style }) => {
                    return (
                        <div key={index} style={style}>
                            {childrenArr[index]}
                        </div>
                    );
                }}
                scrollToIndex={scrollToIndex}
            />
        );
    }
}

export default MenuList;
