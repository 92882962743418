import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSharingOptionsShelf } from '../selectors';
import {
    copySharingLink,
    createSharingLink,
    revokeSharingLink,
    renameSharingLink,
    closeSharingOptions,
} from '../actions';
import SharingOptions from '../components/SharingOptions';

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onCopySharingLink: copySharingLink,
            onCreateSharingLink: createSharingLink,
            onRevokeSharingLink: revokeSharingLink,
            onRenameSharingLink: renameSharingLink,
            onCloseSharingOptions: closeSharingOptions,
        },
        dispatch
    );
};

export default connect(getSharingOptionsShelf, mapDispatchToProps)(SharingOptions);
