import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import { Label } from '../components/widgets';
import css from './AssistedInstallsTable.css';

export default function AssistedInsallsTable({ data }) {
    const assistedBySource = data.data?.value?.assist_by_source;
    if (!assistedBySource) {
        return '';
    }

    return (
        <div className={css.tableWrapper}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Label
                                    className={css.tableHeader}
                                    text="STATIC.PAGES.ASSISTED_INSTALLS.COLUMN_HEADERS.SOURCE"
                                />
                            </TableCell>
                            <TableCell>
                                <Label
                                    className={css.tableHeader}
                                    text="STATIC.PAGES.ASSISTED_INSTALLS.COLUMN_HEADERS.ASSISTED_INSTALLS"
                                />
                            </TableCell>
                            <TableCell>
                                <Label
                                    className={css.tableHeader}
                                    text="STATIC.PAGES.ASSISTED_INSTALLS.COLUMN_HEADERS.PERCENT_FROM_ALL_INSTALLS"
                                />
                            </TableCell>
                            <TableCell>
                                <Label
                                    className={css.tableHeader}
                                    text="STATIC.PAGES.ASSISTED_INSTALLS.COLUMN_HEADERS.LAST_TOUCH_INSTALLS"
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody displayRowCheckbox={false}>
                        {assistedBySource.map(row => {
                            return (
                                <TableRow key={row.name}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.assisted_installs}</TableCell>
                                    <TableCell>{row.percent_from_installs}</TableCell>
                                    <TableCell>{row.last_touch_installs}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

AssistedInsallsTable.propTypes = {
    data: PropTypes.object,
};

AssistedInsallsTable.defaultProps = {
    data: {},
};
