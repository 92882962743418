import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import css from './CheckoutPage.css';
import { parseSearchParams } from '../utils/url';
import { route } from '../utils/routerUtils';
import CheckoutStatus from './components/CheckoutStatus';
import CheckoutEmbed from './components/CheckoutEmbed';
import PaymentComplete from './components/PaymentComplete';
import { ACCOUNT_PLAN_URL, CHECKOUT_ENABLED_TIERS } from './consts';
import CheckoutAPI from './service';
import { getOrganizationTier } from '../selectors/user';

const CheckoutPage = () => {
    const [sessionId, setSessionId] = React.useState(null);
    const [priceId, setPriceId] = React.useState(null);
    const [quantity, setQuantity] = React.useState(null);
    const [shouldShowCheckoutStatus, setShouldShowCheckoutStatus] = React.useState(false);
    const [shouldShowCheckoutEmbed, setShouldShowCheckoutEmbed] = React.useState(false);
    const [isPaymentComplete, setIsPaymentComplete] = React.useState(false);
    const userTier = useSelector(getOrganizationTier);
    const location = useLocation();

    const backToAccountPlan = (hardRefresh = false) => {
        route(ACCOUNT_PLAN_URL, {}, null, hardRefresh);
    };

    const onDone = useCallback(async () => {
        const serviceApi = new CheckoutAPI();
        await serviceApi.onPaymentComplete(sessionId);
        setSessionId(null);
        setShouldShowCheckoutEmbed(false);
        setShouldShowCheckoutStatus(false);
        setIsPaymentComplete(true);
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(3500);
        backToAccountPlan(true);
    }, [sessionId]);

    useEffect(() => {
        const { session_id: urlSessionId, price_id: urlPriceId, quantity: urlQuantity } = parseSearchParams(
            location.search
        );
        setIsPaymentComplete(false);
        if (urlSessionId) {
            setSessionId(urlSessionId);
            setShouldShowCheckoutEmbed(false);
            setShouldShowCheckoutStatus(true);
        } else if (urlPriceId && urlQuantity && parseInt(urlQuantity, 10) > 0) {
            setPriceId(urlPriceId);
            setQuantity(parseInt(urlQuantity, 10));
            setShouldShowCheckoutStatus(false);
            setShouldShowCheckoutEmbed(true);
        }
    }, [location]);

    useEffect(() => {
        if (userTier && !CHECKOUT_ENABLED_TIERS.includes(userTier.toUpperCase())) {
            backToAccountPlan();
        }
    }, [userTier]);

    return (
        <div className={css.page}>
            {isPaymentComplete && <PaymentComplete />}
            {shouldShowCheckoutStatus && <CheckoutStatus sessionId={sessionId} onDone={onDone} />}
            {shouldShowCheckoutEmbed && (
                <CheckoutEmbed
                    key={`${priceId}:${quantity}`}
                    priceId={priceId}
                    quantity={quantity}
                    onCancel={backToAccountPlan}
                    userTier={userTier}
                />
            )}
        </div>
    );
};

export default CheckoutPage;
