import React from 'react';
import PropTypes from 'prop-types';
import Toggle from './Toggle';
import Label from './Label';
import css from './TableCellToggle.css';

const TableCellToggle = ({
    id,
    toggled,
    label1,
    label2,
    disabled,
    disableLabel,
    tdStyle,
    tdClassName,
    onToggle,
    labelEnterTransitionDelay,
    labelExitTransitionDelay,
}) => {
    const getInnerRendered = () => {
        if (disabled && disableLabel) {
            return <Label className={css.disableLabel} text={disableLabel} />;
        } else {
            return [
                label1 && (
                    <span
                        style={{
                            opacity: toggled ? 0 : 1,
                            transition: `opacity ${toggled ? labelExitTransitionDelay : labelEnterTransitionDelay}ms`,
                        }}
                    >
                        {label1}
                    </span>
                ),
                <Toggle
                    onToggle={e => onToggle(e.target.checked, id)}
                    style={{ display: 'inline-block', verticalAlign: 'middle', padding: '0 0 0 7px' }}
                    iconStyle={{ paddingLeft: 0 }}
                    checked={toggled}
                    disabled={disabled}
                />,
                label2 && (
                    <span
                        style={{
                            opacity: toggled ? 1 : 0,
                            transition: `opacity ${toggled ? labelEnterTransitionDelay : labelExitTransitionDelay}ms`,
                        }}
                    >
                        {label2}
                    </span>
                ),
            ];
        }
    };

    return (
        <td style={{ textAlign: 'center', ...tdStyle }} className={tdClassName}>
            {getInnerRendered()}
        </td>
    );
};

TableCellToggle.propTypes = {
    id: PropTypes.string.isRequired,
    label1: PropTypes.string,
    label2: PropTypes.string,
    toggled: PropTypes.bool,
    disabled: PropTypes.bool,
    tdStyle: PropTypes.string,
    tdClassName: PropTypes.string,
    onToggle: PropTypes.func,
    labelEnterTransitionDelay: PropTypes.number,
    labelExitTransitionDelay: PropTypes.number,
    disableLabel: PropTypes.string,
};

TableCellToggle.defaultProps = {
    toggled: undefined,
    disabled: undefined,
    tdStyle: undefined,
    tdClassName: '',
    label1: 'No',
    label2: 'Yes',
    onToggle: () => {},
    labelEnterTransitionDelay: 700,
    labelExitTransitionDelay: 50,
    disableLabel: undefined,
};

export default TableCellToggle;
