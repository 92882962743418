import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Translate, withLocalize} from 'react-localize-redux';
import SingularLogoIcon from '../../resources/svg/singular_flat_logo_white.svg';
import WarningMessage from './WarningMessage';
import CountUpLoader from './CountUpLoader';

import css from './LoadingPageBody.css';

const LoadingPageBody = ({ title, subtitle, warningMsg }) => {
    return (
        <div className={css.landingPageBody}>
            <div className={css.loadingPage}>
                <div className={css.loadingPageInner}>
                    <div className={css.loadingPageTitle}>
                        <Translate id={title} />
                    </div>
                    <SingularLogoIcon className={css.singularIconLoadingPage} />

                    {warningMsg ? (
                        <WarningMessage
                            show
                            message={`${warningMsg} Please refresh the page to try again!` || ''}
                            showIcon={false}
                            type="error"
                            textAlignCenter
                            className={css.warningMsg}
                        />
                    ) : (
                        <div>
                            <div className={classNames(css.loadingPageSubTitle)}>
                                <Translate id={subtitle} />
                            </div>
                            <CountUpLoader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

LoadingPageBody.propTypes = {
    warningMsg: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

LoadingPageBody.defaultProps = {
    warningMsg: undefined,
    title: 'STATIC.PAGES.LOADING_PAGE.DEFAULT_TITLE',
    subtitle: 'STATIC.PAGES.LOADING_PAGE.DEFAULT_SUBTITLE',
};
export default withLocalize(LoadingPageBody);
