import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DEFAULT_EMPTY_FILTER } from 'utils/reports';
import PlusIcon from '../../resources/svg/icon_add_row.svg';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import css from './FilterFields.css';
import Dropdown from '../widgets/Dropdown';
import TagInput from '../widgets/TagInput';
import TextField from '../widgets/TextField';
import { invalidValue } from '../../customDimensions/consts';

function FilterFields({
    fields,
    operators,
    filters: filtersFields,
    plusButtonText,
    onAddFilter,
    onRemoveFilter,
    errorOnEmpty,
    forceHoverStateOnLast,
    onFieldSelection,
    onOperatorSelection,
    onFilterValueAdded,
    onFilterValueRemoved,
    onFilterValueChanged,
    containerClass,
    filterClass,
    showRemoveAlways,
    allowPasteTagInputs,
}) {
    const filters = filtersFields?.filter(filter => !!filter.field) || [];

    if (!fields.length && !filters.length) return null;

    const shouldShowTagError = filter =>
        filter.tags.filter(tag => typeof tag?.name === 'string' && tag.name.includes(invalidValue)).length > 0;

    function getValuesPlaceholder(filter) {
        if (filter.field.name === DEFAULT_EMPTY_FILTER.field) {
            return 'Select a dimension';
        }
        if (filter.operator.name === 'in') {
            return `Select ${filter.field.display_name}`;
        }
        return 'None';
    }

    return (
        <div className={classNames(css.container, containerClass)}>
            {filters.map((filter, index) => (
                <div
                    className={classNames(css.filterContainer, filterClass, {
                        [css.forceHoverOnLast]: forceHoverStateOnLast,
                    })}
                    // Note - using a non-unique key here will cause deletion of rows to behave strangely on render
                    key={filter.key ? filter.key : `${filter.field.name}_${index}}`}
                >
                    <div style={{ display: 'flex', flex: 5 }}>
                        <div
                            style={{ display: plusButtonText && index > 0 ? 'inherit' : 'none' }}
                            className={css.prefixText}
                        >
                            {plusButtonText}
                        </div>
                        <Dropdown
                            items={fields}
                            selected={filter.field}
                            containerStyle={{ width: '100%' }}
                            wrapperStyle={{ width: '100%' }}
                            error={
                                errorOnEmpty && filter.field.name === DEFAULT_EMPTY_FILTER.field ? 'must be chosen' : ''
                            }
                            onSelection={field => onFieldSelection(field, index)}
                        />
                    </div>
                    <div className={css.opsContainer}>
                        <Dropdown
                            items={
                                filter?.field?.operators?.map(name => operators.find(({ id }) => id === name)) ||
                                operators
                            }
                            containerStyle={{ width: '100%' }}
                            wrapperStyle={{ width: '100%' }}
                            selected={filter.operator}
                            onSelection={operator => onOperatorSelection(operator, index)}
                            disabled={filter.field && filter.field.name === DEFAULT_EMPTY_FILTER.field}
                        />
                    </div>
                    {!filter.field || !filter.field.values ? (
                        <div className={css.tagsContainer}>
                            <TextField
                                value={filter.tags.length ? filter.tags[0].name : ''}
                                onChange={value => onFilterValueChanged(value, index)}
                                disabled={filter.field && filter.field.name === 'default'}
                                placeholder="Select filter"
                                debounce={300}
                                error={
                                    errorOnEmpty &&
                                    (filter.tags.length === 0 || filter.tags[0].name.length === 0) &&
                                    filter.field.name !== DEFAULT_EMPTY_FILTER.field
                                        ? 'must not be empty'
                                        : ''
                                }
                            />
                        </div>
                    ) : (
                        <div className={css.tagsContainer}>
                            <TagInput
                                tags={filter.tags.map((tag, i2_) => ({ text: tag.display_name, id: i2_ }))}
                                containerStyle={{ width: '100%' }}
                                placeholder={getValuesPlaceholder(filter)}
                                disabled={filter.field && filter.field.name === 'default'}
                                suggestions={filter.field.values.map(field => field.display_name)}
                                onTagAdded={tag => onFilterValueAdded(tag, index)}
                                onTagRemoved={tag => onFilterValueRemoved(tag, index)}
                                error={
                                    errorOnEmpty &&
                                    filter.tags.length === 0 &&
                                    filter.field.name !== DEFAULT_EMPTY_FILTER.field
                                        ? 'must be chosen'
                                        : ''
                                }
                                tagError={shouldShowTagError(filter)}
                                virtualScrolling
                                allowPasteInput={allowPasteTagInputs}
                            />
                        </div>
                    )}
                    <div
                        className={
                            filters.length === 1 && filter.field && filter.field.name === DEFAULT_EMPTY_FILTER.field
                                ? css.disabledIcon
                                : ''
                        }
                    >
                        <XIcon
                            style={{
                                marginLeft: '10px',
                                marginTop: '13px',
                                height: '11px',
                                width: '11px',
                            }}
                            className={classNames(css.xIcon, {
                                [css.showOnHover]: index !== filters.length - 1 && !showRemoveAlways,
                            })}
                            onClick={() => {
                                onRemoveFilter(index);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            visibility: index === filters.length - 1 ? 'inherit' : 'hidden',
                        }}
                        className={classNames(css.plusIconContainer, {
                            [css.enabled]: filter.tags.length && fields.length,
                            [css.shown]: index === filters.length - 1,
                        })}
                        onClick={() => {
                            if (!filter.tags.length || !fields.length) {
                                return;
                            }
                            onAddFilter();
                        }}
                    >
                        <PlusIcon
                            style={{
                                marginTop: plusButtonText ? 7 : 5,
                                height: plusButtonText ? '11px' : '15px',
                                width: plusButtonText ? '11px' : '15px',
                            }}
                        />
                        <div
                            style={{
                                fontSize: 17,
                                marginLeft: 2,
                                fontWeight: 400,
                            }}
                        >
                            {plusButtonText}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

FilterFields.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    operators: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.arrayOf(PropTypes.object),
    onFieldSelection: PropTypes.func,
    onOperatorSelection: PropTypes.func,
    onFilterValueAdded: PropTypes.func,
    onFilterValueRemoved: PropTypes.func,
    onRemoveFilter: PropTypes.func,
    onAddFilter: PropTypes.func,
    onFilterValueChanged: PropTypes.func,
    plusButtonText: PropTypes.string,
    errorOnEmpty: PropTypes.bool,
    forceHoverStateOnLast: PropTypes.bool,
    containerClass: PropTypes.string,
    filterClass: PropTypes.string,
    showRemoveAlways: PropTypes.bool,
    allowPasteTagInputs: PropTypes.bool,
};

FilterFields.defaultProps = {
    fields: [],
    operators: [],
    filters: [],
    onFieldSelection: () => null,
    onOperatorSelection: () => null,
    onFilterValueAdded: () => null,
    onFilterValueRemoved: () => null,
    onFilterValueChanged: () => null,
    onRemoveFilter: () => null,
    onAddFilter: () => null,
    plusButtonText: '',
    errorOnEmpty: false,
    forceHoverStateOnLast: false,
    containerClass: '',
    filterClass: '',
    showRemoveAlways: false,
    allowPasteTagInputs: false,
};

export default FilterFields;
