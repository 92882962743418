import API from '../services/api';
import { LINKS_FILTER_TYPE } from './utils';

let instance = null;
const api = new API();

const URL_LINKS_FOR_APP = 'api/attribution/singular_links';
const URL_LEGACY_LINKS_SUMMARY = 'api/attribution/tracking_links/summary';
const URL_DOMAINS = 'api/attribution/domains';

export default class LinkManagementService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getLink(appId, linkId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/${appId}/${linkId}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getLinksForApp(
        appId,
        includeOldLinks = false,
        searchStr = '',
        includeUrls = true,
        filter = LINKS_FILTER_TYPE.LINKS_MANUAL_FILTER
    ) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/${appId}`, {
                method: 'GET',
                credentials: 'include',
                params: {
                    include_old_links: includeOldLinks,
                    search_str: searchStr || '',
                    include_urls: includeUrls,
                    filter,
                },
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getSuggestionsForApp(appId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/suggestions`, {
                method: 'GET',
                credentials: 'include',
                params: { app_id: appId },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getDomains() {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_DOMAINS}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    archiveDomain(subdomain, dnsZone) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_DOMAINS}/archive`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    subdomain,
                    dns_zone: dnsZone,
                    archived: true,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    favouriteDomain(subdomain, dnsZone, favourite) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_DOMAINS}/favourite`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    subdomain,
                    dns_zone: dnsZone,
                    favourite,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    createDomain(subDomain, dnsZone) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_DOMAINS}`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    subdomain: subDomain,
                    dns_zone: dnsZone,
                },
                fixErr: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateOrCreateLink(data) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/`, {
                method: 'POST',
                credentials: 'include',
                body: data,
            })
                .then(response => {
                    if (response.status !== 0) {
                        const err = {
                            msg: response.value,
                            extra: response.extra && response.extra.error_message ? response.extra.error_message : '',
                        };
                        return reject(err);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    generateShortLink(linkId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/short_link`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    link_id: linkId,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        const err = {
                            msg: response.value,
                            extra: response.extra && response.extra.error_message ? response.extra.error_message : '',
                        };
                        return reject(err);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getOptionalExtraParams() {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/extra_params`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        const err = {
                            msg: response.value,
                            extra: response.extra && response.extra.error_message ? response.extra.error_message : '',
                        };
                        return reject(err);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    archiveLink(linkId, archived = true) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/archive`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    link_id: linkId,
                    archived,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    saveConfiguration(configKey, configValue) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/configuration`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    config_key: configKey,
                    config_value: configValue,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getLinksSummary() {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_LINKS_FOR_APP}/summary`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getLegacyLinksSummary() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_LEGACY_LINKS_SUMMARY, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
