import { useCallback } from 'react';
import LoadingPageService from './service';
import { useRequest } from '../utils/customHooks';

export const useFetchSignupActivation = uuid => {
    const LoadingPageAPI = new LoadingPageService();
    return useRequest(
        useCallback(LoadingPageAPI.activateAccount, []),
        () => {
            return true;
        },
        () => {},
        uuid
    );
};
