import Highlighter from 'react-highlight-words';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Spinner, Dialog, ShelfHeader, AutoCompleteField } from '../widgets';
import css from './ImpersonateOrgDialog.css';
import defaultUserIcon from '../../resources/png/default_user_icon.png';
import VIcon from '../../resources/svg/icon_small_ok.svg';

function ImpersonateOrgDialog({ open, loading, onClose, onSelection, organizations, onStart }) {
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (open) {
            onStart();
        } else {
            setSearch('');
        }
    }, [open]);

    const onAutoCompleteChanged = (value) => {
        setSearch(value);
    };
    const handleOrgClicked = (org) => {
        if (org.active) {
            return;
        }
        onSelection(org);
    };

    const onSearchBlur = () => {
        setSearch(search);
    };

    const searchLower = search.toLowerCase();
    const searchLowerNoSpace = searchLower.replace(/ /g, '');

    const filteredOrganizations = organizations.filter((org) => {
        const orgLower = org.display_name.toLowerCase();
        return orgLower.includes(searchLower) || orgLower.includes(searchLowerNoSpace);
    });

    return (
        <Dialog
            open={open}
            noPadding
            onEscapePress={() => {
                onClose();
            }}
        >
            <ShelfHeader
                text="STATIC.PAGES.PARTNERS_PORTAL.LANDING_PAGE_BUTTON"
                onClose={() => {
                    onClose();
                }}
            />
            <div className={css.contentArea}>
                {loading ? (
                    <div className={`${css.paddedArea} ${css.spinnerArea}`}>
                        <Spinner show />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className={css.paddedArea}>
                            <AutoCompleteField
                                onInputChange={onAutoCompleteChanged}
                                onChange={onAutoCompleteChanged}
                                value={search}
                                controlled
                                searchable
                                placeholder="STATIC.PAGES.PARTNERS_PORTAL.LANDING_PAGE_MODAL_SEARCH_PLACEHOLDER"
                                isMulti={false}
                                options={[]}
                            />
                        </div>
                        <div className={css.separator} />
                        <div className={`${css.list} ${css.paddedArea}`}>
                            {filteredOrganizations.map((org) => {
                                const { display_name, company_logo, id, active } = org;
                                return (
                                    <div
                                        key={id}
                                        className={`${css.listItem} ${active ? css.disabled : ''}`}
                                        onClick={() => {
                                            handleOrgClicked(org);
                                        }}
                                    >
                                        <img
                                            className={css.listItemIcon}
                                            alt={display_name}
                                            src={company_logo || defaultUserIcon}
                                        />
                                        <div className={css.listItemText}>
                                            <Highlighter
                                                searchWords={[search, searchLower, searchLowerNoSpace]}
                                                textToHighlight={display_name}
                                                highlightClassName={css.highlighted}
                                            />
                                        </div>
                                        {active ? (
                                            <div className={css.active}>
                                                <VIcon className={css.activeIcon} />
                                                <Translate id="en.STATIC.PAGES.PARTNER_CONFIG.STATUS_INDICATION_OK" />
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Dialog>
    );
}

ImpersonateOrgDialog.propTypes = {
    open: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onSelection: PropTypes.func,
    organizations: PropTypes.arrayOf(PropTypes.object),
    onStart: PropTypes.func,
};

ImpersonateOrgDialog.defaultProps = {
    open: false,
    loading: false,
    onClose: () => {},
    onSelection: () => {},
    organizations: [],
    onStart: () => {},
};

export default ImpersonateOrgDialog;
