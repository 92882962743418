import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './GenericPopover.css';

function CreateLinkPopover(props) {
    const container = useRef();
    const arrow = useRef();
    const { children, show, containerStyle, arrowStyle, onInnerShowChanged } = props;

    const [right, setRight] = useState(0);
    const [arrowRight, setArrowRight] = useState(0);
    const [positionSet, setPositionSet] = useState(false);
    const [shouldShow, setShouldShow] = useState(show);

    const handleOutsideClick = useCallback(e => {
        if (!container.current) {
            document.removeEventListener('click', handleOutsideClick, false);
            return;
        }

        if (container.current.contains(e.target)) {
            return;
        }

        onInnerShowChanged(false);
    }, []);

    useEffect(() => {
        setShouldShow(show);

        if (show) {
            document.addEventListener('click', handleOutsideClick, true);
        } else {
            document.removeEventListener('click', handleOutsideClick, true);
        }
    }, [show]);

    useEffect(() => {
        setPositionSet(false);
    }, [children]);

    const calculatePositions = () => {
        if (!container.current || !arrow.current || positionSet) {
            return;
        }
        const rect = container.current.getBoundingClientRect();
        const parent = container.current.parentElement;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;
        const arrowCenter = arrow.current.offsetWidth / 2;
        let rightValue = (parent.offsetWidth - rect.width) / 2;
        let arrowRightValue = rect.width / 2 - arrowCenter;

        if (rect.right + Math.abs(rightValue) > windowWidth) {
            rightValue = 0;
            arrowRightValue = parent.offsetWidth / 2 - arrowCenter;
        }

        if (arrowRightValue !== arrowRight || rightValue !== right) {
            setRight(rightValue);
            setArrowRight(arrowRightValue);
            setPositionSet(true);
        }
    };

    useEffect(() => {
        setTimeout(calculatePositions);
    }, [show]);

    return (
        <div
            className={classnames(css.container, {
                [css.open]: shouldShow,
            })}
            style={{
                ...containerStyle,
                right,
            }}
            ref={container}
        >
            <div
                className={css.arrow}
                style={{
                    right: arrowRight,
                    ...arrowStyle,
                }}
                ref={arrow}
            />
            <div>{children}</div>
        </div>
    );
}

CreateLinkPopover.propTypes = {
    show: PropTypes.bool,
    onInnerShowChanged: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    arrowStyle: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.element,
};

CreateLinkPopover.defaultProps = {
    show: false,
    onInnerShowChanged: () => {},
    containerStyle: {},
    arrowStyle: {},
    children: null,
};

export default CreateLinkPopover;
