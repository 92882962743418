import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import css from './ShelfTabs.css';

class ShelfTabs extends React.PureComponent {
    render() {
        const { selectedTabId, tabs, onChange, level } = this.props;
        return (
            <div className={`${css.container} ${css[`level${level}`]}`}>
                <Tabs
                    value={selectedTabId}
                    onChange={onChange}
                    variant="scrollable"
                    classes={{
                        indicator: css.tabIndicator,
                        root: css.tabRoot,
                    }}
                >
                    {tabs.map((tab) => {
                        const { id, label, disabled } = tab;
                        return <Tab key={id} label={label} disabled={disabled} classes={{ root: css.adminLabel }} />;
                    })}
                </Tabs>
            </div>
        );
    }
}

ShelfTabs.propTypes = {
    selectedTabId: PropTypes.number,
    tabs: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    level: PropTypes.oneOf([1, 2]),
};

ShelfTabs.defaultProps = {
    selectedTabId: '',
    tabs: [],
    onChange: () => {},
    level: 1,
};

export default ShelfTabs;
