import React from 'react';
import PropTypes from 'prop-types';
import css from './StickyPageHeader.css';

const StickyPageHeaderActions = ({ children }) => {
    return <div className={css.actionContainer}>{children}</div>;
};

StickyPageHeaderActions.propTypes = {
    children: PropTypes.element.isRequired,
};

export default StickyPageHeaderActions;