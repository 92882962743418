import moment from 'moment';
import numeral from 'numeral';


/***
 * Date and Time formatting
 */
export const IsoDateFormat = 'YYYY-MM-DD';
export const momentIsoDateFormat = 'YYYY MM DD';
export const momentUsaDateFormat = 'MMMM Do YYYY';
export const momentUsaPartialDateFormat = 'M/DD';

export function toMoment(value) {
    return moment(value, momentIsoDateFormat);
}

export function toIsoDate(momentObj) {
    return momentObj.format(IsoDateFormat);
}

export function toUsaDateString(momentObj) {
    return momentObj.format(momentUsaDateFormat);
}

export function toUsaPartialDateString(momentObj) {
    return momentObj.format(momentUsaPartialDateFormat);
}

export function toMomentUsaPartialDateString(value) {
    return toUsaPartialDateString(toMoment(value));
}

/***
 * Number formatting
 */
export function niceInteger(value) {
    return numeral(value).format('0,0');
}

export function nicePct(value) {
    return numeral(value).format('0%');
}

export function niceMoney(value) {
    return numeral(value).format('0,0[.]00');
}


// used in toLocaleFixed
const fixedZeros = [
    (0).toFixed(0),
    (0).toFixed(1),
    (0).toFixed(2),
    (0).toFixed(3),
    (0).toFixed(4),
    (0).toFixed(5),
    (0).toFixed(6),
    (0).toFixed(7),
    (0).toFixed(8)
];

// Print numbers with locale formatting and a fixed number of digits. Default
// locale is the system locale.
// 
// Examples
//      toLocaleFixed(0, false, 123456789.987654321)
//          -> "123,456,790"
//      toLocaleFixed(3, false, 123456789.987654321)
//          -> "123,456,789.988"
//      toLocaleFixed(3, false, 0.0001)
//          -> "0.000"
//      toLocaleFixed(3, true, 0.0001)
//          -> "0"
//      toLocaleFixed(4, true, 0.0001)
//          -> "0.0001"
// 
// A note on performance: 200,000 iterations
//      1136.379ms - numeral(1000000 * Math.random()).format('0,0.000')
//       220.908ms - localeFixed(3, true, 1000000 * Math.random())
//        65.297ms - '' + (1000000 * Math.random())
//
// XXX TODO(joe): test with various locales
export const toLocaleFixed = toCustomLocaleFixed.bind(undefined, undefined);


// Use a custom locale, dependent on the brower support:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Browser_compatibility
//
//      toLocaleFixed('fr', 4, true, 7000000.123456)
//          -> "7 000 000,1235"
export function toCustomLocaleFixed(locale, fixedDigits, abridgeZero, value) {
    var decimals,
        fixed,
        sep;
    if (typeof value !== 'number' || value !== value) {
        return value;
    }
    if (abridgeZero && (fixed = value.toFixed(fixedDigits)) === fixedZeros[fixedDigits]) {
        return '0';
    }
    if (fixedDigits) {
        sep = (0.1).toLocaleString(locale || undefined)[1];
        decimals = sep + (fixed || value.toFixed(fixedDigits)).slice(-fixedDigits);
        return Math.floor(value).toLocaleString(locale || undefined) + decimals;
    } else {
        return Math.round(value).toLocaleString(locale || undefined);
    }
}


export function gaSendEvent(category, action, label, value) {
    if (typeof ga !== 'function') {
        console.error(`Google Analytics called but not setup.`);
    } else if (arguments.length < 2) {
        throw 'gaEvent called with not enough arguments';
    } else {
        ga('send', 'event', category, action, label, value);
    }
}

