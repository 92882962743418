import { gql } from '@apollo/client';

export const GET_AB_TESTING_METADATA = gql`
    query getAbTestingMetadata {
        abTestingMetadata {
            id
            name
            status
            startDate
            endDate
            appsite {
                appName
                longname
                platform
                icon
            }
            testChannels {
                adnetworkLogoPath
                isControlGroup
                adnetworkDisplayName
            }
        }
    }
`;

export const GET_AB_TEST = gql`
    query getAudienceABTest($testId: Int!) {
        abTest(testId: $testId) {
            id
            name
            description
            segment {
                id
                name
                reach
            }
            appsite {
                id
                appName
                platform
                icon
            }
            status
            startDate
            endDate
            events
            testChannels {
                id
                adnetworkName
                adnetworkDisplayName
                adnetworkLogoPath
                distributionConfigValues
                isControlGroup
                adnetworkIntegrationId
                uanId
                adAccountId
                minRange
                maxRange
            }
        }
    }
`;

export const GET_APP_TO_SEGMENTS = gql`
    query appToSegments {
        appToSegments {
            appsiteId
            appName
            platform
            icon
            segments {
                id
                name
                hasAbTest
                hasActiveDistribution
                reach
            }
        }
    }
`;

export const EVENT = gql`
    fragment Event on EventNode {
        eventName
        displayName
    }
`;

export const GET_EVENTS = gql`
    query getEvents($siteId: ID!) {
        events(siteId: $siteId) {
            ...Event
        }
    }
    ${EVENT}
`;
