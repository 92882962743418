import React from 'react';
import { Tooltip} from 'react-jsx-highcharts';
import PropTypes from 'prop-types';

import { tooltipFormatter, getTooltipValuesPercentage } from '../../utils/charts';
import LineChartCSS from '../../components/partials/LineChart.css';
import { TOOLTIP_DATE_FORMAT } from '../consts';

const ChartTooltip = ({
    categories,
    isTextHeader,
    getColorByValue,
    shouldSort,
    getTooltipValuesFunction,
    tooltipHeader,
}) => {
    return (
        <Tooltip
            followPointer
            hideDelay={0}
            outside
            symbolRadius={0}
            shared
            split={false}
            enabled
            shadow={false}
            style={{
                boxShadow: '0 0 3px 1px rgba(0,0,0,0.2)',
                width: 'auto',
            }}
            useHTML
            padding={0}
            borderWidth={0}
            formatter={tooltipFormatter(
                LineChartCSS,
                categories,
                TOOLTIP_DATE_FORMAT,
                isTextHeader,
                getTooltipValuesFunction || getTooltipValuesPercentage,
                shouldSort,
                getColorByValue,
                tooltipHeader
            )}
        />
    );
};

ChartTooltip.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.any).isRequired,
    isTextHeader: PropTypes.bool,
    getColorByValue: PropTypes.func,
    shouldSort: PropTypes.bool,
    getTooltipValuesFunction: PropTypes.func,
    tooltipHeader: PropTypes.string,
};

ChartTooltip.defaultProps = {
    isTextHeader: false,
    getColorByValue: undefined,
    shouldSort: false,
    getTooltipValuesFunction: undefined,
    tooltipHeader: undefined,
};

export default ChartTooltip;
