import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Translate, withLocalize } from 'react-localize-redux';
import DateRangeIcon from 'resources/svg/calendar.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Datepicker.css';
import { datePickerTimes } from '../../utils/regional';

export const DATE_PICKER_TEST_ID = 'datepicker';

function Datepicker(props) {
    const {
        id,
        startDate,
        endDate,
        containerStyle,
        containerClass,
        activeLanguage,
        dateFormat,
        linkedCalendars,
        opens,
        drops,
        title,
        translate,
        minDate,
        maxDate,
        dateLimit,
        appendToBody,
        ranges,
        onApply,
        buttonContainerClass,
        buttonType,
        disabled,
        autoApply,
        singleDatePicker,
    } = props;

    const [invalidated, setInvalidated] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [translateRanges, setTranslateRanges] = useState({});
    const shouldAutoApply = useMemo(() => autoApply, [autoApply]);

    useEffect(() => {
        if (!ranges) {
            return undefined;
        }

        // init ranges
        const updatedRanges = {};
        Object.keys(ranges).forEach(key => {
            updatedRanges[translate(key)] = ranges[key];
        });
        setTranslateRanges(updatedRanges);

        setInvalidated(true);
        const timeoutId = setTimeout(() => {
            setInvalidated(false);
        });

        return () => {
            clearTimeout(timeoutId);
        };
    }, [activeLanguage, ranges, translate]);

    const handleApply = (e, picker) => {
        if (singleDatePicker) {
            onApply({
                start_date: picker.startDate.format('YYYY-MM-DD'),
                end_date: picker.startDate.format('YYYY-MM-DD'),
                time_period: picker.chosenLabel,
            });
        } else {
            onApply({
                start_date: picker.startDate.format('YYYY-MM-DD'),
                end_date: picker.endDate.format('YYYY-MM-DD'),
                time_period: picker.chosenLabel,
            });
        }
    };

    const onShowCalendar = () => {
        // setting a timeout to avoid picker dates reset, first handle apply.
        setTimeout(() => {
            setShowCalendar(true);
        });
    };

    const onHideCalendar = () => {
        // setting a timeout to avoid picker dates reset, first handle apply.
        setTimeout(() => {
            setShowCalendar(false);
        });
    };

    const config = {
        dateLimit: dateLimit || { months: 12 },
        startDate: moment(startDate),
        endDate: moment(endDate),
        minDate: moment(minDate),
        maxDate: maxDate ? moment(maxDate) : undefined,
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: translateRanges,
        linkedCalendars,
        opens,
        drops,
        autoApply: shouldAutoApply,
        buttonClasses: ['btn btn-default'],
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm',
        separator: ' to ',
        singleDatePicker,
        locale: {
            applyLabel: translate('STATIC.BUTTONS.APPLY'),
            format: 'YYYY-MM-DD',
            cancelLabel: translate('STATIC.BUTTONS.CANCEL'),
            fromLabel: translate('STATIC.BUTTONS.DATEPICKER.FROM'),
            toLabel: translate('STATIC.BUTTONS.DATEPICKER.TO'),
            customRangeLabel: translate('STATIC.BUTTONS.CUSTOM'),
            firstDay: 1,
        },
        containerStyles: {
            width: '100%',
            display: 'inline-block',
        },
    };

    return (
        <div
            className={`${containerClass} ${css.container} ${id} ${showCalendar ? css.open : ''} ${
                Object.keys(ranges || {}).length === 0 ? css.hideRanges : ''
            } ${singleDatePicker ? css.singleDatePicker : ''}`}
            style={{ ...containerStyle }}
            data-testid={DATE_PICKER_TEST_ID}
        >
            {invalidated ? null : disabled ? (
                <CalendarButton
                    startDate={startDate}
                    endDate={endDate}
                    disabled
                    singleDatePicker={singleDatePicker}
                    buttonType={buttonType}
                    title={title}
                    activeLanguageCode={activeLanguage.code}
                    buttonContainerClass={buttonContainerClass}
                    dateFormat={dateFormat}
                />
            ) : (
                <DateRangePicker
                    {...config}
                    parentEl={appendToBody ? null : `.${css.container}.${id}`}
                    onApply={handleApply}
                    onShow={onShowCalendar}
                    onHide={onHideCalendar}
                >
                    <CalendarButton
                        startDate={startDate}
                        endDate={endDate}
                        singleDatePicker={singleDatePicker}
                        buttonType={buttonType}
                        title={title}
                        activeLanguageCode={activeLanguage.code}
                        buttonContainerClass={buttonContainerClass}
                        dateFormat={dateFormat}
                    />
                </DateRangePicker>
            )}
        </div>
    );
}

const CalendarButton = React.memo(
    ({
        title,
        disabled,
        buttonContainerClass,
        buttonType,
        startDate,
        endDate,
        dateFormat,
        activeLanguageCode,
        singleDatePicker,
    }) => {
        const formattedStartDate = moment(startDate)
            .locale(activeLanguageCode)
            .format(dateFormat);
        const formattedEndDate = moment(endDate)
            .locale(activeLanguageCode)
            .format(dateFormat);
        return (
            <div>
                {title && (
                    <div className={css.title}>
                        <Translate id={title[0]} data={title[1]} />
                    </div>
                )}
                <button
                    className={classNames(css.buttonContainer, { [css.disabled]: disabled }, buttonContainerClass)}
                    style={{ width: '100%' }}
                    type={buttonType}
                    disabled={disabled}
                >
                    <div className={classNames(css.iconContainer, { [css.disabled]: disabled })}>
                        <DateRangeIcon className={classNames(css.icon, { [css.disabled]: disabled })} />
                    </div>
                    <div className={classNames(css.dateRange, { [css.disabled]: disabled })}>
                        {singleDatePicker
                            ? formattedStartDate
                            : endDate && startDate
                            ? `${formattedStartDate} - ${formattedEndDate}`
                            : ' '}
                    </div>
                </button>
            </div>
        );
    }
);

CalendarButton.propTypes = {
    title: PropTypes.array,
    buttonContainerClass: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    buttonType: PropTypes.string,
    activeLanguageCode: PropTypes.string,
    disabled: PropTypes.bool,
    singleDatePicker: PropTypes.bool,
    dateFormat: PropTypes.string,
};

CalendarButton.defaultProps = {
    disabled: false,
};

Datepicker.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    dateLimit: PropTypes.objectOf(PropTypes.any),
    linkedCalendars: PropTypes.bool,
    onApply: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
    activeLanguage: PropTypes.shape({ code: PropTypes.string, active: PropTypes.bool, name: PropTypes.string }),
    dateFormat: PropTypes.string,
    opens: PropTypes.string,
    drops: PropTypes.string,
    id: PropTypes.string,
    ranges: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.arrayOf(PropTypes.any),
    dynamic: PropTypes.bool,
    translate: PropTypes.func,
    appendToBody: PropTypes.bool,
    buttonContainerClass: PropTypes.string,
    buttonType: PropTypes.string,
    disabled: PropTypes.bool,
    autoApply: PropTypes.bool,
    singleDatePicker: PropTypes.bool,
};

Datepicker.defaultProps = {
    startDate: '',
    endDate: '',
    minDate: '2012-01-01',
    maxDate: undefined,
    dateLimit: undefined,
    linkedCalendars: false,
    onApply: () => {},
    containerStyle: {},
    containerClass: '',
    activeLanguage: { code: 'en' },
    dateFormat: 'll',
    opens: 'right',
    drops: 'down',
    id: 'null',
    ranges: datePickerTimes,
    title: ['', {}],
    dynamic: false,
    translate: () => {},
    appendToBody: false,
    buttonContainerClass: '',
    buttonType: 'submit',
    disabled: false,
    autoApply: false,
    singleDatePicker: false,
};

export default withLocalize(Datepicker);
