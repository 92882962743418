import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_STATUS_SUCCESS } from '../consts';
import SingularLoader from '../../../../dashboard/js/common/components/SingularLoader';
import CheckoutAPI from '../service';

const serviceApi = new CheckoutAPI();

const CheckoutStatus = ({ sessionId, onDone }) => {
    const fetchSessionStatus = useCallback(() => {
        if (sessionId) {
            return serviceApi.getSessionStatus(sessionId);
        }
        return Promise.resolve();
    }, [sessionId]);

    useEffect(() => {
        fetchSessionStatus().then(res => {
            const status = res?.value?.status;
            if (status === PAYMENT_STATUS_SUCCESS) {
                onDone();
            }
        });
    }, [fetchSessionStatus, onDone]);

    return <SingularLoader />;
};

CheckoutStatus.propTypes = {
    sessionId: PropTypes.string,
    onDone: PropTypes.func.isRequired,
};
CheckoutStatus.defaultProps = {
    sessionId: '',
};

export default CheckoutStatus;
