import { connect } from 'react-redux';
import { deleteUserSlackAuth } from 'dataSources/actions';
import AlertsPage from './AlertsPage';
import { getUserData } from '../selectors/user';

const mapStateToProps = state => {
    return {
        userData: getUserData(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onDeleteUserSlackAuth: () => dispatch(deleteUserSlackAuth()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage);
