import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ANDROID_NATIVE, IOS_NATIVE, SDK_INTEGRATION_SECTIONS, TARGET_PLATFORM_TO_PARAM, TARGET_PLATFORM_SHORT_NAME } from '../consts';
import useSDKConfigForPlanning from '../hooks/useGetSDKConfigForPlanning';
import { useGetLatestSDKVersion } from '../hooks/useGetLatestSDKVersion';
import { DEFAULT_MEDIATOR } from '../components/sections/AdMonetization/consts';
import { isObjectEmpty } from '../../../utils/general';

const initialState = {
    selectedTargetPlatform: IOS_NATIVE,
    sdkConfigForPlanning: {},
    sdkVersion: '',
    setSelectedTargetPlatform: () => {},
};

const sdkPlanningContext = createContext(initialState);
const useSDKPlanningContext = () => useContext(sdkPlanningContext);

const SDKPlanningProvider = ({ children }) => {
    const [selectedTargetPlatform, setSelectedTargetPlatform] = useState(initialState.selectedTargetPlatform);

    const { sdkConfigForPlanning = {}, sdkConfigChoicesMetadata = {} } = useSDKConfigForPlanning();
    const { value: sdkVersion } = useGetLatestSDKVersion(TARGET_PLATFORM_TO_PARAM[selectedTargetPlatform]);

    const { Provider } = sdkPlanningContext;
    const selectedTargetPlatformShortName = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];

    return (
        <Provider
            value={{
                selectedTargetPlatform,
                setSelectedTargetPlatform,
                selectedTargetPlatformShortName,
                sdkConfigForPlanning,
                sdkConfigChoicesMetadata,
                sdkVersion,
            }}
        >
            {children}
        </Provider>
    );
};

SDKPlanningProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SDKPlanningProvider, useSDKPlanningContext };
