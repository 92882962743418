import React from 'react';
import { localize } from 'react-localize-redux';
import { SwitchField } from '../../components/widgets';
import css from './EditOrgAdAccounts.css';

class EditOrgAdAccounts extends React.PureComponent {
    render() {
        const { edit, toggleAdAccount } = this.props;

        if (!edit.ad_accounts || !edit.ad_accounts.length) {
            return (
                <div style={{ textAlign: 'left', fontSize: '15px', marginTop: '20px' }}>
                    Please add a {edit.display_name} <a href="#/data-sources">Data Connector</a> before configuring this
                    integration.
                </div>
            );
        }
        return (
            <div className={css.accountsContainer}>
                <table className={`table table-hover ${css.accountsTable}`}>
                    <thead>
                        <tr>
                            <th className={css.tableHeader}>ID</th>
                            <th className={css.tableHeader}>Name</th>
                            <th className={css.tableHeader}>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        {edit.ad_accounts.map((acc) => {
                            let active = false;
                            if (
                                edit.distribution &&
                                edit.distribution.active &&
                                edit.distribution.config &&
                                'enabled_ad_accounts' in edit.distribution.config &&
                                typeof edit.distribution.config.enabled_ad_accounts === 'object'
                            ) {
                                if (acc.account_id in edit.distribution.config.enabled_ad_accounts) {
                                    active = true;
                                }
                            }
                            return (
                                <tr key={acc.account_id}>
                                    <td>{acc.account_id}</td>
                                    <td>{acc.account_name}</td>
                                    <td>
                                        <SwitchField
                                            checked={active}
                                            onChange={(checked) => {
                                                toggleAdAccount(acc, checked);
                                            }}
                                            type="small"
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default EditOrgAdAccounts;
