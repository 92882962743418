export const TRANSPARENCY_COLUMN_LIST = [
    'custom_impressions',
    'adn_impressions',
    'custom_clicks',
    'adn_cost',
    'custom_cost',
    'custom_installs',
];

export const PUBLISHER_ESTIMATED_INDICATION = 'indication___publisher_estimated';
export const INTERPOLATION_INDICATION_FILED_NAMES = [PUBLISHER_ESTIMATED_INDICATION];
export const INTERPOLATION_RELEVANT_COLUMNS = {
    [PUBLISHER_ESTIMATED_INDICATION]: ['publisher_id', 'publisher_site_id', 'publisher_site_name'],
};
export const INTERPOLATION_AFFECTED_COLUMNS = ['adn_cost', 'custom_clicks', 'custom_impressions'];

export const INDICATION_FIELDS_PREFIX = 'indication___';

export const ADMON_DATA_SOURCE_INTERPOLATION = 'interpolation';
export const ADMON_DATA_SOURCE_CALCULATED = 'calculated';
export const ADMON_DATA_AD_REVENUE_SOURCE = 'ad_revenue_source';

export const ADMON_DATA_SOURCE_DISPLAY_NAMES = { [ADMON_DATA_SOURCE_INTERPOLATION]: ADMON_DATA_SOURCE_CALCULATED };

export const ADMON_ALIGNMENT_SOURCE_NAME = 'Unattributed Ad Revenue';

export const FIELDS_AVAILABILITY_BUTTON_ID = 'fieldsAvailability';
export const SHARED_REPORTS_BUTTON_ID = 'sharedReports';
export const DUPLICATE_BOOKMARK_BUTTON_ID = 'duplicateBookmark';
export const BOOKMARK_BUTTON_ID = 'bookmark';
export const EXPORT_TO_GOOGLE_SHEETS_BUTTON_ID = 'exportToGoogleSheets';
export const COPY_CSV_TO_CLIPBOARD_BUTTON_ID = 'copyCsvToClipboard';
export const DOWNLOAD_CSV_BUTTON_ID = 'downloadCsv';

export const DATE_TEXT_FORMAT = 'll';

export const APP_FIELD = 'app';

export const FACEBOOK_DISPLAY_NAME = 'Facebook';

export const FACEBOOK_CENSORING_MESSAGE_TRANSLATE = 'STATIC.PAGES.REPORTS.FB_CENSORED_DATA';
export const FACEBOOK_CENSORING_MESSAGE = 'Data is censored due to Meta/Facebook privacy requirements.';

export const REPORT_EXCEEDS_SIZE_LIMIT_ERROR = 'REPORT_EXCEEDS_SIZE_LIMIT_ERROR';
