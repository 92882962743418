import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Table, TableCellDropdown, TableCellImgLabel, Spinner, Toggle, EmptyState } from '../../components/widgets';
import css from './DataConnectorAccounts.css';
import { useFetchAccountSourceMapping } from '../hooks';
import { getAccountSourceMappingList, parseAccountsData } from '../utils';

const thStyle = { padding: '8px', textAlign: 'center', fontWeight: 600 };
const tdStyle = { height: '56px' };

const DataConnectorAccounts = ({
    isEmptyMccId,
    adnId,
    accountsData,
    onToggleSelectAll,
    onToggleSingleRow,
    onAccountSourceMapChange,
    newAccountsToggle,
    onAutoAddAccounts,
    showViewAs,
}) => {
    const accountSourceMapResponse = useFetchAccountSourceMapping(adnId);

    const tableMetadata = [
        {
            name: 'name',
            cellComponent: TableCellImgLabel,
            displayName: 'Account Name',
            placeholder: 'account name',
            headerAlign: 'center',
            thStyle: { ...thStyle, width: showViewAs ? '215px' : '450px' },
            cellValues: [
                { propName: 'subText', dataKey: 'subText' },
                { propName: 'ImgComponent', dataKey: 'imgComponent' },
            ],
            cellProps: {
                tdStyle: { ...tdStyle, maxWidth: showViewAs ? '215px' : '450px' },
                useDefaultEmptyImg: false,
                imgStyle: {
                    height: '18px',
                    maxWidth: '18px',
                },
                subTextStyle: {
                    fontSize: '11px',
                    fontStyle: 'italic',
                    color: '#FFAA20',
                },
                reverseOrder: true,
            },
        },
        {
            name: 'id',
            displayName: 'Account ID',
            placeholder: 'account ID',
            headerAlign: 'center',
            thStyle: { ...thStyle, width: '140px' },
            cellProps: {
                tdStyle,
            },
        },
    ];

    if (showViewAs) {
        tableMetadata.push({
            name: 'source',
            displayName: 'View This Source As:',
            placeholder: 'source',
            headerAlign: 'center',
            thStyle: { ...thStyle },
            cellComponent: TableCellDropdown,
            headerTooltip: 'STATIC.PAGES.DATA_SOURCES.SHELF.ACCOUNTS.VIEW_AS_TOOLTIP',
            cellProps: {
                valuesList: getAccountSourceMappingList(accountSourceMapResponse),
                tdStyle: { ...tdStyle, padding: '10px 14px' },
                onValueSelection: (newSourceMap, rowIndex) => onAccountSourceMapChange(newSourceMap, rowIndex),
            },
            cellValues: [
                { dataKey: 'selected', propName: 'selected' },
                { dataKey: 'disabled', propName: 'disabled' },
            ],
        });
    }

    return (
        <div className={css.accountsTableContainer}>
            {isEmptyMccId ? (
                <div className={css.noAccountsContainer}>
                    <EmptyState icon="happyPage" header="STATIC.PAGES.DATA_SOURCES.SHELF.EMPTY_MCC_ID" />
                </div>
            ) : !accountsData ? (
                <React.Fragment>
                    <div className={css.loaderContainer}>
                        <Spinner show style={{ width: '64px' }} />
                        <div className={css.loadingText}>
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.LOADING_ACCOUNTS" />
                        </div>
                    </div>
                </React.Fragment>
            ) : accountsData.length === 0 ? (
                <div className={css.noAccountsContainer}>
                    <EmptyState icon="default" header="STATIC.PAGES.DATA_SOURCES.SHELF.NO_AD_ACCOUNTS" />
                </div>
            ) : (
                accountsData.length > 0 && (
                    <React.Fragment>
                        <div className={css.toggleRowContainer}>
                            <span className={css.accountsHeader}>Accounts</span>
                            <Toggle
                                style={{ width: '270px' }}
                                checked={newAccountsToggle}
                                label={
                                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.ACCOUNTS.AUTOMATICALLY_ACTIVATE" />
                                }
                                onToggle={onAutoAddAccounts}
                            />
                        </div>
                        <div className={css.tableContainer}>
                            <Table
                                metadata={tableMetadata}
                                data={parseAccountsData(accountsData)}
                                zebraTable={false}
                                selectable
                                filterable
                                onToggleSelectAll={onToggleSelectAll}
                                onToggleSingleRow={onToggleSingleRow}
                                thClass={css.tableHeader}
                                tableClass={css.table}
                                thFiltersClass={css.tableFiltersHeader}
                            />
                        </div>
                    </React.Fragment>
                )
            )}
        </div>
    );
};

DataConnectorAccounts.propTypes = {
    isEmptyMccId: PropTypes.bool,
    adnId: PropTypes.number,
    accountsData: PropTypes.arrayOf(PropTypes.any),
    newAccountsToggle: PropTypes.bool,
    showViewAs: PropTypes.bool,
    onToggleSelectAll: PropTypes.func,
    onToggleSingleRow: PropTypes.func,
    onAccountSourceMapChange: PropTypes.func,
    onAutoAddAccounts: PropTypes.func,
};

DataConnectorAccounts.defaultProps = {
    isEmptyMccId: false,
    adnId: null,
    accountsData: null,
    newAccountsToggle: false,
    showViewAs: false,
    onToggleSelectAll: () => {},
    onToggleSingleRow: () => {},
    onAccountSourceMapChange: () => {},
    onAutoAddAccounts: () => {},
};

export default DataConnectorAccounts;
