import { call, put, take, delay } from 'redux-saga/effects';
import AudienceDistributionsAPI from './service';
import {
    load,
    unload,
    editSaveFail,
    editSaveDone,
    editSegmentSaveDone,
    editSegmentSaveDoneDelayed,
    toggleAdAccountDone,
    EDIT_SAVE,
    EDIT_SEGMENT_SAVE,
    TOGGLE_AD_ACCOUNT,
    MANUAL_RUN,
} from './actions';

const api = new AudienceDistributionsAPI();

function* onload() {
    try {
        const response = yield call(api.getDistributions);
        yield put(load(response.networks, response.segments, response.types, response.company_logo));
    } catch (e) {
        // handle error
    }
}

function* destroy() {
    yield put(unload());
}

function* editSave() {
    while (true) {
        const { network } = yield take(EDIT_SAVE);
        const res = yield call(api.save, network);
        if (res.error) {
            yield put(editSaveFail(res.error));
        } else {
            yield put(editSaveDone(network, res.id));
        }
    }
}

function* editSegmentSave() {
    while (true) {
        const { segment } = yield take(EDIT_SEGMENT_SAVE);
        try {
            const res = yield call(api.saveSegment, {
                ...segment.edit,
                id: segment.edit.config.config_id,
                active: segment.edit.config.active,
            });
            if (res.error) {
                yield put(editSaveFail(res.error));
            } else {
                yield put(editSegmentSaveDone(segment.edit, res.id));
                yield delay(3000);
                yield put(editSegmentSaveDoneDelayed(segment.edit));
            }
        } catch (e) {
            yield put(editSaveFail('Error saving configuration'));
        }
    }
}

function* toggleAdAccount() {
    while (true) {
        const { network, account, active } = yield take(TOGGLE_AD_ACCOUNT);
        const res = yield call(api.toggleAdAccount, network, account, active);
        yield put(toggleAdAccountDone(res.id, res.config));
    }
}

function* manualRun() {
    while (true) {
        const { uanId } = yield take(MANUAL_RUN);
        const { task_id: taskId } = yield call(api.manualRun, uanId);
        if (taskId) {
            alert(`Task started with id: ${taskId}`);
        } else {
            alert('Task failed to start');
        }
    }
}

const watchers = [editSave, editSegmentSave, toggleAdAccount, manualRun];

export { onload, destroy, watchers };
