import { connect } from 'react-redux';
import { getFieldsForType } from '../selectors/fields';
import {
    filterFieldSelected,
    filterOperatorSelected,
    filterValueAdded,
    filterValueRemoved,
    filterRemove,
    filterAdd,
    setActionType,
} from '../actions/reports';
import { getCalcFilterForType, getAvailableFiltersForType } from '../selectors/reports';
import FilterFields from '../components/partials/FilterFields';

const mapStateToProps = (state, ownProps) => {
    return {
        fields: getAvailableFiltersForType(ownProps.reportType)(state),
        operators: getFieldsForType('filterOperators')(state),
        filters: getCalcFilterForType(ownProps.reportType)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFieldSelection(field, index) {
            dispatch(setActionType(filterFieldSelected(field, index), ownProps.reportType));
        },
        onOperatorSelection(field, index) {
            dispatch(setActionType(filterOperatorSelected(field, index), ownProps.reportType));
        },
        onFilterValueAdded(value, index) {
            dispatch(setActionType(filterValueAdded(value, index), ownProps.reportType));
        },
        onFilterValueRemoved(value, index) {
            dispatch(setActionType(filterValueRemoved(value, index), ownProps.reportType));
        },
        onRemoveFilter(index) {
            dispatch(setActionType(filterRemove(index), ownProps.reportType));
        },
        onAddFilter() {
            dispatch(setActionType(filterAdd(), ownProps.reportType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterFields);
