import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <strong>What:</strong><span> Configure Facebook in your Singular account. This will let Singular track how many installs your apps have had a result of advertising on </span><strong>Facebook
                and Instagram</strong><span>. You’ll see these installs in your reports and you’ll be able to evaluate them against your ad spend (see </span><strong>Run
                an ROI Report</strong><span>).</span>
            </p>
            <p>
                <span>To set up Facebook:</span>
            </p>
            <ol>
                <li>
                    <span>Get your Facebook App ID from your</span><a
                    href="https://developers.facebook.com/apps" target="_blank"> Facebook App Dashboard</a><span>.</span>
                </li>
                <li>
                    <span>In Singular, go to </span><strong>Partner Configurations</strong><span
                > and add “Facebook”.</span>
                </li>
                <li>
                    <span>In the </span><strong>Enable Integration</strong><span
                > window, confirm you have signed the </span><a
                    href="https://www.facebook.com/ads/manage/advanced_mobile_measurement/app_based_tos" target="_blank"><span
                >Facebook Advanced Mobile Measurement agreement</span></a><span
                >.</span>
                </li>
                <li>
                    <span>In the settings window, enter the Facebook App ID, and then configure your attribution settings. For example, you can enable or disable view-through tracking.</span>
                </li>
            </ol>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/115003252706-Facebook" target="_blank">See our guide to setting up Facebook</a><span
            >.</span>
            </p>
        </div>
    );
}
