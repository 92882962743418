import React from 'react';
import SocialIcon from '@mui/icons-material/People';
import SMSIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import CrossPromoIcon from '@mui/icons-material/NewReleases';
import CustomIcon from '@mui/icons-material/ModeEdit';
import { PageHeader, PageWrapper } from '../partials';
import { Label, FieldGroup, ActionButton } from '../widgets';

import css from './CreateSmartLink.css';

const generalSection = [
    {
        id: 'app',
        label: 'STATIC.APP',
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PLACEHOLDERS.SELECT_APP',
                },
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
    {
        id: 'source',
        label: 'STATIC.SOURCE',
        group: {
            type: 'single',
            data: {
                buttonStyle: {
                    width: '100px',
                    height: '80px',
                    marginRight: '10px',
                },
            },
        },
        fields: [
            {
                name: 'social',
                display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.SOURCES.SOCIAL',
                icon: <SocialIcon />,
                persistentIcon: true,
            },
            {
                name: 'sms',
                display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.SOURCES.SMS',
                icon: <SMSIcon />,
                persistentIcon: true,
            },
            {
                name: 'email',
                display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.SOURCES.EMAIL',
                icon: <EmailIcon />,
                persistentIcon: true,
            },
            {
                name: 'crosspromo',
                display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.SOURCES.CROSSPROMO',
                icon: <CrossPromoIcon />,
                persistentIcon: true,
            },
            {
                name: 'custom',
                display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.SOURCES.CUSTOM',
                icon: <CustomIcon />,
                persistentIcon: true,
            },
        ],
    },
    {
        id: 'customName',
        label: 'STATIC.PAGES.GENERATE_SMART_LINK.CUSTOM_NAME',
        hidden: false,
        group: {
            type: 'autocomplete',
            data: {
                placeholder: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.CUSTOM_NAME',
                selectOptions: {
                    options: [
                        {
                            value: 'aaa',
                            label: 'aaa',
                        },
                        {
                            value: 'bbb',
                            label: 'bbb',
                        },
                    ],
                },
            },
        },
    },
    {
        id: 'campaignName',
        label: 'STATIC.PAGES.GENERATE_TRACKING_LINK.CAMPAIGN_NAME',
        group: {
            type: 'autocomplete',
            data: {
                placeholder: 'STATIC.PAGES.GENERATE_TRACKING_LINK.PLACEHOLDER.CAMPAIGN_NAME',
                selectOptions: {
                    options: [
                        {
                            value: 'aaa',
                            label: 'aaa',
                        },
                        {
                            value: 'bbb',
                            label: 'bbb',
                        },
                    ],
                },
            },
        },
    },
];

const smartDestinations = [
    {
        id: 'iosDest',
        label: 'IOS',
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.SELECT_APP_FIRST',
                },
                disabled: true,
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
    {
        id: 'iosDeepLink',
        label: {
            text: 'STATIC.PAGES.GENERATE_SMART_LINK.SMART_DESTINATION_DEPPLINK_LABEL',
            args: { platform: 'IOS' },
        },
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.SELECT_APP_FIRST',
                },
                disabled: true,
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
    {
        id: 'iosUniversalLink',
        label: {
            text: 'STATIC.PAGES.GENERATE_SMART_LINK.SMART_DESTINATION_UNIVERSAL_LINK_LABEL',
            args: { platform: 'IOS' },
        },
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.SELECT_APP_FIRST',
                },
                disabled: true,
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
    {
        id: 'androidDest',
        label: {
            text: 'STATIC.PAGES.GENERATE_SMART_LINK.SMART_DESTINATION_UNIVERSAL_LINK_LABEL',
            args: { platform: 'Android' },
        },
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.SELECT_APP_FIRST',
                },
                disabled: true,
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
    {
        id: 'androidUniversalLink',
        label: {
            text: 'STATIC.PAGES.GENERATE_SMART_LINK.SMART_DESTINATION_DEPPLINK_LABEL',
            args: { platform: 'Android' },
        },
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.SELECT_APP_FIRST',
                },
                disabled: true,
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
    {
        id: 'other',
        label: 'STATIC.PAGES.GENERATE_SMART_LINK.OTHER_PLATFORMS',
        group: {
            type: 'dropdown',
            data: {
                selected: {
                    name: 'default',
                    display_name: 'STATIC.PAGES.GENERATE_SMART_LINK.PLACEHOLDER.SELECT_APP_FIRST',
                },
                disabled: true,
                containerStyle: {
                    width: '100%',
                },
            },
        },
        fields: [
            {
                name: 'aaa',
                display_name: 'aaa',
            },
        ],
    },
];

class CreateSmartLink extends React.Component {
    constructor(props) {
        super(props);
        this.handleGeneralClick = this._handleGeneralClick.bind(this);
    }

    _handleGeneralClick(field) {
        console.log(field);
    }

    render() {
        return (
            <PageWrapper>
                <PageHeader text="STATIC.PAGE_HEADERS.CUSTOM_TRACKING_LINKS" leftComponents={[]} rightComponent={[]} />
                <Label text="STATIC.PAGES.GENERATE_SMART_LINK.SUB_HEADER" type="h3" />
                <Label text="STATIC.PAGES.GENERATE_SMART_LINK.GENERAL_SECTION" type="h2" />
                <div>
                    {generalSection
                        .filter((item) => !item.hidden)
                        .map((item) => (
                            <div key={item.id} className={css.fieldGroupRow}>
                                <FieldGroup {...item} onFieldClick={this.handleGeneralClick} />
                            </div>
                        ))}
                </div>
                <div className={css.separator} />
                <Label text="STATIC.PAGES.GENERATE_SMART_LINK.SMART_DESTINATIONS_SECTION" type="h2" />
                <div>
                    {smartDestinations
                        .filter((item) => !item.hidden)
                        .map((item) => (
                            <div key={item.id} className={css.fieldGroupRow}>
                                <FieldGroup {...item} onFieldClick={this.handleGeneralClick} />
                            </div>
                        ))}
                </div>
                <Label text="STATIC.PAGES.GENERATE_SMART_LINK.TIP2" type="h3" />
                <div className={css.separator} />
                <ActionButton text="STATIC.PAGES.GENERATE_TRACKING_LINK.GENERATE_LINK" />
            </PageWrapper>
        );
    }
}

CreateSmartLink.propTypes = {};

CreateSmartLink.defaultProps = {};

export default CreateSmartLink;
