import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import css from '../PartnerConfigurationShelf.css';
import { organizationOptionsShape, partnerShape, siteShape, SupportTypes, TemplateTypes } from '../../types';
import { SmallDialog, Table, TableCell } from '../../../../components/widgets';
import TableCellCheckbox from '../../../../components/widgets/TableCellCheckbox';
import {
    getPostbackSupported,
    getSendAllEventsTooltip,
    getTemplate,
    isDisabled,
    orgSupportAdvancedPostbacksFeatures,
} from '../utils';
import { getCheckboxCellProps } from '../tableCellsUtils';
import TableActions from '../../../../components/widgets/TableActions';
import tableActionsCss from '../../../../components/widgets/TableActions.css';
import AdvancedPostbackSettingsFactory from '../advancedPostbackSettings/AdvancedPostbackSettingsFactory';
import advancedPostbackCss from '../advancedPostbackSettings/AdvancedPostbackSettings.css';
import { getIsAgency } from '../../../../selectors/user';

export const TABLE_DATA_TEST_ID = 'attribution-postbacks-table';
export const AttributionTypes = {
    INSTALL: 'install',
    REENGAGEMENT: 'reengagement',
};

const isSelected = (partnerSupportType, configValue) =>
    partnerSupportType !== SupportTypes.NEVER &&
    (partnerSupportType === SupportTypes.MANDATORY || (configValue ?? partnerSupportType === SupportTypes.RECOMMENDED));

const getTranslatedPostbackType = (translate, type) =>
    translate(`STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.${type?.toUpperCase()}`);

const getTooltip = supportType => {
    if (supportType === SupportTypes.NEVER)
        return 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.NOT_SUPPORTED';
    if (supportType === SupportTypes.MANDATORY)
        return 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.MANDATORY';

    return null;
};

function AttributionPostbacks({
    partner,
    site,
    isReadonly,
    translate,
    orgOptions,
    viewThroughLabel,
    viewThroughTooltip,
}) {
    const { values, initialValues, setFieldTouched, setFieldValue } = useFormikContext();
    const [editedPostback, setEditedPostback] = useState(null);

    const isAgency = useSelector(state => getIsAgency(state));
    const { sendAllInstalls, viewthroughSupport, fraudPostbackSupport } = partner;

    const fraudTemplate = getTemplate(partner, site.platform, TemplateTypes.FRAUD);
    const installTemplate = getTemplate(partner, site.platform, TemplateTypes.INSTALL);
    const installSupport = getPostbackSupported(installTemplate, partner.installSupport);
    const reengagementSupport = getPostbackSupported(installTemplate, partner.reengagementSupport);
    const advancedPostbackSupport = orgSupportAdvancedPostbacksFeatures(orgOptions);

    const getSelectedFields = (selected, sendViewThrough, sendAll) => {
        return {
            selected,
            sendViewThrough: selected ? isSelected(viewthroughSupport, sendViewThrough) : false,
            sendAll: selected ? isSelected(sendAllInstalls, sendAll) : false,
            fraudEnabled: selected ? isSelected(fraudPostbackSupport, values.fraudEnabled) && !!fraudTemplate : false,
        };
    };

    const getRowData = (attributionType, supportType) => {
        const { sendAll, sendViewThrough, campaignRegex, customByUser, postbackUri } = values[attributionType] || {};
        const selected = isSelected(supportType, values[attributionType]?.selected);

        const rowData = {
            ...getSelectedFields(selected, sendViewThrough, sendAll),
            attributionType,
            campaignRegex,
            customByUser,
            postbackUri,
            selectDisabled: supportType === SupportTypes.MANDATORY,
            selectTooltipText: getTooltip(supportType),
            selectTooltipData: { partnerName: partner.displayName, attributionType },
        };

        if (supportType === SupportTypes.NEVER || !selected || isReadonly) {
            // return disabled row
            return {
                ...rowData,
                selectDisabled: supportType === SupportTypes.NEVER,
                disabled: true,
            };
        }

        return rowData;
    };

    const installRow = getRowData(AttributionTypes.INSTALL, installSupport);
    const reengagementRow = getRowData(AttributionTypes.REENGAGEMENT, reengagementSupport);

    const clearEditedPostback = () => setEditedPostback(null);

    const getInitialValues = (attributionType, { selected, sendAll, sendViewThrough }) => ({
        selected,
        ...(initialValues[attributionType] || {}),
        ...(values[attributionType] || {}),
        sendAll,
        sendViewThrough,
    });

    useEffect(() => {
        setFieldValue(AttributionTypes.INSTALL, getInitialValues(AttributionTypes.INSTALL, installRow));
        setFieldValue(AttributionTypes.REENGAGEMENT, getInitialValues(AttributionTypes.REENGAGEMENT, reengagementRow));
        setFieldValue(
            'fraudEnabled',
            values.fraudEnabled ?? (isSelected(fraudPostbackSupport, initialValues.fraudEnabled) && !!fraudTemplate)
        );
    }, [initialValues]);

    const handleSelectedChange = (selectedValue, currentRow) => {
        // when selecting unchecked row for the first time, init values
        if (initialValues && !initialValues?.[currentRow.attributionType]?.selected && selectedValue) {
            const selectedFields = getSelectedFields(selectedValue, currentRow.sendViewThrough, currentRow.sendAll);
            setFieldValue(currentRow.attributionType, { ...currentRow, ...selectedFields });
        }
    };

    const onChange = (value, row, field) => {
        setFieldTouched(`${row.attributionType}.${field}`, true, false);
        if (field === 'selected') {
            handleSelectedChange(value, row);
        }

        if (field === 'fraudEnabled') {
            setFieldValue('fraudEnabled', value);
        } else {
            setFieldValue(`${row.attributionType}.${field}`, value);
        }
    };

    const updateAdvancedPostbackFields = (campaignRegex, customByUser, postbackUri) => {
        onChange(campaignRegex, editedPostback, 'campaignRegex');
        onChange(customByUser, editedPostback, 'customByUser');
        onChange(customByUser ? postbackUri : null, editedPostback, 'postbackUri');
        setEditedPostback(null);
    };
    const mandatoryFields = [
        {
            name: 'selected',
            displayName: '',
            cellComponent: TableCellCheckbox,
            cellValues: [
                { propName: 'tooltipText', dataKey: 'selectTooltipText' },
                { propName: 'tooltipData', dataKey: 'selectTooltipData' },
                {
                    propName: 'disabled',
                    dataKey: 'selectDisabled',
                    transformation: selectDisabled => selectDisabled || isReadonly,
                },
                {
                    propName: 'tdClassName',
                    dataKey: 'selectDisabled',
                    transformation: disabled =>
                        classNames(css.tableCell, { [css.enabledCell]: !disabled && !isReadonly }),
                },
            ],
        },
        {
            name: 'attributionType',
            headerAlign: 'center',
            displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.ATTRIBUTION_EVENT',
            headerTooltip: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.ATTRIBUTION_EVENT_TOOLTIP',
            headerTooltipData: { partnerName: partner.displayName },
            cellComponent: TableCell,
            cellProps: { className: css.attributionTypeCell },
            cellValues: [
                {
                    propName: 'value',
                    dataKey: 'attributionType',
                    transformation: type => getTranslatedPostbackType(translate, type),
                },
            ],
        },
        getCheckboxCellProps(
            'sendViewThrough',
            viewThroughLabel ?? 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SEND_VIEW_THROUGH',
            viewThroughTooltip ??
                'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SEND_VIEW_THROUGH_TOOLTIP',
            {},
            isDisabled(viewthroughSupport, isReadonly),
            {
                tooltipText: getTooltip(viewthroughSupport),
                tooltipData: { partnerName: partner.displayName, attributionType: 'View Through Attribution' },
            }
        ),
        getCheckboxCellProps(
            'sendAll',
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SEND_ALL_EVENTS',
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SEND_ALL_TOOLTIP',
            { partnerName: partner.displayName },
            isDisabled(sendAllInstalls, isReadonly || isAgency),
            {
                tooltipText: getSendAllEventsTooltip(sendAllInstalls, isReadonly),
                tooltipData: { partnerName: partner.displayName },
            }
        ),
        getCheckboxCellProps(
            'fraudEnabled',
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SEND_FRAUD_POSTBACKS',
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SEND_FRAUD_POSTBACKS_TOOLTIP',
            { partnerName: partner.displayName },
            isDisabled(fraudPostbackSupport, isReadonly) || !fraudTemplate,
            {
                tooltipText: getTooltip(fraudPostbackSupport),
                tooltipData: { partnerName: partner.displayName, attributionType: 'Sending Fraud Postbacks' },
            }
        ),
    ];

    const optionalFields =
        advancedPostbackSupport && installSupport
            ? [
                  {
                      key: 'edit',
                      displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_POSTBACK',
                      headerAlign: 'center',
                      headerTooltip: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_POSTBACK_TOOLTIP',
                      cellComponent: TableActions,
                      cellProps: {
                          onEdit: i => setEditedPostback(i === 0 ? installRow : reengagementRow),
                          editTooltip: i =>
                              (i === 0 ? installRow : reengagementRow)?.customByUser
                                  ? translate('STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.CUSTOM_POSTBACK_TOOLTIP', {
                                        partnerName: partner.displayName,
                                    })
                                  : null,
                          editClassName: i => {
                              const postback = i === 0 ? installRow : reengagementRow;
                              return classNames(
                                  {
                                      [tableActionsCss.disabled]: !postback.selected,
                                      [tableActionsCss.dot]: postback.customByUser && postback.selected,
                                  },
                                  css.editAction
                              );
                          },
                      },
                  },
              ]
            : [];

    const tableMetadata = [...mandatoryFields, ...optionalFields];

    return (
        <div className={css.section}>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.ATTRIBUTION_POSTBACKS" />
            </div>
            <div className={css.subtitle}>
                <Translate
                    id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.ATTRIBUTION_POSTBACKS_SUBTITLE"
                    data={{ partnerName: partner.displayName }}
                />
            </div>
            <Table
                dataTestId={TABLE_DATA_TEST_ID}
                data={[installRow, reengagementRow]}
                metadata={tableMetadata}
                zebraTable={false}
                trClass={css.tableRow}
                onToggleSingleRow={onChange}
            />
            <SmallDialog
                open={!!editedPostback}
                onClose={clearEditedPostback}
                onEscapePress={clearEditedPostback}
                classes={{ paperScrollBody: classNames(advancedPostbackCss.postbackPopup) }}
            >
                {editedPostback && (
                    <AdvancedPostbackSettingsFactory
                        onReject={clearEditedPostback}
                        orgOptions={orgOptions}
                        partner={partner}
                        template={installTemplate}
                        postback={editedPostback}
                        isReadonly={isReadonly}
                        eventName={getTranslatedPostbackType(translate, editedPostback.attributionType)}
                        onAccept={updateAdvancedPostbackFields}
                    />
                )}
            </SmallDialog>
        </div>
    );
}

AttributionPostbacks.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    isReadonly: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    viewThroughLabel: PropTypes.string,
    viewThroughTooltip: PropTypes.string ,
};

AttributionPostbacks.defaultProps = {
    isReadonly: false,
};

export default withLocalize(AttributionPostbacks);
