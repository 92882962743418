import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik-2';
import classNames from 'classnames';
import {
    partnerShape,
    eventShape,
    siteShape,
    templateShape,
    TemplateTypes,
    organizationOptionsShape,
} from '../../types';
import css from '../PartnerConfigurationShelf.css';
import eventPostbackCss from './CustomEventPostbacks.css';
import EventPostbacksData from './EventPostbacksData';

export const EVENTS_TABLE_DATA_TEST_ID = 'events-postbacks-table';

function CustomEventPostbacks({ partner, site, templates, events, isReadonly, orgOptions }) {
    const { values, setValues, errors, touched, setTouched } = useFormikContext();

    const customEvents = events.filter(event => !event.revenueEvent);
    const customEventTemplate = templates.find(template => template.type === TemplateTypes.CUSTOM);

    const addEmptyEventPostback = () => setValues({ ...values, customEventPostbacks: [] });
    const setEventPostbacksValues = customEventPostbacks => setValues({ ...values, customEventPostbacks });
    const setTouchedEventsPostbacks = customEventPostbacks => setTouched({ ...touched, customEventPostbacks });

    return (
        <div className={css.section}>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.EVENTS_POSTBACKS" />
            </div>
            {values.customEventPostbacks && customEventTemplate && customEvents.length ? null : (
                <div
                    onClick={addEmptyEventPostback}
                    className={classNames(eventPostbackCss.addEventButton, {
                        [eventPostbackCss.disabled]: !customEventTemplate || !customEvents.length || isReadonly,
                    })}
                >
                    <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.ADD_EVENT" />
                </div>
            )}
            <EventPostbacksData
                configuredEvents={values.customEventPostbacks}
                template={customEventTemplate}
                events={customEvents}
                partner={partner}
                site={site}
                tableDataTestId={EVENTS_TABLE_DATA_TEST_ID}
                templateType={TemplateTypes.CUSTOM}
                setPostbacks={setEventPostbacksValues}
                setTouched={setTouchedEventsPostbacks}
                errors={errors.customEventPostbacks}
                touched={touched.customEventPostbacks}
                isReadonly={isReadonly}
                orgOptions={orgOptions}
            />
        </div>
    );
}

CustomEventPostbacks.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    templates: PropTypes.arrayOf(templateShape),
    events: PropTypes.arrayOf(eventShape),
    isReadonly: PropTypes.bool,
};

CustomEventPostbacks.defaultProps = {
    templates: [],
    events: [],
    isReadonly: false,
};

export default CustomEventPostbacks;
