import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, PageWrapper } from '../components/partials';
import { ApiBox, ResetAPIButton } from './components';
import { userApiKey } from './selectors';
import { setApiKey } from '../actions/user';

import css from './ApiKeys.pcss';

function APIKeys() {
    const dispatch = useDispatch();
    const { apiKey = '' } = useSelector(state => userApiKey(state));

    const onAPIKeyChange = value => {
        dispatch(setApiKey(value));
    };

    return (
        <PageWrapper>
            <PageHeader
                text="STATIC.PAGES.API.REPORTING_API_KEY.TITLE"
                subText={{ faqLink: 'https://support.singular.net/hc/en-us/articles/207553433' }}
            />
            <div className={css.apiKeyContainer}>
                <ApiBox apiKey={apiKey} />
                <ResetAPIButton onAPIKeyChange={onAPIKeyChange} />
            </div>
        </PageWrapper>
    );
}

export default APIKeys;
