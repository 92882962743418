import { gql } from '@apollo/client';
import client from '../apollo';

export const GET_ALLOWED_DOMAINS = gql`
    query getAllowedDomains {
        allowedDomains
    }
`;

const GET_PARTNER = gql`
    query getPartner($partnerId: ID!) {
        partner(id: $partnerId) {
            id
            name
            mobileWebSupport
            viewthroughSupport
            reengagementSupport
        }
    }
`;

const GET_PARTNER_CONFIGURATION_FOR_APP = gql`
    query getPartnerConfigurationForApp($partnerId: Int!, $longname: String!, $platform: String!) {
        partnerConfiguration(partnerId: $partnerId, longname: $longname, platform: $platform) {
            siteId
            agencyId
        }
    }
`;

const GET_PARTNER_APP_WINDOWS = gql`
    query getPartnerAppDefaultWindows($partnerId: Int!, $longname: String!, $platform: String!) {
        partnerAppDefaultWindows(partnerId: $partnerId, longname: $longname, platform: $platform) {
            installClickWindowId
            installClickWindowIp
            installViewWindowId
            installViewWindowIp
            reengagementClickWindowId
            ctvViewWindow
            ctvPrioritizationWindow
            reengagementInactivityWindow
        }
    }
`;

export const SAVE_ALLOWED_DOMAINS = gql`
    mutation SaveAllowedDomains($allowedDomains: [String]) {
        saveAllowedDomains(allowedDomains: $allowedDomains) {
            ok
            error
        }
    }
`;

export const getPartnerDetails = async partnerId => {
    const { data } = await client.query({
        query: GET_PARTNER,
        variables: { partnerId },
    });

    return data.partner;
};

export const getPartnerConfigurationsForApp = async (partnerId, longname, platform) => {
    const { data } = await client.query({
        query: GET_PARTNER_CONFIGURATION_FOR_APP,
        variables: { partnerId, longname, platform },
    });

    return data.partnerConfiguration;
};

export const getPartnerAppWindows = async (partnerId, longname, platform) => {
    const { data } = await client.query({
        query: GET_PARTNER_APP_WINDOWS,
        variables: { partnerId, longname, platform },
    });

    return data.partnerAppDefaultWindows;
};
