import React from 'react';
import PropTypes from 'prop-types';
import css from './Legacy.css';
import { FauxButton, Label } from '../../components/widgets';

class Legacy extends React.PureComponent {
    render() {
        const { onLegacyLinkClicked, showPartnerButton, showCustomButton, label, subLabel } = this.props;
        return (
            <div className={css.legacy}>
                <Label type="h3" text={label} />
                {subLabel ? <Label type="h3" text={subLabel} /> : null}
                {showPartnerButton ? (
                    <div className={css.legacyLink}>
                        <FauxButton
                            onClick={() => {
                                const url = '#/react/link-management/create-link';
                                onLegacyLinkClicked(url, 'partner');
                                window.location.href = url;
                            }}
                        >
                            Create Legacy Partner Link
                        </FauxButton>
                    </div>
                ) : null}
                {showCustomButton ? (
                    <div className={css.legacyLink}>
                        <FauxButton
                            onClick={() => {
                                const url = '#/react/link-management/create-smart-link';
                                onLegacyLinkClicked(url, 'custom');
                                window.location.href = url;
                            }}
                        >
                            Create Legacy Custom Link
                        </FauxButton>
                    </div>
                ) : null}
            </div>
        );
    }
}

Legacy.propTypes = {
    onLegacyLinkClicked: PropTypes.func,
    showPartnerButton: PropTypes.bool,
    showCustomButton: PropTypes.bool,
    label: PropTypes.string,
    subLabel: PropTypes.string,
};

Legacy.defaultProps = {
    onLegacyLinkClicked: () => {},
    showPartnerButton: true,
    showCustomButton: true,
    label: 'STATIC.PAGES.MANAGE_LINKS.LEGACY_LINKS_TEXT',
    subLabel: '',
};

export default Legacy;
