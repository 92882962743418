import API from '../services/api';

let instance = null;
const api = new API();
const URL_DISTRIBUTIONS = '/api/audiences/distributions';

export default class AudienceABTestingService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getDistributions() {
        try {
            return api.getJson(URL_DISTRIBUTIONS, {
                method: 'GET',
                cache: true,
            });
        } catch (e) {
            console.error(e);
            return {
                error: true,
            };
        }
    }
}
