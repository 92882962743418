import React, { memo } from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './StepButtons.css';
import { SingularButton } from '../../../../components/widgets';
import MemoChevronIcon from '../../MemoChevronIcon';

const StepButtons = ({ onNext, onBack, nextTitle, backTitle, showNextIcon, isLast, showSkip }) => {
    return (
        <div className={css.buttonContainer}>
            {showSkip && (
                <div onClick={onNext} className={css.skip}>
                    <Translate id="STATIC.BUTTONS.SKIP" />
                </div>
            )}
            {backTitle && (
                <SingularButton
                    className={css.backButton}
                    textClassName={css.backText}
                    type="secondary"
                    level="level1"
                    onClick={onBack}
                >
                    <>
                        <MemoChevronIcon className={classNames(css.arrow, css.back)} />
                        <Translate id="STATIC.BUTTONS.BACK" />
                    </>
                </SingularButton>
            )}
            {nextTitle && (
                <SingularButton submit={!!isLast} type="primary" onClick={onNext}>
                    <>
                        <Translate id={nextTitle} />
                        {showNextIcon && <MemoChevronIcon className={classNames(css.arrow, css.next)} />}
                    </>
                </SingularButton>
            )}
        </div>
    );
};

StepButtons.propTypes = {
    nextTitle: PropTypes.string,
    backTitle: PropTypes.string,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    showNextIcon: PropTypes.bool,
    isLast: PropTypes.bool,
    showSkip: PropTypes.bool,
};

StepButtons.defaultProps = {
    showNextIcon: true,
    isLast: false,
    showSkip: false,
};

export default memo(StepButtons);
