import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../../components/widgets';
import fieldVisibilityRules from '../../../selectors/reportsConfig/fieldVisibilityRules';
import css from '../DataDestinationShelf.css';
import { getCohortFields, getSelectedOptions } from '../../customShemaUtils';

const MANDATORY_COHORT_PERIODS = ['p1'];

export default function SkanCustomSchemaFields({ readonly, selected, getOptions, onChange }) {
    const eventsOptions = getOptions('skanModeledCustomEvents', fieldVisibilityRules.skanSummary);
    const periodsOptions = getOptions('periods', fieldVisibilityRules.skanSummary, MANDATORY_COHORT_PERIODS);

    const [events, setEvents] = useState([]);
    const [periods, setPeriods] = useState([]);

    useEffect(() => {
        const {
            selectedEvents = [],
            selectedPeriods = periodsOptions.filter(({ value }) => MANDATORY_COHORT_PERIODS.includes(value)),
        } = getSelectedOptions(readonly, selected, [], eventsOptions, [], periodsOptions);

        setEvents(selectedEvents);
        setPeriods(selectedPeriods);
    }, [readonly, selected]);

    useEffect(() => {
        if (readonly) {
            return;
        }

        const cohortFields = getCohortFields([...events], periods);
        onChange({ ...cohortFields });
    }, [readonly, periods, events]);

    const autocompleteProps = {
        disabled: readonly,
        clearable: true,
        searchable: true,
        containerClass: css.field,
    };

    return (
        <>
            <AutoCompleteField
                value={events}
                label="STATIC.MODELED_SKAN_CUSTOM_EVENTS"
                options={eventsOptions}
                onChange={setEvents}
                onInputChange={setEvents}
                {...autocompleteProps}
            />
            <AutoCompleteField
                value={periods}
                label="STATIC.SKAN_COHORT_PERIODS"
                options={periodsOptions}
                onChange={setPeriods}
                onInputChange={setPeriods}
                {...autocompleteProps}
            />
        </>
    );
}

SkanCustomSchemaFields.propTypes = {
    readonly: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
};

SkanCustomSchemaFields.defaultProps = {
    readonly: false,
    selected: [],
};
