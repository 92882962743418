import { reportTypes } from 'utils/reports';
import {
    getBaseConfig,
    getEmptyStateConfig,
    shelfDateFieldName,
    shelfIOSNetworkMetrics,
    shelfDataTypeFields,
    shelfMetricGroupFields,
    shelfCohortMetricGroupFields,
    shelfIOSDimensions,
    shelfIOSTimeBreakdowns,
} from './baseReportConfig';
import { shelfIOSPeriods } from './shelfPeriods';

const type = reportTypes.unifiedReport;
const path = '/react/unified_report';
const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.UNIFIED_REPORT', undefined);

export default {
    ...basicConfig,
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/12174630341787-Unified-iOS-Report-FAQ',
            hideVideo: true,
        },
        emptyStateHeaderData: getEmptyStateConfig('UNIFIED_REPORT'),
    },
    shelf: [
        shelfDateFieldName,
        shelfDataTypeFields,
        shelfMetricGroupFields,
        shelfCohortMetricGroupFields,
        shelfIOSPeriods,
        shelfIOSNetworkMetrics,
        shelfIOSDimensions,
        shelfIOSTimeBreakdowns,
    ],
    fieldWarnings: {},
    path,
    chart: {
        enabled: false,
        show: false,
    },
};
