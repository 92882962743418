import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { faChevronDown } from '@fortawesome/fontawesome-free-solid';
import { Manager, Reference, Popper } from 'react-popper';
import classNames from 'classnames';
import ActionButton from './ActionButton';
import css from './Menu.css';
import Tooltip from './Tooltip';

function Menu({
    primary,
    disabled,
    buttonLabel,
    buttonIcon,
    actionIcon,
    buttonActiveClass,
    buttonClass,
    buttonIconStyle,
    buttonIconClass,
    disabledClass,
    itemClass,
    containerClass,
    items,
    listStyle,
    listClass,
    tooltipTitle,
}) {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const menuToggle = isShow => {
        if (!disabled) {
            setShow(isShow);
        }
    };

    const itemSelect = item => {
        setShow(false);
        item.onClick && item.onClick();
    };

    const dispatchFunction = item => {
        if (item.onClickDispatch) {
            const { dispatchMethod, params } = item.onClickDispatch;
            const action = dispatchMethod(params);
            dispatch(action);
        }
    };

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div
                        role="button"
                        tabIndex="0"
                        onBlur={() => {
                            menuToggle(false);
                        }}
                        className={classNames(css.container, containerClass)}
                        ref={ref}
                    >
                        <Tooltip style={{ display: 'flex' }} titleTranslationKey={tooltipTitle} disabled={!disabled}>
                            <ActionButton
                                containerClass={buttonClass}
                                text={buttonLabel}
                                onClick={() => {
                                    menuToggle(!show);
                                }}
                                primary={primary}
                                icon={buttonIcon}
                                disabled={disabled}
                                active={show}
                                activeClass={buttonActiveClass}
                                newStyle
                                buttonIconStyle={buttonIconStyle}
                                buttonIconClass={buttonIconClass}
                                disabledClass={disabledClass}
                                IconClass={actionIcon}
                            />
                        </Tooltip>
                    </div>
                )}
            </Reference>
            {show && (
                <Popper placement="bottom-start">
                    {({ ref, style, placement }) => (
                        <div
                            ref={ref}
                            style={{
                                zIndex: 999,
                                ...style,
                            }}
                        >
                            <ul
                                ref={ref}
                                data-placement={placement}
                                className={classNames(css.dropdown, listClass)}
                                style={listStyle}
                            >
                                {items &&
                                    items.map(item => {
                                        if (item.componentClass) {
                                            return <item.componentClass item={item} itemClass={itemClass} />;
                                        }

                                        return (
                                            <li
                                                id={item.value}
                                                key={item.label}
                                                className={classNames(css.item, itemClass)}
                                                onMouseDown={() => {
                                                    itemSelect(item);
                                                    dispatchFunction(item);
                                                }}
                                            >
                                                {!!item.icon && <item.icon className={item.iconStyle} />}
                                                <Translate id={item.label} />
                                                {!!item.tag && (
                                                    <sup className={css.sideBadge}>
                                                        <Translate id={item.tag} />
                                                    </sup>
                                                )}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                </Popper>
            )}
        </Manager>
    );
}

Menu.propTypes = {
    primary: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonLabel: PropTypes.string,
    buttonIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    actionIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    buttonIconStyle: PropTypes.objectOf(PropTypes.any),
    buttonIconClass: PropTypes.string,
    disabledClass: PropTypes.string,
    buttonActiveClass: PropTypes.string,
    buttonClass: PropTypes.string,
    itemClass: PropTypes.string,
    containerClass: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    listStyle: PropTypes.objectOf(PropTypes.string),
    listClass: PropTypes.string,
    tooltipTitle: PropTypes.string,
};

Menu.defaultProps = {
    primary: true,
    disabled: false,
    buttonLabel: 'Menu',
    buttonIcon: faChevronDown,
    actionIcon: null,
    buttonIconStyle: {},
    buttonIconClass: '',
    disabledClass: '',
    buttonClass: '',
    buttonActiveClass: '',
    itemClass: '',
    containerClass: '',
    items: [],
    listStyle: {},
    listClass: '',
    tooltipTitle: '',
};

export default Menu;
