import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { Tooltip } from 'react-tippy';
import XIcon from 'resources/svg/icon_remove_row.svg';
import * as css from 'teamManagement/components/MemberBox.css';
import Badge from 'components/widgets/Badge';
import FauxButton from 'components/widgets/FauxButton';
import ThreeLoadingDots from 'components/widgets/ThreeLoadingDots';
import { RoleTypes } from '../utils';

const INACTIVE_COLOR = '#c1c1c1';
const ROLE_TO_COLOR = {
    [RoleTypes.ADMIN]: '#3A92E5',
    [RoleTypes.RESTRICTED]: '#FFAA20',
    [RoleTypes.STANDARD]: '#00D0B2',
};

class MemberBox extends React.PureComponent {
    render() {
        const {
            name,
            roleText,
            description,
            email,
            status,
            style,
            onDelete,
            deletable,
            isMe,
            actionButtons,
            highlightedText,
            imgSrc,
        } = this.props;

        const color = status !== 'inactive' ? ROLE_TO_COLOR[roleText] : INACTIVE_COLOR;

        return (
            <div className={`${css.boxBody} ${css[status]}`} style={style}>
                {!roleText ? null : <div className={css.ribbon} style={{ backgroundColor: color }} />}
                {imgSrc ? <img alt="" className={css.img} src={imgSrc} /> : null}
                <div className={css.linesContainer}>
                    <div className={css.leadingLine}>
                        <Highlighter
                            className={css.usernameText}
                            searchWords={[highlightedText]}
                            textToHighlight={isMe ? `${name} (Me)` : name}
                            highlightClassName={css.highlighted}
                            autoEscape
                        />
                        {!roleText ? null : (
                            <Badge backgroundColor={color}>
                                <Highlighter
                                    searchWords={[highlightedText]}
                                    textToHighlight={roleText}
                                    highlightClassName={css.highlighted}
                                    autoEscape
                                />
                            </Badge>
                        )}
                        <span className={css.description}>
                            <Highlighter
                                searchWords={[highlightedText]}
                                textToHighlight={description}
                                highlightClassName={css.highlighted}
                                autoEscape
                            />
                        </span>
                    </div>
                    <Highlighter
                        className={css.secondaryLine}
                        searchWords={[highlightedText]}
                        textToHighlight={email}
                        highlightClassName={css.highlighted}
                        autoEscape
                    />
                </div>
                <span className={css.buttonContainer}>
                    {actionButtons.map(
                        ({ buttonName, onClick, inProgress, disabled, disabledTooltip, className }, i, arr) => [
                            <Tooltip
                                key={`btn${buttonName}`}
                                title={disabledTooltip}
                                disabled={!disabledTooltip}
                                className={className}
                                position="top"
                                inertiaf
                                animation="scale"
                                distance={10}
                                theme="light"
                                size="big"
                            >
                                <FauxButton
                                    containerStyle={{
                                        marginRight: i < arr.length - 1 ? 10 : 0,
                                        fontSize: 14,
                                    }}
                                    onClick={onClick}
                                    disabled={disabled}
                                >
                                    <span>
                                        {buttonName}
                                        {inProgress ? <ThreeLoadingDots /> : null}
                                    </span>
                                </FauxButton>
                            </Tooltip>,
                            i < arr.length - 1 && <span key={`sep${buttonName}`} className={css.seperator} />,
                        ]
                    )}
                    {deletable && !isMe ? (
                        <XIcon className={css.icon} onClick={onDelete} />
                    ) : (
                        <div style={{ width: 24, marginLeft: 15 }} />
                    )}
                </span>
            </div>
        );
    }
}

MemberBox.propTypes = {
    name: PropTypes.string,
    roleText: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    onDelete: PropTypes.func,
    deletable: PropTypes.bool,
    isMe: PropTypes.bool,
    actionButtons: PropTypes.arrayOf(PropTypes.any),
    highlightedText: PropTypes.string,
    imgSrc: PropTypes.string,
};

MemberBox.defaultProps = {
    name: '',
    roleText: '',
    description: '',
    email: '',
    status: '',
    style: {},
    onDelete: () => {},
    deletable: false,
    isMe: false,
    actionButtons: [],
    highlightedText: '',
    imgSrc: '',
};

export default MemberBox;
