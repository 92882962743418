import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

export const TableWidgetMetadataShape = PropTypes.arrayOf(
    PropTypes.shape({
        headerAlign: PropTypes.string,
        headerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        thStyle: stylePropType,
        headerClick: PropTypes.func,
        headerTooltip: PropTypes.string,
        headerTooltipData: PropTypes.objectOf(PropTypes.any),
        showHeaderArrow: PropTypes.bool,
        headerArrow: PropTypes.string,
        cellComponent: PropTypes.elementType,
        cellValues: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.shape({
                    propName: PropTypes.string,
                    dataKey: PropTypes.string,
                    transformation: PropTypes.func,
                }),
            ])
        ),
        displayName: PropTypes.string,
        name: PropTypes.string,
        className: PropTypes.string,
        cellEvents: PropTypes.objectOf(PropTypes.func),
        cellProps: PropTypes.objectOf(PropTypes.any),
    })
);
