import API from 'services/api';

let instance = null;
const api = new API();
export const URL_LATEST_SDK_VERSION = 'api/latest_sdk_version';

export default class SDKPlanningService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getLatestSDKVersions(sdkType) {
        return api.getJson(`${URL_LATEST_SDK_VERSION}?sdk_type=${sdkType}`, {
            method: 'GET',
        });
    }
}
