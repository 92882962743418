import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Banner from '../../components/widgets/Banner';
import AllLogos from '../../resources/svg/data_destinations_upgrade_banner/all-logos.svg';
import { getUserData } from '../../mta/selectors';
import AccountPlanAPI from '../../organizationPlan/service';
import { UPGRADE_BANNER_STEPS, UPGRADE_BANNER_MIXPANEL_EVENT, ETL_UPGRADE_REQUESTED } from '../consts';
import { trackMixpanelEvent } from '../../utils/general';
import css from './UpgradeETLBanner.css';

const accountPlanService = new AccountPlanAPI();

const UpgradeETLBanner = () => {
    const [isDone, setIsDone] = useState(false);
    const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [bannerStep, setBannerStep] = useState(0);
    const {
        company,
        email,
        first_name: firstName,
        last_name: lastName,
        organization_tier: tier,
        country,
        role_type: role,
    } = useSelector(state => getUserData(state));

    useEffect(() => {
        /**
         * If the value is true in local storage before we engaged (step 0) don't dhow the banner
         * Otherwise we hide the banner only if done (since we set the value on localstorage while running we cannot rely only on that)
         */
        if (bannerStep === 0) {
            const isUpgradeRequested = !!localStorage.getItem(ETL_UPGRADE_REQUESTED);
            setShow(!isUpgradeRequested);
        } else {
            setShow(!isDone);
        }
    }, [bannerStep, isDone]);

    const lastStep = Object.keys(UPGRADE_BANNER_STEPS).length - 1;

    const trackUpgradeClick = () => {
        trackMixpanelEvent(UPGRADE_BANNER_MIXPANEL_EVENT.prefix, UPGRADE_BANNER_MIXPANEL_EVENT.name, {});
    };

    const storeUpgradeRequestedOnLocalStorage = () => {
        localStorage.setItem(ETL_UPGRADE_REQUESTED, 'true');
    };
    const requestUpgrade = () => {
        storeUpgradeRequestedOnLocalStorage();
        return accountPlanService.upgradeEtlRequest(email, firstName, lastName, company, tier, country, role);
    };

    const hasMoreSteps = () => {
        return bannerStep < lastStep;
    };

    const onCtaClick = async () => {
        setIsLoading(true);
        trackUpgradeClick();
        await requestUpgrade();
        setIsLoading(false);
        if (hasMoreSteps()) {
            setBannerStep(bannerStep + 1);
        } else {
            setIsDone(true);
        }
    };
    return (
        show && (
            <Banner
                header={<AllLogos />}
                anchor="bottomEnd"
                buttonOnClick={onCtaClick}
                isLoading={isLoading}
                buttonText="STATIC.BANNERS.ETL_UPGRADE.CTA"
                className={css.banner}
                {...UPGRADE_BANNER_STEPS[bannerStep]}
            />
        )
    );
};
export default UpgradeETLBanner;
