import React from 'react';
import PropTypes from 'prop-types';
import css from '../../sectionsItems.css';
import StepsList from '../../../../../../components/widgets/StepsList';
import Step from '../../../../../../components/widgets/Step';
import { ExpandedContent, Label, BulletList } from '../../../../../../components/widgets';
import AppSiteUpdateForm from '../../AppSiteUpdateForm/AppSiteUpdateForm';
import { IOS_NATIVE, SDK_INTEGRATION_SECTIONS } from '../../../../consts';
import { FIELDS_ITEMS_BY_PLATFORM } from '../../AppSiteUpdateForm/consts';
import { sendMixpanelEventSdkIntegration } from '../../../../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../../consts';

const IOS_DEEP_LINKING_TRANSLATE_KEY = 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.DEEP_LINKING';
const STEP_1_SUB_STEP_COUNT = 3;
const { DEEP_LINKING_APPLE, DEEP_LINKING_MORE_BUTTON_CLICKED } = SDK_INTEGRATION_MIXPANEL_EVENTS;
const DeepLinkingIOS = ({ appSites, isLoadingAppSites }) => {
    const onExpendContent = isExpended => {
        if (isExpended) {
            sendMixpanelEventSdkIntegration(
                DEEP_LINKING_MORE_BUTTON_CLICKED,
                SDK_INTEGRATION_SECTIONS.DEEP_LINKING,
                IOS_NATIVE
            );
        }
    };

    const trackMixpanelForLink = () => {
        sendMixpanelEventSdkIntegration(DEEP_LINKING_APPLE, SDK_INTEGRATION_SECTIONS.DEEP_LINKING, IOS_NATIVE);
    };

    return (
        <StepsList>
            <Step
                className={css.grayBack}
                title={`${IOS_DEEP_LINKING_TRANSLATE_KEY}.STEP_1.TITLE`}
                onExpendContent={onExpendContent}
                isExpandable
            >
                <ExpandedContent>
                    <div className={css.expendedContent}>
                        <BulletList
                            textItems={Array.from({ length: STEP_1_SUB_STEP_COUNT }, (_, i) => i + 1).map(
                                i => `${IOS_DEEP_LINKING_TRANSLATE_KEY}.STEP_1.SUB_STEP_${i}`
                            )}
                        />
                        <Label
                            className={css.stepLink}
                            text={`${IOS_DEEP_LINKING_TRANSLATE_KEY}.STEP_1.LINK`}
                            type="dialogBody"
                            onClick={trackMixpanelForLink}
                        />
                    </div>
                </ExpandedContent>
            </Step>
            <Step className={css.grayBack} title={`${IOS_DEEP_LINKING_TRANSLATE_KEY}.STEP_2.TITLE`}>
                <AppSiteUpdateForm
                    appSites={appSites}
                    isLoadingAppSites={isLoadingAppSites}
                    targetPlatform={IOS_NATIVE}
                    formInputFields={FIELDS_ITEMS_BY_PLATFORM[IOS_NATIVE]}
                />
            </Step>
        </StepsList>
    );
};

DeepLinkingIOS.propTypes = {
    appSites: PropTypes.arrayOf(PropTypes.shape({ longname: PropTypes.string, id: PropTypes.number })).isRequired,
    isLoadingAppSites: PropTypes.bool,
};

DeepLinkingIOS.defaultProps = {
    isLoadingAppSites: true,
};

export default DeepLinkingIOS;
