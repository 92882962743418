import { connect } from 'react-redux';
import AdnetworkBasicLogin from '../components/AdnetworkBasicLogin';
import { getShelfData } from '../selector';
import {
    saveUanData,
    saveUanDataWithAccounts,
    saveAdwordsMccData,
    saveAdwordsData,
    getNetworkAuthUrl,
    setAuthPopupUrl,
    validateLogin,
    uanSaveDisplayError,
    getUanAccounts,
    sendScrapeJob,
} from '../actions';

const mapStateToProps = state => {
    return getShelfData(state);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onUanSubmit: (uanData, partnerSourceId) => dispatch(saveUanData(uanData, partnerSourceId)),
    onUanWithAccountsSubmit: (uanData, uanId, partnerSourceId) =>
        dispatch(saveUanDataWithAccounts(uanData, uanId, partnerSourceId)),
    onAdwordsMccSubmit: (uanId, mccClientId) => dispatch(saveAdwordsMccData(uanId, mccClientId)),
    onAdwordsSubmit: (uanData, uanId, mccClientId, partnerSourceId) =>
        dispatch(saveAdwordsData(uanData, uanId, mccClientId, partnerSourceId)),
    onUanAuthClick: (url, adnId, uanId) => dispatch(getNetworkAuthUrl(url, adnId, uanId)),
    setAuthPopupUrl: url => dispatch(setAuthPopupUrl(url)),
    validateLogin: uanId => dispatch(validateLogin(uanId)),
    onAuthFail: (errorMessage, adnetDisplayName, uanId) =>
        dispatch(uanSaveDisplayError({ value: errorMessage }, adnetDisplayName, uanId)),
    getAccountsForUan: (uanId, disableSaveAccounts) => dispatch(getUanAccounts(uanId, disableSaveAccounts)),
    sendScrapeJob: uanData => dispatch(sendScrapeJob(uanData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdnetworkBasicLogin);
