import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

class TrackingLink extends Component {
    constructor(props) {
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    copyToClipboard(text) {
        const x = window.scrollX;
        const y = window.scrollY;
        const e = document.createElement('input');
        e.value = text;
        e.style.position = 'absolute';
        e.style.left = '-9999px';
        e.style.top = '0';
        document.body.appendChild(e);
        e.focus();
        this[`${text}-input`].focus();
        this[`${text}-input`].setSelectionRange(0, text.length);

        // prevent window from scorlling to the top after focusing the element
        window.scrollTo(x, y);
        e.setSelectionRange(0, text.length);
        try {
            document.execCommand('copy');
            if (this[text]) this[text].textContent = 'Copied';
        } catch (error) {
            if (this[text]) this[text].textContent = 'Failed to Copy';
        }
        e.blur();
        setTimeout(() => {
            this[text].textContent = 'Copy';
            this[`${text}-input`].setSelectionRange(0, 0);
            this[`${text}-input`].blur();
        }, 3000);
        document.body.removeChild(e);
    }

    render() {
        const { data, css } = this.props;

        const clickTag = data.click_url;
        const impressionTag = data.impression_url;

        return (
            <div>
                <div className={css.FieldContainer}>
                    <div className={css.FieldLabel} style={{ color: '#CCCCCC' }}>
                        View-through enabled
                    </div>
                    <div className={css.FieldValue}>
                        <Toggle
                            className={data.link_action === 'impression' ? css.ToggleChecked : css.ToggleUnchecked}
                            icons={false}
                            checked={data.link_action === 'impression'}
                            disabled
                        />
                    </div>
                </div>
                <div className={css.FieldContainer}>
                    <div className={css.FieldLabel} style={{ color: '#CCCCCC' }}>
                        Re-engagement enabled
                    </div>
                    <div className={css.FieldValue}>
                        <Toggle
                            className={
                                clickTag.indexOf('re=1') > -1 || data.re_enabled
                                    ? css.ToggleChecked
                                    : css.ToggleUnchecked
                            }
                            icons={false}
                            checked={clickTag.indexOf('re=1') > -1 || data.re_enabled}
                            disabled
                        />
                    </div>
                </div>
                <div className={`${css.FieldContainer} ${css.Link}`}>
                    <div className={css.FieldLabel}>Click-Through Tracking Link</div>
                    <div
                        className={`${css.FieldValue} ${css.TrackingLinkContainer}`}
                        onClick={() => {
                            this.copyToClipboard(clickTag);
                        }}
                    >
                        <input
                            readOnly
                            style={{ color: '#989898' }}
                            className={`${css.TextBox} ${css.TrackingLink}`}
                            type="text"
                            value={clickTag}
                            ref={(e) => {
                                this[`${clickTag}-input`] = e;
                            }}
                        />
                        <div
                            ref={(e) => {
                                this[clickTag] = e;
                            }}
                            className={`${css.FieldValue} ${css.ClickToCopy}`}
                            onClick={() => {
                                this.copyToClipboard(clickTag);
                            }}
                        >
                            Copy
                        </div>
                    </div>
                </div>
                {impressionTag && (
                    <div className={`${css.FieldContainer} ${css.Link}`}>
                        <div className={css.FieldLabel}>View-Through Tracking Link</div>
                        <div
                            className={`${css.FieldValue} ${css.TrackingLinkContainer}`}
                            onClick={() => {
                                this.copyToClipboard(impressionTag);
                            }}
                        >
                            <input
                                readOnly
                                style={{ color: '#989898' }}
                                className={`${css.TextBox} ${css.TrackingLink}`}
                                type="text"
                                value={impressionTag}
                                ref={(e) => {
                                    this[`${impressionTag}-input`] = e;
                                }}
                            />
                            <div
                                ref={(e) => {
                                    this[impressionTag] = e;
                                }}
                                className={`${css.FieldValue} ${css.ClickToCopy}`}
                                onClick={() => {
                                    this.copyToClipboard(impressionTag);
                                }}
                            >
                                Copy
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

TrackingLink.propTypes = {
    css: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.objectOf(PropTypes.any),
};

TrackingLink.defaultProps = {
    css: {},
    data: {},
};

export default TrackingLink;
