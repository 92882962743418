const DEFAULT_CREATIVE_SIZE = 234;

export const OPTIONAL_CREATIVES_SIZES = [
    { width: DEFAULT_CREATIVE_SIZE, height: 234 },
    { width: DEFAULT_CREATIVE_SIZE, height: 410 },
    { width: DEFAULT_CREATIVE_SIZE, height: 180 },
    { width: DEFAULT_CREATIVE_SIZE, height: 120 },
];

const getClosestRatio = (currentRatio, ratios) => {
    return ratios.reduce((closest, ratio) => {
        return Math.abs(currentRatio - ratio) < Math.abs(currentRatio - closest) ? ratio : closest;
    });
};

export const getCreativeSizeRatio = (creativeWidth, creativeHeight) => {
    const optionalRatios = OPTIONAL_CREATIVES_SIZES.map(({ width, height }) => width / height);

    if (!creativeWidth || !creativeHeight) {
        return optionalRatios[0];
    }

    return getClosestRatio(creativeWidth / creativeHeight, optionalRatios);
};
