import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import { trackMixpanelEvent } from 'utils/general';
import { useFetchDataConnectorAlerts, useFetchOrgUsers, useSaveDataConnectorAlerts } from 'alerts/hooks';
import { DC_ALERTS_SHELF_OPENED, DC_ALERTS_TRACK_EVENT_PREFIX } from '../../alerts/consts';
import AlertsShelf from '../../alerts/components/AlertsShelf';
import css from './DataConnectorAlerts.css';

const alertsSummaryTitle = dataConnectorAlerts =>
    dataConnectorAlerts && (
        <Translate
            id="STATIC.PAGES.ALERTS_V2.DATA_CONNECTOR_ALERTS_SHELF.ABOUT_TITLE"
            data={{ uanName: dataConnectorAlerts.uan.display_name }}
        />
    );

const globalAlertsLinkTitle = () => (
    <div className={css.textGlobalAlerts}>
        <Translate id="STATIC.PAGES.ALERTS_V2.DATA_CONNECTOR_ALERTS_SHELF.GLOBAL_ALERTS" />
    </div>
);

function DataConnectorAlerts({ uanId, onClose, isUserSlackAuth, onDeleteUserSlackAuth }) {
    const [shouldSaveAlert, setShouldSaveAlert] = useState(false);
    const [shouldLoadDataConnectorAlerts, setShouldLoadDataConnectorAlerts] = useState(true);
    const [dataDelayAlert, setDataDelayAlert] = useState({});
    const [loginAlert, setLoginAlert] = useState({});
    const [isModifiedFlag, setIsModifiedFlag] = useState(false);
    const [alertsToSave, setAlertsToSave] = useState({});
    const [loginAlertsToSave, setLoginAlertsToSave] = useState({});
    const [dataDelayAlertsToSave, setDataDelayAlertsToSave] = useState({});
    const { value: orgUsers, isLoading: usersLoading } = useFetchOrgUsers();

    const onSaveCleanup = () => {
        setShouldSaveAlert(false);
    };

    const onRefetchAlertsCleanup = () => {
        setShouldLoadDataConnectorAlerts(false);
    };

    const { value: updatedDataConnectorAlerts, isLoading: isSaveLoading } = useSaveDataConnectorAlerts(
        shouldSaveAlert,
        uanId,
        loginAlertsToSave.emails,
        loginAlertsToSave.send_to_admins,
        dataDelayAlertsToSave.emails,
        dataDelayAlertsToSave.send_to_admins,
        onSaveCleanup
    );

    const { data_connector_alerts: dataConnectorAlerts, isLoading: isFetchLoading } = useFetchDataConnectorAlerts(
        uanId,
        shouldLoadDataConnectorAlerts,
        onRefetchAlertsCleanup
    );

    const shouldCloseShelf = () => {
        return !!(
            !isModifiedFlag &&
            !isSaveLoading &&
            updatedDataConnectorAlerts &&
            Object.keys(updatedDataConnectorAlerts).length
        );
    };

    useEffect(() => {
        if (dataConnectorAlerts && Object.keys(dataConnectorAlerts).length) {
            setDataDelayAlert(dataConnectorAlerts.data_delay_alert_recipients);
            setLoginAlert(dataConnectorAlerts.login_alert_recipients);
        }
    }, [dataConnectorAlerts]);

    useEffect(() => {
        if (Object.keys(alertsToSave).length) {
            setLoginAlertsToSave(alertsToSave.login);
            setDataDelayAlertsToSave(alertsToSave.dataDelay);
            setShouldSaveAlert(true);
        }
    }, [alertsToSave]);

    useEffect(() => {
        if (shouldCloseShelf()) {
            setTimeout(() => {
                onClose();
            }, 2000);
        }
    }, [updatedDataConnectorAlerts, isModifiedFlag, isSaveLoading]);

    useEffect(() => {
        trackMixpanelEvent(DC_ALERTS_TRACK_EVENT_PREFIX, DC_ALERTS_SHELF_OPENED, { uan: uanId });
    }, [uanId]);

    return (
        <AlertsShelf
            title="data_connector_alerts_shelf"
            aboutTitle={alertsSummaryTitle(dataConnectorAlerts)}
            bottomSectionShelf={globalAlertsLinkTitle()}
            orgUsers={orgUsers}
            loginAlert={loginAlert}
            dataDelayAlert={dataDelayAlert}
            setAlertsToSave={setAlertsToSave}
            loginAlertsName="login_alert_recipients"
            dataDelayAlertsName="data_delay_alert_recipients"
            isModifiedFlag={isModifiedFlag}
            setIsModifiedFlag={setIsModifiedFlag}
            setShouldSaveAlert={setShouldSaveAlert}
            isAlertSaved={updatedDataConnectorAlerts && !isSaveLoading}
            isAlertFetched={!(isFetchLoading && usersLoading)}
            onClose={onClose}
            isUserSlackAuth={isUserSlackAuth}
            onDeleteUserSlackAuth={onDeleteUserSlackAuth}
        />
    );
}

DataConnectorAlerts.propTypes = {
    uanId: PropTypes.number,
    onClose: PropTypes.func,
    isUserSlackAuth: PropTypes.bool,
    onDeleteUserSlackAuth: PropTypes.func,
};

DataConnectorAlerts.defaultProps = {
    uanId: null,
    onClose: () => {},
    isUserSlackAuth: false,
    onDeleteUserSlackAuth: () => {},
};

export default withLocalize(DataConnectorAlerts);
