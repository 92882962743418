import { getUserEmails } from 'alerts/utils';
import TagInput from 'components/widgets/TagInput';
import React from 'react';
import PropTypes from 'prop-types';
import { validateEmail } from 'signup/utils';
import css from './EmailTagInput.css';

const EmailTagInput = ({ orgUsers, emails, setEmails, showAdminTag, sendToAdmins, setSendToAdmins, onChange, disabled }) => {
    const adminsTag = 'All Admin Users';

    const onTagAdded = (tag) => {
        onChange();
        if (tag === adminsTag) {
            setSendToAdmins(true);
        } else {
            setEmails([...emails, tag]);
        }
    };
    const onTagRemoved = (tag) => {
        onChange();
        if (tag === adminsTag) {
            setSendToAdmins(false);
        } else {
            setEmails(emails.filter((email) => email !== tag));
        }
    };
    const onCreateTag = (tag) => {
        return `${tag}`;
    };

    const emailSuggestions = getUserEmails(orgUsers, sendToAdmins);

    const tags = emails.map(email => (validateEmail(email) !== '' ? { text: email, invalid: true } : { text: email }));
    const isError = tags.filter(tag => validateEmail(tag.text) !== '').length > 0;

    return (
        <React.Fragment>
            <TagInput
                tags={sendToAdmins && showAdminTag ? [{ text: adminsTag }].concat(tags) : tags}
                containerStyle={{ width: '100%' }}
                creatable
                disabled={disabled}
                suggestions={showAdminTag ? [adminsTag].concat(emailSuggestions) : emailSuggestions}
                onTagAdded={onTagAdded}
                onTagRemoved={onTagRemoved}
                placeholder="STATIC.PAGES.ALERTS_V2.SELECT_EMAILS"
                virtualScrolling
                formatCreateLabel={onCreateTag}
                error={isError ? 'invalid Email' : ''}
            />
            {isError && <div className={css.invalidEmail}>Invalid Email</div>}
        </React.Fragment>
    );
};

export default EmailTagInput;

EmailTagInput.propTypes = {
    orgUsers: PropTypes.arrayOf(PropTypes.object),
    emails: PropTypes.arrayOf(PropTypes.string),
    setEmails: PropTypes.func,
    showAdminTag: PropTypes.bool,
    sendToAdmins: PropTypes.bool,
    setSendToAdmins: PropTypes.func,
    setIsModified: PropTypes.func,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

EmailTagInput.defaultProps = {
    orgUsers: [],
    emails: [],
    setEmails: () => {},
    showAdminTag: true,
    sendToAdmins: false,
    setSendToAdmins: () => {},
    setIsModified: () => {},
    disabled: false,
    onChange: () => {},
};
