import React, { useState, useCallback, useRef, useEffect, Fragment } from 'react';
import debounce from 'lodash/debounce';
import { PageWrapper, PageHeaderSlim } from '../components/partials';
import BenchmarksFilters from './components/benchmarksFilters';
import css from './styles.css';
import {
    BENCHMARKS_GRAPH_TYPES,
    COUNTRY_FILTER,
    CATEGORY_FILTER,
    PLATFORM_FILTER,
    APP_FILTER,
    TRACK_EVENT_PAGE_LOADED,
    TRACK_EVENT_FILTER_CHANGE,
    BENCHMARKS_PAGES,
    BENCHMARKS_PERCENTILES_GRAPHS,
    TRACK_EVENT_PREFIX,
} from './consts';
import BenchmarksCharts from './components/benchmarksCharts';
import { useFetchBenchmarksNetworkOptimizations, useStickyHeader } from './hooks';
import { calculatePercentilesNoDataCount } from './utils';
import Spinner from '../components/widgets/Spinner';
import { trackMixpanelEvent } from '../utils/general';

const BenchmarksNetworkOptimizations = () => {
    const ref = useRef(null);
    const [isFading, setFading] = useState(false);
    const [selectedFilterValues, setSelectedFilterValues] = useState({});
    const response = useFetchBenchmarksNetworkOptimizations(selectedFilterValues);

    const { graphs: chartsData = {}, logo_by_label: logoByName, isLoading } = response;

    useEffect(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_PAGE_LOADED);
    }, []);

    useStickyHeader(ref);

    /**
     * debouncing filters change to let the user change more than one filter before the charts refreshes
     * @type {*|debounced}
     */
    const onFiltersChange = useCallback(
        debounce((newSelectedValues) => {
            setSelectedFilterValues(newSelectedValues);
            setFading(false);
        }, 2000),
        []
    );
    const onChange = (newSelectedValues, validationErrors) => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_FILTER_CHANGE, { selectedValues: newSelectedValues });

        if (!validationErrors) {
            setFading(true);
            onFiltersChange(newSelectedValues);
        }
    };

    return (
        <Fragment>
            <PageWrapper style={{ overflowX: 'initial' }} ref={ref}>
                <div className={css.headerWrapper}>
                    <PageHeaderSlim
                        text="STATIC.PAGE_HEADERS.BENCHMARKS_NETWORK_OPTIMIZATIONS"
                        subheaderText="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.SUBHEADER"
                        className={css.header}
                    />
                </div>
                <BenchmarksFilters onChange={onChange} viewName={BENCHMARKS_PAGES.NETWORK_OPTIMIZATIONS}>
                    <BenchmarksFilters.Categories optionsKey={CATEGORY_FILTER} />
                    <BenchmarksFilters.Countries optionsKey={COUNTRY_FILTER} />
                    <BenchmarksFilters.Platforms optionsKey={PLATFORM_FILTER} />
                    <BenchmarksFilters.Apps optionsKey={APP_FILTER} addEmptyOption optional />
                </BenchmarksFilters>
                <Spinner className={css.spinner} show={isLoading} expanded />
                <BenchmarksCharts
                    isLoading={isLoading}
                    isFading={isFading}
                    noDataCount={calculatePercentilesNoDataCount(chartsData)}
                    chartCount={BENCHMARKS_PERCENTILES_GRAPHS.length}
                    skipEmptyChartCheck
                >
                    <BenchmarksCharts.PercentilesChart
                        logoByName={logoByName}
                        data={chartsData}
                        chartKey={BENCHMARKS_GRAPH_TYPES.CPI_PERCENTILES}
                        title="Networks Optimization (Covers last 60 days)"
                    />
                </BenchmarksCharts>
            </PageWrapper>
        </Fragment>
    );
};

export default BenchmarksNetworkOptimizations;
