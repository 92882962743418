const colorLuminance = (hex, lum = 0) => {
    // taken from http://www.sitepoint.com/javascript-generate-lighter-darker-color/
    let workHex = hex;
    // Validate hex string
    workHex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (workHex.length < 6) {
        workHex = workHex.replace(/(.)/g, '$1$1');
    }
    // Convert to decimal and change luminosity
    let rgb = '#';
    let c;
    for (let i = 0; i < 3; ++i) {
        c = parseInt(workHex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += `00${c}`.substr(c.length);
    }
    return rgb;
};

export { colorLuminance };
