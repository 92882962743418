import { gql } from '@apollo/client';

const CONSUMPTION_FRAGMENT = gql`
    fragment Consumption on ConsumptionRecord {
        connectorName
        jobDate
        startDate
        endDate
        rows
    }
`;

const READ_USAGE = gql`
    query usage($startDate: Date, $endDate: Date) {
        usage(startDate: $startDate, endDate: $endDate) {
            ...Consumption
        }
    }
    ${CONSUMPTION_FRAGMENT}
`;

export { READ_USAGE };
