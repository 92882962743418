import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import css from './AdNetworksEmptyState.css';
import Label from './Label';
import { EmptyStateBox, AutoComplete } from '.';
import { trackMixpanelEvent } from '../../utils/general';
import { adNetworkMap as specialNetworks, DEFAULT_INTERNAL_BI_PARTNER } from '../../utils/consts';
import DefaultOrgLogo from '../../../../dashboard/img/Singularlogo.png';

function AdNetworksEmptyState({
    adNetworkOptions,
    emptyStateAdNetworks,
    mixpanelPrefix,
    onAdNetworkSelectionChange,
    translationPageKey,
    translate,
    orgLogoPath,
}) {
    const isAdNetworkDisabled = adnId => {
        const adNetwork = adNetworkOptions.find(network => parseInt(network.id, 10) === adnId);
        return adNetwork?.isDisabled || false;
    };
    const getAdNetworkEmptyState = adnId => {
        const adNetwork = adNetworkOptions.find(network => parseInt(network.id, 10) === adnId);

        if (!adNetwork) return null;

        const { value, label, logoPath } = adNetwork;
        return {
            title: label,
            image: [value === DEFAULT_INTERNAL_BI_PARTNER ? orgLogoPath : logoPath],
            name: value,
        };
    };
    const getEmptyStateBoxes = adNetworks => {
        return adNetworks.map(adnId => {
            const adNetwork = specialNetworks[adnId] || getAdNetworkEmptyState(adnId);
            if (!adNetwork) return null;

            const { title, image, name, style, disabledImage } = adNetwork;
            const disabled = isAdNetworkDisabled(adnId);
            return (
                <EmptyStateBox
                    disabled={disabled}
                    key={title}
                    title={`Add ${title}`}
                    image={disabled && disabledImage ? disabledImage : image}
                    imageStyle={style || {}}
                    onClick={() => {
                        trackMixpanelEvent(mixpanelPrefix, 'Empty State Logo Clicked', { name: adnId }, true);
                        onAdNetworkSelectionChange({ id: adnId, value: name });
                    }}
                />
            );
        });
    };
    return (
        <div className={css.noDataContainer}>
            <div className={css.textContainer}>
                <Label
                    className={css.emptyStateHeader}
                    text={`STATIC.PAGES.${translationPageKey}.EMPTY_STATE_HEADER`}
                />
                <Label
                    className={css.emptyStateSubHeader}
                    text={`STATIC.PAGES.${translationPageKey}.EMPTY_STATE_SUB_HEADER`}
                />
            </div>
            <AutoComplete
                placeholder={`STATIC.PAGES.${translationPageKey}.EMPTY_STATE_NEW_UAN_PLACEHOLDER`}
                selectOptions={{
                    options: adNetworkOptions.map(network => {
                        const hoverText = network.isDisabled
                            ? translate(`STATIC.PAGES.${translationPageKey}.UNAVAILABLE_SHORT`)
                            : translate(`STATIC.PAGES.${translationPageKey}.EMPTY_STATE_DROPDOWN_HOVER`);
                        return {
                            ...network,
                            extraOnHover: hoverText,
                        };
                    }),
                }}
                onChange={value => onAdNetworkSelectionChange(value)}
                onInputChange={text => {
                    trackMixpanelEvent(mixpanelPrefix, 'Empty State New UAN Search', { name: text }, true);
                }}
                searchable
                containerStyle={{
                    width: '620px',
                }}
                controlledValue=""
                big
            />
            <Label className={css.topConnectorsText} text={`STATIC.PAGES.${translationPageKey}.CHOOSE_TOP_NETWORK`} />
            <div className={css.emptyStateBoxesContainer}>{getEmptyStateBoxes(emptyStateAdNetworks)}</div>
        </div>
    );
}

AdNetworksEmptyState.propTypes = {
    translate: PropTypes.func,
    adNetworkOptions: PropTypes.arrayOf(PropTypes.any),
    emptyStateAdNetworks: PropTypes.arrayOf(PropTypes.number),
    translationPageKey: PropTypes.string,
    mixpanelPrefix: PropTypes.string.isRequired,
    onAdNetworkSelectionChange: PropTypes.func,
    orgLogoPath: PropTypes.string,
};

AdNetworksEmptyState.defaultProps = {
    translate: () => {},
    adNetworkOptions: [],
    emptyStateAdNetworks: [345, 124, 77760, 377, 774, 1377],
    translationPageKey: 'DATA_SOURCES',
    onAdNetworkSelectionChange: () => {},
    orgLogoPath: DefaultOrgLogo,
};

export default withLocalize(AdNetworksEmptyState);
