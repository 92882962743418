import update from 'immutability-helper';
import { LOAD } from './actions';

export const initialState = {
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD: {
            const { loading } = action;
            return update(state, {
                loading: { $set: loading },
            });
        }
        default:
            return state;
    }
};
