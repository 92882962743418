import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './LoadingPlaceholder.css';
import { ToggleButton, ThreeLoadingDots } from '../widgets';

class LoadingPlaceholder extends React.Component {
    static get READY() {
        return 0;
    }

    static get LOADING() {
        return 1;
    }

    render() {
        const { loadingText, readyText, buttonText, state, className } = this.props;
        return (
            <div className={`${css.container} ${className}`}>
                {state === LoadingPlaceholder.LOADING ? (
                    <div>
                        <Translate id={loadingText} />
                        <ThreeLoadingDots />
                    </div>
                ) : null}
                {state === LoadingPlaceholder.READY ? (
                    <div className={css.readyContainer}>
                        <div className={css.readyText}>
                            <Translate id={readyText} />
                        </div>
                        <ToggleButton text={buttonText} showIcon={false} onClick={this.props.onReadyClick} />
                    </div>
                ) : null}
            </div>
        );
    }
}

LoadingPlaceholder.propTypes = {
    state: PropTypes.oneOf([LoadingPlaceholder.READY, LoadingPlaceholder.LOADING]),
    loadingText: PropTypes.string,
    readyText: PropTypes.string,
    buttonText: PropTypes.string,
    onReadyClick: PropTypes.func,
    className: PropTypes.string,
};

LoadingPlaceholder.defaultProps = {
    state: LoadingPlaceholder.LOADING,
    loadingText: '',
    readyText: '',
    buttonText: '',
    onReadyClick: () => {},
    className: '',
};

export default LoadingPlaceholder;
