import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik-2';
import PropTypes from 'prop-types';
import { GET_AB_TEST } from '../../queries';
import { DEFAULT_END_DATE, DEFAULT_START_DATE, EMPTY_TEST_SKELETON } from './consts';
import css from './WizardWrapper.css';
import MemoChevronIcon from '../MemoChevronIcon';
import { Spinner } from '../../../components/widgets';
import WizardContainer from './WizardContainer';
import { normalizeTestChannels } from './Steps/wizardUtils';

const normalizeGetAbTest = values => {
    return {
        id: values?.id,
        testName: values?.name || '',
        description: values?.description || '',
        appsite: {
            label: `${values.appsite.appName} - ${values.appsite.platform}`,
            value: values.appsite.id,
            icon: <img src={values.appsite.icon} alt="" className={css.icon} />,
            className: css.dropdownRow,
        },
        segment: {
            value: values.segment.id,
            label: values.segment.name,
            reach: values.segment.reach,
        },
        startDate: values?.startDate || DEFAULT_START_DATE,
        endDate: values?.endDate || DEFAULT_END_DATE,
        isTestOngoing: !!values?.endDate,
        events: values?.events || [],
        testChannels: normalizeTestChannels(values?.testChannels || []),
        status: values.status,
    };
};

const WizardWrapper = ({ onSave, onClose, testId }) => {
    const { data: abTestDataResponseData, loading: isFetchingTest } = useQuery(GET_AB_TEST, {
        skip: !testId,
        variables: { testId },
    });

    const initialValues = useMemo(
        () => (isFetchingTest || !testId ? EMPTY_TEST_SKELETON : normalizeGetAbTest(abTestDataResponseData.abTest)),
        [isFetchingTest, abTestDataResponseData, testId]
    );
    const onSubmit = formValues => {
        onSave(formValues);
    };

    return (
        <div className={css.containerPopup}>
            <div className={css.header}>
                <div className={css.goBackContainer} onClick={onClose}>
                    <MemoChevronIcon className={css.goBackIcon} />
                    <div className={css.goBackText}>
                        <Translate id="STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.GO_BACK" />
                    </div>
                </div>
                <div className={css.title}>
                    <Translate id="STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.HEADER" />
                </div>
            </div>
            {isFetchingTest ? (
                <Spinner show expanded />
            ) : (
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    <WizardContainer onClose={onClose} />
                </Formik>
            )}
        </div>
    );
};

WizardWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    testId: PropTypes.string,
};

export default WizardWrapper;
