import React from 'react';
import css from './ConversionModelInfo.css';
import { conversionModelTypeShape } from '../../components/skan4/types';

function ConversionModelInfo({ model }) {
    const { id, enabledDatetime, disabledDatetime, conversionModelGroupId } = model;

    const parseUnixTimestamp = timestamp => {
        return timestamp != null ? new Date(timestamp * 1000).toLocaleDateString('en-CA') : 'N/A';
    };

    return (
        <div className={css.modelInfo}>
            <div>Group ID: {conversionModelGroupId}</div>
            <div>Model ID: {id}</div>
            <div>Enabled Datetime: {parseUnixTimestamp(enabledDatetime)}</div>
            <div>Disabled Datetime: {parseUnixTimestamp(disabledDatetime)}</div>
        </div>
    );
}

ConversionModelInfo.propTypes = {
    model: conversionModelTypeShape,
};

ConversionModelInfo.defaultProps = {
    model: {},
};

export default ConversionModelInfo;
