import API from '../services/api';

let instance = null;
const api = new API();
const FIELDS_AVAILABILITY_URL = '/api/users/fields_availability';

export default class FieldsAvailabilityService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getFieldsAvailability() {
        return api.getJson(FIELDS_AVAILABILITY_URL, {
            method: 'GET',
            credentials: 'include',
        });
    }
}
