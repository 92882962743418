import { connect } from 'react-redux';
import ReportUnenrichedButton from '../../components/widgets/ReportUnenrichedButton';
import { setActionType, setUnenriched } from '../../actions/reports';
import { getAdminModeEnabled } from '../../selectors/user';
import { reportDataSelectors } from '../../selectors/reports';

const mapStateToProps = (state, ownProps) => {
    const selected = reportDataSelectors[ownProps.reportType](state);
    return {
        unenriched: selected.unenriched,
        hide: !getAdminModeEnabled(state),
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...ownProps,
        onClick: () => dispatch(setActionType(setUnenriched(), ownProps.reportType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportUnenrichedButton);
