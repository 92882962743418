export const GENERATE_ANONYMOUS_LINK = 'action.anonymous.generate.link';
export const DELETE_REPORT = 'action.anonymous.report.delete';
export const CLOSE_SHELF = 'action.anonymous.close.shelf';
export const GENERATE_ANONYMOUS_LIST = 'action.anonymous.generate.list';
export const SHOW_PASSWORD_MODAL = 'action.anonymous.show.password.modal';
export const UPDATE_REPORT_NAME = 'action.anonymous.update.report.name';
export const HIDE_PASSWORD_MODAL = 'action.anonymous.hide.password.modal';
export const RUN_ANONYMOUS_PAGE = 'action.anonymous.run.page';
export const UPDATE_ANONYMOUS_ID = 'action.anonymous.update.anonymous.id';
export const TOGGLE_DATE_SELECTION = 'action.anonymous.toggle.date.selection';
export const UPDATE_CREATOR = 'action.anonymous.update.creator';
export const UPDATE_PASSWORD = 'action.anonymous.update.password';
export const UPDATE_ANONYMOUS_ERROR = 'action.anonymous.update.error';

export const generateAnonymousLink = (name, password, expirationDays, flexibleDates, reportType) => {
    return {
        type: GENERATE_ANONYMOUS_LINK,
        name,
        password,
        expirationDays,
        flexibleDates,
        reportType,
    };
};

export const closeShelf = reportType => {
    return {
        type: CLOSE_SHELF,
        reportType,
    };
};

export const deleteReport = (secretId, reportType) => {
    return {
        type: DELETE_REPORT,
        secretId,
        reportType,
    };
};

export const generateAnonymousList = () => {
    return {
        type: GENERATE_ANONYMOUS_LIST,
    };
};

export const updateReportName = reportName => {
    return {
        type: UPDATE_REPORT_NAME,
        reportName,
    };
};

export const hidePasswordModal = () => {
    return {
        type: HIDE_PASSWORD_MODAL,
    };
};

export const showPasswordModal = () => {
    return {
        type: SHOW_PASSWORD_MODAL,
    };
};

export const runAnonymousPage = () => {
    return {
        type: RUN_ANONYMOUS_PAGE,
    };
};

export const updateSecretId = secretId => {
    return {
        type: UPDATE_ANONYMOUS_ID,
        secretId,
    };
};

export const updateCreator = creator => {
    return {
        type: UPDATE_CREATOR,
        creator,
    };
};

export const updatePassword = password => {
    return {
        type: UPDATE_PASSWORD,
        password,
    };
};

export const updateError = msg => {
    return {
        type: UPDATE_ANONYMOUS_ERROR,
        msg,
    };
};

export const toggleDateSelection = show => {
    return {
        type: TOGGLE_DATE_SELECTION,
        show,
    };
};
