export const SELECTED_SOURCE_LOCAL_STORAGE_KEY = 'CampaignReport.source';
export const SELECTED_CAMPAIGN_LOCAL_STORAGE_KEY = 'CampaignReport.campaign';
export const START_DATE_LOCAL_STORAGE_KEY = 'CampaignReport.startDate';
export const END_DATE_LOCAL_STORAGE_KEY = 'CampaignReport.endDate';

export const DATA_TEST_ID_SOURCES_AUTOCOMPLETE = 'sources-autocomplete';
export const DATA_TEST_ID_CAMPAIGNS_AUTOCOMPLETE = 'campaigns-autocomplete';

export const SELECTED_CAMPAIGN_MIXPANEL_EVENT_NAME = 'Selected campaign filter';
export const SELECTED_SOURCE_MIXPANEL_EVENT_NAME = 'Selected source filter';
