import { call, put } from 'redux-saga/effects';
import { queryFinished, queryStart, unload } from './actions';
import AthenaOverRealtimeAPI from './service';

const api = new AthenaOverRealtimeAPI();

function* onload() {
    yield put(queryStart());
    const response = yield call(api.runQuery);
    console.log(response);
    yield put(queryFinished(response));
}

function* destroy() {
    yield put(unload());
}

const watchers = [];

export { onload, destroy, watchers };
