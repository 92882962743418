import React from 'react';


export default function SingularLoader(props) {
  return (
    <div className="singular-loader">
        {[...Array(5)].map((_, index) => (
            <div key={index} className="singular-loader__bar" />
        ))}
        <div className="singular-loader__ball" />
    </div>
  );
}