import API from './api';

let instance = null;
const api = new API();
const URL_SINGULAR_EVENTS = '/api/singular-events';
const URL_EVENTS_RELATED_REPORTS = '/api/singular-events-related';
const URL_UAN_EVENTS = '/api/uan-events';
const URL_PUBLIC_IDS = '/api/apps/publicIds';
const URL_ARCHIVED_STANDARD_EVENTS = '/api/archived-standard-events';
const URL_ARCHIVED_RESTORE_STANDARD_EVENT = `${URL_ARCHIVED_STANDARD_EVENTS}/restore`;

class CustomEvents {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    updateCustomEvents() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_SINGULAR_EVENTS, {
                method: 'GET',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getArchivedStandardEvents() {
        return api.getJson(URL_ARCHIVED_STANDARD_EVENTS, {
            method: 'GET',
            credentials: 'include',
            unique: true,
        });
    }

    restoreStandardEvent(data) {
        return api.getJson(URL_ARCHIVED_RESTORE_STANDARD_EVENT, {
            method: 'POST',
            credentials: 'include',
            unique: true,
            body: data,
        });
    }

    updateRelatedReports() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_EVENTS_RELATED_REPORTS, {
                method: 'GET',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateUanEvents() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_UAN_EVENTS, {
                method: 'GET',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updatePublicIDs() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_PUBLIC_IDS, {
                method: 'GET',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    saveCustomEvent(eventId, data) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_SINGULAR_EVENTS}${eventId ? `/${eventId}` : ''}`, {
                method: 'POST',
                credentials: 'include',
                body: data,
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    deleteCustomEvent(eventId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_SINGULAR_EVENTS}/${eventId}`, {
                method: 'DELETE',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default CustomEvents;
