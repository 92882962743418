import React from 'react';
import css from './ThreeLoadingDots.css';

class ThreeLoadingDots extends React.PureComponent {
    render() {
        return (
            <span className={css.container}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </span>
        );
    }
}

ThreeLoadingDots.propTypes = {};

ThreeLoadingDots.defaultProps = {};

export default ThreeLoadingDots;
