export const HOMEPAGE_PATH = '/react/home';
export const SDK_INTEGRATION_PATH = '/react/sdk_integration';
export const SHOW_BACK_PANEL = 'showBackPanel';
export const BACK_PANEL_ORIGIN_PATH = 'backPanelOriginPath';

export const BACK_PANEL_CONTAINER_CSS_CLASS = 'back-panel-container';
export const BACK_PANEL_SHOWN_CSS_CLASS = 'back-panel-shown';

export const PATH_TO_SECTION_DISPLAY_NAME = {
    [HOMEPAGE_PATH]: 'ONBOARDING_GUIDE',
    [SDK_INTEGRATION_PATH]: 'SDK_INTEGRATION',
};
