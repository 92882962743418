import { call, put, takeLatest, delay } from 'redux-saga/effects';

import {
    SUBMIT_SIGNUP_FORM,
    setStatus,
    setIsLoading,
    RESEND_EMAIL,
    setEmailSent,
    SUBMIT_COMPLETE_GOOGLE_SIGN_UP,
    STARTED_SIGNUP_FORM,
    STARTED_GOOGLE_SIGNUP_FORM,
    setFinishedGoogleSignupFirstStep,
} from './actions';
import SignupService from './service';
import { setGeneralError } from '../appLoadingState/actions';

const signupService = new SignupService();

export function* resendEmail(action) {
    try {
        yield put(setIsLoading(true));
        yield put(setEmailSent(false));

        yield call(signupService.resendEmail, action.data);

        yield put(setIsLoading(false));
        yield put(setEmailSent(true));

        yield delay(10000);

        yield put(setEmailSent(false));
    } catch (res) {
        if (res.message === 'ERROR_ORG_EXISTS') {
            yield put(setGeneralError('STATIC.PAGES.SIGNUP.ERROR_ORG_EXISTS'));
        } else if (res.message) {
            yield put(setGeneralError(res.message));
        } else {
            yield put(setGeneralError());
        }
        console.error(res);
        yield put(setIsLoading(false));
        yield put(setEmailSent(false));
    }
}

export function* startedSignupForm(action) {
    try {
        yield put(setStatus(null, null));
        yield put(setIsLoading(true));
        yield call(signupService.startedSignupForm, action.data);
    } catch (res) {
        yield put(setStatus('error', res.message));
    } finally {
        yield put(setIsLoading(false));
    }
}

export function* submitSignupForm(action) {
    try {
        yield put(setStatus(null, null));
        yield put(setIsLoading(true));

        const { success, message: errorCode } = yield call(signupService.submitSignupForm, action.data);

        window.mixpanel.track('sign_up_submit', {
            signup_email: action.data.email,
            currency: action.data.currency.name,
            timezone: action.data.timezone.name,
            country: action.data.country.display_name,
            has_mobile_app: action.data.hasMobileApp.name,
            user_role: action.data.userRole.name,
            organization_display_name: action.data.companyName,
            time_to_submit: (new Date() - action.data.pageLoadTime) / 1000,
            server_error: errorCode,
            success: (!!success).toString(),
        });
        yield put(setIsLoading(false));
        yield put(setStatus(success ? 'success' : 'error', errorCode));
    } catch (res) {
        console.error(res);
        yield put(setIsLoading(false));
        yield put(setStatus('error', res.message));

        window.mixpanel.track('sign_up_submit', {
            signup_email: action.data.email,
            currency: action.data.currency.name,
            timezone: action.data.timezone.name,
            has_mobile_app: action.data.hasMobileApp.name,
            user_role: action.data.userRole.name,
            organization_display_name: action.data.companyName,
            time_to_submit: (new Date() - action.data.pageLoadTime) / 1000,
            server_error: res.message,
            success: 'false',
        });
    }
}

export function* startedGoogleSignupForm(action) {
    try {
        yield put(setStatus(null, null));
        yield put(setIsLoading(true));
        yield call(signupService.startedGoogleSignUpForm, action.data);
        yield put(setFinishedGoogleSignupFirstStep(true));
    } catch (res) {
        yield put(setStatus('error', res.message));
    } finally {
        yield put(setIsLoading(false));
    }
}

export function* submitCompleteGoogleSignUp(action) {
    try {
        yield put(setStatus(null, null));
        yield put(setIsLoading(true));

        const { success, message: errorCode, new_user_created, signup_email, proceed } = yield call(
            signupService.submitCompleteGoogleSignUp,
            action.data
        );

        if (!success || new_user_created) {
            // Note! If you're changing this event name, please make sure to also change it in backend!
            window.mixpanel.track('sign_up_submit_via_google', {
                time_to_submit: (new Date() - action.data.pageLoadTime) / 1000,
                currency: action.data.currency.name,
                timezone: action.data.timezone.name,
                has_mobile_app: action.data.hasMobileApp.name,
                user_role: action.data.userRole.name,
                organization_display_name: action.data.companyName,
                server_error: errorCode,
                success: (!!success).toString(),
                signup_email,
                origin: 'signup_page',
            });
        } else {
            window.mixpanel.track('Track Missing Sign Ups', {
                server_error_test: errorCode,
                success_test: (!!success).toString(),
                signup_email,
                origin_test: 'signup_page',
                new_user_created,
            });
        }

        if (success && proceed) {
            document.location.href = '/#/react/home';
        } else {
            yield put(setIsLoading(false));
            yield put(setStatus(success ? 'success' : 'error', errorCode));
        }
    } catch (res) {
        console.error(res);
        yield put(setIsLoading(false));
        yield put(setStatus('error', res.message));

        window.mixpanel.track('sign_up_submit_via_google', {
            time_to_submit: (new Date() - action.data.pageLoadTime) / 1000,
            server_error: res.message,
            success: 'false',
            signup_email: res.signup_email,
            origin: 'signup_page',
        });
    }
}

function* watchSubmitSignupForm() {
    yield takeLatest(STARTED_SIGNUP_FORM, startedSignupForm);
    yield takeLatest(SUBMIT_SIGNUP_FORM, submitSignupForm);
    yield takeLatest(RESEND_EMAIL, resendEmail);
    yield takeLatest(STARTED_GOOGLE_SIGNUP_FORM, startedGoogleSignupForm);
    yield takeLatest(SUBMIT_COMPLETE_GOOGLE_SIGN_UP, submitCompleteGoogleSignUp);
}

export const watchers = [watchSubmitSignupForm];
