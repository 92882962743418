import API from 'services/api';

let instance = null;
const api = new API();
export const URL_SDK_CONFIG = 'api/sdk_config';

export default class SDKKeysService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getSDKConfig() {
        return api.getJson(URL_SDK_CONFIG, {
            method: 'GET',
            legacyStatus: true,
        });
    }

    saveSDKConfig(answers, platforms) {
        return api.getJson(URL_SDK_CONFIG, {
            method: 'POST',
            legacyStatus: true,
            body: { answers, platforms },
        });
    }
}
