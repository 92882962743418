import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SingularButton from '../components/widgets/SingularButton';
import WarningMessage from '../components/widgets/WarningMessage';
import css from './style.css';
import Label from '../components/widgets/Label';
import { trackTimeSpentOnTask } from './utils';

export default function HomePageContent(props) {
    const {
        warningMsg,
        mediaSrc,
        mediaSrcType,
        buttonText,
        buttonOnClick,
        locked,
        ContentBodyComponent,
        itemId,
        title,
        apsalarEnabled,
    } = props;

    const [lastTaskId, setLastTaskId] = useState(null);
    const [lastVisitedTimestamp, setLastVisitedTimestamp] = useState(new Date().getTime());

    useEffect(() => {
        if (lastTaskId) {
            trackTimeSpentOnTask(lastTaskId, lastVisitedTimestamp);
        }

        setLastTaskId(itemId);
        setLastVisitedTimestamp(new Date().getTime());
    }, [itemId]);

    return (
        <Fragment>
            <div className={css.mediaContainer}>
                {mediaSrcType === 'video' && (
                    <iframe
                        title="singular"
                        className={classNames(css.media)}
                        src={mediaSrc}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                )}
                {mediaSrcType === 'img' && <img alt="singular" className={css.media} src={mediaSrc} />}
            </div>
            <div className={css.header}>
                <Label
                    style={{
                        lineHeight: '36px',
                        fontWeight: 800,
                    }}
                    type="h1"
                    text={title}
                />
                {buttonOnClick && (
                    <SingularButton
                        type="primary"
                        level="level1"
                        className={css.button}
                        text={buttonText}
                        onClick={buttonOnClick}
                        disabled={!!locked}
                    />
                )}
            </div>
            <div className={css.body}>
                {ContentBodyComponent && <ContentBodyComponent taskId={itemId} isApsalarEnabled={apsalarEnabled} />}
            </div>
            <div className={css.footer}>
                {warningMsg ? (
                    <WarningMessage
                        show
                        type="error"
                        message={warningMsg}
                        showIcon={false}
                        containerStyle={{
                            width: '500px',
                        }}
                    />
                ) : (
                    <span />
                )}
            </div>
        </Fragment>
    );
}

HomePageContent.propTypes = {
    buttonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    mediaSrc: PropTypes.string,
    mediaSrcType: PropTypes.oneOf(['video', 'img']),
    warningMsg: PropTypes.string,
    buttonOnClick: PropTypes.func,
    locked: PropTypes.bool,
    ContentBodyComponent: PropTypes.func,
    apsalarEnabled: PropTypes.bool,
};

HomePageContent.defaultProps = {
    buttonOnClick: undefined,
    warningMsg: undefined,
    mediaSrc: undefined,
    mediaSrcType: undefined,
    locked: false,
    ContentBodyComponent: undefined,
    apsalarEnabled: false,
};

HomePageContent.defaultProps = {};
