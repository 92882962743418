import API from '../services/api';

let instance = null;
const api = new API();
const URL_HOMEPAGE = 'api/users/self/homepage';
const URL_HOMEPAGE_SET_COMPLETED_TASK = 'api/homepage/set_completed_task';
const AUDIT_SDK = 'api/audit/sdk';
const AUDIT_GENERAL = 'api/audit/general';

export default class HomepageService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getHomepageData() {
        return api.getJson(URL_HOMEPAGE, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    saveCompletedTask(completedTaskName, adminMarkedAsDone = false) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_HOMEPAGE_SET_COMPLETED_TASK, {
                method: 'POST',
                credentials: 'include',
                body: {
                    completed_task_name: completedTaskName,
                    admin_marked_as_done: adminMarkedAsDone,
                },
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    auditSdk() {
        return new Promise((resolve, reject) => {
            api.getJson(AUDIT_SDK, {
                method: 'GET',
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    auditGeneral() {
        return new Promise((resolve, reject) => {
            api.getJson(AUDIT_GENERAL, {
                method: 'GET',
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
