import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Table.css';
import TableRowActions from './TableRowActions';
import Tooltip from './Tooltip';

const TableCellText = ({
    value,
    index,
    isEditable,
    onDelete,
    onAdd,
    getIsAddDisabled,
    getIsDeleteDisabled,
    addDisabledTooltip,
    getIsShowAdd,
    getIsShowDelete,
    showRowActions,
    className,
    tdStyle,
    tooltip,
    cellTooltipKey,
}) => {
    return (
        <td className={classNames(css.rowIndexCell, className)} style={tdStyle}>
            <Tooltip titleTranslationKey={cellTooltipKey} disabled={!(tooltip || cellTooltipKey)} title={tooltip || ''}>
                {value}
            </Tooltip>
            {isEditable && showRowActions && (
                <TableRowActions
                    onAdd={() => onAdd(index)}
                    onDelete={() => onDelete(index)}
                    isDeleteDisabled={getIsDeleteDisabled(index)}
                    isAddDisabled={getIsAddDisabled(index)}
                    addDisabledTooltip={addDisabledTooltip}
                    isShowAdd={getIsShowAdd(index)}
                    isShowDelete={getIsShowDelete(index)}
                />
            )}
        </td>
    );
};

TableCellText.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.number,
    isEditable: PropTypes.bool,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    getIsAddDisabled: PropTypes.func,
    getIsDeleteDisabled: PropTypes.func,
    showRowActions: PropTypes.bool,
    addDisabledTooltip: PropTypes.string,
    getIsShowAdd: PropTypes.func,
    getIsShowDelete: PropTypes.func,
    className: PropTypes.string,
    tdStyle: PropTypes.objectOf(PropTypes.any),
    tooltip: PropTypes.string,
    cellTooltipKey: PropTypes.string,
};

TableCellText.defaultProps = {
    index: undefined,
    isEditable: false,
    onDelete: undefined,
    onAdd: undefined,
    getIsAddDisabled: () => false,
    getIsDeleteDisabled: () => false,
    showRowActions: false,
    addDisabledTooltip: undefined,
    getIsShowAdd: () => true,
    getIsShowDelete: () => true,
    className: undefined,
    tdStyle: {},
    tooltip: '',
};

export default TableCellText;
