import API from './api';

const api = new API();
let instance = null;

const URL_USER_SELF = '/api/users/self';
const URL_USER_ONBOARDING = '/api/users/self/onboarding';
const URL_SAVE_PASSWORD = '/password_change';

class User {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getCurrentUser() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_USER_SELF, {
                method: 'GET',
                credentials: 'include',
                cache: true,
            })
                .then(response => {
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    getCurrentOnboardingState() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_USER_ONBOARDING, {
                method: 'GET',
                credentials: 'include',
                cache: true,
            })
                .then(response => {
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    savePassword({ oldPassword, newPassword }) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_SAVE_PASSWORD, {
                method: 'POST',
                credentials: 'include',
                body: { pwd: btoa(JSON.stringify([oldPassword, newPassword])) },
                cache: false,
                unique: true,
            })
                .then(resolve)
                .catch(reject);
        });
    }
}

export default User;
