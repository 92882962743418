import Highcharts from 'highcharts';
import moment from 'moment';
import { expandMetadata, expandData } from './reports';
import { isFullSpanPeriod } from './regional';
import { INDICATION_FIELDS_PREFIX } from './reportsConsts';

const dashStyles = [
    'Solid',
    'Dash',
    'ShortDash',
    'Dot',
    'ShortDot',
    'ShortDashDot',
    'ShortDashDotDot',
    'LongDash',
    'DashDot',
    'LongDashDot',
    'LongDashDotDot',
];

const dashStylesCSS = ['1000', '8,6', '6,2', '2,6', '2,2', '6,2,2,2', '2,2', '2,2', '2,2', '2,2', '2,2'];

export const chartColor1 = '#4479A9';
export const chartColor2 = '#0BB5D2';
export const chartColor3 = '#F4B03C';
export const chartColor4 = '#F37773';
export const chartColor5 = '#A75885';
export const chartColor6 = '#17A38F';
export const chartColor7 = '#B186D8';
export const chartColor8 = '#FED674';
export const chartColor9 = '#A2745E';
export const chartColor10 = '#C3BAB7';

const lineColors = [
    chartColor1,
    chartColor2,
    chartColor3,
    chartColor4,
    chartColor5,
    chartColor6,
    chartColor7,
    chartColor8,
    chartColor9,
    chartColor10,
];

const metricsPriority = ['adn_cost', 'custom_installs'];

const dateFields = ['date_field', 'skan_postback_date', 'estimated_install_date'];

const hashCode = data => {
    let hash = 0;
    if (data.length === 0) return hash;
    for (let i = 0; i < data.length; i++) {
        const char = data.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return hash;
};

const reportDataToChartData = reportsData => {
    // The function logic was copied from the backend in order to avoid using different API results for the reports table and chart.
    // The next step is to fix the chart code to use the same backend result without the need for this function.
    const chartResults = { dimensions: {}, metrics: [] };
    const keyDimensions = [];
    const metadata = expandMetadata(reportsData.metadata, false);
    const expandedReportsData = expandData(metadata, reportsData.results);

    Object.values(metadata).forEach(field => {
        if (field.type === 'metric') {
            chartResults.metrics.push({
                id: field.id,
                name: field.name,
                display_name: field.display_name,
                format: field.display_format,
            });
            chartResults[field.name] = {};
        } else if (!dateFields.includes(field.name) && !field.name.includes(INDICATION_FIELDS_PREFIX)) {
            keyDimensions.push({ id: field.id, name: field.name });
        }
    });

    chartResults.metrics.sort((a, b) => {
        if (a.id > b.id) {
            return 1;
        }
        if (a.id < b.id) {
            return -1;
        }
        return 0;
    });

    keyDimensions.sort((a, b) => {
        if (a.id > b.id) {
            return 1;
        }
        if (a.id < b.id) {
            return -1;
        }
        return 0;
    });

    expandedReportsData.forEach(row => {
        let keyForRow = [];
        keyDimensions.forEach(dimensionField => {
            let currentDimensionValue = row[dimensionField.name];
            if (typeof currentDimensionValue !== 'string') {
                currentDimensionValue = String(currentDimensionValue);
            }
            keyForRow.push(currentDimensionValue);
        });

        const displayNameForRow = keyForRow.join(' - ');
        keyForRow = hashCode(displayNameForRow);
        chartResults.dimensions[keyForRow] = displayNameForRow;

        Object.values(chartResults.metrics).forEach(metric => {
            let dateValue = '';
            dateFields.forEach(dateField => {
                if (row[dateField]) {
                    dateValue = row[dateField];
                }
            });

            if (dateValue in chartResults[metric.name]) {
                chartResults[metric.name][dateValue][keyForRow] = row[metric.name];
            } else {
                chartResults[metric.name][dateValue] = {};
                chartResults[metric.name][dateValue][keyForRow] = row[metric.name];
                chartResults[metric.name][dateValue].date = dateValue;
            }
        });
    });

    return chartResults;
};

const valueFormatter = (metric, isForYAxis = false) => {
    return function getValue(inValue, inAxis = { isLog: true }) {
        const val = this.value || inValue;
        const axis = this.axis || inAxis;
        let { precision } = metric.format;

        if (isForYAxis && metric.format.type === 'currency') {
            precision = 0;
        } else if (!precision) {
            precision = -1;
        }

        const { numericSymbols } = Highcharts.getOptions().lang;
        const numericSymbolDetector = axis.isLog ? val : axis.tickInterval;

        let ret;
        let i = numericSymbols && numericSymbols.length;

        while (i--) {
            const multi = Math.pow(1000, i + 1);
            if (numericSymbolDetector >= multi && (val * 10) % multi === 0 && numericSymbols[i] !== null) {
                ret = Highcharts.numberFormat(val / multi, precision) + numericSymbols[i];
                break;
            }
        }

        // TODO: Refactor this whole function
        if (ret === undefined) {
            if (metric.format.type === 'percentage') {
                ret = val;
            } else {
                ret = Highcharts.numberFormat(val, precision);
            }
        }

        if (metric.format.type === 'currency' && metric.format.symbol) {
            ret = `${metric.format.symbol.trim()}${ret}`;
        } else if (metric.format.type === 'percentage') {
            ret = `${(ret * 100).toFixed(metric.format.precision)}%`;
        }

        return ret;
    };
};

export const getTooltipValuesPercentiles = (css, data, key, metrics, itemStyle) => {
    return metrics
        .map(metricName => {
            const { type, low, high } = data[key].metrics[metricName];
            const formatter = data[key].formatter[metricName];
            let val;

            if (type === 'marker') {
                val = `${formatter(low)}`;
            } else {
                val = `${formatter(low)} - ${formatter(high)}`;
            }

            return `<td><span class="${css.tooltipValue}" style="${itemStyle}">${val}</span></td>`;
        })
        .reduce((current, total) => total + current, '');
};

export const getTooltipValuesPercentage = (css, data, key, metrics, itemStyle) => {
    return metrics
        .map(metricName => {
            const val = data[key].metrics[metricName];

            if (val > 0 && val < 0.1) {
                return `<td><span class="${css.tooltipValue}" style="${`${itemStyle};margin-left: -13px`}">< ${data[
                    key
                ].formatter[metricName](0.01)}</span></td>`;
            } else {
                return `<td><span class="${css.tooltipValue}" style="${itemStyle}">${data[key].formatter[metricName](
                    val
                )}</span></td>`;
            }
        })
        .reduce((current, total) => total + current, '');
};

const getTooltipValuesDefault = (css, data, key, metrics, itemStyle) => {
    return metrics
        .map(metricName => {
            let val = 'N/A';
            if (data[key].metrics[metricName]) {
                val = data[key].formatter[metricName](data[key].metrics[metricName]);
            }
            return `<td><span class="${css.tooltipValue}" style="${itemStyle}">${val}</span></td>`;
        })
        .reduce((current, total) => total + current, '');
};

const tooltipFormatter = (
    css,
    breakdown,
    dateFormat = 'LL',
    isTextHeader = false,
    getTooltipValues = getTooltipValuesDefault,
    shouldSort = true,
    getColorByValue,
    tooltipHeader
) => {
    const getHeaders = metrics => {
        return metrics
            .map(metricName => {
                return `<td><span class="${css.tooltipValue} ${css.tooltipValueHeader}">${metricName}</span></td>`;
            })
            .reduce((current, total) => total + current, '');
    };

    return function ttFormatter() {
        const data = {};
        const metrics = [];
        this.points.forEach(point => {
            const {
                series: { name, options },
                y,
                color,
                point: { low, high, type },
            } = point;
            if (!data[name]) {
                data[name] = {};
            }
            if (!metrics.includes(options.yAxis)) {
                metrics.push(options.yAxis);
            }
            if (!data[name].formatter) {
                data[name].formatter = {};
            }
            if (!data[name].metrics) {
                data[name].metrics = {};
            }
            data[name].metrics[options.yAxis] = low && high ? { low, high, type } : y;
            data[name].color = getColorByValue ? getColorByValue(y) : color;
            data[name].formatter[options.yAxis] = options.formatter;
        });
        metrics.reverse();
        let s = `<div class="${css.tooltipContainer}">`;
        let header = '';

        if (tooltipHeader) {
            header = tooltipHeader;
        } else if (isTextHeader) {
            header = this.x;
        } else {
            header = moment(this.x, 'YYYY-MM-DD').format(dateFormat);
        }

        if (this.x && this.x.includes && this.x.includes('...')) {
            const split = this.x.split(' ... ');
            const partialSpan =
                breakdown && !isFullSpanPeriod(breakdown, split[0], split[1]) ? ` (Partial ${breakdown})` : '';
            header = `${moment(split[0], 'YYYY-MM-DD').format(dateFormat)} - ${moment(split[1], 'YYYY-MM-DD').format(
                dateFormat
            )}${partialSpan}`;
        }
        s += `<div class="${css.tooltipHeader}">${header}</div>`;

        s += `<div class="${css.tableContainer}"><table>`;
        if (metrics.length > 1) {
            s += `<tr class="${css.tooltipItem}">
                        <td class="${css.tooltipItemColor}" style="background-color:transparent;" ></td>
                        <td class="${css.tooltipItemName}" > </td>
                        ${getHeaders(metrics)}
                  </tr>`;
        }

        let arrangedList = Object.keys(data).map(item => {
            return {
                label: item,
                orderBy: data[item].metrics[Object.keys(data[item].metrics)[0]],
            };
        });

        if (shouldSort) {
            arrangedList = arrangedList.sort((a, b) => {
                return b.orderBy - a.orderBy;
            });
        }
        const { hoverSeries } = this.points[0].series.chart;

        arrangedList.forEach(item => {
            const itemStyle = item.label === hoverSeries?.name && arrangedList.length > 1 ? 'font-weight:700' : '';

            s += `<tr class="${css.tooltipItem}">
                            <td>
                                <div class="${css.tooltipItemColor}" style="background-color:${
                data[item.label].color
            };" ></div>
                            </td>
                            <td>
                                <div class="${css.tooltipItemName}" style="${itemStyle}"> <span>${item.label.slice(
                0,
                -10
            )}</span><span class="${css.tooltipEnding}">${item.label.slice(-10)}</span> </div>
                            </td>
                            ${getTooltipValues(css, data, item.label, metrics, itemStyle)}
                        </tr>`;
        });
        s += '</div></table>';
        s += '</div>';
        return s;
    };
};

const legendFormatter = css => {
    return function legendLabelFormatter() {
        return `<div class="${css.legendItem}" title="${this.name}">
                    <div class="${css.legendColor}" style="background-color: ${this.color};" ></div>
                    <div class="${css.legendText}" title="${this.name}"> ${this.name} </div>
                </div>`;
    };
};

const tooltipPositioner = function positioner(boxWidth, boxHeight, point) {
    // eslint-disable-line object-shorthand
    // taken from http://ahumbleopinion.com/customizing-highcharts-tooltip-positioning/
    const { pick } = Highcharts;
    const { distance, chart } = this;

    const ret = {};
    // Don't use h if chart isn't inverted (#7242)
    const h = (chart.inverted && point.h) || 0; // #4117
    let swapped;
    let first = [
        'y',
        chart.chartHeight,
        boxHeight,
        point.plotY + chart.plotTop,
        chart.plotTop,
        chart.plotTop + chart.plotHeight,
    ];
    let second = [
        'x',
        chart.chartWidth,
        boxWidth,
        point.plotX + chart.plotLeft,
        chart.plotLeft,
        chart.plotLeft + chart.plotWidth,
    ];
    // The far side is right or bottom
    const preferFarSide = !this.followPointer && pick(point.ttBelow, !chart.inverted === !!point.negative); // #4984

    /*
     * Handle the preferred dimension. When the preferred dimension is
     * tooltip on top or bottom of the point, it will look for space
     * there.
     */
    const firstDimension = (dim, outerSize, innerSize, point, min, max) => {
        const roomLeft = innerSize < point - distance;
        const roomRight = point + distance + innerSize < outerSize;
        const alignedLeft = point - distance - innerSize;
        const alignedRight = point + distance;

        if (preferFarSide && roomRight) {
            ret[dim] = alignedRight;
        } else if (!preferFarSide && roomLeft) {
            ret[dim] = alignedLeft;
        } else if (roomLeft) {
            ret[dim] = Math.min(max - innerSize, alignedLeft - h < 0 ? alignedLeft : alignedLeft - h);
        } else if (roomRight) {
            ret[dim] = Math.max(min, alignedRight + h + innerSize > outerSize ? alignedRight : alignedRight + h);
        } else {
            return false;
        }
        return true;
    };
    /*
     * Handle the secondary dimension. If the preferred dimension is
     * tooltip on top or bottom of the point, the second dimension is to
     * align the tooltip above the point, trying to align center but
     * allowing left or right align within the chart box.
     */
    const secondDimension = (dim, outerSize, innerSize, point) => {
        let retVal;

        // Too close to the edge, return false and swap dimensions
        if (point < distance || point > outerSize - distance) {
            retVal = false;
            // Align left/top
        } else if (point < innerSize / 2) {
            ret[dim] = 1;
            // Align right/bottom
        } else if (point > outerSize - innerSize / 2) {
            ret[dim] = outerSize - innerSize - 2;
            // Align center
        } else {
            ret[dim] = point - innerSize / 2;
        }
        return retVal;
    };
    /*
     * Swap the dimensions
     */
    const swap = count => {
        const temp = first;
        first = second;
        second = temp;
        swapped = count;
    };
    const run = () => {
        if (firstDimension.apply(0, first) !== false) {
            if (secondDimension.apply(0, second) === false && !swapped) {
                swap(true);
                run();
            }
        } else if (!swapped) {
            swap(true);
            run();
        } else {
            ret.x = 0;
            ret.y = 0;
        }
    };

    // Under these conditions, prefer the tooltip on the side of the point
    if (chart.inverted || this.len > 1) {
        swap();
    }
    run();

    return ret;
};

export {
    reportDataToChartData,
    lineColors,
    dashStyles,
    valueFormatter,
    tooltipFormatter,
    legendFormatter,
    dashStylesCSS,
    tooltipPositioner,
    metricsPriority,
};
