import * as PropTypes from 'prop-types';
import React from 'react';
import css from './Section.css';

export default function Section({ index, title, showDivider, children }) {
    return (
        <div className={css.section}>
            <div className={css.index}>{index}</div>
            <div className={css.content}>
                <div className={css.titleContainer}>
                    <span className={css.title}>{title}</span>
                    {showDivider && <div className={css.divider} />}
                </div>
                {children}
            </div>
        </div>
    );
}

Section.propTypes = {
    index: PropTypes.number,
    title: PropTypes.string,
    showDivider: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Section.defaultProps = {
    index: 0,
    title: '',
    showDivider: false,
};
