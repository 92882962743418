import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import GuideIcon from '../../../../resources/svg/guide.svg';
import css from './footerHelpCenterLink.css';
import { CONTENT_FOOTER_HELP_CENTER_LINK_TITLE, SECTIONS_TRANSLATE_KEY } from './consts';
import { Label } from '../../../../components/widgets';

const FooterHelpCenterLink = ({ translate, text, helpCenterLink, onHelpCenterLinkClick }) => {
    return (
        <div className={css.container}>
            <div className={css.iconContainer}>
                <GuideIcon />
            </div>
            <div className={css.textContainer}>
                <Label
                    type="p"
                    text={text}
                    className={classNames(css.line, { [css.appendedLine]: !!helpCenterLink })}
                />
                {helpCenterLink && (
                    <a
                        className={css.link}
                        target="_blank"
                        onClick={() => onHelpCenterLinkClick(helpCenterLink)}
                        href={helpCenterLink}
                        rel="noreferrer"
                    >
                        {translate(`${SECTIONS_TRANSLATE_KEY}.${CONTENT_FOOTER_HELP_CENTER_LINK_TITLE}`)}
                    </a>
                )}
            </div>
        </div>
    );
};

FooterHelpCenterLink.propTypes = {
    translate: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    helpCenterLink: PropTypes.string.isRequired,
    onHelpCenterLinkClick: PropTypes.func,
};

FooterHelpCenterLink.defaultProps = {
    onHelpCenterLinkClick: () => {},
};

export default withLocalize(FooterHelpCenterLink);
