import { connect } from 'react-redux';
import { createCustomDimensionSelector } from 'customDimensions/selectors';
import * as actions from 'customDimensions/actions';
import CustomDimension from 'customDimensions/components/CustomDimension';

const mapStateToProps = (state, ownProps) => {
    return createCustomDimensionSelector(ownProps.dimensionId)(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onValueAdded: dimensionId => {
            dispatch(actions.valueAdded(dimensionId));
        },
        onDimensionExpansionToggle: id => {
            dispatch(actions.dimensionExpansionToggle(id));
        },
        onDimensionNameChanged: (id, value) => {
            dispatch(actions.dimensionNameChanged(id, value));
        },
        onDefaultValueChanged: (id, value) => {
            dispatch(actions.defaultValueChanged(id, value));
        },
        onDimensionDeleted: id => {
            dispatch(actions.customDimensionRemoveAttempted(id));
        },
        onDimensionUndoChanges: id => {
            dispatch(actions.customDimensionUndoChanges(id));
        },
        onDimensionSave: (id, reordered) => {
            dispatch(actions.customDimensionSave(id, reordered));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDimension);
