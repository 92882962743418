import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './AdnetworkBasicLogin.css';
import WizardFooter from '../../components/widgets/WizardFooter';
import WarningMessage from '../../teamManagement/components/WizardWarningMessage';
import { SingularButton, Spinner, TextField, HelpMenu } from '../../components/widgets';
import { parseSafeHtml } from '../../utils/render';
import AdNetworkReportError from './AdNetworkReportError';
import {
    AUTH_TYPES,
    NO_WINDOW_OPENER_AUTH_TYPES,
    AUTH_TYPES_DISPLAY_NAMES_WITH_ARTICLES,
    FILE_BASED_AUTH_TYPES,
    DATA_SOURCES_STATUSES,
    OAUTH_NETWORKS,
    NETWORKS_WITH_ACCOUNTS_NAMES,
    NETWORKS_WITH_ACCOUNT_MAPPING,
    MANUAL_SCRAPE_SUBSTATUS,
} from '../utils';
import DataConnectorAccounts from './DataConnectorAccounts';
import { sortAlphabetically } from '../../utils/sortUtil';
import { trackMixpanelEvent } from '../../utils/general';
import { OAuthLoginField } from '../../components/partials';

const mixpanelPrefix = 'Data Connectors';
const mccClientIdRegexp = /^([0-9]{10}|[0-9]{3}-[0-9]{3}-[0-9]{4})$/;
const gdriveCustomSubHeader = 'This is a Gdrive integration, share your doc first with reports@singular.net';
const emailCustomSubHeader = 'This is an email integration';
const faqUrl =
    'https://support.singular.net/hc/en-us/articles/360001353492-Setting-Up-Mail-Reports-and-Google-Docs-Reports#Email_Report_Format';
const customSubHeader = 'Need help with this partner?';

function AdnetworkBasicLogin({
    showSavingSuccess,
    uanSaveErrorMessage,
    networkData,
    partnersIds,
    updatedUanId,
    authPopupUrl,
    partnerSourceId,
    uanAccountsData,
    onUanSubmit,
    onUanWithAccountsSubmit,
    onAdwordsMccSubmit,
    onAdwordsSubmit,
    onCancel,
    onUanAuthClick,
    validateLogin,
    setAuthPopupUrl,
    onAuthFail,
    getAccountsForUan,
    sendScrapeJob,
    sendScrapeJobStatus,
    onAuth,
    adnetworks,
    orgHasAppsWithSDKEvent,
    onChange,
}) {
    let textTimeoutId = null;
    const hasExplanationLink = networkData.explanation && networkData.explanation.startsWith('http');
    const passwordPlaceholder = '**********';
    const tokenPlaceholder = '********************';
    const isNewUan = !networkData.uan_id;
    const uanIdRef = useRef(networkData.uan_id);
    const authModal = useRef(window.self);
    let supportsAccounts = NETWORKS_WITH_ACCOUNTS_NAMES.includes(networkData.name);
    const getSortedUanAccounts = () =>
        uanAccountsData.accounts
            ? uanAccountsData.accounts.sort((a, b) => sortAlphabetically(a.name, b.name, false))
            : null;
    const [numberTypeToIgnore] = ['e', 'E', '+', '.'];

    const [showLoadingSpinner, setShowLoadingSpinner] = useState(!isNewUan);
    const [showSavingSpinner, setShowSavingSpinner] = useState(false);
    const [showRedirectSavingSpinner, setRedirectShowSavingSpinner] = useState(false);
    const [isSaveRedirectClicked, setIsSaveRedirectClicked] = useState(false);
    const [usernameValue, setUsernameValue] = useState('');
    const [passwordValue, setPasswordValue] = useState(isNewUan ? '' : passwordPlaceholder);
    const [tokenValue, setTokenValue] = useState(isNewUan ? '' : tokenPlaceholder);
    const [token2Value, setToken2Value] = useState(isNewUan ? '' : tokenPlaceholder);
    const [mccClientId, setMccClientId] = useState('');
    const [warningMessage, setWarningMessage] = useState(
        networkData.status === DATA_SOURCES_STATUSES.INACTIVE ? { message: networkData.message, type: 'error' } : {}
    );
    const [hasChanges, setHasChanges] = useState(false);
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const [isValidated, setIsValidated] = useState(!isNewUan);
    const [showPopupWindow, setShowPopupWindow] = useState(false);
    const [accountsDataToUpdate, setAccountsDataToUpdate] = useState(getSortedUanAccounts());
    const [automaticallyAddNewAccounts, setAutomaticallyAddNewAccounts] = useState(
        uanAccountsData.auto_add_new_accounts
    );
    const [uanStatus, setUanStatus] = useState(networkData.status);
    const [uanDescription, setUanDescription] = useState(isNewUan ? '' : networkData.subTextExtra);

    const defaultFieldPropeties = {
        clearable: true,
        debounce: 500,
        autoCompleteOff: true,
        disabled: showSavingSpinner || showRedirectSavingSpinner || showSavingSuccess,
    };
    const noAccountsSelected = useMemo(() => {
        return !accountsDataToUpdate || !accountsDataToUpdate.find(account => account.is_selected === true);
    }, [accountsDataToUpdate]);

    function isAdwords() {
        return networkData.name === 'adwords';
    }

    function isApplovin() {
        return networkData.name === 'applovin';
    }

    function isOAuthNetwork() {
        return OAUTH_NETWORKS.includes(networkData.auth_type);
    }

    function disableSpinners() {
        setShowSavingSpinner(false);
        setRedirectShowSavingSpinner(false);
    }

    useEffect(() => {
        onChange(hasChanges || (supportsAccounts && noAccountsSelected));
    }, [hasChanges, noAccountsSelected, supportsAccounts]);

    useEffect(() => {
        if (partnerSourceId) {
            setTimeout(() => {
                onCancel();
                setImmediate(() => {
                    window.location.hash = `#/partner-config?source=${partnerSourceId}`;
                });
            }, 2000);
        } else if (showSavingSuccess) {
            setWarningMessage({ message: 'STATIC.PAGES.DATA_SOURCES.SHELF.TOP_MESSAGES.SUCCESS', type: 'success' });
            disableSpinners();
        } else if (uanSaveErrorMessage) {
            setWarningMessage({ message: uanSaveErrorMessage, type: 'error' });
            disableSpinners();
        } else if (sendScrapeJobStatus.subStatus === MANUAL_SCRAPE_SUBSTATUS.SUCCESS) {
            setWarningMessage({
                message: 'STATIC.PAGES.DATA_SOURCES.SHELF.FIXED_PROBLEM_RESCRAPE_SENT',
                type: 'info',
            });
        } else if (sendScrapeJobStatus.subStatus === MANUAL_SCRAPE_SUBSTATUS.TIME_LIMIT_EXCEEDED) {
            setWarningMessage({
                message: 'STATIC.PAGES.DATA_SOURCES.SHELF.RESCRAPE_TIME_LIMIT_EXCEEDED',
                type: 'warning',
                textReplace: { timeFrame: sendScrapeJobStatus.value.time_frame },
            });
        } else if (isOAuthNetwork() && isValidated && supportsAccounts && noAccountsSelected) {
            setWarningMessage({
                message: 'STATIC.PAGES.DATA_SOURCES.SHELF.SELECT_ONE_ACCOUNT_INFO',
                type: 'info',
            });
        } else {
            setWarningMessage({});
        }
    }, [showSavingSuccess, uanSaveErrorMessage, sendScrapeJobStatus, partnerSourceId, accountsDataToUpdate]);

    useEffect(() => {
        if (!isNewUan) {
            setUsernameValue(networkData.user);
            if (Object.keys(networkData).includes('user')) {
                setShowLoadingSpinner(false);
            }
            supportsAccounts = NETWORKS_WITH_ACCOUNTS_NAMES.includes(networkData.name);
            setMccClientId(networkData.client_id || mccClientId || '');
        }
    }, [networkData]);

    useEffect(() => {
        // new uan should be updated with uan id on login error, so that another new uan is not created when re-submitting
        if (updatedUanId && !uanIdRef.current && saveButtonClicked) {
            uanIdRef.current = updatedUanId;
        }
    }, [updatedUanId]);

    useEffect(() => {
        if (authPopupUrl && showPopupWindow) {
            // In case the network doesn't support window.opener js object (e.g facebook)
            // we want to fully redirect to the authentication window.
            if (NO_WINDOW_OPENER_AUTH_TYPES.includes(networkData.auth_type)) {
                authModal.current = window.open(authPopupUrl, '_self');
            } else {
                authModal.current = window.open(authPopupUrl, '_blank', 'status=1, height=500, width=1024');
            }
            setShowPopupWindow(false);
        }
    });

    useEffect(() => {
        const sortedAccounts = getSortedUanAccounts();
        setAccountsDataToUpdate(sortedAccounts);
        setAutomaticallyAddNewAccounts(uanAccountsData.auto_add_new_accounts);
    }, [uanAccountsData]);

    function handleAdwordsError(urlRedirect, logText, uanAuthClick) {
        authModal.current.location.href = urlRedirect;
        setTimeout(() => {
            console.log(logText);
            uanAuthClick();
        }, 2000);
    }

    function preventNonNumericKeys(event) {
        return numberTypeToIgnore.includes(event.key) && event.preventDefault();
    }

    function handleAuthPopupResponse(uan, errorMsg, uanAuthClick) {
        if (authModal) {
            if (uan != null) {
                uanIdRef.current = uan.uan_id;
                networkData.uan_id = uan.uan_id;
                setUsernameValue(uan.username || uan.sub_text);
                if (!isAdwords() || mccClientId) {
                    getAccountsForUan(uanIdRef.current);
                }
                setHasChanges(true);

                let newUanStatus = uan.status;
                if (!errorMsg) {
                    newUanStatus = DATA_SOURCES_STATUSES.ACTIVE;
                }
                if (!supportsAccounts) {
                    setUanStatus(newUanStatus);
                }
                onAuth(uan.uan_id);
            }
            let shouldCloseModal = true;

            if (errorMsg === 'aw_wrong_account') {
                shouldCloseModal = false;
                handleAdwordsError(
                    '/static/dashboard_react/src/dataSources/templates/wrong_account.html',
                    'Logged the wrong user, reauthorizing...',
                    uanAuthClick
                );
            } else if (errorMsg === 'aw_reauthorize') {
                shouldCloseModal = false;
                handleAdwordsError(
                    '/static/dashboard_react/src/dataSources/templates/google_redirect.html',
                    'adwords refresh token is None, reauthorizing...',
                    uanAuthClick
                );
            } else if (errorMsg) {
                onAuthFail(errorMsg, uan ? uan.display_name : networkData.display_name, uan ? uan.uan_id : '');
                setIsValidated(false);
            } else {
                setIsValidated(true);
                if (!networkData.token_needed && !networkData.token2_needed) {
                    setUsernameValue(uan.username || uan.sub_text);
                    if (!supportsAccounts) {
                        setShowSavingSpinner(true);
                        validateLogin(uan.uan_id);
                    }
                }
            }

            return shouldCloseModal;
        }
        return false;
    }

    const shouldDisplayToken = () => !supportsAccounts && validateOAuthOrNotOAuth();

    function isMccIdValid(value = null) {
        const clientId = value !== null ? value : mccClientId;

        if (!clientId) {
            return false;
        }

        return mccClientIdRegexp.test(clientId);
    }

    function isFormInvalid() {
        const shouldDisplay = shouldDisplayToken();
        const isInvalidUsername = !supportsAccounts && networkData.username_needed && !usernameValue;
        const isInvalidPassword = !supportsAccounts && networkData.password_needed && !passwordValue;
        const isInvalidToken = shouldDisplay && networkData.token_needed && !tokenValue && networkData.token_mandatory;
        const isInvalidToken2 =
            shouldDisplay && networkData.token2_needed && !token2Value && networkData.token2_mandatory;
        const isInvalidMccClientId = isAdwords() && !isMccIdValid();
        const isNoAccounts =
            isOAuthNetwork() && supportsAccounts && (!accountsDataToUpdate || !accountsDataToUpdate.length);

        return (
            isInvalidUsername ||
            isInvalidPassword ||
            isInvalidToken ||
            isInvalidToken2 ||
            isInvalidMccClientId ||
            isNoAccounts
        );
    }

    function getMccIdWithoutDashes(mccId) {
        return mccId.replaceAll('-', '');
    }

    function getCurrentAdnetworkId() {
        if (networkData) {
            if (networkData.id) {
                return networkData.id.toString();
            } else if (adnetworks[networkData.name]) {
                return adnetworks[networkData.name].id.toString();
            }
        }

        return null;
    }

    function saveUan(partnerId) {
        // In case the there is no param, react sends the click event and we want to ignore it.
        if (typeof partnerId === 'object') {
            partnerId = null;
        }

        if (partnerId) {
            setRedirectShowSavingSpinner(true);
            setIsSaveRedirectClicked(true);
        } else {
            setShowSavingSpinner(true);
            setIsSaveRedirectClicked(false);
        }

        if ((!isNewUan || isOAuthNetwork()) && supportsAccounts) {
            const uanData = {
                accounts: accountsDataToUpdate,
                auto_add_new_accounts: automaticallyAddNewAccounts,
                detect_instagram: uanAccountsData.detect_instagram,
                description: uanDescription,
            };

            if (noAccountsSelected) {
                setWarningMessage({
                    message: 'STATIC.PAGES.DATA_SOURCES.SHELF.SELECT_ONE_ACCOUNT_INFO',
                    type: 'error',
                });
                disableSpinners();
                if (isAdwords()) {
                    onAdwordsMccSubmit(uanIdRef.current, getMccIdWithoutDashes(mccClientId));
                }
                return;
            }

            if (isAdwords()) {
                onAdwordsSubmit(uanData, uanIdRef.current, getMccIdWithoutDashes(mccClientId), partnerId);
            } else {
                onUanWithAccountsSubmit(uanData, uanIdRef.current, partnerId);
            }
        } else {
            const uanData = {
                adn_id: networkData.id || networkData.adn_id, // when adding new it's id, when editing it's adn_id. fix it when rewriting the backend!
                username: usernameValue,
                uan_id: uanIdRef.current,
                password: passwordValue !== passwordPlaceholder ? passwordValue : '',
                token: tokenValue !== tokenPlaceholder ? tokenValue : '',
                token2: token2Value !== tokenPlaceholder ? token2Value : '',
                extra_modal_field_values: {},
                description: uanDescription,
            };
            onUanSubmit(uanData, partnerId);
        }
        setHasChanges(false);
        setSaveButtonClicked(true);
    }

    function saveUanAndAddPartner() {
        const adnetworkId = getCurrentAdnetworkId();

        trackMixpanelEvent(mixpanelPrefix, 'Clicked Save And Add Attribution', {
            ...networkData,
            adnetworkId,
        });

        saveUan(adnetworkId);
    }

    function onToogleAccountsSelection(checked, accountRow = undefined) {
        const accountsData = accountsDataToUpdate.map(account => {
            if ((!accountRow || account.id === accountRow.id) && !account.multiple_account_in_uans) {
                account.is_selected = checked;
            }
            return account;
        });

        setAccountsDataToUpdate(accountsData);
        setHasChanges(true);
    }

    function onAccountSourceMapChange(newSourceMap, rowIndex) {
        const updatedAccounts = Object.assign([], accountsDataToUpdate);
        updatedAccounts[rowIndex].source = { id: newSourceMap.name, name: newSourceMap.display_name };
        setAccountsDataToUpdate(updatedAccounts);
        setHasChanges(true);
    }

    function updateNewAccountsToggle(e, selected) {
        setAutomaticallyAddNewAccounts(selected);
        setHasChanges(true);
    }

    function getFooterBtns() {
        const isPartnerExistsForUan = partnersIds.has(getCurrentAdnetworkId());
        const isDisabled =
            !hasChanges || isFormInvalid() || showSavingSuccess || showSavingSpinner || showRedirectSavingSpinner;

        const btns = [
            <SingularButton
                type="flat"
                disabled={showSavingSpinner || showRedirectSavingSpinner || showSavingSuccess}
                onClick={onCancel}
            >
                Cancel
            </SingularButton>,
            <SingularButton
                onClick={saveUan}
                type={isPartnerExistsForUan && !isDisabled ? 'secondary' : 'primary'}
                showSpinner={showSavingSpinner && !showSavingSuccess}
                showV={showSavingSuccess && !isSaveRedirectClicked}
                disabledDark={isDisabled}
            >
                Submit
            </SingularButton>,
        ];

        if (isPartnerExistsForUan && orgHasAppsWithSDKEvent) {
            btns.push(
                <SingularButton
                    onClick={saveUanAndAddPartner}
                    showSpinner={showRedirectSavingSpinner && !showSavingSuccess}
                    showV={showSavingSuccess && isSaveRedirectClicked}
                    disabledDark={isDisabled}
                >
                    Submit & Configure Attribution
                </SingularButton>
            );
        }

        return btns;
    }

    const adnId = networkData.id || networkData.adn_id;
    const isFileBased = networkData.channel && FILE_BASED_AUTH_TYPES.includes(networkData.channel);
    const isMailBased = networkData?.default_subtype === AUTH_TYPES.mail;
    const showAuthTypeHeader = Object.keys(AUTH_TYPES_DISPLAY_NAMES_WITH_ARTICLES).includes(networkData.channel);

    const adnName = networkData.adnetworkName || Object.keys(adnetworks).find(name => adnetworks[name].id === adnId);
    const { connector_tour_id: tourId, connector_guide_link: guideLink } = adnetworks[adnName] || {};

    function validateOAuthOrNotOAuth() {
        return (isOAuthNetwork() && isValidated) || !isOAuthNetwork();
    }
    function getCustomSubHeader() {
        if (networkData.default_subtype === AUTH_TYPES.gdrive) {
            return gdriveCustomSubHeader;
        }
        if (networkData.default_subtype === AUTH_TYPES.mail) {
            return emailCustomSubHeader;
        } else {
            return customSubHeader;
        }
    }

    const ifEditNotGdrive = isValidated ? !networkData?.default_subtype === AUTH_TYPES.gdrive : true;

    return (
        <React.Fragment>
            <WarningMessage
                show={!!warningMessage.message && !showSavingSpinner && !showRedirectSavingSpinner}
                message={warningMessage.message}
                type={warningMessage.type}
                duration={3}
                textReplace={warningMessage.textReplace}
            />
            {showLoadingSpinner ? (
                <Spinner show expanded />
            ) : (
                <div className={css.page}>
                    {(tourId || guideLink) &&
                        (!isOAuthNetwork() || (isOAuthNetwork() && isValidated)) &&
                        ifEditNotGdrive && (
                            <div>
                                <HelpMenu
                                    guideLink={guideLink}
                                    tourId={tourId}
                                    customSubHeader={getCustomSubHeader()}
                                    faqLink={faqUrl}
                                    containerClass={css.customSubHeaderText}
                                />
                                <div className={css.subHeaderSeparator} />
                            </div>
                        )}
                    {(!isOAuthNetwork() || (isOAuthNetwork() && isValidated)) && ifEditNotGdrive && isMailBased && (
                        <div>
                            <HelpMenu
                                customSubHeader={getCustomSubHeader()}
                                faqLink={faqUrl}
                                containerClass={css.customSubHeaderText}
                            />
                            <div className={css.subHeaderSeparator} />
                        </div>
                    )}
                    {showAuthTypeHeader && (
                        <div>
                            <Translate
                                id="STATIC.PAGES.DATA_SOURCES.SHELF.INTEGRATION_TYPE_INTRO"
                                data={{ authType: AUTH_TYPES_DISPLAY_NAMES_WITH_ARTICLES[networkData.channel] }}
                            />
                            {isFileBased && networkData.channel !== AUTH_TYPES.s3 && (
                                <span>
                                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.INTEGRATION_TYPE_INTO_READ_ABOUT_IT" />
                                </span>
                            )}
                            {isFileBased && <div id="rectangle" className={css.separator} />}
                        </div>
                    )}
                    {!!networkData.report_validation_error && isFileBased && (
                        <span>
                            <AdNetworkReportError
                                reportValidationError={networkData.report_validation_error}
                                channel={networkData.channel}
                                sendScrapeJob={() => sendScrapeJob(networkData)}
                            />
                            <div id="rectangle" className={css.separator} />
                        </span>
                    )}
                    {hasExplanationLink && (
                        <div className={css.explanationLink}>
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.LEARN_HOW_PULL_DATA" />
                            <a href={networkData.explanation} target="_blank" rel="noreferrer">
                                {networkData.display_name}
                            </a>
                        </div>
                    )}
                    {(((!isOAuthNetwork() || networkData.auth_type === AUTH_TYPES.mailru) &&
                        networkData.username_needed) ||
                        isApplovin()) && (
                        <TextField
                            label={networkData.username_placeholder}
                            value={usernameValue}
                            onChange={value => {
                                setHasChanges(true);
                                setUsernameValue(value);
                            }}
                            {...defaultFieldPropeties}
                        />
                    )}
                    {!isOAuthNetwork() && networkData.password_needed && (
                        <TextField
                            label={networkData.password_placeholder}
                            value={passwordValue}
                            password
                            onChange={value => {
                                setHasChanges(true);
                                setPasswordValue(value);
                            }}
                            {...defaultFieldPropeties}
                        />
                    )}
                    {((networkData.token_needed && networkData.token_text && shouldDisplayToken()) || isApplovin()) && (
                        <TextField
                            label={networkData.token_text}
                            value={tokenValue}
                            password
                            onChange={value => {
                                setHasChanges(true);
                                setTokenValue(value);
                            }}
                            {...defaultFieldPropeties}
                        />
                    )}
                    {networkData.token2_needed && networkData.token2_text && shouldDisplayToken() && (
                        <TextField
                            label={networkData.token2_text}
                            value={token2Value}
                            password
                            onChange={value => {
                                setHasChanges(true);
                                setToken2Value(value);
                            }}
                            {...defaultFieldPropeties}
                        />
                    )}
                    {isApplovin() && (
                        <div className={css.applovinOauthExplanation}>
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.APPLOVIN_OAUTH_EXPLANATION" />
                        </div>
                    )}
                    {isOAuthNetwork() && (
                        <OAuthLoginField
                            networkData={networkData}
                            isValidated={isValidated}
                            authPopupHandler={handleAuthPopupResponse}
                            usernameValue={usernameValue}
                            onError={() =>
                                setWarningMessage({
                                    message: 'STATIC.PAGES.DATA_SOURCES.SHELF.TOP_MESSAGES.GENERAL_ERROR',
                                    type: 'error',
                                })
                            }
                        />
                    )}
                    {isAdwords() && isValidated && (
                        <TextField
                            label={networkData.token_text}
                            type="text"
                            maxLength="12"
                            value={mccClientId}
                            error={mccClientId && !isMccIdValid() ? 'MCC Customer ID must be 10 digits' : ''}
                            onKeyDown={preventNonNumericKeys}
                            placeholder="Enter MCC ID - e.g. 861-851-4423"
                            onChange={value => {
                                setAccountsDataToUpdate(null);
                                setWarningMessage({});
                                setHasChanges(true);
                                setMccClientId(value);

                                if (textTimeoutId !== null) {
                                    clearTimeout(textTimeoutId);
                                    textTimeoutId = null;
                                }

                                if (isMccIdValid(value)) {
                                    textTimeoutId = setTimeout(() => {
                                        if (isMccIdValid(value)) {
                                            onAdwordsMccSubmit(uanIdRef.current, getMccIdWithoutDashes(value));
                                            getAccountsForUan(uanIdRef.current, true);
                                        }
                                    }, 500);
                                }
                            }}
                            {...defaultFieldPropeties}
                        />
                    )}
                    {networkData.explanation && !hasExplanationLink && validateOAuthOrNotOAuth() && (
                        <div>
                            <div className={css.textLink}>
                                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.HOW_DO_I_GET_THIS" />
                            </div>
                            <div
                                className={css.explanationText}
                                dangerouslySetInnerHTML={{ __html: parseSafeHtml(networkData.explanation) }}
                            />
                        </div>
                    )}
                    {((supportsAccounts && !isAdwords() && !isApplovin()) ||
                        (isAdwords() && (!mccClientId || isMccIdValid()))) &&
                        uanIdRef.current && (
                            <div>
                                <DataConnectorAccounts
                                    isEmptyMccId={isAdwords() && !mccClientId}
                                    adnId={adnId}
                                    accountsData={accountsDataToUpdate}
                                    onToggleSelectAll={onToogleAccountsSelection}
                                    onToggleSingleRow={onToogleAccountsSelection}
                                    onAccountSourceMapChange={onAccountSourceMapChange}
                                    newAccountsToggle={automaticallyAddNewAccounts}
                                    onAutoAddAccounts={updateNewAccountsToggle}
                                    showViewAs={NETWORKS_WITH_ACCOUNT_MAPPING.includes(networkData.auth_type)}
                                />
                            </div>
                        )}
                    {validateOAuthOrNotOAuth() && (
                        <TextField
                            label="Description (optional)"
                            type="text"
                            maxLength="100"
                            value={uanDescription}
                            placeholder="Type up to 100 characters"
                            containerStyle={supportsAccounts ? { marginTop: '20px' } : { marginTop: '40px' }}
                            onChange={value => {
                                setHasChanges(true);
                                setUanDescription(value);
                            }}
                            {...defaultFieldPropeties}
                        />
                    )}
                </div>
            )}
            <WizardFooter
                leftComponents={[
                    <span key="checking_credentials">
                        {(showSavingSpinner || showRedirectSavingSpinner) && !showSavingSuccess && (
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.CHECKING_CREDENTIALS" />
                        )}
                    </span>,
                ]}
                buttons={getFooterBtns()}
            />
        </React.Fragment>
    );
}

AdnetworkBasicLogin.propTypes = {
    orgHasAppsWithSDKEvent: PropTypes.bool,
    networkData: PropTypes.objectOf(PropTypes.any),
    partnersIds: PropTypes.objectOf(PropTypes.any),
    showSavingSuccess: PropTypes.bool,
    uanSaveErrorMessage: PropTypes.string,
    updatedUanId: PropTypes.number,
    authPopupUrl: PropTypes.string,
    partnerSourceId: PropTypes.string,
    adnetworks: PropTypes.objectOf(PropTypes.any),
    uanAccountsData: PropTypes.objectOf(PropTypes.any),
    onUanSubmit: PropTypes.func,
    onUanWithAccountsSubmit: PropTypes.func,
    onAdwordsMccSubmit: PropTypes.func,
    onAdwordsSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onUanAuthClick: PropTypes.func,
    validateLogin: PropTypes.func,
    setAuthPopupUrl: PropTypes.func,
    onAuthFail: PropTypes.func,
    getAccountsForUan: PropTypes.func,
    sendScrapeJob: PropTypes.func,
    sendScrapeJobStatus: PropTypes.objectOf(PropTypes.any),
    onAuth: PropTypes.func,
    onChange: PropTypes.func,
};

AdnetworkBasicLogin.defaultProps = {
    orgHasAppsWithSDKEvent: false,
    networkData: {},
    partnersIds: new Set(),
    showSavingSuccess: false,
    uanSaveErrorMessage: '',
    updatedUanId: null,
    authPopupUrl: '',
    uanAccountsData: {},
    partnerSourceId: null,
    adnetworks: {},
    onUanSubmit: () => {},
    onUanWithAccountsSubmit: () => {},
    onAdwordsMccSubmit: () => {},
    onAdwordsSubmit: () => {},
    onCancel: () => {},
    onUanAuthClick: () => {},
    validateLogin: () => {},
    setAuthPopupUrl: () => {},
    onAuthFail: () => {},
    getAccountsForUan: () => {},
    sendScrapeJob: () => {},
    sendScrapeJobStatus: { subStatus: MANUAL_SCRAPE_SUBSTATUS.UNDEFINED },
    onAuth: () => {},
    onChange: () => {},
};

export default AdnetworkBasicLogin;
