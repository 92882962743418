import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const tooltipFormatType = PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired,
    precision: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
});
const BubbleChart = ({ title, subTitle, xAxisOptions, yAxisOptions, tooltipFormat, points, height }) => {
    const getTooltipPointFormat = () => {
        const { x, y, z } = tooltipFormat;
        return (
            '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
            `<tr><th>${x.displayName}:</th><td>${x.prefix}{point.x:.${x.precision}f}${x.suffix}</td></tr>` +
            `<tr><th>${y.displayName}:</th><td>${y.prefix}{point.y:.${y.precision}f}${y.suffix}</td></tr>` +
            `<tr><th>${z.displayName}:</th><td>${z.prefix}{point.z:.${z.precision}f}${z.suffix}</td></tr>`
        );
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'bubble',
                    plotBorderWidth: 1,
                    zoomType: 'xy',
                    height,
                },
                title: {
                    text: title,
                },
                subtitle: {
                    text: subTitle,
                },
                xAxis: {
                    gridLineWidth: 1,
                    title: {
                        text: xAxisOptions.title,
                    },
                    labels: {
                        format: xAxisOptions.labelsFormat,
                    },
                },
                yAxis: {
                    title: {
                        text: yAxisOptions.title,
                    },
                    labels: {
                        format: yAxisOptions.labelsFormat,
                    },
                    maxPadding: 0.2,
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '<table>',
                    pointFormat: getTooltipPointFormat(),
                    footerFormat: '</table>',
                    followPointer: true,
                },
                series: [
                    {
                        name: '',
                        data: points,
                        colorByPoint: true,
                    },
                ],
                credits: {
                    enabled: false,
                },
            }}
        />
    );
};

BubbleChart.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    xAxisOptions: PropTypes.shape({
        title: PropTypes.string.isRequired,
        labelsFormat: PropTypes.string.isRequired,
    }).isRequired,
    yAxisOptions: PropTypes.shape({
        title: PropTypes.string.isRequired,
        labelsFormat: PropTypes.string.isRequired,
    }).isRequired,
    points: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            z: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
    tooltipFormat: PropTypes.shape({
        x: tooltipFormatType.isRequired,
        y: tooltipFormatType.isRequired,
        z: tooltipFormatType.isRequired,
    }).isRequired,
    height: PropTypes.number,
};

BubbleChart.defaultProps = {
    title: '',
    subTitle: '',
    height: null,
};

export default BubbleChart;
