import xss from 'xss';

let instance;

const xssWhiteList = Object.keys(xss.whiteList).reduce((retWhiteList, key) => {
    const existWhiteList = xss.whiteList[key];

    retWhiteList[key] = existWhiteList.includes('style') ? [...existWhiteList] : [...existWhiteList, 'style'];

    return retWhiteList;
}, {});

const xssOptions = {
    css: {
        whiteList: {
            display: true,
            margin: true,
            color: true,
            position: true,
            top: true,
            'pointer-events': true,
            'margin-left': true,
            'margin-right': true,
            'margin-top': true,
            'font-size': true,
            float: true,
        },
    },
    whiteList: xssWhiteList,
};

class SingularXSS {
    constructor() {
        if (!instance) {
            instance = new xss.FilterXSS(xssOptions);
        }

        return instance;
    }
}

export const parseSafeHtml = innerHtml => {
    return new SingularXSS().process(innerHtml);
};
