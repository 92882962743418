import { useEffect } from 'react';
import { sendMixpanelEventSdkIntegration } from '../sdkPlanning/utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../consts';
import { TARGET_PLATFORM_TO_PARAM } from '../sdkPlanning/consts';

const useMixpanelEventOnPageLoad = (step, platform, extraParams) => {
    useEffect(() => {
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.PAGE_LOAD,
            step,
            TARGET_PLATFORM_TO_PARAM[platform],
            extraParams
        );
    }, []);
};

export default useMixpanelEventOnPageLoad;
