import { connect } from 'react-redux';
import { setActionType, showDrilldownPopover, showTransparencyPopover } from '../../actions/reports';
import GridGeneralCellRenderer from '../../components/widgets/GridGeneralCellRenderer';

const mapStateToProps = (state, ownProps) => {
    const { reportType } = ownProps;

    return {
        ...ownProps,
        showTransparencyPopover: state[reportType]?.showTransparencyPopover,
        dimensionDrilldownCellId: state[reportType]?.dimensionDrilldownCellId,
        supportedAppNames: state[reportType]?.reportSupportedApps,
        unsupportedAppTooltip: state[reportType]?.reportUnsupportedAppTooltip,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { reportType } = ownProps;

    return {
        onPopoverToggle: show => {
            dispatch(setActionType(showTransparencyPopover(show), reportType));
        },
        onDrilldownPopoverToggle: cellId => {
            dispatch(setActionType(showDrilldownPopover(cellId), reportType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GridGeneralCellRenderer);
