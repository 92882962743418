export const CUSTOMER_TIERS = {
    STANDARD_UNIFIED: 'STANDARD_UNIFIED',
    ENTERPRISE: 'ENTERPRISE',
    STANDARD_ANALYTICS: 'STANDARD_ANALYTICS',
    FREE: 'FREE',
};

export const CUSTOMER_SF_TIERS = {
    NO_ARR: 'No ARR, No Tier',
    STARTER: 'Starter',
    NOT_MANAGED: 'Not Managed',
    COMMERCIAL: 'Commercial',
    GROWTH: 'Growth',
    FREE: 'Free',
};

export const EVENT_SLOT_ANALYSIS_TYPES = ['conversion', 'cohort'];
export const ATTRIBUTION_VIEWS = { SKAN: 'skan' };
export const EVENT_SLOT_ATTRIBUTION_VIEWS = [ATTRIBUTION_VIEWS.SKAN];

export const EVENT_SLOT_GROUP_TYPE = {
    CONVERSION: 'conversion',
    COHORT: 'cohort',
    BASIC: 'basic',
    SKAN: 'skan',
};
export const PARSING_DATA_TYPES = {
    BOOL: 'bool',
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date',
    OBJECT: 'object',
};

export const PYTHON_PARSING_DATA_TYPES = {
    STRING: 'str',
};

export const EDIT_DEVICE_INITIAL_STATE = {};
export const SELECTED_DEVICES_INITIAL_STATE = [];

export const driftUrl = 'https://singular.drift.click/97a1cf4f-f40c-4fc7-b9ba-f9693945cb21';

export const SLACK_AUTH_URL =
    'https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,incoming-webhook,channels:read&client_id=2386050246.1693083561232';

export const ADNETWORK_IDS = {
    GOOGLE_ANALYTICS: 747,
    FACEBOOK: 345,
    SINGULAR: 614,
    APPSFLYER: 354,
};

export const MESSAGE_ONCLICK_SUFFIX = '::';
export const MESSAGE_ONCLICK_INNER_PREFIX = '<<';
export const MESSAGE_ONCLICK_INNER_SUFFIX = '>>';
export const MESSAGE_ONCLICK_ACTION = {
    OPEN_SHELF: 'OPEN_SHELF',
};

export const TOUR_LINK_IDS = {
    apps: 180936,
    reports: 215086,
    pivot: 215086,
    adMonetization: 176951,
    customEvents: 215105,
    creativeReports: 82580,
    dataConnectors: 176238,
    alerts: 249709,
    sdkConsole: 82466,
    partnerConfiguration: 215095,
    exportLogs: 266130,
    biPostbacks: 266161,
    conversionModels: 180731,
    skanReport: 266960,
    manageLinks: 122444,
    dashboards: 274269,
    customDimensions: 134460,
    dataDestinations: 266164,
};

const getEmptyStateImage = image => `/static/dashboard_react/src/resources/svg/data_connectors_empty_state/${image}`;
export const adNetworkMap = {
    345: {
        title: 'Facebook',
        image: [getEmptyStateImage('facebook-small.svg'), getEmptyStateImage('instagram-small.svg')],
        name: 'facebook',
    },
    124: {
        title: 'AdWords',
        image: [getEmptyStateImage('google-small.svg'), getEmptyStateImage('youtube-small.svg')],
        name: 'adwords',
    },
    77760: {
        title: 'TikTok',
        image: [getEmptyStateImage('tiktok.svg')],
        name: 'tiktok',
    },
    377: {
        title: 'Twitter',
        image: [getEmptyStateImage('twitter.svg')],
        name: 'twitter',
        style: { height: '37px' },
    },
    774: {
        title: 'Pinterest',
        image: [getEmptyStateImage('pinterest.svg')],
        name: 'pinterest',
    },
    1377: {
        title: 'Snapchat',
        image: [getEmptyStateImage('snapchat.svg')],
        name: 'snapchat',
    },
    84257: {
        title: 'Amazon Redshift',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/aws-redshift-logo.svg'],
        redshift_destination: 'redshift_destination',
        disabledImage: [
            '/static/dashboard_react/src/resources/svg/data_destinations_empty_state_disabled/aws-redshift-logo.svg',
        ],
    },
    85181: {
        title: 'Google BigQuery',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/big-query.svg'],
        name: 'bigquery_destination',
        disabledImage: [
            '/static/dashboard_react/src/resources/svg/data_destinations_empty_state_disabled/big-query.svg',
        ],
    },
    85180: {
        title: 'Snowflake',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/snowflake.svg'],
        name: 'snowflake_destination',
        disabledImage: [
            '/static/dashboard_react/src/resources/svg/data_destinations_empty_state_disabled/snowflake.svg',
        ],
    },
    85310: {
        title: 'MySQL',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/my-sql.svg'],
        name: 'mysql_destination',
    },
    85311: {
        title: 'PostgreSQL',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/postgre-sql.svg'],
        name: 'postgres_destination',
        disabledImage: [
            '/static/dashboard_react/src/resources/svg/data_destinations_empty_state_disabled/postgre-sql.svg',
        ],
    },
    84776: {
        title: 'Amazon S3',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/aws-s-3.svg'],
        name: 's3_destination',
        disabledImage: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state_disabled/aws-s-3.svg'],
    },
    88251: {
        title: 'Google Data Studio',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/data-studio-logo.svg'],
        name: 'google_data_studio_destination',
    },
    86536: {
        title: 'GCS',
        image: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state/gcs.svg'],
        name: 'gcs_destination',
        disabledImage: ['/static/dashboard_react/src/resources/svg/data_destinations_empty_state_disabled/gcs.svg'],
    },
};

export const MIXPANEL_EVENT_PAGE_LOADED = 'Page Loaded';

export const EVENT_TYPES = {
    STANDARD: 'standard',
    CUSTOM: 'custom',
    BUILT_IN: 'builtIn',
};

export const METRIC_FIELD_NAMES = {
    CLICKS: 'clicks',
    COST: 'adn_cost',
    CUSTOM_INSTALLS: 'custom_installs',
    REVENUE: 'revenue',
    ROI: 'roi',
    ADN_ORIGINAL_COST: 'adn_original_cost',
    CUSTOM_IMPRESSIONS: 'custom_impressions',
};

export const UNIFIED_METRIC_GROUP_FIELD_NAMES = {
    INSTALLS: 'installs',
    CVR: 'cvr',
    OCVR: 'ocvr',
    ECPI: 'ecpi',
    REVENUE: 'revenue',
    ROI: 'roi',
};

export const METRIC_CARDS_TIME_PERIODS = {
    LAST_WEEK: 'LAST_WEEK',
    LAST_MONTH: 'LAST_MONTH',
    LAST_PERIOD: 'LAST_PERIOD',
};

export const NUMBER_SIGNS = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    ZERO: 'zero',
};

export const PAGE_HEADER_WITH_PADDING_STYLE = {
    paddingTop: 'var(--pageWrapperTopPadding)',
    paddingLeft: 'var(--pageWrapperLeftPadding)',
    paddingRight: 'var(--pageWrapperLeftPadding)',
    backgroundColor: 'var(--white)',
};

export const PAGE_WRAPPER_NO_PADDING_STYLE = { padding: 0 };

export const DEFAULT_INTERNAL_BI_PARTNER = 'internal_bi';

export const BUDGET_PROGRESS_FORECAST = 'budget_progress_forecast';

export const COHORT_PERIODS_THRESHOLD = 10;

export const loremIpsum = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n
Sed do eiusmod tempor incididunt ut labore et dolore magna\n
aliqua. Ut enim ad minim veniam, quis nostrud exercitation\n
ullamco laboris nisi ut aliquip ex ea commodo consequat.\n
Duis aute irure dolor in reprehenderit in voluptate velit\n
esse cillum dolore eu fugiat nulla pariatur. Excepteur sint\n
occaecat cupidatat non proident, sunt in culpa qui officia\n
deserunt mollit anim id est laborum.\n`;

export const midLoremIpsum = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n
Sed do eiusmod tempor incididunt ut labore et dolore magna\n
aliqua. Ut enim ad minim veniam, quis nostrud exercitation\n
ullamco laboris nisi ut aliquip ex ea commodo consequat.`;

export const shortLoremIpsum = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n
Sed do eiusmod tempor incididunt ut labore et dolore magna\n`;

export const veryShortLoremIpsum = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n`;

export const ACCOUNT_TYPES = {
    PARTNER: 5,
};

export const PLAN_CARD_TIERS = [CUSTOMER_TIERS.FREE, CUSTOMER_TIERS.STANDARD_UNIFIED, CUSTOMER_TIERS.ENTERPRISE];
