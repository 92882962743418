import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import HelpIcon from '../../../resources/svg/tooltip.svg';
import FAQIcon from '../../../resources/svg/icons/question_mark.svg';
import GuideIcon from '../../../resources/svg/icons/guide.svg';
import TourIcon from '../../../resources/svg/icons/map.svg';
import VideoIcon from '../../../resources/svg/icons/video.svg';
import Chevron from '../../../resources/icons/chevron.svg';
import css from './HelpMenu.css';

function HelpMenu({ faqLink, tourId, guideLink, customSubHeader, onVideoClick, containerClass, onClick }) {
    const anchorEl = useRef(null);
    const [isHelpPopoverOpen, setIsHelpPopoverOpen] = useState(false);
    const shouldRenderHelpPopover = faqLink.length > 0 && (guideLink.length > 0 || tourId > 0 || onVideoClick);

    const onFaqClick = () => {
        onClick('FAQ');
        window.open(faqLink, '_blank', 'noreferrer');
    };

    const onGuideClick = () => {
        onClick('Guide');
        window.open(guideLink, '_blank', 'noreferrer');
    };

    const onTourClicked = () => {
        onClick('Tour');
        window.Intercom('startTour', tourId);
    };

    const onVideoClicked = () => {
        onClick('Video');
        onVideoClick();
    };

    const renderPopover = () => {
        return (
            <ClickAwayListener
                onClickAway={() => {
                    setIsHelpPopoverOpen(false);
                }}
            >
                <Popper
                    open={isHelpPopoverOpen}
                    anchorEl={anchorEl.current}
                    placement="bottom-end"
                    className={css.helpPopover}
                >
                    <div className={css.helpPopoverContentWrapper}>
                        {faqLink && (
                            <div className={css.helpPopoverRow} onClick={onFaqClick}>
                                <span className={css.helpPopoverLink}>
                                    <Translate id="STATIC.PAGE_SUB_HEADER.FAQ" />
                                </span>
                                <FAQIcon />
                            </div>
                        )}
                        {guideLink && (
                            <div className={css.helpPopoverRow} onClick={onGuideClick}>
                                <span className={css.helpPopoverLink}>
                                    <Translate id="STATIC.PAGE_SUB_HEADER.GUIDE" />
                                </span>
                                <GuideIcon />
                            </div>
                        )}
                        {tourId && (
                            <div className={css.helpPopoverRow} onClick={onTourClicked}>
                                <span className={css.helpPopoverLink}>
                                    <Translate id="STATIC.PAGE_SUB_HEADER.TAKE_A_TOUR" />
                                </span>
                                <TourIcon />
                            </div>
                        )}
                        {onVideoClick && (
                            <div className={css.helpPopoverRow} onClick={onVideoClicked}>
                                <span className={css.helpPopoverLink}>
                                    <Translate id="STATIC.PAGE_SUB_HEADER.WATCH_A_VIDEO" />
                                </span>
                                <VideoIcon />
                            </div>
                        )}
                    </div>
                </Popper>
        </ClickAwayListener>);
    };

    return (
        <div className={classNames(css.subHeaderText, containerClass)}>
            {customSubHeader ? (
                <span>{customSubHeader}</span>
            ) : (
                <div
                    className={classNames(css.helpTextContainer, { [css.clickable]: shouldRenderHelpPopover })}
                    onClick={() => {
                        if (shouldRenderHelpPopover) {
                            setIsHelpPopoverOpen(!isHelpPopoverOpen);
                        }
                    }}
                >
                    <div className={css.helpIconContainer}>
                        <HelpIcon className={css.helpIcon} />
                    </div>
                    <div className={css.subHeaderHelpText}>
                        <Translate id="STATIC.PAGE_SUB_HEADER.HELP_TEXT" />
                    </div>
                </div>
            )}
            {shouldRenderHelpPopover && (
                <div
                    className={css.toggleMenuIconContainer}
                    onClick={() => {
                        setIsHelpPopoverOpen(!isHelpPopoverOpen);
                    }}
                >
                    <span ref={anchorEl}>
                        {isHelpPopoverOpen && <Chevron className={classNames(css.toggleMenuIcon, css.top)} />}
                        {!isHelpPopoverOpen && <Chevron className={css.toggleMenuIcon} />}
                    </span>
                </div>
            )}
            {!shouldRenderHelpPopover && (
                <a href={faqLink} target="_blank" rel="noreferrer" className={css.faqLinkText}>
                    <Translate id="STATIC.PAGE_SUB_HEADER.FAQ" />
                </a>
            )}
            {isHelpPopoverOpen && renderPopover()}
        </div>
    );
}

HelpMenu.propTypes = {
    faqLink: PropTypes.string,
    guideLink: PropTypes.string,
    tourId: PropTypes.number,
    customSubHeader: PropTypes.string,
    containerClass: PropTypes.string,
    onVideoClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onClick: PropTypes.func,
};

HelpMenu.defaultProps = {
    faqLink: '',
    guideLink: '',
    tourId: null,
    customSubHeader: '',
    containerClass: '',
    onVideoClick: null,
    onClick: () => {},
};

export default HelpMenu;
