import API from './api';

let instance = null;
const api = new API();
const URL_FIELDS_CREATIVE_TAG_GROUP = '/api/creative_tag_group';
const URL_BOOKMARKS = '/api/bookmarks';
const URL_ALL_FIELDS_LIST = 'api/users/all_fields';
const URL_FILTER_FIELDS_LIST = 'api/users/filter_fields';
const URL_SOURCES_FIELDS_AVAILABILITY = 'api/users/sources_fields_availability';
const URL_FIELDS_GOALS_LIST = '/goals/list';

class Fields {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    updateAllFields(forceRefresh = false) {
        return api.getJson(URL_ALL_FIELDS_LIST, {
            method: 'GET',
            credentials: 'include',
            cache: true,
            disableBrowserCache: true,
            forceRefresh,
        });
    }

    updateFilterFields() {
        return api.getJson(URL_FILTER_FIELDS_LIST, {
            method: 'GET',
            credentials: 'include',
            cache: true,
            disableBrowserCache: true,
        });
    }

    updateSourcesFieldsAvailability() {
        return api.getJson(URL_SOURCES_FIELDS_AVAILABILITY, {
            method: 'GET',
            credentials: 'include',
            cache: true,
            disableBrowserCache: true,
        });
    }

    updateGoalsList() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_FIELDS_GOALS_LIST, {
                method: 'GET',
                credentials: 'include',
                params: {
                    override_error_handling: true,
                },
                cache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateTagGroups() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_FIELDS_CREATIVE_TAG_GROUP, {
                method: 'GET',
                credentials: 'include',
                cache: false,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateBookmarks() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_BOOKMARKS, {
                method: 'GET',
                credentials: 'include',
                params: {},
                cache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default Fields;
