import React from 'react';
import DocumentationPanel from './components/DocumentationPanel';
import { SDKCodeSnippetProvider } from './codeSnippets/context/useSDKCodeSnippetContext';

const SDKPlanning = () => {
    return (
        <SDKCodeSnippetProvider>
            <DocumentationPanel />
        </SDKCodeSnippetProvider>
    );
};

export default SDKPlanning;
