import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';
import css from './QuestionMark.css';
import TooltipIcon from '../../resources/svg/tooltip.svg';
import withTranslate from '../../containers/widgets/withTranslate';

class QuestionMark extends React.PureComponent {
    constructor(props) {
        super(props);
        this.getMessage = this._getMessage.bind(this);
    }

    _getMessage() {
        const { message, translate } = this.props;
        if (typeof message === 'string') {
            return translate(message);
        }
        return message;
    }

    render() {
        const { contentStyle, containerClassName, iconClassName, interactive, tooltipClassName } = this.props;
        return (
            <Tooltip
                html={<div style={contentStyle}>{this.getMessage()}</div>}
                position="top"
                inertia
                className={tooltipClassName}
                interactive={interactive}
                theme="light"
                size="big"
                popperOptions={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false,
                        },
                        hide: {
                            enabled: false,
                        },
                    },
                }}
            >
                <span className={classNames(css.questionMarkContainer, containerClassName)}>
                    <TooltipIcon className={classNames(iconClassName, css.icon)} />
                </span>
            </Tooltip>
        );
    }
}

QuestionMark.propTypes = {
    message: PropTypes.string,
    contentStyle: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
    // https://github.com/tvkhoa/react-tippy:
    // Makes a tooltip interactive, i.e. will not close when the user hovers over or clicks on the tooltip.
    // This lets you create a popover (similar to Bootstrap) when used in conjunction with a click trigger.
    interactive: PropTypes.bool,
    iconClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
};

QuestionMark.defaultProps = {
    message: '',
    contentStyle: {},
    translate: () => {},
    interactive: false,
    iconClassName: '',
    containerClassName: '',
    tooltipClassName: '',
};

export default memo(withTranslate(QuestionMark));
