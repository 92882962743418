import { call, put, take, fork, select } from 'redux-saga/effects';
import * as actions from './actions';
import WebTrackingLinksAPI from './service';
import { getLanguage } from './selectors';
import toaster from '../utils/toaster';

const api = new WebTrackingLinksAPI();

export function* fetchMeta() {
    try {
        const response = yield call(api.trackingLinksDiscover);
        const data = {
            partners: response.partners,
            targets: response.targets,
            has_pc_console_apps: response.has_pc_console_apps,
        };
        yield put(actions.fetchMetaDone(data));
    } catch (error) {
        const errorAction = actions.error();
        yield put(errorAction);
        // report the error to sentry?
    }
}

export function* generateLink(targetId, adNetworkId, url, attributes, landingPage, reEnabled) {
    try {
        yield put(actions.generateLinkStarted());
        const response = yield call(
            api.trackingLinksGenerateLink,
            targetId,
            adNetworkId,
            url,
            attributes,
            landingPage,
            reEnabled
        );
        yield put(actions.generateLinkDone(response.tracking_link));
    } catch (error) {
        yield put(actions.generateLinkDone(null));
        const errorAction = actions.error();
        yield put(errorAction);
        // report the error to sentry?
    }
}

export function* partnerDiscover(adNetworkId) {
    try {
        const defaultAttributes = {};
        yield put(actions.partnerDiscoverStarted(defaultAttributes));
        const language = yield select(getLanguage);
        const response = yield call(api.trackingLinksPartnerDiscover, adNetworkId, language);
        const attributesMeta = response.attributes ? response.attributes : [];
        const attributes = {};
        attributesMeta.forEach(attribute => {
            attributes[attribute.name] = attribute.default;
        });
        const { instructions } = response;
        yield put(actions.partnerDiscoverDone(attributesMeta, attributes, instructions));
    } catch (error) {
        const errorAction = actions.error();
        yield put(errorAction);
        // report the error to sentry?
    }
}

function* watchPartnerDiscover() {
    while (true) {
        const action = yield take(actions.PARTNER_DISCOVER);
        yield fork(partnerDiscover, action.partnerId);
    }
}

function* watchFetchMeta() {
    while (true) {
        yield take(actions.FETCH_META);
        yield fork(fetchMeta);
    }
}

function* watchError() {
    while (true) {
        yield take(actions.ERROR);
        toaster.error('An unexpected error has occurred, please try again later');
    }
}

function* watchGenerateLink() {
    while (true) {
        const action = yield take(actions.GENERATE_LINK);
        yield fork(
            generateLink,
            action.targetId,
            action.partnerId,
            action.url,
            action.attributes,
            action.landingPage,
            action.reEnabled
        );
    }
}

function* onload() {
    yield put(actions.fetchMeta());
}

const watchers = [watchFetchMeta, watchPartnerDiscover, watchGenerateLink, watchError];

export { onload, watchers };
