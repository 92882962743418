import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './Table.css';
import RightArrow from '../../resources/svg/right_arrow.svg';
import Infinity from '../../resources/svg/infinity.svg';
import Tooltip from './Tooltip';
import ValueLabel from './ValueLabel';

function TableCellNumericRange({
    from,
    to,
    isHidden,
    isEditable,
    disableAutoScroll,
    getEmptyValueLabel,
    isCellEmpty,
    valueLabel,
    translationArgs,
    onValueChanged,
    index,
    maxValue,
    minValue,
    decimalPlaces,
    error,
    classNames: propsClassNames,
}) {
    const isEmpty = isCellEmpty(index, from, to);
    const emptyValueLabel = getEmptyValueLabel(from);
    const label = isEmpty ? emptyValueLabel : valueLabel;

    return (
        <td key={`${from}-${to}-${index}`} className={classNames(css.numericRangeCell, ...propsClassNames)}>
            {isHidden ? (
                <div className={css.noRange}>-</div>
            ) : (
                <>
                    <ValueLabel
                        containerClass={classNames({ [css.numericRangeValueLabel]: true, [css.emptyRange]: isEmpty })}
                        value={from}
                        label={label}
                        translationArgs={translationArgs}
                        append={isEmpty ? null : '+'}
                    />

                    {isEmpty ? null : (
                        <>
                            <RightArrow />
                            {to && !Number.isFinite(to) ? (
                                <Tooltip
                                    wrapperClass={classNames(css.numericRangeValueLabel, css.infinityIconWrapper)}
                                    titleTranslationKey="STATIC.NUMERIC_RANGE_INFINITY_TOOLTIP"
                                >
                                    <Infinity />
                                </Tooltip>
                            ) : (
                                <ValueLabel
                                    value={to}
                                    label={label}
                                    translationArgs={translationArgs}
                                    isEditable={isEditable}
                                    disableAutoScroll={disableAutoScroll}
                                    onValueChanged={onValueChanged}
                                    index={index}
                                    maxValue={maxValue}
                                    minValue={minValue}
                                    decimalPlaces={decimalPlaces}
                                    containerClass={css.numericRangeValueLabel}
                                    inputClass={classNames([
                                        { [css.editableNumericRangeInput]: isEditable, [css.error]: !!error },
                                    ])}
                                    labelClass={css.labelFormatting}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </td>
    );
}

TableCellNumericRange.propTypes = {
    from: PropTypes.number,
    to: PropTypes.number,
    isHidden: PropTypes.bool,
    isEditable: PropTypes.bool,
    disableAutoScroll: PropTypes.bool,
    getEmptyValueLabel: PropTypes.func,
    isCellEmpty: PropTypes.func,
    valueLabel: PropTypes.string,
    translationArgs: PropTypes.objectOf(PropTypes.string),
    onValueChanged: PropTypes.func,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    decimalPlaces: PropTypes.number,
    classNames: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
};

TableCellNumericRange.defaultProps = {
    from: 0,
    to: 0,
    isHidden: false,
    isEditable: false,
    disableAutoScroll: false,
    getEmptyValueLabel: () => '',
    isCellEmpty: () => false,
    valueLabel: '',
    translationArgs: {},
    onValueChanged: () => {},
    index: -1,
    maxValue: 999999,
    minValue: 0,
    decimalPlaces: 2,
    classNames: [],
    error: undefined,
};

export default TableCellNumericRange;
