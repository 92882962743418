import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import WizardFooter from 'components/widgets/WizardFooter';
import Wizardable from 'components/widgets/Wizardable';
import { FauxButton, Checkbox } from 'components/widgets';
import ExpandableGroup from 'teamManagement/components/ExpandableGroup';
import LabeledCheckboxList from 'components/widgets/LabeledCheckboxList';
import css from 'teamManagement/components/MetricsSelection.css';
import AddFutureCheckbox from 'teamManagement/containers/AddFutureCheckbox';

class MetricsSelection extends Wizardable {
    constructor(props) {
        super(props);

        this.buttonOverrides = props.wizardOverrideButtons;
        this.onMetricSelected = this._onMetricSelected.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.groupCheckboxStateChanged = this.groupCheckboxStateChanged.bind(this);
        this.allFields = this._getAllFields(props.fields);
    }

    _getAllFields(fields) {
        return fields.reduce((total, fieldType) => {
            total.push(...fieldType.values);
            return total;
        }, []);
    }

    _onMetricSelected(id, value) {
        const { onChange } = this.props;
        onChange([id], value);
    }

    selectAll() {
        const { onChange } = this.props;
        const flat = this.allFields;
        onChange(flat.map((field) => field.name), !(this.selectedFields.length === this.allFields.length));
    }

    groupCheckboxStateChanged(group, newState) {
        this.toggleAllInGroup(group, !!newState);
    }

    toggleAllInGroup(group, selected) {
        const { onChange } = this.props;
        onChange(group.values.map((item) => item.name), selected);
    }

    render() {
        const { fields, lastAction } = this.props;

        this.selectedFields = this._getAllFields(fields).filter((field) => field.selected);

        return (
            <React.Fragment>
                <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                    <div>
                        <div className={css.headline}>
                            <div className={css.preamble}>
                                <div>Select metrics to be available for this user:</div>
                                <div className={css.faqLine}>
                                    For more information{' '}
                                    <a
                                        href="https://singular.zendesk.com/hc/en-us/articles/360020835952-Team-Management-FAQ"
                                        target="_blank"
                                    >
                                        read the FAQ
                                    </a>
                                    .
                                </div>
                            </div>
                            <div className={css.selectorContainer}>
                                <FauxButton onClick={this.selectAll} containerStyle={{ padding: 0 }}>
                                    {this.selectedFields.length !== this.allFields.length ? 'Select' : 'Deselect'} All
                                </FauxButton>
                            </div>
                        </div>
                    </div>
                    <div className={css.contentArea}>
                        {fields.map((field) => {
                            const selectedFields = field.values.filter((child) => child.selected);
                            const fieldChecked = selectedFields.length === field.values.length;
                            const fieldIndeterminate =
                                selectedFields.length > 0 && selectedFields.length < field.values.length;

                            return (
                                <div className={css.expandableGroupHolder} key={field.label}>
                                    <ExpandableGroup
                                        title={<Translate id={field.label} />}
                                        rightComponents={
                                            <Checkbox
                                                label={`${selectedFields.length} of ${field.values.length} selected`}
                                                className={css.sectionCheckbox}
                                                labelPosition="left"
                                                checked={fieldChecked}
                                                threeStatesEnabled
                                                indeterminate={fieldIndeterminate}
                                                onChange={(newState) => {
                                                    this.groupCheckboxStateChanged(field, newState);
                                                }}
                                            />
                                        }
                                    >
                                        <div>
                                            {
                                                <LabeledCheckboxList
                                                    fields={field.values}
                                                    columnsNumber={field.type === 'customEvents' ? 1 : 4}
                                                    onChange={this.onMetricSelected}
                                                />
                                            }
                                        </div>
                                    </ExpandableGroup>
                                </div>
                            );
                        })}
                    </div>
                </this.AnimationItem>

                <WizardFooter
                    buttons={this.getButtons()}
                    leftComponents={<AddFutureCheckbox itemType="metrics" form="selectedMetrics" />}
                />
            </React.Fragment>
        );
    }
}

MetricsSelection.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
};

MetricsSelection.defaulltProps = {
    fields: [],
    onChange: () => {},
};

export default MetricsSelection;
