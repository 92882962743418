import React from 'react';
import PropTypes from 'prop-types';
import css from '../partials/Grid.css';

import SortOff from '../../resources/svg/sort_off.svg';

class GridCustomHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSort: null,
        };

        this.onSortChanged = this._onSortChanged.bind(this);
        this.onSortRequested = this._onSortRequested.bind(this);
        this.onMenuClicked = this._onMenuClicked.bind(this);

        props.column.addEventListener('sortChanged', this.onSortChanged);
    }

    componentDidMount() {
        this.onSortChanged();
    }

    componentWillUnmount() {
        this.props.column.removeEventListener('sortChanged', this.onSortChanged);
    }

    _onSortChanged() {
        this.setState({ currentSort: this.props.column.getSort() });
    }

    _onMenuClicked(e) {
        e.stopPropagation();
        this.props.showColumnMenu(this.menuButton);
    }

    _onSortRequested() {
        this.props.progressSort(false);
    }

    render() {
        const menu = (
            <div
                ref={(menuButton) => {
                    this.menuButton = menuButton;
                }}
                className={css.customHeaderMenuButton}
                onClick={this.onMenuClicked}
            >
                <i className="ag-icon ag-icon-menu" />
            </div>
        );

        const sortValue = this.state.currentSort || null;
        const sort = (
            <div className={css.customSortLabel}>
                <i className={`ag-icon ag-icon-${sortValue}`}>{!sortValue && <SortOff />}</i>
            </div>
        );

        return (
            <div className={css.customHeaderContainer} onClick={this.onSortRequested}>
                {menu}
                <div className={css.customHeaderLabel}>{this.props.displayName}</div>
                {sort}
            </div>
        );
    }
}

GridCustomHeader.propTypes = {
    progressSort: PropTypes.func,
    showColumnMenu: PropTypes.func,
    column: PropTypes.objectOf(PropTypes.any),
    displayName: PropTypes.string,
};

GridCustomHeader.defaultProps = {
    progressSort: () => {},
    showColumnMenu: () => {},
    column: {},
    displayName: '',
};

export default GridCustomHeader;
