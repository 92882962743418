export function computePattern(fields) {
    return [...fields.filter(x => x.name && !x.hidden).map(x => `{${x.name}}`), '{GOV_ID}'].join('_');
}

const GOVERNANCE_ID_LENGTH = 20;

function generateGovID() {
    const options = 'abcdefghijklmnopqrstuvwxyz0123456789';

    let id = '';
    for (let i = 0; i < GOVERNANCE_ID_LENGTH; i++) {
        id += options[Math.floor(Math.random() * options.length)];
    }
    return `ORG${id}`;
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

const SAMPLE_GOV_ID = generateGovID();

export function computeSampleCode(fields) {
    return [
        ...fields
            .filter(field => field.name && !field.hidden)
            .map(field => {
                if (field.values.length === 0) return field.name;
                return field.values[0].code;
            }),
        SAMPLE_GOV_ID,
    ].join('_');
}

let tempID = 0;
export function getLocalSchemaId() {
    return ++tempID;
}

export function isAssetFieldType(fieldType) {
    return fieldType === 'single_asset';
}
