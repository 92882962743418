import { createSelector } from 'reselect';
import { getAttributionAppsList } from '../selectors/apps';
import { sortAlphabetically } from '../utils/sortUtil';

export const getLoading = state => state.assistedInstalls.loading;

export const getApps = createSelector([getAttributionAppsList], apps => {
    if (!apps) {
        return [];
    }

    return apps
        .map(app => {
            return {
                id: app.app_id,
                name: app.name,
                display_name: app.name,
            };
        })
        .sort((a, b) => sortAlphabetically(a.name, b.name, false, false));
});
