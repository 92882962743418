import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { getMaxLengthErrMsg } from 'banners/utils';
import { useFormikContext } from 'formik-2';
import { AutoCompleteFieldEnhanced, FormField } from '../../../../utils/components/formUtils';
import { EMPTY_OPTIONS } from '../../../consts';
import FileField from '../../../../components/widgets/FileField';

export const CONTENT_VALIDATION_SCHEMA = {
    title: Yup.string()
        .max(100, getMaxLengthErrMsg('Title', 100))
        .nullable(),
    subtitle: Yup.string()
        .max(100, getMaxLengthErrMsg('Subtitle', 100))
        .nullable(),
    download_button: Yup.string()
        .max(100, getMaxLengthErrMsg('Call To Action button', 100))
        .nullable(),
};

export default function Content() {
    const { values, touched, errors, setValues } = useFormikContext();

    return (
        <Fragment>
            <FileField
                clearable
                value={values.logo}
                label="STATIC.PAGES.BANNERS.BANNER_SHELF.LOGO_LABEL"
                uploadText="STATIC.PAGES.BANNERS.BANNER_SHELF.UPLOAD_LOGO_TEXT"
                clearText="STATIC.PAGES.BANNERS.BANNER_SHELF.DELETE_LOGO_TEXT"
                onChange={value => setValues({ ...values, logo: value })}
                error={touched.logo && errors.logo}
            />
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="title"
                    value={values.title}
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.TITLE_LABEL"
                    isMulti={false}
                    options={EMPTY_OPTIONS}
                    searchable
                    placeholder="STATIC.PAGES.BANNERS.BANNER_SHELF.TITLE_PLACEHOLDER"
                    debounceTime={700}
                    error={touched.title && errors.title}
                />
            </FormField>
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="subtitle"
                    value={values.subtitle}
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.SUBTITLE_LABEL"
                    isMulti={false}
                    options={EMPTY_OPTIONS}
                    searchable
                    placeholder="STATIC.PAGES.BANNERS.BANNER_SHELF.SUBTITLE_PLACEHOLDER"
                    debounceTime={700}
                    error={touched.subtitle && errors.subtitle}
                />
            </FormField>
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="download_button"
                    value={values.download_button}
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.DOWNLOAD_BUTTON_LABEL"
                    isMulti={false}
                    options={EMPTY_OPTIONS}
                    searchable
                    placeholder="STATIC.PAGES.BANNERS.BANNER_SHELF.DOWNLOAD_BUTTON_PLACEHOLDER"
                    debounceTime={700}
                    error={touched.download_button && errors.download_button}
                />
            </FormField>
        </Fragment>
    );
}

Content.propTypes = {
    values: PropTypes.objectOf(PropTypes.any),
    setValues: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.any),
    errors: PropTypes.objectOf(PropTypes.any),
};

Content.defaultProps = {
    values: {},
    setValues: () => {},
    touched: {},
    errors: {},
};
