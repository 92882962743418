import flatten from 'lodash/flatten';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { attributionAppsSchema } from '../utils/apps';

export const getAttributionAppsData = state => {
    return state.apps.attributionAppsData;
};
export const getAppsData = state => {
    return state.apps.appsData;
};
export const getFavouriteApps = state => {
    return state.apps.favouriteApps;
};

export const getAttributionAppsList = createSelector([getAttributionAppsData], appsData => {
    return appsData && denormalize(appsData.result, attributionAppsSchema, appsData.entities);
});

export const getAttributionSitesList = createSelector([getAttributionAppsList], appsList => {
    return appsList && flatten(appsList.map(({ sites }) => sites));
});

export const getAppsList = createSelector([getAppsData], appsData => {
    const res = appsData && denormalize(appsData.result, attributionAppsSchema, appsData.entities);
    // Right now this is used only for the manage team. so the data is VERY partial.
    // Extend it if you need more!!
    return res
        ? res.map(app => ({
              name: app.name,
              id: app.id,
              icon: app.sites.length > 0 ? app.sites[0].icon : '',
          }))
        : null;
});

export const getAppsListAutoSuggestions = createSelector([getAttributionAppsList], appsList => {
    return (appsList || []).map(app => {
        return {
            value: app.app_id,
            label: app.name,
        };
    });
});
