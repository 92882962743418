import { connect } from 'react-redux';
import { ChangePasswordDialog } from '../../components/partials';
import { getChangePasswordDialog } from '../../selectors/user';
import { closeChangePasswordDialog, changePassword } from '../../actions/user';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        ...getChangePasswordDialog(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClose: () => {
            dispatch(closeChangePasswordDialog());
        },
        onSave: (oldPassword, newPassword) => {
            dispatch(changePassword(oldPassword, newPassword));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordDialog);
