import CheckedIcon from '../../../resources/icons/check_mark.svg';
import UncheckedIcon from '../../../resources/icons/minus.svg';
import TableCellToggle from '../../../components/widgets/TableCellToggle';
import TableCellCheckbox from '../../../components/widgets/TableCellCheckbox';
import TableCellDropdown from '../../../components/widgets/TableCellDropdown';
import TableCellBoolean from '../../../components/widgets/TableCellBoolean';
import TableCellText from '../../../components/widgets/TableCellText';
import {
    ANSWER_CHOICES_DATA_KEY,
    QUESTIONS_TOOLTIPS,
    QUESTION_ID_DATA_KEY,
    DROPDOWN_OPTIONS_DATA_KEY,
    SDK_CONFIGURATION_PLATFORMS_STEP,
    QUESTIONS_DEPENDENCIES,
    DROPDOWN_SELECTION_DATA_KEY,
    SUBHEADER_TEXT_TABLE_METADATA,
    QUESTION_TEXT_TABLE_METADATA,
    QUESTION_TEXT_SUMMARY_TABLE_METADATA,
    NO_CATEGORY,
    ANSWER_TYPE_DATA_KEY,
    SDK_CONFIGURATION_MIXPANEL_PREFIX,
    IS_EDITED_MIXPANEL_PARAM,
    PLATFORMS_ANSWER_MIXPANEL_PARAM,
    QUESTIONS_METADATA,
    PARENT_QUESTIONS,
    IS_PARENT_QUESTION_KEY,
    YOUR_PLATFORMS,
    PLATFORMS_CONFIG,
    SELECT_MEDIATION_PLATFORM,
    FREE_TIER_BLACKLIST_QUESTIONS,
    IOS_SPECIFIC_QUESTIONS,
} from './consts';
import { PYTHON_PARSING_DATA_TYPES, CUSTOMER_TIERS } from '../../../utils/consts';
import { OS } from '../../../utils/OS';
import css from './sdkConfiguration.css';
import { trackMixpanelEvent } from '../../../utils/general';
import TableCellMultiIcons from '../../../components/widgets/TableCellMultiIcons';

const getAnswerData = (answer, parentAnswer) => {
    return {
        answer,
        [DROPDOWN_SELECTION_DATA_KEY]:
            parentAnswer === false || !answer
                ? undefined
                : {
                      name: answer,
                      display_name: answer,
                  },
    };
};

export const getPlatformAnswerData = (questionID, sdkConfigurationAnswers) => {
    if (questionID !== YOUR_PLATFORMS) return {};

    return {
        answer: sdkConfigurationAnswers?.[questionID]
            ?.map(platform => PLATFORMS_CONFIG[platform]?.Icon)
            .filter(icon => icon !== undefined),
        className: css.platformIcons,
    };
};

export const isQuestionsCategorised = questionsMetadata => {
    const categoriesIds = Object.keys(questionsMetadata);
    return !(categoriesIds.length === 1 && categoriesIds[0] === NO_CATEGORY);
};

const getTableStyleAndCssClasses = (
    questionID,
    parentQuestionID,
    isParentQuestion,
    parentAnswer,
    questionsMetadata,
    isSummary
) => {
    const setBackground = isQuestionsCategorised(questionsMetadata);

    const tdStyle = {
        padding: '5px 0 2px 0',
        width: 620,
        position: 'relative',
        left: isParentQuestion || questionID === SELECT_MEDIATION_PLATFORM ? '-28px' : '0',
    };

    if (setBackground) {
        tdStyle.backgroundColor = 'var(--white)';
    }

    const hideChildRow = !parentAnswer;
    const hideClass = `childTableRow ${hideChildRow ? `hideRow${isSummary ? 'Display' : ''}` : ''}`;

    return {
        trClass: parentQuestionID ? hideClass : '',
        tdClassCheckbox: isParentQuestion || questionID === SELECT_MEDIATION_PLATFORM ? 'hideTd' : css.checkboxTd,
        disableCheckbox: isParentQuestion || questionID === SELECT_MEDIATION_PLATFORM,
        hideRight: !isParentQuestion ? 'hideTd' : '',
        className: css.answerText,
        tdStyle,
    };
};

const getAnswersMetadataConfig = (answersMetadata, questionID, isParentQuestion) => {
    if (!answersMetadata) return {};

    const questionChoices = answersMetadata[questionID]?.choices;
    return {
        [ANSWER_CHOICES_DATA_KEY]: questionChoices,
        disableToggle: isParentQuestion ? false : !questionChoices,
        [DROPDOWN_OPTIONS_DATA_KEY]: answersMetadata[questionID]?.choices?.map(choice => ({
            name: choice,
            display_name: choice,
        })),
        [ANSWER_TYPE_DATA_KEY]: questionID === YOUR_PLATFORMS ? YOUR_PLATFORMS : answersMetadata[questionID]?.type,
    };
};

const questionsDataFilter = (questionID, organizationTier, platforms) => {
    const isFreeTier = organizationTier === CUSTOMER_TIERS.FREE;
    const isIosSelected = platforms.includes(OS.IOS);
    return (
        !(isFreeTier && FREE_TIER_BLACKLIST_QUESTIONS.includes(questionID)) &&
        (isIosSelected || !IOS_SPECIFIC_QUESTIONS.includes(questionID))
    );
};

export const getTableData = (
    questionsMetadata,
    sdkConfigurationAnswers,
    translate,
    answersMetadata,
    organizationTier,
    platforms = [],
    isSummary
) => {
    let subHeader;

    return Object.keys(questionsMetadata).reduce((accum, categoryID) => {
        const questionsOrder = questionsMetadata[categoryID];

        const questions = questionsOrder
            .filter(questionID => questionsDataFilter(questionID, organizationTier, platforms))
            .map(questionID => {
                const isParentQuestion = !!PARENT_QUESTIONS[questionID];

                // has parent question
                const parentQuestionID = QUESTIONS_DEPENDENCIES[questionID];
                const parentAnswer = sdkConfigurationAnswers?.[parentQuestionID];

                return {
                    [IS_PARENT_QUESTION_KEY]: isParentQuestion,
                    questionID,
                    QUESTION_ID_DATA_KEY_CHECKBOX: questionID,
                    questionText: translate(`STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.${questionID}`),
                    tooltipText: QUESTIONS_TOOLTIPS[questionID],
                    ...getAnswerData(
                        sdkConfigurationAnswers?.[questionID],
                        sdkConfigurationAnswers?.[parentQuestionID]
                    ),
                    ...getTableStyleAndCssClasses(
                        questionID,
                        parentQuestionID,
                        isParentQuestion,
                        parentAnswer,
                        questionsMetadata,
                        isSummary
                    ),
                    ...getPlatformAnswerData(questionID, sdkConfigurationAnswers),
                    ...getAnswersMetadataConfig(answersMetadata, questionID, isParentQuestion),
                };
            });

        if (categoryID !== NO_CATEGORY) {
            subHeader = { subHeaderText: translate(`STATIC.PAGES.SDK_CONFIGURATION.CATEGORIES.${categoryID}`) };
        }

        return subHeader ? [...accum, subHeader, ...questions] : [...accum, ...questions];
    }, []);
};

const getSummaryAnswerCellComponent = rowData => {
    if (rowData[ANSWER_TYPE_DATA_KEY] === YOUR_PLATFORMS) {
        return TableCellMultiIcons;
    } else {
        return rowData[ANSWER_TYPE_DATA_KEY] === PYTHON_PARSING_DATA_TYPES.STRING ? TableCellText : TableCellBoolean;
    }
};

export const getAnswersSummaryColumnMetadata = () => {
    return {
        name: 'answer',
        getCellComponent: getSummaryAnswerCellComponent,
        headerAlign: 'center',
        cellValues: [
            { propName: 'value', dataKey: 'answer' },
            { propName: 'icons', dataKey: 'answer' },
            { propName: 'className', dataKey: 'className' },
        ],
        cellProps: {
            TrueIcon: CheckedIcon,
            trueIconClass: css.trueIcon,
            FalseIcon: UncheckedIcon,
            falseIconClass: css.falseIcon,
            tdStyle: {
                textAlign: 'right',
                width: 180,
                paddingRight: 15,
            },
        },
        thStyle: { width: 150 },
    };
};

export const getRightCellComponent = rowData => {
    if (rowData[ANSWER_CHOICES_DATA_KEY]) {
        return TableCellDropdown;
    } else {
        return TableCellToggle;
    }
};

export const getRightColumnMetadata = (handleToggle = () => {}) => {
    return {
        name: 'answer',
        getCellComponent: getRightCellComponent,
        headerAlign: 'center',
        cellValues: [
            { propName: 'selected', dataKey: DROPDOWN_SELECTION_DATA_KEY },
            { propName: 'toggled', dataKey: 'answer' },
            { propName: 'value', dataKey: 'answer' },
            { propName: 'id', dataKey: QUESTION_ID_DATA_KEY },
            { propName: 'valuesList', dataKey: DROPDOWN_OPTIONS_DATA_KEY },
            { propName: 'tdClassName', dataKey: 'hideRight' },
            { propName: 'disabled', dataKey: 'disableToggle' },
        ],
        cellProps: {
            onToggle: (value, questionID) => {
                handleToggle(questionID, value);
            },
            onValueSelection: ({ name: value }, questionID) => {
                handleToggle(questionID, value);
            },
            dropdownContainerStyle: { width: 210 },
            disableLabel: 'STATIC.PAGES.SDK_CONFIGURATION.DISABLED_QUESTION_LABEL',
            tdStyle: { textAlign: 'right', width: 150 },
        },
        thStyle: { width: 150 },
    };
};

export const getCheckboxColumnMetadata = (handleToggle = () => {}) => {
    return {
        name: 'answer',
        cellComponent: TableCellCheckbox,
        headerAlign: 'center',
        cellValues: [
            { propName: 'value', dataKey: 'answer' },
            { propName: 'id', dataKey: QUESTION_ID_DATA_KEY },
            { propName: 'tdClassName', dataKey: 'tdClassCheckbox' },
            { propName: 'disabled', dataKey: 'disableCheckbox' },
        ],
        cellProps: {
            onChange: (value, questionID) => {
                handleToggle(questionID, value);
            },
            disableLabel: 'STATIC.PAGES.SDK_CONFIGURATION.DISABLED_QUESTION_LABEL',
            tdStyle: { textAlign: 'right', width: 150 },
        },
        thStyle: { width: 150 },
    };
};

export const getSummaryTableMetadata = () => {
    const metadata = [SUBHEADER_TEXT_TABLE_METADATA, QUESTION_TEXT_SUMMARY_TABLE_METADATA];
    metadata.push(getAnswersSummaryColumnMetadata());
    return metadata;
};

export const getTableMetadata = handleToggle => {
    return [
        getCheckboxColumnMetadata(handleToggle),
        SUBHEADER_TEXT_TABLE_METADATA,
        QUESTION_TEXT_TABLE_METADATA,
        getRightColumnMetadata(handleToggle),
    ];
};

export const validationByStep = {
    [SDK_CONFIGURATION_PLATFORMS_STEP]: ({ platforms }) => {
        return !!platforms.length;
    },
};

export const parseAnswers = (platforms, sdkConfigurationAnswers) => {
    const parsedAnswers = {};

    platforms.forEach(platform => {
        const curPlatformParsedAnswers = { ...sdkConfigurationAnswers };
        Object.keys(QUESTIONS_DEPENDENCIES).forEach(questionId => {
            const parentQuestionId = QUESTIONS_DEPENDENCIES[questionId];
            const parentAnswer = sdkConfigurationAnswers[parentQuestionId];

            if (!parentAnswer) {
                curPlatformParsedAnswers[questionId] = null;
            }
        });

        parsedAnswers[platform] = curPlatformParsedAnswers;
    });

    return parsedAnswers;
};

const sendStepAnswersMixpanel = (stepEvent, answers, isEdited, platform) => {
    let params = { ...answers, [IS_EDITED_MIXPANEL_PARAM]: isEdited };
    if (platform) {
        params = { ...params, [PLATFORMS_ANSWER_MIXPANEL_PARAM]: platform };
    }
    trackMixpanelEvent(SDK_CONFIGURATION_MIXPANEL_PREFIX, stepEvent, params);
};

export const sendQuestionAnswersMixpanelEventsByStep = (step, isEdited, platforms, answers) => {
    const stepQuestions = QUESTIONS_METADATA[step] || {};
    const updatedAnswers = {};

    if (step === SDK_CONFIGURATION_PLATFORMS_STEP) {
        updatedAnswers.platforms = platforms;

        Object.values(stepQuestions).forEach(questions => {
            questions.forEach(question => {
                updatedAnswers[question] = answers[question];
            });
        });
        sendStepAnswersMixpanel(step, updatedAnswers, isEdited);
    } else {
        Object.values(stepQuestions).forEach(questions => {
            questions.forEach(question => {
                updatedAnswers[question] = answers[question];
            });
        });
        sendStepAnswersMixpanel(step, updatedAnswers, isEdited);
    }
};

/**
 * @param {string} platformName the name of the platform
 * @param {array} orgAvailablePlatforms list of platforms available for the org
 * @param {boolean} isWebOrg is org uses web customer
 * from non-technical reasons we currently have duplication within the web platform availability settings,
 * so we check in both org options (is_web and available_platforms)
 * @returns {boolean} isAvailable
 */
export const isPlatformAvailable = (platformName, orgAvailablePlatforms, isWebOrg) => {
    let isAvailable = true;

    if (platformName === OS.WEB) {
        isAvailable = isWebOrg;
    } else if (platformName === OS.PC) {
        isAvailable = orgAvailablePlatforms?.includes(platformName);
    }

    return isAvailable;
};
