import { CUSTOMER_TIERS } from '../utils/consts';

export const MIXPANEL_UPGRADE_ACCOUNT = 'Upgrade Account Click';
export const MIXPANEL_PAY_NOW_CLICK = 'Pay Now Click';
export const MIXPANEL_PAGE_LOADED = 'Page Loaded';
export const TIERS_SHOW_PLAN_PRICE = [CUSTOMER_TIERS.STANDARD_UNIFIED, CUSTOMER_TIERS.ENTERPRISE];

export const DATA_TEST_ID_TIMEZONE = 'datapair-timezone';
export const DATA_TEST_ID_CURRENCY = 'datapair-currency';
export const DATA_TEST_ID_START_DATE = 'datapair-start-date';
export const DATA_TEST_ID_END_DATE = 'datapair-end-date';
export const DATA_TEST_ID_PLAN_PRICE = 'datapair-plan-price';
export const DATA_TEST_ID_NO_ACTIVE_PLAN = 'no-active-plan';
export const DATA_TEST_ID_PRICING_CARDS = 'pricing-cards';
export const DATA_TEST_ID_ORG_PLAN_PROGRESS = 'org-plan-progress';
export const DATA_TEST_ID_ORG_PLAN_DETAILS = 'org-plan-details';
export const DATA_TEST_ID_ACTIVE_TIER_FOOTER = 'active-tier-footer';

export const DATA_BADGE_TIER_FREE = 'badge-tier-free';
export const DATA_BADGE_TIER_STANDARD_UNIFIED = 'badge-tier-standard';
export const DATA_BADGE_TIER_ENTERPRISE = 'badge-tier-enterprise';

export const DATA_BADGE_MAP = {
    [CUSTOMER_TIERS.FREE]: DATA_BADGE_TIER_FREE,
    [CUSTOMER_TIERS.STANDARD_UNIFIED]: DATA_BADGE_TIER_STANDARD_UNIFIED,
    [CUSTOMER_TIERS.ENTERPRISE]: DATA_BADGE_TIER_ENTERPRISE,
};
export const DATA_TEST_ID_CAP = 'cap';
export const DATA_TEST_ID_AD_SPEND_CAP = 'ad-spend';
export const CHECKOUT_URL = 'react/plan-upgrade';

export const PACING_TYPES = {
    INSTALLS: 'installs',
    AD_SPEND: 'spend',
};

export const isExpired = expiresInDays => expiresInDays <= 0;

export const isAboutToExpire = expireInDays => expireInDays <= 45 && expireInDays > 0;

export const isNoUsage = usageInPercent => usageInPercent === 0;
export const isLowUsage = usageInPercent => usageInPercent < 50;
export const isHighUsage = usageInPercent => usageInPercent >= 50 && usageInPercent < 75;
export const isAboutToMaxCap = usageInPercent => usageInPercent >= 75 && usageInPercent < 100;
export const isCapMaxed = usageInPercent => usageInPercent >= 100;

export const TIER_CARD_COLORS = {
    [CUSTOMER_TIERS.FREE]: 'var(--turquoise100)',
    [CUSTOMER_TIERS.STANDARD_UNIFIED]: 'var(--blue300)',
    [CUSTOMER_TIERS.ENTERPRISE]: 'var(--indigo)',
};

export const WARNING_TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    EXTERNAL_LINK: 'externalLink',
};

export const ARR_CURRENCY = 'USD';

export const PACING_WARNING_MESSAGES = {
    upgrade: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.INFORMATION.UPGRADE_CTA',
    noUsage: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.INFORMATION.NO_USAGE',
    usage: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.INFORMATION.USAGE',
    maxed: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.INFORMATION.MAXED_CAP',
    expiring: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.INFORMATION.DATE_EXPIRED',
    expired: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.INFORMATION.EXPIRED',
};
