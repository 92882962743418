import React from 'react';
import PropTypes from 'prop-types';
import { ExpandablePanel, EventsItemFooter, EventsItemHeader, EventsItemContent } from 'components/widgets';
import classNames from 'classnames';
import { getEventSlotGroupType } from '../utils';
import css from '../page.css';
import { EVENT_TYPES } from '../../utils/consts';
import { EVENT_TYPE_BORDER_CLASSES } from '../consts';

class CustomEventEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.onSaveClicked = this._onSaveClicked.bind(this);
        this.onEventNameChanged = this._onEventNameChanged.bind(this);
        this.onDeleteClicked = this._onDeleteClicked.bind(this);
    }

    _onSaveClicked() {
        const { onSaveClicked, customEvent } = this.props;
        onSaveClicked(customEvent);
    }

    _onEventNameChanged(value) {
        const { onEventNameChanged, customEvent } = this.props;
        onEventNameChanged(customEvent, value);
    }

    _onDeleteClicked() {
        const { onDeleteClicked, customEvent } = this.props;
        onDeleteClicked(customEvent);
    }

    render() {
        const {
            onEventExpand,
            customEvent,
            uans,
            calculatedMetricsOptions,
            onUANEventRemoved,
            onUANEventAdded,
            onToggleCalculatedMetric,
            onCancelClicked,
            onUniqueToggle,
            warnings,
            saving,
            saveError,
            uniqueToggleDisabled,
            showSlotNumber,
            saveDisabled,
            eventType,
            disabled,
            activeGroupType,
            readOnly,
            dataTestId,
        } = this.props;
        const {
            name,
            create_ts,
            creator,
            id,
            last_update_ts,
            expanded,
            is_locked,
            slot,
            is_unique,
        } = customEvent;
        return (
            <ExpandablePanel
                id={id}
                disabled={is_locked || disabled}
                onExpandClick={onEventExpand}
                expanded={expanded}
                isSticky
                className={classNames({
                    [EVENT_TYPE_BORDER_CLASSES[eventType]]: eventType,
                    [css.eventItemBorder]: eventType,
                })}
                headerContainerClassName={css.eventHeaderContainer}
                isExpandable={eventType !== EVENT_TYPES.STANDARD}
                dataTestId={dataTestId}
                footer={
                    <EventsItemFooter
                        modifiedBy=""
                        createdBy={creator}
                        lastModified={last_update_ts}
                        created={create_ts}
                        onCancelClicked={onCancelClicked}
                        onSaveClicked={this.onSaveClicked}
                        saving={saving}
                        slot={slot}
                        saveDisabled={saveDisabled}
                        readOnly={readOnly}
                    />
                }
                header={
                    <EventsItemHeader
                        disabled={disabled || saving}
                        eventName={name}
                        lastModified={last_update_ts}
                        expanded={expanded}
                        locked={is_locked}
                        calculatedMetricsOptions={calculatedMetricsOptions}
                        onEventNameChanged={this.onEventNameChanged}
                        onDeleteClicked={this.onDeleteClicked}
                        slot={slot}
                        showSlotNumber={showSlotNumber}
                        unique={is_unique}
                        eventSlotGroupType={getEventSlotGroupType(customEvent)}
                        readOnly={readOnly}
                        standard={eventType === EVENT_TYPES.STANDARD}
                    />
                }
                content={
                    <EventsItemContent
                        uans={uans}
                        calculatedMetricsOptions={calculatedMetricsOptions}
                        customEvent={customEvent}
                        onUANEventAdded={onUANEventAdded}
                        onUANEventRemoved={onUANEventRemoved}
                        onToggleCalculatedMetric={onToggleCalculatedMetric}
                        onUniqueToggle={onUniqueToggle}
                        warnings={warnings}
                        saving={saving}
                        saveError={saveError}
                        uniqueToggleDisabled={uniqueToggleDisabled}
                        activeGroupType={activeGroupType}
                        readOnly={readOnly}
                        standard={eventType === EVENT_TYPES.STANDARD}
                    />
                }
            />
        );
    }
}

CustomEventEditor.propTypes = {
    onEventExpand: PropTypes.func,
    customEvent: PropTypes.objectOf(PropTypes.any),
    uans: PropTypes.arrayOf(PropTypes.any),
    calculatedMetricsOptions: PropTypes.arrayOf(PropTypes.any),
    warnings: PropTypes.arrayOf(PropTypes.any),
    onUANEventAdded: PropTypes.func,
    onUANEventRemoved: PropTypes.func,
    onToggleCalculatedMetric: PropTypes.func,
    onCancelClicked: PropTypes.func,
    onSaveClicked: PropTypes.func,
    saving: PropTypes.bool,
    saveError: PropTypes.string,
    onEventNameChanged: PropTypes.func,
    onDeleteClicked: PropTypes.func,
    onUniqueToggle: PropTypes.func,
    uniqueToggleDisabled: PropTypes.bool,
    showSlotNumber: PropTypes.bool,
    eventType: PropTypes.string,
    permissionData: PropTypes.shape({
        typesMap: PropTypes.objectOf(PropTypes.any),
        permission: PropTypes.number,
    }),
    readOnly: PropTypes.bool,
    dataTestId: PropTypes.string,
};

CustomEventEditor.defaultProps = {
    onEventExpand: () => {},
    customEvent: {},
    uans: [],
    calculatedMetricsOptions: [],
    warnings: [],
    onUANEventAdded: () => {},
    onUANEventRemoved: () => {},
    onToggleCalculatedMetric: () => {},
    onCancelClicked: () => {},
    onSaveClicked: () => {},
    onEventNameChanged: () => {},
    onDeleteClicked: () => {},
    onUniqueToggle: () => {},
    saving: false,
    saveError: '',
    eventType: undefined,
    uniqueToggleDisabled: false,
    showSlotNumber: false,
    permissionData: {
        typesMap: {},
        permission: 3,
    },
    readOnly: false,
    dataTestId: undefined,
};

export default CustomEventEditor;
