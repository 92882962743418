import { createSelector } from 'reselect';

export const getFields = state => state.fields;
export const getFieldsInitiated = state => state.fields.initiated;

export const getFieldsForType = type => createSelector([getFields], fields => fields[type]);

export const getFilters = createSelector([getFieldsForType('filters')], filters => {
    let retFilters = [];
    if (!filters) {
        return [];
    }
    for (const type in filters) {
        retFilters = retFilters.concat(filters[type]);
    }
    return retFilters;
});

export const getAccountType = state => {
    return state.user?.data?.account_type;
};
