import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts, HighchartsChart, ColumnSeries } from 'react-jsx-highcharts';
import { colorByNetwork, shareOfCostPlotOptions } from '../consts';
import {
    appLabelsFormatter,
    seriesFloatValueFormatter,
    mapShareOfCostSeriesValues,
    getShareOfCostCategories,
    getEmptyCategoryIndexes,
} from '../utils';
import ChartTooltip from './chartTooltip';
import ChartLegend from './chartLegend';
import ChartXAxis from './chartXAxis';
import ChartYAxis from './chartYAxis';
import Chart from './chart';

const ShareOfCostChart = ({ chartData, logoByName, nameByLabel }) => {
    const { data, labels } = chartData;

    return (
        <HighchartsChart plotOptions={shareOfCostPlotOptions}>
            <ChartLegend reversed />
            <Chart type="bar" height={labels.length * 100} minWidth={200} marginLeft={125} />
            <ChartTooltip isTextHeader categories={labels} />
            <ChartXAxis
                id="share_of_cost_x_axis"
                categories={getShareOfCostCategories(labels, nameByLabel)}
                emptyCategoryIndexes={getEmptyCategoryIndexes(data)}
                labelsFormat={null}
                lineWidth={1}
                formatter={appLabelsFormatter.bind(this, logoByName)}
            />
            <ChartYAxis id="share_of_voice_y_axis" labelsEnabled={false}>
                {data.map(({ data: values, label }) => {
                    return (
                        <ColumnSeries
                            formatter={seriesFloatValueFormatter}
                            key={label}
                            name={label}
                            data={mapShareOfCostSeriesValues(values)}
                            color={colorByNetwork[label]}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

ShareOfCostChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    nameByLabel: PropTypes.objectOf(PropTypes.any),
    logoByName: PropTypes.objectOf(PropTypes.any),
};

ShareOfCostChart.defaultProps = {
    chartData: {},
    nameByLabel: {},
    logoByName: {}
};

export default withHighcharts(ShareOfCostChart, Highcharts);
