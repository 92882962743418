import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <strong>What:</strong><span> Configure Google Ads in your Singular account. This will let Singular track how many installs your apps have had as a result of advertising in Google Ads. You’ll see these installs in your reports and you’ll be able to evaluate them against your ad spend (see </span><strong>Run
                an ROI Report</strong><span>).</span>
            </p>
            <p>
                <span>To set up Google Ads:</span>
            </p>
            <ol>
                <li>
                    <span>In your Google Ads account, generate a </span>
                    <strong>Link ID</strong><span> (see </span>
                    <a href="https://support.google.com/google-ads/answer/7365001?hl=en" target="_blank">Google’s instructions</a><span>).</span>
                </li>
                <li>
                    <span>In Singular, go to </span><strong>Partner Configurations</strong><span
                > and add “AdWords”.</span>
                </li>
                <li>
                    <span>In the settings window, enter the Link ID you have generated, and then configure your attribution settings. For example, you can enable or disable view-through tracking.</span>
                </li>
            </ol>
            <p>
                <strong>Tip</strong><span>: Google Ads is a “Self-Attributing Network”, meaning you don’t need to create Singular tracking links and embed them in your ads. To learn how Singular works with Google Ads, see </span><a
                href="https://support.singular.net/hc/en-us/articles/115003252786-Google-Ads-AdWords-" target="_blank">Singular’s Google Ads guide</a><span>.</span>
            </p>
        </div>
    );
}
