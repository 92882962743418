import rules from './fieldVisibilityRules';
import { reportBase, cohorts } from './reportsBase';
import { SORTING_INDEX_MAP } from '../../components/pages/reports/consts';

const TYPE = 'anonymousReport';

export default {
    type: TYPE,
    fields: [
        ...reportBase.fields,
        ...cohorts.fields,
        'custom_dimensions',
        'discrepancyMetrics',
        'goalsMetrics',
        'goalsForecastMetrics',
        'sourceAttributionType',
        'crossDeviceDemoCohortType',
    ],
    visibleRule: rules[TYPE],
    dataSelector: {
        ...reportBase.dataSelector,
        ...cohorts.dataSelector,
        custom_dimensions: 'custom_dimensions',
        discrepancyMetrics: 'discrepancyMetrics',
        goalsMetrics: 'goalsMetrics',
        goalsForecastMetrics: 'goalsForecastMetrics',
        sourceAttributionType: 'sourceAttributionType',
        crossDeviceDemoCohortType: 'crossDeviceDemoCohortType',
        selectedTab: 'selectedTab',
        tableDataFormatted: 'reportData',
        chartDataFormatted: 'chartData',
        chart: 'chart',
        mode: 'mode',
        selectedGoal: 'selectedGoal',
        pivotTable: 'pivotTable',
    },
    colDefClassRule: colDef => {
        const { unified_report_type: unifiedReportType, is_unified_report: isUnifiedReport } = colDef;
        return isUnifiedReport && unifiedReportType ? `col_type__metric__${unifiedReportType}` : '';
    },
    getSortingKey: colDef => {
        const { unified_report_type: unifiedReportType, is_unified_report: isUnifiedReport } = colDef;

        return isUnifiedReport && unifiedReportType ? SORTING_INDEX_MAP[unifiedReportType] : undefined;
    },
};
