import {
    chartColor1,
    chartColor2,
    chartColor3,
    chartColor4,
    chartColor6,
    chartColor7,
    chartColor8,
} from 'utils/charts';

export const COUNTRY_FILTER = 'country';
export const APP_FILTER = 'app';
export const PLATFORM_FILTER = 'platform';
export const METRIC_FILTER = 'metric';

export const INSTALLS_METRIC = 'tracker_installs';

export const TRACK_EVENT_PREFIX = 'MMM';
export const TRACK_EVENT_FILTER_CHANGE = 'Selector Change';
export const TRACK_EVENT_PAGE_LOADED = 'Page Loaded';

export const MMM_GRAPH_TYPES = {
    OUTCOME_PER_CHANNEL: 'outcome_per_channel',
    OUTCOME_PER_CHANNEL_NO_SLOPE_FORCING: 'outcome_per_channel_no_slope_forcing',
    OUTCOME_PER_CHANNEL_SLOPE_FORCING_LATEST: 'outcome_per_channel_slope_forcing_latest',
    NETWORK_SHARE: 'network_share',
    NETWORK_SHARE_WITHOUT_SLOPE_FORCING: 'network_share_without_slope_forcing',
    CALCULATED_METRIC_OVER_TIME: 'calculated_metric_over_time',
    COUNTRY_TREND_OVER_TIME: 'country_trend_over_time',
    APP_TREND_OVER_TIME: 'app_trend_over_time',
    DIRECT_RESPONSE_COUNTRY_TREND_OVER_TIME_SERIES: 'direct_response_calculated_metric_over_time',
    NON_WEIGHTED_COST_OVER_TIME_SERIES: 'cost_over_time',
    DIRECT_AND_MODELED_OVER_TIME: 'direct_and_modeled_over_time',
    MODELED_BY_SOURCE_OVER_TIME: 'modeled_by_source_over_time',
};

export const overTimeChartPlotOptions = {
    line: {
        lineWidth: 2,
        states: {
            hover: {
                lineWidth: 4,
            },
        },
        marker: {
            enabled: false,
        },
    },
    series: {
        cursor: 'pointer',
        pointPlacement: 'on',
        groupPadding: 0.35,
        pointPadding: 0.01,
        pointWidth: 40,
        minPointLength: 2,
    },
};

export const coefPerChannelPlotOptions = {
    series: {
        cursor: 'pointer',
        groupPadding: 0.17,
        pointPadding: 0.01,
        pointWidth: 40,
        minPointLength: 2,
    },
};

export const costPerChannelPlotOptions = {
    series: {
        cursor: 'pointer',
        groupPadding: 0.3,
        pointPadding: 0.01,
        pointWidth: 40,
        minPointLength: 2,
    },
};

export const dashStyleByNetwork = {
    Adwords: 'Dash',
    Facebook: 'Dash',
    Organic: 'Dash',
    Other: 'Dash',
    'Unity Ads': 'Dash',
    Vungle: 'Dash',
    'Adwords Model Estimate': 'Solid',
    'Facebook Model Estimate': 'Solid',
    'Organic Model Estimate': 'Solid',
    'Other Model Estimate': 'Solid',
    'Unity Ads Model Estimate': 'Solid',
    'Vungle Model Estimate': 'Solid',
};

export const labelByMetric = {
    mmm_estimate: 'Modeled',
    direct_attribution: 'Direct Attribution',
    cost_per_channel: 'Cost Per Channel',
};

export const colorByMetric = {
    mmm_estimate: chartColor1,
    direct_attribution: chartColor2,
    cost_per_channel: chartColor2,
};

export const colorByNetwork = {
    Facebook: chartColor1,
    'Facebook Model Estimate': chartColor1,
    Organic: chartColor6,
    'Organic Model Estimate': chartColor6,
    'Unity Ads': chartColor3,
    'Unity Ads Model Estimate': chartColor3,
    Vungle: chartColor4,
    'Vungle Model Estimate': chartColor4,
    Other: chartColor7,
    Adwords: chartColor8,
};
export const X_AXIS_LABEL_MONTH_FORMAT = 'MMM YY';
export const TITLE_LABEL_MONTH_FORMAT = 'MMMM YYYY';

export const CURRENCY = 'currency';

export const dashStyles = {
    solid: 'Solid',
    shortDash: 'ShortDash',
};
