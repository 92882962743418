import API from './api';

const api = new API();
let instance = null;

const FEATURES_LIST = 'api/self/features';

export default class FeaturesAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getFeaturesList() {
        return new Promise((resolve, reject) => {
            api.getJson(FEATURES_LIST, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
