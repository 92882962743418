import API from './api';

let instance = null;
const api = new API();
const URL_COUNTRIES = 'api/countries';

export default class ResourcesAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getCountries() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_COUNTRIES, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
