import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';
import { reportBase, cohorts } from './reportsBase';

export default {
    type: reportTypes.pivot,
    fields: [
        ...reportBase.fields,
        ...cohorts.fields,
        'governanceDimensions',
        'custom_dimensions',
        'xorgMetrics',
        'discrepancyMetrics',
        'xorgCohortMetrics',
        'goalsMetrics',
        'goalsForecastMetrics',
        'fileCombinerDimensions',
        'granularityLevels',
        'enrichmentDimensions',
        'sourceDimensions',
        'sourceAttributionType',
        'crossDeviceDemoCohortType',
        'metadataDimensions',
        'conversionEvents',
        'appMetrics',
        'webMetrics',
        'utmParamsFields',
        'socialEngagementMetrics',
    ],
    visibleRule: rules[reportTypes.pivot],
    dataSelector: {
        ...reportBase.dataSelector,
        ...cohorts.dataSelector,
        governanceDimensions: 'governanceDimensions',
        custom_dimensions: 'custom_dimensions',
        fileCombinerDimensions: 'fileCombinerDimensions',
        granularityLevels: 'granularityLevels',
        enrichmentDimensions: 'enrichmentDimensions',
        sourceDimensions: 'sourceDimensions',
        metadataDimensions: 'metadataDimensions',
        xorgMetrics: 'xorgMetrics',
        appMetrics: 'appMetrics',
        webMetrics: 'webMetrics',
        utmParamsFields: 'utmParamsFields',
        discrepancyMetrics: 'discrepancyMetrics',
        xorgCohortMetrics: 'xorgCohortMetrics',
        sourceAttributionType: 'sourceAttributionType',
        crossDeviceDemoCohortType: 'crossDeviceDemoCohortType',
        selectedTab: 'selectedTab',
        tableDataFormatted: 'reportData',
        mode: 'mode',
        selectedGoal: 'selectedGoal',
        unenriched: 'unenriched',
        admonAlignment: 'admonAlignment',
        pivotTable: 'pivotTable',
        isSlimMode: 'isSlimMode',
        openFieldsAvailability: 'openFieldsAvailability',
        fieldsAvailabilityFieldName: 'fieldsAvailabilityFieldName',
        selectedSources: 'selectedSources',
        conversionEvents: 'conversionEvents',
        socialEngagementMetrics: 'socialEngagementMetrics',
    },
};
