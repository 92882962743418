import { connect } from 'react-redux';
import { getBookmarkPopoverData } from '../../selectors/reports';
import { saveBookmark } from '../../actions/reports';
import { BookmarkPopover } from '../../components/reportsWidgets';

let bookmarkDataSelector;

const mapStateToProps = (state, ownProps) => {
    bookmarkDataSelector = getBookmarkPopoverData(ownProps.reportType);

    return bookmarkDataSelector(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSaveBookmark: (data, resolve) => {
            dispatch(saveBookmark(ownProps.reportType, data, resolve));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkPopover);
