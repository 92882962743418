import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import Tooltip from './Tooltip';
import css from './DataTypeTooltip.css';
import CheckMark from '../../resources/icons/check_mark.svg';

function DataTypeTooltip({ field, dataTypeFields, wrapperClass, children }) {
    const getDynamicTooltipStyle = dataType => css[field.dynamic_data_type_tooltip[dataType]?.style];
    const getDynamicTooltipTitle = dataType => field.dynamic_data_type_tooltip[dataType]?.tooltip;

    const isDataTypeSelected = useCallback(dataType => dataTypeFields.includes(dataType), [dataTypeFields]);

    return (
        <Tooltip
            title={Object.keys(field.dynamic_data_type_tooltip).map(dataType => (
                <div className={css.dynamicTooltipHolder} key={getDynamicTooltipTitle(dataType)}>
                    <CheckMark
                        className={classNames(css.dynamicCheckMark, {
                            [css.visible]: isDataTypeSelected(dataType),
                            [getDynamicTooltipStyle(dataType)]: isDataTypeSelected(dataType),
                        })}
                    />
                    <span
                        className={classNames({
                            [css.tooltipTitleSelected]: isDataTypeSelected(dataType),
                            [getDynamicTooltipStyle(dataType)]: isDataTypeSelected(dataType),
                        })}
                    >
                        {`${getDynamicTooltipTitle(dataType)}`}
                    </span>
                </div>
            ))}
            wrapperClass={wrapperClass}
            contentClass={css.tooltipContent}
        >
            {children}
        </Tooltip>
    );
}

DataTypeTooltip.propTypes = {
    field: PropTypes.any,
    dataTypeFields: PropTypes.arrayOf(PropTypes.string),
    wrapperClass: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

DataTypeTooltip.defaultProps = {
    field: {},
    dataTypeFields: [],
    wrapperClass: undefined,
};

export default withLocalize(DataTypeTooltip);
