import { call, put, select, all } from 'redux-saga/effects';
import Logger from '../services/logger';
import AppsAPI from '../services/apps';
import { load, pageDestroy } from './actions';

import { updateAttributionAppsData } from '../actions/apps';

const logger = new Logger('[assistedInstalls:saga]');

const appsApi = new AppsAPI();

function* onload() {
    try {
        yield put(load(true));

        const apps = yield select(state => state.apps);
        let appsResponse;
        if (!apps.attributionAppsData.result?.length) {
            [appsResponse] = yield all([call(appsApi.getAttributionApps, false, true)]);
        }

        yield put(load(false));

        if (appsResponse && appsResponse.apps && appsResponse.apps.length) {
            yield put(updateAttributionAppsData(appsResponse));
        }
    } catch (e) {
        logger.debug('Error while loading the page', e);
    }
}

function* destroy() {
    yield put(pageDestroy());
}

export { onload, destroy };
