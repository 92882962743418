import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import css from './SkanAppsList.css';
import AppsList from '../../components/partials/VerticalList/AppsList';
import { sortAlphabetically } from '../../utils/sortUtil';

function SkanAppsList({ skanApps, appsFilter, onAppSelected, sortApps }) {
    if (!skanApps.length && !appsFilter) {
        return null;
    }

    return (
        <AppsList
            className={css.sector}
            apps={skanApps}
            appsFilter={appsFilter}
            onAppSelected={onAppSelected}
            sortApps={sortApps}
            disableAnimation
            showLastUpdate={false}
        />
    );
}

SkanAppsList.propTypes = {
    skanApps: PropTypes.arrayOf(PropTypes.any),
    appsFilter: PropTypes.string,
    onAppSelected: PropTypes.func,
    sortApps: PropTypes.func,
};

SkanAppsList.defaultProps = {
    skanApps: [],
    appsFilter: '',
    onAppSelected: () => {},
    sortApps: apps => apps.sort((a, b) => sortAlphabetically(a.name, b.name, false, false)),
};

export default withLocalize(SkanAppsList);
