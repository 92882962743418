import PropTypes from 'prop-types';
import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import posed, { PoseGroup } from 'react-pose';
import PlusCircleIcon from 'resources/svg/add.svg';
import XIcon from 'resources/svg/icon_remove_row.svg';
import FilterFields from 'components/partials/FilterFields';
import EditableTitle from 'components/widgets/EditableTitle';
import HoverButtonGroup from 'components/widgets/HoverButtonGroup';
import css from 'customDimensions/components/CustomDimension.css';
import { invalidValue } from '../consts';
import TrashIcon from '../../resources/svg/trash.svg';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 300,
        },
        delay: 50,
    },
    exit: {
        opacity: 0,
        y: -50,
        transition: {
            duration: 0,
        },
    },
});

const tagToValue = (dimensions, dimension, tag) => {
    const dimensionObj = dimensions.find(({ name }) => name === dimension);
    if (dimensionObj.values) {
        const find = dimensionObj.values.find(({ display_name }) => display_name === tag);
        if (!find) {
            return tag.replace(` ${invalidValue}`, '');
        }
        return find.name;
    }
    return tag;
};

const valueToTag = (dimensions, dimension, value) => {
    const dimensionObj = dimensions.find(({ name }) => name === dimension);
    if (dimensionObj?.values) {
        const find = dimensionObj.values.find(({ name }) => name === value);
        if (!find) {
            return `${value} ${invalidValue}`;
        }
        return find.display_name;
    }
    return value;
};

class CustomDimensionValue extends React.Component {
    constructor(props) {
        super(props);
        this.renderRule = this.renderRule.bind(this);
        this.setHovered = this._setHovered.bind(this);
        this.unsetHovered = this._unsetHovered.bind(this);
        this.state = {};
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { value, rule } = this.props;
        const { deleteButtonHovered, level0Hovered, addRuleContainerHovered } = this.state;
        return (
            nextProps.value !== value ||
            (nextProps.rule && nextProps.rule.hash !== rule.hash) ||
            nextState.deleteButtonHovered !== deleteButtonHovered ||
            nextState.level0Hovered !== level0Hovered ||
            nextState.addRuleContainerHovered !== addRuleContainerHovered
        );
    }

    _setHovered(id, type) {
        this.setState({
            [type]: id,
        });
    }

    _unsetHovered(id, type) {
        const { ...state } = this.state;
        if (state[type] === id) {
            this.setState({
                [type]: null,
            });
        }
    }

    renderRule({ id, level, inner, parentId }, indexInBrotherList, numberOfBrothers) {
        let innerRendered = null;
        const {
            availableDimensions: dimensions,
            availableOperators: operators,
            onConditionAdded,
            onConditionRemoved,
            onConditionTagAdded,
            onConditionTagRemoved,
            onConditionValueChanged,
            onConditionDimensionChanged,
            onConditionOperatorChanged,
            onRuleRemoved,
            onRuleAdded,
            saveStatus,
        } = this.props;
        const { deleteButtonHovered, level0Hovered, addRuleContainerHovered } = this.state;
        if (level === 0) {
            innerRendered = [
                <div className={css.level0rule} key={`${id}_0`}>
                    <div
                        className={`${css.dot} ${css.rule0dotFirst} ${(deleteButtonHovered === parentId ||
                            deleteButtonHovered === id) &&
                            css.markBeforeDeletion}`}
                        style={{ opacity: indexInBrotherList === 0 ? 1 : 0 }}
                    />
                    <FilterFields
                        key={`inner_${id}`}
                        fields={dimensions}
                        operators={operators}
                        filters={inner.map(({ dimension, operator, values, id: key }) => ({
                            operator:
                                operator === 'default'
                                    ? {
                                          name: 'default',
                                          display_name: 'Select an operator',
                                      }
                                    : operators.find(({ name }) => name === operator),
                            tags: values.map(val => {
                                const tag = valueToTag(dimensions, dimension, val);
                                return { display_name: tag, name: tag };
                            }),
                            field:
                                operator === 'default'
                                    ? {
                                          name: 'default',
                                          display_name: 'Select a dimension',
                                      }
                                    : dimensions.find(({ name }) => name === dimension),
                            key,
                        }))}
                        onAddFilter={() => {
                            onConditionAdded(id);
                        }}
                        onRemoveFilter={index => {
                            onConditionRemoved(inner[index].id);
                        }}
                        onFilterValueAdded={(tag, index) => {
                            onConditionTagAdded(inner[index].id, tagToValue(dimensions, inner[index].dimension, tag));
                        }}
                        onFilterValueRemoved={(tag, index) => {
                            onConditionTagRemoved(inner[index].id, tagToValue(dimensions, inner[index].dimension, tag));
                        }}
                        onFilterValueChanged={(value, index) => {
                            onConditionValueChanged(inner[index].id, value);
                        }}
                        onFieldSelection={(value, index) => {
                            onConditionDimensionChanged(inner[index].id, value.name);
                        }}
                        onOperatorSelection={(value, index) => {
                            onConditionOperatorChanged(inner[index].id, value.name);
                        }}
                        plusButtonText="or"
                        errorOnEmpty={saveStatus === 'displayErrors'}
                        forceHoverStateOnLast={
                            level0Hovered === id ||
                            (!level0Hovered &&
                                addRuleContainerHovered === parentId &&
                                indexInBrotherList === numberOfBrothers - 1)
                        }
                        allowPasteTagInputs
                    />
                    <div
                        className={`${css.dot} ${css.rule0dotLast} ${(deleteButtonHovered === parentId ||
                            deleteButtonHovered === id) &&
                            css.markBeforeDeletion}`}
                        style={{
                            opacity: indexInBrotherList === numberOfBrothers - 1 || deleteButtonHovered === id ? 1 : 0,
                        }}
                    />
                </div>,
            ];
        } else {
            innerRendered = <PoseGroup>{inner.map((x, index) => this.renderRule(x, index, inner.length))}</PoseGroup>;
        }

        return (
            <AnimationItem
                key={id}
                style={{ position: 'relative' }}
                className={`${level === 1 ? css.level1rule : ''} ${level === 0 ? css.level0ruleContainer : ''}`}
                onMouseEnter={() => level === 0 && this.setHovered(id, 'level0Hovered')}
                onMouseLeave={() => level === 0 && this.unsetHovered(id, 'level0Hovered')}
            >
                <div
                    className={`${css.conditionBox} ${level === 1 && css.orConditionBox}`}
                    style={{ display: indexInBrotherList > 0 ? 'flex' : 'none' }}
                >
                    <div
                        className={`${css.dot} ${level === 0 && css.rule1dot} ${level === 1 && css.rule2dot}
                        ${level === 0 &&
                            (deleteButtonHovered === parentId || deleteButtonHovered === id) &&
                            css.markBeforeDeletion}`}
                    />
                    {level === 0 ? (
                        <span
                            style={{
                                marginLeft: 17,
                                fontWeight: 500,
                                fontSize: 15,
                                marginTop: 1,
                                flex: 1,
                            }}
                        >
                            AND
                        </span>
                    ) : (
                        <div style={{ flex: 1 }}>
                            <span style={{ marginLeft: 108, marginRight: 12, fontWeight: 'bold' }}>OR</span>
                            <span style={{ fontSize: 14 }}>
                                <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.USE_VALUE_WHEN" />
                            </span>
                        </div>
                    )}
                    <span className={`${css.deleteText} ${deleteButtonHovered === id && css.visible}`}>
                        <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_HOVER_TEXT" />
                    </span>
                    <XIcon
                        className={css.xButton}
                        onClick={() => {
                            this.unsetHovered(id, 'deleteButtonHovered');
                            onRuleRemoved(id);
                        }}
                        onMouseEnter={() => this.setHovered(id, 'deleteButtonHovered')}
                        onMouseLeave={() => this.unsetHovered(id, 'deleteButtonHovered')}
                    />
                </div>
                <div
                    className={`${css.blackLine} ${indexInBrotherList === 0 ? css.blackLineFirstElement : ''}
                                 ${
                                     deleteButtonHovered === id || deleteButtonHovered === parentId
                                         ? css.markBeforeDeletion
                                         : ''
                                 }`}
                    style={{ display: level === 0 ? '' : 'none' }}
                />
                <div
                    className={`${css.blackLine} ${
                        indexInBrotherList < numberOfBrothers - 1 ? css.blackLineConnectToNextElement : ''
                    }
                                 ${deleteButtonHovered === parentId ? css.markBeforeDeletion : ''}`}
                    style={{ display: level === 0 && indexInBrotherList < numberOfBrothers - 1 ? '' : 'none' }}
                />
                <div
                    className={css.addRuleContainer}
                    style={{ display: level !== 1 && 'none', opacity: deleteButtonHovered === id && 0 }}
                    onMouseEnter={() => this.setHovered(id, 'addRuleContainerHovered')}
                    onMouseLeave={() => this.unsetHovered(id, 'addRuleContainerHovered')}
                >
                    <div className={`${css.dashedLine}`} />
                    <HoverButtonGroup
                        className={css.addRuleButtonContainer}
                        icon={<PlusCircleIcon className={css.andOrIcon} />}
                        options={
                            indexInBrotherList === numberOfBrothers - 1
                                ? [
                                      { text: 'AND', color: '#B26AF1' },
                                      { text: 'OR', color: '#00D0B2' },
                                  ]
                                : [{ text: 'AND', color: '#B26AF1' }]
                        }
                        onClick={index => {
                            if (index === 0) onRuleAdded(id);
                            else onRuleAdded(parentId);
                        }}
                    />
                </div>
                {innerRendered}
            </AnimationItem>
        );
    }

    render() {
        const { id, value, hasSubHeaderMessage, saveStatus, onValueChanged, onValueRemoved, rule, tab } = this.props;
        const { deleteButtonHovered } = this.state;
        if (!value && !rule) {
            return <div />;
        }
        return (
            <AnimationItem key={id}>
                <div key={id} style={{ backgroundColor: '#f8f8f8', marginBottom: 10 }}>
                    <div
                        className={`${css.customDimensionValue} ${css[tab]}`}
                        style={{ top: 78 + (hasSubHeaderMessage ? 35 : 0) }}
                    >
                        <div style={{ display: 'flex', flex: 1, alignItems: 'baseline' }}>
                            <span style={{ fontSize: 18, marginRight: 8 }}>
                                <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.VALUE_NAME" />
                            </span>
                            <div style={{ width: 313 }}>
                                <EditableTitle
                                    placeholder="Add a value name"
                                    value={value}
                                    onTextChanged={valueName => {
                                        onValueChanged(id, valueName);
                                    }}
                                    containerStyle={{ marginTop: 1 }}
                                    error={
                                        saveStatus === 'displayErrors' && value.length === 0 ? 'must not be empty' : ''
                                    }
                                />
                            </div>
                        </div>
                        <span className={`${css.deleteText} ${deleteButtonHovered === id && css.visible}`}>
                            <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.VALUE_DELETE_HOVER_TEXT" />
                        </span>
                        <TrashIcon
                            onClick={() => {
                                onValueRemoved(id);
                            }}
                            className={css.trashIcon}
                            onMouseEnter={() => this.setHovered(id, 'deleteButtonHovered')}
                            onMouseLeave={() => this.unsetHovered(id, 'deleteButtonHovered')}
                        />
                    </div>
                    <div style={{ marginLeft: 20, paddingTop: 26, paddingBottom: 20 }}>
                        <span style={{ fontSize: 16, marginLeft: 70 }}>
                            <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.USE_VALUE_WHEN" />
                        </span>
                        <div style={{ marginTop: 25 }}>{this.renderRule(rule, 0, 1)}</div>
                    </div>
                </div>
            </AnimationItem>
        );
    }
}

CustomDimensionValue.propTypes = {
    id: PropTypes.string,
    rule: PropTypes.object,
    tab: PropTypes.string,
    hasSubHeaderMessage: PropTypes.bool,
    saveStatus: PropTypes.string,
    value: PropTypes.string,
    availableDimensions: PropTypes.arrayOf(PropTypes.object),
    availableOperators: PropTypes.arrayOf(PropTypes.object),
    onConditionAdded: PropTypes.func,
    onConditionRemoved: PropTypes.func,
    onConditionTagAdded: PropTypes.func,
    onConditionTagRemoved: PropTypes.func,
    onConditionValueChanged: PropTypes.func,
    onConditionDimensionChanged: PropTypes.func,
    onConditionOperatorChanged: PropTypes.func,
    onRuleAdded: PropTypes.func,
    onRuleRemoved: PropTypes.func,
    onValueRemoved: PropTypes.func,
    onValueChanged: PropTypes.func,
};

CustomDimensionValue.defaultProps = {
    // Add your props defaults here
    id: '',
    value: '',
    tab: '',
    rule: {},
    hasSubHeaderMessage: false,
    saveStatus: false,
    availableDimensions: [],
    availableOperators: [],
    onConditionAdded: () => null,
    onConditionRemoved: () => null,
    onConditionTagAdded: () => null,
    onConditionTagRemoved: () => null,
    onConditionValueChanged: () => null,
    onConditionDimensionChanged: () => null,
    onConditionOperatorChanged: () => null,
    onRuleAdded: () => null,
    onRuleRemoved: () => null,
    onValueRemoved: () => null,
    onValueChanged: () => null,
};

export default withLocalize(CustomDimensionValue);
