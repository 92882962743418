import React from 'react';
import { useSelector } from 'react-redux';
import { PageHeader, PageWrapper } from '../components/partials';
import { getIsAgency, isUserAdNetwork } from '../selectors/user';
import { getEmptyStateOrgData } from '../selectors/onboarding';
import { getPartnerConfigurationEmptyStateData, shouldShowEmptyState } from './emptyStateUtils';
import PartnerConfigurationEmptyStateImage from '../resources/png/partner_configuration_empty_state.png';
import { Spinner } from '../components/widgets';
import Content from './components/Content';
import { FAQ_LINKS } from './consts';

export default function PartnerConfiguration() {
    const emptyStateOrgData = useSelector(state => getEmptyStateOrgData(state));
    const isAgency = useSelector(state => getIsAgency(state));
    const isPartner = useSelector(state => isUserAdNetwork(state));
    if (!emptyStateOrgData) {
        return <Spinner show expanded />;
    }
    const emptyState = getPartnerConfigurationEmptyStateData(emptyStateOrgData);
    const showTasksEmptyState = shouldShowEmptyState(emptyStateOrgData);

    // Agencies are not responsible for adding and configuring apps, therefore we're skipping this empty state for them
    return showTasksEmptyState && !isAgency && !isPartner ? (
        <PageWrapper>
            <PageHeader
                text="STATIC.PAGE_HEADERS.PARTNER_CONFIGURATION"
                subText={FAQ_LINKS}
                emptyStateOrgData={emptyStateOrgData}
                emptyStateHeaderData={emptyState}
                showEmptyState={showTasksEmptyState}
                showEmptyStateCloseIcon={false}
                imageSrc={emptyState.imageSrc}
            />
            <img src={PartnerConfigurationEmptyStateImage} alt="emptyState" />
        </PageWrapper>
    ) : (
        <Content />
    );
}
