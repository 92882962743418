import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon as IconifyIcon } from '@iconify/react';
import css from './Icon.css';

export const IconTypes = {
    TABLER: 'tabler',
    MINGCUTE: 'mingcute',
};

export const IconVariants = {
    DARK: 'dark',
    LIGHT: 'light',
    WHITE: 'white',
    ACTIVE: 'active',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
};

export const IconSizes = {
    X_SMALL: 'x-small',
    SMALL: 'small',
    LARGE: 'large',
};

const Icon = ({ name, type, size, variant, disabled, className, onClick, interactive, ...props }) => {
    const fullIconName = `${type}:${name}`;

    const classes = classNames(css.singularIcon, css[size], css[variant], className, {
        [css.disabled]: disabled,
        [css.interactive]: interactive,
    });

    return <IconifyIcon icon={fullIconName} className={classes} onClick={onClick} {...props} />;
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(IconTypes)),
    size: PropTypes.oneOf(Object.values(IconSizes)),
    variant: PropTypes.oneOf(Object.values(IconVariants)),
    disabled: PropTypes.bool,
    interactive: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Icon.defaultProps = {
    type: IconTypes.TABLER,
    size: IconSizes.LARGE,
    variant: IconVariants.DARK,
    disabled: false,
    interactive: true,
    className: '',
};

export default Icon;
