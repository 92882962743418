import React, { useState } from 'react';
import { PageWrapper } from '../components/partials';
import { SDK_PLANNING } from './consts';
import SDKPlanning from './sdkPlanning/SDKPlanning';
import SDKKeys from './sdkKeys/SDKKeys';
import SDKPageHeader from './SDKPageHeader';
import css from './SDKPage.css';
import { SDKPageProvider } from './context/useSDKPageContext';
import { SDKPlanningProvider } from './sdkPlanning/context/useSDKPlanningContext';

const SDKPage = () => {
    const [selectedPage, setSelectedPage] = useState(SDK_PLANNING);

    return (
        <PageWrapper disablePadding className={css.container}>
            <SDKPageProvider>
                <SDKPlanningProvider>
                    <SDKPageHeader setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
                    {selectedPage === SDK_PLANNING ? <SDKPlanning /> : <SDKKeys />}
                </SDKPlanningProvider>
            </SDKPageProvider>
        </PageWrapper>
    );
};

export default SDKPage;
