import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { SingularButton } from 'components/widgets';
import { GeneralPopup, PopupTypes } from 'components/partials';
import { useResetAPIKey } from '../hooks';

import css from './ResetAPIButton.pcss';

function ResetAPIButton({ translate, onAPIKeyChange }) {
    const [shouldResetAPIKey, setShouldResetAPIKey] = useState(false);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    // this state is to differentiate between the useRequest variables default value
    // (e.g. isLoading is set to true before any call is made)
    const [firstTrigger, setFirstTrigger] = useState(false);

    const onResetAPIKeyCleanup = () => {
        setShouldResetAPIKey(false);
    };

    const { isLoading, value } = useResetAPIKey(shouldResetAPIKey, onResetAPIKeyCleanup);

    useEffect(() => {
        if (value && value.length) {
            onAPIKeyChange(value);
        }
    }, [value]);

    const onResetAPIKey = () => {
        setFirstTrigger(true);
        setShouldResetAPIKey(true);
        setShowWarningMessage(false);
    };

    return (
        <React.Fragment>
            <GeneralPopup
                open={showWarningMessage}
                text={translate('STATIC.PAGES.API.REPORTING_API_KEY.RESET_KEY_API.RESET_KEY_WARNING_POPUP')}
                acceptText={translate('STATIC.PAGES.API.REPORTING_API_KEY.RESET_KEY_API.RESET_KEY')}
                onAccept={() => onResetAPIKey()}
                onReject={() => setShowWarningMessage(false)}
                type={PopupTypes.INFO_WITH_CANCEL}
            />
            <SingularButton
                className={css.resetButton}
                level="level2"
                onClick={() => setShowWarningMessage(true)}
                showV={false}
                type="flat"
                showSpinner={firstTrigger && isLoading}
            >
                {translate('STATIC.PAGES.API.REPORTING_API_KEY.RESET_KEY_API.RESET_KEY')}
            </SingularButton>
        </React.Fragment>
    );
}

ResetAPIButton.propTypes = {
    translate: PropTypes.func,
    onAPIKeyChange: PropTypes.func.isRequired,
};

ResetAPIButton.defaultProps = {
    translate: () => {},
};

export default withLocalize(ResetAPIButton);
