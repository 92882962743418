import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Form } from 'formik-2';
import css from './WizardLayout.css';

const prefixTranslate = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD';

const WizardLayout = ({ buttons, title, children, onSaveDraft, shouldShowDraft, isContentScrollable }) => {
    return (
        <Form>
            <div className={css.headerContainer}>
                <div className={css.pageTitle}>
                    <Translate id={title} />
                </div>
                {shouldShowDraft && (
                    <div onClick={onSaveDraft} className={css.saveDraftTitle}>
                        <Translate id={`${prefixTranslate}.BUTTONS.SAVE_DRAFT`} />
                    </div>
                )}
            </div>
            <div className={css.contentLayout}>
                <div className={`${css.leftContainer} ${isContentScrollable ? css.contentScrollable : ''}`}>
                    {children}
                </div>
                <div className={css.rightContainer}>{buttons}</div>
            </div>
        </Form>
    );
};

WizardLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    buttons: PropTypes.element,
    onSaveDraft: PropTypes.func,
    shouldShowDraft: PropTypes.bool,
    isContentScrollable: PropTypes.bool,
};

WizardLayout.defaultProps = {
    shouldShowDraft: true,
    isContentScrollable: true,
};

export default WizardLayout;
