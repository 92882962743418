import update from 'immutability-helper';
import {
    CUSTOMDIMENSION_CONDITION_ADDED,
    CUSTOMDIMENSION_CONDITION_DIMENSION_CHANGED,
    CUSTOMDIMENSION_CONDITION_OPERATOR_CHANGED,
    CUSTOMDIMENSION_CONDITION_REMOVED,
    CUSTOMDIMENSION_CONDITION_TAG_ADDED,
    CUSTOMDIMENSION_CONDITION_TAG_REMOVED,
    CUSTOMDIMENSION_CONDITION_VALUE_CHANGED,
    CUSTOM_DIMENSIONS_LOADED,
    CUSTOMDIMENSION_DEFAULT_VALUE_CHANGED,
    CUSTOMDIMENSION_DIMENSION_ADDED,
    CUSTOMDIMENSION_DIMENSION_EXPANSION_TOGGLE,
    CUSTOMDIMENSION_DIMENSION_NAME_CHANGED,
    CUSTOMDIMENSION_DIMENSION_REMOVE_ATTEMPTED,
    CUSTOMDIMENSION_DIMENSION_REMOVE_REJECTED,
    CUSTOMDIMENSION_DIMENSION_REMOVED,
    CUSTOMDIMENSION_DIMENSION_SAVE_DONE,
    CUSTOMDIMENSION_DIMENSION_SAVE_ERROR,
    CUSTOMDIMENSION_DIMENSION_SAVE_START,
    CUSTOMDIMENSION_DIMENSION_SAVE_STATUS_RESET,
    CUSTOMDIMENSION_DIMENSION_UNDO_CHANGES,
    CUSTOMDIMENSION_DIMENSIONS_LOADED,
    CUSTOMDIMENSION_FILTER_CHANGED,
    CUSTOMDIMENSION_OPERATORS_LOADED,
    CUSTOMDIMENSION_RULE_ADDED,
    CUSTOMDIMENSION_RULE_REMOVED,
    CUSTOMDIMENSION_VALUE_ADDED,
    CUSTOMDIMENSION_VALUE_NAME_CHANGED,
    CUSTOMDIMENSION_VALUES_REORDERED,
    CUSTOMDIMENSION_VALUE_REMOVED,
    CUSTOMDIMENSIONS_PAGE_LOADED,
    CUSTOMDIMENSIONS_PAGE_UNLOAD,
    CUSTOMDIMENSION_TAB_SWITCH,
    CUSTOMDIMENSION_DIMENSIONS_RELATED_LOADED,
} from 'customDimensions/actions';
import { generateGuidShort } from 'global/utils';
import { REPORTS_TAB } from 'customDimensions/selectors';

const initialState = {
    availableDimensions: [],
    supportedMacroDimensions: [],
    availableOperators: [],
    dimensionsBackup: {},
    dimensionsList: [],
    dimensionFilter: '',
    dimensionsMap: {},
    valuesMap: {},
    rulesMap: {},
    conditionsMap: {},
    maxDimensions: 0,
    maxConditionsPerDimension: 0,
    deleteDialogOn: null,
    v3Dimensions: [],
    tabsToShow: [],
    tab: REPORTS_TAB,
};

function addCondition(state, action) {
    const conditionId = generateGuidShort();
    return update(state, {
        conditionsMap: {
            [conditionId]: {
                $set: {
                    dimension: 'default',
                    operator: 'default',
                    values: [],
                    parentId: action.parentId,
                    id: conditionId,
                },
            },
        },
        rulesMap: {
            [action.parentId]: {
                inner: {
                    $push: [conditionId],
                },
            },
        },
    });
}

function removeCondition(state, action) {
    const condition = state.conditionsMap[action.id];
    return update(state, {
        conditionsMap: {
            [action.id]: {
                $set: null,
            },
        },
        rulesMap: {
            [condition.parentId]: {
                inner: {
                    $splice: [[state.rulesMap[condition.parentId].inner.indexOf(action.id), 1]],
                },
            },
        },
    });
}

function addRule(state, action) {
    let parent = null;
    if (action.parentId) {
        parent = state.rulesMap[action.parentId];
    } else {
        parent = { level: 3, op: 'AND' };
    }
    const ruleId = generateGuidShort();
    let newState = update(state, {
        rulesMap: {
            [ruleId]: {
                $set: {
                    level: parent.level - 1,
                    op: parent.op === 'AND' ? 'OR' : 'AND',
                    inner: [],
                    id: ruleId,
                },
            },
        },
    });
    if (action.parentId) {
        newState = update(newState, {
            rulesMap: {
                [ruleId]: {
                    parentId: {
                        $set: action.parentId,
                    },
                },
                [action.parentId]: {
                    inner: {
                        $push: [ruleId],
                    },
                },
            },
        });
    } else {
        newState = update(newState, {
            valuesMap: {
                [action.valueId]: {
                    rule: {
                        $set: ruleId,
                    },
                },
            },
        });
    }
    if (parent.level - 1 > 0) {
        newState = addRule(newState, { parentId: ruleId });
    } else {
        newState = addCondition(newState, { parentId: ruleId });
    }
    return newState;
}

function removeRule(state, action) {
    const rule = state.rulesMap[action.id];
    let newState = state;
    let removeFunc = removeCondition;
    if (rule.level > 0) {
        removeFunc = removeRule;
    }
    for (let i = 0; i < rule.inner.length; i++) {
        newState = removeFunc(newState, { parentId: action.id, id: rule.inner[i] });
    }
    newState = update(newState, {
        rulesMap: {
            [action.id]: {
                $set: null,
            },
        },
    });
    if (rule.parentId) {
        newState = update(newState, {
            rulesMap: {
                [rule.parentId]: {
                    inner: {
                        $splice: [[state.rulesMap[rule.parentId].inner.indexOf(action.id), 1]],
                    },
                },
            },
        });
    }
    return newState;
}

function addValue(state, action) {
    const valueId = generateGuidShort();
    let maxValueNumber = 0;
    for (let i = 0; i < state.dimensionsMap[action.dimensionId].values.length; i++) {
        const res = /^New Value \((\d+)\)$/.exec(
            state.valuesMap[state.dimensionsMap[action.dimensionId].values[i]].value
        );
        if (res !== null) {
            maxValueNumber = Math.max(maxValueNumber, parseInt(res[1], 10));
        }
    }
    const newState = update(state, {
        valuesMap: {
            [valueId]: {
                $set: {
                    value: `New Value (${String(maxValueNumber + 1)})`,
                    id: valueId,
                    dimensionId: action.dimensionId,
                },
            },
        },
        dimensionsMap: {
            [action.dimensionId]: {
                values: {
                    $push: [valueId],
                },
            },
        },
    });
    return addRule(newState, { valueId });
}

function removeValue(state, action) {
    const value = state.valuesMap[action.id];
    const newState = removeRule(state, { id: value.rule });
    return update(newState, {
        valuesMap: {
            [action.id]: {
                $set: null,
            },
        },
        dimensionsMap: {
            [value.dimensionId]: {
                values: {
                    $splice: [[state.dimensionsMap[value.dimensionId].values.indexOf(action.id), 1]],
                },
            },
        },
    });
}

function deserializeDimension(state, id) {
    const backup = state.dimensionsBackup[id];
    const maps = Object.keys(backup);
    let newState = state;
    for (let i = 0; i < maps.length; i++) {
        const keys = Object.keys(backup[maps[i]] || {});
        for (let j = 0; j < keys.length; j++) {
            newState = update(newState, {
                [maps[i]]: {
                    [keys[j]]: {
                        $set: backup[maps[i]][keys[j]],
                    },
                },
            });
        }
    }
    return newState;
}

function removeDimension(state, action) {
    let newState = state;
    for (let i = 0; i < state.dimensionsMap[action.id]?.values?.length || 0; i++) {
        newState = removeValue(newState, { id: state.dimensionsMap[action.id].values[i] });
    }
    return update(newState, {
        dimensionsMap: {
            [action.id]: {
                $set: null,
            },
        },
        dimensionsList: {
            $splice: [[state.dimensionsList.indexOf(action.id), 1]],
        },
    });
}

function revertDimension(state, id) {
    let newState = removeDimension(state, { id });
    if (state.dimensionsBackup[id]) {
        newState = update(deserializeDimension(newState, id), {
            dimensionsList: {
                $set: state.dimensionsList,
            },
        });
        newState = update(newState, {
            dimensionsMap: {
                [id]: {
                    expanded: {
                        $set: false,
                    },
                },
            },
        });
    }
    return newState;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMDIMENSIONS_PAGE_LOADED: {
            return update(state, {
                tab: { $set: REPORTS_TAB },
            });
        }
        case CUSTOMDIMENSIONS_PAGE_UNLOAD: {
            return initialState;
        }
        case CUSTOMDIMENSION_CONDITION_ADDED: {
            return addCondition(state, action);
        }
        case CUSTOMDIMENSION_CONDITION_REMOVED: {
            let toDelete = null;
            let parent = state.rulesMap[state.conditionsMap[action.id].parentId];
            while (parent.inner.length === 1 && parent.parentId) {
                toDelete = parent.id;
                parent = state.rulesMap[parent.parentId];
            }
            let newState = null;
            if (toDelete) {
                newState = removeRule(state, { id: toDelete });
                if (parent.inner.length === 1) {
                    return addRule(newState, { parentId: parent.id });
                }
            } else {
                newState = removeCondition(state, action);
            }
            return newState;
        }
        case CUSTOMDIMENSION_CONDITION_TAG_ADDED: {
            return update(state, {
                conditionsMap: {
                    [action.id]: {
                        values: {
                            $push: [action.value],
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_CONDITION_TAG_REMOVED: {
            return update(state, {
                conditionsMap: {
                    [action.id]: {
                        values: {
                            $splice: [[state.conditionsMap[action.id].values.indexOf(action.value), 1]],
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_CONDITION_VALUE_CHANGED: {
            return update(state, {
                conditionsMap: {
                    [action.id]: {
                        values: {
                            $set: [action.value],
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_CONDITION_DIMENSION_CHANGED: {
            return update(state, {
                conditionsMap: {
                    [action.id]: {
                        dimension: {
                            $set: action.value,
                        },
                        operator: {
                            $set: state.availableDimensions.find(dimension => {
                                return dimension.name === action.value;
                            }).operators[0],
                        },
                        values: {
                            $set: [],
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_CONDITION_OPERATOR_CHANGED: {
            return update(state, {
                conditionsMap: {
                    [action.id]: {
                        operator: {
                            $set: action.value,
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSIONS_LOADED: {
            return update(state, {
                availableDimensions: {
                    $set: action.dimensions,
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSIONS_RELATED_LOADED: {
            return update(state, {
                customDimensionsRelated: {
                    $set: action.dimensionsRelated,
                },
            });
        }
        case CUSTOMDIMENSION_OPERATORS_LOADED: {
            return update(state, {
                availableOperators: {
                    $set: action.operators.map(({ id, display_name, type }) => {
                        return {
                            id,
                            display_name,
                            type,
                            name: id,
                        };
                    }),
                },
            });
        }
        case CUSTOMDIMENSION_TAB_SWITCH: {
            Object.keys(state.dimensionsMap).forEach(d => {
                if (state.dimensionsMap[d] !== null) {
                    state = revertDimension(state, d);
                }
            });
            return update(state, {
                tab: { $set: action.id },
            });
        }
        case CUSTOM_DIMENSIONS_LOADED: {
            let newState = update(state, {
                maxDimensions: {
                    $set: action.maxDimensions,
                },
                maxConditionsPerDimension: {
                    $set: action.maxConditionsPerDimension,
                },
                supportedMacroDimensions: {
                    $set: action.supportedMacroDimensions,
                },
                v3Dimensions: {
                    $set: action.v3Dimensions,
                },
                tabsToShow: {
                    $set: action.tabsToShow,
                },
            });
            for (let i = 0; i < action.dimensions.length; i++) {
                const dimensionConfig = action.dimensions[i];
                newState = update(newState, {
                    dimensionsBackup: {
                        [dimensionConfig.uuid]: {
                            $set: {
                                dimensionsMap: {
                                    [dimensionConfig.uuid]: {
                                        name: dimensionConfig.name,
                                        values: dimensionConfig.config.valuesList,
                                        defaultValue: dimensionConfig.config.defaultValue,
                                        availableInTab: dimensionConfig.tab,
                                        expanded: false,
                                        saveStatus: '',
                                    },
                                },
                                valuesMap: dimensionConfig.config.valuesMap,
                                rulesMap: dimensionConfig.config.rulesMap,
                                conditionsMap: dimensionConfig.config.conditionsMap,
                            },
                        },
                    },
                });
                newState = deserializeDimension(newState, dimensionConfig.uuid);
                newState = update(newState, {
                    dimensionsList: {
                        $push: [dimensionConfig.uuid],
                    },
                });
            }
            return newState;
        }
        case CUSTOMDIMENSION_RULE_ADDED: {
            return addRule(state, action);
        }
        case CUSTOMDIMENSION_RULE_REMOVED: {
            let parent = state.rulesMap[state.rulesMap[action.id].parentId];
            let toDelete = action.id;
            while (parent.inner.length === 1 && parent.parentId) {
                toDelete = parent.id;
                parent = state.rulesMap[parent.parentId];
            }
            const newState = removeRule(state, { id: toDelete });
            if (parent.inner.length === 1) {
                return addRule(newState, { parentId: parent.id });
            }
            return newState;
        }
        case CUSTOMDIMENSION_VALUE_ADDED: {
            return addValue(state, action);
        }
        case CUSTOMDIMENSION_VALUE_REMOVED: {
            const value = state.valuesMap[action.id];
            const dimensionValues = state.dimensionsMap[value.dimensionId].values;
            const newState = removeValue(state, action);

            if (dimensionValues.length === 1) {
                return addValue(newState, { dimensionId: value.dimensionId });
            }
            return newState;
        }
        case CUSTOMDIMENSION_VALUE_NAME_CHANGED: {
            return update(state, {
                valuesMap: {
                    [action.id]: {
                        value: {
                            $set: action.value,
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_VALUES_REORDERED: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        values: {
                            $set: action.values,
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_EXPANSION_TOGGLE: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        expanded: {
                            $set: !state.dimensionsMap[action.id].expanded,
                        },
                        saveStatus: {
                            $set: '',
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_NAME_CHANGED: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        name: {
                            $set: action.value,
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DEFAULT_VALUE_CHANGED: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        defaultValue: {
                            $set: action.value,
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_ADDED: {
            const id = generateGuidShort();
            let maxDimensionNumber = 0;
            for (let i = 0; i < state.dimensionsList.length; i++) {
                const res = /^New Dimension \((\d+)\)$/.exec(state.dimensionsMap[state.dimensionsList[i]].name);
                if (res !== null) {
                    maxDimensionNumber = Math.max(maxDimensionNumber, parseInt(res[1], 10));
                }
            }
            const newState = update(state, {
                dimensionsMap: {
                    [id]: {
                        $set: {
                            name: `New Dimension (${String(maxDimensionNumber + 1)})`,
                            values: [],
                            defaultValue: '',
                            expanded: true,
                            saveStatus: '',
                            availableInTab: state.tab,
                        },
                    },
                },
                dimensionsList: {
                    $unshift: [id],
                },
            });
            return addValue(newState, { dimensionId: id });
        }
        case CUSTOMDIMENSION_DIMENSION_REMOVE_ATTEMPTED: {
            return update(state, {
                deleteDialogOn: {
                    $set: action.id,
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_REMOVE_REJECTED: {
            return update(state, {
                deleteDialogOn: {
                    $set: null,
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_REMOVED: {
            const newState = removeDimension(state, { id: state.deleteDialogOn });
            return update(newState, {
                dimensionsBackup: {
                    [state.deleteDialogOn]: {
                        $set: null,
                    },
                },
                deleteDialogOn: {
                    $set: null,
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_UNDO_CHANGES: {
            return revertDimension(state, action.id);
        }
        case CUSTOMDIMENSION_DIMENSION_SAVE_ERROR: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        saveStatus: {
                            $set: 'displayErrors',
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_SAVE_START: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        saveStatus: {
                            $set: 'saving',
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_SAVE_DONE: {
            return update(state, {
                dimensionsBackup: {
                    [action.id]: {
                        $set: action.config,
                    },
                },
                dimensionsMap: {
                    [action.id]: {
                        saveStatus: {
                            $set: 'saved',
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_DIMENSION_SAVE_STATUS_RESET: {
            return update(state, {
                dimensionsMap: {
                    [action.id]: {
                        saveStatus: {
                            $set: '',
                        },
                    },
                },
            });
        }
        case CUSTOMDIMENSION_FILTER_CHANGED: {
            return update(state, {
                dimensionFilter: {
                    $set: action.value,
                },
            });
        }
        default:
            return state;
    }
};

export default reducer;
