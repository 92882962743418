import React, { useState, useLayoutEffect } from 'react';
import css from './StickyShadow.css';

function StickyShadow({ targetRef }) {
    const [coveredElementHeight, setCoveredElementHeight] = useState(0);

    useLayoutEffect(() => {
        const { current } = targetRef;
        if (!current) {
            return;
        }

        // Remove "px" from top - since e.getComputedStyle is not supported across all browsers we have to use the
        // older window.getComputedStyle API
        const topStr = getComputedStyle(current).top;
        const topStickyness = parseInt(topStr.substr(0, topStr.length - 2), 10) || 0;
        setCoveredElementHeight(current.clientHeight + topStickyness);
    }, [targetRef.current]);

    return (
        <React.Fragment>
            <div
                className={css.stickyShadow}
                style={{
                    top: coveredElementHeight, // needed for border of sticky actions
                }}
            />
            <div className={css.stickyShadowCover} />
        </React.Fragment>
    );
}

export default StickyShadow;
