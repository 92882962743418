import { connect } from 'react-redux';
import { exportTable } from '../../actions/reports';
import { ExportPopover } from '../../components/reportsWidgets';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onItemClicked: itemId => {
            dispatch(exportTable(ownProps.reportType, itemId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportPopover);
