import update from 'immutability-helper';
import {
    SET_MAIN_VIEW_NAME,
    SET_STATUS,
    SET_IS_LOADING,
    SET_EMAIL_SENT,
    SET_FINISHED_GOOGLE_SIGNUP_FIRST_STEP,
} from './actions';
import { ERROR_CODES } from './utils';

const initialState = {
    status: null,
    emailSent: false,
    errorCode: null,
    isLoading: false,
};

export default function homepageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_MAIN_VIEW_NAME:
            return update(state, {
                mainViewName: { $set: action.mainViewName },
            });
        case SET_EMAIL_SENT:
            return update(state, {
                emailSent: { $set: action.emailSent },
            });
        case SET_STATUS:
            return update(state, {
                status: { $set: action.status },
                errorCode: { $set: action.errorCode },
            });
        case SET_IS_LOADING:
            return update(state, {
                isLoading: { $set: action.isLoading },
            });
        case SET_FINISHED_GOOGLE_SIGNUP_FIRST_STEP:
            return update(state, {
                finishedGoogleSignupFirstStep: { $set: action.isFinished },
            });
        default:
            return state;
    }
}
