import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getFlooredFixed } from '../../utils/numbers';
import css from './ValueLabel.css';

function ValueLabel({
    containerClass,
    inputClass,
    inputStyle,
    labelClass,
    value,
    label,
    translationArgs,
    append,
    isEditable,
    disableAutoScroll,
    onValueChanged,
    index,
    maxValue,
    minValue,
    decimalPlaces,
}) {
    const [lastValue, setLastValue] = useState(null);
    const [trueValue, setTrueValue] = useState(value);

    // Only for editable rows whose input has changed
    const inputRef = useRef(null);
    const inputPrefixRef = useRef(null);

    const contents = (
        <>
            <Translate
                id={label}
                data={{
                    ...translationArgs,
                    i: value,
                }}
            />
            {append}
        </>
    );

    const onChangeDebounced = useCallback(
        debounce(
            (v, i) => {
                onValueChanged(v, i);
            },
            800,
            { trailing: true }
        ),
        [onValueChanged]
    );

    // Scroll to editable label
    useEffect(() => {
        if (!inputRef) {
            return;
        }

        // Prevent scrolling from happening on every update
        if (isEditable && lastValue !== value && !disableAutoScroll) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            });
        }

        setLastValue(value);
    });

    const onFocus = () => {
        if (inputRef.current) {
            inputRef.current.select();
        }
    };

    const toValidValue = textValue => {
        if (!textValue) {
            return textValue;
        }

        const numericValue = parseFloat(textValue);

        if (numericValue > maxValue || numericValue < minValue) {
            return value;
        }

        return +getFlooredFixed(numericValue, decimalPlaces);
    };

    return (
        <div className={containerClass}>
            {!isEditable ? (
                contents
            ) : (
                <div className={inputClass}>
                    <input
                        ref={inputRef}
                        type="number"
                        value={trueValue}
                        onChange={e => {
                            const newValue = toValidValue(e.target.value);
                            setTrueValue(newValue);
                            onChangeDebounced(newValue, index);
                        }}
                        onFocus={onFocus}
                        className={css.valueLabelInput}
                        style={inputStyle}
                    />

                    {/* Add any formatting on top of the input */}
                    <div
                        className={classNames({
                            [css.formatStaticText]: !labelClass,
                            [labelClass]: !!labelClass,
                        })}
                        ref={inputPrefixRef}
                    >
                        <Translate
                            id={label}
                            data={{
                                ...translationArgs,
                                i: '',
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

ValueLabel.propTypes = {
    isEditable: PropTypes.bool,
    disableAutoScroll: PropTypes.bool,
    translationArgs: PropTypes.objectOf(PropTypes.string),
    onValueChanged: PropTypes.func,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    decimalPlaces: PropTypes.number,
    containerClass: PropTypes.string,
    inputClass: PropTypes.string,
    inputStyle: PropTypes.objectOf(PropTypes.string),
    labelClass: PropTypes.string,
    value: PropTypes.number,
    label: PropTypes.string,
    append: PropTypes.string,
};

ValueLabel.defaultProps = {
    isEditable: false,
    disableAutoScroll: false,
    translationArgs: {},
    onValueChanged: () => {},
    index: -1,
    maxValue: 999999,
    minValue: 0,
    decimalPlaces: 2,
    containerClass: '',
    inputClass: '',
    inputStyle: null,
    labelClass: '',
    value: 0,
    label: null,
    append: null,
};

export default ValueLabel;
