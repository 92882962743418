import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { SingularButton, Wizard } from '../../../components/widgets';
import WarningMessage, { MessageTypes } from '../../../teamManagement/components/WizardWarningMessage';
import css from '../ConversionModelShelf.css';

import {
    cloneObject,
    getModelKindName,
    getRelevantModel,
    getShelfSteps,
    getStepsFormValues,
    isSkan4Timers,
    parseConversionModelGroupToPayload,
    parseConversionModelToPayload,
    parseFinePostback,
    parseShelfStepsValuesToConversionModels,
    VALIDATION_ERRORS_FUNCTIONS,
} from '../../utils';
import { getNextStepIndex, getPrevStepIndex, isFirstStep, isLastStep } from '../../../utils/wizard';
import {
    MIXED_TYPES,
    P1_INDEX,
    POSTBACK_CONVERSION_VALUE_TYPE_FINE,
    RevenueModelTypes,
    SHELF_STEP_P1,
    SHELF_STEP_P1_COARSE,
    SHELF_STEP_P2,
    SHELF_STEP_P3,
    SHELF_STEPS,
    SHELF_STEPS_SKAN_3,
    TRACK_EVENT_PREFIX,
    UNUSED,
} from '../../consts';
import ConversionModelShelfStep1 from './ConversionModelShelfStep1';
import ConversionModelShelfStep2 from './ConversionModelShelfStep2';
import ConversionModelShelfStep3 from './ConversionModelShelfStep3';
import WizardFooter from '../../../components/widgets/WizardFooter';
import SKAdnetworkAPI from '../../service';
import { conversionModelGroupShape, sdkEventsShape } from './types';
import ConversionModelShelfStep1Coarse from './ConversionModelShelfStep1Coarse';
import { GeneralPopup, PopupTypes } from '../../../components/partials';
import { trackMixpanelEvent } from '../../../utils/general';
import { ModelsApiContext } from '../../hooks';
import { getMigrateUntilHelper } from '../../appUtils';

const SAVING_FAILURE_MASSAGE = {
    type: MessageTypes.ERROR,
    message: 'STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.MODEL_GROUP_SAVE_FAILED',
};

const COARSE_MAPPING_FAILURE_MASSAGE = {
    type: MessageTypes.ERROR,
    message: 'STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.COARSE_MAPPING_FAILED',
};

const api = new SKAdnetworkAPI();

const getFillUnusedCv = decodingTable => {
    const filledDecodingTable = {};
    let currentCv = 0;

    const cvList = Object.keys(decodingTable)
        .map(cv => parseInt(cv, 10))
        .sort((a, b) => a - b); // Sort numerically

    cvList.forEach(cv => {
        while (currentCv < cv) {
            filledDecodingTable[currentCv] = [{ eventType: UNUSED, event: UNUSED }];
            currentCv++;
        }

        filledDecodingTable[cv] = decodingTable[cv];
        currentCv++;
    });

    return filledDecodingTable;
};

const getParsedDecodingTable = decodingTable => {
    const filledDecodingTable = getFillUnusedCv(decodingTable);

    const cvList = Object.keys(filledDecodingTable).map(cv => parseInt(cv, 10));
    const parsedDecodingTable = {};

    cvList.forEach(cv => {
        const cvDecodingData = filledDecodingTable[cv];

        parsedDecodingTable[cv] = cvDecodingData.map(data => {
            const { event_type: eventType, value } = data;
            delete data.event_type;

            return {
                eventType,
                ...data,
                value: Object.values(RevenueModelTypes).includes(eventType)
                    ? value.map(x => x && parseFloat(x))
                    : value,
            };
        });
    });

    return parsedDecodingTable;
};

function ConversionModelShelf({
    isAdminMode,
    onCancel,
    onSave,
    conversionModelGroup,
    appId,
    currencySymbol,
    sdkEvents,
    isSkan3Mode,
    isUpgradeMode,
    conversionModelIdToName,
}) {
    const { appConfig } = useContext(ModelsApiContext);
    const { first24Hours } = getMigrateUntilHelper(appConfig?.migrateUntil);
    const containerRef = useRef();

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [shelfSteps, setShelfSteps] = useState(isSkan3Mode && !isUpgradeMode ? SHELF_STEPS_SKAN_3 : SHELF_STEPS);
    const [modelKind, setModelKind] = useState();
    const [modelName, setModelName] = useState();
    const [formValuesByStep, setFormValuesByStep] = useState({});
    const [decodingTable, setDecodingTable] = useState();

    const [formValidationErrors, setFormValidationErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isMigrationPopup, setIsMigrationPopup] = useState(false);
    const [parsedModelsGroup, setParsedModelsGroup] = useState();

    const activeStep = shelfSteps[activeStepIndex];
    const currentFormValues = formValuesByStep[activeStep];

    const isMultiStep = !isSkan3Mode || isUpgradeMode;
    const multiCoarseMapping = true;

    useEffect(() => {
        setErrorMessage({});
    }, [activeStepIndex]);

    const trackMixpanel = () => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, 'save_new_conversion_model', {
            is_skan_4: true,
            is_upgrade: isUpgradeMode,
            model_kind: modelKind?.name,
            model_name: modelName,
            app_id: appId,
        });
    };

    const saveModels = modelGroup => {
        setIsSaving(true);

        api.saveGroupModels(appId, modelGroup)
            .then(() => {
                onSave(true);
                trackMixpanel();
            })
            .catch(() => {
                setErrorMessage(SAVING_FAILURE_MASSAGE);
                onSave(false);
            })
            .finally(() => setIsSaving(false));
    };

    const getParamToCompare = param => {
        if (param !== null && typeof param === 'object') {
            return JSON.stringify(param);
        }

        return param;
    };

    const isFineModelChanged = (currentFineModel, newFineModel) => {
        const compareFields = [
            'modelType',
            'revenueType',
            'eventsType',
            'revenueBuckets',
            'revenueBucketSize',
            'apsalarEventIds',
            'measurementPeriod',
        ];

        return !!compareFields.find(field => {
            const currentFieldValue = getParamToCompare(currentFineModel[field]);
            const newFieldValue = getParamToCompare(newFineModel[field]);
            return currentFieldValue !== newFieldValue;
        });
    };

    const handleOnSave = () => {
        const parsedValues = parseShelfStepsValuesToConversionModels(formValuesByStep, conversionModelGroup);
        let isMigration = false;

        // In case of edit mode.
        if (conversionModelGroup) {
            const currentFineModel = getRelevantModel(
                conversionModelGroup.conversionModels,
                P1_INDEX,
                POSTBACK_CONVERSION_VALUE_TYPE_FINE
            );

            const newFineModel = getRelevantModel(
                parsedValues.conversionModels,
                P1_INDEX,
                POSTBACK_CONVERSION_VALUE_TYPE_FINE
            );

            const isSkan4TimersModel = isSkan4Timers(newFineModel);

            // Show migration popup if the model enabled and has changed after 24 hours,
            // and in case it is not with skan 4 timers, because migration is not required for skan 4 timers models.
            if (
                !first24Hours &&
                currentFineModel.enabled &&
                !isSkan4TimersModel &&
                isFineModelChanged(currentFineModel, newFineModel)
            ) {
                isMigration = true;
            }
        }

        const parseGroupToPayload = parseConversionModelGroupToPayload(parsedValues, isMultiStep);

        if (isMigration) {
            setParsedModelsGroup(parseGroupToPayload);
            setIsMigrationPopup(true);
        } else {
            saveModels(parseGroupToPayload);
        }
    };

    const moveToStep = stepIndex => {
        setFormValidationErrors({});

        if (shelfSteps[stepIndex] === SHELF_STEP_P1_COARSE) {
            const fineModel = cloneObject(currentFormValues);
            parseFinePostback(fineModel, currentFormValues);
            const model = parseConversionModelToPayload(fineModel);
            setIsSaving(true);

            api.getModelDecodingTable(appId, model)
                .then(result => {
                    setDecodingTable(getParsedDecodingTable(result.value));
                    setActiveStepIndex(stepIndex);
                })
                .catch(() => {
                    setErrorMessage(COARSE_MAPPING_FAILURE_MASSAGE);
                })
                .finally(() => {
                    setIsSaving(false);
                });
        } else {
            setActiveStepIndex(stepIndex);
        }
    };

    const handleNextStepClick = () => {
        const validationFunction = VALIDATION_ERRORS_FUNCTIONS[activeStep];

        const { formValidationErrors: errors, isValidForm, tableGeneralError } = validationFunction(
            { ...currentFormValues, conversionModelIdToName },
            true,
            isMultiStep
        );

        if (isValidForm) {
            if (isLastStep(activeStepIndex, shelfSteps)) {
                handleOnSave();
            } else {
                moveToStep(getNextStepIndex(activeStepIndex, shelfSteps));
            }
        } else {
            setFormValidationErrors({ ...errors, tableGeneralError });
        }
    };

    const handlePrevStepClick = () => {
        if (isFirstStep(activeStepIndex)) {
            onCancel();
        } else {
            setActiveStepIndex(getPrevStepIndex(activeStepIndex));
        }
    };

    const handleFormValuesChange = (step, formValues) => {
        setFormValuesByStep(prevFormValuesByStep => ({
            ...prevFormValuesByStep,
            [step]: { ...prevFormValuesByStep[step], ...formValues },
        }));
    };

    useEffect(() => {
        if (!currentFormValues || !Object.keys(formValidationErrors).length) return;

        const validationFunction = VALIDATION_ERRORS_FUNCTIONS[activeStep];

        const { formValidationErrors: errors, tableGeneralError } = validationFunction(
            { ...currentFormValues, conversionModelIdToName },
            false,
            isMultiStep
        );

        setFormValidationErrors({ ...errors, tableGeneralError });
    }, [currentFormValues]);

    useEffect(() => {
        if (!conversionModelGroup || modelKind) return;

        const fineModel = getRelevantModel(
            conversionModelGroup.conversionModels,
            P1_INDEX,
            POSTBACK_CONVERSION_VALUE_TYPE_FINE
        );
        setModelKind({
            name: getModelKindName(fineModel.modelType, fineModel.revenueType, fineModel.eventsType),
            mixed_types: MIXED_TYPES[fineModel.eventsType],
        });
    }, [conversionModelGroup]);

    useEffect(() => {
        if (!modelKind) return;

        let newShelfSteps = shelfSteps;

        if (isMultiStep) {
            newShelfSteps = getShelfSteps(modelKind.name);
            setShelfSteps(newShelfSteps);
        }

        const modelGroupName = modelName || conversionModelGroup?.name;

        const newStepFormValues = getStepsFormValues(
            newShelfSteps,
            conversionModelGroup,
            currencySymbol,
            modelKind,
            modelGroupName,
            multiCoarseMapping,
            isSkan3Mode,
            isUpgradeMode
        );

        setFormValuesByStep(newStepFormValues);
        setFormValidationErrors({});
        !modelName && setModelName(conversionModelGroup?.name);
    }, [modelKind]);

    const cleanMigrationPopup = () => {
        setIsMigrationPopup(false);
        setParsedModelsGroup(null);
    };

    const handleStepClicked = stepIndex => {
        if (stepIndex < activeStepIndex) {
            setActiveStepIndex(stepIndex);
        }
    };

    return (
        <div className={css.container} ref={containerRef}>
            <WarningMessage
                show={!!errorMessage.message}
                duration={1000}
                type={errorMessage.type}
                message={errorMessage.message}
            />
            <GeneralPopup
                open={isMigrationPopup}
                type={PopupTypes.WARNING_WITH_CANCEL}
                title="STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.TITLE"
                text="STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.START_MIGRATION_TEXT"
                acceptText="STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.ACCEPT_BUTTON_TEXT"
                rejectText="STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.REJECT_BUTTON_TEXT"
                onAccept={() => {
                    saveModels(parsedModelsGroup);
                    cleanMigrationPopup();
                }}
                onReject={() => {
                    cleanMigrationPopup();
                }}
            />
            <Wizard
                stepLabelColor="var(--turquoise200)"
                cssClassThemeName="turquoiseThemeWizard"
                stepActiveLabelColor="var(--green400)"
                showStepper
                activeStep={shelfSteps[activeStepIndex]}
                stepperContainerStyle={{ padding: '12px 150px' }}
                handleStepClicked={handleStepClicked}
                isHidden={!isMultiStep}
            >
                <ConversionModelShelfStep1
                    id={SHELF_STEP_P1}
                    label={SHELF_STEP_P1}
                    isAdminMode={isAdminMode}
                    onChange={formValues => handleFormValuesChange(SHELF_STEP_P1, formValues)}
                    values={formValuesByStep[SHELF_STEP_P1]}
                    errors={formValidationErrors}
                    currencySymbol={currencySymbol}
                    sdkEvents={sdkEvents}
                    setModelKind={setModelKind}
                    modelName={modelName}
                    isUpgradeMode={isUpgradeMode}
                    isSkan3Mode={isSkan3Mode}
                    shelfElement={containerRef.current}
                    multiCoarseMapping={multiCoarseMapping}
                    isMultiStep={isMultiStep}
                />
                {shelfSteps.includes(SHELF_STEP_P1_COARSE) ? (
                    <ConversionModelShelfStep1Coarse
                        id={SHELF_STEP_P1_COARSE}
                        isMidStep
                        decodingTable={decodingTable}
                        values={formValuesByStep[SHELF_STEP_P1_COARSE]}
                        revenueType={formValuesByStep[SHELF_STEP_P1].revenueType}
                        multiCoarseMapping={multiCoarseMapping}
                        errors={formValidationErrors}
                        onChange={formValues => handleFormValuesChange(SHELF_STEP_P1_COARSE, formValues)}
                    />
                ) : null}
                {shelfSteps.includes(SHELF_STEP_P2) ? (
                    <ConversionModelShelfStep2
                        id={SHELF_STEP_P2}
                        label={SHELF_STEP_P2}
                        values={formValuesByStep[SHELF_STEP_P2]}
                        onChange={formValues => handleFormValuesChange(SHELF_STEP_P2, formValues)}
                        sdkEvents={sdkEvents}
                        errors={formValidationErrors}
                    />
                ) : null}
                {shelfSteps.includes(SHELF_STEP_P3) ? (
                    <ConversionModelShelfStep3
                        id={SHELF_STEP_P3}
                        label={SHELF_STEP_P3}
                        values={formValuesByStep[SHELF_STEP_P3]}
                        onChange={formValues => handleFormValuesChange(SHELF_STEP_P3, formValues)}
                        sdkEvents={sdkEvents}
                        errors={formValidationErrors}
                    />
                ) : null}
            </Wizard>
            <WizardFooter
                buttons={[
                    <SingularButton type="flat" onClick={handlePrevStepClick} disabled={isSaving}>
                        <Translate id={`STATIC.BUTTONS.${isFirstStep(activeStepIndex) ? 'CANCEL' : 'BACK'}`} />
                    </SingularButton>,
                    <SingularButton onClick={handleNextStepClick} showSpinner={isSaving}>
                        <Translate id={`STATIC.BUTTONS.${isLastStep(activeStepIndex, shelfSteps) ? 'SAVE' : 'NEXT'}`} />
                    </SingularButton>,
                ]}
            />
        </div>
    );
}

ConversionModelShelf.propTypes = {
    conversionModelGroup: conversionModelGroupShape,
    appId: PropTypes.number,
    currencySymbol: PropTypes.string.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    isAdminMode: PropTypes.bool,
    sdkEvents: PropTypes.objectOf(sdkEventsShape),
    isSkan3Mode: PropTypes.bool,
    isUpgradeMode: PropTypes.bool,
    conversionModelIdToName: PropTypes.objectOf(PropTypes.string),
};

ConversionModelShelf.defaultProps = {
    conversionModelGroup: null,
    appId: null,
    onSave: () => {},
    onCancel: () => {},
    isAdminMode: false,
    sdkEvents: {},
    isSkan3Mode: false,
    isUpgradeMode: false,
    conversionModelIdToName: {},
};

export default ConversionModelShelf;
