import React from 'react';
import { Tooltip } from 'react-tippy';
import { withLocalize } from 'react-localize-redux';

class InnerTooltip extends React.PureComponent {
    render() {
        const { children, translate, text, ...rest } = this.props;
        return (
            <Tooltip
                html={translate(text)}
                position="top"
                inertia
                animation="scale"
                distance={10}
                theme="light"
                size="big"
                popperOptions={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false,
                        },
                        hide: {
                            enabled: false,
                        },
                    },
                }}
                delay={1000}
                {...rest}
            >
                {children}
            </Tooltip>
        );
    }
}

export default withLocalize(InnerTooltip);
