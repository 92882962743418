import React from 'react';
import { OSIcons } from '../../utils/OS';
import TableCellText from '../../components/widgets/TableCellText';
import TableCellWithSubTextAndIcon from '../../components/widgets/TableCellWithSubTextAndIcon';
import css from './ABTestingTable.css';
import { TableCellIcon } from '../../components/widgets';
import TableCellMultiIcons from '../../components/widgets/TableCellMultiIcons';
import EditDraftIcon from '../../resources/svg/edit_draft.svg';
import CompletedIcon from '../../resources/svg/checked_with_thin_circle_green.svg';
import ControlGroupIcon from '../../resources/svg/ab_testing_control_group.svg';
import TableCellActions from '../../components/widgets/TableCellActions';
import EditIcon from '../../resources/svg/edit.svg';
import TrashIcon from '../../resources/svg/trash.svg';

export const AUDIENCE_ABTESTING_STATUS = {
    LIVE: 'Active',
    SCHEDULED: 'Pending',
    ERROR: 'Error',
    COMPLETE: 'Done',
    EDIT: 'Edit',
};

export const STATUSES_COLOR_MAP = {
    [AUDIENCE_ABTESTING_STATUS.LIVE]: 'var(--green300)',
    [AUDIENCE_ABTESTING_STATUS.SCHEDULED]: 'var(--blue300)',
    [AUDIENCE_ABTESTING_STATUS.ERROR]: 'var(--red300)',
    [AUDIENCE_ABTESTING_STATUS.COMPLETE]: 'var(--green300)',
    [AUDIENCE_ABTESTING_STATUS.EDIT]: 'var(--gray600)',
};

const getTableFieldProps = fieldName => ({
    name: fieldName,
    cellValue: fieldName ? [fieldName] : null,
});

const TABLE_KEYS = {
    TEST_NAME: 'name',
    APPSITE: 'appsite',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    STATUS: 'status',
    DISTRIBUTIONS: 'testChannels',
    ACTIONS: 'actions',
};

const normalizeAppsite = appSite => {
    return [
        {
            title: appSite.appName,
            subText: appSite.longname,
            containerClassName: css.appsiteColumn,
            subTextClassName: css.subTextContainer,
            prefixIcons: [
                {
                    elem: (
                        // eslint-disable-next-line react/jsx-filename-extension
                        <img
                            className={css.appsiteIcon}
                            key={`appsite_${appSite.icon}`}
                            src={appSite.icon}
                            alt={appSite.icon}
                        />
                    ),
                    className: 'appsiteIcon',
                },
                { elem: OSIcons[appSite.platform], className: 'appsiteIcon' },
            ],
        },
    ];
};

const translationPrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.TABLE_COLUMNS';
const translationActionPrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.TABLE_ACTIONS';
const translationPrefixStatusTooltips = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.STATUS_TOOLTIPS';
export const translationPrefixStatusLabels = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.STATUS_LABEL';

const normalizeDistributions = distributions =>
    distributions.map(distribution =>
        distribution.isControlGroup ? ControlGroupIcon : distribution.adnetworkLogoPath
    );

const normalizeDistributionsTooltip = (distributions, translate) =>
    distributions.map(distribution =>
        distribution.isControlGroup
            ? translate('STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.TABLE_UTILS.CONTROL_GROUP')
            : distribution.adnetworkDisplayName
    );

const normalizeEndDateTooltip = (endDate, translate) =>
    endDate === '' ? translate('STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.TABLE_UTILS.END_DATE_ONGOING_TOOLTIP') : '';

const normalizeScheduleStatusTooltip = (startDate, rowData, translate) => ({
    [AUDIENCE_ABTESTING_STATUS.LIVE]: translate(`${translationPrefixStatusTooltips}.LIVE`),
    [AUDIENCE_ABTESTING_STATUS.SCHEDULED]: translate(`${translationPrefixStatusTooltips}.SCHEDULED`, { startDate }),
    [AUDIENCE_ABTESTING_STATUS.ERROR]: `${translate(`${translationPrefixStatusTooltips}.ERROR`)}`,
});

const normalizeEndDate = (endDate, translate) =>
    endDate.trim() || translate('STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.TABLE_UTILS.END_DATE_ONGOING');

const getActionComponentsConfigs = (onEdit, onDelete, translate) => [
    {
        ActionComponent: EditIcon,
        key: 'editConfiguration',
        title: translate(`${translationActionPrefix}.EDIT`),
        onClick: onEdit,
        shouldShow: () => true,
        shouldHideIcon: true,
    },
    {
        ActionComponent: TrashIcon,
        key: 'deleteConfiguration',
        title: translate(`${translationActionPrefix}.DELETE`),
        onClick: onDelete,
        shouldShow: () => true,
        shouldHideIcon: true,
    },
];

export const tableColumns = (data, onEdit, onDelete, translate) => [
    {
        ...getTableFieldProps(TABLE_KEYS.TEST_NAME),
        displayName: `${translationPrefix}.TEST_NAME`,
        cellComponent: TableCellText,
        cellProps: {
            className: css.testNameCell,
        },
    },
    {
        cellComponent: TableCellWithSubTextAndIcon,
        cellValues: [
            { propName: 'elements', dataKey: 'appsite', transformation: appSite => normalizeAppsite(appSite) },
        ],
        displayName: `${translationPrefix}.APPSITE_NAME`,
        cellProps: {
            className: css.appsiteRowCell,
        },
    },
    {
        ...getTableFieldProps(TABLE_KEYS.START_DATE),
        displayName: `${translationPrefix}.START_DATE`,
        cellProps: { className: css.dateCell },
        cellComponent: TableCellText,
    },
    {
        ...getTableFieldProps(TABLE_KEYS.END_DATE),
        displayName: `${translationPrefix}.END_DATE`,
        cellProps: {
            className: css.dateCell,
        },
        cellComponent: TableCellText,
        cellValues: [
            {
                propName: 'value',
                dataKey: TABLE_KEYS.END_DATE,
                transformation: endDate => normalizeEndDate(endDate, translate),
            },
            {
                propName: 'tooltip',
                dataKey: TABLE_KEYS.END_DATE,
                transformation: endDate => normalizeEndDateTooltip(endDate, translate),
            },
        ],
    },
    {
        ...getTableFieldProps(TABLE_KEYS.DISTRIBUTIONS),
        displayName: `${translationPrefix}.DISTRIBUTIONS`,
        cellComponent: TableCellMultiIcons,
        cellValues: [
            { propName: 'icons', dataKey: 'testChannels', transformation: normalizeDistributions },
            {
                propName: 'tooltips',
                dataKey: 'testChannels',
                transformation: testChannels => normalizeDistributionsTooltip(testChannels, translate),
            },
        ],
        cellProps: {
            className: css.disturbutionsCell,
        },
    },
    {
        ...getTableFieldProps(TABLE_KEYS.STATUS),
        displayName: `${translationPrefix}.STATUS`,
        cellComponent: TableCellIcon,
        cellValues: [
            {
                propName: 'containerClassname',
                dataKey: TABLE_KEYS.STATUS,
                transformation: status =>
                    status === AUDIENCE_ABTESTING_STATUS.EDIT ? css.editStatusCell : css.statusCell,
            },
            {
                propName: 'tooltip',
                dataKey: TABLE_KEYS.START_DATE,
                transformation: (startDate, index) => normalizeScheduleStatusTooltip(startDate, data[index], translate),
            },
        ],
        cellProps: {
            color: STATUSES_COLOR_MAP,
            shouldTranslateToolTip: true,
            iconElement: {
                [AUDIENCE_ABTESTING_STATUS.COMPLETE]: <CompletedIcon />,
                [AUDIENCE_ABTESTING_STATUS.EDIT]: <EditDraftIcon />,
            },
            onClick: {
                [AUDIENCE_ABTESTING_STATUS.EDIT]: onEdit,
            },
            text: {
                [AUDIENCE_ABTESTING_STATUS.LIVE]: translate(`${translationPrefixStatusLabels}.LIVE`),
                [AUDIENCE_ABTESTING_STATUS.SCHEDULED]: translate(`${translationPrefixStatusLabels}.SCHEDULED`),
                [AUDIENCE_ABTESTING_STATUS.ERROR]: translate(`${translationPrefixStatusLabels}.ERROR`),
                [AUDIENCE_ABTESTING_STATUS.COMPLETE]: translate(`${translationPrefixStatusLabels}.COMPLETE`),
                [AUDIENCE_ABTESTING_STATUS.EDIT]: translate(`${translationPrefixStatusLabels}.EDIT`),
            },
        },
    },
    {
        name: 'actionItems',
        cellComponent: TableCellActions,
        displayName: `${translationPrefix}.ACTIONS`,
        cellProps: {
            className: css.actionCell,
            dataTestId: 'action-cell',
            actionComponentsConfigs: getActionComponentsConfigs(onEdit, onDelete, translate),
        },
    },
];
