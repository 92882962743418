import React from 'react';
import EditPanel from './EditPanel';
import {SEGMENT_AUTO_PAUSE_DISABLED, SEGMENT_AUTO_PAUSE_ENABLED} from "../consts";

export default class Settings extends React.Component {
    render() {
        const fields = [
            {
                type: 'dropdown',
                label: 'Unused Segments Behaviour',
                value: 'auto_pause_enabled',
                onChange: 'auto_pause_enabled',
                labelTooltip: 'STATIC.PAGES.AUDIENCES.AUTO_PAUSE_TOOLTIP',
                items: [
                    {
                        display_name: 'STATIC.PAGES.AUDIENCES.AUTO_PAUSE_OPTION_ENABLED',
                        name: SEGMENT_AUTO_PAUSE_ENABLED,
                    },
                    {
                        display_name: 'STATIC.PAGES.AUDIENCES.AUTO_PAUSE_OPTION_DISABLED',
                        name: SEGMENT_AUTO_PAUSE_DISABLED,
                    },
                ],
            },
        ];

        return (
            <EditPanel
                title="Settings"
                fields={fields}
                {...this.props}
            />
        );
    }
}
