import { useCallback } from 'react';
import { AUDIENCE_ABTESTING_STATUS } from '../../tableUtils';

const useWizard = ({ status, id }) => {
    const shouldShowDraft = useCallback(() => {
        return !status || AUDIENCE_ABTESTING_STATUS.EDIT === status;
    }, [status]);

    const isAlreadyStarted = useCallback(() => {
        return id && ![AUDIENCE_ABTESTING_STATUS.EDIT, AUDIENCE_ABTESTING_STATUS.SCHEDULED].includes(status);
    }, [status, id]);

    const isAlreadyEnded = useCallback(() => {
        return status && AUDIENCE_ABTESTING_STATUS.COMPLETE === status;
    }, [status]);

    return {
        shouldShowDraft: !!shouldShowDraft(),
        isAlreadyStarted: !!isAlreadyStarted(),
        isAlreadyEnded: !!isAlreadyEnded(),
    };
};

export default useWizard;
