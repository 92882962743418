import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../PageHeader';
import css from './BasePartnerAppSitesSummary.css';
import AppSitesTable from '../AppSitesTable';
import { partnerShape } from '../types';
import AddAppSiteButton from '../AddAppSiteButton';

export const SUMMARY_DATA_TEST_ID = 'app-sites-summary';

export default function BasePartnerAppSitesSummary({ partner, filter, onAppSiteForPartner }) {
    return (
        <div className={css.appSitesSummary} data-testid={SUMMARY_DATA_TEST_ID}>
            <PageHeader partner={partner} />
            <AddAppSiteButton className={css.addAppSiteButton} partner={partner} onAddAppSite={onAppSiteForPartner} />
            <AppSitesTable
                filter={filter}
                partner={partner}
                onEditAppSite={onAppSiteForPartner}
                onDuplicateAppSite={(...args) => onAppSiteForPartner(...args, true)}
            />
        </div>
    );
}

BasePartnerAppSitesSummary.propTypes = {
    partner: partnerShape.isRequired,
    filter: PropTypes.string,
    onAppSiteForPartner: PropTypes.func,
};

BasePartnerAppSitesSummary.defaultProps = {
    filter: '',
    onAppSiteForPartner: () => {},
};
