import { useCallback } from 'react';
import { useRequest } from '../utils/customHooks';
import AccountPlanAPI from './service';

export const useFetchAccountPlan = () => {
    const accountPlanAPI = new AccountPlanAPI();
    return useRequest(useCallback(accountPlanAPI.getAccountPlan, []));
};

export const useFetchAllProducts = () => {
    const accountPlanAPI = new AccountPlanAPI();
    return useRequest(useCallback(accountPlanAPI.getAllProducts, []));
};
