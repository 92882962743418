import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import GoogleGLogo from '../../resources/svg/google_g_logo.svg';
import SpinnerIcon from '../../resources/svg/singular_loader.svg';
import SingularButtonCSS from './SingularButton.css';
import css from './GoogleAuthButton.css';
import { loadGoogleSDK } from '../../../../dashboard/js/googleSDK';

const GoogleAuthButton = ({
    onAuthSuccess,
    onAuthError,
    isLoading,
    className,
    trackingParams,
    onSubmit,
    text,
    isValid,
}) => {
    const [client, setClient] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const onClick =
        onSubmit ||
        (() => {
            if (isValid()) {
                window.mixpanel.track('google_sign_up_btn_click', trackingParams);
                client.requestAccessToken();
            }
        });

    useEffect(() => {
        if (!loaded) {
            loadGoogleSDK(() => setLoaded(true));
        } else {
            const googleClient = window.google.accounts.oauth2.initTokenClient({
                client_id: '952991682077-ssbhjqpc8riqr02huvrb81f8o40l22ln.apps.googleusercontent.com',
                scope: 'email profile',
                callback: tokenResponse => {
                    onAuthSuccess(tokenResponse.access_token);
                },
            });
            setClient(googleClient);
        }
    }, [loaded]);

    return (
        <button
            className={classNames(SingularButtonCSS.container, css.container, className, {
                [SingularButtonCSS.showSpinner]: isLoading,
                [css.disabled]: isLoading,
            })}
            onClick={onClick}
            type="button"
            disabled={isLoading}
        >
            <div className={css.buttonContent}>
                <GoogleGLogo className={SingularButtonCSS.icon} />
                <span className={SingularButtonCSS.textContainer}>
                    <Translate id={text || 'Sign up with Google'} />
                </span>
            </div>
            {isLoading && <SpinnerIcon className={classNames(css.spinner, SingularButtonCSS.buttonSpinner)} />}
        </button>
    );
};

GoogleAuthButton.propTypes = {
    onAuthSuccess: PropTypes.func.isRequired,
    onAuthError: PropTypes.func.isRequired,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    trackingParams: PropTypes.objectOf(PropTypes.any),
    onSubmit: PropTypes.func,
    text: PropTypes.string,
    isValid: PropTypes.func,
};

GoogleAuthButton.defaultProps = {
    isLoading: false,
    className: undefined,
    trackingParams: {},
    text: '',
    onSubmit: undefined,
    isValid: () => {
        return true;
    },
};

export default GoogleAuthButton;
