import update from 'immutability-helper';
import {
    LOAD,
    UNLOAD,
    SEARCH,
    EDIT_START,
    EDIT_CANCEL,
    EDIT_SAVE,
    EDIT_SAVE_FAIL,
    EDIT_SAVE_DONE,
    EDIT_CHANGE,
    TOGGLE_AD_ACCOUNT,
    TOGGLE_AD_ACCOUNT_DONE,
    EDIT_SEGMENT_START,
    EDIT_SEGMENT_CHANGE,
    EDIT_SEGMENT_CANCEL,
    EDIT_SEGMENT_SAVE,
    EDIT_SEGMENT_SAVE_DONE,
    EDIT_SEGMENT_SAVE_DONE_DELAYED,
} from './actions';

import { sortNetworks } from './utils';

const initialState = {
    loading: true,
    networks: null,
    segments: null,
    companyLogo: null,
    search: '',
    edit: null,
    editError: null,
    segmentEdit: null,
    saving: false,
    editingSegmentsIds: [],
    editingSegmentsData: {},
};

function getSegmentConfigData(segment, network) {
    let config = {};
    if (segment.id in network.distribution.configured_segments) {
        config = network.distribution.configured_segments[segment.id].config;
        config.active = network.distribution.configured_segments[segment.id].active;
        config.config_id = network.distribution.configured_segments[segment.id].id;
    } else {
        network.segment_fields.forEach(field => {
            let fieldDefault = field.default || '';
            if (fieldDefault.startsWith('$segment')) {
                const accessors = fieldDefault.split('.');
                accessors.shift();
                fieldDefault = segment;
                while (accessors.length) {
                    fieldDefault = fieldDefault[accessors[0]];
                    accessors.shift();
                }
            }
            config[field.name] = fieldDefault;
        });
        config.active = false;
    }
    return config;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD: {
            const networks = sortNetworks(action.networks);
            return update(state, {
                loading: { $set: false },
                networks: { $set: networks },
                segments: { $set: action.segments },
                companyLogo: { $set: action.companyLogo },
            });
        }
        case UNLOAD:
            return initialState;
        case SEARCH: {
            return update(state, {
                search: { $set: action.query },
            });
        }
        case EDIT_START: {
            let { network } = action;
            if (!network.distribution) {
                const distribution = {
                    active: true,
                    config: {},
                    configured_segments: {},
                };
                if (network.name !== 'facebook' || network.name !== 'snapchat') {
                    network.distribution_fields.forEach(field => {
                        if (!(field.name in distribution.config)) {
                            distribution.config[field.name] = field.default || '';
                        }
                    });
                }
                network = update(network, { distribution: { $set: distribution } });
            }

            const editingSegmentsData = {};
            state.segments.forEach(segment => {
                editingSegmentsData[segment.id] = getSegmentConfigData(segment, network);
            });

            return update(state, {
                edit: { $set: network },
                editingSegmentsData: {
                    $set: editingSegmentsData,
                },
            });
        }
        case EDIT_SEGMENT_START: {
            const { segment } = action;
            const editingSegmentsIds = [...state.editingSegmentsIds];
            const segIndex = editingSegmentsIds.findIndex(segId => segId === segment.id);
            if (segIndex === -1) {
                editingSegmentsIds.push(segment.id);
            } else {
                editingSegmentsIds.splice(segIndex, 1);
            }

            return update(state, {
                editingSegmentsIds: {
                    $set: editingSegmentsIds,
                },
            });
        }
        case EDIT_CANCEL: {
            return update(state, {
                edit: { $set: null },
                segmentEdit: { $set: null },
                saving: { $set: false },
                editError: { $set: null },
                editingSegmentsIds: { $set: [] },
            });
        }
        case EDIT_SAVE: {
            return update(state, {
                saving: { $set: true },
            });
        }
        case EDIT_SAVE_FAIL: {
            const { error } = action;
            return update(state, {
                saving: { $set: false },
                editError: { $set: error },
            });
        }
        case EDIT_SAVE_DONE: {
            let { network, id } = action;
            network = update(network, {
                distribution: {
                    id: { $set: id },
                },
            });
            let networks = [...state.networks];
            for (let i = 0; i < networks.length; i++) {
                if (networks[i].name === network.name) {
                    networks.splice(i, 1, network);
                    break;
                }
            }
            networks = sortNetworks(networks);
            return update(state, {
                networks: { $set: networks },
                saving: { $set: false },
                edit: { $set: null },
                segmentEdit: { $set: null },
                editError: { $set: null },
            });
        }
        case EDIT_CHANGE: {
            const { inConfig, key, value } = action;
            let distribution = { [key]: { $set: value } };
            if (inConfig) {
                distribution = { config: distribution };
            }
            return update(state, {
                edit: {
                    distribution,
                },
            });
        }
        case EDIT_SEGMENT_CHANGE: {
            const { segment, key, value } = action;
            return update(state, {
                editingSegmentsData: {
                    [segment.id]: {
                        [key]: {
                            $set: value,
                        },
                    },
                },
            });
        }
        case EDIT_SEGMENT_CANCEL: {
            const { segment } = action;
            const index = state.editingSegmentsIds.findIndex(segId => segId === segment.id);
            if (index === -1) {
                return state;
            }
            return update(state, {
                editingSegmentsIds: {
                    $splice: [[index, 1]],
                },
                editingSegmentsData: {
                    [segment.id]: {
                        $set: getSegmentConfigData(segment, state.edit),
                    },
                },
            });
        }
        case EDIT_SEGMENT_SAVE: {
            const { segment } = action;
            return update(state, {
                saving: {
                    $set: true,
                },
                editingSegmentsData: {
                    [segment.id]: {
                        saving: {
                            $set: true,
                        },
                    },
                },
            });
        }
        case EDIT_SEGMENT_SAVE_DONE: {
            let { segment, id } = action;
            const networkIndex = state.networks.findIndex(n => n.name === state.edit.name);
            segment = update(segment, { id: { $set: id }, active: { $set: segment.config.active } });
            return update(state, {
                networks: {
                    [networkIndex]: {
                        distribution: {
                            configured_segments: {
                                [segment.segment_id]: {
                                    $set: segment,
                                },
                            },
                        },
                    },
                },
                edit: {
                    distribution: {
                        configured_segments: {
                            [segment.segment_id]: { $set: segment },
                        },
                    },
                },
                saving: { $set: false },
                editingSegmentsData: {
                    [segment.segment_id]: {
                        $set: {
                            ...segment.config,
                            done: true,
                        },
                    },
                },
                editError: { $set: null },
            });
        }
        case EDIT_SEGMENT_SAVE_DONE_DELAYED: {
            const { segment } = action;
            const newSegmentData = {
                ...state.editingSegmentsData[segment.segment_id],
                saving: false,
                done: false,
            };
            return update(state, {
                saving: {
                    $set: true,
                },
                editingSegmentsData: {
                    [segment.segment_id]: {
                        $set: newSegmentData,
                    },
                },
            });
        }
        case TOGGLE_AD_ACCOUNT: {
            return update(state, {
                saving: { $set: true },
            });
        }
        case TOGGLE_AD_ACCOUNT_DONE: {
            const { id, config } = action;
            const networkIndex = state.networks.findIndex(n => n.name === state.edit.name);
            return update(state, {
                saving: { $set: false },
                edit: {
                    distribution: {
                        id: { $set: id },
                        config: { $set: config },
                        active: { $set: true },
                    },
                },
                networks: {
                    [networkIndex]: {
                        distribution: {
                            config: { $set: config },
                        },
                    },
                },
            });
        }
        default:
            return state;
    }
};
