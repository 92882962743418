export const EMPTY_OPTIONS = [];

export const CUSTOM_OPTION = 'custom';
export const DEFAULT_OPTION = 'default';

export const BannerPlacement = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

export const BannerFormat = {
    SMALL: 'small',
};

export const BannerSortedField = {
    PRIORITY: 'priority',
    NAME: 'name',
};

export const URL_PATTERN_OPERATOR_KEY = 'url_pattern_operator_';
export const URL_PATTERN_VALUE_KEY = 'url_pattern_value_';

export const UrlPatternOperator = {
    CONTAIN: 'url_contains',
    NOT_CONTAIN: 'url_not_contains',
    START: 'url_starts_with',
    END: 'url_ends_with',
};

export const NO_LIMIT = 'No Limit';

export const MIN_PRIORITY = 1;
export const MAX_PRIORITY = 10000;

export const ApiStatus = {
    LOADING: 'loading',
    ERROR: 'error',
    SUCCESS: 'success',
};

export const DEVICE_TYPES = ['phone', 'tablet'];
export const PLATFORMS = ['android', 'ios'];

export const CSS_TEMPLATE = `#singular-banner {
\twidth: 100%;
\tmin-height: 80px;
\tposition: fixed;
\ttop: 0;
\tdisplay: flex;
\tpadding: 10px;
\tbackground-color: #F8F8F8;
\tborder: 1px solid #CCCCCC;
\toverflow: hidden;
\tbox-sizing: border-box;
\tz-index: 99999;
\tuser-select: none;
}

#singular-banner.bottom {
\tbottom: 0;
\ttop: auto;
}

#singular-banner .singular-banner-logo {
\theight: 60px;
\twidth: 60px;
\tborder-radius: 10px;
}

#singular-banner .singular-banner-content {
\tdisplay: flex;
\tflex-direction: column;
\tmargin: auto 10px;
}

#singular-banner .singular-banner-title {
\tfont-size: 12px;
\tfont-weight: 600;
\tmargin-bottom: 2px;
}

#singular-banner .singular-banner-subtitle {
\tfont-size: 11px;
\tcolor: #777777;
\twhite-space: pre-line;
}

#singular-banner .singular-banner-download-button {
\tvertical-align: middle;
\tmargin-top: auto; 
\tmargin-bottom: auto; 
\tmargin-left: auto;
\tfont-size: 12px;
\tfont-weight: 600;
\tcolor: #3a92e5;
\tbackground-color: #EDEDED;
\tborder-radius: 10px;
\tcursor: pointer;
\tpadding: 3px 10px;
}

#singular-banner .singular-banner-close-button {
\tposition: absolute;
\twidth: 8px;
\ttop: 5px;
\tright: 5px;
\tfill: black;
}`;
