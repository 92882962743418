import React from 'react';
import css from './metricCards.css';
import { METRIC_CARDS_ORDER, METRICS_CARDS_DATA } from './consts';
import MetricCardsEmptyStateInner from './metricCardsEmptyStateInner';

function MetricCardsEmptyState() {
    return (
        <div className={css.metricCardsContainer}>
            {METRIC_CARDS_ORDER.map(key => {
                const { displayName, cohortPeriod } = METRICS_CARDS_DATA[key];
                const nameToDisplay = cohortPeriod ? `${displayName} ${cohortPeriod}` : displayName;
                return (
                    <div className={css.metricCard} key={displayName}>
                        <div className={css.metricName}>{nameToDisplay}</div>
                        <MetricCardsEmptyStateInner displayName={displayName} />
                    </div>
                );
            })}
        </div>
    );
}

export default MetricCardsEmptyState;
