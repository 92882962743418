import React, { useContext, useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from '../../components/ConversionModelsList.css';
import ConversionModelsGroupContent from './ConversionModelsGroupContent';
import { GeneralPopup, PopupTypes } from '../../../components/partials';
import {
    getEnabledFineModel,
    getGroupById,
    getRelevantModel,
    is48HoursMultiChangeModel,
    isGroupEnabledByGroupId,
    isSkan4Timers,
} from '../../utils';
import { P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE } from '../../consts';
import toaster from '../../../utils/toaster';
import SKAdnetworkAPI from '../../service';
import { conversionModelGroupShape } from '../../components/skan4/types';
import { ModelsApiContext } from '../../hooks';
import { getMigrateUntilHelper } from '../../appUtils';

const api = new SKAdnetworkAPI();

const POPUP_KINDS = {
    MIGRATION: 0,
    MULTI_CHANGES_MIGRATION: 1,
    ARCHIVE: 2,
};

const POPUP_CONFIG = {
    [POPUP_KINDS.MIGRATION]: {
        type: PopupTypes.WARNING_WITH_CANCEL,
        title: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.TITLE',
        text: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.START_MIGRATION_TEXT',
        acceptText: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.ACCEPT_BUTTON_TEXT',
        rejectText: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.REJECT_BUTTON_TEXT',
    },
    [POPUP_KINDS.MULTI_CHANGES_MIGRATION]: {
        type: PopupTypes.WARNING_WITH_CANCEL,
        title: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.TITLE',
        text: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.SKAN_4_MULTI_48_HOURS_CHANGES',
        acceptText: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.ACCEPT_BUTTON_TEXT',
        rejectText: 'STATIC.PAGES.SKADNETWORK.SUBMIT_CONFIRMATION_MODAL.REJECT_BUTTON_TEXT',
    },
    [POPUP_KINDS.ARCHIVE]: {
        type: PopupTypes.INFO_WITH_CANCEL,
        title: 'STATIC.PAGES.SKADNETWORK.DELETE_MODAL.TITLE',
        text: '',
        acceptText: 'STATIC.PAGES.SKADNETWORK.DELETE_MODAL.ACCEPT_BUTTON_TEXT',
        rejectText: 'STATIC.PAGES.SKADNETWORK.DELETE_MODAL.REJECT_BUTTON_TEXT',
    },
};

function ConversionModelGroupsList({ conversionModelGroups, setConversionModelGroups, refetchModelsData, translate }) {
    const { appConfig } = useContext(ModelsApiContext);
    const { last24Hours, first24Hours } = getMigrateUntilHelper(appConfig?.migrateUntil);

    const [skan4ModelGroups, setSkan4ModelGroups] = useState([]);
    const [skan3ModelGroups, setSkan3ModelGroups] = useState([]);
    const [popupGroupId, setPopupGroupId] = useState(null);
    const [popupConfig, setPopupConfig] = useState({});

    useEffect(() => {
        setSkan4ModelGroups(conversionModelGroups.filter(({ isSkan4 }) => isSkan4));
        setSkan3ModelGroups(conversionModelGroups.filter(({ isSkan4 }) => !isSkan4));
    }, [conversionModelGroups]);

    const onToggleAction = groupId => {
        let isEnabled = false;

        const newConversionModelGroups = conversionModelGroups.map(group => {
            return {
                ...group,
                conversionModels: group.conversionModels.map(model => {
                    const isToggledGroup = group.id === groupId;

                    if (isToggledGroup) {
                        isEnabled = !model.enabled;
                    }

                    return {
                        ...model,
                        enabled: isToggledGroup ? isEnabled : false,
                    };
                }),
            };
        });

        setConversionModelGroups(newConversionModelGroups);

        api.toggleGroup(groupId, isEnabled)
            .then(refetchModelsData)
            .catch(() => {
                toaster.error(translate('STATIC.GENERAL_MESSAGES.UNEXPECTED_ERROR'));
            });
    };

    const onArchiveAction = groupId => {
        api.archiveGroupModel(groupId)
            .then(refetchModelsData)
            .catch(() => {
                toaster.error(translate('STATIC.GENERAL_MESSAGES.UNEXPECTED_ERROR'));
            });
    };

    const POPUP_KIND_TO_ACCEPT_METHOD = {
        [POPUP_KINDS.MIGRATION]: onToggleAction,
        [POPUP_KINDS.MULTI_CHANGES_MIGRATION]: onToggleAction,
        [POPUP_KINDS.ARCHIVE]: onArchiveAction,
    };

    const onOpenPopup = (groupId, popupKind) => {
        const config = POPUP_CONFIG[popupKind];
        config.onAccept = POPUP_KIND_TO_ACCEPT_METHOD[popupKind];
        setPopupConfig(POPUP_CONFIG[popupKind]);
        setPopupGroupId(groupId);
    };

    const onClosePopup = () => {
        setPopupConfig({});
        setPopupGroupId(null);
    };

    const onToggleClick = groupId => {
        const newIsEnabled = !isGroupEnabledByGroupId(conversionModelGroups, groupId);
        const groupModels = getGroupById(conversionModelGroups, groupId).conversionModels;
        const fineModel = getRelevantModel(groupModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE);
        const isSkan4TimersModel = isSkan4Timers(fineModel);
        const isEnabledModel = !!getEnabledFineModel(conversionModelGroups);

        if (newIsEnabled && isEnabledModel && !isSkan4TimersModel && !first24Hours && !last24Hours) {
            onOpenPopup(groupId, POPUP_KINDS.MIGRATION);
        } else if (newIsEnabled && isSkan4TimersModel && is48HoursMultiChangeModel(conversionModelGroups)) {
            onOpenPopup(groupId, POPUP_KINDS.MULTI_CHANGES_MIGRATION);
        } else {
            onToggleAction(groupId);
        }
    };

    const onArchiveClick = groupId => {
        onOpenPopup(groupId, POPUP_KINDS.ARCHIVE);
    };

    // if (!appConfig) return null;

    const getSkanModels = (skanModels, title) => {
        if (!skanModels.length) return null;

        return (
            <>
                <div className={css.listSectionHeader}>
                    <Translate id={title} />
                </div>
                <div className={css.container}>
                    {skanModels.map(modelGroup => {
                        return (
                            <div className={css.itemContainer} key={`${modelGroup.id}`}>
                                <ConversionModelsGroupContent
                                    conversionModelsGroup={modelGroup}
                                    onToggleClick={onToggleClick}
                                    onArchiveClick={onArchiveClick}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    return (
        <>
            {getSkanModels(skan4ModelGroups, 'STATIC.PAGES.SKADNETWORK.MODEL_SUMMARY.SKAN_4_HEADER')}
            {getSkanModels(skan3ModelGroups, 'STATIC.PAGES.SKADNETWORK.MODEL_SUMMARY.SKAN_3_HEADER')}
            <GeneralPopup
                open={!!popupGroupId}
                type={popupConfig.type}
                title={popupConfig.title}
                text={popupConfig.text}
                acceptText={popupConfig.acceptText}
                rejectText={popupConfig.rejectText}
                onAccept={() => {
                    popupConfig.onAccept && popupConfig.onAccept(popupGroupId);
                    onClosePopup();
                }}
                onReject={() => {
                    popupConfig.onReject && popupConfig.onReject(popupGroupId);
                    onClosePopup();
                }}
            />
        </>
    );
}

ConversionModelGroupsList.propTypes = {
    conversionModelGroups: PropTypes.arrayOf(conversionModelGroupShape),
    setConversionModelGroups: PropTypes.func,
    refetchModelsData: PropTypes.func,
    translate: PropTypes.func,
};

ConversionModelGroupsList.defaultProps = {
    conversionModelGroups: [],
    setConversionModelGroups: () => {},
    refetchModelsData: () => {},
    translate: () => {},
};

export default withLocalize(ConversionModelGroupsList);
