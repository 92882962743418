import React from 'react';
import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import popoverStyle from './SingularPopover.css';

const SingularPopover = props => {
    const { showPopover, anchorEl, children, header, wrapperRef, anchorOrigin, className } = props;
    return (
        <Popover
            open={showPopover}
            anchorEl={anchorEl}
            anchorOrigin={
                anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div ref={wrapperRef} className={classNames(popoverStyle.content, className)}>
                {header ? <span className={popoverStyle.header}>{header}</span> : null}
                <div className={popoverStyle.content}>{children}</div>
            </div>
        </Popover>
    );
};

SingularPopover.propTypes = {
    children: PropTypes.node.isRequired,
    showPopover: PropTypes.bool,
    anchorEl: PropTypes.objectOf(PropTypes.object),
    header: PropTypes.string,
    wrapperRef: PropTypes.object,
    className: PropTypes.string,
};

SingularPopover.defaultProps = {
    showPopover: false,
    anchorEl: {},
    header: null,
    wrapperRef: null,
    className: '',
};

export default SingularPopover;
