import React from 'react';
import PropTypes from 'prop-types';
import { SAN_PARTNERS } from '../../../consts';
import AdvancedPostbackSettings from './AdvancedPostbackSettings';
import { organizationOptionsShape, partnerShape } from '../../types';

export default function AdvancedPostbackSettingsFactory(props) {
    const { partner, orgOptions, isReadonly } = props;

    const flexiblePostbackReadOnly = SAN_PARTNERS.includes(partner.name)
        ? (orgOptions.sansFlexiblePostbackReadOnlyMode && !orgOptions.isAdmin) || isReadonly
        : isReadonly;

    return <AdvancedPostbackSettings {...props} flexiblePostbackReadOnly={flexiblePostbackReadOnly} />;
}

AdvancedPostbackSettingsFactory.propTypes = {
    partner: partnerShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    isReadonly: PropTypes.bool,
};
