import EmptyStateService from './service';
import { trackMixpanelEvent } from '../../../utils/general';

export const EmptyStateTasks = {
    SDK_INTEGRATED: 'sdk_integrated',
    IOS_SDK_INTEGRATED: 'ios_sdk_integrated',
    DATA_CONNECTOR_ADDED: 'data_connector_added',
    ADD_APP: 'add_app',
    ADD_IOS_APP: 'add_ios_app',
    ADD_PARTNER: 'add_partner',
    AUDIT_SDK_INTEGRATION: 'audit_sdk_integration',
    ADDED_DEVICE: 'added_device',
    REGISTER_DEVICE: 'register_device',
    EXPORT_EVENT_LEVEL_DATA: 'export_event_level_data',
    ADD_COHORT_EVENT: 'added_cohort_events',
    ADD_SKAN_MODELS: 'added_skan_modeled',
    PROD_APPS_EXISTS: 'prod_apps_exists',
    SUPPORTED_ATTRIBUTION_APP_EXISTS: 'supported_attribution_apps_exists',
    PROD_APPS_WITH_SUPPORTED_ATTRIBUTION_PLATFORM_EXISTS: 'prod_apps_with_supported_attribution_apps',
};

export const EmptyStateImageSrc = {
    INTRO_SINGULAR_PIPELINE: 'introSingularPipeline',
    SINGULAR_DUDE: 'singularDude',
    SINGULAR_LADY: 'singularLady',
    APPS_CONFIGURATION: 'appsConfiguration',
};

export const hideEmptyStateOption = emptyStateType => {
    const EmptyStateApi = new EmptyStateService();
    EmptyStateApi.hideEmptyState(emptyStateType);
};

export const trackEmptyStateMixPanelEvents = (eventName, params) => {
    trackMixpanelEvent('Empty state instructions', eventName, params);
};

export const isEmptyStateTasksDone = (tasksToDo, allTasksStatus) => {
    const tasksDone = tasksToDo
        .map(task => task.topic)
        .reduce((currentValue, taskTopic) => {
            if (allTasksStatus[taskTopic]) return { ...currentValue, [taskTopic]: allTasksStatus[taskTopic] };
            return {};
        }, {});
    return Object.keys(tasksToDo).length === Object.keys(tasksDone).length;
};
