import React from 'react';

export default function SdkKeysAndDocs() {
    return (
        <div>
            <p>
                <span>To integrate the Singular SDK, you will need to:&nbsp;</span>
            </p>
            <ul>
                <li>
                    <a href="https://app.singular.net/#/react/sdk_integration" target="_blank">Get your SDK
                        keys</a><span>.</span>
                </li>
                <li>
                    <a href="https://support.singular.net/hc/en-us/categories/360002441132" target="_blank">Read the developers documentation</a><span
                >. There are instructions for integrating the iOS, Android, and Unity SDKs.</span>
                </li>
            </ul>
            <p>
                <span>
                    You can also access sample apps for <a href="https://github.com/singular-labs/unity-sample-app" target="_blank"><span>Unity</span></a>, <a href="https://github.com/singular-labs/ios-sample-app" target="_blank"><span>IOS </span></a> and <a href="https://github.com/singular-labs/android-sample-app" target="_blank"><span> Android </span></a> to give you more reference help.
                </span>
            </p>
            <p>
                <br />
                    <strong>Note</strong><span>: Starter packages do not support S2S integrations.</span>
            </p>
        </div>
);
}

