import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import * as Yup from 'yup';
import { useFormikContext } from 'formik-2';
import css from './ActivationRules.css';
import Tooltip from '../../../../components/widgets/Tooltip';
import XIcon from '../../../../resources/svg/icon_remove_row.svg';
import PlusIcon from '../../../../resources/svg/icon_add_row.svg';
import RadioButtonsGroup from '../../../../components/widgets/RadioButtonsGroup';
import { getUrlPatternOperatorKey, getUrlPatternValueKey, getDeviceTypeKey, getMaxLengthErrMsg } from '../../../utils';

import {
    EMPTY_OPTIONS,
    CUSTOM_OPTION,
    DEFAULT_OPTION,
    URL_PATTERN_OPERATOR_KEY,
    UrlPatternOperator,
    NO_LIMIT,
    PLATFORMS,
    DEVICE_TYPES,
} from '../../../consts';
import {
    AutoCompleteFieldEnhanced,
    DropdownFieldEnhanced,
    FormField,
    useFieldHelper,
} from '../../../../utils/components/formUtils';
import Label from '../../../../components/widgets/Label';
import { getCountries } from '../../../selectors';
import Checkbox from '../../../../components/widgets/Checkbox';

// TODO: Move to server
const LANGUAGES_OPTIONS = ['en-US', 'en-GB'];
const TIMES_TO_DISPLAY_OPTIONS = [NO_LIMIT, ...Array.from(Array(10).keys(), (_, i) => i + 1)];
const MAX_URL_PATTERNS = 10;

export const ACTIVATION_RULES_VALIDATION_SCHEMA = Array.from(Array(MAX_URL_PATTERNS).keys()).reduce(
    (acc, key) => ({
        ...acc,
        [`url_pattern_${key}`]: Yup.string()
            .max(100, getMaxLengthErrMsg('Url Pattern', 100))
            .nullable(),
    }),
    {}
);

export default function ActivationRules() {
    const { values, touched, errors, setValues } = useFormikContext();

    const getDefinedUrlPatterns = () => {
        if (!values) return [];

        return Object.entries(values)
            .filter(([key, _]) => key.startsWith(URL_PATTERN_OPERATOR_KEY))
            .map(([_, value]) => value);
    };

    const definedUrlPatterns = getDefinedUrlPatterns(values);
    /*
    const countries = useSelector(state => getCountries(state));
    const [convertToAutoComplete, sortAutoCompleteOptions] = useFieldHelper(setValues, {}, values);
    const countryOptions = convertToAutoComplete(countries).sort(sortAutoCompleteOptions);
    const languageOptions = LANGUAGES_OPTIONS.map(option => ({ value: option, label: option }));
    const timesToVisitOptions = TIMES_TO_DISPLAY_OPTIONS.map(option => ({ name: option, display_name: option }));
    */
    const setDeviceType = (platform, deviceType, checked) => {
        const { device_types: deviceTypes = [], ...rest } = values;
        const platformDeviceType = getDeviceTypeKey(platform, deviceType);

        const activeDeviceTypes = !checked
            ? deviceTypes.filter(type => type !== platformDeviceType)
            : [...deviceTypes, platformDeviceType];

        setValues({ ...rest, device_types: activeDeviceTypes });
    };

    const isChecked = (platform, deviceType) => {
        if (!values.device_types || !values.device_types.length) {
            return false;
        }

        return values.device_types.some(type => type === getDeviceTypeKey(platform, deviceType));
    };

    return (
        <Fragment>
            <div>
                <RadioButtonsGroup
                    className={css.urlOptions}
                    radioItems={[
                        {
                            label: 'STATIC.PAGES.BANNERS.BANNER_SHELF.ALL_PAGES_OPTION',
                            checked: values.showOnAllPages,
                            id: DEFAULT_OPTION,
                        },
                        {
                            label: 'STATIC.PAGES.BANNERS.BANNER_SHELF.CUSTOM_PAGES_OPTION',
                            checked: !values.showOnAllPages,
                            id: CUSTOM_OPTION,
                        },
                    ]}
                    onChange={({ id }) => setValues({ ...values, showOnAllPages: id === DEFAULT_OPTION })}
                />
                <Label text="STATIC.PAGES.BANNERS.BANNER_SHELF.URL_LABEL" type="fieldLabel" />
                {values.showOnAllPages ? (
                    <UrlPatternComponent disabled />
                ) : (
                    Array(definedUrlPatterns.length)
                        .fill()
                        .map((_, i) => (
                            <UrlPatternComponent
                                index={i}
                                key={`${getUrlPatternOperatorKey(i)}${getUrlPatternValueKey(i)}`}
                                values={values}
                                setValues={setValues}
                                errors={errors}
                                touched={touched}
                                numberOfDefinedPatterns={definedUrlPatterns.length}
                            />
                        ))
                )}
            </div>
            {/*
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="countries_whitelist"
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.GEO_LABEL"
                    labelTooltip="STATIC.PAGES.BANNERS.BANNER_SHELF.GEO_TOOLTIP"
                    options={countryOptions}
                    searchable
                    value={values.countries_whitelist || []}
                    disabled
                />
            </FormField>
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="languages"
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.LANGUAGE_LABEL"
                    labelTooltip="STATIC.PAGES.BANNERS.BANNER_SHELF.LANGUAGE_TOOLTIP"
                    options={languageOptions}
                    searchable
                    value={values.languages || []}
                    disabled
                />
            </FormField>
            */}
            {/* <FormField>*/}
            {/*    <Label text="STATIC.PAGES.BANNERS.BANNER_SHELF.TIMES_TO_DISPLAY_LABEL" type="fieldLabel" />*/}
            {/*    <DropdownFieldEnhanced*/}
            {/*        containerClass={css.timesToDisplay}*/}
            {/*        name="times_to_display"*/}
            {/*        items={timesToVisitOptions}*/}
            {/*        selected={values.times_to_display || NO_LIMIT}*/}
            {/*    />*/}
            {/* </FormField>*/}
            <div>
                <Label text="STATIC.PAGES.BANNERS.BANNER_SHELF.DEVICE_TYPES_LABEL" type="fieldLabel" />
                <div className={css.platformsContainer}>
                    {PLATFORMS.map(platform => (
                        <div key={platform} className={css.platformDeviceTypes}>
                            <div className={css.platformLabel}>
                                <Translate id={`STATIC.PLATFORMS.${platform.toUpperCase()}`} />:
                            </div>
                            <div className={css.deviceTypes}>
                                {DEVICE_TYPES.map(deviceType => (
                                    <Checkbox
                                        size="small"
                                        key={deviceType}
                                        className={css.deviceTypeCheckbox}
                                        checked={isChecked(platform, deviceType)}
                                        onChange={checked => setDeviceType(platform, deviceType, checked)}
                                        label={
                                            <Translate
                                                id={`STATIC.PAGES.BANNERS.BANNER_SHELF.DEVICE_TYPE_${deviceType.toUpperCase()}`}
                                            />
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
}

ActivationRules.propTypes = {
    values: PropTypes.objectOf(PropTypes.any),
    setValues: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.any),
    errors: PropTypes.objectOf(PropTypes.any),
};

ActivationRules.defaultProps = {
    values: {},
    setValues: () => {},
    touched: {},
    errors: {},
};

const OPERATOR_OPTIONS = Object.values(UrlPatternOperator).map(name => ({
    name,
    display_name: `STATIC.PAGES.BANNERS.BANNER_SHELF.${name.toUpperCase()}`,
}));

function UrlPatternComponent({ index, disabled, values, setValues, numberOfDefinedPatterns, touched, errors }) {
    const operatorKey = getUrlPatternOperatorKey(index);
    const valueKey = getUrlPatternValueKey(index);

    const _getAddDisabledReason = () => {
        if (!values[valueKey]) {
            return 'STATIC.PAGES.BANNERS.BANNER_SHELF.URL_PATTERN_ADD_DISABLED';
        } else if (numberOfDefinedPatterns > MAX_URL_PATTERNS) {
            return 'STATIC.PAGES.BANNERS.BANNER_SHELF.MAX_URL_PATTERNS';
        }

        return null;
    };

    const _onAdd = () => {
        setValues({ ...values, [getUrlPatternOperatorKey(index + 1)]: null });
    };

    const _onDelete = () => {
        const valuesCopy = { ...values };

        delete valuesCopy[operatorKey];
        delete valuesCopy[valueKey];

        setValues(valuesCopy);
    };

    const isLastPattern = index === numberOfDefinedPatterns - 1;

    const addDisableReason = _getAddDisabledReason();
    const isAddDisabled = addDisableReason != null;
    const isDeleteDisabled = index === 0;

    return (
        <div className={css.urlPatternContainer}>
            <DropdownFieldEnhanced
                containerClass={css.select}
                name={operatorKey}
                disabled={disabled}
                items={OPERATOR_OPTIONS}
                selected={values[operatorKey]}
            />
            <AutoCompleteFieldEnhanced
                containerClass={css.value}
                name={valueKey}
                disabled={disabled}
                error={touched[valueKey] && errors[valueKey]}
                isMulti={false}
                options={EMPTY_OPTIONS}
                searchable
                value={values[valueKey]}
            />
            <div className={classNames(css.iconsContainer, { [css.hidden]: !isLastPattern || disabled })}>
                <Tooltip
                    disabled={!isDeleteDisabled}
                    titleTranslationKey="STATIC.PAGES.BANNERS.BANNER_SHELF.FIRST_URL_PATTERN_DELETE_DISABLED"
                >
                    <XIcon
                        className={classNames(css.xIcon, css.icon, { [css.disabled]: isDeleteDisabled })}
                        onClick={_onDelete}
                    />
                </Tooltip>
                <Tooltip disabled={!isAddDisabled} titleTranslationKey={addDisableReason}>
                    <PlusIcon
                        className={classNames(css.plusIcon, css.icon, { [css.disabled]: isAddDisabled })}
                        onClick={_onAdd}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

UrlPatternComponent.propTypes = {
    index: PropTypes.number,
    disabled: PropTypes.bool,
    numberOfDefinedPatterns: PropTypes.number,
    values: PropTypes.objectOf(PropTypes.any),
    setValues: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.any),
    errors: PropTypes.objectOf(PropTypes.any),
};

UrlPatternComponent.defaultProps = {
    index: 0,
    numberOfDefinedPatterns: 0,
    disabled: false,
    values: {},
    setValues: () => {},
    touched: {},
    errors: {},
};
