import React, {Fragment, useContext, useState} from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import moment from 'moment';
import { withLocalize } from 'react-localize-redux';
import { PageHeader, PageWrapper } from '../components/partials';
import { READ_USAGE } from './queries';
import { store, StateProvider } from './store';
import { reportDataToTableData } from '../utils/grids';
import Grid from '../components/partials/Grid';
import LineChart from '../components/partials/LineChart';
import Datepicker from '../components/widgets/Datepicker';

let colId = 0;
const GRID_METADATA = {
    connectorName: {
        type: 'dimension',
        name: 'connectorName',
        display_name: 'Connector Name',
        id: ++colId,
    },
    jobDate: {
        type: 'dimension',
        name: 'jobDate',
        display_name: 'Job Date',
        id: ++colId,
    },
    startDate: {
        type: 'dimension',
        name: 'startDate',
        display_name: 'Start Date',
        id: ++colId,
    },
    endDate: {
        type: 'dimension',
        name: 'endDate',
        display_name: 'End Date',
        id: ++colId,
    },
    rows: {
        type: 'metric',
        name: 'rows',
        display_name: 'Rows',
        id: ++colId,
        display_format: {
            type: 'number',
            precision: 0
        },
    },
};

function UsageReport({ startDate, endDate }) {
    const { state, setState } = useContext(store);
    const { loading, error, data } = useQuery(READ_USAGE, {
        variables: {
            startDate: startDate,
            endDate: endDate,
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (data.usage.length > 0 && state.usage.length === 0) {
        state.usage = data.usage;
        setState((x) => {
            return { ...x, usage: data.usage };
        });
    }

    if (data.usage.length === 0) {
        return <div>No usage records available yet!</div>;
    }

    function getChartData() {
        const dimensions = {};
        const rows = {};
        data.usage.forEach((consumptionRecord) => {
            if (!dimensions[consumptionRecord.connectorName])
                dimensions[consumptionRecord.connectorName] = consumptionRecord.connectorName;

            if (!rows[consumptionRecord.jobDate]) {
                rows[consumptionRecord.jobDate] = {
                    date: consumptionRecord.jobDate,
                };
            }

            rows[consumptionRecord.jobDate][consumptionRecord.connectorName] =
                (rows[consumptionRecord.jobDate][consumptionRecord.connectorName] || 0) +
                consumptionRecord.rows;
        });

        return {
            dimensions,
            metrics: [
                {
                    id: 0,
                    name: 'rows',
                    display_name: 'Rows',
                    format: { type: 'number', precision: 0 },
                    chart_type: 'bar',
                },
            ],
            rows,
            orgOptions: {
                metricsLimit: 3,
            },
        };
    }

    try {
        const tableData = reportDataToTableData(
            'reports',
            { metadata: GRID_METADATA, results: data.usage, total: {} },
            '',
            false,
            { status: null },
            false
        );
        console.log('tableData', tableData);

        const chartData = getChartData();
        console.log('chartData', tableData);

        return (
            <Fragment>
                <LineChart
                    chartData={chartData}
                    timeBreakdown="day"
                    chart={{
                        showError: false,
                        lines: 5,
                        selectedMetrics: [],
                    }}
                    currentLanguage="en"
                    showLegend={false}
                    showTopLinesInput={false}
                />
                <Grid reportType="reports" {...tableData} scrollOnlyOnPageBottom pivotMode />
            </Fragment>
        );
    } catch (e) {
        console.log('Grid exception', e);
    }
}

function Page({ languages }) {
    const [dates, setDates] = useState({
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    });

    return (
        <StateProvider>
            <PageWrapper style={{ minHeight: 500 }}>
                <PageHeader text="Usage" leftComponents={[]} rightComponent={[]} />
                {languages.length > 0 && (
                    <Datepicker
                        {...dates}
                        onApply={(newDates) => {
                            console.log('NEW DATES', newDates);
                            setDates({ startDate: newDates.start_date, endDate: newDates.end_date });
                        }}
                    />
                )}
                <UsageReport {...dates} />
            </PageWrapper>
        </StateProvider>
    );
}

Page.propTypes = {
    languages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withLocalize(Page);
