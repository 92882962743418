import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Checkbox } from 'components/widgets';

class AddFutureCheckbox extends React.PureComponent {
    render() {
        const { itemType, addFuture, onCheckboxChanged } = this.props;
        return (
            <Checkbox
                style={{
                    marginBottom: 0,
                }}
                label={
                    <Translate
                        id="STATIC.PAGES.TEAM_MEMBERS.AUTO_ADD_FUTURE_ITEMS"
                        data={{
                            itemType,
                        }}
                    />
                }
                checked={addFuture}
                onChange={onCheckboxChanged}
            />
        );
    }
}

AddFutureCheckbox.propTypes = {
    itemType: PropTypes.string,
    addFuture: PropTypes.bool,
    onCheckboxChanged: PropTypes.func,
};

AddFutureCheckbox.defaultProps = {
    itemType: {},
    addFuture: false,
    onCheckboxChanged: () => {},
};

export default AddFutureCheckbox;
