import { connect } from 'react-redux';
import { TrackingLinksMain } from '../components/pages';
import * as actions from '../actions/trackingLinks';

const mapStateToProps = state => state.trackingLinks;

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addFilter: () => {
            dispatch(actions.addFilter());
        },
        removeFilter: index => {
            dispatch(actions.removeFilter(index));
        },
        changeFilter: (index, key, data, tagAction) => {
            dispatch(actions.changeFilter(index, key, data, tagAction));
        },
        addFilterTag: (filterIndex, tag) => {
            dispatch(actions.addFilterTag(filterIndex, tag));
        },
        removeFilterTag: (filterIndex, tag) => {
            dispatch(actions.removeFilterTag(filterIndex, tag));
        },
        clearFilterTags: (filterIndex, tags) => {
            dispatch(actions.clearFilterTags(filterIndex, tags));
        },
        collapseAll: () => {
            dispatch(actions.collapseAll());
        },
        expandAll: () => {
            dispatch(actions.expandAll());
        },
        toggleExpandApp: id => {
            dispatch(actions.toggleExpandApp(id));
        },
        toggleExpandNetwork: (app, network) => {
            dispatch(actions.toggleExpandNetwork(app, network));
        },
        toggleExpandCampaign: campaign => {
            dispatch(actions.toggleExpandCampaign(campaign));
        },
        handleFilterSelector: filterType => {
            dispatch(actions.handleFilterSelector(filterType.id));
        },
        updateSearch: link => {
            dispatch(actions.updateSearch(link));
        },
        searchTrackingLink: shouldClear => {
            dispatch(actions.searchTrackingLink(shouldClear));
        },
        deleteClicked: campaign => {
            dispatch(actions.deleteCampaignClicked(campaign));
        },
        deleteConfirmed: campaignIDToDelete => {
            dispatch(actions.deleteCampaignConfirmed(campaignIDToDelete));
        },
        deleteDismissed: () => {
            dispatch(actions.deleteCampaignDismissed());
        },
        editClicked: campaign => {
            dispatch(actions.editCampaignNameClicked(campaign));
        },
        editCampaignName: (editingCampaignID, newCampaignName, campaign, app) => {
            dispatch(actions.editCampaignNameConfirmed(editingCampaignID, newCampaignName, campaign, app));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLinksMain);
