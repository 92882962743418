import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import css from '../style.css';
import SingularLoader from '../../../../dashboard/js/common/components/SingularLoader';
import {
    HIDE_SIDEBAR_ON_WIDTH,
    HOMEPAGE_SECTION_NAME,
    MENU_SECTION_CLICKED_MIXPANEL_EVENT_NAME,
    MENU_SECTIONS_MIXPANEL_PREFIX,
    SIDENAV_SLIM_WIDTH,
    SIDENAV_WIDTH,
    TAGS,
} from '../consts';
import { reportTypes } from '../../utils/reports';
import { trackMixpanelEvent } from '../../utils/general';
import { getAdminModeEnabled, getIsTrialUser } from '../../selectors/user';
import OnboardingProgressBar from '../../onboardingGuide/components/OnboardingProgressBar';
import { getIsPlanYourOnboardingTaskComplete, getOnboardingProgressData } from '../../homepage/selector';
import { showOnboardingProgressBar } from '../../homepage/utils';
import { REACT_ROUTES_SET } from '../../config';
import { getSelectedSection } from '../utils';
import MemoChevronIcon from './MemoChevronIcon';

function Sidenav({ children, translate, sections, shouldHideSidenav }) {
    const location = useLocation();
    const navigate = useNavigate();
    const adminMode = useSelector(state => getAdminModeEnabled(state));
    const isTrialUser = useSelector(state => getIsTrialUser(state));
    const isQueryRunning = useSelector(state => {
        return (
            state?.reports?.creativesGalleryReportRunning ||
            Object.values(reportTypes).some(type => state[type] && state[type].queryRunning)
        );
    });
    const isOnboardingPlanningDone = useSelector(state => getIsPlanYourOnboardingTaskComplete(state));
    const onboardingProgressBarData = useSelector(state => getOnboardingProgressData(state));

    const [selectedSection, setSelectedSection] = useState({});
    const [slim, setSlim] = useState(false);
    const [forceSlim, setForceSlim] = useState(false);
    const isReactRoute = REACT_ROUTES_SET.has(location.pathname);

    useEffect(() => {
        if (sections && (!selectedSection.name || slim)) {
            const { pathname } = location;
            setSelectedSection(getSelectedSection(sections, pathname) || {});
        }
    }, [sections, slim]);

    useEffect(() => {
        const { pathname } = location;
        sections && setSelectedSection(getSelectedSection(sections, pathname) || {});
    }, [location]);

    useEffect(() => {
        const angularContainer = document.getElementById('content');

        if (!angularContainer) {
            return;
        }

        if (!sections) {
            angularContainer.style.display = 'none';
        }

        if (sections && !isReactRoute) {
            setTimeout(() => {
                angularContainer.style.display = 'inherit';
                angularContainer.style.marginLeft = `${slim ? SIDENAV_SLIM_WIDTH : SIDENAV_WIDTH}px`;
            }, 0);
        }
    }, [isReactRoute, sections, slim]);

    const dispatchToggleEvent = () => {
        window.dispatchEvent(new CustomEvent('sidebarToggled'));
    };

    const toggleSidebar = () => {
        dispatchToggleEvent();
        trackMixpanelEvent('Sidenav', 'Toggle', {
            action: slim ? 'open' : 'close',
        });

        setSlim(!slim);
    };

    const setSlimSidebar = isSlim => {
        dispatchToggleEvent();
        setSlim(isSlim);
    };

    const navigateToPage = link => {
        window.location.href = `#${link}`;

        // Bug Fix - make sure React triggers a URL change when going from React to Angular
        const navigateToAngularRoute = !REACT_ROUTES_SET.has(link);
        if (isReactRoute && navigateToAngularRoute) {
            navigate(link, { replace: true });
        }

        // cancels the default browser behaviour for <a> tag
        return false;
    };

    useEffect(() => {
        if (isQueryRunning && !slim) {
            setSlimSidebar(true);
        }
    }, [isQueryRunning]);

    useEffect(() => {
        if (!isOnboardingPlanningDone && selectedSection.name === HOMEPAGE_SECTION_NAME && !slim) {
            setSlimSidebar(true);
        }
    }, [isOnboardingPlanningDone, selectedSection]);

    useEffect(() => {
        const handleWindowResize = debounce(() => {
            if (window.innerWidth < HIDE_SIDEBAR_ON_WIDTH && !slim) {
                setSlimSidebar(true);
                setForceSlim(true);
            }

            if (window.innerWidth > HIDE_SIDEBAR_ON_WIDTH && slim && forceSlim) {
                setSlimSidebar(false);
                setForceSlim(false);
            }
        }, 200);

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [slim, forceSlim]);

    const getSectionTag = section => {
        let tag = null;

        if (section.new) {
            tag = TAGS.NEW;
        } else if (section.beta) {
            tag = TAGS.BETA;
        } else if (section.trial) {
            tag = TAGS.TRIAL;
        } else if (section.deprecated) {
            tag = <img src="/static/dashboard/img/deprecation_icon.svg" />;
        }

        return <>{tag ? <sup className="sidebar-el-tag">{tag}</sup> : null}</>;
    };

    const getSectionContent = section => {
        const tooltipTheme = createTheme({
            overrides: {
                MuiTooltip: {
                    tooltip: {
                        margin: '0px 4px !important',
                        padding: '13px 0px',
                        minWidth: '170px',
                        borderRadius: '8px',
                    },
                },
            },
        });

        const sectionElement = (
            <div
                onClick={() => {
                    if (section.elements.length) {
                        setSelectedSection(selectedSection.name === section.name && !slim ? {} : section);
                        trackMixpanelEvent(MENU_SECTIONS_MIXPANEL_PREFIX, MENU_SECTION_CLICKED_MIXPANEL_EVENT_NAME, {
                            section_name: section.display_name,
                        });
                    }
                }}
                className={classnames(css.navSection, {
                    [css.externalLinkSection]: section.external_link,
                    [css.slimMode]: slim,
                })}
            >
                <div style={{ display: 'inline' }}>
                    {slim && <MemoChevronIcon className={css.slimIconChevron} />}
                    <img className={classnames(css.navLogo, { [css.slimMode]: slim })} src={section.icon} />
                    {!slim && <span>{translate(section.display_name)}</span>}
                </div>
                {section.external_link && (
                    <img
                        className={css.externalLinkIcon}
                        src="/static/dashboard/img/icons/export-external-link.svg"
                        alt=""
                    />
                )}
                {!slim && getSectionTag(section)}
            </div>
        );

        const tooltipContent = (
            <div
                style={{ fontSize: '14px' }}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <List disablePadding>
                    <ListItem
                        style={{
                            padding: `0 15px 0 20px`,
                            fontFamily: '"Open Sans", sans-serif',
                            lineHeight: '25px',
                            textDecoration: 'none',
                            color: 'white',
                            fontWeight: '600',
                        }}
                        href={`#${section.link}`}
                        component={section.link ? 'a' : 'span'}
                        button={!!section.link}
                    >
                        <ListItemText
                            style={{ fontFamily: '"Open Sans", sans-serif' }}
                            primary={translate(section.display_name.toString())}
                            disableTypography
                        />
                    </ListItem>
                    {!!section.elements.length && (
                        <>
                            <div className={css.navSeparator} />
                            {section.elements
                                .filter(el => !(el.admin_only_visible && !adminMode))
                                .map(el => (
                                    <ListItem
                                        style={{
                                            paddingLeft: '20px',
                                            margin: '0',
                                            height: '35px',
                                            fontFamily: '"Open Sans", sans-serif',
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        key={el.link}
                                        className={classnames(css.navbarTooltipSubsectionItem)}
                                        button
                                        component="a"
                                        href={`#${el.link}`}
                                    >
                                        <ListItemText primary={translate(el.display_name)} disableTypography />
                                    </ListItem>
                                ))}
                        </>
                    )}
                </List>
            </div>
        );

        return (
            <>
                {slim ? (
                    <ThemeProvider theme={tooltipTheme}>
                        <Tooltip
                            classes={{ tooltip: css.tooltip }}
                            title={tooltipContent}
                            interactive="true"
                            placement="right-start"
                        >
                            {sectionElement}
                        </Tooltip>
                    </ThemeProvider>
                ) : (
                    sectionElement
                )}
                {!!section.elements.length && section.name === selectedSection.name && !slim && (
                    <ul>
                        {section.elements.map(subSector => {
                            if (subSector.admin_only_visible && !adminMode) {
                                return null;
                            }

                            return (
                                <li className={css.navSubSection} key={subSector.name}>
                                    <a
                                        href={`#${subSector.link}`}
                                        onClick={() => navigateToPage(subSector.link)}
                                        className={classnames(css.subSectionLink, {
                                            [css.selected]: location.pathname === subSector.link,
                                        })}
                                    >
                                        {translate(subSector.display_name)}
                                        {getSectionTag(subSector)}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </>
        );
    };

    const footer = (
        <div className={css.footer}>
            <div>© Singular {new Date().getFullYear()}</div>
            <div>
                <a href="https://www.singular.net/terms/" target="_blank" rel="noreferrer" className={css.footerLink}>
                    Terms and Conditions
                </a>
                <span className={css.footerLink}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <a
                    href="https://www.singular.net/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className={css.footerLink}
                >
                    Privacy Policy
                </a>
            </div>
        </div>
    );

    let isExternalLinkSeen = false;

    const sidenavElement = !sections ? (
        <SingularLoader />
    ) : (
        <>
            {isReactRoute && (
                <div className={classnames(css.bodyContainer, css.widthAnimation, { [css.slimMode]: slim })}>
                    {children}
                </div>
            )}
            <div
                id="sidenav"
                className={classnames(css.sidenavContainer, css.widthAnimation, {
                    [css.slimMode]: slim,
                })}
            >
                <div className={css.slimButtonSector}>
                    <div onClick={toggleSidebar} className={classnames(css.slimButton, { [css.slimMode]: slim })}>
                        <MemoChevronIcon
                            className={classnames(css.slimButtonIcon, {
                                [css.chevronRight]: slim,
                                [css.chevronLeft]: !slim,
                            })}
                        />
                    </div>
                </div>
                <div
                    className={classnames(css.sidenavSections, { [css.slimMode]: slim })}
                    onClick={() => setSlimSidebar(false)}
                >
                    {sections.map(section => {
                        if (
                            (section.admin_only_visible && !adminMode) ||
                            (slim && section.external_link) ||
                            (section.name === 'help_center' && !isTrialUser)
                        ) {
                            return null;
                        }

                        const isSelected =
                            location.pathname === section.link || (slim && section.name === selectedSection.name);

                        const sectionElement = section.elements.length ? (
                            <div
                                className={classnames(css.navSectionContainer, {
                                    [css.selected]: isSelected,
                                })}
                            >
                                {getSectionContent(section)}
                            </div>
                        ) : (
                            <a
                                className={classnames(css.navSectionContainer, css.linkSection, {
                                    [css.selected]: isSelected,
                                })}
                                href={section.external_link ? section.link : `#${section.link}`}
                                target={section.external_link ? '_blank' : '_self'}
                                rel="noreferrer"
                            >
                                {getSectionContent(section)}
                                {!slim && section.progress_bar && showOnboardingProgressBar(onboardingProgressBarData) && (
                                    <div className={css.progressBarContainer}>
                                        <OnboardingProgressBar
                                            containerColor="var(--blue400)"
                                            donePercentage={onboardingProgressBarData.donePercentage}
                                            barHeight="7px"
                                        />
                                        <div className={css.progressBarText}>
                                            {`${onboardingProgressBarData.doneTasks}/${
                                                onboardingProgressBarData.totalTasks
                                            } 
                                                ${translate('STATIC.PAGES.SIDEBAR_PROGRESS_BAR.NEXT_STEP')}:
                                                ${translate(
                                                    `STATIC.PAGES.SIDEBAR_PROGRESS_BAR.${onboardingProgressBarData.nextTask}`
                                                )}`}
                                        </div>
                                    </div>
                                )}
                            </a>
                        );

                        let externalLinkSeparator = null;

                        if (section.external_link && !isExternalLinkSeen && !slim) {
                            isExternalLinkSeen = true;
                            externalLinkSeparator = <div className={css.navSeparator} />;
                        }

                        return (
                            <div key={section.name}>
                                {externalLinkSeparator}
                                {sectionElement}
                            </div>
                        );
                    })}
                </div>
                {!slim && footer}
            </div>
        </>
    );

    return <div className={css.mainContainer}>{shouldHideSidenav ? children : sidenavElement}</div>;
}

Sidenav.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    translate: PropTypes.func,
    sections: PropTypes.arrayOf(PropTypes.object),
    shouldHideSidenav: PropTypes.bool,

};

Sidenav.defaultProps = {
    translate: () => {},
    sections: [],
    shouldHideSidenav: false,
};

export default withLocalize(Sidenav);
