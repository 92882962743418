export const CUSTOM_EVENTS_PAGE_LOADED = 'action.events.custom.events.page.loaded';
export const CUSTOM_EVENTS_PAGE_UN_LOADED = 'action.events.custom.events.page.unloaded';
export const UPDATE_CUSTOM_EVENTS = 'action.events.update.custom.events';
export const UPDATE_RELATED_REPORTS = 'action.events.update.custom.events.related.reports';
export const UPDATE_UAN_EVENTS = 'action.events.update.uan.events';
export const UPDATE_PUBLIC_IDS = 'action.events.update.public.ids';
export const TOGGLE_EVENT_EDITOR = 'action.events.toggle.event.editor';
export const TOGGLE_EVENT_EDITOR_CLICKED = 'action.events.toggle.event.editor.clicked';
export const UAN_EVENT_ADDED = 'action.events.uan.event.added';
export const UAN_EVENT_REMOVED = 'action.events.uan.event.removed';
export const TOGGLE_EVENT_CALCULATED_METRIC = 'action.events.toggle.event.calculated.metric';
export const EVENT_EDITOR_CANCEL_CLICKED = 'action.events.editor.cancel.clicked';
export const EVENT_EDITOR_SAVE_CLICKED = 'action.events.editor.save.clicked';
export const EVENT_EDITOR_DELETE_CLICKED = 'action.events.editor.delete.clicked';
export const CUSTOM_EVENTS_ADD_NEW = 'action.events.add.new';
export const SAVING_CUSTOM_EVENT = 'action.events.saving.custom.event';
export const SAVE_CUSTOM_EVENT_SUCCESS = 'action.events.save.custom.event.success';
export const FRONTEND_CACHE_LOAD = 'action.events.reports.cache';
export const SAVE_CUSTOM_EVENT_FAILED = 'action.events.save.custom.event.failed';
export const DELETE_CUSTOM_EVENT_SUCCESS = 'action.events.delete.custom.event.success';
export const CUSTOM_EVENT_NAME_CHANGED = 'action.events.event.name.changed';
export const EVENT_EDITOR_UNIQUE_TOGGLE = 'action.events.editor.unique.toggle';
export const EVENT_EDIT_TYPE_CHANGING = 'action.events.edit.type.changing';
export const EVENT_EDIT_TYPE_CHANGED = 'action.events.edit.type.changed';
export const EVENT_EDIT_APP_SPECIFIC_SELECTED = 'action.events.edit.app.specific.selected';
export const CUSTOM_EVENTS_SET_ACTIVE_GROUP_TYPE = 'action.events.update.active_group_type';
export const CUSTOM_EVENTS_CATEGORY_TAB_CLICK = 'action.events.category.click';
export const REFRESH_VIEW = 'action.events.refresh.view';

export const updateCustomEvents = data => ({
    type: UPDATE_CUSTOM_EVENTS,
    data,
});

export const updateRelatedReports = data => ({
    type: UPDATE_RELATED_REPORTS,
    data,
});

export const handleFrontendCacheLoad = loadTime => ({
    type: FRONTEND_CACHE_LOAD,
    loadTime,
});

export const updateUanEvents = data => ({
    type: UPDATE_UAN_EVENTS,
    data,
});

export const updatePublicIds = data => ({
    type: UPDATE_PUBLIC_IDS,
    data,
});

export const toggleEventEditor = id => ({
    type: TOGGLE_EVENT_EDITOR,
    id,
});

export const toggleEventEditorClicked = id => ({
    type: TOGGLE_EVENT_EDITOR_CLICKED,
    id,
});

export const uanEventAdded = (uanEvent, customEvent, uan) => ({
    type: UAN_EVENT_ADDED,
    uanEvent,
    customEvent,
    uan,
});

export const uanEventRemoved = (uanEvent, customEvent, uan) => ({
    type: UAN_EVENT_REMOVED,
    uanEvent,
    customEvent,
    uan,
});

export const toggleEventCalculatedMetric = (calculatedMetric, customEvent, value) => ({
    type: TOGGLE_EVENT_CALCULATED_METRIC,
    calculatedMetric,
    customEvent,
    value,
});

export const cancelClicked = () => ({
    type: EVENT_EDITOR_CANCEL_CLICKED,
});

export const saveClicked = customEvent => ({
    type: EVENT_EDITOR_SAVE_CLICKED,
    customEvent,
});
export const deleteClicked = customEvent => ({
    type: EVENT_EDITOR_DELETE_CLICKED,
    customEvent,
});

export const addNewCustomEvent = isStandardEventsLayoutActive => ({
    type: CUSTOM_EVENTS_ADD_NEW,
    isStandardEventsLayoutActive,
});

export const savingCustomEvent = customEvent => ({
    type: SAVING_CUSTOM_EVENT,
    customEvent,
});

export const saveCustomEventSuccess = (customEvent, response, backupEvent) => ({
    type: SAVE_CUSTOM_EVENT_SUCCESS,
    customEvent,
    response,
    backupEvent,
});

export const saveCustomEventFailed = (customEvent, error) => ({
    type: SAVE_CUSTOM_EVENT_FAILED,
    customEvent,
    error,
});

export const customEventNameChanged = (customEvent, value) => ({
    type: CUSTOM_EVENT_NAME_CHANGED,
    customEvent,
    value,
});

export const deleteCustomEventSuccess = (customEvent, response) => ({
    type: DELETE_CUSTOM_EVENT_SUCCESS,
    customEvent,
    response,
});

export const editorUniqueToggle = customEvent => ({
    type: EVENT_EDITOR_UNIQUE_TOGGLE,
    customEvent,
});

export const customEventsPageLoaded = () => ({
    type: CUSTOM_EVENTS_PAGE_LOADED,
});

export const customEventsPageUnLoaded = () => ({
    type: CUSTOM_EVENTS_PAGE_UN_LOADED,
});

export const eventEditTypeChanged = selected => ({
    type: EVENT_EDIT_TYPE_CHANGED,
    selected,
});

export const eventEditTypeChanging = selected => ({
    type: EVENT_EDIT_TYPE_CHANGING,
    selected,
});

export const eventEditAppSpecificSelected = selected => ({
    type: EVENT_EDIT_APP_SPECIFIC_SELECTED,
    selected,
});

export const setActiveGroupType = groupType => ({
    type: CUSTOM_EVENTS_SET_ACTIVE_GROUP_TYPE,
    groupType,
});

export const customEventsCategoryClick = groupType => ({
    type: CUSTOM_EVENTS_CATEGORY_TAB_CLICK,
    groupType,
});

export const refreshView = () => ({
    type: REFRESH_VIEW,
});
