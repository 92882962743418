import React from 'react';
import PropTypes from 'prop-types';
import { APPLE_SEARCH_ADS_PARTNER_NAME, FACEBOOK_PARTNER_NAME, HOTSTAR_NAME } from '../../../consts';
import { organizationOptionsShape, partnerShape, siteShape } from '../../types';
import AttributionWindows from './AttributionWindows';
import { OS } from '../../../../utils/OS';

export default function AttributionWindowsFactory({ partner, site, isReadonly, orgOptions }) {
    if (partner.name === FACEBOOK_PARTNER_NAME) {
        return (
            <AttributionWindows
                partner={partner}
                orgOptions={orgOptions}
                site={site}
                isReadonly={isReadonly}
                hiddenSliders={
                    site.platform === OS.IOS
                        ? {
                              installViewWindowProbabilistic: true,
                              reengClickWindowProbabilistic: true,
                              reengViewWindowProbabilistic: true,
                          }
                        : {
                              installClickWindowProbabilistic: true,
                              installViewWindowProbabilistic: true,
                              reengClickWindowProbabilistic: true,
                              reengViewWindowProbabilistic: true,
                          }
                }
                labels={{
                    installClickWindowProbabilistic: 'FACEBOOK_CLICK_THROUGH_PROBABILISTIC_WINDOW',
                }}
                tooltips={{
                    installClickWindowProbabilistic: 'FACEBOOK_CLICK_THROUGH_PROBABILISTIC_WINDOW_TOOLTIP',
                }}
                useSubTitle
                showNonDefaultWarning
            />
        );
    } else if (partner.name === HOTSTAR_NAME) {
        return (
            <AttributionWindows
                partner={partner}
                site={site}
                isReadonly={isReadonly}
                hiddenSliders={{ installViewWindowDeterministic: true }}
            />
        );
    } else if (partner.name === APPLE_SEARCH_ADS_PARTNER_NAME) {
        return (
            <AttributionWindows
                orgOptions={orgOptions}
                partner={partner}
                site={site}
                isReadonly={isReadonly}
                hiddenSliders={{
                    installViewWindowProbabilistic: true,
                    installClickWindowProbabilistic: true,
                }}
            />
        );
    }

    return <AttributionWindows orgOptions={orgOptions} partner={partner} site={site} isReadonly={isReadonly} />;
}

AttributionWindowsFactory.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    isReadonly: PropTypes.bool,
    orgOptions: organizationOptionsShape.isRequired
};
