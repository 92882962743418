import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import css from './LinkGroupHeader.css';
import { getCustomSourceLogo } from '../../utils/sources';

class LinkGroupHeader extends React.PureComponent {
    _getLogo() {
        const { logo } = this.props;
        if (!logo) {
            return <div className={`${css.logo} ${css.emptyLogo}`} />;
        }
        const CustomLogo = getCustomSourceLogo(logo);
        return CustomLogo ? (
            <CustomLogo className={`${css.logo} ${css.customLogo}`} />
        ) : (
            <img className={css.logo} src={logo} />
        );
    }

    render() {
        const { title, subTitle, expanded } = this.props;

        return (
            <div className={`${css.container} ${expanded ? css.expanded : ''}`}>
                {this._getLogo()}
                <div>
                    <div className={css.title}>{title}</div>
                    <div className={css.subTitle}>{subTitle}</div>
                </div>
                <div className={css.arrowArea}>
                    <ArrowDownIcon className={css.arrow} />
                </div>
            </div>
        );
    }
}

LinkGroupHeader.propTypes = {
    title: PropTypes.string,
    logo: PropTypes.string,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    expanded: PropTypes.bool,
};

LinkGroupHeader.defaultProps = {
    title: '',
    logo: '',
    subTitle: '',
    expanded: false,
};

export default LinkGroupHeader;
