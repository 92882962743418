import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import css from './BannerForm.css';
import BannerPreview from './BannerPreview';
import { SectionToElement } from './shelfSections';
import MobilePlaceholder from '../../../resources/svg/mobile_placeholder.svg';
import VerticalWizard from '../../../components/widgets/VerticalWizard';

function BannerForm({ sections, values }) {
    const cssStyleRef = useRef(null);

    useEffect(() => {
        cssStyleRef.current.innerHTML = values.css;
    }, [values.css]);

    const navbarSections = useMemo(
        () =>
            sections.reduce(
                (total, section) => [
                    ...total,
                    {
                        name: section,
                        displayName: `STATIC.PAGES.BANNERS.BANNER_SHELF.${section}`,
                        element: SectionToElement[section],
                    },
                ],
                []
            ),
        [sections]
    );

    return (
        <div className={css.bannerFormContainer}>
            <VerticalWizard className={css.bannerForm} sections={navbarSections} />
            <div className={css.bannerPreviewContainer}>
                <style ref={cssStyleRef} type="text/css" />
                <MobilePlaceholder />
                <div className={css.bannerPreview}>
                    <BannerPreview {...values} />
                </div>
            </div>
        </div>
    );
}

BannerForm.propTypes = {
    values: PropTypes.objectOf(PropTypes.any),
    sections: PropTypes.arrayOf(PropTypes.string),
};

BannerForm.defaultProps = {
    values: {},
    sections: [],
};

export default BannerForm;
