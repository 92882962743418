import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import posed, { PoseGroup } from 'react-pose';
import { AutoCompleteField } from '../../components/widgets';
import { getFields } from '../../selectors/fields';
import { getAdminModeEnabled } from '../../selectors/user';
import { getUserData } from '../../mta/selectors';
import fieldVisibilityRules from '../../selectors/reportsConfig/fieldVisibilityRules';
import { useAsync } from '../../utils/customHooks';
import DataSourcesService from '../service';
import { useFieldHelper } from '../../utils/components/formUtils';
import TrackerCustomSchemaFields from './customSchemaFields/TrackerCustomSchemaFields';
import { arrayToDict } from '../../utils/arrays';
import css from './DataDestinationShelf.css';
import SkanCustomSchemaFields from './customSchemaFields/SkanCustomSchemaFields';
import { getFieldDisplayName } from '../customShemaUtils';
import UnifiedCustomSchemaFields from './customSchemaFields/UnifiedCustomSchemaFields';

const TRACKER_SCHEMA_TYPE = 'tracker';
const SKAN_SCHEMA_TYPE = 'skan';
const UNIFIED_SCHEMA_TYPE = 'unified';

export const CustomFieldTypes = {
    REPORTS_DIMENSIONS: {
        id: 'reports_dimensions',
        type: 'custom_dimensions',
        label: 'STATIC.CUSTOM_DIMENSIONS',
        visibility: fieldVisibilityRules.reports,
    },
    CREATIVES_DIMENSIONS: {
        id: 'creatives_dimensions',
        type: 'custom_dimensions',
        label: 'STATIC.CUSTOM_DIMENSIONS',
        visibility: fieldVisibilityRules.creatives,
    },
    ADMON_DIMENSIONS: {
        id: 'admon_dimensions',
        type: 'custom_dimensions',
        label: 'STATIC.CUSTOM_DIMENSIONS',
        visibility: fieldVisibilityRules.adMonetization,
    },
    CONVERSION_EVENTS: {
        id: 'conversion_events',
        type: 'conversionEvents',
        label: 'STATIC.CONVERSION_EVENTS',
        visibility: fieldVisibilityRules.reports,
    },
};

export const SchemaToCustomFields = {
    campaign: [CustomFieldTypes.REPORTS_DIMENSIONS, CustomFieldTypes.CONVERSION_EVENTS],
    campaign_keyword: [CustomFieldTypes.REPORTS_DIMENSIONS, CustomFieldTypes.CONVERSION_EVENTS],
    campaign_publisher: [CustomFieldTypes.REPORTS_DIMENSIONS, CustomFieldTypes.CONVERSION_EVENTS],
    campaign_creative: [CustomFieldTypes.CREATIVES_DIMENSIONS],
    admon: [CustomFieldTypes.ADMON_DIMENSIONS],
};

const FieldAnimationItem = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 },
});

const DataSourcesAPI = new DataSourcesService();

export default function CustomSchemaFields({ schema, readonly, sourceType, onChange }) {
    const [schemaResponse, run] = useAsync();
    const [customFields, setCustomFields] = useState({});

    const { apiFields, userData, isAdmin } = useSelector(state => ({
        apiFields: getFields(state),
        userData: getUserData(state),
        isAdmin: getAdminModeEnabled(state),
    }));

    const [convertToAutoComplete, sortAutoCompleteOptions] = useFieldHelper();

    useEffect(async () => {
        if (readonly) {
            await run(DataSourcesAPI.getCustomSchemas(sourceType));
        } else {
            await run(DataSourcesAPI.getBaseSchemas(sourceType));
        }
    }, []);

    // clean fields when a different schema is selected
    useEffect(() => {
        setCustomFields({});
        onChange([]);
    }, [schema]);

    const getOptions = useCallback(
        (type, visibility, mandatoryFields = [], mappingFunc = field => field) => {
            const fields = apiFields[type].filter(field => visibility(field, isAdmin, userData)).map(mappingFunc);
            return convertToAutoComplete(fields, mandatoryFields).sort(sortAutoCompleteOptions);
        },
        [apiFields, isAdmin, userData]
    );

    const onFieldChanged = (type, selected) => {
        const updatedFields = { ...customFields, [type]: selected };
        const updatedFieldsMapping = Object.values(updatedFields).reduce(
            (total, fieldsArray) => ({
                ...total,
                ...arrayToDict(fieldsArray, ({ value, label }) => ({ [value]: getFieldDisplayName(label) })),
            }),
            {}
        );

        setCustomFields(updatedFields);
        onChange(updatedFieldsMapping);
    };

    const { type: schemaType, fields: selectedFields } = schemaResponse.data?.schemas[schema] || {};

    if (!schemaType) {
        return null;
    }

    const getSchemaCustomFields = () => {
        if (schemaType === TRACKER_SCHEMA_TYPE) {
            return (
                <TrackerCustomSchemaFields
                    readonly={readonly}
                    selected={selectedFields}
                    onChange={onChange}
                    getOptions={getOptions}
                />
            );
        }

        if (schemaType === SKAN_SCHEMA_TYPE) {
            return (
                <SkanCustomSchemaFields
                    readonly={readonly}
                    selected={selectedFields}
                    onChange={onChange}
                    getOptions={getOptions}
                />
            );
        }

        if (schemaType === UNIFIED_SCHEMA_TYPE) {
            return (
                <UnifiedCustomSchemaFields
                    readonly={readonly}
                    selected={selectedFields}
                    onChange={onChange}
                    getOptions={getOptions}
                />
            );
        }

        return SchemaToCustomFields[schemaType]?.map(({ id, type, label, visibility, mappingFunc }) => {
            const options = getOptions(type, visibility, [], mappingFunc);
            const selectedOptions = readonly ? options.filter(field => selectedFields.includes(field.value)) : [];

            if (readonly && !selectedOptions.length) {
                return null;
            }

            return (
                <AutoCompleteField
                    key={id}
                    disabled={readonly}
                    value={customFields[id] || selectedOptions}
                    clearable
                    searchable
                    label={label}
                    options={options}
                    onChange={selected => onFieldChanged(id, selected)}
                    onInputChange={selected => onFieldChanged(id, selected)}
                    containerClass={css.field}
                />
            );
        });
    };

    return (
        <PoseGroup animateOnMount>
            <FieldAnimationItem key={schemaType}>{getSchemaCustomFields()}</FieldAnimationItem>
        </PoseGroup>
    );
}

CustomSchemaFields.propTypes = {
    schema: PropTypes.string,
    readonly: PropTypes.bool,
    sourceType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

CustomSchemaFields.defaultProps = {
    schema: '',
    readonly: false,
};
