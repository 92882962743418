import API from '../services/api';

let instance = null;
const api = new API();

const TESTING_CONSOLE_DEVICE_ENDPOINT = 'api/sdk_console/device';
const TESTING_CONSOLE_LOGS_ENDPOINT = 'api/sdk_console/logs';
const AUDIT_SDK = 'api/audit/sdk';

export default class TestingConsoleAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getDevices() {
        return api.getJson(TESTING_CONSOLE_DEVICE_ENDPOINT, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    getLogs(deviceModelIds) {
        const options = {
            method: 'GET',
            credentials: 'include',
            params: {
                ids: deviceModelIds,
            },
            disableBrowserCache: true,
        };

        return api.getJson(TESTING_CONSOLE_LOGS_ENDPOINT, options);
    }

    triggerAudit() {
        return api.getJson(AUDIT_SDK, {
            method: 'GET',
            credentials: 'include',
        });
    }

    saveNewDevice(device) {
        return api.getJson(TESTING_CONSOLE_DEVICE_ENDPOINT, {
            method: 'POST',
            credentials: 'include',
            body: device,
        });
    }

    deleteDevice(device) {
        return api.getJson(TESTING_CONSOLE_DEVICE_ENDPOINT, {
            method: 'DELETE',
            credentials: 'include',
            params: {
                model_id: device.id,
            },
        });
    }
}
