import { connect } from 'react-redux';
import TrialStatusPanel from './trialStatusPanel';
import { shouldShowTrialStatusPanel, getTrialRealDaysLeft, getUserData } from '../../selectors/user';
import { toggleTrialStatusPanel } from '../../actions/user';

const mapStateToProps = state => {
    return {
        show: shouldShowTrialStatusPanel(state),
        trialRealDaysLeft: getTrialRealDaysLeft(state),
        userData: getUserData(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShow: show => {
            dispatch(toggleTrialStatusPanel(show));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialStatusPanel);
