import API from 'services/api';

const api = new API();
const URL_CUSTOM_DIMENSIONS = 'api/custom_dimensions_v2';
const URL_CUSTOM_DIMENSIONS_RELATED = 'api/custom_dimensions_related';
const URL_DIMENSIONS = 'api/custom_dimensions/dimensions';
const URL_OPERATORS = 'api/custom_dimensions/operators';

export const getDimensions = () =>
    new Promise((resolve, reject) => {
        api.getJson(URL_DIMENSIONS, {
            method: 'GET',
            credentials: 'include',
            params: {},
        })
            .then(response => {
                if (response.status !== 0) {
                    return reject(response.value);
                }
                return resolve(response.value.dimensions);
            })
            .catch(reject);
    });

export const getDimensionsRelated = () =>
    new Promise((resolve, reject) => {
        api.getJson(URL_CUSTOM_DIMENSIONS_RELATED, {
            method: 'GET',
            credentials: 'include',
            params: {},
        })
            .then(response => {
                if (response.status !== 0) {
                    return reject(response.value);
                }
                return resolve(response.value);
            })
            .catch(reject);
    });

export const getOperators = () =>
    new Promise((resolve, reject) => {
        api.getJson(URL_OPERATORS, {
            method: 'GET',
            credentials: 'include',
            params: {},
        })
            .then(response => {
                if (response.status !== 0) {
                    return reject(response.value);
                }
                return resolve(response.value.operators);
            })
            .catch(reject);
    });

export const getCustomDimensions = () =>
    new Promise((resolve, reject) => {
        api.getJson(URL_CUSTOM_DIMENSIONS, {
            method: 'GET',
            credentials: 'include',
            params: {},
        })
            .then(response => {
                if (response.status !== 0) {
                    return reject(response.value);
                }
                return resolve({
                    dimensions: response.value.custom_dimensions,
                    maxDimensions: response.value.max_custom_dimensions,
                    maxConditionsPerDimension: response.value.max_conditions_per_dimension,
                    supportedMacroDimensions: response.value.supported_macro_dimensions,
                    v3Dimensions: response.value.v3_dimensions,
                    tabsToShow: response.value.tabs_to_show,
                });
            })
            .catch(reject);
    });

export const save = dimensionConfig =>
    new Promise((resolve, reject) => {
        api.getJson(URL_CUSTOM_DIMENSIONS, {
            method: 'POST',
            credentials: 'include',
            body: { ...dimensionConfig },
        })
            .then(response => {
                if (response.status !== 0) {
                    return reject(response.value);
                }
                return resolve(dimensionConfig);
            })
            .catch(reject);
    });

export const deleteDimension = dimensionId =>
    new Promise((resolve, reject) => {
        api.getJson(URL_CUSTOM_DIMENSIONS, {
            method: 'DELETE',
            credentials: 'include',
            params: { dimensionId },
        })
            .then(response => {
                if (response.status !== 0) {
                    return reject(response.value);
                }
                return resolve(dimensionId);
            })
            .catch(reject);
    });
