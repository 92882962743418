import { SEGMENT_STATUSES } from './consts';

export const LOAD = 'action.audiences.segments.load';
export const UNLOAD = 'action.audiences.segments.unload';
export const SEARCH = 'action.audiences.segments.search';
export const TOGGLE_STATUS = 'action.audiences.segments.toggle_status';
export const TOGGLE_STATUS_DONE = 'action.audiences.segments.toggle_status_done';
export const EDIT_START = 'action.audiences.segments.edit_start';
export const EDIT_START_DONE = 'action.audiences.segments.edit_start_done';
export const EDIT_CANCEL = 'action.audiences.segments.edit_cancel';
export const EDIT_SAVE = 'action.audiences.segments.edit_save';
export const EDIT_SAVE_START = 'action.audiences.segments.edit_save_start';
export const EDIT_SAVE_DONE = 'action.audiences.segments.edit_save_done';
export const EDIT_DETAILS = 'action.audiences.segments.edit_details';
export const DELETE_START = 'action.audiences.segments.delete_start';
export const DELETE_CANCEL = 'action.audiences.segments.delete_cancel';
export const DELETE_CONFIRM = 'action.audiences.segments.delete_confirm';
export const ADD_FILTER = 'action.audiences.segments.add_filter';
export const EDIT_FILTER = 'action.audiences.segments.edit_filter';
export const DELETE_FILTER = 'action.audiences.segments.delete_filter';
export const ADD_CONDITION = 'action.audiences.segments.add_condition';
export const EDIT_CONDITION = 'action.audiences.segments.edit_condition';
export const DELETE_CONDITION = 'action.audiences.segments.delete_condition';
export const VALIDATE_SEGMENT = 'action.audiences.segments.validate_segment';
export const DOWNLOAD = 'action.audiences.segments.download';
export const OPEN_SHARING_OPTIONS = 'action.audiences.segments.open_sharing_options';
export const CLOSE_SHARING_OPTIONS = 'action.audiences.segments.close_sharing_options';
export const SHARING_OPTIONS_LOADED = 'action.audiences.segments.sharing_options_loaded';
export const COPY_SHARING_LINK = 'action.audiences.segments.copy_sharing_link';
export const CREATE_SHARING_LINK = 'action.audiences.segments.create_sharing_link';
export const REVOKE_SHARING_LINK = 'action.audiences.segments.revoke_sharing_link';
export const RENAME_SHARING_LINK = 'action.audiences.segments.rename_sharing_link';
export const CHANGE_SORT = 'action.audiences.segments.change_sort';

function track(name, params) {
    if (typeof window.mixpanel !== 'object') {
        return;
    }
    window.mixpanel.track(`Audiences: Segments: ${name}`, params);
}

export const load = (segments, templates, activeSegmentsLimit) => {
    track('Page Loaded');
    return {
        type: LOAD,
        segments,
        templates,
        activeSegmentsLimit,
    };
};

export const unload = () => {
    return {
        type: UNLOAD,
    };
};

export const search = query => {
    return {
        type: SEARCH,
        query,
    };
};

export const toggleStatus = (id, status) => {
    track(`${status === SEGMENT_STATUSES.INACTIVE ? 'Pause' : 'Resume'} Segment`);
    return {
        type: TOGGLE_STATUS,
        id,
        status,
    };
};

export const editStart = (id, template = '', duplicate = false, admin = false) => {
    if (template) {
        track('New Segment: Template', { templateName: template });
    } else if (id === -1) {
        track('New Segment: Blank');
    } else if (duplicate) {
        track('New Segment: Duplicate');
    }
    return {
        type: EDIT_START,
        id,
        template,
        duplicate,
        admin,
    };
};

export const editStartDone = (segment, metadata, duplicate = false, admin = false) => {
    return {
        type: EDIT_START_DONE,
        segment,
        metadata,
        duplicate,
        admin,
    };
};

export const editCancel = id => {
    if (id === -1) {
        track('New Segment: Cancel');
    }
    return {
        type: EDIT_CANCEL,
    };
};

export const editSave = (id, segment) => {
    return {
        type: EDIT_SAVE,
        id,
        segment,
    };
};

export const editSaveStart = () => {
    return {
        type: EDIT_SAVE_START,
    };
};

export const editSaveDone = (id, status) => {
    return {
        type: EDIT_SAVE_DONE,
        id,
        status,
    };
};

export const editDetails = (key, value) => {
    return {
        type: EDIT_DETAILS,
        key,
        value,
    };
};

export const deleteStart = id => {
    return {
        type: DELETE_START,
        id,
    };
};

export const deleteCancel = () => {
    return {
        type: DELETE_CANCEL,
    };
};

export const deleteConfirm = id => {
    return {
        type: DELETE_CONFIRM,
        id,
    };
};

export const addFilter = filter => {
    track('Add Behavior Filter', { filterType: filter.filter_type });
    return {
        type: ADD_FILTER,
        filter,
    };
};

export const editFilter = (index, key, value) => {
    return {
        type: EDIT_FILTER,
        index,
        key,
        value,
    };
};

export const deleteFilter = index => {
    return {
        type: DELETE_FILTER,
        index,
    };
};

export const addCondition = (index, condition, filterType) => {
    track('Add Condition', { filterType });
    return {
        type: ADD_CONDITION,
        index,
        condition,
    };
};

export const editCondition = (index, cIndex, key, value) => {
    return {
        type: EDIT_CONDITION,
        index,
        cIndex,
        key,
        value,
    };
};

export const deleteCondition = (index, cIndex) => {
    return {
        type: DELETE_CONDITION,
        index,
        cIndex,
    };
};

export const validateSegment = (enableValidation = false) => {
    return {
        type: VALIDATE_SEGMENT,
        enableValidation,
    };
};

export const download = id => {
    track('Download Segment');
    return {
        type: DOWNLOAD,
        id,
    };
};

export const openSharingOptions = id => {
    track('Share');
    return {
        type: OPEN_SHARING_OPTIONS,
        id,
    };
};

export const closeSharingOptions = () => {
    return {
        type: CLOSE_SHARING_OPTIONS,
    };
};

export const sharingLinksLoaded = links => {
    return {
        type: SHARING_OPTIONS_LOADED,
        links,
    };
};

export const copySharingLink = linkId => {
    return {
        type: COPY_SHARING_LINK,
        linkId,
    };
};

export const createSharingLink = () => {
    return {
        type: CREATE_SHARING_LINK,
    };
};

export const revokeSharingLink = linkId => {
    return {
        type: REVOKE_SHARING_LINK,
        linkId,
    };
};

export const renameSharingLink = (linkId, displayName) => {
    return {
        type: RENAME_SHARING_LINK,
        linkId,
        displayName,
    };
};

export const changeSort = sort => {
    return {
        type: CHANGE_SORT,
        sort,
    };
};
