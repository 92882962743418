import React from 'react';
import PropTypes from 'prop-types';
import ConversionModelFormTitle from '../../conversionModelForm/ConversionModelFormTitle';

function ModelForm({ typeData, children }) {
    return (
        <div data-testid={`testing-${typeData.name}-type`}>
            <ConversionModelFormTitle displayName={typeData.display_name} description={typeData.description} />
            {children}
        </div>
    );
}

ModelForm.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    typeData: PropTypes.shape({
        display_name: PropTypes.string,
        description: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
};

ModelForm.defaultProps = {};

export default ModelForm;
