import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './WizardFooter.css';

export const WIZARD_FOOTER_HEIGHT = 75;

class WizardFooter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateOverflowState);
    }

    render() {
        const { leftComponents, buttons, containerClass } = this.props;
        return (
            <div className={classNames(css.container, containerClass)} style={{ height: `${WIZARD_FOOTER_HEIGHT}px` }} ref={this.container}>
                <div className={css.leftContainer}>{leftComponents}</div>
                <div className={css.buttonsContainer}>
                    {buttons.map((button, index) => {
                        return (
                            <div key={`footerButton_${index}`} className={css.buttonHolder}>
                                {button}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

WizardFooter.propTypes = {
    leftComponents: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    buttons: PropTypes.arrayOf(PropTypes.element),
    containerClass: PropTypes.string,
};

WizardFooter.defaultProps = {
    containerClass: '',
    leftComponents: [],
    buttons: [],
};

export default WizardFooter;
