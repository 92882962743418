import API from '../services/api';

let instance = null;
const api = new API();

export const URL_ADNETWORKS = 'api/adnetworks';
export const URL_GET_UAN = 'api/users/self/uans';
export const URL_SAVE_UAN = 'api/users/self/uans/update';
export const URL_VALIDATE_UAN = 'api/org/login_uan';
export const URL_DATA_SOURCES = 'api/users/self/datasources/uans_statuses';
export const URL_DATA_DESTINATIONS = 'api/users/self/datasources/etl_uans_statuses';
export const URL_UAN_LIST = 'api/users/self/datasources/uan_list';
export const URL_CUSTOM_SCHEMAS = '/api/data_destinations/custom_schemas';
export const URL_BASE_SCHEMAS = '/api/data_destinations/base_schemas';
const URL_PARTNERS = 'api/attribution/partner_configuration_list';
const URL_EXPORT_2PHASE = 'api/users/self/datasources/export_2phase';
const URL_UAN_OBJECT = 'api/org/get_uan_status';
const URL_SAVE_UAN_WITH_ACCOUNTS = 'api/org/save_data_source_config';
const URL_SAVE_ADWORDS = 'adwordscfg/save';
const URL_DELETE_UAN = '/adcfg/remove';
const URL_GET_ACCOUNTS = '/api/org/obtain_data_source_config';
const URL_GET_ACCOUNT_SOURCE_MAPPING = '/api/org/obtain_mapping_options_for_source';
const URL_IS_USER_SLACK_AUTH = 'api/slack/isUserSlackAuth';
const URL_DELETE_USER_SLACK_AUTH = '/api/slack/deleteUserSlackAuth';
const URL_DATA_REFRESH = '/api/users/self/datasources/send_scrape_job';
const URL_DATA_AVAILABILITY = '/api/users/self/datasources/data_availability';
const URL_CREATE_NEW_GSHEET = '/api/data_destinations/create_new_gsheet';
const URL_DOWNLOAD_GSHEET = '/api/data_destinations/download_gsheet';

export default class DataSourcesService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getEtlUanStates() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_DATA_DESTINATIONS, {
                method: 'POST',
                credentials: 'include',
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getOrgUans(uanIds, unique = false, nonArchivedOnly = false) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_DATA_SOURCES, {
                body: uanIds
                    ? { non_archived_only: nonArchivedOnly, uan_ids: JSON.stringify(uanIds) }
                    : { non_archived_only: nonArchivedOnly },
                method: 'POST',
                credentials: 'include',
                unique,
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getUanList() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_UAN_LIST)
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getAdNetworks(includeEtl, adnId) {
        return new Promise((resolve, reject) => {
            const extraParams = adnId ? { adn_id: adnId } : {};
            api.getJson(URL_ADNETWORKS, {
                method: 'GET',
                credentials: 'include',
                unique: true,
                cache: true,
                params: {
                    include_etl: !!includeEtl,
                    ...extraParams,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getConfiguredSources() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_PARTNERS, {
                method: 'GET',
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(() => {
                    resolve(null);
                });
        });
    }

    export2Phase(uanId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_EXPORT_2PHASE, {
                method: 'GET',
                params: { uan_id: uanId },
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getUanObject(uanId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_UAN_OBJECT, {
                method: 'GET',
                params: { uan_id: uanId },
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getUanData(uanId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_GET_UAN, {
                method: 'GET',
                params: { uan_id: uanId },
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    createNewGsheet(uanId, sheetName) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_CREATE_NEW_GSHEET, {
                method: 'POST',
                body: { uanId, sheetName },
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    downloadGSheet(uanId, sheetId) {
        return new Promise((resolve, reject) => {
            api.getFile(URL_DOWNLOAD_GSHEET, {
                method: 'POST',
                body: { uanId, sheetId },
                credentials: 'include',
                unique: true,
                disableBrowserCache: true,
            })
                .then(response => {
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    saveUanData(uanData) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_SAVE_UAN, {
                method: 'POST',
                legacyStatus: true,
                body: uanData,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    saveAdwordsData(mccClientId, uanId, description) {
        return api.getJson(URL_SAVE_ADWORDS, {
            method: 'POST',
            legacyStatus: true,
            body: { customer_id: mccClientId, uan_id: uanId, description },
        });
    }

    saveUanWithAccounts(uanData, uanId) {
        return api.getJson(URL_SAVE_UAN_WITH_ACCOUNTS, {
            method: 'POST',
            legacyStatus: true,
            body: { config: uanData, uan_id: uanId },
        });
    }

    validateUan(uanId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_VALIDATE_UAN, {
                method: 'POST',
                legacyStatus: true,
                body: { uan_id: uanId },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    deleteUan(uanId) {
        return api.getJson(URL_DELETE_UAN, {
            method: 'POST',
            legacyStatus: true,
            body: { uan_id: uanId },
        });
    }

    getNetworkAuthUrl(url, state) {
        return api.getJson(url, {
            method: 'POST',
            legacyStatus: true,
            body: { sdk_token: true, state },
        });
    }

    getAccounts(uanId, disableSaveAccounts) {
        return api.getJson(URL_GET_ACCOUNTS, {
            method: 'GET',
            credentials: 'include',
            params: { uan_id: uanId, get_all: true, disable_save_accounts: disableSaveAccounts },
            disableBrowserCache: true,
        });
    }

    getAccountSourceMapping(adnId) {
        return api.getJson(URL_GET_ACCOUNT_SOURCE_MAPPING, {
            method: 'GET',
            credentials: 'include',
            params: { adnetwork_id: adnId },
            disableBrowserCache: true,
        });
    }

    sendScrapeJob(uanId) {
        return api.getJson(URL_DATA_REFRESH, {
            method: 'POST',
            legacyStatus: true,
            params: { uan_id: uanId, try_again_button: true },
        });
    }

    isUserSlackAuth() {
        return api.getJson(URL_IS_USER_SLACK_AUTH, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    deleteUserSlackAuth() {
        return api.getJson(URL_DELETE_USER_SLACK_AUTH, {
            method: 'DELETE',
            credentials: 'include',
            params: {},
        });
    }

    createCustomSchema(adnetworkId, baseSchema, customFields) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_CUSTOM_SCHEMAS, {
                method: 'POST',
                body: {
                    base_schema: baseSchema,
                    adnetwork_id: adnetworkId,
                    custom_fields: customFields,
                },
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    getDataRefreshConfig(uanId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_DATA_REFRESH, {
                method: 'GET',
                credentials: 'include',
                params: { uan_id: uanId },
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getCustomSchemas(sourceType) {
        return api.getJson(URL_CUSTOM_SCHEMAS, {
            method: 'GET',
            disableBrowserCache: true,
            params: { source_type: sourceType },
        });
    }

    getBaseSchemas(sourceType) {
        return api.getJson(URL_BASE_SCHEMAS, {
            method: 'GET',
            disableBrowserCache: true,
            params: { source_type: sourceType },
        });
    }

    sendDataRefreshJob(dataRefreshParams) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_DATA_REFRESH, {
                method: 'POST',
                credentials: 'include',
                params: dataRefreshParams,
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getDataAvailability({ uanId, monthYear }) {
        return api.getJson(URL_DATA_AVAILABILITY, {
            method: 'POST',
            credentials: 'include',
            params: { uan_id: uanId, month_year: monthYear },
        });
    }
}
