import React from 'react';
import { PageHeader, PageWrapper } from '../components/partials';
import AssistedInstallsForm from './AssistedInstallsForm';
import AssistedInstallsTable from './AssistedInstallsTable';
import { useAsync } from '../utils/customHooks';
import AssistedInstallsApi from './service';

const api = new AssistedInstallsApi();

function AssistedInstalls() {
    const [response, run] = useAsync();
    const fetchAssisted = async ({ app, os }) => {
        await run(api.loadAssistedInstalls(app, os));
    };

    return (
        <PageWrapper>
            <PageHeader
                text="STATIC.PAGES.ASSISTED_INSTALLS.PAGE_HEADER"
                subText={{ faqLink: 'https://support.singular.net/hc/en-us/articles/207553433' }}
            />
            <AssistedInstallsForm onFetchAssisted={fetchAssisted} />
            <AssistedInstallsTable data={response} />
        </PageWrapper>
    );
}

export default AssistedInstalls;
