import { connect } from 'react-redux';
import {
    changePopupDialog,
    chartLinesChanged,
    chartLoaded,
    chartMetricSelected,
    checkboxClicked,
    columnFreeze,
    columnMove,
    columnPin,
    columnToggle,
    filtersExpanded,
    gridReady,
    hideChart,
    pivotChartChanged,
    pivotColumnsChanged,
    pivotRowGroupChanged,
    pivotValuesChanged,
    reportsFieldClicked,
    reportsFieldSet,
    resetTable,
    setActionType,
    showChart,
    showDrilldownPopover,
    showTransparencyPopover,
    toggleFieldsAvailability,
    toggleSlowLoadingChart,
    updateChartShowLegend,
    updateQueryURL,
    updateReportsFilter,
} from 'actions/reports';
import { updateFields } from 'actions/fields';
import {
    getActiveBookmarkName,
    getCustomFieldGroupLabels,
    getFieldGroupsToHide,
    getIsEditShelfOpen,
    getPopupDialogParams,
    getReportsFilters,
    getShowPopupDialog,
    reportDataSelectors,
} from 'selectors/reports';

import Reports from 'components';
import { getAdminModeEnabled, getFieldsAvailabilityEnabled, isShelfReporting, showPivotCharts } from 'selectors/user';
import { getEmptyStateOrgData } from 'selectors/onboarding';
import { onCloseReportsEmptyState } from 'actions/onboarding';

let reportType = '';
let getReportsData;

const mapStateToProps = (state, ownProps) => {
    if (ownProps.type !== reportType) {
        reportType = ownProps.type;
        getReportsData = reportDataSelectors[reportType];
    }

    return {
        ...getReportsData(state),
        permissionKeys: state.user.data.permission_keys,
        reportType,
        adminMode: getAdminModeEnabled(state),
        customFieldGroupLabels: getCustomFieldGroupLabels()(state),
        fieldGroupsToHide: getFieldGroupsToHide()(state),
        showPivotCharts: showPivotCharts(state),
        activeBookmarkName: getActiveBookmarkName(reportType)(state),
        showFieldsAvailabilityFeature: getFieldsAvailabilityEnabled(state, reportType),
        reportFilters: getReportsFilters(state, reportType)(),
        showPopupDialog: getShowPopupDialog(state, reportType)(),
        popupDialogParams: getPopupDialogParams(state, reportType)(),
        emptyStateOrgData: getEmptyStateOrgData(state),
        isEditShelfOpen: getIsEditShelfOpen(state, reportType)(),
        isShelfReporting: isShelfReporting(state, reportType),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCheckboxClick: () => dispatch(setActionType(checkboxClicked(), reportType)),
        onFilterChanged: (model, colDefs) => dispatch(updateReportsFilter(reportType, model, colDefs)),
        onRefreshClicked: () => dispatch(setActionType(updateFields(), reportType)),
        onFieldClick: (...args) => dispatch(setActionType(reportsFieldClicked(...args), reportType)),
        onFieldSet: (...args) => dispatch(setActionType(reportsFieldSet(...args), reportType)),
        onChartLinesChanged: value => dispatch(chartLinesChanged(reportType, value)),
        onGridReady: () => dispatch(gridReady(reportType)),
        onChartLegendToggle: show => dispatch(setActionType(updateChartShowLegend(show, reportType), reportType)),
        onChartMetricSelected: (metric, chartParams) =>
            dispatch(setActionType(chartMetricSelected(metric, chartParams), reportType)),
        onChartLoaded: chartParams => dispatch(setActionType(chartLoaded(chartParams), reportType)),
        onToggleOffSlowLoadingChart: () => dispatch(setActionType(toggleSlowLoadingChart(false), reportType)),
        onPopoverToggle: show => dispatch(setActionType(showTransparencyPopover(show), reportType)),
        onDrilldownPopoverToggle: cellId => dispatch(setActionType(showDrilldownPopover(cellId), reportType)),
        onPinColumn: params => dispatch(columnPin(params)),
        onMoveColumn: params => dispatch(columnMove(params)),
        onResetTable: params => dispatch(resetTable(params)),
        onToggleColumn: params => dispatch(columnToggle(params)),
        onFreezeColumn: params => dispatch(columnFreeze(params)),
        onToggleChart: show =>
            show ? dispatch(setActionType(showChart(), reportType)) : dispatch(setActionType(hideChart(), reportType)),
        onPivotRowGroupChanged: rowGroup => dispatch(setActionType(pivotRowGroupChanged(rowGroup), reportType)),
        onPivotColumnsChanged: columns => dispatch(setActionType(pivotColumnsChanged(columns), reportType)),
        onPivotValuesChanged: values => dispatch(setActionType(pivotValuesChanged(values), reportType)),
        onPivotChartChange: chartSettings => dispatch(setActionType(pivotChartChanged(chartSettings), reportType)),
        onUpdateQueryURL: () => dispatch(setActionType(updateQueryURL(reportType), reportType)),
        onFieldsAvailabilityClick: (field, isOpen) =>
            dispatch(setActionType(toggleFieldsAvailability(field, isOpen), reportType)),
        changePopupDialog: (isOpen, params) => dispatch(setActionType(changePopupDialog(isOpen, params)), reportType),
        onCloseReportsEmptyState: () => dispatch(onCloseReportsEmptyState()),
        onFiltersExpand: isExpanded => dispatch(setActionType(filtersExpanded(isExpanded), reportType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
