export const REPORTS_EVENT_PREFIX = 'Reports';
export const REPORT_QUERY_PREFIX = 'Report Query';
export const SHOW_VIDEO_EVENT_PREFIX = 'Show Video';
export const SHARED_REPORTS_EVENT_PREFIX = 'SharedReports';
export const OPTIMIZED_MODELS_EVENT_PREFIX = 'optimizedModels';
export const BANNERS_EVENT_PREFIX = 'Banners';
export const ETL_EVENT_PREFIX = 'ETL Destinations';
export const DATA_CONNECTORS_EVENT_PREFIX = 'Data Connectors';
export const PARTNER_CONFIGURATION_EVENT_PREFIX = 'Partner Configuration V2';
export const MANAGE_LINKS_EVENT_PREFIX = 'Manage Links';
export const TEAM_MANAGEMENT_EVENT_PREFIX = 'Team Management';
export const ACCOUNT_PLAN_EVENT_PREFIX = 'Account Plan';
export const BLOCKED_PAGE_EVENT_PREFIX = 'Blocked Page';

export const getTextWidth = (text, font) => {
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

export const getMixpanelEventLegacyFormat = (prefix, eventName) => {
    return `${prefix}_${eventName}`.toLowerCase().replace(/ /g, '_');
};

export const trackMixpanelEvent = (trackEventPrefix, eventName, params, migrateFromLegacyFormat = false) => {
    try {
        window.mixpanel.track(`${trackEventPrefix} - ${eventName}`, params);

        // To enable search of old data, we will migrate to the new format gradually
        // Don't forget to delete eventually :)
        if (migrateFromLegacyFormat) {
            window.mixpanel.track(getMixpanelEventLegacyFormat(trackEventPrefix, eventName), params);
        }
    } catch (e) {
        console.error(e);
    }
};

export const trackShowVideoMixpanelEvent = ({ watchedFrom }) => {
    trackMixpanelEvent(SHOW_VIDEO_EVENT_PREFIX, 'Clicked On Show Video', { watched_from: watchedFrom });
};

export const isObjectEmpty = obj => {
    return !obj || typeof obj !== 'object' || !Object.keys(obj).length;
};
export const capitalize = (str = '') => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export const PATHS_NOT_IN_NAVIGATION_MENU = [
    '/react/account-plan',
    '/react/plan-upgrade',
    '/settings/2fa',
    '/react/quick-reports/campaign',
    '/react/nike-social-links',
    '/react/team',
    '/react/anonymous/:secretId',
];

export const isPathAllowed = (allowedPaths, path) => {
    return allowedPaths.some(p => path.replace('/react', '').includes(p.replace('/react', '')));
};

export const formatSectionPaths = (menuSections = []) => {
    return menuSections.reduce((result, value) => {
        if (value.elements) {
            result.push(...value.elements.map(el => el.link));
        }
        if (value.link) {
            result.push(value.link);
        }
        return result;
    }, []);
};

export const getSwappedListElements = (firstIndex, secondIndex, valuesList) => {
    const listCopy = [...valuesList];
    [listCopy[firstIndex], listCopy[secondIndex]] = [listCopy[secondIndex], listCopy[firstIndex]];
    return listCopy;
};
