import { useCallback } from 'react';
import { useRequest } from 'utils/customHooks';
import SDKPlanningService from '../service';

export const useGetLatestSDKVersion = sdkType => {
    const sdkPlanningAPI = new SDKPlanningService();
    return useRequest(
        useCallback(sdkPlanningAPI.getLatestSDKVersions, []),
        () => true,
        () => {},
        sdkType
    );
};
