import React, { useEffect, useState } from 'react';
import css from './sectionsItems.css';
import {
    ANDROID_NATIVE,
    DEEP_LINKING,
    IOS_NATIVE,
    TARGET_PLATFORM_SHORT_NAME,
    TARGET_PLATFORM_TO_PARAM,
} from '../../consts';
import { useSDKPlanningContext } from '../../context/useSDKPlanningContext';
import { useFetchAppSitesByPlatform } from '../../../hooks/useFetchAppSites';
import { ALERT_TYPES } from '../../../../components/widgets/Alert/Alert';
import SDKSectionsWrapper from '../SdkSectionsWrapper/SDKSectionsWrapper';
import { Alert } from '../../../../components/widgets';
import DeepLinkingIOS from './ios/ConfigureTheSDK/DeepLinkingIOS';
import DeepLinkingAndroid from './android/ConfigureTheSDK/DeepLinkingAndroid';
import { getSectionTranslateID } from '../../sectionsWrapper/utils';
import { SECTIONS_TRANSLATE_KEY } from './consts';
import useMixpanelEventOnPageLoad from '../../../hooks/useMixpanelEventOnPageLoad';

const DEEP_LINKING_COMPONENTS_BY_PLATFORM = {
    [IOS_NATIVE]: DeepLinkingIOS,
    [ANDROID_NATIVE]: DeepLinkingAndroid,
};
const DeepLinking = () => {
    const [AlertType, setAlertType] = useState(ALERT_TYPES.INFO);
    const { selectedTargetPlatform } = useSDKPlanningContext();
    const platform = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];
    const alertInfoText = getSectionTranslateID(DEEP_LINKING, 'INFO_MESSAGE', platform);
    const [alertText, setAlertText] = useState(alertInfoText);
    const DeepLinkingByPlatform = DEEP_LINKING_COMPONENTS_BY_PLATFORM[selectedTargetPlatform];
    const { isLoading: isLoadingAppSites, appSites } = useFetchAppSitesByPlatform(
        TARGET_PLATFORM_TO_PARAM[selectedTargetPlatform]
    );

    useMixpanelEventOnPageLoad(DEEP_LINKING, selectedTargetPlatform);

    useEffect(() => {
        if (isLoadingAppSites !== null && !isLoadingAppSites) {
            setAlertText(
                appSites.length
                    ? alertInfoText
                    : `${SECTIONS_TRANSLATE_KEY}.${TARGET_PLATFORM_TO_PARAM[
                          selectedTargetPlatform
                      ].toUpperCase()}.DEEP_LINKING.WARNING_MESSAGE`
            );
            setAlertType(appSites.length ? ALERT_TYPES.INFO : ALERT_TYPES.WARNING);
        }
    }, [isLoadingAppSites, appSites]);

    return (
        <SDKSectionsWrapper sectionKey={DEEP_LINKING}>
            <div className={css.sectionContainer}>
                <div className={css.row}>
                    <Alert text={alertText} type={AlertType} />
                    {DeepLinkingByPlatform && (
                        <DeepLinkingByPlatform appSites={appSites} isLoadingAppSites={isLoadingAppSites} />
                    )}
                </div>
            </div>
        </SDKSectionsWrapper>
    );
};

export default DeepLinking;
