import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import AutoComplete from './AutoComplete';
import TagInput from './TagInput';
import css from './AutoCompleteField.css';
import QuestionMark from './QuestionMark';
import MuiAutoComplete from './MuiAutoComplete';

function AutoCompleteField(props) {
    const {
        label,
        labelTooltip,
        labelTooltipStyle,
        labelTranslateArgs,
        labelClassName,
        translate,
        options,
        value,
        isMulti,
        containerStyle,
        onChange,
        onInputChange,
        debounceTime,
        disabled,
        placeholder,
        error,
        big,
        selectOptions,
        searchable,
        onBlur,
        controlled,
        placeholderData,
        mandatory,
        clearable,
        copiable,
        loading,
        containerClass,
        innerContainerClass,
        errorClassName,
        openMenuOnClick,
        badgeText,
        badgeClass,
        dataTestId,
        useMaterialUI,
        hideDropdownIndicator,
        removable,
        onRemove,
        disableOptions,
        limitTags,
    } = props;

    const [inputValue, setInputValue] = useState(value || null);
    const handleInputChangeDebounce = useCallback(
        debounce(
            currentValue => {
                onInputChange(currentValue);
            },
            debounceTime,
            { trailing: true }
        ),
        []
    );
    const onInputChangeInner = currentValue => {
        setInputValue(currentValue);
        handleInputChangeDebounce(currentValue);
    };

    const onChangeInner = currentValue => {
        setInputValue(currentValue);
        onChange(currentValue);
    };

    useEffect(() => {
        if (controlled && value !== inputValue) {
            setInputValue(value);
        }
    }, [controlled, value]);

    if (useMaterialUI) {
        return (
            <MuiAutoComplete
                dataTestId={dataTestId}
                options={options}
                value={value}
                label={label}
                onChange={onChange}
                className={innerContainerClass}
                disabled={disabled}
                loading={loading}
                multiple={isMulti}
                removable={removable}
                onRemove={onRemove}
                limitTags={limitTags}
            />
        );
    }

    return (
        <div className={classNames(css.container, containerClass)} style={containerStyle}>
            <div className={classNames(css.labelText, labelClassName, { [css.error]: !!error })}>
                {mandatory ? <span className={css.mandatory}>*</span> : null}
                {badgeText ? (
                    <div className={classNames(css.badge, badgeClass)}>
                        <div className={css.badgeInner}>{badgeText}</div>
                    </div>
                ) : null}
                <span>{translate(label, labelTranslateArgs) || label}</span>
                {labelTooltip ? (
                    <QuestionMark message={labelTooltip} contentStyle={labelTooltipStyle} interactive />
                ) : null}
            </div>
            {isMulti ? (
                <TagInput
                    dataTestId={dataTestId}
                    ariaLabel={label}
                    placeholder={translate(placeholder, placeholderData) || placeholder}
                    suggestions={options}
                    tags={value || null}
                    disabled={disabled}
                    containerStyle={{
                        width: '100%',
                    }}
                    onChange={onChange}
                    error={error}
                    virtualScrolling
                    openMenuOnClick={openMenuOnClick}
                    className={innerContainerClass}
                    hideDropdownIndicator={hideDropdownIndicator}
                    disableOptions={disableOptions}
                />
            ) : (
                <AutoComplete
                    copiable={copiable}
                    ariaLabel={label}
                    dataTestId={dataTestId}
                    placeholder={translate(placeholder, placeholderData) || placeholder}
                    selectOptions={{
                        options,
                        disabled,
                        ...(searchable
                            ? {
                                  noOptionsMessage: () => null,
                                  isSearchable: true,
                                  inputValue: typeof inputValue === 'string' ? inputValue : '',
                              }
                            : {}),
                        isClearable: clearable,
                        isLoading: loading,
                        ...selectOptions,
                    }}
                    defaultValue={inputValue}
                    onChange={onChangeInner}
                    onInputChange={onInputChangeInner}
                    onBlur={onBlur}
                    controlledValue={controlled ? inputValue : null}
                    searchable={searchable}
                    error={error}
                    big={big}
                    virtualScrolling
                    containerClass={innerContainerClass}
                />
            )}
            {error && <div className={classNames(css.errorMessage, errorClassName)}>{translate(error)}</div>}
        </div>
    );
}

export const AutoCompleteOptionType = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
});

AutoCompleteField.propTypes = {
    label: PropTypes.string,
    labelTooltip: PropTypes.string,
    labelTooltipStyle: PropTypes.objectOf(PropTypes.any),
    labelTranslateArgs: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    labelClassName: PropTypes.string,
    options: PropTypes.arrayOf(AutoCompleteOptionType).isRequired,
    translate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    isMulti: PropTypes.bool,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.string,
    ]),
    error: PropTypes.string,
    big: PropTypes.bool,
    selectOptions: PropTypes.objectOf(PropTypes.any),
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    copiable: PropTypes.bool,
    loading: PropTypes.bool,
    onBlur: PropTypes.func,
    controlled: PropTypes.bool,
    placeholderData: PropTypes.objectOf(PropTypes.any),
    mandatory: PropTypes.bool,
    debounceTime: PropTypes.number,
    containerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    innerContainerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorClassName: PropTypes.string,
    openMenuOnClick: PropTypes.bool,
    badgeText: PropTypes.string,
    badgeClass: PropTypes.string,
    dataTestId: PropTypes.string,
    useMaterialUI: PropTypes.bool,
    hideDropdownIndicator: PropTypes.bool,
    removable: PropTypes.bool, // supported only for MuiAutoComplete at the moment
    onRemove: PropTypes.func,
    disableOptions: PropTypes.bool,
    limitTags: PropTypes.number,
};

AutoCompleteField.defaultProps = {
    label: '',
    isMulti: true,
    containerStyle: null,
    disabled: false,
    placeholder: 'Select',
    error: '',
    big: false,
    selectOptions: {},
    searchable: false,
    clearable: false,
    copiable: false,
    loading: false,
    onBlur: () => {},
    controlled: false,
    placeholderData: {},
    labelTooltip: null,
    labelTooltipStyle: {},
    mandatory: false,
    debounceTime: 200,
    containerClass: {},
    innerContainerClass: {},
    errorClassName: '',
    openMenuOnClick: true,
    badgeText: null,
    badgeClass: null,
    labelTranslateArgs: null,
    dataTestId: null,
    onInputChange: () => {},
    useMaterialUI: false,
    hideDropdownIndicator: true,
    disableOptions: false,
    limitTags: undefined,
};

export default withLocalize(AutoCompleteField);
