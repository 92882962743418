import { connect } from 'react-redux';
import { updateRoleSelectionForm } from 'teamManagement/actions';
import RoleSelection from 'teamManagement/components/RoleSelection';
import { getRoleSelectionFormPage } from 'teamManagement/selectors';

const mapStateToProps = (state, ownProps) => {
    return getRoleSelectionFormPage(state);
};

const mapDispatchToProps = dispatch => {
    return {
        onRoleSelection: role => {
            dispatch(updateRoleSelectionForm(role));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RoleSelection);
