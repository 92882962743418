import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts, HighchartsChart, ColumnSeries } from 'react-jsx-highcharts';
import { getCurrencySymbol } from 'utils/currencyUtils';
import {
    mapShareOfCostSeriesValues,
    networkLabelsFormatter,
    seriesFloatValueFormatter,
    syncChartsScrolls,
} from 'benchmarks/utils';
import ChartTooltip from 'benchmarks/components/chartTooltip';
import ChartLegend from 'benchmarks/components/chartLegend';
import ChartXAxis from 'benchmarks/components/chartXAxis';
import ChartYAxis from 'benchmarks/components/chartYAxis';
import Chart from 'benchmarks/components/chart';
import { seriesRangeValueFormatter } from '../utils';
import { labelByMetric, colorByMetric, coefPerChannelPlotOptions, MMM_GRAPH_TYPES, CURRENCY } from '../consts';

let visibleLegendCount = 2;

const CoefPerChannelChart = ({ chartData, metricType, currency }) => {
    const { data, labels, logo_by_label: logoByLabel } = chartData;
    const [chartLoaded, setChartLoaded] = useState(false);

    return (
        <HighchartsChart
            plotOptions={coefPerChannelPlotOptions}
            callback={() => {
                visibleLegendCount = 2;

                setTimeout(() => {
                    setChartLoaded(true);
                    syncChartsScrolls(MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL, MMM_GRAPH_TYPES.COST_PER_CHANNEL);
                }, 0);
            }}
        >
            <ChartLegend />
            <Chart minWidth={labels.length * 75} loaded={chartLoaded} marginBottom={100} />
            <ChartTooltip isTextHeader categories={labels} />
            <ChartXAxis
                id="revenue_vs_cost_column_x_axis"
                categories={labels}
                labelsFormat={null}
                lineWidth={1}
                formatter={networkLabelsFormatter.bind(this, logoByLabel)}
            />
            <ChartYAxis
                id="revenue_vs_cost_column_y_axis"
                endOnTick
                labelSuffix={metricType === CURRENCY ? getCurrencySymbol(currency) : '%'}
            >
                {data.map(({ data: values, label }) => {
                    return (
                        <ColumnSeries
                            formatter={
                                metricType === CURRENCY
                                    ? seriesRangeValueFormatter(currency)
                                    : seriesFloatValueFormatter
                            }
                            key={label}
                            name={labelByMetric[label]}
                            data={metricType === CURRENCY ? values : mapShareOfCostSeriesValues(values)}
                            color={colorByMetric[label]}
                            dataLabels={{
                                enabled: true,
                                y: 5, // pixels down from the top
                                style: {
                                    textOutline: 0,
                                },
                                format: metricType === CURRENCY ? `${getCurrencySymbol(currency)}{y:,.1f}` : '{y:.1f}%',
                            }}
                            onLegendItemClick={({ target }) => {
                                if (visibleLegendCount === 1 && target.visible) {
                                    return false;
                                } else {
                                    visibleLegendCount = target.visible
                                        ? visibleLegendCount - 1
                                        : visibleLegendCount + 1;
                                }

                                return true;
                            }}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

CoefPerChannelChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    metricType: PropTypes.string,
    currency: PropTypes.string,
};

CoefPerChannelChart.defaultProps = {
    chartData: {},
    metricType: '',
    currency: 'USD',
};

export default withHighcharts(CoefPerChannelChart, Highcharts);
