import React from 'react';
import PropTypes from 'prop-types';
import { SAN_PARTNERS } from '../../../consts';
import LDS from './LDS';
import { partnerShape } from '../../types';

export const AnonymizedPostbacksOptionsForSANs = {
    NONE: 'NONE',
    ANONYMIZED_POSTBACKS_FALSE: 'ANONYMIZED_POSTBACKS_FALSE',
};

export const AnonymizedPostbacksOptions = {
    ...AnonymizedPostbacksOptionsForSANs,
    ANONYMIZED_POSTBACKS_TRUE: 'ANONYMIZED_POSTBACKS_TRUE',
};

export default function LDSFactory({ partner, isReadonly }) {
    const anonymizedPostbacksOptions = SAN_PARTNERS.includes(partner.name)
        ? AnonymizedPostbacksOptionsForSANs
        : AnonymizedPostbacksOptions;

    return <LDS partner={partner} isReadonly={isReadonly} anonymizedPostbacksOptions={anonymizedPostbacksOptions} />;
}

LDSFactory.propTypes = {
    partner: partnerShape.isRequired,
    isReadonly: PropTypes.bool,
};
