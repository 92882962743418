import React from 'react';
import PropTypes from 'prop-types';
import { OS, OSIcons } from '../../../utils/OS';

const OSIcon = ({ osValue, className }) => {
    const Icon = OSIcons[osValue] || OSIcon[OS.OTHER];

    return <Icon key={osValue} title={osValue} className={className} />;
};

OSIcon.propTypes = {
    osValue: PropTypes.string,
    className: PropTypes.string,
};

OSIcon.defaultProps = {};

export default OSIcon;
