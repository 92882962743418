import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './TableCellMultiIcons.css';
import Tooltip from './Tooltip';

const TableCellMultiIcons = ({ icons, className, tooltips, rowIndex }) => {
    return (
        <td>
            <div className={classNames(css.TableCellMultiIcons, className)}>
                {icons.map((Icon, index) => (
                    <Tooltip key={`icon-${Icon}`} disabled={!tooltips[index]} title={tooltips[index]}>
                        {typeof Icon === 'string' ? (
                            <img src={Icon} alt={`icon-${rowIndex}`} className={css.imageIcon} />
                        ) : (
                            <Icon className={css.multiIcon} />
                        )}
                    </Tooltip>
                ))}
            </div>
        </td>
    );
};

TableCellMultiIcons.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.any),
    className: PropTypes.string,
    tooltips: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    rowIndex: PropTypes.number,
};

TableCellMultiIcons.defaultProps = {
    icons: [],
    className: '',
    tooltips: [],
};

export default TableCellMultiIcons;
