import React from 'react';
import { useSelector } from 'react-redux';
import css from './ConversionModelContent.css';
import { ConversionModelContentInner } from '../../components/ConversionModelContentInner';
import { getUserCurrency } from '../../../selectors/user';
import { getConversionModelFields, getConversionModelTranslationMap } from '../utils';
import { ModelKinds } from '../../consts';
import { conversionModelTypeShape } from '../../components/skan4/types';

function ConversionModelContent({ conversionModel }) {
    const { symbol: currencySymbol } = useSelector(state => getUserCurrency(state)) || {};
    const {
        type,
        conversionModelGroupId,
        eventsTypeDisplayName,
        revenueTypeDisplayName,
        eventsType,
        revenueType,
    } = conversionModel;

    const isMixedModel = type === ModelKinds.MIXED;

    return (
        <div className={css.container}>
            {isMixedModel ? (
                <div className={css.mixedModelContent}>
                    <div className={css.mixedSubModelContent}>
                        <div className={css.mixedSubModelTitle}>{eventsTypeDisplayName}</div>
                        <ConversionModelContentInner
                            fields={getConversionModelFields(conversionModel, currencySymbol, eventsType)}
                            translationMap={getConversionModelTranslationMap(
                                conversionModel,
                                currencySymbol,
                                eventsType
                            )}
                            conversionModelGroupId={conversionModelGroupId}
                        />
                    </div>
                    <div className={css.mixedSubModelContent}>
                        <div className={css.mixedSubModelTitle}>{revenueTypeDisplayName}</div>
                        <ConversionModelContentInner
                            fields={getConversionModelFields(conversionModel, currencySymbol, revenueType)}
                            translationMap={getConversionModelTranslationMap(
                                conversionModel,
                                currencySymbol,
                                revenueType
                            )}
                            conversionModelGroupId={conversionModelGroupId}
                        />
                    </div>
                </div>
            ) : (
                <ConversionModelContentInner
                    fields={getConversionModelFields(conversionModel, currencySymbol)}
                    translationMap={getConversionModelTranslationMap(conversionModel, currencySymbol)}
                    conversionModelGroupId={conversionModelGroupId}
                />
            )}
        </div>
    );
}

ConversionModelContent.propTypes = {
    conversionModel: conversionModelTypeShape,
};

ConversionModelContent.defaultProps = {
    conversionModel: {},
};

export default ConversionModelContent;
