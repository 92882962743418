import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from '../utils/customHooks';
import { Fields, Reports, UnifiedReport } from '../services';
import { DataTypes, WIDGET_DATA_TYPES } from './widgetDataTypes';
import { getHeatMapColumnDefs, reportDataToTableData } from '../utils/grids';
import { buildQueryObject, renameTimebreakdownDimension } from './utils';
import WidgetService from './widgetService';
import { COMPARE_MODE, REGULAR_MODE } from '../components/pages/reports/consts';
import { getFilteredRowData } from '../components/partials/PivotChart/utils';

const reportsAPI = new Reports();
const widgetsAPI = new WidgetService();
const fieldsAPI = new Fields();
const unifiedReportAPI = new UnifiedReport();

export const useEffectSkipFirst = (callback, deps) => {
    const firstRef = useRef(true);

    useEffect(() => {
        if (firstRef.current) {
            firstRef.current = false;
            return;
        }

        callback();
    }, deps);
};

export const useFetchDisableReports = () => {
    return useRequest(
        useCallback(reportsAPI.updateReportInitState, []),
        () => {
            return true;
        },
        () => {}
    );
};

export function useRefreshCounterByInterval(intervalMs) {
    // Refreshing widget versions every WIDGET_VERSIONS_REFRESH_RATE ms
    const [refreshCounter, setRefreshCounter] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => setRefreshCounter(refreshCounter + 1), intervalMs);
        return () => {
            clearInterval(intervalId);
        };
    }, [refreshCounter, intervalMs]);

    return refreshCounter;
}

export function useFetchWidgetVersions(
    dashboardId,
    refreshCounter,
    forceRefresh,
    startDate,
    endDate,
    globalFilters = []
) {
    const [widgetVersions, setWidgetVersions] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await widgetsAPI.getWidgetVersions(dashboardId, globalFilters, startDate, endDate);
                setWidgetVersions(response.value);
            } catch (responseError) {
                console.log('Got an error while fetching widget versions! Error = ', responseError);
            }
        };
        fetchData();
    }, [dashboardId, refreshCounter, forceRefresh, startDate, endDate]);

    return widgetVersions;
}

export function useFetchTableData(
    dashboardId,
    widgetId,
    query,
    globalFilters = [],
    startDate,
    endDate,
    startDate2,
    endDate2,
    reportType,
    dispatchWidgetVersions,
    dispatchWidgetsLoading,
    compare,
    dataTypeName,
    displayUnenriched,
    updatingGraphQL,
    forceRefreshCounter,
    heatMapOptions = undefined
) {
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState(null);
    const [error, setError] = useState(null);

    const dataType = WIDGET_DATA_TYPES[dataTypeName];
    const DataTypeService = dataType.service;
    const dataTypeFields = dataType.fields || {};

    useEffect(() => {
        const abortController = new AbortController();
        let shouldSetData = true;

        setLoading(true);
        dispatchWidgetsLoading({ [widgetId]: true });
        setError(null);

        // Running async methods in useEffect is a bit annoying, you can't wait for them :)
        // Updating the state should happen inside the async method that awaits the response from the server
        const fetchDataBlocking = async () => {
            try {
                // Fetching data from cached endpoint
                // Should run when only graphQL isn't updating the dashboard model
                let reportData = null;
                if (!compare && !updatingGraphQL && dataType.name === DataTypes.REPORTS) {
                    reportData = await widgetsAPI.getWidgetData(
                        dashboardId,
                        widgetId,
                        globalFilters,
                        startDate,
                        endDate,
                        reportType,
                        { signal: abortController.signal },
                        displayUnenriched
                    );
                }

                // In case of cache failure or no cached data, we'll pull the data from the regular endpoint
                if (compare || reportData == null || reportData.cacheFailure) {
                    reportData = await new DataTypeService().runQuery(
                        buildQueryObject(
                            query,
                            globalFilters,
                            startDate,
                            endDate,
                            displayUnenriched,
                            reportType,
                            compare,
                            startDate2,
                            endDate2,
                            dataTypeFields,
                            dataTypeName
                        ),
                        { unique: false }
                    );
                }

                const newTableData = reportDataToTableData(
                    reportType,
                    reportData,
                    compare ? COMPARE_MODE : REGULAR_MODE,
                    false,
                    { status: null },
                    false,
                    [...renameTimebreakdownDimension(query.dimensions), ...query.metrics, ...query.dataTypeFields]
                );
                newTableData.reportId = reportData.report_id;
                newTableData.version = reportData.version_hash || null;
                if (heatMapOptions) {
                    newTableData.columnDefs = getHeatMapColumnDefs(newTableData.columnDefs, heatMapOptions);
                }
                if (shouldSetData) {
                    setTableData(newTableData);
                    dispatchWidgetVersions({ [widgetId]: newTableData.version });

                    setLoading(false);
                    dispatchWidgetsLoading({ [widgetId]: false });
                }
            } catch (e) {
                console.error(e);
                if (shouldSetData && e.name !== 'AbortError') {
                    setError(e);
                    setLoading(false);
                    dispatchWidgetsLoading({ [widgetId]: false });
                }
            }
        };

        fetchDataBlocking();
        return () => {
            shouldSetData = false;
            abortController.abort();
        };
    }, [
        JSON.stringify(
            [
                ...query.dimensions,
                ...query.metrics,
                ...query.filters,
                ...query.dataTypeFields,
                query.skanDateDimensionName,
            ].sort()
        ),
        startDate,
        endDate,
        startDate2,
        endDate2,
        reportType,
        compare,
        forceRefreshCounter,
        globalFilters,
    ]);

    return [loading, tableData, error];
}

export function useFetchAllFields() {
    return useRequest(
        useCallback(fieldsAPI.updateAllFields, []),
        () => {
            return true;
        },
        () => {}
    );
}

export function useFetchFilterFields() {
    return useRequest(
        useCallback(fieldsAPI.updateFilterFields, []),
        () => {
            return true;
        },
        () => {}
    );
}

export function useFetchDataTypesToMetricName() {
    return useRequest(
        useCallback(unifiedReportAPI.getDataTypesToMetricName, []),
        () => {
            return true;
        },
        () => {}
    );
}

export const useFilteredRowData = (rowData, topXCount, metricForTopX) => {
    return useMemo(() => {
        return getFilteredRowData(rowData, topXCount, metricForTopX);
    }, [rowData, topXCount]);
};
