import update from 'immutability-helper';
import { SET_GENERAL_ERROR, SET_IS_LOADING } from './actions';

const initialState = {};

const appLoadingStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GENERAL_ERROR: {
            return update(state, {
                errorMsg: {
                    $set: action.errorMsg,
                },
                isLoading: {
                    $set: false,
                },
            });
        }
        case SET_IS_LOADING: {
            return update(state, {
                isLoading: {
                    $set: action.isLoading,
                },
            });
        }
        default:
            return state;
    }
};

export default appLoadingStateReducer;
