import API from './api';

let instance = null;
const api = new API();

const URL_SINGULAR_LINKS_SOURCES = 'api/attribution/singular_links/sources';
const URL_ATTRIBUTION_CONFIGURATION = 'api/attribution/configuration';
const URL_ATTRIBUTION_SOURCE_CONFIGURATION = '/api/attribution/partner_configuration';

class Sources {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getSingularLinksSources() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_SINGULAR_LINKS_SOURCES, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    postAttributionConfiguration(partnerId, appSiteId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ATTRIBUTION_CONFIGURATION, {
                method: 'POST',
                credentials: 'include',
                body: {
                    partner_id: partnerId,
                    app_site_id: appSiteId,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getAttributionConfiguration() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ATTRIBUTION_CONFIGURATION, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getAttributionSourceConfiguration(partnerId, appSiteId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_ATTRIBUTION_SOURCE_CONFIGURATION}/${partnerId}/${appSiteId}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getPartnerConfiguration(partnerName) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_ATTRIBUTION_SOURCE_CONFIGURATION}?partner=${partnerName}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default Sources;
