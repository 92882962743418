import update from 'immutability-helper';
import {
    LOAD,
    APP_FILTER_CHANGED,
    APP_SELECTED_FIRST,
    BANNERS_FOR_APP,
    CLOSE_SHELF,
    OPEN_SHELF_EDIT_MODE,
    DESTROY,
    ADD_BANNER_CLICKED,
    SAVING_CONFIGURATION,
    SAVING_CONFIGURATION_FAILED,
    APP_SITE_CHANGED,
} from './actions';
import { normalizeInitialValues, EMPTY_URL_PATTERN_VALUE, ALL_DEVICE_TYPES_PER_PLATFORM } from './utils';
import { BannerFormat, BannerPlacement, CSS_TEMPLATE } from './consts';

const DEFAULT_INITIAL_VALUES = {
    format: BannerFormat.SMALL,
    placement: BannerPlacement.TOP,
    css: CSS_TEMPLATE,
    showOnAllPages: true,
    device_types: ALL_DEVICE_TYPES_PER_PLATFORM,
    ...EMPTY_URL_PATTERN_VALUE,
};

export const initialState = {
    loading: false,
    loadingConfigs: false,
    searching: false,
    selectedAppId: -1,
    selectedAppSiteId: -1,
    appsFilter: '',
    configurationPerApp: {},
    countries: [],
    shelf: {
        open: false,
        type: 'create',
        initialValues: DEFAULT_INITIAL_VALUES,
    },
    savingConfiguration: false,
    savingError: null,
    savedNewBanner: false,
    blockMessage: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD: {
            const { countries, loading } = action;
            return update(state, {
                loading: { $set: loading },
                countries: { $set: countries },
            });
        }
        case DESTROY: {
            return initialState;
        }
        case APP_FILTER_CHANGED: {
            return update(state, {
                appsFilter: { $set: action.value },
            });
        }
        case APP_SELECTED_FIRST: {
            const { appId } = action;

            return update(state, {
                selectedAppId: { $set: appId },
                loadingConfigs: { $set: true },
            });
        }
        case APP_SITE_CHANGED: {
            const { appSiteId } = action;
            return update(state, {
                selectedAppSiteId: { $set: appSiteId },
            });
        }
        case BANNERS_FOR_APP: {
            const { appSiteId: actionAppSiteId, configuredBanners } = action;
            const { loadingConfigs, selectedAppSiteId } = state;
            const newConfig = {
                [actionAppSiteId]: {
                    $set: {
                        configuredBanners,
                    },
                },
            };

            return update(state, {
                configurationPerApp: newConfig,
                loadingConfigs: { $set: loadingConfigs && selectedAppSiteId !== actionAppSiteId }, // Do deal with multiple loads
            });
        }
        case OPEN_SHELF_EDIT_MODE: {
            const { configuredBanner } = action;
            return update(state, {
                shelf: {
                    open: {
                        $set: true,
                    },
                    initialValues: {
                        $set: normalizeInitialValues(configuredBanner),
                    },
                },
            });
        }
        case CLOSE_SHELF: {
            return update(state, {
                shelf: {
                    open: {
                        $set: false,
                    },
                    initialValues: {
                        $set: DEFAULT_INITIAL_VALUES,
                    },
                },
                savingError: {
                    $set: false,
                },
            });
        }
        case ADD_BANNER_CLICKED: {
            return update(state, {
                shelf: {
                    open: {
                        $set: true,
                    },
                    initialValues: {
                        $set: DEFAULT_INITIAL_VALUES,
                    },
                },
            });
        }
        case SAVING_CONFIGURATION: {
            return update(state, {
                savingConfiguration: {
                    $set: action.savingConfiguration,
                },
                savedNewBanner: {
                    $set: action.savedNewBanner,
                },
            });
        }
        case SAVING_CONFIGURATION_FAILED: {
            return update(state, {
                savingConfiguration: {
                    $set: false,
                },
                savingError: {
                    $set: action.error,
                },
            });
        }
        default:
            return state;
    }
};
