import React from 'react';
import PropTypes from 'prop-types';
import CopyArea from './CopyArea';
import QRCodeWidget from './QRCodeWidget';
import css from './CopyAreaWithQRCode.css';

function CopyAreaWithQRCode({
    containerStyle,
    type,
    label,
    copyText,
    copiedText,
    content,
    qrCodeLabel,
    qrCodeDownloadPNG,
    qrCodeDownloadSVG,
    qrCodeSize,
}) {
    return (
        <div className={`${css.container} ${css[type]}`} style={containerStyle}>
            <div className={css.copyAreaWithQRCodeContainer}>
                <div className={css.copyAreaContainer}>
                    <CopyArea
                        containerStyle={containerStyle}
                        type={type}
                        label={label}
                        copyText={copyText}
                        copiedText={copiedText}
                        content={content}
                    />
                </div>

                <div className={css.qrCodeContainer}>
                    <QRCodeWidget
                        label={qrCodeLabel}
                        downloadPNG={qrCodeDownloadPNG}
                        downloadSVG={qrCodeDownloadSVG}
                        size={qrCodeSize}
                        value={content}
                    />
                </div>
            </div>
        </div>
    );
}

CopyAreaWithQRCode.propTypes = {
    containerStyle: PropTypes.objectOf(PropTypes.any),
    type: PropTypes.oneOf(['small', 'large', null]),
    label: PropTypes.string,
    copyText: PropTypes.string,
    copiedText: PropTypes.string,
    content: PropTypes.string,
    qrCodeLabel: PropTypes.string,
    qrCodeDownloadPNG: PropTypes.string,
    qrCodeDownloadSVG: PropTypes.string,
    qrCodeSize: PropTypes.number,
    onCopyClicked: PropTypes.func,
};

CopyAreaWithQRCode.defaultProps = {
    containerStyle: {},
    type: null,
    label: '',
    copyText: '',
    copiedText: '',
    content: '',
    qrCodeLabel: '',
    qrCodeDownloadPNG: '',
    qrCodeDownloadSVG: '',
    qrCodeSize: 100,
    textReplace: {},
    onCopyClicked: () => {},
};

export default CopyAreaWithQRCode;
