import React from 'react';
import PropTypes from 'prop-types';
import css from './UsedSlots.css';

function UsedSlots({size, slots}) {
    return (
        <div className={css.container}>
            {slots.map((slot, i) => (
                <div key={i} className={css.box} style={{backgroundColor: slot.color}}>{slot.text}</div>
            ))}
            {Array(Math.max(0, size - slots.length)).fill().map((_, i) => (
                <div key={slots.length + i} className={css.box}/>
            ))}
        </div>
    );
}

UsedSlots.propTypes = {
    size: PropTypes.number,
    slots: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
    })),
};

UsedSlots.defaultProps = {
    size: 6,
    slots: [],
};

export default UsedSlots;
