import { TOUR_LINK_IDS } from 'utils/consts';
import {
    clearFieldSelection,
    dateChangedGlobal,
    modeSelectedGeneric,
    setActionType,
    toggleSlimMode,
} from 'actions/reports';
import { reportDataSelectors } from 'selectors/reports';
import { getUserData } from 'selectors/user';
import { isFieldSelectionCleared, reportTypes, unenrichedButton } from 'utils/reports';
import { getDaysDiff, getSecondDatePickerRanges } from 'utils/regional';
import { COMPARE_MODE, REGULAR_MODE } from 'components/pages/reports/consts';
import { getFieldPresetButtons } from './fieldPresets';

import {
    admonAlignmentToggle,
    getBaseConfig,
    getEmptyStateConfig,
    getExpandedFiltersButton,
    shelfCohortMetrics,
    shelfConversionEvents,
    shelfCrossDeviceDemoCohortType,
    shelfCustomDimensions,
    shelfCustomEvents,
    shelfDimensions,
    shelfDiscrepancyMetrics,
    shelfEnrichmentDimensions,
    shelfFileCombinerDimensions,
    shelfGovernanceDimensions,
    shelfGranularityLevels,
    shelfMetadataDimensions,
    shelfMetrics,
    shelfReportingAppMetrics,
    shelfReportingWebMetrics,
    shelfSocialEngagementMetrics,
    shelfSourceAttributionType,
    shelfSourceDimensions,
    shelfTimeBreakdowns,
    shelfUTMParamsFields,
    shelfXorgCohortMetrics,
    shelfXorgMetrics,
    unenrichedToggle,
} from './baseReportConfig';
import { shelfPeriods } from './shelfPeriods';
import EraserIcon from '../../resources/icons/eraser.svg';
import PlusIcon from '../../resources/svg/plus_link.svg';
import reportSingularButtonCss from '../../components/widgets/ReportSingularButton.css';

// widgets
const ActionButton = 'ActionButton';
const Datepicker = 'Datepicker';
const Toggle = 'Toggle';
const SingularButton = 'SingularButton';
const Label = 'Label';

const type = reportTypes.pivot;
const path = '/react/pivot';

const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.PIVOT');

export default {
    ...basicConfig,
    path,
    chart: {
        enabled: false,
        show: false,
    },
    pivotTable: {},
    events: {
        load: ['Pivot: Page Loaded'],
        urlQuery: ['Pivot: Query Loaded From URL'],
    },
    header: {
        text: 'STATIC.PAGE_HEADERS.PIVOT',
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/360031266632',
            tourId: TOUR_LINK_IDS.pivot,
        },
        emptyStateHeaderData: getEmptyStateConfig('PIVOT'),
        leftComponents: [
            {
                id: 'date',
                componentClass: Datepicker,
                props: {
                    id: 'headerDatepicker',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const containerStyle = {
                        width: 232,
                    };
                    const startDate = selected.reportDates.start_date;
                    const endDate = selected.reportDates.end_date;
                    return {
                        startDate,
                        endDate,
                        containerStyle,
                        title:
                            selected.mode === COMPARE_MODE
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(startDate, endDate) },
                                  ]
                                : null,
                    };
                },
                actions: [
                    {
                        actionName: 'onApply',
                        action: data => {
                            return setActionType(dateChangedGlobal(type, data), type);
                        },
                    },
                ],
            },
            {
                id: 'vsLabel',
                componentClass: Label,
                props: {
                    text: 'vs',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const style = {
                        margin: '0 10px 5px 10px',
                    };
                    if (selected.mode !== COMPARE_MODE) {
                        style.display = 'none';
                    }
                    return {
                        style,
                    };
                },
                actions: [],
            },
            {
                id: 'date2',
                componentClass: Datepicker,
                props: {
                    id: 'headerDatepicker2',
                    dynamic: true,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const containerStyle = {
                        verticalAlign: 'bottom',
                        width: 230,
                    };
                    if (selected.mode !== COMPARE_MODE) {
                        containerStyle.display = 'none';
                    }
                    const startDate = selected.reportDates.start_date_2;
                    const endDate = selected.reportDates.end_date_2;
                    let ranges = null;
                    if (selected.reportDates.start_date && selected.reportDates.end_date) {
                        ranges = getSecondDatePickerRanges(
                            selected.reportDates.start_date,
                            selected.reportDates.end_date
                        );
                    }
                    return {
                        ranges,
                        startDate,
                        endDate,
                        containerStyle,
                        title:
                            selected.mode === COMPARE_MODE
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PREVIOUS_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(startDate, endDate) },
                                  ]
                                : null,
                    };
                },
                actions: [
                    {
                        actionName: 'onApply',
                        action: data => {
                            const newData = {
                                start_date_2: data.start_date,
                                end_date_2: data.end_date,
                            };
                            return setActionType(dateChangedGlobal(type, newData), type);
                        },
                    },
                ],
            },
            {
                id: 'compare',
                componentClass: ActionButton,
                props: {
                    type: 'secondary',
                    level: 'level1',
                    cssModifier: 'compare',
                    IconClass: PlusIcon,
                    tooltipText: 'STATIC.BUTTONS.COMPARE_TWO_PERIODS',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);

                    const containerStyle = {
                        marginRight: '10px',
                    };

                    if (selected.mode !== REGULAR_MODE) {
                        containerStyle.display = 'none';
                    }
                    return {
                        containerStyle,
                        disabled: !!selected.disableReportState,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            return modeSelectedGeneric(type, 'compare');
                        },
                    },
                ],
            },
            {
                id: 'regularMode',
                componentClass: ActionButton,
                props: {
                    text: 'STATIC.BUTTONS.REMOVE',
                    primary: false,
                    cssModifier: 'remove_compare',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const userData = getUserData(state);

                    const containerStyle = {
                        marginRight: userData.show_report_toggle ? '20px' : 0,
                    };
                    if (selected.mode !== COMPARE_MODE) {
                        containerStyle.display = 'none';
                    }
                    return {
                        containerStyle,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            return modeSelectedGeneric(type, 'regular');
                        },
                    },
                ],
            },
            getExpandedFiltersButton(type),
            {
                id: 'toggle_slim_mode',
                componentClass: Toggle,
                props: {
                    label: 'STATIC.BUTTONS.ADVANCED_VIEW',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const userData = getUserData(state);

                    return {
                        checked: userData.show_report_toggle && !selected.isSlimMode,
                        style: {
                            display:
                                userData.show_report_toggle && selected.showFieldsSelection ? 'inline-block' : 'none',
                            marginLeft: '10px',
                            width: '165px',
                        },
                    };
                },
                actions: [
                    {
                        actionName: 'onToggle',
                        action: ({ target }) => {
                            return setActionType(toggleSlimMode(!target.checked), type);
                        },
                    },
                ],
            },
            {
                id: 'clear_selection',
                componentClass: SingularButton,
                props: {
                    text: 'STATIC.BUTTONS.CLEAR_SELECTION',
                    type: 'link',
                    Icon: EraserIcon,
                    iconClass: reportSingularButtonCss.clearButtonIcon,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const { selectedFields, filters: selectedFilters } = state[type];

                    return {
                        style: {
                            display: selected.showFieldsSelection ? 'inline-block' : 'none',
                            padding: '0 5px',
                        },
                        disabled:
                            isFieldSelectionCleared(selectedFields, selectedFilters) || !!selected.disableReportState,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            window.mixpanel.track('Clear Report Fields Clicked', { report_type: type });

                            return setActionType(clearFieldSelection(), type);
                        },
                    },
                ],
            },
        ],
        rightComponents: [
            ...basicConfig.header.rightComponents,
            unenrichedButton(type),
            ...getFieldPresetButtons(type),
        ],
    },
    shelf: [
        shelfDimensions,
        shelfGovernanceDimensions,
        shelfCustomDimensions,
        shelfFileCombinerDimensions,
        shelfGranularityLevels,
        shelfEnrichmentDimensions,
        shelfSourceDimensions,
        shelfMetadataDimensions,
        shelfMetrics,
        shelfReportingAppMetrics,
        shelfConversionEvents,
        shelfXorgMetrics,
        shelfDiscrepancyMetrics,
        shelfSocialEngagementMetrics,
        shelfCohortMetrics,
        shelfXorgCohortMetrics,
        shelfCustomEvents,
        shelfPeriods,
        shelfUTMParamsFields,
        shelfReportingWebMetrics,
        shelfTimeBreakdowns,
        shelfSourceAttributionType,
        shelfCrossDeviceDemoCohortType,
        unenrichedToggle,
        admonAlignmentToggle,
    ],
};
