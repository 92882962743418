import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsVariwide from 'highcharts/modules/variwide';
import PropTypes from 'prop-types';

const tooltipFormatType = PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired,
    precision: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
});
const VariwideChart = ({ title, subTitle, tooltipFormat, points, height }) => {
    highchartsVariwide(Highcharts);

    const getTooltipPointFormat = () => {
        const { y, z } = tooltipFormat;
        return (
            `${y.displayName}: <b>${y.prefix}{point.y:.${y.precision}f}${y.suffix}</b><br>` +
            `${z.displayName}: <b>${z.prefix}{point.z:.${z.precision}f}${z.suffix}</b><br>`
        );
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'variwide',
                    height,
                },
                title: {
                    text: title,
                },
                subtitle: {
                    text: subTitle,
                },
                xAxis: {
                    type: 'category',
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    pointFormat: getTooltipPointFormat(),
                },
                series: [
                    {
                        name: '',
                        data: points,
                        colorByPoint: true,
                    },
                ],
                credits: {
                    enabled: false,
                },
            }}
        />
    );
};

VariwideChart.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired,
    tooltipFormat: PropTypes.shape({
        y: tooltipFormatType.isRequired,
        z: tooltipFormatType.isRequired,
    }).isRequired,
    height: PropTypes.number,
};

VariwideChart.defaultProps = {
    title: '',
    subTitle: '',
    height: null,
};

export default VariwideChart;
