import React, { useState, Fragment } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import omit from 'lodash/omit';
import WizardWarningMessage from 'teamManagement/components/WizardWarningMessage';
import trim from 'lodash/trim';
import some from 'lodash/some';
import countBy from 'lodash/countBy';
import { DROPDOWN_TYPES, FIELD_TYPES } from 'governance/consts';
import css from '../governance.css';
import ExpandablePanel from '../../components/widgets/ExpandablePanel';
import EditableTitle from '../../components/widgets/EditableTitle';
import Field from './field';
import SingularButton from '../../components/widgets/SingularButton';
import { computePattern, computeSampleCode, getLocalSchemaId } from '../utils';
import TrashIcon from '../../resources/svg/trash.svg';

import client from '../../apollo';
import { READ_SCHEMAS, CREATE_SCHEMA, SCHEMA_FRAGMENT, UPDATE_SCHEMA, DELETE_SCHEMA } from '../queries';
import Tooltip from '../../components/widgets/Tooltip';
import { Label } from '../../components/widgets';
import { trackMixpanelEvent } from '../../utils/general';
import Icon, { IconVariants } from '../../components/foundations/Icon';

export const getSchemaTypeDisplayName = schemaType => {
    const lowercaseSchemaType = schemaType?.toLowerCase();
    if (lowercaseSchemaType?.includes('campaign')) {
        return 'Campaign';
    } else if (lowercaseSchemaType?.includes('creative') || lowercaseSchemaType?.includes('migrate')) {
        return 'Creative';
    }
    return 'Unknown';
};

function Schema({ schema, translate }) {
    const [origSchema, setOrigSchema] = useState(schema);
    const [expanded, setExpanded] = useState(schema.id.startsWith('local'));
    const [saveStatus, setSaveStatus] = useState('');
    const [error, setError] = useState('');

    function validate() {
        if (trim(schema.name).length === 0) {
            setError('Schema name cannot be empty.');
            return false;
        }

        if (schema.fields.length === 0) {
            setError('Schema must contain at least 1 field.');
            return false;
        }

        if (!schema.type) {
            setError('Missing schema type.');
            return false;
        }

        const fieldValidation = schema.fields.map(field => {
            if (trim(field.name).length === 0) {
                setError('Field name is missing');
                return false;
            }

            if (trim(field.kind).length === 0) {
                setError('Field type is missing');
                return false;
            }

            if (DROPDOWN_TYPES.includes(field.kind)) {
                if (field.values.length === 0) {
                    setError(`Field ${field.name} is missing allowed values.`);
                    return false;
                }

                const codeCounts = countBy(field.values, x => x.code);
                const dupeCodes = Object.keys(codeCounts).filter(x => codeCounts[x] > 1);
                if (dupeCodes.length > 0) {
                    setError(`Field ${field.name} has duplicate shortened values ${dupeCodes.join(', ')}.`);
                    return false;
                }
            }

            return true;
        });

        if (some(fieldValidation, x => x === false)) {
            return false;
        }

        if (!schema.pattern.includes('{GOV_ID}')) {
            setError('Taxonomy pattern must include "{GOV_ID}"');
            return false;
        }

        return true;
    }

    function addFieldInCache() {
        const x = client.readFragment({
            id: `SchemaNode:${schema.id}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
        });

        // Find a unique "new field" name
        let num = 1;
        let desiredName = 'New Field';
        while (x.fields.find(f => f.name === desiredName)) {
            desiredName = `New Field (${num})`;
            ++num;
        }

        // Add a new field
        const newFields = [
            ...x.fields,
            {
                __typename: 'FieldNode',
                guid: null,
                name: desiredName,
                kind: 'autocomplete',
                values: [],
                default: [],
                optional: false,
                hidden: false,
            },
        ];
        client.writeFragment({
            id: `SchemaNode:${schema.id}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
            data: {
                ...x,
                pattern: computePattern(newFields),
                fields: newFields,
            },
        });
    }

    function replaceSchemaInCache(newSchema) {
        const schemas = client.readQuery({ query: READ_SCHEMAS }).schemas.slice();
        schemas.splice(
            schemas.findIndex(x => x.id === schema.id),
            1,
            newSchema
        );
        client.writeQuery({
            query: READ_SCHEMAS,
            data: {
                schemas,
            },
        });
    }

    function duplicateSchema() {
        if (schema.id.startsWith('local')) return;

        const newSchema = {
            __typename: 'SchemaNode',
            id: `local${getLocalSchemaId()}`,
            name: `Copy of ${schema.name}`,
            fields: schema.fields.map(f => ({
                ...f,
                guid: null,
                __typename: 'FieldNode',
            })),
        };
        newSchema.pattern = computePattern(newSchema.fields);
        const { schemas } = client.readQuery({ query: READ_SCHEMAS });
        client.writeQuery({
            query: READ_SCHEMAS,
            data: {
                schemas: [newSchema, ...schemas],
            },
        });

        trackMixpanelEvent('Governance', 'Schema Duplicated', {
            schema_name: newSchema.name,
            schema_pattern: newSchema.pattern,
            field_count: newSchema.fields.length,
            field_types: newSchema.fields.map(x => x.kind),
            has_default_fields: newSchema.fields.some(x => x.default),
            has_optional_fields: newSchema.fields.some(x => x.optional),
            has_hidden_fields: newSchema.fields.some(x => x.hidden),
        });
    }

    function afterCreate(cache, { data: { createSchema } }) {
        setSaveStatus('saved');
        setTimeout(() => {
            replaceSchemaInCache(createSchema);
        }, 1000);
    }

    function deleteSchemaFromCache() {
        const schemas = client.readQuery({ query: READ_SCHEMAS }).schemas.slice();
        schemas.splice(
            schemas.findIndex(x => x.id === schema.id),
            1
        );
        client.writeQuery({
            query: READ_SCHEMAS,
            data: {
                schemas,
            },
        });
    }

    function updateSchemaInCache(key, value) {
        const x = client.readFragment({
            id: `SchemaNode:${schema.id}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
        });
        client.writeFragment({
            id: `SchemaNode:${schema.id}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
            data: {
                ...x,
                [key]: value,
            },
        });
    }

    function writeEntireSchemaInCache(schemaValue) {
        client.writeFragment({
            id: `SchemaNode:${schema.id}`,
            fragment: SCHEMA_FRAGMENT,
            fragmentName: 'Schema',
            data: schemaValue,
        });
    }

    const [createSchema] = useMutation(CREATE_SCHEMA, { update: afterCreate });
    const [updateSchema] = useMutation(UPDATE_SCHEMA, {
        onCompleted({ updateSchema }) {
            setSaveStatus('saved');
            // replaceSchemaInCache(updateSchema);
            setOrigSchema(updateSchema);
            setTimeout(() => {
                setSaveStatus('');
                setExpanded(false);
            }, 500);
        },
        onError(error) {
            console.log('ERROR', error);
            setSaveStatus('');
        },
    });
    const [deleteSchema] = useMutation(DELETE_SCHEMA, { update: deleteSchemaFromCache });

    function onSave() {
        if (!validate()) return;

        setError('');
        setSaveStatus('saving');

        // If this is a locally saved copy, create on the server
        if (schema.id.startsWith('local')) {
            createSchema({ variables: omit(schema, ['id']) });
        } else {
            updateSchema({ variables: schema });
        }
    }

    function onCancel() {
        // If this is a locally saved copy, cancelling means deleting the schema
        if (schema.id.startsWith('local')) {
            deleteSchemaFromCache();
        } else {
            writeEntireSchemaInCache(origSchema);
        }

        setExpanded(false);
    }

    return (
        <div className={css.schema}>
            <ExpandablePanel
                animate={false}
                isSticky
                expanded={expanded}
                onExpandClick={() => setExpanded(!expanded)}
                overrideClasses={{ contentArea: css.schemaContentArea }}
                header={
                    <div className={css.headerRow}>
                        <div className={css.schemaName}>
                            {expanded ? (
                                <>
                                    <div className={css.expandedTitleContainer}>
                                        <span className={css.schemaTypePrefix}>
                                            {getSchemaTypeDisplayName(schema.type)} -
                                        </span>
                                        <EditableTitle
                                            placeholder="Schema name"
                                            value={schema.name}
                                            onTextChanged={name => updateSchemaInCache('name', name)}
                                            error={error && !schema.name.length ? 'must not be empty' : ''}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className={css.expandedTitleContainer}>
                                    <span className={css.schemaTypePrefix}>
                                        {getSchemaTypeDisplayName(schema.type)} -
                                    </span>
                                    {schema.name ? (
                                        <span>{`${schema.name}`}</span>
                                    ) : (
                                        <span style={{ fontStyle: 'italic', color: '#ccc' }}>(Empty Schema Name)</span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={css.schemaActions}>
                            {!schema.id.startsWith('local') && (
                                <Tooltip interactive titleTranslationKey="STATIC.PAGES.GOVERNANCE.DUPLICATE_SCHEMA">
                                    <Icon
                                        name="copy"
                                        variant={IconVariants.LIGHT}
                                        onClick={e => {
                                            e.stopPropagation();
                                            duplicateSchema();
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip interactive titleTranslationKey="STATIC.PAGES.GOVERNANCE.DELETE_SCHEMA">
                                <TrashIcon
                                    className={css.deleteSchemaIcon}
                                    onClick={e => {
                                        e.stopPropagation();

                                        if (!confirm('Are you sure you want to delete this schema?')) {
                                            return;
                                        }

                                        // If it's a local copy, remove it from cache
                                        if (schema.id.startsWith('local')) {
                                            deleteSchemaFromCache();
                                        } else {
                                            // otherwise, delete from server
                                            deleteSchema({ variables: { id: schema.id } });
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                }
                collapsedContent={
                    <div className={css.collapsedContentContainer}>
                        {schema.fields.map(field => (
                            <div key={`collapsed_${field.name}`} className={`${css.collapsedValueItem}`}>
                                <span>{field.name}</span>
                            </div>
                        ))}
                    </div>
                }
                subHeader={
                    expanded && error ? (
                        <WizardWarningMessage
                            show
                            showIcon={false}
                            message={error}
                            type="error"
                            textAlignCenter={false}
                        />
                    ) : null
                }
            >
                <div className={css.governanceFieldHeader}>
                    <Tooltip
                        interactive
                        position="top-start"
                        title={translate('STATIC.PAGES.GOVERNANCE.NAME_PATTERN_TOOLTIP')}
                    >
                        <Label text={`${translate('STATIC.PAGES.GOVERNANCE.NAME_PATTERN')}: ${schema.pattern}`} />
                    </Tooltip>
                </div>

                <div className={css.governanceFieldContent}>
                    <Label
                        text={`${translate('STATIC.PAGES.GOVERNANCE.EXAMPLE')}: "${computeSampleCode(schema.fields)}"`}
                    />
                </div>

                {schema.fields.map((field, index) => (
                    <Field
                        key={index.toString()}
                        schemaId={schema.id}
                        fieldIndex={index}
                        field={field}
                        error={error}
                        firstField={index === 0}
                        lastField={index === schema.fields.length - 1}
                    />
                ))}

                <div className={css.footerBlock}>
                    <div className={css.addValueButtonContainer}>
                        <SingularButton
                            type="primary"
                            onClick={() => {
                                addFieldInCache();
                            }}
                        >
                            <Translate id="STATIC.PAGES.GOVERNANCE.ADD_FIELD" />
                        </SingularButton>
                    </div>
                    <SingularButton type="flat" onClick={onCancel}>
                        <Translate id="STATIC.BUTTONS.CANCEL" />
                    </SingularButton>
                    <SingularButton
                        type="secondary"
                        level="level1"
                        showSpinner={saveStatus === 'saving'}
                        showV={saveStatus === 'saved'}
                        onClick={onSave}
                    >
                        <Translate id="STATIC.BUTTONS.SAVE" />
                    </SingularButton>
                </div>
            </ExpandablePanel>
        </div>
    );
}

Schema.propTypes = {
    schema: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pattern: PropTypes.string,
        type: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                guid: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                kind: PropTypes.oneOf(FIELD_TYPES.map(x => x.value)),
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string.isRequired,
                        value: PropTypes.string.isRequired,
                    })
                ).isRequired,
                default: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                optional: PropTypes.bool,
                hidden: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
    translate: PropTypes.func.isRequired,
};

Schema.fragments = {
    schema: SCHEMA_FRAGMENT,
};

export default withLocalize(Schema);
