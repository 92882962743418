import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '../components/widgets';
import css from './ModelDescription.css';
import { models } from './consts';

export default function ModelDescription({ modelName }) {
    const model = models.filter(m => m.name === modelName)[0] || models[0];

    return (
        <div className={css.container}>
            <Label text={model.display_name} className={css.title} />
            <div className={css.description}>{model.description}</div>
            <div className={css.graph}>
                <img src={model.image} />
            </div>
        </div>
    );
}

ModelDescription.propTypes = {
    modelName: PropTypes.string,
};

ModelDescription.defaultProps = {
    modelName: 'linear',
};
