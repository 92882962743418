import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { trackMixpanelEvent } from 'utils/general';
import PropTypes from 'prop-types';
import { NON_EMPTY_FILTERS } from 'benchmarks/consts';
import debounce from 'lodash/debounce';
import { useFetchMMMData } from 'mediaMixModeling/hooks';
import { validationErrorsFuncs } from 'benchmarks/utils';
import Spinner from 'components/widgets/Spinner';
import { WhitePageHeader } from '../components/partials';
import Charts from 'benchmarks/components/benchmarksCharts';
import {
    APP_FILTER,
    COUNTRY_FILTER,
    CURRENCY,
    INSTALLS_METRIC,
    METRIC_FILTER,
    MMM_GRAPH_TYPES,
    PLATFORM_FILTER,
    TRACK_EVENT_FILTER_CHANGE,
    TRACK_EVENT_PREFIX,
    TRACK_EVENT_PAGE_LOADED,
} from 'mediaMixModeling/consts';
import { monthTitleFormatter } from 'mediaMixModeling/utils';
import { useSelector } from 'react-redux';
import css from './page.css';
import CoefPerChannelChart from './components/coefPerChannelChart';
import NetworkShareChart from './components/networkShareChart';
import OverTimeChart from './components/overTimeChart';
import TrendChart from './components/trendChart';
import { PageWrapper } from '../components/partials';
import Filters from './components/filters';
import { getAdminModeEnabled } from '../selectors/user';
import StackAreaTrendChart from './components/StackAreaTrendChart';

const MediaMixModeling = ({ translate }) => {
    const [isFading, setFading] = useState(false);
    const [shouldShowCharts, setShouldShowCharts] = useState(false);
    const [selectedFilterValues, setSelectedFilterValues] = useState({});
    const {
        graphs: chartsData = {},
        metric_type: metricType,
        reference_date: referenceDate,
        currency,
        isLoading,
        zero_cost_channels: zeroCostChannels,
    } = useFetchMMMData(selectedFilterValues);
    const isAdminMode = useSelector(state => getAdminModeEnabled(state));
    useEffect(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_PAGE_LOADED);
    }, []);

    const onFiltersChange = useCallback(
        debounce(newSelectedValues => {
            setSelectedFilterValues(newSelectedValues);
            setFading(false);
        }, 2000),
        []
    );

    const onChange = newSelectedValues => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_FILTER_CHANGE, { selectedValues: newSelectedValues });
        const _shouldShowCharts =
            Object.keys(newSelectedValues).length > 0 &&
            newSelectedValues.app.length > 0 &&
            newSelectedValues.country.length > 0 &&
            newSelectedValues.platform.length > 0 &&
            newSelectedValues.metric.length > 0;
        setShouldShowCharts(_shouldShowCharts);
        setFading(true);
        onFiltersChange(newSelectedValues);
    };
    const isInstallsMetric = selectedFilterValues.metric && selectedFilterValues.metric[0] === INSTALLS_METRIC;
    return (
        <Fragment>
            <div className={css.pageHeaderWrapper}>
                <WhitePageHeader
                    text="STATIC.PAGES.MEDIA_MIX_MODELING.PAGE_NAME"
                    className={css.pageHeader}
                    style={{ paddingLeft: '40px', height: '58px' }}
                    textStyle={{ fontWeight: '600', fontSize: '24px' }}
                />
            </div>
            <PageWrapper className={css.pageWrapper}>
                <Filters onChange={onChange} getValidationErrors={validationErrorsFuncs[NON_EMPTY_FILTERS]}>
                    <Filters.Apps optionsKey={APP_FILTER} />
                    <Filters.Countries optionsKey={COUNTRY_FILTER} includeAllOption={false} />
                    <Filters.Platforms optionsKey={PLATFORM_FILTER} includeAllOption={false} />
                    <Filters.Metrics optionsKey={METRIC_FILTER} />
                </Filters>
                <Spinner style={{ top: '10vh' }} show={isLoading && shouldShowCharts} expanded />
                {shouldShowCharts && (
                    <Charts
                        isLoading={isLoading}
                        isFading={isFading}
                        emptyStateSubText="STATIC.PAGES.MEDIA_MIX_MODELING.EMPTY_STATE_SUBTEXT"
                        trackEventPrefix={TRACK_EVENT_PREFIX}
                    >
                        <Charts.CoefPerChannelChart
                            data={chartsData[MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL_SLOPE_FORCING_LATEST]}
                            chartKey={MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL_SLOPE_FORCING_LATEST}
                            title={`${
                                isInstallsMetric
                                    ? translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.CPI_PER_CHANNEL_GRAPH_SLOPE_FORCING_LATEST'
                                      )
                                    : translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.ROI_PER_CHANNEL_GRAPH_SLOPE_FORCING_LATEST'
                                      )
                            } (${monthTitleFormatter(referenceDate)})`}
                            subtitle={referenceDate}
                            metricType={metricType}
                            currency={currency}
                        />
                        <Charts.CoefPerChannelChart
                            data={isAdminMode ? chartsData[MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL] : null}
                            chartKey={MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL}
                            title={`${
                                isInstallsMetric
                                    ? translate('STATIC.PAGES.MEDIA_MIX_MODELING.CPI_PER_CHANNEL_GRAPH')
                                    : translate('STATIC.PAGES.MEDIA_MIX_MODELING.ROI_PER_CHANNEL_GRAPH')
                            } (${monthTitleFormatter(referenceDate)})`}
                            metricType={metricType}
                            currency={currency}
                        />
                        <Charts.CoefPerChannelChart
                            data={isAdminMode ? chartsData[MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL_NO_SLOPE_FORCING] : null}
                            chartKey={MMM_GRAPH_TYPES.OUTCOME_PER_CHANNEL_NO_SLOPE_FORCING}
                            title={`${
                                isInstallsMetric
                                    ? translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.CPI_PER_CHANNEL_GRAPH_NO_SLOPE_FORCING'
                                      )
                                    : translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.ROI_PER_CHANNEL_GRAPH_NO_SLOPE_FORCING'
                                      )
                            } (${monthTitleFormatter(referenceDate)})`}
                            metricType={metricType}
                            currency={currency}
                        />
                        <Charts.NetworkShareChart
                            data={chartsData[MMM_GRAPH_TYPES.NETWORK_SHARE]}
                            chartKey={MMM_GRAPH_TYPES.NETWORK_SHARE}
                            title={`${translate(
                                'STATIC.PAGES.MEDIA_MIX_MODELING.NETWORK_SHARE_GRAPH'
                            )} (${monthTitleFormatter(referenceDate)})`}
                        />
                        <Charts.NetworkShareChart
                            data={isAdminMode ? chartsData[MMM_GRAPH_TYPES.NETWORK_SHARE_WITHOUT_SLOPE_FORCING] : null}
                            chartKey={MMM_GRAPH_TYPES.NETWORK_SHARE_WITHOUT_SLOPE_FORCING}
                            title={`${translate(
                                'STATIC.PAGES.MEDIA_MIX_MODELING.NETWORK_SHARE_GRAPH_WITHOUT_SLOPE_FORCING'
                            )} (${monthTitleFormatter(referenceDate)})`}
                        />
                        <Charts.OverTimeChart
                            data={chartsData[MMM_GRAPH_TYPES.CALCULATED_METRIC_OVER_TIME]}
                            chartKey={MMM_GRAPH_TYPES.CALCULATED_METRIC_OVER_TIME}
                            title={
                                isInstallsMetric
                                    ? translate('STATIC.PAGES.MEDIA_MIX_MODELING.CPI_OVER_TIME_GRAPH')
                                    : translate('STATIC.PAGES.MEDIA_MIX_MODELING.ROI_OVER_TIME_GRAPH')
                            }
                            metricType={metricType}
                            currency={currency}
                            zeroCostChannels={zeroCostChannels}
                        />
                        <Charts.OverTimeChart
                            data={chartsData[MMM_GRAPH_TYPES.DIRECT_RESPONSE_COUNTRY_TREND_OVER_TIME_SERIES]}
                            chartKey={MMM_GRAPH_TYPES.DIRECT_RESPONSE_COUNTRY_TREND_OVER_TIME_SERIES}
                            title={
                                isInstallsMetric
                                    ? translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.DIRECT_ATTRIBUTION_CPI_OVER_TIME_GRAPH'
                                      )
                                    : translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.DIRECT_ATTRIBUTION_ROI_OVER_TIME_GRAPH'
                                      )
                            }
                            metricType={metricType}
                            currency={currency}
                            zeroCostChannels={zeroCostChannels}
                        />
                        <Charts.OverTimeChart
                            data={chartsData[MMM_GRAPH_TYPES.NON_WEIGHTED_COST_OVER_TIME_SERIES]}
                            chartKey={MMM_GRAPH_TYPES.NON_WEIGHTED_COST_OVER_TIME_SERIES}
                            title={translate('STATIC.PAGES.MEDIA_MIX_MODELING.COST_OVER_TIME_GRAPH')}
                            metricType={CURRENCY}
                            currency={currency}
                        />
                        <Charts.TrendChart
                            data={chartsData[MMM_GRAPH_TYPES.COUNTRY_TREND_OVER_TIME]}
                            chartKey={MMM_GRAPH_TYPES.COUNTRY_TREND_OVER_TIME}
                            title={translate('STATIC.PAGES.MEDIA_MIX_MODELING.COUNTRY_ORGANIC_TREND_GRAPH')}
                        />
                        <Charts.TrendChart
                            data={chartsData[MMM_GRAPH_TYPES.APP_TREND_OVER_TIME]}
                            chartKey={MMM_GRAPH_TYPES.APP_TREND_OVER_TIME}
                            title={translate('STATIC.PAGES.MEDIA_MIX_MODELING.APP_ORGANIC_TREND_GRAPH')}
                        />
                        <Charts.TrendChart
                            data={chartsData[MMM_GRAPH_TYPES.DIRECT_AND_MODELED_OVER_TIME]}
                            chartKey={MMM_GRAPH_TYPES.DIRECT_AND_MODELED_OVER_TIME}
                            title={
                                isInstallsMetric
                                    ? translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.DIRECT_AND_MODELED_INSTALLS_OVER_TIME_GRAPH'
                                      )
                                    : translate(
                                          'STATIC.PAGES.MEDIA_MIX_MODELING.DIRECT_AND_MODELED_REVENUE_OVER_TIME_GRAPH'
                                      )
                            }
                            metricType={metricType}
                            currency={currency}
                        />
                        <Charts.StackAreaTrendChart
                            data={chartsData[MMM_GRAPH_TYPES.MODELED_BY_SOURCE_OVER_TIME]}
                            chartKey={MMM_GRAPH_TYPES.MODELED_BY_SOURCE_OVER_TIME}
                            title={
                                isInstallsMetric
                                    ? translate('STATIC.PAGES.MEDIA_MIX_MODELING.MODELED_INSTALLS_BY_SOURCE_GRAPH')
                                    : translate('STATIC.PAGES.MEDIA_MIX_MODELING.MODELED_REVENUE_BY_SOURCE_GRAPH')
                            }
                            metricType={metricType}
                            currency={currency}
                        />
                    </Charts>
                )}
            </PageWrapper>
        </Fragment>
    );
};

Charts.CoefPerChannelChart = CoefPerChannelChart;
Charts.NetworkShareChart = NetworkShareChart;
Charts.OverTimeChart = OverTimeChart;
Charts.TrendChart = TrendChart;
Charts.StackAreaTrendChart = StackAreaTrendChart;

MediaMixModeling.propTypes = {
    userData: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
};

MediaMixModeling.defaultProps = {
    userData: {},
    translate: () => {},
};

export default withLocalize(MediaMixModeling);
