import PropTypes from 'prop-types';

export const tasksTodoShape = PropTypes.shape({
    name: PropTypes.string,
    topic: PropTypes.string,
    goRedirect: PropTypes.string,
    disabled: PropTypes.bool,
});

export const emptyStatePropsType = {
    titleText: PropTypes.string,
    subTitleText: PropTypes.string,
    tasksTodo: PropTypes.arrayOf(tasksTodoShape),
    tasksDone: PropTypes.object,
    onCloseEmptyState: PropTypes.func,
    showCloseIcon: PropTypes.bool,
    pageName: PropTypes.string,
    onTaskClick: PropTypes.func,
    imageSrc: PropTypes.string,
};

export const emptyStateDefaultProps = {
    titleText: '',
    subTitleText: '',
    tasksTodo: [],
    tasksDone: {},
    onCloseEmptyState: () => {},
    showCloseIcon: false,
    pageName: '',
    onTaskClick: () => {},
    imageSrc: '',
};
