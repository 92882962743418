import API from 'services/api';

let instance = null;
const api = new API();
const URL_USERS_SELF_RESET_API_KEY = 'api/users/self/reset_api_key';

export default class APIKeysService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    resetAPIKey() {
        return api.getJson(URL_USERS_SELF_RESET_API_KEY, {
            method: 'POST',
            body: {},
        });
    }
}
