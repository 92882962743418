import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from '../styles.css';
import AutoCompleteField from '../../components/widgets/AutoCompleteField';
import { useFetchBenchmarksMetadata } from '../hooks';
import { filterOptionsParserByFilterType, findFilterValue, getFormattedSelectedValue } from '../utils';
import { APP_FILTER, CATEGORY_FILTER, COUNTRY_FILTER, PLATFORM_FILTER } from '../consts';

const BenchmarksFilters = ({ children, onChange, viewName, enableFilters, wrapperStyle, getValidationErrors }) => {
    const { breakdowns = {}, default_breakdowns: defaultFilterValues } = useFetchBenchmarksMetadata(viewName);
    const [selectedFilterValues, setSelectedFilterValues] = useState({});
    const [validationErrors, setValidationErrors] = useState(null);

    useEffect(() => {
        if (defaultFilterValues) {
            setSelectedFilterValues(defaultFilterValues);
            onChange(defaultFilterValues, null);
        }
    }, [defaultFilterValues]);

    const handleChange = (filterType, selected) => {
        if (selected) {
            const newSelectedFilterValues = {
                ...selectedFilterValues,
                [filterType]: getFormattedSelectedValue(selected),
            };
            const newValidationErrors = getValidationErrors(newSelectedFilterValues);

            setSelectedFilterValues(newSelectedFilterValues);
            setValidationErrors(newValidationErrors);

            onChange(newSelectedFilterValues, newValidationErrors);
        }
    };

    if (!defaultFilterValues) {
        return null;
    } else {
        return (
            <div className={classNames(css.headerFiltersWrapper)} style={wrapperStyle}>
                <div className={css.headerFilters}>
                    {React.Children.map(children, (child) => {
                        const {
                            optionsKey,
                            isRequired = false,
                            isMulti = false,
                            addEmptyOption,
                            optional,
                        } = child.props;
                        const options = filterOptionsParserByFilterType[optionsKey](
                            breakdowns[optionsKey],
                            { addEmptyOption },
                        );
                        const value = findFilterValue(options, selectedFilterValues[optionsKey], isMulti);
                        const error = validationErrors && validationErrors[optionsKey];

                        return React.cloneElement(child, {
                            value,
                            options,
                            isMulti,
                            searchable: true,
                            controlled: true,
                            clearable: !!isMulti,
                            containerClass: css.headerFilter,
                            onChange: (selected) => {
                                handleChange(optionsKey, selected, isRequired, isMulti);
                            },
                            openMenuOnClick: !!(enableFilters || error),
                            error,
                            optional,
                        });
                    })}
                </div>
            </div>
        );
    }
};

BenchmarksFilters.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onChange: PropTypes.func.isRequired,
    enableFilters: PropTypes.bool,
    wrapperStyle: PropTypes.objectOf(PropTypes.any),
    getValidationErrors: PropTypes.func,
    viewName: PropTypes.string.isRequired,
};

BenchmarksFilters.defaultProps = {
    enableFilters: true,
    wrapperStyle: {},
    getValidationErrors: () => {},
};

const BenchmarksFilter = withLocalize(({ optional, translate, type, ...props }) => {
    return (
        <AutoCompleteField
            label={`${translate(
                `STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.${type.toUpperCase()}_SELECTION_LABEL`
            )} ${optional ? translate('STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.SELECTION_LABEL_OPTIONAL') : ''}`}
            placeholder={`STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.${type.toUpperCase()}_SELECTION_PLACEHOLDER`}
            {...props}
        />
    );
});

BenchmarksFilters.Categories = (props) => {
    return <BenchmarksFilter {...props} type={CATEGORY_FILTER} />;
};

BenchmarksFilters.Countries = (props) => {
    return <BenchmarksFilter {...props} type={COUNTRY_FILTER} />;
};

BenchmarksFilters.Platforms = (props) => {
    return <BenchmarksFilter {...props} type={PLATFORM_FILTER} />;
};

BenchmarksFilters.Apps = (props) => {
    return <BenchmarksFilter {...props} type={APP_FILTER} />;
};

export default withLocalize(BenchmarksFilters);
