import API from '../services/api';
import { DataTypes, WIDGET_DATA_TYPES } from './widgetDataTypes';

let instance = null;
const api = new API();

const URL_WIDGET_DATA = '/api/get_dashboard_widget_data';
const URL_WIDGET_VERSIONS = '/api/get_dashboard_widget_latest_data_hash';

export default class WidgetService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    abortPromise() {
        return Promise.reject(new DOMException('Aborted', 'AbortError'));
    }

    getWidgetData(
        dashboardId,
        widgetId,
        globalFilters,
        dashboardStartDate,
        dashboardEndDate,
        reportType,
        { signal },
        displayUnenriched
    ) {
        if (signal.aborted) {
            return this.abortPromise();
        }

        // We have special treatment in the case the widget isn't recognized / cached by the backend
        // So we need to catch the 'Unsupported widget' error message and handle specifically
        return new Promise((resolve, reject) => {
            api.getJson(URL_WIDGET_DATA, {
                method: 'GET',
                credentials: 'include',
                params: {
                    dashboard_id: dashboardId,
                    widget_id: widgetId,
                    start_date: dashboardStartDate,
                    end_date: dashboardEndDate,
                    extra_params: {
                        query_type: reportType,
                        global_filters: globalFilters.length ? globalFilters : null,
                        display_unenriched: displayUnenriched,
                    },
                },
                cache: false,
                disableBrowserCache: true,
                unique: false,
            })
                .then(response => {
                    if (response.status !== 0) {
                        if (response.substatus.includes('Unsupported widget')) {
                            resolve({ cacheFailure: true });
                        } else {
                            reject(WIDGET_DATA_TYPES[DataTypes.REPORTS].ERROR_RUN_QUERY);
                        }
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    getWidgetVersions(dashboardId, globalFilters, startDate, endDate) {
        return api.getJson(URL_WIDGET_VERSIONS, {
            method: 'GET',
            credentials: 'include',
            params: {
                dashboard_id: dashboardId,
                start_date: startDate,
                end_date: endDate,
                extra_params: { global_filters: globalFilters.length ? globalFilters : null },
            },
            cache: false,
            disableBrowserCache: true,
            unique: false,
        });
    }
}
