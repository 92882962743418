import { capitalize } from 'utils/general';
import { SWIFT } from '../../consts';

const getCodeSnippet = () => {
    return `// Example 1: Standard event, no attributes
Singular.event(EVENT_SNG_LOGIN)

// Example 2: Standard event, with standard attributes
var dic: [AnyHashable : Any] = [:]
dic[ATTRIBUTE_SNG_ATTR_CONTENT_TYPE] = "PrivacyController"
dic[ATTRIBUTE_SNG_ATTR_CONTENT_ID] = "0"
dic[ATTRIBUTE_SNG_ATTR_CONTENT] = "GDPR and CCPA Opt-Out Options"
Singular.event(EVENT_SNG_CONTENT_VIEW, withArgs: dic)

// Example 3: Custom event, without attributes
Singular.event("signup")

// Example 4: Custom event, with a custom attribute
var bonusdata: [AnyHashable: Any] = [ "level": 10, "points": 500 ]
Singular.event("Bonus Points Earned", withArgs: bonusdata)`;
};

const getIosSwiftEventsSnippet = () => {
    return {
        markedCode: getCodeSnippet(),
        codeLanguage: SWIFT,
        placeholders: {},
        customTitle: capitalize(SWIFT),
    };
};

export default getIosSwiftEventsSnippet;
