import moment from 'moment';
import { isSkan4Supported, isSkanSupported, resolveModelTypeDisplayName, SKAN_PLUS_STATUSES } from './utils';
import { STATUS_INFO, STATUS_NONE, STATUS_OK, STATUS_WARNING } from '../linkManagement/utils';
import { getOptimizedModelsData } from './components/skan4/optimizedModelsUtils';
import { OS } from '../utils/OS';
import { getAppIcon } from '../utils/apps';

export const APP_FIELD_OFF = 'Off';
const HOURS_FACTOR = 60 * 60 * 1000;
const SECONDS_FACTOR = 1000;

function configuredModelTypeToDisplayName(modelType, areVisibleModels) {
    if (!modelType || typeof modelType === 'string') {
        return areVisibleModels ? APP_FIELD_OFF : modelType;
    }

    return modelType.map(type => resolveModelTypeDisplayName(type)).join(', ');
}

export function getMigrateUntilHelper(migrateUntil) {
    const defaultVal = {
        migrateUntilTime: null,
        migrateUntilFirst24HoursTime: null,
        last24hours: false,
    };

    if (!migrateUntil) {
        return defaultVal;
    }

    const currentTime = moment();
    const migrateUntilTime = moment(migrateUntil * SECONDS_FACTOR);

    if (migrateUntilTime.diff(currentTime) <= 0) {
        return defaultVal;
    }

    const migrateUntilTimeStr = migrateUntilTime.format('lll');

    const timeUntilMigration = migrateUntilTime.diff(currentTime);
    const last24Hours = timeUntilMigration <= 24 * HOURS_FACTOR;
    const first24Hours = !last24Hours && timeUntilMigration <= 48 * HOURS_FACTOR;

    // substract mutates the moment object, so need to do it afterwards.
    const migrateUntilFirst24HoursTimeStr = migrateUntilTime.subtract(24, 'hours').format('lll');

    return {
        migrateUntilTime: migrateUntilTimeStr,
        migrateUntilFirst24HoursTime: migrateUntilFirst24HoursTimeStr,
        last24Hours,
        first24Hours,
    };
}

function getAppStatus(app, appConfig) {
    const ret = {
        status: STATUS_NONE,
        notification: null,
    };

    const defaultNotification = {
        message: '',
        type: STATUS_OK,
        duration: 300,
        textReplace: {},
    };

    const { migrateUntilTime, last24Hours, first24Hours, migrateUntilFirst24HoursTime } = getMigrateUntilHelper(
        appConfig.migrateUntil
    );

    const isEnabledModel =
        appConfig.configuredTypes?.some(conf => conf.enabled) || appConfig.configuredModelType?.length;

    if (!isSkanSupported(app)) {
        return {
            ...ret,
            status: isEnabledModel ? STATUS_OK : STATUS_NONE,
            notification: {
                ...defaultNotification,
                message: 'STATIC.PAGES.SKADNETWORK.SDK_NOT_SUPPORTED_WARNING',
                type: STATUS_WARNING,
            },
        };
    }

    if (isEnabledModel && first24Hours) {
        return {
            ...ret,
            status: STATUS_OK,
            notification: {
                ...defaultNotification,
                message: appConfig.loading ? '' : 'STATIC.PAGES.SKADNETWORK.MIGRATION_FIRST_24H_WARNING',
                type: STATUS_INFO,
                textReplace: {
                    migrateUntilFirst24HoursTime,
                },
            },
        };
    }

    if (isEnabledModel && last24Hours) {
        return {
            ...ret,
            status: STATUS_OK,
            notification: {
                ...defaultNotification,
                message: appConfig.loading ? '' : 'STATIC.PAGES.SKADNETWORK.MIGRATION_WARNING',
                type: STATUS_WARNING,
                textReplace: {
                    migrateUntil: migrateUntilTime,
                },
            },
        };
    }

    if (!isSkan4Supported(app)) {
        ret.notification = {
            ...defaultNotification,
            message: appConfig.loading ? '' : 'STATIC.PAGES.SKADNETWORK.SDK_NOT_SUPPORTED_SKAN4_WARNING',
            type: STATUS_WARNING,
        };
    }

    if (appConfig && isEnabledModel) {
        ret.status = STATUS_OK;
    }

    return ret;
}

export const getAppItemData = (app, config) => {
    const { configuredModelType, areVisibleModels, lastModified } = config;
    // Get Summary endpoint response
    const modelType = configuredModelTypeToDisplayName(configuredModelType, areVisibleModels);
    const { status, notification } = getAppStatus(app, config);
    const lastUpdate = lastModified
        ? moment
              .utc(lastModified)
              .local()
              .format('lll')
        : null;
    return {
        modelType,
        status,
        notification,
        lastUpdate,
    };
};

export const getApp = (app, appConfig) => {
    const appId = app.app_id;

    let skanPlusData = null;

    if (appConfig) {
        const { enabledModelData, optimizedModels = [], measurementPeriod } = appConfig;

        if (enabledModelData) {
            const {
                model_type: modelType,
                revenue_type: revenueType,
                revenue_buckets: revenueBuckets,
            } = enabledModelData;

            // eslint-disable-next-line prefer-destructuring
            skanPlusData = getOptimizedModelsData(
                optimizedModels,
                modelType,
                revenueType,
                revenueBuckets,
                measurementPeriod
            );
        }
    }

    const appData = getAppItemData(app, appConfig || {});

    return {
        id: appId,
        name: app.name,
        external_uuid: app.external_uuid,
        icon: getAppIcon(app),
        sites: app.sites,
        skanPlusStatus: skanPlusData?.status || SKAN_PLUS_STATUSES.NO_OPTIMIZATION_MODEL,
        ...appData,
    };
};

export const getSkanApps = (allApps, configurationsPerApp, appsFilter) => {
    let relevantApps = allApps.filter(app => {
        if (!app || !app.sites) {
            return false;
        }
        const iosSites = app.sites.filter(({ platform }) => platform.toLowerCase() === OS.IOS.toLowerCase());

        if (!iosSites.length) {
            return false;
        }

        if (!appsFilter) {
            return true;
        }

        const sitesString = iosSites.map(({ site_public_id: sitePublicId }) => sitePublicId).join('::');
        const searchString = `${app.name}::${sitesString}`;

        return searchString.toLowerCase().includes(appsFilter.toLowerCase());
    });

    relevantApps = relevantApps.map(app => getApp(app, configurationsPerApp[app.app_id]));

    return relevantApps;
};
