import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { getTranslate } from '../selectors/locale';
import BannersAPI from './service';
import Logger from '../services/logger';
import AppsAPI from '../services/apps';
import {
    load,
    selectApp,
    updateBannersForAppSite,
    openShelfEditMode,
    pageDestroy,
    closeShelf,
    selectAppFirst,
    APP_SELECTED,
    EDIT_BANNER_CLICKED,
    CLOSE_SHELF_CLICKED,
    SAVE_SHELF_CLICKED,
    BANNER_PRIORITY_CHANGED,
    SAVING_CONFIGURATION,
    SAVING_CONFIGURATION_FAILED,
    ARCHIVE_BANNER_CLICKED,
    APP_SITE_CHANGED,
} from './actions';

import { updateAttributionAppsData } from '../actions/apps';
import { getBanners, getSelectedAppSiteId } from './selectors';
import ResourcesAPI from '../services/resources';
import { convertToServerBanner } from './utils';
import { MAX_PRIORITY } from './consts';
import { BANNERS_EVENT_PREFIX, trackMixpanelEvent } from '../utils/general';

const logger = new Logger('[banners:saga]');

const api = new BannersAPI();
const appsApi = new AppsAPI();
const resourcesApi = new ResourcesAPI();

function* onload() {
    try {
        yield put(load(true));

        const [appsResponse, countries] = yield all([
            call(appsApi.getAttributionApps, false, true),
            call(resourcesApi.getCountries),
        ]);

        yield put(load(false, countries));

        if (appsResponse && appsResponse.apps && appsResponse.apps.length) {
            yield put(updateAttributionAppsData(appsResponse));
        }
    } catch (e) {
        logger.debug('Error while loading the page', e);
    }
}

function* handleAppSelected({ appId }) {
    yield put(selectAppFirst(appId));
}

function* handleAppSiteSelected({ appSiteId }) {
    try {
        const { banners } = yield call(api.getBannersForApp, appSiteId);
        yield put(updateBannersForAppSite(appSiteId, banners));
    } catch (e) {
        logger.debug(`Error while fetching banners for: ${appSiteId}`, e);
        yield put(updateBannersForAppSite(appSiteId, []));
    }
}

function* handleEditBannerClicked(action) {
    const { id } = action;
    const configuredBanners = yield select(getBanners);
    const configuredBanner = configuredBanners.find(configured => configured.id === id);

    yield put(openShelfEditMode(configuredBanner));
}

function* handleCloseShelfClicked(action) {
    const { isFormDirty } = action;

    if (isFormDirty) {
        const translate = yield select(getTranslate);

        if (!confirm(translate('STATIC.PAGES.BANNERS.UNSAVED_CHANGES_WARNING'))) {
            return;
        }
    }

    yield put(closeShelf());
}

function* saveBanner(configuredBanner, isShelf = false) {
    yield put({ type: SAVING_CONFIGURATION, savingConfiguration: true });

    const appSiteId = yield select(getSelectedAppSiteId);
    const savedBanner = convertToServerBanner(configuredBanner);
    const configuredBanners = yield select(getBanners);
    const isNewBanner = !savedBanner.id;

    // Set priority to be last when creating a new banner
    if (isNewBanner) {
        const maxPriorityConfigured = Math.max(...configuredBanners.map(banner => banner.priority), 0) + 1;

        savedBanner.priority = Math.min(MAX_PRIORITY, maxPriorityConfigured);
    }

    try {
        yield call(api.saveBanner, appSiteId, savedBanner);
    } catch (error) {
        logger.debug(`Error while saving banner configuration`, error);
        yield put({ type: SAVING_CONFIGURATION_FAILED, error });
        return;
    }

    const { banners } = yield call(api.getBannersForApp, appSiteId);
    yield put(updateBannersForAppSite(appSiteId, banners));

    yield put({ type: SAVING_CONFIGURATION, savingConfiguration: false, savedNewBanner: !savedBanner.id });

    if (isShelf) {
        yield put(closeShelf());
    }

    if (isNewBanner) {
        trackMixpanelEvent(BANNERS_EVENT_PREFIX, 'Create Banner');
    } else {
        const previousBanner = configuredBanners.find(banner => banner.id === savedBanner.id);
        const priorityChanged = previousBanner.priority !== savedBanner.priority;
        trackMixpanelEvent(BANNERS_EVENT_PREFIX, priorityChanged ? 'Banner Priority Changed' : 'Update Banner');
    }
}

function* handleShelfSaveClicked({ configuredBanner }) {
    yield call(saveBanner, configuredBanner, true);
}

function* handleBannerPriorityChanged({ id, priority }) {
    const configuredBanners = yield select(getBanners);
    const configuredBanner = configuredBanners.find(configured => configured.id === id);

    yield call(saveBanner, { ...configuredBanner, priority });
}

function* handleArchiveBannerClicked({ id: bannerId }) {
    try {
        yield call(api.deleteBanner, bannerId);
    } catch (error) {
        logger.debug(`Error while deleting the banner`, error);
        return;
    }

    const appSiteId = yield select(getSelectedAppSiteId);
    const { banners } = yield call(api.getBannersForApp, appSiteId);
    yield put(updateBannersForAppSite(appSiteId, banners));

    trackMixpanelEvent(BANNERS_EVENT_PREFIX, 'Archive Banner');
}

function* destroy() {
    yield put(pageDestroy());
}

/* ---------------------------- */
/* ------- Watchers ----------- */
/* ---------------------------- */

function* watchAppSelected() {
    yield takeEvery(APP_SELECTED, handleAppSelected);
}

function* watchAppSiteSelected() {
    yield takeEvery(APP_SITE_CHANGED, handleAppSiteSelected);
}

function* watchEditBannerClicked() {
    yield takeEvery(EDIT_BANNER_CLICKED, handleEditBannerClicked);
}

function* watchShelfCloseClicked() {
    yield takeEvery(CLOSE_SHELF_CLICKED, handleCloseShelfClicked);
}

function* watchShelfSaveClicked() {
    yield takeEvery(SAVE_SHELF_CLICKED, handleShelfSaveClicked);
}

function* watchBannerPriorityChanged() {
    yield takeEvery(BANNER_PRIORITY_CHANGED, handleBannerPriorityChanged);
}

function* watchArchiveBannerClicked() {
    yield takeEvery(ARCHIVE_BANNER_CLICKED, handleArchiveBannerClicked);
}

const watchers = [
    watchAppSelected,
    watchAppSiteSelected,
    watchEditBannerClicked,
    watchShelfCloseClicked,
    watchShelfSaveClicked,
    watchBannerPriorityChanged,
    watchArchiveBannerClicked,
];

export { onload, destroy, watchers };
