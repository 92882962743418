import { connect } from 'react-redux';
import FieldsAvailability from '../components/fieldsAvailability';
import { fetchAllFields } from '../../actions/fields';
import { getVisibleFields } from '../selectors';

const mapStateToProps = state => {
    return {
        fields: getVisibleFields(state),
    };
};

const mapDispatchToProps = dispatch => ({
    fetchAllFields: () => dispatch(fetchAllFields()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldsAvailability);
