import React from 'react';

export default function SdkTestRevenueEvents() {
    return (
        <div>
            <p>
                <span>Once you are receiving basic events, you can start testing your revenue events.</span>
            </p>
            <p>
                <span>Use your test device to trigger an event associated with a revenue sum, such as a purchase. Check for the following:</span>
            </p>
            <ul>
                <li>
                    <strong>Event names: </strong><span>Make sure the events are named appropriately. If you are using multiple revenue types, you should see your customized name. Otherwise, the default is __IAP__.</span>
                </li>
                <li>
                    <strong>Parameters: </strong><span>Check that the currency amount and currency type are correct.</span>
                </li>
            </ul>
            <p>
                <strong>Receipt validation: </strong><span>&nbsp;We provide receipt validation. Once you are done with the integration validate that you are seeing the same amount of revenue reported by the playstore (keep in mind you will not be able to do this in the testing phase). </span>
            </p>
        </div>
    );
}
