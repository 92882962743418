import React from 'react';
import { Translate } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import PropTypes from 'prop-types';
import { partnerShape } from '../../types';
import css from '../PartnerConfigurationShelf.css';
import ldsCss from './LDS.css';
import { DropdownField } from '../../../../components/widgets';

export const LDSDefaultValueOptions = {
    false: 'LDS_FALSE',
    true: 'LDS_TRUE',
};

const anonymizedPostbacksValueToDropdown = (restrictLds, anonymizePostbacks) =>
    !restrictLds ? 'NONE' : anonymizePostbacks ? 'ANONYMIZED_POSTBACKS_TRUE' : 'ANONYMIZED_POSTBACKS_FALSE';

function LDS({ partner, isReadonly, anonymizedPostbacksOptions }) {
    const { values, setFieldValue } = useFormikContext();

    const selectedLdsDefault = LDSDefaultValueOptions[values.ldsDefault];
    const selectedAnonymizePostbacks = anonymizedPostbacksValueToDropdown(
        values.restrictLds,
        values.anonymizePostbacks
    );

    const ldsOptions = Object.values(LDSDefaultValueOptions).map(field => ({
        name: field,
        display_name: `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.${field}`,
    }));

    const restrictionOptions = Object.values(anonymizedPostbacksOptions).map(field => ({
        name: field,
        display_name: `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.${field}`,
    }));

    const onLdsDefaultOptionChanged = selectedOption => {
        setFieldValue('ldsDefault', selectedOption === LDSDefaultValueOptions.true);
    };

    const onAnonymizedPostbacksOptionChanged = selectedOption => {
        if (selectedOption === anonymizedPostbacksOptions?.ANONYMIZED_POSTBACKS_TRUE) {
            setFieldValue('restrictLds', true);
            setFieldValue('anonymizePostbacks', true);
        } else if (selectedOption === anonymizedPostbacksOptions?.ANONYMIZED_POSTBACKS_FALSE) {
            setFieldValue('restrictLds', true);
            setFieldValue('anonymizePostbacks', false);
        } else {
            setFieldValue('restrictLds', false);
            setFieldValue('anonymizePostbacks', false);
        }
    };

    return (
        <div className={css.section}>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.PRIVACY_TITLE" />
            </div>
            <div className={css.subtitle}>
                <Translate
                    id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.PRIVACY_SUBTITLE"
                    data={{ partnerName: partner.displayName }}
                />
            </div>
            <DropdownField
                items={restrictionOptions}
                label="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.RESTRICTION_TYPE"
                labelTooltip="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.RESTRICTION_TYPE_TOOLTIP"
                selected={selectedAnonymizePostbacks || anonymizedPostbacksOptions.NONE}
                onChange={onAnonymizedPostbacksOptionChanged}
                containerClass={ldsCss.inputContainer}
                disabled={isReadonly}
            />
            <DropdownField
                items={ldsOptions}
                label="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.LDS_DEFAULT"
                labelTooltip="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.LDS_DEFAULT_TOOLTIP"
                selected={selectedLdsDefault || LDSDefaultValueOptions.false}
                onChange={onLdsDefaultOptionChanged}
                containerClass={ldsCss.inputContainer}
                disabled={isReadonly}
            />
        </div>
    );
}

LDS.propTypes = {
    partner: partnerShape.isRequired,
    isReadonly: PropTypes.bool,
    anonymizedPostbacksOptions: PropTypes.object.isRequired,
};

LDS.defaultProps = {
    isReadonly: false,
};

export default LDS;
