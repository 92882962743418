window.languages = window.languages || {};

var ja = {
    "API": "API",
    "ARPU JPY": "ARPU (円)",
    "Audiences": "オーディエンス",
    "Account ID": "アカウントID",
    "Account Name": "アカウント名",
    "Ad Monetization": "広告収益化レポート",
    "Ad Network Report": "ネットワークレポート",
    "Affiliate ID": "アフィリエイトID",
    "Affiliate Name": "アフィリエイト名",
    "Agency": "代理店",
    "Alerts": "通知",
    "All Time": "全期間",
    "Analytics": "アナリティクス",
    "App": "アプリ",
    "App + OS Report": "アプリ + OS レポート",
    "Apps": "アプリ",
    "Attribution": "アトリビューション",
    "Automation": "自動化",
    "Benchmarks": "ベンチマーク",
    "Bid Amount": "入札金額",
    "Bid Strategy": "入札戦略",
    "Bid Type": "入札タイプ",
    "CPI Configuration": "CPI 設定",
    "Campaign": "キャンペーン",
    "Campaign ID": "キャンペーンID",
    "Campaign Name": "キャンペーン名",
    "Campaign Report": "キャンペーンレポート",
    "Campaign URL": "キャンペーンURL",
    "Click Type": "クリックタイプ",
    "Clicks": "クリック",
    "Clicks Discrepancy": "クリック乖離",
    "Conversion Type": "コンバージョンタイプ",
    "Cost": "コスト",
    "Cost Forecast": "コスト予測",
    "Cost Forecast (%)": "コスト予測（%）",
    "Cost Goal": "予算目標",
    "Cost per Event (CPE)": "イベント単価（CPE）",
    "Country": "国",
    "Create Link": "共有リンク生成",
    "Creative": "クリエイティブ",
    "Creative Tags": "クリエイティブタグ",
    "Creatives": "クリエイティブレポート",
    "Custom Dimensions": "カスタムディメンション",
    "Custom Events": "カスタムイベント",
    "Custom Rules": "カスタムルール",
    "Dashboard": "ダッシュボード",
    "Data Connectors": "データ接続",
    "Data Sources": "データソース",
    "Data source is disabled": "データソースをご利用できません",
    "Day": "日",
    "Deactivated": "無効",
    "Deactivated Members": "無効のメンバー",
    "Distributions": "配信",
    "Est": {
        " Conversions": "推定コンバージョン数"
    },
    "Event Conversion Rate (ECVR)": "イベントコンバージョン率（ECVR）",
    "Executive Summary or Weekly UA Report": "エグゼクティブサマリー又は週間UAレポート",
    "Export Logs": "ログ抽出",
    "Fraud Prevention": "フラウド防止",
    "Full Members": "全権限メンバー",
    "Global Settings": "グローバル設定",
    "Goal Complete (%)": "目標達成（%）",
    "Goals": "目標",
    "Gross Cost": "コスト（グロース）",
    "Impressions": "インプレッション",
    "Impressions Discrepancy": "インプレッション乖離",
    "Installs": "インストール",
    "Installs Discrepancy": "インストール乖離",
    "Keyword": "キーワード",
    "Keyword ID": "キーワードID",
    "Login successful": "ログイン成功",
    "Manage Links": "リンク管理",
    "Manage Team": "チーム管理",
    "Member": "メンバー",
    "Month": "月",
    "Net Cost": "コスト（ネット）",
    "Network Campaign ID": "ネットワークキャンペーンID",
    "Network Campaign Name": "ネットワークキャンペーン名",
    "Network Clicks": "ネットワーククリック",
    "Network Impressions": "ネットワークインプレッション",
    "Network Installs": "ネットワークインストール",
    "Network Report": "ネットワークレポート",
    "Network Sub Campaign ID": "ネットワークサブキャンペーンID",
    "Network Sub Campaign Name": "ネットワークサブキャンペーン名",
    "OS Report": "OSレポート",
    "Original Cost": "コスト原価",
    "Original Currency": "元通貨",
    "Original Revenue": "売上（元通貨）",
    "Partner Configuration": "パートナー設定",
    "Partner Fees": "手数料",
    "Pending Invitations": "招待待ち中",
    "Pending integration": "連携待ち",
    "Pivot": "ピボットレポート",
    "Platform": "プラットフォーム",
    "Please add Account IDs": "アカウントIDを入力してください",
    "Please add MCC Customer ID": "MCC Customer IDを入力してください",
    "Please verify authorization": "認証をご確認ください",
    "Please verify authorization & the MCC Customer ID": "MCC Customer ID及び認証をご確認ください",
    "Please verify login credentials": "ログイン情報をご確認ください",
    "Protected Report": "フラウド保護レポート",
    "Publisher ID": "パブリッシャーID",
    "ROI JPY": "ROI (円)",
    "ROI JPY (Gross)": "ROI (円 - グロス)",
    "Re-Engagements": "リーエンゲージメント",
    "Rejected Installs": "リジェクトインストール",
    "Rejected Report": "フラウドリジェクトレポート",
    "Reports": "レポート",
    "Retargeting": "リターゲティング",
    "Retention": "リテンション",
    "Retention CPA": "リテンション CPA",
    "Retention Rate": "リテンション率",
    "Revenue": "売上",
    "Revenue JPY": "売上 (円)",
    "Revenue LTV": "売上 LTV",
    "STATIC": {
        "ADMIN_OPTIONS": "アドミンオプション",
        "APP": "アプリ",
        "BUTTONS": {
            "ADD": "追加",
            "ADD_CELL_ABOVE": "上に追加する",
            "ADD_CELL_BELOW": "下に追加する",
            "ADD_NEW_DIMENSIONS": "新規追加",
            "ADD_TO_LIST": "リストに追加",
            "APPLY": "実行",
            "BACK": "戻る",
            "BOOKMARK": "ブックマーク",
            "CANCEL": "キャンセル",
            "COMPARE_TWO_PERIODS": "２つの期間比較",
            "CONTINUE": "続ける",
            "COPIED": "コピーされました",
            "COPY": "コピー",
            "CREATE_GOAL": "目標設定",
            "TEAM_USERS_CREATE_NEW_MEMBER": "新規ユーザーを生成",
            "CUSTOM": "カスタム",
            "DATEPICKER": {
                "CUSTOM": "カスタム",
                "LAST_30_DAYS": "30日間",
                "LAST_7_DAYS": "7日間",
                "LAST_MONTH": "先月",
                "PREVIOUS_PERIOD": "以前期間",
                "SAME_PERIOD_LAST_YEAR": "前年同期間",
                "THIS_MONTH": "今月",
                "THIS_QUARTER": "今四半期",
                "TODAY": "今日",
                "YESTERDAY": "昨日"
            },
            "DEACTIVATE_TWO_FACTOR_AUTH": "２段階認証を無効にする",
            "DELETE": "削除",
            "DELETE_CELL": "セルを削除",
            "DISABLE": "無効",
            "DISPLAY_MODE": "ディスプレイモード",
            "DONE": "完了",
            "EDIT": "編集",
            "EDIT_MODE": "編集モード",
            "EDIT_REPORT": "レポート編集",
            "EDIT_TAGS": "タグ編集",
            "ENABLE": "有効",
            "EXACT_RESIZE": "サイズを変更",
            "EXPORT": "抽出",
            "EXPORT_CSV": "CSV抽出",
            "EXPORT_JSON": "JSON抽出",
            "EXPORT_RAW_CSV": "RAW CSV抽出",
            "GENERATE": "生成",
            "GOAL_REPORT_TAB": "目標設定",
            "HIDE_CHART": "チャート非表示",
            "HIDE_LEGEND": "凡例非表示",
            "IMAGE": "イメージ",
            "IMAGE_AND_TEXT": "イメージとテキスト",
            "LEGEND_TOGGLE": "凡例",
            "NETWORK": "ネットワーク",
            "NEW_ALERT": "新しい通知",
            "NEW_CUSTOM_DIMENSION": "新しいカスタムディメンション",
            "NEW_CUSTOM_EVENT": "新しいカスタムイベント",
            "NEW_CUSTOM_METRIC": "新しいカスタム指標",
            "NEW_GOAL": "新しい目標",
            "NEXT": "次",
            "PRESS_GENERATE": "生成",
            "REGULAR_REPORT_TAB": "通常",
            "REMOVE": "除去",
            "REPORT_ON_ONE_PERIOD": "期間",
            "RESIZE_MODE": "サイズ変更モード",
            "RUN": "実行",
            "RUN_CELL": "セル実行",
            "RUN_EVERYTHING": "全部実行",
            "SAVE": "保存",
            "SAVE_AND_ADD_ANOTHER": "保存 & 新規追加",
            "SETUP_TWO_FACTOR_AUTH": "2段階認証を設定",
            "SHARE": "共有",
            "SHOW_CHART": "チャート表示",
            "SHOW_LEGEND": "凡例表示",
            "SUBMIT": "提出",
            "TEXT": "テキスト",
            "TRACKER": "トラッカー",
            "UNENRICHED": "標準化エラー",
            "UPDATE": "更新"
        },
        "CAMPAIGN": "キャンペーン",
        "CLUSTERING": "クラスタリング",
        "COHORT_METRICS": "コーホート指標",
        "COHORT_PERIOD": "コーホート期間",
        "COHORT_PERIODS": "コーホート期間",
        "CONTENT": "内容",
        "CUSTOM_DIMENSIONS": "カスタムディメンション",
        "CUSTOM_EVENTS": "カスタムイベント",
        "CUSTOM_METRICS": "カスタム指標",
        "DATE": "日付",
        "DESTINATION": "目的地",
        "DIMENSIONS": "ディメンション",
        "FILTERS": "フィルター",
        "FORMATS": {
            "REPORTS_CHART_DAY_PERIOD": "XX日",
            "REPORTS_CHART_MONTH_PERIOD": "XXXX年YYYY年"
        },
        "GENERAL_ERROR_DIALOG_TEXT": "エラーが発生しました。設定を確認してください。",
        "GENERAL_ERROR_DIALOG_TITLE": "エラーが発生しました",
        "GOAL_FORECAST": "目標予測",
        "GOAL_METRICS": "目標指標",
        "GRANULARITY_LEVELS": "データ粒度",
        "GROUP_BY": "グループ",
        "LINK_EXPIRATION_IN_DAYS": "リンク有効期間（日）",
        "LINK_URL": "リンクURL",
        "MANAGE_TEAM": "チーム管理",
        "METADATA_DIMENSIONS": "キャンペーンプロパティ",
        "METRICS": "指標",
        "DAU_METRICS": "User Activity Metrics",
        "MISSING_DASHBOARD_DATA": "選択した期間のデータはありません。もしくは、集計の対象データではありません。",
        "MODAL_HEADERS": {
            "ADD_SOURCE": "新しいソース",
            "CHANGE_PASSWORD": "パスワード変更",
            "EDIT_SOURCE": "編集",
            "SHARE_REPORT": "レポート共有",
            "WIDGET_CONFIG": "設定"
        },
        "GO_BACK_PANEL": {
            "GO_BACK_TO_ONBOARDING_GUIDE": "\nクリックしてホームページに戻る"
        },
        "PAGES": {
            "ALERTS": {
                "LEARN_MORE": "ご不明な点は <a href=\"https://singular.zendesk.com/hc/en-us/articles/207429293-How-to-Use-Alerts-Part-1-Overview-FAQs\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">ヘルプ</a> を参照してください。",
                "NEW_ALERT": "新しい通知",
                "NOTE": "注意：通知は、広告媒体が広告配信を開始したタイミングで起動します。",
                "NO_ALERTS": "通知が設定されておりません。",
                "P1": "通知は、配信中キャンペーンの急激なＣＰＩ上昇や、予算越えなど、特定の条件にマッチした時点で、担当者へ自動的に通知することができる機能です。"
            },
            "ANONYMOUS": {
                "ERROR_EXPIRED": "レポートの有効期限が切れました。",
                "ERROR_INVALID": "無効なレポート",
                "ERROR_INVALID_EMAIL": "パスワードが正しくありません。",
                "MODAL_LABEL": "パスワード",
                "MODAL_PLACEHOLDER": "パスワードを入力してください。",
                "MODAL_TITLE": "共有されたレポートにアクセス"
            },
            "SHARED_REPORTS": {
                "REPORT_NAME": "암호 설정 (선택사항):",
                "CHECKBOX_ALLOW_CHANGING_RANGE": "期間変更の許可",
                "COPIED": "コピー完了",
                "COPY_BUTTON": "リンクをコピー",
                "GENERATE_LINK_BUTTON": "リンクを生成",
                "INPUT_HEADER": "パスワードで保護 (任意) ",
                "LINK_EXPIRATION": "リンクの有効期限",
                "LINK_UNIT": "日間",
                "NOTE": "このURLを知っているユーザーであれば誰でも閲覧することができます。レポートに含まれるデータを制限し、責任を持って共有してください。"
            },
            "API": {
                "DEV_ACCOUNT": {
                    "DESCRIPTION": "Singularとの連携テスト用のデベロッパーアカウント",
                    "PASSWORD": "パスワード",
                    "TITLE": "貴社のデベロッパーアカウント",
                    "USERNAME": "ユーザー名:"
                },
                "REPORTING_API_KEY": {
                    "DESC1": "APIキーはSingularのデータをAPIを通じて取得する際に必要です。",
                    "DESC2": "注意：Singular SDKキーは、下記を参照ください。",
                    "DESC3": "詳細については <a href=\"http://docs.singular1.apiary.io/#\" target=\"_blank\">API 資料</a> を参照ください。",
                    "KEY": "キー:",
                    "TITLE": "レポーティング用API キー"
                },
                "SDK_API_KEY": {
                    "DESC1": "Singularアトリビューションを利用するには、SDK Keyが必要です。",
                    "DESC2": "SDKに関するご質問やご不明な点は お問い合わせ ください。",
                    "FOR_MORE_INFORMATION": "詳しい情報は、こちらまでお問合せください。",
                    "NOTE": "注意）Singular Reporting API Keyとは異なるKeyです。お間違えのないようお願いします。",
                    "TITLE": "SDK API キー"
                }
            },
            "APPS_CONFIGURATION": {
                "ADD_SITE": "サイト追加",
                "ANDROID_APP_SCHEME": "アンドロイドアプリ用のスキーム",
                "ANDROID_INSTALL_VALIDATION_KEY": "プレイストアーのライセンスキー",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE": "レシートのないアプリ内課金をリジェクト",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_ENABLED": "より有効",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_WARNING": "この設定を有効にすると、これ以降にアプリをインストールまたは更新した全ユーザーからのレピートがない課金額がリジェクトされます。このまま進みますか？",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_WARNING_HEADER": "注意: レシートのないアプリ内課金をリジェクト",
                "ANDROID_SPECIFIC": "アンドロイドのディープリンク",
                "ATTRIBUTION_CONFIGURATION": "アトリビューション設定",
                "CERTIFICATE_KEY": "証明書ファイルのキー",
                "CREATE_OR_SEARCH": "アプリを登録もしくは、検索",
                "DEEP_LINK_TARGETS": "ディープリンクのURL",
                "DEFAULT_STORE_URL": "ストア URLを入力",
                "DESTINATIONS": "遷移先",
                "DESTINATION_URLS": "ストア URL: ",
                "DOMAIN_NAME_PREFIX": "ドメインの識別子:",
                "DRAG_OR_CLICK_TO_UPLOAD_P12": "アップロードしたい証明書ファイル (.p12 file) をクリックまたはドラッグしてください。",
                "DROP_TO_UPLOAD_P12": "アップロードしたい証明書ファイルをドロップしてください。",
                "FCM_SERVER_KEY": "FCM サーバー用キー",
                "FRAUD_OPTIONS": "フラウド対策のオプション",
                "KEYWORD": "キーワード",
                "LATEST_SDK_VERSION": "SDKの最新バージョン:",
                "MOVE_TO": "アプリを移動",
                "NOT_INTEGRATED": "連携されていません",
                "NO_APPS_CONFIGURED_DEVELOPMENT": "登録したテストアプリがまだありません。\"Add New App\"より登録をお願い致します。",
                "NO_APPS_CONFIGURED_PRODUCTION": "登録したライブアプリがまだありません。\"Add New App\"より登録をお願い致します。",
                "NO_DEEP_LINKS_CONFIGURED": "Deep Linkがまだ設定されておりません。クリックしURLを追加してください。",
                "NO_IOS_UNIVERSAL_LINKS_CONFIGURED": "iOS Universal Linkがまだ設定されておりません。クリックしURLを追加してください。",
                "PACKAGE_IDENTIFIER": "アプリのパッケージ名",
                "PLACEHOLDERS": {
                    "CHOOSE_PLATFORM": "プラットフォームの選択",
                    "OPTIONAL": "（オプション）"
                },
                "REATTRIBUTION_WINDOW": "リアトリビューション ウィンドウ:",
                "SDK_APP_ID": "SDK アプリID:",
                "SDK_INTEGRATED": "SDK連携済み",
                "SDK_NOT_INTEGRATED": "SDK未連携",
                "SDK_STATUS": "SDK連携状況:",
                "SITE_NICKNAME": "サイトニックネーム:",
                "STORE_ID": "ストアID:",
                "STORE_URL": "ストアURL：",
                "TEAM_ID": "チーム ID:",
                "UNINSTALL_TRACKING": "アンインストールトラッキング"
            },
            "ATTRIBUTION": {
                "CONFIRM_MESSAGE_SEND": "担当 Customer Success Managerへメッセージを送りますか？",
                "CONTACT_US": "お問い合わせ",
                "MESSAGE_SENT": "メッセージの送信が完了致しました。担当CSMよりご連絡をさせていただきます。 \n",
                "SEND_MESSAGE": "メッセージを送る"
            },
            "AUDIENCES": {
                "CONFIGURE_NETWORK": "ネットワーク設定",
                "CREATE_NEW": "新しいセグメントを生成",
                "DELETE_WARNING": "本当にこのセグメントを削除しても宜しいですか？",
                "EDIT_SEGMENT": "セグメント編集",
                "ENABLE_SEGMENT_DISTRIBUTION_TOGGLE_LABEL": "このセグメントを有効にする",
                "NEW_SEGMENT": "新規セグメント",
                "OFF": "オフ",
                "ON": "オン",
                "SEARCH_DISTRIBUTIONS": "配信先を検索",
                "SEARCH_SEGMENTS": "セグメントを検索",
                "SEGMENT_TEMPLATES": "セグメントテンプレート",
                "SHARING_OPTIONS": "このセグメントを共有",
                "SHARING_OPTIONS_COPY_TOOLTIP": "URLをコピー",
                "SHARING_OPTIONS_RENAME_TOOLTIP": "URL名を変更する",
                "SHARING_OPTIONS_REVOKE_TOOLTIP": "URLを無効にする",
                "SHARING_OPTIONS_SEGMENT_NAME": "セグメント名",
                "SHARING_OPTIONS_SHELF_CREATE": "リンクを生成",
                "SHARING_OPTIONS_SHELF_CREATE_EMPTY": "リンクを生成",
                "SHARING_OPTIONS_SHELF_EMPTY": "このセグメントのリンク生成ははじめになります。",
                "SHARING_OPTIONS_SHELF_TITLE": "Singularオーディエンスを連携されていないパートナーにこのセグメントを共有するには、セグメント用のパーマ (固定) リンクを作成することが出来ます。\nパートナーはこのリンクURLを開き、最新のセグメントファイルをダウンロード出来ます。リンクをを無効にすることも可能です。",
                "UNSAVED_MESSAGE": "セグメント編集を終了すると変更が反映されません。"
            },
            "BATCH_TRACKING_LINKS": {
                "PLACEHOLDER": {
                    "APP": "アプリを選択",
                    "PARTNER": "パートナーを検索"
                },
                "PLEASE_CONFIG_THIS_SOURCE": "まずパートナー登録を完了してください。",
                "SOURCE_CONFIG": "パートナー登録"
            },
            "CREATIVES": {
                "EDIT_TAGS_NO_CREATIVE_SELECTED": "タグを編集するクリエイティブを選択してください。"
            },
            "CREATIVE_TAGS": {
                "DEFINE_VALUES": "このグループのタグを定義してください。(選択)",
                "NEW_CREATIVE_TAG": "新規タググループ",
                "NO_CREATIVE_TAGS_DEFINED_IMPLORE": "まだ定義されたタググループがありません。クリックし追加してください。",
                "PLACEHOLDERS": {
                    "ADD_TAG_VALUES": "タグ名を入力してください。",
                    "ENTER_TAG": "タグを入力",
                    "TAG_NAME": "例: Theme"
                },
                "PREAMBLE": "クリエイティブデータをグループ化するカスタムタグを作成してください。<a href=\"https://singular.zendesk.com/hc/en-us/articles/115003236386-Introduction-to-Creative-Tagging\" class=\"link-underline\" target=\"_blank\" >Learn more about defining Creative Tags.</a>",
                "TAGS_GROUP_NAME": "タググループ名"
            },
            "CUSTOM_DIMENSIONS": {
                "ADD_VALUE": "新しく条件を追加する",
                "CREATED_BY": "作成者:",
                "DEFINE_VALUES": "カスタムディメンションも条件を設定してください。",
                "DELETE_HOVER_TEXT": "削除される項目は赤い破線で表示されます。",
                "DELETE_MESSAGE": "このカスタムディメンションをを完全に削除していいですか？ \n一度実行しますと変更が出来なくなり、アカウント全体に反映されます。",
                "DELETE_WARNING": "過去にマッピングしたデータが削除されます。<br/>本当に宜しいでしょうか?",
                "DIMENSION_NAME": "カスタムディメンション名:",
                "GOALS_AFFECTED_BY_CHANGE_WARNING": "こちらのディメンションの変更は現在利用中の目標に影響を与えます。それでも変更しますか？",
                "HELP_TOOLTIP": "値が設定されていない場合 “N/A”と表示されます。",
                "IF_NO_VALUE_MATCHED_SET_DEFAULT_TO": "上記で定義したの条件名以外は、下記の条件を適用する。",
                "IN_DATE": "作成日:",
                "LAST_EDIT_ON": "最終更新日:",
                "NO": "キャンセル",
                "NO_CUSTOM_DIMENSIONS_DEFINED_IMPLORE": "表示するカスタムディメンションはありません。",
                "PLACEHOLDERS": {
                    "DIMENSION_NAME": "例）パブリッシャー",
                    "FILTER_CONDITION": "条件",
                    "FILTER_DIMENSION": "ディメンションを選択",
                    "FILTER_VALUE": "値",
                    "VALUE": "条件名を入力もしくは、選択",
                    "VALUE_NAME": "例）株式会社Tokyogames"
                },
                "PREAMBLE": "<p>よりシンプルで包括的なパフォーマンス分析のためにデータを分類する</p> ご不明な点は <a href=\"https://singular.zendesk.com/hc/en-us/articles/208579346-Creating-Custom-Dimensions\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">ヘルプ</a> を参照してください。",
                "SAVE_ANYWAY": "保存",
                "USE_VALUE_WHEN": "条件入力：",
                "VALUE_NAME": "条件名:",
                "WARNING_MAXIMUM_REACHED": "最大{{max}}個までカスタムイベントは作成できます。",
                "YES": "確認する"
            },
            "CUSTOM_EVENTS": {
                "ADD_CALCULATED_METRIC_BASED_ON_EVENT": "このイベントのCPE又はECVRを\b指標に追加：",
                "APP_SELECTION_AUTOCOMPLETE_PLACEHOLDER": "カスタムイベントを設定するアプリを選択",
                "APP_SPECIFIC_CONT": "特定アプリのみ属するイベント",
                "APP_SPECIFIC_EVENTS": "特定アプリのみ属するイベント",
                "AUTO_GENERATED": "(自動生成)",
                "EMPTY_STATE_NO_APP_SELECTED": "アプリを選択してください。",
                "EMPTY_STATE_NO_CUSTOM_EVENTS": "カスタムイベントがまだ設定されていません。",
                "EMPTY_STATE_NO_CUSTOM_EVENTS_FOR_APP": "カスタムイベントがまだ設定されていません。",
                "EMPTY_STATE_NO_UAN_EVENTS": "イベントが確認できません。",
                "EVENTS_CONT": "イベント",
                "EVENT_AT_LEAST_ONE_EVENT_ERROR": "最低1つのイベントを追加してください。",
                "EVENT_AT_LEAST_ONE_EVENT_ERROR_REACT": "最低1つのイベントを追加してください。",
                "EVENT_DATA_NOTE": "該当イベントの1ヶ月前のデータから取得します。",
                "EVENT_NAME_ERROR": "イベント名を追加してください。",
                "GLOBAL_EVENTS": "共通イベント",
                "GLOBAL_EVENTS_CONT": "共通イベント",
                "HELP": "ご不明点は <a href=\"https://singular.zendesk.com/hc/en-us/articles/208385833\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">ヘルプ</a> を参照してください。",
                "HELP_TOOLTIP": "取得イベント情報には、新しくイベント名前がつきます。（例： CPRegister）",
                "LAST_MODIFIED": "最終更新日 {{date}} 作成者 {{editor}}",
                "LAST_MODIFIED_AUTOMATICALLY": "最終更新{{date}}、自動的に生成。",
                "LOCKED_UAN_EVENT_ALT": "一度マッピングされたイベントのデータ自体は削除できません。",
                "MAP_TO": "対象イベント：",
                "MAP_TO_REACT": "カスタムイベントは下記とマッピングされます。",
                "MAP_TO_REACT_UNIQUE": "カスタムイベントは下記とマッピングされます（ユニークイベントのみ表示）。",
                "MAX_EVENTS": "アカウント別に最大 {{maximumEventSlots}} カスタムイベントが設定可能です。",
                "NEAR_MAXIMUM_GLOBAL_WARNING": "最大イベント設定数の ${maxEvents} のうち、${maxAppEvents} をご利用中です。${maxEvents} に到達した場合、新たな共通カスタムイベントは生成できません。",
                "NO_EVENTS": "新しいイベントはありません。",
                "PLACEHOLDERS": {
                    "FILTER_VALUES": "値を入力"
                },
                "REACHED_MAXIMUM_GLOBAL_WARNING": "カスタムイベントの最大設定数${maxEvents} に到達したため、共通カスタムイベントの追加設定ができません。追加するには、個別のアプリから登録したイベントの数が${maxEvents}より少ないように設定してください。<br/> ご不明点はサポートまでご連絡ください。",
                "REACHED_MAXIMUM_WARNING": "カスタムイベントの最大設定数${maxEvents} に到達したため、カスタムイベントの追加設定ができません。",
                "SAVE_SERVER_ERROR": "処理途中にエラーが発生致しました。",
                "UNIQUE_TOGGLE_DISABLE_INFO": "このカスタムイベントは既に${eventType}のソースイベントと<br/>マッピングされております。",
                "UNIQUE_TOGGLE_INFO": "有効にする場合、累積ではなくユニークカウントのイベントのみマッピング可能です。",
                "UNIQUE_TOGGLE_LABEL": "ユニークカスタムイベント",
                "UNSAVED_CHANGES_WARNING": "まだ保存されておりません。本当に終了しても宜しいですか？"
            },
            "CUSTOM_METRICS": {
                "ADD_RULE": "ルールを追加",
                "BOTH_FIELDS_REQUIRED": "\nオペレーターと値の両方を指定するか、または無し",
                "CUSTOM_METRIC_CREATED": "保存済み、かつ利用可能な指数",
                "DEFINE_VALUES": "指数の値を定義してください。",
                "DISPLAY_FORMAT": "表示するフォーマット",
                "DISPLAY_FORMAT_REQUIRED": "表示するフォーマットの設定が必要です",
                "DO_THIS": "これを設定してください。",
                "FORMAT_JSON": "JSONフォーマット",
                "HELP_TOOLTIP": "デフォルト値がない場合、値は次のようになります。&quot;N/A&quot;.",
                "IF_NO_VALUE_MATCHED_SET_DEFAULT_TO": "一致する値がない場合、デフォルト値を次のように設定してます。",
                "INVALID_FORMAT_JSON": "無効なJSONフォーマット",
                "METRIC_CUSTOM_EVENT": "カスタムイベントとして表示",
                "METRIC_DEFAULT": "デフォルト",
                "METRIC_DISPLAY_NAME": "指標の表示名",
                "METRIC_NAME": "指標名",
                "METRIC_RESTRICTED": "制限付き",
                "METRIC_VISIBLE": "可視可能",
                "NO_CUSTOM_METRICS_DEFINED_IMPLORE": "カスタムイベントが定義されていません。追加するにはクリックしてください。",
                "PLACEHOLDERS": {
                    "FILTER_CONDITION": "条件"
                }
            },
            "DATA_SOURCES": {
                "ADD_NEW": "新規追加",
                "ADD_NEW_CONNECTOR": "データソース追加",
                "ADNETWORK_SELECTION_PLACEHOLDER": "追加するデータソース",
                "DELETE_UAN_ACTION": "${uan_name} が削除されました。",
                "EDIT_UAN_TOOLTIP": "編集",
                "EXPORT_RAW_DATA": "CSVのローデータを抽出",
                "GROUP_TITLES": {
                    "Action Required": "要確認",
                    "Live Data": "接続中",
                    "Pending Integration": "連携準備中",
                    "Unavailable": "未接続"
                },
                "HEADER_TEXT": "データソースは24時間以内に更新されます。一度ログイン情報が正しく入力されたら、3ヶ月前までのデータが取得され始めます。",
                "HELP_CENTER": "ヘルプセンター",
                "LAST_LOGIN": "最終ログイン:",
                "LAST_SUCCESSFUL_LOGIN": "最終ログイン:",
                "MSG_CASE_NO_SOURCES": "追加されてソースがありません。右上の新規追加ボタンから登録可能です。",
                "NEW_UAN_ACTION": "${uan_name} が追加されました。",
                "NO_DATA": "表示するデータがありません。",
                "NO_DATA_CONNECTORS": "データソースが設定されていません。",
                "NO_SEARCH_RESULTS": " ${searchText} に対する結果がありません。",
                "PLACEHOLDERS": {
                    "ADD_DATA_SOURCE": "データソースを入力してください。"
                },
                "REAUTHORIZE": "再認証",
                "RUN_TEST_REPORT_TOOLTIP": "テストレポートを実行",
                "SETTINGS": {
                    "ACCOUNTS_ACTIVATE": {
                        "AUTOMATICALLY": "新規のアカウントは自動的にデータを抽出する",
                        "TITLE": "データを抽出するアカウントを選択："
                    },
                    "GENERAL": {
                        "TITLE": "基本設定:"
                    },
                    "INTEGRATION_LINK_TEXT": "{{ adnName }} とSingularを繋ぐ方法について"
                },
                "SUBHEADER": "データソースの設定について、より詳しい情報は <a target='_blank' href=\"https://support.singular.net/hc/en-us/articles/360031000051-Data-Connectors-FAQ\">data connectors FAQ</a>をご参照ください。",
                "TOGGLE_ARCHIVED_UANS": "過去30日間データが存在しないソースも表示",
                "TYPE_CONNECTOR_NAME": "データソース検索",
                "TYPE_SOURCE_NAME": "データソースを検索",
                "UPDATE_UAN_ACTION": "${uan_name} が更新されました。",
                "UPDATE_UAN_AFTER_VERIFICATION_ACTION": "${uan_name} がログイン認証後に更新されました。",
                "WARNING": "ログイン情報をご確認ください"
            },
            "DELETE_MODAL": {
                "DELETE_NO": "いいえ",
                "DELETE_YES": "はい",
                "DELETE_YES_REACT": "はい",
                "TYPES": {
                    "ALERT": "通知",
                    "APP": "アプリ",
                    "CUSTOM_METRIC": "カスタム指標",
                    "GOAL": "目標",
                    "PARTNER_CONFIG": "パートナー設定",
                    "SITE": "アプリサイト",
                    "SITE_CONFIG": "アプリサイト設定",
                    "TAG_GROUP": "タググループ"
                },
                "WARNING_P1": "この{{deleteType}} を本当に削除しますか？",
                "WARNING_P2": "一度削除したデーターは完全に削除される為、再登録する必要があります。"
            },
            "EXPORT_LOGS": {
                "APP": "アプリ",
                "CONVERSION_TYPE": "コンバージョンタイプ",
                "CONVERSION_TYPES": {
                    "ALL": "全て",
                    "INSTALL": "インストール",
                    "REENGAGEMENT": "リーエンゲージメント"
                },
                "COUNTRIES": "国",
                "DATE": "期間",
                "DAYS_ERROR_MSG": "選択したデータ期間は {{limit}} の制限日より ({{diff}} 日) を超えています。",
                "DEVICE_ID": "デバイスID",
                "DEVICE_TYPE": "デバイスタイプ",
                "ERROR_MSG": "リクエストを確認してください",
                "EVENTS": "イベント",
                "EXPORT": "ログ抽出",
                "LEAVE_EMPTY_FOR_ALL": "全選択の場合は空欄",
                "LIMIT": "結果制限",
                "ORIGINAL_RECORD": "テキスト検索",
                "PARTNERS": "パートナー",
                "PLACEHOLDERS": {
                    "ADD_COUNTRIES": "国名(英文)を検索",
                    "ADD_DEVICE_TYPE": "デバイスタイプを検索",
                    "ADD_EVENTS": "イベントを検索",
                    "ADD_PARTNERS": "パートナーを検索"
                },
                "POSTBACK_TYPE": "ポストバックタイプを検索",
                "REALTIME_DATA_TOOLTIP": "このオプションを選択する場合、10分前まで格納されたデータの抽出が可能です。ただし、必ず、データの期限は\"当日のみ\"を選択してください。",
                "SITE": "サイト",
                "TIMEZONE": "タイムゾーン",
                "TYPE": "タイプ",
                "TYPES": {
                    "Clicks": "クリック",
                    "Events": "イベント",
                    "Fps": "不正",
                    "Installs": "インストール",
                    "Links": "リンク",
                    "Postbacks": "ポストバック"
                }
            },
            "GENERATE_SMART_LINK": {
                "CUSTOM_NAME": "カスタム名",
                "CUSTOM_SOURCE_NAME_NOTE": "<strong>注意</strong>: カスタムソース名は '(Custom)'が付きます。",
                "OTHER_PLATFORMS": "その他プラットフォーム",
                "PLACEHOLDER": {
                    "CUSTOM_NAME": "表示させたいカスタムソース名を入力してください",
                    "DESTINATION": "ランディング先を選択してください",
                    "SELECT_APP_FIRST": "アプリを選択してください"
                },
                "SUB_HEADER": "ユーザーのデバイスによってiTune, Google Play, 又はその他のストアに自動分岐するカスタムトラッキングリンクを生成。",
                "TIP": "連携媒体向けのトラッキングリンクは \"リンク生成\"にて行います。",
                "TIP2": "ランディング先の追加は、\"アプリ設定\"にて行います。"
            },
            "GENERATE_TRACKING_LINK": {
                "APP": "アプリ",
                "ATTRIBUTION_SETTINGS": "サイト",
                "CAMPAIGN_NAME": "トラッキングリンク名",
                "CAMPAIGN_NAME_TOOLTIP": "トラッキングリンク名の定義をする時には、なるべく詳細な情報をつけてください",
                "CLICK_THROUGH_LINK": "クリックスルーのトラッキングリンク",
                "CONFIG": "設定",
                "CREATIVE_NAME": "クリエイティブ名",
                "DEEPLINK_DESTINATION_URL": "ディープリンクランディングURL",
                "DESTINATION_URL": "ランディングURL",
                "DISABLED_REENGAGEMENT_TOGGLE": "リーエンゲージメントトラッキングの状況が設定され、変更出来ません。",
                "DISABLED_VIEWTHROUGH_TOGGLE": "ビュースルートラッキングの状況が設定され、変更出来ません。",
                "DISABLED_WEBREFERRER_TOGGLE": "リファラー設定の状況が設定され、変更出来ません。",
                "EDIT": "編集",
                "ENABLE_REENGAGEMENT": "リエンゲージメントの有効",
                "ENABLE_REENGAGEMENT_TOOLTIP": "トラッキングリンクをリエンゲージメント向けに利用する",
                "ENABLE_RETARGETING_TOOLTIP": "トラッキングリンクをリエンゲージメント向けに利用するには有効にしてください。",
                "ENABLE_VIEWTHROUGH": "ビュースルーの有効",
                "ENABLE_VIEWTHROUGH_TOOLTIP": "ビュースルトラッキングの有効",
                "ENABLE_WEBREFERRER_TOOLTIP": "Webの目的地（飛び先）にリファラーを付属させる",
                "GENERATE_LINK": "リンク生成",
                "GENERATING_SHORT_LINK_ERROR": "ショットリンク生成中にエラーが発生しました。",
                "GENERATING_TRACKING_LINK_ERROR": "トラッキングリンク生成際にエラーが発生しました。",
                "LINK_ALREADY_EXIST_WARNING": "該当するトラッキングリンクは既に存在します。下記に表示されるリンクを改めてご利用ください。",
                "MODAL": {
                    "ADN_EVENT_NAME": "{{adnname}} イベント名",
                    "ALLOW_NO_POSTBACKS_NETWORKS": "広告ネットワークによっては、すべてのインストール（該当ネットワークからのインストール以外も含む）に関するポストバックを送る必要があります。下記のチェックボックスをクリックし、許可してください",
                    "ALL_EVENT_POSTBACKS": "全てのイベントポストバック",
                    "APP_SPECIFIC_CONFIGURATION": "アプリの詳細設定",
                    "AUTO_GENERATED_NOTE": "\"*\"で表示されるイベントはSingular SDKより自動生成されるイベントです。",
                    "BASE_SOURCE_NOT_CONFIGURED": "<div>{{adnname}} は {{baseadnetname}} PMD/FMPです。</div><div>この連携を有効にする前に、 {{baseadnetname}} を設定する必要があります。</div>",
                    "CLICK_THROUGH_ATTRIBUTION_WINDOW": "クリックスルー アトリビューションの期間指定",
                    "CLICK_TO_ADD_NEW_EVENT": "<button type=\"button\" class=\"button-add btn-link text-hide\" title=\"Add\" ng-click=\"addEvent()\" ng-disabled=\"isInAppEventsSendingDisabled()\">「+」</button> をクリックし、新しいイベントを追加してください。",
                    "CLICK_TO_ADD_NEW_EVENT_POSTBACK": "<button type=\"button\" class=\"button-add btn-link text-hide\" title=\"Add\" ng-click=\"addEventPostback()\" ng-disabled=\"isInAppEventsSendingDisabled()\">+</button> をクリックし、新しいイベントポストバックを追加してください。",
                    "CLICK_TO_ADD_NEW_INSTALL_POSTBACK": "<button type=\"button\" class=\"button-add btn-link text-hide\" title=\"Add\" ng-click=\"addInstallPostback()\">+</button> をクリックし、新しいポストバックルールを追加してください。",
                    "CONFIGURE_BASE_SOURCE": "{{baseadnetname}} アカウント設定",
                    "CUSTOM_POSTBACKS_SUB_TITLE": "インストール又はリーエンゲージメント",
                    "CUSTOM_POSTBACKS_TITLE": "インストールとリーエンゲージメント",
                    "CUSTOM_POSTBACKS_TITLE_NO_RE": "インストールポストバック",
                    "ENABLE_FRAUD_POSTBACKS": "フラウドポストバックを有効にする。",
                    "ENABLE_INTEGRATION": "連携を有効にする。",
                    "ENABLE_REENGAGEMENT": "リーエンゲージメントポストバックを有効にする。",
                    "ENABLE_REENGAGEMENT_TRACKING": "リーエンゲージメントトラッキングを有効にする。",
                    "ENABLE_VIEWTHROUGH": "ビュースルーポストバックを有効にする。",
                    "ENABLE_VIEWTHROUGH_TRACKING": "ビュースルートラッキングを有効にする。",
                    "ENABLE_VIEW_THROUGH": "ビュースルーアトリビューションを有効にする。",
                    "EVENT_POSTBACKS": "イベントポストバック",
                    "GENERIC_INPUT_VALIDATION_ERROR": "無効な文字が含まれています。",
                    "GOT_IT": "畏まりました！",
                    "INCLUDE_VIEWTHROUGH_ATTRIBUTIONS": "ビュースルーアトリビューションを含む",
                    "INCLUDE_VIEWTHROUGH_ATTRIBUTIONS_TOOLTIP": "有効の場合、クリックスルーとビュースルーアトリビューションの両方がポストバックに含まれます。",
                    "INSTALL_POSTBACK_CONFIGURATION": "インストールポストバックの設定",
                    "MANDATORY_FIELD": "(必須) ご記入ください",
                    "PLEASE_ENTER_EVENT": "イベント名をしてください",
                    "PLEASE_FILL_THIS_FIELD": "この欄を記入をしてください",
                    "PLEASE_SELECT_APPLICATION": "有効なアプリを選択してください。",
                    "PLEASE_SELECT_EVENT": "イベントを選択する",
                    "PLEASE_SELECT_EVENT_NAME": "イベント名を選択してください",
                    "POSTBACK_URI_DIFFER_FROM_TEMPLATE": "ポストバックURLがテンプレートとは別の場合",
                    "PRIORITIZE_CLICK_FINGERPRINTS": "クリック時のフィンガープリントを優先する",
                    "SDK_EVENT_NAME": "アプリ内(SDK)イベント名",
                    "SEND_ALL_EVENTS_POSTBACKS_TO_ADN": "全てのインストール に対してイベントをポストバックする {{adnname}}",
                    "SEND_ALL_POSTBACKS_TO_ADN": "インストールに関するポストバックを{{adnname}} まで送信してください",
                    "SEND_ALL_POSTBACKS_TO_ADN_EVENTS": "全て送付",
                    "SEND_ALL_POSTBACKS_TO_ADN_EVENTS_TOOLTIP": "このイベントの発生する際に、 {{adnname}}へ全て(アトリビューションされている及びされていない) レポートする",
                    "SEND_ALL_POSTBACKS_TO_ADN_INSTALLS_TOOLTIP": " {{adnname}}へ全て(アトリビューションされている及びされていない) {{pbtype}} レポートする",
                    "SEND_ALL_POSTBACKS_TO_ADN_WITH_CONTEXT": "全て {{pbtype}} をポストバックする {{adnname}}",
                    "SEND_EVENT_DATA": "イベントデータを送る",
                    "SEND_IN_APP_EVENTS_TO": "アプリ内イベントを{{adnname}}に送る",
                    "SEND_POSTBACK_FOR_ALL_EVENTS": "全イベントをポストバックする",
                    "SEND_REVENUE": "値を送信してください",
                    "SEND_REVENUE_DISABLED_TOOLTIP": "ポストバックURLがカスタムの場合はご利用頂けません。",
                    "SEND_REVENUE_TOOLTIP": "レベニュー値と一緒に送られるイベントのみ可能です。",
                    "SITE": "トラッキングリンクを生成",
                    "SNAPCHAT_ENABLE_INTEGRATION_EXPLANATION": "次へ進むには、この利用規約 <a href=\"https://businesshelp.snapchat.com/en-US/article/attribution-terms\" target=\"_blank\">Snap Inc.'s Terms and Conditions</a>.に同意してください。",
                    "SNAPCHAT_TERMS_AND_CONDITIONS_HEADER": "SnapChatの利用規約",
                    "SOURCE_CONFIG_EDIT_POSTBACK_CAMPAIGN_REGEX": "トラッカーキャンペーン名フィルター",
                    "SOURCE_CONFIG_EDIT_POSTBACK_CAMPAIGN_REGEX_TOOLTIP": "この設定を有効にすると、このトラッカー名にアトリビューションされるインストール/リーエンゲージメントのみのポストバックが発生します。\n\n",
                    "SOURCE_CONFIG_EDIT_POSTBACK_POSTBACK_URI": "ポストバック URL",
                    "SOURCE_CONFIG_EDIT_POSTBACK_SEND_ATTRIBUTED_INSTALLS": "アトリビューション されたインストールのみを送付",
                    "SOURCE_CONFIG_EDIT_POSTBACK_VIEWTHROUGH_ENABLED": "ビュースルー設定を有効",
                    "SOURCE_DOESNT_SUPPORT_EVENT_POSTBACKS": "このデータソースは、イベントのポストバックをサポートしていません.",
                    "PARTNER_MANAGE_USERS": "{{adnname}} ユーザーを設定",
                    "PARTNER_ADD_ACCOUNTS": "{{adnname}}の広告アカウント",
                    "PARTNER_ADD_ACCOUNTS_EXPLANATION": "このコンバージョントラッキングに利用する{{adnname}}アカウントを選択してください。",
                    "PARTNER_ADD_LOGIN_EXPLANATION": "{{adnname}}ユーザーを選択し、コンバージョントラッキングを有効にする。",
                    "TITLE": "{{adnname}} 設定",
                    "TITLE_NO_NAME": "データソース設定",
                    "TITLE_PARTNER_USERS": "{{adnname}} ユーザー設定",
                    "TWITTER_EDIT_AD_ACCOUNTS": "Twitterの広告アカウントを編集",
                    "TWITTER_ENABLE_INTEGRATION_EXPLANATION": "Twitterにてモバイル広告ののコンバージョンイベントを確認するためにアカウント連携してください。",
                    "TWITTER_TERMS_AND_CONDITIONS_HEADER": "Twitterの利用規約 (モバイルアプリのコンバージョントラッキング / MACT) ",
                    "VIEW_THROUGH_ATTRIBUTION_WINDOW": "ビュースルー アトリビューションの期間指定"
                },
                "PLACEHOLDER": {
                    "ADNET": "広告媒体を選択",
                    "CAMPAIGN_NAME": "キャンペーン名を入力",
                    "CREATIVE_NAME": "クリエイティブ名",
                    "DDL": "何もしない",
                    "DEEPLINK": "デフォルト設定へ戻る",
                    "DEEPLINK_DESTINATION_URL": "ディープリンクがありません。",
                    "DESTINATION_2": "遷移先URLを選択",
                    "DESTINATION_URL": "広告媒体を選択",
                    "DOMAIN": "ドメインを選択",
                    "FALLBACK": "フォールバックURLを入力",
                    "MISSING_OS_SPECIFIC_VALUE": "このアプリの{{value}}  が設定されていません",
                    "MISSING_OS_SPECIFIC_VALUE_REACT": "このアプリの${value} が設定されていません",
                    "SELECT_APP": "アプリを選択",
                    "SITE": "サイトを選択",
                    "UNIVERSAL_IOS_DESTINATION_URL": "iOS ユニバーサルリンクがありません。"
                },
                "PLEASE_CONFIG_THIS_SOURCE": "このデータソースを設定してください",
                "REDIRECTS_TITLE": "リンクのリダイレクト",
                "REDIRECT_DDL_LABEL": "アプリを一度開くと",
                "REDIRECT_DEEPLINK_LABEL": "アプリがインストールされている場合",
                "REDIRECT_DESTINATION_LABEL": "アプリがインストールされていない場合",
                "REDIRECT_FALLBACK_LABEL": "フォールバックのリダイレクト",
                "RESURFACED_DELETED_LINK": "注意: 入力したパラメーターとキャンペーン名を含むトラッキングリンクは以前にアーカイブされています。以下の元のリンクを再利用そることもできます。このリンクは、[ビューリンク]ページで再表示されます。",
                "SEND_WEB_REFERRER": "ウェブリファラーを送る",
                "SITE": "サイト",
                "SOURCE_CONFIG": "パートナー設定",
                "SOURCE_LABEL": "ソース",
                "UNAUTHORIZED_SOURCE_CONFIG": "ソースを変更する権限がございません。",
                "UNIVERSAL_IOS_DESTINATION_URL": "iOS ユニバーサルリンクの遷移先",
                "VIEW_THROUGH_LINK": "ビュースルー トラッキングリンク"
            },
            "GOALS": {
                "NO_GOALS_MSG": "表示する目標はありません。",
                "NO_GOALS_TEXT": "目標を設定してください。"
            },
            "HOMEPAGE": {
                "MENU_ITEMS": {
                    "ADVANCED_SETUP_CATEGORY_LABEL": "アドバンス設定",
                    "APPS_BUTTON": "アプリ追加",
                    "APPS_CONTENT_TITLE": "アプリ設定",
                    "APPS_LABEL": "アプリ追加",
                    "BASIC_SET_UP_CATEGORY_LABEL": "基本設定",
                    "BASIC_SET_UP_INTRO_BUTTON": "はじめる",
                    "BASIC_SET_UP_INTRO_CONTENT_TITLE": "ようこそ、Singularへ！",
                    "BASIC_SET_UP_INTRO_LABEL": "はじめる",
                    "BI_POSTBACKS_BUTTON": "BI ポストバックを設定",
                    "BI_POSTBACKS_CONTENT_TITLE": "御社の社内BIへのポストバックを設定",
                    "BI_POSTBACKS_LABEL": "BI ポストバックを設定",
                    "BOOKMARKS_BUTTON": "ブックマーク追加",
                    "BOOKMARKS_CONTENT_TITLE": "レポートをブックマークに保存",
                    "BOOKMARKS_LABEL": "レポートをブックマーク",
                    "COHORTED_EVENTS_BUTTON": "イベント設定",
                    "COHORTED_EVENTS_CONTENT_TITLE": "イベントを設定",
                    "COHORTED_EVENTS_LABEL": "イベント設定",
                    "CREATIVE_TAGS_BUTTON": "クリエイティブタグ追加",
                    "CREATIVE_TAGS_CONTENT_TITLE": "クリエイティブタグの生成又は管理",
                    "CREATIVE_TAGS_LABEL": "クリエイティブタグ追加",
                    "CUSTOM_DIMENSIONS_BUTTON": "カスタムディメンション追加",
                    "CUSTOM_DIMENSIONS_CONTENT_TITLE": "レポートをカスタマイズ",
                    "CUSTOM_DIMENSIONS_LABEL": "カスタムディメンション追加",
                    "DATA_CONNECTORS_BUTTON": "データソース追加",
                    "DATA_CONNECTORS_CONTENT_TITLE": "データソースの設定",
                    "DATA_CONNECTORS_LABEL": "データソース追加",
                    "EXPORT_LOGS_BUTTON": "ユーザーデータのレビュー",
                    "EXPORT_LOGS_CONTENT_TITLE": "ログ抽出に関して",
                    "EXPORT_LOGS_LABEL": "ユーザーデータ（ログ抽出）のレビュー",
                    "GET_STARTED_ADWORDS_BUTTON": "Google Adsの設定",
                    "GET_STARTED_ADWORDS_CONTENT_TITLE": "Google Ads (Adwords)の追加方法",
                    "GET_STARTED_ADWORDS_LABEL": "Google Ads (Adwords)をはじめよう",
                    "GET_STARTED_FACEBOOK_BUTTON": "Facebookの設定",
                    "GET_STARTED_FACEBOOK_CONTENT_TITLE": "Facebookの追加方法",
                    "GET_STARTED_FACEBOOK_LABEL": "Facebookをはじめよう",
                    "INVITE_USERS_BUTTON": "ユーザー招待",
                    "INVITE_USERS_CONTENT_TITLE": "チームを招待",
                    "INVITE_USERS_LABEL": "ユーザー招待",
                    "MANAGE_LINKS_BUTTON": "トラッキンリンクの生成",
                    "MANAGE_LINKS_CONTENT_TITLE": "トラッキングリンクの生成と管理方法",
                    "MANAGE_LINKS_LABEL": "トラッキンリンクの生成",
                    "PARTNER_CONFIGURATION_BUTTON": "パートナー設定",
                    "PARTNER_CONFIGURATION_CONTENT_TITLE": "アトリビューション設定の方法",
                    "PARTNER_CONFIGURATION_LABEL": "アトリビューショ設定（パートナー設定）",
                    "REPORTING_API_BUTTON": "API文書に移動",
                    "REPORTING_API_CONTENT_TITLE": "API Keyを取得",
                    "REPORTING_API_LABEL": "Reporting APIの連携",
                    "REPORTING_INTEGRATIONS_CATEGORY_LABEL": "Reporting連携 - APIとBI ポストバック",
                    "REPORTING_INTEGRATIONS_INTRO_BUTTON": "はじめる",
                    "REPORTING_INTEGRATIONS_INTRO_CONTENT_TITLE": "プログラマティックレポーティング",
                    "REPORTING_INTEGRATIONS_INTRO_LABEL": "紹介",
                    "RUN_CREATIVE_REPORT_BUTTON": "クリエイティブレポート実行",
                    "RUN_CREATIVE_REPORT_CONTENT_TITLE": "クリエイティブの成果を分析",
                    "RUN_CREATIVE_REPORT_LABEL": "クリエイティブレポート実行",
                    "RUN_REPORT_BUTTON": "基本レポート実行",
                    "RUN_REPORT_CONTENT_TITLE": "キャンペーンデータの確認",
                    "RUN_REPORT_LABEL": "基本レポート実行",
                    "RUN_ROI_REPORT_BUTTON": "ROIレポート実行",
                    "RUN_ROI_REPORT_CONTENT_TITLE": "ROI分析",
                    "RUN_ROI_REPORT_LABEL": "ROIレポート実行",
                    "SDK_CONSOLE_ADVANCED_EVENTS_BUTTON": "アプリ内イベントのテスト",
                    "SDK_CONSOLE_ADVANCED_EVENTS_CONTENT_TITLE": "アプリ内イベントのテスト方法",
                    "SDK_CONSOLE_ADVANCED_EVENTS_LABEL": "アプリ内イベントのテスト",
                    "SDK_CONSOLE_BASIC_EVENTS_BUTTON": "基本イベントのテスト",
                    "SDK_CONSOLE_BASIC_EVENTS_CONTENT_TITLE": "ライブに必要な項目",
                    "SDK_CONSOLE_BASIC_EVENTS_LABEL": "基本イベントのテスト",
                    "SDK_CONSOLE_REVENUE_EVENTS_BUTTON": "収益イベントのテスト",
                    "SDK_CONSOLE_REVENUE_EVENTS_CONTENT_TITLE": "収益イベントのテスト方法",
                    "SDK_CONSOLE_REVENUE_EVENTS_LABEL": "収益イベントのテスト",
                    "SDK_CONSOLE_TESTING_BUTTON": "テストデバイスの追加",
                    "SDK_CONSOLE_TESTING_CONTENT_TITLE": "SDK連携テストの方法",
                    "SDK_CONSOLE_TESTING_LABEL": "連携をSDKコンソルからテスト",
                    "SDK_INTEGRATION_CATEGORY_LABEL": "Singular SDK連携",
                    "SDK_INTEGRATION_INTRO_BUTTON": "はじめる",
                    "SDK_INTEGRATION_INTRO_CONTENT_TITLE": "Singular SDK追加の手順",
                    "SDK_INTEGRATION_INTRO_LABEL": "SDK連携の計画",
                    "SDK_KEYS_AND_DOCUMENTATION_BUTTON": "SDK文書に移動",
                    "SDK_KEYS_AND_DOCUMENTATION_CONTENT_TITLE": "SDK Keyと文書の確認場所",
                    "SDK_KEYS_AND_DOCUMENTATION_LABEL": "SDK Keyと文書の確認",
                    "SET_UP_CAMPAIGNS_CATEGORY_LABEL": "キャンペーン配信",
                    "SET_UP_CAMPAIGNS_INTRO_BUTTON": "はじめる",
                    "SET_UP_CAMPAIGNS_INTRO_CONTENT_TITLE": "キャンペーンをはじめよう",
                    "SET_UP_CAMPAIGNS_INTRO_LABEL": "紹介",
                    "VIEW_DATA_CATEGORY_LABEL": "データ分析"
                }
            },
            "MANAGE_LINKS": {
                "RE_ENGAGEMENT_LABEL": "Enable Re-Engagement Tracking",
                "VIEW_ATTRIBUTION_WINDOW_SETTINGS_LABEL": "View attribution window settings"
            },
            "NEW_ALERT": {
                "ALERT_ME_WHEN": "下記の条件を満たした際に時に通知を行う。",
                "ALERT_STATUS": "通知の状況",
                "CHECK_ALERT_ON_EACH": "通知詳細設定：",
                "PLACEHOLDERS": {
                    "ALERT_TITLE": "通知タイトルを入力",
                    "EMAIL": "メールアドレス",
                    "FILTER_VALUES": "フィルター値（オプション）",
                    "METRIC": "指標",
                    "NOTES": "内要"
                },
                "PREAMBLE": "自動通知を設定してください。ご不明な点は <a href=\"https://singular.zendesk.com/hc/en-us/articles/207429303-How-to-Use-Alerts-Part-2-Configuration\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">ヘルプ</a> を参照してください。",
                "SEND_EMAIL_TO": "メール送信先"
            },
            "NEW_GOAL": {
                "BREAKDOWNS": {
                    "Ad Networks": "媒体",
                    "Apps": "アプリ",
                    "Country": "国",
                    "Platforms": "プラットフォーム"
                },
                "BREAKDOWNS_TITLE": "条件",
                "DURATION": "期間",
                "GOAL_NAME": "目標",
                "METRIC": "指標",
                "SUB_GOALS": "サブ目標",
                "TOTAL_BUDGET": "全体予算"
            },
            "PARTNERS_PORTAL": {
                "LANDING_PAGE_BUTTON": "アカウント選択",
                "LANDING_PAGE_HEADER": "ようこそ、 ${partnerName}",
                "LANDING_PAGE_MODAL_SEARCH_PLACEHOLDER": "アカウントを選択",
                "LANDING_PAGE_SUB_HEADER": "アクセスしたアカウントを選択してください。"
            },
            "PARTNER_CONFIG": {
                "STATUS_ERROR_DESC": "ポストバック失敗",
                "STATUS_ERROR_HEADER": "アクション必要",
                "STATUS_INDICATION_ERROR": "エラー",
                "STATUS_INDICATION_INACTIVE": "無効",
                "STATUS_INDICATION_OK": "有効",
                "STATUS_INDICATION_WAITING": "データ待ち",
                "STATUS_INDICATION_WARNING": "警告",
                "STATUS_WARNING_DESC": "ポストバック再送",
                "STATUS_WARNING_HEADER": "警告"
            },
            "REPORTS": {
                "BOOKMARKS": {
                    "COMPARISON_GAP_1": "終了",
                    "COMPARISON_GAP_2": "日前に終了",
                    "LAST_DAYS_BACK_1": "過去",
                    "LAST_DAYS_BACK_2": "日間",
                    "PLACEHOLDER_REPORT_NAME": "例）アプリ名",
                    "PUBLIC": "チームと共有",
                    "REMOVE": "削除",
                    "ROLLING_PERIOD": "継続",
                    "STATIC_PERIOD": "固定",
                    "TIME_PERIOD": "期間",
                    "TITLE": "保存名："
                },
                "CHART_CONTAINS_A_LOT_OF_DATA": "チャートに大量のデータが含まれております。しばらく、お待ちくださいませ。",
                "CHART_MAX_REACHED": "最大 ${max} 個までの指標を同時選択出来ます。",
                "CHART_METRIC_NO_DATA": "この指標にて表示されるデータがありません。",
                "CHART_NO_DATA": "表示するデータがありません。",
                "COMPARE_WARNING": "警告： 比較の二つ期間が一致しません。",
                "DATEPICKER_PERIOD_TITLE": "期間 (${daysPeriod} 日)",
                "DATEPICKER_PREVIOUS_PERIOD_TITLE": "前期間 (${daysPeriod} 日)",
                "DIMENSION_DRILLDOWN_DISABLED_SELECTION": "パブリッシャー内訳の為には、アプリとソースをフィルターする必要があります。",
                "DIMENSION_DRILLDOWN_ICON_TOOLTIP": "クリックし内訳表示",
                "DIMENSION_DRILLDOWN_ITEM_CAMPAIGN": "キャンペーン (ID, Name)",
                "DIMENSION_DRILLDOWN_ITEM_COUNTRY": "国",
                "DIMENSION_DRILLDOWN_ITEM_CREATIVES": "クリエイティブ素材",
                "DIMENSION_DRILLDOWN_ITEM_DAY": "日付: 日",
                "DIMENSION_DRILLDOWN_ITEM_PUBLISHER": "パブリッシャー (ID, Name, Site ID)",
                "DIMENSION_DRILLDOWN_ITEM_SUB_CAMPAIGN": "サブキャンペーン (ID, Name)",
                "GOAL_WARNING": "注意: 目標の時間軸が未来である為、全てのデータは反映されません。",
                "GOAL_WARNING_2": "目標の時間軸が未来である為、データが表示されません。",
                "METADATA_DIMENSION_SUB_TEXT": "<a href=\"https://support.singular.net/hc/en-us/articles/360032548672-Campaign-Properties-FAQ\" target='_blank'>可能な媒体</a>",
                "ADMON_DAU_METRICS_SUB_TEXT": "Available for <a href=\"https://support.singular.net/hc/en-us/articles/360022067552-Ad-Monetization-Analytics-FAQ#User_Activity_Metrics_DAU_and_ARPDAU\" target='_blank'>selected dimensions</a>",
                "PARTNER_TIMEZONE_INFO": "${customer} のトラッカー集計データは ${attributionTimezone} 基準です。",
                "PERFORMANCE_WARNING": "<div>配信国とキャンペーンの詳細を選択した場合、表示には時間がかかることがあります。</div><div>設定期間を短くすることで表示時間が短縮します。</div>",
                "PUBLISHER_PERFORMANCE_WARNING": "<div>注意: パブリッシャーの内訳を選択する場合、大量のデータが表示され為、</div><div> アプリとソースのフィルター指定が必要です。</div>",
                "ADMON_DAU_WARNING": "<div>DAU and ARPDAU are only available for the dimensions app, OS, and/or country.</div>",
                "SOURCE_DRILLDOWN_PREAMBLE": "具体的なソースを表示するには、下記デーブルの各欄をクリックください。<a href=\"https://singular.zendesk.com/knowledge/articles/213519803/en-us?brand_id=188619\" class=\"link-underline\">詳しくはこちら</a>",
                "SUBHEADER_FAQ": "レポートの操作に関しては、 <a href=\"https://support.singular.net/hc/en-us/articles/205046069-Reports-FAQ\" class=\"link-underline\" target=\"_blank\">Reports FAQ</a> をご参照ください。",
                "TOOLTIPS": {
                    "custom": {
                        "PLURAL": "${metric} はカスタム設定によって計算",
                        "SINGLE": "${metric} はカスタム設定によって計算"
                    },
                    "mixed": {
                        "PLURAL": "${metric} はカスタム設定で媒体からもトラッカーからも取得",
                        "SINGLE": "${metric} はカスタム設定で媒体からもトラッカーからも取得"
                    },
                    "network": {
                        "PLURAL": "${metric} は媒体から取得",
                        "SINGLE": "${metric} は媒体から取得"
                    },
                    "tracker": {
                        "PLURAL": "${metric} はトラッカーから取得",
                        "SINGLE": "${metric} はトラッカーから取得"
                    }
                },
                "TRANSPARENCY": {
                    "METRIC_DRILLDOWN": "指標内訳"
                },
                "VALIDATION_ERROR_FUTURE_GOAL": "未来ではない目標の時間軸を選択してください。",
                "VALIDATION_ERROR_GRANULARITY": "一つ以上のデータ粒度レベルとファイル統合ディメンションを選択してください。",
                "VALIDATION_ERROR_PERIOD_FOR_COHORT": "コーホート指標やカスタムイベントを選択するには、一つ以上の期間を選択してください。",
                "VALIDATION_ERROR_PUBLISHER": "パブリッシャーデータの出力には一つのアプリとソースのみ選択可能です。",
                "VALIDATION_ERROR_SELECT_GOAL": "目標を選択してください。",
                "VALIDATION_ERROR_SELECT_METRIC": "一つ以上の指標を選択してください。",
                "VALIDATION_ERROR_ALLOWED_DIMENSIONS": "DAU and ARPDAU are not available for the dimensions you selected. To run a report with DAU and/or ARPDAU, remove all dimensions except for app, OS, and country.",
                "YOUR_CHART_IS_READY": "チャートが準備出来ました！"
            },
            "SDK_CONSOLE": {
                "ADD_DEVICE": "デバイス追加",
                "FORGET_DEVICE": "デバイスの記録を削除しますか？",
                "FORGET_DEVICE_CANCEL": "いいえ。",
                "FORGET_DEVICE_CONFIRM": "はい、デバイスの記録を削除します。"
            },
            "TEAM_MEMBERS": {
                "ATTENTION": "注意",
                "AUTO_ADD_FUTURE_ITEMS": "自動的に将来の ${itemType} を追加",
                "CHANGING_TO_ADMIN_WARNING": "アドミンに変更する場合、全てのメニューとデータにアクセス出来ます。",
                "DEACTIVATE_USER": "無効にする",
				"DELETE_MODAL": {
                    "TITLE": "本当にこのユーザーを削除しますか？",
                    "MESSAGE": "このアクションは永久的、かつ全組織に影響します",
                    "ACCEPT_BUTTON_TEXT": "はい",
                    "REJECT_BUTTON_TEXT": "いいえ",
                },
                "EMAIL": "メールアドレス",
                "EXTERNAL_USER_WARNING": "組織外のユーザーを招待しております。御社のポリシーに適切なデータ制限が行っているか必ずご確認ください。",
                "FILL_FIELDS_TOOLTIP": "必須項目をご記入ください。",
                "FIRST_NAME": "名前",
                "FUTURE_ITEMS_WILL_BE_ADDED": "将来の ${itemType} は自動的に追加されます。",
                "GROUP_NAME_ACTIVE": "メンバー有効化",
                "GROUP_NAME_INACTIVE_TEAM_USERS": "ユーザーの無効化",
                "GROUP_NAME_PENDING_TEAM_USERS": "招待保留",
                "INVITE": "メンバーを招待する",
                "INVITE_FULL_MEMBER": "メンバーの招待",
                "INVITE_INFO": "${role} \"${userName}\" がSingularに招待されました！",
                "INVITE_REASON": "組織外のメンバーを招待する具体的な理由を記載ください。",
                "LAST_NAME": "姓",
                "MAKE_NEW_POC_MEMBER_EXPLANATION": "次に進む為に新しい担当者を選択してください。",
                "MAKE_POC": "担当者のアサイン",
                "MAKE_POC_TOOLTIP": "Singularとやり取りを行う担当者をアサイン。",
                "MANAGE_PERMISSIONS": "権限管理",
                "ME": "(あなた)",
                "MEMBER_YOU": "有効（あなた）",
                "MODIFIED_TYPE_INFO": "${userName} のユーザータイプが ${role} と変更されました。",
                "POINT_OF_CONTACT": "担当者",
                "REINVITE_DISABLED_TOOLTIP": "招待の再送信は24時間事に送信可能です。",
                "REMOVE_POC": "担当者を削除",
                "RESEND_INVITE": "招待を再送",
                "RESEND_INVITE_REACT": "招待の再送信",
                "REVOKE_INVITE": "招待を取消",
                "RE_INVITE_SENT": "${userName} に招待が送信されました。",
                "ROLE_SELECTION_CREATE_USER_TITLE": "どのタイプのユーザーを生成しますか？",
                "ROLE_SELECTION_MANAGE_USER": "${userName} にどの権限を付与しますか?",
                "SCREEN_SELECTION_WARNING": "このメニューには全てのアプリとソースのデータが表示されます。",
                "SELECT_AT_LEAST_ONE_TOOLTIP": "一つ以上を選択してください。 ${itemType}",
                "SENDING_INVITE": "招待を送信",
                "SEND_INVITE": "招待",
                "SHELF_CREATE_NEW_USER": "新規ユーザーを生成",
                "SHELF_CREATE_NEW_USER_WITH_ROLE": "新規 ${roleType} ユーザーを生成",
                "SHELF_MANAGE_USER_PERMISSIONS": "ユーザー権限を管理",
                "USER_DELETED": "${userName}のユーザーは削除されました。",
                "USER_TYPE_ADMIN": "アドミン",
                "USER_TYPE_RESTRICTED": "制限ユーザー",
                "USER_TYPE_STANDARD": "一般ユーザー",
                "WARN1": "セキュリティー上、グループメールではなく、個別のメールアドレスのみご登録ください。",
                "WARN2": "招待されたメンバーは、全ての情報にアクセスができます。",
                "WIZARD_CHANGE_USER_TYPE": "ユーザータイプの変更",
                "WIZARD_SCREENS_ENABLE_EXPORT_LOGS": "ログ抽出メニューを共有する為には、全ての指標を選択する必要があります。",
                "WIZARD_SCREEN_SELECTION_TITLE": "共有するメニューを選択してください：",
                "WIZARD_SUMMARY_APPS_TITLE": "共有アプリ (${count}/${overall})",
                "WIZARD_SUMMARY_DS_TITLE": "共有データソース (${count}/${overall})",
                "WIZARD_SUMMARY_METRICS_TITLE": "共有指標 (${count}/${overall})",
                "WIZARD_SUMMARY_SCREENS_TITLE": "共有メニュー (${count}/${overall})",
                "WIZARD_SUMMARY_TITLE": "ユーザー権限をご確認ご確認ください。",
                "WIZARD_SUMMARY_USER_DETAILS_EMAIL": "メールアドレス",
                "WIZARD_SUMMARY_USER_DETAILS_NAME": "ユーザー名",
                "WIZARD_SUMMARY_USER_DETAILS_TITLE": "ユーザーの詳細",
                "WIZARD_SUMMARY_USER_TYPE": "ユーザーのタイプ"
            },
            "TWO_FACTOR_AUTH": {
                "P1": "2 段階認証は現在のアカウントではご利用いただけません。",
                "P1_ACTIVE": "２段階認証は現在有効です。",
                "P2": "2段階認証とは、マスターIDとパスワードの他に、もう1つ認証を加えた方法です。シンギュラーアカウントのログインに2段階認証をかけることで、セキュリティーにアカウントの セキュリティがさらに強化されます。設定後には、パスワードと認証コード（モバイルから）の二つ入力することが必須となります。",
                "P3": "注意：２段階認証が有効になると現在実行中の全てのセッション（他端末含む）からログアウトされます。 ご了承ください。"
            },
            "TWO_FACTOR_AUTH_CONFIG": {
                "PREAMBLE": "２段階認証の設定後、シンギュラーアカウントへ、ログインする為には、下記の認証アプリが作成した「認証コード」を入力する必要があります。",
                "STEP_1": {
                    "DESCRIPTION": "アプリを入手する。",
                    "INSTRUCTIONS": "いずれかのアプリをインストールする：<a href=\"https://m.google.com/authenticator\" target=\"_blank\">Google Authenticator</a>, <a href=\"http://guide.duosecurity.com/\" target=\"_blank\">Duo Mobile</a>, <a href=\"https://www.authy.com/\" target=\"_blank\">Authy</a>, <a href=\"https://www.windowsphone.com/en-us/store/app/authenticator/e7994dbc-2336-4950-91ba-ca22d653759b\" target=\"_blank\">Windows Phone Authenticator</a>",
                    "TITLE": "ステップ 1"
                },
                "STEP_2": {
                    "DESCRIPTION": "バーコードをスキャンする。",
                    "HELP": "画像のスキャンができない場合、アプリより「Manual entry」を選択し、下記の仮キーを入力してください。</p> <p><code>{{code}}</code></p> <p>仮キー入力後、アプリが生成した6ケタの認証コードを下記に入力してください。",
                    "INSTRUCTIONS": "認証アプリを開く：<ul><li>「+」 もしくは 「Add Account」 アイコンをタップする。</li><li>カメラを使い画像をスキャンする。</li></ul>",
                    "TITLE": "ステップ 2"
                },
                "STEP_3": {
                    "DESCRIPTION": "認証コードを入力する。",
                    "INSTRUCTIONS": "アプリが生成した６ケタの認証コードを入力してください。",
                    "TITLE": "ステップ 3"
                }
            },
            "VALIDATE_ACTION_MODAL": {
                "ACTION_NO": "いいえ",
                "ACTION_YES": "はい",
                "WARNING_P2": "このまま続けますか?"
            },
            "VIEW_TRACKING_LINKS": {
                "ARCHIVE_YES": "はい、リンクをアーカイブします",
                "DELETE_WARNING": "このリンクをアーカイブしますか?"
            }
        },
        "PAGE_HEADERS": {
            "AD_MONETIZATION": "広告収益レポート",
            "ALERTS": "アラート",
            "API": "Singular API",
            "APPS_CONFIGURATION": "アプリ設定",
            "AUDIENCE_DISTRIBUTIONS": "オーディエンスの連携先",
            "AUDIENCE_SEGMENTS": "オーディエンスを定義",
            "BATCH_LINKS": "トラッキングリンクの一括生成",
            "CREATIVES": "クリエイティブ レポート",
            "CREATIVE_TAGS": "クリエティブタグ",
            "CUSTOM_DIMENSIONS": "カスタムディメンジョン",
            "CUSTOM_EVENTS": "カスタムイベント",
            "CUSTOM_METRICS": "カスタム指標",
            "CUSTOM_TRACKING_LINKS": "カスタムリンクを生成",
            "DASHBOARD": "ダッシュボード",
            "DATA_CONNECTORS": "データーコネクタ",
            "DATA_SOURCES": "データソース",
            "EDIT_ALERT": "アラートを編集する",
            "EDIT_GOAL": "目標を編集する",
            "EDIT_QUERY": "クエリーを編集する",
            "EXPORT_LOGS": "ログをエキスポート",
            "FRAUD_DASHBOARD": "フラウドダッシュボード",
            "FRAUD_PROTECTED": "フラウド保護レポート",
            "FRAUD_REJECTED": "フラウドリジェクトレポート",
            "FRAUD_SUSPICIOUS": "フラウド疑惑レポート",
            "GENERATE_LINK": "Singularトラッキングリンクの生成",
            "GOAL_MANAGEMENT": "目標管理",
            "NEW_ALERT": "自動アラート",
            "NEW_GOAL": "新しい目標設定",
            "DUPLICATE_GOAL": "新しい目標設定",
            "PIVOT": "ピボットテーブル",
            "REPORTS": "レポートクエリー",
            "RETENTION": "リテンション",
            "SDK_CONSOLE": "SDK コンソール",
            "TEAM_MEMBERS": "メンバーの招待",
            "TWO_FACTOR_AUTH": "2段階認証の設定",
            "TWO_FACTOR_AUTH_CONFIG": "2段階認証の設定について",
            "WEB_TRACKING_LINKS": "カスタムのウェブトラッキングリンクを生成"
        },
        "PAGE_MESSAGES": {
            "NO_APPS_CONFIGURED_PLEASE_CONFIGURE": "アプリ内イベントが確認できません。SDKの実装内容を確認 または サポートチームまでお問い合わせください。",
            "RESULTS_COUNT": "{{x}} の {{y}} 件を表示"
        },
        "PARTNER": "パートナー",
        "PLACEHOLDERS": {
            "CURRENT_PASSWORD": "現在のパスワード",
            "ENTER_2FA_CODE": "コードを入力",
            "NEW_PASSWORD": "新しいパスワード",
            "SELECT_APP": "アプリを選択",
            "SELECT_CAMPAIGN": "キャンペーンを選択",
            "SELECT_EMAIL_ADDRESS": "メールアドレス",
            "SELECT_EVENT": "イベントの選択",
            "SELECT_FILTER": "条件を入力",
            "SELECT_GOAL": "目標を選択",
            "SELECT_OS": "OSを選択",
            "SELECT_PERIOD": "期間を選択",
            "SELECT_PLATFORM": "プラットフォームを選択",
            "SELECT_SOURCE": "ソースを選択"
        },
        "PLATFORM": "プラットフォーム",
        "POPOVERS": {
            "PARTNER_CONFIG_STATUS": {
                "EXPORT": "詳細なポストバックログを抽出",
                "NO_DATA": "データが存在しません",
                "TIMESPAN": " {{pull_since}} と {{pull_from}} ({{timezone}})期間のデータ",
                "TYPE_FAILURE": "失敗",
                "TYPE_RETRIES": "再試行",
                "TYPE_SUCCESS": "成功"
            }
        },
        "REPORT_CHART_TITLE": "{{metric}}（{{suffix}}{{period}}）",
        "REPORT_CHART_TITLE_2": "全期間",
        "REPORT_COMMENTS": "コメント  (オプション)",
        "REPORT_NAME": "レポート名",
        "REQUIRED_FIELD": "必要項目",
        "SHARE_URL_DISCLAIMER1": "このURLは非公開です。このURLを知っているユーザーであれば誰でも閲覧することができますので、ご注意ください。",
        "SHARE_URL_DISCLAIMER2": "レポートにアクセスが出来ますので、 ご注意ください。",
        "SHARE_WITH": "共有",
        "SHOW_CHART_TOP_ROWS1": "グラフ表示数",
        "SHOW_CHART_TOP_ROWS2": "テーブル列",
        "SHOW_CHART_TOP_ROWS3": "グラフの上の部分を表示する",
        "SOURCE": "ソース",
        "SOURCE_DIMENSIONS": "ソースディメンション",
        "SOURCE_METRICS": "取得情報",
        "TIME_BREAKDOWN": "期間設定",
        "TOOLTIPS":{
            "MARK_FAVOURITE_APP": "お気に入りに追加"
        },
        "TOPBAR_HEADERS": {
            "BOOKMARKS": "ブックマーク",
            "CHANGE_PASSWORD": "パスワード変更",
            "CHANGE_USER": "アカウント変更",
            "HELP_CENTER": "ヘルプセンター",
            "LOGOUT": "ログアウト",
            "NOTIFICATION_TOOLTIP": "通知を受け取る場合はここをクリック",
            "TWO_FACTOR_AUTH": "2段階認証",
            "WARNING_SENSITIVE_DATA": "機密なお客様の情報を閲覧中です。"
        },
        "TRACKING_LINKS": "トラッキングリンク",
        "WIDGET_TITLE": "ウィジェットタイトル",
        "XORG_COHORT_METRICS": "組織横断コーホート指標",
        "XORG_KPI_METRICS": "お客様KPI",
        "XORG_METRICS": "組織横断指標"
    },
    "Segments": "セグメント",
    "Select a dimension": "ディメンション選択",
    "Settings": "設定",
    "Size": "サイズ",
    "Source": "ソース",
    "Source configured successfully": "実装完了",
    "Standarized Bid Strategy": "標準化入札戦略",
    "Standarized Bid Type": "標準化入札タイプ",
    "Status": "配信ステータス",
    "Sub Ad Network": "サブアドネットワーク",
    "Sub Campaign": "サブキャンペーン",
    "Sub Campaign ID": "サブキャンペーンID",
    "Sub Campaign Name": "サブキャンペーン名",
    "Suspicious Report": "フラウド疑惑レポート",
    "Team Management": "チーム管理",
    "Total Budget": "総予算",
    "Totals": "合計",
    "Tracker Campaign ID": "トラッカーキャンペーンID",
    "Tracker Campaign Name": "トラッカーキャンペーン名",
    "Tracker Clicks": "トラッカークリック",
    "Tracker Conversions": "トラッカーコンバージョン",
    "Tracker Impressions": "トラッカーインプレッション",
    "Tracker Installs": "トラッカーインストール",
    "Tracker Name": "トラッカー名",
    "Tracker Publisher ID": "トラッカーパブリッシャーID",
    "Tracker Sub Campaign ID": "トラッカーサブキャンペーンID",
    "Tracker Sub Campaign Name": "トラッカーサブキャンペーン名",
    "USD to JPY Exchange Rate": "為替レート（USD → JPY）",
    "Undergoing Verification": "認証中",
    "Week": "週",
    "You have invalid accounts, please remove them": {
        "": "アカウント情報が無効です。削除してください。"
    },
    "You haven't defined any goals yet": "目標がまだ設定されていません。",
    "alert": "通知",
    "app": "アプリ",
    "contains": "を含む",
    "custom dimension": "カスタムディメンション",
    "d": "日",
    "day": "日",
    "eCPI (Orig": {
        " Cost)": "eCPI (原価)"
    },
    "ends with": "で終わる",
    "event": "イベント",
    "exclude": "を含まない",
    "goal": "目標",
    "include": "を含む",
    "invite_opened": "招待開封済",
    "invite_sent": "招待済",
    "month": "月",
    "site": "URL",
    "starts with": "で始まる",
    "week": "週"
};

languages['ja'] = ja;

module.exports = ja;
