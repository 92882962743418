export const LOAD = 'action.audiences.distributions.load';
export const UNLOAD = 'action.audiences.distributions.unload';
export const SEARCH = 'action.audiences.distributions.search';
export const EDIT_START = 'action.audiences.distributions.edit_start';
export const EDIT_CANCEL = 'action.audiences.distributions.edit_cancel';
export const EDIT_SAVE = 'action.audiences.distributions.edit_save';
export const EDIT_SAVE_FAIL = 'action.audiences.distributions.edit_save_fail';
export const EDIT_SAVE_DONE = 'action.audiences.distributions.edit_save_done';
export const EDIT_CHANGE = 'action.audiences.distributions.edit_change';
export const TOGGLE_AD_ACCOUNT = 'action.audiences.distributions.toggle_ad_account';
export const TOGGLE_AD_ACCOUNT_DONE = 'action.audiences.distributions.toggle_ad_account_done';
export const EDIT_SEGMENT_START = 'action.audiences.distributions.edit_segment_start';
export const EDIT_SEGMENT_CHANGE = 'action.audiences.distributions.edit_segment_change';
export const EDIT_SEGMENT_SAVE = 'action.audiences.distributions.edit_segment_save';
export const EDIT_SEGMENT_SAVE_DONE = 'action.audiences.distributions.edit_segment_save_done';
export const EDIT_SEGMENT_SAVE_DONE_DELAYED = 'action.audiences.distributions.edit_segment_save_done_delayed';
export const EDIT_SEGMENT_CANCEL = 'action.audiences.distributions.edit_segment_cancel';
export const MANUAL_RUN = 'action.audiences.distributions.manual_run';

function track(name, params) {
    if (typeof window.mixpanel !== 'object') {
        return;
    }
    window.mixpanel.track(`Audiences: Distributions: ${name}`, params);
}

export const load = (networks, segments, distributionTypes, companyLogo) => {
    track('Page Loaded');
    return {
        type: LOAD,
        networks,
        segments,
        distributionTypes,
        companyLogo,
    };
};

export const unload = () => {
    return {
        type: UNLOAD,
    };
};

export const search = query => {
    return {
        type: SEARCH,
        query,
    };
};

export const editStart = network => {
    track('Open Configuration', { network: network.name });
    return {
        type: EDIT_START,
        network,
    };
};

export const editCancel = edit => {
    track('Cancel Configuration', { network: edit.name });
    return {
        type: EDIT_CANCEL,
    };
};

export const editSave = network => {
    track('Save Configuration', { network: network.name });
    return {
        type: EDIT_SAVE,
        network,
    };
};

export const editSaveFail = error => {
    return {
        type: EDIT_SAVE_FAIL,
        error,
    };
};

export const editSaveDone = (network, id) => {
    return {
        type: EDIT_SAVE_DONE,
        network,
        id,
    };
};

export const editChange = (key, value, inConfig = false) => {
    return {
        type: EDIT_CHANGE,
        key,
        value,
        inConfig,
    };
};

export const toggleAdAccount = (network, account, active) => {
    return {
        type: TOGGLE_AD_ACCOUNT,
        network,
        account,
        active,
    };
};

export const toggleAdAccountDone = (id, config) => {
    return {
        type: TOGGLE_AD_ACCOUNT_DONE,
        id,
        config,
    };
};

export const editSegmentStart = segment => {
    return {
        type: EDIT_SEGMENT_START,
        segment,
    };
};

export const editSegmentChange = (key, value, segment) => {
    return {
        type: EDIT_SEGMENT_CHANGE,
        key,
        value,
        segment,
    };
};

export const editSegmentSave = segment => {
    return {
        type: EDIT_SEGMENT_SAVE,
        segment,
    };
};

export const editSegmentSaveDone = (segment, id) => {
    return {
        type: EDIT_SEGMENT_SAVE_DONE,
        segment,
        id,
    };
};

export const editSegmentSaveDoneDelayed = segment => {
    return {
        type: EDIT_SEGMENT_SAVE_DONE_DELAYED,
        segment,
    };
};

export const editSegmentCancel = segment => {
    return {
        type: EDIT_SEGMENT_CANCEL,
        segment,
    };
};

export const manualRun = uanId => {
    return {
        type: MANUAL_RUN,
        uanId,
    };
};
