import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faApple from '@fortawesome/fontawesome-free-brands/faApple';
import faAndroid from '@fortawesome/fontawesome-free-brands/faAndroid';
import faPencilAlt from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import { EXPAND_ARROW, COLLAPSE_ARROW } from '../../../utils/trackingLinks';
import Destination from './Destination';
import TrackingLink from './TrackingLink';
import ArchiveIcon from '../../../resources/svg/archive_grey.svg';

class Campaign extends Component {
    constructor(props) {
        super(props);
        this.editInput = React.createRef();
    }

    adjustWidthOfInput() {
        const inputElement = document.getElementById('editInputField');
        inputElement.size = Math.max(18, inputElement.value.length + 3);
    }

    render() {
        const { props } = this;
        const { css } = props;
        const firstItem = props.data.platforms[Object.keys(props.data.platforms)[0]];
        return (
            <div className={css.CampaignPanel}>
                <div className={css.CampaignHeading}>
                    <div
                        className={`${css.CampaignName} ${css.Expand}`}
                        onClick={() => {
                            props.toggleExpand(props.campaign_id);
                        }}
                    >
                        {props.expanded ? COLLAPSE_ARROW : EXPAND_ARROW}
                    </div>
                    <div
                        className={`${css.CampaignName} ${css.TextWrapper}`}
                        onClick={() => {
                            props.toggleExpand(props.campaign_id);
                        }}
                    >
                        <span>
                            {props.data.platforms.iOS && (
                                <FontAwesomeIcon
                                    icon={faApple}
                                    style={{ marginRight: '5px', color: '#CCCCCC', fontSize: '16px' }}
                                    className="fa fa-fw fa-apple"
                                />
                            )}
                        </span>
                        <span>
                            {props.data.platforms.Android && (
                                <FontAwesomeIcon
                                    icon={faAndroid}
                                    style={{ marginRight: '5px', color: '#CCCCCC', fontSize: '16px' }}
                                    className="fa fa-fw fa-android"
                                />
                            )}
                        </span>
                        {props.editingCampaign === props.campaign_id ? (
                            <input
                                autoFocus
                                type="text"
                                defaultValue={props.campaign}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        props.editCampaignName(props.app_id, e);
                                    } else if (e.key === 'Escape') {
                                        props.editClicked(undefined);
                                    } else {
                                        this.adjustWidthOfInput();
                                    }
                                }}
                                id="editInputField"
                                ref={this.editInput}
                                onBlur={() => {
                                    props.editClicked(undefined);
                                }}
                                size={Math.max(18, props.campaign.length + 3)}
                                onClick={(e) => e.stopPropagation()}
                                onKeyUp={this.adjustWidthOfInput.bind(this)}
                                style={{
                                    height: '20px',
                                    fontSize: '16px',
                                    marginBottom: '5px',
                                }}
                            />
                        ) : (
                            <span className={css.CampaignNameLabel}>{props.campaign}</span>
                        )}
                        {firstItem.singular_link ? (
                            <span className={css.CampaignNameSingularLinkLabel}>(Singular Link)</span>
                        ) : null}
                        {!firstItem.singular_link ? (
                            <React.Fragment>
                                <span
                                    className={css.deleteButton}
                                    style={{
                                        borderRight: '1px solid #a3a3a3',
                                        paddingRight: '10px',
                                        paddingLeft: '10px',
                                        marginLeft: '10px',
                                        fontSize: '12px',
                                        color: '#a3a3a3',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (props.editingCampaign === props.campaign_id) {
                                            props.editClicked(undefined);
                                        } else {
                                            props.editClicked(props.campaign_id);
                                        }
                                    }}
                                >
                                    {props.editingCampaign === props.campaign_id ? (
                                        <FontAwesomeIcon icon={faTimes} />
                                    ) : (
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    )}
                                </span>
                                <span
                                    className={css.deleteButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.deleteClicked(props.campaign_id);
                                    }}
                                    style={{ paddingLeft: '10px', verticalAlign: 'top' }}
                                >
                                    <ArchiveIcon
                                        style={{
                                            height: '15px',
                                            width: '15px',
                                            marginTop: '3px',
                                        }}
                                    />
                                </span>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
                {props.expanded && (
                    <div>
                        <div className={css.DestinationPanel}>
                            <div className={css.DestinationHeading}>Destinations</div>
                            <div className={css.DestinationTable}>
                                <div>
                                    {Object.keys(props.data.platforms)
                                        .sort()
                                        .map((platform) => (
                                            <Destination
                                                key={`${props.campaign}_destination_${platform}`}
                                                css={css}
                                                destination={props.data.platforms[platform].destination}
                                                platform={platform}
                                                link={props.data.platforms[platform]}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className={css.DestinationPanel}>
                            <div className={css.DestinationHeading}>Tracking Links</div>
                            <div className={css.DestinationTable}>
                                <TrackingLink css={css} data={firstItem} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Campaign.propTypes = {
    app_id: PropTypes.number,
    campaign: PropTypes.string,
    campaign_id: PropTypes.string,
    css: PropTypes.objectOf(PropTypes.any),
    deleteClicked: PropTypes.func,
    data: PropTypes.objectOf(PropTypes.any),
    editClicked: PropTypes.func,
    editCampaignName: PropTypes.func,
    editingCampaign: PropTypes.string,
    enabledCampaignEditing: PropTypes.bool,
    expanded: PropTypes.bool,
    toggleExpand: PropTypes.func,
};

Campaign.defaultProps = {
    app_id: null,
    campaign: '',
    campaign_id: '',
    css: {},
    deleteClicked: {},
    data: {},
    editClicked: {},
    editCampaignName: {},
    editingCampaign: '',
    enabledCampaignEditing: false,
    expanded: false,
    toggleExpand: {},
};

export default Campaign;
