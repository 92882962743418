import React from 'react';
import PropTypes from 'prop-types';

export default function DataConnectorsTaskContentBody(props) {
    const { isApsalarEnabled } = props;
    return (
        <div>
            <p>
                <strong>What:</strong><span> Add a data connector for each partner you work with (you’ll be asked to enter your login credentials).</span>
            </p>
            <p>
                <strong>Why: </strong><span>Data connectors are how Singular pulls data from your networks into our platform. Once you set up your data connectors, you’ll be able to start running reports containing campaign data such as cost, {isApsalarEnabled && 'creative, '}clicks, etc (see Run A Basic R section below).</span>
            </p>
            <p>
                <strong>Tip: </strong><span>Once you add a Data Connector, Singular will start pulling data from the partner immediately, getting data for 90 days back. Revisit the Data Connector page to confirm that the data pull was successful. Going forward, Singular will pull data every day at midnight in&nbsp; your account’s timezone, each time pulling data for 7 days back.</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <img class="svg" src="/static/dashboard/img/icons/faq.svg" />
                <a href="https://support.singular.net/hc/en-us/articles/360031000051" target="_blank"> FAQ</a><span>.</span>
            </p>
        </div>
    );
}

DataConnectorsTaskContentBody.propTypes = {
    isApsalarEnabled: PropTypes.bool,
};

DataConnectorsTaskContentBody.defaultProps = {
    isApsalarEnabled: undefined,
};
