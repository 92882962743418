import React from 'react';
import { TextField, AutoCompleteField } from '../../components/widgets';
import css from './Edit.pcss';
import DropdownField from '../../components/widgets/DropdownField';

export default class EditPanel extends React.Component {
    constructor(props) {
        super(props);

        this.fieldTypes = {
            text: TextField,
            tags: AutoCompleteField,
            dropdown: DropdownField,
        };
    }

    getFieldOptions(field) {
        const { edit, invalid, metadata, onChange } = this.props;

        const options = metadata ? metadata[field.options] : [];
        let editValue = edit[field.value];
        if (field.type === 'tags') {
            const editValues = editValue.map((val) => val.value);
            editValue = options.filter((option) => editValues.includes(option.value));
        }

        return {
            label: field.label,
            value: editValue,
            selected: editValue,
            onChange: (value) => {
                onChange(field.onChange, value);
            },
            error: field.error,
            placeholder: field.placeholder,
            options,
            debounce: 300,
            items: field.items,
            labelTooltip: field.labelTooltip,
        };
    }

    render() {
        const { title, fields } = this.props;

        return (
            <div className={css.panel}>
                <div className={css.header}>{title}</div>
                <div className={css.body}>
                    {fields.map((field) => {
                        const Field = this.fieldTypes[field.type];

                        return (
                            <div key={`field_${field.value}`} className={css.detailsFieldHolder}>
                                <Field {...this.getFieldOptions(field)} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
