import difference from 'lodash/difference';
import {TEAM_MANAGEMENT_EVENT_PREFIX, trackMixpanelEvent} from "../utils/general";

export const MemberGroupTypes = {
    active: 'active',
    pending: 'pending',
    inactive: 'inactive',
};

export const DELETABLE_GROUPS = [MemberGroupTypes.pending, MemberGroupTypes.active];

export const ROLE_ORG_ADMIN = 0;
export const ROLE_STANDARD = 1;
export const ROLE_RESTRICTED = 2;
export const ROLE_AGENCY = 4;

export const ACCOUNT_PARTNER = 5;
export const ACCOUNT_AGENCY = 8;
export const RECAPTCHA_PUBLIC_KEY = '6LeGkr8ZAAAAAIOFqgfHM15x_VgW2U9Tdl25AuTs';

export const RESTRICTED_ROLES = [ROLE_RESTRICTED, ROLE_AGENCY];

export const RoleTypes = {
    ADMIN: 'Admin',
    STANDARD: 'Standard',
    RESTRICTED: 'Restricted',
    AGENCY: 'Agency',
    PARTNER: 'Partner',
};

export const ACCOUNT_TYPE_NAME_MAP = {
    [ROLE_RESTRICTED]: RoleTypes.RESTRICTED,
    [ACCOUNT_AGENCY]: RoleTypes.AGENCY,
    [ACCOUNT_PARTNER]: RoleTypes.PARTNER,
};

export const ROLES = [
    {
        label: RoleTypes.ADMIN,
        id: ROLE_ORG_ADMIN,
        description: 'A user with full privileges including Team Management',
        default: true,
    },
    {
        label: RoleTypes.STANDARD,
        id: ROLE_STANDARD,
        description: 'A user with full privileges excluding Team Management',
    },
    { label: RoleTypes.RESTRICTED, id: ROLE_RESTRICTED, description: 'A user with custom access to data and screens' },
];

export const AGENCY_PERMISSION_DIMENSIONS = ['app'];

export const EXCLUDED_METRICS_SELECTION_PAGE = ['unified_data_type', 'installs_metric_group', 'revenue_metric_group'];

export const initialFieldTypes = [
    {
        type: 'metrics',
        label: 'STATIC.METRICS',
    },
    {
        type: 'discrepancyMetrics',
        label: 'STATIC.SOURCE_METRICS',
    },
    {
        type: 'cohortMetrics',
        label: 'STATIC.COHORT_METRICS',
    },
    {
        type: 'adMonDAUMetrics',
        label: 'STATIC.DAU_METRICS',
    },
    {
        type: 'dataTypeFields',
        label: 'STATIC.DATA_TYPE_FIELDS',
    },
    {
        type: 'metricGroupFields',
        label: 'STATIC.METRIC_GROUP_FIELDS',
    },
    {
        type: 'cohortMetricGroupFields',
        label: 'STATIC.COHORT_METRIC_GROUP_FIELDS',
    },
    {
        type: 'skanModeledMetrics',
        label: 'STATIC.MODELED_SKAN_METRICS',
    },
    {
        type: 'skanModeledCohortMetrics',
        label: 'STATIC.MODELED_SKAN_COHORT_METRICS',
    },
    {
        type: 'skanModeledCustomEvents',
        label: 'STATIC.MODELED_SKAN_CUSTOM_EVENTS',
    },
];

export const shelfSteps = {
    roleSelection: 'roleSelection',
    userDetails: 'userDetails',
    agencyDetails: 'agencyDetails',
    appSelection: 'appSelection',
    sourceSelection: 'sourceSelection',
    metricsSelection: 'metricsSelection',
    screenSelection: 'screenSelection',
    review: 'review',
};

export const REGISTRATION_STEPS = [shelfSteps.agencyDetails];

export const wizardModes = {
    create: 'create',
    edit: 'edit',
};

export const MemberTypes = {
    USER: 'TEAM_USERS',
    AGENCY: 'AGENCIES',
};

export const trackTeamManagementMixpanelEvent = (eventName, params = null) => {
    trackMixpanelEvent(TEAM_MANAGEMENT_EVENT_PREFIX, eventName, params || {});
};

export const getRoleDisplayName = role => {
    const roleLabel = ROLES.find(r => r.id === role);
    return roleLabel ? roleLabel.label : role;
};

export const getUserRole = member => {
    if (member.role) return getRoleDisplayName(member.role.type);
    if (member.is_admin) return RoleTypes.ADMIN;
    if (member.is_restricted) return RoleTypes.RESTRICTED;

    return RoleTypes.STANDARD;
};

export const getUserDisplayName = (user, fieldMap = {}) => {
    const firstName = user.first_name || user[fieldMap.first_name];
    const lastName = user.last_name || user[fieldMap.last_name];
    const email = user.email || user[fieldMap.email];
    return firstName && lastName ? `${firstName} ${lastName}` : email;
};

const getStateFromUserDetailsOld = user => {
    let role = ROLE_STANDARD;
    if (user.is_admin) {
        role = ROLE_ORG_ADMIN;
    } else if (user.is_restricted) {
        role = ROLE_RESTRICTED;
    }
    return {
        roleSelectionForm: {
            role,
        },
        userRegistrationForm: {
            firstName: {
                value: user.first_name,
            },
            lastName: {
                value: user.last_name,
            },
            email: {
                value: user.email,
            },
            description: {
                value: '',
            },
        },
        editMemberId: user.id,
        selectedMetrics: {
            selectedValues: user.restrictions && user.restrictions.metric ? [...user.restrictions.metric.values] : [],
        },
        screenSelectionForm: {
            selectedScreens: [],
        },
        dimensionFilters: {
            app: user.restrictions && user.restrictions.app ? [...user.restrictions.app.values] : [],
            base_source: user.restrictions && user.restrictions.source ? [...user.restrictions.source.values] : [],
        },
    };
};

export const isOperatorToAddFuture = operator => operator === 'not in';

const deserializeRoleRowPermission = (rowPermission, allValues) => {
    // "Add future" works by negating the filter, and filtering out the non-selected values from the screen
    const addFuture = rowPermission && isOperatorToAddFuture(rowPermission.operator);
    return {
        values: !rowPermission ? [] : addFuture ? difference(allValues, rowPermission.values) : rowPermission.values,
        addFuture,
    };
};

// TODO: move this to selector - causes re-renders!
// This method is called when a specific user's permissions are edited
// and it's transforming the user's role/features as they're stored in the DB
// into the state the screen can work with
export const getStateFromUserDetails = (user, data) => {
    if (!user.role) {
        return getStateFromUserDetailsOld(user);
    }
    const { id, name, role } = user;
    const { type, description, row, column, features } = role;

    // Iterate data.permissionDimensions which is fed from the org's permission_dimensions setting
    // and fill values from the user's row-level permissions, if exist
    const dimensionFilters = {};
    Object.values(data.permissionDimensions).forEach(permissionDimension => {
        const rowPermission = row.find(rp => rp.dimension === permissionDimension.name);
        const allValues = permissionDimension.values.map(x => x.name);

        // If the user has restrictions on this dimension, we deserialize them
        if (rowPermission) {
            dimensionFilters[permissionDimension.name] = deserializeRoleRowPermission(rowPermission, allValues);
        }

        // If the user doesn't have restrictions on this dimension (happens when we add a dimension into the org's
        // permission_dimensions setting, after the user was created) - we grant access to all values
        else {
            dimensionFilters[permissionDimension.name] = { values: allValues, addFuture: true };
        }
    });

    const state = {
        roleSelectionForm: {
            role: type,
        },
        userRegistrationForm: {
            firstName: {
                value: user.first_name,
            },
            lastName: {
                value: user.last_name,
            },
            email: {
                value: user.email,
            },
            description: {
                value: description,
            },
        },
        agencySelectionForm: { id, name },
        editMemberId: id,
        selectedMetrics: deserializeRoleRowPermission(column, data.metrics),
        screenSelectionForm: {
            selectedScreens: features.access_new_features ? difference(data.screens, features.values) : features.values,
            addFuture: features.access_new_features,
        },
        appSelection: {},
        dimensionFilters,
    };

    return state;
};
