import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { WarningMessage } from 'components/widgets';

export const MessageTypes = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

class WizardWarningMessage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.Animated = posed.div({
            hidden: {
                maxHeight: 0,
                top: '-100%',
                opacity: 0,
                transition: {
                    delay: 0,
                },
            },
            visible: {
                maxHeight: 100,
                top: '0%',
                opacity: 1,
                transition: {
                    delay: props.delay,
                    duration: props.duration,
                },
            },
        });
        this.state = { ...props };
    }

    componentDidUpdate(prevProps) {
        const { message, show, type, textAlignCenter } = this.props;
        if (
            prevProps.message !== message ||
            prevProps.show !== show ||
            prevProps.type !== type ||
            prevProps.textAlignCenter !== textAlignCenter
        ) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.setState({ show: false });
                this.timeout = setTimeout(() => this.setState({ ...this.props }), 300);
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { showTypeIcon } = this.props;
        const {
            show,
            showIcon,
            message,
            type,
            className,
            textAlignCenter,
            buttonAction,
            buttonText,
            textReplace,
            containerStyle,
            animatedElStyle,
            textContainerClass,
        } = this.state;
        return (
            <this.Animated style={animatedElStyle} pose={show ? 'visible' : 'hidden'}>
                <WarningMessage
                    message={message}
                    textReplace={textReplace}
                    type={type}
                    className={className}
                    containerStyle={{
                        ...containerStyle,
                        padding: 7,
                        fontSize: 14,
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                    }}
                    showIcon={showIcon}
                    showTypeIcon={showTypeIcon || !textAlignCenter}
                    textAlignCenter={textAlignCenter}
                    buttonAction={buttonAction}
                    buttonText={buttonText}
                    textContainerClass={textContainerClass}
                />
            </this.Animated>
        );
    }
}

WizardWarningMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    show: PropTypes.bool,
    showIcon: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(MessageTypes)),
    delay: PropTypes.number,
    duration: PropTypes.number,
    className: PropTypes.string,
    textAlignCenter: PropTypes.bool,
    showTypeIcon: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonAction: PropTypes.func,
    animatedElStyle: PropTypes.objectOf(PropTypes.any),
    textContainerClass: PropTypes.string,
};

WizardWarningMessage.defaultProps = {
    message: '',
    show: false,
    showIcon: false,
    type: 'warning',
    delay: 0,
    duration: 0,
    className: '',
    textAlignCenter: false,
    showTypeIcon: false,
    buttonText: '',
    buttonAction: () => {},
    animatedElStyle: {},
    textContainerClass: '',
};

export default WizardWarningMessage;
