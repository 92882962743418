import { createSelector } from 'reselect';

const getSecretId = state => {
    return state.anonymous.secretId;
};
const getPassword = state => {
    return state.anonymous.password;
};
const getCreator = state => {
    return state.anonymous.creator;
};

const getReportName = state => {
    return state.anonymous.reportName;
};

const getError = state => {
    return state.anonymous.error;
};
const getShowPasswordModal = state => {
    return state.anonymous.showPasswordModal;
};
const getShowDateSelection = state => {
    return state.anonymous.showDateSelection;
};

export { getSecretId, getShowPasswordModal, getPassword, getShowDateSelection, getCreator, getReportName, getError };
