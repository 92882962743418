import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import useWizard from '../hook/useWizard';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.INTERNAL_DISTRIBUTION';

const InternalDistribution = ({ onSaveDraft, pageTitle, onNext, onBack }) => {
    const { values } = useFormikContext();
    const { shouldShowDraft } = useWizard(values);

    return (
        <WizardLayout
            title={pageTitle}
            shouldShowDraft={shouldShowDraft}
            onSaveDraft={onSaveDraft}
            buttons={
                <StepButtons
                    onBack={onBack}
                    onNext={onNext}
                    showSkip
                    backTitle="BUTTONS.BACK"
                    nextTitle="STATIC.BUTTONS.NEXT"
                />
            }
        >
            <div>
                <Translate id={`${translatePrefix}.SUBTITLE`} />
            </div>
        </WizardLayout>
    );
};

InternalDistribution.propTypes = {
    onSaveDraft: PropTypes.func,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    pageTitle: PropTypes.string,
};

export default InternalDistribution;
