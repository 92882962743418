import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const Destination = (props) => {
    const { platform, destination, css, link } = props;
    if (!destination) {
        return (
            <div className={css.FieldContainer} style={{ paddingLeft: '10px' }}>
                There are no destinations for this campaign
            </div>
        );
    }
    return (
        <div>
            <div className={css.FieldContainer}>
                <div className={css.FieldLabel}>{platform} App Store URL</div>
                <div className={`${css.FieldValue} ${css.TextBoxContainer}`}>
                    <input
                        className={css.TextBox}
                        type="text"
                        style={{ color: '#989898' }}
                        readOnly
                        disabled
                        value={destination.appstore || 'No App Store URL'}
                    />
                </div>
            </div>
            <div className={css.FieldContainer}>
                <div className={css.FieldLabel}>{platform} Deeplink URL</div>
                <div className={`${css.FieldValue} ${css.TextBoxContainer}`}>
                    <input
                        className={css.TextBox}
                        type="text"
                        style={{ color: '#989898' }}
                        readOnly
                        disabled
                        value={
                            link.singular_link
                                ? destination.deeplink || 'No Deeplink URL'
                                : destination.deferred_deeplink || destination.deeplink || 'No Deeplink URL'
                        }
                    />
                </div>
            </div>
            {link.singular_link ? (
                <div className={css.FieldContainer}>
                    <div className={css.FieldLabel}>{platform} Deferred Deeplink URL</div>
                    <div className={`${css.FieldValue} ${css.TextBoxContainer}`}>
                        <input
                            className={css.TextBox}
                            type="text"
                            style={{ color: '#989898' }}
                            readOnly
                            disabled
                            value={destination.deferred_deeplink || 'No Deferred Deeplink URL'}
                        />
                    </div>
                </div>
            ) : (
                <div className={css.FieldContainer} style={{ paddingTop: '0px' }}>
                    <div className={css.FieldLabel} />
                    <div className={`${css.FieldValue} ${css.DeferredToggleContainer}`}>
                        <span className={css.DeferredToggleWrapper}>
                            <Toggle
                                icons={false}
                                checked={destination.deferred_deeplink !== null}
                                className={
                                    destination.deferred_deeplink !== null ? css.ToggleChecked : css.ToggleUnchecked
                                }
                                disabled
                            />
                        </span>
                        <span className={`${css.DeferredToggleWrapper} ${css.DeferredTextWrapper}`}>Deferred</span>
                    </div>
                </div>
            )}
            {platform === 'iOS' && !link.singular_link ? (
                <div className={css.FieldContainer}>
                    <div className={css.FieldLabel}>{platform} Universal Link</div>
                    <div className={`${css.FieldValue} ${css.TextBoxContainer}`}>
                        <input
                            className={css.TextBox}
                            type="text"
                            style={{ color: '#989898' }}
                            readOnly
                            disabled
                            value={destination.universal_link || 'No iOS Universal Link'}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

Destination.propTypes = {
    css: PropTypes.objectOf(PropTypes.any),
    destination: PropTypes.objectOf(PropTypes.any),
    platform: PropTypes.string,
};

Destination.defaultProps = {
    css: {},
    destination: {},
    platform: '',
};

export default Destination;
