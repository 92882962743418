import moment from 'moment';
import { IsoDateFormat } from '../../../dashboard/js/common/utils';

/**
 * Calculate date diff in an object of time periods.
 * @param {Date} date1 - date1
 * @param {Date} date2 - date2
 * @returns {Object} the date diff object
 */
export const getDateDiffObject = (date1, date2) => {
    const diff = moment(date2).diff(
        moment(date1)
            .clone()
            .subtract(1, 'days')
    );
    const diffDuration = moment.duration(diff);

    return {
        days: diffDuration.asDays(),
        weeks: diffDuration.asWeeks(),
        months: diffDuration.asMonths(),
    };
};

export const diffHours = (dt1, dt2) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(diff);
};

export const calculateSecondPeriod = (startDateStr, endDateStr) => {
    const startDate = moment(startDateStr);
    const endDate = moment(endDateStr);
    const dateDiff = endDate.diff(startDate);
    const endDate2 = startDate.clone().subtract(1);
    const startDate2 = endDate2.clone().subtract(dateDiff);

    return { startDate2: startDate2.format(IsoDateFormat), endDate2: endDate2.format(IsoDateFormat) };
};
