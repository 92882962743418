import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import css from './OAuthLoginField.css';
import {
    AUTH_TYPES,
    NETWORKS_AUTH_URL_MAPPING,
    NO_WINDOW_OPENER_AUTH_TYPES,
    SPECIAL_OAUTH_NETWORKS,
} from '../../dataSources/utils';
import CheckedIcon from '../../resources/icons/check_mark.svg';
import DeleteIcon from '../../resources/svg/icon_delete_tag_red.svg';
import { FlatIconButton, GoogleSignInButton, Label, SingularButton } from '../widgets';
import DataSourcesService from '../../dataSources/service';

const dataSourcesService = new DataSourcesService();

function isInvalidMailruUsername(networkData, usernameValue) {
    return networkData.auth_type === AUTH_TYPES.mailru && !usernameValue;
}

const OAuthLoginField = forwardRef(({ networkData, isValidated, authPopupHandler, usernameValue, onError }, ref) => {
    const uanIdRef = useRef(networkData.uan_id);
    const authModal = useRef(window.self);

    async function getNetworkAuthUrl(url, adnId, uanId) {
        const state = window.btoa(JSON.stringify([adnId, uanId]));
        try {
            const response = await dataSourcesService.getNetworkAuthUrl(url, state);
            return response.value.url;
        } catch (e) {
            onError(e);
        }
        return '';
    }

    function getAuthTypeName(showAsDisplayName) {
        let authName = networkData.name;

        if (SPECIAL_OAUTH_NETWORKS.includes(networkData.auth_type)) {
            authName = networkData.auth_type;
        }

        return showAsDisplayName ? networkData.display_name : authName;
    }

    async function uanAuthClick() {
        const authPath = NETWORKS_AUTH_URL_MAPPING[getAuthTypeName(false)];
        const urlSuffix = networkData.auth_type === AUTH_TYPES.mailru ? usernameValue : '';

        const authUrl = await getNetworkAuthUrl(
            `${window.location.origin}${authPath}${urlSuffix}`,
            networkData.id || networkData.adn_id,
            uanIdRef.current || null
        );

        // open window
        if (NO_WINDOW_OPENER_AUTH_TYPES.includes(networkData.auth_type)) {
            authModal.current = window.open(authUrl, '_self');
        } else {
            authModal.current = window.open(authUrl, '_blank', 'status=1, height=500, width=1024');
        }
    }

    useImperativeHandle(ref, () => ({
        oauthClick() {
            uanAuthClick();
        },
    }));

    function isLoginError() {
        return networkData.is_login_error;
    }

    function isOauthCompleted() {
        return networkData.isSpecialOauthAuthorized ?? isValidated;
    }

    window.handleAuthPopupResponse = function(uan, errorMessage) {
        if (authModal) {
            const shouldCLose = authPopupHandler(uan, errorMessage, uanAuthClick);
            if (shouldCLose) {
                authModal.current.close();
            }
        }
    };

    return (
        <>
            {!isOauthCompleted() && (
                <div className={css.authButton}>
                    {networkData.auth_type.includes(AUTH_TYPES.google) ? (
                        <GoogleSignInButton onButtonClick={uanAuthClick} />
                    ) : (
                        <FlatIconButton
                            iconPath={networkData.logo_path}
                            text={`Sign in with ${getAuthTypeName(true)}`}
                            buttonStyle={{ margin: '0 auto' }}
                            onButtonClick={uanAuthClick}
                            disabled={isInvalidMailruUsername(networkData)}
                            tooltipTextKey={
                                isInvalidMailruUsername(networkData)
                                    ? 'STATIC.PAGES.DATA_SOURCES.OAUTH_REQUIRE_USERNAME'
                                    : ''
                            }
                        />
                    )}
                </div>
            )}
            {isOauthCompleted() && (
                <div className={css.authedContainer}>
                    <div className={css.authedUser}>
                        {!isLoginError() ? (
                            <CheckedIcon className={css.checkedIcon} />
                        ) : (
                            isLoginError() && <DeleteIcon className={css.checkedIcon} />
                        )}
                        <Label text="STATIC.PAGES.DATA_SOURCES.OAUTH_CONNECTED_USER" className={css.connectedUser} />
                        <Label text={usernameValue} className={css.connectedName} />
                    </div>
                    <SingularButton
                        level="level1"
                        text="STATIC.PAGES.DATA_SOURCES.REAUTHORIZE"
                        type="secondary"
                        onClick={uanAuthClick}
                    />
                </div>
            )}
        </>
    );
    // Connected user:&nbsp;<b>{usernameValue}</b>
});

OAuthLoginField.propTypes = {
    networkData: PropTypes.shape({
        id: PropTypes.number,
        uan_id: PropTypes.number,
        adn_id: PropTypes.number,
        auth_type: PropTypes.string,
        display_name: PropTypes.string,
        name: PropTypes.string,
        is_login_error: PropTypes.bool,
        logo_path: PropTypes.string,
        isSpecialOauthAuthorized: PropTypes.bool,
    }),
    isValidated: PropTypes.bool,
    authPopupHandler: PropTypes.func,
    usernameValue: PropTypes.string,
    onError: PropTypes.func,
};

OAuthLoginField.defaultProps = {
    networkData: {},
    isValidated: false,
    authPopupHandler: () => {},
    usernameValue: '',
    onError: () => {},
};

export default OAuthLoginField;
