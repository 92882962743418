const getCurrentPath = state => {
    let path = state.routing.locationBeforeTransitions;

    if (path) {
        return path;
    }

    path = window.location.hash.split('#')[1].split('?');
    const pathname = path[0];
    const search = path.length > 1 ? path[1] : '';

    return { search, pathname };
};

export default getCurrentPath;
