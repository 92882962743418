import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { withLocalize } from 'react-localize-redux';
import Lottie from 'lottie-react';
import { PageHeader } from '../../../components/partials';
import Datepicker from '../../../components/widgets/Datepicker';
import css from './TagsVisibilityHeader.css';
import { CreativeFilters } from '../../creativesFiltersUtils';
import AutoCompleteField, { AutoCompleteOptionType } from '../../../components/widgets/AutoCompleteField';
import DownloadCsvIcon from '../../../resources/icons/download.svg';
import downloadFileAnimation from '../../../resources/animations/download_file.json';
import { METRICS_DROPDOWN_TAGS_LIMIT } from '../../consts';

const SUPPORT_LINK =
    'https://support.singular.net/hc/en-us/articles/33037500937243-Creative-Gallery-FAQ&utm_source=singular';
export const AUTO_COMPLETE_APP_TEST_ID = 'auto_complete_app';
export const AUTO_COMPLETE_SOURCE_TEST_ID = 'auto_complete_source';
export const AUTO_COMPLETE_METRICS_TEST_ID = 'auto_complete_metrics';
export const EXPORT_CSV_RUN_QUERY_MESSAGE = 'STATIC.PAGES.TAGS_VISIBILITY.EXPORT_CSV_TOOLTIP.RUN_QUERY_MESSAGE';
export const EXPORT_CSV_NO_DATA_MESSAGE = 'STATIC.PAGES.TAGS_VISIBILITY.EXPORT_CSV_TOOLTIP.NO_DATA_MESSAGE';
export const EXPORT_CSV_ICON_TITLE = 'ExportCsv';

const HeaderAutoComplete = memo(({ onChange, isMulti, ...props }) => (
    <AutoCompleteField
        innerContainerClass={css.input}
        searchable
        isMulti={isMulti}
        clearable
        onChange={onChange}
        {...props}
    />
));

HeaderAutoComplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
};

HeaderAutoComplete.defaultProps = {
    isMulti: false,
};

const TagsVisibilityHeader = ({
    apps,
    sources,
    metrics,
    startDate,
    endDate,
    app,
    source,
    isLoadingApps,
    isLoadingSources,
    onFilterChange,
    selectedMetrics,
    hasQueryBeenDone,
    creativesAmount,
    onCsvDownloadClicked,
    translate,
    isLoadingCsvExport,
}) => {
    const disableCsvDownload = !hasQueryBeenDone || !creativesAmount;

    const options = {
        animationData: downloadFileAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        style: { width: '45px', height: '45px' },
    };

    const selectedAppOption = app && apps?.find(option => option.value === app);
    const selectedSourceOption = source && sources?.find(option => option.value === source);

    return (
        <PageHeader
            text="STATIC.PAGES.TAGS_VISIBILITY.PAGE_NAME"
            sticky
            stickyShadow
            shouldAddPadding
            className={css.headerWrapper}
            subText={{
                faqLink: SUPPORT_LINK,
            }}
        >
            <div className={css.content}>
                <div className={css.filters}>
                    <Datepicker
                        buttonContainerClass={css.input}
                        startDate={startDate}
                        endDate={endDate}
                        onApply={data => {
                            onFilterChange(CreativeFilters.DATE_RANGE, {
                                startDate: data.start_date,
                                endDate: data.end_date,
                            });
                        }}
                    />
                    <HeaderAutoComplete
                        options={apps}
                        value={selectedAppOption}
                        placeholder="STATIC.PLACEHOLDERS.SELECT_APP"
                        dataTestId={AUTO_COMPLETE_APP_TEST_ID}
                        loading={isLoadingApps}
                        disabled={!!isLoadingApps}
                        controlled
                        onChange={selected => {
                            onFilterChange(CreativeFilters.APP, selected?.value);
                        }}
                    />
                    <HeaderAutoComplete
                        options={sources}
                        value={selectedSourceOption}
                        placeholder="STATIC.PLACEHOLDERS.SELECT_SOURCE"
                        dataTestId={AUTO_COMPLETE_SOURCE_TEST_ID}
                        loading={isLoadingSources}
                        disabled={!!isLoadingSources}
                        controlled
                        onChange={selected => {
                            onFilterChange(CreativeFilters.SOURCE, selected?.value);
                        }}
                    />
                    <HeaderAutoComplete
                        options={metrics}
                        value={selectedMetrics}
                        label="STATIC.METRICS"
                        dataTestId={AUTO_COMPLETE_METRICS_TEST_ID}
                        onChange={selected => {
                            onFilterChange(CreativeFilters.METRICS, selected);
                        }}
                        containerClass={css.input}
                        isMulti
                        useMaterialUI
                        limitTags={METRICS_DROPDOWN_TAGS_LIMIT}
                    />
                </div>
                <Tooltip
                    classes={{
                        tooltip: css.tooltip,
                    }}
                    title={
                        disableCsvDownload &&
                        translate(
                            hasQueryBeenDone === false ? EXPORT_CSV_RUN_QUERY_MESSAGE : EXPORT_CSV_NO_DATA_MESSAGE
                        )
                    }
                >
                    <div className={css.downloadCsvContainer}>
                        {isLoadingCsvExport ? (
                            <Lottie {...options} />
                        ) : (
                            <DownloadCsvIcon
                                title={EXPORT_CSV_ICON_TITLE}
                                onClick={!disableCsvDownload ? onCsvDownloadClicked : () => {}}
                                className={classnames(css.downloadCsvIcon, { [css.disabled]: disableCsvDownload })}
                            />
                        )}
                    </div>
                </Tooltip>
            </div>
        </PageHeader>
    );
};

TagsVisibilityHeader.propTypes = {
    apps: PropTypes.arrayOf(AutoCompleteOptionType),
    sources: PropTypes.arrayOf(AutoCompleteOptionType),
    metrics: PropTypes.arrayOf(AutoCompleteOptionType),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    app: PropTypes.string,
    source: PropTypes.string,
    selectedMetrics: PropTypes.arrayOf(AutoCompleteOptionType),
    isLoadingApps: PropTypes.bool,
    isLoadingSources: PropTypes.bool,
    onFilterChange: PropTypes.func,
    onCsvDownloadClicked: PropTypes.func,
    creativesAmount: PropTypes.number,
    hasQueryBeenDone: PropTypes.bool,
    translate: PropTypes.func,
    isLoadingCsvExport: PropTypes.bool,
};

TagsVisibilityHeader.defaultProps = {
    startDate: '',
    endDate: '',
    apps: [],
    sources: [],
    creativesAmount: 0,
    hasQueryBeenDone: false,
    isLoadingCsvExport: false,
};

export default withLocalize(TagsVisibilityHeader);
