export const UPDATE_FAVOURITE_APPS_FOR_USER = 'action.attribution.apps.update.favorite.apps.for_user';
export const UPDATE_ATTRIBUTION_APPS_DATA = 'action.attribution.apps.update.data';
export const UPDATE_APPS_DATA = 'action.apps.update.data';

export const updateAttributionAppsData = data => {
    return {
        type: UPDATE_ATTRIBUTION_APPS_DATA,
        data,
    };
};

export const updateAppsData = data => {
    return {
        type: UPDATE_APPS_DATA,
        data,
    };
};

export const updateFavouriteAppsForUser = apps => {
    return {
        type: UPDATE_FAVOURITE_APPS_FOR_USER,
        apps,
    };
};
