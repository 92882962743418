import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import css from '../PartnerConfigurationShelf.css';
import { Slider, WarningMessage } from '../../../../components/widgets';
import { organizationOptionsShape, partnerShape, siteShape, SupportTypes, TemplateTypes } from '../../types';
import { GET_PARTNER_APP_DEFAULT_WINDOWS } from '../../../queries';
import { getPostbackSupported, getTemplate } from '../utils';
import InactivityWindow, { MAX_REENG_INACTIVITY_WINDOW } from './InactivityWindow';
import CollapsableRow from './CollapsableRow';

export const INSTALL_WIN_DATA_TEST_ID = 'install-windows-sliders';
export const REENG_WIN_DATA_TEST_ID = 'reeng-windows-sliders';
export const CTV_WIN_DATA_TEST_ID = 'ctv-windows-sliders';

export const MIN_CLICK_WINDOW_DETERMINISTIC = 0; // Days
export const MAX_CLICK_WINDOW_DETERMINISTIC = 30; // Days
export const MIN_CLICK_WINDOW_PROBABILISTIC = 0;
export const MAX_CLICK_WINDOW_PROBABILISTIC = 168;

export const MIN_VIEW_WINDOW_DETERMINISTIC = 0;
export const MAX_VIEW_WINDOW_DETERMINISTIC = 24;
export const MIN_VIEW_WINDOW_PROBABILISTIC = 0;
export const MAX_VIEW_WINDOW_PROBABILISTIC = 24;

export const MIN_CTV_VIEW_WINDOW = 0;
export const MIN_CTV_PRIORITIZATION_WINDOW = 0;
export const MAX_CTV_VIEW_WINDOW = 72;
export const MAX_CTV_PRIORITIZATION_WINDOW = 24;

const MAX_WINDOWS = {
    installClickWindowDeterministic: MAX_CLICK_WINDOW_DETERMINISTIC,
    installClickWindowProbabilistic: MAX_CLICK_WINDOW_PROBABILISTIC,
    installViewWindowDeterministic: MAX_VIEW_WINDOW_DETERMINISTIC,
    installViewWindowProbabilistic: MAX_VIEW_WINDOW_PROBABILISTIC,
    reengClickWindowDeterministic: MAX_CLICK_WINDOW_DETERMINISTIC,
    reengClickWindowProbabilistic: MAX_CLICK_WINDOW_PROBABILISTIC,
    reengViewWindowDeterministic: MAX_VIEW_WINDOW_DETERMINISTIC,
    reengViewWindowProbabilistic: MAX_VIEW_WINDOW_PROBABILISTIC,
    reengInactivityWindow: MAX_REENG_INACTIVITY_WINDOW,
    ctvViewWindow: MAX_CTV_VIEW_WINDOW,
    ctvPrioritizationWindow: MAX_CTV_PRIORITIZATION_WINDOW,
};

export const DEFAULT_CLICK_WINDOW_DETERMINISTIC = 7; // Days
export const DEFAULT_INSTALL_CLICK_WINDOW_PROBABILISTIC = 24;
export const DEFAULT_REENG_CLICK_WINDOW_PROBABILISTIC = 0;
export const DEFAULT_INSTALL_VIEW_WINDOW_DETERMINISTIC = 24;
export const DEFAULT_REENG_VIEW_WINDOW_DETERMINISTIC = 0;
export const DEFAULT_INSTALL_VIEW_WINDOW_PROBABILISTIC = 8;
export const DEFAULT_REENG_VIEW_WINDOW_PROBABILISTIC = 0;
export const DEFAULT_CTV_VIEW_WINDOW = 24;
export const DEFAULT_CTV_PRIORITIZATION_WINDOW = 0;
export const DEFAULT_REENG_INACTIVITY_WINDOW = 7;

const DEFAULT_WINDOWS = {
    installClickWindowDeterministic: DEFAULT_CLICK_WINDOW_DETERMINISTIC,
    installClickWindowProbabilistic: DEFAULT_INSTALL_CLICK_WINDOW_PROBABILISTIC,
    installViewWindowDeterministic: DEFAULT_INSTALL_VIEW_WINDOW_DETERMINISTIC,
    installViewWindowProbabilistic: DEFAULT_INSTALL_VIEW_WINDOW_PROBABILISTIC,
    reengClickWindowDeterministic: DEFAULT_CLICK_WINDOW_DETERMINISTIC,
    reengClickWindowProbabilistic: DEFAULT_REENG_CLICK_WINDOW_PROBABILISTIC,
    reengViewWindowDeterministic: DEFAULT_REENG_VIEW_WINDOW_DETERMINISTIC,
    reengViewWindowProbabilistic: DEFAULT_REENG_VIEW_WINDOW_PROBABILISTIC,
    reengInactivityWindow: DEFAULT_REENG_INACTIVITY_WINDOW,
    ctvViewWindow: DEFAULT_CTV_VIEW_WINDOW,
    ctvPrioritizationWindow: DEFAULT_CTV_PRIORITIZATION_WINDOW,
};

const WINDOWS_STEPS = {
    installClickWindowDeterministic: 'days',
    installClickWindowProbabilistic: 'hrs',
    installViewWindowDeterministic: 'hrs',
    installViewWindowProbabilistic: 'hrs',
    reengClickWindowDeterministic: 'days',
    reengClickWindowProbabilistic: 'hrs',
    reengViewWindowDeterministic: 'hrs',
    reengViewWindowProbabilistic: 'hrs',
    ctvViewWindow: 'hrs',
    ctvPrioritizationWindow: 'hrs',
};

const ATTRIBUTION_TYPE_WINDOWS = {
    install: [
        'installClickWindowDeterministic',
        'installViewWindowDeterministic',
        'installClickWindowProbabilistic',
        'installViewWindowProbabilistic',
    ],
    reeng: [
        'reengClickWindowDeterministic',
        'reengViewWindowDeterministic',
        'reengClickWindowProbabilistic',
        'reengViewWindowProbabilistic',
        'reengInactivityWindow',
    ],
};

export const getTranslation = key => `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.${key}`;

function AttributionWindows({
    partner,
    site,
    isReadonly,
    hiddenSliders,
    labels,
    tooltips,
    useSubTitle,
    showNonDefaultWarning,
    orgOptions,
}) {
    const { values, setFieldValue } = useFormikContext();
    const { windows } = values;
    const [isInstallExpanded, setIsInstallExpanded] = useState(false);
    const [isReengExpanded, setIsReengExpanded] = useState(false);
    const [isCtvExpanded, setIsCtvExpanded] = useState(false);
    const partnerAppDefaultWindowsResponse = useQuery(GET_PARTNER_APP_DEFAULT_WINDOWS, {
        variables: { partnerId: partner.id, longname: site.longname, platform: site.platform },
    });

    const installTemplate = getTemplate(partner, site.platform, TemplateTypes.INSTALL);
    const partnerTemplateDefaultClickWindow = installTemplate?.clickWindow;
    const reengSupported = getPostbackSupported(installTemplate, partner.reengagementSupport) !== SupportTypes.NEVER;
    const viewSupported = partner.viewthroughSupport !== SupportTypes.NEVER;
    const ctvSupported = partner.ipProbabilisticSupport !== SupportTypes.NEVER;

    const partnerDefaultWindows = {
        ...DEFAULT_WINDOWS,
        installClickWindowDeterministic:
            partnerTemplateDefaultClickWindow || DEFAULT_WINDOWS.installClickWindowDeterministic,
        reengClickWindowDeterministic:
            partnerTemplateDefaultClickWindow || DEFAULT_WINDOWS.reengClickWindowDeterministic,
    };

    const getMaxWindowValue = (initWindows, winField) => {
        return initWindows[winField] > MAX_WINDOWS[winField] ? MAX_WINDOWS[winField] : initWindows[winField];
    };

    const setInitWindows = () => {
        const {
            installClickWindowId,
            installClickWindowIp,
            installViewWindowId,
            installViewWindowIp,
            reengagementClickWindowId,
            reengagementClickWindowIp,
            reengagementViewWindowId,
            reengagementViewWindowIp,
            reengagementInactivityWindow,
            ctvViewWindow,
            ctvPrioritizationWindow,
        } = partnerAppDefaultWindowsResponse.data?.partnerAppDefaultWindows || {};

        let initWindows = {
            installClickWindowDeterministic: installClickWindowId
                ? installClickWindowId / 24
                : partnerDefaultWindows.installClickWindowDeterministic,
            installClickWindowProbabilistic: installClickWindowIp,
            installViewWindowDeterministic: viewSupported ? installViewWindowId : 0,
            installViewWindowProbabilistic: viewSupported ? installViewWindowIp : 0,
            reengClickWindowDeterministic: 0,
            reengClickWindowProbabilistic: reengSupported ? reengagementClickWindowIp : 0,
            reengViewWindowDeterministic: reengSupported && viewSupported ? reengagementViewWindowId : 0,
            reengViewWindowProbabilistic: reengSupported && viewSupported ? reengagementViewWindowIp : 0,
            reengInactivityWindow: Math.ceil(site.app_inactivity_window / 24),
            ctvViewWindow: ctvSupported ? ctvViewWindow : 0,
            ctvPrioritizationWindow: ctvSupported ? ctvPrioritizationWindow : 0,
        };

        if (reengSupported) {
            initWindows = {
                ...initWindows,
                reengClickWindowDeterministic: reengagementClickWindowId
                    ? reengagementClickWindowId / 24
                    : partnerDefaultWindows.reengClickWindowDeterministic,
                reengInactivityWindow:
                    reengagementInactivityWindow || reengagementInactivityWindow === 0
                        ? reengagementInactivityWindow / 24
                        : Math.ceil(site.app_inactivity_window / 24) || DEFAULT_REENG_INACTIVITY_WINDOW,
            };
        }

        // if some val above the max, set it to the max
        Object.keys(ATTRIBUTION_TYPE_WINDOWS).forEach(attributionType => {
            ATTRIBUTION_TYPE_WINDOWS[attributionType].forEach(winField => {
                initWindows[winField] = getMaxWindowValue(initWindows, winField);
            });
        });
        initWindows.ctvViewWindow = getMaxWindowValue(initWindows, 'ctvViewWindow');
        initWindows.ctvPrioritizationWindow = getMaxWindowValue(initWindows, 'ctvPrioritizationWindow');

        setFieldValue('windows', initWindows);
    };

    useEffect(() => {
        if (partnerAppDefaultWindowsResponse.loading || windows) return;

        setInitWindows();
    }, [partnerAppDefaultWindowsResponse.data, partnerAppDefaultWindowsResponse.loading, setInitWindows]);

    const nonDefaultWarning = shouldShow =>
        shouldShow && (
            <div className={css.title}>
                <WarningMessage
                    showIcon={false}
                    type="warning"
                    message={getTranslation('ATTRIBUTION_WINDOWS_DEFAULT_WARNING')}
                    textReplace={{ partnerName: partner.displayName }}
                />
            </div>
        );

    const shouldShowNonDefaultWarning = attributionType => {
        // check if one of the sliders is not on the default values
        return (
            showNonDefaultWarning &&
            !ATTRIBUTION_TYPE_WINDOWS[attributionType].every(winField => {
                return windows?.[winField] || windows?.[winField] === 0
                    ? partnerDefaultWindows[winField] === windows[winField]
                    : true;
            })
        );
    };

    const getWindowValue = winField => {
        return windows?.[winField] == null ? partnerDefaultWindows[winField] : windows[winField];
    };

    const sliderProps = {
        className: css.slider,
        sliderContainerClassName: css.sliderContainer,
        labelClassName: css.sliderLabel,
        labelTextClassName: css.sliderLabelText,
        tooltipClassName: useSubTitle ? css.sliderTooltipUseSubTitle : css.sliderTooltip,
        marginBottom: 20,
        marginTop: 20,
        showValue: false,
    };
    const stepsOverride = { 0: '0 (Off)' };

    const getLabel = (key, customKey = '') => {
        return customKey ? getTranslation(customKey) : getTranslation(useSubTitle ? `${key}_WITH_SUBTITLE` : key);
    };

    const getLabelData = winField => {
        return useSubTitle
            ? {
                  defaultWin: partnerDefaultWindows[winField],
                  steps: WINDOWS_STEPS[winField],
                  partnerName: partner.displayName,
              }
            : {};
    };

    const getClickWindowSliders = attributionType => {
        return (
            <>
                <Slider
                    {...sliderProps}
                    min={MIN_CLICK_WINDOW_DETERMINISTIC}
                    max={MAX_CLICK_WINDOW_DETERMINISTIC}
                    extraSteps={[7, 14, 21]}
                    stepsOverride={{
                        ...stepsOverride,
                        [MAX_CLICK_WINDOW_DETERMINISTIC]: `${MAX_CLICK_WINDOW_DETERMINISTIC} days`,
                    }}
                    value={getWindowValue(`${attributionType}ClickWindowDeterministic`)}
                    onChange={value => setFieldValue(`windows.${attributionType}ClickWindowDeterministic`, value)}
                    label={getLabel('CLICK_THROUGH_DETERMINISTIC_WINDOW')}
                    labelData={getLabelData(`${attributionType}ClickWindowDeterministic`)}
                    labelTooltip={getTranslation('CLICK_THROUGH_DETERMINISTIC_WINDOW_TOOLTIP')}
                    color="#3088f4"
                    disabled={isReadonly}
                />
                {!hiddenSliders?.[`${attributionType}ClickWindowProbabilistic`] && (
                    <Slider
                        {...sliderProps}
                        min={MIN_CLICK_WINDOW_PROBABILISTIC}
                        max={MAX_CLICK_WINDOW_PROBABILISTIC}
                        extraSteps={[12, 24, 48, 72, 96, 120, 144]}
                        stepsOverride={{
                            ...stepsOverride,
                            [MAX_CLICK_WINDOW_PROBABILISTIC]: `${MAX_CLICK_WINDOW_PROBABILISTIC} hrs`,
                        }}
                        value={getWindowValue(`${attributionType}ClickWindowProbabilistic`)}
                        onChange={value => setFieldValue(`windows.${attributionType}ClickWindowProbabilistic`, value)}
                        label={getLabel(
                            'CLICK_THROUGH_PROBABILISTIC_WINDOW',
                            labels?.[`${attributionType}ClickWindowProbabilistic`]
                        )}
                        labelData={getLabelData(`${attributionType}ClickWindowProbabilistic`)}
                        labelTooltip={getTranslation(
                            tooltips?.[`${attributionType}ClickWindowProbabilistic`] ||
                                'CLICK_THROUGH_PROBABILISTIC_WINDOW_TOOLTIP'
                        )}
                        color="#3088f4"
                        disabled={isReadonly}
                    />
                )}
            </>
        );
    };

    const getViewWindowSliders = attributionType => {
        return (
            <>
                {!hiddenSliders?.[`${attributionType}ViewWindowDeterministic`] && (
                    <Slider
                        {...sliderProps}
                        min={MIN_VIEW_WINDOW_DETERMINISTIC}
                        max={MAX_VIEW_WINDOW_DETERMINISTIC}
                        extraSteps={[4, 8, 12, 16, 20]}
                        stepsOverride={{
                            ...stepsOverride,
                            [MAX_VIEW_WINDOW_DETERMINISTIC]: `${MAX_VIEW_WINDOW_DETERMINISTIC} hrs`,
                        }}
                        value={getWindowValue(`${attributionType}ViewWindowDeterministic`)}
                        onChange={value => setFieldValue(`windows.${attributionType}ViewWindowDeterministic`, value)}
                        label={getLabel('VIEW_THROUGH_DETERMINISTIC_WINDOW')}
                        labelData={getLabelData(`${attributionType}ViewWindowDeterministic`)}
                        labelTooltip={getTranslation('VIEW_THROUGH_DETERMINISTIC_WINDOW_TOOLTIP')}
                        color="#00D0B2"
                        disabled={isReadonly}
                    />
                )}
                {!hiddenSliders?.[`${attributionType}ViewWindowProbabilistic`] && (
                    <Slider
                        {...sliderProps}
                        min={MIN_VIEW_WINDOW_PROBABILISTIC}
                        max={MAX_VIEW_WINDOW_PROBABILISTIC}
                        extraSteps={[4, 8, 12, 16, 20]}
                        stepsOverride={{
                            ...stepsOverride,
                            [MAX_VIEW_WINDOW_PROBABILISTIC]: `${MAX_VIEW_WINDOW_PROBABILISTIC} hrs`,
                        }}
                        value={getWindowValue(`${attributionType}ViewWindowProbabilistic`)}
                        onChange={value => setFieldValue(`windows.${attributionType}ViewWindowProbabilistic`, value)}
                        label={getLabel('VIEW_THROUGH_PROBABILISTIC_WINDOW')}
                        labelData={getLabelData(`${attributionType}ViewWindowProbabilistic`)}
                        labelTooltip={getTranslation('VIEW_THROUGH_PROBABILISTIC_WINDOW_TOOLTIP')}
                        color="#00D0B2"
                        disabled={isReadonly}
                    />
                )}
            </>
        );
    };

    return (
        <div className={css.section}>
            <div className={css.title}>
                <Translate id={getTranslation('ATTRIBUTION_WINDOWS')} />
            </div>
            <div className={css.subsection} data-testid={INSTALL_WIN_DATA_TEST_ID}>
                <CollapsableRow
                    onClick={() => setIsInstallExpanded(!isInstallExpanded)}
                    expanded={isInstallExpanded}
                    disabled={isReadonly}
                    title={<Translate id={getTranslation('INSTALLS')} />}
                >
                    {nonDefaultWarning(shouldShowNonDefaultWarning('install'))}
                    {getClickWindowSliders('install')}
                    {viewSupported && getViewWindowSliders('install')}
                </CollapsableRow>
            </div>
            {reengSupported && (
                <div className={css.subsection} data-testid={REENG_WIN_DATA_TEST_ID}>
                    <CollapsableRow
                        onClick={() => setIsReengExpanded(!isReengExpanded)}
                        expanded={isReengExpanded}
                        disabled={isReadonly}
                        title={<Translate id={getTranslation('REENGAGEMENTS')} />}
                    >
                        {nonDefaultWarning(shouldShowNonDefaultWarning('reeng'))}
                        {getClickWindowSliders('reeng')}
                        {viewSupported && getViewWindowSliders('reeng')}
                        <InactivityWindow
                            partner={partner}
                            isMinZero={!!orgOptions?.zeroInactivityWindowSupported}
                            value={getWindowValue('reengInactivityWindow')}
                            sliderProps={sliderProps}
                            site={site}
                            setFieldValue={setFieldValue}
                            isReadonly={isReadonly}
                        />
                    </CollapsableRow>
                </div>
            )}
            {ctvSupported && (
                <div className={css.subsection} data-testid={CTV_WIN_DATA_TEST_ID}>
                    <CollapsableRow
                        onClick={() => setIsCtvExpanded(!isCtvExpanded)}
                        disabled={isReadonly}
                        expanded={isCtvExpanded}
                        title={<Translate id={getTranslation('CTV_SETTINGS')} />}
                    >
                        <Slider
                            {...sliderProps}
                            min={MIN_CTV_VIEW_WINDOW}
                            max={MAX_CTV_VIEW_WINDOW}
                            extraSteps={[24, 48]}
                            stepsOverride={{
                                ...stepsOverride,
                                [MAX_CTV_VIEW_WINDOW]: `${MAX_CTV_VIEW_WINDOW} hrs`,
                            }}
                            value={getWindowValue('ctvViewWindow')}
                            onChange={value => setFieldValue(`windows.ctvViewWindow`, value)}
                            label={getLabel('CTV_VIEW_WINDOW')}
                            labelData={getLabelData('ctvViewWindow')}
                            labelTooltip={getTranslation('CTV_VIEW_WINDOW_TOOLTIP')}
                            color="#00D0B2"
                            disabled={isReadonly}
                        />
                        <Slider
                            {...sliderProps}
                            min={MIN_CTV_PRIORITIZATION_WINDOW}
                            max={MAX_CTV_PRIORITIZATION_WINDOW}
                            extraSteps={[4, 8, 12, 16, 20]}
                            stepsOverride={{
                                ...stepsOverride,
                                [MAX_CTV_PRIORITIZATION_WINDOW]: `${MAX_CTV_PRIORITIZATION_WINDOW} hrs`,
                            }}
                            value={getWindowValue('ctvPrioritizationWindow')}
                            onChange={value => setFieldValue(`windows.ctvPrioritizationWindow`, value)}
                            label={getLabel('CTV_PRIORITIZATION_WINDOW')}
                            labelData={getLabelData('ctvPrioritizationWindow')}
                            labelTooltip={getTranslation('CTV_PRIORITIZATION_WINDOW_TOOLTIP')}
                            color="#b26af1"
                            disabled={isReadonly}
                        />
                    </CollapsableRow>
                </div>
            )}
        </div>
    );
}

AttributionWindows.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    isReadonly: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    hiddenSliders: PropTypes.shape,
    labels: PropTypes.shape,
    tooltips: PropTypes.shape,
    useSubTitle: PropTypes.bool,
    showNonDefaultWarning: PropTypes.bool,
    orgOptions: organizationOptionsShape.isRequired,
};

AttributionWindows.defaultProps = {
    isReadonly: false,
    hiddenSliders: {},
    labels: {},
    tooltips: {},
    useSubTitle: false,
    showNonDefaultWarning: false,
};

export default withLocalize(AttributionWindows);
