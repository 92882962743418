import React from 'react';

export default function Bookmarks() {
    return (
        <div>
            <p>
                <strong>What: </strong><span> Save a report query to your bookmarks.&nbsp;</span>
            </p>
            <p>
                <strong>Why: </strong><span> This lets you run the same report again without having to define it from scratch. You can also share bookmarked reports with other team members – making sure you are all looking at the same data.</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/218104323-Bookmarking-a-Report" target="_blank">Learn more about Bookmarking a Report</a><span>.</span>
            </p>
        </div>
    );
}
