import { Translate } from 'react-localize-redux';
import React from 'react';
import { utc } from 'moment';
import {
    COARSE_VALUES_AMOUNT,
    COARSE_VALUES_TRANSLATE,
    POSTBACK_CONVERSION_VALUE_TYPE_FINE,
    POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
    EventsModelTypes,
    FunnelTypes,
    ModelDisplayNames,
    ModelKinds,
    RevenueModelTypes,
    RevenueTypeDisplayNames,
} from '../consts';
import Infinity from '../../resources/svg/infinity.svg';
import { resolveModelTypeDisplayName } from '../utils';

const REVENUE_TRANSLATION_MAP = {
    buckets: 'BUCKETS_LABEL',
    unit: 'UNIT_LABEL',
    event_filter: 'EVENT_FILTER_LABEL',
    product_type_filter: 'PRODUCT_TYPE_FILTER_LABEL',
};

const STATIC_TRANSLATION_MAP = {
    [RevenueModelTypes.ADMON]: REVENUE_TRANSLATION_MAP,
    [RevenueModelTypes.ALL]: REVENUE_TRANSLATION_MAP,
    [RevenueModelTypes.IAP]: REVENUE_TRANSLATION_MAP,
};

const STATIC_FIELDS = {
    [ModelKinds.CUSTOM_FUNNEL]: {
        configuration: <Translate id="STATIC.PAGES.SKADNETWORK.CUSTOM_CONFIGURATION_SEE_PREVIEW_LABEL" />,
    },
};

const getSlotTranslateLabel = index => (
    <Translate id="STATIC.PAGES.SKADNETWORK.SLOT_NUMBER_LABEL" data={{ i: index }} />
);

const getEventsTranslationMap = conversionModel => {
    return conversionModel.modelEvents.map((modelEvent, i) => {
        const translateLable = FunnelTypes.includes(conversionModel.type) ? (
            getSlotTranslateLabel(i + 1)
        ) : (
            <Translate id="STATIC.PAGES.SKADNETWORK.EVENT_NUMBER_LABEL" data={{ i: i + 1 }} />
        );
        return [translateLable, modelEvent?.name];
    });
};

const getRevenueTranslationMap = (revenueBuckets, labelByIndex, currency) => {
    if (!revenueBuckets) return [];

    return revenueBuckets.map((bucketStart, index) => {
        const revenueTranslationMap = [labelByIndex[index]];
        if (!revenueBuckets[index + 1]) {
            revenueTranslationMap.push(
                <div style={{ display: 'inline' }}>
                    {`${bucketStart} - `}
                    <Infinity />
                    {` ${currency}`}
                </div>
            );
        } else {
            revenueTranslationMap.push(`${bucketStart} - ${revenueBuckets[index + 1]} ${currency}`);
        }
        return revenueTranslationMap;
    });
};

export const getConversionModelTranslationMap = (conversionModel, currency, modelType) => {
    const { type, revenueBuckets, postbackConversionValueType, modelEvents } = conversionModel;
    const currentType = modelType || type;
    let translationMap =
        postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_COARSE
            ? []
            : STATIC_TRANSLATION_MAP[currentType];
    const isEventsModel = Object.values(EventsModelTypes).includes(currentType);

    if (!translationMap && isEventsModel) {
        translationMap = getEventsTranslationMap(conversionModel);
    }

    if (postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_COARSE) {
        if (modelEvents?.length) {
            translationMap = modelEvents
                .filter(event => !!event)
                .map((modelEvent, index) => {
                    return [COARSE_VALUES_TRANSLATE[index], modelEvent.name];
                });
        }

        if (translationMap.length < COARSE_VALUES_AMOUNT) {
            const remainedCoarseValuesToMap = [...Array(COARSE_VALUES_AMOUNT - translationMap.length)].map(
                (_, index) => {
                    return COARSE_VALUES_TRANSLATE[translationMap.length + index];
                }
            );

            const revenueMap = getRevenueTranslationMap(revenueBuckets, remainedCoarseValuesToMap, currency);

            translationMap = translationMap.concat(revenueMap);
        }
    } else if (type !== ModelKinds.MIXED && FunnelTypes.includes(currentType) && revenueBuckets) {
        const revenueLabels = [...Array(revenueBuckets.length)].map((_, i) =>
            getSlotTranslateLabel(i + translationMap.length + 1)
        );
        translationMap = translationMap.concat(getRevenueTranslationMap(revenueBuckets, revenueLabels, currency));
    }

    return translationMap;
};

export const getConversionModelFields = (conversionModel, currency, modelType) => {
    const currentType = modelType || conversionModel.type;
    let contentFields = STATIC_FIELDS[currentType];
    if (!contentFields && Object.values(RevenueModelTypes).includes(currentType)) {
        const isRevenueCustom = !conversionModel.revenueBucketSize;
        contentFields = {
            buckets: conversionModel.revenueBuckets.length + 1,
            unit: isRevenueCustom ? (
                <Translate id="STATIC.PAGES.SKADNETWORK.FLEXIBLE_REVENUE_UNITS" />
            ) : (
                `${currency}${conversionModel.revenueBucketSize}`
            ),
        };
    }
    return contentFields;
};

const getGroupConversionModels = (conversionModelGroupConversionModels, sdkEvents) => {
    return conversionModelGroupConversionModels
        .map(conversionModel => {
            const {
                postback_conversion_value_type: postbackConversionValueType,
                postback_index: postbackIndex,
                revenue_bucket_size: revenueBucketSize,
                revenue_buckets: revenueBuckets = [],
                apsalar_event_ids: modelEventsIds = [],
                measurement_period: measurementPeriod,
                custom_mapping: customMapping = {},
                revenue_type: revenueType,
                events_type: eventsType,
                conversion_model_group: conversionModelGroupId,
                model_type: modelType,
                enabled_datetime: enabledDatetime,
                disabled_datetime: disabledDatetime,
                migrate_until: migrateUntil,
                modified: lastUpdate,
                enabled,
                type,
                id,
            } = conversionModel;

            const typeDisplayName = resolveModelTypeDisplayName(type);

            return {
                postbackConversionValueType,
                postbackIndex,
                type,
                modelType,
                id,
                enabled,
                revenueType,
                eventsType,
                revenueBucketSize,
                revenueBuckets,
                measurementPeriod,
                customMapping,
                conversionModelGroupId,
                typeDisplayName,
                enabledDatetime,
                disabledDatetime,
                migrateUntil,
                lastUpdate: lastUpdate
                    ? utc(lastUpdate)
                          .local()
                          .format('lll')
                    : null,
                numberOfRevenueBuckets: revenueBuckets?.length ? revenueBuckets?.length + 1 : null,
                isCustomRevenue: !revenueBucketSize,
                revenueTypeDisplayName: RevenueTypeDisplayNames[revenueType],
                eventsTypeDisplayName: ModelDisplayNames[eventsType],
                apsalarEventIds: modelEventsIds,
                modelEvents: modelEventsIds?.map(eventId => sdkEvents[eventId]),
            };
        })
        .sort((conversionModelA, conversionModelB) => {
            if (conversionModelA.postbackIndex === conversionModelB.postbackIndex) {
                return conversionModelA.postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_FINE ? -1 : 1;
            } else {
                return conversionModelA.postbackIndex - conversionModelB.postbackIndex;
            }
        });
};

export const getParsedConversionModelGroups = (conversionModelGroups, sdkEvents) => {
    return conversionModelGroups.map(conversionModelGroup => {
        const {
            is_skan_4: isSkan4,
            app_id: appId,
            site_id: siteId,
            conversion_models: conversionModels,
            id,
            name,
        } = conversionModelGroup;

        return {
            id,
            name,
            isSkan4,
            appId,
            siteId,
            conversionModels: getGroupConversionModels(conversionModels, sdkEvents),
        };
    });
};

export const getParsedSdkEvents = sdkEvents => {
    const parsedSdkEvents = {};

    Object.values(sdkEvents).forEach(sdkEvent => {
        const {
            apsalar_internal_event_id: id,
            apsalar_internal_event_name: name,
            is_revenue: isRevenue,
            longname,
            display_name: displayName,
        } = sdkEvent;

        parsedSdkEvents[id] = {
            id,
            name,
            isRevenue,
            longname,
            displayName,
        };
    });

    return parsedSdkEvents;
};
