import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import classnames from 'classnames';
import css from './EmptyStateBox.css';
import Label from './Label';
import Tooltip from './Tooltip';

export default function EmptyStateBox({ title, image, Icon, imageStyle, onClick, disabled }) {
    const Animation = posed.div({
        hoverable: !disabled,
        init: {
            boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.1)',
        },
        hover: {
            boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.15)',
            transition: {
                duration: 300,
            },
        },
    });
    return (
        <Tooltip disabled={!disabled} titleTranslationKey="STATIC.PAGES.DATA_DESTINATIONS.UNAVAILABLE" distance={-25}>
            <Animation className={css.animatedBox}>
                <div
                    className={classnames(css.boxContainer, { [css.disabledBoxContainer]: disabled })}
                    onClick={disabled ? undefined : onClick}
                >
                    <Label text={title} className={css.boxTitle} />
                    <div className={css.imagesContainer}>
                        {Icon && <Icon style={imageStyle} className={css.boxImage} />}
                        {!Icon &&
                            image.map((path, index) => {
                                return (
                                    <Fragment key={path}>
                                        {index ? <span className={css.imagesSeparator} /> : null}
                                        <img src={path} style={imageStyle} className={css.boxImage} />
                                    </Fragment>
                                );
                            })}
                    </div>
                </div>
            </Animation>
        </Tooltip>
    );
}

EmptyStateBox.propTypes = {
    title: PropTypes.string,
    image: PropTypes.arrayOf(PropTypes.string),
    Icon: PropTypes.func,
    imageStyle: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

EmptyStateBox.defaultProps = {
    title: '',
    image: [],
    Icon: undefined,
    imageStyle: {},
    onClick: () => {},
    disabled: false,
};
