import React from 'react';

export default function AppsTaskContentBody() {
    return (
        <div>
            <p>
                <strong>What: </strong>
                <span>Tell Singular what apps you are marketing.&nbsp;</span>
            </p>
            <p>
                <span >Make sure the app name you give us matches the app name as it is configured in your ad network.&nbsp;</span>
            </p>
            <p>
                <strong>Why? </strong><span > This will let us map the campaign data we pull from ad networks to the correct app.</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/360025661232" rel="" target="_blank">Check out the Apps Configuration FAQ</a><span
                >.</span>
            </p>
        </div>
    );
}
