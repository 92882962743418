import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import XIcon from '../../../resources/svg/icon_remove_row.svg';
import { BannerFormat, BannerPlacement } from '../../consts';
import css from './BannerPreview.css';

// TODO: HTML template should be fetched from S3
function BannerPreview({
    title,
    subtitle,
    download_button: downloadButton,
    logo,
    background_image: backgroundImage,
    placement,
    format,
}) {
    const [logoPreview, setLogoPreview] = useState(null);

    useEffect(() => {
        // logo can be either object (file) or the url if it was saved before
        const newLogoPreview = logo && typeof logo !== 'string' ? URL.createObjectURL(logo) : logo;
        setLogoPreview(newLogoPreview);
    }, [logo]);

    return (
        <div id="singular-banner" className={classNames(css.scaleBanner, format, placement, css[placement])}>
            {backgroundImage ? <img className="singular-banner-background-image" src={backgroundImage} /> : null}
            {logo ? (
                <img className="singular-banner-logo" src={logoPreview} />
            ) : (
                <div className={classNames('singular-banner-logo', css.logoPlaceholder)} />
            )}
            <div className="singular-banner-content">
                <span className="singular-banner-title">{title}</span>
                <span className="singular-banner-subtitle"> {subtitle}</span>
            </div>
            <XIcon className="singular-banner-close-button" />
            <div className="singular-banner-download-button">{downloadButton}</div>
        </div>
    );
}

BannerPreview.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    download_button: PropTypes.string,
    placement: PropTypes.string,
    format: PropTypes.string,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    background_image: PropTypes.string,
};

BannerPreview.defaultProps = {
    title: 'Title placeholder',
    subtitle: 'Subtitle placeholder',
    download_button: 'Download',
    placement: BannerPlacement.TOP,
    format: BannerFormat.SMALL,
    logo: null,
    background_image: null,
};

export default BannerPreview;
