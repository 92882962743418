import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <span>The first step in setting up Singular attribution is integrating the SDK! Before your development team starts tackling the integration, take a minute to think about what in-app events you would like to measure. Events need to be sent via the SDK and then configured in the Custom Events page to help marketing teams optimize campaigns based on post install events. Learn more and review examples </span><a
                href="https://support.singular.net/hc/en-us/articles/360036736891" rel="" target="_blank">here
            </a><span>.&nbsp;</span>
            </p>
            <p>
                <span>Your Engineering team will need to:</span>
            </p>
            <ul>
                <li>
                    <span
                    >Get the SDK keys and developers documentation and integrate the code</span>
                </li>
                <li>
                    <span>Learn how to use the Singular SDK console for testing</span>
                </li>
                <li>
                    <span>Test the SDK integration</span>
                </li>
            </ul>
        </div>
    );
}
