import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Popover } from '@mui/material';
import classNames from 'classnames';
import AiStar from '../../../resources/icons/creatives/ai_star.svg';

import css from './DimensionPopover.css';

import {
    DimensionCategory,
    DimensionCategoryToColor,
    GeneralDimensions,
    GeneralDimensionsConfig,
} from '../../dimensionsUtils';
import SearchTextField from '../SearchTextField/SearchTextField';
import { useOutsideEventHandler } from '../../../utils/customHooks';

const DimensionIcon = React.memo(dimension => {
    const Icon = GeneralDimensionsConfig[dimension.dimension]?.icon || AiStar;
    return (
        <Icon
            className={css.dimensionIcon}
            style={{
                '--icon-color':
                    DimensionCategoryToColor[dimension.category] || DimensionCategoryToColor[DimensionCategory.OTHER],
            }}
        />
    );
});

const DimensionPopover = ({
    open,
    position,
    onClose,
    aiDimensionsByCategory,
    selectedDimensions,
    onDimensionSelect,
}) => {
    const popoverRef = useRef(null);
    const [search, setSearch] = useState('');

    const dimensionsByCategory = useMemo(
        () => ({
            [DimensionCategory.GENERAL_DIMENSIONS]: Object.values(GeneralDimensions),
            ...aiDimensionsByCategory,
        }),
        [aiDimensionsByCategory]
    );

    const filteredDimensionsByCategory = Object.entries(dimensionsByCategory || {}).reduce(
        (acc, [category, dimensions]) => {
            const filteredDimensions = dimensions.filter(dimension =>
                dimension.toLowerCase().includes(search.toLowerCase())
            );
            if (filteredDimensions.length > 0) {
                acc[category] = filteredDimensions;
            }
            return acc;
        },
        {}
    );

    useEffect(() => {
        if (!open) {
            setSearch('');
        }
    }, [open]);

    const handleDimensionSelect = dimension => {
        onDimensionSelect(dimension);
        onClose();
    };

    useOutsideEventHandler(popoverRef, onClose);

    return (
        <Popover
            className={css.dimensionPopover}
            open={open}
            anchorEl={position}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <div ref={popoverRef} className={css.dimensionPopoverContent}>
                <SearchTextField search={search} onSearchChange={setSearch} />
                <List className={css.dimensionList}>
                    {Object.entries(filteredDimensionsByCategory).map(([category, dimensions]) => (
                        <div key={category}>
                            <div className={css.dimensionListSubheader}>{category}</div>
                            {dimensions.map(dimension => (
                                <ListItem
                                    key={`${category}_${dimension}`}
                                    className={classNames(css.dimensionListItem, {
                                        [css.selected]: selectedDimensions.includes(dimension),
                                    })}
                                    onClick={() => handleDimensionSelect(dimension)}
                                >
                                    <ListItemText
                                        primary={
                                            <div className={css.dimensionListItemText}>
                                                <DimensionIcon dimension={dimension} category={category} />
                                                <div className={css.dimensionName}>{dimension}</div>
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </div>
                    ))}
                </List>
            </div>
        </Popover>
    );
};

DimensionPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    position: PropTypes.instanceOf(Element),
    onClose: PropTypes.func.isRequired,
    aiDimensionsByCategory: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    onDimensionSelect: PropTypes.func.isRequired,
    selectedDimensions: PropTypes.arrayOf(PropTypes.string),
};

export default DimensionPopover;
