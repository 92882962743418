export const LOAD = 'action.link.management.load';
export const UPDATE_LINKS_SUMMARY = 'action.link.management.load.links.summary';
export const UPDATE_LEGACY_LINKS_SUMMARY = 'action.link.management.load.legacy.links.summary';
export const UNLOAD = 'action.link.management.unload';
export const SAMPLE_ACTION = 'action.link.management.sample_action';
export const APP_SELECTED = 'action.link.management.app.selected';
export const APP_FILTER_CHANGED = 'action.link.management.apps.filter.changed';
export const APP_FILTER_CHANGED_FINISHED = 'action.link.management.apps.filter.changed.finished';
export const API_LINKS_TOGGLE = 'action.link.management.api.links.toggle';
export const API_LINKS_TOGGLE_CONTINUE = 'action.link.management.api.links.toggle.continue';
export const OLD_LINKS_TOGGLE = 'action.link.management.old.links.toggle';
export const OLD_LINKS_TOGGLE_CONTINUE = 'action.link.management.old.links.toggle.continue';
export const AGENCIES_LINKS_TOGGLE = 'action.link.management.agencies.links.toggle';
export const UPDATE_LINKS_FOR_APP = 'action.link.management.update.links.for.app';
export const UPDATE_API_LINKS_FOR_APP = 'action.link.management.update.api.links.for.app';
export const PARTNER_EXPAND_CLICK = 'action.link.management.partner.expand.click';
export const LINK_CLICKED = 'action.link.management.link.clicked';
export const ARCHIVE_LINK_CLICKED = 'action.link.management.link.archive.clicked';
export const ARCHIVE_LINK_FINISHED = 'action.link.management.link.archive.finished';
export const UN_ARCHIVE_LINK_CLICKED = 'action.link.management.link.unarchive.clicked';
export const LINK_CLICKED_FINISH = 'action.link.management.link.clicked.finish';
export const CREATE_LINK_CLICKED = 'action.link.management.create.link.clicked';
export const CLOSE_SHELF = 'action.link.management.close.shelf';
export const CLOSE_SUBDOMAINS_SHELF = 'action.link.management.close.subdomains.shelf';
export const OPEN_SHELF = 'action.link.management.open.shelf';
export const SHELF_GROUP_CLICKED = 'action.link.management.shelf.group.clicked';
export const FORM_UPDATED = 'action.link.management.shelf.form.updated';
export const FORM_FIELDS_DEFS_CHANGED = 'action.link.management.shelf.form.fields.defs.changed';
export const GENERATE_LINK = 'action.link.management.shelf.generate';
export const UPDATE_LINK = 'action.link.management.shelf.update';
export const UPDATE_LINK_DATA = 'action.link.management.shelf.update.link.data';
export const GENERATE_LINK_SUCCESS = 'action.link.management.shelf.generate.success';
export const GENERATE_LINK_SUCCESS_AFTER = 'action.link.management.shelf.generate.success.after';
export const SET_SHELF_ERROR = 'action.link.management.shelf.set.error';
export const OPTIONAL_EXTRA_PARAMS_RESULT = 'action.link.management.shelf.optional.extra.params.result';
export const SOURCE_CONFIGURATION_RESULT = 'action.link.management.source.config.result';
export const TOP_BUTTON_CLICKED = 'action.link.management.top.button.clicked';
export const SUB_DOMAINS_CREATE = 'action.link.management.subdomains.create';
export const CREATE_SUBDOMAIN_SUCCESS = 'action.link.management.subdomains.create.success';
export const CREATE_SUBDOMAIN_ERROR = 'action.link.management.subdomains.create.error';
export const FAVOURITE_BUTTON_CLICKED = 'action.link.management.subdomains.favourite.clicked';
export const FAVOURITE_BUTTON_FINISHED = 'action.link.management.subdomains.favourite.finished';
export const ARCHIVE_BUTTON_CLICKED = 'action.link.management.subdomains.archive.clicked';
export const ARCHIVE_BUTTON_FINISHED = 'action.link.management.subdomains.archive.finished';
export const FIELD_VALUES_CHANGED = 'action.link.management.field.values.changed';
export const CATEGORY_DEFS_CHANGED = 'action.link.management.category.defs.changed';
export const LEGACY_LINK_CLICKED = 'action.link.management.legacy.link.clicked';
export const COPY_LINK_CLICKED = 'action.link.management.legacy.copy.link.clicked';
export const UPDATE_SINGULAR_LINKS_SOURCES_DATA = 'action.sources.singular.links.update.data';
export const UPDATE_SOURCES_CONFIGURATION_DATA = 'action.sources.update.source.config.data';
export const UPDATE_PARTNERS_CONFIG_DATA = 'action.link.management.update.partners.config.data';
export const UPDATE_SELECTED_PARTNER_ID = 'action.link.management.update.selected.partner.id';

export const load = (domains, dnsZones, domainsLimit, config, appsFilter) => {
    return {
        type: LOAD,
        domains,
        dnsZones,
        domainsLimit,
        config,
        appsFilter,
    };
};

export const unload = () => {
    return {
        type: UNLOAD,
    };
};

export const sampleAction = () => {
    return {
        type: SAMPLE_ACTION,
    };
};

export const selectApp = (appId, forceReload = false) => {
    return {
        type: APP_SELECTED,
        appId,
        forceReload,
    };
};

export const changeAppsFilter = value => {
    return {
        type: APP_FILTER_CHANGED,
        value,
    };
};

export const changeAppsFilterFinished = () => {
    return {
        type: APP_FILTER_CHANGED_FINISHED,
    };
};

export const updateLinksForApp = (appId, links, suggestions, apps) => {
    return {
        type: UPDATE_LINKS_FOR_APP,
        appId,
        links,
        suggestions,
        apps,
    };
};

export const updateApiLinksForApp = (appId, links, apps) => {
    return {
        type: UPDATE_API_LINKS_FOR_APP,
        appId,
        links,
        apps,
    };
};

export const partnerExpandClick = partnerId => {
    return {
        type: PARTNER_EXPAND_CLICK,
        partnerId,
    };
};

export const linkClicked = (linkId, readOnly, isLegacyLink = false, isDuplicate = false) => {
    return {
        type: LINK_CLICKED,
        linkId,
        readOnly,
        isDuplicate,
        isLegacyLink,
    };
};

export const archiveClicked = (linkId, singularLink, campaignId) => {
    return {
        type: ARCHIVE_LINK_CLICKED,
        linkId,
        singularLink,
        campaignId,
    };
};

export const unArchiveClicked = linkId => {
    return {
        type: UN_ARCHIVE_LINK_CLICKED,
        linkId,
    };
};

export const linkClickedFinish = (oldLink, formValues, readOnly) => {
    return {
        type: LINK_CLICKED_FINISH,
        oldLink,
        formValues,
        readOnly,
    };
};

export const createLinkClicked = () => {
    return {
        type: CREATE_LINK_CLICKED,
    };
};

export const closeShelf = () => {
    return {
        type: CLOSE_SHELF,
    };
};

export const closeSubDomainsShelf = () => {
    return {
        type: CLOSE_SUBDOMAINS_SHELF,
    };
};

export const openShelf = (headerText, actionButton) => {
    return {
        type: OPEN_SHELF,
        headerText,
        actionButton,
    };
};

export const shelfGroupClicked = (groupName, formValues) => {
    return {
        type: SHELF_GROUP_CLICKED,
        groupName,
        formValues,
    };
};

export const formUpdate = (field, values, oldLink, formDirty) => {
    return {
        type: FORM_UPDATED,
        field,
        values,
        oldLink,
        formDirty,
    };
};

export const generateLink = formValues => {
    return {
        type: GENERATE_LINK,
        formValues,
    };
};

export const updateLink = formValues => {
    return {
        type: UPDATE_LINK,
        formValues,
    };
};

export const updateLinkData = (linkId, data) => {
    return {
        type: UPDATE_LINK_DATA,
        linkId,
        data,
    };
};

export const generateLinkSuccess = (data, requestData, isUpdate = false) => {
    return {
        type: GENERATE_LINK_SUCCESS,
        data,
        requestData,
        isUpdate,
    };
};

export const generateLinkSuccessAfter = actionButton => {
    return {
        type: GENERATE_LINK_SUCCESS_AFTER,
        actionButton,
    };
};

export const setShelfError = (err, errorType, errorButtonText = '', errorButtonAction = null) => {
    const emptyFunc = () => {};
    return {
        type: SET_SHELF_ERROR,
        err,
        errorType,
        errorButtonText,
        errorButtonAction: errorButtonAction || emptyFunc,
    };
};

export const sourceConfigResult = (result, newLink) => {
    return {
        type: SOURCE_CONFIGURATION_RESULT,
        result,
        newLink,
    };
};

export const optionalExtraParamsResult = result => {
    return {
        type: OPTIONAL_EXTRA_PARAMS_RESULT,
        result,
    };
};

export const topButtonClicked = buttonId => {
    return {
        type: TOP_BUTTON_CLICKED,
        buttonId,
    };
};

export const createSubDomain = (subDomain, dnsZone) => {
    return {
        type: SUB_DOMAINS_CREATE,
        subDomain,
        dnsZone,
    };
};

export const createSubDomainError = message => {
    return {
        type: CREATE_SUBDOMAIN_ERROR,
        message,
    };
};

export const createSubDomainSuccess = (domains, dnsZones, message) => {
    return {
        type: CREATE_SUBDOMAIN_SUCCESS,
        domains,
        dnsZones,
        message,
    };
};

export const formFieldsDefsChanged = fields => {
    return {
        type: FORM_FIELDS_DEFS_CHANGED,
        fields,
    };
};

export const updateLinksSummary = data => {
    return {
        type: UPDATE_LINKS_SUMMARY,
        data,
    };
};

export const updateLegacyLinksSummary = data => {
    return {
        type: UPDATE_LEGACY_LINKS_SUMMARY,
        data,
    };
};

export const favouriteButtonClicked = (subdomain, zone, favourite) => {
    return {
        type: FAVOURITE_BUTTON_CLICKED,
        subdomain,
        zone,
        favourite,
    };
};

export const archiveButtonClicked = (subdomain, zone) => {
    return {
        type: ARCHIVE_BUTTON_CLICKED,
        subdomain,
        zone,
    };
};

export const favouriteButtonFinished = domain => {
    return {
        type: FAVOURITE_BUTTON_FINISHED,
        domain,
    };
};

export const archiveButtonFinished = domain => {
    return {
        type: ARCHIVE_BUTTON_FINISHED,
        domain,
    };
};

export const oldLinksToggle = () => {
    return {
        type: OLD_LINKS_TOGGLE,
    };
};

export const oldLinksToggleContinue = externalUUID => {
    return {
        type: OLD_LINKS_TOGGLE_CONTINUE,
        externalUUID,
    };
};

export const showApiLinksFilterChangedContinue = externalUUID => {
    return {
        type: API_LINKS_TOGGLE_CONTINUE,
        externalUUID,
    };
};

export const agenciesLinksToggle = () => {
    return {
        type: AGENCIES_LINKS_TOGGLE,
    };
};

export const showApiLinksFilterChanged = () => {
    return {
        type: API_LINKS_TOGGLE,
    };
};

export const fieldValuesChanged = (fieldValues, resetForm = false) => {
    return {
        type: FIELD_VALUES_CHANGED,
        fieldValues,
        resetForm,
    };
};

export const categoryDefsChanged = categoryDefs => {
    return {
        type: CATEGORY_DEFS_CHANGED,
        categoryDefs,
    };
};

export const leacyClicked = (url, linkType) => {
    return {
        type: LEGACY_LINK_CLICKED,
        url,
        linkType,
    };
};

export const copyLinkClicked = (text, label) => {
    return {
        type: COPY_LINK_CLICKED,
        text,
        label,
    };
};

export const updateSingularLinksSourcesData = data => {
    return {
        type: UPDATE_SINGULAR_LINKS_SOURCES_DATA,
        data,
    };
};

export const updateSourcesConfigurationData = (sourceId, appSiteId, data) => {
    return {
        type: UPDATE_SOURCES_CONFIGURATION_DATA,
        sourceId,
        appSiteId,
        data,
    };
};

export const updatePartnersConfigData = partnersConfig => {
    return {
        type: UPDATE_PARTNERS_CONFIG_DATA,
        partnersConfig,
    };
};

export const updateSelectedPartnerId = partnerId => {
    return {
        type: UPDATE_SELECTED_PARTNER_ID,
        partnerId,
    };
};
