import { gql } from '@apollo/client';

export const GET_USER_FAVOURITE_PARTNERS = gql`
    query getFavouritePartners {
        favouritePartners
    }
`;

export const UPDATE_USER_FAVOURITE_PARTNER = gql`
    mutation updateUserFavouritePartner($partnerId: Int!, $favourite: Boolean!) {
        updateUserFavouritePartner(partnerId: $partnerId, favourite: $favourite) {
            ok
            error
        }
    }
`;
