export const FILTER_TYPES = {
    INSTALL: 'install',
    SESSION: 'session',
    EVENT: 'event',
    REVENUE: 'revenue',
};

export const INNER_LOGIC = {
    and: 'and',
    or: 'or',
};

export const OUTER_LOGIC = {
    include: 'include',
    exclude: 'exclude',
};

export const OUTER_LOGIC_LABEL_EXTRA = {
    and: 'users that meet all of the following',
    or: 'users that meet at least one of the following',
};

export const NUM_OPS = {
    eq: 'Equal to',
    gt: 'Greater than',
    lt: 'Less than',
    gte: 'At least',
    lte: 'At most',
    bt: 'Between',
};

export const DEFAULT_CONDITIONS = {
    [FILTER_TYPES.INSTALL]: {
        time: -7,
    },
    [FILTER_TYPES.SESSION]: {
        valueA: 1,
        op: 'gt',
        time: -7,
    },
    [FILTER_TYPES.EVENT]: {
        valueA: 1,
        op: 'gt',
        time: -7,
        event: null,
    },
    [FILTER_TYPES.REVENUE]: {
        valueA: 1,
        op: 'gt',
        time: -7,
        event: null,
    },
};

export const FILTER_LABELS = {
    [FILTER_TYPES.INSTALL]: 'Where install was',
    [FILTER_TYPES.SESSION]: 'Where number of sessions is',
    [FILTER_TYPES.EVENT]: 'Where occurrences of',
    [FILTER_TYPES.REVENUE]: 'Where revenue is',
};

export const TIMES = {
    [-1]: 'day',
    [-2]: '2 days',
    [-3]: '3 days',
    [-4]: '4 days',
    [-5]: '5 days',
    [-6]: '6 days',
    [-7]: 'week',
    [-14]: '2 weeks',
    [-21]: '3 weeks',
    [-30]: 'month',
    [-60]: '2 months',
    [-90]: '3 months',
};

export const SEGMENT_STATUSES = {
    INACTIVE: 'inactive',
    ACTIVE: 'active',
    LOADING: 'loading',
};

export const SEGMENT_AUTO_PAUSE_ENABLED = 'enabled';
export const SEGMENT_AUTO_PAUSE_DISABLED = 'disabled';
export const SEGMENT_AUTO_PAUSE_MAP = {
    [SEGMENT_AUTO_PAUSE_ENABLED]: true,
    [SEGMENT_AUTO_PAUSE_DISABLED]: false,
};
