import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Formik, Form } from 'formik-2';
import PropTypes from 'prop-types';
import { Shelf, Alert, SingularButton } from 'components/widgets';
import { CopyBlock, solarizedLight } from 'react-code-blocks';
import WizardFooter from '../../components/widgets/WizardFooter';
import Label from '../../components/widgets/Label';
import TrashSvg from '../../resources/svg/trashInheritColor.svg';
import { AutoCompleteFieldEnhanced, DropdownFieldEnhanced, FormField } from '../../utils/components/formUtils';
import {
    EMPTY_OPTIONS,
    MIXPANEL_EVENT_ADDED_DEVICE,
    MIXPANEL_EVENT_PREFIX,
    DEVICE_TYPES_KEYSPACES_SHOW_DESCRIPTION,
    DEVICE_TYPES_KEYSPACES_INSTRUCTIONS_CODE_SNIPPETS,
    IDFA,
    AVAILABLE_PLATFORMS,
    DEVICE_TYPE_KEYSPACES_BY_PLATFORM,
    WEB,
    SDID,
} from '../consts';
import { getIdentifierHint, getInitialValues, validationSchema } from '../utils';
import { useSaveDevice } from '../hooks';
import css from './TestingConsoleShelf.css';
import { trackMixpanelEvent } from '../../utils/general';
import { ALERT_TYPES } from '../../components/widgets/Alert/Alert';

const TestingConsoleShelf = ({ editedDevice, open, onClose, onDeviceSave, onDeviceDelete, deviceList }) => {
    const isEditDevice = editedDevice.editable;
    const [selectedPlatform, setSelectedPlatform] = useState(editedDevice.platform || '');
    const [availableIdTypes, setAvailableIdTypes] = useState(
        editedDevice.platform ? DEVICE_TYPE_KEYSPACES_BY_PLATFORM[editedDevice.platform] : []
    );
    const [selectedKeyspace, setSelectedKeyspace] = useState(editedDevice.keyspace || '');
    const [identifierHint, setIdentifierHint] = useState('');
    const [shouldSaveDevice, setShouldSaveDevice] = useState(false);
    const formRef = useRef();

    useEffect(() => {
        setIdentifierHint(getIdentifierHint(selectedKeyspace));
    }, [selectedKeyspace]);

    const onPlatformChange = platform => {
        setSelectedKeyspace('');
        setSelectedPlatform(platform);
        setAvailableIdTypes(DEVICE_TYPE_KEYSPACES_BY_PLATFORM[platform] || []);
    };
    useEffect(() => {
        if (editedDevice.platform) {
            setSelectedPlatform(editedDevice.platform);
            setAvailableIdTypes(DEVICE_TYPE_KEYSPACES_BY_PLATFORM[editedDevice.platform]);
        }
        if (editedDevice.keyspace) {
            setSelectedKeyspace(editedDevice.keyspace);
        }
    }, [editedDevice]);
    const onKeyspaceChange = keyspace => setSelectedKeyspace(keyspace);

    const onSaveDeviceCleanup = () => {
        setShouldSaveDevice(false);
        if (formRef.current) {
            formRef.current.setSubmitting(false);
        }
    };

    const initiateSaveDevice = () => {
        if (formRef.current) {
            setShouldSaveDevice(true);
        }
    };

    const getKeySpace = () => {
        if (selectedKeyspace) {
            const keyspaceAvailable = availableIdTypes.filter(type => type.name === selectedKeyspace).length;
            return keyspaceAvailable ? selectedKeyspace : '';
        } else if (availableIdTypes.length === 1) {
            return availableIdTypes[0].name;
        }
        return '';
    };

    const deviceValues = useMemo(() => {
        if (formRef.current) {
            const device = formRef.current.values;
            const keySetToSet = getKeySpace();
            device.platform = selectedPlatform;
            device.keyspace = keySetToSet;
            if (keySetToSet !== selectedKeyspace) {
                setSelectedKeyspace(keySetToSet);
            }
            return isEditDevice ? { id: editedDevice.id, ...device } : device;
        } else {
            return {};
        }
    }, [formRef.current]);

    const { isLoading: saveDeviceInProcess, status: saveStatus, value: savedDevice } = useSaveDevice(
        shouldSaveDevice,
        onSaveDeviceCleanup,
        deviceValues
    );

    useEffect(() => {
        if (saveStatus === 0) {
            onDeviceSave(savedDevice);
            trackMixpanelEvent(MIXPANEL_EVENT_PREFIX, MIXPANEL_EVENT_ADDED_DEVICE, { ...savedDevice, isEditDevice });
        }
    }, [savedDevice]);

    const validateValues = ({ device_id: deviceId, display_name: displayName }) => {
        const errors = {};

        const restOfDevices = deviceList.filter(device => device.device_id !== editedDevice.device_id);
        if (deviceId && restOfDevices.some(device => device.device_id.toLowerCase() === deviceId.toLowerCase())) {
            errors.device_id = 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.DEVICE_ID_EXISTS';
        }
        if (displayName && restOfDevices.some(device => device.display_name === displayName)) {
            errors.display_name = 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.DISPLAY_NAME_EXISTS';
        }
        return errors;
    };
    return (
        <React.Fragment>
            <Shelf
                open={open}
                onClose={onClose}
                headerText={`STATIC.PAGES.TESTING_CONSOLE.SHELF.TITLE.${isEditDevice ? 'EDIT' : 'ADD'}`}
            >
                <div className={css.page}>
                    <Formik
                        innerRef={formRef}
                        initialValues={getInitialValues(editedDevice)}
                        onSubmit={initiateSaveDevice}
                        validationSchema={validationSchema}
                        validate={validateValues}
                    >
                        {({ values, errors, touched, isValid, isSubmitting, dirty }) => (
                            <div>
                                <Form>
                                    <FormField>
                                        <DropdownFieldEnhanced
                                            name="platform"
                                            valueField="name"
                                            items={AVAILABLE_PLATFORMS}
                                            label="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.PLATFORM.LABEL"
                                            placeholder="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.PLATFORM.PLACEHOLDER"
                                            onChange={onPlatformChange}
                                            selected={values.platform}
                                            bodyClassName={css.keyspaceDropdown}
                                        />
                                    </FormField>
                                    <FormField>
                                        <DropdownFieldEnhanced
                                            name="keyspace"
                                            valueField="name"
                                            items={availableIdTypes}
                                            label="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.TYPE.LABEL"
                                            placeholder="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.TYPE.PLACEHOLDER"
                                            onChange={onKeyspaceChange}
                                            selected={values.keyspace}
                                            bodyClassName={css.keyspaceDropdown}
                                        />
                                    </FormField>
                                    {values.keyspace === IDFA && (
                                        <Alert
                                            text={`STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.TYPE.ALERT.${values.keyspace}`}
                                            textClassName={css.alertText}
                                            type={ALERT_TYPES.WARNING}
                                        />
                                    )}
                                    <FormField>
                                        <AutoCompleteFieldEnhanced
                                            name="device_id"
                                            label="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.ID.LABEL"
                                            labelTooltip="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.ID.TOOLTIP"
                                            isMulti={false}
                                            options={EMPTY_OPTIONS}
                                            searchable
                                            placeholder="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.ID.PLACEHOLDER"
                                            debounceTime={200}
                                            value={values.device_id}
                                            error={touched.device_id && errors.device_id}
                                        />
                                        <Label className={css.identifierHint} text={identifierHint} type="h4" />
                                    </FormField>
                                    <FormField>
                                        <AutoCompleteFieldEnhanced
                                            name="display_name"
                                            label="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.NAME.LABEL"
                                            labelTooltip="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.NAME.TOOLTIP"
                                            isMulti={false}
                                            options={EMPTY_OPTIONS}
                                            searchable
                                            placeholder="STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.NAME.PLACEHOLDER"
                                            value={values.display_name}
                                            error={touched.display_name && errors.display_name}
                                        />
                                    </FormField>
                                    {DEVICE_TYPES_KEYSPACES_SHOW_DESCRIPTION.includes(values.keyspace) && (
                                        <FormField>
                                            <Label
                                                text={`STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.TYPE.DESCRIPTION.${values.keyspace}`}
                                                type="dialogBody"
                                            />
                                        </FormField>
                                    )}
                                    {DEVICE_TYPES_KEYSPACES_INSTRUCTIONS_CODE_SNIPPETS?.[values.keyspace] &&
                                        (values.keyspace !== SDID ||
                                            (values.platform === WEB && values.keyspace === SDID)) && (
                                            <div className={css.codeSnippetsContainer}>
                                                <p>
                                                    <Translate
                                                        id={`STATIC.PAGES.TESTING_CONSOLE.SHELF.FIELDS.TYPE.INSTRUCTIONS.${values.keyspace}`}
                                                    />
                                                </p>
                                                {DEVICE_TYPES_KEYSPACES_INSTRUCTIONS_CODE_SNIPPETS[
                                                    values.keyspace
                                                ].items.map((codeSnippet, key) => {
                                                    return (
                                                        <div className={css.styledCopyBlock}>
                                                            <CopyBlock
                                                                key={`code-block-item-${key}`}
                                                                customStyle={{ padding: '10px' }}
                                                                showLineNumbers={false}
                                                                text={codeSnippet.code}
                                                                language={codeSnippet.lang}
                                                                wrapLines
                                                                theme={solarizedLight}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    <WizardFooter
                                        leftComponents={
                                            isEditDevice ? (
                                                <>
                                                    <SingularButton
                                                        className={css.deleteDeviceButton}
                                                        Icon={TrashSvg}
                                                        type="flat"
                                                        onClick={() => onDeviceDelete(editedDevice.id)}
                                                    >
                                                        <Translate id="STATIC.PAGES.TESTING_CONSOLE.SHELF.DELETE_BUTTON" />
                                                    </SingularButton>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        buttons={[
                                            <SingularButton type="flat" onClick={onClose}>
                                                <Translate id="STATIC.BUTTONS.CANCEL" />
                                            </SingularButton>,
                                            <SingularButton
                                                submit
                                                showSpinner={isSubmitting || saveDeviceInProcess}
                                                disabledDark={!isValid || !dirty || saveDeviceInProcess}
                                            >
                                                <Translate id="STATIC.PAGES.TESTING_CONSOLE.SHELF.SAVE_BUTTON" />
                                            </SingularButton>,
                                        ]}
                                    />
                                </Form>
                            </div>
                        )}
                    </Formik>
                </div>
            </Shelf>
        </React.Fragment>
    );
};

TestingConsoleShelf.propTypes = {
    editedDevice: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    deviceList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDeviceSave: PropTypes.func,
    onDeviceDelete: PropTypes.func,
    translate: PropTypes.func,
};

TestingConsoleShelf.defaultProps = {
    editedDevice: {},
    deviceList: [],
    open: false,
    onClose: () => {},
    onDeviceSave: () => {},
    onDeviceDelete: () => {},
    translate: () => {},
};

export default withLocalize(TestingConsoleShelf);
