import React from 'react';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from '../styles.css';
import OverTimeChart from './overTimeChart';
import OrganicVsPaidChart from './organicVsPaidChart';
import ShareOfVoiceChart from './shareOfVoiceChart';
import YearOverYearChart from './yearOverYearChart';
import ShareOfCostChart from './shareOfCostChart';
import PercentilesChart from './percentilesChart';
import EmptyState from './emptyState';
import EmptyChartState from './emptyChartState';
import { TRACK_EVENT_HOVER_ON_CHART, TRACK_EVENT_PREFIX } from '../consts';
import { trackMixpanelEvent } from '../../utils/general';

const chartEnterTimes = {};

const BenchmarksCharts = ({
    children,
    isFading,
    isLoading,
    translate,
    showEmptyChartComponent,
    noDataCount,
    chartCount,
    skipEmptyChartCheck,
    emptyStateSubText,
    trackEventPrefix,
}) => {
    const chartCountCalculated = chartCount || React.Children.count(children);
    let noDataCountCalculated = noDataCount || 0;

    const chartHasData = (chartData) => {
        return chartData && chartData.data && chartData.data.length > 0;
    };

    const chartElements = React.Children.map(children, (child) => {
        const key = child.props.chartKey;
        const { data: chartData, title } = child.props;

        if (!noDataCount && noDataCount !== 0) {
            noDataCountCalculated += !chartHasData(chartData) && 1;
        }

        return {
            el: React.cloneElement(child, {
                chartData,
                key,
            }),
            key,
            chartData,
            title,
        };
    });

    const getTitle = (key, title) => {
        return (
            <div className={css.chartTitle} key={`${key}_chart_title`}>
                {title || translate(`STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.CHART_TITLE_${key.toUpperCase()}`)}
            </div>
        );
    };

    const getChartEl = (chartData, el, key, title) => {
        if (skipEmptyChartCheck || noDataCount === 0 || chartHasData(chartData)) {
            return [getTitle(key, title), el];
        } else if (showEmptyChartComponent) {
            return [getTitle(key, title), <EmptyChartState />];
        } else {
            return null;
        }
    };

    if (noDataCountCalculated === chartCountCalculated && !isLoading) {
        return <EmptyState emptyStateSubText={emptyStateSubText} />;
    } else {
        return (
            <div className={classNames(css.chartsWrapper)}>
                <div className={classNames(css.fading, { [css.active]: isFading })} />
                {!isLoading &&
                    chartElements.map(({ el, chartData, title, key }) => {
                        return (
                            <div
                                key={key}
                                className={css.chartContainer}
                                id={key}
                                onMouseEnter={() => {
                                    chartEnterTimes[key] = new Date();
                                }}
                                onMouseLeave={() => {
                                    const hoverTime = new Date() - chartEnterTimes[key];
                                    trackMixpanelEvent(trackEventPrefix, TRACK_EVENT_HOVER_ON_CHART, {
                                        chartName: key,
                                        hoverTime,
                                    });
                                }}
                            >
                                {getChartEl(chartData, el, key, title)}
                            </div>
                        );
                    })}
            </div>
        );
    }
};

BenchmarksCharts.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    translate: PropTypes.func.isRequired,
    isFading: PropTypes.bool,
    isLoading: PropTypes.bool,
    noDataCount: PropTypes.number,
    chartCount: PropTypes.number,
    skipEmptyChartCheck: PropTypes.bool,
    emptyStateSubText: PropTypes.string,
    trackEventPrefix: PropTypes.string,
};

BenchmarksCharts.defaultProps = {
    isFading: false,
    isLoading: null,
    noDataCount: undefined,
    chartCount: undefined,
    skipEmptyChartCheck: false,
    emptyStateSubText: 'STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.EMPTY_STATE_SUBTEXT',
    trackEventPrefix: TRACK_EVENT_PREFIX,
};

BenchmarksCharts.OverTimeChart = OverTimeChart;
BenchmarksCharts.OrganicVsPaidChart = OrganicVsPaidChart;
BenchmarksCharts.ShareOfVoiceChart = ShareOfVoiceChart;
BenchmarksCharts.YearOverYearChart = YearOverYearChart;
BenchmarksCharts.ShareOfCostChart = ShareOfCostChart;
BenchmarksCharts.PercentilesChart = PercentilesChart;

export default withLocalize(BenchmarksCharts);
