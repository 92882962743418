import { JAVA } from '../../../../consts';

const defaultConfig = {
    showLineNumbers: true,
    ShowHideButtonVisibility: true,
    ShowHideButtonTitle: 'Keys',
    hidePlaceholders: true,
    showHeader: true,
    darkTheme: true,
    customStyle: {
        overflow: 'hidden',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
};

export const defaultConfigIOS = {
    ...defaultConfig,
    codeLanguage: 'objectivec',
};

export const defaultConfigAndroid = {
    ...defaultConfig,
    codeLanguage: JAVA,
};

export const GET_SDK_FILES_VERSION_PLACEHOLDER = '%%SDK_VERSION%%';

export const IMPORT_CODE_SNIPPET = {
    initialCode: `#import <Singular/Singular.h>`,
};

export const ADD_CODE_SNIPPET = {
    initialCode: `target 'ExampleTarget' do
    use_frameworks!
    //other pod dependencies
    pod 'Singular-SDK'

end`,
};

export const STEP_2_CODE_SNIPPET = {
    initialCode: `dependencyResolutionManagement {
     repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)
     repositories {
          google()
          mavenCentral()
          maven { url 'https://maven.singular.net/' }
     }
}`,
};

export const STEP_3_CODE_SNIPPET = {
    initialCode: `dependencies {
    implementation 'com.google.android.gms:play-services:6.5.87'
    implementation fileTree(dir: 'libs', include: ['*.jar'])
    ...
    implementation 'com.singular.sdk:singular_sdk:${GET_SDK_FILES_VERSION_PLACEHOLDER}'
    ...
}`,
};

export const STEP_4_CODE_SNIPPET = {
    initialCode: `dependencies {
     ...
     implementation 'com.google.android.gms:play-services-ads-identifier:17.0.0+'
     ...
}`,
    customCopyCodeSnippet: `implementation 'com.google.android.gms:play-services-ads-identifier:17.0.0+'`,
};

export const INSTALL_CODE_SNIPPET = {
    initialCode: `$ pod install`,
};

export const COCOA_PODS = 'CocoaPods';
