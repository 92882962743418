import React from 'react';
import GradleIcon from '../../../../../resources/svg/icons/gradle.svg';
import CocoaPodsIcon from '../../../../../resources/svg/icons/cocoa-pods.svg';
import SwiftIcon from '../../../../../resources/svg/icons/swift.svg';

export const DEPENDENCY_MANAGER = Object.freeze({
    COCOAPODS: 'COCOAPODS',
    XCODE_SET_VERSION: 'XCODE_SET_VERSION',
    GRADLE: 'GRADLE',
    HELP_CENTER: 'HELP_CENTER',
});

export const DEPENDENCY_MANAGER_DEFAULTS = {
    IOS: DEPENDENCY_MANAGER.COCOAPODS,
    ANDROID: DEPENDENCY_MANAGER.GRADLE,
};

export const ANDROID_DROPDOWN_ITEMS = [
    {
        name: DEPENDENCY_MANAGER.GRADLE,
        display_name: 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.GET_SDK_FILES.HEADER_DROPDOWN.GRADLE',
        icon: <GradleIcon />,
    },
    {
        name: DEPENDENCY_MANAGER.HELP_CENTER,
        display_name: 'STATIC.PAGES.SDK.SDK_PLANNING.TARGET_PLATFORM_OPTIONS.OTHER_HELP_CENTER',
        link:
            'https://support.singular.net/hc/en-us/articles/360037581952--UPDATED-Android-SDK-Integration-Guide#12_Adding_the_SDK_Without_Gradle',
    },
];

export const IOS_DROPDOWN_ITEMS = [
    {
        name: DEPENDENCY_MANAGER.COCOAPODS,
        display_name: 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.HEADER_DROPDOWN.COCOAPODS',
        icon: <CocoaPodsIcon />,
    },
    {
        name: DEPENDENCY_MANAGER.XCODE_SET_VERSION,
        display_name: 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.HEADER_DROPDOWN.XCODE_SET_VERSION',
        icon: <SwiftIcon />,
    },
    {
        name: 'HELP_CENTER',
        display_name: 'STATIC.PAGES.SDK.SDK_PLANNING.TARGET_PLATFORM_OPTIONS.OTHER_HELP_CENTER',
        link:
            'https://support.singular.net/hc/en-us/articles/12054824479387--UPDATED-iOS-SDK-Integration-Guide#1_Installing_the_SDK',
    },
];

export const DROPDOWN_ITEMS = {
    IOS: IOS_DROPDOWN_ITEMS,
    ANDROID: ANDROID_DROPDOWN_ITEMS,
};
