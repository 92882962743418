import { connect } from 'react-redux';
import { getTeamManagementShelf } from 'teamManagement/selectors';
import { closeShelfCalled, wizardFinished, wizardActionTypeChanged } from 'teamManagement/actions';
import TeamManagementShelf from 'teamManagement/components/TeamManagementShelf';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
    return getTeamManagementShelf(state);
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onCloseShelf: closeShelfCalled,
            onWizardFinish: wizardFinished,
            onWizardActionTypeChanged: wizardActionTypeChanged,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagementShelf);
