import update from 'immutability-helper';

import * as types from '../actions/trackingLinks';
import { applyAllFilters } from '../utils/trackingLinks';

const initialState = {
    apps: [],
    filteredApps: [],
    trackingLinks: [],
    filteredTrackingLinks: [],
    expanded: [],
    expandedCampaigns: [],
    expandedNetworks: {},
    filterType: 'dimensions',
    filters: [{ target: 'default', type: 'Includes', tags: [] }],
    search: undefined,
    fetching: true,
    filterSuggestions: {
        app: [],
        campaign: [],
        network: [],
    },
    searchOptions: [
        { id: 'dimensions', label: 'Filter by dimensions', checked: true },
        { id: 'link', label: 'Search for a specific URL', checked: false },
    ],
    campaign_id_to_delete: undefined,
    enabledCampaignEditing: false,
};

function fetchMetaDone(state, action) {
    if (action.type !== types.FETCH_META_DONE) {
        return state;
    }

    const { trackingLinks } = action;

    const apps = action.apps
        .map(app => {
            const appSites = app.sites.map(appSite => ({ longname: appSite.longname, platform: appSite.platform }));
            const links = trackingLinks.filter(
                link =>
                    appSites.findIndex(
                        appSite => appSite.longname === link.longname && appSite.platform === link.platform
                    ) > -1
            );
            return Object.assign({}, app, { links });
        })
        .filter(app => app.links.length > 0);

    const filterSuggestions = {
        app: Array.from(new Set(apps.map(app => app.name)))
            .sort()
            .map(o => ({ label: o, value: o })),
        campaign: Array.from(new Set(trackingLinks.map(t => t.campaign_name)))
            .sort()
            .map(o => ({ label: o, value: o })),
        network: Array.from(new Set(trackingLinks.map(t => t.partner)))
            .sort()
            .map(o => ({ label: o, value: o })),
    };

    return update(state, {
        apps: { $set: apps },
        filterSuggestions: { $set: filterSuggestions },
        filteredApps: { $set: apps },
        fetching: { $set: false },
        trackingLinks: { $set: action.trackingLinks },
        filteredTrackingLinks: { $set: action.trackingLinks },
        enabledCampaignEditing: { $set: action.enabledCampaignEditing },
    });
}

function toggleExpandApp(state, action) {
    if (action.type !== types.TOGGLE_EXPAND_APP) {
        return state;
    }

    const { app } = action;
    const index = state.expanded.indexOf(app);
    if (index > -1) {
        return update(state, { expanded: { $splice: [[index, 1]] } });
    }

    return update(state, { expanded: { $push: [app] } });
}

function toggleExpandNetwork(state, action) {
    if (action.type !== types.TOGGLE_EXPAND_NETWORK) {
        return state;
    }

    const { app, network } = action;
    const expandedNetworksForApp = state.expandedNetworks[app] || [];
    const index = expandedNetworksForApp.indexOf(network);
    if (index > -1) {
        return update(state, { expandedNetworks: { [app]: { $splice: [[index, 1]] } } });
    }

    if (state.expandedNetworks[app]) {
        return update(state, { expandedNetworks: { [app]: { $push: [network] } } });
    } else {
        return update(state, { expandedNetworks: { [app]: { $set: [network] } } });
    }
}

function toggleExpandCampaign(state, action) {
    if (action.type !== types.TOGGLE_EXPAND_CAMPAIGN) {
        return state;
    }

    const { campaign } = action;
    const index = state.expandedCampaigns.indexOf(campaign);
    if (index > -1) {
        return update(state, { expandedCampaigns: { $splice: [[index, 1]] } });
    }

    return update(state, { expandedCampaigns: { $push: [campaign] } });
}

function collapseAll(state, action) {
    if (action.type !== types.COLLAPSE_ALL) {
        return state;
    }

    return update(state, { expanded: { $set: [] } });
}

function expandAll(state, action) {
    if (action.type !== types.EXPAND_ALL) {
        return state;
    }

    return update(state, { expanded: { $set: state.filteredApps.map(app => app.id) } });
}

function addFilter(state, action) {
    if (action.type !== types.ADD_FILTER) {
        return state;
    }

    const filter = { target: 'default', type: 'Includes', tags: [] };
    return update(state, { filters: { $push: [filter] } });
}

function removeFilter(state, action) {
    if (action.type !== types.REMOVE_FILTER) {
        return state;
    }

    let filters;
    const tagsToReAdd = state.filters[action.index].tags.map(tag => ({ label: tag, value: tag }));
    const deletedFilterTarget = state.filters[action.index].target.toLowerCase();

    let filterSuggestions = {};
    if (deletedFilterTarget === 'default') {
        filterSuggestions = Object.assign({}, state.filterSuggestions);
    } else {
        filterSuggestions = Object.assign({}, state.filterSuggestions, {
            [deletedFilterTarget]: [...tagsToReAdd, ...state.filterSuggestions[deletedFilterTarget]],
        });
    }

    if (state.filters.length > 1) {
        filters = [...state.filters.slice(0, action.index), ...state.filters.slice(action.index + 1)];
    } else {
        filters = [{ target: '', type: '', tags: [] }];
    }
    // filter data
    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, state.apps, state.trackingLinks);

    return update(state, {
        filters: { $set: filters },
        filteredApps: { $set: filteredApps },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
        filterSuggestions: { $set: filterSuggestions },
    });
}

function changeFilter(state, action) {
    if (action.type !== types.CHANGE_FILTER) {
        return state;
    }
    const filter = Object.assign({}, state.filters[action.index], { [action.key]: action.data });
    if (action.key === 'target') {
        filter.tags = [];
    }
    const filters = [...state.filters.slice(0, action.index), filter, ...state.filters.slice(action.index + 1)];

    // filter data
    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, state.apps, state.trackingLinks);

    return update(state, {
        filters: { $set: filters },
        filteredApps: { $set: filteredApps },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
    });
}

function addFilterTag(state, action) {
    if (action.type !== types.ADD_FILTER_TAG) {
        return state;
    }
    const i = action.filterIndex;
    const tags = [...state.filters[i].tags, action.tag];
    const filter = Object.assign({}, state.filters[i], { tags });
    const filters = [...state.filters.slice(0, i), filter, ...state.filters.slice(i + 1)];
    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, state.apps, state.trackingLinks);
    const filterTarget = state.filters[i].target.toLowerCase();

    const filterTagIndex = state.filterSuggestions[filterTarget].findIndex(element => element.value === action.tag);

    const filterSuggestions = {
        ...state.filterSuggestions,
        [filterTarget]: [
            ...state.filterSuggestions[filterTarget].slice(0, filterTagIndex),
            ...state.filterSuggestions[filterTarget].slice(filterTagIndex + 1),
        ],
    };

    return update(state, {
        filters: { $set: filters },
        filteredApps: { $set: filteredApps },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
        filterSuggestions: { $set: filterSuggestions },
    });
}

function removeFilterTag(state, action) {
    if (action.type !== types.REMOVE_FILTER_TAG) {
        return state;
    }
    const i = action.filterIndex;
    const { tag } = action;
    let { tags } = state.filters[i];
    const tagIndex = tags.indexOf(tag);
    tags = [...state.filters[i].tags.slice(0, tagIndex), ...state.filters[i].tags.slice(tagIndex + 1)];
    const filter = Object.assign({}, state.filters[i], { tags });
    const filters = [...state.filters.slice(0, i), filter, ...state.filters.slice(i + 1)];

    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, state.apps, state.trackingLinks);
    const filterTarget = state.filters[i].target.toLowerCase();
    const tagObject = { label: tag, value: tag };

    const filterSuggestions = {
        ...state.filterSuggestions,
        [filterTarget]: [tagObject, ...state.filterSuggestions[filterTarget]],
    };

    return update(state, {
        filters: { $set: filters },
        filteredApps: { $set: filteredApps },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
        filterSuggestions: { $set: filterSuggestions },
    });
}

function clearFilterTags(state, action) {
    if (action.type !== types.CLEAR_FILTER_TAGS) {
        return state;
    }
    const i = action.filterIndex;
    const { tags } = action;
    const filters = [...state.filters];
    filters[i].tags = [];
    const filterTarget = filters[i].target.toLowerCase();

    const filterSuggestions = {
        ...state.filterSuggestions,
        [filterTarget]: [...tags.map(t => ({ label: t, value: t })), ...state.filterSuggestions[filterTarget]],
    };
    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, state.apps, state.trackingLinks);
    return update(state, {
        filters: { $set: filters },
        filteredApps: { $set: filteredApps },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
        filterSuggestions: { $set: filterSuggestions },
    });
}

function handleFilterSelector(state, action) {
    if (action.type !== types.HANDLE_FILTER_SELECTOR) {
        return state;
    }

    let filters = [...state.filters];
    let { search } = state;
    if (action.filterType === 'dimensions') {
        search = undefined;
    } else {
        filters = [{ target: 'default', type: 'Includes', tags: [] }];
    }

    const searchOptions = [...state.searchOptions].map(option => ({
        ...option,
        checked: option.id === action.filterType,
    }));

    return update(state, {
        filters: { $set: filters },
        filterType: { $set: action.filterType },
        search: { $set: search },
        filteredApps: {
            $set: state.apps,
        },
        filteredTrackingLinks: {
            $set: state.trackingLinks,
        },
        searchOptions: {
            $set: searchOptions,
        },
    });
}

function updateSearch(state, action) {
    if (action.type !== types.UPDATE_SEARCH) {
        return state;
    }

    return update(state, {
        search: {
            $set: action.search,
        },
    });
}

function searchTrackingLink(state, action) {
    if (action.type !== types.SEARCH_TRACKING_LINK) {
        return state;
    }

    if (action.shouldClear) {
        return update(state, {
            filteredApps: { $set: state.apps },
            filteredTrackingLinks: { $set: state.trackingLinks },
            search: { $set: undefined },
        });
    }
    const hRegex = /h=[A-Za-z0-9]*/;
    const stRegex = /st=[A-Za-z0-9]*/;
    const accountRegex = /[&?]a=[A-Za-z0-9]*/;
    const longnameRegex = /[&?]i=([A-Za-z][A-Za-z\d_]*\.)*[A-Za-z][A-Za-z\d_]*/;
    const platformRegex = /[&?]p=[A-Za-z]*/;
    const networkRegex = /[&?]an=[A-Za-z0-9]*/;

    const url = state.search;
    const h = url.match(hRegex);
    const st = url.match(stRegex);
    const account = url.match(accountRegex);
    const longname = url.match(longnameRegex);
    const platform = url.match(platformRegex);
    const network = url.match(networkRegex);
    let filteredTrackingLinks;
    if (h) {
        filteredTrackingLinks = state.trackingLinks.filter(l => l.click_url && l.click_url.indexOf(h[0]) > -1);
    } else if (st) {
        filteredTrackingLinks = state.trackingLinks.filter(l => l.click_url && l.click_url.indexOf(st[0]) > -1);
    } else {
        filteredTrackingLinks = state.trackingLinks.filter(l => {
            if (!account || !longname || !platform || !network || !l.click_url) {
                return false;
            }
            if (l.click_url.indexOf(account[0]) === -1) {
                return false;
            }
            if (l.click_url.indexOf(longname[0]) === -1) {
                return false;
            }
            if (l.click_url.indexOf(platform[0]) === -1) {
                return false;
            }
            if (l.click_url.indexOf(network[0]) === -1) {
                return false;
            }
            return true;
        });
    }

    const filteredApps = state.apps.map(app => {
        const appSites = app.sites.map(appSite => ({ longname: appSite.longname, platform: appSite.platform }));
        const links = filteredTrackingLinks.filter(
            link =>
                appSites.findIndex(
                    appSite => appSite.longname === link.longname && appSite.platform === link.platform
                ) > -1
        );
        return Object.assign({}, app, { links });
    });

    return update(state, {
        filteredApps: { $set: filteredApps },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
    });
}

function deleteClicked(state, action) {
    if (action.type !== types.DELETE_CAMPAIGN_CLICKED) {
        return state;
    }

    return update(state, {
        campaign_id_to_delete: {
            $set: action.campaign,
        },
    });
}

function deleteSuccess(state, action) {
    if (action.type !== types.DELETE_CAMPAIGN_SUCCESS) {
        return state;
    }

    let trackingLinks = [];

    if (Array.isArray(action.campaign)) {
        const deletedCampaigns = action.campaign.map(c => +c);
        trackingLinks = state.trackingLinks.filter(l => deletedCampaigns.indexOf(l.campaign_id) === -1);
    } else trackingLinks = state.trackingLinks.filter(l => l.campaign_id !== +action.campaign);

    const apps = state.apps
        .map(app => {
            const appSites = app.sites.map(appSite => ({ longname: appSite.longname, platform: appSite.platform }));
            const links = trackingLinks.filter(
                link =>
                    appSites.findIndex(
                        appSite => appSite.longname === link.longname && appSite.platform === link.platform
                    ) > -1
            );
            return Object.assign({}, app, { links });
        })
        .filter(app => app.links.length > 0);

    const { filters } = state;

    // filter data
    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, apps, trackingLinks);

    return update(state, {
        apps: { $set: apps },
        filteredApps: { $set: filteredApps },
        trackingLinks: { $set: trackingLinks },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
        campaign_id_to_delete: { $set: undefined },
    });
}

function deleteFailed(state) {
    return state;
}

function deleteDismissed(state, action) {
    if (action.type !== types.DELETE_CAMPAIGN_DISMISSED) {
        return state;
    }

    return update(state, {
        campaign_id_to_delete: {
            $set: undefined,
        },
    });
}

function editClicked(state, action) {
    if (action.type !== types.EDIT_CAMPAIGN_NAME_CLICKED) {
        return state;
    }

    return update(state, {
        editingCampaign: {
            $set: action.campaign,
        },
    });
}

function editSuccess(state, action) {
    if (action.type !== types.EDIT_CAMPAIGN_NAME_SUCCESS) {
        return state;
    }

    const index = state.trackingLinks.findIndex(link => link.campaign_id === +action.campaignID);
    if (index === -1) {
        return state;
    }

    const updatedLink = state.trackingLinks[index];
    updatedLink.campaign_name = action.campaignName;
    const updatedTag = action.response.updated_tag;
    updatedLink.tracking_tag = updatedTag;
    if (updatedLink.link_action === 'impression' && updatedLink.impression_url !== null) {
        updatedLink.impression_url = updatedTag;
    } else {
        updatedLink.click_url = updatedTag;
    }

    const trackingLinks = [
        ...state.trackingLinks.slice(0, index),
        updatedLink,
        ...state.trackingLinks.slice(index + 1),
    ];
    const apps = state.apps
        .map(app => {
            const appSites = app.sites.map(appSite => ({ longname: appSite.longname, platform: appSite.platform }));
            const links = trackingLinks.filter(
                link =>
                    appSites.findIndex(
                        appSite => appSite.longname === link.longname && appSite.platform === link.platform
                    ) > -1
            );
            return Object.assign({}, app, { links });
        })
        .filter(app => app.links.length > 0);

    const { filters } = state;

    // filter data
    const { filteredApps, filteredTrackingLinks } = applyAllFilters(filters, apps, trackingLinks);

    return update(state, {
        apps: { $set: apps },
        filteredApps: { $set: filteredApps },
        trackingLinks: { $set: trackingLinks },
        filteredTrackingLinks: { $set: filteredTrackingLinks },
        editingCampaign: { $set: undefined },
    });
}

function editFailed(state) {
    return state;
}

export default function rootReducer(state = initialState, action) {
    state = fetchMetaDone(state, action);
    state = toggleExpandApp(state, action);
    state = toggleExpandNetwork(state, action);
    state = toggleExpandCampaign(state, action);
    state = collapseAll(state, action);
    state = expandAll(state, action);
    state = addFilter(state, action);
    state = removeFilter(state, action);
    state = changeFilter(state, action);
    state = addFilterTag(state, action);
    state = removeFilterTag(state, action);
    state = clearFilterTags(state, action);
    state = handleFilterSelector(state, action);
    state = updateSearch(state, action);
    state = searchTrackingLink(state, action);
    state = deleteClicked(state, action);
    state = deleteSuccess(state, action);
    state = deleteFailed(state, action);
    state = deleteDismissed(state, action);
    state = editClicked(state, action);
    state = editSuccess(state, action);
    state = editFailed(state, action);

    return state;
}
