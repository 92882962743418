import React from 'react';
import {
    WIZARD_INTERNAL_DISTRIBUTION,
    WIZARD_OVERVIEW,
    WIZARD_TEST_CHANNELS,
    WIZARD_TEST_DETAILS,
    WIZARD_TEST_PARAMETERS,
    WIZARD_EVENTS,
} from './consts';
import TestDetails from './Steps/TestDetails';
import TestChannels from './Steps/TestChannels';
import TestParameters from './Steps/TestParameters';
import InternalDistribution from './Steps/InternalDistribution';
import Overview from './Steps/Overview';
import Events from './Steps/Events';

const prefixAudiencePageTitle = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTIONS_HEADER';

const getCustomSection = (key, onNext, onBack, onSaveDraft, distributionsData) => {
    const defaultProps = {
        onNext,
        onBack,
        onSaveDraft,
    };

    const testComponents = {
        [WIZARD_TEST_DETAILS]: <TestDetails {...defaultProps} pageTitle={`${prefixAudiencePageTitle}.TEST_DETAILS`} />,
        [WIZARD_TEST_CHANNELS]: (
            <TestChannels
                distributionsData={distributionsData}
                {...defaultProps}
                pageTitle={`${prefixAudiencePageTitle}.TEST_CHANNELS`}
            />
        ),
        [WIZARD_TEST_PARAMETERS]: (
            <TestParameters
                distributionsData={distributionsData}
                {...defaultProps}
                pageTitle={`${prefixAudiencePageTitle}.TEST_PARAMETERS`}
            />
        ),
        [WIZARD_INTERNAL_DISTRIBUTION]: (
            <InternalDistribution {...defaultProps} pageTitle={`${prefixAudiencePageTitle}.INTERNAL_DISTRIBUTION`} />
        ),
        [WIZARD_EVENTS]: <Events {...defaultProps} pageTitle={`${prefixAudiencePageTitle}.EVENTS`} />,
        [WIZARD_OVERVIEW]: (
            <Overview
                {...defaultProps}
                distributionsData={distributionsData}
                pageTitle={`${prefixAudiencePageTitle}.OVERVIEW`}
            />
        ),
    };

    return testComponents[key] || null;
};

export default getCustomSection;
