import { connect } from 'react-redux';
import Signup from './signup';
import { getErrorCode, getStatus, getIsLoading, getEmailSent, getFinishedGoogleSignupFirstStep } from './selector';
import {
    submitSignupForm,
    submitCompleteGoogleSignIn,
    resendConfirmationEmail,
    submitSignupFormStarted,
    submitGoogleSignupStarted,
} from './actions';

const mapStateToProps = state => {
    return {
        status: getStatus(state),
        errorCode: getErrorCode(state),
        isLoading: getIsLoading(state),
        emailSent: getEmailSent(state),
        finishedGoogleSignupFirstStep: getFinishedGoogleSignupFirstStep(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignupStarted: data => {
            dispatch(submitSignupFormStarted(data));
        },
        onGoogleSignupStarted: data => {
            dispatch(submitGoogleSignupStarted(data));
        },
        onSubmitClick: data => {
            dispatch(submitSignupForm(data));
        },
        onCompleteGoogleSignUp: data => {
            dispatch(submitCompleteGoogleSignIn(data));
        },
        onResendClick: email => {
            dispatch(resendConfirmationEmail(email));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
