import API from '../services/api';

let instance = null;
const api = new API();

const ACCOUNT_PLAN_ENDPOINT = 'api/account_plan';
const CONTACT_US_ENDPOINT = 'api/contact_us';
const UPGRADE_REQUEST_URL = 'api/upgrade_request';
const ALL_PRODUCTS_URL = 'api/products';

export default class AccountPlanAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getAccountPlan() {
        return api.getJson(ACCOUNT_PLAN_ENDPOINT, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    submitContactUs(tier) {
        return api.getJson(CONTACT_US_ENDPOINT, { method: 'GET', params: { tier } });
    }

    upgradeEtlRequest(email, firstName, lastName, company, tier, country, role) {
        return api.getJson(UPGRADE_REQUEST_URL, {
            method: 'POST',
            body: {
                is_etl: true,
                email,
                first_name: firstName,
                last_name: lastName,
                company,
                tier,
                country,
                role,
            },
        });
    }

    getAllProducts() {
        return api.getJson(ALL_PRODUCTS_URL, {
            method: 'GET',
            credentials: 'include',
        });
    }
}
