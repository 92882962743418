import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import * as css from 'teamManagement/components/ExpandableGroup.css';

class ExpandableGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: props.defaultExpanded,
        };
        this.handleToggle = this._handleToggle.bind(this);
        this.expandedHeight = null;
        this.Animated = posed.div({
            expanded: {
                height: ({ element }) => {
                    if (!this.expandedHeight) {
                        const { children } = element;
                        let height = 0;
                        for (const child of children) {
                            height += child.getBoundingClientRect().height;
                        }
                        this.expandedHeight = height;
                    }
                    return this.expandedHeight;
                },
            },
            collapsed: {
                height: '0px',
            },
        });
    }

    _handleToggle() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    render() {
        const { title, rightComponents, marked, hasWarning } = this.props;

        const { expanded } = this.state;
        return (
            <div
                className={`${css.container} ${expanded ? css.expanded : ''} ${marked ? css.marked : ''} ${
                    hasWarning ? css.markedWarning : ''
                }`}
            >
                <div className={css.headerArea}>
                    <div className={css.titleIcon} onClick={this.handleToggle}>
                        <div className={expanded ? css.minus : css.plus} />
                    </div>
                    <div className={css.title}>{title}</div>
                    <div className={css.titleRightArea}>{rightComponents}</div>
                </div>
                <this.Animated className={css.animatedArea} pose={expanded ? 'expanded' : 'collapsed'}>
                    <div className={css.contentContainer}>{this.props.children}</div>
                </this.Animated>
            </div>
        );
    }
}

ExpandableGroup.propTypes = {
    defaultExpanded: PropTypes.bool,
    rightComponents: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.string]),
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    marked: PropTypes.bool,
    hasWarning: PropTypes.bool,
};

ExpandableGroup.defaultProps = {
    defaultExpanded: true,
    rightComponents: null,
    title: '',
    marked: false,
    hasWarning: false,
};

export default ExpandableGroup;
