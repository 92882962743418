const reportBase = {
    fields: ['dimensions', 'metrics', 'skanModeledMetrics', 'skanModeledCohortMetrics', 'timeBreakdowns'],
    dataSelector: {
        dimensions: 'dimensions',
        metrics: 'metrics',
        skanModeledMetrics: 'skanModeledMetrics',
        skanModeledCohortMetrics: 'skanModeledCohortMetrics',
        timeBreakdowns: 'timeBreakdowns',
        shelfFormatted: 'shelf',
        header: 'header',
        showFieldsSelection: 'showFieldsSelection',
        showHeader: 'showHeader',
        showSpinner: 'showSpinner',
        reportDates: 'reportDates',
        table: 'table',
        bookmark: 'bookmark',
        validationError: 'validationError',
        showReportMeta: 'showReportMeta',
        disableReportState: 'disableReportState',
        reportFormHasChanged: 'reportFormHasChanged',
    },
};

const cohorts = {
    fields: [
        'cohortMetrics',
        'customEvents',
        'skanModeledCustomEvents',
        'periods',
        'skanModeledCohortPeriods',
        'unifiedCohortPeriods',
    ],
    dataSelector: {
        cohortMetrics: 'cohortMetrics',
        customEvents: 'customEvents',
        skanModeledCustomEvents: 'skanModeledCustomEvents',
        periods: 'periods',
        skanModeledCohortPeriods: 'skanModeledCohortPeriods',
        unifiedCohortPeriods: 'unifiedCohortPeriods',
    },
};

export { reportBase, cohorts };
