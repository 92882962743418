import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PageWrapper } from '../components/partials';
import Spinner from '../components/widgets/Spinner';
import { getUserData } from '../selectors/user';
import withTranslate from '../containers/widgets/withTranslate';
import { useFetchAccountPlan } from './hooks';
import css from './OrganizationPlan.css';
import OrgPlanDetails from './components/OrgPlanDetails';
import { MIXPANEL_PAGE_LOADED, PACING_TYPES } from './consts';
import PacingCard from './components/PacingCard';
import { CUSTOMER_TIERS } from '../utils/consts';
import UpgradePlans from './components/UpgradePlans';
import { getOrganizationPlanStartDate } from './utils';
import { ACCOUNT_PLAN_EVENT_PREFIX, trackMixpanelEvent } from '../utils/general';
import WarningMessage from '../components/widgets/WarningMessage';

function OrganizationPlan({ translate }) {
    const { isLoading, error, ...data } = useFetchAccountPlan();
    const [ userClosedWarningMsg, setUserClosedWarningMsg ] = useState(false);
    const {
        organization_tier: tier = '',
        company_display_name: orgName = '',
        should_block_app: orgIsBlocked = false,
        is_paying_customer: isPaying = false,
    } = useSelector(state => getUserData(state));
    const {
        timezone = '',
        currency = '',
        planPrice = 0,
        installCap = 0,
        adSpendCap = 0,
        actualInstalls,
        actualAdSpend,
        contractStartDate = '',
        contractEndDate = '',
        pacingStartDate = '',
        daysLeft = 0,
        prevActualInstalls = 0,
        found: hasAccountPlan = false,
    } = data || {};

    useEffect(() => {
        if (orgName) {
            trackMixpanelEvent(ACCOUNT_PLAN_EVENT_PREFIX, MIXPANEL_PAGE_LOADED, { blocked: orgIsBlocked });
        }
    }, [orgName]);
    const showWarningMessage = orgIsBlocked && !userClosedWarningMsg;

    const getOrganizationPlanPage = () => {
        if (!Object.keys(data).length) return <></>;
        return (
            <>
                <WarningMessage
                    show={showWarningMessage}
                    className={css.warningMessage}
                    showIcon
                    showTypeIcon
                    onClose={() => setUserClosedWarningMsg(true)}
                    type="error"
                    message="STATIC.PAGES.ACCOUNT_PLAN.BLOCKED_ORF_ERROR_MSG"
                />
                <div className={css.pageHeaderContainer}>
                    <div className={css.pageHeader}>
                        {translate('STATIC.PAGES.ACCOUNT_PLAN.HEADER')} - {orgName}
                    </div>
                </div>
                <div className={css.pageContent}>
                    {hasAccountPlan && (
                        <div className={css.planDetailsContainer}>
                            <OrgPlanDetails
                                timezone={timezone}
                                currency={currency}
                                contractStartDate={getOrganizationPlanStartDate(
                                    tier,
                                    pacingStartDate,
                                    contractStartDate
                                )}
                                contractEndDate={contractEndDate}
                                planPrice={planPrice || 0}
                                installCap={installCap}
                                adSpendCap={adSpendCap}
                            />
                            <PacingCard
                                type={installCap ? PACING_TYPES.INSTALLS : PACING_TYPES.AD_SPEND}
                                currentValue={Math.round(
                                    installCap ? actualInstalls + prevActualInstalls : actualAdSpend
                                )}
                                maxValue={Math.round(installCap || adSpendCap)}
                                expiresInDays={daysLeft}
                                isFreeTier={tier === CUSTOMER_TIERS.FREE}
                                isPaying={isPaying}
                            />
                        </div>
                    )}
                    <UpgradePlans hasAccountPlan={hasAccountPlan} />
                </div>
            </>
        );
    };

    return (
        <PageWrapper className={css.organizationPlanPage} disablePadding={!!showWarningMessage}>
            {isLoading && !error ? <Spinner style={{ top: '50vh' }} show expanded /> : getOrganizationPlanPage()}
        </PageWrapper>
    );
}

OrganizationPlan.propTypes = {
    translate: PropTypes.func,
};

OrganizationPlan.defaultProps = {};

export default withTranslate(OrganizationPlan);
