import css from 'teamManagement/styles.css';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik-2';
import * as Yup from 'yup';
import Wizardable from 'components/widgets/Wizardable';
import WizardFooter from 'components/widgets/WizardFooter';
import { AutoCompleteFieldEnhanced, FormField } from '../../utils/components/formUtils';
import { useFetchAgencies } from '../../dataSources/hooks';
import Spinner from '../../components/widgets/Spinner';

export default function AgencySelectionForm({ orgAgencies, agencySelectionForm, ...props }) {
    const { value: agencies, isLoading } = useFetchAgencies();

    const agencyOptions = agencies
        ? agencies.map(({ agency_id: value, display_name: label }) => ({ value, label }))
        : [];

    const initialAgency = agencyOptions.find(({ value }) => value === agencySelectionForm.id);

    const VALIDATION_SCHEMA = Yup.object().shape({
        agency: Yup.string()
            .required()
            .test('isAlreadyPermitted', '', function(agency) {
                if (!agency) return true;

                const exists = orgAgencies.find((orgAgency) => orgAgency.agency_id === agency);
                return !exists ? true : this.createError({ message: 'Agency already have permissions' });
            }),
    });

    return (
        <Fragment>
            <Spinner show={isLoading} expanded />
            {isLoading === false ? (
                <Formik initialValues={{ agency: initialAgency }} validationSchema={VALIDATION_SCHEMA}>
                    {(formikProps) => (
                        <AgencySelectionContent agencies={agencyOptions} {...formikProps} {...props} />
                    )}
                </Formik>
            ) : null}
        </Fragment>
    );
}

AgencySelectionForm.propTypes = {
    orgAgencies: PropTypes.arrayOf(PropTypes.object),
    agencySelectionForm: PropTypes.objectOf(PropTypes.any),
};

AgencySelectionForm.defaultProps = {
    orgAgencies: [],
    agencySelectionForm: {},
};

class AgencySelectionContent extends Wizardable {
    constructor(props) {
        super(props);

        const onNextClick = this.buttonDefs.next.onClick;
        this.buttonDefs.next.onClick = () => {
            const { values: { agency }, agencies, updateAgencySelectionForm } = this.props;

            const agencyId = typeof agency !== 'object' ? agency : agency.value;
            const selectedAgency = agencies.find(({ value }) => value === agencyId);

            updateAgencySelectionForm({ id: selectedAgency.value, name: selectedAgency.label });

            onNextClick();
        };
    }

    validate() {
        const { touched, errors, initialValues } = this.props;
        const isValid = touched.agency ? !errors.agency : initialValues.agency;

        this.setTooltip(['next'], isValid ? null : errors.agency);

        return isValid;
    }

    render() {
        const { agencies, lastAction, initialValues, errors, touched } = this.props;
        return (
            <Fragment>
                <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                    <div className={css.headline}>
                        <Translate
                            id="STATIC.PAGES.TEAM_MEMBERS.WIZARD_AGENCY_SELECTION_TITLE"
                            data={{
                                articleId: '360020835952-Team-Management-FAQ',
                                articleName: 'Team Management FAQ',
                            }}
                        />
                    </div>
                    <FormField>
                        <AutoCompleteFieldEnhanced
                            name="agency"
                            label="Agency"
                            isMulti={false}
                            options={agencies}
                            mandatory
                            value={initialValues.agency}
                            placeholder="STATIC.PLACEHOLDERS.SELECT_FILTER_VALUE"
                            error={touched.agency && errors.agency}
                        />
                    </FormField>
                </this.AnimationItem>
                <WizardFooter buttons={this.getButtons()} />
            </Fragment>
        );
    }
}

AgencySelectionContent.propTypes = {
    updateAgencySelectionForm: PropTypes.func,
};

AgencySelectionContent.defaultProps = {
    updateAgencySelectionForm: () => {},
};
