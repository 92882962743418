import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { attributionSourceSchema } from '../utils/sources';

export const getSourcesData = state => {
    return state.sources.sourcesData;
};
export const getSourcesSingularLinksData = state => {
    return state.sources.sourcesSingularLinksData;
};
export const getSourceConfigurations = state => {
    return state.sources.sourceConfigurations;
};

export const getAttributionSourcesList = createSelector([getSourcesData], sourcesData => {
    return sourcesData && denormalize(sourcesData.result, attributionSourceSchema, sourcesData.entities);
});

export const getSingularLinksSourcesList = createSelector([getSourcesSingularLinksData], sourcesData => {
    return sourcesData && denormalize(sourcesData.result, attributionSourceSchema, sourcesData.entities);
});
