import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeaturePermissions } from '../selectors/permissions';

const mapStateToProps = (state, ownProps) => {
    const { typesMap, permission } = getFeaturePermissions(state);
    const types = Object.keys(typesMap);
    const requiredPermissionName = Object.keys(ownProps).find(prop => types.includes(prop));
    if (!requiredPermissionName) {
        console.warn(`Couldn't find a valid permission type in: ${Object.keys(ownProps)}`);
        return {
            requiredPermission: 1,
            permission: 1,
        };
    }

    const requiredPermission = typesMap[requiredPermissionName];
    return {
        requiredPermission,
        permission,
    };
};

class PermissionsWrapper extends React.PureComponent {
    render() {
        const { children, permission, requiredPermission } = this.props;
        if (permission < requiredPermission) {
            return null;
        }
        return children;
    }
}

PermissionsWrapper.propTypes = {
    children: PropTypes.any,
    permission: PropTypes.number,
    requiredPermission: PropTypes.number,
};

PermissionsWrapper.defaultProps = {
    children: null,
    permission: 3,
    requiredPermission: 1,
};

const PermissionsWrapperContainer = connect(mapStateToProps, null)(PermissionsWrapper);

export default PermissionsWrapperContainer;
