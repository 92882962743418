import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import EditIcon from 'resources/svg/edit.svg';
import Tooltip from '../../components/widgets/Tooltip';
import css from './LinkRowItem.css';
import AppleIcon from '../../resources/icons/os/ios.svg';
import AndroidIcon from '../../resources/icons/os/android.svg';
import PCConsoleIcon from '../../resources/icons/os/pc_console.svg';
import ArchiveIcon from '../../resources/svg/archive_grey.svg';
import AgencyIcon from '../../../../dashboard/img/briefcase.svg';
import PermissionsWrapper from '../../containers/PermissionsWrapper';
import OverflowingText from '../../components/widgets/OverflowingText';
import { LINK_TYPE_LEGACY, LINK_TYPE_MOBILE_WEB_TO_APP } from '../linkTypes';
import { getFeaturePermissions } from '../../selectors/permissions';
import Icon, { IconSizes, IconVariants } from '../../components/foundations/Icon';

const platformToIcon = {
    android: AndroidIcon,
    ios: AppleIcon,
    pcConsole: PCConsoleIcon,
};

function LinkRowItem({
    type,
    linksCount,
    agency_name: agencyName,
    campaign_name: campaignName,
    tag_id: tagId,
    singular_link: singularLink,
    configured,
    createdDisplay,
    modifiedDisplay,
    showAgencyLabel,
    onEditClick,
    isLast,
    absolute_link_type: absoluteLinkType,
    ...props
}) {
    const { permission, typesMap } = useSelector(state => getFeaturePermissions(state));
    const readOnly = permission === typesMap.read;

    const onDuplicateClicked = e => {
        e.stopPropagation();
        props.onDuplicateClicked(tagId, readOnly);
    };

    const onArchiveClicked = e => {
        e.stopPropagation();
        props.onArchiveClicked(tagId);
    };

    return (
        <div className={`${css.container} ${isLast ? css.isLast : ''}`}>
            {showAgencyLabel ? (
                <div className={css.icons}>
                    <div className={css.agency}>
                        {agencyName ? (
                            <OverflowingText className={css.agency}>
                                <AgencyIcon className={css.agencyIcon} />
                                <Translate id={agencyName} />
                            </OverflowingText>
                        ) : null}
                    </div>
                </div>
            ) : null}
            <OverflowingText className={css.campaignName}>
                <Translate id={campaignName} />
            </OverflowingText>
            <div className={css.icons}>
                {Object.entries(configured).map(([platform, isConfigured]) => {
                    const IconElement = platformToIcon?.[platform];
                    return isConfigured && IconElement ? (
                        <IconElement key={platform} className={css.icon} />
                    ) : (
                        <div key={platform} className={`${css.icon} ${css.noIcon}`} />
                    );
                })}
            </div>
            <div className={css.dates}>
                {createdDisplay && (
                    <div>
                        <Translate id="STATIC.PAGES.MANAGE_LINKS.LINK_ROW_ITEM_CREATED_AT" data={{ createdDisplay }} />
                    </div>
                )}

                {modifiedDisplay && (
                    <React.Fragment>
                        <div className={css.separator} />
                        <div>
                            <Translate
                                id="STATIC.PAGES.MANAGE_LINKS.LINK_ROW_ITEM_UPDATED_AT"
                                data={{ modifiedDisplay }}
                            />
                        </div>
                    </React.Fragment>
                )}
                {!singularLink ? <div>(Legacy Link)</div> : null}
            </div>
            <div className={css.overlayButtons}>
                <Tooltip
                    distance={linksCount > 1 ? 10 : 30}
                    interactive
                    titleTranslationKey="STATIC.PAGES.MANAGE_LINKS.EDIT_TOOLTIP"
                >
                    <div>
                        <EditIcon
                            onClick={() => onEditClick(tagId, readOnly, absoluteLinkType === LINK_TYPE_LEGACY)}
                            className={css.overlayButton}
                        />
                    </div>
                </Tooltip>
                <PermissionsWrapper write>
                    {singularLink && type !== LINK_TYPE_MOBILE_WEB_TO_APP ? (
                        <Tooltip
                            distance={linksCount > 1 ? 10 : 30}
                            interactive
                            titleTranslationKey="STATIC.PAGES.MANAGE_LINKS.DUPLICATE_TOOLTIP"
                        >
                            <div>
                                <Icon
                                    name="copy"
                                    size={IconSizes.SMALL}
                                    variant={IconVariants.LIGHT}
                                    className={css.duplicate}
                                    onClick={onDuplicateClicked}
                                />
                            </div>
                        </Tooltip>
                    ) : null}

                    <Tooltip
                        distance={linksCount > 1 ? 10 : 30}
                        interactive
                        titleTranslationKey="STATIC.PAGES.MANAGE_LINKS.ARCHIVE_TOOLTIP"
                    >
                        <div>
                            <ArchiveIcon onClick={onArchiveClicked} className={css.overlayButton} />
                        </div>
                    </Tooltip>
                </PermissionsWrapper>
            </div>
        </div>
    );
}

LinkRowItem.propTypes = {
    type: PropTypes.string,
    agency_name: PropTypes.string,
    campaign_name: PropTypes.string,
    configured: PropTypes.objectOf(PropTypes.any),
    onEditClick: PropTypes.func,
    onDuplicateClicked: PropTypes.func,
    onArchiveClicked: PropTypes.func,
    createdDisplay: PropTypes.string,
    absolute_link_type: PropTypes.string,
    modifiedDisplay: PropTypes.string,
    singular_link: PropTypes.bool,
    tag_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    campaign_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showAgencyLabel: PropTypes.bool,
    isLast: PropTypes.bool,
    linksCount: PropTypes.number,
};

LinkRowItem.defaultProps = {
    campaign_name: '',
    configured: {},
    onEditClick: () => {},
    onDuplicateClicked: () => {},
    onArchiveClicked: () => {},
    createdDisplay: '',
    modifiedDisplay: '',
    singular_link: false,
    isLast: false,
    tag_id: '',
    campaign_id: '',
    agency_name: '',
    showAgencyLabel: true,
};

export default LinkRowItem;
