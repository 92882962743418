import React from 'react';
import { EmptyState } from '../widgets';
import css from './ReportsNoData.css';

function ReportsNoData({}) {
    return (
        <div className={css.midContainer}>
            <div className={css.noDataContainer}>
                <EmptyState icon="noChartData" header="STATIC.PAGES.REPORTS.CHART_NO_DATA" />
            </div>
        </div>
    );
}

export default ReportsNoData;
