import API from '../services/api';

let instance = null;
const api = new API();
const URL_DISTRIBUTIONS = '/api/audiences/distributions';
const URL_DISTRIBUTIONS_AD_ACCOUNT = `${URL_DISTRIBUTIONS}/adaccount`;
const URL_DISTRIBUTIONS_SEGMENT = `${URL_DISTRIBUTIONS}/segment`;
const URL_DISTRIBUTIONS_MANUAL = `${URL_DISTRIBUTIONS}/manual`;

export default class AudienceDistributionsService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getDistributions() {
        return api.basicRequest(URL_DISTRIBUTIONS);
    }

    save(network) {
        return api.basicRequest(URL_DISTRIBUTIONS, 'POST', network);
    }

    saveSegment(segment) {
        return api.basicRequest(URL_DISTRIBUTIONS_SEGMENT, 'POST', segment);
    }

    toggleAdAccount(network, account, active) {
        return api.basicRequest(URL_DISTRIBUTIONS_AD_ACCOUNT, 'POST', { network, account, active });
    }

    manualRun(uanId) {
        return api.basicRequest(URL_DISTRIBUTIONS_MANUAL, 'POST', { uan_id: uanId });
    }
}
