import { createContext, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRequest } from '../utils/customHooks';
import SKAdnetworkAPI from './service';
import Apps from '../services/apps';

const skanApi = new SKAdnetworkAPI();
const appsApi = new Apps();

export const useFetchApps = () => {
    return useRequest(useCallback(appsApi.getAttributionApps, []));
};

export const useFetchConfiguration = (appId, isFetchModelsNeeded, onFetchCleanup) => {
    return useRequest(
        useCallback(skanApi.getModelsGroupConfigurationForApp, []),
        () => {
            return !!appId && isFetchModelsNeeded;
        },
        onFetchCleanup,
        appId,
        isFetchModelsNeeded
    );
};

export const useFetchOrganizationMissingPartnersEvents = () => {
    return useRequest(useCallback(skanApi.getMissingOrganizationEventMapPartners, {}));
};

export const useFetchAppModelSummary = () => {
    return useRequest(useCallback(skanApi.getAppModelSummary, []));
};

export const useModelKinds = (typesList, initialModelKind, isAdminMode, modelTypeFilter, mixedModelTypesFilter) => {
    const [modelKinds, setModelKinds] = useState([]);

    useEffect(() => {
        setModelKinds(
            typesList.filter(modelType => {
                if (modelType.mixed_types && mixedModelTypesFilter) {
                    return mixedModelTypesFilter.find(typesFilter =>
                        typesFilter.every((type, i) => type === modelType.mixed_types[i])
                    );
                } else {
                    return (
                        ((modelTypeFilter && modelTypeFilter.includes(modelType.name)) || !modelTypeFilter) &&
                        (!modelType.admin_only || initialModelKind === modelType.name || isAdminMode)
                    );
                }
            })
        );
    }, [typesList]);

    return modelKinds;
};

export const useScrollTo = (isFlexibleMode, measurementPeriod, buckets, scrollToRef) => {
    const loaded = useRef(false);

    useLayoutEffect(() => {
        if (!isFlexibleMode || !loaded.current) {
            if (scrollToRef.current) {
                scrollToRef.current.scrollTop = scrollToRef.current.scrollHeight;
            }
        }
        loaded.current = true;
    }, [loaded.current, measurementPeriod, isFlexibleMode, buckets]);
};

export const ModelsApiContext = createContext();
