import React from 'react';
import { Translate } from 'react-localize-redux';
import parse from 'html-react-parser';
import { partnerShape } from '../types';
import css from './PartnerConfigurationShelf.css';
import InfoIcon from '../../../resources/svg/info.svg';

function PartnerAdditionalInformation({ partner }) {
    const { instructions } = partner;
    if (!instructions) {
        return null;
    }

    return (
        <div className={css.section}>
            <div className={css.title}>
                <InfoIcon className={css.icon} />
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADDITIONAL_INFORMATION" />
            </div>
            {instructions && <div>{parse(instructions)}</div>}
        </div>
    );
}

PartnerAdditionalInformation.propTypes = {
    partner: partnerShape.isRequired,
};

export default PartnerAdditionalInformation;
