import { CHART_TYPES } from '../PivotChart/consts';
import PivotChartWidget from '../../../customDashboards/pivotChartWidget';
import AgGridWidget from '../../../customDashboards/agGridWidget';
import MetricCardsWidget from '../../../customDashboards/metricCardsWidget';
import ThreeAxisChartsWidget from '../ThreeAxisChartsWidget/ThreeAxisChartsWidget';
import { QUICK_REPORTS_TYPES } from '../../../quickReports/consts';

export const GRID_COLS = 8;
export const GRID_ROW_HEIGHT = 100;
export const QUICK_REPORT_GRID_ROW_HEIGHT = 65;
export const GRID_WIDTH_OFFSPACE = 80;
export const GRID_MARGIN = 20;
export const GRID_HEADER_HEIGHT = 50;
export const GRID_WIDGET_TYPES = {
    table: AgGridWidget,
    pivot: AgGridWidget,
    metricCards: MetricCardsWidget,
    bubble: ThreeAxisChartsWidget,
    variwide: ThreeAxisChartsWidget,
    heatMapTable: AgGridWidget,
};
CHART_TYPES.forEach(x => {
    GRID_WIDGET_TYPES[x.name] = PivotChartWidget;
});

export const OPERATOR_TYPES = [
    {
        name: 'in',
        display_name: 'include',
        actual_type: 'filterOperators',
    },
    {
        name: 'not in',
        display_name: 'exclude',
        actual_type: 'filterOperators',
    },
];
export const WIDGET_VERSIONS_REFRESH_RATE = 3 * 60 * 1000;
export const FORCE_DISPLAY_UNENRICHED_REPORT_TYPES = [QUICK_REPORTS_TYPES.CAMPAIGN];
