import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import withTranslate from '../../containers/widgets/withTranslate';
import css from './DimensionDrilldownPopover.css';
import DropdownBody from '../widgets/DropdownBody';
import { buildDrilldownRedirectQuery } from '../../utils/reports';

function DimensionDrilldownPopover({
    nodeData,
    selectedFilters = [],
    currentPath,
    dimensionList,
    availableFilters,
    reportSelectedFields,
    anchorEl,
    showPopover,
    closePopover,
    translate,
}) {
    const [tooltipMsg, setTooltipMsg] = useState();
    const [tooltipAnchorEl, setTooltipAnchorEl] = useState();

    useEffect(() => {
        document.addEventListener('click', closePopover, { capture: true });

        return () => {
            document.removeEventListener('click', closePopover, { capture: true });
        };
    }, []);

    const handleDimensionSelection = event => {
        const dimensionField = dimensionList.find(item => event.target.id === item.name);

        if (!dimensionField.disabled) {
            const redirectQuery = buildDrilldownRedirectQuery(
                reportSelectedFields.dimensions,
                availableFilters,
                selectedFilters,
                nodeData,
                dimensionField
            );
            const redirectPath = dimensionField.path ? dimensionField.path : currentPath.pathname;
            const redirectHref = window.location.href.replace(currentPath.pathname, redirectPath);
            const redirectUrl = `${redirectHref.substring(0, redirectHref.indexOf('?query'))}?${redirectQuery}`;

            window.mixpanel.track('Drilldown dimension clicked', {
                report_selected_fields: reportSelectedFields,
                report_selected_filters: selectedFilters,
                drilldown_dimension: dimensionField.display_name,
            });

            window.open(redirectUrl, '_blank');
        }
    };

    const handleDimensionMouseEnter = event => {
        const dimension = dimensionList.find(item => event.target.id === item.name);
        setTooltipMsg(dimension.tooltipMsg);
        setTooltipAnchorEl(event.target);
    };

    const handleDimensionMouseLeave = () => {
        setTooltipMsg(undefined);
    };

    const items = dimensionList.map(dimension => {
        return dimension.disabled
            ? {
                  ...dimension,
                  onMouseEnter: handleDimensionMouseEnter,
                  onMouseLeave: handleDimensionMouseLeave,
              }
            : { ...dimension };
    });

    return (
        <Popper open={showPopover} anchorEl={anchorEl} placement="bottom-end">
            <div className={css.popover}>
                <label className={css.header}>{translate('STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_HEADER')}</label>
                <DropdownBody
                    hideTopBorder
                    showButton={false}
                    translate={translate}
                    items={items}
                    style={{ maxHeight: '300px' }}
                    onSelection={handleDimensionSelection}
                />
                <Popper anchorEl={tooltipAnchorEl} placement="top-start" open={!!tooltipMsg}>
                    <div className={css.tooltip}>{translate(tooltipMsg || '')}</div>
                </Popper>
            </div>
        </Popper>
    );
}

DimensionDrilldownPopover.propTypes = {
    nodeData: PropTypes.objectOf(PropTypes.any),
    selectedFilters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            operator: PropTypes.string,
            tags: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    currentPath: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }),
    dimensionList: PropTypes.arrayOf(
        PropTypes.shape({
            display_name: PropTypes.string,
            name: PropTypes.string,
            path: PropTypes.string,
            type: PropTypes.string,
            standardAnalyticsVisible: PropTypes.bool,
            tooltipMsg: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    availableFilters: PropTypes.arrayOf(PropTypes.any),
    reportSelectedFields: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    showPopover: PropTypes.bool,
    anchorEl: PropTypes.objectOf(PropTypes.any),
    closePopover: PropTypes.func,
    translate: PropTypes.func,
};

DimensionDrilldownPopover.defaultProps = {
    nodeData: {},
    selectedFilters: [],
    currentPath: {},
    dimensionList: [],
    availableFilters: [],
    reportSelectedFields: {},
    showPopover: true,
    anchorEl: {},
    closePopover: () => {},
    translate: () => {},
};

export default withTranslate(DimensionDrilldownPopover);
