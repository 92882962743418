import { connect } from 'react-redux';
import { getLinkEditorShelf } from '../selectors';
import LinkEditorShelf from '../components/LinkEditorShelf';
import { shelfGroupClicked, formUpdate, closeShelf, generateLink, updateLink, unArchiveClicked } from '../actions';

const mapDispatchToProps = dispatch => {
    return {
        onGroupClicked: (...args) => {
            dispatch(shelfGroupClicked(...args));
        },
        onFormUpdate: (...args) => {
            dispatch(formUpdate(...args));
        },
        onCloseShelf: (...args) => {
            dispatch(closeShelf());
        },
        onGenerate: (...args) => {
            dispatch(generateLink(...args));
        },
        onUpdate: (...args) => {
            dispatch(updateLink(...args));
        },
        unArchiveClicked: fieldValues => {
            const { linkId } = fieldValues;
            dispatch(unArchiveClicked(linkId));
        },
    };
};

export default connect((state, ownProps) => {
    return {
        ...ownProps,
        ...getLinkEditorShelf(state),
    };
}, mapDispatchToProps)(LinkEditorShelf);
