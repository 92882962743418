const NETWORK_STATUS_ACTIVE = 'active';
const NETWORK_STATUS_INACTIVE = 'inactive';
const NETWORK_STATUS_NOT_CONFIGURED = 'notConfigured';

export const NETWORK_GROUPS = [NETWORK_STATUS_ACTIVE, NETWORK_STATUS_INACTIVE, NETWORK_STATUS_NOT_CONFIGURED];

export function determineIfActiveSegments(network) {
    if (!network.distribution || !network.distribution.active || !network.distribution.configured_segments) {
        return false;
    }
    return Object.values(network.distribution.configured_segments).some(configuredSegment => configuredSegment.active);
}

export function sortNetworks(networks) {
    const statusResult = Object.values(networks).reduce(
        (status, current) => {
            if (!current.distribution) {
                status.notConfigured.push(current);
            } else if (determineIfActiveSegments(current)) {
                status.active.push(current);
            } else {
                status.inactive.push(current);
            }
            return status;
        },
        {
            [NETWORK_STATUS_ACTIVE]: [],
            [NETWORK_STATUS_INACTIVE]: [],
            [NETWORK_STATUS_NOT_CONFIGURED]: [],
        }
    );
    return [NETWORK_STATUS_ACTIVE, NETWORK_STATUS_INACTIVE, NETWORK_STATUS_NOT_CONFIGURED].reduce(
        (total, statusType) => {
            return total.concat(
                statusResult[statusType].map(network => {
                    network.group = statusType;
                    return network;
                })
            );
        },
        []
    );
}
