import React from 'react';
import { EmptyStateTasks } from '../../components/partials/EmptyState/utils';
import IOSLogo from '../../resources/icons/os/ios.svg';
import AndroidLogo from '../../resources/icons/os/android.svg';
import { createArrayOfObjectsFromKeys } from '../../utils/arrays';
import {
    IS_AD_MONETIZATION_BASED,
    IS_RUNNING_SKAN_CAMPAIGNS,
    IS_SHOWING_ATT_PROMPT,
    IS_USING_DEEP_LINKING_TRACKING_LINKS,
    SELECT_MEDIATION_PLATFORM,
} from '../../onboardingGuide/components/sdkConfiguration/consts';

export const TEST_A_SESSION = 'TEST_A_SESSION';
export const DEEP_LINKING = 'DEEP_LINKING';
export const SDK_INTEGRATION_SECTIONS = Object.freeze({
    GET_SDK_FILES: 'GET_SDK_FILES',
    ANDROID_MANIFEST_SETTINGS: 'ANDROID_MANIFEST_SETTINGS',
    CONFIGURE_THE_SDK: 'CONFIGURE_THE_SDK',
    IMPORT_LIBRARY: 'IMPORT_LIBRARY',
    CREATE_A_CONFIGURATION_OBJECT: 'CREATE_A_CONFIGURATION_OBJECT',
    DEEP_LINKING: 'DEEP_LINKING',
    CUSTOM_USER_ID: 'CUSTOM_USER_ID',
    INITIATE_THE_CODE: 'INITIATE_THE_CODE',
    TEST_A_SESSION,
    ATT_PROMPT: 'ATT_PROMPT',
    SKAN_ENABLED: 'SKAN_ENABLED',
    INITIALIZATION: 'INITIALIZATION',
    USER_ACTIVITY: 'USER_ACTIVITY',
    HANDLING_DEEPLINKS: 'HANDLING_DEEPLINKS',
    EVENTS: 'EVENTS',
    ADD_EVENTS: 'ADD_EVENTS',
    TEST_EVENTS: 'TEST_EVENTS',
    REVENUE: 'REVENUE',
    REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT: 'REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT',
    REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT: 'REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT',
    REVENUE_ANDROID_WITH_PURCHASE_RECEIPT: 'REVENUE_ANDROID_WITH_PURCHASE_RECEIPT',
    REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT: 'REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT',
    TEST_REVENUE: 'TEST_REVENUE',
    AD_MONETIZATION: 'AD_MONETIZATION',
});

export const SDK_PLANNING_EMPTY_STATE_TASKS = [
    {
        topic: EmptyStateTasks.ADD_APP,
        name: 'STATIC.EMPTY_STATE.TASKS.ADD_APP_TASK',
        goRedirect: '#/apps?new-app=1',
    },
    {
        topic: EmptyStateTasks.SDK_INTEGRATED,
        name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK_SDK_KEYS_PAGE',
    },
];

export const IOS_NATIVE = 'IOS_NATIVE';
export const ANDROID_NATIVE = 'ANDROID_NATIVE';
export const ANDROID = 'ANDROID';
export const IOS = 'IOS';
export const SWIFT = 'swift';
export const KOTLIN = 'kotlin';
export const GRADLE = 'gradle';
export const XML = 'xml';
export const JAVA = 'java';
export const OTHER_HELP_CENTER = 'OTHER_HELP_CENTER';
export const TARGET_PLATFORMS_OPTIONS = [IOS_NATIVE, ANDROID_NATIVE];

export const TARGET_PLATFORMS_LOGOS = {
    [IOS_NATIVE]: IOSLogo,
    [ANDROID_NATIVE]: AndroidLogo,
};

export const TARGET_PLATFORM_TO_PARAM = {
    [IOS_NATIVE]: 'ios',
    [ANDROID_NATIVE]: 'android',
};

export const TARGET_PLATFORM_SHORT_NAME = {
    [IOS_NATIVE]: IOS,
    [ANDROID_NATIVE]: ANDROID,
};

const TARGET_PLATFORM_HELP_CENTER_ITEM = {
    name: OTHER_HELP_CENTER,
    display_name: `STATIC.PAGES.SDK.SDK_PLANNING.TARGET_PLATFORM_OPTIONS.${OTHER_HELP_CENTER}`,
    link: 'https://support.singular.net/hc/en-us/categories/360002441132-Singular-SDK-and-S2S',
};

const TARGET_PLATFORMS_DROPDOWN_ITEMS = createArrayOfObjectsFromKeys(TARGET_PLATFORMS_OPTIONS, targetPlatform => {
    const TargetPlatformLogo = TARGET_PLATFORMS_LOGOS[targetPlatform];

    return {
        name: targetPlatform,
        display_name: `STATIC.PAGES.SDK.SDK_PLANNING.TARGET_PLATFORM_OPTIONS.${targetPlatform}`,
        icon: (TargetPlatformLogo && <TargetPlatformLogo />) || null,
    };
});

export const TARGET_PLATFORMS_ALL_DROPDOWN_ITEMS = TARGET_PLATFORMS_DROPDOWN_ITEMS.concat([
    TARGET_PLATFORM_HELP_CENTER_ITEM,
]);

export const PLATFORM_MAPPING_FOR_SDK_CONFIGURATION = {
    iOS: IOS_NATIVE,
    Android: ANDROID_NATIVE,
};

export const SDK_CONFIG_QUESTIONS_MAPPING = {
    [IOS_NATIVE]: {
        [IS_SHOWING_ATT_PROMPT]: [SDK_INTEGRATION_SECTIONS.ATT_PROMPT],
        [IS_RUNNING_SKAN_CAMPAIGNS]: [SDK_INTEGRATION_SECTIONS.SKAN_ENABLED],
        [IS_USING_DEEP_LINKING_TRACKING_LINKS]: [
            SDK_INTEGRATION_SECTIONS.DEEP_LINKING,
            SDK_INTEGRATION_SECTIONS.HANDLING_DEEPLINKS,
        ],
        [IS_AD_MONETIZATION_BASED]: [SDK_INTEGRATION_SECTIONS.AD_MONETIZATION],
    },
    [ANDROID_NATIVE]: {
        [IS_USING_DEEP_LINKING_TRACKING_LINKS]: [SDK_INTEGRATION_SECTIONS.DEEP_LINKING],
        [IS_AD_MONETIZATION_BASED]: [SDK_INTEGRATION_SECTIONS.AD_MONETIZATION],
        [SELECT_MEDIATION_PLATFORM]: [SDK_INTEGRATION_SECTIONS.AD_MONETIZATION],
    },
};

export const SDK_CONFIG_QUESTIONS_DATA_MAPPING = {
    [IOS_NATIVE]: {
        [SELECT_MEDIATION_PLATFORM]: [SDK_INTEGRATION_SECTIONS.AD_MONETIZATION],
    },
    [ANDROID_NATIVE]: {
        [SELECT_MEDIATION_PLATFORM]: [SDK_INTEGRATION_SECTIONS.AD_MONETIZATION],
    },
};

export const SDK_CONFIG_QUESTIONS_METADATA_WITH_CHOICES = [SELECT_MEDIATION_PLATFORM];

export const TEST_A_SESSION_SECTIONS_CONFIG = {
    TEST_EVENTS: SDK_INTEGRATION_SECTIONS.TEST_EVENTS,
    TEST_A_SESSION: SDK_INTEGRATION_SECTIONS.TEST_A_SESSION,
};

export const SDK_PLANNING_EVENT_PREFIX = 'SDK Planning';

export const SDK_PLANNING_PAGE_SECTIONS_STATE_LOCAL_STORAGE = 'sdkPlanningPageSectionsState';
