import API from '../services/api';

let instance = null;
const api = new API();
const MMM_METADATA_URL = '/api/media-mix-modeling/metadata';
const MMM_DATA_URL = `/api/media-mix-modeling/data`;

export default class MMMService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getMMMMetadata(viewName) {
        return api.getJson(MMM_METADATA_URL, {
            method: 'GET',
            credentials: 'include',
            params: { view: viewName }, // TODO: Remove viewName
        });
    }

    getMMMData(selectedFilterValues) {
        return api.getJson(MMM_DATA_URL, {
            method: 'GET',
            credentials: 'include',
            params: { ...selectedFilterValues },
        });
    }
}
