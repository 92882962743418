import React from 'react';
import { withLocalize } from 'react-localize-redux';
import posed, { PoseGroup } from 'react-pose';
import css from './NetworkGrid.css';
import * as networkImages from '../../resources/png/networks';
import { determineIfActiveSegments, NETWORK_GROUPS } from '../utils';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => {
            return i * 50;
        },
    },
    exit: { opacity: 0, y: 20 },
});

class NetworkGrid extends React.PureComponent {
    render() {
        const { networks, translate, editStart } = this.props;
        let cols = 0;
        return (
            <div className={css.networks}>
                {NETWORK_GROUPS.map((group) => {
                    const groupNetworks = networks.filter((network) => network.group === group);
                    return (
                        <PoseGroup key={`network_group_${group}`} animateOnMount>
                            {groupNetworks.map((network) => {
                                const active = determineIfActiveSegments(network);
                                return (
                                    <AnimationItem key={network.name} i={cols++} className={css.networkContainer}>
                                        <div className={css.network}>
                                            <div
                                                role="button"
                                                className={`${css.networkInner} ${
                                                    network.distribution ? css.configured : ''
                                                }`}
                                                onClick={() => {
                                                    editStart(network);
                                                }}
                                                tabIndex={0}
                                            >
                                                <img alt={network.name} src={networkImages[network.name]} />
                                                {network.distribution && (
                                                    <div className={css.activity}>
                                                        <div
                                                            className={`${css.activityDot} ${active ? css.active : ''}`}
                                                        />
                                                        <div className={css.activityLabel}>
                                                            {active ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={css.networkConfigure}>
                                                    {translate('STATIC.PAGES.AUDIENCES.CONFIGURE_NETWORK')}
                                                </div>
                                            </div>
                                        </div>
                                    </AnimationItem>
                                );
                            })}
                        </PoseGroup>
                    );
                })}
            </div>
        );
    }
}

export default withLocalize(NetworkGrid);
