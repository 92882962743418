import { createSelector } from 'reselect';
import { reportTypes } from '../utils/reports';

export const normalizeAccountTypeName = accountTypeName => (accountTypeName || '').toLowerCase().replace(/ /g, '_');

const ACCOUNT_DEMO = 'demo';
const ACCOUNT_AD_NETWORK = 'ad_network';
export const AGENCY_ACCOUNT = 'agency_account';

export const getIsBlocked = state => {
    return state.user.data?.should_block_app;
};

export const getIsOrgAdmin = state => {
    return state.user.data?.is_org_admin;
};

export const getAdminModeEnabled = state => {
    return state.user.adminMode;
};

export const getUserData = state => {
    return state.user.data;
};

export const getFieldsAvailabilityEnabled = (state, type) => {
    return (
        [reportTypes.skan, reportTypes.mainReport, reportTypes.pivot].includes(type) &&
        (getUserData(state).is_fields_availability_enable || state.user.adminMode)
    );
};

export const getIsUserAdmin = state => {
    return getUserData(state).is_admin;
};

export const getUserFeatureFlags = state => {
    return getUserData(state).feature_flags;
};

export const getOrganizations = state => {
    return state.user.organizations;
};

export const getImpersonateOrgDialog = state => {
    return state.user.impersonateOrgDialog;
};

export const getChangePasswordDialog = state => {
    return state.user.changePasswordDialog;
};

export const isUserTiEnabled = state => {
    return getUserData(state).is_ti_enabled;
};

export const showPivotCharts = state => {
    return getUserData(state).show_pivot_charts;
};

export const getUserPermissionDimensions = state => {
    return getUserData(state).permission_dimensions || [];
};

export const isShelfReporting = (state, reportType) => {
    return reportType === reportTypes.mainReport && getUserData(state).is_shelf_reporting;
};

export const getPermissionKeys = state => {
    return getUserData(state).permission_keys;
};

export const getImpersonateDialog = createSelector(
    [getOrganizations, getImpersonateOrgDialog],
    (organizations, impersonateDialog) => ({
        organizations,
        ...impersonateDialog,
    })
);

export const isUserAdNetwork = createSelector([getUserData], userData => {
    const accountType = normalizeAccountTypeName(userData.account_type_name);
    return accountType === ACCOUNT_AD_NETWORK;
});

export const getIsAgency = createSelector([getUserData], userData => {
    return normalizeAccountTypeName(userData.account_type_name) === AGENCY_ACCOUNT;
});

export const shouldShowTrialStatusPanel = state => {
    return state.user.showTrialStatusPanel;
};

export const getTrialRealDaysLeft = state => {
    return state.user.trialRealDaysLeft;
};

export const getIsTrialUser = state => {
    return !!getUserData(state).trial;
};

export const getOrganizationTier = state => {
    return getUserData(state).organization_tier;
};

export const getOrganizationIsWeb = state => {
    return getUserData(state).is_web;
};

export const getOrganizationAvailablePlatforms = state => {
    return getUserData(state).available_platforms;
};

export const getOrganizationLogo = state => {
    return getUserData(state).company_logo;
};

export const getUserCurrency = state => {
    return getUserData(state).currency;
};

export const getDisplayUnenriched = state => {
    const userData = getUserData(state);
    return userData.display_unenriched || userData.display_unenriched_by_default;
};
