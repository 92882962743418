import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { useSearchParams } from 'react-router-dom';
import {
    DELETE_CONFIGURATION,
    GET_CONFIGURED_PARTNERS,
    GET_ORGANIZATION_OPTIONS,
    GET_PARTNER_CONFIGURATIONS_FOR_PARTNER,
} from '../queries';
import { Spinner, Table, TableCell, TableCellIcon, TableCellImgLabel } from '../../components/widgets';
import css from './AppSitesTable.css';
import EditIcon from '../../resources/svg/edit.svg';
import VisibleIcon from '../../resources/svg/visible.svg';
import TrashIcon from '../../resources/svg/trash.svg';
import TableCellWithSubTextAndIcon from '../../components/widgets/TableCellWithSubTextAndIcon';
import AgencyIcon from '../../../../dashboard/img/briefcase.svg';
import { getIsAgency, isUserAdNetwork } from '../../selectors/user';
import { sortAlphabeticallyLowecase, sortDate, sortNumeric } from '../../utils/sortUtil';
import GeneralPopup, { PopupTypes } from '../../components/partials/GeneralPopup';
import { partnerShape } from './types';
import { trackPartnerConfigMixpanelEvent } from './shelf/utils';
import { OSIcons } from '../../utils/OS';
import { getUseNewShelfOption } from '../../utils/adNetworks';
import Icon, { IconSizes } from '../../components/foundations/Icon';

export const TABLE_DATA_TEST_ID = 'app-sites-table';

const AppSiteField = {
    OS: 'os',
    NAME: 'appName',
    CLICK_THROUGH_WINDOW: 'clickThroughWindow',
    LAST_MODIFIED: 'lastModified',
};

const getHoverTextForPostbackType = (isAttributed, isEvent) => {
    if (isAttributed) {
        return 'STATIC.PAGES.PARTNER_CONFIGURATION.ATTRIBUTED';
    }

    return isEvent
        ? 'STATIC.PAGES.PARTNER_CONFIGURATION.ALL_USERS_EVENT'
        : 'STATIC.PAGES.PARTNER_CONFIGURATION.ALL_USERS';
};

const getPostbackAttributedText = isSendAll =>
    isSendAll
        ? 'STATIC.PAGES.PARTNER_CONFIGURATION.ATTRIBUTED_TYPES.ALL_USERS'
        : 'STATIC.PAGES.PARTNER_CONFIGURATION.ATTRIBUTED_TYPES.ATTRIBUTED';

const shouldShowEditMode = (partnerConfiguration, isAgency, isPartner, editAgencyConfigurationSupported) =>
    (!partnerConfiguration.agencyName ||
        (partnerConfiguration.agencyName && isAgency) ||
        editAgencyConfigurationSupported) &&
    partnerConfiguration.siteId &&
    !partnerConfiguration.isCustom &&
    !isPartner;

const getActionComponentsConfigs = (
    isAgency,
    isPartner,
    isNewShelf,
    onDuplicateClicked,
    onEditClicked,
    onDeleteClicked,
    editAgencyConfigurationSupported
) => [
    {
        ActionComponent: VisibleIcon,
        key: 'viewConfiguration',
        title: 'viewConfiguration',
        onClick: onEditClicked,
        shouldShow: partnerConfiguration =>
            ((partnerConfiguration.agencyName && !isAgency && !editAgencyConfigurationSupported) ||
                partnerConfiguration.isCustom ||
                isPartner) &&
            partnerConfiguration.siteId,
        tooltipText: 'STATIC.PAGES.PARTNER_CONFIGURATION.VIEW_PARTNER_APP_SITE_TOOLTIP',
        shouldTranslateToolTip: true,
    },
    {
        ActionComponent: props => <Icon name="copy" size={IconSizes.SMALL} {...props} />,
        key: 'duplicateConfiguration',
        title: 'duplicateConfiguration',
        onClick: onDuplicateClicked,
        tooltipText: 'STATIC.PAGES.PARTNER_CONFIGURATION.DUPLICATE_PARTNER_APP_SITE_TOOLTIP',
        shouldShow: config => isNewShelf && shouldShowEditMode(config, isAgency, isPartner),
        shouldTranslateToolTip: true,
    },
    {
        ActionComponent: EditIcon,
        key: 'editConfiguration',
        title: 'editConfiguration',
        onClick: onEditClicked,
        tooltipText: 'STATIC.PAGES.PARTNER_CONFIGURATION.EDIT_PARTNER_APP_SITE_TOOLTIP',
        shouldShow: config => shouldShowEditMode(config, isAgency, isPartner, editAgencyConfigurationSupported),
        shouldTranslateToolTip: true,
    },
    {
        ActionComponent: TrashIcon,
        key: 'deleteConfiguration',
        title: 'deleteConfiguration',
        onClick: onDeleteClicked,
        shouldShow: partnerConfiguration =>
            (!partnerConfiguration.agencyName || (partnerConfiguration.agencyName && isAgency)) &&
            !isPartner &&
            partnerConfiguration.siteId,
        tooltipText: 'STATIC.PAGES.PARTNER_CONFIGURATION.DELETE_PARTNER_APP_SITE_TOOLTIP',
        shouldTranslateToolTip: true,
    },
];

const getSortedFieldArrow = (field, sortOptions) => {
    if (sortOptions.field === field) {
        return sortOptions.ascending ? 'up' : 'down';
    }
};

const getTableFieldProps = (fieldName, sortOptions = null, onSortChanged = null) => ({
    name: fieldName,
    headerAlign: 'center',
    boldHeader: true,
    ...(!sortOptions
        ? {}
        : {
              showHeaderArrow: true,
              headerArrow: getSortedFieldArrow(fieldName, sortOptions),
              headerClick: onSortChanged,
          }),
});

const getTableMetadata = (ldsSupported, sortOptions, onSortChanged) => {
    const mandatoryFields = [
        {
            ...getTableFieldProps(),
            displayName: '',
            cellComponent: TableCellImgLabel,
            cellValues: [{ propName: 'imgSrc', dataKey: 'icon' }],
            cellProps: { tdClassName: css.appImageCell, imgClassName: css.appImage },
        },
        {
            ...getTableFieldProps(AppSiteField.OS, sortOptions, onSortChanged),
            displayName: 'STATIC.PLATFORM',
            cellComponent: TableCellIcon,
            cellProps: {
                tdClassName: css.osAndDateCell,
                shouldTranslateToolTip: true,
                tooltip: {
                    iOS: 'STATIC.PLATFORMS.IOS',
                    Android: 'STATIC.PLATFORMS.ANDROID',
                    Web: 'STATIC.PLATFORMS.WEB',
                    Playstation: 'STATIC.PLATFORMS.PLAYSTATION',
                    Xbox: 'STATIC.PLATFORMS.XBOX',
                    PC: 'STATIC.PLATFORMS.PC',
                    CTV: 'STATIC.PLATFORMS.CTV',
                    MetaQuest: 'STATIC.PLATFORMS.METAQUEST',
                    Nintendo: 'STATIC.PLATFORMS.NINTENDO',
                },
                iconElement: Object.entries(OSIcons).reduce(
                    (total, [os, Icon]) => ({ ...total, [os]: <Icon className={css.osIcon} title={os} /> }),
                    {}
                ),
            },
        },
        {
            ...getTableFieldProps(AppSiteField.NAME, sortOptions, onSortChanged),
            displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.TABLE_COLUMNS.APP_SITE',
            cellComponent: TableCellWithSubTextAndIcon,
            cellValues: [{ propName: 'elements', dataKey: 'site' }],
            cellProps: { className: css.appNameCell },
        },
        {
            ...getTableFieldProps(AppSiteField.CLICK_THROUGH_WINDOW, sortOptions, onSortChanged),
            displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.TABLE_COLUMNS.CLICK_THROUGH_WINDOW',
            cellComponent: TableCell,
            cellProps: { className: css.tableCellContainer },
        },
        {
            ...getTableFieldProps(),
            displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.TABLE_COLUMNS.POSTBACKS_TO_PARTNER',
            cellComponent: TableCellWithSubTextAndIcon,
            cellProps: { tdClassName: css.tableCellContainer, className: css.postbacksCellContainer },
            cellValues: [{ propName: 'elements', dataKey: 'postbacks' }],
        },
    ];

    const optionalFields = ldsSupported
        ? [
              {
                  ...getTableFieldProps(),
                  displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.TABLE_COLUMNS.PRIVACY',
                  cellComponent: TableCellWithSubTextAndIcon,
                  cellProps: { tdClassName: css.tableCellContainer, className: css.tableCellContainer },
                  cellValues: [{ propName: 'elements', dataKey: 'ldsElement' }],
              },
          ]
        : [];

    return [
        ...mandatoryFields,
        ...optionalFields,
        {
            ...getTableFieldProps(AppSiteField.LAST_MODIFIED, sortOptions, onSortChanged),
            displayName: 'STATIC.PAGES.PARTNER_CONFIGURATION.TABLE_COLUMNS.LAST_EDITED',
            cellComponent: TableCell,
            cellProps: { className: css.osAndDateCell },
            cellValues: [{ propName: 'rightCellElement', dataKey: 'agency' }],
        },
    ];
};

const getLdsCell = (restrictLds, translate) => [
    {
        title: restrictLds
            ? translate('STATIC.PAGES.PARTNER_CONFIGURATION.RESTRICTED')
            : translate('STATIC.PAGES.PARTNER_CONFIGURATION.NOT_RESTRICTED'),
        titleClassName: restrictLds ? css.title : css.disabledTitle,
        subText: restrictLds ? translate('STATIC.PAGES.PARTNER_CONFIGURATION.LIMITED_DATA_SHARING') : null,
        subTextClassName: css.subText,
    },
];

const getPostbackElement = (title, isExists, isSendAll, isEvent, partnerName, translate) => ({
    title,
    titleClassName: isExists ? css.title : css.disabledTitle,
    subText: isExists ? translate(getPostbackAttributedText(isSendAll)) : null,
    cellTooltipKey: isExists ? getHoverTextForPostbackType(!isSendAll, isEvent) : null,
    cellTooltipData: isExists ? { partnerName } : null,
    subTextClassName: css.subText,
});

const isSendAll = postbacks => postbacks?.every(postback => postback.sendAll);

const getPostbacksCell = (configuredAppSite, partnerName, translate) => {
    const { install, reengagement, revenueEvents, customEvents } = configuredAppSite.postbacks;

    return [
        getPostbackElement(
            translate('STATIC.PAGES.PARTNER_CONFIGURATION.POSTBACK_TYPES.INSTALL'),
            install,
            install?.sendAll,
            false,
            partnerName,
            translate
        ),
        getPostbackElement(
            translate('STATIC.PAGES.PARTNER_CONFIGURATION.POSTBACK_TYPES.REENGAGEMENT'),
            reengagement,
            reengagement?.sendAll,
            false,
            partnerName,
            translate
        ),
        customEvents?.length
            ? {
                  title: translate('STATIC.PAGES.PARTNER_CONFIGURATION.POSTBACK_TYPES.EVENTS', {
                      eventsCount: customEvents.length,
                  }),
                  titleClassName: css.title,
                  headerTooltip: customEvents.map(postback => postback.eventName).join(', '),
                  subText: translate(getPostbackAttributedText(isSendAll(customEvents))),
                  subTextTooltipKey: getHoverTextForPostbackType(!isSendAll(customEvents), true),
                  subTextTooltipData: { partnerName },
                  subTextClassName: css.subText,
              }
            : {
                  title: translate('STATIC.PAGES.PARTNER_CONFIGURATION.POSTBACK_TYPES.NO_EVENTS'),
                  titleClassName: css.disabledTitle,
              },
        getPostbackElement(
            translate('STATIC.PAGES.PARTNER_CONFIGURATION.POSTBACK_TYPES.REVENUE'),
            revenueEvents?.length,
            isSendAll(revenueEvents),
            true,
            partnerName,
            translate
        ),
    ];
};

const getAppSiteCell = (data, filter, translate) => [
    {
        filter,
        title: data.isCustom ? `${data.appName} (Custom)` : data.appName,
        titleClassName: css.title,
        subText: data.longname,
        subTextClassName: css.subText,
        cellTooltipKey: !data.siteId ? translate('STATIC.PAGES.PARTNER_CONFIGURATION.MISSING_SITE') : null,
    },
];

const getAgencyCell = agencyName => ({
    text: agencyName,
    icon: <AgencyIcon />,
});

const getFormattedPartnerConfigurations = sites =>
    sites.map(site => ({
        ...site,
        appName: site.appName || 'Unavailable',
        clickThroughWindow: site.windows?.installClickWindowDeterministic,
    }));

const sortPartnerConfigurations = (data, sortOptions) => {
    const { field, ascending } = sortOptions;
    if (field === AppSiteField.CLICK_THROUGH_WINDOW) {
        return data.sort((a, b) => sortNumeric(a[field], b[field], ascending));
    } else if (field === AppSiteField.LAST_MODIFIED) {
        return data.sort((a, b) => sortDate(a[field], b[field], !ascending));
    }

    return data.sort((a, b) => sortAlphabeticallyLowecase(a[field], b[field], !ascending));
};

function AppSitesTable({ partner, filter, translate, onEditAppSite, onDuplicateAppSite }) {
    const [sortOptions, setSortOptions] = useState({ field: AppSiteField.NAME, ascending: true });
    const [searchParams, setSearchParams] = useSearchParams();
    const [siteForDeletion, setSiteForDeletion] = useState(null);
    const organizationOptionsResponse = useQuery(GET_ORGANIZATION_OPTIONS);
    const { ldsSupported = false, editAgencyConfigurationSupported = false } = organizationOptionsResponse.data || {};
    const isNewShelf = getUseNewShelfOption(organizationOptionsResponse.data, partner);

    const [deletePartnerConfiguration, deletePartnerConfigurationResponse] = useMutation(DELETE_CONFIGURATION, {
        variables: { partnerId: partner.id, siteId: siteForDeletion },
    });

    const partnerConfigurationsResponse = useQuery(GET_PARTNER_CONFIGURATIONS_FOR_PARTNER, {
        variables: { partnerId: partner.id },
    });
    const [, configuredPartnerResponse] = useLazyQuery(GET_CONFIGURED_PARTNERS);

    const isAgency = useSelector(state => getIsAgency(state));
    const isPartner = useSelector(state => isUserAdNetwork(state));

    // we got back from angular modal after saving a partner configuration
    useEffect(() => {
        const refresh = searchParams.get('refresh');
        if (refresh) {
            partnerConfigurationsResponse.refetch();
            searchParams.delete('refresh');
            setSearchParams(searchParams);
        }
    }, [partnerConfigurationsResponse]);

    useEffect(() => {
        const { called, loading, data } = deletePartnerConfigurationResponse;
        if (!called || loading) {
            return;
        }

        if (data?.deletePartnerConfiguration?.ok) {
            partnerConfigurationsResponse.refetch();
            configuredPartnerResponse.refetch();
        }

        setSiteForDeletion(null);
        deletePartnerConfigurationResponse.reset();
    }, [deletePartnerConfigurationResponse]);

    const onSortChanged = field => {
        trackPartnerConfigMixpanelEvent('Sort', partner, null, { column: field });
        setSortOptions({ field, ascending: sortOptions.field === field ? !sortOptions.ascending : true });
    };

    const onDuplicateClicked = ({ siteId, agencyId }) => {
        trackPartnerConfigMixpanelEvent('Duplicate Clicked', partner);
        onDuplicateAppSite(partner.id, siteId, agencyId);
    };

    const onEditClicked = ({ siteId, agencyId }) => {
        trackPartnerConfigMixpanelEvent('Edit Clicked', partner);
        onEditAppSite(partner.id, siteId, agencyId);
    };

    const onDeleteClicked = ({ siteId }) => setSiteForDeletion(siteId);
    const onDeleteApproved = async () => {
        trackPartnerConfigMixpanelEvent('Delete', partner);
        await deletePartnerConfiguration();
    };

    if (partnerConfigurationsResponse.loading || organizationOptionsResponse.loading) {
        return <Spinner show expanded />;
    }

    const { partnerConfigurations = [] } = partnerConfigurationsResponse.data || {};

    let filteredPartnerConfigurations = !filter
        ? partnerConfigurations
        : partnerConfigurations.filter(
              ({ appName, longname }) =>
                  (appName && appName.toLowerCase().includes(filter.toLowerCase())) ||
                  (longname && longname.toLowerCase().includes(filter.toLowerCase()))
          );

    filteredPartnerConfigurations = getFormattedPartnerConfigurations(filteredPartnerConfigurations);
    const sortedPartnerConfigurations = sortPartnerConfigurations([...filteredPartnerConfigurations], sortOptions);
    const tableMetadata = getTableMetadata(ldsSupported, sortOptions, onSortChanged);

    const tableData = sortedPartnerConfigurations.map(data => ({
        ...data,
        disabled: !data.siteId,
        site: getAppSiteCell(data, filter, translate),
        clickThroughWindow: data.clickThroughWindow ? `${data.clickThroughWindow} Days` : null,
        postbacks: getPostbacksCell(data, partner.displayName, translate),
        agency: data.agencyName ? getAgencyCell(data.agencyName) : null,
        lastModified: moment(data.lastModified).format('MMM D, YYYY'),
        ...(ldsSupported ? { ldsElement: getLdsCell(data.ldsOptions.restrictLds, translate) } : {}),
    }));

    return (
        <>
            <div className={css.sitesTable}>
                <Table
                    dataTestId={TABLE_DATA_TEST_ID}
                    data={tableData}
                    metadata={tableMetadata}
                    trClass={css.tableCell}
                    theadClass={css.tableHeader}
                    zebraTable={false}
                    actionComponentsConfigs={getActionComponentsConfigs(
                        isAgency,
                        isPartner,
                        isNewShelf,
                        onDuplicateClicked,
                        onEditClicked,
                        onDeleteClicked,
                        editAgencyConfigurationSupported
                    )}
                />
            </div>
            <GeneralPopup
                open={!!siteForDeletion}
                type={PopupTypes.DELETE}
                title="STATIC.PAGES.PARTNER_CONFIGURATION.DELETE_CONFIGURATION_MESSAGE"
                acceptText="STATIC.BUTTONS.DELETE"
                onAccept={onDeleteApproved}
                onReject={() => setSiteForDeletion(null)}
                loading={deletePartnerConfigurationResponse.loading}
            />
        </>
    );
}

AppSitesTable.propTypes = {
    partner: partnerShape.isRequired,
    filter: PropTypes.string,
    translate: PropTypes.func,
    onEditAppSite: PropTypes.func,
    onDuplicateAppSite: PropTypes.func,
};

AppSitesTable.defaultProps = {
    filter: '',
    translate: () => {},
    onEditAppSite: () => {},
    onDuplicateAppSite: () => {},
};

export default withLocalize(AppSitesTable);
