import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './CircularPlusButton.css';

import CirclePlusIcon from '../../resources/svg/circle_plus.svg';

function CircularPlusButton({ className, onClick, disabled }) {
    return (
        <CirclePlusIcon
            className={classNames(css.circularPlusButton, className, { [css.disabled]: disabled })}
            onClick={onClick}
        />
    );
}

CircularPlusButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

CircularPlusButton.defaultProps = {
    className: '',
    onClick: () => {},
    disabled: false,
};

export default CircularPlusButton;
