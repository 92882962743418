import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '../../components/widgets';
import css from './ProductSelect.css';
import { defaultActionOnProductSelected } from '../utils';

const ProductSelect = ({
    selectedProductId,
    productData,
    onProductSelected = defaultActionOnProductSelected,
    disabled,
}) => {
    const shapedProductsArray = productData.map(product => {
        return {
            display_name: product.name,
            ...product,
        };
    });

    return (
        <Dropdown
            disabled={disabled}
            containerClass={css.productsDropdown}
            items={shapedProductsArray}
            selected={shapedProductsArray.find(item => item.product_id === selectedProductId)}
            onSelection={product => {
                onProductSelected(product);
            }}
        />
    );
};

ProductSelect.propTypes = {
    selectedProductId: PropTypes.string,
    productData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            product_id: PropTypes.string,
            amount: PropTypes.number,
        })
    ),
    onProductSelected: PropTypes.func,
    disabled: PropTypes.bool,
};

ProductSelect.defaultProps = {
    selectedProductId: '',
    productData: [],
    disabled: false,
};
export default ProductSelect;
