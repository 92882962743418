import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './ProgressBar.css';

const valueSigns = {
    money: '$',
    number: '',
};

function ProgressBar({
    progressPercentage,
    barHeight,
    barWidth,
    containerColor,
    fillColor,
    showLegend,
    range,
    valueType,
}) {
    const normalizedPercentage = Math.min(100, Math.max(0, progressPercentage));
    const calcTransformForLegend = () => {
        const maxTransform = 100;
        if (normalizedPercentage <= 4) {
            return maxTransform - 10 * normalizedPercentage;
        }
        if (normalizedPercentage >= 96) {
            return 10 * (100 - normalizedPercentage);
        }
        return maxTransform / 2;
    };

    const dynamicCSSVariables = {
        '--legend-transform-percent': `${calcTransformForLegend()}%`,
        '--bar-height': barHeight,
        '--bar-width': barWidth,
        '--container-color': containerColor,
        '--fill-color': fillColor,
        '--fill-width': `${normalizedPercentage}%`,
        '--legend-top': `-${parseInt(barHeight, 10) + 8}px`,
    };
    return (
        <div
            style={dynamicCSSVariables}
            className={classnames([
                css.progressBarContainer,
                {
                    [css.withLegend]: showLegend,
                },
            ])}
        >
            <div className={css.filler}>
                {showLegend && (
                    <div className={css.legend}>
                        <span className={css.legendText}>{`${progressPercentage}%`}</span>
                    </div>
                )}
            </div>
            {range && range.length === 2 && (
                <div className={css.rangeBar}>
                    <span>{`${valueSigns[valueType]}${range[0].toLocaleString()}`}</span>
                    <span>{`${valueSigns[valueType]}${range[1].toLocaleString()}`}</span>
                </div>
            )}
        </div>
    );
}

ProgressBar.propTypes = {
    progressPercentage: PropTypes.number.isRequired,
    barHeight: PropTypes.string,
    containerColor: PropTypes.string,
    fillColor: PropTypes.string,
    barWidth: PropTypes.string,
    showLegend: PropTypes.bool,
    range: PropTypes.arrayOf(PropTypes.number),
    valueType: PropTypes.oneOf(['number', 'money']),
};

ProgressBar.defaultProps = {
    barHeight: '20px',
    containerColor: 'var(--gray400)',
    fillColor: 'var(--blue300)',
    barWidth: '100%',
    showLegend: false,
    range: null,
    valueType: 'number',
};

export default ProgressBar;
