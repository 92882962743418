import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import { Translate, withLocalize } from 'react-localize-redux';
import {
    Spinner,
    Label,
    GroupContainer,
    SingularButton,
    ExpandablePanel,
    Shelf,
    EmptyState,
    Toggle,
    Dialog,
} from '../components/widgets';
import LinkEditorShelf from './containers/LinkEditorShelf';
import SubDomainsShelf from './components/manageDomainsShelf/SubDomainsShelf';
import TopArea from './components/TopArea';
import LinkList from './components/LinkList';
import InnerTooltip from './components/InnerTooltip';
import LinkGroupHeader from './components/LinkGroupHeader';
import css from './page.css';
import { DeleteDialog, PageHeader } from '../components/partials';
import PermissionsWrapper from '../containers/PermissionsWrapper';
import AppsList from '../components/partials/VerticalList/AppsList';
import { MANAGE_LINKS_EVENT_PREFIX } from '../utils/general';

const FAQ_LINKS = { faqLink: 'https://support.singular.net/hc/en-us/articles/360030934212-Singular-Links-FAQ' };

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => {
            return i * 50;
        },
    },
    exit: { opacity: 0, y: 20 },
});

class LinkManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shelfFormDirty: false,
            showDeleteDialog: false,
            openGroups: {
                custom: false,
            },
        };
        this.onFormDirtyChanged = this._onFormDirtyChanged.bind(this);
        this.onCloseShelf = this._onCloseShelf.bind(this);
        this.handleArchiveLink = this._handleArchiveLink.bind(this);
        this.handleArchiveLinkAccept = this._handleArchiveLinkAccept.bind(this);
        this.handleArchiveLinkReject = this._handleArchiveLinkReject.bind(this);
        this.handleGroupTitleClicked = this._handleGroupTitleClicked.bind(this);
        this.isGroupOpen = this._isGroupOpen.bind(this);
        this.getToggleCountByValue = this._getToggleCountByValue.bind(this);
        this.getAppListWithContent = this._getAppListWithContent.bind(this);
    }

    _onFormDirtyChanged(dirty) {
        this.setState({
            shelfFormDirty: dirty,
        });
    }

    _onCloseShelf() {
        const { onCloseShelf, translate } = this.props;
        const { shelfFormDirty } = this.state;
        if (shelfFormDirty) {
            if (!confirm(translate('STATIC.PAGES.CUSTOM_EVENTS.UNSAVED_CHANGES_WARNING'))) {
                return;
            }
        }
        this.setState({
            shelfFormDirty: false,
        });
        this.archiveLinkData = null;
        onCloseShelf();
    }

    _handleArchiveLink(linkId, singularLink, campaignId) {
        this.archiveLinkData = {
            singularLink,
            linkId,
            campaignId,
        };
        this.setState({
            showDeleteDialog: true,
        });
    }

    _handleArchiveLinkAccept() {
        const { onArchiveClicked } = this.props;
        onArchiveClicked(
            this.archiveLinkData.linkId,
            this.archiveLinkData.singularLink,
            this.archiveLinkData.campaignId
        );
        this.setState({
            showDeleteDialog: false,
        });
        this.archiveLinkData = null;
    }

    _handleArchiveLinkReject() {
        this.setState({
            showDeleteDialog: false,
        });
        this.archiveLinkData = null;
    }

    _handleGroupTitleClicked(groupName) {
        if (!(groupName in this.state.openGroups)) {
            this.setState({
                openGroups: {
                    ...this.state.openGroups,
                    [groupName]: false,
                },
            });
            return;
        }
        this.setState({
            openGroups: {
                ...this.state.openGroups,
                [groupName]: !this.state.openGroups[groupName],
            },
        });
    }

    _isGroupOpen(group) {
        return (!(group.name in this.state.openGroups) && group.open) || this.state.openGroups[group.name];
    }

    _getAppListWithContent() {
        return this.props.apps.map(app => ({
            ...app,
            lastUpdate: app.lastUpdate,
            content: app.content ? (
                <div key={app.id} className={css.appSummaryContent}>
                    <Translate
                        id="STATIC.PAGES.MANAGE_LINKS.CUSTOM_LINKS_COUNT"
                        data={{ customCount: app.content.customCount }}
                    />
                    <br />
                    <Translate
                        id="STATIC.PAGES.MANAGE_LINKS.PARTNER_LINKS_COUNT"
                        data={{ partnerCount: app.content.partnerCount }}
                    />
                </div>
            ) : (
                <></>
            ),
        }));
    }

    _getToggleCountByValue(count) {
        if (count === null) {
            return 'N/A';
        }
        if (count < 100) {
            return count;
        }
        return '100+';
    }

    render() {
        const {
            loading,
            loadingLinks,
            searching,
            apps,
            onAppSelected,
            groups,
            onAppFilterChanged,
            appsFilter,
            totalLinks,
            onExpandClick,
            onCreateLinkClicked,
            onCloseShelf,
            onEditClick,
            onDuplicateClicked,
            shelf,
            onTopButtonClicked,
            subDomainsShelf,
            emptyState,
            showOldLinks,
            onOldLinksFilterChanged,
            onShowApiLinksFilterChanged,
            onEmptyStateAction,
            showLegacyLinksToggle,
            showAgenciesLinksToggle,
            onAgenciesLinksFilterChanged,
            showAgenciesLinks,
            legacyLinksCount,
            agenciesLinksCount,
            apiLinksCount,
            showApiLinks,
        } = this.props;
        const { showDeleteDialog } = this.state;

        if (loading) {
            return <Spinner show expanded />;
        }
        let groupIndex = 0;
        const totalPartners = groups
            .map(group => (group.partners && group.partners.length) || 0)
            .reduce((total, groupCount) => total + groupCount, 0);
        const animate = totalPartners < 100;
        return (
            <div className={css.container}>
                <div className={css.topArea}>
                    <TopArea
                        onAppFilterChanged={onAppFilterChanged}
                        search={appsFilter}
                        onClick={onTopButtonClicked}
                        emptyState={emptyState}
                        searching={searching}
                    />
                </div>
                <div className={css.mainArea}>
                    <AppsList
                        sortApps={e => e}
                        className={css.leftArea}
                        apps={this.getAppListWithContent()}
                        appsFilter={appsFilter}
                        onAppSelected={onAppSelected}
                        disableAnimation
                    />
                    <div className={css.rightArea}>
                        <div className={css.rightAreaTop}>
                            <PageHeader
                                subText={FAQ_LINKS}
                                text="STATIC.PAGES.MANAGE_LINKS.AVAILABLE_LINKS"
                                textReplace={{ totalLinks }}
                                mixpanelEventPrefix={MANAGE_LINKS_EVENT_PREFIX}
                            />
                            <div className={css.actions}>
                                {apiLinksCount > 0 && (
                                    <div className={css.toggleLinks}>
                                        <Toggle
                                            label={
                                                <Translate
                                                    id="STATIC.PAGES.MANAGE_LINKS.API_LINKS_TOGGLE_LABEL"
                                                    data={{ count: this.getToggleCountByValue(apiLinksCount) }}
                                                />
                                            }
                                            checked={showApiLinks}
                                            onToggle={onShowApiLinksFilterChanged}
                                        />
                                    </div>
                                )}
                                {showLegacyLinksToggle && (
                                    <div className={css.toggleLinks}>
                                        <InnerTooltip text="STATIC.PAGES.MANAGE_LINKS.LEGACY_TOGGLE_TOOLTIP">
                                            <Toggle
                                                label={
                                                    <Translate
                                                        id="STATIC.PAGES.MANAGE_LINKS.OLD_LINKS_TOGGLE_LABEL"
                                                        data={{ count: this.getToggleCountByValue(legacyLinksCount) }}
                                                    />
                                                }
                                                checked={showOldLinks}
                                                onToggle={onOldLinksFilterChanged}
                                                disabled={emptyState && emptyState.oldLinksDisabled}
                                            />
                                        </InnerTooltip>
                                    </div>
                                )}
                                {showAgenciesLinksToggle && (
                                    <div className={css.toggleLinks}>
                                        <Toggle
                                            label={
                                                <Translate
                                                    id="STATIC.PAGES.MANAGE_LINKS.AGENCY_LINKS_TOGGLE_LABEL"
                                                    data={{ count: this.getToggleCountByValue(agenciesLinksCount) }}
                                                />
                                            }
                                            checked={showAgenciesLinks}
                                            onToggle={onAgenciesLinksFilterChanged}
                                        />
                                    </div>
                                )}
                                <PermissionsWrapper write>
                                    <SingularButton
                                        className={css.createButton}
                                        onClick={onCreateLinkClicked}
                                        disabled={emptyState && emptyState.createLinkDisabled}
                                    >
                                        <Translate id="STATIC.PAGES.MANAGE_LINKS.CREATE_LINK_BUTTON" />
                                    </SingularButton>
                                </PermissionsWrapper>
                            </div>
                        </div>
                        <div className={css.rightAreaMain}>
                            {loadingLinks ? <Spinner show style={{ marginTop: 120 }} /> : null}
                            {!loadingLinks && !emptyState
                                ? groups.map(group => {
                                      const groupIsOpen = this.isGroupOpen(group);
                                      return (
                                          <GroupContainer
                                              key={group.name}
                                              {...group}
                                              controlled
                                              onTitleClick={this.handleGroupTitleClicked}
                                              open={groupIsOpen}
                                          >
                                              <PoseGroup animateOnMount={animate}>
                                                  {group.partners.map(partner => {
                                                      if (groupIsOpen) {
                                                          groupIndex++;
                                                      }
                                                      return (
                                                          <AnimationItem
                                                              key={partner.name}
                                                              i={groupIndex}
                                                              className={css.expandableOverrideContainer}
                                                          >
                                                              <ExpandablePanel
                                                                  id={partner.name}
                                                                  expanded={partner.expanded}
                                                                  content={
                                                                      <LinkList
                                                                          links={partner.links}
                                                                          onEditClick={onEditClick}
                                                                          onDuplicateClicked={onDuplicateClicked}
                                                                          onArchiveClicked={this.handleArchiveLink}
                                                                          showAgenciesLinks={showAgenciesLinks}
                                                                      />
                                                                  }
                                                                  header={
                                                                      <LinkGroupHeader
                                                                          {...partner}
                                                                          title={partner.name}
                                                                          subTitle={
                                                                              <Translate
                                                                                  id="STATIC.PAGES.MANAGE_LINKS.LINK_GROUP_HEADER_SUBTITLE"
                                                                                  data={{ count: partner.links.length }}
                                                                              />
                                                                          }
                                                                      />
                                                                  }
                                                                  overrideClasses={{
                                                                      container: css.expandableOverrideContainer,
                                                                      contentArea: css.expandableOverrideContent,
                                                                      headerContainer: css.expandableOverrideHeader,
                                                                      headerContainerExpanded:
                                                                          css.expandableOverrideHeaderExpanded,
                                                                      arrowArea: css.expandableOverrideArrow,
                                                                      externalHeaderArea:
                                                                          css.expandableOverrideExternal,
                                                                  }}
                                                                  onExpandClick={onExpandClick}
                                                                  isSticky
                                                                  animate={animate}
                                                              />
                                                          </AnimationItem>
                                                      );
                                                  })}
                                              </PoseGroup>
                                          </GroupContainer>
                                      );
                                  })
                                : null}
                            {!loadingLinks && emptyState ? (
                                <EmptyState
                                    style={{ marginTop: 120, textAlign: 'center' }}
                                    {...emptyState.data}
                                    onActionTriggered={onEmptyStateAction}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <Shelf {...shelf} shelfSize="medium" onClose={this.onCloseShelf}>
                    <LinkEditorShelf onFormDirtyChanged={this.onFormDirtyChanged} />
                </Shelf>
                <SubDomainsShelf {...subDomainsShelf} />
                <Dialog open={showDeleteDialog} onEscapePress={this.onDeleteRejected}>
                    <DeleteDialog
                        title="STATIC.PAGES.VIEW_TRACKING_LINKS.DELETE_WARNING"
                        acceptText="STATIC.PAGES.VIEW_TRACKING_LINKS.ARCHIVE_YES"
                        onAccept={this.handleArchiveLinkAccept}
                        onReject={this.handleArchiveLinkReject}
                        deleteType="link"
                    />
                </Dialog>
            </div>
        );
    }
}

LinkManagement.propTypes = {
    loading: PropTypes.bool,
    loadingLinks: PropTypes.bool,
    searching: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.any),
    onAppSelected: PropTypes.func,
    onAppFilterChanged: PropTypes.func,
    onExpandClick: PropTypes.func,
    onCreateLinkClicked: PropTypes.func,
    onCloseShelf: PropTypes.func,
    onCloseSubDomainsShelf: PropTypes.func,
    onEditClick: PropTypes.func,
    appsFilter: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.any),
    totalLinks: PropTypes.number,
    shelf: PropTypes.objectOf(PropTypes.any),
    onTopButtonClicked: PropTypes.func,
    subDomainsShelf: PropTypes.objectOf(PropTypes.any),
    showOldLinks: PropTypes.bool,
    onOldLinksFilterChanged: PropTypes.func,
    onShowApiLinksFilterChanged: PropTypes.func,
    onDuplicateClicked: PropTypes.func,
    onArchiveClicked: PropTypes.func,
    onEmptyStateAction: PropTypes.func,
    legacyLinksCount: PropTypes.number,
    showLegacyLinksToggle: PropTypes.bool,
    agenciesLinksCount: PropTypes.number,
    showAgenciesLinksToggle: PropTypes.bool,
    onAgenciesLinksFilterChanged: PropTypes.func,
    showAgenciesLinks: PropTypes.bool,
    apiLinksCount: PropTypes.number,
    showApiLinks: PropTypes.bool,
};

LinkManagement.defaultProps = {
    loading: true,
    loadingLinks: true,
    searching: true,
    apps: [],
    onAppSelected: () => {},
    onAppFilterChanged: () => {},
    onExpandClick: () => {},
    onCreateLinkClicked: () => {},
    onCloseShelf: () => {},
    onCloseSubDomainsShelf: () => {},
    onEditClick: () => {},
    appsFilter: '',
    groups: [],
    totalLinks: 0,
    shelf: { open: false },
    onTopButtonClicked: () => {},
    subDomainsShelf: { open: false },
    showOldLinks: false,
    showAgenciesLinks: false,
    showApiLinks: false,
    onOldLinksFilterChanged: () => {},
    onShowApiLinksFilterChanged: () => {},
    onAgenciesLinksFilterChanged: () => {},
    onDuplicateClicked: () => {},
    onArchiveClicked: () => {},
    onEmptyStateAction: () => {},
    legacyLinksCount: 0,
    showLegacyLinksToggle: true,
    agenciesLinksCount: 0,
    showAgenciesLinksToggle: false,
    apiLinksCount: 0,
};

export default withLocalize(LinkManagement);
