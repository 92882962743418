import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../../components/widgets/AutoComplete';
import Dropdown from '../../components/widgets/Dropdown';
import { AVAILABILITY_PARAMETER_OPTION__SOURCE, availabilityParameterOptions } from '../consts';
import { getOptions } from '../utils';
import css from '../styles.css';

const FieldsAvailabilityFilters = ({
    availabilityParameter,
    availabilityParameterValue,
    sourcesOptions,
    dimensionsOptions,
    onChange,
    disabled,
}) => {
    const [selectedAvailabilityParameter, setSelectedAvailabilityParameter] = useState(availabilityParameter);
    const [selectedAvailabilityParameterValue, setSelectedAvailabilityParameterValue] = useState(
        availabilityParameterValue
    );
    const { options: availabilityParameterValueOptions, placeholder } = getOptions(
        selectedAvailabilityParameter,
        sourcesOptions,
        dimensionsOptions
    );

    return (
        <>
            <Dropdown
                items={availabilityParameterOptions}
                selected={availabilityParameterOptions.find(({ name }) => name === selectedAvailabilityParameter)}
                onSelection={selected => {
                    setSelectedAvailabilityParameter(selected.name);
                    setSelectedAvailabilityParameterValue(null);
                    onChange(selectedAvailabilityParameter, null);
                }}
                containerClass={css.availabilityParameterDropdown}
                popperClass={css.availabilityParameterDropdown}
                disabled={disabled}
            />
            <div>
                <AutoComplete
                    placeholder={placeholder}
                    containerStyle={{
                        width: '480px',
                    }}
                    selectOptions={{
                        options: availabilityParameterValueOptions,
                        noOptionsMessage: () => null,
                        isSearchable: true,
                        disabled,
                    }}
                    onChange={input => {
                        setSelectedAvailabilityParameterValue(input.value);
                        onChange(selectedAvailabilityParameter, input.value);
                    }}
                    controlledValue={
                        availabilityParameterValueOptions.find(
                            ({ value }) => value === selectedAvailabilityParameterValue
                        ) || ''
                    }
                />
            </div>
        </>
    );
};

FieldsAvailabilityFilters.propTypes = {
    availabilityParameter: PropTypes.string,
    availabilityParameterValue: PropTypes.string,
    sourcesOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    dimensionsOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldsAvailabilityFilters.defaultProps = {
    availabilityParameter: AVAILABILITY_PARAMETER_OPTION__SOURCE.name,
    availabilityParameterValue: '',
    sourcesOptions: [],
    dimensionsOptions: [],
    onChange: () => {},
    disabled: false,
};

export default FieldsAvailabilityFilters;
