import { createSelector } from 'reselect';
import { getUserData, normalizeAccountTypeName, isUserAdNetwork } from './user';
import getCurrentPath from './routing';
import { CUSTOMER_TIERS } from '../utils/consts';

const READ = 1;
const WRITE = 2;
const READ_WRITE = 3;
const FEATURE_GOALS = 'goals';

const typesMap = {
    read: READ,
    write: WRITE,
    readWrite: READ_WRITE,
};

const ADNETWORK_MAP = {
    '/react/link-management': READ,
};

const AGENCY_MAP = {
    '/react/singular-events': READ,
};

const ACCOUNT_TYPES_MAP = {
    ad_network: ADNETWORK_MAP,
    agency_account: AGENCY_MAP,
};

const getUserMap = createSelector([getUserData], userData => {
    const accountType = normalizeAccountTypeName(userData.account_type_name);
    return ACCOUNT_TYPES_MAP[accountType] || {};
});

export const getFeaturePermissions = createSelector([getUserMap, getCurrentPath], (userMap, currentPath) => {
    return {
        typesMap,
        permission: userMap[currentPath.pathname] || READ_WRITE,
    };
});

export const isGoalsReportPermitted = createSelector([isUserAdNetwork, getUserData], (isAdNetwork, userData) => {
    return !isAdNetwork && userData.feature_flags?.includes(FEATURE_GOALS);
});
