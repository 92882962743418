import { createSelector } from 'reselect';
import moment from 'moment';
import * as Yup from 'yup';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { getAttributionAppsList, getAttributionSitesList } from '../selectors/apps';
import { getSingularLinksSourcesList, getSourceConfigurations } from '../selectors/sources';
import { getUserData, isUserAdNetwork, getAdminModeEnabled, getIsAgency } from '../selectors/user';
import { getFeaturePermissions } from '../selectors/permissions';
import { getTranslate } from '../selectors/locale';
import { getAppIcon } from '../utils/apps';
import {
    CUSTOM_SOURCES_DISPLAYED_NAMES,
    getPredefinedSourcesList,
    IP_PROBABILISTIC_SUPPORT_OPTIONS,
} from '../utils/sources';
import {
    defaultExtraParams,
    denormalizeLinks,
    groupLinksByPartner,
    LINK_TYPE_OPTIONS,
    getWindowsUpdatedDefs,
    getTotalCountFromSummary,
    SAME_AS_DEEPLINK_OBJECT,
    getOverrideWindowsToggleLabel,
    LINK_NAME_MOBILE_WEB_TO_APP,
    VISIBLE_LINK_TYPES,
    isCustomLink,
    getIpProbabilisticToggleInfo,
    PlatformTrackingTypes,
    nonMobilePlatforms,
    nonMobilePlatform,
    getPlatformPrefix,
    allNonMobileSitesPlatform,
    nonMobileSitesFilter,
    CUSTOM_SOURCE_NAME_FIELD,
    CUSTOM_SOURCE_NAME_KEY,
} from './utils';
import { LINK_TYPE_MOBILE_CUSTOM, LINK_TYPE_MOBILE_PARTNER, LINK_TYPE_MOBILE_WEB_TO_APP } from './linkTypes';
import { generateGuid } from '../global/utils';
import { toCamelCase, toSnakecase } from '../utils/strings';
import { sortNumeric } from '../utils/sortUtil';

const getLoading = state => state.linkManagement.loading;
const getLoadingLinks = state => state.linkManagement.loadingLinks;
export const getSelectedAppId = state => state.linkManagement.selectedAppId;
const getOpenGroups = state => state.linkManagement.openGroups;
export const getOpenPartners = state => state.linkManagement.openPartners;
export const getLinksPerAppId = state => state.linkManagement.linksPerAppId;
export const getLinksData = state => state.linkManagement.linksData;
export const getLinksMetaData = state => state.linkManagement.linksMetadata;
export const getApiLinksMetaData = state => state.linkManagement.apiLinksMetadata;
const getApiLinksData = state => state.linkManagement.apiLinksData;
export const getApiLinksPerAppId = state => state.linkManagement.apiLinksPerAppId;
export const getAppsFilter = state => state.linkManagement.appsFilter;
export const getPartnersConfig = state => state.linkManagement.partnersConfig;
export const getSelectedPartnerId = state => state.linkManagement.selectedPartnerId;
export const getAppsFilterResult = state => state.linkManagement.appsFilterResult;
export const getSearching = state => state.linkManagement.searching;
export const getShowOldLinks = state => state.linkManagement.showOldLinks;
export const getShowAgenciesLinks = state => state.linkManagement.showAgenciesLinks;
export const getShowApiLinks = state => state.linkManagement.showApiLinks;
const getShelf = state => state.linkManagement.shelf;
const getSubDomainsShelf = state => state.linkManagement.subDomainsShelf;
const getDomains = state => state.linkManagement.domains;
const getDnsZones = state => state.linkManagement.dnsZones;
const getDomainsLimit = state => state.linkManagement.domainsLimit;
const getLinksSummary = state => state.linkManagement.linksSummary;
const getLegacyLinksSummary = state => state.linkManagement.legacyLinksSummary;
export const getConfig = state => state.linkManagement.config;

const getFormCategoriesList = state => state.linkManagement.formCategoriesList;
export const getCategoryFields = state => state.linkManagement.categoryFields;
export const getCategoryDefs = state => state.linkManagement.categoryDefs;
export const getFieldsDefs = state => state.linkManagement.fieldsDefs;
const getOptionalExtraParams = state => state.linkManagement.optionalExtraParams;
export const getFieldValues = state => state.linkManagement.fieldValues;
export const getClickThroughDisplayLink = state =>
    state.linkManagement.fieldValues.showShortLink === true
        ? state.linkManagement.fieldValues.clickThroughShortLink
        : state.linkManagement.fieldValues.clickThroughLink;
const getLinksSuggestions = state => state.linkManagement.linksSuggestions;

const getSourceExtra = (singularLinksReady, supportClicks, supportPCConsole) => {
    if (!supportClicks && !supportPCConsole) {
        return 'Not supported';
    }
    if (!singularLinksReady) {
        return 'Not yet supported';
    }
    return '';
};

const groups = [
    {
        defaultOpen: true,
        name: LINK_TYPE_MOBILE_WEB_TO_APP,
        title: 'STATIC.PAGES.MANAGE_LINKS.GROUP_TITLE_MOBILE_WEB_TO_APP',
        linkType: LINK_NAME_MOBILE_WEB_TO_APP,
    },
    {
        defaultOpen: true,
        name: LINK_TYPE_MOBILE_PARTNER,
        title: 'STATIC.PAGES.MANAGE_LINKS.GROUP_TITLE_PARTNER',
        linkType: 'Link',
    },
    {
        defaultOpen: true,
        name: LINK_TYPE_MOBILE_CUSTOM,
        title: 'STATIC.PAGES.MANAGE_LINKS.GROUP_TITLE_CUSTOM',
        linkType: 'SmartLink',
    },
];

const filterCustomSources = [
    ...CUSTOM_SOURCES_DISPLAYED_NAMES,
    ...CUSTOM_SOURCES_DISPLAYED_NAMES.map(e => `${e} (Custom)`),
    ...CUSTOM_SOURCES_DISPLAYED_NAMES.map(e => e.toLowerCase()),
];

export const getSelectedSource = createSelector([getFieldValues], fieldValues => {
    return fieldValues.source;
});

export const getSelectAppExternalUUID = createSelector(
    [getAttributionAppsList, getSelectedAppId],
    (appsList, selectedAppId) => {
        if (!selectedAppId || !appsList) {
            return null;
        }
        const foundApp = appsList.find(app => app.app_id === selectedAppId);
        if (!foundApp) {
            return null;
        }
        return foundApp.external_uuid;
    }
);

export const getShowOldLinksSelectedApp = createSelector(
    [getSelectAppExternalUUID, getShowOldLinks],
    (selectedExternalUUID, showOldLinks) => {
        return !!showOldLinks[selectedExternalUUID];
    }
);

export const getShowAgenciesLinksSelectedApp = createSelector(
    [getSelectedAppId, getShowAgenciesLinks],
    (selectedAppID, showAgenciesLinks) => {
        return !!showAgenciesLinks[selectedAppID];
    }
);

export const getShowApiLinksSelectedApp = createSelector(
    [getSelectAppExternalUUID, getShowApiLinks],
    (selectedExternalUUID, showApiLinks) => {
        return !!showApiLinks[selectedExternalUUID];
    }
);

export const getLinksList = createSelector(
    [
        getLinksData,
        getLinksPerAppId,
        getApiLinksData,
        getApiLinksPerAppId,
        getShowApiLinksSelectedApp,
        getSelectedAppId,
    ],
    (linksData, linksPerAppId, apiLinksData, apiLinksPerAppId, showApiLinks, selectedAppId) => {
        if (!selectedAppId) {
            return [];
        }
        return [
            ...(linksPerAppId[selectedAppId] || []).map(linkId => linksData[linkId]),
            ...(apiLinksPerAppId[selectedAppId] || []).map(linkId => apiLinksData[linkId]),
        ];
    }
);

const getGroupLinksByType = createSelector(
    [
        getLinksPerAppId,
        getLinksData,
        getShowApiLinksSelectedApp,
        getApiLinksData,
        getSelectedAppId,
        getShowOldLinksSelectedApp,
        getIsAgency,
        getShowAgenciesLinksSelectedApp,
        getApiLinksPerAppId,
    ],
    (
        linksPerAppId,
        linksData,
        showApiLinks,
        apiLinksData,
        selectedAppId,
        showOldLinks,
        isAgency,
        showAgenciesLinks,
        apiLinksPerAppId
    ) => {
        const relevantLinks = [
            ...denormalizeLinks(linksPerAppId[selectedAppId] || [], linksData),
            ...denormalizeLinks(showApiLinks ? apiLinksPerAppId[selectedAppId] : [], apiLinksData),
        ];
        return relevantLinks
            .filter(link => {
                return (
                    (!link.status || link.status === 'live') &&
                    (showOldLinks || link.singular_link) &&
                    VISIBLE_LINK_TYPES.includes(link.absolute_link_type) &&
                    (isAgency || (!isAgency && !link.agency_name) || (!isAgency && showAgenciesLinks))
                );
            })
            .reduce(
                (total, current) => {
                    total[current.type].push(current);
                    return total;
                },
                {
                    [LINK_TYPE_MOBILE_CUSTOM]: [],
                    [LINK_TYPE_MOBILE_PARTNER]: [],
                    [LINK_TYPE_MOBILE_WEB_TO_APP]: [],
                }
            );
    }
);

export const getCustomSourceNames = createSelector([getGroupLinksByType], groupMap => {
    const customLinks = groupMap[CUSTOM_SOURCE_NAME_KEY] || [];
    const groupByCustomSourceName = groupBy(customLinks, CUSTOM_SOURCE_NAME_FIELD) || {};
    const uniqueCustomNamesSet = new Set(Object.keys(groupByCustomSourceName));
    return Array.from(uniqueCustomNamesSet)
        .filter(customName => !filterCustomSources.includes(customName))
        .map(e => ({ value: e, label: e }));
});

const getGroups = createSelector(
    [getOpenGroups, getOpenPartners, getTranslate, getGroupLinksByType],
    (openGroups, openPartners, translate, groupMap) => {
        return groups
            .filter(group => groupMap[group.name].length)
            .map(group => {
                const groupLinks = groupMap[group.name];
                const partners = groupLinksByPartner(groupLinks, openPartners);
                return {
                    ...group,
                    title: `${translate(group.title)} (${groupLinks.length})`,
                    partners,
                    open: openGroups.includes(group.name),
                };
            });
    }
);

const getAppItemData = (app, linksSummary, translate, legacyLinksSummary) => {
    if (!linksSummary) {
        return {
            content: null,
            lastUpdate: null,
        };
    }
    const linkSummaryData =
        linksSummary && linksSummary[app.external_uuid]
            ? linksSummary[app.external_uuid]
            : {
                  custom_count: 0,
                  partner_count: 0,
                  last_modified: 0,
              };
    const linkLegacySummaryData =
        legacyLinksSummary && legacyLinksSummary[app.external_uuid]
            ? legacyLinksSummary[app.external_uuid]
            : {
                  custom_count: 0,
                  partner_count: 0,
                  last_modified: 0,
              };
    if (linkSummaryData || linkLegacySummaryData) {
        const customCount = (linkSummaryData.custom_count || 0) + (linkLegacySummaryData.custom_count || 0);
        const partnerCount = (linkSummaryData.partner_count || 0) + (linkLegacySummaryData.partner_count || 0);
        let lastModifiedTS = linkSummaryData.last_modified || 0;
        if (linkLegacySummaryData.last_modified) {
            lastModifiedTS = Math.max(lastModifiedTS, linkLegacySummaryData.last_modified);
        }
        if (partnerCount + customCount) {
            const lastModified = lastModifiedTS ? moment(lastModifiedTS * 1000).format('lll') : '';
            return {
                content: {
                    customCount,
                    partnerCount,
                },
                lastUpdate: translate('STATIC.PAGES.MANAGE_LINKS.LAST_UPDATE', { lastModified }),
                modifiedAt: lastModifiedTS,
            };
        }
    }
    return {
        content: null,
        lastUpdate: null,
    };
};

export const getApps = createSelector(
    [
        getAttributionAppsList,
        getSelectedAppId,
        getAppsFilter,
        getLinksSummary,
        getTranslate,
        getLegacyLinksSummary,
        getAppsFilterResult,
    ],
    (apps, selectedAppId, appsFilter, linksSummary, translate, legacyLinksSummary, appsFilterResult) => {
        if (!apps) {
            return [];
        }
        const appsFilterResultSet = new Set(appsFilterResult || []);
        return apps
            .filter(app => {
                if (!appsFilter) {
                    return true;
                }
                const sitesString = app.sites.map(site => site.site_public_id).join('::');
                const searchString = `${app.name}::${sitesString}`;
                return (
                    searchString.toLowerCase().includes(appsFilter.toLowerCase()) || appsFilterResultSet.has(app.app_id)
                );
            })
            .map(app => {
                return {
                    id: app.app_id,
                    name: app.name,
                    external_uuid: app.external_uuid,
                    icon: getAppIcon(app),
                    selected: app.app_id === selectedAppId,
                    ...getAppItemData(app, linksSummary, translate, legacyLinksSummary),
                };
            })
            .sort((app1, app2) => sortNumeric(app1.modifiedAt, app2.modifiedAt, true));
    }
);

const NO_APPS_EMPTY_STATE = {
    type: 'noApps',
    data: {
        icon: 'happyPage',
        header: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_HEADER',
        subHeader: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_SUBHEADER_NO_APPS',
    },
    createLinkDisabled: true,
    appsFilterDisabled: true,
    oldLinksDisabled: true,
    oldLinksMessage: false,
};

const NO_SUBDOMAINS_EMPTY_STATE = {
    type: 'noDomains',
    data: {
        icon: 'happyPage',
        header: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_HEADER',
        subHeader: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_SUBHEADER_NO_SUBDOMAINS',
    },
    createLinkDisabled: true,
    appsFilterDisabled: false,
    oldLinksDisabled: true,
    oldLinksMessage: true,
};

const NO_LINKS_SEARCH_EMPTY_STATE = {
    type: 'noLinksSearch',
    data: {
        icon: 'happyPage',
        header: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_SEARCH_HEADER',
        subHeader: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_SUBHEADER_NO_LINKS_SEARCH_READ_ONLY',
    },
    createLinkDisabled: false,
    appsFilterDisabled: false,
    oldLinksMessage: false,
};

const NO_LINKS_EMPTY_STATE = {
    type: 'noLinks',
    data: {
        icon: 'happyPage',
        header: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_HEADER',
        subHeader: 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_SUBHEADER_NO_LINKS',
    },
    createLinkDisabled: false,
    appsFilterDisabled: false,
    oldLinksMessage: true,
};

const HAS_OLD_LINKS_MESSAGE = 'STATIC.PAGES.MANAGE_LINKS.EMPTY_STATE_SUBHEADER_OLD_LINKS';

const tryGetTranslatedValue = (translate, value) => {
    let translated = value;
    try {
        translated = translate(translated).props.dangerouslySetInnerHTML.__html;
    } catch (e) {}
    return translated;
};

const getEmptyState = (apps, groups, domains, oldLinksCount, translate, permissions, isAdnetwork, appsFilter) => {
    let ret = null;
    const readOnly = permissions.permission === permissions.typesMap.read;
    if (!apps || !apps.length) {
        ret = NO_APPS_EMPTY_STATE;
    } else if ((!domains || !domains.length) && !isAdnetwork) {
        ret = NO_SUBDOMAINS_EMPTY_STATE;
    } else if (!groups || !groups.length) {
        ret = appsFilter ? NO_LINKS_SEARCH_EMPTY_STATE : NO_LINKS_EMPTY_STATE;
    }
    if (!ret) {
        return ret;
    }
    ret = { ...ret, data: { ...ret.data } };
    if (readOnly) {
        ret.data.subHeader = `${ret.data.subHeader}_READ_ONLY`;
    }
    ret.data = { ...ret.data, subHeader: tryGetTranslatedValue(translate, ret.data.subHeader) };
    if (oldLinksCount > 0 && ret.oldLinksMessage) {
        ret.data.subHeader += tryGetTranslatedValue(translate, HAS_OLD_LINKS_MESSAGE);
    }
    return ret;
};

export const getPage = createSelector(
    [
        getApps,
        getAttributionAppsList,
        getLoading,
        getLoadingLinks,
        getGroups,
        getAppsFilter,
        getSearching,
        getLinksList,
        getShelf,
        getSubDomainsShelf,
        getDomains,
        getShowOldLinksSelectedApp,
        getShowAgenciesLinksSelectedApp,
        getShowApiLinksSelectedApp,
        getTranslate,
        getFeaturePermissions,
        isUserAdNetwork,
        getIsAgency,
        getUserData,
        getLegacyLinksSummary,
        getSelectAppExternalUUID,
        getLinksSummary,
        getShowApiLinks,
    ],
    (
        apps,
        originalAppsList,
        loading,
        loadingLinks,
        groups,
        appsFilter,
        searching,
        linksList,
        shelf,
        subDomainsShelf,
        domains,
        showOldLinks,
        showAgenciesLinks,
        showApiLinks,
        translate,
        permissions,
        isAdnetwork,
        isAgency,
        userData,
        legacyLinksSummary,
        selectedAppExternalUUID,
        linksSummary
    ) => {
        const filteredLinksList = linksList.filter(link => {
            return (
                (!link.status || link.status === 'live') &&
                (showOldLinks || link.singular_link) &&
                VISIBLE_LINK_TYPES.includes(link.absolute_link_type)
            );
        });
        const oldLinksCount = linksList.length - filteredLinksList.length;
        let legacyLinksCount = 0;
        if (isAdnetwork) {
            legacyLinksCount = null;
        } else if (legacyLinksSummary) {
            legacyLinksCount = getTotalCountFromSummary(legacyLinksSummary, selectedAppExternalUUID) || 0;
        }
        const agenciesLinksCount = filteredLinksList.filter(link => link.agency_name).length;
        const showAgenciesLinksToggle = !isAgency && agenciesLinksCount > 0;
        let apiLinksCount = 0;
        if (linksSummary) {
            apiLinksCount = linksSummary[selectedAppExternalUUID]?.api_links;
        }

        return {
            apps,
            loading,
            loadingLinks,
            searching,
            groups,
            appsFilter,
            totalLinks: filteredLinksList.length,
            shelf,
            subDomainsShelf,
            emptyState: getEmptyState(
                originalAppsList,
                groups,
                domains,
                oldLinksCount,
                translate,
                permissions,
                isAdnetwork,
                appsFilter
            ),
            showOldLinks,
            showAgenciesLinks,
            showLegacyLinksToggle: !!legacyLinksCount || isAdnetwork,
            legacyLinksCount,
            showAgenciesLinksToggle,
            agenciesLinksCount,
            apiLinksCount,
            showApiLinks,
        };
    }
);

const checkVisibilityRules = (field, fieldValues, shelfData) => {
    if (!field.visibilityRules.length) {
        return false;
    }
    return field.visibilityRules.reduce((total, currentRule) => {
        return total && currentRule.call(null, fieldValues, shelfData);
    }, true);
};

const filterSitesByPlatforms = (sites, platforms) => sites.filter(site => platforms.includes(site.platform));

const mapSitesForShelf = (site, showPlatform = false) => ({
    value: site.site_id,
    label: `${site.site_public_id}${showPlatform ? ` (${site.platform})` : ''}`,
    appScheme: site.app_scheme,
    appLinksSigningCertificate: site.app_signing_certificate,
    iosTeamId: site.ios_team_id,
    platform: site.platform,
    longname: site.longname,
});

export const getIOSSites = createSelector([getAttributionAppsList, getSelectedAppId], (appsList, selectedAppId) => {
    const appData = appsList.find(app => app.app_id === selectedAppId);
    if (!appData) {
        return [];
    }
    return filterSitesByPlatforms(appData.sites, ['iOS']).map(mapSitesForShelf);
});

export const getAndoridSites = createSelector([getAttributionAppsList, getSelectedAppId], (appsList, selectedAppId) => {
    const appData = appsList.find(app => app.app_id === selectedAppId);
    if (!appData) {
        return [];
    }
    return filterSitesByPlatforms(appData.sites, ['Android']).map(mapSitesForShelf);
});
export const getNonMobileSites = createSelector(
    [getAttributionAppsList, getSelectedAppId, getTranslate],
    (appsList, selectedAppId, translate) => {
        const selectedApp = appsList.find(app => app.app_id === selectedAppId);
        if (!selectedApp) return [];

        const sites = filterSitesByPlatforms(selectedApp.sites, nonMobilePlatforms).map(site =>
            mapSitesForShelf(site, true)
        );

        if (sites.length > 0) {
            sites.push({
                platform: allNonMobileSitesPlatform,
                label: translate('STATIC.PAGES.MANAGE_LINKS.ALL_NON_MOBILE_APPS'),
                value: null,
                longname: sites[0].longname,
                appScheme: null,
                appLinksSigningCertificate: null,
                iosTeamId: null,
            });
        }

        return sites;
    }
);

const getShelfDomains = createSelector([getDomains], domains => {
    return domains
        ? domains.map(domain => ({
              display_name: domain.full_domain,
              name: domain.full_domain,
              default: domain.favourite,
          }))
        : [];
});

export const getShelfSources = createSelector([getSingularLinksSourcesList, getFieldValues], (sources, fieldValues) => {
    let useSources = sources;
    if (fieldValues && isCustomLink(fieldValues.linkType)) {
        useSources = getPredefinedSourcesList(fieldValues.linkType);
    }
    return useSources
        ? useSources
              .map(
                  ({
                      display_name,
                      name,
                      singular_links_ready,
                      support_clicks,
                      ip_probabilistic_support_type,
                      support_pc_console,
                      extra_info,
                      extra_params,
                  }) => ({
                      label: display_name,
                      value: name,
                      singular_links_ready,
                      support_clicks,
                      ip_probabilistic_support_type,
                      support_pc_console,
                      extra: getSourceExtra(singular_links_ready, support_clicks, support_pc_console),
                      description: extra_info,
                      extra_params,
                  })
              )
              .sort((a, b) => {
                  if (a.label < b.label) {
                      return -1;
                  }
                  if (a.label > b.label) {
                      return 1;
                  }
                  return 0;
              })
        : [];
});

const getShelfDestinations = createSelector(
    [getAttributionAppsList, getSelectedAppId, getFieldValues, getLinksSuggestions],
    (appsList, selectedAppId, fieldValues, suggestions) => {
        const ret = {
            iosRedirects: [],
            iosDeeplinks: [],
            iosDeferredDeeplinks: [],
            androidRedirects: [],
            androidDeeplinks: [],
            androidDeferredDeeplinks: [],
            nonMobileRedirects: [],
            fallbackRedirect: [],
        };
        const iterations = [
            { platform: 'iOS', valuesKey: 'iosDestinationSite' },
            { platform: 'Android', valuesKey: 'androidDestinationSite' },
            { platform: nonMobilePlatform, valuesKey: 'nonMobileDestinationSite' },
        ];
        const appData = appsList.find(app => app.app_id === selectedAppId);
        iterations.forEach(({ platform, valuesKey }) => {
            const selectedSite = fieldValues[valuesKey];
            const isNonMobile = platform === nonMobilePlatform;
            const sites = filterSitesByPlatforms(appData.sites, isNonMobile ? nonMobilePlatforms : [platform]);
            const platformFromServerPrefix = isNonMobile ? toSnakecase(platform) : platform.toLowerCase();

            if (selectedSite && selectedSite.value) {
                const selectedSiteObj = sites.find(site => site.site_id === selectedSite.value);
                if (!selectedSiteObj) {
                    return;
                }

                const redirects = Array.from(
                    new Set([
                        ...(suggestions[`${platformFromServerPrefix}_redirect`] || []),
                        ...selectedSiteObj.download_destinations.map(dest => dest.destination_url).filter(Boolean),
                    ])
                );

                const deeplinks = Array.from(
                    new Set([
                        ...(suggestions[`${platformFromServerPrefix}_deeplink`] || []),
                        ...selectedSiteObj.deeplink_destinations.map(dest => dest.destination_url).filter(Boolean),
                    ])
                );

                const deferredDeeplinks = Array.from(
                    new Set([
                        ...(suggestions[`${platformFromServerPrefix}_deferred_deeplink`] || []),
                        ...selectedSiteObj.deeplink_destinations.map(dest => dest.destination_url).filter(Boolean),
                    ])
                );

                const platformPrefix = toCamelCase(platformFromServerPrefix);

                ret[`${platformPrefix}Redirects`] = redirects.map(dest => ({ value: dest, label: dest }));
                ret[`${platformPrefix}Deeplinks`] = deeplinks.map(dest => ({ value: dest, label: dest }));
                ret[`${platformPrefix}DDLs`] = [{ ...SAME_AS_DEEPLINK_OBJECT }].concat(
                    deferredDeeplinks.map(dest => ({
                        value: dest,
                        label: dest,
                    }))
                );
            }
        });

        ret.fallbackRedirect = (suggestions.web_fallback || []).map(dest => ({
            value: dest,
            label: dest,
        }));

        return ret;
    }
);

const getShelfWarningMessages = createSelector(
    [getSourceConfigurations, getFieldValues],
    (sourceConfigurationsMap, fieldValues) => {
        const iosSite = fieldValues?.iosDestinationSite?.value || '';
        const androidSite = fieldValues?.androidDestinationSite?.value || '';
        const nonMobileSite = fieldValues?.nonMobileDestinationSite?.value || '';
        const source = fieldValues?.source?.value || '';

        return {
            iosWarning: sourceConfigurationsMap?.[`${source}_${iosSite}`]?.warningMessage || '',
            androidWarning: sourceConfigurationsMap?.[`${source}_${androidSite}`]?.warningMessage || '',
            nonMobileWarning: sourceConfigurationsMap?.[`${source}_${nonMobileSite}`]?.warningMessage || '',
        };
    }
);

const getShelfPlatformData = createSelector(
    [getAndoridSites, getIOSSites, getFieldValues],
    (androidSites, iosSites, fieldValues) => {
        let { iosLabel, androidLabel } = fieldValues;

        if (fieldValues.platformTrackingType !== PlatformTrackingTypes.MOBILE) {
            return { iosLabel: false, androidLabel: false };
        }

        // if this is edit mode, use the current field values
        if (fieldValues.linkId) {
            return { iosLabel, androidLabel };
        }

        // if this is create mode, figure out if one of the platforms can be selected by default
        if (!androidSites || !androidSites.length) {
            iosLabel = true;
            androidLabel = false;
        }
        if (!iosSites || !iosSites.length) {
            iosLabel = false;
            androidLabel = true;
        }
        return {
            iosLabel,
            androidLabel,
        };
    }
);

const getPartnerData = createSelector([getSingularLinksSourcesList, getFieldValues], (sourcesList, fieldValues) => {
    const isPartnerLink = fieldValues.linkType === LINK_TYPE_MOBILE_PARTNER;
    if (!isPartnerLink) {
        return null;
    }
    const sourceObj = sourcesList.find(source => {
        return source.name === fieldValues.source.value;
    });
    return sourceObj;
});

const getLinkTypeOptions = createSelector(
    [getUserData, getGroups, getFieldValues, getAdminModeEnabled],
    (userData, groups, fieldValues, adminMode) => {
        const mobileWebToAppLinkExists = groups.some(
            group => group.linkType === LINK_NAME_MOBILE_WEB_TO_APP && group.partners.length
        );

        const options = [...LINK_TYPE_OPTIONS];
        if (!userData.show_legacy_links) {
            const index = options.findIndex(o => o.name === 'legacy');
            if (index && index !== -1) {
                options.splice(index, 1);
            }
        }

        // Can't have multiple MW2A links per app (unless this is an existing link) + Only admins can create these
        if ((!adminMode || mobileWebToAppLinkExists) && fieldValues.linkType !== LINK_TYPE_MOBILE_WEB_TO_APP) {
            const index = options.findIndex(o => o.name === LINK_TYPE_MOBILE_WEB_TO_APP);
            if (index && index !== -1) {
                options.splice(index, 1);
            }
        }

        return options;
    }
);

const getPlatformTrackingTypeOptions = () => [
    { display_name: 'Mobile', name: PlatformTrackingTypes.MOBILE, regular: true },
    { display_name: 'PC/Console', name: PlatformTrackingTypes.PC_CONSOLE, regular: true },
];

const getOptionalExtraParamsDisplay = createSelector(
    [getOptionalExtraParams, getSelectedSource],
    (extraParams, selectedSource) => {
        const sourceExtraParams =
            selectedSource && selectedSource.extra_params ? Object.keys(selectedSource.extra_params) : [];
        return extraParams.map(param => ({
            value: param.name,
            label: param.name,
            displayName: param.display_name,
            description: param.description,
            extra: param.display_name,
            required: Boolean(param.required),
            isSourceExtraParam: sourceExtraParams.includes(param.name),
        }));
    }
);

const getLinkParams = createSelector([getOptionalExtraParamsDisplay, getFieldValues], (extraParams, fieldValues) => {
    const { linkParams } = fieldValues;
    if (linkParams) {
        const updateDict = extraParams.reduce((toUpdate, param) => {
            toUpdate[param.value] = { isSourceExtraParam: Boolean(param.isSourceExtraParam) };
            return toUpdate;
        }, {});

        linkParams.forEach(linkParam => {
            Object.assign(linkParam, updateDict[linkParam.field.value]);
        });

        return linkParams;
    }

    const requiredParams = extraParams
        .filter(param => param.required)
        .map(param => ({
            field: { value: param.value, label: param.value },
            value: '',
            required: true,
            isSourceExtraParam: Boolean(param.isSourceExtraParam),
            key: generateGuid(),
        }));
    return requiredParams.length > 0 ? requiredParams : defaultExtraParams;
});

const getShelfSelectedData = createSelector(
    [
        getIOSSites,
        getAndoridSites,
        getNonMobileSites,
        getShelfDomains,
        getShelfSources,
        getShelfDestinations,
        getSourceConfigurations,
        getShelfWarningMessages,
        getShelfPlatformData,
        getPartnerData,
        getConfig,
        getLinkTypeOptions,
        getPlatformTrackingTypeOptions,
        getOptionalExtraParamsDisplay,
        getLinkParams,
        getClickThroughDisplayLink,
        getCustomSourceNames,
    ],
    (
        iosSites,
        androidSites,
        nonMobileSites,
        domains,
        sources,
        shelfDestinations,
        sourceConfigurations,
        warningMessages,
        platformData,
        partnerData,
        config,
        linkTypeOptions,
        platformTrackingTypeOptions,
        optionalExtraParams,
        linkParams,
        clickThroughDisplayLink,
        customSourcesNames
    ) => {
        return {
            iosSites,
            androidSites,
            nonMobileSites,
            domains,
            sources,
            sourceConfigurations,
            partnerData,
            config,
            linkTypeOptions,
            platformTrackingTypeOptions,
            ...shelfDestinations,
            ...warningMessages,
            ...platformData,
            optionalExtraParams,
            linkParams,
            clickThroughDisplayLink,
            customSourcesNames,
        };
    }
);

const getOnlyValueOrNothing = (values, valueKey = null) => {
    let ret = '';
    if (values && values.length === 1) {
        ret = values[0];
        if (valueKey) {
            ret = ret[valueKey];
        }
    }
    return ret;
};

const getDefaultOrNothing = (values, valueKey = null, defaultKey = 'default') => {
    let ret = '';
    if (values && values.length) {
        ret = values.find(val => val[defaultKey]) || '';
        if (valueKey) {
            ret = ret[valueKey];
        }
    }
    return ret;
};

export const getShelfValues = createSelector(
    [getFormCategoriesList, getCategoryFields, getCategoryDefs, getFieldsDefs, getFieldValues, getShelfSelectedData],
    (formCategories, categoryFields, categoryDefs, fieldsDefs, fieldValues, shelfSelectedData) => {
        const useFieldValues = { ...fieldValues };
        [
            { dataKey: 'iosSites', dataValue: null, fieldValueKey: 'iosDestinationSite' },
            { dataKey: 'androidSites', dataValue: null, fieldValueKey: 'androidDestinationSite' },
            { dataKey: 'nonMobileSites', dataValue: null, fieldValueKey: 'nonMobileDestinationSite' },
            { dataKey: 'domains', dataValue: 'name', fieldValueKey: 'subDomain', checkDefault: true },
            { dataKey: 'iosRedirects', dataValue: null, fieldValueKey: 'iosDestinationRedirect' },
            { dataKey: 'androidRedirects', dataValue: null, fieldValueKey: 'androidDestinationRedirect' },
            { dataKey: 'nonMobileRedirects', dataValue: null, fieldValueKey: 'nonMobileDestinationRedirect' },
            { dataKey: 'iosWarning', dataValue: null, fieldValueKey: 'iosWarning', rawValue: true },
            { dataKey: 'androidWarning', dataValue: null, fieldValueKey: 'androidWarning', rawValue: true },
            { dataKey: 'nonMobileWarning', dataValue: null, fieldValueKey: 'nonMobileWarning', rawValue: true },
            { dataKey: 'iosLabel', dataValue: null, fieldValueKey: 'iosLabel', rawValue: true },
            { dataKey: 'androidLabel', dataValue: null, fieldValueKey: 'androidLabel', rawValue: true },
            { dataKey: 'linkParams', dataValue: null, fieldValueKey: 'linkParams', rawValue: true },
            {
                dataKey: 'clickThroughDisplayLink',
                dataValue: null,
                fieldValueKey: 'clickThroughDisplayLink',
                rawValue: true,
            },
            {
                dataKey: 'clickThroughDisplayLink',
                dataValue: null,
                fieldValueKey: 'clickThroughDisplayLinkWithQRCode',
                rawValue: true,
            },
        ].forEach(({ dataKey, dataValue, fieldValueKey, rawValue, checkDefault }) => {
            if (rawValue) {
                useFieldValues[fieldValueKey] = shelfSelectedData[dataKey];
                return;
            }
            let selectedValue = getOnlyValueOrNothing(shelfSelectedData[dataKey], dataValue);
            if (checkDefault && !selectedValue) {
                selectedValue = getDefaultOrNothing(shelfSelectedData[dataKey], dataValue);
            }
            useFieldValues[fieldValueKey] = useFieldValues[fieldValueKey] || selectedValue;
        });
        const validationSchema = {};
        const ret = {
            categories: formCategories
                .map(categoryName => {
                    const fullCategory = { ...categoryDefs[categoryName], name: categoryName };
                    fullCategory.fields = categoryFields[categoryName]
                        .filter(
                            fieldName =>
                                fieldsDefs[fieldName] &&
                                (fieldsDefs[fieldName].visible ||
                                    checkVisibilityRules(fieldsDefs[fieldName], fieldValues, shelfSelectedData))
                        )
                        .map(fieldName => {
                            const fieldDef = fieldsDefs[fieldName];
                            if (fieldDef.selector) {
                                fieldDef.selectedItems = shelfSelectedData[fieldDef.selector];
                            }
                            if (fieldDef.validations) {
                                validationSchema[fieldName] = fieldDef.validations;
                            }
                            fieldDef.extra = [useFieldValues, shelfSelectedData];

                            return {
                                ...fieldDef.json,
                                name: fieldName,
                            };
                        });
                    return fullCategory;
                })
                .filter(category => {
                    return category.fields.length || category.isLoading;
                }),
            fieldValues: useFieldValues,
        };
        ret.validationSchema = Yup.object().shape(validationSchema);

        return ret;
    }
);

export const getLinkEditorShelf = createSelector(
    [getShelf, getShelfValues],
    (shelf, { categories, fieldValues, validationSchema }) => {
        return {
            categories,
            fieldValues,
            validationSchema,
            shelf,
        };
    }
);

export const getGenerateLinkData = createSelector([getDomains, getApps, getFieldsDefs], (domains, apps, fieldDefs) => {
    return {
        domains,
        app: apps.find(app => app.selected),
        fieldDefs,
    };
});

const extractRedirectsFromLinkData = destination => {
    const platform = getPlatformPrefix(destination.platform);

    let ddlVal = destination.deferred_deeplink;
    if (destination.deferred_deeplink && destination.deeplink) {
        ddlVal =
            destination.deferred_deeplink === destination.deeplink
                ? SAME_AS_DEEPLINK_OBJECT
                : destination.deferred_deeplink;
    }
    return {
        [`${platform}DestinationRedirect`]: {
            value: destination.appstore,
            label: destination.appstore,
        },
        [`${platform}DestinationDDL`]: ddlVal,
        [`${platform}DestinationDeeplink`]: destination.deeplink,
        destinationFallback: destination.web_fallback,
    };
};

const extractSitesFromLinkData = (longname, sitesList, linkPlatform, translate) => {
    let longnames = [longname];
    if (typeof longname === 'object') {
        longnames = longname;
    }
    if (linkPlatform === allNonMobileSitesPlatform) {
        return {
            [`${nonMobilePlatform}DestinationSite`]: {
                value: null,
                label: translate('STATIC.PAGES.MANAGE_LINKS.ALL_NON_MOBILE_APPS'),
                longname,
            },
        };
    }

    // To support cases in which Android and iOS longname are the same
    const sites = longnames.reduce(
        (total, current) => [
            ...total,
            ...sitesList.filter(s => s.longname === current && nonMobileSitesFilter(s.platform, linkPlatform)),
        ],
        []
    );

    return sites
        .map(site => {
            const nonMobile = nonMobilePlatforms.includes(site.platform);
            const platformPrefix = getPlatformPrefix(site.platform);

            return {
                [`${platformPrefix}DestinationSite`]: {
                    value: site.site_id,
                    label: `${site.site_public_id}${nonMobile ? ` (${site.platform})` : ''}`,
                    appScheme: site.app_scheme,
                    appLinksSigningCertificate: site.app_signing_certificate,
                    iosTeamId: site.ios_team_id,
                },
            };
        })
        .reduce((total, item) => {
            return {
                ...total,
                ...item,
            };
        }, {});
};

export const getLinkData = linkData => {
    return createSelector(
        [
            getSingularLinksSourcesList,
            getAttributionSitesList,
            getFieldsDefs,
            getTranslate,
            getOptionalExtraParamsDisplay,
        ],
        (sourcesList, sitesList, fieldDefs, translate, extraParams) => {
            const isPartnerLink = linkData.type === LINK_TYPE_MOBILE_PARTNER;
            const list = isPartnerLink ? sourcesList : getPredefinedSourcesList(linkData.type);
            let customSourceName = '';
            const sourceObj = list.find(source => {
                if (isPartnerLink) {
                    return source.display_name === linkData.partner;
                } else {
                    return source.name === linkData.partner;
                }
            }) || {
                name: linkData.partner,
                display_name: linkData.partner,
                singular_links_ready: !!linkData.singular_link,
                support_clicks: true,
                ip_probabilistic_support_type: IP_PROBABILISTIC_SUPPORT_OPTIONS.OPTIONAL,
                extra_info: '',
                extra_params: {},
            };

            let partner = null;
            if (sourceObj) {
                const singularLinksReady = sourceObj.singular_links_ready || true;
                const supportClicks = sourceObj.support_clicks || true;
                const supportPCConsole = sourceObj.support_pc_console || false;
                const ipProbabilisticSupport =
                    sourceObj.ip_probabilistic_support_type != null
                        ? sourceObj.ip_probabilistic_support_type
                        : IP_PROBABILISTIC_SUPPORT_OPTIONS.NEVER;
                partner = {
                    label: sourceObj.display_name,
                    value: sourceObj.name,
                    singular_links_ready: singularLinksReady,
                    support_clicks: supportClicks,
                    ip_probabilistic_support_type: ipProbabilisticSupport,
                    support_pc_console: sourceObj.support_pc_console,
                    extra: getSourceExtra(singularLinksReady, supportClicks, supportPCConsole),
                    description: sourceObj.extra_info,
                    extra_params: sourceObj.extra_params,
                };
            } else if (!isPartnerLink) {
                const customOption = list.find(source => source.name === LINK_TYPE_MOBILE_CUSTOM) || list[0];
                partner = { label: customOption.display_name, value: customOption.name };
                customSourceName = linkData.partner;
            }

            const destinationsIter =
                linkData.platform === 'both'
                    ? linkData.destinations
                    : [{ ...linkData.destination, platform: linkData.platform }];
            let destinations = {};
            destinationsIter.forEach(dest => {
                destinations = { ...destinations, ...extractRedirectsFromLinkData(dest) };
            });

            linkData.attribution_settings = Object.keys(linkData.attribution_settings || {}).reduce(
                (total, currentKey) => {
                    total[currentKey] =
                        linkData.attribution_settings[currentKey] / fieldDefs[currentKey].normalizeValue;
                    return total;
                },
                {}
            );
            linkData.fieldsToUpdate = [getOverrideWindowsToggleLabel(isPartnerLink, partner, translate)]
                .concat(getWindowsUpdatedDefs(linkData.attribution_settings, fieldDefs))
                .concat(getIpProbabilisticToggleInfo(isPartnerLink, translate));

            if (!linkData.singular_link) {
                linkData.re_enabled = linkData.click_url && linkData.click_url.includes('re=1');
            }

            const requiredParams = extraParams.filter(param => param.required).map(param => param.value);
            linkData.extra_params = linkData.extra_params || {};
            requiredParams.forEach(param => {
                if (linkData.extra_params[param] === undefined) {
                    linkData.extra_params[param] = '';
                }
            });

            linkData.extra_params =
                Object.keys(linkData.extra_params).length > 0
                    ? Object.entries(linkData.extra_params).map(([k, v]) => ({
                          field: { value: k, label: k },
                          value: v,
                          required: requiredParams.includes(k),
                          key: generateGuid(),
                      }))
                    : defaultExtraParams;
            linkData.extra_params = sortBy(linkData.extra_params, param => !param.required);
            return {
                ...linkData,
                partner,
                destinations,
                sites: extractSitesFromLinkData(linkData.longname, sitesList, linkData.platform, translate),
                customSourceName,
                iosLabel: ['both', 'iOS'].includes(linkData.platform),
                androidLabel: ['both', 'Android'].includes(linkData.platform),
            };
        }
    );
};

export const getSingularLinksDomainsData = createSelector(
    [getDomains, getDnsZones, getSubDomainsShelf, getDomainsLimit, getIsAgency],
    (subDomains, dnsZones, shelf, domainsLimit, isAgency) => {
        return {
            subDomains: subDomains.map(({ subdomain, dns_zone, full_domain, favourite }) => {
                return {
                    fullDomain: full_domain,
                    favourite,
                    subdomain,
                    zone: dns_zone,
                };
            }),
            dnsZones: dnsZones ? dnsZones.map(zone => ({ display_name: zone, name: zone })) : [],
            shelf,
            domainsLimit,
            isAgency,
        };
    }
);
