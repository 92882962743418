import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import css from './Wizard.css';

const styles = () => ({
    root: ({ rootContainerStyle }) => ({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...rootContainerStyle,
    }),
    stepperRoot: ({ stepperRootBackgroundColor }) => ({
        backgroundColor: stepperRootBackgroundColor,
        padding: '12px 30px',
    }),
    stepRoot: {
        padding: 0,
        position: 'relative',
        top: 5,
    },
    stepLabelRoot: {
        flexDirection: 'column',
    },
    stepLabelIconContainer: {
        padding: 0,
    },
    stepLabelLabel: ({ stepLabelColor, stepLabelWidth }) => ({
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: stepLabelColor,
        width: stepLabelWidth,
        textAlign: 'center',
    }),
    stepLabelActive: ({ stepActiveLabelColor }) => ({
        '&.MuiStepLabel-active': {
            fontWeight: 500,
            cursor: 'pointer',
            color: stepActiveLabelColor,
        },
    }),
    stepLabelCompleted: ({ stepActiveLabelColor, stepLabelColor, isWizardCompleted }) => ({
        '&.MuiStepLabel-completed': {
            fontWeight: 500,
            cursor: 'pointer',
            color: isWizardCompleted ? stepLabelColor : stepActiveLabelColor,
        },
    }),
});

class MyStepIcon extends React.PureComponent {
    render() {
        const {
            active,
            completed,
            isFirst,
            isLast,
            stepNumber,
            showStepsNumbers,
            isMidStep,
            stepIconConnectorClass,
            stepperIconClass,
            stepActiveIconClass,
            CheckedIcon,
        } = this.props;

        const getStepperIconContent = () => {
            if (CheckedIcon && !active && completed) {
                return <CheckedIcon style={{ width: '100%', height: '100%' }} />;
            }
            return showStepsNumbers ? stepNumber : null;
        };

        return (
            <div
                className={classNames(
                    css.stepperIconContainer,
                    { [css.active]: active },
                    { [stepActiveIconClass]: active },
                    { [css.completed]: completed },
                    { [css.completedWithIcon]: !!CheckedIcon && completed },
                    { [css.first]: isFirst },
                    { [css.midStep]: isMidStep },
                    { [css.last]: isLast }
                )}
            >
                <div className={classNames(css.stepperIcon, stepperIconClass)}>{getStepperIconContent()}</div>
                <div className={classNames(css.stepperIconLine, css.stepperIconLineLeft, stepIconConnectorClass)} />
                <div className={classNames(css.stepperIconLine, css.stepperIconLineRight, stepIconConnectorClass)} />
            </div>
        );
    }
}

MyStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    isMidStep: PropTypes.bool,
    stepNumber: PropTypes.number,
    stepperIconClass: PropTypes.string,
    showStepsNumbers: PropTypes.bool,
    CheckedIcon: PropTypes.elementType,
    stepActiveColor: PropTypes.string,
    stepActiveIconClass: PropTypes.string,
    stepIconConnectorClass: PropTypes.string,
};

MyStepIcon.defaultProps = {
    active: false,
    completed: false,
    isFirst: false,
    isMidStep: false,
    isLast: false,
    stepNumber: 1,
    showStepsNumbers: false,
    CheckedIcon: undefined,
    stepperIconClass: '',
    stepActiveColor: '',
    stepActiveIconClass: '',
    stepIconConnectorClass: '',
};

class MyStepConnector extends React.PureComponent {
    // eslint-disable-line react/no-multi-comp
    render() {
        const { done, stepsConnectorClass } = this.props;
        return <div className={classNames(css.stepConnector, { [css.done]: done }, stepsConnectorClass)} />;
    }
}

MyStepConnector.propTypes = {
    done: PropTypes.bool,
    completed: PropTypes.bool,
    active: PropTypes.bool,
    stepActiveColor: PropTypes.string,
    stepsConnectorClass: PropTypes.string,
};

MyStepConnector.defaultProps = {
    done: false,
    completed: false,
    active: false,
    stepsConnectorClass: '',
};

class HorizontalLinearStepper extends React.Component {
    // eslint-disable-line react/no-multi-comp
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            skipped: new Set(),
            completedSteps: new Set(),
            lastAction: 'load',
        };

        if (props.activeStep) {
            this.controlled = true;
        }

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.getCurrentStep = this.getCurrentStep.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.handleStepClicked = this.handleStepClicked.bind(this);
        this.currentStep = React.createRef();
    }

    componentDidMount() {
        const { onWizardPageChanged, isWizardCompleted } = this.props;
        const { activeStep } = this;
        const steps = this.getSteps();
        const completedSteps = isWizardCompleted
            ? new Set([...Array(steps.length).keys()])
            : new Set([...Array(activeStep).keys()]);

        setTimeout(() => {
            this.setState({
                completedSteps,
            });
        });
        onWizardPageChanged(this.children[this.activeStep].props, this.children[this.activeStep].props, 'load');
    }

    componentDidUpdate() {
        const { completedSteps } = this.state;
        // Hack to color step connectors when done
        const connectors = document.querySelectorAll(`.${css.stepConnector}`);
        const { activeStep } = this;
        connectors.forEach((connector, index) => {
            if (index < activeStep || completedSteps.has(index)) {
                connector.classList.add(css.done);
            } else {
                connector.classList.remove(css.done);
            }
        });
    }

    get children() {
        const { children, branch } = this.props;
        if (branch) {
            return React.Children.toArray(children).filter(
                c => typeof c.props.branch === 'undefined' || c.props.branch.includes(branch)
            );
        } else {
            return React.Children.toArray(children);
        }
    }

    get activeStep() {
        const { activeStep: controlledActiveStep } = this.props;
        const { activeStep } = this.state;
        if (!this.controlled) {
            return activeStep;
        }
        return controlledActiveStep && this.children.findIndex(child => child.props.id === controlledActiveStep);
    }

    getSteps() {
        return this.children.map(c => ({ label: c.props.label, id: c.props.id, isMidStep: c.props.isMidStep }));
    }

    getCurrentStep(activeStep, steps) {
        const WizardPage = this.children[activeStep];
        return (
            <WizardPage.type
                {...WizardPage.props}
                isFirst={activeStep === 0}
                isLast={activeStep === steps.length - 1}
                onNext={this.handleNext}
                onBack={this.handleBack}
                onFinish={this.handleFinish}
                onCancel={this.handleCancel}
                lastAction={this.state.lastAction}
            />
        );
    }

    handleNext({ skipCompleted = false } = {}) {
        const { onWizardPageChanged } = this.props;
        let { skipped } = this.state;
        const { completedSteps } = this.state;
        const { activeStep } = this;
        if (this.isStepSkipped(activeStep)) {
            skipped = new Set(skipped.values());
            skipped.delete(activeStep);
        }
        let nextStep = activeStep + 1;
        while (skipCompleted && completedSteps.has(nextStep)) {
            nextStep++;
        }
        this.setState({
            activeStep: nextStep,
            skipped,
            completedSteps: completedSteps.add(activeStep),
            lastAction: 'next',
        });
        onWizardPageChanged(this.children[this.activeStep].props, this.children[nextStep].props, 'next');
    }

    handleBack() {
        const { onWizardPageChanged } = this.props;
        this.setState({
            activeStep: this.activeStep - 1,
            lastAction: 'back',
        });
        onWizardPageChanged(this.children[this.activeStep].props, this.children[this.activeStep - 1].props, 'back');
    }

    _wizardableIsValid() {
        // trying to figure out if the wizardable page is valid or not
        let ret = true;
        try {
            ret = this.currentStep.current.wrappedInstance.validate();
        } catch (e) {}
        try {
            ret = this.currentStep.current.validate();
        } catch (e) {}

        return ret;
    }

    handleStepClicked(step, stepIndex) {
        const { onWizardPageChanged, handleStepClicked } = this.props;

        handleStepClicked(stepIndex);

        const { completedSteps } = this.state;

        if (!completedSteps.has(stepIndex)) {
            return;
        }

        // Either valid, or active step is required to perform navigation
        if (!this._wizardableIsValid() && stepIndex < Math.max.apply(null, Array.from(completedSteps))) {
            return;
        }

        this.setState({
            activeStep: stepIndex,
            lastAction: 'back',
        });

        onWizardPageChanged(this.children[this.activeStep].props, this.children[stepIndex].props, 'back');
    }

    handleFinish(...args) {
        const { onWizardFinish } = this.props;
        onWizardFinish(...args);
    }

    handleCancel() {
        const { onWizardCancel } = this.props;
        onWizardCancel();
    }

    handleSkip() {
        this.setState(state => {
            const skipped = new Set(state.skipped.values());
            skipped.add(this.activeStep);
            return {
                activeStep: this.activeStep + 1,
                skipped,
            };
        });
    }

    handleReset() {
        this.setState({
            activeStep: 0,
        });
    }

    isStepSkipped(step) {
        return this.state.skipped.has(step);
    }

    render() {
        const {
            classes,
            showStepper,
            showStepsNumbers,
            stepperContainerStyle,
            stepperIconClass,
            stepsConnectorClass,
            stepIconConnectorClass,
            stepActiveIconClass,
            stepActiveColor,
            CheckedIcon,
            cssClassThemeName,
            children,
            isHidden,
        } = this.props;

        if (isHidden) return children;

        const { completedSteps } = this.state;
        const steps = this.getSteps();

        const { activeStep } = this;

        return (
            <div className={classNames(classes.root, cssClassThemeName)}>
                <Stepper
                    activeStep={activeStep}
                    connector={
                        <MyStepConnector stepActiveColor={stepActiveColor} stepsConnectorClass={stepsConnectorClass} />
                    }
                    classes={{
                        root: classes.stepperRoot,
                    }}
                    style={{
                        display: showStepper ? 'flex' : 'none',
                        ...stepperContainerStyle,
                    }}
                    className={css.stepper}
                >
                    {steps.map(({ label, id, isMidStep }, index) => {
                        const isActive = index === activeStep;
                        const isCompleted = index < activeStep || completedSteps.has(index);
                        const props = {};
                        if (this.isStepSkipped(index)) {
                            props.completed = false;
                        }
                        return (
                            <Step
                                key={id}
                                classes={{
                                    root: classes.stepRoot,
                                }}
                                onClick={() => {
                                    this.handleStepClicked({ label, id }, index);
                                }}
                                {...props}
                            >
                                <StepLabel
                                    classes={{
                                        root: classes.stepLabelRoot,
                                        iconContainer: classes.stepLabelIconContainer,
                                        label: classes.stepLabelLabel,
                                        active: classes.stepLabelActive,
                                        completed: classes.stepLabelCompleted,
                                    }}
                                    icon={
                                        <MyStepIcon
                                            active={isActive}
                                            completed={isCompleted}
                                            isFirst={index === 0}
                                            showStepsNumbers={showStepsNumbers}
                                            stepNumber={index + 1}
                                            isLast={index === steps.length - 1}
                                            stepActiveColor={stepActiveColor}
                                            isMidStep={isMidStep}
                                            stepActiveIconClass={stepActiveIconClass}
                                            stepperIconClass={stepperIconClass}
                                            stepIconConnectorClass={stepIconConnectorClass}
                                            CheckedIcon={CheckedIcon}
                                        />
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {this.getCurrentStep(activeStep, steps)}
            </div>
        );
    }
}

HorizontalLinearStepper.propTypes = {
    classes: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.arrayOf(PropTypes.element),
    branch: PropTypes.string,
    onWizardFinish: PropTypes.func,
    onWizardCancel: PropTypes.func,
    showStepper: PropTypes.bool,
    showStepsNumbers: PropTypes.bool,
    activeStep: PropTypes.string,
    onWizardPageChanged: PropTypes.func,
    stepperRootBackgroundColor: PropTypes.string,
    stepperIconClass: PropTypes.string,
    CheckedIcon: PropTypes.elementType,
    stepsConnectorClass: PropTypes.string,
    stepIconConnectorClass: PropTypes.oneOfType([PropTypes.string]),
    stepActiveIconClass: PropTypes.oneOfType([PropTypes.string]),
    stepLabelColor: PropTypes.oneOfType([PropTypes.string]),
    stepActiveColor: PropTypes.oneOfType([PropTypes.string]),
    stepActiveLabelColor: PropTypes.oneOfType([PropTypes.string]),
    stepsIconConnectorClass: PropTypes.string,
    stepperContainerStyle: PropTypes.shape({
        padding: PropTypes.oneOfType([PropTypes.string]),
        width: PropTypes.oneOfType([PropTypes.string]),
    }),
    rootContainerStyle: PropTypes.shape({
        height: PropTypes.oneOfType([PropTypes.string]),
    }),
    handleStepClicked: PropTypes.func,
    isHidden: PropTypes.bool,
    cssClassThemeName: PropTypes.string,
    isWizardCompleted: PropTypes.bool,
};

HorizontalLinearStepper.defaultProps = {
    children: [],
    branch: '',
    classes: {},
    onWizardFinish: () => {},
    onWizardCancel: () => {},
    showStepper: false,
    showStepsNumbers: false,
    activeStep: '',
    onWizardPageChanged: () => {},
    stepperRootBackgroundColor: '#CCCCCC',
    stepperIconClass: '',
    CheckedIcon: undefined,
    stepsConnectorClass: '',
    stepsIconConnectorClass: '',
    stepIconConnectorClass: '',
    stepActiveIconClass: '',
    stepLabelColor: '#515864',
    stepActiveColor: '#515864',
    stepActiveLabelColor: '#191e21',
    stepperContainerStyle: {},
    rootContainerStyle: {},
    handleStepClicked: () => {},
    isHidden: false,
    cssClassThemeName: undefined,
    isWizardCompleted: undefined,
};

export default withStyles(styles)(HorizontalLinearStepper);
