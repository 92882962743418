import React from 'react';
import { Label } from '../../../../../components/widgets';
import css from './revenue.css';
import { getSectionTranslateID } from '../../../sectionsWrapper/utils';
import { SDK_INTEGRATION_SECTIONS } from '../../../consts';
import { useSDKPlanningContext } from '../../../context/useSDKPlanningContext';

const { REVENUE } = SDK_INTEGRATION_SECTIONS;

const Revenue = () => {
    const { selectedTargetPlatformShortName: platform } = useSDKPlanningContext();

    return (
        <div>
            <Label className={css.title} type="sideLabel" text={getSectionTranslateID(REVENUE, 'ABOUT', platform)} />
            <Label className={css.content} type="p" text={getSectionTranslateID(REVENUE, 'CONTENT', platform)} />
        </div>
    );
};

export default Revenue;
