import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faWarning from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';
import { Tooltip } from 'react-tippy';
import WizardFooter from 'components/widgets/WizardFooter';
import Wizardable from 'components/widgets/Wizardable';
import css from 'teamManagement/components/ScreenSelection.css';
import { FauxButton, Checkbox } from 'components/widgets';
import WizardWarningMessage from 'teamManagement/components/WizardWarningMessage';
import AddFutureCheckbox from 'teamManagement/containers/AddFutureCheckbox';
import ExpandableGroup from 'teamManagement/components/ExpandableGroup';

class ScreenSelection extends Wizardable {
    constructor(props) {
        super(props);
        this.state = {
            sourceFilter: '',
        };
        this.toggleAll = this.toggleAll.bind(this);
        this.buttonOverrides = props.wizardOverrideButtons;
        this.allScreens = this._getAllScreens(props.screens);
        this.disabledScreens = this.allScreens.filter((screen) => screen.disabled);
    }

    _getAllScreens(screens) {
        return screens.reduce((total, section) => {
            total.push(...section.children);
            return total;
        }, []);
    }

    toggleAll() {
        const { toggleScreens, selectedScreens } = this.props;
        const filteredFlat = this.allScreens.filter((screen) => this.disabledScreens.indexOf(screen) === -1);
        const isAllScreensSelected = selectedScreens.length === filteredFlat.length;
        toggleScreens(filteredFlat.map((screen) => screen.name), !isAllScreensSelected);
    }

    sectionCheckboxStateChanged(section, newState) {
        this.toggleAllInSection(section, !!newState);
    }

    toggleAllInSection(section, selected) {
        const { toggleScreens } = this.props;
        toggleScreens(section.children.map((screen) => screen.name), selected);
    }

    screenCheckboxStateChanged(screen, newState) {
        const { toggleScreens } = this.props;
        toggleScreens([screen.name], newState);
    }

    render() {
        const { screens, selectedScreens, lastAction } = this.props;
        const selected = selectedScreens.length;
        return (
            <React.Fragment>
                <WizardWarningMessage show message="STATIC.PAGES.TEAM_MEMBERS.SCREEN_SELECTION_WARNING" />
                <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                    <div className={css.headline}>
                        <div className={css.preamble}>
                            <Translate id="STATIC.PAGES.TEAM_MEMBERS.WIZARD_SCREEN_SELECTION_TITLE" />
                            <div className={css.faqLine}>
                                For more information{' '}
                                <a
                                    href="https://singular.zendesk.com/hc/en-us/articles/360020835952-Team-Management-FAQ"
                                    target="_blank"
                                >
                                    read the FAQ
                                </a>
                                .
                            </div>
                        </div>
                        <div className={css.selectorContainer}>
                            <FauxButton onClick={this.toggleAll} containerStyle={{ padding: 0 }}>
                                {selected + this.disabledScreens.length !== this.allScreens.length
                                    ? 'Select'
                                    : 'Deselect'}{' '}
                                All
                            </FauxButton>
                        </div>
                    </div>
                    <div className={css.contentArea}>
                        {screens.map((section) => {
                            const selectedChildren = section.children.filter((child) => child.selected);
                            const sectionChecked = selectedChildren.length === section.children.length;
                            const sectionIndeterminate =
                                selectedChildren.length > 0 && selectedChildren.length < section.children.length;
                            const hasWarnings = section.children.filter((child) => child.warning === true).length > 0;
                            return (
                                <div className={css.expandableGroupHolder} key={section.name}>
                                    <ExpandableGroup
                                        marked={sectionIndeterminate || sectionChecked}
                                        hasWarning={hasWarnings}
                                        title={section.displayName}
                                        rightComponents={
                                            <Checkbox
                                                label={`${selectedChildren.length} of ${section.children.length} selected`}
                                                className={css.sectionCheckbox}
                                                labelPosition="left"
                                                checked={sectionChecked}
                                                threeStatesEnabled
                                                indeterminate={sectionIndeterminate}
                                                onChange={(newState) => {
                                                    this.sectionCheckboxStateChanged(section, newState);
                                                }}
                                            />
                                        }
                                    >
                                        <div>
                                            {section.children.map((screen) => {
                                                if (screen.selected && screen.disabled) {
                                                    this.screenCheckboxStateChanged(screen, false);
                                                }
                                                return (
                                                    <div className={css.screenContainer} key={screen.name}>
                                                        {screen.warning ? (
                                                            <FontAwesomeIcon
                                                                className={css.screenWarning}
                                                                icon={faWarning}
                                                            />
                                                        ) : null}
                                                        <Tooltip
                                                            key={`${screen.name}Feature`}
                                                            html={screen.disabledTooltip}
                                                            disabled={!screen.disabledTooltip}
                                                            position="top-start"
                                                            inertiaf
                                                            animation="scale"
                                                            distance={10}
                                                            theme="light"
                                                            size="big"
                                                        >
                                                            <Checkbox
                                                                label={screen.displayName}
                                                                checked={screen.selected}
                                                                className={css.sectionCheckbox}
                                                                onChange={(newState) => {
                                                                    this.screenCheckboxStateChanged(screen, newState);
                                                                }}
                                                                size="small"
                                                                checkmarkStyle={{ marginRight: 6 }}
                                                                disabled={screen.disabled}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </ExpandableGroup>
                                </div>
                            );
                        })}
                    </div>
                </this.AnimationItem>
                <WizardFooter
                    buttons={this.getButtons()}
                    leftComponents={<AddFutureCheckbox itemType="screens" form="screenSelectionForm" />}
                />
            </React.Fragment>
        );
    }
}

ScreenSelection.propTypes = {
    wizardOverrideButtons: PropTypes.objectOf(PropTypes.any),
    screens: PropTypes.arrayOf(PropTypes.any),
};

ScreenSelection.defaultProps = {
    wizardOverrideButtons: {},
    screens: [],
};

export default ScreenSelection;
