import { connect } from 'react-redux';
import MetricsSelection from 'teamManagement/components/MetricsSelection';
import { getMetricsSelectionPage } from 'teamManagement/selectors';
import { changeMetricSelection } from 'teamManagement/actions';

const mapStateToProps = (state, ownProps) => {
    return getMetricsSelectionPage(state);
};

const mapDispatchToProps = dispatch => {
    return {
        onChange: (id, value) => dispatch(changeMetricSelection(id, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MetricsSelection);
