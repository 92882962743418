import API from '../services/api';
import { getUtmParams } from './utils';

let instance = null;
const api = new API();
const URL_SUBMIT_SIGNUP_FORM = '/signup/submit';
const URL_STARTED_SIGNUP_FORM = '/signup/started';
const URL_GOOGLE_STARTED_SIGNUP = '/signup/google/started';
const URL_GOOGLE_COMPLETE_SIGNUP = '/signup/google_sign_up';
const URL_RESEND_EMAIL = '/signup/resend_email';
const URL_SIGNUP_METADATA_PARAMETERS = '/api/signup_metadata_parameters';

export default class SignupService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    startedGoogleSignUpForm(data) {
        return api.getJson(URL_GOOGLE_STARTED_SIGNUP, {
            method: 'POST',
            legacyStatus: true,
            body: {
                oauth_access_token: data.accessToken,
                ...getUtmParams(),
                url: window.location.href,
                is_free_tier: window.isFreeTier,
            },
        });
    }

    submitCompleteGoogleSignUp(data) {
        return api.getJson(URL_GOOGLE_COMPLETE_SIGNUP, {
            method: 'POST',
            legacyStatus: true,
            body: {
                oauth_access_token: data.accessToken,
                currency: data.currency.name,
                timezone: data.timezone.name,
                companyName: data.companyName,
                has_mobile_app: data.hasMobileApp.name,
                company_stage: data.companyStage.value,
                user_role: data.userRole.name,
                is_agency: data.isAgency.value,
                ...getUtmParams(),
                url: window.location.href,
                is_free_tier: window.isFreeTier,
                country: data.country.display_name,
            },
        });
    }

    startedSignupForm(data) {
        return api.getJson(URL_STARTED_SIGNUP_FORM, {
            method: 'POST',
            legacyStatus: true,
            body: {
                email: data.email,
                is_free_tier: window.isFreeTier,
                ...getUtmParams(),
            },
        });
    }

    submitSignupForm(data) {
        return api.getJson(URL_SUBMIT_SIGNUP_FORM, {
            method: 'POST',
            legacyStatus: true,
            body: {
                full_name: data.fullName,
                email: data.email,
                password: data.password,
                currency: data.currency.name,
                timezone: data.timezone.name,
                user_role: data.userRole.name,
                companyName: data.companyName,
                has_mobile_app: data.hasMobileApp.name,
                company_stage: data.companyStage.value,
                is_agency: data.isAgency.value,
                recaptcha_token: data.recaptchaToken,
                ...getUtmParams(),
                url: window.location.href,
                is_free_tier: window.isFreeTier,
                country: data.country.display_name,
            },
        });
    }

    resendEmail(data) {
        return api.getJson(URL_RESEND_EMAIL, {
            method: 'POST',
            legacyStatus: true,
            body: {
                email: data.email,
            },
            fixErr: true,
        });
    }

    getMetadataParameters() {
        return api.getJson(URL_SIGNUP_METADATA_PARAMETERS, {
            method: 'GET',
            legacyStatus: true,
            credentials: 'include',
            params: {},
        });
    }
}
