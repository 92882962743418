import { createSelector } from 'reselect';
import { getAttributionAppsList } from '../selectors/apps';
import { sortAlphabetically } from '../utils/sortUtil';

export const getLoading = state => state.mta.loading;
export const getUserData = state => state.user.data;

export const getEventData = state => state.mta.uanEventsData;

export const getConversionEvents = createSelector([getEventData], events => {
    if (!events) {
        return [];
    }
    return events
        .filter(event => event.name !== '__PAGE_VISIT__')
        .map(event => {
            return {
                id: event.id,
                key: String(event.event_id),
                name: event.name,
                display_name: event.name,
                site_id: event.site_id,
            };
        })
        .sort((a, b) => sortAlphabetically(a.name, b.name, false, false));
});

export const getApps = createSelector([getAttributionAppsList], apps => {
    if (!apps) {
        return [];
    }

    return apps
        .filter(app => app.platforms.find(platform => platform.name === 'Web'))
        .filter(app => {
            // return true if at least one site have a longname.
            return app.sites.some(site => site.platform === 'Web' && site.longname);
        })
        .map(app => {
            const sites = app.sites
                .filter(site => site.platform === 'Web' && site.longname)
                .map(site => {
                    return {
                        id: site.site_id,
                        key: String(site.site_id),
                        name: site.longname,
                        display_name: site.longname,
                        longname: site.longname,
                    };
                });

            return {
                id: app.app_id,
                name: app.name,
                display_name: app.name,
                sites,
            };
        })
        .sort((a, b) => sortAlphabetically(a.name, b.name, false, false));
});
