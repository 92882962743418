import { connect } from 'react-redux';
import { getPage } from './selectors';
import Page from './page';
import { openImpersonateDialog } from '../actions/user';

const mapDispatchToProps = dispatch => {
    return {
        openDialog: () => {
            dispatch(openImpersonateDialog());
        },
    };
};

export default connect(state => getPage(state), mapDispatchToProps)(Page);
