import API from '../services/api';

let instance = null;
const api = new API();

const QUERY_URL = 'api/realtime_reporting';

export default class AthenaOverRealtimeAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    runQuery() {
        return new Promise((resolve, reject) => {
            api.getJson(QUERY_URL, {
                method: 'GET',
                credentials: 'include',
                fixErr: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
