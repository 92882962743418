import React, { useCallback, useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap-daterangepicker';
import SingularButton from '../../components/widgets/SingularButton';
import singularButtonCss from '../../components/widgets/SingularButton.css';
import ExternalLinkIcon from '../../resources/svg/export_external_link.svg';
import { getImpersonationFromQuery } from '../../utils/url';
import { trackMixpanelEvent } from '../../utils/general';
import GeneralPopup, { PopupTypes } from '../../components/partials/GeneralPopup';
import css from './audits.css';
import { ROLE_RESTRICTED } from '../../teamManagement/utils';
import AuditsSchema from '../../resources/png/audits_schema.png';
import SdkAuditsSchema from '../../resources/png/sdk_audit_schema.png';
import { trackTaskButtonClick, UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL, VALIDATE_SETUP } from '../../homepage/utils';

function Audits(props) {
    const {
        translate,
        auditSdk,
        auditGeneral,
        validationId,
        validationMessage,
        resetValidationMessage,
        userData,
        validationType,
        isSdkAuditLoading,
        isGeneralAuditLoading,
        itemId,
    } = props;

    const isGeneralAuditEnabled = useCallback(() => {
        return userData.role_type !== ROLE_RESTRICTED;
    }, [userData]);

    useEffect(() => {
        if (validationId && userData && validationType) {
            let url = `/logs/download?validation_id=${validationId}&validation_type=${validationType}`;

            const impersonatedOrganization = getImpersonationFromQuery('customer_id');
            if (impersonatedOrganization) {
                url += `&customer_id=${impersonatedOrganization}`;
            }

            trackMixpanelEvent('Audits', `Clicked ${validationType.toUpperCase()} Audit`, { validationId });

            window.open(url, '_blank');
        }
    }, [validationId, userData, validationType]);

    const innerButtonClick = (buttonName, callback) => {
        trackTaskButtonClick(itemId, null, VALIDATE_SETUP, UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL, buttonName);
        callback();
    };

    return (
        <div>
            <div className={css.auditsTextSector}>
                {isGeneralAuditEnabled() && (
                    <div className={css.auditSector}>
                        <div className={css.textSector}>
                            {translate('STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.GENERAL_AUDIT')}
                        </div>
                        <SingularButton
                            textClassName={css.auditButtonText}
                            className={css.auditButton}
                            onClick={() => {
                                innerButtonClick('Run General audit', auditGeneral);
                            }}
                            type="secondary"
                            showSpinner={isGeneralAuditLoading}
                        >
                            {translate('STATIC.PAGES.ONBOARDING_GUIDE.BUTTON.GENERAL_AUDIT')}
                            <ExternalLinkIcon className={singularButtonCss.icon} style={{ margin: '0 0 0 8px' }} />
                        </SingularButton>
                    </div>
                )}
                <div className={css.auditSector}>
                    <GeneralPopup
                        open={!!validationMessage}
                        type={PopupTypes.INFO}
                        title="Just a moment..."
                        text={validationMessage}
                        acceptText="Got it"
                        onAccept={resetValidationMessage}
                        onReject={resetValidationMessage}
                    />
                    <div className={css.textSector}>{translate('STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.SDK_AUDIT')}</div>
                    <SingularButton
                        textClassName={css.auditButtonText}
                        className={css.auditButton}
                        onClick={() => {
                            innerButtonClick('Run SDK audit', auditSdk);
                        }}
                        type="secondary"
                        showSpinner={isSdkAuditLoading}
                    >
                        {translate('STATIC.PAGES.ONBOARDING_GUIDE.BUTTON.SDK_AUDIT')}
                        <ExternalLinkIcon className={singularButtonCss.icon} style={{ margin: '0 0 0 8px' }} />
                    </SingularButton>
                </div>
            </div>
            <img className={css.auditsSchemaImg} src={isGeneralAuditEnabled() ? AuditsSchema : SdkAuditsSchema} />
        </div>
    );
}

Audits.propTypes = {
    translate: PropTypes.func.isRequired,
    auditSdk: PropTypes.func,
    auditGeneral: PropTypes.func,
    resetValidationMessage: PropTypes.func,
    validationId: PropTypes.string,
    validationMessage: PropTypes.string,
    userData: PropTypes.objectOf(PropTypes.any),
    validationType: PropTypes.string,
    isSdkAuditLoading: PropTypes.bool,
    isGeneralAuditLoading: PropTypes.bool,
    itemId: PropTypes.string,
};

Audits.defaultProps = {
    auditSdk: () => {},
    auditGeneral: () => {},
    resetValidationMessage: () => {},
    validationId: null,
    validationMessage: '',
    userData: {},
    validationType: null,
    isSdkAuditLoading: false,
    isGeneralAuditLoading: false,
    itemId: '',
};

export default withLocalize(Audits);
