import { reportBase, cohorts } from './reportsBase';
import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';

export default {
    type: reportTypes.skanSummary,
    fields: [
        ...reportBase.fields,
        ...cohorts.fields,
        'skan_redownloads_dimensions',
        'skan_validated_dimensions',
        'confidenceIntervalFlag',
        'discrepancyMetrics',
        'skanDateDimensionName',
        'skanCustomEvents',
        'withDruidAppend',
    ],
    visibleRule: rules[reportTypes.skanSummary],
    dataSelector: {
        ...reportBase.dataSelector,
        skanDateDimensionName: 'skanDateDimensionName',
        ...cohorts.dataSelector,
        confidenceIntervalFlag: 'confidenceIntervalFlag',
        skanCustomEvents: 'skanCustomEvents',
        skan_redownloads_dimensions: 'skan_redownloads_dimensions',
        showRedownloadsDimensionInReport: 'showRedownloadsDimensionInReport',
        skan_validated_dimensions: 'skan_validated_dimensions',
        showValidatedDimensionInReport: 'showValidatedDimensionInReport',
        discrepancyMetrics: 'discrepancyMetrics',
        tableDataFormatted: 'reportData',
        withDruidAppend: 'withDruidAppend',
    },
    sourceAttributionType: ['skan'],
    isSkanSummary: true,
};
