import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaxSlotsIndicator from '../../conversionModelForm/MaxSlotsIndicator';
import SkanEventsGroup from './SkanEventsGroup';
import { conversionModelTypeShape, modelErrorsShape, sdkEventsShape } from '../types';
import { getMaxSlotsData, getUsedSlotsByEventType } from '../eventsUtils';
import { ModelKinds, ModelTypes, NUMBER_OF_BITS } from '../../../consts';

function EventsType({ values, sdkEvents, maxSlots, onChange, errors }) {
    const { eventsType, apsalarEventIds, modelType } = values;
    const [eventIds, setEventIds] = useState(apsalarEventIds);
    const [maxSlotsData, setMaxSlotsData] = useState(() => getMaxSlotsData(eventsType, maxSlots, apsalarEventIds));

    useEffect(() => {
        setEventIds(apsalarEventIds);
    }, [apsalarEventIds]);

    useEffect(() => {
        if (!eventIds.length) return;

        const updatedValues = { apsalarEventIds: eventIds };

        if (modelType !== ModelTypes.MIXED) {
            updatedValues.usedSlots = getUsedSlotsByEventType(eventsType, eventIds, maxSlots);
        }

        onChange(updatedValues);
    }, [eventIds, eventsType, maxSlots]);

    useEffect(() => {
        setMaxSlotsData(getMaxSlotsData(eventsType, maxSlots, apsalarEventIds));
    }, [maxSlots, eventsType, apsalarEventIds]);

    const getMaxEvents = useCallback(() => {
        if (eventsType === ModelKinds.FUNNEL) {
            return Math.pow(2, maxSlots) - 1;
        }

        return maxSlots;
    }, [eventsType, maxSlots]);

    return (
        <>
            <MaxSlotsIndicator {...maxSlotsData} />
            {eventIds && (
                <SkanEventsGroup
                    sdkEvents={sdkEvents}
                    maxEvents={getMaxEvents()}
                    eventIds={eventIds}
                    setEventIds={setEventIds}
                    errors={errors}
                />
            )}
        </>
    );
}

EventsType.propTypes = {
    values: conversionModelTypeShape.isRequired,
    sdkEvents: PropTypes.objectOf(sdkEventsShape).isRequired,
    errors: modelErrorsShape,
    maxSlots: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};

EventsType.defaultProps = {
    maxSlots: NUMBER_OF_BITS,
    errors: {},
};

export default EventsType;
