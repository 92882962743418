import React, { useEffect, useState } from 'react';
import { Formik, Form, setNestedObjectValues } from 'formik-2';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import css from './PartnerConfigurationShelf.css';
import { Shelf, SingularButton, Spinner, Tabs, WizardFooter } from '../../../components/widgets';
import OneCircleIcon from '../../../resources/svg/timeline/one.svg';
import TwoCircleIcon from '../../../resources/svg/timeline/two.svg';
import ThreeCircleIcon from '../../../resources/svg/timeline/three.svg';
import ChevronIcon from '../../../resources/icons/chevron.svg';
import AttributionSettings, { getAttributionSettingsValidationSchema } from './AttributionSettings';
import {
    GET_PARTNER_CONFIGURATION_FOR_APP,
    GET_PARTNER,
    SAVE_CONFIGURATION,
    GET_PARTNER_CONFIGURATIONS_FOR_PARTNER,
    GET_CONFIGURED_PARTNERS,
    GET_ORGANIZATION_OPTIONS,
    GET_PARTNER_APP_DEFAULT_WINDOWS,
} from '../../queries';
import { siteShape, partnerShape } from '../types';
import AppSelection from './AppSelection';
import EventsSettings, { getEventsSettingsValidationSchema } from './EventsSettings';
import GuideIcon from '../../../resources/svg/guide.svg';
import WarningMessage, { MessageTypes } from '../../../teamManagement/components/WizardWarningMessage';
import {
    parsePartnerConfigurationFromServer,
    parsePartnerConfigurationToServer,
    trackPartnerConfigMixpanelEvent,
} from './utils';
import { getIsAgency, isUserAdNetwork } from '../../../selectors/user';
import tabsCss from '../../../components/widgets/Tabs.css';
import DuplicateAppSelection from './DuplicateAppSelection';
import AdvancedSettings from './AdvancedSettings';

export const SHELF_DATA_TEST_ID = 'partner-config-shelf';

const ATTRIBUTION_SETTINGS = 'ATTRIBUTION_SETTINGS';
const EVENTS_SETTINGS = 'EVENTS_SETTINGS';
const ADVANCED_SETTINGS = 'ADVANCED_SETTINGS';

const SHELF_PAGES = [
    {
        id: ATTRIBUTION_SETTINGS,
        label: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.HEADER',
        icon: OneCircleIcon,
    },
    {
        id: EVENTS_SETTINGS,
        label: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.EVENTS_SETTINGS',
        icon: TwoCircleIcon,
    },
    {
        id: ADVANCED_SETTINGS,
        label: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.HEADER',
        icon: ThreeCircleIcon,
    },
];

const getFooterButtons = (shelfPages, selectedPage, isSaving, isReadonly, isDuplicate, onClose, onPageChanged) => {
    const isFirstPage = selectedPage === shelfPages[0].id;
    const isLastPage = selectedPage === shelfPages[shelfPages.length - 1].id;
    const getClassName = disabled => classNames(css.footerBtn, { [css.disabled]: disabled });

    return [
        <SingularButton type="flat" onClick={onClose} className={css.cancelButton}>
            <Translate id="STATIC.BUTTONS.CANCEL" />
        </SingularButton>,
        <SingularButton type="secondary" className={getClassName(isFirstPage)} onClick={() => onPageChanged(false)}>
            <>
                <ChevronIcon className={classNames(css.arrow, css.back)} />
                <Translate id="STATIC.BUTTONS.BACK" />
            </>
        </SingularButton>,
        <SingularButton type="secondary" className={getClassName(isLastPage)} onClick={() => onPageChanged(true)}>
            <>
                <Translate id="STATIC.BUTTONS.NEXT" />
                <ChevronIcon className={classNames(css.arrow, css.next)} />
            </>
        </SingularButton>,
        <SingularButton submit disabled={isSaving} showSpinner={isSaving} disabledDark={isReadonly}>
            <Translate id={`STATIC.BUTTONS.${isDuplicate ? 'DUPLICATE' : 'SAVE'}`} />
        </SingularButton>,
    ];
};

const containsAdvancedFeatures = organizationOptions =>
    organizationOptions.ldsSupported || organizationOptions.geoRestrictionsSupported;

export default function PartnerConfigurationShelf({
    open,
    partner,
    siteId,
    agencyId,
    apps,
    isDuplicate,
    onClose,
    loading: shelfLoading,
}) {
    const [selectedPage, setSelectedPage] = useState(ATTRIBUTION_SETTINGS);
    const [selectedSiteId, setSelectedSiteId] = useState(siteId);
    const [duplicatedSiteId, setDuplicatedSiteId] = useState(null);
    const [warning, setWarning] = useState(null);
    const isAgency = useSelector(state => getIsAgency(state));
    const isPartner = useSelector(state => isUserAdNetwork(state));

    const [getPartner, partnerResponse] = useLazyQuery(GET_PARTNER);
    const [getPartnerConfiguration, partnerConfigurationResponse] = useLazyQuery(GET_PARTNER_CONFIGURATION_FOR_APP);
    const [savePartnerConfiguration, savePartnerConfigurationResponse] = useMutation(SAVE_CONFIGURATION);
    const [, configuredPartnerResponse] = useLazyQuery(GET_CONFIGURED_PARTNERS);
    const [, partnerConfigurationsResponse] = useLazyQuery(GET_PARTNER_CONFIGURATIONS_FOR_PARTNER, {
        variables: { partnerId: partner.id },
    });

    const [getPartnerWindows] = useLazyQuery(GET_PARTNER_APP_DEFAULT_WINDOWS);
    const organizationOptionsResponse = useQuery(GET_ORGANIZATION_OPTIONS);
    const organizationOptions = organizationOptionsResponse.data || {};

    const filteredShelfPages = containsAdvancedFeatures(organizationOptions)
        ? SHELF_PAGES
        : SHELF_PAGES.filter(page => page.id !== ADVANCED_SETTINGS);

    const partnerDetails = partnerResponse?.data?.partner;
    const { guideLink, postbackTemplates } = partnerDetails || {};
    const sites = apps.map(app => app.sites).flat();
    const selectedSite = sites.find(site => site.site_id === selectedSiteId);
    const duplicatedSite = duplicatedSiteId ? sites.find(site => site.site_id === duplicatedSiteId) : null;
    const isEdit = !isDuplicate && !!siteId;
    const mode = isDuplicate ? 'Duplicate' : isEdit ? 'Edit' : 'Create';
    const platform = (duplicatedSite || selectedSite)?.platform;

    const editedPartnerConfiguration = partnerConfigurationResponse?.data?.partnerConfiguration;
    const isCustomConfig = !!editedPartnerConfiguration?.isCustom;
    const isReadonly =
        (!isAgency &&
            !!editedPartnerConfiguration?.agencyId &&
            !organizationOptions?.editAgencyConfigurationSupported) ||
        isPartner ||
        isCustomConfig;

    const tabContentProps = {
        partner: partnerDetails,
        site: duplicatedSite || selectedSite,
        orgOptions: organizationOptions,
        isReadonly,
    };

    let initialValues =
        editedPartnerConfiguration && selectedSiteId === editedPartnerConfiguration.siteId
            ? parsePartnerConfigurationFromServer(editedPartnerConfiguration, partnerDetails, platform)
            : { site: selectedSiteId || undefined };

    if (isDuplicate) {
        initialValues = { ...initialValues, site: '' };
    }

    const validationSchema =
        !partnerDetails || isReadonly
            ? null
            : Yup.object().shape({
                  site: Yup.string().required('STATIC.REQUIRED_FIELD'),
                  ...(selectedPage === ATTRIBUTION_SETTINGS
                      ? getAttributionSettingsValidationSchema(partnerDetails, platform)
                      : {}),
                  ...(selectedPage === EVENTS_SETTINGS
                      ? getEventsSettingsValidationSchema(partnerDetails, platform)
                      : {}),
              });

    useEffect(() => {
        getPartner({ variables: { partnerId: partner.id } });
        selectedSite &&
            getPartnerConfiguration({
                variables: {
                    partnerId: partner.id,
                    longname: selectedSite.longname,
                    platform: selectedSite.platform,
                    agencyId,
                },
            });
        selectedSite &&
            getPartnerWindows({
                variables: { partnerId: partner.id, longname: selectedSite.longname, platform: selectedSite.platform },
            });
    }, [getPartner, getPartnerConfiguration, partner, selectedSite]);

    useEffect(() => {
        const { called, loading, data } = savePartnerConfigurationResponse;
        if (!called || loading) return;

        if (data?.savePartnerConfiguration?.ok) {
            partnerConfigurationsResponse.refetch();
            configuredPartnerResponse.refetch();
            onClose();
        } else {
            setWarning({
                type: MessageTypes.ERROR,
                message: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SAVING_FAILURE_MESSAGE',
            });
        }

        savePartnerConfigurationResponse.reset();
    }, [savePartnerConfigurationResponse]);

    const onSiteChanged = id => {
        if (isDuplicate) {
            setDuplicatedSiteId(id);
            return;
        }

        setSelectedSiteId(id);
        setSelectedPage(filteredShelfPages[0].id);
    };

    const onGuideClick = () => {
        trackPartnerConfigMixpanelEvent('Shelf Guide Clicked', partner, selectedSite);
        window.open(guideLink, '_blank', 'noreferrer');
    };

    const validateForm = async form => {
        const errors = await form.validateForm();
        if (Object.keys(errors).length === 0) {
            return true;
        }

        form.setTouched(setNestedObjectValues(errors, true));
        return false;
    };

    const onPageChanged = async (isNextPage, form) => {
        const isFormValid = await validateForm(form);
        if (!isFormValid) return;

        const step = isNextPage ? 1 : -1;
        const index = filteredShelfPages.findIndex(({ id }) => id === selectedPage);
        const nextPage = filteredShelfPages[index + step].id;
        setSelectedPage(nextPage);

        const buttonName = isNextPage ? 'Next' : 'Back';
        trackPartnerConfigMixpanelEvent(`Shelf ${buttonName} Clicked`, partner, selectedSite, { mode });
    };

    const onTabClicked = async (tabId, form) => {
        const isFormValid = await validateForm(form);
        if (isFormValid) setSelectedPage(tabId);
    };

    const onSave = async partnerConfigurationValues => {
        const partnerConfiguration = parsePartnerConfigurationToServer(
            partner,
            duplicatedSite || selectedSite,
            partnerConfigurationValues,
            isAgency ? agencyId : editedPartnerConfiguration?.agencyId
        );

        try {
            trackPartnerConfigMixpanelEvent('Save', partner, selectedSite, { mode });
            await savePartnerConfiguration({ variables: { partnerConfiguration } });
        } catch (error) {
            setWarning({
                type: MessageTypes.ERROR,
                message: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SAVING_FAILURE_MESSAGE',
            });
        }
    };

    return (
        <Shelf
            open={open}
            shelfSize="xlarge"
            headerText={`STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.${isDuplicate ? 'DUPLICATE' : 'HEADER'}`}
            headerData={{ partnerName: partner.displayName }}
            headerImagePath={partner.logoPath}
            enterAnimationDisabled={false}
            onClose={onClose}
            dataTestId={SHELF_DATA_TEST_ID}
        >
            {partnerResponse.loading || shelfLoading ? (
                <Spinner show expanded />
            ) : (
                <>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSave}
                    >
                        {form => (
                            <Form className={css.partnerConfigurationShelf}>
                                <Tabs
                                    tabs={filteredShelfPages}
                                    selected={selectedPage}
                                    containerClass={tabsCss.shelfTabs}
                                    onClick={tabId => onTabClicked(tabId, form)}
                                />
                                <WarningMessage
                                    show={!!warning}
                                    showIcon
                                    type={warning?.type}
                                    message={warning?.message}
                                    duration={1000}
                                />
                                <div className={css.tabContent}>
                                    <div className={css.topArea}>
                                        {isDuplicate ? (
                                            <DuplicateAppSelection
                                                duplicatedSite={selectedSite}
                                                partner={partner}
                                                sites={sites}
                                                postbackTemplates={postbackTemplates}
                                                onChange={onSiteChanged}
                                                onWarning={setWarning}
                                            />
                                        ) : (
                                            <AppSelection
                                                disabled={isEdit}
                                                partner={partner}
                                                sites={sites}
                                                postbackTemplates={postbackTemplates}
                                                onChange={onSiteChanged}
                                            />
                                        )}
                                        {!guideLink ? null : (
                                            <div className={css.guide} onClick={onGuideClick}>
                                                <GuideIcon className={css.icon} />
                                                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.PARTNER_GUIDE" />
                                            </div>
                                        )}
                                    </div>
                                    {partnerConfigurationResponse.loading ? (
                                        <Spinner show expanded />
                                    ) : (
                                        <>
                                            {selectedPage === ATTRIBUTION_SETTINGS && selectedSite && (
                                                <AttributionSettings {...tabContentProps} />
                                            )}
                                            {selectedPage === EVENTS_SETTINGS && selectedSite && (
                                                <EventsSettings {...tabContentProps} />
                                            )}
                                            {selectedPage === ADVANCED_SETTINGS && selectedSite && (
                                                <AdvancedSettings {...tabContentProps} />
                                            )}
                                        </>
                                    )}
                                </div>
                                <WizardFooter
                                    buttons={getFooterButtons(
                                        filteredShelfPages,
                                        selectedPage,
                                        form.isSubmitting,
                                        isReadonly,
                                        isDuplicate,
                                        onClose,
                                        isNextPage => onPageChanged(isNextPage, form)
                                    )}
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Shelf>
    );
}

PartnerConfigurationShelf.propTypes = {
    open: PropTypes.bool,
    partner: partnerShape.isRequired,
    siteId: PropTypes.number,
    agencyId: PropTypes.string,
    apps: PropTypes.arrayOf(PropTypes.shape({ sites: PropTypes.arrayOf(siteShape) })),
    isDuplicate: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
};

PartnerConfigurationShelf.defaultProps = {
    open: false,
    siteId: null,
    agencyId: null,
    apps: [],
    isDuplicate: false,
    loading: false,
    onClose: () => {},
};
