import React from 'react';
import * as css from './CustomDimensionsTabs.css';
import FauxButton from './FauxButton';

export default ({ tabs, onSwitch, selected }) => (
    <div className={css.tray}>
        {tabs.map(({ name, displayName }) => (
            <div
                className={`${css.tab} ${selected === name ? css.selected : ''}`}
                onClick={() => {
                    onSwitch(name);
                }}
                key={name}
            >
                {displayName}
            </div>
        ))}
    </div>
);
