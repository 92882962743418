import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './ToggleButton.css';

class ToggleButton extends React.Component {
    constructor(props) {
        super(props);
        this._handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick();
    }

    render() {
        const { text, open, containerStyle, showIcon } = this.props;
        return (
            <button
                className={`${css.container} ${open ? css.opened : ''}`}
                onClick={this._handleClick}
                style={{
                    ...containerStyle,
                }}
            >
                <div className={css.buttonInner}>{<Translate id={text} />}</div>
            </button>
        );
    }
}

ToggleButton.propTypes = {
    text: PropTypes.string,
    open: PropTypes.bool,
    onClick: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    showIcon: PropTypes.bool,
};

ToggleButton.defaultProps = {
    text: '',
    open: false,
    onClick: () => {},
    containerStyle: {},
    showIcon: true,
};

export default ToggleButton;
