import { getSelectedLanguage } from '../selectors/locale';

export const getLanguage = state => getSelectedLanguage(state);

export const getMyState = state => {
    const s = state.webTrackingLinks;
    const targets = s.targets || [];

    let urls = [];
    if (s.target) {
        urls = s.targets
            .find(target => target.id === s.target.id)
            .urls.map(url => ({ name: url.id.toString(), urlId: url.id, display_name: url.url }));
    }

    let landingPages = [];
    if (s.target) {
        landingPages = s.targets.find(target => target.id === s.target.id).landingPages;
    }

    let partners = [];
    if (s.partners) {
        partners = s.partners.map(partner => {
            return { name: partner.name, partnerId: partner.id, display_name: partner.display_name };
        });
    }

    const language = getLanguage(state);

    return {
        ...s,
        targets,
        urls,
        landingPages,
        partners,
        language,
    };
};
