import moment from 'moment';
import { createContext } from 'react';
import { MetricsMap } from './metricsUtils';

export const NO_VALUE = 'N/A';
export const DATE_RANGE_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_START_DATE = moment()
    .subtract(6, 'days')
    .format(DATE_RANGE_DATE_FORMAT);
export const DEFAULT_END_DATE = moment().format(DATE_RANGE_DATE_FORMAT);
export const DEFAULT_METRICS = [MetricsMap.CUSTOM_IMPRESSIONS, MetricsMap.CUSTOM_CLICKS, MetricsMap.CUSTOM_INSTALLS];
export const SOURCES_OPTIONS = [
    'ad_colony',
    'adjoe',
    'aarki',
    'Bigabid',
    'dataseat',
    'jampp',
    'jetfuel',
    'kakao',
    'liftoff',
    'moloco',
    'outbrain',
    'reddit',
    'remerge',
    'taboola',
    'ironsource',
    'Unity',
    'yandex',
    'Yahoo',
    'mintegral',
    'adwords',
    'facebook',
    'TikTok',
    'twitter',
    'snapchat',
    'pinterest',
    'stackadapt',
    'Chartboost',
    'tapjoy',
    'verizon',
    'youappi',
    'wider_planet',
    'zuckspf',
    'applovin',
];

export const AIDimensionsContext = createContext();

export const TRACK_EVENT_PREFIX = 'Creatives Gallery';

export const METRICS_DROPDOWN_TAGS_LIMIT = 3;
export const DIMENSIONS_DROPDOWN_TAGS_LIMIT = 1;
