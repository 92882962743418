import React from 'react';
import PropTypes from 'prop-types';
import LoadingBar from 'react-redux-loading-bar';
import { withLocalize } from 'react-localize-redux';
import Reports from 'containers/reports';
import { Navbar, ActionButton, Label, SmallDialog } from '../widgets';
import css from './Anonymous.css';
import toaster from '../../utils/toaster';

class Anonymous extends React.Component {
    constructor(props) {
        super(props);
        this.handleModalSubmit = this._handleModalSubmit.bind(this);
    }

    componentWillUpdate(nextProps) {
        const { translate, error } = this.props;

        if (nextProps.error !== error) {
            if (this.toastId) {
                toaster.dismiss(this.toastId);
            }

            this.toastId = toaster.toast(translate(translate(nextProps.error)), { type: toaster.TYPE.ERROR });
        }
    }

    _handleModalSubmit() {
        this.props.onPasswordInput(this.passwordInput.value);
    }

    render() {
        const { reportType, showPasswordModal, translate } = this.props;
        return (
            <div className={css.container}>
                <LoadingBar updateTime={100} maxProgress={95} progressIncrease={1} className={css.loadingBar} />
                <Navbar promotionText={translate('STATIC.PAGES.ANONYMOUS.PROMOTION_TEXT')} />
                <Reports type={reportType} />
                <SmallDialog modal open={showPasswordModal}>
                    <div className={css.modalContainer}>
                        <Label type="h1" text="STATIC.PAGES.ANONYMOUS.MODAL_TITLE" style={{ marginBottom: 24 }} />
                        <table>
                            <tbody>
                                <tr>
                                    <td className={css.labelCell}>{translate('STATIC.PAGES.ANONYMOUS.MODAL_LABEL')}</td>
                                    <td className={css.modalInputCell}>
                                        <input
                                            ref={(el) => {
                                                this.passwordInput = el;
                                            }}
                                            type="text"
                                            placeholder={translate(
                                                translate('STATIC.PAGES.ANONYMOUS.MODAL_PLACEHOLDER')
                                            )}
                                            className={css.modalInput}
                                        />
                                    </td>
                                </tr>
                                <tr className={css.rowSpacer} />
                                <tr>
                                    <td />
                                    <td>
                                        <ActionButton
                                            text="STATIC.BUTTONS.SUBMIT"
                                            containerClass={css.submitButton}
                                            onClick={this.handleModalSubmit}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </SmallDialog>
            </div>
        );
    }
}

Anonymous.propTypes = {
    reportType: PropTypes.string,
    showPasswordModal: PropTypes.bool,
    onPasswordInput: PropTypes.func,
    error: PropTypes.string,
    translate: PropTypes.func,
};

Anonymous.defaultProps = {
    reportType: '',
    showPasswordModal: false,
    onPasswordInput: () => {},
    error: '',
    translate: () => {},
};

export default withLocalize(Anonymous);
