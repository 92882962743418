import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import Step from './Step';
import css from './StepsList.css';

const StepsList = ({ children }) =>
    Children.toArray(children)
        .filter(child => child.type === Step)
        .map((step, index) => {
            const { title } = step.props;
            return (
                <div key={title} className={css.stepItem}>
                    {cloneElement(step, { stepNumber: index + 1 })}
                </div>
            );
        });

StepsList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default StepsList;
