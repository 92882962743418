import { reportTypes } from '../../utils/reports';
import { getBaseConfig, shelfDimensions, shelfMetrics, shelfTimeBreakdowns } from './baseReportConfig';

const type = reportTypes.fraudRejected;
const path = '/react/fraud/rejected';

const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.FRAUD_REJECTED');

export default {
    ...basicConfig,
    shelf: [shelfDimensions, shelfMetrics, shelfTimeBreakdowns],
    path,
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/360003121852-Fraud-Prevention-Reporting',
        },
    },
};
