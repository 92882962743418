import React from 'react';
import { withLocalize } from 'react-localize-redux';
import css from '../styles.css';
import DimensionsAvailabilityTable from './dimensionsAvailabilityTable';

const DimensionsAvailability = ({ source, availabilityByDimension, fieldsDict }) => {
    return (
        <div className={css.dimensionsAvailabilityContainer}>
            <DimensionsAvailabilityTable
                sourceName={source}
                availabilityByDimension={availabilityByDimension}
                dimensionsDict={fieldsDict}
                showTableHead
            />
        </div>
    );
};

DimensionsAvailability.propTypes = {};

DimensionsAvailability.defaultProps = {};

export default withLocalize(DimensionsAvailability);
