import { connect } from 'react-redux';
import LoadingPage from './loading_page';

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);
