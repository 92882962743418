import { SDK_INTEGRATION_SECTIONS } from '../../consts';

const SECTIONS_TRANSLATE_KEY = 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS';
const SECTIONS_TITLES_TRANSLATE_KEY = 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS_TITLES';
const CONTENT_TRANSLATE_KEY = 'CONTENT';
const MORE_INFO_CONTENT_TRANSLATE_KEY = 'MORE_INFO_CONTENT';
const CONTENT_FOOTER_TRANSLATE_KEY = 'CONTENT_FOOTER';
const CONTENT_FOOTER_HELP_CENTER_LINK_TITLE = 'CONTENT_FOOTER_HELP_CENTER_LINK_TITLE';

// Example of configable item: [SDK_INTEGRATION_SECTIONS.ADD_EVENTS]: true
const EXCLUDE_SECTIONS_TRANSLATIONS_PLATFORM = {};

export const LEARN_MORE_SECTIONS_LINKS = {
    CONFIGURE_THE_SDK:
        'https://support.singular.net/hc/en-us/articles/12054824479387--UPDATED-iOS-SDK-Integration-Guide',
    CREATE_A_CONFIGURATION_OBJECT:
        'https://support.singular.net/hc/en-us/articles/360037581952--UPDATED-Android-SDK-Integration-Guide#SingularConfig',
    CUSTOM_USER_ID:
        'https://support.singular.net/hc/en-us/articles/360037581952-Android-SDK-Integration-Guide#25_Sending_the_User_ID_to_Singular_Optional',
    INITIATE_THE_CODE:
        'https://support.singular.net/hc/en-us/articles/360037581952--UPDATED-Android-SDK-Integration-Guide#24_Initializing_Singular',
    EVENTS:
        'https://support.singular.net/hc/en-us/articles/360037640172-Integrating-a-Singular-SDK-Planning-and-Prerequisites',
    ADD_EVENTS: 'https://support.singular.net/hc/en-us/articles/360036736891-How-to-Track-In-App-Events',
    ATT_PROMPT: 'https://support.singular.net/hc/en-us/articles/12054824479387-iOS-SDK-Integration-Guide#att_scenarios',
    SKAN_ENABLED:
        'https://support.singular.net/hc/en-us/articles/12054824479387--UPDATED-iOS-SDK-Integration-Guide#23_Customizing_SKAdNetwork_Options',
};
export {
    CONTENT_FOOTER_HELP_CENTER_LINK_TITLE,
    SECTIONS_TRANSLATE_KEY,
    CONTENT_TRANSLATE_KEY,
    CONTENT_FOOTER_TRANSLATE_KEY,
    SECTIONS_TITLES_TRANSLATE_KEY,
    MORE_INFO_CONTENT_TRANSLATE_KEY,
    EXCLUDE_SECTIONS_TRANSLATIONS_PLATFORM,
};
