import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppsService from '../../../services/apps';
import DefaultIcon from '../../../resources/svg/icon_new_app.svg';
import VerticalList from './VerticalList';
import { updateFavouriteAppsForUser } from '../../../actions/apps';
import { getFavouriteApps } from '../../../selectors/apps';
import { trackMixpanelEvent } from '../../../utils/general';
import { sortAlphabetically } from '../../../utils/sortUtil';

const appsApi = new AppsService();

export default function AppsList({
    apps,
    appsFilter,
    className,
    onAppSelected,
    sortApps,
    disableAnimation,
    showLastUpdate,
}) {
    const dispatch = useDispatch();
    const favouriteApps = useSelector(state => getFavouriteApps(state));

    const onFavouriteAppClicked = async (e, isFavourite, id, name) => {
        e.stopPropagation();
        trackMixpanelEvent('Apps', isFavourite ? 'Unstar Application' : 'Star Application', { AppName: name });
        const response = await appsApi.updateFavouriteApp(!isFavourite, id);
        dispatch(updateFavouriteAppsForUser(response));
    };

    useEffect(async () => {
        const response = await appsApi.getFavouriteApps();
        dispatch(updateFavouriteAppsForUser(response));
    }, []);

    if (!favouriteApps) {
        return null;
    }

    return (
        <VerticalList
            items={apps}
            filter={appsFilter}
            className={className}
            onItemSelected={onAppSelected}
            sortItems={sortApps}
            favouriteItems={favouriteApps}
            onFavouriteItemClicked={onFavouriteAppClicked}
            defaultIconElement={DefaultIcon}
            disableAnimation={disableAnimation}
            autoSelectItem
            showLastUpdate={showLastUpdate}
        />
    );
}

AppsList.propTypes = {
    apps: PropTypes.arrayOf(PropTypes.any),
    appsFilter: PropTypes.string,
    className: PropTypes.string,
    onAppSelected: PropTypes.func,
    sortApps: PropTypes.func,
    disableAnimation: PropTypes.bool,
    showLastUpdate: PropTypes.bool,
};

AppsList.defaultProps = {
    apps: [],
    appsFilter: '',
    className: '',
    onAppSelected: () => {},
    sortApps: apps => apps.sort((a, b) => sortAlphabetically(a.name, b.name, false, false)),
    disableAnimation: false,
    showLastUpdate: true,
};
