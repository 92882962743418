import React from 'react';

export default function SdkTestAdvancedEvents() {
    return (
        <div>
            <p>
                <span>Singular allows you to set up additional events to track in your report. Once you are done testing basic and revenue events, make a list of all other events you would like to track in Singular. Test them in the SDK console and verify any additional parameters you have configured. </span>
            </p>
        </div>
    );
}
