import { connect } from 'react-redux';
import HomepageGeneric from './homepageGeneric';
import { getAdminModeEnabled } from '../selectors/user';
import { saveCompletedTask, auditSdk, auditGeneral, resetValidationMessage } from './actions';
import {
    getMenuItems,
    getError,
    getMenuOrder,
    getIsApsalarEnabled,
    getSdkToken,
    getSdkKey,
    getValidationId,
    getValidationMessage,
    getUserData,
    getIsSdkAuditLoading,
    getIsGeneralAuditLoading,
    getValidationType,
} from './selector';

const mapStateToProps = state => {
    return {
        error: getError(state),
        menuItemsConfig: getMenuItems(state),
        menuItemsOrder: getMenuOrder(state),
        adminMode: getAdminModeEnabled(state),
        apsalarEnabled: !!getIsApsalarEnabled(state),
        sdkToken: getSdkToken(state),
        sdkKey: getSdkKey(state),
        validationId: getValidationId(state),
        validationType: getValidationType(state),
        validationMessage: getValidationMessage(state),
        isSdkAuditLoading: getIsSdkAuditLoading(state),
        isGeneralAuditLoading: getIsGeneralAuditLoading(state),
        userData: getUserData(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveCompletedTask: (completedTaskName, adminMarkedAsDone = false) => {
            dispatch(saveCompletedTask(completedTaskName, adminMarkedAsDone));
        },
        auditSdk: () => {
            dispatch(auditSdk());
        },
        auditGeneral: () => {
            dispatch(auditGeneral());
        },
        resetValidationMessage: () => {
            dispatch(resetValidationMessage());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageGeneric);
