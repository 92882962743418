// Chart types
export const COLUMN_CHART = 'column';
export const BAR_CHART = 'bar';
export const STACKED_CHART = 'stacked';
export const LINE_CHART = 'line';
export const PIE_CHART = 'pie';

// Quick report only chart types
export const MULTI_CHART = 'multiChart';

export const CURRENCY_METRIC_TYPE = 'currency';
export const PERCENTAGE_METRIC_TYPE = 'percentage';

export const CHART_TYPES = [
    { name: COLUMN_CHART, display_name: 'Bar Chart (Vertical)' },
    { name: BAR_CHART, display_name: 'Bar Chart (Horizontal)' },
    { name: STACKED_CHART, display_name: 'Stacked Bar Chart' },
    { name: LINE_CHART, display_name: 'Line Chart' },
    { name: PIE_CHART, display_name: 'Pie Chart' },
    { name: MULTI_CHART, display_name: 'Multi Chart' },
];

export const CUSTOM_DASHBOARD_CHART_TYPES = CHART_TYPES.filter(type =>
    [COLUMN_CHART, BAR_CHART, STACKED_CHART, LINE_CHART, PIE_CHART].includes(type.name)
);

export const TOP_X_COUNT_MAX_VALUE = 25;
export const TOP_X_THRESHOLD = 5;
