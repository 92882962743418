import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/widgets/Tooltip';
import { Translate } from 'react-localize-redux';
import SystemBellIcon from 'resources/svg/alerts_bells/system.svg';
import CheckedCircleIcon from 'resources/svg/checked_with_special_circle.svg';
import TrashIcon from 'resources/svg/trash.svg';
import TestReportIcon from 'resources/svg/test_report.svg';
import EditIcon from 'resources/svg/edit.svg';
import Highlighter from 'react-highlight-words';
import { RAN_TEST_REPORT, REPORT_TYPE_MAP, STATUS_MESSAGE_MAP, TRACK_EVENT_PREFIX } from 'alerts/consts';
import { trackMixpanelEvent } from 'utils/general';
import css from './AlertItem.css';

const AlertItem = ({
    alert,
    reportTypes,
    userData,
    onSetReportType,
    openDeleteDialog,
    openShelf,
    onSetSelectedAlert,
    isSystemAlert,
    systemAlerts,
    searchedText,
    isUserRestricted,
}) => {
    const BellIcon = alert.report_type ? REPORT_TYPE_MAP[alert.report_type].BellIcon : SystemBellIcon;

    const isSubscribed = () => {
        if (isSystemAlert) {
            return (
                systemAlerts.data_connector_login_alert.emails.includes(userData.email) ||
                systemAlerts.data_delay_alert.emails.includes(userData.email) ||
                (userData.is_admin &&
                    (systemAlerts.data_connector_login_alert.send_to_admins ||
                        systemAlerts.data_delay_alert.send_to_admins))
            );
        }
        return alert.emails.includes(userData.email) || (userData.is_admin && alert.send_to_admins);
    };

    const getSubHeader = () => {
        if (isSystemAlert) return alert.subHeader;
        const createdString = new Date(alert.created).toLocaleString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
        return `Created by ${alert.creator} on ${createdString}`;
    };

    const getAlertDetails = () => {
        const getDimensionString = (dimensionList, title) => {
            let dimensionsString = '';
            if (!dimensionList.length) return null;
            dimensionsString += dimensionList[0].display_name;
            if (dimensionList.length > 1) {
                dimensionsString += `, ${dimensionList[1].display_name}`;
            }
            if (dimensionList.length > 2) {
                dimensionsString += ` and ${dimensionList.length - 2} more`;
            }
            dimensionsString = dimensionsString ? title + dimensionsString : null;
            return dimensionsString;
        };

        const getFiltersList = (dimensionName) => {
            const sourceFiltersList = alert.filters
                .filter((filter) => filter.dimension === dimensionName && filter.operator === 'in')
                .map((filter) => filter.values);
            return sourceFiltersList.length ? sourceFiltersList[0] : [];
        };

        if (isSystemAlert) return '';

        const appString = getDimensionString(getFiltersList('app'), 'Apps: ');
        const sourceString = getDimensionString(getFiltersList('source'), 'Sources: ');
        const breakdownString = getDimensionString(alert.breakdown_dimensions, 'Broken down by: ');
        const alertDetails = [appString, sourceString, breakdownString].filter((detail) => detail).join(' | ');
        return alertDetails;
    };
    const alertErrorMessage = alert.status !== 'OK' ? alert.status_message : '';
    const alertDetails = getAlertDetails();

    const onClickDelete = () => {
        onSetSelectedAlert(alert);
        openDeleteDialog();
    };
    const onClickEdit = () => {
        if (!isSystemAlert) {
            const reportType = reportTypes.filter((_reportType) => _reportType.name === alert.report_type)[0];
            onSetReportType(reportType);
        }
        openShelf();
        onSetSelectedAlert(alert);
    };

    return (
        <div
            className={css.itemContainer}
            onClick={onClickEdit}
            style={{
                borderLeft: alert.report_type ? `3px solid ${REPORT_TYPE_MAP[alert.report_type].borderColor}` : '',
            }}
        >
            <div className={css.itemLeft}>
                <BellIcon className={css.logo} />
                <div className={css.itemHeaders}>
                    <div className={css.itemHeader}>
                        <Highlighter
                            highlightClassName={css.highlighted}
                            searchWords={[searchedText]}
                            textToHighlight={alert.name}
                            autoEscape
                            className={css.itemHeaderName}
                        />
                        {isSubscribed() && (
                            <div className={css.itemHeaderSubscribed}>
                                <div className={css.itemHeaderPipe} style={{ fontSize: '16px' }}>
                                    {' | '}
                                </div>
                                <div className={css.itemHeaderSubscribed} style={{ fontSize: '12px' }}>
                                    <Translate id="STATIC.PAGES.ALERTS_V2.ALERT_ITEM.SUBSCRIBED" />
                                </div>
                                <CheckedCircleIcon className={css.itemHeaderSubscribedIcon} />
                            </div>
                        )}
                    </div>
                    <Highlighter
                        highlightClassName={css.highlighted}
                        searchWords={[searchedText]}
                        textToHighlight={getSubHeader()}
                        autoEscape
                        className={css.itemSubHeader}
                    />
                </div>
            </div>
            <div className={css.itemRight}>
                {alertErrorMessage && (
                    <div
                        className={`${css.itemErrorDetails} ${!alertDetails ? css.itemDetails : ''}`}
                        style={{ color: STATUS_MESSAGE_MAP[alert.status] }}
                    >
                        {alertErrorMessage}
                    </div>
                )}
                {alertDetails && (
                    <div className={css.itemDetails}>{alertErrorMessage ? `  | ${alertDetails}` : alertDetails}</div>
                )}
                <div
                    className={`${css.alertButtons} ${isSystemAlert ? css.EditIconOnly : ''} ${
                        !alertDetails && !alertErrorMessage ? css.noAlertDetails : ''
                    }`}
                >
                    {!isSystemAlert && (
                        <React.Fragment>
                            <Tooltip interactive titleTranslationKey="STATIC.PAGES.ALERTS_V2.DELETE">
                                <div
                                    className={css.button}
                                    onClick={(e) => {
                                        onClickDelete();
                                        e.stopPropagation();
                                    }}
                                >
                                    <TrashIcon />
                                </div>
                            </Tooltip>
                            <Tooltip interactive titleTranslationKey="STATIC.PAGES.ALERTS_V2.TEST">
                                <a
                                    className={css.button}
                                    href={alert.report_url}
                                    target="_blank"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        trackMixpanelEvent(TRACK_EVENT_PREFIX, RAN_TEST_REPORT, {
                                            alert_id: alert.id,
                                            alert_name: alert.name,
                                            report_url: alert.report_url,
                                        });
                                    }}
                                    rel="noreferrer"
                                >
                                    <TestReportIcon />
                                </a>
                            </Tooltip>
                        </React.Fragment>
                    )}
                    {!isUserRestricted && (
                        <Tooltip interactive titleTranslationKey="STATIC.PAGES.ALERTS_V2.EDIT">
                            <div className={css.button}>
                                <EditIcon width="23" height="23" viewBox="0 0 22 18" />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};

AlertItem.propTypes = {
    alert: PropTypes.objectOf(PropTypes.any),
    reportTypes: PropTypes.arrayOf(PropTypes.object),
    userData: PropTypes.objectOf(PropTypes.any),
    onSetReportType: PropTypes.func,
    openDeleteDialog: PropTypes.func,
    openShelf: PropTypes.func,
    onSetSelectedAlert: PropTypes.func,
    isSystemAlert: PropTypes.bool,
    systemAlerts: PropTypes.objectOf(PropTypes.any),
    searchedText: PropTypes.string,
    isUserRestricted: PropTypes.bool,
};

AlertItem.defaultProps = {
    alert: {},
    reportTypes: [],
    userData: {},
    onSetReportType: () => {},
    openDeleteDialog: () => {},
    openShelf: () => {},
    onSetSelectedAlert: () => {},
    isSystemAlert: false,
    systemAlerts: {},
    searchedText: '',
    isUserRestricted: false,
};

export default AlertItem;
