import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { useSelector } from 'react-redux';
import css from './BannersList.css';
import BannerHeader from './BannerHeader';
import BannerPreviewImage from '../../resources/svg/banner_preview.svg';
import { getSavedNewBanner } from '../selectors';

function BannersList({ banners }) {
    const containerRef = React.useRef();
    const savedNewBanner = useSelector(state => getSavedNewBanner(state));

    useLayoutEffect(() => {
        if (savedNewBanner) {
            // scroll to the new banner
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    });

    return (
        <div ref={containerRef} className={css.bannersList}>
            {banners.map(banner => (
                <BannerItem key={banner.id} {...banner} />
            ))}
        </div>
    );
}

BannersList.propTypes = {
    banners: PropTypes.arrayOf(PropTypes.any),
};

BannersList.defaultProps = {
    banners: [],
};

// TODO: fix to use translation
const getBannerBehavior = ({
    languages,
    countries_whitelist,
    url_contains,
    url_not_contains,
    url_ends_with,
    url_starts_with,
}) => {
    const behaviorTexts = [];
    const urlPatterns = { url_contains, url_not_contains, url_ends_with, url_starts_with };

    if (Object.values(urlPatterns).some(value => value && value.length)) {
        behaviorTexts.push('some pages');
    } else {
        behaviorTexts.push('all pages');
    }

    if (languages && languages.length) {
        behaviorTexts.push('for some languages');
    }

    if (countries_whitelist && countries_whitelist.length) {
        behaviorTexts.push('for some countries');
    }

    return `show for ${behaviorTexts.join(', ')}`;
};

function BannerItem({ id, name, status, priority, description, created, edited, edited_by, ...fields }) {
    const createdLocalTime = moment.utc(created).toDate();
    const editedLocalTime = moment.utc(edited).toDate();

    const fieldToValueMap = {
        ...(description ? { description } : {}),
        created: moment(createdLocalTime).format('MMMM Do, YYYY'),
        edited: moment(editedLocalTime).format('MMMM Do, YYYY'),
        edited_by,
        banner_behavior: getBannerBehavior(fields),
    };

    return (
        <div className={css.bannerItem}>
            <BannerHeader id={id} name={name} status={status} priority={priority} className={css.headerContainer} />
            <div className={css.contentArea}>
                <BannerPreviewImage />
                <div className={css.bannerFields}>
                    {Object.entries(fieldToValueMap).map(([fieldName, fieldValue]) => (
                        <div key={fieldName} className={css.fieldItem}>
                            <div className={css.label}>
                                <Translate id={`STATIC.PAGES.BANNERS.${fieldName.toUpperCase()}_LABEL`} />:
                            </div>
                            <span className={css.value}>{fieldValue}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

BannerItem.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    priority: PropTypes.number,
    description: PropTypes.string,
    created: PropTypes.string,
    edited: PropTypes.string,
    edited_by: PropTypes.string,
};

BannerItem.defaultProps = {
    id: null,
    name: '',
    status: '',
    priority: 1,
    description: '',
    created: Date.now(),
    edited: Date.now(),
    edited_by: '',
};

export default BannersList;
