import { INTERNAL_S3_KEY, FILTERED_EVENTS_NAMES, EMPTY_TEST_CHANNEL } from '../consts';

export const normalizeDistributionsAndNetworks = distributionNetworks => {
    const normalizeNetworks = {};
    const allNetworks = Object.keys(distributionNetworks)
        .map(network => {
            const distribution = distributionNetworks[network];
            const accs = Object.values(distribution?.distribution?.config?.enabled_ad_accounts || {});
            normalizeNetworks[network] =
                accs.length > 0
                    ? {
                          ...distribution,
                          label: distribution.display_name,
                          value: distribution.name,
                          segment_fields: distribution.segment_fields.map(segmentField => {
                              const segmentFieldCopy = { ...segmentField };
                              if (segmentField.name === 'ad_account_id') {
                                  segmentFieldCopy.choices = accs.map(acc => ({
                                      name: acc.account_id,
                                      display: `${acc.account_id}: ${acc.account_name}`,
                                  }));
                              }
                              return segmentFieldCopy;
                          }),
                      }
                    : {
                          ...distribution,
                          label: distribution.display_name,
                          value: distribution.name,
                      };
            return {
                label: distributionNetworks[network].display_name,
                name: network,
                value: network,
                isDisabled: false,
            };
        })
        .filter(Boolean);

    const internalS3 = distributionNetworks[INTERNAL_S3_KEY];
    return { allNetworks, internalDistributions: internalS3, distributionNetworks: normalizeNetworks };
};

export const normalizeTestChannelsOverviewForPie = (testChannels, networks) => {
    if (!testChannels) return [];
    const colors = ['#3784db', '#9d7be0', '#f4a622'];
    const controlGroupValue = testChannels.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
    const data = testChannels
        .filter(testChannel => !!testChannel.name)
        .map((testChannel, index) => ({
            name: networks[testChannel.name].display_name,
            y: testChannel.value,
            color: colors[index],
        }));
    data.push({
        name: 'Control Group',
        y: 100 - controlGroupValue,
        color: '#c4c4c4',
    });
    return data;
};

export const normalizeEventForDisplay = events =>
    events
        .filter(event => !FILTERED_EVENTS_NAMES.includes(event.displayName))
        .map(event => ({
            value: event.eventName,
            label: event.displayName,
        }));

export const normalizeTestChannels = testChannels => {
    if (!testChannels.length) {
        return [EMPTY_TEST_CHANNEL];
    }

    return testChannels
        .filter(e => !e.isControlGroup)
        .sort((a, b) => a.minRange - b.minRange)
        .map(testChannel => ({
            id: testChannel?.id,
            name: testChannel.adnetworkName,
            uanId: testChannel.uanId,
            adnetworkIntegrationId: testChannel.adnetworkIntegrationId,
            value: testChannel.maxRange - testChannel.minRange,
            config: JSON.parse(testChannel.distributionConfigValues),
        }));
};
