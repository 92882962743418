import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client';
import { getImpersonationFromQuery } from './utils/url';

const getCookie = name => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

const impersonationUsername = getImpersonationFromQuery('user');
const impersonatedOrganization = getImpersonationFromQuery('customer_id');

const headers = {
    'X-CSRFToken': getCookie('csrftoken'),
};

if (impersonationUsername !== null && impersonationUsername.length > 0) {
    headers['X-Impersonation-User'] = impersonationUsername;
}

if (impersonatedOrganization) {
    headers['X-Impersonated-Org'] = impersonatedOrganization;
}

const httpLink = new HttpLink({
    uri: '/api/graphql/graphql',
    headers,
});

// https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            ABTestNode: {
                keyFields: ['id'],
            },
        },
    }),
    link: from([cleanTypeName, httpLink]),
});

export default client;
