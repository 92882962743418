import React from 'react';
import PropTypes from 'prop-types';
import { APPLE_SEARCH_ADS_PARTNER_NAME } from '../../../consts';
import { organizationOptionsShape, partnerShape, siteShape } from '../../types';
import AttributionWindows from './AttributionPostbacks';

export default function AttributionPostbacksFactory({ partner, site, isReadonly, orgOptions }) {
    if (partner.name === APPLE_SEARCH_ADS_PARTNER_NAME) {
        return (
            <AttributionWindows
                orgOptions={orgOptions}
                partner={partner}
                site={site}
                isReadonly={isReadonly}
                viewThroughLabel="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.ENABLE_VIEW_THROUGH"
                viewThroughTooltip="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.ENABLE_VIEW_THROUGH_TOOLTIP"
            />
        );
    }

    return <AttributionWindows orgOptions={orgOptions} partner={partner} site={site} isReadonly={isReadonly} />;
}

AttributionPostbacksFactory.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    isReadonly: PropTypes.bool,
    orgOptions: organizationOptionsShape.isRequired,
};
