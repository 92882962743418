import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SDKConfigurationWizard from './sdkConfigurationWizard';
import SDKConfigurationWelcome from './sdkConfigurationWelcomePage';
import { useGetSDKConfigAnswers } from '../../hooks';
import css from './sdkConfiguration.css';
import Spinner from '../../../components/widgets/Spinner';
import { useAsync, useLocalStorageState } from '../../../utils/customHooks';
import {
    EDIT_MIXPANEL_EVENT_NAME,
    LOCAL_STORAGE_ACTIVE_INDEX_KEY,
    SDK_CONFIGURATION_MIXPANEL_PREFIX,
    SDK_CONFIGURATION_PLATFORMS_STEP_INDEX,
    SDK_CONFIGURATION_STEPS,
} from './consts';
import { getLastStepIndex, getNextStepIndex, getPrevStepIndex, isActiveStepDefined } from '../../../utils/wizard';
import SDKConfigurationSummaryPage from './sdkConfigurationSummaryPage';
import { validationByStep, parseAnswers } from './utils';
import SDKConfigService from './service';
import { getUserData } from '../../../selectors/user';
import { SDK_CONFIGURATION } from '../../../homepage/utils';
import { getCompletedTasks } from '../../../homepage/selector';
import { trackMixpanelEvent } from '../../../utils/general';

const SDKConfigurationPage = ({ onNextButtonClick: onOnboardingNextClick, onMarkAsDoneClick }) => {
    const [response, run] = useAsync();
    const { first_name: firstName, organization_tier: organizationTier, trial: isTrial } = useSelector(state =>
        getUserData(state)
    );
    const { [SDK_CONFIGURATION]: isStepCompleted = false } = useSelector(state => getCompletedTasks(state));

    const [showDoneText, setShowDoneText] = useState(false);
    const [skipped, setSkipped] = useState(false);
    const { isLoading, sdkConfig = {} } = useGetSDKConfigAnswers(!!response.data);
    const [activeStepIndex, setActiveStepIndex] = useLocalStorageState(LOCAL_STORAGE_ACTIVE_INDEX_KEY, undefined);
    const [showSummary, setShowSummary] = useState(isStepCompleted && !isActiveStepDefined(activeStepIndex));
    const [isValidByStep, setIsValidByStep] = useState({});
    const [inEditMode, setInEditMode] = useState(false);
    const activeStep = SDK_CONFIGURATION_STEPS[activeStepIndex];
    const sdkConfigAPI = new SDKConfigService();

    useEffect(() => {
        if (skipped) {
            onOnboardingNextClick();
        }
    }, [skipped]);

    const onStartButtonClick = () => {
        setActiveStepIndex(SDK_CONFIGURATION_PLATFORMS_STEP_INDEX);
    };

    const onEditClick = () => {
        trackMixpanelEvent(SDK_CONFIGURATION_MIXPANEL_PREFIX, EDIT_MIXPANEL_EVENT_NAME);
        setInEditMode(true);
        setActiveStepIndex(SDK_CONFIGURATION_PLATFORMS_STEP_INDEX);
        setShowSummary(false);
    };

    const onPrevClick = () => {
        setActiveStepIndex(getPrevStepIndex(activeStepIndex));
    };

    const onSummaryBackClick = () => {
        setShowSummary(false);
        setActiveStepIndex(getLastStepIndex(SDK_CONFIGURATION_STEPS));
    };

    const runActiveStepValidation = validationArgs => {
        let isValidStep = true;
        const validationFunction = validationByStep[activeStep];

        if (activeStep && validationFunction) {
            isValidStep = validationFunction(validationArgs);
            setIsValidByStep({ ...isValidByStep, [activeStep]: isValidStep });
        }
        return isValidStep;
    };

    const onSubmit = async (platforms, sdkConfigurationAnswers) => {
        setShowDoneText(true);
        await run(sdkConfigAPI.saveSDKConfig(parseAnswers(platforms, sdkConfigurationAnswers), platforms));
        setShowSummary(true);
        setActiveStepIndex(null);
        onMarkAsDoneClick({ skipConfirmationPopup: true });
    };

    const onNextClick = validationArgs => {
        const isValidStep = runActiveStepValidation(validationArgs);

        if (isValidStep) {
            setActiveStepIndex(getNextStepIndex(activeStepIndex, SDK_CONFIGURATION_STEPS));
        }
    };

    const onSkipClick = () => {
        setActiveStepIndex(null);
        onMarkAsDoneClick({ skipConfirmationPopup: true });
        setSkipped(true);
    };

    const onStepClick = stepIndex => {
        if (stepIndex < activeStepIndex || isStepCompleted) {
            setActiveStepIndex(stepIndex);
        }
    };

    const getPageToRender = () => {
        if (isLoading || skipped || response.isLoading) {
            return <Spinner className={css.loader} show />;
        } else if (showSummary) {
            return (
                <SDKConfigurationSummaryPage
                    sdkConfig={sdkConfig}
                    showEdit={!showDoneText}
                    onEditClick={onEditClick}
                    onBackClick={onSummaryBackClick}
                    onNextClick={onOnboardingNextClick}
                    organizationTier={organizationTier}
                />
            );
        } else if (isActiveStepDefined(activeStepIndex)) {
            return (
                <SDKConfigurationWizard
                    runActiveStepValidation={runActiveStepValidation}
                    isValidByStep={isValidByStep}
                    inEditMode={inEditMode}
                    activeStepIndex={activeStepIndex}
                    sdkConfig={sdkConfig}
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    onSubmit={onSubmit}
                    onSkipClick={onSkipClick}
                    isWizardCompleted={isStepCompleted}
                    onStepClick={onStepClick}
                    organizationTier={organizationTier}
                />
            );
        } else {
            return (
                <SDKConfigurationWelcome
                    firstName={firstName}
                    organizationTier={organizationTier}
                    isTrial={isTrial}
                    onStartButtonClick={onStartButtonClick}
                    onSkipButtonClick={onSkipClick}
                />
            );
        }
    };

    return getPageToRender();
};

SDKConfigurationPage.propTypes = {};

SDKConfigurationPage.defaultProps = {};

export default SDKConfigurationPage;
