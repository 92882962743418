import { call, put, takeEvery } from 'redux-saga/effects';
import Logger from '../services/logger';
import PartnersAPI from './service';
import {
    setOrganizations,
    toggleImpersonateDialogLoading,
    IMPERSONATE_DIALOG_STARTED,
    IMPERSONATE_ORG,
} from '../actions/user';

const api = new PartnersAPI();

const logger = new Logger('[partners-portal:saga]');

function* onload() {
    logger.log('onload');
    yield Promise.resolve();
}

function* handleImpersonateDialogStarted() {
    logger.log('impersonate dialog started');
    yield put(toggleImpersonateDialogLoading(true));
    try {
        const { organizations } = yield call(api.getOrganizationsList);
        logger.log('loaded the following orgs - ', JSON.stringify(organizations));
        yield put(setOrganizations(organizations));
        yield put(toggleImpersonateDialogLoading(false));
    } catch (e) {
        logger.error('Failed to get organizations');
    }
}

function* handleImpersonateOrg({ org }) {
    logger.log(`impersonate org started ${org.display_name}`);
    yield put(toggleImpersonateDialogLoading(true));
    try {
        const res = yield call(api.impersonateOrg, org.id);
        window.location.href = '/';
    } catch (e) {
        yield put(toggleImpersonateDialogLoading(false));
        logger.error(`Failed to impersonate ${org.display_name}`);
    }
}

/* ---------------------------- */
/* ------- Watchers ----------- */
/* ---------------------------- */

function* watchImpersonateDialogStarted() {
    yield takeEvery(IMPERSONATE_DIALOG_STARTED, handleImpersonateDialogStarted);
}
function* watchImpersonateOrg() {
    yield takeEvery(IMPERSONATE_ORG, handleImpersonateOrg);
}

const watchers = [watchImpersonateDialogStarted, watchImpersonateOrg];

export { onload, watchers };
