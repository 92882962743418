import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Popper, ClickAwayListener } from '@mui/material';
import { formatCell, getAdmonDataSources, getAdmonDataSourceType, getAdmonSourceContentText } from '../../utils/grids';
import withTranslate from '../../containers/widgets/withTranslate';
import css from './TransparencyPopover.css';
import { TRANSPARENCY_COLUMN_LIST } from '../../utils/reportsConsts';

const singleMetrics = ['adn_cost'];

class TransparencyPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            targetOrigin: { horizontal: 'right', vertical: 'bottom' },
        };
        this.getTooltipText = this._getTooltipText.bind(this);
    }

    _getTooltipText(nodeData, column) {
        const { translate } = this.props;
        return translate(
            `STATIC.PAGES.REPORTS.TOOLTIPS.${nodeData.sources.column_source[column.colId].source}.${
                singleMetrics.includes(column.colId) ? 'SINGLE' : 'PLURAL'
            }`,
            {
                metric: column.colDef.headerName,
            }
        );
    }

    _renderSourcePopoverContent(uansContent) {
        const { column, nodeData, translate } = this.props;

        return (
            <>
                <span className={css.header}>
                    {translate('STATIC.PAGES.REPORTS.TRANSPARENCY.METRIC_DRILLDOWN')}
                </span>
                <div className={css.content}>
                    <div className={css.contentText}>{this.getTooltipText(nodeData, column)}</div>
                    {uansContent
                        .filter((uan) => !uan.is_admon)
                        .sort((uan) => {
                            const isMutedText = nodeData.sources.column_source[column.colId].source !== uan.type;

                            return isMutedText ? 1 : -1;
                        })
                        .map((uan) => {
                            const isMutedText = nodeData.sources.column_source[column.colId].source !== uan.type;

                            return (
                                <div
                                    key={`${column.colId}_${uan.source.value}`}
                                    className={classNames(
                                        isMutedText ? `${css.mutedText} ${css.lineThroughText}` : '',
                                        css[`${uan.type}LineColor`],
                                        css.sourceLine
                                    )}
                                >
                                    <div className={classNames({ [css.mutedText]: isMutedText }, css.sourceDetails)}>
                                        <span>{uan.source.value}</span>
                                        <span className={css.usernameText}>{uan.username}</span>
                                    </div>
                                    <span className={classNames({ [css.mutedText]: isMutedText }, css.sourceValue)}>
                                        {uan[column.colDef.field]
                                            ? formatCell(uan[column.colDef.field].value, column.colDef.displayFormat)
                                            : 'N/A'}
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </>
        );
    }

    _renderAdmonSourcePopoverContent(uansContent) {
        const { column, translate } = this.props;
        const admonDataSourcesUanConfig = uansContent.find((uan) => uan.is_admon);
        const admonDataSources = getAdmonDataSources(admonDataSourcesUanConfig, column.colDef.field);
        const translationKeyText = getAdmonSourceContentText(admonDataSources);

        return (
            <>
                <span className={css.header}>{translate('STATIC.PAGES.REPORTS.TRANSPARENCY.AD_REVENU_DRILLDOWN')}</span>
                <div className={css.content}>
                    <div className={css.contentText}>{translate(translationKeyText)}</div>
                    {admonDataSources.map(({ admonDataSource, adRevenue }) => {
                        const admonSourceType = getAdmonDataSourceType(admonDataSource);

                        return (
                            <div
                                key={`${column.colId}_${admonDataSource}`}
                                className={classNames(css.sourceLine, css[`${admonSourceType}LineColor`])}
                            >
                                <div className={classNames(css.sourceDetails, css.sourceDetailsAdRevenue)}>
                                    <span>{admonDataSource}</span>
                                </div>
                                <span className={css.sourceValue}>
                                    {admonDataSourcesUanConfig[column.colDef.field]
                                        ? formatCell(adRevenue, column.colDef.displayFormat)
                                        : 'N/A'}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    render() {
        const { uansContent, column, popupWidth, anchorEl, showPopover, handleRequestClose } = this.props;

        return (
            <ClickAwayListener onClickAway={handleRequestClose}>
                <Popper open={showPopover} anchorEl={anchorEl} placement="top" disablePortal={false}>
                    <div className={css.transparencyPopover} style={{ minWidth: `${popupWidth.toString()}px` }}>
                        {TRANSPARENCY_COLUMN_LIST.includes(column.colId)
                            ? this._renderSourcePopoverContent(uansContent)
                            : this._renderAdmonSourcePopoverContent(uansContent)}
                    </div>
                </Popper>
            </ClickAwayListener>
        );
    }
}

TransparencyPopover.propTypes = {
    uansContent: PropTypes.arrayOf(PropTypes.any),
    column: PropTypes.objectOf(PropTypes.any),
    nodeData: PropTypes.objectOf(PropTypes.any),
    handleRequestClose: PropTypes.func,
    popupWidth: PropTypes.number,
    showPopover: PropTypes.bool,
    anchorEl: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
};

TransparencyPopover.defaultProps = {
    uansContent: [],
    column: {},
    nodeData: {},
    handleRequestClose: () => {},
    popupWidth: 150,
    showPopover: false,
    anchorEl: {},
    translate: () => {},
};

export default withTranslate(TransparencyPopover);
