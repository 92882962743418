import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Dropdown.css';

class DropdownBody extends React.Component {
    constructor(props) {
        super(props);
        this.getInnerHtml = props.getInnerHtml || this._getInnerHtml;
    }

    _getInnerHtml = (value, key) => {
        const { translate } = this.props;
        const shouldTranslate = value[key] && typeof value[key] === 'string';

        return <span>{shouldTranslate ? translate(value[key]) : value[key]}</span>;
    };

    render() {
        const { items, show, onSelection, onMouseDown, placement, hideTopBorder, style, className, valueField, dataTestId } = this.props;
        return (
            <ul
                style={style}
                className={classNames(css.itemsContainer, className, css[placement], {
                    [css.show]: show,
                    [css.hideTopBorder]: hideTopBorder,
                })}
                data-testid={dataTestId}
                onClick={onSelection}
                onMouseDown={onMouseDown}
            >
                {items.map((item, i) => {
                    const itemId = item[valueField];
                    const itemKey = `${item[valueField]}-${i}`;
                    return (
                        <li
                            id={itemId}
                            key={itemKey}
                            className={classNames(css.generalItem, {
                                [css.divider]: item.divider,
                                [css.disabledItem]: item.disabled,
                            })}
                            onMouseEnter={item.onMouseEnter}
                            onMouseLeave={item.onMouseLeave}
                        >
                            {this.getInnerHtml(item, 'display_name', 'explanation', 'icon')}
                        </li>
                    );
                })}
            </ul>
        );
    }
}

DropdownBody.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
    onSelection: PropTypes.func,
    translate: PropTypes.func,
    getInnerHtml: PropTypes.func,
    valueField: PropTypes.string,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
};

DropdownBody.defaultProps = {
    items: [],
    onSelection: () => {},
    translate: () => {},
    getInnerHtml: null,
    valueField: 'name',
    className: undefined,
    dataTestId: undefined,
};

export default DropdownBody;
