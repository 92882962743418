import moment from 'moment';
import {
    SELECTED_CAMPAIGN_LOCAL_STORAGE_KEY,
    SELECTED_SOURCE_LOCAL_STORAGE_KEY,
} from './filters/CampaignFilters/consts';
import { formatCampaignFilter } from './filters/CampaignFilters/utils';

const DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DAYS_TO_SUBTRACT = 6;
const DATE_UNIT = 'days';
export const getStartDate = () => {
    return moment()
        .subtract(DEFAULT_DAYS_TO_SUBTRACT, DATE_UNIT)
        .format(DATE_FORMAT);
};

export const getEndDate = () => {
    return moment().format(DATE_FORMAT);
};

export const getDefaultFiltersFromLocalStorage = () => {
    const selectedSource = JSON.parse(localStorage.getItem(SELECTED_SOURCE_LOCAL_STORAGE_KEY));
    const selectedCampaign = JSON.parse(localStorage.getItem(SELECTED_CAMPAIGN_LOCAL_STORAGE_KEY));
    if (selectedCampaign?.value && selectedSource?.value) {
        return formatCampaignFilter(selectedCampaign.value, selectedSource.value);
    }
    return [];
};
