import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './ConversionModelsGroupContent.css';
import ExpandablePanel from '../../../components/widgets/ExpandablePanel';
import ConversionModelsGroupHeader from './ConversionModelsGroupHeader';
import { P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE } from '../../consts';
import { conversionModelGroupShape } from '../../components/skan4/types';
import ConversionModelsCard from './ConversionModelsCard';
import { getModelMeasurementPeriod, getRelevantModel } from '../../utils';
import { ModelsApiContext } from '../../hooks';
import { getOptimizedModelsData } from '../../components/skan4/optimizedModelsUtils';

function ConversionModelsGroupContent({ conversionModelsGroup, onToggleClick, onArchiveClick }) {
    const { optimizedModels } = useContext(ModelsApiContext);

    const [relevantOptimizedModels, setRelevantOptimizedModels] = useState([]);
    const [expanded, setExpanded] = useState(true);

    const { id: groupId, name, conversionModels, siteId, isSkan4 } = conversionModelsGroup;
    const fineConversionModel = getRelevantModel(conversionModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE);

    useEffect(() => {
        const shouldShowOptimizedModels = optimizedModels?.length && fineConversionModel?.enabled;

        setRelevantOptimizedModels(
            shouldShowOptimizedModels
                ? getOptimizedModelsData(
                      optimizedModels,
                      fineConversionModel.modelType,
                      fineConversionModel.revenueType,
                      fineConversionModel.revenueBuckets,
                      getModelMeasurementPeriod(fineConversionModel)
                  ).optimizedModels
                : []
        );
    }, [fineConversionModel, optimizedModels]);

    if (!fineConversionModel) {
        return null;
    }

    const header = (
        <ConversionModelsGroupHeader
            name={name}
            groupId={groupId}
            model={fineConversionModel}
            onToggleClick={onToggleClick}
            onArchiveClick={onArchiveClick}
            relevantOptimizedModels={relevantOptimizedModels}
            isSkan4={isSkan4}
        />
    );

    const content = <ConversionModelsCard conversionModels={conversionModels} groupId={groupId} siteId={siteId} />;

    return (
        <div className={css.container}>
            <ExpandablePanel
                animate={false}
                expanded={expanded}
                isSticky={false}
                header={header}
                content={content}
                onExpandClick={() => {
                    setExpanded(!expanded);
                }}
                overrideClasses={{
                    headerContainer: classNames(css.headerContainer, { [css.enabled]: fineConversionModel.enabled }),
                    externalHeaderArea: css.expandableOverrideExternal,
                    contentArea: css.contentArea,
                }}
            />
        </div>
    );
}

ConversionModelsGroupContent.propTypes = {
    conversionModelsGroup: conversionModelGroupShape,
    onToggleClick: PropTypes.func,
    onArchiveClick: PropTypes.func,
};

ConversionModelsGroupContent.defaultProps = {
    conversionModelsGroup: {},
    onToggleClick: () => {},
    onArchiveClick: () => {},
};

export default ConversionModelsGroupContent;
