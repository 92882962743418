import React from 'react';
import EditPanel from './EditPanel';

export default class Details extends React.Component {
    render() {
        const { edit, invalid, metadata, onChange } = this.props;

        const fields = [
            {
                type: 'text',
                label: 'Name',
                value: 'name',
                onChange: 'name',
                error: invalid && !edit.name ? 'Name is a required field' : '',
            },
            {
                type: 'text',
                label: 'Description',
                value: 'description',
                onChange: 'description',
            },
            {
                type: 'tags',
                label: 'Apps',
                value: 'apps',
                options: 'appOptions',
                onChange: 'apps',
                error: invalid && !edit.apps.length ? 'You must select at least 1 app' : '',
                placeholder: 'Select an App',
            },
            {
                type: 'tags',
                label: 'Location',
                value: 'locations',
                options: 'locationOptions',
                onChange: 'locations',
                placeholder: 'Select a Location',
            },
            {
                type: 'tags',
                label: 'Cities',
                value: 'cities',
                options: 'citiesOptions',
                onChange: 'cities',
                placeholder: 'Select a City',
            },
            {
                type: 'tags',
                label: 'Device Model',
                value: 'device_models',
                options: 'deviceOptions',
                onChange: 'device_models',
                placeholder: 'Select a Device Model',
            },
            {
                type: 'tags',
                label: 'OS Version',
                value: 'os_versions',
                options: 'osOptions',
                onChange: 'os_versions',
                placeholder: 'Select an OS Version',
            },
        ];

        return (
            <EditPanel
                title="Details"
                fields={fields}
                edit={edit}
                invalid={invalid}
                metadata={metadata}
                onChange={onChange}
            />
        );
    }
}
