// Get selected options after extracting periods from fields
// For example: [a_1d, a_3d, b_1d, b_3d] => [a, b] and [1d, 3d]
import { arrayToDict } from '../utils/arrays';

export const getSelectedOptions = (
    readonly,
    selectedValues,
    dimensionsOptions,
    eventsOptions,
    metricsOptions,
    periodsOptions
) => {
    if (!readonly) {
        return {};
    }

    const selectedPeriodsOptions = new Set();
    const selectedFieldsOptions = new Set();
    const allFieldsOptions = [...dimensionsOptions, ...eventsOptions, ...metricsOptions];

    selectedValues.forEach(field => {
        const separatorIndex = field.lastIndexOf('_');
        const fieldWithoutPeriod = field.slice(0, separatorIndex);
        const period = field.slice(separatorIndex + 1);

        const periodOption = periodsOptions.find(({ value }) => value === period);
        if (periodOption) {
            selectedPeriodsOptions.add(periodOption);
        }

        const fieldName = periodOption ? fieldWithoutPeriod : field;
        const fieldOption = allFieldsOptions.find(({ value }) => value === fieldName);
        if (fieldOption) {
            selectedFieldsOptions.add(fieldOption);
        }
    });

    const selectedPeriods = [...selectedPeriodsOptions];
    const selectedMetrics = metricsOptions.filter(option => selectedFieldsOptions.has(option));
    const selectedEvents = eventsOptions.filter(option => selectedFieldsOptions.has(option));
    const selectedDimensions = dimensionsOptions.filter(option => selectedFieldsOptions.has(option));

    return { selectedDimensions, selectedEvents, selectedMetrics, selectedPeriods };
};

export const getFieldDisplayName = field => {
    return field
        .replace(/[_()-]/g, ' ') // remove unsupported characters
        .replace(/\s\s+/g, ' ') // replace all consecutive spaces with a single space
        .replace(/ /g, '_') // replace space with underscore
        .toLowerCase();
};

// Get cohort fields with period suffix
// For example: [a, b] and [1d, 3d] => [a_1d, a_3d, b_1d, b_3d]
export const getCohortFields = (fields, periods) => {
    return fields.reduce(
        (total, metric) => ({
            ...total,
            ...arrayToDict(periods, period => ({
                [`${metric.value}_${period.value}`]: getFieldDisplayName(`${metric.label}_${period.label}`),
            })),
        }),
        {}
    );
};
