import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import css from './FiltersSector.css';
import FilterFields from '../../containers/FilterFields';

function FiltersSector({ reportType }) {
    const isExpanded = useSelector(state => !!state[reportType]?.isFiltersExpanded);

    return (
        <>
            {isExpanded && (
                <FilterFields
                    reportType={reportType}
                    containerClass={css.container}
                    filterClass={css.filterContainer}
                    showRemoveAlways
                />
            )}
        </>
    );
}

FiltersSector.propTypes = {
    reportType: PropTypes.string,
};

FiltersSector.defaultProps = {
    reportType: '',
};

export default FiltersSector;
