import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import { Translate } from 'react-localize-redux';
import css from './Toggle.css';
import CheckedIcon from '../../resources/svg/checked_circle.svg';

function Toggle(props) {
    const { disabled, label, labelPosition, checked, onToggle, className, style, styleOverride } = props;
    const elementRef = useRef();

    useEffect(() => {
        elementRef.current.querySelector('input').style.pointerEvents = disabled ? 'none' : 'auto';
    }, [disabled]);

    const useLabel = label && typeof label === 'string' ? <Translate id={label} /> : label;

    return (
        <div className={classNames(css.toggleContainer, className)} ref={elementRef} style={style}>
            <FormControlLabel
                label={useLabel}
                labelPlacement={labelPosition}
                sx={{
                    margin: 0,
                    '& .MuiFormControlLabel-label': {
                        fontWeight: 400,
                        fontSize: 13,
                        ...(styleOverride.labelStyle || {}),
                    },
                }}
                control={
                    <Switch
                        checked={checked}
                        onChange={onToggle}
                        disabled={disabled}
                        checkedIcon={<CheckedIcon />}
                        sx={{
                            width: '40px',
                            height: 'auto',
                            padding: '2px',
                            marginRight: '4px',
                            '& .MuiSwitch-track': {
                                borderRadius: '11px',
                                height: '22px',
                                backgroundColor: '#BCBEC2',
                                opacity: 1,
                            },
                            '& .MuiSwitch-thumb': {
                                width: '16px',
                                height: '16px',
                            },
                            '& .MuiSwitch-switchBase': {
                                padding: '5px',
                                '&.Mui-checked': {
                                    transform: 'translateX(14px)',
                                    color: '#fff',
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: '#3a92e5',
                                        opacity: 1,
                                    },
                                },
                            },
                            '& .Mui-disabled': {
                                '& .MuiSwitch-thumb': {
                                    boxShadow: 'none',
                                },
                                '&.MuiSwitch-switchBase': {
                                    color: '#fff',
                                    '+.MuiSwitch-track': {
                                        opacity: 0.32,
                                    },
                                },
                            }
                        }}
                    />
                }
            />
        </div>
    );
}

Toggle.propTypes = {
    checked: PropTypes.bool,
    onToggle: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    disabled: PropTypes.bool,
    labelPosition: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    styleOverride: PropTypes.objectOf(PropTypes.any),
};

Toggle.defaultProps = {
    checked: false,
    onToggle: () => {},
    label: '',
    disabled: false,
    labelPosition: 'end',
    className: '',
    style: {},
    styleOverride: {},
};

export default Toggle;
