import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <span>In the SDK console, check if your app is sending user sessions to Singular. A session (or an app-open) will also allow you to verify if attribution is working; in other words, an install is triggered only once the user opens the app for the first time.&nbsp;</span>
            </p>
            <p>
                <span>You will need to check that you see the following events:</span>
            </p>
            <ul>
                <li>
                    <strong>Check session events</strong>
                </li>
                <li>
                    <strong>For iOS: </strong><span>Check for Apple’s token value in the ‘attribution_token’ parameter on our SDK start Session..</span>
                </li>
                <li>
                    <strong>For Android:</strong><span> Check if "__InstallReferrer" is received after your initial session event.</span>
                </li>
            </ul>
            <p>
                <strong>Custom User ID:</strong><span> You can add a unique identifier to track users. This is especially helpful for tracking users across devices and when users have blocked their Apple/Google Advertiser Ids. During testing, verify that Custom User ID is showing up as a parameter for your events.</span>
            </p>
        </div>
    );
}
