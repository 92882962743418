import React from 'react';
import { DEFAULT_COHORT, DEFAULT_CONDITION, DEFAULT_METRIC, THRESHOLD_CONDITIONS } from 'alerts/consts';
import ShelfGroup from 'components/widgets/ShelfGroup';
import css from 'alerts/components/CustomAlertsShelf.css';
import { Translate } from 'react-localize-redux';
import Dropdown from 'components/widgets/Dropdown';
import { getMetricSymbol } from 'alerts/utils';
import TextField from 'components/widgets/TextField';
import XIcon from 'resources/svg/icon_remove_row.svg';
import PropTypes from 'prop-types';

function ThresholdGroup({
    translate,
    alertsPageMetadata,
    metrics,
    selectedThresholdMetric,
    onThresholdMetricChanged,
    selectedThresholdCohort,
    onThresholdCohortChanged,
    selectedThresholdCondition,
    onThresholdConditionChanged,
    selectedThresholdValue,
    onThresholdValueChanged,
}) {
    const isThresholdCohort = selectedThresholdMetric && selectedThresholdMetric.is_cohort;

    const onClearThresholdInputs = () => {
        onThresholdMetricChanged(DEFAULT_METRIC);
        onThresholdConditionChanged(DEFAULT_CONDITION);
        onThresholdValueChanged('');
    };
    const isDefaultSelectedMetric = !selectedThresholdMetric || selectedThresholdMetric.name === DEFAULT_METRIC.name;
    const thresholdConditions = alertsPageMetadata.operators.filter((operator) =>
        THRESHOLD_CONDITIONS.includes(operator.name)
    );
    const thresholdConfiguredCount =
        selectedThresholdMetric && selectedThresholdCondition && selectedThresholdValue ? 1 : 0;
    return (
        <ShelfGroup
            className={css.shelfGroup}
            contentContainer={css.shelfGroupContent}
            label={`${translate(
                translate('STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.THRESHOLD')
            )} (${thresholdConfiguredCount})`}
            labelContainer={css.shelfGroupLabelContainer}
        >
            <div className={css.sectionHeader}>
                <div className={css.shelfGroupInfo}>
                    <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.THRESHOLD_INFO" />
                </div>
            </div>
            <Dropdown
                items={metrics}
                wrapperStyle={{ width: '570px' }}
                containerStyle={{ width: '100%' }}
                selected={selectedThresholdMetric || DEFAULT_METRIC}
                selectedContainer={css.dropdown}
                onSelection={onThresholdMetricChanged}
            />
            <div className={css.thresholdCondition} style={{ marginBottom: '-15px' }}>
                {isThresholdCohort && (
                    <Dropdown
                        items={alertsPageMetadata.cohort_periods}
                        disabled={!selectedThresholdMetric}
                        wrapperStyle={{ width: '150px' }}
                        containerStyle={{ width: '100%' }}
                        selected={selectedThresholdCohort || DEFAULT_COHORT}
                        selectedContainer={css.dropdown}
                        onSelection={onThresholdCohortChanged}
                    />
                )}
                <Dropdown
                    items={thresholdConditions}
                    wrapperStyle={{ width: isThresholdCohort ? '286px' : '436px' }}
                    containerStyle={{ width: '100%', paddingLeft: isThresholdCohort ? '10px' : '0' }}
                    selected={selectedThresholdCondition || DEFAULT_CONDITION}
                    disabled={isDefaultSelectedMetric}
                    selectedContainer={css.dropdown}
                    onSelection={onThresholdConditionChanged}
                />
                <div className={css.metricFormat}>{getMetricSymbol(selectedThresholdMetric)}</div>
                <TextField
                    containerStyle={{ width: '100px', marginLeft: '20px' }}
                    inputConfig={{ maxLength: 8 }}
                    value={selectedThresholdValue}
                    containerClass={css.textInputLine}
                    disabled={isDefaultSelectedMetric}
                    onChange={onThresholdValueChanged}
                    placeholder="Value"
                    type="number"
                />
                <div className={css.clearIcon} style={{ marginLeft: '15px' }} onClick={onClearThresholdInputs}>
                    <XIcon style={{ width: '10px' }} />
                </div>
            </div>
        </ShelfGroup>
    );
}

ThresholdGroup.propTypes = {
    translate: () => {},
    alertsPageMetadata: PropTypes.objectOf(PropTypes.any),
    metrics: PropTypes.arrayOf(PropTypes.object),
    selectedThresholdMetric: PropTypes.objectOf(PropTypes.any),
    onThresholdMetricChanged: PropTypes.func,
    selectedThresholdCohort: PropTypes.objectOf(PropTypes.any),
    onThresholdCohortChanged: PropTypes.func,
    selectedThresholdCondition: PropTypes.objectOf(PropTypes.any),
    onThresholdConditionChanged: PropTypes.func,
    selectedThresholdValue: PropTypes.string,
    onThresholdValueChanged: PropTypes.func,
};

ThresholdGroup.defaultProps = {
    translate: PropTypes.func,
    alertsPageMetadata: {},
    metrics: [],
    selectedThresholdMetric: {},
    onThresholdMetricChanged: () => {},
    selectedThresholdCohort: {},
    onThresholdCohortChanged: () => {},
    selectedThresholdCondition: {},
    onThresholdConditionChanged: () => {},
    selectedThresholdValue: '',
    onThresholdValueChanged: () => {},
};

export default ThresholdGroup;
