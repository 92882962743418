import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts, HighchartsChart, ColumnSeries } from 'react-jsx-highcharts';
import { colorByType, organicVsPaidPlotOptions } from '../consts';
import ChartTooltip from './chartTooltip';
import ChartLegend from './chartLegend';
import ChartXAxis from './chartXAxis';
import ChartYAxis from './chartYAxis';
import Chart from './chart';
import { dateLabelsFormatter, mapSeriesValues, seriesFloatValueFormatter } from '../utils';

const OrganicVsPaidChart = ({ chartData }) => {
    const [chartLoaded, setChartLoaded] = useState(false);
    const { data, labels } = chartData;
    const categories = labels.map((label) => {
        return new Date(label);
    });

    return (
        <HighchartsChart plotOptions={organicVsPaidPlotOptions} callback={() => setChartLoaded(true)}>
            <ChartLegend disableHover />
            <Chart loaded={chartLoaded} />
            <ChartTooltip categories={categories} />
            <ChartXAxis
                id="organic_vs_paid_x_axis"
                categories={categories}
                lineWidth={1}
                formatter={dateLabelsFormatter}
            />
            <ChartYAxis id="organic_vs_paid_y_axis">
                {data.map(({ data: values, label }) => {
                    return (
                        <ColumnSeries
                            formatter={seriesFloatValueFormatter}
                            key={label}
                            name={label}
                            data={mapSeriesValues(values)}
                            color={colorByType[label]}
                            onLegendItemClick={() => {
                                return false;
                            }}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

OrganicVsPaidChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
};

OrganicVsPaidChart.defaultProps = {
    chartData: {},
};

export default withHighcharts(OrganicVsPaidChart, Highcharts);
