import API from '../../../services/api';

let instance = null;
const api = new API();

const URL_HIDE_EMPTY_STATE = 'api/empty_state/hide';

export default class EmptyStateService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    hideEmptyState(emptyStateType) {
        return api.getJson(URL_HIDE_EMPTY_STATE, {
            method: 'POST',
            credentials: 'include',
            body: { emptyStateType },
        });
    }
}
