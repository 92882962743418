import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { getBookmark, getBookmarkPopoverData, getQueryDataForType, getTableConfig } from '../selectors/reports';
import {
    DELETE_BOOKMARK,
    deletingBookmark,
    reportFormChanged,
    SAVE_BOOKMARK,
    setActionType,
    updateBookmarkData,
    updateBookmarkError,
} from '../actions/reports';
import { getUserData } from '../selectors/user';
import { Reports } from '../services';

const reportsAPI = new Reports();

function triggerBookmarkEvent(type, data) {
    const event = new CustomEvent('bookmarkEvent', {
        detail: {
            type,
            data,
        },
    });
    window.dispatchEvent(event);
    return Promise.resolve();
}

function reportEditFormEqualsTable(report, table) {
    const sortedSelectedReportsData = Object.keys(report).reduce((obj, x) => {
        if (!x.startsWith('bookmark')) {
            obj[x] = Array.isArray(report[x]) ? report[x].sort() : report[x];
        }
        return obj;
    }, {});
    const sortedTableConfigRequest = Object.keys(table).reduce((obj, x) => {
        if (!x.startsWith('bookmark')) {
            obj[x] = Array.isArray(table[x]) ? table[x].sort() : table[x];
        }
        return obj;
    }, {});
    return JSON.stringify(sortedSelectedReportsData) === JSON.stringify(sortedTableConfigRequest);
}

function* handleSaveBookmark(action) {
    let eventType = 'bookmarkAdded';
    const { reportType, data, resolve } = action;
    if (!data || !data.name) {
        yield put(setActionType(updateBookmarkError('Bookmark name is mandatory', data.instanceId), reportType));
        return;
    }
    const tableConfig = yield select(getTableConfig(reportType));
    const selectedReportsData = yield select(getQueryDataForType(reportType, 'reports'));
    const userData = yield select(getUserData);
    const bookmark = yield select(getBookmark(reportType));
    const request = {
        ...data,
        query: { ...selectedReportsData, column_order: tableConfig.columnOrder },
        creator: userData.username,
        is_run: tableConfig.request ? reportEditFormEqualsTable(selectedReportsData, tableConfig.request) : false, // report was run before bookmark was saved
    };
    if (bookmark && bookmark.bookmark_id && !data.saveNewCopy) {
        request.id = bookmark.bookmark_id;
        eventType = 'bookmarkUpdated';
    }
    try {
        const response = yield call(reportsAPI.saveBookmark, request);
        yield put(setActionType(updateBookmarkData(response, request, eventType), reportType));
        yield call(triggerBookmarkEvent, eventType, response.bookmark);
        yield put(setActionType(reportFormChanged(false), reportType));
        resolve();
    } catch (errorMessage) {
        yield put(setActionType(updateBookmarkError(errorMessage, data.instanceId), reportType));
    }
}

function* handleDeleteBookmark(action) {
    const { reportType } = action;
    const bookmark = yield select(getBookmark(reportType));
    const { bookmarkData } = yield select(getBookmarkPopoverData(reportType));
    yield put(setActionType(deletingBookmark(bookmarkData || bookmark), reportType));
    yield call(reportsAPI.deleteBookmark, bookmark.bookmark_id);
    yield put(setActionType(updateBookmarkData({ bookmark_id: '' }), reportType));
    yield call(triggerBookmarkEvent, 'bookmarkRemoved', bookmark.bookmark_id);
}

function* watchSaveBookmark() {
    while (true) {
        const action = yield take(SAVE_BOOKMARK);
        yield fork(handleSaveBookmark, action);
    }
}

function* watchDeleteBookmark() {
    while (true) {
        const action = yield take(DELETE_BOOKMARK);
        yield fork(handleDeleteBookmark, action);
    }
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

function* watchBookmarks() {
    yield all([fork(watchSaveBookmark), fork(watchDeleteBookmark)]);
}

export { watchBookmarks };
