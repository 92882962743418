import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik-2';
import { Translate, withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import css from './TestParameters.css';
import ControlGroupIcon from '../../../../resources/svg/ab_testing_control_group.svg';
import PercentageInput from './components/PercentageInput';
import useWizard from '../hook/useWizard';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.TEST_PARAMETERS';

const TestParameters = ({ onSaveDraft, pageTitle, onNext, onBack, translate, distributionsData }) => {
    const { setFieldValue, values } = useFormikContext();
    const { shouldShowDraft, isAlreadyStarted } = useWizard(values);
    const [{ value: testChannels }] = useField('testChannels');

    const MAXIMUM_ALLOWED_PARAMETER_VALUE = 100 - (testChannels || []).length;

    const [controlGroupValue, setControlGroupValue] = useState(
        100 - testChannels.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)
    );

    const onPercentChange = (rowIndex, value) => {
        const updatedTestChannels = [...testChannels];
        const currentControl = controlGroupValue;
        const totalRows = testChannels.length + 1; // Networks + control group

        if (rowIndex === testChannels.length) {
            const currentA0 = updatedTestChannels[0].value; // First network’s value
            const maxAllowable = Math.min(
                totalRows === 2 ? 99 : 98, // Max based on number of rows
                currentControl + (currentA0 - 1) // Limited by first network’s minimum
            );
            const clampedValue = Math.max(1, Math.min(value, maxAllowable));
            const delta = clampedValue - currentControl;

            updatedTestChannels[0].value = currentA0 - delta;
            setControlGroupValue(clampedValue);
        } else {
            const i = rowIndex;
            const currentValue = updatedTestChannels[i].value;
            const maxAllowable = Math.min(
                totalRows === 2 ? 99 : 98, // Max based on number of rows
                currentValue + (currentControl - 1) // Limited by control group’s minimum
            );
            const clampedValue = Math.max(1, Math.min(value, maxAllowable));
            const delta = clampedValue - currentValue;

            updatedTestChannels[i].value = clampedValue;
            setControlGroupValue(currentControl - delta);
        }

        updatedTestChannels.forEach((tc, index) => {
            setFieldValue(`testChannels.${index}.value`, tc.value);
        });
    };

    const Row = ({ value, title, icon, onChange }) => (
        <div key={value} className={css.rowContainer}>
            <div className={css.nameContainer}>
                {(icon && <img src={icon} alt="" className={css.icon} />) || <ControlGroupIcon />}
                {title}
            </div>
            <div className={classNames(css.sizeContainer, { [css.disabled]: isAlreadyStarted })}>
                <PercentageInput
                    disabled={isAlreadyStarted}
                    min={1}
                    max={MAXIMUM_ALLOWED_PARAMETER_VALUE}
                    onChange={onChange}
                    value={value}
                />
            </div>
        </div>
    );

    Row.propTypes = {
        value: PropTypes.number,
        onChange: PropTypes.func,
        icon: PropTypes.string,
        title: PropTypes.string,
    };
    return (
        <WizardLayout
            title={pageTitle}
            onSaveDraft={onSaveDraft}
            shouldShowDraft={shouldShowDraft}
            buttons={
                <StepButtons onBack={onBack} onNext={onNext} backTitle="BUTTONS.BACK" nextTitle="STATIC.BUTTONS.NEXT" />
            }
        >
            <div>
                <Translate id={`${translatePrefix}.SUBTITLE`} />
                <div className={css.container}>
                    <div className={css.gridContainer}>
                        <div className={css.rowContainer}>
                            <div className={css.networkHeader}>
                                <Translate id={`${translatePrefix}.COLUMNS.NETWORKS`} />
                            </div>
                            <div className={css.sizeHeader}>
                                <Translate id={`${translatePrefix}.COLUMNS.SIZE`} />
                            </div>
                        </div>
                        {testChannels.map((testChannel, index) => (
                            <Row
                                key={testChannel.name}
                                rowIndex={index}
                                onChange={val => onPercentChange(index, val)}
                                value={testChannel.value}
                                icon={distributionsData?.distributionNetworks[testChannel.name]?.logo_path}
                                title={distributionsData?.distributionNetworks[testChannel.name]?.display_name}
                            />
                        ))}
                        <Row
                            title={translate(`${translatePrefix}.CONTROL_GROUP_LABEL`)}
                            rowIndex={testChannels.length}
                            onChange={val => onPercentChange(testChannels.length, val)}
                            value={controlGroupValue}
                        />
                    </div>
                </div>
            </div>
        </WizardLayout>
    );
};

TestParameters.propTypes = {
    onSaveDraft: PropTypes.func,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    pageTitle: PropTypes.string,
    translate: PropTypes.func,
    distributionsData: PropTypes.shape({
        allNetworks: PropTypes.arrayOf(
            PropTypes.shape({
                display_name: PropTypes.string,
                name: PropTypes.string,
                isDisabled: PropTypes.bool,
            })
        ),
        internalDistributions: PropTypes.any,
        distributionNetworks: PropTypes.any,
    }),
};

export default withLocalize(TestParameters);
