import { useCallback, useEffect, useState } from 'react';
import { useRequest, usePollingEffect } from '../utils/customHooks';
import TestingConsoleAPI from './service';

const GET_LOGS_INTERVAL_DELAY = 1500;

export const useFetchDevices = (firstPageLoad, shouldFetchDevices, onCleanup) => {
    const testingConsoleAPI = new TestingConsoleAPI();
    return useRequest(
        useCallback(testingConsoleAPI.getDevices, [shouldFetchDevices]),
        () => {
            return firstPageLoad || shouldFetchDevices;
        },
        onCleanup,
        shouldFetchDevices
    );
};

export const useTriggerSDKAudit = (shouldRun, onCleanup) => {
    const testingConsoleAPI = new TestingConsoleAPI();
    return useRequest(
        useCallback(testingConsoleAPI.triggerAudit, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        {}
    );
};

export const useSaveDevice = (shouldRun, onCleanup, deviceValues) => {
    const testingConsoleAPI = new TestingConsoleAPI();
    return useRequest(
        useCallback(testingConsoleAPI.saveNewDevice, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        {
            id: deviceValues.id,
            platform: deviceValues.platform,
            keyspace: deviceValues.keyspace,
            device_id: deviceValues.device_id,
            display_name: deviceValues.display_name,
        }
    );
};

export const useDeleteDevice = (shouldRun, onCleanup, deviceModelId) => {
    const testingConsoleAPI = new TestingConsoleAPI();

    return useRequest(
        useCallback(testingConsoleAPI.deleteDevice, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        { id: deviceModelId }
    );
};

export const useFetchLogs = (selectedDevices, timeLimit) => {
    const testingConsoleAPI = new TestingConsoleAPI();
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [timeExpired, setTimeExpired] = useState(false);
    const [startPollingTime, setStartPollingTime] = useState(new Date().getTime());

    const { isPollingStopped, killPoll, respawnPoll, forceCallback } = usePollingEffect(
        async () => {
            setIsLoading(true);
            try {
                const { status, value } = await testingConsoleAPI.getLogs(selectedDevices.map(device => device.id));
                if (new Date().getTime() - startPollingTime > timeLimit) {
                    setTimeExpired(true);
                }
                if (status === 0 && JSON.stringify(logs) !== JSON.stringify(value)) {
                    setLogs(value);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [selectedDevices],
        { interval: GET_LOGS_INTERVAL_DELAY }
    );

    const internalKillPoll = () => {
        killPoll();
        setTimeExpired(false);
    };

    const internalRespawnPoll = () => {
        respawnPoll();
        setStartPollingTime(new Date().getTime());
    };

    useEffect(() => {
        if (timeExpired) {
            internalKillPoll();
        }
    }, [timeExpired]);

    return {
        logs,
        setLogs,
        isLoading,
        isPollingStopped,
        killPoll: internalKillPoll,
        respawnPoll: internalRespawnPoll,
        forceFetchLogs: forceCallback,
    };
};
