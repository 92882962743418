import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import {
    eventShape,
    ParameterTypes,
    partnerShape,
    SupportTypes,
    templateShape,
    PostbackWindowStartingPoint,
    organizationOptionsShape,
} from '../../types';
import { SmallDialog, Table } from '../../../../components/widgets';
import TableActions from '../../../../components/widgets/TableActions';
import tableActionsCss from '../../../../components/widgets/TableActions.css';
import shelfCss from '../PartnerConfigurationShelf.css';
import { isDisabled, getSendAllEventsTooltip, orgSupportAdvancedPostbacksFeatures, getUsedParameters } from '../utils';
import {
    getAutocompleteCellProps,
    getCheckboxCellProps,
    getDropdownCellProps,
    getTableCellProps,
    getTextCellProps,
    getWindowCell,
} from '../tableCellsUtils';
import AdvancedPostbackSettingsFactory from '../advancedPostbackSettings/AdvancedPostbackSettingsFactory';
import advancedPostbackCss from '../advancedPostbackSettings/AdvancedPostbackSettings.css';
import { getIsAgency } from '../../../../selectors/user';
import { useSelector } from 'react-redux';

const getTranslation = key => `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.EVENT_POSTBACKS_TABLE.${key}`;

export const getDropdownOption = (displayName, name) => {
    if (!displayName && !name) return undefined;

    return { display_name: displayName, name: name || displayName };
};

export const getAutocompleteOption = (displayName, name) => {
    if (!displayName && !name) return undefined;

    return { label: displayName, value: name || displayName };
};

export const getDefaultEventPostback = (partner, template, parameters, postbackExtraFields) => ({
    eventId: null,
    sendAll: [SupportTypes.MANDATORY, SupportTypes.RECOMMENDED].includes(partner.sendAllEvents),
    postbackWindowStartingPoint:
        partner.sendAllEvents === SupportTypes.MANDATORY
            ? PostbackWindowStartingPoint.UNLIMITED
            : PostbackWindowStartingPoint.INSTALL,
    postbackWindow: partner.sendAllEvents !== SupportTypes.MANDATORY ? template.installWindow : '',
    ...postbackExtraFields,
    ...parameters.reduce((total, param) => ({ ...total, [param.name]: '' }), {}),
});

function EventPostbacksTable({
    eventsPostbacks,
    partner,
    template,
    events,
    extraTableCells,
    postbackExtraFields,
    setPostbacks,
    setTouched,
    errors,
    touched,
    tableDataTestId,
    isReadonly,
    orgOptions,
    translate,
}) {
    const [editedPostbackIndex, setEditedPostbackIndex] = useState(null);
    const { platform, type: templateType } = template;
    const isAgency = useSelector(state => getIsAgency(state));
    const advancedPostbackSupport = orgSupportAdvancedPostbacksFeatures(orgOptions);
    const parameters = getUsedParameters(partner, platform, templateType, [ParameterTypes.POSTBACK]);
    const touchedPostbacks = !touched?.length ? Array(eventsPostbacks?.length || 1).fill({}) : touched;
    const postbacks = !eventsPostbacks?.length
        ? [getDefaultEventPostback(partner, template, parameters, postbackExtraFields)]
        : eventsPostbacks.map(postback => ({ ...postback, disabled: isReadonly }));

    const predefinedOptionsParameter =
        parameters.length > 0 && parameters.filter(parameter => parameter.predefinedValues?.length).length === 1
            ? parameters[0]
            : null;

    const usedEvents = predefinedOptionsParameter
        ? postbacks.map(row => `${row.eventId} ${row[predefinedOptionsParameter.name]}`).filter(Boolean)
        : postbacks.map(row => row.eventId).filter(Boolean);

    const remainingEvents = predefinedOptionsParameter
        ? events.filter(
              event =>
                  usedEvents.filter(eventParam => eventParam.startsWith(event.id)).length !==
                  predefinedOptionsParameter.predefinedValues.length
          )
        : events.filter(event => !usedEvents.some(eventId => eventId === event.id));

    const onAddRowClicked = () => {
        setPostbacks([...postbacks, getDefaultEventPostback(partner, template, parameters, postbackExtraFields)]);
        setTouched([...touchedPostbacks, {}]);
    };

    const clearEditedPostback = () => setEditedPostbackIndex(null);

    const onDeleteRowClicked = rowIndex => {
        const updatedPostbacks = [...postbacks];
        updatedPostbacks.splice(rowIndex, 1);
        updatedPostbacks.length === 0 ? setPostbacks(null) : setPostbacks(updatedPostbacks);

        const updatedTouchedPostbacks = [...touchedPostbacks];
        updatedTouchedPostbacks.splice(rowIndex, 1);
        updatedTouchedPostbacks.length === 0 ? setTouched(null) : setTouched(updatedTouchedPostbacks);
    };

    const getErrorCellProp = field => ({
        propName: 'error',
        transformation: (_, index) => touchedPostbacks?.[index]?.[field] && errors?.[index]?.[field],
    });

    const getValueCellProp = (field, transformation) => ({
        propName: 'value',
        transformation: (_, index) => transformation(postbacks[index]?.[field]),
    });

    const getRowRemainingEvents = currentRowIndex => {
        if (!predefinedOptionsParameter || currentRowIndex >= postbacks?.length) {
            return remainingEvents;
        }

        // if we have postback paramerter with multiple options we're allowing mapping same sdk event to different options,
        // but not duplicate them
        return remainingEvents.filter(
            remainingEvent =>
                !postbacks.some(
                    (postback, postbackIndex) =>
                        postbackIndex !== currentRowIndex &&
                        postback?.eventId === remainingEvent.id &&
                        postback[predefinedOptionsParameter.name] !== null &&
                        postback[predefinedOptionsParameter.name] ===
                            postbacks[currentRowIndex][predefinedOptionsParameter.name]
                )
        );
    };

    const getRemainingPredefinedValues = (currentRowIndex, predefinedValues, paramName) => {
        if (currentRowIndex >= postbacks?.length) {
            return predefinedValues;
        }

        // if we have postback paramerter with multiple options we allowing mapping same sdk event to different options,
        // but not duplicate them
        return predefinedValues.filter(
            value =>
                !postbacks.some(
                    (postback, postbackIndex) =>
                        postbackIndex !== currentRowIndex &&
                        postback?.eventId === postbacks[currentRowIndex]?.eventId &&
                        postback[paramName] === value
                )
        );
    };

    const onFieldUpdated = (value, field, index) => {
        const updatedTouched = [...touchedPostbacks];
        updatedTouched[index][field] = true;
        setTouched(updatedTouched);

        const updatedPostbacks = [...postbacks];
        updatedPostbacks[index][field] = value;

        if (field === 'sendAll') {
            updatedPostbacks[index].postbackWindowStartingPoint = PostbackWindowStartingPoint.UNLIMITED;
            updatedPostbacks[index].postbackWindow = '';
        }

        // keep event name updated if eventId is updated
        if (field === 'eventId') {
            updatedPostbacks[index].eventName = events.find(event => event.id === value)?.eventName;
        }

        setPostbacks(updatedPostbacks);
    };

    const updateAdvancedPostbackFields = (campaignRegex, customByUser, postbackUri) => {
        onFieldUpdated(campaignRegex, 'campaignRegex', editedPostbackIndex);
        onFieldUpdated(customByUser, 'customByUser', editedPostbackIndex);
        onFieldUpdated(customByUser ? postbackUri : null, 'postbackUri', editedPostbackIndex);
        setEditedPostbackIndex(null);
    };

    const eventCell = getAutocompleteCellProps(
        'eventId',
        getTranslation('SDK_EVENT_NAME'),
        getTranslation('SDK_EVENT_NAME_TOOLTIP'),
        index => getRowRemainingEvents(index).map(event => getAutocompleteOption(event.displayName, event.id)),
        onFieldUpdated,
        [
            getErrorCellProp('eventId'),
            getValueCellProp('eventId', value => {
                const event = events.find(({ id }) => value === id);
                return event ? { label: event.displayName, value: event.id } : undefined;
            }),
        ]
    );

    const parametersCells = parameters.map(({ name, label, description, predefinedValues }) =>
        !predefinedValues?.length
            ? getTextCellProps(name, label, description, {}, [getErrorCellProp(name)])
            : getAutocompleteCellProps(
                  name,
                  label,
                  description,
                  index =>
                      getRemainingPredefinedValues(index, predefinedValues, name).map(value => getAutocompleteOption(value)),
                  onFieldUpdated,
                  [getErrorCellProp(name), getValueCellProp(name, getAutocompleteOption)]
              )
    );

    const sendAllCell = getCheckboxCellProps(
        'sendAll',
        'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SEND_ALL_EVENTS',
        getTranslation('SEND_ALL_EVENTS_TOOLTIP'),
        { partnerName: partner.displayName },
        isDisabled(partner.sendAllEvents, isReadonly || isAgency),
        {
            tdClassName: classNames(shelfCss.tableCell, shelfCss.checkboxCell),
            tooltipText: getSendAllEventsTooltip(partner.sendAllEvents, isReadonly),
            tooltipData: { partnerName: partner.displayName },
        }
    );

    const postbackWindowStartPointCell = getDropdownCellProps(
        'postbackWindowStartingPoint',
        getTranslation('WINDOW_STARTING_POINT'),
        getTranslation('WINDOW_STARTING_POINT_TOOLTIP'),
        Object.values(PostbackWindowStartingPoint).map(value => getDropdownOption(value)),
        onFieldUpdated,
        [
            getErrorCellProp('postbackWindowStartingPoint'),
            getValueCellProp('postbackWindowStartingPoint', getDropdownOption),
            { propName: 'disabled', dataKey: 'sendAll', transformation: sendAll => sendAll || isReadonly },
        ]
    );

    const postbackWindowCell = getWindowCell(
        'postbackWindow',
        getTranslation('POSTBACK_WINDOW'),
        getTranslation('POSTBACK_WINDOW_TOOLTIP'),
        { partnerName: partner.displayName },
        [
            getErrorCellProp('postbackWindow'),
            {
                propName: 'disabled',
                dataKey: 'postbackWindowStartingPoint',
                transformation: startPoint => startPoint === PostbackWindowStartingPoint.UNLIMITED || isReadonly,
            },
        ]
    );

    const tableActionsCell = {
        ...getTableCellProps(null, ''),
        cellComponent: TableActions,
        cellProps: {
            onAdd: isReadonly ? null : onAddRowClicked,
            onDelete: isReadonly ? null : onDeleteRowClicked,
            onEdit: advancedPostbackSupport ? i => setEditedPostbackIndex(i) : null,
            addClassName: i =>
                classNames({
                    [tableActionsCss.hidden]: i !== postbacks.length - 1,
                    [tableActionsCss.disabled]: !remainingEvents.length || !postbacks[i]?.eventId,
                }),
            editClassName: i =>
                classNames({
                    [tableActionsCss.hidden]: !postbacks[i]?.eventId,
                    [tableActionsCss.dot]: postbacks[i]?.customByUser,
                }),
            editTooltip: i =>
                postbacks[i]?.customByUser
                    ? translate('STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.CUSTOM_POSTBACK_TOOLTIP', {
                          partnerName: partner.displayName,
                      })
                    : null,
            addTooltip: i => {
                if (!postbacks[i]?.eventId) return getTranslation('EVENT_NOT_FILLED');
                else if (!remainingEvents.length) return getTranslation('NO_REMAINING_EVENTS');
                else return null;
            },
        },
    };

    const tableMetadata = [
        eventCell,
        ...parametersCells,
        sendAllCell,
        postbackWindowStartPointCell,
        postbackWindowCell,
        ...extraTableCells,
        ...(isReadonly && !advancedPostbackSupport ? [] : [tableActionsCell]),
    ];

    const editedPostback = editedPostbackIndex !== null ? postbacks[editedPostbackIndex] : null;

    return (
        <>
            <Table
                dataTestId={tableDataTestId}
                data={postbacks}
                metadata={tableMetadata}
                trClass={shelfCss.tableRow}
                zebraTable={false}
                rowHoverEnabled={false}
                onToggleSingleRow={(value, row, field, index) => onFieldUpdated(value, field, index)}
            />
            <SmallDialog
                open={!!editedPostback}
                onClose={clearEditedPostback}
                onEscapePress={clearEditedPostback}
                classes={{ paperScrollBody: classNames(advancedPostbackCss.postbackPopup) }}
            >
                {editedPostback && (
                    <AdvancedPostbackSettingsFactory
                        template={template}
                        orgOptions={orgOptions}
                        partner={partner}
                        isReadonly={isReadonly}
                        postback={editedPostback}
                        onReject={clearEditedPostback}
                        eventName={events.find(event => event.id === editedPostback.eventId)?.displayName}
                        onAccept={updateAdvancedPostbackFields}
                    />
                )}
            </SmallDialog>
        </>
    );
}

EventPostbacksTable.propTypes = {
    partner: partnerShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    template: templateShape.isRequired,
    events: PropTypes.arrayOf(eventShape),
    extraTableCells: PropTypes.arrayOf(PropTypes.object),
    postbackExtraFields: PropTypes.object,
    eventsPostbacks: PropTypes.arrayOf(PropTypes.object),
    tableDataTestId: PropTypes.string,
    setPostbacks: PropTypes.func,
    setTouched: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object)]),
    touched: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object)]),
    isReadonly: PropTypes.bool,
    translate: PropTypes.func.isRequired,
};

EventPostbacksTable.defaultProps = {
    events: [],
    extraTableCells: [],
    postbackExtraFields: {},
    eventsPostbacks: null,
    tableDataTestId: null,
    setPostbacks: () => {},
    setTouched: () => {},
    errors: [],
    touched: [],
    isReadonly: false,
};

export default withLocalize(EventPostbacksTable);
