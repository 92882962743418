import { SWIFT } from '../../consts';
import { capitalize } from '../../../../utils/general';
import { CODE_SNIPPETS } from '../consts';

const getAdMobCodeSnippet = () => {
    return `var rewardedAd: GADRewardedAd?
GADRewardedAd.load(withAdUnitID: "AD_UNIT_ID", request: request) { 
   [weak self] ad, error in
     guard let self = self else { return }
   if let error = error {
      print("Rewarded ad failed to load with error: \\(error.localizedDescription)")
      return
   }
   self.rewardedAd = ad
   self.rewardedAd?.paidEventHandler = { adValue in
      let impressionData = adValue
      let data = SingularAdData(
         adPlatform: "Admob",
         currency: impressionData.currencyCode,
         revenue: impressionData.value)
   }
}`;
};

const getApplovinCodeSnippet = () => {
    return `if let message = message {
   let data = SingularAdData(
      adPlatform: "AppLovin",
      currency: "USD",
      revenue: message.data["revenue"] as? Double ?? 0.0)
    Singular.adRevenue(data)
}`;
};

const getUnityCodeSnippet = () => {
    return `if let impressionData = impressionData {
   let data = SingularAdData(
      adPlatform: "IronSource",
      currency: "USD",
      revenue: impressionData.revenue)
   Singular.adRevenue(data)
}`;
};

const getOtherCodeSnippet = () => {
    return `let data = SingularAdData(
   adPlatform: "YOUR_AD_PLATFORM",
   currency: "CURRENCY_CODE",
   revenue: 9.90)

Singular.adRevenue(data)`;
};

const getTradPlusCodeSnippet = () => {
    return `func tradPlusAdImpression(_ adInfo: [AnyHashable: Any]?) {
   guard let adInfo = adInfo else { return }
   let currency = "USD"
   if let ecpm = adInfo["ecpm"] as? Float {
      let revenue = CGFloat(ecpm) / 1000.0
      let data = SingularAdData(adPlatform: "TradPlus",
         currency: currency,
         revenue: revenue)
      Singular.adRevenue(data)
   }
}`;
};

const IosAdMonetizationCodeSnippetsGetters = {
    [CODE_SNIPPETS.AD_MONETIZATION_ADMOB]: getAdMobCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN]: getApplovinCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_UNITY]: getUnityCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_OTHER]: getOtherCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS]: getTradPlusCodeSnippet,
};

const getCodeSnippet = mediator => {
    const codeSnippetGetter = IosAdMonetizationCodeSnippetsGetters[mediator];
    return {
        markedCode: codeSnippetGetter(),
        codeLanguage: SWIFT,
        placeholders: {},
        customTitle: capitalize(SWIFT),
    };
};

const getSwiftAdMonAdMobSnippet = () => getCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_ADMOB);
const getSwiftAdMonUnitySnippet = () => getCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_UNITY);
const getSwiftAdMonApplovinSnippet = () => getCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN);
const getSwiftAdMonOtherSnippet = () => getCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_OTHER);

const getSwiftAdMonTradPlusSnippet = () => getCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS);

export {
    getSwiftAdMonAdMobSnippet,
    getSwiftAdMonApplovinSnippet,
    getSwiftAdMonUnitySnippet,
    getSwiftAdMonOtherSnippet,
    getSwiftAdMonTradPlusSnippet,
};
