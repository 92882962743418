import { gql } from '@apollo/client';

export const PARTNER = gql`
    fragment Partner on PartnerNode {
        id
        name
        displayName
        logoPath
        options
    }
`;

export const GET_PARTNERS = gql`
    query getPartners {
        partners {
            ...Partner
        }
    }
    ${PARTNER}
`;

export const CONFIGURED_PARTNER = gql`
    fragment ConfiguredPartner on ConfiguredPartnerNode {
        id
        name
        displayName
        icon
        appSitesCount
        linksCount
        lastModified
    }
`;

export const GET_CONFIGURED_PARTNERS = gql`
    query getConfiguredPartners($appFilter: String) {
        configuredPartners(appFilter: $appFilter) {
            ...ConfiguredPartner
        }
    }
    ${CONFIGURED_PARTNER}
`;

export const EVENT = gql`
    fragment Event on EventNode {
        id
        eventName
        displayName
        revenueEvent
    }
`;

export const GET_COUNTRIES = gql`
    query getCountries {
        countries {
            isoCode
            displayName
        }
    }
`;

export const GET_EVENTS = gql`
    query getEvents($siteId: ID!) {
        events(siteId: $siteId) {
            ...Event
        }
    }
    ${EVENT}
`;

export const GET_PARTNER = gql`
    query getPartner($partnerId: ID!) {
        partner(id: $partnerId) {
            id
            name
            displayName
            logoPath
            sendAllInstalls
            sendAllEvents
            viewthroughSupport
            installSupport
            reengagementSupport
            ipProbabilisticSupport
            fraudPostbackSupport
            description
            instructions
            guideLink
            postbackTemplates {
                type
                platform
                clickWindow
                installWindow
                parameters
                templateUri
            }
            parameters {
                id
                name
                type
                description
                label
                predefinedValues
                required
                regex
                displayIndex
                displayType
            }
        }
    }
`;

export const ATTRIBUTION_POSTBACK = gql`
    fragment AttributionPostback on AttributionPostbackNode {
        sendAll
        sendViewThrough
        campaignRegex
        postbackUri
        customByUser
    }
`;

export const EVENT_POSTBACK = gql`
    fragment EventPostback on EventPostbackNode {
        eventId
        eventName
        sendAll
        installWindow
        clickWindow
        parameters
        sendRevenue
        campaignRegex
        postbackUri
        customByUser
    }
`;

export const WINDOWS = gql`
    fragment Windows on WindowsNode {
        installClickWindowDeterministic
        installClickWindowProbabilistic
        installViewWindowDeterministic
        installViewWindowProbabilistic
        reengClickWindowDeterministic
        reengClickWindowProbabilistic
        reengViewWindowDeterministic
        reengViewWindowProbabilistic
        reengInactivityWindow
        ctvViewWindow
        ctvPrioritizationWindow
    }
`;

export const POSTBACKS = gql`
    fragment Postbacks on PostbacksNode {
        install {
            ...AttributionPostback
        }
        reengagement {
            ...AttributionPostback
        }
        customEvents {
            ...EventPostback
        }
        revenueEvents {
            ...EventPostback
        }
        fraudEnabled
    }
    ${ATTRIBUTION_POSTBACK}
    ${EVENT_POSTBACK}
`;

export const GET_PARTNER_CONFIGURATIONS_FOR_PARTNER = gql`
    query getPartnerConfigurationsForPartner($partnerId: Int!) {
        partnerConfigurations(partnerId: $partnerId) {
            siteId
            agencyId
            appName
            icon
            os
            longname
            lastModified
            ldsOptions {
                restrictLds
                ldsDefault
                anonymizePostbacks
            }
            agencyName
            isCustom
            postbacks {
                install {
                    sendAll
                }
                reengagement {
                    sendAll
                }
                customEvents {
                    eventName
                    sendAll
                }
                revenueEvents {
                    eventName
                    sendAll
                }
            }
            windows {
                installClickWindowDeterministic
            }
        }
    }
`;

export const GET_PARTNER_CONFIGURATION_FOR_APP = gql`
    query getPartnerConfigurationForApp($partnerId: Int!, $longname: String!, $platform: String!, $agencyId: String) {
        partnerConfiguration(partnerId: $partnerId, longname: $longname, platform: $platform, agencyId: $agencyId) {
            siteId
            agencyId
            ldsOptions {
                restrictLds
                ldsDefault
                anonymizePostbacks
            }
            isCustom
            customDescription
            parameters
            postbacks {
                ...Postbacks
            }
            geoRestrictions {
                allowedCountries
                excludedCountries
            }
            windows {
                ...Windows
            }
        }
    }
    ${POSTBACKS}
    ${WINDOWS}
`;

export const GET_ORGANIZATION_OPTIONS = gql`
    query getOrganizationOptions {
        isAdmin
        ldsSupported
        geoRestrictionsSupported
        trackerCampaignNameFilterSupported
        advancedPostbackEditingSupported
        flexiblePostbackSupported
        editAgencyConfigurationSupported
        zeroInactivityWindowSupported
        sansFlexiblePostbackReadOnlyMode
        newPartnerConfigShelf
        newTiktokPartnerConfigShelf
        newSnapchatPartnerConfigShelf
    }
`;

export const DELETE_CONFIGURATION = gql`
    mutation deleteConfiguration($partnerId: Int!, $siteId: Int!) {
        deletePartnerConfiguration(partnerId: $partnerId, siteId: $siteId) {
            ok
            error
        }
    }
`;

export const SAVE_CONFIGURATION = gql`
    mutation saveConfiguration($partnerConfiguration: SavedPartnerConfiguration!) {
        savePartnerConfiguration(partnerConfiguration: $partnerConfiguration) {
            ok
            error
        }
    }
`;

export const GET_PARTNER_APP_DEFAULT_WINDOWS = gql`
    query getPartnerAppDefaultWindows($partnerId: Int!, $longname: String!, $platform: String!) {
        partnerAppDefaultWindows(partnerId: $partnerId, longname: $longname, platform: $platform) {
            installClickWindowId
            installClickWindowIp
            installViewWindowId
            installViewWindowIp
            reengagementClickWindowId
            reengagementClickWindowIp
            reengagementViewWindowId
            reengagementViewWindowIp
            reengagementInactivityWindow
            ctvViewWindow
            ctvPrioritizationWindow
        }
    }
`;
