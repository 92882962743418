import { FIELD_SECTION, FIELDS_BY_ORDER, SECTIONS_BY_ORDER } from './consts';
import fieldVisibilityRules from '../../../selectors/reportsConfig/fieldVisibilityRules';
import { getDateDiffObject } from '../../../utils/dateUtils';
import { CUSTOMER_TIERS } from '../../../utils/consts';
import { getIsAgency, isUserAdNetwork } from '../../../selectors/user';
import { reportTypes } from '../../../utils/reports';
import { REPORTS_EVENT_PREFIX, trackMixpanelEvent } from '../../../utils/general';

const PRODUCT_TYPE_UNIFIED = 'unified';
const UNIFIED_BANNER_CLICKS_AMOUNT_KEY = 'unifiedBannerClicksAmount';
const UNIFIED_BANNER_CLOSE_AMOUNT_LIMIT = 3;
const UNIFIED_BANNER_CLOSE_DAYS_LIMIT = 7;
const UNIFIED_BANNER_USER_CREATION_DAYS_LIMIT = 30;

const isFieldSectorAllow = (sector, permissionKeys, isAdminMode) => {
    const isShowAdmin = sector.isShowAdmin && isAdminMode;
    return !sector.permissionKey || isShowAdmin || permissionKeys.includes(sector.permissionKey);
};

export const extractCheckboxesFieldsFromState = (state, reportType, checkboxes, permissionKeys, isAdminModeEnabled) => {
    return checkboxes
        .filter(checkbox => isFieldSectorAllow(checkbox, permissionKeys, isAdminModeEnabled))
        .map(checkbox => {
            const checkboxName = checkbox.field;
            checkbox.checked = !!state[reportType][checkboxName];
            return checkbox;
        });
};

export const getOrderedFields = fields => {
    return fields.sort((a, b) => {
        const aType = a.section;
        const bType = b.section;

        if (aType === bType) {
            return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1;
        }

        const aTypeIndex = FIELDS_BY_ORDER.indexOf(aType);
        const bTypeIndex = FIELDS_BY_ORDER.indexOf(bType);

        return aTypeIndex - bTypeIndex;
    });
};

export const isCohortMetricSelected = selectedFields => {
    const cohortSections = [FIELD_SECTION.COHORT_METRICS, FIELD_SECTION.EVENTS];

    const cohortField = selectedFields.find(selectedField => {
        return cohortSections.includes(selectedField.section);
    });

    return !!cohortField;
};

export const getSectionPlaceholder = section => {
    return `Select ${section}`;
};

export const getSectionIndex = (section, selectedFields) => {
    const sectionIndex = SECTIONS_BY_ORDER.indexOf(section);
    const cohortPeriodsIndex = SECTIONS_BY_ORDER.indexOf(FIELD_SECTION.COHORT_PERIODS);

    return cohortPeriodsIndex < sectionIndex && !isCohortMetricSelected(selectedFields)
        ? sectionIndex
        : sectionIndex + 1;
};

export const getSelectedFieldsBySection = selectedFields => {
    return selectedFields.reduce((total, curr) => {
        if (!isCohortMetricSelected(selectedFields) && curr.section === FIELD_SECTION.COHORT_PERIODS) {
            return { ...total };
        }

        return {
            ...total,
            [curr.section]: [...(total[curr.section] || []), curr],
        };
    }, {});
};

export const getFilteredItems = (items, searchInput) => {
    const filteredItems = {};

    Object.entries(items).forEach(([section, options]) => {
        const filteredOptions = options.filter(option => {
            return option.display_name.toLowerCase().includes(searchInput.trim().toLowerCase());
        });

        if (filteredOptions.length > 0) {
            filteredItems[section] = filteredOptions;
        }
    });

    return { ...filteredItems };
};

export const shouldShowField = (field, searchWord, reportType, adminMode, userData) => {
    const shouldDisplay = fieldVisibilityRules[reportType](field, adminMode, userData);

    if (!searchWord) {
        return shouldDisplay;
    }

    return shouldDisplay && field.display_name.toLowerCase().includes(searchWord.trim().toLowerCase());
};

const getLastWeekBannerClicksData = () => {
    return JSON.parse(window.localStorage.getItem(UNIFIED_BANNER_CLICKS_AMOUNT_KEY)) || 0;
};

const hadReachedLastWeekBannerLimit = () => {
    return getLastWeekBannerClicksData() >= UNIFIED_BANNER_CLOSE_AMOUNT_LIMIT;
};

export const insertUserBannerCloseClicks = () => {
    const amount = getLastWeekBannerClicksData();
    window.localStorage.setItem(UNIFIED_BANNER_CLICKS_AMOUNT_KEY, amount + 1);
};

// Checking rather the user is exposed to the unified banner.
// If the user is unified only, the user is only exposed if its not part of an agency or partner org
// Otherwise, the user is exposed to the unified banner regardless of the organization
const isUserOrgExposedToUnifiedBanner = user => {
    const isUserUnified =
        user.data.organization_tier in [CUSTOMER_TIERS.STANDARD_UNIFIED, CUSTOMER_TIERS.FREE] ||
        user.data.user_product === PRODUCT_TYPE_UNIFIED;
    return isUserUnified ? !(getIsAgency({ user }) || isUserAdNetwork({ user })) : true;
};

const shouldDisplayBannerForUser = (user, reportType) =>
    reportType === reportTypes.mainReport &&
    getDateDiffObject(new Date(user.data.created_at || '0'), new Date()).days <
        UNIFIED_BANNER_USER_CREATION_DAYS_LIMIT &&
    isUserOrgExposedToUnifiedBanner(user) &&
    !hadReachedLastWeekBannerLimit() &&
    user.data.can_view_unified_reports;

export const shouldDisplayBanner = (index, user, reportType) => {
    if (shouldDisplayBannerForUser(user, reportType) && index === 0) {
        trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Unified banner had been displayed', {});
        return true;
    }

    return false;
};
