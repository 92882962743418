import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Datepicker, Spinner } from '../components/widgets';
import { PageWrapper, PageHeader, DashboardGrid } from '../components/partials';
import { useGridResize, useLocalStorageState } from '../utils/customHooks';
import ResizeSensor from '../components/partials/resize_sensor';
import { READ_QUICK_REPORT } from '../customDashboards/queries';
import { getStartDate, getEndDate, getDefaultFiltersFromLocalStorage } from './utils';
import css from './QuickReports.css';
import { getEntireGridHeight } from '../components/partials/DashboardGrid/utils';
import StickyShadow from '../components/partials/StickyShadow';
import { START_DATE_LOCAL_STORAGE_KEY, END_DATE_LOCAL_STORAGE_KEY } from './filters/CampaignFilters/consts';
import { trackMixpanelEvent } from '../utils/general';
import { CAMPAIGN_REPORT_MIXPANEL_PREFIX, MIN_DATE_PICKER_DATE, SELECT_DATE_RANGE_MIXPANEL_EVENT_NAME } from './consts';
import { QUICK_REPORT_GRID_ROW_HEIGHT } from '../components/partials/DashboardGrid/consts';

const QuickReports = ({ Filters, type }) => {
    const { loading, data } = useQuery(READ_QUICK_REPORT, {
        variables: { type },
    });
    const filtersRef = useRef();
    const [startDate, setStartDate] = useLocalStorageState(START_DATE_LOCAL_STORAGE_KEY, getStartDate());
    const [endDate, setEndDate] = useLocalStorageState(END_DATE_LOCAL_STORAGE_KEY, getEndDate());
    const [filtersValues, setFiltersValues] = useState(getDefaultFiltersFromLocalStorage());
    const [width, onGridResize] = useGridResize();

    const onFiltersChange = filters => {
        setFiltersValues(filters);
    };

    const { quickReport: dashboard } = data || {};
    if (loading) {
        return (
            <div className={css.spinnerWrapper}>
                <Spinner show />
            </div>
        );
    }

    return (
        <PageWrapper>
            <ResizeSensor onResize={onGridResize} />
            <PageHeader
                text={dashboard.name}
                subText={{
                    faqLink:
                        'https://support.singular.net/hc/en-us/articles/13515655234459-Understand-The-Campaign-Report',
                }}
            />
            <div className={css.headerContainer} ref={filtersRef}>
                <Datepicker
                    containerClass={css.datePicker}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={MIN_DATE_PICKER_DATE}
                    onApply={({ start_date: newStartDate, end_date: newEndDate }) => {
                        setStartDate(newStartDate);
                        setEndDate(newEndDate);
                        trackMixpanelEvent(CAMPAIGN_REPORT_MIXPANEL_PREFIX, SELECT_DATE_RANGE_MIXPANEL_EVENT_NAME, {
                            start_date: newStartDate,
                            end_date: newEndDate,
                        });
                    }}
                />
                {!!Filters && <Filters onChange={onFiltersChange} startDate={startDate} endDate={endDate} />}
            </div>
            <StickyShadow targetRef={filtersRef} />
            <DashboardGrid
                dashboard={dashboard}
                widgets={dashboard.widgets}
                globalFilters={filtersValues}
                gridStyle={{ height: getEntireGridHeight(dashboard.widgets, QUICK_REPORT_GRID_ROW_HEIGHT) }}
                startDate={startDate}
                endDate={endDate}
                resizable={false}
                width={width}
                isQuickReportGrid
                quickReportType={type}
                rowHeight={QUICK_REPORT_GRID_ROW_HEIGHT}
            />
        </PageWrapper>
    );
};

QuickReports.propTypes = {
    Filters: PropTypes.elementType,
    type: PropTypes.string,
};

QuickReports.defaultProps = {
    Filters: null,
    type: null,
};

export default QuickReports;
