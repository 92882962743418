import React from 'react';

export default function runROIReport() {
    return (
        <div>
            <p>
                <strong>What:</strong><span> Run a report that combines campaign data (cost) and tracker data (revenue).</span>
            </p>
            <p>
                <strong>Try these sample reports:</strong>
            </p>
            <ul>
                <li>
                    <a href="https://app.singular.net/#/react/reports?query=%7B%22compare%22:false,%22is_goals%22:false,%22dimensions%22:%5B%22source%22%5D,%22metrics%22:%5B%22adn_cost%22,%22custom_installs%22%5D,%22discrepancy_metrics%22:%5B%5D,%22file_combiner_dimensions%22:%5B%5D,%22granularity_levels%22:%5B%5D,%22enrichment_dimensions%22:%5B%5D,%22source_dimensions%22:%5B%5D,%22metadata_dimensions%22:%5B%5D,%22cohort_metrics%22:%5B%22roi%22%5D,%22cohort_periods%22:%5B%223d%22%5D,%22goals_metrics%22:%5B%22goal_budget%22,%22goal_completion%22%5D,%22goals_forecast_metrics%22:%5B%22run_rate%22,%22budget_progress_forecast%22%5D,%22filters%22:%5B%5D,%22time_breakdown%22:%5B%22all%22%5D,%22source_attribution_type%22:%5B%22dynamic%22%5D,%22dual_attribution_mode%22:%5B%22original%22%5D,%22goal%22:null,%22display_unenriched%22:false,%22bookmark_id%22:%22%22,%22is_fraud%22:false,%22is_admon_report%22:false,%22pivot_table%22:%7B%7D,%22query_type%22:%22reports%22,%22post_process_filters_ui%22:%7B%7D%7D" target="_blank"><span>Day 3 ROI per Source in the last week</span></a>
                </li>
                <li><a href="https://app.singular.net/#/react/reports?query=%7B%22compare%22:false,%22is_goals%22:false,%22dimensions%22:%5B%22source%22,%22sub_campaign_id%22,%22sub_campaign_name%22%5D,%22metrics%22:%5B%22adn_cost%22,%22custom_installs%22%5D,%22discrepancy_metrics%22:%5B%5D,%22file_combiner_dimensions%22:%5B%5D,%22granularity_levels%22:%5B%5D,%22enrichment_dimensions%22:%5B%5D,%22source_dimensions%22:%5B%5D,%22metadata_dimensions%22:%5B%5D,%22cohort_metrics%22:%5B%22roi%22%5D,%22cohort_periods%22:%5B%223d%22%5D,%22goals_metrics%22:%5B%22goal_budget%22,%22goal_completion%22%5D,%22goals_forecast_metrics%22:%5B%22run_rate%22,%22budget_progress_forecast%22%5D,%22filters%22:%5B%7B%22dimension%22:%22source%22,%22operator%22:%22in%22,%22values%22:%5B%22facebook%22%5D,%22options%22:%7B%22no_lowercase%22:true%7D%7D%5D,%22time_breakdown%22:%5B%22all%22%5D,%22source_attribution_type%22:%5B%22dynamic%22%5D,%22dual_attribution_mode%22:%5B%22original%22%5D,%22goal%22:null,%22display_unenriched%22:false,%22bookmark_id%22:%22%22,%22is_fraud%22:false,%22is_admon_report%22:false,%22pivot_table%22:%7B%7D,%22query_type%22:%22reports%22,%22post_process_filters_ui%22:%7B%7D%7D" target="_blank"><span>ROI per subcampaign for Facebook</span></a>
                </li>
            </ul>
            <p>
                <strong>Why:</strong><span> Singular combines campaign data and tracker data about the same apps and campaign so that you get the full view of your marketing performance. For example, if we get cost data from your ad network and revenue data from the tracker, Singular can calculate your ROI per campaign and per app.</span>
            </p>
            <p>
                <strong>Tip:</strong><span> If you're not sure if a certain metric is based on network data or tracker data, hover over the row. Tags will appear next to each metric saying "network" or "tracker".</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/205046069-Reports-FAQ" target="_blank">Check out the Reports FAQ</a><span>.</span>
            </p>
        </div>
    );
}
