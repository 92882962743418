import update from 'immutability-helper';
import {
    UPDATE_CUSTOM_EVENTS,
    UPDATE_PUBLIC_IDS,
    UPDATE_UAN_EVENTS,
    TOGGLE_EVENT_EDITOR,
    UAN_EVENT_ADDED,
    UAN_EVENT_REMOVED,
    TOGGLE_EVENT_CALCULATED_METRIC,
    EVENT_EDITOR_CANCEL_CLICKED,
    CUSTOM_EVENTS_ADD_NEW,
    SAVE_CUSTOM_EVENT_SUCCESS,
    SAVE_CUSTOM_EVENT_FAILED,
    SAVING_CUSTOM_EVENT,
    CUSTOM_EVENT_NAME_CHANGED,
    DELETE_CUSTOM_EVENT_SUCCESS,
    EVENT_EDITOR_UNIQUE_TOGGLE,
    EVENT_EDIT_TYPE_CHANGED,
    EVENT_EDIT_APP_SPECIFIC_SELECTED,
    CUSTOM_EVENTS_PAGE_LOADED,
    CUSTOM_EVENTS_PAGE_UN_LOADED,
    CUSTOM_EVENTS_SET_ACTIVE_GROUP_TYPE,
    UPDATE_RELATED_REPORTS,
} from '../customEvents/actions';
import { getSimilarInAppEvents } from '../utils/events';
import { NEW_EVENT_ID } from '../customEvents/utils';

const initialState = {
    customEvents: [],
    customEventsLoaded: false,
    customEventsMap: {},
    uanEvents: {},
    publicIDs: {},
    calculatedMetricsOptions: [
        {
            name: 'CPE',
            value: false,
            display_name: 'Cost per Event (CPE)',
        },
        {
            name: 'ECVR',
            value: false,
            display_name: 'Event Conversion Rate (ECVR)',
        },
    ],
    backupEvent: {},
    expandedEvents: [],
    suggestions: {},
    saving: false,
    saveError: '',
    pageLoaded: false,
    editOptions: [
        {
            label: 'STATIC.PAGES.CUSTOM_EVENTS.GLOBAL_EVENTS',
            checked: true,
            id: 'all',
        },
        {
            label: 'STATIC.PAGES.CUSTOM_EVENTS.APP_SPECIFIC_EVENTS',
            checked: false,
            id: 'specific',
        },
    ],
    selectedAppId: null,
};

const newCustomEvent = {
    slot: -1,
    is_locked: false,
    name: '',
    events_by_uan: {},
    creator: '',
    calculated_metrics: [
        {
            name: 'CPE',
            value: false,
        },
        {
            name: 'ECVR',
            value: false,
        },
    ],
    last_update_ts: '',
    apps: [],
    id: NEW_EVENT_ID,
    create_ts: '',
};

update.extend('$auto', (value, object) => {
    return object ? update(object, value) : update({}, value);
});
update.extend('$autoArray', (value, object) => {
    return object ? update(object, value) : update([], value);
});

const events = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CUSTOM_EVENTS: {
            return update(state, {
                customEvents: {
                    $set: action.data.map(ce => ce.id),
                },
                customEventsLoaded: {
                    $set: true,
                },
                customEventsMap: {
                    $set: action.data.reduce((total, current) => {
                        total[current.id] = current;
                        return total;
                    }, {}),
                },
            });
        }
        case UPDATE_RELATED_REPORTS: {
            return update(state, {
                customEventsRelatedReports: {
                    $set: action.data,
                },
            });
        }
        case UPDATE_PUBLIC_IDS: {
            return update(state, {
                publicIDs: {
                    $set: action.data,
                },
            });
        }
        case UPDATE_UAN_EVENTS: {
            return update(state, {
                uanEvents: {
                    $set: action.data,
                },
            });
        }
        case CUSTOM_EVENTS_PAGE_LOADED: {
            return update(state, {
                pageLoaded: {
                    $set: true,
                },
            });
        }
        case CUSTOM_EVENTS_PAGE_UN_LOADED: {
            return update(state, {
                pageLoaded: {
                    $set: false,
                },
            });
        }
        case TOGGLE_EVENT_EDITOR: {
            let expandedEvents = [...state.expandedEvents];
            const index = expandedEvents.findIndex(e => e === action.id);
            const newCustomEvents = state.customEvents.filter(ce => {
                return ce !== newCustomEvent.id;
            });
            if (action.id) {
                if (index === -1) {
                    expandedEvents.splice(0, expandedEvents.length, action.id);
                } else {
                    expandedEvents.splice(index, 1);
                }
            } else {
                expandedEvents = [];
            }

            const toUpdate = {
                expandedEvents: {
                    $set: expandedEvents,
                },
                customEvents: {
                    $set: newCustomEvents,
                },
                backupEvent: {
                    $set: expandedEvents.length ? state.customEventsMap[action.id] : {},
                },
            };
            if (state.backupEvent && state.backupEvent.id) {
                toUpdate.customEventsMap = {
                    [state.backupEvent.id]: {
                        $set: update(state.backupEvent, {}),
                    },
                };
            }
            return update(state, toUpdate);
        }
        case UAN_EVENT_ADDED: {
            const currentEventId = action.customEvent.id;
            const currentEvent = state.customEventsMap[currentEventId];
            const currentUanId = action.uan.uan_id;
            const currentUanEvent = action.uanEvent;
            currentUanEvent.deleteEnabled = true;
            const newEventsByUan = (action.customEvent.events_by_uan[currentUanId] || []).concat(currentUanEvent);
            const newSuggestions = [];
            for (const eventByUan of newEventsByUan) {
                newSuggestions.push({
                    uanEventId: eventByUan.id,
                    suggested: getSimilarInAppEvents(eventByUan, state.uanEvents[currentUanId].events, newEventsByUan),
                });
            }

            return update(state, {
                customEventsMap: {
                    [currentEventId]: {
                        events_by_uan: {
                            [currentUanId]: {
                                $autoArray: {
                                    $push: [currentUanEvent],
                                },
                            },
                        },
                    },
                },
                suggestions: {
                    [currentEventId]: {
                        $auto: {
                            [currentUanId]: {
                                $autoArray: {
                                    $set: newSuggestions,
                                },
                            },
                        },
                    },
                },
            });
        }
        case UAN_EVENT_REMOVED: {
            const currentEventId = action.customEvent.id;
            const currentUanId = action.uan.uan_id;
            const currentUanEvent = action.uanEvent;
            const eventsByUan = state.customEventsMap[currentEventId].events_by_uan[currentUanId];

            const newEventsByUan = [...action.customEvent.events_by_uan[currentUanId]];
            newEventsByUan.splice(
                newEventsByUan.findIndex(ev => ev.id === currentUanEvent.id),
                1
            );

            const newSuggestions = [];
            for (const eventByUan of newEventsByUan) {
                newSuggestions.push({
                    uanEventId: eventByUan.id,
                    suggested: getSimilarInAppEvents(eventByUan, state.uanEvents[currentUanId].events, newEventsByUan),
                });
            }

            return update(state, {
                customEventsMap: {
                    [currentEventId]: {
                        events_by_uan: {
                            [currentUanId]: {
                                $splice: [[eventsByUan.findIndex(ev => ev.id === currentUanEvent.id), 1]],
                            },
                        },
                    },
                },
                suggestions: {
                    [currentEventId]: {
                        $auto: {
                            [currentUanId]: {
                                $autoArray: {
                                    $set: newSuggestions,
                                },
                            },
                        },
                    },
                },
            });
        }
        case TOGGLE_EVENT_CALCULATED_METRIC: {
            const optionIndex = state.customEventsMap[action.customEvent.id].calculated_metrics.findIndex(
                calculatedMetric => {
                    return calculatedMetric.name === action.calculatedMetric.name;
                }
            );
            return update(state, {
                customEventsMap: {
                    [action.customEvent.id]: {
                        calculated_metrics: {
                            $splice: [
                                [optionIndex, 1, { ...action.calculatedMetric, value: !action.calculatedMetric.value }],
                            ],
                        },
                    },
                },
            });
        }
        case EVENT_EDITOR_CANCEL_CLICKED: {
            const newCustomEvents = state.customEvents.filter(ce => {
                return ce !== newCustomEvent.id;
            });
            return update(state, {
                customEventsMap: {
                    [state.backupEvent.id]: {
                        $set: state.backupEvent,
                    },
                    [newCustomEvent.id]: {
                        $set: {},
                    },
                },
                customEvents: {
                    $set: newCustomEvents,
                },
                expandedEvents: {
                    $set: [],
                },
            });
        }
        case CUSTOM_EVENTS_ADD_NEW: {
            const newItem = update(newCustomEvent, {});

            // In case the layout is active - we want to push events to bottom of the list.
            // Otherwise, we'll push them to the top of the list.
            const { isStandardEventsLayoutActive } = action;
            let updateMethod;
            if (isStandardEventsLayoutActive) {
                updateMethod = { $push: [newItem.id] };
            } else {
                updateMethod = { $unshift: [newItem.id] };
            }
            return update(state, {
                expandedEvents: {
                    $set: [newItem.id],
                },
                customEvents: updateMethod,
                customEventsMap: {
                    $auto: {
                        [newItem.id]: {
                            $set: newItem,
                        },
                    },
                },
                saving: {
                    $set: false,
                },
                saveError: {
                    $set: '',
                },
            });
        }
        case SAVING_CUSTOM_EVENT: {
            return update(state, {
                saving: {
                    $set: true,
                },
                saveError: {
                    $set: '',
                },
            });
        }
        case SAVE_CUSTOM_EVENT_SUCCESS: {
            const { response } = action;
            let customEventsMapAction = '$merge';
            let customEvents = {
                $set: state.customEvents,
            };
            if (!state.customEventsMap[response.id]) {
                customEventsMapAction = '$set';
                customEvents = {
                    $splice: [[state.customEvents.findIndex(ce => ce === newCustomEvent.id), 1, response.id]],
                };
            }
            return update(state, {
                expandedEvents: {
                    $set: [],
                },
                saving: {
                    $set: false,
                },
                saveError: {
                    $set: '',
                },
                customEvents,
                customEventsMap: {
                    $auto: {
                        [response.id]: {
                            [customEventsMapAction]: response,
                        },
                    },
                },
                backupEvent: {
                    $set: {},
                },
            });
        }
        case SAVE_CUSTOM_EVENT_FAILED: {
            return update(state, {
                saving: {
                    $set: false,
                },
                saveError: {
                    $set: action.error,
                },
            });
        }
        case CUSTOM_EVENT_NAME_CHANGED: {
            const { customEvent, value } = action;
            return update(state, {
                customEventsMap: {
                    [customEvent.id]: {
                        name: {
                            $set: value,
                        },
                    },
                },
            });
        }
        case DELETE_CUSTOM_EVENT_SUCCESS: {
            const { customEvent } = action;
            return update(state, {
                customEvents: {
                    $splice: [[state.customEvents.findIndex(ceId => ceId === customEvent.id), 1]],
                },
            });
        }
        case EVENT_EDITOR_UNIQUE_TOGGLE: {
            const { customEvent } = action;
            return update(state, {
                customEventsMap: {
                    [customEvent.id]: {
                        is_unique: {
                            $set: !state.customEventsMap[customEvent.id].is_unique,
                        },
                    },
                },
            });
        }
        case EVENT_EDIT_TYPE_CHANGED: {
            const { selected } = action;
            const newEditOptions = state.editOptions.map(option => {
                return {
                    ...option,
                    checked: selected.id === option.id,
                };
            });
            return update(state, {
                editOptions: {
                    $set: newEditOptions,
                },
            });
        }
        case EVENT_EDIT_APP_SPECIFIC_SELECTED: {
            const { selected } = action;
            return update(state, {
                selectedAppId: {
                    $set: selected.value,
                },
            });
        }
        case CUSTOM_EVENTS_SET_ACTIVE_GROUP_TYPE: {
            const { groupType } = action;
            return update(state, {
                activeGroupType: {
                    $set: groupType,
                },
                editOptions: {
                    $set: initialState.editOptions,
                },
            });
        }
        default:
            return state;
    }
};

export default events;
