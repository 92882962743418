import { call, put, takeLatest } from 'redux-saga/effects';
import {
    AUDIT_GENERAL,
    AUDIT_SDK,
    auditGeneralId,
    auditMessage,
    auditSdkId,
    generalAuditLoading,
    SAVE_HOMEPAGE_COMPLETED_TASK,
    sdkAuditLoading,
    setCompletedTask,
    setHomepageData,
    setHomepageError,
} from './actions';
import HomepageService from './service';

const homepageService = new HomepageService();

export function* updatePageData() {
    try {
        const { data } = yield call(homepageService.getHomepageData);
        yield put(setHomepageData(data));
    } catch (error) {
        yield put(setHomepageError());
    }
}

export function* saveCompletedTask({ completedTaskName, adminMarkedAsDone = false }) {
    try {
        yield call(homepageService.saveCompletedTask, completedTaskName, adminMarkedAsDone);
        yield put(setCompletedTask(completedTaskName));
        yield call(updatePageData);
    } catch (error) {
        yield put(setHomepageError());
    }
}

function* auditSdk() {
    yield put(sdkAuditLoading(true));

    try {
        const result = yield call(homepageService.auditSdk);
        const { message, validationId } = result;

        if (message) {
            yield put(auditMessage(message));
        } else {
            yield put(auditSdkId(validationId));
        }
    } catch (error) {
        console.log(error);
    }

    yield put(sdkAuditLoading(false));
}

function* auditGeneral() {
    yield put(generalAuditLoading(true));

    try {
        const result = yield call(homepageService.auditGeneral);
        yield put(auditGeneralId(result.validationId));
    } catch (error) {
        console.log(error);
    }

    yield put(generalAuditLoading(false));
}

function* watchSaveCompletedTask() {
    yield takeLatest(SAVE_HOMEPAGE_COMPLETED_TASK, saveCompletedTask);
}

function* watchAuditSdk() {
    yield takeLatest(AUDIT_SDK, auditSdk);
}

function* watchAuditGeneral() {
    yield takeLatest(AUDIT_GENERAL, auditGeneral);
}

export const watchers = [watchSaveCompletedTask, watchAuditSdk, watchAuditGeneral];
