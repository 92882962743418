import { GeneralDimensionsConfig, GeneralDimensions } from '../../dimensionsUtils';
import { isObjectEmpty } from '../../../utils/general';

const convertToAutocompleteOption = value => ({
    label: value,
    value,
});

export const getAutocompleteOptions = options => Array.from(new Set(options)).map(convertToAutocompleteOption);

export const isEqualCreatives = (creatives, comparedCreatives) =>
    creatives.length === comparedCreatives.length &&
    creatives.every((creative, index) => creative.imageHash === comparedCreatives[index].imageHash);

export const isGeneralDimension = dimensionName => Object.values(GeneralDimensions).includes(dimensionName);

// In this function we iterate all possible general filters because in the case of them not selected
// we want to hide their indication on the creative card, and in the case they are existing with selected
// options we want to filter out the creatives which don't match
export const filterCreativesByGeneralDimensions = (creatives, selectedDimensions, dimensionToSelectedTags) =>
    Object.values(GeneralDimensions).reduce((acc, dimensionName) => {
        const filterValues =
            dimensionToSelectedTags[dimensionName] ?? (selectedDimensions.includes(dimensionName) ? [] : null);
        return GeneralDimensionsConfig[dimensionName].filterBy(acc, filterValues);
    }, creatives);

export const filterCreativesByAIDimensions = (
    creatives,
    selectedDimensions,
    dimensionToSelectedTags,
    dimensionToCreativeTags
) => {
    const dimensionsWithTags = selectedDimensions.filter(dimension => !!dimensionToCreativeTags[dimension]);
    if (!dimensionsWithTags.length) return creatives;

    const imageHashToFilteredTags = dimensionsWithTags.reduce((acc, dimensionName) => {
        const selectedTagsForDimension = dimensionToSelectedTags[dimensionName] || [];
        const creativesTagsData = dimensionToCreativeTags[dimensionName] || [];

        creativesTagsData.forEach(({ imageHash, category, tags: creativeTags }) => {
            if (!acc[imageHash]) acc[imageHash] = {};

            const filteredTags = selectedTagsForDimension.length
                ? creativeTags.filter(tag => selectedTagsForDimension.includes(tag))
                : creativeTags;

            acc[imageHash][dimensionName] = { category, tags: filteredTags };
        });

        return acc;
    }, {});
    const dimensionsWithSelectedTags = selectedDimensions.filter(dimension => !!dimensionToSelectedTags[dimension]);
    return creatives
        .map(creative => {
            const aiTags = imageHashToFilteredTags[creative.imageHash];
            const aiTagsList = Object.entries(aiTags || {}).map(([dimensionName, data]) => ({
                dimensionName,
                ...data,
            }));
            if (isObjectEmpty(dimensionToSelectedTags)) return { ...creative, aiTags: aiTagsList };

            if (!aiTags) return null;

            const excludeCreative = dimensionsWithSelectedTags.some(dimensionName => {
                const selectedTags = dimensionToSelectedTags[dimensionName] || [];
                return selectedTags.length && !aiTags[dimensionName]?.tags.length;
            });

            return excludeCreative ? null : { ...creative, aiTags: aiTagsList };
        })
        .filter(Boolean);
};
