import update from 'immutability-helper';
import * as Yup from 'yup';
import {
    LOAD,
    UNLOAD,
    APP_SELECTED,
    APP_FILTER_CHANGED,
    APP_FILTER_CHANGED_FINISHED,
    UPDATE_API_LINKS_FOR_APP,
    UPDATE_LINKS_FOR_APP,
    PARTNER_EXPAND_CLICK,
    CREATE_LINK_CLICKED,
    OPEN_SHELF,
    CLOSE_SHELF,
    CLOSE_SUBDOMAINS_SHELF,
    SHELF_GROUP_CLICKED,
    FORM_UPDATED,
    GENERATE_LINK,
    GENERATE_LINK_SUCCESS,
    GENERATE_LINK_SUCCESS_AFTER,
    SET_SHELF_ERROR,
    SOURCE_CONFIGURATION_RESULT,
    LINK_CLICKED_FINISH,
    TOP_BUTTON_CLICKED,
    CREATE_SUBDOMAIN_SUCCESS,
    CREATE_SUBDOMAIN_ERROR,
    SUB_DOMAINS_CREATE,
    FORM_FIELDS_DEFS_CHANGED,
    UPDATE_LINKS_SUMMARY,
    UPDATE_LEGACY_LINKS_SUMMARY,
    FAVOURITE_BUTTON_FINISHED,
    ARCHIVE_BUTTON_FINISHED,
    OLD_LINKS_TOGGLE_CONTINUE,
    API_LINKS_TOGGLE_CONTINUE,
    UPDATE_LINK,
    FIELD_VALUES_CHANGED,
    CATEGORY_DEFS_CHANGED,
    OPTIONAL_EXTRA_PARAMS_RESULT,
    UPDATE_LINK_DATA,
    UPDATE_PARTNERS_CONFIG_DATA,
    UPDATE_SELECTED_PARTNER_ID,
    AGENCIES_LINKS_TOGGLE,
    LINK_CLICKED,
} from './actions';
import {
    isImpersonatedByPartnerRule,
    linkTypeChosenRule,
    notOverrideWindowsRule,
    ctvDisabled,
    ctvEnabled,
    clickThroughAvailableRule,
    viewThroughLinkAvailableRule,
    reEnabledRule,
    iosWarningRule,
    notIOSWarningRule,
    androidWarningRule,
    notAndroidWarningRule,
    customSourceChosen,
    androidChecked,
    androidSiteChosenRule,
    iosChecked,
    iosSiteChosenRule,
    singlePlatformRule,
    appOnePlatformConfiguredRule,
    iosSitesNotAvailableRule,
    androidSitesNotAvailableRule,
    partnerTypeRule,
    notPartnerTypeRule,
    legacyLinkTypeChosenRule,
    notSingularLinksReadyRule,
    androidDeeplinkExistRule,
    iosDeeplinkExistRule,
    initialFieldValues,
    initialCategoryDefs,
    customLinkTypeChosen,
    multiPlatformWindowsConflictRule,
    notMultiPlatformWindowsConflictRule,
    getTotalCountFromSummary,
    notClicksSupportedPartnerRule,
    customLinkTypeOrRequiredParams,
    getRedirectUrlValidator,
    shouldReloadLinks,
    isMobileWebToAppLinkRule,
    FORBIDDEN_EXTRA_PARAMS_VALUES,
    handleLinksUpdate,
    ipProbabilisticMandatoryRule,
    ipProbabilisticSupportedRule,
    iosProbabilisticNotChecked,
    iosProbabilisticAttributionChecked,
    pcConsoleSupportedRule,
    notPCConsoleSupportedRule,
    isMobileLinkRule,
    isNonMobileLinkRule,
    nonMobileSiteChosenRule,
    nonMobileWarningRule,
    notNonMobileWarningRule,
    singularLinkSupportedRule,
    onlyOneTypeOfSites,
    PlatformTrackingTypes,
} from './utils';
import { LINK_TYPE_MOBILE_CUSTOM, LINK_TYPE_MOBILE_PARTNER, LINK_TYPE_MOBILE_WEB_TO_APP } from './linkTypes';
import {
    WarningItem,
    AutoCompleteItem,
    DropdownItem,
    CopyItem,
    CopyWithQRCodeItem,
    OSLabelItem,
    SeparatorItem,
    SliderItem,
    ToggleItem,
    LegacyItem,
    LineLoaderItem,
    LinkParamsItem,
    LabelItem,
} from './extra/fieldItems';
import { OS } from '../utils/OS';

Yup.addMethod(Yup.array, 'uniqueParameters', function(message) {
    return this.test('unique', message, function(value) {
        if (!value || !value.length) {
            return true;
        }
        const fieldNamesCount = value
            .filter(val => val && val.field && val.field.value && val.field.value !== 'default')
            .reduce((total, val) => {
                if (!total[val.field.value]) {
                    total[val.field.value] = 0;
                }
                total[val.field.value] += 1;
                return total;
            }, {});
        const isDuplicateValue = Object.values(fieldNamesCount).some(fieldCount => fieldCount > 1);
        if (isDuplicateValue) {
            return this.createError(message);
        }
        return true;
    });
});

Yup.addMethod(Yup.array, 'invalidParameters', function(message) {
    return this.test('required', message, function(value) {
        if (!value || !value.length) {
            return true;
        }

        if (
            value.filter(
                val => val && val.field && val.field.value && FORBIDDEN_EXTRA_PARAMS_VALUES.includes(val.field.value)
            ).length > 0
        ) {
            return this.createError(message);
        }
        return true;
    });
});

Yup.addMethod(Yup.array, 'requiredParameters', function(message) {
    return this.test('required', message, function(value) {
        if (!value || !value.length) {
            return true;
        }
        if (value.some(param => param.required && !param.value)) {
            return this.createError(message);
        }
        return true;
    });
});

Yup.addMethod(Yup.array, 'noSourceExtraParams', function(message) {
    return this.test('unique', message, function(value) {
        if (!value || !value.length) {
            return true;
        }
        if (value.some(param => param.isSourceExtraParam)) {
            return this.createError(message);
        }
        return true;
    });
});

const initialState = {
    loading: true,
    loadingLinks: true,
    searching: false,
    selectedAppId: -1,
    openGroups: [LINK_TYPE_MOBILE_PARTNER, LINK_TYPE_MOBILE_CUSTOM, LINK_TYPE_MOBILE_WEB_TO_APP],
    openPartners: [],
    linksPerAppId: {},
    linksMetadata: {},
    apiLinksMetadata: {},
    linksSuggestions: {},
    linksData: {},
    apiLinksPerAppId: {},
    apiLinksData: {},
    linksSummary: null,
    legacyLinksSummary: null,
    appsFilter: '',
    appsFilterResult: null,
    showOldLinks: {},
    showApiLinks: {},
    showAgenciesLinks: {},
    shelf: {
        open: false,
        headerText: '',
        working: false,
        saved: false,
        error: '',
        errorType: 'error',
        errorButtonText: '',
        errorButtonAction: '',
        actionButton: 'generate',
        readOnly: false,
        loading: false,
    },
    subDomainsShelf: {
        open: false,
        headerText: 'STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_HEADER',
        working: false,
        message: '',
        error: false,
    },
    domains: null,
    dnsZones: null,
    config: null,
    formCategoriesList: [
        'linkTypeCategory',
        'main',
        'linkSettings',
        'attributionSettings',
        'linkParamsCategory',
        'linkSummary',
    ],
    categoryFields: {
        linkTypeCategory: ['linkType', 'separator'],
        main: [
            'legacy',
            'source',
            'sourceNotReady',
            'clicksNotSupported',
            'pcConsoleNotSupported',
            'customSourceName',
            'campaignName',
            'platformTrackingType',
        ],
        linkSettings: [
            'linkSettingsLabel',
            'subDomain',
            'platformWarning',
            'singlePlatformConfiguredWarning',
            'iosLabel',
            'probabilisticAttributionToggle',
            'iosDestinationSite',
            'iosWarning',
            'iosWarningLoader',
            'iosDestinationRedirect',
            'iosDestinationDeeplink',
            'iosDestinationDDL',
            'androidLabel',
            'androidDestinationSite',
            'androidWarning',
            'androidWarningLoader',
            'androidDestinationRedirect',
            'androidDestinationDeeplink',
            'androidDestinationDDL',
            'nonMobileDestinationSite',
            'nonMobileWarning',
            'nonMobileWarningLoader',
            'nonMobileDestinationRedirect',
            'fallbackLabel',
            'destinationFallback',
        ],
        attributionSettings: [
            'attributionSettingsLabel',
            'reEnabled',
            'ipProbabilisticAttributionToggle',
            'tvMeasurementProbabilisticAttributionConflict',
            'overrideWindows',
            'multiPlatformWindowsConflict',
            'clickthroughWindow',
            'clickthroughFPWindow',
            'viewthroughWindow',
            'viewthroughFPWindow',
            'reWindow',
            'ctvWindow',
            'ctvPriorityWindow',
            // REMOVING IA_WINDOW // 'iaWindow',
        ],
        linkSummary: [
            'clickThroughDisplayLink',
            'clickThroughDisplayLinkWithQRCode',
            'showShortLink',
            'viewThroughLink',
        ],
        linkParamsCategory: ['linkParams'],
    },
    categoryDefs: initialCategoryDefs,
    fieldsDefs: {
        linkType: new DropdownItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.LINK_TYPE_LABEL',
            selector: 'linkTypeOptions',
            visible: true,
            updateOnChange: true,
            resetField: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.LINK_TYPE_TOOLTIP',
        }),
        separator: new SeparatorItem({ visible: true }),
        legacy: new LegacyItem({
            visibilityRules: [legacyLinkTypeChosenRule],
            validations: Yup.string().required('Required'),
        }),
        source: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.SOURCE_NAME_LABEL',
            selector: 'sources',
            visibilityRules: [linkTypeChosenRule],
            updateOnChange: true,
            validations: Yup.string().required('Required'),
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.AUTO_COMPLETE_PLACEHOLDER',
            mandatory: true,
            disabledRule: isMobileWebToAppLinkRule,
        }),
        sourceNotReady: new LegacyItem({
            visibilityRules: [linkTypeChosenRule, notSingularLinksReadyRule],
            validations: Yup.string().required('Required'),
            label: 'STATIC.PAGES.MANAGE_LINKS.SOURCE_NOT_READY_LABEL',
            showCustomButton: false,
        }),
        clicksNotSupported: new LegacyItem({
            visibilityRules: [linkTypeChosenRule, notClicksSupportedPartnerRule],
            label: 'STATIC.PAGES.MANAGE_LINKS.SINGULAR_LINK_NOT_SUPPORTED_PARTNER_LABEL',
            validations: Yup.mixed().when('platformTrackingType', {
                is: val => val === PlatformTrackingTypes.MOBILE,
                then: Yup.string().required('Required'),
                otherwise: Yup.mixed().notRequired(),
            }),
            showCustomButton: false,
            showPartnerButton: false,
            subLabel: '',
        }),
        pcConsoleNotSupported: new LegacyItem({
            visibilityRules: [linkTypeChosenRule, notPCConsoleSupportedRule],
            label: 'STATIC.PAGES.MANAGE_LINKS.SINGULAR_LINK_NOT_SUPPORTED_PARTNER_LABEL',
            validations: Yup.mixed().when('platformTrackingType', {
                is: val => val === PlatformTrackingTypes.PC_CONSOLE,
                then: Yup.string().required('Required'),
                otherwise: Yup.mixed().notRequired(),
            }),
            labelTooltip: '',
            showCustomButton: false,
            showPartnerButton: false,
            subLabel: '',
        }),
        customSourceName: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.CUSTOM_SOURCE_NAME_LABEL',
            visibilityRules: [linkTypeChosenRule, customSourceChosen, singularLinkSupportedRule],
            searchable: true,
            validations: Yup.string().required('Required'),
            updateOnChange: true,
            mandatory: true,
            selector: 'customSourcesNames',
        }),
        campaignName: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.TRACKER_LINK_NAME_LABEL',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule],
            searchable: true,
            validations: Yup.string().required('Required'),
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.LINK_NAME_TOOLTIP',
            updateOnChange: true,
            mandatory: true,
            disabledRule: isMobileWebToAppLinkRule,
        }),
        platformTrackingType: new DropdownItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.PLATFORM_TRACKING_TYPE_LABEL',
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.PLATFORM_TRACKING_TYPE_TOOLTIP',
            selector: 'platformTrackingTypeOptions',
            updateOnChange: true,
            mandatory: true,
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, pcConsoleSupportedRule],
            disabledRule: onlyOneTypeOfSites,
        }),
        linkSettingsLabel: new LabelItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.LINK_SETTINGS_LABEL',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule],
        }),
        subDomain: new DropdownItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.SUB_DOMAIN_LABEL',
            selector: 'domains',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule],
            fieldContainerStyle: {
                width: '320px',
                position: 'relative',
                marginBottom: 20,
            },
            validations: Yup.string().required('Required'),
            mandatory: true,
        }),
        platformWarning: new WarningItem({
            visibilityRules: [linkTypeChosenRule, singlePlatformRule, singularLinkSupportedRule, isMobileLinkRule],
            type: 'info',
            message: 'STATIC.PAGES.MANAGE_LINKS.MULTI_PLATFORM_NOT_SUPPORTED_INFO',
        }),
        singlePlatformConfiguredWarning: new WarningItem({
            visibilityRules: [
                linkTypeChosenRule,
                appOnePlatformConfiguredRule,
                singularLinkSupportedRule,
                isMobileLinkRule,
            ],
            type: 'info',
            message: 'STATIC.PAGES.MANAGE_LINKS.SINGLE_PLATFORM_CONFIGURED_INFO',
        }),
        iosLabel: new OSLabelItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.IOS_REDIRECT_DESTINATIONS_LABEL',
            icon: OS.IOS,
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, isMobileLinkRule],
            updateOnChange: true,
            disabledRule: iosSitesNotAvailableRule,
        }),
        probabilisticAttributionToggle: new OSLabelItem({
            label: 'Enable Probabilistic Attribution',
            visibilityRules: [linkTypeChosenRule, iosChecked, singularLinkSupportedRule],
            updateOnChange: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.PROBABILISTIC_ATTRIBUTION_OPTION_LABEL',
        }),
        iosDestinationSite: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.SITE_LABEL',
            selector: 'iosSites',
            visibilityRules: [linkTypeChosenRule, iosChecked, singularLinkSupportedRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.SITE_PLACEHOLDER',
            updateOnChange: true,
            mandatory: true,
        }),
        iosWarning: new WarningItem({
            selector: 'iosWarning',
            visibilityRules: [linkTypeChosenRule, iosWarningRule, iosChecked, singularLinkSupportedRule],
            validations: Yup.object({
                type: Yup.string().matches(/info|warning/),
            }).noUnknown(false),
        }),
        iosWarningLoader: new LineLoaderItem({
            visibilityRules: [
                linkTypeChosenRule,
                notIOSWarningRule,
                iosChecked,
                iosSiteChosenRule,
                partnerTypeRule,
                singularLinkSupportedRule,
            ],
            validations: Yup.string().required('Required'),
        }),
        iosDestinationRedirect: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.REDIRECT_LABEL',
            selector: 'iosRedirects',
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, iosChecked, singularLinkSupportedRule],
            validations: getRedirectUrlValidator(['itms', 'itms-apps']),
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.AUTO_COMPLETE_PLACEHOLDER',
            mandatory: true,
        }),
        iosDestinationDeeplink: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.DEEPLINK_LABEL',
            selector: 'iosDeeplinks',
            updateOnChange: true,
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, iosChecked, singularLinkSupportedRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.SITE_DEEPLINK_PLACEHOLDER',
            validations: Yup.mixed().when('linkId', {
                is: val => !!val,
                then: Yup.lazy(value => {
                    if (value === null) {
                        return Yup.mixed().notRequired();
                    }
                    if (typeof value === 'object') {
                        return Yup.object({
                            value: Yup.string().required('Required'),
                        }).noUnknown(false);
                    }
                    return Yup.string().required('Required');
                }),
                otherwise: Yup.mixed().notRequired(),
            }),
            labelTooltip: '',
        }),
        iosDestinationDDL: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.DEFERRED_DEEPLINK_LABEL',
            selector: 'iosDDLs',
            updateOnChange: true,
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, iosChecked, singularLinkSupportedRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.DDL_PLACEHOLDER',
            disabledRule: iosDeeplinkExistRule,
        }),
        androidLabel: new OSLabelItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.ANDROID_REDIRECT_DESTINATIONS_LABEL',
            icon: OS.ANDROID,
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, isMobileLinkRule],
            updateOnChange: true,
            disabledRule: androidSitesNotAvailableRule,
        }),
        androidDestinationSite: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.SITE_LABEL',
            selector: 'androidSites',
            visibilityRules: [linkTypeChosenRule, androidChecked, singularLinkSupportedRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.SITE_PLACEHOLDER',
            updateOnChange: true,
            mandatory: true,
        }),
        androidWarning: new WarningItem({
            selector: 'androidWarning',
            visibilityRules: [linkTypeChosenRule, androidWarningRule, androidChecked, singularLinkSupportedRule],
            validations: Yup.object({
                type: Yup.string().matches(/info/),
            }).noUnknown(false),
        }),
        androidWarningLoader: new LineLoaderItem({
            visibilityRules: [
                linkTypeChosenRule,
                notAndroidWarningRule,
                androidChecked,
                androidSiteChosenRule,
                partnerTypeRule,
                singularLinkSupportedRule,
            ],
            validations: Yup.string().required('Required'),
        }),
        androidDestinationRedirect: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.REDIRECT_LABEL',
            selector: 'androidRedirects',
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, androidChecked, singularLinkSupportedRule],
            validations: getRedirectUrlValidator([
                'market',
                'mimarket',
                'amzn',
                'samsungapps',
                'vivomarket',
                'palmplay',
            ]),
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.AUTO_COMPLETE_PLACEHOLDER',
            mandatory: true,
        }),
        androidDestinationDeeplink: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.DEEPLINK_LABEL',
            selector: 'androidDeeplinks',
            updateOnChange: true,
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, androidChecked, singularLinkSupportedRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.SITE_DEEPLINK_PLACEHOLDER',
            validations: Yup.mixed().when('linkId', {
                is: val => !!val,
                then: Yup.lazy(value => {
                    if (value === null) {
                        return Yup.mixed().notRequired();
                    }
                    if (typeof value === 'object') {
                        return Yup.object({
                            value: Yup.string().required('Required'),
                        }).noUnknown(false);
                    }
                    return Yup.string().required('Required');
                }),
                otherwise: Yup.mixed().notRequired(),
            }),
            labelTooltip: '',
        }),
        androidDestinationDDL: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.DEFERRED_DEEPLINK_LABEL',
            selector: 'androidDDLs',
            updateOnChange: true,
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, androidChecked, singularLinkSupportedRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.DDL_PLACEHOLDER',
            disabledRule: androidDeeplinkExistRule,
        }),
        nonMobileDestinationSite: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.SITE_LABEL',
            selector: 'nonMobileSites',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, isNonMobileLinkRule],
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.SITE_PLACEHOLDER',
            updateOnChange: true,
            mandatory: true,
        }),
        nonMobileWarning: new WarningItem({
            selector: 'nonMobileWarning',
            visibilityRules: [linkTypeChosenRule, nonMobileWarningRule, singularLinkSupportedRule, isNonMobileLinkRule],
            validations: Yup.object({
                type: Yup.string().matches(/info|warning/),
            }).noUnknown(false),
        }),
        nonMobileWarningLoader: new LineLoaderItem({
            visibilityRules: [
                linkTypeChosenRule,
                notNonMobileWarningRule,
                nonMobileSiteChosenRule,
                partnerTypeRule,
                singularLinkSupportedRule,
                isNonMobileLinkRule,
            ],
            validations: Yup.string().required('Required'),
        }),
        nonMobileDestinationRedirect: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.NON_MOBILE_REDIRECT_LABEL',
            selector: 'nonMobileRedirects',
            searchable: true,
            clearable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, isNonMobileLinkRule],
            validations: getRedirectUrlValidator(['steam']),
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.AUTO_COMPLETE_PLACEHOLDER',
            mandatory: true,
        }),
        fallbackLabel: new OSLabelItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.FALLBACK_DESTINATIONS_LABEL',
            icon: 'fallback',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, isMobileLinkRule],
            hideCheckbox: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.FALLBACK_TOOLTIP',
        }),
        destinationFallback: new AutoCompleteItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.FALLBACK_FIELD_LABEL',
            selector: 'fallbackRedirect',
            clearable: true,
            searchable: true,
            copiable: true,
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, isMobileLinkRule],
            validations: getRedirectUrlValidator(),
            placeholder: 'STATIC.PAGES.MANAGE_LINKS.FALLBACK_PLACEHOLDER',
            mandatory: true,
        }),
        template: new AutoCompleteItem({
            label: 'Choose from link settings (Optional)',
        }),
        attributionSettingsLabel: new LabelItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.ATTRIBUTION_SETTINGS_LABEL',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule],
        }),
        reEnabled: new ToggleItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.RE_ENGAGEMENT_LABEL',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule],
            updateOnChange: true,
            disabled: true,
            disabledRule: isImpersonatedByPartnerRule,
        }),
        ipProbabilisticAttributionToggle: new ToggleItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.IP_PROBABILISTIC_ATTRIBUTION_LABEL',
            visibilityRules: [ipProbabilisticSupportedRule, isMobileLinkRule],
            updateOnChange: true,
            infoMessage: 'STATIC.PAGES.MANAGE_LINKS.IP_PROBABILISTIC_INFO_CUSTOM',
            disabledRule: ipProbabilisticMandatoryRule,
        }),
        tvMeasurementProbabilisticAttributionConflict: new WarningItem({
            visibilityRules: [iosProbabilisticNotChecked, iosProbabilisticAttributionChecked, iosChecked],
            type: 'warning',
            message: 'STATIC.PAGES.MANAGE_LINKS.ENABLE_PROBABILISTIC_ATTRIBUTION_WARNING',
        }),
        overrideWindows: new ToggleItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.OVERRIDE_ATTRIBUTION_WINDOW_CUSTOM_LABEL',
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule],
            updateOnChange: true,
        }),
        multiPlatformWindowsConflict: new WarningItem({
            visibilityRules: [linkTypeChosenRule, singularLinkSupportedRule, multiPlatformWindowsConflictRule],
            type: 'warning',
            message: 'STATIC.PAGES.MANAGE_LINKS.MULTI_PLATFORM_WINDOWS_WARNING',
        }),
        clickthroughWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.CT_WINDOW_LABEL',
            visibilityRules: [linkTypeChosenRule, notMultiPlatformWindowsConflictRule, singularLinkSupportedRule],
            disabledRule: notOverrideWindowsRule,
            updateOnChange: true,
        }),
        clickthroughFPWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.CT_FINGERPRINT_WINDOW_LABEL',
            visibilityRules: [linkTypeChosenRule, notMultiPlatformWindowsConflictRule, singularLinkSupportedRule],
            max: 24,
            stepsPrefix: 'hr',
            normalizeValue: 60 * 60,
            extraSteps: [4, 8, 12],
            disabledRule: notOverrideWindowsRule,
            updateOnChange: true,
        }),
        viewthroughWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.VT_WINDOW_LABEL',
            visibilityRules: [linkTypeChosenRule, notMultiPlatformWindowsConflictRule, singularLinkSupportedRule],
            color: '#00D0B2',
            max: 24,
            stepsPrefix: 'hr',
            normalizeValue: 60 * 60,
            extraSteps: [4, 8, 12],
            disabledRule: notOverrideWindowsRule,
            updateOnChange: true,
        }),
        viewthroughFPWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.VT_FINGERPRINT_WINDOW_LABEL',
            visibilityRules: [linkTypeChosenRule, notMultiPlatformWindowsConflictRule, singularLinkSupportedRule],
            color: '#00D0B2',
            max: 24,
            stepsPrefix: 'hr',
            normalizeValue: 60 * 60,
            extraSteps: [4, 8, 12],
            disabledRule: notOverrideWindowsRule,
            updateOnChange: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.VT_FINGERPRINT_WINDOW_TOOLTIP',
        }),
        reWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.RE_WINDOW_LABEL',
            visibilityRules: [
                linkTypeChosenRule,
                reEnabledRule,
                notMultiPlatformWindowsConflictRule,
                singularLinkSupportedRule,
            ],
            color: '#b26af1',
            disabledRule: notOverrideWindowsRule,
            updateOnChange: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.DETERMINISTIC_CLICK_WINDOW_TOOLTIP',
        }),
        ctvWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.CTV_WINDOW_SLIDER_LABEL',
            visibilityRules: [ipProbabilisticSupportedRule, notMultiPlatformWindowsConflictRule, ctvEnabled],
            color: '#b26af1',
            min: 0,
            max: 72,
            stepsPrefix: 'Hours',
            normalizeValue: 60 * 60,
            extraSteps: [12, 24, 36],
            disabledRule: ctvDisabled,
            updateOnChange: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.CTV_WINDOW_SLIDER_TOOLTIP',
        }),
        ctvPriorityWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.CTV_PRIORITIZATION_WINDOW_SLIDER_LABEL',
            visibilityRules: [ipProbabilisticSupportedRule, notMultiPlatformWindowsConflictRule, ctvEnabled],
            color: '#b26af1',
            min: 0,
            max: 24,
            stepsPrefix: 'Hours',
            normalizeValue: 60 * 60,
            extraSteps: [4, 8, 12],
            disabledRule: ctvDisabled,
            updateOnChange: true,
            labelTooltip: 'STATIC.PAGES.MANAGE_LINKS.CTV_PRIORITIZATION_WINDOW_SLIDER_TOOLTIP',
        }),
        iaWindow: new SliderItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.RE_INACTIVITY_WINDOW_LABEL',
            visibilityRules: [
                linkTypeChosenRule,
                reEnabledRule,
                notMultiPlatformWindowsConflictRule,
                singularLinkSupportedRule,
            ],
            color: '#b26af1',
            disabledRule: notOverrideWindowsRule,
            updateOnChange: true,
        }),
        clickThroughDisplayLink: new CopyItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.TRACKING_LINK_CLICK_LABEL',
            visibilityRules: [
                linkTypeChosenRule,
                clickThroughAvailableRule,
                singularLinkSupportedRule,
                partnerTypeRule,
            ],
        }),
        clickThroughDisplayLinkWithQRCode: new CopyWithQRCodeItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.TRACKING_LINK_CLICK_LABEL',
            qrCodeLabel: 'STATIC.PAGES.MANAGE_LINKS.TRACKING_LINK_QR_CODE',
            qrCodeDownloadPNG: 'STATIC.PAGES.MANAGE_LINKS.DOWNLOAD_PNG',
            qrCodeDownloadSVG: 'STATIC.PAGES.MANAGE_LINKS.DOWNLOAD_SVG',
            visibilityRules: [
                linkTypeChosenRule,
                clickThroughAvailableRule,
                singularLinkSupportedRule,
                notPartnerTypeRule,
            ],
        }),
        viewThroughLink: new CopyItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.TRACKING_LINK_VIEW_LABEL',
            containerStyle: { marginTop: 15 },
            visibilityRules: [linkTypeChosenRule, viewThroughLinkAvailableRule, singularLinkSupportedRule],
        }),
        showShortLink: new ToggleItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.SHOW_SHORT_LINK_LABEL',
            visibilityRules: [
                linkTypeChosenRule,
                customLinkTypeChosen,
                clickThroughAvailableRule,
                singularLinkSupportedRule,
            ],
            updateOnChange: true,
            disabled: true,
            infoMessage: 'STATIC.PAGES.MANAGE_LINKS.SHOW_SHORT_LINK_INFO',
        }),
        linkParams: new LinkParamsItem({
            label: 'STATIC.PAGES.MANAGE_LINKS.LINK_PARAMETERS_LABEL',
            visibilityRules: [linkTypeChosenRule, customLinkTypeOrRequiredParams, singularLinkSupportedRule],
            updateOnChange: true,
            selector: 'optionalExtraParams',
            validations: Yup.array()
                .uniqueParameters('non unique')
                .invalidParameters('invalid parameters')
                .concat(Yup.array().requiredParameters('required'))
                .concat(Yup.array().noSourceExtraParams('source extra param')),
        }),
    },
    fieldValues: {},
    partnersConfig: null,
    selectedPartnerId: null,
};

const oldLinksInitialState = {
    categoryFields: {
        linkTypeCategory: ['linkType', 'separator'],
        main: ['source', 'customSourceName', 'campaignName'],
        linkSettings: [
            'iosLabel',
            'probabilisticAttributionToggle',
            'iosDestinationSite',
            'iosWarning',
            'iosDestinationRedirect',
            'iosDestinationDDL',
            'iosDestinationDeeplink',
            'androidLabel',
            'androidDestinationSite',
            'androidWarning',
            'androidDestinationRedirect',
            'androidDestinationDDL',
            'androidDestinationDeeplink',
            'nonMobileDestinationSite',
            'nonMobileWarning',
            'nonMobileDestinationRedirect',
            'fallbackLabel',
            'destinationFallback',
        ],
        attributionSettings: ['reEnabled'],
        linkSummary: ['clickThroughDisplayLink', 'clickThroughDisplayLinkWithQRCode', 'viewThroughLink'],
        linkParamsCategory: [],
    },
};

const DISABLED_FIELDS = [
    'linkType',
    'source',
    'customSourceName',
    'platformTrackingType',
    'probabilisticAttributionToggle',
    'iosDestinationSite',
    'androidDestinationSite',
    'nonMobileDestinationSite',
    'subDomain',
    'iosLabel',
    'androidLabel',
];

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD: {
            return update(state, {
                loading: { $set: false },
                domains: { $set: action.domains },
                dnsZones: { $set: action.dnsZones },
                domainsLimit: { $set: action.domainsLimit },
                config: { $set: action.config },
                appsFilter: { $set: action.appsFilter },
            });
        }
        case UPDATE_LINKS_SUMMARY: {
            return update(state, {
                linksSummary: {
                    $set: action.data,
                },
            });
        }
        case UPDATE_LEGACY_LINKS_SUMMARY: {
            let { showOldLinks } = state;
            const {
                config: { old_links_state: oldLinksState = {} },
            } = state;
            const { data } = action;
            const totalLinks = getTotalCountFromSummary(data);
            if (totalLinks > 0) {
                showOldLinks = Object.keys(data).reduce((total, externalUUID) => {
                    total[externalUUID] = true;
                    return total;
                }, {});
                showOldLinks = {
                    ...showOldLinks,
                    ...oldLinksState,
                };
            }
            return update(state, {
                legacyLinksSummary: {
                    $set: data,
                },
                showOldLinks: {
                    $set: showOldLinks,
                },
            });
        }
        case UNLOAD:
            return initialState;
        case APP_SELECTED: {
            const { linksMetadata, appsFilter, linksPerAppId } = state;
            const { appId, forceReload = false } = action;
            const loadingLinks = forceReload || shouldReloadLinks(linksPerAppId, linksMetadata, appId, appsFilter);
            return update(state, {
                selectedAppId: { $set: appId },
                loadingLinks: { $set: loadingLinks },
                openGroups: { $set: initialState.openGroups },
                openPartners: { $set: initialState.openPartners },
            });
        }
        case APP_FILTER_CHANGED: {
            return update(state, {
                appsFilter: { $set: action.value },
                searching: { $set: true },
                openGroups: { $set: initialState.openGroups },
                openPartners: { $set: initialState.openPartners },
                appsFilterResult: { $set: initialState.appsFilterResult },
            });
        }
        case APP_FILTER_CHANGED_FINISHED: {
            return update(state, {
                searching: { $set: false },
            });
        }
        case UPDATE_API_LINKS_FOR_APP: {
            const { appId, apps, links } = action;
            const processedLinks = handleLinksUpdate(
                appId,
                apps,
                links,
                state.apiLinksPerAppId,
                state.apiLinksMetadata,
                state.appsFilter
            );
            const stateUpdate = {
                apiLinksPerAppId: processedLinks.linksPerAppId,
                apiLinksData: {
                    $merge: processedLinks.totalLinksData,
                },
                apiLinksMetadata: {
                    $merge: processedLinks.linksMetadata,
                },
            };
            return update(state, stateUpdate);
        }
        case UPDATE_LINKS_FOR_APP: {
            // In this reducer we update the links details that returned from the BE
            const { suggestions = state.linksSuggestions, appId, apps, links } = action;
            const processedLinks = handleLinksUpdate(
                appId,
                apps,
                links,
                state.linksPerAppId,
                state.linksMetadata,
                state.appsFilter
            );

            const stateUpdate = {
                linksPerAppId: processedLinks.linksPerAppId,
                linksData: {
                    $merge: processedLinks.totalLinksData,
                },
                linksMetadata: {
                    $merge: processedLinks.linksMetadata,
                },
                linksSuggestions: {
                    $set: suggestions,
                },
                loadingLinks: {
                    $set: false,
                },
                appsFilterResult: {
                    $set: apps || state.appsFilterResult,
                },
            };

            return update(state, stateUpdate);
        }
        case PARTNER_EXPAND_CLICK: {
            const { partnerId } = action;
            let updateFunc;
            const index = state.openPartners.findIndex(pId => pId === partnerId);
            if (index === -1) {
                updateFunc = {
                    $push: [partnerId],
                };
            } else {
                updateFunc = {
                    $splice: [[index, 1]],
                };
            }

            return update(state, {
                openPartners: updateFunc,
            });
        }
        case CREATE_LINK_CLICKED: {
            Object.keys(state.fieldsDefs).forEach(fieldName => {
                state.fieldsDefs[fieldName].disabled = false;
            });

            return update(state, {
                shelf: {
                    open: { $set: true },
                    headerText: { $set: 'Create Link' },
                },
                fieldValues: {
                    $set: initialFieldValues,
                },
            });
        }
        case LINK_CLICKED: {
            const { isDuplicate, readOnly } = action;
            const headerText = readOnly ? 'View Link' : isDuplicate ? 'Duplicate Link' : 'Edit Link';
            const actionButton = readOnly ? 'done' : isDuplicate ? 'generate' : 'update';
            return update(state, {
                shelf: {
                    open: { $set: true },
                    loading: { $set: true },
                    headerText: { $set: headerText },
                    actionButton: { $set: actionButton },
                    readOnly: { $set: readOnly },
                },
            });
        }
        case LINK_CLICKED_FINISH: {
            const { oldLink, formValues, readOnly } = action;
            const updateObj = {
                shelf: {
                    loading: { $set: false },
                },
            };
            let disabledFields = [...DISABLED_FIELDS];
            if (oldLink || readOnly) {
                disabledFields = Object.keys(state.fieldsDefs);
                if (oldLink) {
                    updateObj.categoryFields = {
                        $set: oldLinksInitialState.categoryFields,
                    };
                }
            }
            if (!formValues.androidDestinationDeeplink) {
                disabledFields.push('androidDestinationDeeplink');
            }
            if (!formValues.iosDestinationDeeplink) {
                disabledFields.push('iosDestinationDeeplink');
            }

            Object.keys(state.fieldsDefs).forEach(fieldName => {
                state.fieldsDefs[fieldName].disabled = disabledFields.includes(fieldName);
                if (state.fieldsDefs[fieldName].placeholder) {
                    state.fieldsDefs[fieldName].placholder = initialState.fieldsDefs[fieldName].placeholder;
                }
            });
            return update(state, updateObj);
        }
        case OPEN_SHELF: {
            const { headerText = 'Create Link', actionButton = 'generate' } = action;
            return update(state, {
                shelf: {
                    open: { $set: true },
                    headerText: { $set: headerText },
                    actionButton: { $set: actionButton },
                    readOnly: { $set: false },
                },
            });
        }
        case CLOSE_SHELF: {
            return update(state, {
                shelf: { $set: initialState.shelf },
                fieldsValues: { $set: initialState.fieldValues },
                categoryDefs: { $set: initialState.categoryDefs },
                categoryFields: { $set: initialState.categoryFields },
            });
        }
        case CLOSE_SUBDOMAINS_SHELF: {
            return update(state, {
                subDomainsShelf: { $set: initialState.subDomainsShelf },
            });
        }
        case SHELF_GROUP_CLICKED: {
            const { formValues, groupName } = action;
            return update(state, {
                categoryDefs: {
                    [groupName]: {
                        collapsed: {
                            $set: !state.categoryDefs[groupName].collapsed,
                        },
                    },
                },
                fieldValues: {
                    $set: formValues || state.fieldValues,
                },
            });
        }
        case FORM_UPDATED: {
            return update(state, {
                fieldValues: {
                    $set: action.values,
                },
            });
        }
        case UPDATE_LINK:
        case GENERATE_LINK: {
            return update(state, {
                shelf: {
                    working: {
                        $set: true,
                    },
                    error: {
                        $set: initialState.shelf.error,
                    },
                    errorButtonText: {
                        $set: initialState.shelf.errorButtonText,
                    },
                    errorButtonAction: {
                        $set: initialState.shelf.errorButtonAction,
                    },
                },
                fieldValues: {
                    $set: action.formValues,
                },
            });
        }
        case GENERATE_LINK_SUCCESS: {
            let { data } = action;
            if (action.data.existing_tags) {
                data = action.data.existing_tags;
            }
            const { clickthrough_url: clickThroughLink, viewthrough_url: viewThroughLink, link_id: linkId } = data;

            const disabledFields = [...DISABLED_FIELDS];
            if (!state.fieldValues.androidDestinationDeeplink) {
                disabledFields.push('androidDestinationDeeplink');
            }
            if (!state.fieldValues.iosDestinationDeeplink) {
                disabledFields.push('iosDestinationDeeplink');
            }
            Object.keys(state.fieldsDefs).forEach(fieldName => {
                state.fieldsDefs[fieldName].disabled =
                    disabledFields.includes(fieldName) || state.fieldsDefs[fieldName].disabled;
            });

            return update(state, {
                shelf: {
                    working: {
                        $set: false,
                    },
                    saved: {
                        $set: true,
                    },
                },
                fieldValues: {
                    clickThroughLink: {
                        $set: clickThroughLink || state.fieldValues.clickThroughLink,
                    },
                    viewThroughLink: {
                        $set: viewThroughLink || state.fieldValues.viewThroughLink,
                    },
                    linkId: {
                        $set: linkId || state.fieldValues.linkId,
                    },
                },
            });
        }
        case GENERATE_LINK_SUCCESS_AFTER: {
            return update(state, {
                shelf: {
                    working: {
                        $set: false,
                    },
                    saved: {
                        $set: false,
                    },
                    actionButton: {
                        $set: action.actionButton,
                    },
                },
            });
        }
        case SET_SHELF_ERROR: {
            return update(state, {
                shelf: {
                    working: {
                        $set: false,
                    },
                    loading: {
                        $set: false,
                    },
                    error: {
                        $set: action.err,
                    },
                    errorType: {
                        $set: action.errorType,
                    },
                    errorButtonText: {
                        $set: action.errorButtonText || state.shelf.errorButtonText,
                    },
                    errorButtonAction: {
                        $set: action.errorButtonAction || state.shelf.errorButtonAction,
                    },
                },
            });
        }
        case SOURCE_CONFIGURATION_RESULT: {
            const { result, newLink = true } = action;
            const reEnabled = result.reengagementSupport !== 'never';
            state.fieldsDefs.reEnabled.disabled = result.reengagementSupport !== 'optional';

            return update(state, {
                fieldValues: {
                    reEnabled: {
                        $set: newLink ? reEnabled : state.fieldValues.reEnabled,
                    },
                },
            });
        }
        case OPTIONAL_EXTRA_PARAMS_RESULT: {
            const { result } = action;
            return update(state, {
                optionalExtraParams: {
                    $set: result,
                },
            });
        }
        case TOP_BUTTON_CLICKED: {
            const { buttonId } = action;
            if (buttonId === 'subdomains') {
                return update(state, {
                    subDomainsShelf: {
                        open: {
                            $set: true,
                        },
                    },
                });
            }
            return state;
        }
        case SUB_DOMAINS_CREATE: {
            return update(state, {
                subDomainsShelf: {
                    working: {
                        $set: true,
                    },
                    message: {
                        $set: '',
                    },
                    error: {
                        $set: false,
                    },
                },
            });
        }
        case CREATE_SUBDOMAIN_SUCCESS: {
            return update(state, {
                domains: { $set: action.domains },
                dnsZones: { $set: action.dnsZones },
                subDomainsShelf: {
                    working: {
                        $set: false,
                    },
                    message: {
                        $set: action.message,
                    },
                    error: {
                        $set: false,
                    },
                },
            });
        }
        case CREATE_SUBDOMAIN_ERROR: {
            return update(state, {
                subDomainsShelf: {
                    working: {
                        $set: false,
                    },
                    error: {
                        $set: true,
                    },
                    message: {
                        $set: action.message,
                    },
                },
            });
        }
        case FORM_FIELDS_DEFS_CHANGED: {
            const { fields } = action;
            const newFieldsDef = { ...state.fieldsDefs };
            for (const field of fields) {
                field.keys.forEach(({ key, value }) => {
                    newFieldsDef[field.name][key] = value;
                });
            }

            return update(state, {
                fieldsDefs: { $set: newFieldsDef },
            });
        }
        case FAVOURITE_BUTTON_FINISHED: {
            const { dns_zone, subdomain, favourite } = action.domain;
            const currentIndex = state.domains.findIndex(
                domain => domain.dns_zone === dns_zone && domain.subdomain === subdomain
            );
            if (currentIndex !== -1) {
                const newDomains = state.domains.map((domain, i) => {
                    return {
                        ...domain,
                        favourite: i === currentIndex ? favourite : false,
                    };
                });
                return update(state, {
                    domains: {
                        $set: newDomains,
                    },
                });
            }
            return state;
        }
        case ARCHIVE_BUTTON_FINISHED: {
            const { dns_zone, subdomain, archived } = action.domain;
            const currentIndex = state.domains.findIndex(
                domain => domain.dns_zone === dns_zone && domain.subdomain === subdomain
            );
            if (currentIndex !== -1 && archived) {
                return update(state, {
                    domains: {
                        $splice: [[currentIndex, 1]],
                    },
                });
            }
            return state;
        }
        case OLD_LINKS_TOGGLE_CONTINUE: {
            const { externalUUID } = action;
            const { showOldLinks } = state;
            const currentState = showOldLinks[externalUUID] || false;
            return update(state, {
                showOldLinks: {
                    [externalUUID]: {
                        $set: !currentState,
                    },
                },
                loadingLinks: {
                    $set: true,
                },
            });
        }
        case API_LINKS_TOGGLE_CONTINUE: {
            const { externalUUID } = action;
            const { showApiLinks } = state;
            const currentState = showApiLinks[externalUUID] || false;
            return update(state, {
                showApiLinks: {
                    [externalUUID]: {
                        $set: !currentState,
                    },
                },
            });
        }
        case AGENCIES_LINKS_TOGGLE: {
            const { showAgenciesLinks, selectedAppId } = state;
            const currentState = showAgenciesLinks[selectedAppId] || false;
            return update(state, {
                showAgenciesLinks: {
                    [selectedAppId]: {
                        $set: !currentState,
                    },
                },
            });
        }
        case FIELD_VALUES_CHANGED: {
            const { fieldValues, resetForm = false } = action;
            let { categoryDefs } = state;
            if (resetForm) {
                categoryDefs = initialCategoryDefs;
                Object.keys(state.fieldsDefs).forEach(fieldName => {
                    state.fieldsDefs[fieldName].disabled = false;
                });
            }
            return update(state, {
                fieldValues: {
                    $set: {
                        ...state.fieldValues,
                        ...fieldValues,
                    },
                },
                categoryDefs: {
                    $set: categoryDefs,
                },
            });
        }
        case CATEGORY_DEFS_CHANGED: {
            const { categoryDefs } = action;
            return update(state, {
                categoryDefs: {
                    $set: categoryDefs,
                },
            });
        }
        case UPDATE_LINK_DATA: {
            const { linkId, data } = action;
            const { linksData } = state;
            if (!linksData[linkId]) {
                return state;
            }
            return update(state, {
                linksData: {
                    [linkId]: {
                        $merge: data,
                    },
                },
            });
        }
        case UPDATE_PARTNERS_CONFIG_DATA: {
            return update(state, {
                partnersConfig: {
                    $set: action.partnersConfig,
                },
            });
        }
        case UPDATE_SELECTED_PARTNER_ID: {
            return update(state, {
                selectedPartnerId: {
                    $set: action.partnerId,
                },
            });
        }
        default:
            return state;
    }
};
