import React from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../widgets';
import css from './SearchArea.css';
import SearchIcon from '../../../resources/icons/search.svg';

function SearchArea({ searchingText, onChange, placeholder }) {
    return (
        <div className={css.searchContainer}>
            <AutoCompleteField
                containerClass={css.search}
                onInputChange={onChange}
                onChange={onChange}
                value={searchingText}
                placeholder={placeholder}
                searchable
                clearable={!!searchingText}
                controlled
                loading={false}
                isMulti={false}
                debounceTime={500}
                options={[]}
            />
            <div className={css.searchIconSector}>
                <SearchIcon className={css.searchIcon} />
            </div>
        </div>
    );
}

SearchArea.propTypes = {
    searchingText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

SearchArea.defaultProps = {
    searchingText: '',
    placeholder: 'STATIC.PAGES.BANNERS.APP_SEARCH_PLACEHOLDER',
};

export default SearchArea;
