import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chevron from '../../resources/icons/chevron.svg';
import css from './ExpandButton.css';

const ExpandButton = forwardRef(
    ({ containerClass, containerStyle, Icon, text, hide, onClick, isClicked, isExpanded: isOpen, disabled }, ref) => {
        const [isExpanded, setIsExpanded] = useState(isOpen);

        useEffect(() => {
            setIsExpanded(isOpen);
        }, [isOpen]);

        const onExpandClicked = () => {
            setIsExpanded(prev => prev);
            onClick(!isExpanded);
        };

        if (hide) return null;

        return (
            <div
                ref={ref}
                className={classNames(css.container, containerClass, {
                    [css.clicked]: isExpanded || isClicked,
                    [css.disabled]: disabled,
                })}
                style={containerStyle}
                onClick={onExpandClicked}
            >
                <div className={css.textContainer}>
                    {Icon && <Icon className={css.icon} />}
                    <span>{text}</span>
                </div>
                <div className={css.expandContainer}>
                    <Chevron className={classNames(css.icon, { [css.top]: isExpanded })} />
                </div>
            </div>
        );
    }
);

ExpandButton.propTypes = {
    containerClass: PropTypes.string,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hide: PropTypes.bool,
    onClick: PropTypes.func,
    isClicked: PropTypes.bool,
    isExpanded: PropTypes.bool,
    disabled: PropTypes.bool,
};

ExpandButton.defaultProps = {
    containerStyle: {},
    containerClass: '',
    Icon: null,
    text: '',
    hide: false,
    onClick: () => {},
    isClicked: false,
    isExpanded: false,
    disabled: false,
};

export default ExpandButton;
