import API from '../services/api';

let instance = null;
const api = new API();
const ACTIVATE_ACCOUNT_URL = '/signup/mail_activation';

export default class LoadingPageService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    activateAccount(uuid) {
        console.log('Activate Account!');
        return api.getJson(ACTIVATE_ACCOUNT_URL, {
            method: 'GET',
            legacyStatus: true,
            credentials: 'include',
            params: { id: uuid },
        });
    }
}
