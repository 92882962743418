window.languages = window.languages || {};

var cn = {
    "API": "API",
    "Ad Monetization": "广告收益报告",
    "Alerts": "提醒",
    "Analytics": "数据分析",
    "Apps": "配置应用",
    "Attribution": "归因配置",
    "Audiences": "用户群组",
    "Automation": "自动化",
    "Benchmarks": "行业基准参照",
    "CPI Configuration": "配置CPI",
    "Create Web Link": "生成网页链接",
    "Creative Tags": "配置素材标签",
    "Creatives": "素材报告",
    "Custom Dimensions": "自定义维度",
    "Custom Events": "自定义事件",
    "Custom Rules": "自定义过滤规则",
    "Dashboard": "综合报告",
    "Data Connectors": "连接渠道数据",
    "Distributions": "推送用户群组",
    "Export Logs": "下载原始数据",
    "Fraud Prevention": "假量识别与过滤",
    "Global Settings": "系统反假量规则",
    "Goals": "目标",
    "Manage Links": "管理链接",
    "Partner Configuration": "配置渠道",
    "Partner Fees": "配置代理商费率",
    "Pivot": "数据透视报表",
    "Protected Report": "可信流量报告",
    "Rejcted Report": "屏蔽流量报告",
    "Reports": "自定义报告",
    "Retention": "留存报告",
    "SDK Console": "SDK测试控制台",
    "SDK Keys": "SDK密钥",
    "STATIC": {
        "APP": "应用",
        "POPOVERS": {
            "FIRST_APP_ADDED": {
                "CONGRATS": "步骤 1 完成！继续...",
                "MESSAGE": "下一步：将 Singular SDK 集成到您的产品中。\n还没准备好吗？可以选择先从数据连接器开始",
                "BUTTON": "集成SDK",
                "ALTERNATIVE_BUTTON": "添加数据连接器"
            },
            "ADD_MORE_PLATFORM": {
                "CONGRATS": "开局不错！这个应用应该还有其他平台版本吧...",
                "MESSAGE": "您已成功添加第一个应用 \n该应用还有其他操作系统版本吗（例如 iOS、Android、Web）？",
                "ADD_ANOTHER_PLATFORM_BUTTON": "添加另一个平台",
                "ONLY_ONE_PLATFORM_BUTTON": "我只有一个平台"
            }
        },
        "BUTTONS": {
            "ADD": "添加",
            "ADD_NEW_DIMENSIONS": "添加新自定义维度",
            "APPLY": "应用",
            "BACK": "返回",
            "BOOKMARK": "书签",
            "CANCEL": "取消",
            "CLEAR_SELECTION": "初始化选项",
            "COMPARE_TWO_PERIODS": "对比两个期间",
            "CONTINUE": "继续",
            "COPIED": "已复制",
            "COPY": "复制",
            "CREATE_GOAL": "创建目标",
            "TEAM_USERS_CREATE_NEW_MEMBER": "创建新用户",
            "DATEPICKER": {
                "CUSTOM": "自定义",
                "FROM": "从",
                "LAST_30_DAYS": "过去30天",
                "LAST_7_DAYS": "过去7天",
                "LAST_MONTH": "上个月",
                "PREVIOUS_PERIOD": "前一个期间",
                "SAME_PERIOD_LAST_YEAR": "去年同期",
                "THIS_MONTH": "这个月",
                "THIS_QUARTER": "这个季度",
                "TO": "到",
                "TODAY": "今天",
                "YESTERDAY": "昨天"
            },
            "DEACTIVATE_TWO_FACTOR_AUTH": "撤销双重认证",
            "DELETE": "删除",
            "DELETE_CELL": "删除单元",
            "DISABLE": "撤销",
            "EDIT_REPORT": "编辑报表",
            "EDIT_TAGS": "编辑标签",
            "EXPORT": "导出报告",
            "EXPORT_CSV": "导出CSV样本文件",
            "EXPORT_JSON": "导出JSON文件",
            "EXPORT_RAW_CSV": "原始数据",
            "GENERATE": "生成",
            "GOAL_REPORT_TAB": "目标",
            "HIDE_CHART": "隐藏曲线图",
            "HIDE_LEGEND": "隐藏图例",
            "IMAGE": "图片",
            "IMAGE_AND_TEXT": "图片与文字",
            "LEGEND_TOGGLE": "图例",
            "NETWORK": "媒体网络",
            "NEW_ALERT": "新提醒",
            "NEW_CUSTOM_DIMENSION": "新自定义维度",
            "NEW_CUSTOM_EVENT": "新自定义事件",
            "NEW_CUSTOM_METRIC": "新自定义指标",
            "NEW_GOAL": "新目标",
            "NEXT": "下一步",
            "PRESS_GENERATE": "点击生成",
            "REGULAR_REPORT_TAB": "报表",
            "REMOVE": "移除对比",
            "RUN": "运行报告",
            "SAVE": "保存",
            "SAVE_AND_ADD_ANOTHER": "保存并添加另一个",
            "SETUP_TWO_FACTOR_AUTH": "设置双重认证",
            "SHARE": "分享报告",
            "SHOW_CHART": "显示曲线图",
            "SHOW_LEGEND": "显示图例",
            "SUBMIT": "提交",
            "TEXT": "文字",
            "TRACKER": "归因工具",
            "UNENRICHED": "未匹配"
        },
        "CAMPAIGN": "广告系列",
        "CLUSTERING": "群聚",
        "COHORT_METRICS": "群组指标",
        "COHORT_PERIOD": "群组期间",
        "COHORT_PERIODS": "群组期间",
        "CUSTOM_DIMENSIONS": "自定义维度",
        "CUSTOM_EVENTS": "自定义事件",
        "DESTINATION": "链接落地页",
        "DIMENSIONS": "维度",
        "FILTERS": "过滤选项",
        "GENERAL_ERROR_DIALOG_TEXT": "出错了...请刷新页面重新尝试",
        "GENERAL_ERROR_DIALOG_TITLE": "出错了...请刷新页面重新尝试",
        "GOAL_FORECAST": "目标完成度预测",
        "GOAL_METRICS": "目标指标",
        "LINK_EXPIRATION_IN_DAYS": "设置链接有效期",
        "LINK_URL": "链接URL",
        "MANAGE_TEAM": "用户授权和管理",
        "METADATA_DIMENSIONS": "投放竞价指标",
        "METRICS": "指标",
        "SOURCE": "伙伴",
        "PLATFORM": "平台",
        "DAU_METRICS": "User Activity Metrics",
        "MISSING_DASHBOARD_DATA": "所选取的指标下没有可以匹配的数据",
        "MODAL_HEADERS": {
            "ADD_SOURCE": "添加媒体渠道",
            "CHANGE_PASSWORD": "修改密码"
        },
        "GO_BACK_PANEL": {
            "GO_BACK_TO_ONBOARDING_GUIDE": "点击回到主界面",
        },
        "PAGES": {
            "ALERTS": {
                "LEARN_MORE": "<a href=\"https://singular.zendesk.com/hc/en-us/articles/207429293-How-to-Use-Alerts-Part-1-Overview-FAQs\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">点击了解更多</a>",
                "NEW_ALERT": "新提醒",
                "NOTE": "注意：当渠道数据符合要求的时候才会触发提醒",
                "NO_ALERTS": "尚未创建新提醒",
                "P1": "创建提醒，当各项指标的变动满足预设条件时可以收到邮件提醒，以进行优化操作"
            },
            "SKADNETWORK": {
                "SKAN_PLUS_CHIP_TOOLTIP": {
                    "OPTIMIZED_MODEL": 'SKAN+ 模型推荐已应用',
                    "UNOPTIMIZED_MODEL": 'SKAN+ 模型推荐可用'
                },
            },
            "ANONYMOUS": {
                "ERROR_EXPIRED": "该报表已过期，请联系账户负责人重新共享报表",
                "ERROR_INVALID": "无效报表",
                "ERROR_INVALID_EMAIL": "输入的密码错误",
                "MODAL_LABEL": "密码",
                "MODAL_PLACEHOLDER": "请输入密码",
                "MODAL_TITLE": "查看共享报告"
            },
            "SHARED_REPORTS": {
                "SHELF_TITLE": "已分享的报告",
                "NOTE": "持有链接的人可以查看当前报表上的所有数据，建议在分享链接前确认报表上是否有需要保密的数据。" +
                    "<br/> Learn more in the <a href=\"https://support.singular.net/hc/en-us/articles/360046877232\" style=\"font-weight: 400 \" target=\"_blank\">Shared Reports FAQ</a>.",
                "GENERATE_REPORT_SECTION_HEADER": "生成共享报告链接",
                "REPORT_NAME": "报告名称:",
                "REPORT_NAME_HINT": "输入一个名称",
                "PASSWORD": "为分享链接设置密码（推荐）:",
                "PASSWORD_HINT": "输入密码",
                "DATES": "指定的期间:",
                "SPECIFY_DATE_RANGE_FALSE": "只显示指定期间的数据",
                "SPECIFY_DATE_RANGE_TRUE": "允许收到共享链接的人调整报告期间",
                "LINK_EXPIRATION": "设置链接有效期：",
                "GENERATED_LINKS": "已生成的链接 ",
                "NO_EXISTING_REPORTS": "尚未生成任何分享报告",
                "EMPTY_STATE": "尚未创建共享报告。请先在后台运行一个报告，然后进行分享。",
                "EXPIRES": "链接有效至",
                "DELETE_REPORT": "删除链接",
                "OPEN_REPORT_IN_SINGULAR": "通过Singular后台运行该报告",
                "COPY_BUTTON": "复制链接地址",
                "CLOSE": "关闭",
                "LINK_UNIT": "天",
                "GENERATE_LINK_BUTTON": "为当前报表生成链接",
            },
            "API": {
                "REPORTING_API_KEY": {
                    "DESC1": "当使用API接口时，需要用到以下API密钥。</br>请勿公示改密钥，以避免信息泄漏！",
                    "DESC2": "<strong>请注意：</strong> 该密钥不是对接Singular SDK时用到的密钥。",
                    "DESC3": "详情请参考 <a href=\"https://developers.singular.net/reference#reporting\" target=\"_blank\">API文档</a>",
                    "KEY": "密钥",
                    "TITLE": "报表数据API接口密钥"
                },
                "SDK_API_KEY": {
                    "DESC1": "在对接SDK时，需要用到以下SDK密钥。",
                    "NOTE": "<strong>请注意：</strong> 该密钥不是使用报表数据API时用到的密钥。",
                    "TITLE": "SDK API密钥"
                }
            },
            "APPS_CONFIGURATION": {
                "ADD_SITE": "添加应用版本",
                "ANDROID_INSTALL_VALIDATION_KEY": "谷歌商店许可密钥",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE": "屏蔽无效收益",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_DISABLED": "关闭状态",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_ENABLED": "启用日期",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_TOOLTIP": "启用该项后，系统将会屏蔽缺失商店购买信息的IAP事件。请确保在启用该功能前，已对IAP追踪做了接收商店购买信息的相关配置，否则系统将屏蔽所有IAP事件，详情请参见<a target=\"_blank\" href=\"https://support.singular.net/hc/en-us/articles/360035996792?flash_digest=8ba73a31f9fcdeea4ce829a419f25a40bd1a3b67#enforcement\">帮助中心。</a>",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_WARNING": "启用该选项后，针对启用后安装应用和更新应用的用户，系统会过滤所有缺失商店购买信息的收益事件。请谨慎考虑后决定是否启用。",
                "ANDROID_PURCHASE_ENFORCEMENT_DATE_WARNING_HEADER": "警告：确定要屏蔽缺失商店购买信息的收益事件么？",
                "ANDROID_SPECIFIC": "安卓深度链接",
                "ATTRIBUTION_CONFIGURATION": "归因设置",
                "CERTIFICATE_KEY": "证书密钥",
                "DEEPLINK_EXPLANATION_TITLE": "请使用{{os_type}}为{{os}}应用开启深度链接，详情请参见<a href=\"https://support.singular.net/hc/en-us/articles/360031371451-Singular-Links-Prerequisites\" target=\"_blank\">帮助中心。</a>",
                "DEEP_LINK_TARGETS": "深度链接URL",
                "DEFAULT_STORE_URL": "默认落地页为商店URL",
                "DESTINATIONS": "落地页设置",
                "DESTINATION_URLS": "落地页URL设置",
                "DOMAIN_NAME_PREFIX": "域名前缀",
                "DRAG_OR_CLICK_TO_UPLOAD_P12": "拖入或上传证书（.p12 格式文档）",
                "DROP_TO_UPLOAD_P12": "拖入证书以上传",
                "FCM_SERVER_KEY": "FCM服务器密钥",
                "FRAUD_OPTIONS": "IAP认证设置",
                "GCM_DEPRECATION_TOOLTIP": "谷歌已于2019年5月开始停止GCM推送服务并由FCM代替GCM进行全面的消息推送服务，请考虑升级到FCM，详情请参见<a target=\"_blank\" href=\"https://developers.google.com/cloud-messaging/android/android-migrate-fcm\">谷歌官宣。</a>对于尚未升级到FCM的广告主用户，仍可以在此输入GCM键值进行卸载追踪。",
                "IOS_PUSH_CERTIFICATE": "iOS Push证书",
                "IOS_UNIVERSAL_LINKS": "iOS通用链接",
                "IOS_UNIVERSAL_LINK_TARGETS": "通用链接URL",
                "KEYWORD": "匹配密钥",
                "LATEST_SDK_VERSION": "上次对接的SDK版本",
                "MOVE_TO": "移到…",
                "NOT_INTEGRATED": "尚未对接",
                "NO_APPS_CONFIGURED_DEVELOPMENT": "无匹配搜索内容的测试应用，请重新搜索或添加新应用",
                "NO_APPS_CONFIGURED_PRODUCTION": "无匹配搜索内容的应用，请重新搜索或添加新应用",
                "NO_DEEP_LINKS_CONFIGURED": "尚未创建深度链接，请添加URL",
                "NO_IOS_UNIVERSAL_LINKS_CONFIGURED": "尚未创建通用链接，请添加URL",
                "PACKAGE_IDENTIFIER": "包名",
                "PLACEHOLDERS": {
                    "APP_NAME": "应用名称",
                    "CHOOSE_PLATFORM": "选择平台",
                    "OPTIONAL": "（可选项）"
                },
                "SDK_INTEGRATED": "已检测到应用内事件",
                "SDK_NOT_INTEGRATED": "尚未检测到应用内事件",
                "SDK_STATUS": "SDK对接状态：",
                "SITE_NICKNAME": "自定义应用名称",
                "STORE_ID": "商店URL",
                "STORE_URL": "商店URL（推荐登陆方式）",
                "UNINSTALL_TRACKING": "卸载追踪"
            },
            "ATTRIBUTION": {
                "CONFIRM_MESSAGE_SEND": "需要将详细内容发送给我们的客户经理么？",
                "CONTACT_US": "联系我们",
                "MESSAGE_SENT": "信息已发送。我们的客户经理会尽快联系您。",
                "PAGE_DESCRIPTION": "借助Singular归因解决方案，揭示用户行为和分析广告渠道效果。",
                "SEND_MESSAGE": "发送信息"
            },
            "AUDIENCES": {
                "CONFIGURE_NETWORK": "配置媒体伙伴",
                "CREATE_NEW": "创建新用户群组",
                "DELETE_WARNING": "确定删除该用户群组？",
                "EDIT_SEGMENT": "编辑用户群组",
                "ENABLE_SEGMENT_DISTRIBUTION_TOGGLE_LABEL": "启用该用户群组",
                "HELP_DISTRIBUTIONS": "利用群组推送功能将已生成的<a href=\"#/react/audiences/segments\" class=\"link-underline\">目标用户群组</a>推送给我们的媒体伙伴。请在下面选择一个媒体伙伴进行配置。",
                "HELP_SEGMENTS": "利用创建群组功能从事件数据中筛选出目标用户，并通过<a href=\"#/react/audiences/distributions\" class=\"link-underline\">群组推送功能</a>将目标用户推送给我们的媒体伙伴。",
                "NEW_SEGMENT": "新用户群组",
                "OFF": "停用",
                "ON": "启用",
                "SEARCH_DISTRIBUTIONS": "检索已创建的推送",
                "SEARCH_SEGMENTS": "检索已创建的用户群组",
                "SEGMENTS_COUNT": "已创建的群组 (${activeSegmentsCount} active)",
                "SEGMENT_TEMPLATES": "用户群租模版",
                "SHARING_OPTIONS": "共享该用户群组",
                "SHARING_OPTIONS_COPY_TOOLTIP": "复制链接",
                "SHARING_OPTIONS_RENAME_TOOLTIP": "重命名",
                "SHARING_OPTIONS_REVOKE_TOOLTIP": "撤销链接",
                "SHARING_OPTIONS_SHELF_CREATE": "创建链接",
                "SHARING_OPTIONS_SHELF_CREATE_EMPTY": "创建链接",
                "SHARING_OPTIONS_SHELF_EMPTY": "请创建一个链接",
                "SHARING_OPTIONS_SHELF_TITLE": "对于尚未和Singular完成对接的媒体渠道，可以通过链接将用户群组分享给渠道。也可以根据需要撤销已生成的链接。",
                "UNSAVED_MESSAGE": "现在离开的话，系统将不会保存配置。"
            },
            "CREATIVES": {
                "EDIT_TAGS_NO_CREATIVE_SELECTED": "请选择素材并为其添加标签",
                "PREAMBLE": "了解更多内容，请点击 <a class=\"link-underline\" target=\"_blank\" href=\"https://support.singular.net/hc/en-us/articles/115001862443\">帮助中心</a>"
            },
            "CREATIVE_TAGS": {
                "DEFINE_VALUES": "为标签组定义名称：（非必选项）",
                "NEW_CREATIVE_TAG": "新标签组",
                "NO_CREATIVE_TAGS_DEFINED_IMPLORE": "尚未创建标签组，请添加标签",
                "PLACEHOLDERS": {
                    "ADD_TAG_VALUES": "输入一个名称",
                    "ENTER_TAG": "输入标签",
                    "TAG_NAME": "例如素材主题"
                },
                "PREAMBLE": "创建自定义标签以聚合素材数据，<a href=\"https://singular.zendesk.com/hc/en-us/articles/115003236386-Introduction-to-Creative-Tagging\" class=\"link-underline\" target=\"_blank\" >点击了解更多</a>",
                "TAGS_GROUP_NAME": "标签组名称"
            },
            "CUSTOM_DIMENSIONS": {
                "ADD_VALUE": "添加条件",
                "DELETE_MESSAGE": "确定要删除该自定义维度么？<br/>确认后系统会在账户级别删除该维度，并不可恢复。",
                "DELETE_WARNING": "进行此操作会将该自定义维度连同与其匹配的历史数据一并删除<br/>请确认是否删除？",
                "IF_NO_VALUE_MATCHED_SET_DEFAULT_TO": "当没有满足条件的数据时，指定一个用来显示的默认数据值: <em>例如 N/A</em>",
                "PLACEHOLDERS": {
                    "FILTER_DIMENSION": "选择一个维度"
                },
                "PREAMBLE": "对数据进行自定义分类和聚合，以进行更简单、更全面的数据分析。<a href=\"https://singular.zendesk.com/hc/en-us/articles/208579346-Creating-Custom-Dimensions\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">点击了解更多</a>",
                "USE_VALUE_WHEN": "当满足下列条件时，聚合数据到该自定义维度",
                "VALUE_DELETE_HOVER_TEXT": "删除子维度",
                "VALUE_NAME": "子维度名称",
                "WARNING_MAXIMUM_REACHED": "每个企业账户最多支持{{max}}个自定义维度"
            },
            "CUSTOM_EVENTS": {
                "ADD_CALCULATED_METRIC_BASED_ON_EVENT": "为自定义事件添加附加指标",
                "APP_SELECTION_AUTOCOMPLETE_PLACEHOLDER": "选择一个应用",
                "APP_SPECIFIC_CONT": "应用级别自定义事件 (${number})",
                "APP_SPECIFIC_EVENTS": "应用级别自定义事件",
                "EMPTY_STATE_NO_APP_SELECTED": "请选择一个应用",
                "EMPTY_STATE_NO_CUSTOM_EVENTS_FOR_APP": "尚无已配置的应用级别自定义事件",
                "EMPTY_STATE_NO_UAN_EVENTS": "找不到已追踪的SDK事件",
                "EVENTS_CONT": "已配置的账户级别自定义事件(${number})",
                "EVENT_DATA_NOTE": "请注意系统会检索过去1个月内侦测到的SDK事件",
                "GLOBAL_EVENTS": "通用自定义事件",
                "GLOBAL_EVENTS_CONT": "账户级别通用自定义事件 (${number})",
                "HELP": "了解更多内容请点击 <a href=\"https://singular.zendesk.com/hc/en-us/articles/208385833\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">帮助中心</a>",
                "HELP_TOOLTIP": "CPE = Cost/Event.<br>ECVR = events/installs.<br>选择后，系统会自动按照自定义事件名称将以上指标添加到报表中。",
                "LAST_MODIFIED": "最后编辑时间 {{date}}, 由 {{editor}} 用户生成",
                "LAST_MODIFIED_AUTOMATICALLY": "最后编辑时间 {{date}}, 系统自动生成的默认自定义事件",
                "MAP_TO": "该自定义事件所匹配的SDK事件",
                "MAP_TO_REACT": "该自定义事件所匹配的SDK事件",
                "MAP_TO_REACT_UNIQUE": "该自定义事件所匹配的SDK事件（只显示用户基准事件）",
                "NEAR_MAXIMUM_GLOBAL_WARNING": "请注意该账户下某一个应用所属的自定义事件已经达到${maxAppEvents}个，当账户内任意一个应用所属的自定义事件数超过上限额度${maxEvents}时，将无法再配置账户级别的通用自定义事件。",
                "NO_EVENTS": "尚未配置自定义事件",
                "PLACEHOLDERS": {
                    "EVENT_NAME": "定义自定义事件名称"
                },
                "REACHED_MAXIMUM_GLOBAL_WARNING": "该账户下某一个应用所属的自定义事件数量已达到上限额度${maxEvents} ，要添加账户级别的通用自定义事件，须确保为该账户下每个应用所属的自定义事件少于上限额度<br/>系客服 获取更多帮助。",
                "REACHED_MAXIMUM_WARNING": "该应用所属的自定义事件数量已达到上限额度${maxEvents}，无法在添加更多自定义事件。",
                "SAVE_SERVER_ERROR": "系统处理该请求时发生错误，请稍后重新尝试。",
                "UNIQUE_TOGGLE_DISABLE_INFO": "该自定义事件已被定义为 ${eventType} 类型",
                "UNIQUE_TOGGLE_INFO": "启用后，系统会按照用户基准对自定义事件进行排重统计",
                "UNIQUE_TOGGLE_LABEL": "用户基准自定义事件",
                "UNSAVED_CHANGES_WARNING": "尚未保存，确认离开么？"
            },
            "DASHBOARD": {
                "PREAMBLE": "了解更多内容，请点击 <a href=\"https://support.singular.net/hc/en-us/articles/206241026-Dashboard-FAQ\" class=\"link-underline\" target=\"_blank\" >帮助中心</a>"
            },
            "DATA_SOURCES": {
                "ADD_NEW": "添加新渠道",
                "ADD_NEW_CONNECTOR": "添加新渠道",
                "ADNETWORK_SELECTION_PLACEHOLDER": "选择渠道",
                "DELETE_UAN_ACTION": "${uan_name}已删除",
                "EDIT_UAN_TOOLTIP": "编辑",
                "EXPORT_RAW_DATA": "导出CSV文件",
                "GROUP_TITLES": {
                    "Action Required": "需要修改设置",
                    "Pending Integration": "系统确认中"
                },
                "HEADER_TEXT": "系统会在渠道登陆后的24小时内更新数据。在系统确认所添加的渠道信息无误后，即开始从媒体渠道方拉取过往3个月内的投放信息。",
                "HELP_CENTER": "帮助中心",
                "LAST_LOGIN": "最后登陆",
                "LAST_SUCCESSFUL_LOGIN": "最后成功登陆",
                "NEW_UAN_ACTION": "${uan_name}已添加",
                "NO_DATA": "尚无可展示的数据",
                "NO_DATA_CONNECTORS": "尚未配置渠道",
                "NO_SEARCH_RESULTS": "未找到可以匹配${searchText}的渠道",
                "PLACEHOLDERS": {
                    "ADD_DATA_SOURCE": "输入需要添加的渠道"
                },
                "REAUTHORIZE": "重新授权",
                "RUN_TEST_REPORT_TOOLTIP": "运行测试报告",
                "SETTINGS": {
                    "ACCOUNTS_ACTIVATE": {
                        "AUTOMATICALLY": "当该账户内有新的子账户时，自动勾选子账户",
                        "TITLE": "选择需要添加的子账户"
                    },
                    "GENERAL": {
                        "TITLE": "一般设置"
                    }
                },
                "SUBHEADER": "更多关于渠道配置和管理的内容，请点击<a target='_blank' href=\"https://support.singular.net/hc/en-us/articles/360031000051-Data-Connectors-FAQ\">帮助中心</a>",
                "TOGGLE_ARCHIVED_UANS": "显示近30天内未有更新数据的渠道",
                "TYPE_CONNECTOR_NAME": "检索渠道名称",
                "TYPE_SOURCE_NAME": "检索渠道名称",
                "WARNING": "请验证登陆信息"
            },
            "DELETE_MODAL": {
                "DELETE_NO": "否，我要撤回该操作",
                "DELETE_YES": "是，请删除{{deleteType}}",
                "DELETE_YES_REACT": "是，请删除${deleteType}",
                "TYPES": {
                    "PARTNER_CONFIG": "设置媒体渠道"
                },
                "WARNING_P1": "确定要删除这个{{deleteType}}?",
                "WARNING_P2": "系统会在账户级别进行该操作，并且不可逆。"
            },
            "EXPORT_LOGS": {
                "APP": "应用",
                "CONVERSION_TYPE": "转化类型",
                "CONVERSION_TYPES": {
                    "ALL": "全部",
                    "INSTALL": "安装",
                    "REENGAGEMENT": "再营销"
                },
                "COUNTRIES": "国家",
                "DATE": "日期",
                "DAYS_ERROR_MSG": "所选日期期间 ({{diff}} days) 超过期间限制 {{limit}} days",
                "ERROR_MSG": "请重新确认请求",
                "EVENTS": "事件",
                "EXPORT": "下载原始数据",
                "LEAVE_EMPTY_FOR_ALL": "无内容则默认下载全部",
                "PARTNERS": "投放渠道",
                "PLACEHOLDERS": {
                    "ADD_COUNTRIES": "请输入要选择的国家",
                    "ADD_DEVICE_TYPE": "请输入要选择的设备类型",
                    "ADD_EVENTS": "请输入要选择的应用内事件",
                    "ADD_PARTNERS": "请输入要选择的媒体渠道"
                },
                "POSTBACK_TYPE": "回传类型",
                "REALTIME_DATA_TOOLTIP": "如果勾选，所下载的原始数据将包含最近60分钟内收集的数据。",
                "SITE": "选择应用版本",
                "TIMEZONE": "已指定的时区",
                "TYPE": "选择数据类型",
                "TYPES": {
                    "Clicks": "点击",
                    "Events": "事件",
                    "Fps": "假量",
                    "Installs": "安装",
                    "Postbacks": "回传"
                }
            },
            "GENERATE_SMART_LINK": {
                "CUSTOM_SOURCE_NAME_NOTE": "<strong>注意</strong>: 如果选择使用自定义渠道名称，链接生成时渠道名称会以'(Custom)'为后缀",
                "GENERAL_SECTION": "基本设置",
                "OTHER_PLATFORMS": "其它应用版本",
                "PLACEHOLDER": {
                    "CUSTOM_NAME": "输入自定义渠道名称",
                    "DESTINATION": "请选择落地页",
                    "SELECT_APP_FIRST": "请选择应用"
                },
                "SMART_DESTINATION_DEPPLINK_LABEL": "${platform} 深度连接",
                "SMART_DESTINATION_UNIVERSAL_LINK_LABEL": "${platform} 通用链接",
                "SOURCES": {
                    "CROSSPROMO": "交叉推广",
                    "CUSTOM": "自定义",
                    "EMAIL": "邮件",
                    "SMS": "短信",
                    "SOCIAL": "社交"
                },
                "SUB_HEADER": "创建一个自定义跟踪链接，以实现在用户点击链接后，按照用户的设备类型，页面会自动跳转到到苹果商店、谷歌商店或者其它第三方商店。",
                "TIP": "针对已和Singular完成对接的渠道，需要在“创建跟踪链接”页面生成跟踪链接",
                "TIP2": "可以“应用设置”页面里添加更多落地页"
            },
            "GENERATE_TRACKING_LINK": {
                "ATTRIBUTION_SETTINGS": "归因设置",
                "CAMPAIGN_NAME": "跟踪链接名称",
                "CAMPAIGN_NAME_TOOLTIP": "为了避免在匹配数据时产生问题，建议按照“应用_OS_国家_附加信息”的样式来命名跟踪连接，例如：appname_ios_us_20200103",
                "CLICK_THROUGH_LINK": "点击型跟踪链接",
                "CONFIG": "配置",
                "CREATIVE_NAME": "素材名称",
                "DEEPLINK_DESTINATION_URL": "深度链接落地页URL",
                "DESTINATION_URL": "落地页URL",
                "GENERATE_LINK": "生成链接",
                "GENERATING_SHORT_LINK_ERROR": "在生成短链接时产生了错误",
                "GENERATING_TRACKING_LINK_ERROR": "在生成跟踪链接时产生了错误",
                "MODAL": {
                    "ALLOW_NO_POSTBACKS_NETWORKS": "一些媒体不仅需要自己渠道带来的安装回传，也需要其它渠道以及自然安装的回传来优化广告投放。勾选已开启全部安装回传给改渠道。",
                    "CLICK_THROUGH_ATTRIBUTION_WINDOW": "配置点击归因窗口",
                    "ENABLE_FRAUD_POSTBACKS": "针对被屏蔽的假量，开启回传",
                    "ENABLE_INTEGRATION": "启用归因追踪",
                    "ENABLE_REENGAGEMENT": "针对再营销广告，开启回传",
                    "ENABLE_REENGAGEMENT_TRACKING": "针对再营销广告，开启跟踪",
                    "ENABLE_VIEWTHROUGH": "针对无点击的广告曝光归因，开启回传",
                    "ENABLE_VIEWTHROUGH_TRACKING": "针对无点击的广告曝光归因，开启跟踪",
                    "GOT_IT": "已了解",
                    "INCLUDE_VIEWTHROUGH_ATTRIBUTIONS": "启用展示归因",
                    "INCLUDE_VIEWTHROUGH_ATTRIBUTIONS_TOOLTIP": "启用后，安装回传会包含归因为广告点击和展示的安装信息。",
                    "INSTALL_POSTBACK_CONFIGURATION": "安装回传设置",
                    "PLEASE_ENTER_EVENT": "请输入一个事件名称",
                    "PLEASE_SELECT_EVENT": "请选择一个事件",
                    "PLEASE_SELECT_EVENT_NAME": "请输入一个有效的 {{eventname}}",
                    "SEND_ALL_POSTBACKS_TO_ADN": "将所有安装回传给 {{adnname}}",
                    "SEND_IN_APP_EVENTS_TO": "将应用内事件回传给 {{adnname}}",
                    "SEND_POSTBACK_FOR_ALL_EVENTS": "针对所有事件发送回传",
                    "SEND_REVENUE": "发送收益数据",
                    "SEND_REVENUE_DISABLED_TOOLTIP": "自定义回传链接启用时此选项不能改变",
                    "SEND_REVENUE_TOOLTIP": "仅适用于购买事件",
                    "SNAPCHAT_ENABLE_INTEGRATION_EXPLANATION": "如需继续操作, 请阅读并同意 <a href=\"https://businesshelp.snapchat.com/en-US/article/attribution-terms\" target=\"_blank\">Snap Inc.'s Terms and Conditions</a>.",
                    "SOURCE_CONFIG_EDIT_POSTBACK_CAMPAIGN_REGEX": "归因广告活动的名字筛选",
                    "SOURCE_CONFIG_EDIT_POSTBACK_CAMPAIGN_REGEX_TOOLTIP": "设定之后，已归因的回传只会触发 安装",
                    "PARTNER_MANAGE_USERS": "管理{{adnname}}用户",
                    "PARTNER_ADD_LOGIN_EXPLANATION": "添加{{adnname}}账号并开启转化追踪",
                    "PARTNER_ADD_ACCOUNTS": "{adnname}} 广告账户",
                    "PARTNER_ADD_ACCOUNTS_EXPLANATION": "选择需要追踪归宗转化的{{adnname}}账户",
                    "TWITTER_EDIT_AD_ACCOUNTS": "编辑Twitter广告账户",
                    "TWITTER_ENABLE_INTEGRATION_EXPLANATION": "连接你的账户并查看由推特带来的转化和分析",
                    "TWITTER_TERMS_AND_CONDITIONS_HEADER": "Twitter移动归因跟踪转化条款"
                },
                "PLACEHOLDER": {
                    "ADNET": "检索媒体伙伴",
                    "CAMPAIGN_NAME": "输入名称以区分跟踪链接",
                    "DESTINATION_2": "选择落地页URL",
                    "DESTINATION_URL": "选择落地页",
                    "SITE": "选择应用版本"
                },
                "PLEASE_CONFIG_THIS_SOURCE": "请先配置该媒体伙伴",
                "SITE": "应用版本",
                "SOURCE_CONFIG": "配置媒体伙伴",
                "SOURCE_LABEL": "渠道",
                "VIEW_THROUGH_LINK": "展示归因监测连接"
            },
            "GOALS": {
                "NO_GOALS_MSG": "尚无已创建的目标，请点击<a class=\"new-goal-link\" href=\"#/goals/new\" class=\"text-success create-goal\">这里</a> 添加一个目标。",
                "NO_GOALS_TEXT": "尚无已创建的目标",
                "PREAMBLE": "了解更多内容，请点击 <a class=\"link-underline\" target=\"_blank\" href=\"https://support.singular.net/hc/en-us/articles/206975446-Goal-Management-FAQ\">帮助中心</a>"
            },
            "HOMEPAGE": {
                "MENU_ITEMS": {
                    "ADVANCED_SETUP_CATEGORY_LABEL": "高级设置",
                    "APPS_BUTTON": "开始",
                    "APPS_CONTENT_TITLE": "如何配置应用",
                    "APPS_LABEL": "添加应用",
                    "BASIC_SET_UP_CATEGORY_LABEL": "基本设置",
                    "BASIC_SET_UP_INTRO_BUTTON": "开始",
                    "BASIC_SET_UP_INTRO_CONTENT_TITLE": "欢迎使用Singular",
                    "BASIC_SET_UP_INTRO_LABEL": "开始",
                    "BI_POSTBACKS_BUTTON": "进行BI回传配置",
                    "BI_POSTBACKS_CONTENT_TITLE": "如何为内部BI配置回传",
                    "BI_POSTBACKS_LABEL": "配置BI回传",
                    "BOOKMARKS_BUTTON": "开始",
                    "BOOKMARKS_CONTENT_TITLE": "如何使用书签来保存报告",
                    "BOOKMARKS_LABEL": "使用书签来保存报告",
                    "COHORTED_EVENTS_BUTTON": "开始",
                    "COHORTED_EVENTS_CONTENT_TITLE": "如何创建自定义事件",
                    "COHORTED_EVENTS_LABEL": "创建自定义事件",
                    "CREATIVE_TAGS_BUTTON": "开始",
                    "CREATIVE_TAGS_CONTENT_TITLE": "建立并管理素材标签",
                    "CREATIVE_TAGS_LABEL": "添加素材标签",
                    "CUSTOM_DIMENSIONS_BUTTON": "开始",
                    "CUSTOM_DIMENSIONS_CONTENT_TITLE": "如何通过添加自定义维度来定制化报告",
                    "CUSTOM_DIMENSIONS_LABEL": "添加自定义维度",
                    "DATA_CONNECTORS_BUTTON": "添加渠道",
                    "DATA_CONNECTORS_CONTENT_TITLE": "配置渠道",
                    "DATA_CONNECTORS_LABEL": "添加渠道",
                    "EXPORT_LOGS_BUTTON": "开始",
                    "EXPORT_LOGS_CONTENT_TITLE": "如何下载原始数据",
                    "EXPORT_LOGS_LABEL": "分析用户级别的原始数据",
                    "GET_STARTED_ADWORDS_BUTTON": "开始",
                    "GET_STARTED_ADWORDS_CONTENT_TITLE": "如何设置AdWords",
                    "GET_STARTED_ADWORDS_LABEL": "设置Adwords",
                    "GET_STARTED_FACEBOOK_BUTTON": "开始",
                    "GET_STARTED_FACEBOOK_CONTENT_TITLE": "如何设置Facebook",
                    "GET_STARTED_FACEBOOK_LABEL": "设置Facebook",
                    "INVITE_USERS_BUTTON": "开始",
                    "INVITE_USERS_CONTENT_TITLE": "为团队成员创建后台账户",
                    "INVITE_USERS_LABEL": "添加后台账户",
                    "MANAGE_LINKS_BUTTON": "开始创建",
                    "MANAGE_LINKS_CONTENT_TITLE": "如何生成和管理跟踪链接",
                    "MANAGE_LINKS_LABEL": "创建跟踪链接",
                    "PARTNER_CONFIGURATION_BUTTON": "开始设置",
                    "PARTNER_CONFIGURATION_CONTENT_TITLE": "如何针对一般媒体伙伴进行归因设置",
                    "PARTNER_CONFIGURATION_LABEL": "设置媒体伙伴",
                    "REPORTING_API_BUTTON": "查看API文档",
                    "REPORTING_API_CONTENT_TITLE": "获取API密钥",
                    "REPORTING_API_LABEL": "配置数据报告API",
                    "REPORTING_INTEGRATIONS_CATEGORY_LABEL": "配置数据报告API和BI回传",
                    "REPORTING_INTEGRATIONS_INTRO_BUTTON": "开始进行",
                    "REPORTING_INTEGRATIONS_INTRO_CONTENT_TITLE": "如何以程序化的形式获取数据报告",
                    "REPORTING_INTEGRATIONS_INTRO_LABEL": "介绍",
                    "RUN_CREATIVE_REPORT_BUTTON": "开始",
                    "RUN_CREATIVE_REPORT_CONTENT_TITLE": "如何进行素材效果分析",
                    "RUN_CREATIVE_REPORT_LABEL": "运行一个素材分析报告",
                    "RUN_REPORT_BUTTON": "开始运行",
                    "RUN_REPORT_LABEL": "运行一个基本报告",
                    "RUN_ROI_REPORT_BUTTON": "开始运行",
                    "RUN_ROI_REPORT_CONTENT_TITLE": "分析",
                    "RUN_ROI_REPORT_LABEL": "运行一个ROI报告",
                    "SDK_CONSOLE_ADVANCED_EVENTS_BUTTON": "进行一般应用内事件测试",
                    "SDK_CONSOLE_ADVANCED_EVENTS_CONTENT_TITLE": "如何测试一般应用内事件",
                    "SDK_CONSOLE_ADVANCED_EVENTS_LABEL": "测试一般应用内事件",
                    "SDK_CONSOLE_BASIC_EVENTS_BUTTON": "进行安装测试",
                    "SDK_CONSOLE_BASIC_EVENTS_CONTENT_TITLE": "如何进行安装测试",
                    "SDK_CONSOLE_BASIC_EVENTS_LABEL": "测试安装",
                    "SDK_CONSOLE_REVENUE_EVENTS_BUTTON": "进行购买事件测试",
                    "SDK_CONSOLE_REVENUE_EVENTS_CONTENT_TITLE": "如何测试够购买事件",
                    "SDK_CONSOLE_REVENUE_EVENTS_LABEL": "测试购买事件",
                    "SDK_CONSOLE_TESTING_BUTTON": "添加测试设备",
                    "SDK_CONSOLE_TESTING_CONTENT_TITLE": "如何进行SDK接入测试",
                    "SDK_CONSOLE_TESTING_LABEL": "在SDK Console里进行接入测试",
                    "SDK_INTEGRATION_CATEGORY_LABEL": "接入Singular SDK",
                    "SDK_INTEGRATION_INTRO_BUTTON": "开始配置",
                    "SDK_INTEGRATION_INTRO_CONTENT_TITLE": "关于接入Singular SDK",
                    "SDK_INTEGRATION_INTRO_LABEL": "着手接入SDK",
                    "SDK_KEYS_AND_DOCUMENTATION_BUTTON": "查看SDK接入文档",
                    "SDK_KEYS_AND_DOCUMENTATION_CONTENT_TITLE": "如何获取SDK密钥和接入文档",
                    "SDK_KEYS_AND_DOCUMENTATION_LABEL": "获取SDK密钥和接入文档",
                    "SET_UP_CAMPAIGNS_CATEGORY_LABEL": "跟踪广告投放",
                    "SET_UP_CAMPAIGNS_INTRO_BUTTON": "开始",
                    "SET_UP_CAMPAIGNS_INTRO_CONTENT_TITLE": "在开始投放广告之前",
                    "SET_UP_CAMPAIGNS_INTRO_LABEL": "介绍",
                    "VIEW_DATA_CATEGORY_LABEL": "数据分析"
                }
            },
            "MANAGE_LINKS": {
                "ANDROID_REDIRECT_DESTINATIONS_LABEL": "配置安卓跳转落地页",
                "APP_SEARCH_PLACEHOLDER": "输入应用名称以进行检索",
                "ARCHIVE_TOOLTIP": "存档该域名",
                "ATTRIBUTION_SETTINGS_GROUP_LABEL": "归因设置",
                "ATTRIBUTION_SETTINGS_LABEL": "了解归因逻辑以及归因窗口，请点击<a class=\"link-underline\" href='https://support.singular.net/hc/en-us/articles/115000526963-Introduction-to-Singular-Attribution' target='_blank'>帮助中心</a>",
                "AUTO_COMPLETE_PLACEHOLDER": "输入内容或者从列表里选择",
                "AVAILABLE_LINKS": "可用链接 (${totalLinks})",
                "CREATE_LINK_BUTTON": "创建链接",
                "CT_FINGERPRINT_WINDOW_LABEL": "点击到安装归因窗口（模糊匹配）",
                "CT_WINDOW_LABEL": "点击到安装归因窗口（设备识别码）",
                "CUSTOM_SOURCE_NAME_LABEL": "指定自定义渠道名称",
                "DDL_PLACEHOLDER": "不配置应用内落地页的话，默认跳转到应用首页",
                "DEEPLINK_LABEL": "配置深度链接：针对已安装用户，请选择应用打开时需要跳转的应用页面",
                "DEFAULT_TOOLTIP": "设置为默认域名",
                "DEFERRED_DEEPLINK_LABEL": "配置延迟深度链接：针对用户安装后首次打开应用的情况，请选择需要跳转的应用页面",
                "DETERMINISTIC_CLICK_WINDOW_TOOLTIP": "当广告渠道通过链接提供设备识别码（应用内广告通常支持设别识别码，而移动网页广告则相反），或者在谷歌referrer可用的情况下，系统才会采用此种归因方式。<br/>点击<a href='https://support.singular.net/hc/en-us/articles/115000526963#click_install_id' target='_blank' class=\"link-underline\">帮助中心 - 归因方式</a> 了解更多相关内容。",
                "DETERMINISTIC_VIEW_WINDOW_TOOLTIP": "当广告渠道通过链接提供设备识别码（应用内广告通常支持设别识别码，而移动网页广告则相反），系统才会采用此种归因方式。<br/>点击<a href='https://support.singular.net/hc/en-us/articles/115000526963#click_install_id' target='_blank' class=\"link-underline\">帮助中心 - 归因方式</a> 了解更多相关内容。",
                "DUPLICATE_TOOLTIP": "以该链接为模版生成新链接",
                "FALLBACK_DESTINATIONS_LABEL": "为其它平台设置回退URL",
                "FALLBACK_FIELD_LABEL": "针对用户在其它平台点击广告的情况（如桌面端网页)，请选择回退URL",
                "FALLBACK_PLACEHOLDER": "输入回退URL",
                "FALLBACK_TOOLTIP": "当跟踪链接不支持用户设备所搭载的平台（如桌面端网页），或者没有为该链接配置任何落地页时，默认跳转到回退URL。",
                "GENERATE_BUTTON": "生成链接",
                "GROUP_TITLE_CUSTOM": "自定义链接",
                "GROUP_TITLE_PARTNER": "媒体伙伴链接",
                "IOS_REDIRECT_DESTINATIONS_LABEL": "配置iOS跳转落地页",
                "LAST_UPDATE": "最后更新时间 ${lastModified}",
                "LEGACY_TOGGLE_TOOLTIP": "在新版链接格式发布前由旧有格式生成的链接会被系统标示为旧格式链接，启用下面的按键以查看旧有格式链接。",
                "LINK_NAME_TOOLTIP": "为了区分各个链接，请为该链接指定一个特定的名称。在生成报告的时候，可以通过 “Tracker Name”维度查看该链接名称。",
                "LINK_PARAMETERS_LABEL": "为链接配置参数以向Singular传递更多信息，您也可以添加自定义参数。<a class=\"link-underline\" href='https://support.singular.net/hc/en-us/articles/360018879491' target='_blank'>点击了解更多</a>。",
                "LINK_ROW_ITEM_CREATED_AT": "创建时间 ${createdDisplay}",
                "LINK_ROW_ITEM_UPDATED_AT": "最后更新时间 ${modifiedDisplay}",
                "LINK_SETTINGS_GROUP_LABEL": "配置链接和跳转",
                "LINK_SETTINGS_LABEL": "为该链接配置跳转规则，<a class=\"link-underline\" href='https://support.singular.net/hc/en-us/articles/360030934212-Singular-Links-FAQ#redirects' target='_blank'>点击了解更多</a>",
                "LINK_TYPE_LABEL": "选择链接类型",
                "LINK_TYPE_TOOLTIP": "如果将要投放广告的渠道是与Singular建立了对接的媒体伙伴，请选择”媒体伙伴“。<br/>如果要在邮件、短信等其它类型的渠道投放广告，请选择”自定义链接“。",
                "MANAGE_SUBDOMAINS": "管理链接域名",
                "OLD_LINKS_TOGGLE_LABEL": "显示旧格式链接 (${count})",
                "OVERRIDE_ATTRIBUTION_WINDOW_CUSTOM_LABEL": "为该链接单独配置归因窗口",
                "REDIRECT_LABEL": "如过该用户未安装应用，则跳转到：",
                "RE_ENGAGEMENT_LABEL": "开启再营销广告追踪",
                "RE_WINDOW_LABEL": "点击到再营销归因窗口（设备识别码）",
                "SHELF_TITLE": "为移动应用归因生成一个跟踪链接。Singular通过跟踪链接获取用于归因的信息并引导用户从广告链接跳转到商店下载页或应用内部内容。",
                "SITE_DEEPLINK_PLACEHOLDER": "不配置应用内落地页的话，会按照未安装应用的情况处理跳转",
                "SITE_LABEL": "应用版本",
                "SOURCE_NAME_LABEL": "选择媒体伙伴",
                "SUBDOMAIN_SHELF_ADD": "已添加的域名 (${subDomainsCount}/${limit}):",
                "SUBDOMAIN_SHELF_CREATE": "生成域名",
                "SUBDOMAIN_SHELF_HEADER": "管理链接域名",
                "SUBDOMAIN_SHELF_INFO": "Singular系统默认使用\"sng.link\"作为链接域名，但是可以自定义子域名。",
                "SUBDOMAIN_SHELF_SUB_TITLE": "添加新域名",
                "SUBDOMAIN_SHELF_TITLE": "配置跟踪链接所使用的域名，点击<a class=\"link-underline\" href=\"https://support.singular.net/hc/en-us/articles/360030934212-Singular-Links-FAQ#what_is_a_link_sub_domain\" target=\"_blank\">帮助中心</a>了解更多关于域名管理和相关要求的内容。",
                "SUB_DOMAIN_LABEL": "链接子域名",
                "TRACKER_LINK_NAME_LABEL": "指定链接名称",
                "VT_FINGERPRINT_WINDOW_LABEL": "展示到安装归因窗口（模糊匹配）",
                "VT_WINDOW_LABEL": "展示到安装归因窗口（设备识别码）"
            },
            "NEW_ALERT": {
                "ALERT_ME_WHEN": "为该提醒设置指标条件",
                "ALERT_STATUS": "开启状态",
                "CHECK_ALERT_ON_EACH": "为该提醒设置维度条件",
                "PLACEHOLDERS": {
                    "ALERT_TITLE": "为该提醒指定名称",
                    "EMAIL": "邮箱地址",
                    "FILTER_VALUES": "请指定（可选）",
                    "METRIC": "数据指标",
                    "NOTES": "为提醒邮件添加内容"
                },
                "PREAMBLE": "设定触发提醒的条件 <a href=\"https://singular.zendesk.com/hc/en-us/articles/207429303-How-to-Use-Alerts-Part-2-Configuration\" class=\"link-underline\" target=\"_blank\" ng-click=\"reportLink($event)\">点击了解更多信息</a>",
                "SEND_EMAIL_TO": "输入接收提醒的邮箱地址（可输入多个地址）"
            },
            "NEW_GOAL": {
                "BREAKDOWNS": {
                    "Ad Networks": "渠道",
                    "Apps": "应用",
                    "Country": "国家",
                    "Platforms": "平台"
                },
                "BREAKDOWNS_TITLE": "添加维度条件",
                "DURATION": "指定有效期间",
                "GOAL_NAME": "为该目标指定名称",
                "METRIC": "添加数据指标",
                "SUB_GOALS": "子目标",
                "TOTAL_BUDGET": "总预算"
            },
            "PARTNER_CONFIG": {
                "STATUS_INDICATION_INACTIVE": "闲置中",
                "STATUS_INDICATION_OK": "活动中"
            },
            "REPORTS": {
                "BOOKMARKS": {
                    "PUBLIC": "和其他用户分享书签",
                    "REMOVE": "删除该书签",
                    "ROLLING_PERIOD": "滚动时间",
                    "SAVE": "保存",
                    "STATIC_PERIOD": "指定日期",
                    "TIME_PERIOD": "期间选项",
                    "TITLE": "指定书签名称"
                },
                "UNIFIED_BANNER": {
                    "HEADER": "解锁 SKAN 和 iOS 洞察",
                    "SUB_HEADER": "统一指标结合 SKAN 和 第三方 MMP 数据，创建一个单一的、去重的，精确的 iOS 安装转化和收入视图。",
                },
                "CHART_METRIC_NO_DATA": "针对该指标没有可以显示的数据",
                "CHART_NO_DATA": "没有可以显示的数据",
                "COMPARE_WARNING": "注意：对比期间的时间长度不一致，请重新设置",
                "DATEPICKER_PERIOD_TITLE": "已选期间",
                "DATEPICKER_PREVIOUS_PERIOD_TITLE": "对比期间",
                "DIMENSION_DRILLDOWN_DISABLED_SELECTION": "要按照Publisher维度分解数据，在生成报表时至少要选择App和Source维度",
                "DIMENSION_DRILLDOWN_HEADER": "以该项目为基准，按照下列维度对数据分解",
                "DIMENSION_DRILLDOWN_ICON_TOOLTIP": "点击以分解数据",
                "GOAL_WARNING": "注意：该目标所设定的结束期间尚未发生，只能显示部分数据",
                "GOAL_WARNING_2": "注意：该目标的设定期间尚未发生，无可显示的数据",
                "METADATA_DIMENSION_SUB_TEXT": "点击查看目前所支持的<a href=\"https://support.singular.net/hc/en-us/articles/360032548672-Campaign-Properties-FAQ\" target='_blank'>广告渠道</a>",
                "ADMON_DAU_METRICS_SUB_TEXT": "Available for <a href=\"https://support.singular.net/hc/en-us/articles/360022067552-Ad-Monetization-Analytics-FAQ#User_Activity_Metrics_DAU_and_ARPDAU\" target='_blank'>selected dimensions</a>",
                "PERFORMANCE_WARNING": "<div>注意：生成country或者campaign维度报告需要较长时间</div><div>如果需要更快的生成报告，请缩短报告期间</div>",
                "PUBLISHER_PERFORMANCE_WARNING": "<div>注意：Publisher维度系涉及大量数据</div><div>因此运行报告前需要先限定App和Source<</div>",
                "ADMON_DAU_WARNING": "<div>DAU and ARPDAU are only available for the dimensions app, OS, and/or country.</div>",
                "SUBHEADER_FAQ": "了解更多关于如何生成报告的内容，请点击 <a href=\"https://support.singular.net/hc/en-us/articles/205046069-Reports-FAQ\" class=\"link-underline\" target=\"_blank\">帮助中心。</a>",
                "VALIDATION_ERROR_PERIOD_FOR_COHORT": "至少需要选择一个期间",
                "VALIDATION_ERROR_SELECT_GOAL": "选择一个目标",
                "VALIDATION_ERROR_SELECT_METRIC": "至少需要选择一个指标",
                "VALIDATION_ERROR_ALLOWED_DIMENSIONS": "DAU and ARPDAU are not available for the dimensions you selected. To run a report with DAU and/or ARPDAU, remove all dimensions except for app, OS, and country.",
            },
            "SDK_CONSOLE": {
                "ADD_DEVICE": "添加测试设备",
                "FORGET_DEVICE": "清除测试设备归因记录",
                "FORGET_DEVICE_CANCEL": "否，取消操作",
                "FORGET_DEVICE_CONFIRM": "是，确认清除"
            },
            "TEAM_MEMBERS": {
                "AUTO_ADD_FUTURE_ITEMS": "当出现新的 ${itemType} 时，自动获得授权",
                "CHANGING_TO_ADMIN_WARNING": "如果更改授权类型为“管理员”，那么该用户将获得查看所有数据和使用所有功能的权限。",
                "EMAIL": "邮箱地址",
                "EXTERNAL_USER_WARNING": "请注意要授权的用户邮箱域名与该账户的企业域名不符，针对外部用户，建议您根据企业的数据安全准则授权",
                "FIRST_NAME": "名",
                "GROUP_NAME_ACTIVE": "已接受授权用户",
                "GROUP_NAME_INACTIVE_TEAM_USERS": "解除授权用户",
                "GROUP_NAME_PENDING_TEAM_USERS": "尚未接受授权用户",
                "INVITE": "授权新用户",
                "INVITE_FULL_MEMBER": "授权新用户",
                "LAST_NAME": "姓",
                "MANAGE_PERMISSIONS": "管理权限",
                "ME": "当前用户",
                "REINVITE_DISABLED_TOOLTIP": "24小时内只能重新发送一次邀请",
                "RESEND_INVITE_REACT": "重新发送邀请",
                "RE_INVITE_SENT": "授权邀请已经发送到 ${userName}",
                "ROLE_SELECTION_CREATE_USER_TITLE": "请选择授权类型",
                "ROLE_SELECTION_MANAGE_USER": "请为 ${userName} 选择授权类型",
                "SELECT_AT_LEAST_ONE_TOOLTIP": "请至少选择一个 ${itemType}",
                "SHELF_CREATE_NEW_USER": "创建新用户",
                "SHELF_MANAGE_USER_PERMISSIONS": "管理用户权限",
                "USER_DELETED": "${userName}'s 用户授权已被解除",
                "USER_TYPE_ADMIN": "管理员用户",
                "USER_TYPE_RESTRICTED": "受限用户",
                "USER_TYPE_STANDARD": "标准用户",
                "WIZARD_CHANGE_USER_TYPE": "更改授权类型",
                "WIZARD_SCREENS_ENABLE_EXPORT_LOGS": "要授权该用户使用‘下载原始数据’功能，该用户必须获得查看所有数据指标的权限。",
                "WIZARD_SCREEN_SELECTION_TITLE": "选择要授权的功能",
                "WIZARD_SUMMARY_APPS_TITLE": "授权管理的应用",
                "WIZARD_SUMMARY_DS_TITLE": "授权管理的渠道",
                "WIZARD_SUMMARY_METRICS_TITLE": "授权查看的数据指标",
                "WIZARD_SUMMARY_SCREENS_TITLE": "授权使用的功能",
                "WIZARD_SUMMARY_USER_DETAILS_EMAIL": "邮箱",
                "WIZARD_SUMMARY_USER_DETAILS_NAME": "用户名称",
                "WIZARD_SUMMARY_USER_DETAILS_TITLE": "用户信息",
                "WIZARD_SUMMARY_USER_TYPE": "授权类型"
            },
            "TWO_FACTOR_AUTH": {
                "P1": "双重认证<strong>尚未启用</strong>",
                "P1_ACTIVE": "双重认证<strong>已启用</strong>",
                "P2": "启用双重认证以提高您账户的安全性。启用后，你需要在移动设备上安装的认证应用内获取验证码，并结合账户密码才可以完成登陆。",
                "P3": "<strong>注意：</strong>开启双重认证后，您将在所有已登陆Singular账户的设备上登出。"
            },
            "TWO_FACTOR_AUTH_CONFIG": {
                "PREAMBLE": "配置双重认证后，您需要在指定的认证应用内输入指令才可以登陆Singular后台。",
                "STEP_1": {
                    "DESCRIPTION": "获取认证应用",
                    "INSTRUCTIONS": "请选择一个认证应用并将其安装在您的移动设备上，<a href=\"https://m.google.com/authenticator\" target=\"_blank\">Google Authenticator</a>, <a href=\"http://guide.duosecurity.com/\" target=\"_blank\">Duo Mobile</a>, <a href=\"https://www.authy.com/\" target=\"_blank\">Authy</a>, <a href=\"https://www.windowsphone.com/en-us/store/app/authenticator/e7994dbc-2336-4950-91ba-ca22d653759b\" target=\"_blank\">Windows Phone Authenticator</a>",
                    "TITLE": "第一步"
                },
                "STEP_2": {
                    "DESCRIPTION": "扫描二维码",
                    "HELP": "用应用内的手动输入代替扫描或者应用所提供的限时密钥</p> <p><code>{{code}}</code></p> <p>随后应用会生成6位验证码",
                    "INSTRUCTIONS": "打开认证应用：<ul><li>点击 ”+“ 或者 ”添加账户“</li><li>用移动设备扫描左侧的二维码</li></ul>",
                    "TITLE": "第二步"
                },
                "STEP_3": {
                    "DESCRIPTION": "输入验证码",
                    "INSTRUCTIONS": "在下方输入认证应用内所生成的6位验证码",
                    "TITLE": "第三步"
                }
            },
            "VALIDATE_ACTION_MODAL": {
                "ACTION_NO": "否，我要撤回该操作",
                "ACTION_YES": "是，{{confirmText}}"
            },
            "PARTNER_CONFIGURATION": {
                "EMPTY_STATE_HEADER": "添加首个渠道",
                "EMPTY_STATE_SUB_HEADER": "合作伙伴配置定义将发送给合作伙伴的事件",
                "EMPTY_STATE_NEW_UAN_PLACEHOLDER": "搜索广告渠道合作伙伴",
                "EMPTY_STATE_DROPDOWN_HOVER": "添加为新合作伙伴",
                "CHOOSE_TOP_NETWORK": "或从选择常用的渠道合作伙伴",
                "ADD_PARTNER": "添加渠道合作伙伴",
                "ADD_APP_SITE": "添加应用",
                "EXPORT_ALL": "下载所有配置信息",
                "EXPORT_ALL_FAILURE_MESSAGE": "下载失败",
                "USER_CONFIGURATION": "用户配置",
                "EDIT_PARTNER_APP_SITE_TOOLTIP": "编辑配置",
                "VIEW_PARTNER_APP_SITE_TOOLTIP": "查看配置",
                "DELETE_PARTNER_APP_SITE_TOOLTIP": "删除配置",
                "ALL_USERS": "发送所有回传给 ${partnerName}，包括所有归因渠道",
                "ALL_USERS_EVENT": "发送至少一个事件的回传给 ${partnerName}，包括所有归因渠道",
                "ATTRIBUTED": "仅针对归因为 ${partnerName} 渠道的用户发送回传",
                "LIMITED_DATA_SHARING": "有限数据共享",
                "RESTRICTED": "受限",
                "NOT_RESTRICTED": "不受限",
                "DELETE_CONFIGURATION_MESSAGE": "您确定要永久删除此合作伙伴配置吗？",
                "MISSING_SITE": "此回传处于活动状态，但应用程序不可用。它已被删除或移至“测试应用程序”，您可以通过“应用程序”页面管理您的设置。e",
                "FILTER": "筛选基准",
                "FILTER_PLACEHOLDER": "检索",
                "TABLE_COLUMNS": {
                    "APP_SITE": "应用",
                    "CLICK_THROUGH_WINDOW": "点击归因窗口",
                    "POSTBACKS_TO_PARTNER": "向渠道合作伙伴发送回传",
                    "LAST_EDITED": "上一次编辑",
                    "PRIVACY": "隐私",
                },
                "ATTRIBUTED_TYPES" : {
                    "ATTRIBUTED": "归因",
                    "ALL_USERS" : "所有用户",
                },
                "POSTBACK_TYPES" : {
                    "REVENUE": "收入",
                    "EVENTS" : "${eventsCount} 事件",
                    "NO_EVENTS" : "没有事件",
                    "INSTALL" : "安装转化",
                    "REENGAGEMENT" : "在营销转化",
                }
            },
        },
        "PAGE_HEADERS": {
            "AD_MONETIZATION": "广告收益报告（按渠道划分）",
            "ALERTS": "提醒",
            "API": "Singular API 接口",
            "APPS_CONFIGURATION": "配置应用",
            "ATTRIBUTION": "欢迎使用Singular归因解决方案<br/>跟踪用户行为数据",
            "AUDIENCE_DISTRIBUTIONS": "推送用户群组",
            "AUDIENCE_SEGMENTS": "创建用户群组",
            "CREATIVES": "素材报告",
            "CREATIVE_TAGS": "素材标签",
            "CUSTOM_DIMENSIONS": "自定义维度",
            "CUSTOM_EVENTS": "自定义事件",
            "DASHBOARD": "数据总台",
            "DATA_CONNECTORS": "连接渠道数据",
            "EDIT_ALERT": "编辑提醒",
            "EDIT_GOAL": "编辑目标",
            "EXPORT_LOGS": "下载原始数据",
            "FRAUD_DASHBOARD": "假量侦测综合报告",
            "FRAUD_PROTECTED": "可信流量报告",
            "FRAUD_REJECTED": "屏蔽流量报告",
            "FRAUD_SUSPICIOUS": "可疑流量报告",
            "GENERATE_LINK": "创建跟踪链接",
            "GOAL_MANAGEMENT": "管理目标",
            "NEW_ALERT": "创建新提醒",
            "NEW_GOAL": "创建新目标",
            "DUPLICATE_GOAL": "创建新目标",
            "PIVOT": "数据透视报表",
            "REPORTS": "自定义报告",
            "RETENTION": "留存报告",
            "SDK_CONSOLE": "SDK测试控制台",
            "TWO_FACTOR_AUTH": "双重认证",
            "TWO_FACTOR_AUTH_CONFIG": "配置双重认证",
            "WEB_TRACKING_LINKS": "创建网页跟踪链接",
            "PARTNER_CONFIGURATION": "配置渠道",
        },
        "PAGE_MESSAGES": {
            "NO_APPS_CONFIGURED_PLEASE_CONFIGURE": "系统尚未侦测到任何事件，请先查看是否已按照要求配置SDK，如有疑问请联系技术支持。"
        },
        "PLACEHOLDERS": {
            "CURRENT_PASSWORD": "当前密码",
            "NEW_PASSWORD": "新密码",
            "SELECT_EVENT": "选择自定义事件",
            "SELECT_FILTER": "选择过滤项",
            "SELECT_GOAL": "选择已创建的目标",
            "SELECT_OS": "选择系统",
            "SELECT_PERIOD": "选择群组期间",
            "SELECT_PLATFORM": "选择平台",
            "SELECT_SOURCE": "选择渠道"
        },
        "SHOW_CHART_TOP_ROWS1": "显示前",
        "SHOW_CHART_TOP_ROWS3": "显示前",
        "SOURCE_DIMENSIONS": "渠道维度",
        "SOURCE_METRICS": "渠道指标",
        "TIME_BREAKDOWN": "时间细分模式",
        "TOOLTIPS":{
            "MARK_FAVOURITE_APP": "添加收藏"
        },
        "TOPBAR_HEADERS": {
            "BOOKMARKS": "已保存的书签",
            "CHANGE_PASSWORD": "修改密码",
            "LOGOUT": "退出账户",
            "TWO_FACTOR_AUTH": "双重认证"
        },
        "WIDGET_TITLE": "项目名称"
    },
    "Segments": "创建用户群组",
    "Settings": "设置",
    "Suspicious Report": "可疑流量报告",
    "Team Management": "管理用户",
};

languages['cn'] = cn;

module.exports = cn;
