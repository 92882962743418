import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from '@mui/material/Collapse';
import css from './ExpandablePanel.css';

class ExpandablePanel extends React.Component {
    constructor(props) {
        super(props);
        this.onExpandClick = this._onExpandClick.bind(this);
    }

    _onExpandClick() {
        const { id, disabled, onExpandClick, isExpandable } = this.props;
        if (disabled || !isExpandable) {
            return;
        }
        onExpandClick(id);
    }

    _getClass(className) {
        const { overrideClasses } = this.props;
        return [css[className], overrideClasses[className]];
    }

    _getContentArea() {
        const { expanded, collapsedContent, animate, content, footer } = this.props;
        let contentElem = (
            <div className={classNames(...this._getClass('contentArea'))}>
                <div key="content">{content || this.props.children}</div>
                <div key="footer">{footer}</div>
            </div>
        );

        if (animate) {
            contentElem = (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {contentElem}
                </Collapse>
            );
        } else if (!expanded) {
            contentElem = null;
        }
        return (
            <React.Fragment>
                {contentElem}
                {!animate && !expanded && collapsedContent ? collapsedContent : null}
            </React.Fragment>
        );
    }

    render() {
        const {
            expanded,
            collapsedContent,
            header,
            disabled,
            className,
            subHeader,
            isSticky,
            isExpandable,
            headerContainerClassName,
            dataTestId,
            overrideClasses,
        } = this.props;
        return (
            <div
                className={classNames(...this._getClass('container'), {
                    [css.expanded]: expanded,
                    [css.hasFooter]: collapsedContent && !expanded,
                    [css.disabled]: disabled,
                    [css.notExpandable]: !isExpandable,
                    [className]: className,
                })}
                data-testid={dataTestId}
            >
                <div className={classNames(css.headerStickinessContainer, { [css.sticky]: isSticky })}>
                    <div
                        className={classNames(...this._getClass('headerContainer'), {
                            [headerContainerClassName]: headerContainerClassName,
                            [overrideClasses.headerContainerExpanded]:
                                expanded && overrideClasses?.headerContainerExpanded,
                        })}
                        data-testid={`${dataTestId}Inner`}
                        onClick={this.onExpandClick}
                        role="button"
                    >
                        <div className={classNames(...this._getClass('arrowArea'))}>
                            <div className={css.arrow} />
                        </div>
                        <div className={classNames(...this._getClass('externalHeaderArea'))}>{header}</div>
                    </div>
                    {subHeader}
                </div>
                {this._getContentArea()}
            </div>
        );
    }
}

ExpandablePanel.propTypes = {
    expanded: PropTypes.bool,
    onExpandClick: PropTypes.func,
    content: PropTypes.element,
    collapsedContent: PropTypes.element,
    footer: PropTypes.element,
    header: PropTypes.element,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    headerContainerClassName: PropTypes.string,
    subHeader: PropTypes.element,
    overrideClasses: PropTypes.objectOf(PropTypes.any),
    animate: PropTypes.bool,
    isSticky: PropTypes.bool,
    isExpandable: PropTypes.bool,
    dataTestId: PropTypes.string,
};

ExpandablePanel.defaultProps = {
    expanded: false,
    onExpandClick: () => {},
    content: null,
    collapsedContent: null,
    footer: null,
    header: null,
    id: '',
    disabled: false,
    className: null,
    headerContainerClassName: null,
    subHeader: null,
    overrideClasses: {},
    animate: true,
    isSticky: false,
    isExpandable: true,
    dataTestId: undefined,
};

export default ExpandablePanel;
