import React from 'react';
import PropTypes from 'prop-types';

export default function RunBasicReport(props) {
    const { isApsalarEnabled } = props;
    return (
        <div>
            <p>
                <strong>What: </strong><span>After you have set up your {isApsalarEnabled && 'apps and '}data connectors, you can start running reports on your campaign data as it comes in from the </span><span
            >ad networks</span><span>. View your aggregate campaign data, monitor your ad spend, track your run rates and more.</span>
            </p>
            <p>
                <strong>Try these sample reports:</strong>
            </p>
            <ul>
                <li>
                    <a
                        href="https://app.singular.net/#/react/reports?query=%7B%22compare%22:false,%22is_goals%22:false,%22dimensions%22:%5B%22source%22%5D,%22metrics%22:%5B%22adn_cost%22%5D,%22discrepancy_metrics%22:%5B%5D,%22file_combiner_dimensions%22:%5B%5D,%22granularity_levels%22:%5B%5D,%22enrichment_dimensions%22:%5B%5D,%22source_dimensions%22:%5B%5D,%22metadata_dimensions%22:%5B%5D,%22cohort_metrics%22:%5B%5D,%22cohort_periods%22:%5B%5D,%22goals_metrics%22:%5B%22goal_budget%22,%22goal_completion%22%5D,%22goals_forecast_metrics%22:%5B%22run_rate%22,%22budget_progress_forecast%22%5D,%22filters%22:%5B%5D,%22time_breakdown%22:%5B%22all%22%5D,%22source_attribution_type%22:%5B%22dynamic%22%5D,%22dual_attribution_mode%22:%5B%22original%22%5D,%22goal%22:null,%22display_unenriched%22:false,%22bookmark_id%22:%22%22,%22is_fraud%22:false,%22is_admon_report%22:false,%22pivot_table%22:%7B%7D,%22query_type%22:%22reports%22,%22post_process_filters_ui%22:%7B%7D%7D"
                        target="_blank">Cost trend per source for the last 7 days</a>
                </li>
                {isApsalarEnabled && (
                    <li>
                        <a
                            href="https://app.singular.net/#/react/reports?query=%7B%22compare%22:false,%22is_goals%22:false,%22dimensions%22:%5B%22source%22,%22unified_campaign_name%22%5D,%22metrics%22:%5B%22adn_cost%22,%22cvr%22,%22custom_installs%22%5D,%22discrepancy_metrics%22:%5B%5D,%22file_combiner_dimensions%22:%5B%5D,%22granularity_levels%22:%5B%5D,%22enrichment_dimensions%22:%5B%5D,%22source_dimensions%22:%5B%5D,%22metadata_dimensions%22:%5B%22bid_type%22,%22bid_amount%22%5D,%22cohort_metrics%22:%5B%5D,%22cohort_periods%22:%5B%5D,%22goals_metrics%22:%5B%22goal_budget%22,%22goal_completion%22%5D,%22goals_forecast_metrics%22:%5B%22run_rate%22,%22budget_progress_forecast%22%5D,%22filters%22:%5B%5D,%22time_breakdown%22:%5B%22week%22%5D,%22source_attribution_type%22:%5B%22dynamic%22%5D,%22dual_attribution_mode%22:%5B%22original%22%5D,%22goal%22:null,%22display_unenriched%22:false,%22bookmark_id%22:%22%22,%22is_fraud%22:false,%22is_admon_report%22:false,%22pivot_table%22:%7B%7D,%22query_type%22:%22reports%22,%22post_process_filters_ui%22:%7B%7D%7D"
                            target="_blank">Bid changes in the last week</a>
                    </li>
                )}
                {!isApsalarEnabled && (
                    <li>
                        <a
                            href="https://app.singular.net/#/react/reports?query=%7B%22compare%22:false,%22is_goals%22:false,%22dimensions%22:%5B%22source%22,%22unified_campaign_id%22,%22unified_campaign_name%22%5D,%22metrics%22:%5B%22custom_impressions%22,%22custom_clicks%22,%22adn_cost%22,%22ctr%22%5D,%22discrepancy_metrics%22:%5B%5D,%22file_combiner_dimensions%22:%5B%5D,%22granularity_levels%22:%5B%5D,%22enrichment_dimensions%22:%5B%5D,%22source_dimensions%22:%5B%5D,%22metadata_dimensions%22:%5B%5D,%22cohort_metrics%22:%5B%5D,%22cohort_periods%22:%5B%5D,%22goals_metrics%22:%5B%5D,%22goals_forecast_metrics%22:%5B%5D,%22filters%22:%5B%5D,%22time_breakdown%22:%5B%22week%22%5D,%22source_attribution_type%22:%5B%5D,%22dual_attribution_mode%22:%5B%5D,%22cross_device_demo_cohort_type%22:%5B%5D,%22start_date%22:%222020-02-28%22,%22start_date_2%22:%222020-01-29%22,%22end_date%22:%222020-03-28%22,%22end_date_2%22:%222020-02-27%22,%22goal%22:null,%22display_unenriched%22:false,%22bookmark_id%22:%22%22,%22is_fraud%22:false,%22is_admon_report%22:false,%22pivot_table%22:%7B%22rowGroup%22:%5B%22date_field%22,%22source%22,%22unified_campaign_name%22,%22unified_campaign_id%22%5D,%22columns%22:%5B%5D,%22values%22:%5B%22custom_impressions%22,%22custom_clicks%22,%22adn_cost%22,%22ctr%22%5D%7D,%22query_type%22:%22reports%22,%22is_slim_mode%22:false,%22post_process_filters_ui%22:%7B%7D%7D"
                            target="_blank">Week over week campaign CTR</a>
                    </li>
                )}
            </ul>
            {isApsalarEnabled && (
                <div>
                    <p>
                        <strong>Note:</strong><span> If you’ve already implemented the SDK and configured your attribution settings, you can run reports that also include tracker information – see </span><strong>Run
                        an ROI Report</strong><span>.</span>
                    </p>
                </div>
            )}
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/205046069-Reports-FAQ" target="_blank">Check out the Reports FAQ</a><span>.</span>
            </p>
        </div>
    );
}

RunBasicReport.propTypes = {
    isApsalarEnabled: PropTypes.bool,
};

RunBasicReport.defaultProps = {
    isApsalarEnabled: undefined,
};
