import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import commonCss from './CreativeAsset.css';

export const UNKNOWN_DURATION_INDICATION = 'N/A';

const VideoAsset = React.forwardRef(
    ({ url, onLoad: onLoadProp, onError: onErrorProp, controls, showCreativeTypeIndication, muted }, ref) => {
        const [isPlaying, setIsPlaying] = useState(false);
        const [remainingVideoDuration, setRemainingVideoDuration] = useState(null);

        const handleVideo = () => {
            if (!controls) {
                setIsPlaying(prevIsPlaying => !prevIsPlaying);
                if (isPlaying) {
                    ref.current.pause();
                    ref.current.currentTime = 0; // go back to the beginning of the video
                } else {
                    ref.current.play();
                }
            }
        };

        const onError = async () => {
            if (!controls) {
                setIsPlaying(false);
            }
            onErrorProp && onErrorProp();
        };

        const onVideoUpdate = () => {
            if (showCreativeTypeIndication) {
                const { duration, currentTime } = ref.current;
                if (!duration) {
                    setRemainingVideoDuration(UNKNOWN_DURATION_INDICATION);
                    return;
                }
                const remainingDuration = duration - currentTime;

                const minutes = Math.floor(remainingDuration / 60);
                const seconds = Math.floor(remainingDuration % 60);
                const formattedSeconds = `0${seconds}`.slice(-2);

                setRemainingVideoDuration(`${minutes}:${formattedSeconds}`);
            }
        };

        const onLoad = () => {
            onVideoUpdate();
            onLoadProp && onLoadProp();
        };

        useEffect(() => {
            onVideoUpdate();
        }, [showCreativeTypeIndication]);

        return (
            <>
                {showCreativeTypeIndication && <div className={commonCss.creativeType}>{remainingVideoDuration}</div>}
                <video
                    ref={ref}
                    data-testid={`video_${url}`}
                    className={commonCss.creativeAsset}
                    onLoadedData={onLoad}
                    onError={onError}
                    muted={muted}
                    loop
                    onPointerOver={handleVideo}
                    onPointerLeave={handleVideo}
                    onTimeUpdate={onVideoUpdate}
                    controls={controls}
                >
                    <track kind="captions" />
                    <source src={url} type="video/mp4" />
                </video>
            </>
        );
    }
);

VideoAsset.propTypes = {
    url: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    controls: PropTypes.bool,
    showCreativeTypeIndication: PropTypes.bool,
    muted: PropTypes.bool,
};

VideoAsset.defaultProps = {
    controls: false,
    showCreativeTypeIndication: false,
    muted: true,
};

export default VideoAsset;
