import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';
import css from './DimensionFilter.css';
import ExpandButton from '../../../components/widgets/ExpandButton';
import Plus from '../../../resources/svg/icons/plus_link.svg';
import DimensionPopover from '../dimensionPopover/DimensionPopover';
import { GET_CREATIVE_TAGS } from '../../queries';
import { AutoCompleteField } from '../../../components/widgets';
import { CreativeType } from '../../types';
import DimensionsFilterHeader from './dimensionsFilterHeader/DimensionsFilterHeader';
import { isObjectEmpty, trackMixpanelEvent } from '../../../utils/general';
import { AIDimensionsContext, DIMENSIONS_DROPDOWN_TAGS_LIMIT, TRACK_EVENT_PREFIX } from '../../consts';
import { GeneralDimensions } from '../../dimensionsUtils';
import {
    filterCreativesByAIDimensions,
    filterCreativesByGeneralDimensions,
    getAutocompleteOptions,
    isEqualCreatives,
    isGeneralDimension,
} from './dimensionFilltersUtils';
import { useUrlParams } from '../../../utils/useUrlParamsHook';

export const ADD_FILTER_TEXT = 'STATIC.PAGES.TAGS_VISIBILITY.ADD_FILTER_DIMENSION';
const DimensionsFilter = ({
    app,
    source,
    creatives,
    disabled,
    onFilteredCreativesChange,
    isLoadingMultiGeneralDimensions,
}) => {
    const { aiDimensionsByCategory } = useContext(AIDimensionsContext);
    const { getParamsObject, setParamsObject } = useUrlParams();

    const allDimensionsCount = useMemo(() => {
        const aiDimensionsCount = Object.values(aiDimensionsByCategory || {})
            .map(dimensions => dimensions.length)
            .reduce((a, b) => a + b, 0);
        const generalDimensionsCount = Object.keys(GeneralDimensions).length;

        return aiDimensionsCount + generalDimensionsCount;
    }, [aiDimensionsByCategory]);

    const addDimensionButtonRef = useRef(null);
    const lastFetchedCreativesRef = useRef([]);
    const isUrlParamsLoaded = useRef(false);
    const [queryCreativeTags, { data: creativeDimensionTagsResponse }] = useLazyQuery(GET_CREATIVE_TAGS);
    const [expanded, setExpanded] = useState(true);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    // Initialize with default values
    const [selectedDimensions, setSelectedDimensions] = useState(Object.values(GeneralDimensions));
    const [loadingDimensions, setLoadingDimensions] = useState([]);
    const [dimensionToSelectedTags, setDimensionToSelectedTags] = useState({});
    const [dimensionToCreativeTags, setDimensionToCreativeTags] = useState({});

    const queryCreativeTagsForDimensions = dimensionNames => {
        setLoadingDimensions(prev => prev.concat(dimensionNames));
        queryCreativeTags({
            variables: {
                appId: app,
                imageHashes: creatives.map(({ imageHash }) => imageHash),
                dimensionNames,
            },
        });
    };

    const trackEvent = useCallback(
        (eventName, params) =>
            trackMixpanelEvent(TRACK_EVENT_PREFIX, eventName, {
                app,
                source,
                ...params,
            }),
        [app, source]
    );

    const dimensionsTagsOptions = useMemo(
        () =>
            Object.entries(dimensionToCreativeTags).reduce((dimensionToOptions, [dimension, options]) => {
                dimensionToOptions[dimension] = getAutocompleteOptions(options.flatMap(({ tags }) => tags));
                return dimensionToOptions;
            }, {}),
        [dimensionToCreativeTags]
    );

    const { generalDimensions, aiDimensions } = useMemo(
        () =>
            selectedDimensions.reduce(
                (acc, dimensionName) => {
                    if (isGeneralDimension(dimensionName)) acc.generalDimensions.push(dimensionName);
                    else acc.aiDimensions.push(dimensionName);
                    return acc;
                },
                { generalDimensions: [], aiDimensions: [] }
            ),
        [selectedDimensions]
    );

    useEffect(() => {
        if (!creativeDimensionTagsResponse?.creativeTags) return;

        lastFetchedCreativesRef.current = creatives;

        const { dimensionNames, tags } = creativeDimensionTagsResponse?.creativeTags;

        setLoadingDimensions(prev =>
            !dimensionNames?.length ? [] : prev.filter(dimension => !dimensionNames.includes(dimension))
        );

        if (!tags?.length) return;

        const tagsByDimension = tags.reduce((acc, tag) => {
            acc[tag.dimensionName] = acc[tag.dimensionName] || [];
            acc[tag.dimensionName].push({
                imageHash: tag.imageHash,
                category: tag.category,
                tags: tag.tags,
            });
            return acc;
        }, {});

        setDimensionToCreativeTags(prev => ({
            ...prev,
            ...dimensionNames.reduce((acc, dimensionName) => {
                acc[dimensionName] = tagsByDimension[dimensionName] || [];
                return acc;
            }, {}),
        }));
    }, [creativeDimensionTagsResponse?.creativeTags]);

    useEffect(() => {
        let filteredCreatives = filterCreativesByGeneralDimensions(
            creatives,
            generalDimensions,
            dimensionToSelectedTags
        );

        filteredCreatives = aiDimensions.length
            ? filterCreativesByAIDimensions(
                  filteredCreatives,
                  aiDimensions,
                  dimensionToSelectedTags,
                  dimensionToCreativeTags
              )
            : filteredCreatives;

        onFilteredCreativesChange(filteredCreatives);
    }, [creatives, selectedDimensions, dimensionToCreativeTags, dimensionToSelectedTags]);

    useEffect(() => {
        if (disabled || !isUrlParamsLoaded.current) return;

        setParamsObject({
            selectedDimensions,
            dimensionToSelectedTags,
        });
    }, [selectedDimensions, dimensionToSelectedTags, disabled]);

    const resetDimensionsConfigs = () => {
        setLoadingDimensions(prev => (prev.length ? [] : prev));
        setDimensionToSelectedTags(prev => (isObjectEmpty(prev) ? prev : {}));
    };

    useEffect(() => {
        if (disabled) {
            resetDimensionsConfigs();
            return;
        }

        const urlParams = getParamsObject();

        if (isObjectEmpty(urlParams)) return;

        const dimensions = urlParams.selectedDimensions;
        const dimensionTags = urlParams.dimensionToSelectedTags;

        if (dimensions) {
            if (dimensions.length) {
                setSelectedDimensions(dimensions);
                const dimensionsToQuery = dimensions.filter(dimName => !isGeneralDimension(dimName));
                queryCreativeTagsForDimensions(dimensionsToQuery);
            } else {
                setSelectedDimensions([]);
            }

            !isObjectEmpty(dimensionTags) && setDimensionToSelectedTags(dimensionTags);
        }

        isUrlParamsLoaded.current = true;
    }, [disabled]);

    useEffect(() => {
        if (!creatives.length) return;

        if (selectedDimensions.includes(GeneralDimensions.OS)) {
            setLoadingDimensions(prev =>
                isLoadingMultiGeneralDimensions
                    ? [...prev, GeneralDimensions.OS]
                    : prev.filter(d => d !== GeneralDimensions.OS)
            );
        }

        setDimensionToCreativeTags(prev => ({
            ...prev,
            [GeneralDimensions.TYPE]: creatives.map(({ type }) => ({ tags: [type] })),
            [GeneralDimensions.OS]: !isLoadingMultiGeneralDimensions
                ? creatives.map(({ multiValuedDimensions }) => ({ tags: multiValuedDimensions.osList }))
                : [],
        }));
    }, [isLoadingMultiGeneralDimensions, creatives.length]);

    useEffect(() => {
        if (!creatives.length) {
            resetDimensionsConfigs();
            return;
        }

        const lastFetchedCreatives = lastFetchedCreativesRef.current;

        if (aiDimensions.length && lastFetchedCreatives.length && !isEqualCreatives(creatives, lastFetchedCreatives)) {
            const oldCreativeHashes = new Set(lastFetchedCreatives.map(({ imageHash }) => imageHash));
            const areAllCreativesQueriedBefore = creatives.every(creative => oldCreativeHashes.has(creative.imageHash));
            if (!areAllCreativesQueriedBefore) {
                queryCreativeTagsForDimensions(aiDimensions);
                lastFetchedCreativesRef.current = creatives;
            }
        }
    }, [creatives]);

    const onDimensionAdded = dimensionName => {
        if (selectedDimensions.includes(dimensionName)) return;

        setSelectedDimensions(prev => {
            const updatedDimensions = [...prev, dimensionName];
            trackEvent('AI Dimension Selected', { selectedDimension: dimensionName, updatedDimensions });
            return updatedDimensions;
        });

        if (dimensionToCreativeTags[dimensionName] || isGeneralDimension(dimensionName)) return;

        queryCreativeTagsForDimensions([dimensionName]);
    };

    const onClearClicked = () => {
        setSelectedDimensions(prev => {
            trackEvent('AI Dimension Removed', { removedDimensions: prev, updatedDimensions: [] });
            return [];
        });

        setLoadingDimensions(prev => (prev.length ? [] : prev));

        if (!isObjectEmpty(dimensionToSelectedTags)) {
            trackEvent('AI Filter Changed', { updatedAIFilters: {} });
            setDimensionToSelectedTags({});
        }
    };

    const onDimensionRemoved = dimension => {
        if (selectedDimensions.length === 1) {
            onClearClicked();
        } else {
            setSelectedDimensions(prev => {
                const updatedDimensions = prev.filter(d => d !== dimension);
                trackEvent('AI Dimension Removed', { removedDimensions: dimension, updatedDimensions });
                return updatedDimensions;
            });
            setLoadingDimensions(prev => prev.filter(d => d !== dimension));
            setDimensionToSelectedTags(prev => {
                const newSelectedTagsByDimension = { ...prev };
                delete newSelectedTagsByDimension[dimension];
                return newSelectedTagsByDimension;
            });
        }
    };

    const onDimensionTagsChange = (dimensionName, selectedTags) => {
        setDimensionToSelectedTags(prevTags => {
            const updatedAIFilters = { ...prevTags, [dimensionName]: selectedTags };
            trackEvent('AI Filter Changed', { updatedAIFilters });
            return updatedAIFilters;
        });
    };

    const getSelectedDimensionOptions = dimension =>
        (dimensionsTagsOptions[dimension] || []).filter(tag => dimensionToSelectedTags[dimension]?.includes(tag.value));

    const isMaxDimensionsSelected = selectedDimensions.length === allDimensionsCount;

    return (
        <div className={css.collapsableSection}>
            <Collapse orientation="vertical" collapsedSize={60} in={expanded}>
                <div className={css.dimensionFilterSection}>
                    <DimensionsFilterHeader
                        expanded={expanded}
                        setExpanded={setExpanded}
                        onClearClicked={onClearClicked}
                        filtersCount={selectedDimensions.length}
                        disabled={disabled}
                    />
                    <div className={css.content}>
                        {selectedDimensions.map(dimensionName => (
                            <AutoCompleteField
                                value={getSelectedDimensionOptions(dimensionName)}
                                useMaterialUI
                                innerContainerClass={classNames(css.dimensionDropdown, css.dimensionFilter)}
                                key={dimensionName}
                                label={dimensionName}
                                options={dimensionsTagsOptions[dimensionName] || []}
                                onChange={tags => onDimensionTagsChange(dimensionName, tags)}
                                disabled={disabled}
                                loading={loadingDimensions.includes(dimensionName)}
                                multiple
                                removable
                                onRemove={() => onDimensionRemoved(dimensionName)}
                                limitTags={DIMENSIONS_DROPDOWN_TAGS_LIMIT}
                            />
                        ))}
                        <ExpandButton
                            ref={addDimensionButtonRef}
                            text={<Translate id={ADD_FILTER_TEXT} />}
                            Icon={Plus}
                            onClick={() => setIsPopoverOpen(true)}
                            isExpanded={isPopoverOpen}
                            containerClass={classNames(css.dimensionDropdown, css.addDimensionButton)}
                            disabled={disabled || isMaxDimensionsSelected}
                        />
                    </div>
                </div>
            </Collapse>
            <DimensionPopover
                open={isPopoverOpen}
                position={addDimensionButtonRef.current}
                onClose={() => setIsPopoverOpen(false)}
                aiDimensionsByCategory={aiDimensionsByCategory}
                selectedDimensions={selectedDimensions}
                onDimensionSelect={onDimensionAdded}
            />
        </div>
    );
};

DimensionsFilter.propTypes = {
    app: PropTypes.string,
    source: PropTypes.string,
    creatives: PropTypes.arrayOf(PropTypes.shape(CreativeType)),
    disabled: PropTypes.bool,
    onFilteredCreativesChange: PropTypes.func,
    isLoadingMultiGeneralDimensions: PropTypes.bool,
};

DimensionsFilter.defaultProps = {
    creatives: [],
    disabled: false,
    onFilteredCreativesChange: () => {},
    isLoadingMultiGeneralDimensions: false,
};

export default DimensionsFilter;
