import { connect } from 'react-redux';
import { getCustomDimensionsPage } from 'customDimensions/selectors';
import * as actions from 'customDimensions/actions';
import { CustomDimensions } from 'components/pages';

const mapStateToProps = state => {
    return getCustomDimensionsPage(state);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onAddCustomDimension: () => {
        dispatch(actions.customDimensionAdded());
    },
    onFilterTextChanged: value => {
        dispatch(actions.filterChanged(value));
    },
    onDeleteApproved: () => {
        dispatch(actions.customDimensionRemoveSend());
    },
    onDeleteRejected: () => {
        dispatch(actions.customDimensionRemoveRejected());
    },
    onTabSwitch: id => {
        dispatch(actions.tabSwitch(id));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDimensions);
