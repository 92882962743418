import { all, call, fork, select, take } from 'redux-saga/effects';
import moment from 'moment';
import intersection from 'lodash/intersection';
import { LOCATION_CHANGE } from 'react-router-redux';
import { CREATE_NEW_MEMBER, MANAGE_PERMISSIONS_CALLED, WIZARD_FINISHED } from '../teamManagement/actions';
import {
    getMetricsSelectionPage,
    getPermissionDimensions,
    getScreenSelectionPage,
    getWizardFinished,
} from '../teamManagement/selectors';
import { getRoleDisplayName, isOperatorToAddFuture, ROLE_RESTRICTED } from '../teamManagement/utils';
import {
    CUSTOM_EVENTS_PAGE_LOADED,
    DELETE_CUSTOM_EVENT_SUCCESS,
    FRONTEND_CACHE_LOAD,
    SAVE_CUSTOM_EVENT_SUCCESS,
} from '../customEvents/actions';
import { getFields } from '../selectors/fields';
import { getUserData } from '../selectors/user';
import {
    CHART_LINES_CHANGED,
    CHART_LOADED,
    CHART_METRIC_SELECTED,
    COLUMN_FREEZE,
    COLUMN_MOVE,
    COLUMN_PIN,
    COLUMN_TOGGLE,
    DATE_CHANGED_GLOBAL,
    DELETING_BOOKMARK,
    EXPORT_TABLE,
    getExportAction,
    QUERY_ERROR_RESPONSE,
    QUERY_REQUEST_PARAMS,
    QUERY_RESPONSE,
    RESET_TABLE,
    TOTALS_RESPONSE,
    TRANSPARENCY_DATA_RESPONSE,
    TRANSPARENCY_DRILLDOWN,
    UPDATE_BOOKMARK_DATA,
    UPDATE_CHART_SHOW_LEGEND,
} from '../actions/reports';
import {
    ARCHIVE_BUTTON_FINISHED,
    COPY_LINK_CLICKED,
    CREATE_SUBDOMAIN_SUCCESS,
    FAVOURITE_BUTTON_FINISHED,
    FORM_UPDATED,
    GENERATE_LINK_SUCCESS,
    LEGACY_LINK_CLICKED,
    LINK_CLICKED_FINISH,
} from '../linkManagement/actions';
import { OPEN_SHELF, SAVE_UAN_DISPLAY_ERROR, SAVE_UAN_SUCCEEDED, UAN_AUTH_CLICK } from '../dataSources/actions';
import { getFieldValues } from '../linkManagement/selectors';
import { NAVIGATION_BUTTON_CLICKED } from '../actions/navigation';
import Events from '../services/events';
import { getTableFormattedData, reportDataSelectors } from '../selectors/reports';
import reportConfigs from '../reducers/reportsConfig';
import { reportRegexPatternMatcher, reportTypes } from '../utils/reports';
import { getActiveGroupType, getUanEvents } from '../customEvents/selectors';
import { getEventSlotGroupTypes, isNewEvent, isOrgUsingSkanReportingFlag } from '../customEvents/utils';
import { COMPARE_MODE } from '../components/pages/reports/consts';
import { REPORT_QUERY_PREFIX } from '../utils/general';

const reportRoutes = reportConfigs.map(config => config.path);
const getConfigByProp = (prop, value) => reportConfigs.find(config => config[prop] === value);

const eventApi = new Events();

const reportRegexPattern = actionName => {
    const regExp = reportRegexPatternMatcher(actionName);
    return action => {
        return regExp.test(action.type);
    };
};

const multiActionMatcher = (...actionNames) => action => actionNames.includes(action.type);

const getHandlerForAction = action => {
    return Object.values(watchedActions).find(ai => {
        return typeof ai.matcher === 'function' ? ai.matcher(action) : ai.matcher === action.type;
    }).handler;
};

const linkManagementUnsupportedPartner = action => {
    if (!action || action.type !== FORM_UPDATED) {
        return false;
    }
    const { field, values } = action;
    if (!field || !field.name === 'source') {
        return false;
    }

    return values && values.source && !values.source.singular_links_ready;
};

/******************************************************************************/
/** *************************** Subroutines ************************************/
/******************************************************************************/

function* handleQueryRequest(action) {
    const { requestParams, queryType } = action;

    if (queryType !== 'table') {
        return;
    }

    let allMetrics = [];

    ['metrics', 'cohort_metrics', 'discrepancy_metrics'].forEach(metric => {
        if (requestParams[metric] && requestParams[metric].length) {
            allMetrics = allMetrics.concat(requestParams[metric]);
        }
    });

    allMetrics.sort();
    let event = 'Reports: Run report';
    const param1 = requestParams;

    const userData = yield select(getUserData);
    const fields = yield select(getFields);

    const intersects = (dimNames, fieldsSection) =>
        intersection(
            dimNames,
            fieldsSection.map(({ name }) => name)
        ).length > 0;

    if (requestParams.is_goals) {
        event = 'Reports: Run goal report';
    }
    if (requestParams.compare) {
        event = 'Reports: Run Comparison report';
    }

    if (requestParams.query_type === reportTypes.pivot) {
        event = 'Reports: Run Pivot report';
    }

    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    params: param1,
                    hasGovernanceDimensions: intersects(param1.dimensions, fields.governanceDimensions),
                    hasCustomDimensions: intersects(param1.dimensions, fields.custom_dimensions),
                    hasCustomEvents: intersects(param1.cohort_metrics, fields.customEvents),
                    hasSourceMetrics: intersects(param1.discrepancy_metrics, fields.discrepancyMetrics),
                    hasCohortMetrics: intersects(param1.cohort_metrics, fields.cohortMetrics),
                    hasAppFilter: param1.filters.map(f => f.dimension).includes('app'),
                    hasSourceFilter: param1.filters.map(f => f.dimension).includes('source'),
                    impersonated: userData.is_impersonator,
                    isUnenriched: param1.display_unenriched,
                },
            ],
        });
    } catch (e) {}
}

function* handleQueryErrorResponse(action) {
    const { error, durationBucket } = action;
    const event = 'Reporting::Query::Error';
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    error_msg: error,
                    duration_bucket: durationBucket,
                },
            ],
        });
    } catch (e) {}
}

function* handleTransparencyData(action) {
    const { duration, result } = action;
    const event = 'Reports: Transparency Drilldown Response';
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    is_successful: result,
                    duration,
                },
            ],
        });
    } catch (e) {}
}

function* handleTotalsFinished(action) {
    const { duration } = action;
    const event = 'Reports: Totals Response';
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    duration,
                },
            ],
        });
    } catch (e) {}
}

function* handleTransparencyDrilldown(action) {
    const { sources } = action;
    const event = 'Reports: Transparency Drilldown';
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    drilldown_sources: sources.uans,
                },
            ],
        });
    } catch (e) {}
}

function* handleBookmarkUpdate(action) {
    const { request, data } = action;
    if (!request || !data.bookmark_id) {
        return;
    }
    const createdTitle = request.saveNewCopy ? 'Save As' : 'Create';
    const event = `Bookmarks: ${data.created ? createdTitle : 'Edit'} Report`;
    const query = { ...request.query };
    const bookmark = { ...request };
    delete bookmark.query;
    const param1 = {
        Bookmark: bookmark,
        BookmarkQuery: query,
    };
    try {
        yield call([eventApi, eventApi.write], event, param1);
    } catch (e) {}
}

function* handleBookmarkDelete(action) {
    const { bookmark } = action;
    if (!bookmark || !bookmark.uuid) {
        return;
    }
    const query = { ...(bookmark.url || {}) };
    bookmark.id = bookmark.uuid;
    const keysToRemove = ['url', 'uuid', 'org', 'timezone'];
    keysToRemove.forEach(key => {
        if (bookmark[key]) {
            delete bookmark[key];
        }
    });
    try {
        yield call([eventApi, eventApi.write], 'Bookmarks: Delete Report', {
            Bookmark: bookmark,
            BookmarkQuery: query,
        });
    } catch (e) {}
}

function* handleReportPageLoad(pathname) {
    const config = getConfigByProp('path', pathname);
    if (!config || !config.events) {
        return;
    }
    if (config.events.load) {
        yield call([eventApi, eventApi.write], ...config.events.load);
    }
}

function* handleLocationChange(action) {
    const {
        payload: { pathname, search },
    } = action;
    if (reportRoutes.includes(pathname)) {
        yield fork(handleReportPageLoad, pathname, search);
    }
}

function* handleAction(action) {
    yield fork(getHandlerForAction(action), action);
}

function* handleChartMetricSelected(action) {
    const { metric, chartParams = { metrics: [] } } = action;
    const visibleMetrics = chartParams.metrics.filter(m => m.visible);
    if (metric.visible) {
        const event = 'Reporting::Query::Chart Selected Metric';
        try {
            yield call([eventApi, eventApi.write], {
                mp: [
                    event,
                    {
                        metric_name: metric.name,
                        chart_params: chartParams,
                    },
                ],
            });
        } catch (e) {}
    }
    if ([2, 3].includes(visibleMetrics.length)) {
        try {
            yield call([eventApi, eventApi.write], {
                mp: [
                    `Analytics: Reports: Charts: ${visibleMetrics.length} Axis`,
                    {
                        chart_params: chartParams,
                    },
                ],
            });
        } catch (e) {}
    }
}

function* handleChartLoaded(action) {
    const { chartParams } = action;
    const event = 'Reporting::Query::Chart Loaded';
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    chart_params: chartParams,
                },
            ],
        });
    } catch (e) {}
}

function* handleQueryResponse(action) {
    const { response } = action;
    const event = 'Reports: Query Response';
    const userData = yield select(getUserData);
    const momentTimestamp = moment();
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    total_time: response.durationInSeconds,
                    duration_bucket: response.durationBucket,
                    num_rows_bucket: response.numRowsBucket,
                    report_id: response.report_id,
                    is_cache_hit: response.is_cache_hit,
                    timestamp_in_customers_timezone: momentTimestamp.format('YYYY-MM-DD HH:mm:ss'),
                    timestamp_in_utc: momentTimestamp.utc().format('YYYY-MM-DD HH:mm:ss'),
                    date_utc: momentTimestamp.utc().format('YYYY-MM-DD'),
                    impersonating_user: userData.real_username,
                    organization_id: userData.organization_id,
                    organization_display_name: userData.company_display_name,
                    is_admin: userData.is_admin,
                },
            ],
        });
    } catch (e) {}
}

function* handleFrontendCacheLoad(action) {
    const { loadTime } = action;
    const event = 'FrontendCache: Frontend cache loaded';
    const userData = yield select(getUserData);
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    load_time: loadTime,
                    impersonating_user: userData.real_username,
                    organization_id: userData.organization_id,
                    organization_display_name: userData.company_display_name,
                    is_admin: userData.is_admin,
                },
            ],
        });
    } catch (e) {}
}

function* handleReportChartLinesChanged(action) {
    const { value } = action;
    try {
        yield call([eventApi, eventApi.write], 'Analytics: Reports: Charts: Modify top row count', {
            num_lines: value,
        });
    } catch (e) {}
}

function* handleReportShowChartLegend(action) {
    const { reportType } = action;
    const {
        chart: { showLegend },
    } = yield select(reportDataSelectors[reportType]);
    try {
        yield call([eventApi, eventApi.write], 'Analytics: Reports: Charts: Legend', {
            show: showLegend,
        });
    } catch (e) {}
}

function* handleReportsDatesChanged(action) {
    const {
        data: { start_date, end_date, start_date_2, end_date_2 },
        reportType,
    } = action;
    const { mode } = yield select(reportDataSelectors[reportType]);
    let event = 'Reports: Date Ranges Updated';
    let dates = {
        start_date,
        end_date,
    };
    if (mode === COMPARE_MODE) {
        event = 'Reports: Comparison Reports: Date Ranges Updated';
        dates = {
            start_date,
            end_date,
            start_date_2,
            end_date_2,
        };
    }
    try {
        yield call([eventApi, eventApi.write], event, {
            dates,
        });
    } catch (e) {}
}

function* handleNavigationButtonClicked(action) {
    const {
        button: {
            event: { name, params = {} },
        },
    } = action;
    try {
        yield call([eventApi, eventApi.write], name, params);
    } catch (e) {}
}

function* handleExportTable(action) {
    const { reportType, isExportGsheet, isCopyCsv } = action;
    const table = yield select(getTableFormattedData(reportType));
    const exportAction = getExportAction(isExportGsheet, isCopyCsv);
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                `${REPORT_QUERY_PREFIX} - Export`,
                { report_id: table.reportId, async_download: false, report_type: reportType, action: exportAction },
            ],
        });
    } catch (e) {}
}

function* handleCustomEventsPageLoaded() {
    try {
        const userData = yield select(getUserData);

        const eventSlotAnalysisTypes = userData.event_slot_analysis_types || [];
        const isOrgUnified = userData.is_unified;
        const isOrgUsingSkanReporting = isOrgUsingSkanReportingFlag(userData.feature_flags);
        const eventSlotGroupTypes = getEventSlotGroupTypes(
            eventSlotAnalysisTypes,
            isOrgUnified,
            isOrgUsingSkanReporting
        );
        const activeGroupType = yield select(getActiveGroupType);

        const mixpanelData = {
            available_tabs: eventSlotGroupTypes,
            active_tab: activeGroupType,
        };

        yield call([eventApi, eventApi.write], 'Custom Events: Summary: Page View', mixpanelData);
    } catch (e) {
        console.error(e);
    }
}

function* handleCustomEventsSaved({ customEvent, backupEvent }) {
    const uanEvents = yield select(getUanEvents);
    const activeGroupType = yield select(getActiveGroupType);
    const isEdit = isNewEvent(customEvent);
    const networks = Object.keys(customEvent.events_by_uan).map(uanId => {
        return uanEvents[uanId]?.source_name || `uanId_${uanId}`;
    });

    const mixpanelData = {
        networks,
        event_type: activeGroupType,
        event_name: customEvent.name,
        is_edit: isEdit,
    };

    if (isEdit) {
        mixpanelData.old_event_name = backupEvent.name;
    }

    try {
        yield call([eventApi, eventApi.write], 'Custom Events: Save Event', mixpanelData);
    } catch (e) {}
}

function* handleCustomEventsDeleted({ customEvent }) {
    const activeGroupType = yield select(getActiveGroupType);

    const mixpanelData = {
        event_type: activeGroupType,
        event_name: customEvent.name,
    };

    try {
        yield call([eventApi, eventApi.write], 'Custom Events: Delete Event', mixpanelData);
    } catch (e) {}
}

function* handleLoggingEvent(action) {
    const { params, type } = action;

    const TYPE_TO_EVENT = {
        [COLUMN_PIN]: 'Reporting::Table::Column Pin',
        [COLUMN_MOVE]: 'Reporting::Table::Column Move',
        [COLUMN_TOGGLE]: 'Reporting::Table::Column Toggle',
        [RESET_TABLE]: 'Reporting::Table::Reset Table',
        [COLUMN_FREEZE]: 'Reporting::Table::Column Freeze',
    };

    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                TYPE_TO_EVENT[type],
                {
                    ...params,
                },
            ],
        });
    } catch (e) {}
}

function* handleNewUserCreation() {
    const event = 'Team Management::Clicked on Create New User';
    const userData = yield select(getUserData);
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    impersonating_user: userData.real_username,
                    organization_id: userData.organization_id,
                    organization_display_name: userData.company_display_name,
                },
            ],
        });
    } catch (e) {}
}

function* handleSaveNewUser() {
    const event = 'Team Management::User Created';
    const { editUserId, role, registrationForm } = yield select(getWizardFinished);
    const userData = yield select(getUserData);

    let savedRole = {
        user_type: getRoleDisplayName(role.type),
        email: registrationForm.email,
        action: editUserId ? 'Edited' : 'Created',
        added_description: role.description,
    };

    if (role.type === ROLE_RESTRICTED) {
        let { fields } = yield select(getMetricsSelectionPage);
        fields = fields.reduce((total, fieldType) => {
            total.push(...fieldType.values);
            return total;
        }, []);

        let { screens } = yield select(getScreenSelectionPage);
        screens = screens.reduce((total, section) => {
            total.push(...section.children);
            return total;
        }, []);

        const allowFutureFields = isOperatorToAddFuture(role.column.operator);
        const allowFutureScreens = isOperatorToAddFuture(role.features.operator);
        const restrictedRoleDetails = {
            metrics: {
                allow_future: allowFutureFields,
                is_all_selected:
                    role.column.values.length === fields.length || (allowFutureFields && !role.column.values.length),
            },
            screens: {
                allow_future: allowFutureScreens,
                is_all_selected:
                    role.features.values.length === screens.length ||
                    (allowFutureScreens && !role.features.values.length),
            },
        };

        const permissionDimensions = yield select(getPermissionDimensions);
        Object.values(permissionDimensions).forEach(permissionDimension => {
            restrictedRoleDetails[permissionDimension.name] = {
                allow_future: permissionDimension.selected.addFuture,
                is_all_selected: permissionDimension.selected.values.length === permissionDimension.values.length,
            };
        });
        savedRole = { ...savedRole, ...restrictedRoleDetails };
    }

    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    impersonating_user: userData.real_username,
                    organization_id: userData.organization_id,
                    organization_display_name: userData.company_display_name,
                    ...savedRole,
                },
            ],
        });
    } catch (e) {}
}

function* handleManageUserPermissions(action) {
    const event = 'Team Management::Clicked on Manage Permissions';
    const { user } = action;
    const userData = yield select(getUserData);
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    impersonating_user: userData.real_username,
                    organization_id: userData.organization_id,
                    organization_display_name: userData.company_display_name,
                    user_type: getRoleDisplayName(user.role.type),
                },
            ],
        });
    } catch (e) {}
}

function* handleCreateSubDomain(action) {
    const event = 'Attribution: Manage Links: Manage Sub-Domains: Create Sub-Domain';
    const { domains, dnsZones } = action;
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    domains,
                    dnsZones,
                },
            ],
        });
    } catch (e) {}
}

function* handleFavouriteSubDomain(action) {
    const event = 'Attribution: Manage Links: Manage Sub-Domains: Change Default Sub-Domain';
    const { domain } = action;
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    domain,
                },
            ],
        });
    } catch (e) {}
}

function* handleArchiveSubDomain(action) {
    const event = 'Attribution: Manage Links: Manage Sub-Domains: Archive Sub-Domain';
    const { domain } = action;
    try {
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    domain,
                },
            ],
        });
    } catch (e) {}
}

function* handleGenerateLinkSuccess(action) {
    const { data: responseData, requestData, isUpdate = false } = action;
    try {
        const linkType = requestData && requestData.link_type === 'custom' ? 'Custom' : 'Partner';
        const opType = isUpdate ? 'Update' : 'Generate';
        const shelfType = isUpdate ? 'View' : 'Create';
        const event = `Attribution: Manage Links: ${shelfType} Link: ${opType} ${linkType} Link`;
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    responseData,
                    requestData,
                },
            ],
        });
    } catch (e) {}
}

function* handleLegacyLinkClicked(action) {
    const { url, linkType } = action;
    try {
        const event = 'Attribution: Manage Links: Create Link: Go To Legacy Link Create';
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    url,
                    linkType,
                },
            ],
        });
    } catch (e) {}
}

function* handleViewLink(action) {
    const { oldLink, formValues } = action;
    try {
        let linkType = 'Partner';
        if (formValues && formValues.linkType === 'custom') {
            linkType = 'Custom';
        }
        if (oldLink) {
            linkType = 'Legacy';
        }
        const event = `Attribution: Manage Links: View ${linkType} Link`;
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    linkData: formValues,
                    oldLink,
                },
            ],
        });
    } catch (e) {}
}

function* handleCopyLinkClicked(action) {
    const { text, label } = action;
    try {
        const formFieldValues = yield select(getFieldValues);
        let viewLinkType = 'Partner';
        if (formFieldValues && formFieldValues.linkType === 'custom') {
            viewLinkType = 'Custom';
        }
        let linkType = 'Click';
        if (label && label.includes('TRACKING_LINK_VIEW_LABEL')) {
            linkType = 'View';
        }
        // Using this hack to figure out if this is a legacy link or new link. since we don't have the accurate data at this point.
        if (formFieldValues && formFieldValues.linkId && typeof formFieldValues.linkId === 'number') {
            linkType = 'Legacy';
        }
        const event = `Attribution: Manage Links: View ${viewLinkType} Link: Copy ${linkType} Link`;
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    text,
                    formValues: formFieldValues,
                },
            ],
        });
    } catch (e) {}
}

function* handleUnsupportedPartnerSelected(action) {
    const { values } = action;
    try {
        const event = 'Attribution: Manage Links: Create Link: Unsupported Partner';
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    partner_name: values.source.value,
                    partner_display_name: values.source.label,
                },
            ],
        });
    } catch (e) {}
}

function* handleOpenShelf(action) {
    const { adnetDisplayName, uanId } = action;
    try {
        const event = `Data Source: ${uanId ? 'Edit' : 'New'} Source Modal`;
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    uan_id: uanId,
                    adnet_display_name: adnetDisplayName,
                },
            ],
        });
    } catch (e) {}
}

function* handleNetworkAuth(action) {
    const { adnId, uanId } = action;
    try {
        const event = 'Data Sources: Source re-auth init';
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    uan_id: uanId,
                    adn_id: adnId,
                },
            ],
        });
    } catch (e) {}
}

function* handleUanValidationComplete(action) {
    const { adnetDisplayName, uanId } = action;
    try {
        const event = 'Data Sources: Source validation complete';
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    uan_id: uanId,
                    adnet_display_name: adnetDisplayName,
                },
            ],
        });
    } catch (e) {}
}

function* handleUanValidationFailed(action) {
    const { adnetDisplayName, uanId } = action;
    try {
        const event = 'Data Sources: Source validation error';
        yield call([eventApi, eventApi.write], {
            mp: [
                event,
                {
                    uan_id: uanId,
                    adnet_display_name: adnetDisplayName,
                },
            ],
        });
    } catch (e) {}
}

/******************************************************************************/
/** ***************************** HELPERS *************************************/
/******************************************************************************/

const watchedActions = {
    QUERY_REQUEST_PARAMS: {
        // log query requests
        matcher: reportRegexPattern(QUERY_REQUEST_PARAMS),
        handler: handleQueryRequest,
    },
    UPDATE_BOOKMARK_DATA: {
        // log bookmark - save / edit
        matcher: reportRegexPattern(UPDATE_BOOKMARK_DATA),
        handler: handleBookmarkUpdate,
    },
    DELETE_BOOKMARK: {
        // log bookmark - delete
        matcher: reportRegexPattern(DELETING_BOOKMARK),
        handler: handleBookmarkDelete,
    },
    LOCATION_CHANGE: {
        // log location changes / page loads
        matcher: LOCATION_CHANGE,
        handler: handleLocationChange,
    },
    TRANSPARENCY_DATA_RESPONSE: {
        matcher: reportRegexPattern(TRANSPARENCY_DATA_RESPONSE),
        handler: handleTransparencyData,
    },
    TRANSPARENCY_DRILLDOWN: {
        matcher: reportRegexPattern(TRANSPARENCY_DRILLDOWN),
        handler: handleTransparencyDrilldown,
    },
    QUERY_ERROR_RESPONSE: {
        matcher: reportRegexPattern(QUERY_ERROR_RESPONSE),
        handler: handleQueryErrorResponse,
    },
    CHART_METRIC_SELECTED: {
        matcher: reportRegexPattern(CHART_METRIC_SELECTED),
        handler: handleChartMetricSelected,
    },
    CHART_LOADED: {
        matcher: reportRegexPattern(CHART_LOADED),
        handler: handleChartLoaded,
    },
    CHART_LINES_CHANGED: {
        matcher: reportRegexPattern(CHART_LINES_CHANGED),
        handler: handleReportChartLinesChanged,
    },
    UPDATE_CHART_SHOW_LEGEND: {
        matcher: reportRegexPattern(UPDATE_CHART_SHOW_LEGEND),
        handler: handleReportShowChartLegend,
    },
    DATE_CHANGED: {
        matcher: DATE_CHANGED_GLOBAL,
        handler: handleReportsDatesChanged,
    },
    NAVIGATION_BUTTON_CLICKED: {
        matcher: NAVIGATION_BUTTON_CLICKED,
        handler: handleNavigationButtonClicked,
    },
    EXPORT_TABLE: {
        matcher: EXPORT_TABLE,
        handler: handleExportTable,
    },
    CUSTOM_EVENTS_PAGE_LOADED: {
        matcher: CUSTOM_EVENTS_PAGE_LOADED,
        handler: handleCustomEventsPageLoaded,
    },
    SAVE_CUSTOM_EVENT_SUCCESS: {
        matcher: SAVE_CUSTOM_EVENT_SUCCESS,
        handler: handleCustomEventsSaved,
    },
    DELETE_CUSTOM_EVENT_SUCCESS: {
        matcher: DELETE_CUSTOM_EVENT_SUCCESS,
        handler: handleCustomEventsDeleted,
    },
    FRONTEND_CACHE_LOAD: {
        matcher: FRONTEND_CACHE_LOAD,
        handler: handleFrontendCacheLoad,
    },
    LOGGING_ONLY_ACTIONS: {
        matcher: multiActionMatcher(COLUMN_PIN, COLUMN_MOVE, COLUMN_TOGGLE, RESET_TABLE, COLUMN_FREEZE),
        handler: handleLoggingEvent,
    },
    TOTALS_REQUEST_FINISHED: {
        matcher: reportRegexPattern(TOTALS_RESPONSE),
        handler: handleTotalsFinished,
    },
    QUERY_RESPONSE: {
        matcher: reportRegexPattern(QUERY_RESPONSE),
        handler: handleQueryResponse,
    },
    CREATE_NEW_MEMBER: {
        matcher: CREATE_NEW_MEMBER,
        handler: handleNewUserCreation,
    },
    SAVE_USER: {
        matcher: WIZARD_FINISHED,
        handler: handleSaveNewUser,
    },
    MANAGE_USER_PERMISSIONS: {
        matcher: MANAGE_PERMISSIONS_CALLED,
        handler: handleManageUserPermissions,
    },
    CREATE_SUBDOMAIN_SUCCESS: {
        matcher: CREATE_SUBDOMAIN_SUCCESS,
        handler: handleCreateSubDomain,
    },
    FAVOURITE_BUTTON_FINISHED: {
        matcher: FAVOURITE_BUTTON_FINISHED,
        handler: handleFavouriteSubDomain,
    },
    ARCHIVE_BUTTON_FINISHED: {
        matcher: ARCHIVE_BUTTON_FINISHED,
        handler: handleArchiveSubDomain,
    },
    GENERATE_LINK_SUCCESS: {
        matcher: GENERATE_LINK_SUCCESS,
        handler: handleGenerateLinkSuccess,
    },
    LEGACY_LINK_CLICKED: {
        matcher: LEGACY_LINK_CLICKED,
        handler: handleLegacyLinkClicked,
    },
    LINK_CLICKED_FINISH: {
        matcher: LINK_CLICKED_FINISH,
        handler: handleViewLink,
    },
    COPY_LINK_CLICKED: {
        matcher: COPY_LINK_CLICKED,
        handler: handleCopyLinkClicked,
    },
    UNSUPPORTED_PARTNER_SELECTED: {
        matcher: linkManagementUnsupportedPartner,
        handler: handleUnsupportedPartnerSelected,
    },
    OPEN_SHELF: {
        matcher: OPEN_SHELF,
        handler: handleOpenShelf,
    },
    UAN_AUTH_CLICK: {
        matcher: UAN_AUTH_CLICK,
        handler: handleNetworkAuth,
    },
    SAVE_UAN_SUCCEEDED: {
        matcher: SAVE_UAN_SUCCEEDED,
        handler: handleUanValidationComplete,
    },
    SAVE_UAN_DISPLAY_ERROR: {
        matcher: SAVE_UAN_DISPLAY_ERROR,
        handler: handleUanValidationFailed,
    },
};

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

function* watchAction(actionItem) {
    while (true) {
        const action = yield take(actionItem);
        yield fork(handleAction, action);
    }
}

export default function* events() {
    const watchers = [];
    const actionItems = Object.values(watchedActions).map(ai => ai.matcher);
    for (const actionItem of actionItems) {
        watchers.push(fork(watchAction, actionItem));
    }
    yield all(watchers);
}
