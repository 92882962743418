import { connect } from 'react-redux';
import Legacy from '../components/Legacy';
import { leacyClicked } from '../actions';

const mapDispatchToProps = dispatch => {
    return {
        onLegacyLinkClicked: (...args) => {
            dispatch(leacyClicked(...args));
        },
    };
};

export default connect(() => {}, mapDispatchToProps)(Legacy);
