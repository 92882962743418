import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../widgets/Dialog';

import { PageWrapper, PageHeader, DeleteDialog } from '../partials';
import css from './TrackingLinksMain.css';
import App from './TrackingLinks/App';
import Filters from './TrackingLinks/Filters';
import Search from './TrackingLinks/Search';
import Spinner from '../widgets/Spinner';
import RadioButtonsGroup from '../widgets/RadioButtonsGroup';
import { downloadCSV } from '../../utils/trackingLinks';
import NoLinks from '../../resources/svg/icon_no_links.svg';

class TrackingLinksMain extends React.Component {
    constructor(props) {
        super(props);
        this.exportCSV = this.exportCSV.bind(this);
        this.handleEditCampaignName = this.handleEditCampaignName.bind(this);
    }

    exportCSV() {
        const { filteredTrackingLinks } = this.props;
        downloadCSV(filteredTrackingLinks);
    }

    handleEditCampaignName(app, e) {
        if (e.keyCode === 13) {
            const editingCampaignID = this.props.editingCampaign;
            const newCampaignName = e.target.value;
            const campaign = this.props.trackingLinks.find((l) => `${l.campaign_id}` === `${editingCampaignID}`);
            this.props.editCampaignName(editingCampaignID, newCampaignName, campaign, app);
        }
    }

    render() {
        const p = this.props;
        const {
            addFilter,
            removeFilter,
            changeFilter,
            addFilterTag,
            removeFilterTag,
            clearFilterTags,
            collapseAll,
            expandAll,
            toggleExpandApp,
            toggleExpandNetwork,
            toggleExpandCampaign,
            handleFilterSelector,
            updateSearch,
            searchTrackingLink,
            deleteClicked,
            deleteConfirmed,
            deleteDismissed,
            editClicked,
        } = this.props;
        return (
            <PageWrapper>
                <PageHeader text="STATIC.TRACKING_LINKS" leftComponents={[]} rightComponent={[]} />
                <div>
                    To learn more, see <a href="https://support.singular.net/hc/en-us/articles/360015221071">Editing and Archiving Links</a>
                </div>
                <div className={css.FiltersWrapper}>
                    <div className={css.HeaderControls}>
                        <RadioButtonsGroup radioItems={p.searchOptions} horizontal onChange={handleFilterSelector} />
                        <button className={`${css.ExportCsv}`} onClick={this.exportCSV}>
                            Export
                        </button>
                    </div>
                    {p.filterType === 'dimensions' && (
                        <Filters
                            css={css}
                            filters={p.filters}
                            suggestions={p.filterSuggestions}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                            changeFilter={changeFilter}
                            addFilterTag={addFilterTag}
                            removeFilterTag={removeFilterTag}
                            clearFilterTags={clearFilterTags}
                        />
                    )}
                    {p.filterType === 'link' && (
                        <Search css={css} search={p.search} onChange={updateSearch} onSubmit={searchTrackingLink} />
                    )}
                </div>
                <hr className={css.Divider} />

                <Dialog
                    modal
                    open={p.campaign_id_to_delete !== undefined && p.campaign_id_to_delete !== null}
                    repositionOnUpdate={false}
                    style={{
                        paddingTop: 16,
                    }}
                >
                    <DeleteDialog
                        style={{ textAlign: 'center' }}
                        onAccept={() => {
                            deleteConfirmed(this.props.campaign_id_to_delete);
                        }}
                        onReject={deleteDismissed}
                        acceptText="STATIC.PAGES.VIEW_TRACKING_LINKS.ARCHIVE_YES"
                        title="STATIC.PAGES.VIEW_TRACKING_LINKS.DELETE_WARNING"
                        bodyClassName={css.modal}
                    />
                </Dialog>

                <Spinner style={{ marginTop: p.fetching ? '300px' : 0 }} show={p.fetching} />
                {!p.fetching && p.filteredTrackingLinks.length > 0 && (
                    <div className={css.AppsWrapper}>
                        <div className={css.AvailableLinks}>
                            <h5>{`Available Links (${p.filteredTrackingLinks.length})`}</h5>
                        </div>
                        {p.expanded.length > 0 && (
                            <div className={css.CollapseAll} onClick={collapseAll}>
                                Collapse All
                            </div>
                        )}
                        {p.expanded.length === 0 && (
                            <div className={css.CollapseAll} onClick={expandAll}>
                                Expand All
                            </div>
                        )}
                        <div style={{ margin: '20px 0px 10px 0px' }}>
                            {p.filteredApps.map((app) => (
                                <App
                                    app={app}
                                    css={css}
                                    key={app.id}
                                    toggleExpandApp={() => {
                                        toggleExpandApp(app.id);
                                    }}
                                    toggleExpandNetwork={toggleExpandNetwork}
                                    toggleExpandCampaign={toggleExpandCampaign}
                                    expanded={p.expanded.indexOf(app.id) > -1}
                                    expandedNetworks={p.expandedNetworks[app.id] || []}
                                    expandedCampaigns={p.expandedCampaigns}
                                    deleteClicked={deleteClicked}
                                    editCampaignName={this.handleEditCampaignName}
                                    editClicked={editClicked}
                                    editingCampaign={p.editingCampaign}
                                    enabledCampaignEditing={p.enabledCampaignEditing}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {!p.fetching && p.filteredTrackingLinks.length === 0 && p.trackingLinks.length > 0 && (
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '50px',
                            color: '#191E21',
                            fontSize: '16px',
                        }}
                    >
                        No matching links found
                        <br />
                        <NoLinks style={{ marginTop: '20px', width: '38px', height: '48px' }} />
                    </div>
                )}
                {!p.fetching && p.trackingLinks.length === 0 && (
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '50px',
                            color: '#191E21',
                            fontSize: '16px',
                        }}
                    >
                        You have not configured any Tracking Links. You can configure them in the&nbsp;
                        <a target="_blank" href="/react/link-management//create-link">
                            Create Link
                        </a>{' '}
                        or{' '}
                        <a target="_blank" href="/react/link-management/create-smart-link">
                            Create Custom Link
                        </a>{' '}
                        pages
                    </div>
                )}
            </PageWrapper>
        );
    }
}

TrackingLinksMain.propTypes = {
    trackingLinks: PropTypes.arrayOf(PropTypes.any),
    filteredTrackingLinks: PropTypes.arrayOf(PropTypes.any),
    campaign_id_to_delete: PropTypes.arrayOf(PropTypes.string),
    editingCampaign: PropTypes.string,
    addFilter: PropTypes.func,
    removeFilter: PropTypes.func,
    changeFilter: PropTypes.func,
    addFilterTag: PropTypes.func,
    removeFilterTag: PropTypes.func,
    clearFilterTags: PropTypes.func,
    collapseAll: PropTypes.func,
    expandAll: PropTypes.func,
    toggleExpandApp: PropTypes.func,
    toggleExpandNetwork: PropTypes.func,
    toggleExpandCampaign: PropTypes.func,
    handleFilterSelector: PropTypes.func,
    updateSearch: PropTypes.func,
    searchTrackingLink: PropTypes.func,
    deleteClicked: PropTypes.func,
    deleteConfirmed: PropTypes.func,
    deleteDismissed: PropTypes.func,
    editClicked: PropTypes.func,
    editCampaignName: PropTypes.func,
};

TrackingLinksMain.defaultProps = {
    dispatch: () => {},
    trackingLinks: [],
    filteredTrackingLinks: [],
    campaign_id_to_delete: null,
    editingCampaign: null,
    addFilter: () => {},
    removeFilter: () => {},
    changeFilter: () => {},
    addFilterTag: () => {},
    removeFilterTag: () => {},
    clearFilterTags: () => {},
    collapseAll: () => {},
    expandAll: () => {},
    toggleExpandApp: () => {},
    toggleExpandNetwork: () => {},
    toggleExpandCampaign: () => {},
    handleFilterSelector: () => {},
    updateSearch: () => {},
    searchTrackingLink: () => {},
    deleteClicked: () => {},
    deleteConfirmed: () => {},
    deleteDismissed: () => {},
    editClicked: () => {},
    editCampaignName: () => {},
};

export default TrackingLinksMain;
