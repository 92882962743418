import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import CellLoader from './CellLoader';

const TableCellTextInput = ({
    value,
    type,
    wholeNumberOnly,
    className,
    tdClassName,
    errorClassName,
    error,
    disabled,
    isLoading,
    onChange: onChangeProp,
}) => {
    const onChange = val => onChangeProp(val && type === 'number' ? Number(val) : val);

    return (
        <td className={tdClassName}>
            {isLoading ? (
                <CellLoader />
            ) : (
                <TextField
                    type={type}
                    value={value || ''}
                    disabled={disabled}
                    wholeNumberOnly={wholeNumberOnly}
                    error={error}
                    errorClassName={errorClassName}
                    onChange={onChange}
                    containerClass={className}
                    debounce={500}
                />
            )}
        </td>
    );
};

TableCellTextInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(['text', 'password', 'number', 'textarea', null]),
    wholeNumberOnly: PropTypes.bool,
    className: PropTypes.string,
    tdClassName: PropTypes.string,
    errorClassName: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
};

TableCellTextInput.defaultProps = {
    value: '',
    type: null,
    wholeNumberOnly: false,
    className: '',
    tdClassName: '',
    errorClassName: '',
    error: null,
    disabled: false,
    isLoading: false,
    onChange: () => {},
};

export default TableCellTextInput;
