import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import EditIcon from '../../resources/svg/edit.svg';
import css from './EditableTitle.css';

class EditableTitle extends React.Component {
    constructor(props) {
        super(props);
        this.onTextChanged = this._onTextChanged.bind(this);
        this.onClick = this._onClick.bind(this);
        this.onEventNameBlur = this._onBlur.bind(this);
        this.state = {
            isEditSelected: false,
        };
    }

    _onClick(e) {
        e.stopPropagation();
        const { isEditSelected } = this.state;
        if (!isEditSelected) {
            this.eventNameInput.select();
        }
        this.setState({
            isEditSelected: true,
        });
    }

    _onBlur(e) {
        this.setState({
            isEditSelected: false,
        });
        this.eventNameInput.blur();

        this.props.onTextChangeComplete();
    }

    _onTextChanged() {
        const { onTextChanged } = this.props;
        onTextChanged(this.eventNameInput.value);
    }

    render() {
        const { containerStyle, value, placeholder, error, containerClass } = this.props;
        const { isEditSelected } = this.state;
        return (
            <div
                className={classNames(css.editableTitleHolder, containerClass, {
                    [css.inputSelected]: isEditSelected,
                    [css.error]: error,
                })}
                onClick={this.onClick}
                role="button"
                tabIndex={0}
                style={containerStyle}
            >
                <input
                    type="text"
                    className={css.editableInput}
                    ref={(el) => {
                        this.eventNameInput = el;
                    }}
                    onChange={this.onTextChanged}
                    value={value}
                    maxLength="40"
                    onBlur={this.onEventNameBlur}
                    placeholder={placeholder}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') this.onEventNameBlur();
                    }}
                />
                <EditIcon className={css.pencil} />
                <span className={css.errorMessage}>{error}</span>
            </div>
        );
    }
}

EditableTitle.propTypes = {
    onTextChanged: PropTypes.func,
    onTextChangeComplete: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    error: PropTypes.string,
    containerClass: PropTypes.string,
};

EditableTitle.defaultProps = {
    onTextChanged: () => null,
    onTextChangeComplete: () => null,
    value: '',
    placeholder: '',
    containerStyle: {},
    error: null,
    containerClass: null,
};

export default withLocalize(EditableTitle);
