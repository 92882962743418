import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ChartWidgetWrapper from '../ChartWidgetWrapper';
import BubbleChart from './BubbleChart';
import VariwideChart from './VariwideChart';
import { useFetchTableData } from '../../../customDashboards/hooks';
import { getMetricsFormat, getMetricValueFormat } from '../PivotChart/utils';
import { renameTimebreakdownDimension } from '../../../customDashboards/utils';
import { calculatedMetricValue } from '../../../utils/grids';
import { ACTION_TYPES } from '../ChartWidgetWrapper/consts';
import { THREE_AXIS_WIDGETS_TYPES } from './consts';
import { FORMAT_POINT_BY_WIDGET_TYPE } from './utils';
import TopXChartDataFilter from '../TopXChartDataFilter';
import DimensionsChartDataFilter from '../DimensionsChartDataFilter';
import { TOP_X_THRESHOLD, TOP_X_COUNT_MAX_VALUE } from '../PivotChart/consts';

const ThreeAxisChartsWidget = ({
    dispatchWidgetVersions,
    dispatchWidgetsLoading,
    forceRefreshCounter,
    dashboardId,
    widget,
    globalFilters,
    startDate,
    endDate,
    startDate2,
    endDate2,
    compare,
    height,
    updatingGraphQL,
    allowedActions,
    displayUnenriched,
}) => {
    const NO_VALUE = 'N/A';
    const reportType = 'reports';

    const [isDataParsed, setIsDataParsed] = useState(false);
    const [xAxisOptions, setXAxisOptions] = useState({});
    const [yAxisOptions, setYAxisOptions] = useState({});
    const [tooltipFormat, setTooltipFormat] = useState({});
    const [dimensionsWithValues, setDimensionsWithValues] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState();
    const [series, setSeries] = useState([]);
    const [loading, tableData, error] = useFetchTableData(
        dashboardId,
        widget.gridParams.i,
        widget.query,
        globalFilters,
        startDate,
        endDate,
        startDate2,
        endDate2,
        reportType,
        dispatchWidgetVersions,
        dispatchWidgetsLoading,
        compare,
        widget.dataType,
        displayUnenriched,
        updatingGraphQL,
        forceRefreshCounter
    );

    const { rowData = [] } = tableData || {};
    const reportColumns = useMemo(() => {
        return tableData?.columnDefs.map(x => ({
            name: x.field,
            fieldType: x.fieldType,
            displayName: x.headerName,
            displayFormat: x.displayFormat,
        }));
    }, [tableData]);

    const [topXCount, setTopXCount] = useState(TOP_X_COUNT_MAX_VALUE);

    useEffect(() => {
        if (series.length) {
            setTopXCount(Math.min(series.length, TOP_X_COUNT_MAX_VALUE));
        }
    }, [series]);

    const getMetricName = axisMetric =>
        renameTimebreakdownDimension(axisMetric, widget.dataType).filter(metric =>
            reportColumns.find(column => column.name === metric)
        );

    const getMetricValue = (key, row) => {
        const value = typeof row[key] === 'object' ? calculatedMetricValue(row[key]) : row[key];
        const colObject = reportColumns.find(x => x.name === key);

        if (colObject?.displayFormat?.type === 'percentage') {
            return value * 100;
        }
        return value;
    };

    const getColumnDisplayName = (columns, dimension) => columns.find(col => col.name === dimension)?.displayName;

    const settings = useMemo(() => {
        if (!reportColumns) {
            return null;
        }

        const { rows = [], columns = [], zAxis = [] } = widget.options;
        const { dimensions = [] } = widget.query;

        const [xMetricName] = getMetricName(columns);
        const [yMetricName] = getMetricName(rows);
        const [zMetricName] = getMetricName(zAxis);

        const metrics = getMetricsFormat(reportColumns);
        const [initialDimension] = getMetricName(dimensions);
        setSelectedDimension(prevState => ({
            ...prevState,
            name: initialDimension,
            displayName: getColumnDisplayName(reportColumns, initialDimension),
        }));
        return { metrics, xMetricName, yMetricName, zMetricName };
    }, [reportColumns]);

    const setAxisAndTooltipSettings = (metrics, xMetricName, yMetricName, zMetricName) => {
        metrics.forEach(metric => {
            const { valuePrefix, valueSuffix, valueDecimals, formatString } = getMetricValueFormat(metric);
            const tooltipValues = {
                prefix: valuePrefix,
                suffix: valueSuffix,
                precision: valueDecimals,
                displayName: metric.display_name,
            };

            const axisOptions = {
                labelsFormat: formatString,
                title: metric.display_name,
            };

            switch (metric.name) {
                case xMetricName:
                    setXAxisOptions(axisOptions);
                    setTooltipFormat(prevState => ({ ...prevState, x: tooltipValues }));
                    break;
                case yMetricName:
                    setYAxisOptions(axisOptions);
                    setTooltipFormat(prevState => ({ ...prevState, y: tooltipValues }));
                    break;
                case zMetricName:
                    setTooltipFormat(prevState => ({ ...prevState, z: tooltipValues }));
                    break;
                default:
                    break;
            }
        });
    };

    useEffect(() => {
        // check which dimensions have data to se dimension selection in widget
        const availableDimensions = [];
        if (rowData.length && settings?.metrics) {
            const { xMetricName, yMetricName, zMetricName } = settings;
            getMetricName(widget.query.dimensions).forEach(dimension => {
                for (let i = 0; i < rowData.length; i++) {
                    const row = rowData[i];
                    const x = getMetricValue(xMetricName, row);
                    const y = getMetricValue(yMetricName, row);
                    const z = getMetricValue(zMetricName, row);
                    const name = getMetricValue(dimension, row);

                    if (x && x !== NO_VALUE && y && y !== NO_VALUE && z && z !== NO_VALUE && name && name !== NO_VALUE) {
                        availableDimensions.push({
                            name: dimension,
                            displayName: getColumnDisplayName(reportColumns, dimension),
                        });
                        break;
                    }
                }
            });
            setDimensionsWithValues(availableDimensions);
        }
    }, [settings, rowData]);

    useEffect(() => {
        if (!dimensionsWithValues.length) {
            setIsDataParsed(false);
            return;
        }
        const { xMetricName, yMetricName, zMetricName } = settings;
        const filteredRowDataToUpdate = [];

        setAxisAndTooltipSettings(settings.metrics, xMetricName, yMetricName, zMetricName);

        const points = [];
        rowData.forEach(row => {
            const x = getMetricValue(xMetricName, row);
            const y = getMetricValue(yMetricName, row);
            const z = getMetricValue(zMetricName, row);
            const name = getMetricValue(selectedDimension.name, row);

            if (x && x !== NO_VALUE && y && y !== NO_VALUE && z && z !== NO_VALUE && name && name !== NO_VALUE) {
                filteredRowDataToUpdate.push(row);
                points.push(FORMAT_POINT_BY_WIDGET_TYPE[widget.type](x, y, z, name));
            }
        });
        if (widget.type === THREE_AXIS_WIDGETS_TYPES.Variwide) {
            points.sort((a, b) => b[1] - a[1]);
        }
        setSeries(points);
        setIsDataParsed(!!points.length);
    }, [settings, rowData, selectedDimension, dimensionsWithValues]);

    const topXSeries = series.slice(0, topXCount);
    const getChartByType = () =>
        widget.type === THREE_AXIS_WIDGETS_TYPES.Variwide ? (
            <VariwideChart tooltipFormat={tooltipFormat} points={topXSeries} height={height} />
        ) : (
            <BubbleChart
                yAxisOptions={yAxisOptions}
                xAxisOptions={xAxisOptions}
                tooltipFormat={tooltipFormat}
                points={topXSeries}
                height={height}
            />
        );

    return (
        <ChartWidgetWrapper
            widgetTitle={widget.name}
            widgetType={widget.type}
            widgetDataType={widget.dataType}
            showSpinner={loading}
            showNoData={!loading && !error && !isDataParsed}
            showError={!loading && !!error}
            allowedActions={allowedActions}
        >
            {!loading && !error && isDataParsed && (
                <>
                    {widget?.metricForTopX && series.length >= TOP_X_THRESHOLD && (
                        <TopXChartDataFilter
                            topXCount={topXCount}
                            rowDataLength={series.length}
                            onChange={setTopXCount}
                            widgetName={widget.name}
                        />
                    )}
                    {dimensionsWithValues.length > 1 && (
                        <DimensionsChartDataFilter
                            dimension={selectedDimension}
                            dimensions={dimensionsWithValues}
                            widgetName={widget.name}
                            onChange={setSelectedDimension}
                        />
                    )}
                    {getChartByType()}
                </>
            )}
        </ChartWidgetWrapper>
    );
};

ThreeAxisChartsWidget.propTypes = {
    dispatchWidgetVersions: PropTypes.func.isRequired,
    dispatchWidgetsLoading: PropTypes.func.isRequired,
    dashboardId: PropTypes.string.isRequired,
    widget: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(THREE_AXIS_WIDGETS_TYPES)).isRequired,
        query: PropTypes.shape({
            dimensions: PropTypes.arrayOf(PropTypes.string),
            metrics: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        options: PropTypes.shape({
            rows: PropTypes.arrayOf(PropTypes.string),
            columns: PropTypes.arrayOf(PropTypes.string),
            zAxis: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        metricForTopX: PropTypes.string,
    }).isRequired,
    globalFilters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            operator: PropTypes.oneOf(['in', 'not in']).isRequired,
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        })
    ),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate2: PropTypes.string,
    endDate2: PropTypes.string,
    compare: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    updatingGraphQL: PropTypes.bool,
    allowedActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTION_TYPES))),
    displayUnenriched: PropTypes.bool,
    onMenuClick: PropTypes.func,
    onCloneClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

ThreeAxisChartsWidget.defaultProps = {
    startDate2: null,
    endDate2: null,
    globalFilters: [],
    updatingGraphQL: false,
    allowedActions: [],
    displayUnenriched: false,
    onMenuClick: () => {},
    onCloneClick: () => {},
    onDeleteClick: () => {},
};

export default ThreeAxisChartsWidget;
