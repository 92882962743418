import React, { useCallback, useEffect } from 'react';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import css from './CheckoutEmbed.css';
import { stripeLoadingPromise } from '../stripeUtils';
import CheckoutAPI from '../service';
import ChevronDown from '../../resources/icons/chevron.svg';
import { SingularButton } from '../../components/widgets';
import { CHECKOUT_ENABLED_TIERS } from '../consts';
import PlanBanner from './PlanBanner';

const serviceApi = new CheckoutAPI();

const CheckoutEmbed = ({ priceId, quantity, onCancel, userTier }) => {
    const [stripe, setStripe] = React.useState(null);
    useEffect(async () => {
        const stripeRes = await stripeLoadingPromise;
        setStripe(stripeRes);
    }, [stripeLoadingPromise]);
    const fetchClientSecret = useCallback(async () => {
        if (priceId && quantity) {
            const res = await serviceApi.startPaymentSession(priceId, quantity);
            return res?.value?.clientSecret;
        }
        return Promise.resolve();
    }, []);
    const options = { fetchClientSecret };

    return (
        <>
            {stripe && userTier && (
                <div className={css.checkoutEmbedPage}>
                    <div className={css.nonStripeContainer}>
                        <SingularButton
                            text="Back to account plan"
                            type="secondary"
                            level="level2"
                            Icon={ChevronDown}
                            className={css.backButton}
                            iconClass={css.backIcon}
                            onClick={onCancel}
                        />
                        <PlanBanner plan={userTier} />
                    </div>
                    <EmbeddedCheckoutProvider stripe={stripe} options={options}>
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                </div>
            )}
        </>
    );
};

CheckoutEmbed.propTypes = {
    priceId: PropTypes.string,
    quantity: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    userTier: PropTypes.oneOf(CHECKOUT_ENABLED_TIERS).isRequired,
};

CheckoutEmbed.defaultProps = {
    priceId: '',
    quantity: 0,
};
export default CheckoutEmbed;
