import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './GridCustomFilter.css';

export default class GridCustomFilter extends Component {
    static handleFocus(event) {
        event.target.select();
    }

    constructor(props) {
        super(props);
        this.opsMap = {
            greaterThan: '>',
            lessThan: '<',
        };
        let currentValue = '';
        try {
            // in case the filter is re-rendered take the current value from ag-grid api and set it in the current state.
            const filterInstance = props.api.getFilterInstance(props.column);
            currentValue = this._getFilterValue(filterInstance === null ? {} : filterInstance);
        } catch (e) {}
        this.state = {
            parentModel: null,
            currentValue,
        };
        this.filterData = this.filterData.bind(this);
    }

    // when does this get called? how to test this?
    onParentModelChanged(parentModel) {
        this.setState({
            parentModel,
        });
    }

    filterData(event) {
        const { colDef } = this.props.column;

        let valueToFilter = event ? event.target.value : colDef.defaultFilterValue;

        if (typeof valueToFilter === 'string') {
            valueToFilter = valueToFilter.normalize('NFC');
        }

        this.setState({
            currentValue: valueToFilter,
        });

        if (['<', '>'].includes(valueToFilter)) {
            return;
        }

        let type = isNaN(valueToFilter) || colDef.type === 'dimension' ? 'contains' : 'equals';

        if (valueToFilter.match(/^\s*>\s*\d+(.\d+)?\s*$/)) {
            type = 'greaterThan';
            valueToFilter = valueToFilter.substring(1);
        } else if (valueToFilter.match(/^\s*<\s*\d+(.\d+)?\s*$/)) {
            type = 'lessThan';
            valueToFilter = valueToFilter.substring(1);
        }

        if (colDef.displayFormat?.type === 'percentage' && valueToFilter !== '') {
            const perc = Number(valueToFilter) / 100;
            valueToFilter = perc.toPrecision(12).replace(/0+$/, '');
        }

        this.props.onFloatingFilterChanged({
            model: {
                type,
                filter: valueToFilter,
            },
        });
    }

    _getPlaceHolder() {
        let ret = '';
        try {
            // use column api to figure out if the current column is the leftmost metric
            const columns = this.props.column.columnApi.getAllColumns();
            const metrics = columns.filter(col => col.colDef.type !== 'dimension');
            const metricIndex = metrics.findIndex(metric => this.props.column.colId === metric.colId);
            if (metricIndex === 0) {
                ret = 'e.g. <100';
            }
        } catch (e) {}
        return ret;
    }

    _getFilterValue(filterInstance) {
        const columnDisplayFormat = this.props.column.colDef.displayFormat;

        let { filterNumber = this.props.column.colDef.defaultFilterValue } = filterInstance;
        if (columnDisplayFormat && columnDisplayFormat.type === 'percentage' && filterNumber) {
            filterNumber = (parseFloat(filterNumber, 10) * 100).toString();
        }
        const prefix = this.opsMap[filterInstance.filter] || '';
        return `${prefix}${filterNumber || filterInstance.filterText || ''}`;
    }

    render() {
        const { currentValue } = this.state;
        const placeholder = this._getPlaceHolder();
        return (
            <div>
                <input
                    type="text"
                    onFocus={GridCustomFilter.handleFocus}
                    value={currentValue}
                    onChange={this.filterData}
                    placeholder={placeholder}
                    className={css.input}
                    autoComplete="off"
                />
            </div>
        );
    }

    componentDidMount() {
        if (this.props.column.colDef.defaultFilterValue) {
            setTimeout(() => {
                this.filterData();
            }, 0);
        }
    }
}

GridCustomFilter.propTypes = {
    onFloatingFilterChanged: PropTypes.func,
    column: PropTypes.objectOf(PropTypes.any),
    api: PropTypes.objectOf(PropTypes.any),
};

GridCustomFilter.defaultProps = {
    onFloatingFilterChanged: () => null,
    column: {},
    api: {},
};
