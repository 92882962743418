import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Spinner, Shelf, WarningMessage, SingularButton } from '../../components/widgets';
import EditOrg from './EditOrg';
import EditOrgAdAccounts from './EditOrgAdAccounts';
import EditSegment from './EditSegment';
import css from './EditShelf.css';

class EditShelf extends React.PureComponent {
    render() {
        const {
            edit,
            editError,
            editCancel,
            editConfirm,
            saving,
            editChange,
            toggleAdAccount,
            segments,
            segmentEdit,
            editSegmentStart,
            editSegmentChange,
            editSegmentSave,
            editSegmentCancel,
            adminMode,
            manualRun,
        } = this.props;
        let innerOrg;
        switch (true) {
            case edit && Object.keys(edit).indexOf('ad_accounts') > 0:
                innerOrg = <EditOrgAdAccounts toggleAdAccount={toggleAdAccount} edit={edit} />;
                break;
            default:
                innerOrg = (
                    <EditOrg
                        editChange={editChange}
                        edit={edit}
                        fields={edit && edit.distribution_fields}
                        editConfirm={editConfirm}
                    />
                );
                break;
        }
        return (
            <Shelf
                open={!!edit}
                headerText={`${edit ? edit.display_name : ''} Configuration`}
                onClose={editCancel}
                shelfSize="medium"
            >
                {!edit ? (
                    <Spinner show className={css.spinner} />
                ) : (
                    <React.Fragment>
                        <WarningMessage
                            show={!!editError && edit && !saving}
                            message={editError || ''}
                            showIcon={false}
                            type="error"
                        />
                        <div className={css.editContainer}>
                            <div className={css.editTop}>
                                {adminMode && edit.distribution.id ? (
                                    <div style={{ textAlign: 'right' }}>
                                        <SingularButton onClick={manualRun} type="secondary">
                                            Manual Run (Admin Only)
                                        </SingularButton>
                                    </div>
                                ) : null}
                                <h4>Top Level Configuration</h4>
                                {innerOrg}
                            </div>
                            {edit.distribution.id && edit.distribution.id > 0 && edit.distribution.active && (
                                <React.Fragment>
                                    <h4>Segment Configuration</h4>
                                    <div className={css.editContent}>
                                        {segments.map((seg) => (
                                            <EditSegment
                                                key={seg.id}
                                                segment={seg}
                                                editingThisSegment={segmentEdit && segmentEdit.segment_id === seg.id}
                                                editSegmentStart={editSegmentStart}
                                                editSegmentChange={editSegmentChange}
                                                editSegmentSave={editSegmentSave}
                                                editSegmentCancel={editSegmentCancel}
                                            />
                                        ))}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </Shelf>
        );
    }
}

export default withLocalize(EditShelf, 'locale');
