import { serializeSearchParams, parseSearchParams } from './url';

export const BACK_PATH_SEARCH_PARAMS_KEY = 'back_path';

export const calculatePath = (relativePath, searchParams = {}, searchParamToKeep) => {
    const currentSearchParams = parseSearchParams(document.location.hash);
    let newSearchParams;

    if (searchParamToKeep && currentSearchParams) {
        newSearchParams = { ...{ [searchParamToKeep]: currentSearchParams[searchParamToKeep] }, ...searchParams };
    } else {
        newSearchParams = searchParams;
    }
    return `/${relativePath}?${serializeSearchParams(newSearchParams)}`;
};

export const route = (relativePath, searchParams = {}, searchParamToKeep, hardRefresh = false) => {
    const path = calculatePath(relativePath, searchParams, searchParamToKeep);
    if (hardRefresh) {
        document.location.href = `?redir=#${path}`;
    } else {
        document.location.hash = path;
    }
};
