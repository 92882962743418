import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik-2';
import classNames from 'classnames';
import {
    partnerShape,
    eventShape,
    siteShape,
    templateShape,
    TemplateTypes,
    ParameterTypes,
    organizationOptionsShape,
} from '../../types';
import revenueEventPostbackCss from './RevenueEventPostbacks.css';
import EventPostbacksData from './EventPostbacksData';
import { getDefaultEventPostback, getDropdownOption } from './EventPostbacksTable';
import Dropdown from '../../../../components/widgets/Dropdown';
import { getUsedParameters } from '../utils';
import { getCheckboxCellProps } from '../tableCellsUtils';
import css from '../PartnerConfigurationShelf.css';

export const REVENUE_SELECTION_DATA_TEST_ID = 'revenue-postbacks-selection-dropdown';
export const ADMON_USER_LEVEL_REVENUE = '__ADMON_USER_LEVEL_REVENUE__';
export const REVENUE = '__REVENUE__*';
export const NOT_SUPPORTED = getDropdownOption('Not supported');
export const DEFAULT_REVENUE_POSTBACK_OPTION = getDropdownOption('Select');
export const REVENUE_TABLE_DATA_TEST_ID = 'revenue-postbacks-table';

export const RevenuePostbackOptions = {
    SEND_ALL_ADMON: 'SEND_ALL_ADMON',
    SEND_ALL_REVENUE: 'SEND_ALL_REVENUE',
    MANUALLY_SELECT: 'MANUALLY_SELECT',
};

const getSelectedRevenueOption = (revenueEventPostbacks, revenueEvents) => {
    if (!revenueEventPostbacks) {
        return null;
    }

    if (revenueEventPostbacks.length !== 1) {
        return RevenuePostbackOptions.MANUALLY_SELECT;
    }

    const event = revenueEvents.find(({ id }) => id === revenueEventPostbacks[0].eventId);
    if (event?.displayName === REVENUE) {
        return RevenuePostbackOptions.SEND_ALL_REVENUE;
    }

    if (event?.displayName === ADMON_USER_LEVEL_REVENUE) {
        return RevenuePostbackOptions.SEND_ALL_ADMON;
    }

    return RevenuePostbackOptions.MANUALLY_SELECT;
};

function RevenueEventPostbacks({ partner, site, templates, events, isReadonly, orgOptions }) {
    const { values, setValues, errors, touched, setTouched } = useFormikContext();
    const revenueEvents = events.filter(event => event.revenueEvent);
    const revenueOption = getSelectedRevenueOption(values.revenueEventPostbacks, revenueEvents);
    const revenueDropdownOption = revenueOption
        ? getDropdownOption(`STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.${revenueOption}`, revenueOption)
        : null;

    const [selectedRevenueOption, setSelectedRevenueOption] = useState(revenueDropdownOption);

    const revenueParameters = getUsedParameters(partner, site.platform, TemplateTypes.REVENUE, [
        ParameterTypes.POSTBACK,
    ]);

    const addEmptyRevenuePostback = () => setValues({ ...values, revenueEventPostbacks: [] });
    const setRevenuePostbacksValues = revenueEventPostbacks => setValues({ ...values, revenueEventPostbacks });
    const setTouchedRevenuePostbacks = revenueEventPostbacks => setTouched({ ...touched, revenueEventPostbacks });

    const getRevenueSelectionEvents = option => {
        const selectedOption = option?.name || selectedRevenueOption?.name;

        if (selectedOption === RevenuePostbackOptions.MANUALLY_SELECT) {
            return revenueEvents.filter(event => event.displayName !== REVENUE);
        } else if (selectedOption === RevenuePostbackOptions.SEND_ALL_REVENUE) {
            return revenueEvents.filter(event => event.displayName === REVENUE);
        } else if (selectedOption === RevenuePostbackOptions.SEND_ALL_ADMON) {
            return revenueEvents.filter(event => event.displayName === ADMON_USER_LEVEL_REVENUE);
        }

        return revenueEvents;
    };

    const revenueTableCells = [
        getCheckboxCellProps(
            'sendRevenue',
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.EVENT_POSTBACKS_TABLE.INCLUDES_REVENUE',
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.EVENT_POSTBACKS_TABLE.INCLUDES_REVENUE_TOOLTIP',
            {},
            false,
            { tdClassName: classNames(css.tableCell, css.checkboxCell) }
        ),
    ];

    const selectedRevenueOptions = Object.values(RevenuePostbackOptions).map(field => ({
        name: field,
        display_name: `STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.${field}`,
    }));

    const postbackExtraFields = { sendRevenue: true };
    const revenueEventTemplate = templates.find(template => template.type === TemplateTypes.REVENUE);

    const onRevenuePostbackOptionChanged = selectedOption => {
        if (selectedOption === selectedRevenueOption) {
            return;
        }

        setSelectedRevenueOption(selectedOption);

        if (selectedOption.name === RevenuePostbackOptions.MANUALLY_SELECT) {
            addEmptyRevenuePostback();
            return;
        }

        const revenueSelectionEvents = getRevenueSelectionEvents(selectedOption);
        // This should be REVENUE or ADMON_USER_LEVEL_REVENUE event only due to user selection
        const event = revenueSelectionEvents[0];
        if (!event) {
            setRevenuePostbacksValues(null);
            return;
        }

        setRevenuePostbacksValues([
            getDefaultEventPostback(partner, revenueEventTemplate, revenueParameters, {
                ...postbackExtraFields,
                eventId: event.id,
            }),
        ]);
    };

    const dropdownDisabled = !revenueEventTemplate || !revenueEvents.length || isReadonly;
    const defaultDropdownSelection = dropdownDisabled ? NOT_SUPPORTED : DEFAULT_REVENUE_POSTBACK_OPTION;

    return (
        <div className={css.section}>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.REVENUE_EVENTS_POSTBACKS" />
            </div>
            <div className={revenueEventPostbackCss.topText}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.IN_APP_EVENTS.REVENUE_EVENTS_DROPDOWN_TEXT" />
            </div>
            <Dropdown
                containerClass={revenueEventPostbackCss.revenuePostbacksOptions}
                popperClass={revenueEventPostbackCss.revenuePostbacksOptions}
                wrapperClass={revenueEventPostbackCss.dropdownWrapper}
                items={selectedRevenueOptions}
                onSelection={onRevenuePostbackOptionChanged}
                dropDownBodyTestId={REVENUE_SELECTION_DATA_TEST_ID}
                disabled={dropdownDisabled}
                selected={selectedRevenueOption || defaultDropdownSelection}
            />
            <EventPostbacksData
                configuredEvents={values.revenueEventPostbacks}
                events={getRevenueSelectionEvents()}
                template={revenueEventTemplate}
                partner={partner}
                site={site}
                extraTableCells={revenueTableCells}
                postbackExtraFields={postbackExtraFields}
                tableDataTestId={REVENUE_TABLE_DATA_TEST_ID}
                templateType={TemplateTypes.REVENUE}
                setPostbacks={setRevenuePostbacksValues}
                setTouched={setTouchedRevenuePostbacks}
                errors={errors.revenueEventPostbacks}
                touched={touched.revenueEventPostbacks}
                isReadonly={isReadonly}
                orgOptions={orgOptions}
            />
        </div>
    );
}

RevenueEventPostbacks.propTypes = {
    partner: partnerShape.isRequired,
    site: siteShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    templates: PropTypes.arrayOf(templateShape),
    events: PropTypes.arrayOf(eventShape),
    isReadonly: PropTypes.bool,
};

RevenueEventPostbacks.defaultProps = {
    templates: [],
    events: [],
    isReadonly: false,
};

export default RevenueEventPostbacks;
