import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '../../resources/svg/info-2.svg';
import css from '../styles.css';
import SourcesAvailabilityTable from './sourcesAvailabilityTable';

const SourcesAvailability = ({ field, availabilityBySource, sourcesDict }) => {
    const { display_name: displayName, name: fieldName, tooltip } = field;

    return (
        <div className={css.sourcesAvailability}>
            {tooltip && (
                <div className={css.dimensionHint}>
                    <InfoIcon className={css.infoIcon} />
                    <span className={css.dimensionName}>{displayName}</span>
                    <span>: {tooltip}</span>
                </div>
            )}
            <SourcesAvailabilityTable
                fieldName={fieldName}
                availabilityBySource={availabilityBySource}
                sourcesDict={sourcesDict}
            />
        </div>
    );
};

SourcesAvailability.propTypes = {
    availabilityBySource: PropTypes.objectOf(PropTypes.any),
    field: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        tooltip: PropTypes.string,
    }).isRequired,
};

SourcesAvailability.defaultProps = {
    availabilityBySource: {},
};

export default SourcesAvailability;
