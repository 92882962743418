import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import 'react-select/dist/react-select.css';
import css from './AutoComplete.css';
import ClearIcon from '../../resources/svg/clear_field.svg';
import TagInputSuggestion from './TagInputSuggestion';
import SelectMenuList from './SelectMenuList';
import SelectGroup from './SelectGroup';
import Select from './Select';
import AutoCompleteSingleValue from './AutoCompleteSingleValue';

const ClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => {
    return <ClearIcon className={css.clearIcon} {...restInnerProps} ref={ref} />;
};

class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: props.defaultValue,
            searchValue: '',
        };
        this.handleChange = this._handleChange.bind(this);
        this.handleInputChange = this._handleInputChange.bind(this);
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.setFocus) {
            this.selectRef.current.inputRef.focus();
        }
    }

    componentDidUpdate() {
        if (this.props.setFocus) {
            this.selectRef.current.inputRef.focus();
        }
    }

    _handleChange(selectedOption) {
        let searchValue = null;
        if (selectedOption) {
            searchValue = typeof selectedOption === 'string' ? selectedOption : selectedOption.label;
        }
        this.setState({
            selectedOption,
            searchValue,
        });
        if (typeof selectedOption === 'object') {
            this.props.onChange(selectedOption);
        }
    }

    _handleInputChange(newVal, eventAction) {
        const { action } = eventAction;
        const {
            searchable,
            selectOptions: { isSearchable },
        } = this.props;
        if ((action !== 'input-change' && newVal === '') || !(searchable || isSearchable)) {
            return;
        }
        this.props.onInputChange(newVal);
    }

    render() {
        const { selectedOption } = this.state;
        const {
            placeholder,
            selectOptions,
            containerStyle,
            containerClass,
            isCreatable,
            creatableOptions,
            onBlur,
            controlledValue,
            error,
            big,
            hideDropdownIndicator,
            virtualScrolling,
            dataTestId,
            ariaLabel,
            copiable,
        } = this.props;
        const selectedValue = selectOptions?.disabled ? selectedOption || '' : selectedOption;
        const v = controlledValue !== null ? [controlledValue] : selectedValue;
        const subComponents = {
            SingleValue: AutoCompleteSingleValue,
            Option: TagInputSuggestion,
            IndicatorSeparator: selectOptions.isClearable ? components.IndicatorSeparator : null,
            ClearIndicator: selectOptions.isClearable ? ClearIndicator : null,
            DropdownIndicator:
                selectOptions && selectOptions.options && selectOptions.options.length && !hideDropdownIndicator
                    ? components.DropdownIndicator
                    : null,
        };

        if (virtualScrolling) {
            subComponents.MenuList = SelectMenuList;
            subComponents.Group = SelectGroup;
        }

        const baseProps = {
            value: v,
            onChange: this.handleChange,
            clearValue: this.clearValue,
            placeholder: <Translate id={placeholder} />,
            onCloseResetsInput: false,
            className: `${css.singularSelect} ${error ? css.error : ''} ${big ? css.big : ''}`,
            components: subComponents,
            isCopiable: copiable,
            isDisabled: selectOptions.disabled,
            isClearable: selectOptions.isClearable,
            isLoading: selectOptions.isLoading,
            loadingMessage: () => null,
            classNamePrefix: 'Select',
            onBlur,
            onFocus: () => {
                // this is used to select the input content on focus in case the field is searchable
                if (!selectOptions || !selectOptions.isSearchable) {
                    return;
                }
                try {
                    this.selectRef.current.inputRef.select();
                } catch (e) {}
            },
            ref: this.selectRef,
        };

        const creatableProps = {
            ...baseProps,
            ...selectOptions,
            ...creatableOptions,
        };

        const selectProps = {
            ...baseProps,
            ...selectOptions,
            'aria-label': ariaLabel,
            onInputChange: this.handleInputChange,
            noResultsText: '',
            onBlurResetsInput: false,
            dataTestId,
        };

        return (
            <div
                className={classNames(css.container, containerClass, { [css.disabled]: selectOptions.disabled })}
                style={containerStyle}
                data-testid={dataTestId}
            >
                {isCreatable ? <Creatable {...creatableProps} /> : <Select {...selectProps} />}
            </div>
        );
    }
}

AutoComplete.propTypes = {
    defaultValue: PropTypes.any, // can be value or object with value
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    selectOptions: PropTypes.objectOf(PropTypes.any),
    containerStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isCreatable: PropTypes.bool,
    creatableOptions: PropTypes.objectOf(PropTypes.any),
    onBlur: PropTypes.func,
    controlledValue: PropTypes.any,
    searchable: PropTypes.bool,
    copiable: PropTypes.bool,
    error: PropTypes.string,
    big: PropTypes.bool,
    hideDropdownIndicator: PropTypes.bool,
    virtualScrolling: PropTypes.bool,
    setFocus: PropTypes.bool,
    dataTestId: PropTypes.string,
    ariaLabel: PropTypes.string,
};

AutoComplete.defaultProps = {
    defaultValue: '',
    onChange: () => {},
    onInputChange: () => {},
    placeholder: '',
    selectOptions: {},
    containerStyle: {},
    containerClass: {},
    isCreatable: false,
    creatableOptions: {},
    onBlur: () => {},
    controlledValue: null,
    searchable: false,
    copiable: false,
    error: '',
    big: false,
    hideDropdownIndicator: false,
    virtualScrolling: false,
    setFocus: false,
    dataTestId: undefined,
    ariaLabel: '',
};

export default AutoComplete;
