import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { trackMixpanelEvent } from '../../utils/general';
import css from './DataShelf.css';
import { SingularButton, TextField, Datepicker, WizardFooter, WarningMessage } from '../../components/widgets';
import Spinner from '../../components/widgets/Spinner';
import DataSourcesService from '../service';
import Table from '../../components/widgets/Table';
import { FILE_BASED_AUTH_TYPES } from '../utils';
import RadioButtonsGroup from '../../components/widgets/RadioButtonsGroup';

const mixpanelPrefix = 'Data Connectors';
const DEFAULT_DATE_RANGE_LIMIT = 14;
const DATA_REFRESH_CAPACITY = 3;
const DATA_REFRESH_MAX_DAYS_BACK = 180;
const DATA_REFRESH_MIN_DAYS_BACK = 0;
const DAY_TO_MS = 24 * 60 * 60 * 1000;
const MAX_NAME_LENGTH = 30;

const WARNING_SUCCESS = 'success';
const WARNING_WARNING = 'warning';
const WARNING_ERROR = 'error';

const BE_ERRORS_TRANSLATIONS = {
    'DATES NOT OLD ENOUGH': 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.DATES_NOT_OLD_ENOUGH',
    'DATES TOO OLD': 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.DATES_TOO_OLD',
    'DATES INTERVAL EXCEEDED': 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.DATES_INTERVAL_EXCEEDED',
    'CAPACITY EXCEEDED': 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.CAPACITY_EXCEEDED',
    'DISABLED DATA REFRESH FOR ORG': 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.ORG_DISABLED_DATA_REFRESH',
    'UAN NOT SUPPORTS DATA REFRESH':
        'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.UAN_NOT_SUPPORTS_DATA_REFRESH',
};

const dataSourcesService = new DataSourcesService();

function DataRefresh({ uanId, uanName, uanStatus, onClose, translate, innerState, setInnerState }) {
    const [dataRefreshConfig, setDataRefreshConfig] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [reasonSelected, setReasonSelected] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [dataRefreshName, setDataRefreshName] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [warningType, setWarningType] = useState(WARNING_WARNING);
    const [updateClicked, setUpdateClicked] = useState(false);
    const [sendingDataRefresh, setSendingDataRefresh] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const minDaysBack = dataRefreshConfig?.validation_dict?.min_days_back || DATA_REFRESH_MIN_DAYS_BACK;
    const maxDaysBack = dataRefreshConfig?.validation_dict?.max_days_back || DATA_REFRESH_MAX_DAYS_BACK;
    const getDateForComponent = daysBack => new Date(new Date() - daysBack * DAY_TO_MS).toLocaleDateString('sv-SE');
    const minDate = getDateForComponent(maxDaysBack - 1);
    const maxDate = getDateForComponent(minDaysBack + 1);
    const capacity = dataRefreshConfig?.capacity || DATA_REFRESH_CAPACITY;
    const remainingPulls =
        dataRefreshConfig?.remaining_pulls === undefined ? capacity : dataRefreshConfig?.remaining_pulls;
    const usedCap = capacity - remainingPulls;
    const rangeLimit = dataRefreshConfig?.validation_dict?.dates_interval || DEFAULT_DATE_RANGE_LIMIT;
    const capacityReached = remainingPulls <= 0;
    const isFileBased = dataRefreshConfig?.channel && FILE_BASED_AUTH_TYPES.includes(dataRefreshConfig?.channel);
    const hasEtlDataDestinations = dataRefreshConfig?.has_etl_data_destinations;

    const metadataConfig = [
        {
            name: 'dataConnector',
            displayName: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.DATA_CONNECTOR'),
            headerAlign: 'center',
            cellProps: {},
        },
        {
            name: 'dataUpdateName',
            displayName: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.UPDATE_NAME'),
            headerAlign: 'center',
            cellProps: {},
        },
        {
            name: 'submittedDate',
            displayName: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.SUBMISSION_DATE'),
            headerAlign: 'center',
            cellProps: {},
        },
        {
            name: 'status',
            displayName: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.STATUS'),
            headerAlign: 'center',
            cellProps: {},
        },
        {
            name: 'submittedBy',
            displayName: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.SUBMITTED_BY'),
            headerAlign: 'center',
            cellProps: {},
        },
        {
            name: 'updateDates',
            displayName: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.UPDATE_DATES'),
            headerAlign: 'center',
            cellProps: {},
        },
    ];

    const _parseStatus = status => {
        switch (status) {
            case 'STARTED':
                return translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.IN_PROGRESS');
            case 'SUCCESS':
                return translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.DONE');
            case 'FAILURE':
                return translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY.FAILED');
            default:
                return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        }
    };

    const _parseHistory = () => {
        const data = [];
        const history = dataRefreshConfig?.request_history;
        if (!history) {
            return data;
        }
        for (const row of history) {
            data.push({
                dataConnector: row.uan_name,
                dataUpdateName: row.name,
                submittedDate: row.created_at.replace('T', ' '),
                status: _parseStatus(row.status),
                submittedBy: row.user,
                updateDates: `${row.start_date}<br>-<br>${row.end_date}`,
            });
        }
        return data;
    };

    function resetInputs() {
        setUpdateClicked(false);
        setReasonSelected('');
        setDataRefreshName('');
        setStartDate(maxDate);
        setEndDate(maxDate);
        setInnerState({ ...innerState, reasonSelected, dataRefreshName, startDate, endDate});
    }

    function handleDataRefreshResponse(dataRefreshResponse) {
        const daysNumber = Math.ceil((new Date() - new Date(startDate).getTime()) / DAY_TO_MS) + 1;
        const eventProps = {
            'DC name': uanName,
            'DC channel': dataRefreshConfig.channel,
            'UAN status': uanStatus,
            'Label used': dataRefreshName,
            'Number of days ago': daysNumber,
            'Start date': startDate,
        };
        setSendingDataRefresh(false);
        if (dataRefreshResponse.error) {
            let msg = 'Issues found in your request:<br>';
            for (const error of dataRefreshResponse.error) {
                msg += `${translate(BE_ERRORS_TRANSLATIONS[error])}<br>`;
            }
            setWarningMessage(msg);
            setWarningType(WARNING_ERROR);
        } else {
            setWarningMessage(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.SUBMIT_SUCCESSFUL'));
            setWarningType(WARNING_SUCCESS);
            setDataRefreshConfig(dataRefreshResponse);
            setInnerState({ ...innerState, dataRefreshConfig: dataRefreshResponse});
            resetInputs();
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 2000);
            eventProps['Pull tag'] = dataRefreshResponse?.request_history[0].pull_tag;
        }
        trackMixpanelEvent(mixpanelPrefix, 'Submit Data Refresh', eventProps);
    }

    function getErrorMesssage() {
        const emptyFields = [];
        if (!reasonSelected) {
            emptyFields.push(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.ERROR_MSG_REASON'));
        } else if (reasonSelected === 'other' && !otherReason) {
            emptyFields.push(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.ERROR_MSG_OTHER'));
        }
        if (!dataRefreshName) {
            emptyFields.push(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.ERROR_MSG_NAME'));
        }

        if (emptyFields.length === 0) {
            return '';
        }
        return `${translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.EMPTY_FIELDS_MSG')} ${emptyFields.join(
            ', '
        )}`;
    }

    function submitDataRefresh() {
        const errorMessage = getErrorMesssage();
        setWarningMessage(errorMessage);
        if (errorMessage) {
            setSendingDataRefresh(false);
            setWarningMessage(errorMessage);
            setWarningType(WARNING_ERROR);
            return;
        }

        const updateParams = {
            uan_id: uanId,
            start_date: startDate,
            end_date: endDate,
            name: dataRefreshName,
            reason: reasonSelected === 'other' ? otherReason : reasonSelected,
        };
        (async () => {
            try {
                const dataRefreshResponse = await dataSourcesService.sendDataRefreshJob(updateParams);
                handleDataRefreshResponse(dataRefreshResponse);
            } catch (e) {
                setWarningMessage(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.GENERAL_ERROR'));
                setWarningType(WARNING_ERROR);
            } finally {
                setSendingDataRefresh(false);
            }
        })();
    }

    const handleDatesChanged = dates => {
        setStartDate(dates.start_date);
        setEndDate(dates.end_date);
        setInnerState({ ...innerState, startDate: dates.start_date, endDate: dates.end_date });
    };

    useEffect(() => {
        if (dataRefreshConfig && capacityReached && warningType !== 'success') {
            setWarningMessage(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.CAPACITY_EXCEEDED'));
            setWarningType(WARNING_WARNING);
            setStartDate(maxDate);
            setEndDate(maxDate);
            setInnerState({ ...innerState, startDate, endDate });
        }
        const curStartDate = innerState?.startDate || maxDate;
        const curEndDate = innerState?.endDate || maxDate;
        setStartDate(curStartDate);
        setEndDate(curEndDate);
        setInnerState({ ...innerState, startDate: curStartDate, endDate: curEndDate });
    }, [dataRefreshConfig]);

    useEffect(() => {
        if (Object.keys(innerState).length) {
            setDataRefreshConfig(innerState.dataRefreshConfig);
            setReasonSelected(innerState.reasonSelected);
            setOtherReason(innerState.otherReason);
            setDataRefreshName(innerState.dataRefreshName);
            setStartDate(innerState.startDate);
            setEndDate(innerState.endDate);
            setIsLoading(false);
            return;
        }
        (async () => {
            try {
                const dataConfig = await dataSourcesService.getDataRefreshConfig(uanId);
                setIsLoading(false);
                setDataRefreshConfig(dataConfig);
                setInnerState({ ...innerState, dataRefreshConfig: dataConfig });
            } catch (e) {
                setWarningMessage(translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.BE_ERRORS.GENERAL_ERROR'));
                setWarningType(WARNING_ERROR);
            }
        })();
        trackMixpanelEvent(mixpanelPrefix, 'Entered Data Refresh shelf', {});
    }, []);

    function getFooterBtns() {
        return [
            <SingularButton onClick={onClose} type="flat">
                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.CANCEL_BUTTON" />
            </SingularButton>,
            <SingularButton
                showV={showSuccess}
                showSpinner={sendingDataRefresh}
                onClick={() => {
                    setUpdateClicked(true);
                    setSendingDataRefresh(true);
                    submitDataRefresh();
                }}
                disabled={capacityReached}
            >
                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.SUBMIT_BUTTON" />
            </SingularButton>,
        ];
    }

    return (
        <div className={css.content}>
            <WarningMessage
                className={css.warningMessage}
                message={warningMessage}
                show={warningMessage !== ''}
                onClose={() => setWarningMessage('')}
                type={warningType}
            />
            <Spinner show={isLoading} expanded />
            {!isLoading && (
                <React.Fragment>
                    {warningType === WARNING_SUCCESS && capacityReached && (
                        <WarningMessage
                            className={css.warningMessage2}
                            message={translate(BE_ERRORS_TRANSLATIONS['CAPACITY EXCEEDED'])}
                            type={WARNING_WARNING}
                        />
                    )}
                    <p dataTestId="intro_msg">
                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.INTRO" />
                        {hasEtlDataDestinations && (
                            <p dataTestId="has_etl_data_destinations">
                                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HAS_ETL_DATA_DESTINATIONS" />
                            </p>
                        )}
                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.FAQ" />
                    </p>
                    {isFileBased && (
                        <p dataTestId="intro_file_based">
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.FILE_BASED_MSG" />
                        </p>
                    )}
                    <div className={css.borderDivider} />
                    <strong>
                        {' '}
                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.DATE_RANGE" />{' '}
                    </strong>
                    <p className={css.rightSide}>
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.CAPACITY"
                            className={css.rightSide}
                        />
                        <strong>
                            {' '}
                            {usedCap} / {capacity}
                        </strong>
                    </p>
                    <br />
                    <Datepicker
                        dataTestId="date_picker"
                        appendToBody
                        minDate={minDate}
                        maxDate={maxDate}
                        startDate={startDate}
                        endDate={endDate}
                        onApply={handleDatesChanged}
                        ranges={null}
                        dateLimit={{ days: rangeLimit - 1 }}
                    />{' '}
                    <br />
                    <br />
                    <strong>
                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.REASON" />
                    </strong>
                    <br />
                    <p>
                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.WHY_NEED_UPDATE" />
                    </p>
                    <RadioButtonsGroup
                        radioItems={[
                            {
                                label: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.PARTNER_REQUEST',
                                checked: reasonSelected === 'partnerRequest',
                                id: 'partnerRequest',
                            },
                            {
                                label: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.USER_ERROR',
                                checked: reasonSelected === 'userError',
                                id: 'userError',
                            },
                            {
                                label: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.OTHER',
                                checked: reasonSelected === 'other',
                                id: 'other',
                            },
                        ]}
                        onChange={({ id }) => {
                            setReasonSelected(id);
                            setInnerState({ ...innerState, reasonSelected: id });
                        }}
                        disabled={capacityReached}
                    />
                    {reasonSelected === 'other' && (
                        <TextField
                            placeholder={translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.OTHER_PLACEHOLDER')}
                            disabled={reasonSelected !== 'other'}
                            mandatory={reasonSelected === 'other'}
                            value={otherReason}
                            onChange={v => {
                                setOtherReason(v);
                                setInnerState({ ...innerState, otherReason: v });
                            }}
                            error={
                                updateClicked && reasonSelected === 'other' && !otherReason
                                    ? translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.EMPTY_OTHER')
                                    : ''
                            }
                        />
                    )}
                    <br />
                    <strong>
                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.NAME" />
                    </strong>
                    <TextField
                        dataTestId="update_name"
                        className={css.fullWidth}
                        placeholder={translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.NAME_PLACEHOLDER')}
                        mandatory
                        value={dataRefreshName}
                        onChange={v => {
                            setDataRefreshName(v);
                            setInnerState({ ...innerState, dataRefreshName: v });
                        }}
                        maxLength={MAX_NAME_LENGTH}
                        error={
                            updateClicked && !dataRefreshName
                                ? translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.EMPTY_NAME')
                                : ''
                        }
                        disabled={capacityReached}
                    />
                    <div className={css.borderDivider} />
                    <div>
                        <strong>
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.HISTORY_HEADER" />
                        </strong>
                        <p className={css.rightSide}>
                            <Translate
                                id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_REFRESH.CAPACITY"
                                className={css.rightSide}
                            />{' '}
                            <strong>
                                {usedCap} / {capacity}
                            </strong>
                        </p>
                    </div>
                    <br />
                    <Table
                        dataTestId="history_table"
                        tableClass={css.tableContainer}
                        thClass={css.thead}
                        tbodyClass={css.tbody}
                        data={_parseHistory()}
                        metadata={metadataConfig}
                    />
                    <WizardFooter buttons={getFooterBtns()} />
                </React.Fragment>
            )}
        </div>
    );
}

DataRefresh.propTypes = {
    uanId: PropTypes.number,
    onClose: PropTypes.func,
    uanName: PropTypes.string,
    uanStatus: PropTypes.string,
    translate: PropTypes.func,
    innerState: PropTypes.objectOf(PropTypes.any),
    setInnerState: PropTypes.func,
};

DataRefresh.defaultProps = {
    uanId: null,
    onClose: () => {},
    translate: txt => txt,
    uanName: '',
    uanStatus: '',
    innerState: {},
    setInnerState: () => null,
};

export default DataRefresh;
