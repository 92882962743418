import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import AiStar from 'resources/icons/creatives/ai_star.svg';
import Minimize from 'resources/svg/icons/minimize.svg';
import Clear from 'resources/icons/eraser.svg';
import Maximize from 'resources/svg/icons/maximize.svg';
import classNames from 'classnames';
import css from './DimensionsFilterHeader.css';

export const FILTERS_SECTION_TITLE = 'STATIC.PAGES.TAGS_VISIBILITY.FILTERS_SECTION_TITLE';

const DimensionsFilterHeader = ({ onClearClicked, expanded, setExpanded, filtersCount, disabled }) => {
    const CollapseIcon = expanded ? Minimize : Maximize;

    return (
        <div className={css.header}>
            <div className={css.title}>
                <AiStar className={css.aiIcon} />
                <Translate id={FILTERS_SECTION_TITLE} />
                {!!filtersCount && (
                    <div className={css.filtersIndication}>
                        <Translate id="STATIC.PAGES.TAGS_VISIBILITY.NUMBER_OF_FILTERS" data={{ count: filtersCount }} />
                        <div
                            className={classNames(css.clearAll, { [css.disabled]: disabled })}
                            onClick={onClearClicked}
                        >
                            <Clear className={css.clearIcon} />
                            <Translate id="STATIC.BUTTONS.CLEAR_ALL" />
                        </div>
                    </div>
                )}
            </div>
            <CollapseIcon className={css.collapsedIcon} onClick={() => setExpanded(value => !value)} />
        </div>
    );
};

DimensionsFilterHeader.propTypes = {
    onClearClicked: PropTypes.func,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
    filtersCount: PropTypes.number,
    disabled: PropTypes.bool,
};

DimensionsFilterHeader.defaultProps = {
    filtersCount: 0,
    expanded: true,
    disabled: false,
};

export default DimensionsFilterHeader;
