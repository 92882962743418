import { reportBase } from './reportsBase';
import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';

export default {
    type: reportTypes.adMonetization,
    fields: [...reportBase.fields, 'custom_dimensions', 'enrichmentDimensions', 'adMonDAUMetrics'],
    visibleRule: rules[reportTypes.adMonetization],
    dataSelector: {
        ...reportBase.dataSelector,
        custom_dimensions: 'custom_dimensions',
        chart: 'chart',
        tableDataFormatted: 'reportData',
        chartDataFormatted: 'chartData',
        enrichmentDimensions: 'enrichmentDimensions',
        unenriched: 'unenriched',
        adMonDAUMetrics: 'adMonDAUMetrics',
    },
    is_admon_report: true,
};
