import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <strong>What:</strong><span> Configure attribution settings for the ad networks you work with, so that Singular can start tracking how many installs your apps have had as a result of advertising with each network.</span>
            </p>
            <p>
                <span>To configure a network:</span>
            </p>
            <ol>
                <li>
                    <span>In the </span><strong>Partner Configuration</strong><span
                > page, add the network.</span>
                </li>
                <li>
                    <span>In the settings window that opens, select your app and app site, and configure the attribution settings. Depending on the partner, settings include:</span>
                </li>
            </ol>
            <ul>
                <li>
                    <strong>View-through tracking:</strong><span> Do you want to attribute installs by ads users viewed as well as clicked?</span>
                </li>
                <li>
                    <strong>Lookback window: </strong><span>When the Singular tracker registers an app install, how far back should it look for a corresponding click (or view) that led to the install? The default window is 7 days.</span>
                </li>
                <li>
                    <strong>Postbacks: </strong><span>In addition to notifying your networks about installs attributed to them, Singular can also send postbacks about re-engagements and in-app events. This varies per partner.&nbsp;</span>
                </li>
            </ul>
            <div>
                <strong>Note:</strong><span> In addition to networks, there are other types of partners you can configure - for example, you can set up your BI platform to receive postbacks from Singular.</span>
            </div>
            <div>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/115000483946-Partner-Configuration" target="_blank">See out Partner Configuration guide</a>
            </div>
        </div>
    );
}
