import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from './Tooltip';
import css from './Table.css';
import CellLoader from './CellLoader';
import { getClickableText } from './TableCell';

const TableCellIcon = props => {
    const {
        iconElement,
        value,
        color,
        tdStyle,
        tdClassName,
        tooltip,
        text,
        shouldTranslateToolTip,
        isLoading,
        subValue,
        onSubValueClick,
        isBold,
        row,
        onClick,
        containerClassname,
    } = props;

    const onClickFunc = onClick && onClick[value];

    const onContentClick = () => {
        if (!onClick || !onClick[value]) return;
        onClickFunc(row);
    };

    const clickableCellSubvalue = subValue && getClickableText(subValue, onSubValueClick, row);
    let iconText = '';
    if (typeof text[value] === 'string') {
        iconText = `${text[value]}${subValue ? '.' : ''}`;
    } else {
        iconText = text[value];
    }
    const displayedContent = (
        <div
            onClick={onContentClick}
            className={containerClassname}
            style={{
                display: 'flex',
                cursor: onClickFunc ? 'pointer' : 'default',
                justifyContent: text[value] ? 'start' : 'center',
                alignItems: 'center',
            }}
        >
            {isLoading ? (
                <CellLoader />
            ) : (
                <>
                    <span className={css.iconContainer}>
                        {iconElement[value] ? (
                            iconElement[value]
                        ) : (
                            <span className={css.booleanIcon} style={{ backgroundColor: color[value] }} />
                        )}
                    </span>
                    {text[value] && (
                        <span className={classNames([css.iconText, { [css.boldText]: isBold }])}>
                            {iconText}
                            {clickableCellSubvalue ? (
                                <span className={css.iconSubText}> {clickableCellSubvalue} </span>
                            ) : (
                                <span className={css.iconSubText} dangerouslySetInnerHTML={{ __html: subValue }} />
                            )}
                        </span>
                    )}
                </>
            )}
        </div>
    );

    return (
        <td style={{ ...tdStyle }} className={tdClassName}>
            {tooltip ? (
                <Tooltip
                    open={tooltip ? undefined : false}
                    placement="top"
                    title={tooltip[value]}
                    classes={{ tooltip: css.tooltip }}
                    titleTranslationKey={shouldTranslateToolTip ? tooltip[value] : null}
                >
                    {displayedContent}
                </Tooltip>
            ) : (
                displayedContent
            )}
        </td>
    );
};

TableCellIcon.propTypes = {
    tdStyle: PropTypes.objectOf(PropTypes.any),
    text: PropTypes.objectOf(PropTypes.any),
    iconElement: PropTypes.objectOf(PropTypes.any),
    value: PropTypes.any,
    color: PropTypes.objectOf(PropTypes.any),
    tooltip: PropTypes.objectOf(PropTypes.any),
    shouldTranslateToolTip: PropTypes.bool,
    isLoading: PropTypes.bool,
    tdClassName: PropTypes.string,
    subValue: PropTypes.string,
    row: PropTypes.shape({
        displayName: PropTypes.string,
        uanId: PropTypes.number,
    }),
    isBold: PropTypes.bool,
    onSubValueClick: PropTypes.func,
    onClick: PropTypes.any,
    containerClassname: PropTypes.string,
};

TableCellIcon.defaultProps = {
    tdStyle: {},
    tdClassName: null,
    text: {},
    iconElement: {},
    value: '',
    subValue: '',
    color: {},
    tooltip: null,
    shouldTranslateToolTip: false,
    isLoading: false,
    isBold: false,
    row: {},
    onSubValueClick: () => {},
};

export default TableCellIcon;
