import React from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../components/widgets';
import css from './SearchArea.css';

const EMPTY_OPTIONS = [];

function SearchArea({ searchingText, onChange, placeholder, disabled }) {
    return (
        <AutoCompleteField
            containerClass={css.appSearch}
            onInputChange={onChange}
            onChange={onChange}
            value={searchingText}
            placeholder={placeholder}
            searchable
            clearable={!!searchingText}
            controlled
            loading={false}
            isMulti={false}
            debounceTime={500}
            options={EMPTY_OPTIONS}
            disabled={disabled}
        />
    );
}

SearchArea.propTypes = {
    searchingText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

SearchArea.defaultProps = {
    searchingText: '',
    placeholder: 'STATIC.PAGES.BANNERS.APP_SEARCH_PLACEHOLDER',
    disabled: false,
};

export default SearchArea;
