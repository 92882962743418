import React from 'react';

export default class SelectGroup extends React.PureComponent {
    render() {
        const {
            data: { label },
        } = this.props;
        return (
            <div className="Select__group">
                <div className="Select__group-heading">{label}</div>
            </div>
        );
    }
}
