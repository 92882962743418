import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import { trackMixpanelEvent } from 'utils/general';
import { SYSTEM_ALERTS_SHELF_OPENED, TRACK_EVENT_PREFIX } from 'alerts/consts';
import { useFetchAllOrgDataConnectorAlerts, useSaveSystemAlerts } from 'alerts/hooks';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Shelf, TableCellImgLabel, TextField } from '../../components/widgets';
import EmailIcon from '../../resources/icons/dataConnectors/Email.svg';
import S3Icon from '../../resources/icons/dataConnectors/S3.svg';
import GDriveIcon from '../../resources/icons/dataConnectors/Gdrive.svg';
import APIIcon from '../../resources/icons/dataConnectors/api.svg';
import SFTPIcon from '../../resources/icons/dataConnectors/sftp.svg';
import css from './SystemAlertShelf.css';
import MonetizationIcon from '../../../../dashboard/img/icons/monetization.svg';
import AlertsShelf from './AlertsShelf';
import { getAdminModeEnabled } from '../../selectors/user';
import AlertsSummaryIcon from '../../resources/svg/data_connector_alerts_envelopes/dc_alerts_summary.svg';
import Table from '../../components/widgets/Table';
import SpinnerIcon from '../../resources/svg/singular_loader.svg';
import SingularButtonCSS from '../../components/widgets/SingularButton.css';

const alertsSummaryTitle = () => (
    <div className={css.sectionHeader} style={{ marginTop: '25px' }}>
        <AlertsSummaryIcon />
        <div className={css.text}>
            <Translate id="STATIC.PAGES.ALERTS_V2.SYSTEM_ALERTS_SHELF.DATA_CONNECTOR_ALERTS_SUMMARY_TITLE" />
        </div>
    </div>
);

const alertsSummarySearchTitle = (searchText, setSearchText, translate) => (
    <div className={css.search}>
        <TextField
            clearable
            value={searchText}
            debounce={500}
            onChange={setSearchText}
            containerClass={css.searchInput}
            placeholder={translate('STATIC.PAGES.ALERTS_V2.SYSTEM_ALERTS_SHELF.SUMMARY.DATA_CONNECTOR')}
        />
    </div>
);

const alertsSummaryTable = (data, tableConfig) => (
    <Table
        dataTestId="summary_table"
        tableClass={css.tableContainer}
        tbodyClass={css.tbody}
        data={data}
        metadata={tableConfig()}
    />
);

const alertsSummarySpinner = () => <SpinnerIcon className={classNames(css.spinner, SingularButtonCSS.buttonSpinner)} />;

function SystemAlertShelf({
    showShelf,
    onCloseShelf,
    translate,
    orgUsers,
    systemAlerts,
    isAlertsFetched,
    isUserSlackAuth,
    isUserRestricted,
    onSetShouldPullAlerts,
    onDeleteUserSlackAuth,
    canDataConnectorAlerts,
}) {
    const [shouldSaveAlert, setShouldSaveAlert] = useState(false);
    const [shouldPresentDataConnectorAlertsTable, setShouldPresentDataConnectorAlertsTable] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [isModifiedFlag, setIsModifiedFlag] = useState(false);
    const [shouldLoadDCTable, setShouldLoadDCTable] = useState(true);
    const [alertsToSave, setAlertsToSave] = useState({});
    const [loginAlertsToSave, setLoginAlertsToSave] = useState({});
    const [dataDelayAlertsToSave, setDataDelayAlertsToSave] = useState({});
    const channelImages = { mail: EmailIcon, s3: S3Icon, gdrive: GDriveIcon, api: APIIcon, ftp: SFTPIcon };
    const channelProperName = { mail: 'a mail', s3: 'an S3', gdrive: 'a GDrive', api: 'an API', ftp: 'an SFTP' };
    const isAdminMode = useSelector(getAdminModeEnabled);

    const titleColumn = (alertName, alertDisplayName) => {
        return {
            name: alertName,
            displayName: translate(
                `STATIC.PAGES.ALERTS_V2.SYSTEM_ALERTS_SHELF.SUMMARY.${alertDisplayName.toUpperCase()}`
            ),
            headerAlign: 'center',
        };
    };

    const dataConnectorColumn = () => {
        const columnInfo = {
            cellComponent: TableCellImgLabel,
            cellValues: [
                { propName: 'subText', dataKey: 'subText' },
                { propName: 'subTextExtra', dataKey: 'subTextExtra' },
                { propName: 'imgSrc', dataKey: 'imgSrc' },
                { propName: 'showImgBadge', dataKey: 'isAdMonetization' },
                { propName: 'showUsernameImg', dataKey: 'showUsernameImg' },
                { propName: 'SideImg', dataKey: 'channel', transformation: channel => channelImages[channel] },
                {
                    propName: 'sideImgHoverText',
                    dataKey: 'channel',
                    transformation: channel => `This connector is ${channelProperName[channel]} integration`,
                },
            ],
            cellProps: {
                tdClassName: css.tdStyle,
                imgClassName: css.imgStyle,
                labelsClassName: css.labelsStyle,
                subTextClassName: css.subTextStyle,
                subTextExtraClassName: css.subTextExtraStyle,
                Icon: MonetizationIcon,
            },
        };
        const title = titleColumn('dataConnector', 'data_connector');
        columnInfo.name = title.name;
        columnInfo.displayName = title.displayName;
        columnInfo.headerAlign = title.headerAlign;
        return columnInfo;
    };

    const tableConfig = () => [
        dataConnectorColumn(),
        titleColumn('dataDelaysNotifications', 'data_delays_notifications'),
        titleColumn('loginIssuesNotifications', 'login_issues_notifications'),
    ];

    const onSaveCleanup = () => {
        setShouldSaveAlert(false);
    };

    const onRefetchAlertsTableCleanup = () => {
        setShouldLoadDCTable(false);
    };

    const {
        all_data_connector_alerts: allOrgDataConnectorAlerts,
        isLoading: allOrgAlertsLoading,
    } = useFetchAllOrgDataConnectorAlerts(shouldLoadDCTable, onRefetchAlertsTableCleanup);

    const { system_alerts: updatedSystemAlerts, isLoading: isSaveLoading } = useSaveSystemAlerts(
        shouldSaveAlert,
        loginAlertsToSave.emails,
        loginAlertsToSave.send_to_admins,
        dataDelayAlertsToSave.emails,
        dataDelayAlertsToSave.send_to_admins,
        onSaveCleanup
    );

    const handleCloseShelf = () => {
        if (isModifiedFlag) {
            if (!confirm(translate('STATIC.PAGES.SHARED_REPORTS.UNSAVED_CHANGES_WARNING'))) {
                return;
            }
        }
        onCloseShelf();
    };

    const parseDataConnector = row => {
        if (isAdminMode) {
            return `${row.uan.display_name} - ${row.uan.uan_id}`;
        } else {
            return row.uan.display_name;
        }
    };

    const parseRecipients = row => {
        let list = row.emails;
        if (row.send_to_admins) {
            list = ['All Admin Users', ...list];
        }
        return list.join('<br />');
    };

    const bottomSectionShelf = () => {
        return (
            <div>
                {canDataConnectorAlerts && alertsSummaryTitle()}
                {canDataConnectorAlerts && !shouldPresentDataConnectorAlertsTable && alertsSummarySpinner()}
                {shouldPresentDataConnectorAlertsTable &&
                    alertsSummarySearchTitle(searchText, setSearchText, translate)}
                {shouldPresentDataConnectorAlertsTable && alertsSummaryTable(tableData, tableConfig)}
            </div>
        );
    };

    const shouldCloseShelf = () => {
        return !!(!isModifiedFlag && !isSaveLoading && updatedSystemAlerts && Object.keys(updatedSystemAlerts).length);
    };

    useEffect(() => {
        setShouldPresentDataConnectorAlertsTable(
            canDataConnectorAlerts && allOrgDataConnectorAlerts && Object.keys(allOrgDataConnectorAlerts).length
        );
    }, [canDataConnectorAlerts, allOrgDataConnectorAlerts, tableData]);

    useEffect(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, SYSTEM_ALERTS_SHELF_OPENED);
    }, []);

    useEffect(() => {
        if (Object.keys(alertsToSave).length) {
            setLoginAlertsToSave(alertsToSave.login);
            setDataDelayAlertsToSave(alertsToSave.dataDelay);
            setShouldSaveAlert(true);
            setShouldLoadDCTable(false);
            onSetShouldPullAlerts(false);
        }
    }, [alertsToSave]);

    useEffect(() => {
        if (shouldCloseShelf()) {
            setTimeout(() => {
                setShouldLoadDCTable(true);
                onSetShouldPullAlerts(true);
                setIsModifiedFlag(false);
                onCloseShelf();
            }, 2000);
        }
    }, [updatedSystemAlerts, isModifiedFlag, isSaveLoading]);

    useEffect(() => {
        const data = [];
        if (!allOrgAlertsLoading && allOrgDataConnectorAlerts) {
            for (const row of allOrgDataConnectorAlerts) {
                const rowTable = {
                    dataConnector: parseDataConnector(row),
                    subText: row.uan.sub_text,
                    subTextExtra: row.uan.sub_text_extra,
                    agencyName: row.uan.agency_name,
                    imgSrc: row.uan.img_src,
                    isAdMonetization: row.uan.is_ad_monetization,
                    channel: row.channel,
                    showUsernameImg: row.uan.username_img,
                    dataDelaysNotifications: parseRecipients(row.data_delay_alert_recipients),
                    loginIssuesNotifications: parseRecipients(row.login_alert_recipients),
                };
                if (searchText === '') {
                    data.push(rowTable);
                } else if (row.uan.display_name.toLowerCase().includes(searchText.toLowerCase())) {
                    data.push(rowTable);
                }
            }
        }
        setTableData(data);
    }, [searchText, allOrgDataConnectorAlerts, isAdminMode, allOrgAlertsLoading]);

    return (
        <Fragment>
            <Shelf
                open={showShelf}
                shelfSize="medium"
                headerText="STATIC.PAGES.ALERTS_V2.SYSTEM_ALERTS_SHELF.TITLE"
                onClose={handleCloseShelf}
            >
                <AlertsShelf
                    title="system_alerts_shelf"
                    aboutTitle={<Translate id="STATIC.PAGES.ALERTS_V2.SYSTEM_ALERTS_SHELF.ABOUT_TITLE" />}
                    bottomSectionShelf={bottomSectionShelf()}
                    orgUsers={orgUsers}
                    loginAlert={systemAlerts.data_connector_login_alert}
                    dataDelayAlert={systemAlerts.data_delay_alert}
                    setAlertsToSave={setAlertsToSave}
                    loginAlertsName="data_connector_login_alert"
                    dataDelayAlertsName="data_delay_alert"
                    isModifiedFlag={isModifiedFlag}
                    setIsModifiedFlag={setIsModifiedFlag}
                    setShouldSaveAlert={setShouldSaveAlert}
                    isAlertSaved={updatedSystemAlerts && !isSaveLoading}
                    isAlertFetched={isAlertsFetched}
                    onClose={onCloseShelf}
                    isUserSlackAuth={isUserSlackAuth}
                    onDeleteUserSlackAuth={onDeleteUserSlackAuth}
                    isUserRestricted={isUserRestricted}
                />
            </Shelf>
        </Fragment>
    );
}

SystemAlertShelf.propTypes = {
    showShelf: PropTypes.bool,
    onCloseShelf: PropTypes.func,
    translate: PropTypes.func,
    orgUsers: PropTypes.array,
    systemAlerts: PropTypes.objectOf(PropTypes.any),
    isAlertsFetched: PropTypes.bool,
    onSetShouldPullAlerts: PropTypes.func,
    isUserSlackAuth: PropTypes.bool,
    isUserRestricted: PropTypes.bool,
    onDeleteUserSlackAuth: PropTypes.func,
    canDataConnectorAlerts: PropTypes.bool,
};

SystemAlertShelf.defaultProps = {
    showShelf: false,
    onCloseShelf: () => {},
    translate: () => {},
    orgUsers: {},
    systemAlerts: {},
    isAlertsFetched: false,
    onSetShouldPullAlerts: () => {},
    isUserSlackAuth: false,
    isUserRestricted: false,
    onDeleteUserSlackAuth: () => {},
    canDataConnectorAlerts: false,
};

export default withLocalize(SystemAlertShelf);
