import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import css from './sdkConfigurationStepPages.css';
import sdkConfigurationCss from './sdkConfiguration.css';
import EditIconButton from '../../../resources/svg/edit_icon_button.svg';
import Label from '../../../components/widgets/Label';
import SDKConfigurationQuestionsTable from './sdkConfigurationQuestionsTable';
import { getSummaryTableMetadata, getTableData, isPlatformAvailable } from './utils';
import { ALL_PLATFORMS, SUMMARY_QUESTIONS_METADATA } from './consts';
import { SingularButton } from '../../../components/widgets';
import { getOrganizationAvailablePlatforms, getOrganizationIsWeb } from '../../../selectors/user';

const SDKConfigurationSummaryPage = ({
    sdkConfig,
    translate,
    showEdit,
    onEditClick,
    onBackClick,
    onNextClick,
    organizationTier,
}) => {
    const isWebOrg = useSelector(getOrganizationIsWeb);
    const orgAvailablePlatforms = useSelector(getOrganizationAvailablePlatforms);

    const { answers: sdkConfigurationAnswers = {}, platforms, metadata: answersMetadata } = sdkConfig;
    const tableMetadata = getSummaryTableMetadata();

    const tableData = getTableData(
        SUMMARY_QUESTIONS_METADATA,
        {
            YOUR_PLATFORMS: platforms?.length
                ? platforms
                : ALL_PLATFORMS.filter(platformName =>
                      isPlatformAvailable(platformName, orgAvailablePlatforms, isWebOrg)
                  ),
            ...sdkConfigurationAnswers,
        },
        translate,
        answersMetadata,
        organizationTier,
        platforms,
        true
    );

    return (
        <div className={classNames(css.stepContainer, css.summaryStepContainer)}>
            <Label text="STATIC.PAGES.SDK_CONFIGURATION.SUMMARY_DONE_TITLE" className={css.stepTitle} />
            <SDKConfigurationQuestionsTable
                tableMetadata={tableMetadata}
                tableData={tableData}
                className={css.tableOverflow}
            />
            <div className={sdkConfigurationCss.summaryPageFooter}>
                {showEdit ? (
                    <SingularButton
                        className={css.editPlanButton}
                        Icon={EditIconButton}
                        iconClass={css.editPlanButtonIcon}
                        type="flat"
                        onClick={onEditClick}
                    >
                        <Translate id="STATIC.PAGES.SDK_CONFIGURATION.EDIT_PLAN_BUTTON" />
                    </SingularButton>
                ) : (
                    <SingularButton type="flat" onClick={onBackClick}>
                        <Translate id="STATIC.BUTTONS.BACK" />
                    </SingularButton>
                )}
                <SingularButton onClick={onNextClick} className={sdkConfigurationCss.summaryNextButton}>
                    <Translate id={`STATIC.PAGES.SDK_CONFIGURATION.${showEdit ? 'NEXT_BUTTON' : 'CONTINUE_BUTTON'}`} />
                </SingularButton>
            </div>
        </div>
    );
};

SDKConfigurationSummaryPage.propTypes = {
    sdkConfig: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    showEdit: PropTypes.bool.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    organizationTier: PropTypes.string.isRequired,
    onEditClick: PropTypes.func,
};

SDKConfigurationSummaryPage.defaultProps = {
    onEditClick: undefined,
};

export default withLocalize(SDKConfigurationSummaryPage);
