import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import QuestionMark from './QuestionMark';
import css from './FileField.css';
import UploadImage from '../../resources/icons/outline_image.svg';

export default function FileField({
    value,
    label,
    uploadText,
    clearText,
    clearable,
    disabled,
    error,
    labelTooltip,
    mandatory,
    containerClass,
    onChange,
    acceptableTypes,
}) {
    const id = `fileField-${label.replace(/ /g, '-')}`;

    const _onChange = event => onChange(event.target.files[0]);
    const _onClear = () => onChange(null);

    // We clean the current selected file in order to trigger onChange when the same file is being selected
    // This is relevant for the case when we select a file, clear it, and then select it again.
    const _onClick = event => {
        event.target.value = '';
    };

    return (
        <div className={classNames(css.container, containerClass)}>
            <label htmlFor={id}>
                <div className={classNames(css.fieldLabel, { [css.error]: error, [css.mandatory]: mandatory })}>
                    <Translate id={label} />
                    {labelTooltip && <QuestionMark iconClassName={css.tooltipIcon} message={labelTooltip} />}
                </div>
            </label>
            <div className={css.fileField}>
                <label htmlFor={id} className={classNames(css.uploadButton, { [css.disabled]: disabled })}>
                    <UploadImage className={css.uploadImage} />
                    <Translate id={uploadText} />
                    <input
                        id={id}
                        type="file"
                        disabled={disabled}
                        className={css.fileInput}
                        onChange={_onChange}
                        onClick={_onClick}
                        accept={acceptableTypes.join(',')}
                    />
                </label>
                {value && clearable && (
                    <div className={css.clearButton} onClick={_onClear}>
                        <Translate id={clearText} />
                    </div>
                )}
            </div>
            {error && <div className={css.errorMessage}>{error}</div>}
        </div>
    );
}

FileField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.string.isRequired,
    uploadText: PropTypes.string,
    clearText: PropTypes.string,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    labelTooltip: PropTypes.string,
    mandatory: PropTypes.bool,
    containerClass: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    acceptableTypes: PropTypes.arrayOf(PropTypes.string),
};

FileField.defaultProps = {
    value: null,
    uploadText: 'STATIC.BUTTONS.UPLOAD_FILE',
    clearText: 'STATIC.BUTTONS.DELETE_FILE',
    clearable: false,
    disabled: false,
    error: '',
    labelTooltip: null,
    mandatory: false,
    containerClass: '',
    acceptableTypes: ['*'],
};
