import update from 'immutability-helper';
// eslint-disable-next-line import/named
import { LOAD, DESTROY, DOWNLOAD_REPORT_CLICKED, REPORT_INFO_UPDATED, UPDATE_UAN_EVENTS_DATA } from './actions';

export const initialState = {
    loading: false,
    uanEventsData: null,
};

export const getLoading = state => state.mta.loading;

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD: {
            const { countries, loading } = action;
            return update(state, {
                loading: { $set: loading },
                countries: { $set: countries },
            });
        }
        case DESTROY: {
            return initialState;
        }
        case DOWNLOAD_REPORT_CLICKED: {
            const { formData } = action;

            return update(state, {
                formData: { $set: formData },
            });
        }
        case REPORT_INFO_UPDATED: {
            const { reportInfo } = action;

            return update(state, {
                reportInfo: { $set: reportInfo },
            });
        }
        case UPDATE_UAN_EVENTS_DATA: {
            return update(state, {
                uanEventsData: {
                    $set: action.data,
                },
            });
        }
        default:
            return state;
    }
};
