import { useCallback } from 'react';
import { useRequest } from '../utils/customHooks';

import SDKConfigService from './components/sdkConfiguration/service';
import HomepageService from '../homepage/service';
import { OS } from '../utils/OS';

const homepageService = new HomepageService();
const sdkConfigAPI = new SDKConfigService();

export const useGetHomepageData = () => {
    return useRequest(
        useCallback(homepageService.getHomepageData, []),
        () => true,
        () => true
    );
};

export const useGetSDKConfig = dataUpdated => {
    return useRequest(
        useCallback(sdkConfigAPI.getSDKConfig, [dataUpdated]),
        () => true,
        () => true,
        dataUpdated
    );
};

export const useGetSDKConfigAnswers = dataUpdate => {
    const { isLoading, value: sdkConfig = {} } = useGetSDKConfig(dataUpdate);
    const { answers: answersByPlatform, platforms = [] } = sdkConfig;
    const selectedPlatform = platforms.length ? platforms[0] : OS.IOS;
    const parsedAnswers = answersByPlatform?.[selectedPlatform];

    return { isLoading, sdkConfig: { ...sdkConfig, answers: parsedAnswers } };
};
