import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import posed, { PoseGroup } from 'react-pose';
import css from './metricCards.css';
import { useFetchTableData } from './hooks';
import { getAdminModeEnabled } from '../selectors/user';
import { parseMetricCards } from '../utils/metricCards';
import { calculateSecondPeriod } from '../utils/dateUtils';
import { REPORT_TYPES } from './consts';
import MetricCardsEmptyStateInner from './metricCardsEmptyStateInner';

const AnimatedMetricCard = posed.div({
    enter: {
        y: 0,
        transition: {
            y: {
                opacity: { ease: 'easeInOut', duration: 500 },
            },
        },
    },
    exit: {
        y: 20,
    },
});

function MetricCardsWidget({
    dispatchWidgetVersions,
    dispatchWidgetsLoading,
    forceRefreshCounter,
    dashboardId,
    widget,
    globalFilters,
    startDate,
    endDate,
    updatingGraphQL,
    displayUnenriched,
}) {
    const { startDate2, endDate2 } = calculateSecondPeriod(startDate, endDate);
    const isAdminMode = useSelector(state => getAdminModeEnabled(state));

    const [isLoading, tableData] = useFetchTableData(
        dashboardId,
        widget.gridParams.i,
        widget.query,
        globalFilters,
        startDate,
        endDate,
        startDate2,
        endDate2,
        REPORT_TYPES.REPORT,
        dispatchWidgetVersions,
        dispatchWidgetsLoading,
        true,
        widget.dataType,
        displayUnenriched,
        updatingGraphQL,
        forceRefreshCounter
    );

    const parsedMetricCards = parseMetricCards(isLoading, tableData, startDate, endDate);

    return (
        <>
            {isAdminMode && (
                <>
                    <div>
                        <b>startDate:</b> {startDate} <b>endDate:</b> {endDate}
                    </div>
                    <div>
                        <b>startDate2:</b> {startDate2} <b>endDate2:</b> {endDate2}
                    </div>
                </>
            )}
            <div className={css.metricCardsContainer}>
                {parsedMetricCards.map(
                    ({
                        displayName,
                        metricValue,
                        changePercentage,
                        changePercentageLabel,
                        compareFactorName,
                        period2MetricData,
                        noData,
                    }) => (
                        <div className={css.metricCard} key={displayName}>
                            <div className={css.metricName}>{displayName}</div>
                            {isLoading && <img className={css.dotsLoader} src="/static/dashboard/img/icons/dots.gif" />}
                            {!isLoading && (
                                <PoseGroup animateOnMount>
                                    <AnimatedMetricCard key={displayName} className={css.animatedMetricCard}>
                                        {noData ? (
                                            <MetricCardsEmptyStateInner displayName={displayName} />
                                        ) : (
                                            <>
                                                <div className={css.metricValue}>{metricValue}</div>
                                                {isAdminMode && <span>Last Period Data: {period2MetricData}</span>}
                                                <div className={classNames(css.changeValue, css[compareFactorName])}>
                                                    {changePercentage} <Translate id={changePercentageLabel} />
                                                </div>
                                            </>
                                        )}
                                    </AnimatedMetricCard>
                                </PoseGroup>
                            )}
                        </div>
                    )
                )}
            </div>
        </>
    );
}

MetricCardsWidget.propTypes = {
    dispatchWidgetVersions: PropTypes.func.isRequired,
    dispatchWidgetsLoading: PropTypes.func.isRequired,
    dashboardId: PropTypes.string.isRequired,
    widget: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['table', 'pivot', 'metricCards']).isRequired,
        query: PropTypes.shape({
            dimensions: PropTypes.arrayOf(PropTypes.string),
            metrics: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        options: PropTypes.shape({
            rows: PropTypes.arrayOf(PropTypes.string),
            columns: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
    }).isRequired,
    globalFilters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            operator: PropTypes.oneOf(['in', 'not in']).isRequired,
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        })
    ),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    updatingGraphQL: PropTypes.bool,
    forceRefreshCounter: PropTypes.number,
    displayUnenriched: PropTypes.bool,
};

MetricCardsWidget.defaultProps = {
    globalFilters: [],
    updatingGraphQL: false,
    forceRefreshCounter: 0,
    displayUnenriched: false,
};

export default memo(MetricCardsWidget);
