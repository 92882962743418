import { connect } from 'react-redux';
import DownloadReportButton from '../../components/widgets/DownloadReportButton';
import { downloadOrExportReport, setActionType } from '../../actions/reports';
import { reportDataSelectors } from '../../selectors/reports';

const mapStateToProps = (state, ownProps) => {
    const { reportDates, disableReportState } = reportDataSelectors[ownProps.reportType](state);
    const startDate = reportDates.start_date;
    const endDate = reportDates.end_date;

    return {
        ...ownProps,
        disableReportState,
        disabled: !(startDate && endDate) || !!disableReportState,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { onClick, origin, ...otherProps } = ownProps;
    const { reportType } = ownProps;
    return {
        ...otherProps,
        onClick: () => {
            onClick && onClick();
            dispatch(setActionType(downloadOrExportReport(reportType, reportType, origin), reportType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportButton);
