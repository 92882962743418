import React from 'react';
import { withStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

const paperScrollBody = {
    marginTop: 80,
    width: 1000,
    padding: '30px 0',
    backgroundColor: '#e1e1e1',
    verticalAlign: 'top',
};

const styles = () => ({
    scrollPaper: {
        alignItems: 'flex-start',
    },
    paperScrollBody,
    paperScrollBodyNoPadding: {
        ...paperScrollBody,
        padding: 0,
        width: 450,
        verticalAlign: 'top',
    },
    backdropRoot: {
        background: 'rgba(0,0,0,0.8)',
    },
    paperRoot: {
        borderRadius: 3,
    },
});

class SingularDialog extends React.Component {
    _onClose = (event, reason) => {
        const { onEscapePress, onClose } = this.props;
        if (reason === 'escapeKeyDown' && onEscapePress) {
            onEscapePress();
        } else {
            onClose();
        }
    };

    render() {
        const { open, children, classes, noPadding, className } = this.props;
        const { backdropRoot, paperRoot, ...mainClasses } = classes;
        const finalClasses = { ...mainClasses };
        // ugly hack until we upgrade mui's version which support conditional styling
        if (noPadding) {
            finalClasses.paperScrollBody = finalClasses.paperScrollBodyNoPadding;
        }
        delete finalClasses.paperScrollBodyNoPadding;
        if (className) {
            finalClasses.paperScrollBody = `${finalClasses.paperScrollBody} ${className}`;
        }
        return (
            <Dialog
                open={open}
                maxWidth={false}
                classes={finalClasses}
                TransitionComponent={Slide}
                scroll="body"
                BackdropProps={{
                    classes: {
                        root: backdropRoot,
                    },
                }}
                PaperProps={{
                    classes: {
                        root: paperRoot,
                    },
                }}
                onClose={this._onClose}
            >
                {this.props.children}
            </Dialog>
        );
    }
}

export default withStyles(styles)(SingularDialog);
