import update from 'immutability-helper';
import uniq from 'lodash/uniq';
import {
    ADD_FUTURE_CHANGED,
    ADD_MESSAGE_TO_GROUP,
    APP_SELECTION_CHANGE,
    CLEAN_MESSAGES,
    CLOSE_SHELF,
    CREATE_NEW_MEMBER,
    DATA_SOURCE_SELECTION_CHANGE,
    DELETE_USER_DISABLED,
    DELETE_USER_INVITE_REVOKED,
    SAVE_MEMBER_ERROR,
    SAVE_MEMBER_SUCCESS,
    MANAGE_PERMISSIONS,
    METRIC_SELECTION_CHANGE,
    MODIFY_INVITATION_STATE,
    ORG_USERS_LOADED,
    ORG_AGENCIES_LOADED,
    SCREENS_SELECTION_TOGGLE,
    TEAMMANAGEMENT_PAGE_LOADED,
    UPDATE_FEATURES_LIST,
    UPDATE_ROLE_SELECTION_FORM,
    UPDATE_USER_REGISTRATION_FORM,
    UPDATE_AGENCY_SELECTION_FORM,
    WIZARD_FINISHED,
    WIZARD_ACTION_TYPE_CHANGED,
    UPDATE_DATA_SOURCE_DATA,
    DIMENSION_FILTER_SELECTION_CHANGE,
    ACTIVE_MEMBER_TYPE_CHANGED,
    DATA_SOURCE_SELECTION_CHANGE_ALL,
} from 'teamManagement/actions';

import { generateGuid } from 'global/utils';
import {
    ROLE_AGENCY,
    ROLE_STANDARD,
    shelfSteps,
    wizardModes,
    MemberTypes,
    RESTRICTED_ROLES,
} from 'teamManagement/utils';

update.extend('$auto', (value, object) => {
    return object ? update(object, value) : update({}, value);
});
update.extend('$autoArray', (value, object) => {
    return object ? update(object, value) : update([], value);
});

export const initialState = {
    pageLoaded: false,
    orgUsers: [],
    orgAgencies: [],
    messages: {
        pending: [],
        active: [],
    },
    uans: {},
    screens: [],
    messagesDefs: {},
    shelf: {
        open: false,
    },
    editMemberId: null,
    userRegistrationForm: {
        firstName: {
            value: '',
        },
        lastName: {
            value: '',
        },
        email: {
            value: '',
        },
        description: {
            value: '',
        },
    },
    agencySelectionForm: {
        id: '',
        name: '',
    },
    dimensionFilters: {},
    selectedMetrics: {
        values: [],
        addFuture: false,
    },
    roleSelectionForm: {
        role: ROLE_STANDARD,
    },
    screenSelectionForm: {
        selectedScreens: [],
        addFuture: false,
    },
    wizard: {
        mode: '',
        currentActionType: 'load',
    },
    saveMemberError: '',
    activeMemberType: MemberTypes.USER,
};

const selectionToggleFunc = (action, idsKey = 'appIDs', items) => {
    let ret = items;
    if (action.value === false) {
        ret = [...items].filter(id => !action[idsKey].includes(id));
    }
    if (action.value === true) {
        ret = [...items, ...action[idsKey]];
    }
    return uniq(ret);
};

const teamManagement = (state = initialState, action) => {
    switch (action.type) {
        case TEAMMANAGEMENT_PAGE_LOADED: {
            return update(state, {
                pageLoaded: {
                    $set: true,
                },
            });
        }
        case ORG_USERS_LOADED: {
            return update(state, {
                orgUsers: {
                    $set: action.orgUsers,
                },
            });
        }
        case ORG_AGENCIES_LOADED: {
            return update(state, {
                orgAgencies: {
                    $set: action.orgAgencies,
                },
            });
        }
        case UPDATE_FEATURES_LIST: {
            return update(state, {
                screens: {
                    $set: action.featuresList,
                },
            });
        }
        case DELETE_USER_INVITE_REVOKED: {
            const { user } = action;
            const userIndex = state.orgUsers.findIndex(ou => ou.invite_id === user.invite_id);
            return update(state, {
                orgUsers: {
                    $splice: [[userIndex, 1]],
                },
            });
        }
        case DELETE_USER_DISABLED: {
            const { user } = action;
            const userIndex = state.orgUsers.findIndex(ou => ou.user_id === user.user_id);
            return update(state, {
                orgUsers: {
                    $splice: [[userIndex, 1, { ...state.orgUsers[userIndex], status: 'Deactivated' }]],
                },
            });
        }
        case CLEAN_MESSAGES: {
            return update(state, {
                messages: Object.keys(state.messages).reduce((total, groupName) => {
                    total[groupName] = { $set: [] };
                    return total;
                }, {}),
            });
        }
        case ADD_MESSAGE_TO_GROUP: {
            const { group, message, messageType } = action;
            const guid = generateGuid();
            // Changing behaviour per product request. removing other notifications when a new one introduced
            const otherGroups = Object.keys(state.messages)
                .filter(m => m !== group)
                .reduce((total, groupName) => {
                    total[groupName] = {
                        $set: [],
                    };
                    return total;
                }, {});

            return update(state, {
                messages: {
                    [group]: {
                        $set: [guid],
                    },
                    ...otherGroups,
                },
                messagesDefs: {
                    [guid]: {
                        $set: {
                            message,
                            type: messageType,
                        },
                    },
                },
            });
        }
        case CREATE_NEW_MEMBER: {
            return update(state, {
                shelf: {
                    open: {
                        $set: true,
                    },
                },
                userRegistrationForm: {
                    $set: initialState.userRegistrationForm,
                },
                agencySelectionForm: {
                    $set: initialState.agencySelectionForm,
                },
                roleSelectionForm: {
                    role: {
                        $set: state.activeMemberType === MemberTypes.AGENCY ? ROLE_AGENCY : ROLE_STANDARD,
                    },
                },
                dimensionFilters: {
                    $set: {},
                },
                selectedMetrics: {
                    values: {
                        $set: [],
                    },
                    addFuture: {
                        $set: false,
                    },
                },
                screenSelectionForm: {
                    selectedScreens: {
                        $set: [],
                    },
                    addFuture: {
                        $set: false,
                    },
                },
                wizard: {
                    mode: {
                        $set: wizardModes.create,
                    },
                },
            });
        }
        case CLOSE_SHELF: {
            return update(state, {
                shelf: {
                    open: {
                        $set: false,
                    },
                },
                editMemberId: {
                    $set: null,
                },
                wizard: {
                    mode: {
                        $set: '',
                    },
                    currentActionType: {
                        $set: 'load',
                    },
                },
                inviteError: {
                    $set: '',
                },
            });
        }
        case MANAGE_PERMISSIONS: {
            const { user, partialState } = action;
            return update(state, {
                shelf: {
                    open: {
                        $set: true,
                    },
                },
                roleSelectionForm: {
                    $set: partialState.roleSelectionForm,
                },
                userRegistrationForm: {
                    $set: partialState.userRegistrationForm,
                },
                agencySelectionForm: {
                    $set: partialState.agencySelectionForm,
                },
                editMemberId: {
                    $set: partialState.editMemberId,
                },
                selectedMetrics: {
                    $set: partialState.selectedMetrics,
                },
                screenSelectionForm: {
                    $set: partialState.screenSelectionForm,
                },
                dimensionFilters: {
                    $set: partialState.dimensionFilters,
                },
                wizard: {
                    userData: { $set: user },
                    mode: {
                        $set: wizardModes.edit,
                    },
                },
            });
        }
        case UPDATE_DATA_SOURCE_DATA: {
            return update(state, {
                uans: {
                    $set: action.data,
                },
            });
        }
        case APP_SELECTION_CHANGE: {
            return update(state, {
                dimensionFilters: {
                    app: {
                        $auto: {
                            values: {
                                $autoArray: {
                                    $apply: selectionToggleFunc.bind(null, action, 'appIDs'),
                                },
                            },
                        },
                    },
                },
            });
        }
        case SCREENS_SELECTION_TOGGLE: {
            return update(state, {
                screenSelectionForm: {
                    selectedScreens: {
                        $apply: selectionToggleFunc.bind(null, action, 'screenIds'),
                    },
                },
            });
        }
        case DATA_SOURCE_SELECTION_CHANGE: {
            return update(state, {
                dimensionFilters: {
                    base_source: {
                        $auto: {
                            values: {
                                $autoArray: {
                                    $apply: sources => {
                                        if (sources.includes(action.name) && action.value === false) {
                                            return [...sources].filter(id => id !== action.name);
                                        }
                                        if (!sources.includes(action.name) && action.value === true) {
                                            return [...sources, action.name];
                                        }
                                        return sources;
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }
        case DATA_SOURCE_SELECTION_CHANGE_ALL: {
            return update(state, {
                dimensionFilters: {
                    base_source: {
                        $auto: {
                            values: {
                                $autoArray: {
                                    $apply: () => {
                                        if (action.value) {
                                            return action.allSources;
                                        } else {
                                            return [];
                                        }
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }

        case DIMENSION_FILTER_SELECTION_CHANGE: {
            return update(state, {
                dimensionFilters: {
                    [action.name]: {
                        $auto: {
                            values: {
                                $set: action.values,
                            },
                        },
                    },
                },
            });
        }

        case METRIC_SELECTION_CHANGE: {
            return update(state, {
                selectedMetrics: {
                    values: {
                        $apply: metrics => {
                            if (action.value === false) {
                                return [...metrics].filter(id => !action.metricID.includes(id));
                            }
                            if (action.value === true) {
                                return [...new Set([...metrics, ...action.metricID])];
                            }
                            return metrics;
                        },
                    },
                },
            });
        }
        case UPDATE_USER_REGISTRATION_FORM: {
            return update(state, {
                userRegistrationForm: {
                    $set: action.fields,
                },
                saveMemberError: {
                    $set: '',
                },
            });
        }
        case UPDATE_AGENCY_SELECTION_FORM: {
            return update(state, {
                agencySelectionForm: {
                    $set: action.agency,
                },
            });
        }
        case UPDATE_ROLE_SELECTION_FORM: {
            return update(state, {
                roleSelectionForm: {
                    role: {
                        $set: action.role,
                    },
                },
            });
        }
        case SAVE_MEMBER_ERROR: {
            return update(state, {
                saveMemberError: {
                    $set: action.error,
                },
            });
        }
        case SAVE_MEMBER_SUCCESS: {
            return update(state, {
                shelf: {
                    open: {
                        $set: false,
                    },
                },
                wizard: {
                    mode: {
                        $set: '',
                    },
                    currentActionType: {
                        $set: 'load',
                    },
                },
                editMemberId: {
                    $set: null,
                },
            });
        }
        case MODIFY_INVITATION_STATE: {
            const { user, userState } = action;
            const userIndex = state.orgUsers.findIndex(
                u => (user.user_id && u.user_id === user.user_id) || (user.invite_id && u.invite_id === user.invite_id)
            );
            const newUser = {
                ...user,
                ...userState,
            };
            return update(state, {
                orgUsers: {
                    $splice: [[userIndex, 1, newUser]],
                },
            });
        }
        case WIZARD_FINISHED: {
            return update(state, {
                saveMemberError: {
                    $set: '',
                },
            });
        }
        case WIZARD_ACTION_TYPE_CHANGED: {
            return update(state, {
                wizard: {
                    currentActionType: {
                        $set: action.actionType,
                    },
                },
            });
        }
        case ADD_FUTURE_CHANGED: {
            if (action.dimensionFilter) {
                return update(state, {
                    dimensionFilters: {
                        [action.form]: {
                            $auto: {
                                addFuture: {
                                    $set: action.value,
                                },
                            },
                        },
                    },
                });
            }

            return update(state, {
                [action.form]: {
                    addFuture: {
                        $set: action.value,
                    },
                },
            });
        }
        case ACTIVE_MEMBER_TYPE_CHANGED: {
            return update(state, {
                activeMemberType: {
                    $set: action.activeMemberType,
                },
            });
        }
        default:
            return state;
    }
};

export default teamManagement;
