import React, { useEffect, useState } from 'react';
import css from '../sectionsItems.css';
import Header from './Header';
import { DEPENDENCY_MANAGER, DEPENDENCY_MANAGER_DEFAULTS, DROPDOWN_ITEMS } from './consts';
import CocoPods from './content/CocoPods';
import XcodeSetVersion from './content/XcodeSetVersion';
import Gradle from './content/Gradle';
import { useSDKPlanningContext } from '../../../context/useSDKPlanningContext';
import { SDK_INTEGRATION_SECTIONS, TARGET_PLATFORM_SHORT_NAME } from '../../../consts';
import useMixpanelEventOnPageLoad from '../../../../hooks/useMixpanelEventOnPageLoad';

const DEPENDENCY_MANAGER_COMPONENTS = {
    [DEPENDENCY_MANAGER.COCOAPODS]: <CocoPods />,
    [DEPENDENCY_MANAGER.XCODE_SET_VERSION]: <XcodeSetVersion />,
    [DEPENDENCY_MANAGER.GRADLE]: <Gradle />,
};

const { GET_SDK_FILES } = SDK_INTEGRATION_SECTIONS;

const GetSDKFiles = () => {
    const { selectedTargetPlatform } = useSDKPlanningContext();
    const platform = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];
    const [dependencyManager, setDependencyManager] = useState(() => DEPENDENCY_MANAGER_DEFAULTS[platform]);
    const dropdownItems = DROPDOWN_ITEMS[platform];

    useMixpanelEventOnPageLoad(GET_SDK_FILES, selectedTargetPlatform);

    useEffect(() => {
        setDependencyManager(DEPENDENCY_MANAGER_DEFAULTS[platform]);
    }, [platform]);

    return (
        <div className={css.sectionContainer}>
            <Header
                selection={dependencyManager}
                handleSelection={setDependencyManager}
                dropdownItems={dropdownItems}
                platform={platform}
            />
            {DEPENDENCY_MANAGER_COMPONENTS[dependencyManager]}
        </div>
    );
};

export default GetSDKFiles;
