import React from 'react';
import css from './Navbar.css';
import Logo from '../../resources/svg/singular_flat_logo_white.svg';

class Navbar extends React.PureComponent {
    render() {
        const { style, promotionText } = this.props;
        return (
            <div className={css.container} style={style}>
                <Logo style={{ width: '150px', fill: '#fff' }} />
                <div className={css.headerText}>{promotionText}</div>
            </div>
        );
    }
}

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
