import { all, call, fork, put, select, take, delay, takeEvery } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import CustomEventsAPI from '../../services/customEvents';
import AppsAPI from '../../services/apps';
import {
    customEventsPageLoaded,
    customEventsPageUnLoaded,
    deleteCustomEventSuccess,
    EVENT_EDIT_TYPE_CHANGING,
    EVENT_EDITOR_DELETE_CLICKED,
    EVENT_EDITOR_SAVE_CLICKED,
    eventEditTypeChanged,
    saveCustomEventFailed,
    saveCustomEventSuccess,
    savingCustomEvent,
    TOGGLE_EVENT_EDITOR_CLICKED,
    toggleEventEditor,
    updateCustomEvents,
    updatePublicIds,
    updateUanEvents,
    CUSTOM_EVENTS_CATEGORY_TAB_CLICK,
    setActiveGroupType,
    updateRelatedReports,
    REFRESH_VIEW,
} from '../../customEvents/actions';
import { updateAttributionAppsData } from '../../actions/apps';
import { getTotalUanEvents } from '../../utils/events';
import {
    getSitePublicIds,
    getToggleEditorClickedData,
    getActiveGroupType,
    getBackupEvent,
} from '../../customEvents/selectors';
import { getTranslate } from '../../selectors/locale';
import { getUserData } from '../../selectors/user';
import {
    getInitialGroupTypeCategory,
    isNewEvent,
    getEventSlotGroupTypes,
    isOrgUsingSkanReportingFlag,
} from '../../customEvents/utils';
import getCurrentPath from '../../selectors/routing';
import { UPDATE_USER_DATA } from '../../actions/user';
import { onCohortEventAdded } from '../../actions/onboarding';
import { EVENT_SLOT_ANALYSIS_TYPES, EVENT_SLOT_ATTRIBUTION_VIEWS } from '../../utils/consts';
import { EDIT_BANNER_CLICKED } from '../../banners/actions';

const customEventsApi = new CustomEventsAPI();
const appsApi = new AppsAPI();

const updateDataList = [
    {
        serviceMethod: 'updateCustomEvents',
        action: updateCustomEvents,
    },
    {
        serviceMethod: 'updateUanEvents',
        action: updateUanEvents,
    },
    {
        serviceMethod: 'updatePublicIDs',
        action: updatePublicIds,
    },
];

function* updateData({ serviceMethod, action }) {
    const response = yield call(customEventsApi[serviceMethod], {});
    yield put(action(response));
}

function* updateApps() {
    const response = yield call(appsApi.getAttributionApps, false, true, true, true, true);
    yield put(updateAttributionAppsData(response));
}

function* updateReports() {
    const response = yield call(customEventsApi.updateRelatedReports, {});
    yield put(updateRelatedReports(response));
}

function* updateEventsData() {
    const updateDataListCalls = updateDataList.map(updateItem => {
        return call(updateData, updateItem);
    });
    updateDataListCalls.push(call(updateApps));
    yield all(updateDataListCalls);

    let userData = yield select(getUserData);

    if (Object.keys(userData).length === 0) {
        yield take(UPDATE_USER_DATA);
        userData = yield select(getUserData);
    }

    const { search: searchParamsString } = yield select(getCurrentPath);
    const eventSlotAnalysisTypes = userData.event_slot_analysis_types || [];
    const isOrgUnified = userData.is_unified;
    const isOrgUsingSkanReporting = isOrgUsingSkanReportingFlag(userData.feature_flags);
    const eventSlotGroupTypes = getEventSlotGroupTypes(eventSlotAnalysisTypes, isOrgUnified, isOrgUsingSkanReporting);

    if (eventSlotGroupTypes.length > 0) {
        yield put(setActiveGroupType(getInitialGroupTypeCategory(searchParamsString, eventSlotGroupTypes)));
    }

    yield put(customEventsPageLoaded());
    yield updateReports();
}

function* handleDestroy() {
    yield put(customEventsPageUnLoaded());
}

function* validateCustomEvent(customEvent) {
    let valid = true;
    let errorMessage = '';
    const totalUanEvents = getTotalUanEvents(customEvent);
    if (!customEvent.name) {
        valid = false;
        errorMessage = 'STATIC.PAGES.CUSTOM_EVENTS.EVENT_NAME_ERROR';
    } else if (!totalUanEvents.length) {
        valid = false;
        errorMessage = 'STATIC.PAGES.CUSTOM_EVENTS.EVENT_AT_LEAST_ONE_EVENT_ERROR_REACT';
    }
    if (!valid) {
        yield put(saveCustomEventFailed(customEvent, errorMessage));
    }
    return valid;
}

function getAnalysisTypeFromGroupType(groupType) {
    return EVENT_SLOT_ANALYSIS_TYPES.includes(groupType) ? groupType : null;
}

function getAttributionViewFromGroupType(groupType) {
    return EVENT_SLOT_ATTRIBUTION_VIEWS.includes(groupType) ? groupType : null;
}

function* saveCustomEvent(action) {
    const { customEvent } = action;
    const eventId = isNewEvent(customEvent) ? null : customEvent.id;
    const activeGroupType = yield select(getActiveGroupType);
    const eventSlotAnalysisType = isNewEvent(customEvent)
        ? getAnalysisTypeFromGroupType(activeGroupType)
        : customEvent.analysis_type;

    try {
        const valid = yield call(validateCustomEvent, customEvent);
        if (valid) {
            yield put(savingCustomEvent(customEvent));
            const sitePublicIds = yield select(getSitePublicIds);
            const backupEvent = yield select(getBackupEvent);
            const customEventCopy = {
                ...customEvent,
                apps: [...customEvent.apps],
                analysis_type: eventSlotAnalysisType,
                attribution_view: getAttributionViewFromGroupType(activeGroupType),
            };
            customEventCopy.apps.push(...sitePublicIds);
            customEventCopy.apps = uniqBy(customEventCopy.apps, 'public_id');
            const response = yield call(customEventsApi.saveCustomEvent, eventId, customEventCopy);
            yield put(saveCustomEventSuccess(customEvent, response, backupEvent));
            yield put(onCohortEventAdded());
        }
    } catch (error) {
        const translate = yield select(getTranslate);
        const message = `${translate('STATIC.PAGES.CUSTOM_EVENTS.SAVE_SERVER_ERROR')}: </br>${error}`;
        yield put(saveCustomEventFailed(customEvent, message));
    }
}

function* deleteCustomEvent(action) {
    const { customEvent } = action;
    const eventId = customEvent.id;
    try {
        const response = yield call(customEventsApi.deleteCustomEvent, eventId);
        yield put(deleteCustomEventSuccess(customEvent, response));
    } catch (error) {
        // handle error ?
    }
}

function* confirmChanges(newEvents, oldEvent) {
    const translate = yield select(getTranslate);
    let confirmed = true;
    if (JSON.stringify(oldEvent) !== JSON.stringify(newEvents)) {
        if (!confirm(translate('STATIC.PAGES.CUSTOM_EVENTS.UNSAVED_CHANGES_WARNING'))) {
            confirmed = false;
        }
    }
    return confirmed;
}

function* batchPut(putValues) {
    yield all(
        putValues.map(putValue => {
            return put(putValue);
        })
    );
}

function* handleClosingEditorAction(putValues) {
    const { expandedEvents, backupEvent } = yield select(getToggleEditorClickedData);
    if (!expandedEvents || !expandedEvents.length || !backupEvent) {
        yield call(batchPut, putValues);
        return Promise.resolve();
    }
    const result = yield call(confirmChanges, expandedEvents[0], backupEvent);
    if (result) {
        yield call(batchPut, putValues);
    }
    return Promise.resolve();
}

function* handleToggleEditorClicked(action) {
    const { id } = action;
    yield call(handleClosingEditorAction, [toggleEventEditor(id)]);
}

function* handleEventEditorTypeClicked(action) {
    const { selected } = action;
    yield call(handleClosingEditorAction, [eventEditTypeChanged(selected), toggleEventEditor()]);
}

function* handleCategoryTabClicked(action) {
    const { groupType } = action;
    yield call(handleClosingEditorAction, [setActiveGroupType(groupType), toggleEventEditor()]);
}

// ---------------------------------/
//      WATCHERS
// ---------------------------------/

function* onload() {
    yield call(updateEventsData);
}

function* destroy() {
    yield call(handleDestroy);
}

function* watchSaveCustomEvent() {
    while (true) {
        const action = yield take(EVENT_EDITOR_SAVE_CLICKED);
        yield fork(saveCustomEvent, action);
    }
}

function* watchDeleteCustomEvent() {
    while (true) {
        const action = yield take(EVENT_EDITOR_DELETE_CLICKED);
        yield fork(deleteCustomEvent, action);
    }
}

function* watchToggleEventEditorClicked() {
    while (true) {
        const action = yield take(TOGGLE_EVENT_EDITOR_CLICKED);
        yield fork(handleToggleEditorClicked, action);
    }
}

function* watchEventEditorTypeClicked() {
    while (true) {
        const action = yield take(EVENT_EDIT_TYPE_CHANGING);
        yield fork(handleEventEditorTypeClicked, action);
    }
}

function* watchCategoryTabClicked() {
    while (true) {
        const action = yield take(CUSTOM_EVENTS_CATEGORY_TAB_CLICK);
        yield fork(handleCategoryTabClicked, action);
    }
}

function* watchStandardEventRestore() {
    yield takeEvery(REFRESH_VIEW, onload);
}

const watchers = [
    watchSaveCustomEvent,
    watchDeleteCustomEvent,
    watchToggleEventEditorClicked,
    watchEventEditorTypeClicked,
    watchCategoryTabClicked,
    watchStandardEventRestore,
];

export { onload, watchers, destroy };
