import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import css from './BlockedOrgPage.css';
import NoAccessIcon from '../../resources/svg/no_access_man.svg';
import { BLOCKED_PAGE_EVENT_PREFIX, trackMixpanelEvent } from '../../utils/general';

function BlockedOrgPage({ translate }) {
    useEffect(() => {
        trackMixpanelEvent(BLOCKED_PAGE_EVENT_PREFIX, 'Page Loaded', {});
    }, []);

    return (
        <div className={css.container}>
            <NoAccessIcon />
            <div className={css.title}>{translate('STATIC.POPOVERS.BLOCKED_ORG.TITLE')}</div>
            <div className={css.text}> {translate('STATIC.POPOVERS.BLOCKED_ORG.TEXT')}</div>
        </div>
    );
}

BlockedOrgPage.propTypes = {
    translate: PropTypes.func,
};

BlockedOrgPage.defaultProps = {
    translate: () => {},
};

export default withLocalize(BlockedOrgPage);
