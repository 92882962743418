import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-solid';

import css from './Edit.pcss';
import { OUTER_LOGIC, OUTER_LOGIC_LABEL_EXTRA, FILTER_TYPES } from '../consts';
import ConditionSession from './ConditionSession';
import ConditionEvent from './ConditionEvent';
import ConditionInstall from './ConditionInstall';
import Dropdown from '../../components/widgets/Dropdown';
import TrashIcon from '../../resources/svg/trash.svg';

export default class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    getContainer() {
        return this.container;
    }

    render() {
        const {
            index,
            filter,
            metadata,
            editFilter,
            deleteFilter,
            addCondition,
            editCondition,
            deleteCondition,
            apps,
            invalid,
        } = this.props;
        return (
            <div key={index} className={css.filter} ref={this.container}>
                <div className={`${css.dot} ${filter.outer_logic === OUTER_LOGIC.exclude ? css.exclude : ''}`} />
                <div className={css.flexRow} style={{ marginBottom: '6px' }}>
                    <Dropdown
                        containerClass={css.outerLogic}
                        selected={{
                            name: filter.outer_logic,
                            display_name: OUTER_LOGIC[filter.outer_logic],
                        }}
                        onSelection={(s) => {
                            editFilter(index, 'outer_logic', s.name);
                        }}
                        items={Object.keys(OUTER_LOGIC).map((l) => ({
                            name: l,
                            display_name: OUTER_LOGIC[l],
                        }))}
                    />
                    <div className={css.outerLogicExtra}>
                        {filter.conditions.length > 1 ? OUTER_LOGIC_LABEL_EXTRA[filter.inner_logic] : 'users'}
                    </div>
                </div>
                <div className={css.panel}>
                    <div className={css.header}>
                        {filter.filter_type} Filter
                        <div
                            className={css.trash}
                            onClick={() => {
                                deleteFilter(index);
                            }}
                        >
                            <TrashIcon />
                        </div>
                    </div>
                    <div className={css.body}>
                        {filter.conditions.map((condition, cIndex) => {
                            if (filter.filter_type === FILTER_TYPES.EVENT) {
                                return (
                                    <ConditionEvent
                                        key={condition.id ? condition.id : `condition_${index}_${cIndex}`}
                                        condition={condition}
                                        index={index}
                                        cIndex={cIndex}
                                        filter={filter}
                                        addCondition={addCondition}
                                        editCondition={editCondition}
                                        deleteCondition={deleteCondition}
                                        editFilter={editFilter}
                                        metadata={metadata}
                                        apps={apps}
                                        invalid={invalid}
                                    />
                                );
                            } else if (
                                filter.filter_type === FILTER_TYPES.SESSION ||
                                filter.filter_type === FILTER_TYPES.REVENUE
                            ) {
                                return (
                                    <ConditionSession
                                        key={`condition_${index}_${cIndex}`}
                                        condition={condition}
                                        index={index}
                                        cIndex={cIndex}
                                        filter={filter}
                                        addCondition={addCondition}
                                        editCondition={editCondition}
                                        deleteCondition={deleteCondition}
                                        metadata={metadata}
                                        editFilter={editFilter}
                                        invalid={invalid}
                                    />
                                );
                            }
                            return (
                                <ConditionInstall
                                    key={`condition_${index}_${cIndex}`}
                                    condition={condition}
                                    index={index}
                                    cIndex={cIndex}
                                    filter={filter}
                                    addCondition={addCondition}
                                    editCondition={editCondition}
                                    deleteCondition={deleteCondition}
                                    editFilter={editFilter}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
