import { connect } from 'react-redux';
import Page from 'customEvents/page';
import { getCustomEventsPage } from './selectors';
import {
    addNewCustomEvent,
    deleteClicked,
    eventEditTypeChanging,
    eventEditAppSpecificSelected,
    customEventsCategoryClick,
} from './actions';

const mapStateToProps = state => {
    return getCustomEventsPage(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onNewCustomEventClicked: isStandardEventsLayoutActive => {
            dispatch(addNewCustomEvent(isStandardEventsLayoutActive));
        },
        onDelete: (...args) => {
            dispatch(deleteClicked(...args));
        },
        onEditTypeChanged: (...args) => {
            dispatch(eventEditTypeChanging(...args));
        },
        onSpecificAppSelected: (...args) => {
            dispatch(eventEditAppSpecificSelected(...args));
        },
        onCategoryChange: groupType => {
            dispatch(customEventsCategoryClick(groupType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
