import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/widgets/TextField';
import classNames from 'classnames';
import css from './PercentageInput.css';
import MemoChevronIcon from '../../../MemoChevronIcon';

export const TEST_PARAMETER_TEST_ID = 'test-parameters-row';
export const INCREASE_COUNTER_TEST_ID = 'test-parameters-increase';
export const DECREASE_COUNTER_TEST_ID = 'test-parameters-decrease';

const PercentageInput = ({ value, onChange, min, max, disabled }) => {
    const _onChange = val => {
        if (disabled) return;
        const parsedValue = parseInt(val, 10);
        if (parsedValue > max || parsedValue < min) {
            onChange(value);
            return;
        }
        onChange(val);
    };

    return (
        <div
            data-testid={TEST_PARAMETER_TEST_ID}
            className={classNames(css.percentContainer, { [css.disabled]: disabled })}
        >
            <div className={css.arrowContainer}>
                <MemoChevronIcon
                    data-testid={INCREASE_COUNTER_TEST_ID}
                    onClick={() => _onChange(value + 1)}
                    className={classNames(css.arrowFlipped, { [css.disabled]: disabled })}
                />
                <MemoChevronIcon
                    data-testid={DECREASE_COUNTER_TEST_ID}
                    onClick={() => _onChange(value - 1)}
                    className={classNames(css.arrow, { [css.disabled]: disabled })}
                />
            </div>
            <div className={css.percentContentContainer}>
                <TextField
                    autoCompleteOff
                    disabled={disabled}
                    wholeNumberOnly
                    type="number"
                    value={value}
                    debounce={350}
                    onChange={val => _onChange(+val)}
                    inputClassName={css.inputClassName}
                    inputConfig={{ min, max }}
                />
            </div>
            <div className={css.percentContentContainer}>%</div>
        </div>
    );
};

PercentageInput.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
};

export default PercentageInput;
