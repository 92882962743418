import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Collapse from '@mui/material/Collapse';
import css from './GroupContainer.css';

class GroupContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: props.defaultOpen,
        };
        this.handleTitleClick = this._handleTitleClick.bind(this);
    }

    _handleTitleClick() {
        const { controlled, onTitleClick, name } = this.props;
        const { open } = this.state;
        if (!controlled) {
            this.setState({
                open: !open,
            });
            return;
        }
        onTitleClick(name);
    }

    render() {
        const { open: stateOpen } = this.state;
        const { title, open, children, style, titleStyle, controlled } = this.props;
        const useOpen = controlled ? open : stateOpen;
        return (
            <div className={`${css.container} ${useOpen ? css.open : ''}`} style={style}>
                <div className={css.titleContainer} style={titleStyle} onClick={this.handleTitleClick}>
                    <div className={css.arrow} />
                    <div>
                        <Translate id={title} />
                    </div>
                </div>

                <Collapse in={useOpen} timeout="auto" unmountOnExit>
                    <div className={css.content}>{children}</div>
                </Collapse>
            </div>
        );
    }
}

GroupContainer.propTypes = {
    open: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.any, // eslint-disable-line react/forbid-prop-types,
    style: PropTypes.objectOf(PropTypes.any),
    titleStyle: PropTypes.objectOf(PropTypes.any),
    controlled: PropTypes.bool,
    onTitleClick: PropTypes.func,
    name: PropTypes.string,
};

GroupContainer.defaultProps = {
    open: false,
    defaultOpen: false,
    title: '',
    children: null,
    style: {},
    titleStyle: {},
    controlled: false,
    onTitleClick: () => {},
    name: '',
};

export default GroupContainer;
