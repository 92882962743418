import { gql } from '@apollo/client';

export const GET_CREATIVE_TAGS = gql`
    query getCreativeTags($appId: String!, $imageHashes: [String!]!, $dimensionNames: [String]) {
        creativeTags(appId: $appId, imageHashes: $imageHashes, dimensionNames: $dimensionNames) {
            dimensionNames
            tags {
                imageHash
                dimensionName
                category
                tags
            }
        }
    }
`;

export const GET_DIMENSIONS_BY_CATEGORY = gql`
    query getDimensionsByCategory($appId: String!) {
        dimensionsByCategory(appId: $appId)
    }
`;

export const GET_USER_APPS = gql`
    query getUserApps {
        userApps {
            displayName
            name
            icon
        }
    }
`;

export const GET_USER_SOURCES = gql`
    query getUserSources($filteredSourcesNames: [String!]) {
        userSources(filteredSourcesNames: $filteredSourcesNames) {
            displayName
            name
            logoPath
        }
    }
`;

export const GET_USER_METRICS = gql`
    query getUserMetrics {
        userMetrics {
            metrics
            allCohortMetrics
        }
    }
`;
