import React from 'react';
import SingularButton from './SingularButton';

class ReportUnenrichedButton extends React.Component {
    render() {
        let { unenriched, ...restProps } = this.props;

        return (
            <SingularButton
                text="STATIC.BUTTONS.UNENRICHED"
                type="secondary"
                forceActive={unenriched}
                style={{ marginRight: '10px' }}
                {...restProps}
            />
        )
    }
}

export default ReportUnenrichedButton;
