export const LOAD = 'action.assistedInstalls.load';
export const DESTROY = 'action.assistedInstalls.destroy';

export const load = loading => {
    return {
        type: LOAD,
        loading,
    };
};

export const pageDestroy = () => {
    return {
        type: DESTROY,
    };
};
