import { Translate } from 'react-localize-redux';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import css from './ConversionModelContentInner.css';
import { ModelsApiContext } from '../hooks';
import { MAX_SLOTS_ON_SUMMARY } from '../consts';
import Chevron from '../../resources/icons/chevron.svg';

function getFieldEntries(fields, translationMap) {
    if (Array.isArray(translationMap)) {
        return translationMap;
    }

    // Sort so we have a consistent view (and not one dependent on property order)
    const entries = Object.entries(fields);
    entries.sort((d1, d2) => (d1[0] < d2[0] ? -1 : 1));

    return entries;
}

export function ConversionModelContentInner({ fields, translationMap, conversionModelGroupId }) {
    const { onModelGroupEditClick } = useContext(ModelsApiContext);

    const fieldsEntries = getFieldEntries(fields, translationMap);
    const showEditOpen = fieldsEntries.length >= MAX_SLOTS_ON_SUMMARY;
    const fieldsEntriesToPresent = fieldsEntries.slice(0, MAX_SLOTS_ON_SUMMARY);

    return (
        <>
            <div>
                {fieldsEntriesToPresent.map(([k, v]) => {
                    const keyVal = typeof k === 'string' ? k : v;
                    return (
                        <div className={css.conversionModelInnerContainer} key={`${conversionModelGroupId}_${keyVal}`}>
                            <span className={css.conversionModelInnerKey}>
                                {typeof k === 'string' ? (
                                    <Translate
                                        id={translationMap[k] ? `STATIC.PAGES.SKADNETWORK.${translationMap[k]}` : k}
                                    />
                                ) : (
                                    k
                                )}
                                :
                            </span>
                            <span className={css.conversionModelInnerValue}>{v}</span>
                        </div>
                    );
                })}
            </div>
            {showEditOpen && (
                <div
                    onClick={() => onModelGroupEditClick(conversionModelGroupId)}
                    className={css.conversionModelInnerClickMore}
                >
                    <Translate id="STATIC.PAGES.SKADNETWORK.MODEL_SUMMARY.SHOW_MORE" />
                    <Chevron className={css.chevron} />
                </div>
            )}
        </>
    );
}

ConversionModelContentInner.propTypes = {
    fields: PropTypes.any,
    translationMap: PropTypes.any,
    conversionModelGroupId: PropTypes.number,
};

ConversionModelContentInner.defaultProps = {
    fields: {},
    translationMap: {},
    conversionModelGroupId: 0,
};
