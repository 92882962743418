import API from '../services/api';
import { BENCHMARKS_PAGES } from './consts';

let instance = null;
const api = new API();
const BENCHMARKS_METADATA_URL = '/api/benchmarks/metadata';
const BENCHMARKS_MARKET_SHARE_OF_VOICE_DATA_URL = `/api/benchmarks/${BENCHMARKS_PAGES.MARKET_SHARE_OF_VOICE}`;
const BENCHMARKS_MARKET_SHARE_OF_VOICE_VS_ME_DATA_URL = `/api/benchmarks/${BENCHMARKS_PAGES.SHARE_OF_VOICE_VS_ME}`;
const BENCHMARKS_NETWORK_OPTIMIZATIONS = `/api/benchmarks/${BENCHMARKS_PAGES.NETWORK_OPTIMIZATIONS}`;

export default class BenchmarksService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getBenchmarksMetadata(viewName) {
        return api.getJson(BENCHMARKS_METADATA_URL, {
            method: 'GET',
            credentials: 'include',
            params: { view: viewName },
        });
    }

    getBenchmarksMarketShareOfVoiceData(selectedFilterValues) {
        return api.getJson(BENCHMARKS_MARKET_SHARE_OF_VOICE_DATA_URL, {
            method: 'GET',
            credentials: 'include',
            params: { ...selectedFilterValues },
        });
    }

    getBenchmarksShareOfVoiceVsMeData(selectedFilterValues) {
        return api.getJson(BENCHMARKS_MARKET_SHARE_OF_VOICE_VS_ME_DATA_URL, {
            method: 'GET',
            credentials: 'include',
            params: { ...selectedFilterValues },
        });
    }

    getBenchmarksNetworkOptimizations(selectedFilterValues) {
        return api.getJson(BENCHMARKS_NETWORK_OPTIMIZATIONS, {
            method: 'GET',
            credentials: 'include',
            params: { ...selectedFilterValues },
        });
    }
}
