import { useCallback } from 'react';
import SignupService from './service';
import { useRequest } from '../utils/customHooks';

export const useFetchCurrenciesTimezones = () => {
    const SignupAPI = new SignupService();
    return useRequest(
        useCallback(SignupAPI.getMetadataParameters, []),
        () => {
            return true;
        },
        () => {}
    );
};
