import {
    COARSE_BUCKET_DEFAULT_SIZE,
    COARSE_VALUE_HIGH,
    COARSE_VALUE_LOW,
    COARSE_VALUE_MEDIUM,
    COARSE_VALUE_UNUSED,
    COARSE_VALUES,
    COARSE_VALUES_WITH_UNUSED,
    DEFAULT_BUCKET_SIZE,
    DEFAULT_CUSTOM_REVENUE_BUCKETS,
    DEFAULT_CUSTOM_REVENUE_BUCKETS_MIXED_MODEL,
    DEFAULT_MAX_NUMBER_OF_BUCKETS,
    DEFAULT_MEASUREMENT_PERIOD,
    DEFAULT_MEASUREMENT_PERIOD_BY_POSTBACK_INDEX,
    DEFAULT_MIN_NUMBER_OF_BUCKETS,
    DEFAULT_NUMBER_OF_BUCKETS_FOR_MIXED,
    EVENT_TYPE_INDEX,
    EventsModelTypes,
    MIXED_TYPES,
    MODEL_NAME,
    MODEL_SLOTS_COLOR,
    ModelDisplayNames,
    ModelKinds,
    ModelTypes,
    NON_EMPTY_FIELDS_STEP_1,
    NON_EMPTY_FIELDS_STEP_1_ON_SAVE,
    NON_EMPTY_REVENUE_FIELDS_STEP_1,
    P1_INDEX,
    P2_INDEX,
    P3_INDEX,
    POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
    POSTBACK_CONVERSION_VALUE_TYPE_FINE,
    REQUIRED_FIELD,
    RETENTION_SLOTS_COLOR,
    RETENTION_SLOTS_TEXT,
    RevenueModelTypes,
    RevenueTypeDisplayNames,
    SHELF_P1_COARSE_CONFIGURABLE_STEPS,
    SHELF_P1_COARSE_CONFIGURABLE_STEPS_MODEL_KINDS,
    SHELF_STEP_P1,
    SHELF_STEP_P1_COARSE,
    SHELF_STEP_P2,
    SHELF_STEP_P3,
    SHELF_STEPS,
    SKAN4_TIMERS_MEASUREMENT_PERIOD,
    SKAN_COMPATIBILITY_MODE,
    SLOTS_SIGNS,
    USED_NAME,
    DECIMAL_PLACES,
} from './consts';
import { sortAlphabetically } from '../utils/sortUtil';
import { toSnakecase } from '../utils/strings';
import { WIZARD_FOOTER_HEIGHT } from '../components/widgets/WizardFooter';
import { diffHours } from '../utils/dateUtils';

// The options of Skan Plus statuses of a model
// OPTIMIZED_MODEL -> The model is already optimized
// UNOPTIMIZED_MODEL -> There is an optimization available for the model
// NO_OPTIMIZATION_MODEL -> This model can't have skan plus optimization applied on it
export const SKAN_PLUS_STATUSES = {
    OPTIMIZED_MODEL: 'optimized',
    UNOPTIMIZED_MODEL: 'unoptimized',
    NO_OPTIMIZATION_MODEL: 'noOptimization',
};

export const cloneObject = obj => {
    return JSON.parse(JSON.stringify(obj));
};

export const getModelKindName = (modelType, revenueType, eventsType) => {
    let modelKind = null;

    if (modelType === ModelTypes.REVENUE) {
        modelKind = ModelKinds.REVENUE;
    } else if (modelType === ModelTypes.EVENTS) {
        modelKind = eventsType;
    } else if (modelType === ModelTypes.CUSTOM) {
        if (eventsType === ModelKinds.FUNNEL) {
            modelKind = ModelKinds.FUNNEL;
        } else {
            modelKind = ModelKinds.CUSTOM;
        }
    } else if (modelType === ModelTypes.MIXED) {
        modelKind = ModelKinds.MIXED;
    }

    return modelKind;
};

export const getModelTypeData = (typesList, modelKind, eventsType) => {
    return typesList.find(({ name, mixed_types: MixedTypes }) => {
        return name === modelKind && (eventsType && MixedTypes ? MixedTypes[EVENT_TYPE_INDEX] === eventsType : 1);
    });
};

const getMixedModel = types => {
    const displayName = types.map(type => ModelDisplayNames[type]).join(' & ');
    return {
        name: ModelKinds.MIXED,
        mixed_types: types,
        display_name: `Mixed - ${displayName}`,
    };
};

export const getModelTypes = () => [
    {
        name: ModelKinds.REVENUE,
        display_name: ModelDisplayNames[ModelKinds.REVENUE],
        description: 'STATIC.PAGES.SKADNETWORK.MODEL_DESCRIPTIONS.REVENUE',
    },
    {
        name: ModelKinds.CONVERSION_EVENTS,
        display_name: ModelDisplayNames[ModelKinds.CONVERSION_EVENTS],
        description: 'STATIC.PAGES.SKADNETWORK.MODEL_DESCRIPTIONS.CONVERSION_EVENTS',
    },
    {
        name: ModelKinds.ENGAGEMENT,
        display_name: ModelDisplayNames[ModelKinds.ENGAGEMENT],
        description: 'STATIC.PAGES.SKADNETWORK.MODEL_DESCRIPTIONS.ENGAGEMENT',
    },
    {
        name: ModelKinds.FUNNEL,
        display_name: ModelDisplayNames[ModelKinds.FUNNEL],
        description: 'STATIC.PAGES.SKADNETWORK.MODEL_DESCRIPTIONS.FUNNEL',
    },
    ...Object.values(MIXED_TYPES).map(getMixedModel),
];

export function getBitLength(value) {
    if (value === 0) {
        return 0;
    }

    return value.toString(2).length;
}

export const getRetentionBits = measurementPeriod => {
    return measurementPeriod ? getBitLength(measurementPeriod - 1) : 0;
};

export function getUsedSlots(selectedType, measurementPeriod, modelSlotValues, isIncludeRetentionSlots) {
    let retentionSlots = [];

    if (isIncludeRetentionSlots) {
        retentionSlots = Array(getRetentionBits(measurementPeriod))
            .fill()
            .map(() => ({
                text: RETENTION_SLOTS_TEXT,
                color: RETENTION_SLOTS_COLOR,
            }));
    }

    if (!selectedType) {
        return retentionSlots;
    }

    if (!modelSlotValues || !modelSlotValues.length) {
        return retentionSlots;
    }

    const modelSlots = modelSlotValues.map(value => {
        const { text, color } = typeof value === 'string' ? { text: value } : value || {};

        return {
            text: text || '',
            color: color || MODEL_SLOTS_COLOR,
        };
    });

    return [...retentionSlots, ...modelSlots];
}

export const getMaxNumberOfBuckets = maxAvailableSlots => {
    return 2 ** maxAvailableSlots;
};

export function resolveModelTypeDisplayName(modelType, modelSubType) {
    const type = modelSubType || modelType;
    return RevenueTypeDisplayNames[type] || ModelDisplayNames[type];
}

export const isSkanSupported = app => {
    return app.sites && app.sites.some(site => site.is_skan_supported_for_sdk);
};

export const isSkan4Supported = app => {
    return isSkanSupported(app) & app.sites.some(site => site.is_skan_4_supported_for_sdk);
};

export const hasModel = app => {
    const APP_FIELD_OFF = 'Off';
    return app.modelType && app.modelType !== APP_FIELD_OFF;
};

export const sortApps = applications => {
    return applications.sort((app1, app2) => {
        const hasModel1 = hasModel(app1);
        const hasModel2 = hasModel(app2);
        if (hasModel1 && !hasModel2) return -1;
        if (!hasModel1 && hasModel2) return 1;

        const skanSupp1 = isSkanSupported(app1);
        const skanSupp2 = isSkanSupported(app2);
        if (skanSupp1 && !skanSupp2) return 1;
        if (!skanSupp1 && skanSupp2) return -1;

        return sortAlphabetically(app1.name, app2.name, false, false);
    });
};

export const parseCustomMapping = customMapping => {
    const parsedCustomMapping = {};

    Object.keys(customMapping).forEach(coarseValue => {
        customMapping[coarseValue].forEach(index => {
            parsedCustomMapping[index] = coarseValue;
        });
    });

    return parsedCustomMapping;
};

export const getInitialCustomMapping = numberOfElements => {
    const initialCustomMapping = {};
    const lastMediumIndex = Math.floor(numberOfElements / 2);

    let coarseValue;
    let indexes;

    Array.from(Array(numberOfElements)).forEach((value, index) => {
        if (index === 0) {
            coarseValue = COARSE_VALUE_LOW;
        } else if (index <= lastMediumIndex) {
            coarseValue = COARSE_VALUE_MEDIUM;
        } else {
            coarseValue = COARSE_VALUE_HIGH;
        }

        indexes = initialCustomMapping[coarseValue];
        initialCustomMapping[coarseValue] = indexes ? [...indexes, index] : [index];
    });

    return parseCustomMapping(initialCustomMapping);
};

export const getRelevantModel = (conversionModels = [], selectedPostbackIndex, selectedConversionValueType) => {
    return conversionModels.find(
        ({ postbackIndex, postbackConversionValueType }) =>
            postbackIndex === selectedPostbackIndex && postbackConversionValueType === selectedConversionValueType
    );
};

export const getModelMeasurementPeriod = model => {
    return model.measurementPeriod || DEFAULT_MEASUREMENT_PERIOD_BY_POSTBACK_INDEX[model.postbackIndex];
};

export const getEnabledFineModel = groups => {
    let enabledModel = null;

    groups.forEach(({ conversionModels }) => {
        if (enabledModel) return;

        const relevantModel = getRelevantModel(conversionModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE);
        enabledModel = relevantModel?.enabled ? relevantModel : null;
    });

    return enabledModel ? { ...enabledModel, measurementPeriod: getModelMeasurementPeriod(enabledModel) } : null;
};

export const getGroupById = (groups, groupId) => {
    return groups.find(({ id }) => id === groupId);
};

export const isGroupEnabled = conversionModels => {
    return !!conversionModels.find(({ enabled }) => enabled);
};

export const isGroupEnabledByGroupId = (groups, groupId) => {
    const group = getGroupById(groups, groupId);

    return isGroupEnabled(group.conversionModels);
};

export const isSkan4ModelsSector = conversionModels => {
    return conversionModels && conversionModels.length > 1;
};

export const getShelfSteps = modelKind => {
    if (SHELF_P1_COARSE_CONFIGURABLE_STEPS_MODEL_KINDS.includes(modelKind)) {
        return SHELF_P1_COARSE_CONFIGURABLE_STEPS;
    }

    return SHELF_STEPS;
};

export const calculateUsedFunnelSlots = (eventIds, buckets) => {
    return Array(getBitLength(eventIds.length + buckets.length - 1)).fill(SLOTS_SIGNS[ModelKinds.FUNNEL]);
};

export function getNonAscendingBucketIndex(buckets) {
    if (!buckets || !buckets.length) {
        return null;
    }

    let currentValue = buckets[0];

    for (let i = 1; i < buckets.length; i++) {
        const nextBucket = buckets[i];

        if (currentValue >= nextBucket) {
            return i - 1;
        }

        currentValue = nextBucket;
    }

    return null;
}

export const calculateNonCustomBuckets = (numberOfRevenueBuckets, unit) => {
    return Array(parseInt(numberOfRevenueBuckets, 10) - 1)
        .fill()
        .map((_, i) => Number((i * parseFloat(unit)).toFixed(DECIMAL_PLACES)));
};

export const getNumberOfRevenueSlots = (isCustomRevenue, numberOfRevenueBuckets, buckets) => {
    if (!numberOfRevenueBuckets) {
        return 0;
    }

    const bucketCount = isCustomRevenue ? buckets.length : parseInt(numberOfRevenueBuckets, 10) - 1;

    return getBitLength(bucketCount);
};

export const calculateUsedRevenueSlots = (isCustomRevenue, numberOfRevenueBuckets, buckets) => {
    return Array(getNumberOfRevenueSlots(isCustomRevenue, numberOfRevenueBuckets, buckets))
        .fill()
        .map(() => SLOTS_SIGNS[ModelKinds.REVENUE]);
};

export const getStepFormValues = (conversionModelGroupId, model, conversionModels) => {
    const {
        revenueBuckets: buckets,
        revenueBucketSize: unit,
        numberOfRevenueBuckets,
        isCustomRevenue,
        postbackConversionValueType,
        modelType,
        revenueType,
        eventsType,
        customMapping,
        apsalarEventIds,
    } = model;

    const isFineModel = postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_FINE;
    const additionalFields = {};

    if (isFineModel) {
        const modelKind = getModelKindName(modelType, revenueType, eventsType);
        const shelfSteps = getShelfSteps(modelKind);

        if (!shelfSteps.includes(SHELF_STEP_P1_COARSE)) {
            if (isSkan4ModelsSector(conversionModels)) {
                const p1CourseModel = getRelevantModel(
                    conversionModels,
                    P1_INDEX,
                    POSTBACK_CONVERSION_VALUE_TYPE_COARSE
                );
                additionalFields.customMapping = parseCustomMapping(p1CourseModel.customMapping);
            }

            if (modelType === ModelTypes.REVENUE) {
                additionalFields.usedSlots = calculateUsedRevenueSlots(
                    isCustomRevenue,
                    numberOfRevenueBuckets,
                    buckets
                );
            } else if (modelKind === ModelKinds.FUNNEL) {
                additionalFields.apsalarEventIds = [null, ...apsalarEventIds];
                additionalFields.usedSlots = calculateUsedFunnelSlots(apsalarEventIds, buckets);
            }
        }
    }

    if (customMapping && Object.keys(customMapping).length) {
        additionalFields.customMapping = parseCustomMapping(customMapping);
    }

    return {
        ...model,
        buckets,
        unit,
        conversionModelGroupId,
        ...additionalFields,
    };
};

export function getDefaultBuckets(isCustomRevenue, isMixedModel, maxNumberOfBuckets, unit) {
    const customBuckets = isMixedModel ? DEFAULT_CUSTOM_REVENUE_BUCKETS_MIXED_MODEL : DEFAULT_CUSTOM_REVENUE_BUCKETS;
    return isCustomRevenue ? customBuckets : calculateNonCustomBuckets(maxNumberOfBuckets, unit);
}

const getCoarseDefaultFormValues = () => {
    const buckets = [];

    for (let i = 0; i < Object.keys(COARSE_VALUES).length; i++) {
        buckets.push(i * COARSE_BUCKET_DEFAULT_SIZE);
    }

    return {
        revenueType: RevenueModelTypes.ALL,
        buckets,
        eventsType: null,
        apsalarEventIds: [],
    };
};

const getRevenueDefaultFormValues = (shelfStep, isMixedModel, isMultiStep) => {
    if (shelfStep === SHELF_STEP_P1) {
        const isCustomRevenue = true;
        const numberOfRevenueBuckets = DEFAULT_MAX_NUMBER_OF_BUCKETS;
        const buckets = getDefaultBuckets(isCustomRevenue, isMixedModel);

        return {
            isCustomRevenue,
            numberOfRevenueBuckets,
            modelType: ModelTypes.REVENUE,
            revenueType: RevenueModelTypes.ALL,
            customMapping: isMultiStep ? getInitialCustomMapping(DEFAULT_MIN_NUMBER_OF_BUCKETS) : {},
            buckets,
            usedSlots: calculateUsedRevenueSlots(isCustomRevenue, numberOfRevenueBuckets, buckets),
            unit: DEFAULT_BUCKET_SIZE,
        };
    }

    return getCoarseDefaultFormValues();
};

const getRevenueUpgradeDefaultFormValues = firstStepFormValues => {
    const { numberOfRevenueBuckets } = firstStepFormValues;
    return {
        ...firstStepFormValues,
        customMapping: getInitialCustomMapping(numberOfRevenueBuckets),
    };
};

const getFunnelUpgradeDefaultFormValues = firstStepFormValues => {
    const { apsalarEventIds = [], buckets = [] } = firstStepFormValues;

    return {
        ...firstStepFormValues,
        modelType: buckets.length ? ModelTypes.CUSTOM : ModelTypes.EVENTS,
        customMapping: getInitialCustomMapping(apsalarEventIds.length + buckets.length),
    };
};

const getEventsDefaultFormValues = (shelfStep, eventsType) => {
    if (shelfStep === SHELF_STEP_P1) {
        return {
            modelType: ModelTypes.EVENTS,
            eventsType,
            apsalarEventIds: [],
        };
    } else if (shelfStep === SHELF_STEP_P1_COARSE) {
        return {};
    }

    return getCoarseDefaultFormValues();
};

const getFunnelDefaultFormValues = (shelfStep, modelKind, isMultiStep, multiCoarseMapping) => {
    const unusedValueStr = COARSE_VALUE_UNUSED.toString();
    const lowValueStr = COARSE_VALUE_LOW.toString();
    const customMapping = multiCoarseMapping
        ? { 0: lowValueStr, 1: lowValueStr }
        : { 0: lowValueStr, 1: unusedValueStr };

    if (shelfStep === SHELF_STEP_P1) {
        return {
            modelType: ModelTypes.EVENTS,
            eventsType: ModelKinds.FUNNEL,
            apsalarEventIds: [null, null],
            customMapping: isMultiStep ? customMapping : {},
        };
    }

    return getCoarseDefaultFormValues();
};

export const getMixedDefaultFormValues = (shelfStep, eventsType, isMultiStep) => {
    if (shelfStep === SHELF_STEP_P1) {
        const step1values = {
            ...getRevenueDefaultFormValues(shelfStep, true, isMultiStep),
            ...getEventsDefaultFormValues(shelfStep, eventsType, isMultiStep),
            numberOfRevenueBuckets: DEFAULT_NUMBER_OF_BUCKETS_FOR_MIXED,
            modelType: ModelTypes.MIXED,
            isCustomRevenue: false,
        };

        delete step1values.customMapping;

        return step1values;
    }

    return getCoarseDefaultFormValues();
};

export const DEFAULT_FORM_VALUES_PER_MODEL = {
    [ModelKinds.REVENUE]: getRevenueDefaultFormValues,
    [ModelKinds.CONVERSION_EVENTS]: getEventsDefaultFormValues,
    [ModelKinds.FUNNEL]: getFunnelDefaultFormValues,
    [ModelKinds.MIXED]: getMixedDefaultFormValues,
    [ModelKinds.ENGAGEMENT]: getEventsDefaultFormValues,
};

const DEFAULT_UPGRADE_STEPS_VALUES = {
    [ModelKinds.REVENUE]: {
        [SHELF_STEP_P1]: getRevenueUpgradeDefaultFormValues,
    },
    [ModelKinds.CONVERSION_EVENTS]: {
        [SHELF_STEP_P1]: existStep => existStep,
    },
    [ModelKinds.MIXED]: {
        [SHELF_STEP_P1]: existStep => existStep,
    },
    [ModelKinds.FUNNEL]: {
        [SHELF_STEP_P1]: getFunnelUpgradeDefaultFormValues,
    },
    [ModelKinds.ENGAGEMENT]: {
        [SHELF_STEP_P1]: existStep => existStep,
    },
};

export const DEFAULT_COARSE_P1_TOOLTIP_MODEL = {
    [ModelKinds.ENGAGEMENT]: 'STATIC.PAGES.SKADNETWORK.ENGAGEMENT_EVENT_COLUMN_TOOLTIP',
};

const getInitFormValues = (shelfSteps, modelKind, multiCoarseMapping, firstStepFormValues) => {
    const stepsFormValues = {};

    const { name: modelKindName, mixed_types: ModelMixedTypes } = modelKind;

    const eventType =
        modelKindName === ModelKinds.MIXED
            ? ModelMixedTypes[EVENT_TYPE_INDEX]
            : Object.values(EventsModelTypes).find(eventModel => eventModel === modelKindName);

    const isMultiStep = shelfSteps.length > 1;

    shelfSteps.forEach(shelfStep => {
        const upgradeFunc = DEFAULT_UPGRADE_STEPS_VALUES[modelKindName]
            ? DEFAULT_UPGRADE_STEPS_VALUES[modelKindName][shelfStep]
            : null;

        if (upgradeFunc && firstStepFormValues) {
            stepsFormValues[shelfStep] = upgradeFunc(firstStepFormValues);
        } else {
            stepsFormValues[shelfStep] = DEFAULT_FORM_VALUES_PER_MODEL[modelKindName](
                shelfStep,
                eventType,
                isMultiStep,
                multiCoarseMapping
            );
        }

        if (shelfStep === SHELF_STEP_P1) {
            const stepValues = stepsFormValues[shelfStep];

            if (!stepValues.measurementPeriod) {
                stepValues.measurementPeriod = SKAN4_TIMERS_MEASUREMENT_PERIOD;
            }
        }
    });

    return stepsFormValues;
};

export const getStepsFormValues = (
    shelfSteps,
    conversionModelGroup,
    currencySymbol,
    modelKind,
    modelGroupName,
    multiCoarseMapping,
    isSkan3Mode,
    isUpgradeMode
) => {
    let stepsFormValues = {};
    const { conversionModels, id: conversionModelGroupId } = conversionModelGroup || {};

    let compatibilityMode =
        isSkan3Mode && !isUpgradeMode ? SKAN_COMPATIBILITY_MODE.SKAN_3_ONLY : SKAN_COMPATIBILITY_MODE.SKAN_4;

    // In case no model group exists (when creating a new model).
    if (!conversionModels) {
        stepsFormValues = getInitFormValues(shelfSteps, modelKind, multiCoarseMapping);
        const p1Values = stepsFormValues[SHELF_STEP_P1];

        if (compatibilityMode === SKAN_COMPATIBILITY_MODE.SKAN_3_ONLY) {
            p1Values.measurementPeriod = DEFAULT_MEASUREMENT_PERIOD;
        }
    }
    // In case of edit mode.
    else {
        const p1FineModel = getRelevantModel(conversionModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE);
        const { modelType, revenueType, eventsType, measurementPeriod } = p1FineModel;

        if (conversionModels.length === 1) {
            compatibilityMode = SKAN_COMPATIBILITY_MODE.SKAN_3_ONLY;
        } else if (!measurementPeriod) {
            compatibilityMode = SKAN_COMPATIBILITY_MODE.SKAN_4;
        } else {
            compatibilityMode = SKAN_COMPATIBILITY_MODE.SKAN_3;
        }

        const firstStepFormValues = getStepFormValues(conversionModelGroupId, p1FineModel, conversionModels);

        // In case the model type has changed.
        if (modelKind.name !== getModelKindName(modelType, revenueType, eventsType)) {
            stepsFormValues = getInitFormValues(shelfSteps, modelKind, multiCoarseMapping);
        }
        // In case of skan 3 model edit / upgrade.
        else if (isSkan3Mode) {
            stepsFormValues = getInitFormValues(shelfSteps, modelKind, multiCoarseMapping, firstStepFormValues);

            if (isUpgradeMode) {
                compatibilityMode = SKAN_COMPATIBILITY_MODE.SKAN_4;
            }
        } else {
            const p1CoarseModel = getRelevantModel(conversionModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_COARSE);
            const p2Model = getRelevantModel(conversionModels, P2_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_COARSE);
            const p3Model = getRelevantModel(conversionModels, P3_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_COARSE);

            stepsFormValues[SHELF_STEP_P1] = firstStepFormValues;

            if (shelfSteps.includes(SHELF_STEP_P1_COARSE) && p1CoarseModel) {
                stepsFormValues[SHELF_STEP_P1_COARSE] = getStepFormValues(
                    conversionModelGroupId,
                    p1CoarseModel,
                    conversionModels
                );
            }

            stepsFormValues[SHELF_STEP_P2] = getStepFormValues(conversionModelGroupId, p2Model, conversionModels);
            stepsFormValues[SHELF_STEP_P3] = getStepFormValues(conversionModelGroupId, p3Model, conversionModels);
        }
    }

    const result = {
        [SHELF_STEP_P1]: {
            ...stepsFormValues[SHELF_STEP_P1],
            conversionModelGroupId,
            modelGroupName,
            currency: currencySymbol,
            postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_FINE,
            postbackIndex: P1_INDEX,
            compatibilityMode,
        },
        [SHELF_STEP_P2]: {
            ...stepsFormValues[SHELF_STEP_P2],
            currency: currencySymbol,
            postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
            postbackIndex: P2_INDEX,
        },
        [SHELF_STEP_P3]: {
            ...stepsFormValues[SHELF_STEP_P3],
            currency: currencySymbol,
            postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
            postbackIndex: P3_INDEX,
        },
    };

    if (stepsFormValues[SHELF_STEP_P1_COARSE]) {
        result[SHELF_STEP_P1_COARSE] = {
            ...stepsFormValues[SHELF_STEP_P1_COARSE],
            postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
            postbackIndex: P1_INDEX,
            currency: currencySymbol,
            revenueType: stepsFormValues[SHELF_STEP_P1].revenueType,
            eventsType: stepsFormValues[SHELF_STEP_P1].eventsType,
        };
    }

    return result;
};

export const parseCoarsePostback = (conversionModel, formValuesByStep, isCoarseMapping) => {
    const { customMapping, buckets } = formValuesByStep;

    conversionModel.postbackConversionValueType = POSTBACK_CONVERSION_VALUE_TYPE_COARSE;

    if (isCoarseMapping) {
        conversionModel.modelType = ModelTypes.COARSE_MAPPING;
        const updatedCustomMapping = { 0: [], 1: [], 2: [] };

        for (const fineValue in customMapping) {
            const coarseValue = customMapping[fineValue];

            if (coarseValue !== COARSE_VALUE_UNUSED.toString()) {
                updatedCustomMapping[coarseValue].push(+fineValue);
            }
        }

        conversionModel.customMapping = updatedCustomMapping;

        delete formValuesByStep.customMapping;
    } else {
        conversionModel.modelType = ModelTypes.CUSTOM_COARSE;
        conversionModel.revenueBuckets = buckets;

        delete formValuesByStep.buckets;
        delete formValuesByStep.revenueBuckets;
        delete formValuesByStep.measurementPeriod;
        delete formValuesByStep.modelType;
        delete formValuesByStep.customMapping;

        for (const property in formValuesByStep) {
            conversionModel[property] = formValuesByStep[property];
        }
    }
};

export const parseFinePostback = (conversionModel, formValuesByStep) => {
    const valuesForPayload = { ...formValuesByStep };
    const { modelType, buckets, apsalarEventIds, isCustomRevenue } = valuesForPayload;
    conversionModel.modelType = modelType;

    conversionModel.revenueBuckets = buckets;
    conversionModel.apsalarEventIds = apsalarEventIds?.filter(e => !!e);

    delete valuesForPayload.unit;
    delete valuesForPayload.numberOfRevenueBuckets;
    delete valuesForPayload.buckets;
    delete valuesForPayload.revenueBuckets;
    delete valuesForPayload.apsalarEventIds;
    delete valuesForPayload.customMapping;

    // Temp hardcoded until skan 4 timers toggle.
    conversionModel.measurementPeriod = DEFAULT_MEASUREMENT_PERIOD;

    if (isCustomRevenue) {
        delete valuesForPayload.revenueBucketSize;
    }

    for (const property in valuesForPayload) {
        conversionModel[property] = valuesForPayload[property];
    }
};

export const parseShelfStepsValuesToConversionModels = (formValuesByStep, conversionModelGroup) => {
    const p1fine = {
        postbackIndex: P1_INDEX,
        postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_FINE,
    };
    const p1coarse = {
        postbackIndex: P1_INDEX,
        postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
    };
    const p2coarse = {
        postbackIndex: P2_INDEX,
        postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
    };
    const p3coarse = {
        postbackIndex: P3_INDEX,
        postbackConversionValueType: POSTBACK_CONVERSION_VALUE_TYPE_COARSE,
    };

    const formValuesByStepCopy = cloneObject(formValuesByStep);
    const p1StepValues = formValuesByStepCopy[SHELF_STEP_P1];

    const parsedModelGroup = {
        ...(conversionModelGroup || {}),
        conversionModels: [p1fine, p1coarse, p2coarse, p3coarse],
        name: p1StepValues.modelGroupName,
    };

    parseCoarsePostback(p1coarse, formValuesByStepCopy[SHELF_STEP_P1_COARSE] || p1StepValues, true);
    parseFinePostback(p1fine, p1StepValues);
    parseCoarsePostback(p2coarse, formValuesByStepCopy[SHELF_STEP_P2], false);
    parseCoarsePostback(p3coarse, formValuesByStepCopy[SHELF_STEP_P3], false);

    return parsedModelGroup;
};

export const parseConversionModelToPayload = model => {
    return Object.fromEntries(
        Object.entries(model).map(([key, value]) => {
            return [toSnakecase(key), value];
        })
    );
};

export const parseConversionModelGroupToPayload = (conversionModelGroup, isMultiStep) => {
    let parsedConversionModels = conversionModelGroup.conversionModels;
    const parseConversionModelsToPayload = [];

    if (!isMultiStep) {
        parsedConversionModels = parsedConversionModels.filter(
            ({ postbackConversionValueType }) => postbackConversionValueType === POSTBACK_CONVERSION_VALUE_TYPE_FINE
        );
    }

    parsedConversionModels.forEach(model => {
        const conversionModelPayload = parseConversionModelToPayload(model);
        parseConversionModelsToPayload.push(conversionModelPayload);
    });

    const parseGroupToPayload = {};
    conversionModelGroup.conversionModels = parseConversionModelsToPayload;

    for (const property in conversionModelGroup) {
        const underscoreProperty = toSnakecase(property);
        parseGroupToPayload[underscoreProperty] = conversionModelGroup[property];
    }

    return parseGroupToPayload;
};

const getValidationErrorsSingleCoarseMapping = formValues => {
    const { customMapping } = formValues || {};

    if (customMapping && Object.keys(customMapping).length) {
        const isAllValuesUnused = Object.values(customMapping).every(value => value === COARSE_VALUE_UNUSED.toString());

        if (isAllValuesUnused) {
            const generalError = { errorMsg: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUE_NOT_SELECTED' };
            return { isValid: false, generalError };
        }
    }

    return { isValid: true };
};

export const getCustomMappingErrors = customMapping => {
    const customMappingErrors = {};
    let isValidCustomMapping = true;

    for (let i = 0; i < Object.keys(customMapping).length; i++) {
        const coarseValue = parseInt(customMapping[i], 10);

        if (i === 0) {
            if (coarseValue !== 0 && !coarseValue) {
                customMappingErrors[i] = { errorCode: 'STATIC.PAGES.SKADNETWORK.REQUIRED_FIELD' };
                isValidCustomMapping = false;
            }
        } else {
            const prevIndexCoarseValue = parseInt(customMapping[i - 1], 10);

            if (!isValidCustomMapping) {
                customMappingErrors[i] = customMappingErrors[i - 1];
            } else if (coarseValue !== 0 && !coarseValue) {
                customMappingErrors[i] = { errorCode: 'STATIC.PAGES.SKADNETWORK.REQUIRED_FIELD' };
                isValidCustomMapping = false;
            } else if (coarseValue < prevIndexCoarseValue) {
                customMappingErrors[i] = { errorCode: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUES_ASCENDING_ERROR' };
                isValidCustomMapping = false;
            } else if (coarseValue === 0) {
                customMappingErrors[i] = { warningCode: 'STATIC.PAGES.SKADNETWORK.COARSE_LOW_VALUE_WARNING' };
            }
        }
    }

    return { customMappingErrors, isValidCustomMapping };
};

export const getValidationErrorsStep1 = (formValues, isOnSave, isMultiStep) => {
    const formValidationErrors = {};
    const values = formValues || {};
    const {
        buckets = [],
        apsalarEventIds = [],
        customMapping = {},
        modelType,
        revenueType,
        eventsType,
        modelGroupName,
        conversionModelIdToName,
        conversionModelGroupId,
    } = values;
    let isValidForm = true;
    let tableGeneralError = {};

    for (const [groupId, groupName] of Object.entries(conversionModelIdToName)) {
        if (
            (!conversionModelGroupId || groupId.toString() !== conversionModelGroupId.toString()) &&
            groupName === modelGroupName
        ) {
            isValidForm = false;
            formValidationErrors[MODEL_NAME] = { errorCode: USED_NAME };
            break;
        }
    }

    let emptyFieldsToValidate = isOnSave
        ? NON_EMPTY_FIELDS_STEP_1.concat(NON_EMPTY_FIELDS_STEP_1_ON_SAVE)
        : NON_EMPTY_FIELDS_STEP_1;

    if (modelType === ModelTypes.REVENUE) {
        emptyFieldsToValidate = emptyFieldsToValidate.concat(NON_EMPTY_REVENUE_FIELDS_STEP_1);
    }

    emptyFieldsToValidate.forEach(key => {
        if (!values[key]) {
            isValidForm = false;
            formValidationErrors[key] = { errorCode: REQUIRED_FIELD };
        }
    });

    if (revenueType) {
        if (buckets.length < 2) {
            isValidForm = false;
            tableGeneralError = { errorMsg: 'STATIC.PAGES.SKADNETWORK.BUCKETS_TABLE_MIN_ROW_COUNT_ERROR' };
        }

        const nonAscendingBucketIndex = getNonAscendingBucketIndex(buckets);

        if (nonAscendingBucketIndex !== null) {
            const errorMsg = 'STATIC.PAGES.SKADNETWORK.BUCKETS_SORTED_ERROR';

            formValidationErrors.buckets = { [nonAscendingBucketIndex]: { errorMsg } };
            isValidForm = false;
            tableGeneralError = { errorMsg };
        }

        const { customMappingErrors, isValidCustomMapping } = getCustomMappingErrors(customMapping);

        formValidationErrors.customMapping = customMappingErrors;

        if (!isValidCustomMapping) {
            isValidForm = false;
            tableGeneralError = { errorMsg: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUES_HAS_ERROR' };
        }
    }

    if (eventsType) {
        if (!apsalarEventIds.filter(e => !!e).length) {
            isValidForm = false;
            formValidationErrors.sdkEvents = { errorMsg: 'STATIC.PAGES.SKADNETWORK.NO_EVENTS_ERROR' };
        }
    }

    if (isValidForm && isMultiStep) {
        const { isValid, generalError } = getValidationErrorsSingleCoarseMapping(formValues);

        if (!isValid) {
            isValidForm = false;
            tableGeneralError = generalError;
        }
    }

    return { formValidationErrors, isValidForm, tableGeneralError };
};

export const getValidationErrorsStep1Coarse = formValues => {
    const errorValidation = { formValidationErrors: {}, isValidForm: true };

    const { isValid, generalError } = getValidationErrorsSingleCoarseMapping(formValues);

    if (!isValid) {
        errorValidation.isValidForm = false;
        errorValidation.tableGeneralError = generalError;
    }

    return errorValidation;
};

const getCoarseValidationErrors = formValues => {
    const formValidationErrors = {};
    let isValidForm = true;
    const values = formValues || {};
    const { apsalarEventIds, buckets } = values;
    let tableGeneralError = {};

    // Create an empty json of buckets error for each event that has selected as a coarse value.
    const bucketsErrors = apsalarEventIds ? apsalarEventIds.map(() => ({})) : [];

    if (buckets) {
        formValidationErrors.buckets = bucketsErrors.concat(
            buckets.map((currentBucketVal, index) => {
                const nextBucket = buckets[index + 1];

                if (index < buckets.length - 1 && (typeof nextBucket !== 'number' || currentBucketVal >= nextBucket)) {
                    isValidForm = false;
                    return { errorMsg: 'RANGE_ERROR' };
                }

                return {};
            })
        );

        if (!isValidForm) {
            const errorMsg = 'STATIC.PAGES.SKADNETWORK.BUCKETS_SORTED_ERROR';
            tableGeneralError = { errorMsg };
        }
    }

    return { formValidationErrors, isValidForm, tableGeneralError };
};

export const VALIDATION_ERRORS_FUNCTIONS = {
    [SHELF_STEP_P1]: getValidationErrorsStep1,
    [SHELF_STEP_P1_COARSE]: getValidationErrorsStep1Coarse,
    [SHELF_STEP_P2]: getCoarseValidationErrors,
    [SHELF_STEP_P3]: getCoarseValidationErrors,
};

export const getEventSlotName = index => {
    return `${SLOTS_SIGNS[ModelTypes.EVENTS]}${index + 1}`;
};

export const scrollIntoViewIfNeeded = target => {
    // Target is outside the viewport from the bottom.
    if (target.getBoundingClientRect().bottom > window.innerHeight - WIZARD_FOOTER_HEIGHT) {
        target.scrollIntoView({
            behavior: 'smooth',
        });
    }
};

export const isSkan4Timers = model => {
    const { compatibilityMode, measurementPeriod } = model || {};
    return compatibilityMode === SKAN_COMPATIBILITY_MODE.SKAN_4 || !measurementPeriod;
};

export const getSdkEventsValues = sdkEvents => {
    return Object.values(sdkEvents)
        .reduce((acc, obj) => {
            if (!acc.some(item => item.name === obj.name)) {
                acc.push(obj);
            }
            return acc;
        }, [])
        .sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
};

export const getCoarseValueByIndex = (customMapping, index) => {
    if (!customMapping[index]) return null;

    return { name: customMapping[index], display_name: COARSE_VALUES_WITH_UNUSED[customMapping[index]] };
};

export const is48HoursMultiChangeModel = groups => {
    let last48HoursEnabledModelsCount = 0;
    let isEnabledModelBeforeLast48Hours = false;
    const nowDate = new Date();

    groups.forEach(group => {
        const fineModel = getRelevantModel(group.conversionModels, P1_INDEX, POSTBACK_CONVERSION_VALUE_TYPE_FINE);
        const enabledDatetime = new Date(fineModel.enabledDatetime * 1000);
        const disabledDatetime = new Date(fineModel.disabledDatetime * 1000);
        const hoursDiff = diffHours(nowDate, disabledDatetime || enabledDatetime);

        if (hoursDiff < DEFAULT_MEASUREMENT_PERIOD_BY_POSTBACK_INDEX[P1_INDEX] * 24) {
            last48HoursEnabledModelsCount++;
        } else {
            isEnabledModelBeforeLast48Hours = isEnabledModelBeforeLast48Hours || fineModel.enabled;
        }
    });

    return last48HoursEnabledModelsCount >= (isEnabledModelBeforeLast48Hours ? 1 : 2);
};

export const isSkan3CompatibilityMode = compatibilityMode => {
    return compatibilityMode === SKAN_COMPATIBILITY_MODE.SKAN_3_ONLY;
};
