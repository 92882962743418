import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import css from './LabeledCheckboxList.css';

class LabeledCheckboxList extends React.Component {
    render() {
        const { fields, columnsNumber, onChange } = this.props;

        const style = {
            textOverflow: 'ellipsis',
            width: `${100 / columnsNumber}%`,
            overflow: 'hidden',
            paddingRight: '5px',
            fontSize: '14px',
            whiteSpace: 'nowrap',
        };
        const checkmarkStyle = {
            marginRight: '6px',
        };
        return (
            <div className={css.lisContainer}>
                {fields.map((field) => (
                    <Checkbox
                        key={field.id}
                        style={style}
                        checkmarkStyle={checkmarkStyle}
                        checked={field.selected}
                        label={field.display_name}
                        onChange={() => onChange(field.name, !field.selected)}
                        size="small"
                    />
                ))}
            </div>
        );
    }
}

LabeledCheckboxList.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.any),
    columnsNumber: PropTypes.number,
    onChange: PropTypes.func,
};

LabeledCheckboxList.defaultProps = {
    fields: [],
    columnsNumber: 4,
    onChange: () => {},
};

export default LabeledCheckboxList;
