export const UPDATED_DIMENSIONS = 'action.fields.updated.dimensions';
export const UPDATED_FILTERS = 'action.fields.updated.filters';
export const UPDATE_FIELDS = 'action.fields.update';
export const UPDATED_FIELD = 'action.fields.updated.field';
export const FETCHED_FIELDS = 'action.fields.fetched.fields';
export const FETCH_ALL_FIELDS = 'action.fields.fetch.fields';
export const UPDATE_ALL_FIELDS = 'action.fields.updated.all';

export const fetchAllFields = () => {
    return { type: FETCH_ALL_FIELDS };
};

export const updatedFields = (fieldsList, fieldType) => {
    return {
        type: UPDATE_ALL_FIELDS,
        fieldsList,
        fieldType,
    };
};

export const updatedDimensions = dimensions => {
    return {
        type: UPDATED_DIMENSIONS,
        dimensions,
    };
};

export const updatedFilters = filters => {
    return {
        type: UPDATED_FILTERS,
        filters,
    };
};

export const updatedField = (fieldType, data) => {
    return {
        type: UPDATED_FIELD,
        fieldType,
        data,
    };
};

export const updateFields = args => {
    return {
        type: UPDATE_FIELDS,
        args,
    };
};

export const fetchedFields = () => {
    return {
        type: FETCHED_FIELDS,
    };
};
