import { connect } from 'react-redux';
import { updateAgencySelectionForm } from 'teamManagement/actions';
import AgencySelectionForm from '../components/AgencySelectionForm';

const mapStateToProps = state => {
    const { orgAgencies, agencySelectionForm } = state.teamManagement;
    return {
        orgAgencies,
        agencySelectionForm,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateAgencySelectionForm: agency => dispatch(updateAgencySelectionForm(agency)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencySelectionForm);
