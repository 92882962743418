import { createSelector } from 'reselect';
import { getMenuItemsConfig, getMenuItemsOrder, SDK_CONFIGURATION, SETUP_TASKS, shouldDisplayItem } from './utils';
import { ROLE_RESTRICTED } from '../teamManagement/utils';

export const getHomepageData = state => state.homepage;
export const getError = state => state.homepage.error;
export const getIsApsalarEnabled = state => state.homepage.apsalarEnabled;
export const getSdkToken = state => state.homepage.sdkToken;
export const getSdkKey = state => state.homepage.sdkKey;
export const getValidationId = state => state.homepage.validationId;
export const getValidationMessage = state => state.homepage.validationMessage;
export const getIsSdkAuditLoading = state => state.homepage.isSdkAuditLoading;
export const getIsGeneralAuditLoading = state => state.homepage.isGeneralAuditLoading;
export const getValidationType = state => state.homepage.validationType;
export const getUserData = state => state.user.data;
export const getIsRestrictedUser = state => state.user.data.role_type === ROLE_RESTRICTED;
export const getCompletedTasks = state => state.homepage.completedTasks;

export const getIsPlanYourOnboardingTaskComplete = state => !!getCompletedTasks(state)[SDK_CONFIGURATION];

export const getMenuItems = createSelector([getHomepageData, getIsRestrictedUser], (homepageData, isRestricted) => {
    return getMenuItemsConfig(homepageData, isRestricted);
});

export const getMenuOrder = createSelector([getHomepageData], homepageData => {
    return getMenuItemsOrder(homepageData);
});

export const getOnboardingProgressData = createSelector(
    [getCompletedTasks, getMenuOrder, getMenuItems],
    (completedTasks, menuItemsOrder, menuItemsConfig) => {
        const filteredMenuItems = menuItemsOrder[0].items
            .filter(itemId => {
                return (
                    menuItemsConfig[itemId].onboardingGuideCategory === SETUP_TASKS &&
                    shouldDisplayItem(menuItemsConfig[itemId])
                );
            })
            .map(item => {
                return menuItemsConfig[item].onboardingGuideCompletedTaskName || item;
            });
        const doneTasks = Object.keys(completedTasks).filter(key => filteredMenuItems.includes(key));

        const nextTask = filteredMenuItems.find(item => {
            return !doneTasks.includes(item);
        });

        const totalTasks = filteredMenuItems.length;
        const totalDoneTasks = doneTasks.length;

        return {
            nextTask,
            doneTasks: totalDoneTasks,
            totalTasks,
            donePercentage: (100 * parseFloat(totalDoneTasks)) / totalTasks,
            link: '/react/home',
        };
    }
);
