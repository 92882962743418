import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import React, { useEffect, useState } from 'react';
import css from './Filters.css';
import { Dropdown, TextField } from '../../components/widgets';
import SpinnerIcon from '../../resources/svg/singular_loader.svg';

export const PARTNER_CONFIG_FILTER_DROPDOWN_DATA_TEST_ID = 'partner-config-filter-dropdown';
export const FILTER_INPUT_ID = 'partner-config-filter';
export const FILTER_LOADING_DATA_TEST_ID = 'filter-loading';

export const FilterOptions = {
    PARTNER: 'SOURCE',
    APP: 'APP',
};

export default function Filters({ filter, filterLoading, onFilterChanged }) {
    const selectedFilterOptions = Object.values(FilterOptions).map(field => ({
        name: field,
        display_name: `STATIC.${field}`,
    }));

    const [filterOption, setFilterOption] = useState(selectedFilterOptions[0]);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        if (filter && (filter.text !== filterText || filter.option !== filterOption.name)) {
            setFilterText(filter.text);
            setFilterOption(selectedFilterOptions.find(({ name }) => name === filter.option));
        }
    }, [filter]);

    const onFilterOptionChanged = option => {
        setFilterOption(option);
        onFilterChanged({ option: option.name, text: filterText });
    };

    const onFilterTextChanged = value => {
        setFilterText(value);
        onFilterChanged({ option: filterOption.name, text: value });
    };

    return (
        <div className={css.filtersContainer}>
            <label htmlFor={FILTER_INPUT_ID}>
                <div className={css.filterLabel}>
                    <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.FILTER" />
                </div>
            </label>
            <Dropdown
                id={FILTER_INPUT_ID}
                containerClass={css.filterOption}
                selected={filterOption}
                onSelection={onFilterOptionChanged}
                items={selectedFilterOptions}
                dropDownBodyTestId={PARTNER_CONFIG_FILTER_DROPDOWN_DATA_TEST_ID}
            />
            <TextField
                clearable
                value={filterText}
                inputConfig={{ 'aria-label': FILTER_INPUT_ID }}
                containerClass={css.filterText}
                placeholder="STATIC.PAGES.PARTNER_CONFIGURATION.FILTER_PLACEHOLDER"
                onChange={onFilterTextChanged}
                debounce={500}
            />
            {filterLoading ? (
                <SpinnerIcon data-testid={FILTER_LOADING_DATA_TEST_ID} className={css.filterLoading} />
            ) : null}
        </div>
    );
}

Filters.propTypes = {
    filter: PropTypes.shape({
        text: PropTypes.string,
        option: PropTypes.oneOf(Object.values(FilterOptions)),
    }),
    filterLoading: PropTypes.bool,
    onFilterChanged: PropTypes.func.isRequired,
};

Filters.defaultProps = {
    filter: null,
    filterLoading: false,
};
