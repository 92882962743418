import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { parseFieldsAvailabilityResponse } from '../../../fieldsAvailability/utils';
import { useFetchFieldsAvailability } from '../../../fieldsAvailability/hooks';
import { getVisibleFields } from '../../../fieldsAvailability/selectors';

export const useFieldsAvailabilityData = () => {
    const [fieldsAvailabilityData, setFieldsAvailabilityData] = useState({});
    const fields = useSelector(getVisibleFields);

    const {
        sourcesOptions = [],
        dimensionsOptions = [],
        availabilityBySource = {},
        availabilityByDimension = {},
        sourcesDict = {},
        fieldsDict = {},
        isLoading,
    } = parseFieldsAvailabilityResponse(useFetchFieldsAvailability(), fields);

    useEffect(() => {
        if (!isLoading && isLoading !== undefined) {
            setFieldsAvailabilityData({
                sourcesOptions,
                dimensionsOptions,
                availabilityBySource,
                availabilityByDimension,
                sourcesDict,
                fieldsDict,
            });
        }
    }, [isLoading]);

    return fieldsAvailabilityData;
};
