import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './CellLoader.css';

export const CELL_LOADER_DATA_TEST_ID = 'cell-loader';

const CellLoader = ({ loaderClass }) => {
    return (
        <div className={css.wrapper} data-testid={CELL_LOADER_DATA_TEST_ID}>
            <div className={css.wrapperCell}>
                <div className={css.text}>
                    <div className={classNames(css.textLine, loaderClass)} />
                </div>
            </div>
        </div>
    );
};

CellLoader.propTypes = {
    loaderClass: PropTypes.string,
};

export default CellLoader;
