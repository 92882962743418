import { COARSE_BUCKET_DEFAULT_SIZE, COARSE_VALUES, DECIMAL_PLACES } from '../../../consts';
import css from '../../modelTypeForms/RevenueConversionModelForm.css';
import { getEventsRevenueColumnDef } from '../customTableUtils';
import { getTotalRevenueColumnDef } from './RevenueTable';

const getStaticCoarseValueColumnDef = () => ({
    displayName: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUE',
    className: css.tableHeader,
    headerAlign: 'center',
    name: 'coarseValue',
    headerWidth: '120px',
    cellProps: {
        className: css.tableCellCenter,
    },
});

export const getTableMetadata = (values, setValues, sdkEvents) => {
    return [
        getStaticCoarseValueColumnDef(),
        getEventsRevenueColumnDef(values, setValues, false, sdkEvents, COARSE_BUCKET_DEFAULT_SIZE),
        getTotalRevenueColumnDef(values, setValues, false, true),
    ];
};

export const getTableData = (values, errors) => {
    const { buckets, currency, apsalarEventIds } = values;
    const { buckets: bucketsErrors = {} } = errors;

    const translationArgs = { currency };

    const bucketData = buckets?.map(value => (value ? Number(parseFloat(value).toFixed(DECIMAL_PLACES)) : value)) || [];
    const eventsData = apsalarEventIds || [];

    return Object.values(COARSE_VALUES).map((coarseValue, index) => {
        let from = null;
        let to = null;

        if (!eventsData[index] && bucketData.length) {
            const revenueIndex = index - eventsData.length;
            from = bucketData[revenueIndex];
            const nextBucket = bucketData[revenueIndex + 1];
            to = nextBucket === undefined ? Infinity : nextBucket;
        }

        return {
            index,
            from,
            to,
            isHidden: from === null && to === null,
            translationArgs,
            coarseValue,
            error: bucketsErrors[index]?.errorMsg,
            isEditable: true,
        };
    });
};
