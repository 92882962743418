import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDndScrolling } from 'react-dnd-scrolling';
import css from './ReorderList.css';
import DragDropElement from './DragDropElement';

const ReorderList = ({ values, onDragOver: onDragOverProps }) => {
    const scrolledListRef = useRef();
    useDndScrolling(scrolledListRef, {});

    return (
        <div ref={scrolledListRef} className={css.dragDropContainer}>
            {values.map((value, index) => (
                <DragDropElement index={index} value={value} key={values[index]} onDragOver={onDragOverProps} />
            ))}
        </div>
    );
};

ReorderList.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
    onDragOver: PropTypes.func,
};

ReorderList.defaultProps = {
    values: [],
    onDragOver: () => {},
};

export default ReorderList;
