import API from '../services/api';

let instance = null;
const api = new API();

const SKADNETWORK_CONVERSION_MODEL_GROUP_CONFIGURATION = 'api/skadnetwork/app_models_group_configuration';
const SKADNETWORK_SAVE_GROUP_MODELS = 'api/skadnetwork/save_group_models';
const SKADNETWORK_TOGGLE_GROUP = 'api/skadnetwork/toggle_group';
const SKADNETWORK_MODEL_DECODING_TABLE = 'api/skadnetwork/conversion_model_decoding_table';
const SKADNETWORK_ARCHIVE_GROUP_MODEL = 'api/skadnetwork/archive_group_model';
const SKADNETWORK_PREVIEW_GROUP_MODEL = 'api/skadnetwork/preview_group_model';
const SKADNETWORK_SUMMARY = 'api/skadnetwork/summary';
const SKADNETWORK_ORG_MISSING_EVENT_MAP_PARTNERS = 'api/skadnetwork/organization_missing_event_map_partners';

export default class SKAdnetworkService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    saveGroupModels(appId, modelGroup) {
        return new Promise((resolve, reject) => {
            api.getJson(SKADNETWORK_SAVE_GROUP_MODELS, {
                method: 'POST',
                credentials: 'include',
                body: {
                    app_id: appId,
                    ...modelGroup,
                },
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    toggleGroup(groupId, isEnabled) {
        return new Promise((resolve, reject) => {
            api.getJson(SKADNETWORK_TOGGLE_GROUP, {
                method: 'POST',
                credentials: 'include',
                body: {
                    group_id: groupId,
                    is_enabled: isEnabled,
                },
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    getModelDecodingTable(appId, model) {
        return new Promise((resolve, reject) => {
            api.getJson(SKADNETWORK_MODEL_DECODING_TABLE, {
                method: 'POST',
                credentials: 'include',
                body: { model, app_id: appId },
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    archiveGroupModel(modelId) {
        return api.getJson(SKADNETWORK_ARCHIVE_GROUP_MODEL, {
            method: 'POST',
            credentials: 'include',
            body: {
                group_id: modelId,
            },
        });
    }

    previewGroupModel(modelId) {
        return api.getFile(SKADNETWORK_PREVIEW_GROUP_MODEL, {
            method: 'POST',
            credentials: 'include',
            body: {
                group_id: modelId,
            },
        });
    }

    getModelsGroupConfigurationForApp(appId) {
        return api.getJson(SKADNETWORK_CONVERSION_MODEL_GROUP_CONFIGURATION, {
            unique: true,
            params: { app_id: appId },
            method: 'GET',
            disableBrowserCache: true,
        });
    }

    getAppModelSummary() {
        return api.getJson(SKADNETWORK_SUMMARY, {
            disableBrowserCache: true,
            method: 'GET',
        });
    }

    getMissingOrganizationEventMapPartners() {
        return api.getJson(SKADNETWORK_ORG_MISSING_EVENT_MAP_PARTNERS, {
            method: 'GET',
            unique: true,
            credentials: 'include',
        });
    }
}
