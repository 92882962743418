import { EmptyStateTasks } from '../components/partials/EmptyState/utils';

export const NUMBER_OF_BITS = 6;
export const DEFAULT_MEASUREMENT_PERIOD = 1;
export const SKAN4_TIMERS_MEASUREMENT_PERIOD = null;
export const MAX_MEASUREMENT_PERIOD = 7;
export const EVENT_TYPE_INDEX = 0;

export const DEFAULT_MAX_NUMBER_OF_BUCKETS = 64;
export const DEFAULT_MIN_NUMBER_OF_BUCKETS = 3;
export const DEFAULT_NUMBER_OF_BUCKETS_FOR_MIXED = 8;
export const DEFAULT_BUCKET_SIZE = 1;

export const MAX_SLOTS_ON_SUMMARY = 10;

export const SKAN_3 = 'skan3';
export const SKAN_4 = 'skan4';

export const CREATE_MODE = 'create';
export const EDIT_MODE = 'edit';

export const ModelKinds = {
    REVENUE: 'revenue',
    CONVERSION_EVENTS: 'conversion_events',
    ENGAGEMENT: 'engagement',
    FUNNEL: 'funnel',
    LTV: 'ltv',
    CUSTOM: 'custom',
    MIXED: 'mixed',
    CUSTOM_FUNNEL: 'custom_funnel',
};

export const ModelTypes = {
    REVENUE: 'revenue',
    EVENTS: 'events',
    MIXED: 'mixed',
    CUSTOM: 'custom',
    COARSE_MAPPING: 'coarse_mapping',
    CUSTOM_COARSE: 'custom_coarse',
};

export const MIXED_TYPES = {
    [ModelKinds.CONVERSION_EVENTS]: [ModelKinds.CONVERSION_EVENTS, ModelKinds.REVENUE],
    [ModelKinds.ENGAGEMENT]: [ModelKinds.ENGAGEMENT, ModelKinds.REVENUE],
    [ModelKinds.FUNNEL]: [ModelKinds.FUNNEL, ModelKinds.REVENUE],
};

export const RevenueModelTypes = {
    IAP: 'revenue',
    ADMON: 'admon_revenue',
    ALL: 'combined_revenue',
};

export const EventsModelTypes = {
    CONVERSION_EVENTS: 'conversion_events',
    ENGAGEMENT: 'engagement',
    FUNNEL: 'funnel',
    CUSTOM_FUNNEL: 'custom_funnel',
};

export const FunnelTypes = [ModelKinds.FUNNEL, ModelKinds.CUSTOM_FUNNEL];

export const ModelDisplayNames = {
    [ModelKinds.REVENUE]: 'Revenue',
    [ModelKinds.CONVERSION_EVENTS]: 'Conversion Events',
    [ModelKinds.ENGAGEMENT]: 'Engagement',
    [ModelKinds.FUNNEL]: 'Funnel',
    [ModelKinds.CUSTOM]: 'Custom',
    [ModelKinds.MIXED]: 'Mixed',
    [ModelKinds.CUSTOM_FUNNEL]: 'Custom Funnel',
};

export const RevenueTypeDisplayNames = {
    [RevenueModelTypes.IAP]: 'In App Purchase Revenue',
    [RevenueModelTypes.ADMON]: 'Admon Revenue',
    [RevenueModelTypes.ALL]: 'All Revenue',
};

export const RevenueTypeTranslateNames = {
    [RevenueModelTypes.IAP]: 'IN_APP_PURCHASE_REVENUE',
    [RevenueModelTypes.ADMON]: 'ADMON_REVENUE',
    [RevenueModelTypes.ALL]: 'ALL_REVENUE',
};

export const POSTBACK_CONVERSION_VALUE_TYPE_FINE = 'fine';
export const POSTBACK_CONVERSION_VALUE_TYPE_COARSE = 'coarse';

export const P1_INDEX = 0;
export const P2_INDEX = 1;
export const P3_INDEX = 2;

export const SHELF_STEP_P1 = 'P1';
export const SHELF_STEP_P1_COARSE = 'P1_COARSE';
export const SHELF_STEP_P2 = 'P2';
export const SHELF_STEP_P3 = 'P3';

export const SHELF_STEPS = [SHELF_STEP_P1, SHELF_STEP_P2, SHELF_STEP_P3];
export const SHELF_STEPS_SKAN_3 = [SHELF_STEP_P1];
export const SHELF_P1_COARSE_CONFIGURABLE_STEPS = [SHELF_STEP_P1, SHELF_STEP_P1_COARSE, SHELF_STEP_P2, SHELF_STEP_P3];
export const SHELF_P1_COARSE_CONFIGURABLE_STEPS_MODEL_KINDS = [
    ModelKinds.CONVERSION_EVENTS,
    ModelKinds.MIXED,
    ModelKinds.ENGAGEMENT,
];

export const NO_EVENTS = 'No Events';
export const NO_REVENUE = 'No Revenue';
export const UNUSED = 'UNUSED';

export const COARSE_VALUE_UNUSED = -1;
export const COARSE_VALUE_LOW = 0;
export const COARSE_VALUE_MEDIUM = 1;
export const COARSE_VALUE_HIGH = 2;

export const COARSE_VALUES = {
    [COARSE_VALUE_LOW]: 'Low',
    [COARSE_VALUE_MEDIUM]: 'Medium',
    [COARSE_VALUE_HIGH]: 'High',
};

export const COARSE_VALUES_WITH_UNUSED = {
    [COARSE_VALUE_UNUSED]: UNUSED,
    ...COARSE_VALUES,
};

export const COARSE_VALUES_TRANSLATE = {
    [COARSE_VALUE_LOW]: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUES.LOW',
    [COARSE_VALUE_MEDIUM]: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUES.MEDIUM',
    [COARSE_VALUE_HIGH]: 'STATIC.PAGES.SKADNETWORK.COARSE_VALUES.HIGH',
};

export const COARSE_VALUES_AMOUNT = 3;
export const RETENTION_SLOTS_TEXT = 'D';
export const RETENTION_SLOTS_COLOR = '#4c4fee';
export const MODEL_SLOTS_COLOR = '#92c8f6';

export const MIXED_SLOTS_COLOR = ['#54C7AB', '#FFB300'];
export const MAX_SUB_TYPE_SLOTS_ON_INIT = 3;
export const MIN_ENGAGEMENT_SLOTS = 2;
export const MIN_EVENTS_SLOTS = 1;
export const MIN_REVENUE_BUCKETS = 3;

export const MODEL_TYPE = 'modelType';
export const MODEL_NAME = 'modelGroupName';
export const REVENUE_TYPE = 'revenueType';

export const REQUIRED_FIELD = 'REQUIRED_FIELD';
export const USED_NAME = 'USED_NAME';

export const NON_EMPTY_FIELDS_STEP_1 = [MODEL_TYPE];
export const NON_EMPTY_REVENUE_FIELDS_STEP_1 = [REVENUE_TYPE];
export const NON_EMPTY_FIELDS_STEP_1_ON_SAVE = [MODEL_NAME];
export const MODEL_NAME_MAX_LENGTH = 64;

export const DEFAULT_MEASUREMENT_PERIOD_BY_POSTBACK_INDEX = {
    [P1_INDEX]: 2,
    [P2_INDEX]: 7,
    [P3_INDEX]: 35,
};

export const EVENTS_DISABLED_REASON = {
    CURRENT_EMPTY: 'CURRENT_EMPTY',
    NO_OPTIONS: 'NO_OPTIONS',
    MAX_BITS_REACHED: 'MAX_BITS_REACHED',
    CAPACITY_REACHED: 'CAPACITY_REACHED',
    CAPACITY_REACHED_WITH_MIXED: 'CAPACITY_REACHED_WITH_MIXED',
};

export const FINE_BUCKET_DEFAULT_SIZE = 1;
export const COARSE_BUCKET_DEFAULT_SIZE = 5;
export const DEFAULT_CUSTOM_REVENUE_BUCKETS = [0, 1];
export const DEFAULT_CUSTOM_REVENUE_BUCKETS_MIXED_MODEL = [0, 1, 2];
export const ZERO_CONVERSION_VALUE_FIXED_EVENT = '__INSTALL__';

// Mixpanel consts
export const TRACK_EVENT_PREFIX = 'SKAdNetwork';

export const SLOTS_SIGNS = {
    [ModelKinds.REVENUE]: 'R',
    [ModelKinds.FUNNEL]: 'FL',
    [ModelTypes.EVENTS]: 'E',
};

export const PARTNER_ICON_PATH = '/static/dashboard_react/src/resources/svg/partners/';

export const SKAN_MODELS_EMPTY_STATE_TASKS = [
    {
        topic: EmptyStateTasks.ADD_IOS_APP,
        name: 'STATIC.EMPTY_STATE.TASKS.ADD_IOS_APP_TASK',
        goRedirect: '#/apps?new-app=1',
    },
    {
        topic: EmptyStateTasks.IOS_SDK_INTEGRATED,
        name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK',
        hideGoButton: true,
    },
    {
        topic: EmptyStateTasks.ADD_SKAN_MODELS,
        name: 'STATIC.EMPTY_STATE.TASKS.ADD_SKAN_MODEL',
        hideGoButton: true,
    },
];

export const SKAN_COMPATIBILITY_MODE = {
    SKAN_3_ONLY: 'skan_3_only',
    SKAN_3: 'skan_3',
    SKAN_4: 'skan_4',
};

export const REVENUE_SUB_TYPES = [
    {
        name: RevenueModelTypes.ALL,
        display_name: RevenueTypeDisplayNames[RevenueModelTypes.ALL],
    },
    {
        name: RevenueModelTypes.ADMON,
        display_name: RevenueTypeDisplayNames[RevenueModelTypes.ADMON],
    },
    {
        name: RevenueModelTypes.IAP,
        display_name: RevenueTypeDisplayNames[RevenueModelTypes.IAP],
    },
];

export const MAX_REVENUE_VALUE = 1000000000;
export const DECIMAL_PLACES = 6;
