import TopPanelService from './service';
import { CUSTOMER_TIERS } from '../utils/consts';
import { BACK_PANEL_ORIGIN_PATH, HOMEPAGE_PATH, SHOW_BACK_PANEL } from './consts';

export const STATUS_PANEL_CLOSED_DATE = 'statusPanelClosedDate';
const TopPanelAPI = new TopPanelService();

export const setPanelShownClasses = (panelContainerClass, panelShownClass, isShown) => {
    const panelContainer = document.getElementById(panelContainerClass);
    const angularPageContent = document.getElementById('page-container');
    const reactContainer = document.getElementById('reactContainer');

    reactContainer && reactContainer.classList.toggle(panelShownClass, isShown);
    angularPageContent && angularPageContent.classList.toggle(panelShownClass, isShown);
    panelContainer && panelContainer.classList.toggle(panelShownClass, isShown);
};

export const trackTrialStatusCTAClick = (realDaysLeft, origin) => {
    window.mixpanel.track('sdr_cta', { expiration_days: realDaysLeft.toString(), origin });
};

export const trackTrialStatusCloseClick = realDaysLeft => {
    window.mixpanel.track('sdr_panel_closed', { expiration_days: realDaysLeft.toString() });
};

export const trackTrialCTAClick = (email, firstName, lastName, company) => {
    TopPanelAPI.updateUpgradeRequested(email, firstName, lastName, company, CUSTOMER_TIERS.STANDARD_UNIFIED);
};

export const trackFreeTierCTAClick = (email, firstName, lastName, company) => {
    window.mixpanel.track('sdr_cta', { tier: CUSTOMER_TIERS.FREE });
    TopPanelAPI.updateUpgradeRequested(email, firstName, lastName, company, CUSTOMER_TIERS.FREE);
};

export const setShowBackPanel = (originPath = HOMEPAGE_PATH) => {
    sessionStorage.setItem(SHOW_BACK_PANEL, 'true');
    sessionStorage.setItem(BACK_PANEL_ORIGIN_PATH, originPath);
};

export const getShowBackPanel = () => {
    return {
        showBackPanel: sessionStorage.getItem(SHOW_BACK_PANEL),
        backPanelOriginPath: sessionStorage.getItem(BACK_PANEL_ORIGIN_PATH),
    };
};

export const removeShowBackPanel = () => {
    sessionStorage.removeItem(SHOW_BACK_PANEL);
    sessionStorage.removeItem(BACK_PANEL_ORIGIN_PATH);
};
