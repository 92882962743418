import range from 'lodash/range';
import isNumber from 'lodash/isNumber';
import flatten from 'lodash/flatten';
import { ADNETWORK_IDS, EVENT_SLOT_GROUP_TYPE } from './consts';
import { isNewEvent } from '../customEvents/utils';

const MAX_EVENTS_DEFAULT = 12;
export const ADMON_USER_LEVEL_REVENUE_EVENT_NAME = '__ADMON_USER_LEVEL_REVENUE__';

const levenshtein = require('fast-levenshtein');

const simplifyName = name => {
    return name.replace(/[.,\/#!$%\^&\*;:{}=\-_`~() ]/g, '').toLowerCase();
};

const getSimilarInAppEvents = (event, inAppEvents, preSelectedEvents) => {
    const name = event.display_name;
    const simpleName = simplifyName(name);
    const preSelectedIds = preSelectedEvents.map(e => e.id);

    // remove pre-selected in-app events
    const useInAppEvents = inAppEvents.filter(ev => {
        return !preSelectedIds.includes(ev.id);
    });

    // matches groups are ordered from most-likely (exact match) to least-likely (Levenshtein Distance)
    let matches = [[], [], [], [], []];
    useInAppEvents.forEach(inAppEvent => {
        const inAppName = inAppEvent.display_name;
        const simpleInAppName = simplifyName(inAppName);
        let matchIndex;

        if (name === inAppName) {
            // exact match
            matchIndex = 0;
        } else if (name.toLowerCase() === inAppName.toLowerCase()) {
            // case insensitive
            matchIndex = 1;
        } else if (simpleInAppName === simpleName) {
            // exact match (after stripping punctuation and case)
            matchIndex = 2;
        } else if (simpleInAppName.indexOf(simpleName) > -1 || simpleName.indexOf(simpleInAppName) > -1) {
            // one is a substring of the other
            matchIndex = 3;
        } else if (levenshtein.get(simpleInAppName, simpleName) < 2) {
            // low Levenshtein Distance (https://en.wikipedia.org/wiki/Levenshtein_distance)
            matchIndex = 4;
        }

        if (isNumber(matchIndex)) {
            matches[matchIndex].push(inAppEvent);
        }
    });

    matches = flatten(matches);
    return matches;
};

const getTotalUanEvents = (event, mappedKey = 'id') => {
    const totalUanEvents = [];
    Object.keys(event.events_by_uan).forEach(uanId => {
        totalUanEvents.push(...event.events_by_uan[uanId].map(uanEvent => uanEvent[mappedKey]));
    });
    return totalUanEvents;
};

const getCustomEventWarnings = (currentEvent, backupEvent) => {
    const warnings = [];
    if (currentEvent.expanded && Object.keys(backupEvent).length) {
        const totalUanEvents = getTotalUanEvents(currentEvent);
        const totalUanEventsBackup = getTotalUanEvents(backupEvent);
        if (isNewEvent(currentEvent) && totalUanEvents.length > totalUanEventsBackup.length) {
            warnings.push({ message: 'STATIC.PAGES.CUSTOM_EVENTS.EVENT_DATA_NOTE', type: 'info' });
        }
    }
    return warnings;
};

const filterByUniqueToggle = (eventsList, isUnique) => {
    return eventsList.filter(event => (isUnique && event.is_unique) || (!isUnique && !event.is_unique));
};

const sitesForEventToOptionGroups = sitesForEventMap => {
    return Object.keys(sitesForEventMap)
        .reduce((total, siteFullName) => {
            if (sitesForEventMap[siteFullName].length) {
                total.push({ label: siteFullName, options: sitesForEventMap[siteFullName] });
            }
            return total;
        }, [])
        .sort((a, b) => {
            if (a.label === 'All') {
                return -1;
            }
            if (b.label === 'All') {
                return 1;
            }
            return 0;
        });
};

const filterBySelectedApp = (eventsList, selectedApp) => {
    const allowedUanEventIds = flatten(
        selectedApp.sites.map(site => ({ ids: site.uan_events, fullName: site.fullName }))
    );
    const sitesForEventMap = selectedApp.sites.reduce(
        (total, site) => {
            total[site.fullName] = [];
            return total;
        },
        { All: [] }
    );
    eventsList.forEach(event => {
        const allowedSites = allowedUanEventIds.filter(allowedEvent => allowedEvent.ids.includes(event.id));
        if (!allowedSites.length) {
            return;
        }
        if (allowedSites.length === selectedApp.sites.length) {
            sitesForEventMap.All.push(event);
        } else {
            allowedSites.forEach(site => {
                sitesForEventMap[site.fullName].push(event);
            });
        }
    });
    return sitesForEventToOptionGroups(sitesForEventMap);
};

export const filterByAllApps = (eventsList, appsList) => {
    const sitesForEventMap = {};
    const eventForSitesCount = { All: [] };
    const eventIdToObject = {};

    eventsList.forEach(event => {
        eventIdToObject[event.id] = event;
    });

    appsList.forEach(app => {
        app.sites.forEach(({ fullName, uan_events }) => {
            uan_events.forEach(eventId => {
                const event = eventIdToObject[eventId];

                if (!event) {
                    return;
                }

                if (!sitesForEventMap[fullName]) {
                    sitesForEventMap[fullName] = [];
                }

                if (!eventForSitesCount[eventId]) {
                    eventForSitesCount[eventId] = 0;
                }

                sitesForEventMap[fullName].push(event);
                eventForSitesCount[eventId] += 1;
            });
        });
    });

    const siteKeys = Object.keys(sitesForEventMap);
    const totalSites = siteKeys.length;

    // in case no totalSites - we can't group uan events per site, return the list non-grouped
    if (!totalSites) {
        return eventsList;
    }

    // uan events that appear in all sites are grouped under All and removed from site specific
    siteKeys.forEach(siteKey => {
        sitesForEventMap[siteKey] = sitesForEventMap[siteKey].filter(
            event => eventForSitesCount[event.id] !== totalSites
        );
    });

    sitesForEventMap.All = eventsList.filter(event => eventForSitesCount[event.id] === totalSites);

    return sitesForEventToOptionGroups(sitesForEventMap);
};

const isMatchByGroupType = (uan, groupType) => {
    if (groupType === EVENT_SLOT_GROUP_TYPE.COHORT) {
        return (uan.is_tracker && uan.source_id !== ADNETWORK_IDS.GOOGLE_ANALYTICS) || uan.force_enable_cohorts;
    } else if (groupType === EVENT_SLOT_GROUP_TYPE.CONVERSION) {
        return !uan.is_tracker || uan.source_id === ADNETWORK_IDS.GOOGLE_ANALYTICS;
    } else if (groupType === EVENT_SLOT_GROUP_TYPE.SKAN) {
        return [ADNETWORK_IDS.SINGULAR, ADNETWORK_IDS.APPSFLYER].includes(uan.source_id);
    } else {
        return true;
    }
};

const getUansExtendedList = (
    uanEvents,
    suggestionsPerUanId,
    customEvent,
    selectedApp = null,
    appsList = [],
    activeGroupType
) => {
    const uansList = Object.keys(uanEvents)
        .filter(uanId => {
            return isMatchByGroupType(uanEvents[uanId], activeGroupType);
        })
        .map(uanId => {
            let suggestions = [];
            if (
                suggestionsPerUanId &&
                suggestionsPerUanId[customEvent.id] &&
                suggestionsPerUanId[customEvent.id][uanId]
            ) {
                suggestions = flatten(
                    suggestionsPerUanId[customEvent.id][uanId].map(suggestionItem => {
                        return suggestionItem.suggested.map(s => {
                            return {
                                ...s,
                                initator: suggestionItem.uanEventId,
                            };
                        });
                    })
                );
            }
            let { events } = uanEvents[uanId];
            if (!('is_mixed' in customEvent) || !customEvent.is_mixed) {
                events = filterByUniqueToggle(events, customEvent.is_unique);
            }
            if (selectedApp) {
                events = filterBySelectedApp(events, selectedApp);
            } else {
                events = filterByAllApps(events, appsList);
            }
            return {
                ...uanEvents[uanId],
                events,
                suggestions: filterByUniqueToggle(suggestions, customEvent.is_unique),
            };
        });

    uansList.sort((a, b) => {
        if (a.source_name === 'Singular' && b.source_name === 'Singular') {
            return 0;
        }
        if (a.source_name === 'Singular') {
            return -1;
        }
        if (b.source_name === 'Singular') {
            return 1;
        }
        return -1;
    });

    return uansList;
};

const getEventsPerAppMap = (events = [], appsList = []) => {
    const siteForAppMap = appsList.reduce((total, app) => {
        app.sites.forEach(site => {
            total[site.site_public_id] = app.app_id;
        });
        return total;
    }, {});
    return events.reduce((total, customEvent) => {
        if (isNewEvent(customEvent)) {
            return total;
        }
        if (!customEvent.apps.length) {
            if (!total.global) {
                total.global = new Set();
            }
            total.global.add(customEvent);
            return total;
        }
        customEvent.apps.forEach(site => {
            const { public_id } = site;
            if (siteForAppMap[public_id]) {
                if (!total[siteForAppMap[public_id]]) {
                    total[siteForAppMap[public_id]] = new Set();
                }
                total[siteForAppMap[public_id]].add(customEvent);
            }
        });
        return total;
    }, {});
};

const getEventsCapacity = (maxEventSlots, eventSlots, appsList, allAppsChecked, selectedAppId = undefined) => {
    if (!eventSlots.length || (!allAppsChecked && !selectedAppId)) {
        return 0;
    }

    let allowedSiteIds = [];
    if (selectedAppId) {
        const selectedApp = appsList.find(app => app.app_id === selectedAppId);
        allowedSiteIds = selectedApp.sites.map(site => site.site_public_id);
    }
    const sitePublicIds = new Set(allowedSiteIds);
    const isNewSlotCrossApp = !selectedAppId;

    const allSlots = new Set(range(1, maxEventSlots + 1));

    const usedSlots = new Set();
    eventSlots.forEach(eventSlot => {
        const slotPublicIds = eventSlot.apps.map(app => app.public_id);
        const isCurrentSlotCrossApp = !slotPublicIds.length;

        if (isNewSlotCrossApp || isCurrentSlotCrossApp || slotPublicIds.some(id => sitePublicIds.has(id))) {
            usedSlots.add(eventSlot.slot);
        }
    });

    const availableSlots = new Set([...allSlots].filter(slot => !usedSlots.has(slot)));
    return maxEventSlots - availableSlots.size;
};

const getEventsWarningMessage = (allAppsChecked, customEventsCapacity, maxEvents = MAX_EVENTS_DEFAULT) => {
    const customEventsPrecent = customEventsCapacity / maxEvents;
    const CUSTOM_EVENTS_PAGE_LOCALE = 'STATIC.PAGES.CUSTOM_EVENTS.';
    const maxEventsReached = customEventsPrecent >= 1;
    const maxEventsNear = customEventsPrecent >= 0.8 && customEventsPrecent < 1;
    if (maxEventsReached && allAppsChecked) {
        return `${CUSTOM_EVENTS_PAGE_LOCALE}REACHED_MAXIMUM_GLOBAL_WARNING`;
    }
    if (maxEventsReached) {
        return `${CUSTOM_EVENTS_PAGE_LOCALE}REACHED_MAXIMUM_WARNING`;
    }
    if (maxEventsNear && allAppsChecked) {
        return `${CUSTOM_EVENTS_PAGE_LOCALE}NEAR_MAXIMUM_GLOBAL_WARNING`;
    }
    return '';
};

const formatAppsListForSuggestions = (appsListForSuggestions, events, appsList) => {
    const eventsPerApp = getEventsPerAppMap(events, appsList);
    const appsWithCount = appsListForSuggestions.map(app => ({
        ...app,
        count: eventsPerApp[app.value]?.size ?? 0,
    }));

    const sortedAppsWithCount = appsWithCount.sort((app1, app2) => {
        if (app1.count < app2.count) return 1;
        if (app1.count > app2.count) return -1;

        return app1.label.localeCompare(app2.label);
    });
    return sortedAppsWithCount.map(({ value, label, count }) => ({ value, label: `${label} (${count} events)` }));
};

export {
    getSimilarInAppEvents,
    getTotalUanEvents,
    getCustomEventWarnings,
    getUansExtendedList,
    getEventsPerAppMap,
    getEventsCapacity,
    getEventsWarningMessage,
    MAX_EVENTS_DEFAULT,
    isMatchByGroupType,
    formatAppsListForSuggestions,
};
