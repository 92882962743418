import React, { memo } from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './RadioButtonsGroup.css';
import QuestionMark from './QuestionMark';

const RadioButtonsGroup = memo(function RadioButtonsGroup({
    radioItems,
    className,
    disabled,
    horizontal,
    itemsClassName,
    descriptionClassName,
    checkmarkClassName,
    onChange,
}) {
    const innerOnChange = item => {
        if (disabled || item.checked) {
            return;
        }

        onChange(item);
    };

    return (
        <div className={classNames(className, css.container, { [css.horizontal]: horizontal })}>
            {radioItems.map(item => {
                const itemId = item.id;

                return (
                    <label
                        className={classNames(css.radioLabel, itemsClassName, { [css.disabled]: disabled })}
                        htmlFor={`radioButton_${itemId}`}
                        key={`radioButton_${itemId}`}
                        onClick={() => {
                            innerOnChange(item);
                        }}
                    >
                        <Translate id={item.label} data={item.data} />
                        {item.tag && <span className={css.tag}>{item.tag}</span>}
                        <input
                            type="radio"
                            name={`radioButton_${itemId}`}
                            checked={!!item.checked}
                            onChange={() => {
                                innerOnChange(item);
                            }}
                        />
                        <span className={classNames(css.checkmark, checkmarkClassName)} />
                        {!!item.description && (
                            <div className={classNames(css.description, descriptionClassName)}>
                                <Translate id={item.description} />
                            </div>
                        )}
                        {!!item.tooltip && <QuestionMark tooltipClassName={css.tooltip} message={item.tooltip} />}
                    </label>
                );
            })}
        </div>
    );
});

RadioButtonsGroup.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    radioItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
            checked: PropTypes.bool,
            data: PropTypes.objectOf(PropTypes.string),
            tag: PropTypes.string,
            description: PropTypes.string,
            tooltip: PropTypes.string,
        })
    ),
    horizontal: PropTypes.bool,
    itemsClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    checkmarkClassName: PropTypes.string,
};

RadioButtonsGroup.defaultProps = {
    className: '',
    disabled: false,
    onChange: () => {},
    radioItems: [],
    horizontal: false,
    itemsClassName: '',
    descriptionClassName: '',
    checkmarkClassName: '',
};

export default RadioButtonsGroup;
