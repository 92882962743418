import { call, put, take, select } from 'redux-saga/effects';
import AudienceSegmentsAPI from './service';
import { getSegmentValid, getMetaData, getSharingSegmentId } from './selectors';
import {
    load,
    unload,
    editStartDone,
    editSaveStart,
    editSaveDone,
    sharingLinksLoaded,
    TOGGLE_STATUS,
    DELETE_CONFIRM,
    EDIT_START,
    EDIT_SAVE,
    DOWNLOAD,
    OPEN_SHARING_OPTIONS,
    COPY_SHARING_LINK,
    CREATE_SHARING_LINK,
    REVOKE_SHARING_LINK,
    RENAME_SHARING_LINK,
} from './actions';
import { SEGMENT_AUTO_PAUSE_MAP } from './consts';

const api = new AudienceSegmentsAPI();

function* onload() {
    const response = yield call(api.getSegments);
    yield put(load(response.segments, response.templates, response.active_segments_limit));
}

function* destroy() {
    yield put(unload());
}

function* toggleSegment() {
    while (true) {
        const action = yield take(TOGGLE_STATUS);
        yield call(api.toggleActive, action.id);
    }
}

function* deleteSegment() {
    while (true) {
        const action = yield take(DELETE_CONFIRM);
        yield call(api.delete, action.id);
    }
}

function* editSegment() {
    while (true) {
        const action = yield take(EDIT_START);
        const metaData = yield select(getMetaData);
        try {
            const response = yield call(api.editSegment, action.id, action.template, !metaData, action.admin);
            response.segment.auto_pause_enabled = Object.keys(SEGMENT_AUTO_PAUSE_MAP).find(
                key => SEGMENT_AUTO_PAUSE_MAP[key] === response.segment.auto_pause_enabled
            );

            yield put(editStartDone(response.segment, response.metadata, action.duplicate, action.admin));
        } catch (e) {
            // Handle Error
        }
    }
}

function* downloadSegment() {
    while (true) {
        const action = yield take(DOWNLOAD);
        const response = yield call(api.download, action.id);
        const link = document.createElement('a');
        link.download = response.name;
        link.href = response.url;
        link.style = 'display: none;';
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
        }, 1);
    }
}

function* editSave() {
    while (true) {
        const action = yield take(EDIT_SAVE);
        const valid = yield select(getSegmentValid);
        if (valid) {
            yield put(editSaveStart());
            try {
                action.segment.filters = action.segment.filters.map(filter => {
                    const filterCopy = { ...filter };
                    if (filterCopy.new) {
                        // remove generate uid in case its a new filter
                        delete filterCopy.new;
                        delete filterCopy.id;
                    }
                    return filterCopy;
                });
                action.segment.auto_pause_enabled = SEGMENT_AUTO_PAUSE_MAP[action.segment.auto_pause_enabled];

                const response = yield call(api.save, action.id, action.segment);
                yield put(editSaveDone(response.id, response.status));
            } catch (e) {
                // handle error
            }
        }
    }
}

function* openSharingOptions() {
    while (true) {
        const action = yield take(OPEN_SHARING_OPTIONS);
        try {
            const response = yield call(api.getSharingOptions, action.id);
            yield put(sharingLinksLoaded(response.links));
        } catch (e) {
            // Handle Error
        }
    }
}

function* copySharingLink() {
    while (true) {
        const { linkId } = yield take(COPY_SHARING_LINK);
        const segmentId = yield select(getSharingSegmentId);
        const linkUrl = api.getSharingLinkUrl(segmentId, linkId);

        copyToClipboard(linkUrl);
    }
}

function copyToClipboard(str) {
    const el = document.createElement('textarea'); // Create a <textarea> element
    const parent = document.activeElement; // So it'll work inside the shelf!
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    parent.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    parent.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }
}

function* createSharingLink() {
    while (true) {
        yield take(CREATE_SHARING_LINK);
        const segmentId = yield select(getSharingSegmentId);

        try {
            const response = yield call(api.createSharingLink, segmentId);
            yield put(sharingLinksLoaded(response.links));
        } catch (e) {
            // Handle Error
        }
    }
}

function* revokeSharingLink() {
    while (true) {
        const { linkId } = yield take(REVOKE_SHARING_LINK);
        const segmentId = yield select(getSharingSegmentId);

        try {
            const response = yield call(api.updateSharingLink, segmentId, linkId, { is_revoked: true });
            yield put(sharingLinksLoaded(response.links));
        } catch (e) {
            // Handle Error
        }
    }
}

function* renameSharingLink() {
    while (true) {
        const { linkId, displayName } = yield take(RENAME_SHARING_LINK);
        const segmentId = yield select(getSharingSegmentId);

        try {
            const response = yield call(api.updateSharingLink, segmentId, linkId, { display_name: displayName });
            yield put(sharingLinksLoaded(response.links));
        } catch (e) {
            // Handle Error
        }
    }
}

const watchers = [
    toggleSegment,
    deleteSegment,
    editSegment,
    downloadSegment,
    editSave,
    openSharingOptions,
    copySharingLink,
    createSharingLink,
    revokeSharingLink,
    renameSharingLink,
];

export { onload, destroy, watchers };
