import update from 'immutability-helper';
import { CUSTOMER_TIERS } from 'utils/consts';
import * as reportActions from '../actions/reports';
import { IS_CREATIVE_GALLERY_REPORT_RUNNING } from '../actions/reports';
import { RUN_ANONYMOUS_PAGE } from '../actions/anonymous';
import reportsConfig from './reportsConfig';
import {
    DEFAULT_APP_FILTER,
    DEFAULT_SOURCE_FILTER,
    defaultFilters,
    defaultLastFilter,
    isDrilldownEnabled,
    reportTypes,
} from '../utils/reports';
import { UPDATE_USER_DATA } from '../actions/user';
import { REGULAR_MODE, TOTALS_STATUS } from '../components/pages/reports/consts';

const initialState = {
    selectedFields: {},
    selectedGoal: null,
    filters: defaultFilters(),
    shelf: [],
    header: {},
    showFieldsSelection: false,
    showSpinner: true,
    showHeader: false,
    selectedTab: 'regular',
    mode: REGULAR_MODE,
    dates: {},
    chartData: null,
    reportData: null,
    anonymousId: '',
    anonymousList: [],
    bookmark: {
        bookmark_id: '',
        bookmark_creator: '',
        error: '',
        updatedInstanceId: '',
    },
    transparencyData: null,
    queryRunning: false,
    validationError: '',
    unenriched: false,
    admonAlignment: false,
    shouldDrilldown: false,
    showTransparencyPopover: '',
    dimensionDrilldownCellId: '',
    postProcessFiltersUI: {},
    isSlimMode: false,
    isClearSelectionOn: false,
    disableReportState: null,
    openFieldsAvailability: false,
    fieldsAvailabilityFieldName: null,
    selectedSources: [],
    showPopupDialog: false,
    popupDialogParams: {},
    reportFormHasChanged: true,
    isEditShelfOpen: false,
    isFiltersExpanded: true,
    reportWarning: {},
};

const checkActionForType = (action, type) => {
    return action.replace(/{reportType}/g, type);
};

const getConfigForType = reportType => {
    return reportsConfig.find(config => config.type === reportType);
};

// reasoning behind createReducerForType -> allows us to enrich the reducer's closure with the report type
const createReducerForType = reportType => {
    const configForType = getConfigForType(reportType);
    if (reportType === reportTypes.adMonetization) {
        initialState.filters = initialState.filters.filter(cFilter => cFilter.field !== DEFAULT_SOURCE_FILTER.field);
    }
    const isStandardAnalytics = window.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS;
    if (isStandardAnalytics) {
        initialState.filters = initialState.filters.filter(cFilter => cFilter.field !== DEFAULT_APP_FILTER.field);
    }

    // update the state, based on the relevant
    const initState = update(initialState, {
        shelf: {
            $set: configForType.shelf,
        },
        header: {
            $set: configForType.header,
        },
        fieldWarnings: {
            $set: configForType.fieldWarnings,
        },
        showDownloadReportDatesThreshold: {
            $set: configForType.showDownloadReportDatesThreshold,
        },
        fieldShowDownloadReport: {
            $set: configForType.fieldShowDownloadReport,
        },
        chart: {
            $set: {
                showError: false,
                ...configForType.chart,
            },
        },
        table: {
            $set: configForType.table,
        },
        pivotTable: {
            $set: configForType.pivotTable || initialState.pivotTable,
        },
    });
    return (inState = initState, action) => {
        const state = inState;
        switch (action.type) {
            case checkActionForType(reportActions.FIELD_SET, reportType): {
                return update(state, {
                    [action.field]: {
                        $set: action.value,
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.FIELD_CLICKED, reportType): {
                const { name, actual_type: type } = action.field;

                if (!state.selectedFields[type]) {
                    state.selectedFields[type] = [];
                }

                const selectedArr = state.selectedFields[type];

                if (selectedArr.includes(name)) {
                    if (action.isSingle) {
                        return state;
                    }

                    return update(state, {
                        selectedFields: {
                            [type]: {
                                $splice: [[selectedArr.indexOf(name), 1]],
                            },
                        },
                        reportFormHasChanged: {
                            $set: true,
                        },
                    });
                } else if (action.isSingle) {
                    return update(state, {
                        selectedFields: {
                            [type]: {
                                $set: [name],
                            },
                        },
                        reportFormHasChanged: {
                            $set: true,
                        },
                    });
                } else {
                    return update(state, {
                        selectedFields: {
                            [type]: {
                                $push: [name],
                            },
                        },
                        reportFormHasChanged: {
                            $set: true,
                        },
                    });
                }
            }
            case checkActionForType(reportActions.FIELD_CLICKED_BULK, reportType): {
                return update(state, {
                    selectedFields: {
                        $set: {
                            ...state.selectedFields,
                            ...action.values,
                        },
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.FIELD_CLICKED_COMPLETE, reportType): {
                return update(state, {
                    selectedFields: { $set: action.data },
                    reportFormHasChanged: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.FILTER_FIELD_SELECTED, reportType): {
                const updateObj = {
                    filters: {
                        [action.index]: {
                            field: {
                                $set: action.field.name,
                            },
                            tags: {
                                $set: [],
                            },
                        },
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                };

                if (state.filters[action.index].field === 'source') {
                    updateObj.selectedSources = { $set: [] };
                }

                return update(state, updateObj);
            }
            case checkActionForType(reportActions.CHART_METRIC_SELECTED, reportType): {
                return update(state, {
                    chart: {
                        selectedMetrics: {
                            $set: action.chartParams.metrics.filter(f => f.visible).map(v => v.name),
                        },
                    },
                });
            }
            case checkActionForType(reportActions.FILTER_OPERATOR_SELECTED, reportType): {
                return update(state, {
                    filters: {
                        [action.index]: {
                            operator: {
                                $set: action.field.name,
                            },
                        },
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.FILTER_VALUE_ADDED, reportType): {
                const updateObj = {
                    filters: {
                        [action.index]: {
                            tags: {
                                $push: [action.value],
                            },
                        },
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                };

                if (state.filters[action.index].field === 'source') {
                    updateObj.selectedSources = { $push: [action.value] };
                }

                return update(state, updateObj);
            }
            case checkActionForType(reportActions.FILTER_VALUE_REMOVED, reportType): {
                const updateObj = {
                    filters: {
                        [action.index]: {
                            tags: {
                                $splice: [[state.filters[action.index].tags.indexOf(action.value), 1]],
                            },
                        },
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                };

                if (state.filters[action.index].field === 'source') {
                    updateObj.selectedSources = {
                        $splice: [[state.selectedSources.indexOf(action.value), 1]],
                    };
                }

                return update(state, updateObj);
            }
            case checkActionForType(reportActions.FILTER_ADD, reportType): {
                return update(state, {
                    filters: {
                        $push: [{ ...defaultLastFilter }],
                    },
                });
            }
            case checkActionForType(reportActions.FILTER_REMOVE, reportType): {
                let updateObj;

                if (state.filters.length === 1) {
                    updateObj = {
                        filters: {
                            $splice: [[action.index, 1, { ...defaultLastFilter }]],
                        },
                    };
                } else {
                    updateObj = {
                        filters: {
                            $splice: [[action.index, 1]],
                        },
                    };
                }

                if (state.filters[action.index].field === 'source') {
                    updateObj.selectedSources = {
                        $set: [],
                    };
                }

                return update(state, updateObj);
            }
            case checkActionForType(reportActions.TOGGLE_FIELDS_SELECTION, reportType): {
                const value = action.value !== undefined ? action.value : !state.showFieldsSelection;
                return update(state, {
                    showFieldsSelection: {
                        $set: value,
                    },
                });
            }
            case checkActionForType(reportActions.TOGGLE_SLIM_MODE, reportType): {
                return update(state, {
                    isSlimMode: {
                        $set: action.isSlimMode,
                    },
                });
            }
            case checkActionForType(reportActions.TOGGLE_FIELDS_AVAILABILITY_SHELF, reportType): {
                return update(state, {
                    openFieldsAvailability: {
                        $set: !!action.isOpen,
                    },
                    fieldsAvailabilityFieldName: {
                        $set: action.field,
                    },
                });
            }
            case reportActions.POPUP_DIALOG_CHANGED: {
                return update(state, {
                    showPopupDialog: {
                        $set: action.isOpen,
                    },
                    popupDialogParams: {
                        $set: action.popupDialogParams,
                    },
                });
            }
            case checkActionForType(reportActions.CLEAR_FIELD_SELECTION, reportType): {
                const selectedFields = {
                    timeBreakdowns: ['all'],
                };

                if (reportType === reportTypes.skan || reportType === reportTypes.skanSummary) {
                    selectedFields.skanDateDimensionName = ['skan_postback_date'];
                    selectedFields.skan_redownloads_dimensions = ['show_both'];
                    selectedFields.skan_validated_dimensions = ['true'];
                    selectedFields.confidenceIntervalFlag = ['true'];
                }

                return update(state, {
                    isClearSelectionOn: {
                        $set: true,
                    },
                    selectedFields: {
                        $set: selectedFields,
                    },
                    filters: {
                        $set: defaultFilters(reportType),
                    },
                    selectedSources: {
                        $set: [],
                    },
                    unenriched: {
                        $set: false,
                    },
                    admonAlignment: {
                        $set: false,
                    },
                    reportFormHasChanged: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.IS_SHELF_OPEN, reportType): {
                return update(state, {
                    isEditShelfOpen: {
                        $set: action.isToOpenShelf,
                    },
                });
            }
            case checkActionForType(reportActions.CLEAR_FIELD_SELECTION_OFF, reportType): {
                return update(state, {
                    isClearSelectionOn: {
                        $set: false,
                    },
                });
            }
            case checkActionForType(reportActions.TAB_SELECTED, reportType): {
                return update(state, {
                    showFieldsSelection: {
                        $set: true,
                    },
                    selectedTab: {
                        $set: action.id,
                    },
                });
            }
            case checkActionForType(reportActions.MODE_SELECTED, reportType): {
                return update(state, {
                    mode: {
                        $set: action.id,
                    },
                    selectedFields: {
                        metrics: {
                            $set: [],
                        },
                        cohortMetrics: {
                            $set: [],
                        },
                    },
                });
            }
            case checkActionForType(reportActions.FILTERS_EXPANDED, reportType): {
                const { isExpanded } = action;
                return update(state, {
                    isFiltersExpanded: {
                        $set: isExpanded,
                    },
                });
            }
            case checkActionForType(reportActions.DATE_CHANGED, reportType): {
                const { data } = action;
                return update(state, {
                    dates: {
                        $merge: data,
                    },
                    reportFormHasChanged: {
                        $set: action.value,
                    },
                });
            }
            case checkActionForType(reportActions.REPORT_FORM_CHANGED, reportType): {
                return update(state, {
                    reportFormHasChanged: {
                        $set: action.value,
                    },
                });
            }
            case checkActionForType(reportActions.DISABLE_REPORT_STATE_CHANGE, reportType): {
                const { disableReportState } = action;
                return update(state, {
                    disableReportState: {
                        $set: disableReportState,
                    },
                });
            }
            case checkActionForType(reportActions.CHART_LINES_CHANGED, reportType): {
                return update(state, {
                    chartData: {
                        $set: null,
                    },
                });
            }
            case checkActionForType(RUN_ANONYMOUS_PAGE, reportType):
            case checkActionForType(reportActions.RUN_QUERY, reportType): {
                const changes = {
                    showSpinner: { $set: true },
                    chartData: { $set: null },
                    reportData: { $set: null },
                    transparencyData: { $set: null },
                    queryRunning: { $set: true },
                    chart: {
                        selectedMetrics: { $set: [] },
                        slowLoading: { $set: false },
                    },
                    bookmark: {
                        bookmark_id: { $set: state.bookmark.bookmark_id },
                        bookmark_creator: { $set: state.bookmark.bookmark_creator },
                    },
                    table: {
                        filteredTotal: { $set: { status: null } },
                    },
                };

                if (!action.fromUrl) {
                    changes.pivotTable = { $set: {} };
                }

                const newState = update(state, changes);
                return newState;
            }
            case checkActionForType(reportActions.CLEAR_REPORT_DATA, reportType): {
                return update(state, {
                    chartData: { $set: null },
                    reportData: { $set: null },
                    transparencyData: { $set: null },
                    chart: {
                        selectedMetrics: { $set: [] },
                    },
                    bookmark: {
                        // stay in the bookmark context when running a report
                        bookmark_id: { $set: state.queryRunning ? state.bookmark.bookmark_id : '' },
                        bookmark_creator: { $set: state.queryRunning ? state.bookmark.bookmark_creator : '' },
                    },
                    pivotTable: {},
                });
            }
            case checkActionForType(reportActions.CLEAR_REPORT_FILTERS, reportType): {
                return update(state, {
                    selectedSources: { $set: [] },
                    filters: { $set: defaultFilters(reportType) },
                });
            }
            case checkActionForType(reportActions.RUN_QUERY_FINISHED, reportType): {
                return update(state, {
                    queryRunning: {
                        $set: false,
                    },
                    showSpinner: {
                        $set: false,
                    },
                });
            }
            case checkActionForType(reportActions.TRANSPARENCY_DATA_RESPONSE, reportType): {
                return update(state, {
                    transparencyData: {
                        $set: action.transparencyData,
                    },
                });
            }
            case checkActionForType(reportActions.SHOW_TRANSPARENCY_POPOVER, reportType): {
                return update(state, {
                    showTransparencyPopover: {
                        $set: action.value,
                    },
                });
            }
            case checkActionForType(reportActions.SHOW_DRILLDOWN_POPOVER, reportType): {
                return update(state, {
                    dimensionDrilldownCellId: {
                        $set:
                            state.dimensionDrilldownCellId === action.dimensionDrilldownCellId
                                ? ''
                                : action.dimensionDrilldownCellId,
                    },
                });
            }
            case checkActionForType(reportActions.QUERY_RESPONSE, reportType): {
                return update(state, {
                    reportData: {
                        $set: action.response,
                    },
                    showSpinner: {
                        $set: false,
                    },
                    showHeader: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.CHART_RESPONSE, reportType): {
                return update(state, {
                    chartData: {
                        $set: action.response,
                    },
                });
            }
            case checkActionForType(reportActions.TOTALS_RESPONSE, reportType): {
                return update(state, {
                    table: {
                        filteredTotal: {
                            data: {
                                $set: action.response,
                            },
                            status: {
                                $set: TOTALS_STATUS.DONE,
                            },
                        },
                    },
                });
            }
            case checkActionForType(reportActions.TOTALS_CLEANED, reportType): {
                return update(state, {
                    table: {
                        filteredTotal: {
                            data: {
                                $set: undefined,
                            },
                            status: {
                                $set: null,
                            },
                        },
                    },
                });
            }
            case checkActionForType(reportActions.TOTALS_HIDE, reportType): {
                return update(state, {
                    table: {
                        filteredTotal: {
                            data: {
                                $set: null,
                            },
                            status: {
                                $set: TOTALS_STATUS.DONE,
                            },
                        },
                    },
                });
            }
            case checkActionForType(reportActions.COLUMN_MOVE, reportType): {
                return update(state, {
                    table: {
                        columnOrder: {
                            $set: action.params.columnOrder,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.TOTALS_REQUEST, reportType): {
                return update(state, {
                    table: {
                        filteredTotal: {
                            data: {
                                $set: null,
                            },
                            status: {
                                $set: TOTALS_STATUS.PENDING,
                            },
                        },
                    },
                });
            }
            case checkActionForType(reportActions.HIDE_CHART, reportType): {
                return update(state, {
                    chart: {
                        show: {
                            $set: false,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.SHOW_CHART, reportType): {
                return update(state, {
                    chart: {
                        show: {
                            $set: true,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.TOGGLE_SLOW_LOADING_CHART, reportType): {
                const value = action.value || false;
                return update(state, {
                    chart: {
                        slowLoading: {
                            $set: value,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.HIDE_TABLE, reportType): {
                return update(state, {
                    table: {
                        show: {
                            $set: false,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.SHOW_TABLE, reportType): {
                return update(state, {
                    table: {
                        show: {
                            $set: true,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.QUERY_REQUEST_PARAMS, reportType): {
                return update(state, {
                    [action.queryType]: {
                        request: {
                            $set: action.requestParams,
                        },
                    },
                    chartData: {
                        $set: null,
                    },
                    shouldDrilldown: {
                        $set: isDrilldownEnabled(reportType, state.table.drilldownConfig, state, action),
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_CHART_LINES, reportType): {
                return update(state, {
                    chart: {
                        lines: {
                            $set: action.value,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_CHART_SHOW_LEGEND, reportType): {
                return update(state, {
                    chart: {
                        showLegend: {
                            $set: action.show || !state.chart.showLegend,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_ANONYMOUS_ID, reportType): {
                return update(state, {
                    anonymousId: {
                        $set: action.anonymousId,
                    },
                });
            }
            case reportActions.UPDATE_ANONYMOUS_LIST: {
                return update(state, {
                    anonymousList: {
                        $set: action.anonymousList,
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_BOOKMARK_DATA, reportType): {
                return update(state, {
                    bookmark: {
                        $set: action.data,
                    },
                });
            }
            case checkActionForType(reportActions.SAVE_BOOKMARK_ERROR, reportType): {
                return update(state, {
                    bookmark: {
                        error: {
                            $set: action.message,
                        },
                        updatedInstanceId: {
                            $set: action.instanceId,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.GOAL_SELECTED, reportType): {
                return update(state, {
                    selectedGoal: {
                        $set: action.goal,
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_REPORT_DATA, reportType): {
                return update(state, {
                    $merge: { ...action.data },
                    table: { $merge: action.data.table || {} },
                });
            }
            case checkActionForType(reportActions.UPDATE_REPORT_WARNING, reportType): {
                return update(state, {
                    reportWarning: {
                        $set: {
                            text: action.warningText,
                            params: action.warningParams,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_REPORT_SUPPORTED_APPS, reportType): {
                return update(state, {
                    reportSupportedApps: {
                        $set: action.supportedApps,
                    },
                });
            }
            case checkActionForType(reportActions.UPDATE_REPORT_UNSUPPORTED_APP_TOOLTIP, reportType): {
                return update(state, {
                    reportUnsupportedAppTooltip: {
                        $set: {
                            text: action.tooltipText,
                            params: action.tooltipParams,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.SET_VALIDATION_ERROR, reportType): {
                return update(state, {
                    validationError: {
                        $set: action.message,
                    },
                });
            }
            case checkActionForType(reportActions.HIDE_SPINNER, reportType): {
                return update(state, {
                    showSpinner: {
                        $set: false,
                    },
                });
            }
            case checkActionForType(reportActions.SHOW_REPORT_HEADER, reportType): {
                return update(state, {
                    showHeader: {
                        $set: true,
                    },
                });
            }
            case checkActionForType(reportActions.SET_UNENRICHED, reportType): {
                return update(state, {
                    unenriched: {
                        $set: action.value || !state.unenriched,
                    },
                });
            }
            case checkActionForType(reportActions.SET_ADMON_ALIGNMENT, reportType): {
                return update(state, {
                    admonAlignment: {
                        $set: action.value || !state.admonAlignment,
                    },
                });
            }
            case checkActionForType(reportActions.SET_CHART_ERROR, reportType): {
                return update(state, {
                    chart: {
                        showError: {
                            $set: action.value,
                        },
                    },
                });
            }
            case checkActionForType(reportActions.PIVOT_ROW_GROUP_CHANGED, reportType): {
                const changes = {
                    pivotTable: {
                        rowGroup: {
                            $set: action.rowGroup,
                        },
                    },
                };

                // The original request is saved under table.request, so when bookmarking, the original settings
                // will be taken (and not additional clicked fields if the report was not run with them)
                // However, pivot settings are done on the ready table after the report is run, so we always want the
                // latest values.
                // For that reason we're also updating table.request here (maybe there's a cleaner way to handle this
                // though)
                if (state.table.request) {
                    changes.table = {
                        request: {
                            pivot_table: {
                                rowGroup: {
                                    $set: action.rowGroup,
                                },
                            },
                        },
                    };
                }

                return update(state, changes);
            }
            case checkActionForType(reportActions.PIVOT_VALUES_CHANGED, reportType): {
                const changes = {
                    pivotTable: {
                        values: {
                            $set: action.values,
                        },
                    },
                };

                // The original request is saved under table.request, so when bookmarking, the original settings
                // will be taken (and not additional clicked fields if the report was not run with them)
                // However, pivot settings are done on the ready table after the report is run, so we always want the
                // latest values.
                // For that reason we're also updating table.request here (maybe there's a cleaner way to handle this
                // though)
                if (state.table.request) {
                    changes.table = {
                        request: {
                            pivot_table: {
                                values: {
                                    $set: action.values,
                                },
                            },
                        },
                    };
                }

                return update(state, changes);
            }
            case checkActionForType(reportActions.PIVOT_COLUMNS_CHANGED, reportType): {
                const changes = {
                    pivotTable: {
                        columns: {
                            $set: action.columns,
                        },
                    },
                };

                // The original request is saved under table.request, so when bookmarking, the original settings
                // will be taken (and not additional clicked fields if the report was not run with them)
                // However, pivot settings are done on the ready table after the report is run, so we always want the
                // latest values.
                // For that reason we're also updating table.request here (maybe there's a cleaner way to handle this
                // though)
                if (state.table.request) {
                    changes.table = {
                        request: {
                            pivot_table: {
                                columns: {
                                    $set: action.columns,
                                },
                            },
                        },
                    };
                }

                return update(state, changes);
            }
            case checkActionForType(reportActions.PIVOT_CHART_CHANGED, reportType): {
                const changes = {
                    pivotTable: {
                        chart: {
                            $set: action.chart,
                        },
                    },
                };

                // The original request is saved under table.request, so when bookmarking, the original settings
                // will be taken (and not additional clicked fields if the report was not run with them)
                // However, pivot settings are done on the ready table after the report is run, so we always want the
                // latest values.
                // For that reason we're also updating table.request here (maybe there's a cleaner way to handle this
                // though)
                if (state.table.request) {
                    changes.table = {
                        request: {
                            pivot_table: {
                                chart: {
                                    $set: action.chart,
                                },
                            },
                        },
                    };
                }

                return update(state, changes);
            }
            case UPDATE_USER_DATA:
                return update(state, {
                    unenriched: { $set: action.data.display_unenriched_by_default },
                    admonAlignment: { $set: action.data.display_admon_alignment_by_default },
                });
            case IS_CREATIVE_GALLERY_REPORT_RUNNING: {
                return update(state, {
                    creativesGalleryReportRunning: { $set: action.isRunning },
                });
            }
            default:
                return state;
        }
    };
};

export { createReducerForType, checkActionForType };
