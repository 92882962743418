import { connect } from 'react-redux';
import { addFutureChanged } from 'teamManagement/actions';
import AddFutureCheckbox from 'teamManagement/components/AddFutureCheckbox';

const mapStateToProps = (state, { form, dimensionFilter, ...rest }) => {
    const addFuture = dimensionFilter
        ? state.teamManagement.dimensionFilters[form] && state.teamManagement.dimensionFilters[form].addFuture
        : state.teamManagement[form].addFuture;
    return {
        addFuture,
        ...rest,
    };
};

const mapDispatchToProps = (dispatch, { form, dimensionFilter }) => {
    return {
        onCheckboxChanged: (...args) => {
            dispatch(addFutureChanged(form, dimensionFilter, ...args));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddFutureCheckbox);
