import API from '../services/api';

let instance = null;
const api = new API();

const ASSISTED_URL = 'api/assisted_installs';

export default class AssistedInstallsApi {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    loadAssistedInstalls(app, os) {
        const url = `${ASSISTED_URL}?app=${app}&os=${os}`;
        return new Promise((resolve, reject) => {
            return api
                .getJson(url, {
                    method: 'GET',
                })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }
}
