import React from 'react';
import PropsTypes from 'prop-types';
import { Legend } from 'react-jsx-highcharts';

const ChartLegend = ({ disableHover, reversed }) => {
    return (
        <Legend
            reversed={reversed}
            symbolHeight={10}
            symbolRadius={0}
            verticalAlign="top"
            itemDistance={20}
            itemStyle={{
                fontSize: '12px',
                fontWeight: 400,
                color: '#777777',
            }}
            itemHoverStyle={
                disableHover
                    ? {
                          color: '#777777',
                          cursor: 'default',
                          opacity: 1,
                      }
                    : {}
            }
        />
    );
};

ChartLegend.propTypes = {
    disableHover: PropsTypes.bool,
    reversed: PropsTypes.bool,
};

ChartLegend.defaultProps = {
    disableHover: false,
    reversed: undefined,
};

export default ChartLegend;
