import { reportTypes } from '../../utils/reports';
import {
    getBaseConfig,
    getEmptyStateConfig,
    shelfConfidenceIntervalFlag,
    shelfDateFieldName,
    shelfDimensions,
    shelfMetrics,
    shelfSkanConversionDimensions,
    shelfSkanModeledMetrics,
    shelfSkanRedownloadsDimensions,
    shelfSkanValidatedDimensions,
    shelfTimeBreakdowns,
    shelfWithDruidAppend,
} from './baseReportConfig';

const type = reportTypes.skan;
const path = '/react/skadnetwork_report';
const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.SKADNETWORK');

export default {
    ...basicConfig,
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/4408240139163',
        },
        emptyStateHeaderData: getEmptyStateConfig('SKAN'),
    },
    fieldWarnings: {},
    shelf: [
        shelfDateFieldName,
        shelfDimensions,
        shelfSkanConversionDimensions,
        shelfMetrics,
        shelfSkanRedownloadsDimensions,
        shelfSkanValidatedDimensions,
        shelfSkanModeledMetrics,
        shelfConfidenceIntervalFlag,
        shelfTimeBreakdowns,
        shelfWithDruidAppend,
    ],
    path,
};
