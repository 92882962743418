import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Highlighter from 'react-highlight-words';
import css from './SectionsDropdown.css';
import { getFilteredItems } from './utils';

export default function SectionsDropdown({
    placeholder,
    items,
    footerText,
    footerOnClick,
    onItemClick,
    expanded: expandedProp,
    itemActions,
    searchText,
}) {
    const [expanded, setExpanded] = useState(false);
    const [filteredItems, setFilteredItems] = useState({});

    const searchInputElement = useRef();
    const searchInputValue = searchInputElement.current?.value?.trim();

    const handleSearchChange = text => {
        if (!text) {
            setFilteredItems({ ...items });
            return;
        }

        setExpanded(true);
        setFilteredItems(getFilteredItems(items, text));
    };

    useEffect(() => {
        handleSearchChange(searchInputValue);
    }, [items]);

    useEffect(() => {
        setExpanded(expandedProp);
    }, [expandedProp]);

    const showSearchInput =
        searchInputValue || !!Object.values(filteredItems).find(itemsArrays => !!itemsArrays.length);
    const hasItems = !!Object.keys(filteredItems).length;

    return (
        <div className={classNames(css.section, { [css.expanded]: expanded })}>
            {!showSearchInput && (
                <div className={classNames(css.title, css.noResultsTitle)}>
                    <span>No results...</span>
                </div>
            )}
            {showSearchInput && (
                <div className={css.title} onClick={() => hasItems && setExpanded(!expanded)}>
                    <input
                        maxLength={35}
                        ref={searchInputElement}
                        onChange={event => {
                            handleSearchChange(event.target.value);
                        }}
                        type="text"
                        placeholder={placeholder}
                        className={css.titleText}
                    />
                    {hasItems && <ArrowDownIcon className={classNames(css.arrow, { [css.desc]: expanded })} />}
                </div>
            )}
            {expanded ? (
                <>
                    {Object.entries(filteredItems).map(([section, options]) => {
                        if (!options.length) {
                            return null;
                        }

                        return (
                            <>
                                <div className={css.secondaryTitle}>
                                    <span>{section}</span>
                                    <div className={css.divider} />
                                </div>
                                {options
                                    .sort((a, b) =>
                                        a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1
                                    )
                                    .map(item => {
                                        return (
                                            <div
                                                key={item.name}
                                                className={classNames(css.item, {
                                                    [css.selectedItem]: item.checked,
                                                    [css.disabled]: item.disabled,
                                                })}
                                                onClick={() => onItemClick(item)}
                                            >
                                                <Highlighter
                                                    searchWords={[searchText?.trim(), searchInputValue]}
                                                    textToHighlight={item.display_name || ''}
                                                    autoEscape
                                                />
                                                {itemActions && (
                                                    <div className={css.itemActions}>
                                                        {itemActions.map(({ icon: Icon, onClick, shouldShow }) =>
                                                            shouldShow ? (
                                                                <Icon
                                                                    className={css.actionIcon}
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        onClick(item);
                                                                    }}
                                                                />
                                                            ) : null
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                            </>
                        );
                    })}
                    {footerText && showSearchInput && (
                        <div onClick={footerOnClick} className={css.addItem}>
                            {footerText}
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );
}

SectionsDropdown.propTypes = {
    placeholder: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    footerText: PropTypes.string,
    footerOnClick: PropTypes.func,
    onItemClick: PropTypes.func,
    expanded: PropTypes.bool,
    itemActions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    searchText: PropTypes.string,
};

SectionsDropdown.defaultProps = {
    placeholder: '',
    items: [],
    footerText: '',
    footerOnClick: () => {},
    onItemClick: () => {},
    expanded: false,
    itemActions: [],
    searchText: '',
};
