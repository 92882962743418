import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Formik } from 'formik-2';
import * as Yup from 'yup';
import { Spinner, SingularButton } from '../components/widgets';
import { DropdownFieldEnhanced } from '../utils/components/formUtils';
import { getApps, getLoading } from './selectors';
import css from './page.css';
import { useFetchOS } from './hooks';

const ASSIST_VALIDATION_SCHEMA = Yup.object().shape({
    app: Yup.string().required(),
    os:  Yup.string().required(),
});

function AssistedClicksForm({ onFetchAssisted }) {
    const apps = useSelector(state => getApps(state));
    const { value: OSs, isLoading } = useFetchOS();
    const loading = useSelector(state => getLoading(state));

    if (loading) {
        return <Spinner show expanded />;
    }

    const initialValues = {};

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ASSIST_VALIDATION_SCHEMA}
            onSubmit={onFetchAssisted} >
            {({ values, validateForm, errors, submitForm }) => (
                <form>
                    <div className={css.formArea}>
                        <DropdownFieldEnhanced
                            name="app"
                            items={apps || []}
                            selected={values.app}
                            label="STATIC.PAGES.ASSISTED_INSTALLS.APP_LABEL"
                            containerClass={css.dropdownContainer}
                            error={ errors.app ? errors.app : ''}
                            bodyClassName={css.appDropdown}
                        />

                        <DropdownFieldEnhanced
                            name="os"
                            items={OSs || []}
                            selected={values.os}
                            label="STATIC.PAGES.ASSISTED_INSTALLS.OS_LABEL"
                            containerClass={css.dropdownContainer}
                            error={ errors.os ? errors.os : ''}
                            bodyClassName={css.appDropdown}
                        />
                        <SingularButton
                            onClick={() => {
                                validateForm().then(() => {
                                    submitForm();
                                });
                            }}
                            text="STATIC.PAGES.ASSISTED_INSTALLS.SUBMIT"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}

AssistedClicksForm.propTypes = {
    onFetchAssisted: PropTypes.func.isRequired,
};


export default withLocalize(AssistedClicksForm);