import EmptyState from 'components/widgets/EmptyState';
import React from 'react';
import { Translate } from 'react-localize-redux';
import css from '../governance.css';

export default function GovernanceEmptyState() {
    return (
        <EmptyState icon="happyPage" header="STATIC.PAGES.GOVERNANCE.SCHEMAS.EMPTY_STATE_NO_SCHEMAS" containerClass={css.emptyState}>
            <Translate id="STATIC.PAGES.GOVERNANCE.EMPTY_STATE_PLACEHOLDER" />
        </EmptyState>
    );
}
