import TrackerIcon from 'resources/svg/alerts_empty_state/tracker.svg';
import CombinedIcon from 'resources/svg/alerts_empty_state/combined.svg';
import AdmonIcon from 'resources/svg/alerts_empty_state/admon.svg';
import SkanIcon from 'resources/svg/alerts_empty_state/skan.svg';
import FraudIcon from 'resources/svg/alerts_empty_state/fraud.svg';
import ReportsBellIcon from 'resources/svg/alerts_bells/reports.svg';
import TrackerBellIcon from 'resources/svg/alerts_bells/tracker.svg';
import SkanBellIcon from 'resources/svg/alerts_bells/skan.svg';
import AdmonBellIcon from 'resources/svg/alerts_bells/admon.svg';
import FraudBellIcon from 'resources/svg/alerts_bells/fraud.svg';

export const METRIC_TYPES = [
    { name: 'core_metrics', display_name: 'Metrics', divider: true },
    { name: 'discrepancy_metrics', display_name: 'Source Metrics', divider: true },
    { name: 'all_cohort_metrics', display_name: 'Cohort Metrics', divider: true },
];

export const DIMENSION_TYPES = [
    { name: 'core_dimensions', display_name: 'Dimensions', divider: true },
    { name: 'custom_dimensions', display_name: 'Custom Dimensions', divider: true },
];

export const OPERATOR_TYPES = [
    { name: 'absolute', display_name: 'Absolute', divider: true },
    { name: 'relative', display_name: 'Relative', divider: true },
];

export const THRESHOLD_CONDITIONS = ['lte', 'gte'];

export const DEFAULT_METRIC = {
    name: 'default',
    display_name: 'Select a metric or an event',
};

export const DEFAULT_CONDITION = {
    name: 'default',
    display_name: 'Select a condition',
};

export const DEFAULT_COHORT = {
    name: 'default',
    display_name: 'Cohort period',
};

export const DEFAULT_TIME_PERIOD = {
    name: 'default',
    display_name: 'Select a time period',
};

export const NEW_FILTER = {
    dimension: {
        name: 'default_dimension',
        display_name: 'select a dimension',
    },
    values: {
        name: 'default_values',
        display_name: 'values',
    },
};

export const TRACK_EVENT_PREFIX = 'Alerts v2:';
export const DC_ALERTS_TRACK_EVENT_PREFIX = 'Data Connectors Alerts:';
export const TRACK_EVENT_PAGE_LOADED = 'Page Loaded';
export const SYSTEM_ALERTS_SAVED = 'System Alerts Saved';
export const CUSTOM_ALERT_SAVED = 'Custom Alert Saved';
export const DELETE_ALERT = 'Delete Alert';
export const RAN_TEST_REPORT = 'Ran a Test Report';
export const SLACK_AUTH = 'Slack Auth';
export const SYSTEM_ALERTS_SHELF_OPENED = 'System Alerts Shelf Opened';
export const DC_ALERTS_SHELF_OPENED = 'Data Connector Alerts Shelf Opened';
export const DC_ALERTS_SHELF_SAVED = 'Data Connector Alerts Shelf Saved';

export const STATUS_MESSAGE_MAP = {
    WARNING: '#FFAA20',
    ERROR: '#e64d5f',
};

export const REPORT_TYPE_MAP = {
    TRACKER: {
        title: 'Tracker-Only',
        Icon: TrackerIcon,
        BellIcon: TrackerBellIcon,
        borderColor: '#16254e',
    },
    COMBINED: {
        title: 'Reports',
        Icon: CombinedIcon,
        BellIcon: ReportsBellIcon,
        borderColor: '#3a92e5',
    },
    ADMON: {
        title: 'Ad Monetization',
        Icon: AdmonIcon,
        BellIcon: AdmonBellIcon,
        borderColor: '#b26af1',
    },
    SKAN: {
        title: 'SKAdNetwork',
        Icon: SkanIcon,
        style: { height: '37px' },
        BellIcon: SkanBellIcon,
        borderColor: '#54c7ab',
    },
    FRAUD: {
        title: 'Fraud',
        Icon: FraudIcon,
        borderColor: '#17A38F',
        BellIcon: FraudBellIcon,
    },
};
