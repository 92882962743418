import classNames from 'classnames';
import shelfCss from './PartnerConfigurationShelf.css';
import TableCellTextInput from '../../../components/widgets/TableCellTextInput';
import TableCellAutocomplete from '../../../components/widgets/TableCellAutocomplete';
import TableCellCheckbox from '../../../components/widgets/TableCellCheckbox';
import { TableCellDropdown } from '../../../components/widgets';

export const getTableCellProps = (name, displayName, headerTooltip, headerTooltipData) => ({
    name,
    displayName,
    headerAlign: 'center',
    headerTooltip,
    headerTooltipData,
});

export const getTextCellProps = (name, displayName, headerTooltip, headerTooltipData, cellValues) => ({
    ...getTableCellProps(name, displayName, headerTooltip, headerTooltipData),
    cellComponent: TableCellTextInput,
    cellProps: {
        tdClassName: classNames(shelfCss.tableCell, shelfCss.textCell),
        errorClassName: shelfCss.inputError,
    },
    cellValues,
});

export const getWindowCell = (name, displayName, headerTooltip, headerTooltipData, cellValues) => ({
    ...getTextCellProps(name, displayName, headerTooltip, headerTooltipData),
    cellValues,
    cellProps: {
        type: 'number',
        wholeNumberOnly: true,
        tdClassName: classNames(shelfCss.tableCell, shelfCss.windowCell),
        className: shelfCss.textInputCell,
        errorClassName: shelfCss.inputError,
    },
});

export const getCheckboxCellProps = (name, displayName, headerTooltip, headerTooltipData, disabled, cellProps) => ({
    ...getTableCellProps(name, displayName, headerTooltip, headerTooltipData),
    cellComponent: TableCellCheckbox,
    disabled,
    cellProps: {
        tdClassName: shelfCss.tableCell,
        className: shelfCss.checkbox,
        ...cellProps,
    },
});

export const getAutocompleteCellProps = (name, displayName, headerTooltip, options, onChange, cellValues) => ({
    ...getTableCellProps(name, displayName, headerTooltip),
    cellComponent: TableCellAutocomplete,
    cellValues,
    cellProps: {
        options: index => options(index),
        className: shelfCss.autocompleteCell,
        containerClass: shelfCss.autocomplete,
        errorClassName: shelfCss.inputError,
        onChange: (value, id, index) => onChange(value, name, index),
    },
});

export const getDropdownCellProps = (name, displayName, headerTooltip, options, onChange, cellValues) => ({
    ...getTableCellProps(name, displayName, headerTooltip),
    cellComponent: TableCellDropdown,
    cellValues,
    cellProps: {
        valuesList: options,
        className: shelfCss.dropdownCell,
        containerClass: shelfCss.dropdown,
        errorClassName: shelfCss.inputError,
        onChange: (value, id, index) => onChange(value.name, name, index),
    },
});
