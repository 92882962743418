import React, { useEffect, useState, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import Spinner from '../components/widgets/Spinner';
import { PageWrapper, PageHeaderSlim } from '../components/partials';
import BenchmarksFilters from './components/benchmarksFilters';
import css from './styles.css';
import {
    BENCHMARKS_GRAPH_TYPES,
    COUNTRY_FILTER,
    CATEGORY_FILTER,
    PLATFORM_FILTER,
    TRACK_EVENT_FILTER_CHANGE,
    BENCHMARKS_PAGES,
    TRACK_EVENT_PREFIX,
    TRACK_EVENT_PAGE_LOADED,
} from './consts';
import BenchmarksCharts from './components/benchmarksCharts';
import { useFetchBenchmarksMarketShareOfVoiceData, useStickyHeader } from './hooks';
import { trackMixpanelEvent } from '../utils/general';

const BenchmarksMarketShareOfVoice = () => {
    const ref = useRef(null);
    const [isFading, setFading] = useState(false);
    const [selectedFilterValues, setSelectedFilterValues] = useState({});
    const { graphs: chartsData = {}, isLoading } = useFetchBenchmarksMarketShareOfVoiceData(selectedFilterValues);

    useEffect(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_PAGE_LOADED);
    }, []);

    useStickyHeader(ref);

    /**
     * debouncing filters change to let the user change more than one filter before the charts refreshes
     * @type {*|debounced}
     */
    const onFiltersChange = useCallback(
        debounce((newSelectedValues) => {
            setSelectedFilterValues(newSelectedValues);
            setFading(false);
        }, 2000),
        []
    );

    const onChange = (newSelectedValues) => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_FILTER_CHANGE, { selectedValues: newSelectedValues });
        setFading(true);
        onFiltersChange(newSelectedValues);
    };

    return (
        <PageWrapper className={css.pageWrapper} ref={ref}>
            <div className={css.headerWrapper}>
                <PageHeaderSlim
                    text="STATIC.PAGE_HEADERS.BENCHMARKS_MARKET_SHARE_OF_VOICE"
                    subheaderText="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.SUBHEADER"
                    className={css.header}
                />
            </div>
            <BenchmarksFilters onChange={onChange} viewName={BENCHMARKS_PAGES.MARKET_SHARE_OF_VOICE}>
                <BenchmarksFilters.Categories optionsKey={CATEGORY_FILTER} />
                <BenchmarksFilters.Countries optionsKey={COUNTRY_FILTER} />
                <BenchmarksFilters.Platforms optionsKey={PLATFORM_FILTER} />
            </BenchmarksFilters>
            <Spinner style={{ top: '10vh' }} show={isLoading} expanded/>
            <BenchmarksCharts isLoading={isLoading} isFading={isFading}>
                <BenchmarksCharts.ShareOfVoiceChart
                    data={chartsData[BENCHMARKS_GRAPH_TYPES.SHARE_OF_VOICE]}
                    chartKey={BENCHMARKS_GRAPH_TYPES.SHARE_OF_VOICE}
                />
                <BenchmarksCharts.YearOverYearChart
                    data={chartsData[BENCHMARKS_GRAPH_TYPES.YEAR_OVER_YEAR]}
                    chartKey={BENCHMARKS_GRAPH_TYPES.YEAR_OVER_YEAR}
                />
                <BenchmarksCharts.OverTimeChart
                    data={chartsData[BENCHMARKS_GRAPH_TYPES.COST_OVER_TIME]}
                    chartKey={BENCHMARKS_GRAPH_TYPES.COST_OVER_TIME}
                />
                <BenchmarksCharts.OverTimeChart
                    data={chartsData[BENCHMARKS_GRAPH_TYPES.INSTALLS_OVER_TIME]}
                    chartKey={BENCHMARKS_GRAPH_TYPES.INSTALLS_OVER_TIME}
                />
                <BenchmarksCharts.OrganicVsPaidChart
                    data={chartsData[BENCHMARKS_GRAPH_TYPES.ORGANIC_VS_PAID]}
                    chartKey={BENCHMARKS_GRAPH_TYPES.ORGANIC_VS_PAID}
                />
            </BenchmarksCharts>
        </PageWrapper>
    );
};

export default BenchmarksMarketShareOfVoice;
