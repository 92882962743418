import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';
import { Translate } from 'react-localize-redux';
import { DropdownField, TextField, SwitchField, SingularButton } from '../../components/widgets';
import css from './EditSegment.css';

const styles = {
    container: {
        overflow: 'unset',
    },
};

class EditSegment extends React.Component {
    constructor(props) {
        super(props);
        let active = false;
        if (props.segment && props.segment.edit && props.segment.edit.config && 'active' in props.segment.edit.config) {
            active = props.segment.edit.config.active;
        }
        this.state = {
            active,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        try{
            if (nextProps.segment.edit.config.done && !this.props.segment.edit.config.done) {
                this.setState({
                    active: nextProps.segment.edit.config.active,
                });
            }
        } catch(e) {}
    }

    editSegmentChange(key, value) {
        const { editSegmentChange, segment } = this.props;
        editSegmentChange(key, value, segment);
    }

    render() {
        const {
            segment,
            editingThisSegment,
            editSegmentStart,
            editSegmentChange,
            editSegmentSave,
            editSegmentCancel,
            classes,
        } = this.props;

        const { active } = this.state;

        const { edit, open } = segment;
        let fields = [];
        if (edit && edit.fields) {
            fields = edit.fields;
        }

        return (
            <Card className={`${css.cardContainer} ${open ? css.open : ''}`}>
                <CardContent className={css.cardContentContainer}>
                    <div
                        className={css.cardContent}
                        onClick={() => {
                            editSegmentStart(segment);
                        }}
                    >
                        <div className={css.arrow} />
                        <div role="button" className={css.cardContentHeader} tabIndex={0}>
                            {segment.name}
                        </div>
                        <div className={`${css.onOffIndication} ${active ? css.on : css.off}`}>
                            <Translate id={`STATIC.PAGES.AUDIENCES.${active ? 'ON' : 'OFF'}`} />
                        </div>
                    </div>
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        classes={{
                            container: classes.container,
                        }}
                    >
                        <SwitchField
                            checked={edit.config.active}
                            onChange={(checked) => {
                                this.editSegmentChange('active', checked);
                            }}
                            containerClass={css.switchContainerSegment}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            label="STATIC.PAGES.AUDIENCES.ENABLE_SEGMENT_DISTRIBUTION_TOGGLE_LABEL"
                            type="small"
                        />
                        {fields &&
                            fields.map((field) => {
                                switch (field.ui_type) {
                                    case 'dropdown':
                                        return (
                                            <DropdownField
                                                key={field.name}
                                                label={field.display}
                                                items={field.choices.map((c) => ({
                                                    name: c.name,
                                                    display_name: c.display,
                                                }))}
                                                selected={edit.config[field.name]}
                                                onChange={(value) => {
                                                    this.editSegmentChange(field.name, value);
                                                }}
                                            />
                                        );
                                    case 'text':
                                    case 'secret':
                                    default:
                                        return (
                                            <TextField
                                                key={field.name}
                                                label={field.display}
                                                value={edit.config[field.name]}
                                                password={field.ui_type === 'secret'}
                                                onChange={(value) => {
                                                    this.editSegmentChange(field.name, value);
                                                }}
                                                autoCompleteOff
                                                id={`textfield_seg_${segment.id}_field_${field.name}`}
                                            />
                                        );
                                }
                            })}
                    </Collapse>
                </CardContent>
                {open ? (
                    <CardActions className={css.cardActions}>
                        <SingularButton
                            type="flat"
                            level="level1"
                            onClick={() => {
                                editSegmentCancel(segment);
                            }}
                        >
                            <Translate id="STATIC.BUTTONS.CANCEL" />
                        </SingularButton>
                        <SingularButton
                            type="primary"
                            level="important"
                            showV={edit.config.done}
                            forceActive={edit.config.done || edit.config.saving}
                            onClick={() => {
                                editSegmentSave(segment);
                            }}
                        >
                            <Translate id="STATIC.BUTTONS.SAVE" />
                        </SingularButton>
                    </CardActions>
                ) : null}
            </Card>
        );
    }
}

export default withStyles(styles)(EditSegment);
