import React from 'react';
import css from './metricCards.css';
import Label from '../components/widgets/Label';
import EmptyNumerIcon from '../resources/svg/number_empty.svg';

function MetricCardsEmptyStateInner() {
    return (
        <div className={css.animatedMetricCard}>
            <div className={css.noData}>
                <EmptyNumerIcon className={css.noDataIcon} />
                <Label className={css.noDataLabel} text="STATIC.PAGES.QUICK_REPORTS.METRIC_CARDS.NO_DATA" />
            </div>
        </div>
    );
}

export default MetricCardsEmptyStateInner;
