export const getCurrencySymbol = currency => {
    let currencySymbol = '';

    try {
        currencySymbol = (0)
            .toLocaleString('en-US', {
                style: 'currency',
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })
            .replace(/\d/g, '')
            .trim();
    } catch (e) {
        console.error(`error parsing currency "${currency}". Error: `, e.message);
    }

    return currencySymbol;
};
