const SCROLL_IGNORE_LINE = Object.freeze({
    MIN: 0,
    MAX: 20,
});

const DEFAULT_HEIGHT = 600;
const LINE_HEIGHT = 24;

const CODE_BLOCK_SNIPPETS_LANGUAGES = Object.freeze({
    SWIFT: 'swift',
    OBJECTIVEC: 'objectivec',
    JAVASCRIPT: 'javascript',
    KOTLIN: 'kotlin',
    XML: 'xml',
    JAVA: 'java',
});

const SHOW_PLACEHOLDERS = 'SHOW_PLACEHOLDERS';
const HIDE_PLACEHOLDERS = 'HIDE_PLACEHOLDERS';

export {
    SCROLL_IGNORE_LINE,
    DEFAULT_HEIGHT,
    LINE_HEIGHT,
    CODE_BLOCK_SNIPPETS_LANGUAGES,
    SHOW_PLACEHOLDERS,
    HIDE_PLACEHOLDERS,
};
