import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './ExportPopover.css';
import GenericPopover from './GenericPopover';

class ExportPopover extends React.Component {
    constructor(props) {
        super(props);
        this.handleItemClick = this._handleItemClick.bind(this);
    }

    _handleItemClick(e) {
        const itemId = e.target.id.replace('export_popover_item_', '');
        this.props.onItemClicked(itemId);
    }

    render() {
        const { show, exportOptions } = this.props;
        return (
            <GenericPopover show={show} onInnerShowChanged={this.props.onInnerShowChanged}>
                <ul className={css.container} onClick={this.handleItemClick}>
                    {exportOptions.map((item) => {
                        return (
                            <li key={item.id} className={css.item} id={`export_popover_item_${item.id}`}>
                                {item.label}
                            </li>
                        );
                    })}
                </ul>
            </GenericPopover>
        );
    }
}

ExportPopover.propTypes = {
    show: PropTypes.bool,
    exportOptions: PropTypes.arrayOf(PropTypes.any),
    onInnerShowChanged: PropTypes.func,
    onItemClicked: PropTypes.func,
};

ExportPopover.defaultProps = {
    show: false,
    exportOptions: [
        {
            label: 'Export to CSV',
            id: 'exportCsv',
        },
    ],
    onInnerShowChanged: () => {},
    onItemClicked: () => {},
};

export default ExportPopover;
