import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import SingularButton from '../../components/widgets/SingularButton';
import css from './onboardingGuideSection.css';
import {
    trackTimeSpentOnTask,
    getCurrentDateTime,
    VALIDATE_SETUP,
    SDK_INTEGRATION,
    SDK_CONFIGURATION,
    UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL,
    USER_MARK_AS_DONE_ALLOWED_SECTIONS,
    WEB_SDK_INTEGRATION,
} from '../../homepage/utils';
import SdkKeysDocs from './sdkKeysDocs';
import Audits from './audits';
import SDKConfigurationPage from './sdkConfiguration/sdkConfigurationPage';
import WebAttributionSetUp from './sdkConfiguration/webAttributionSetUp';

function OnboardingGuideSection(props) {
    const getSpecialLayout = (itemId, activeInnerPageIdx) => {
        if (itemId === SDK_INTEGRATION && activeInnerPageIdx === 0) {
            return <SdkKeysDocs {...props} />;
        } else if (itemId === SDK_CONFIGURATION) {
            return <SDKConfigurationPage {...props} />;
        } else if (itemId === VALIDATE_SETUP) {
            return <Audits {...props} />;
        } else if (itemId === WEB_SDK_INTEGRATION) {
            return <WebAttributionSetUp {...props} />;
        }

        return null;
    };

    const { menuItemsConfig, itemId, translate, activeInnerPageIdx, onNextButtonClick, onMarkAsDoneClick, isLastItem, adminMode } = props;
    const { onboardingGuideSubSections } = menuItemsConfig[itemId];
    const activeContentId = onboardingGuideSubSections ? onboardingGuideSubSections[activeInnerPageIdx] : itemId;

    const specialLayout = getSpecialLayout(itemId, activeInnerPageIdx);

    const defaultButtonType = `STATIC.BUTTONS.${isLastItem ? 'DONE' : 'NEXT'}`;
    const buttonText = menuItemsConfig[activeContentId].onboardingGuideShowTour
        ? `STATIC.PAGES.ONBOARDING_GUIDE.BUTTON.${activeContentId}`
        : defaultButtonType;
    const mediaSrc = menuItemsConfig[activeContentId].onboardingGuideMediaSrc;
    const mediaSrcType = menuItemsConfig[activeContentId].onboardingGuideMediaSrcType;
    const isTaskCompleted = menuItemsConfig[activeContentId].completed;
    const { showHeader = true, showFooter = true, contentContainerStyle } = menuItemsConfig[activeContentId];

    const contentContainerButton = (
        <div className={css.contentButtonContainer}>
            {!isTaskCompleted && (USER_MARK_AS_DONE_ALLOWED_SECTIONS.includes(activeContentId) || adminMode) && (
                <SingularButton
                    type="flat"
                    className={classNames(css.contentSectionButton, css.contentMarkAsDoneButton)}
                    text={translate(`STATIC.PAGES.ONBOARDING_GUIDE.BUTTON.MARK_AS_DONE`)}
                    onClick={() => onMarkAsDoneClick({ skipConfirmationPopup: false })}
                />
            )}
            <SingularButton
                onClick={() => onNextButtonClick()}
                className={css.contentSectionButton}
                text={translate(buttonText)}
            />
        </div>
    );
    const [lastItemId, setLastItemId] = useState(null);
    const [lastVisitedTimestamp, setLastVisitedTimestamp] = useState(getCurrentDateTime());

    useEffect(() => {
        if (lastItemId && lastItemId !== itemId) {
            trackTimeSpentOnTask(lastItemId, lastVisitedTimestamp, UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL);
        }

        setLastItemId(itemId);
        setLastVisitedTimestamp(getCurrentDateTime());
    }, [itemId]);

    return (
        <Fragment key={`fragment-section-${itemId}-${activeInnerPageIdx}`}>
            {showHeader && (
                <>
                    <div className={css.contentTitleContainer}>
                        <span className={css.contentTitleText}>
                            {translate(`STATIC.PAGES.ONBOARDING_GUIDE.MENU_ITEMS.${activeContentId}`)}
                        </span>
                        <SingularButton
                            onClick={() => onNextButtonClick()}
                            className={css.contentTitleButton}
                            text={translate(buttonText)}
                        />
                    </div>

                    <div className={css.contentSeparator} />
                </>
            )}
            <div className={css.contentContainer} style={contentContainerStyle}>
                {specialLayout ? (
                    <div style={{ width: '100%' }}>
                        {specialLayout}
                        {showFooter && contentContainerButton}
                    </div>
                ) : (
                    <div className={classNames(css.content, { [css.contentNoMedia]: !mediaSrc })}>
                        {translate(`STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.${activeContentId}`)}
                        {showFooter && contentContainerButton}
                    </div>
                )}
                {!specialLayout && mediaSrc && (
                    <div className={css.mediaContainer}>
                        {mediaSrcType === 'img' && <img alt="singular" className={css.mediaImage} src={mediaSrc} />}
                        {mediaSrcType === 'video' && (
                            <iframe
                                title="singular"
                                className={css.mediaVideo}
                                src={mediaSrc}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
}

OnboardingGuideSection.propTypes = {
    itemId: PropTypes.string.isRequired,
    menuItemsConfig: PropTypes.objectOf(PropTypes.any).isRequired,
    translate: PropTypes.func.isRequired,
    activeInnerPageIdx: PropTypes.number.isRequired,
    apiKey: PropTypes.string,
    sdkKey: PropTypes.string,
    onNextButtonClick: PropTypes.func.isRequired,
    onMarkAsDoneClick: PropTypes.func.isRequired,
    isLastItem: PropTypes.bool,
    adminMode: PropTypes.bool,
};

OnboardingGuideSection.defaultProps = {
    apiKey: '',
    sdkKey: '',
    isLastItem: false,
    adminMode: false,
};

export default withLocalize(OnboardingGuideSection);
