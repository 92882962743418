import React from 'react';
import { Translate } from 'react-localize-redux';
import css from './ShelfHeader.css';
import XIcon from '../../resources/svg/close_icon.svg';

function ShelfHeader({ text, data, onClose }) {
    return (
        <header className={css.headerContainer}>
            <span>
                <Translate id={text} data={data} />
            </span>
            <XIcon
                className={css.closeButton}
                onClick={() => {
                    onClose();
                }}
            />
        </header>
    );
}

export default ShelfHeader;
