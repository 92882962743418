import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import css from './ReportsEmptyState.css';
import ReportsPreview from '../../../resources/png/dashboards/previews/column.png';
import { SingularButton } from '../../widgets';
import EditIconButton from '../../../resources/svg/edit_icon_button.svg';
import editSingularButtonCss from '../../widgets/ReportSingularButton.css';
import { isShelfOpen, setActionType } from '../../../actions/reports';

export default function ReportsEmptyState({ reportType }) {
    const dispatch = useDispatch();

    const onOpenEditShelfClick = () => {
        dispatch(setActionType(isShelfOpen(true), reportType));
    };

    return (
        <div className={css.reportsEmptyStateContainer}>
            <img src={ReportsPreview} />
            <div>There is no data to display yet</div>
            <SingularButton
                text="STATIC.BUTTONS.CREATE_REPORT"
                type="primary"
                level="level1"
                style={{
                    marginTop: '20px',
                    height: '38px',
                    display: 'inline-block',
                    borderRadius: '4px',
                }}
                Icon={EditIconButton}
                textClassName={editSingularButtonCss.editButtonText}
                onClick={onOpenEditShelfClick}
            />
        </div>
    );
}

ReportsEmptyState.propTypes = {
    reportType: PropTypes.string,
};

ReportsEmptyState.defaultProps = {
    reportType: '',
};
