export const STARTED_SIGNUP_FORM = 'action.signup.started';
export const SUBMIT_SIGNUP_FORM = 'action.signup.submit';
export const STARTED_GOOGLE_SIGNUP_FORM = 'action.google.signup.started';
export const SUBMIT_COMPLETE_GOOGLE_SIGN_UP = 'action.google-sign-up.submit';
export const RESEND_EMAIL = 'action.signup.resend-email';
export const SET_MAIN_VIEW_NAME = 'action.signup.set.view-name';
export const SET_STATUS = 'action.signup.set.submit.status';
export const SET_IS_LOADING = 'action.signup.set.is-loading';
export const SET_EMAIL_SENT = 'action.signup.set.email-sent';
export const SET_FINISHED_GOOGLE_SIGNUP_FIRST_STEP = 'action.signup.set.google-first-step.finished';

export const submitSignupFormStarted = data => ({
    type: STARTED_SIGNUP_FORM,
    data,
});

export const submitSignupForm = data => ({
    type: SUBMIT_SIGNUP_FORM,
    data,
});

export const submitGoogleSignupStarted = data => ({
    type: STARTED_GOOGLE_SIGNUP_FORM,
    data,
});

export const submitCompleteGoogleSignIn = data => ({
    type: SUBMIT_COMPLETE_GOOGLE_SIGN_UP,
    data,
});

export const resendConfirmationEmail = email => ({
    type: RESEND_EMAIL,
    data: { email },
});

export const setStatus = (status, errorCode) => ({
    type: SET_STATUS,
    status,
    errorCode,
});

export const setEmailSent = emailSent => ({
    type: SET_EMAIL_SENT,
    emailSent,
});

export const setIsLoading = isLoading => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const setFinishedGoogleSignupFirstStep = isFinished => ({
    type: SET_FINISHED_GOOGLE_SIGNUP_FIRST_STEP,
    isFinished,
});
