import update from 'immutability-helper';
import moment from 'moment';
import { CUSTOMER_TIERS } from './consts';
import { trackMixpanelEvent } from './general';
import { getDaysDiff } from './regional';
import { deserializeReportQuery, serializeReportQuery } from './url';
import {
    ADMON_DATA_AD_REVENUE_SOURCE,
    COPY_CSV_TO_CLIPBOARD_BUTTON_ID,
    DATE_TEXT_FORMAT,
    DOWNLOAD_CSV_BUTTON_ID,
    EXPORT_TO_GOOGLE_SHEETS_BUTTON_ID,
    FIELDS_AVAILABILITY_BUTTON_ID,
    INDICATION_FIELDS_PREFIX,
    INTERPOLATION_AFFECTED_COLUMNS,
    INTERPOLATION_INDICATION_FILED_NAMES,
    INTERPOLATION_RELEVANT_COLUMNS,
} from './reportsConsts';
import { NON_REPORTS_FIELDS_WHITELIST, REPORTS_FIELDS_BLACKLIST } from '../fieldsAvailability/consts';
import { COMPARE_MODE, REGULAR_MODE } from '../components/pages/reports/consts';
import { downloadOrExportReport, setActionType, toggleFieldsAvailability } from '../actions/reports';
import { listEqual } from './arrays';

export const isFieldSelectionCleared = (selectedFields, selectedFilters = [], ignoreKeys = ['timeBreakdowns']) => {
    const emptyFields = Object.keys(selectedFields).reduce((isEmpty, key) => {
        if (ignoreKeys.includes(key)) {
            return isEmpty;
        } else {
            return isEmpty && selectedFields[key].length === 0;
        }
    }, true);

    const emptyFilters = selectedFilters.length === 1 && selectedFilters[0].field.name === 'default';

    return emptyFields && emptyFilters;
};

export const getFiltersAmount = filters => {
    return filters.map(f => f.tags.length).reduce((a, b) => a + b, 0);
};

const isObject = item => {
    return item !== null && typeof item === 'object';
};

const _comparisonHandler = (key, val) => {
    return {
        mode: {
            $set: val ? COMPARE_MODE : REGULAR_MODE,
        },
    };
};

const _goalsHandler = (key, val) => {
    return {
        selectedTab: {
            $set: val ? 'goal' : 'regular',
        },
    };
};

const _fieldHandler = (key, val, args) => {
    const useKey = args.modify || key;
    return {
        selectedFields: {
            $merge: {
                [useKey]: val,
            },
        },
    };
};

const _periodHandler = (key, val, { modify, fields }) => {
    const normalizedPeriods = fields.periods
        .filter(p => val.includes(p.name) || val.includes(p.period_id))
        .map(p => p.name);

    const useKey = modify || key;
    return {
        selectedFields: {
            $merge: {
                [useKey]: normalizedPeriods,
            },
        },
    };
};

const _fieldSplitterHandler = (key, val, { fields, split }) => {
    return {
        selectedFields: {
            $merge: split.reduce((res, currentKey) => {
                res[currentKey] = val.filter(v => fields[currentKey].map(m => m.name).includes(v));
                return res;
            }, {}),
        },
    };
};

// Translating filter value names to their display_names.
// in order to make the tag input (filters) work properly.
const _getFilterValues = (valuesList, field, filters) => {
    let ret = valuesList;

    if (filters?.dimensions?.length) {
        const { dimensions } = filters;
        const dimension = dimensions.find(d => d.name === field);

        if (dimension) {
            ret = valuesList.map(val => {
                const dimensionValue = dimension.values.find(dv => dv.name === val);
                return (dimensionValue && dimensionValue.display_name) || val;
            });
        }
    } else if (Array.isArray(filters)) {
        const fieldFilters = filters.find(({ name }) => name === field);

        const valuesSet = new Set(valuesList);
        ret = [];

        (fieldFilters?.values || []).forEach(({ name, display_name: displayName }) => {
            if (valuesSet.has(name) && displayName) {
                ret.push(displayName);
                valuesSet.delete(name);
            }
        });

        // Adding fields values without display_name.
        valuesSet.forEach(val => {
            ret.push(val);
        });
    }

    return ret;
};

const _filtersHandler = (key, val, { fields: { filters } }) => {
    let ret = {};

    if (val.length) {
        const filterMap = val.map(item => {
            const field = (item.selected_filter && item.selected_filter.name) || item.dimension;
            const operator = (item.selected_operator && item.selected_operator.name) || item.operator;
            const tags =
                (item.selected_filter_values && item.selected_filter_values.map(fv => fv.display_name)) ||
                _getFilterValues(item.values, field, filters);
            return {
                field,
                operator,
                tags,
            };
        });

        ret = {
            filters: {
                $set: filterMap,
            },
        };
    }

    return ret;
};

const _skanDateDimensionNameHandler = (key, val, { modify }) => {
    return {
        selectedFields: {
            $merge: {
                [modify]: [val],
            },
        },
    };
};

const _dateHandler = (key, val) => {
    return {
        dates: {
            $merge: {
                [key]: val,
            },
        },
    };
};

const _goalHandler = (key, val) => {
    return {
        selectedGoal: {
            $set: val?.name,
        },
    };
};

const _bookmarkHandler = (key, val) => {
    return {
        bookmark: {
            bookmark_id: {
                $set: val,
            },
        },
        reportFormHasChanged: {
            $set: false,
        },
    };
};

const _bookmarkCreatorHandler = (key, val) => {
    return {
        bookmark: {
            bookmark_creator: {
                $set: val,
            },
        },
    };
};

const _unenrichedHandler = (key, val) => {
    return {
        unenriched: {
            $set: val,
        },
    };
};

const _admonAlignmentHandler = (key, val) => {
    return {
        admonAlignment: {
            $set: val,
        },
    };
};

const _redownloadsHandler = (key, val) => {
    return {
        showRedownloadsDimensionInReport: {
            $set: val,
        },
    };
};

const _validatedHandler = (key, val) => {
    return {
        showValidatedDimensionInReport: {
            $set: val,
        },
    };
};

const _apiKeyHandler = (key, val) => {
    return {
        api_key: {
            $set: val,
        },
    };
};

const _pivotTableHandler = (key, val) => {
    return {
        pivotTable: {
            $set: val,
        },
    };
};

const _postProcessFiltersHandler = (key, val) => {
    return {
        postProcessFiltersUI: {
            $set: val,
        },
    };
};

const _isSlimModeHandler = (key, val) => {
    return {
        isSlimMode: {
            $set: val,
        },
    };
};

const _columnOrder = (key, val) => {
    return {
        table: {
            column_order: {
                $set: val,
            },
        },
    };
};

const queryMap = {
    compare: {
        handler: _comparisonHandler,
    },
    is_goals: {
        handler: _goalsHandler,
    },
    dimensions: {
        handler: _fieldSplitterHandler,
        args: {
            split: ['dimensions', 'custom_dimensions', 'governanceDimensions', 'utmParamsFields'],
        },
    },
    metrics: {
        handler: _fieldSplitterHandler,
        args: {
            split: [
                'metrics',
                'xorgMetrics',
                'appMetrics',
                'webMetrics',
                'crossDeviceMetrics',
                'conversionEvents',
                'adMonDAUMetrics',
                'skanModeledMetrics',
                'socialEngagementMetrics',
            ],
        },
    },
    discrepancy_metrics: {
        handler: _fieldHandler,
        args: {
            modify: 'discrepancyMetrics',
        },
    },
    skanModeledMetrics: {
        handler: _fieldHandler,
        args: {
            modify: 'skanModeledMetrics',
        },
    },
    skan_modeled_cohort_metrics: {
        handler: _fieldHandler,
        args: {
            modify: 'skanModeledCohortMetrics',
        },
    },
    skan_modeled_cohort_periods: {
        handler: _fieldHandler,
        args: {
            modify: 'skanModeledCohortPeriods',
        },
    },
    unified_cohort_periods: {
        handler: _fieldHandler,
        args: {
            modify: 'unifiedCohortPeriods',
        },
    },
    modeled_skan_custom_events: {
        handler: _fieldHandler,
        args: {
            modify: 'skanModeledCustomEvents',
        },
    },
    data_type_fields: {
        handler: _fieldHandler,
        args: {
            modify: 'dataTypeFields',
        },
    },
    metric_group_fields: {
        handler: _fieldHandler,
        args: {
            modify: 'metricGroupFields',
        },
    },
    cohort_metric_group_fields: {
        handler: _fieldHandler,
        args: {
            modify: 'cohortMetricGroupFields',
        },
    },
    cohort_metrics: {
        handler: _fieldSplitterHandler,
        args: {
            split: ['cohortMetrics', 'customEvents', 'xorgCohortMetrics', 'skanCustomEvents'],
        },
    },
    cohort_periods: {
        handler: _periodHandler,
        args: {
            modify: 'periods',
        },
    },
    goals_metrics: {
        handler: _fieldHandler,
        args: {
            modify: 'goalsMetrics',
        },
    },
    goals_forecasct_metrics: {
        handler: _fieldHandler,
        args: {
            modify: 'goalsForecastMetrics',
        },
    },
    filters: {
        handler: _filtersHandler,
        args: {},
    },
    time_breakdown: {
        handler: _fieldHandler,
        args: {
            modify: 'timeBreakdowns',
        },
    },
    source_attribution_type: {
        handler: _fieldHandler,
        args: {
            modify: 'sourceAttributionType',
        },
    },
    with_append_tables: {
        handler: _fieldHandler,
        args: {
            modify: 'withDruidAppend',
        },
    },
    druid_aggregated_data_sources_mode: {
        handler: _fieldHandler,
        args: {
            modify: 'druidAggregatedDataSourcesMode',
        },
    },
    cross_device_demo_cohort_type: {
        handler: _fieldHandler,
        args: {
            modify: 'crossDeviceDemoCohortType',
        },
    },
    cross_device_cohort_type: {
        handler: _fieldHandler,
        args: {
            modify: 'crossDeviceCohortType',
        },
    },
    skan_conversion_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'skanConversionDimensions',
        },
    },

    skan_redownloads_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'skan_redownloads_dimensions',
        },
    },

    skan_validated_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'skan_validated_dimensions',
        },
    },

    confidence_interval_flag: {
        handler: _fieldHandler,
        args: {
            modify: 'confidenceIntervalFlag',
        },
    },

    start_date: {
        handler: _dateHandler,
    },
    start_date_2: {
        handler: _dateHandler,
    },
    end_date: {
        handler: _dateHandler,
    },
    end_date_2: {
        handler: _dateHandler,
    },
    goal: {
        handler: _goalHandler,
    },
    bookmark_id: {
        handler: _bookmarkHandler,
    },
    bookmark_creator: {
        handler: _bookmarkCreatorHandler,
    },
    granularity_levels: {
        handler: _fieldHandler,
        args: {
            modify: 'granularityLevels',
        },
    },
    file_combiner_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'fileCombinerDimensions',
        },
    },
    enrichment_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'enrichmentDimensions',
        },
    },
    source_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'sourceDimensions',
        },
    },
    skan_date_dimension_name: {
        handler: _skanDateDimensionNameHandler,
        args: {
            modify: 'skanDateDimensionName',
        },
    },
    metadata_dimensions: {
        handler: _fieldHandler,
        args: {
            modify: 'metadataDimensions',
        },
    },
    display_unenriched: {
        handler: _unenrichedHandler,
    },
    display_admon_alignment: {
        handler: _admonAlignmentHandler,
    },
    showRedownloadsDimensionInReport: {
        handler: _redownloadsHandler,
    },
    showValidatedDimensionInReport: {
        handler: _validatedHandler,
    },
    api_key: {
        handler: _apiKeyHandler,
    },
    pivot_table: {
        handler: _pivotTableHandler,
    },
    post_process_filters_ui: {
        handler: _postProcessFiltersHandler,
    },
    is_slim_mode: {
        handler: _isSlimModeHandler,
    },
    column_order: {
        handler: _columnOrder,
    },
};

const expandRow = (metadata, row) => {
    const newRow = {};
    for (const rowEntry of Object.entries(row)) {
        const fieldName = rowEntry[0];
        let fieldValue = rowEntry[1];

        if (fieldValue == null && !fieldName.endsWith('_confidence_interval')) {
            fieldValue = 'N/A';
        }

        if (
            isObject(fieldValue) &&
            !(metadata[fieldName] || {}).metric_as_object &&
            fieldName !== 'creative_cluster_members' &&
            fieldName !== 'creative_tags'
        ) {
            Object.entries(fieldValue).forEach(([key, value]) => {
                if (value == null && !fieldName.endsWith('_confidence_interval')) {
                    value = 'N/A';
                }

                const tkey = `${fieldName}__${key}`;
                if (!isObject(value) || (metadata[tkey] || {}).metric_as_object) {
                    newRow[tkey] = value;
                    return;
                }

                const expanded = expandRow(metadata, { [tkey]: value });
                Object.entries(expanded).forEach(([expandedKey, expandedValue]) => {
                    newRow[expandedKey] = expandedValue;
                });
            });
        } else {
            newRow[fieldName] = typeof fieldValue === 'string' ? fieldValue.normalize('NFC') : fieldValue;
        }
    }
    return newRow;
};

const expandData = (metadata, data) => {
    if (!data) return [];
    return data.map(row => expandRow(metadata, row));
};

const buildNameAndSubName = (name, subName) => {
    return subName ? [name, subName].join('__') : name;
};

export const COMPARE_PERIOD = {
    PERIOD_1: 'period1',
    PERIOD_2: 'period2',
};

export const COMPARE_CHANGE = {
    ABS_CHANGE: 'absChange',
    CHANGE: 'change',
};

const expandMetaDataComparison = (col, subCol) => {
    // We want to set ids to the different columns (for coulumns ordering).
    // In order to prevent duplicate ids between different metrics, we multiply the original id by 10.
    col.id *= 10;
    const ret = {};
    const prevCol = { ...col };
    const absCol = { ...col };
    const changeCol = { ...col };
    const subColName = subCol ? subCol.name : '';

    col.name = buildNameAndSubName(col.name, COMPARE_PERIOD.PERIOD_1);
    col.name = buildNameAndSubName(col.name, subColName);
    col.id += 1;

    prevCol.name = buildNameAndSubName(prevCol.name, COMPARE_PERIOD.PERIOD_2);
    prevCol.name = buildNameAndSubName(prevCol.name, subColName);
    prevCol.display_name = `Previous ${prevCol.display_name}`;
    ret[prevCol.name] = prevCol;

    absCol.name = buildNameAndSubName(absCol.name, COMPARE_CHANGE.ABS_CHANGE);
    absCol.name = buildNameAndSubName(absCol.name, subColName);
    absCol.display_name = 'Change';
    absCol.isChange = true;
    absCol.id += 2;
    ret[absCol.name] = absCol;

    changeCol.name = buildNameAndSubName(changeCol.name, COMPARE_CHANGE.CHANGE);
    changeCol.name = buildNameAndSubName(changeCol.name, subColName);
    changeCol.display_name = '% Change';
    changeCol.display_format = { ...changeCol.display_format };
    changeCol.display_format.symbol = '%';
    changeCol.display_format.type = 'percentage';
    changeCol.isChange = true;
    changeCol.id += 3;
    ret[changeCol.name] = changeCol;

    return ret;
};

const expandMetadata = (metadata = {}, compare = false) => {
    const reportMetadata = {};
    Object.values(metadata).forEach(col => {
        if (col.name.endsWith('_confidence_interval')) {
            return;
        }

        const columns = col.columns ? col.columns : [col];

        columns.forEach(subColumn => {
            const newColumn = { ...col };
            newColumn.baseName = newColumn.name;
            let useSubColumn = false;

            if (newColumn.columns) {
                useSubColumn = true;
                newColumn.name = compare ? col.name : buildNameAndSubName(col.name, subColumn.name);
                newColumn.display_name = subColumn.display_name;
                newColumn.id = subColumn.id;
                delete newColumn.columns;
            }

            // if in compare mode and the column is metric, add previous / absChange / % change columns
            if (compare && newColumn.type === 'metric') {
                const expandedComp = expandMetaDataComparison(newColumn, useSubColumn ? subColumn : null);
                Object.assign(reportMetadata, expandedComp);
            }

            reportMetadata[newColumn.name] = newColumn;
        });
    });

    return reportMetadata;
};

const defaultLastFilter = {
    field: {
        name: 'default',
        display_name: 'STATIC.PAGES.CUSTOM_DIMENSIONS.PLACEHOLDERS.FILTER_DIMENSION',
    },
    operator: 'in',
    tags: [],
};

const DEFAULT_APP_FILTER = {
    field: 'app',
    operator: 'in',
    tags: [],
};

const DEFAULT_SOURCE_FILTER = {
    field: 'source',
    operator: 'in',
    tags: [],
};

const DEFAULT_ADMON_SOURCE_FILTER = {
    field: 'admon_source',
    operator: 'in',
    tags: [],
};

const DEFAULT_EMPTY_FILTER = {
    field: 'default',
    operator: 'in',
    tags: [],
};

const DEFAULT_FILTER_NAMES = [DEFAULT_APP_FILTER.field, DEFAULT_SOURCE_FILTER.field, DEFAULT_ADMON_SOURCE_FILTER.field];

export const reportTypes = {
    mainReport: 'reports',
    pivot: 'pivot',
    retention: 'retention',
    fraudProtected: 'fraudProtected',
    fraudSuspicious: 'fraudSuspicious',
    fraudRejected: 'fraudRejected',
    anonymous: 'anonymous',
    adMonetization: 'adMonetization',
    creative: 'creative',
    crossDevice: 'crossDevice',
    skan: 'skan',
    skanSummary: 'skanSummary',
    unifiedReport: 'unifiedReport',
};

export const drilldownReportTypes = [reportTypes.mainReport, reportTypes.crossDevice];

export const isDrilldownEnabled = (reportType, drilldownConfig, state, action) => {
    return drilldownReportTypes.includes(reportType) && drilldownConfig && drilldownConfig(state, action);
};

const REPORT_MODE = {
    ALL: 'all',
    GOALS: 'goals',
    REGULAR: REGULAR_MODE,
    COMPARE: COMPARE_MODE,
    ADMON: reportTypes.adMonetization,
    SKAN: reportTypes.skan,
    SKAN_SUMMARY: reportTypes.skanSummary,
    UNIFIED_REPORT: reportTypes.unifiedReport,
};

const defaultFilters = reportType => {
    if (reportType && reportType === reportTypes.adMonetization) {
        return [DEFAULT_APP_FILTER, DEFAULT_ADMON_SOURCE_FILTER];
    }

    if (window.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS) {
        return [DEFAULT_SOURCE_FILTER];
    }

    return [DEFAULT_APP_FILTER, DEFAULT_SOURCE_FILTER];
};

const translateQueryToStore = (query, fields) => {
    let store = {
        selectedFields: {},
        dates: {},
        mode: REGULAR_MODE,
        selectedTab: 'regular',
        selectedGoal: null,
        filters: defaultFilters(),
        bookmark: {
            bookmark_id: '',
            bookmark_creator: '',
        },
        table: {
            column_order: [],
        },
    };
    for (const key in query) {
        if (query.hasOwnProperty(key)) {
            if (!queryMap[key]) {
                continue;
            }
            const { handler = () => ({}), args = [] } = queryMap[key];
            store = update(store, handler.call(null, key, query[key], { ...args, fields }));
        }
    }
    return store;
};

const anyMetricSelectedCheck = (query, metricNames) => {
    // check if any metric was selected
    for (const metricName of metricNames) {
        if (query[metricName] && query[metricName].length) {
            return true;
        }
    }
    return false;
};

const metricSelectedCheck = (query, metricNames) => {
    return metricNames.filter(value => query.metrics.includes(value)).length > 0;
};

const onlyAllowedDimensionsSelected = (query, allowedDimensions) => {
    for (const dimensionName of query.dimensions) {
        if (!allowedDimensions.includes(dimensionName) && !dimensionName.includes(INDICATION_FIELDS_PREFIX)) {
            return false;
        }
    }
    return true;
};

const isPastGoalCheck = query => {
    return getDaysDiff(query.end_date, new Date(), 0) > 0;
};

const isFutureGoalCheck = query => {
    return getDaysDiff(query.start_date, new Date(), 0) < 0 && getDaysDiff(query.start_date, new Date(), 0) < 0;
};

const restrictionsGranularityCheck = (query, restrictions) => {
    if (!restrictions) {
        return true;
    }

    const minQueryLength = restrictions.min_months;
    const maxQueryLength = restrictions.max_months;
    const restrictedDimensions = restrictions.dimensions;
    const startDate = moment(query.start_date);
    const endDate = moment(query.end_date);
    const startDate2 = moment(query.start_date_2);
    const endDate2 = moment(query.end_date_2);
    const queryLength = Math.max(endDate.diff(startDate, 'months'), endDate2.diff(startDate2, 'months') || 0);
    return (
        queryLength < minQueryLength ||
        (minQueryLength <= queryLength <= maxQueryLength &&
            query.dimensions.every(dim => restrictedDimensions.includes(dim)))
    );
};

const highGranularityRulesCheck = query => {
    const highGranularityFields = [
        'publisher_site_id',
        'publisher_id',
        'sub_publisher_id',
        'sub_publisher_name',
        'publisher_site_name',
        'sub_publisher_id_splitted',
        'publisher_id_splitted',
        'city_field',
    ];
    const validateFilterByName = name => {
        return query.filters.filter(f => f.dimension === name && f.values && f.values.length === 1);
    };
    const highGranularityDimensionExist =
        query.dimensions.filter(dim => {
            return highGranularityFields.includes(dim);
        }).length > 0;
    const singleAppFilter = validateFilterByName('app').length === 1;
    const singleSourceFilter = validateFilterByName('source').length === 1;
    return query.is_async || !highGranularityDimensionExist || singleAppFilter || singleSourceFilter;
};

function isCountryFieldSelectedInQuery(query) {
    const countryField = 'country_field';
    return query.dimensions.includes(countryField) || query.filters.find(filter => filter.dimension === countryField);
}

const validationsMap = {
    [REPORT_MODE.REGULAR]: [],
    [REPORT_MODE.COMPARE]: [],
    [REPORT_MODE.ADMON]: [
        {
            test: query => {
                if (metricSelectedCheck(query, ['dau', 'arpdau'])) {
                    return onlyAllowedDimensionsSelected(query, ['app', 'ad_country', 'os', 'platform']);
                }
                return true;
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_ALLOWED_DIMENSIONS',
        },
    ],
    [REPORT_MODE.GOALS]: [
        {
            test: query => {
                return query.goal && query.goal.public_id;
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_SELECT_GOAL',
        },
        {
            test: query => {
                return (
                    (anyMetricSelectedCheck(query, ['goals_forecast_metrics']) && !isPastGoalCheck(query)) ||
                    anyMetricSelectedCheck(query, ['goals_metrics'])
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_SELECT_METRIC',
        },
        {
            test: query => {
                return !isFutureGoalCheck(query);
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_FUTURE_GOAL',
        },
    ],
    [REPORT_MODE.UNIFIED_REPORT]: [
        {
            test: query => {
                return anyMetricSelectedCheck(query, ['dimensions']);
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_SELECT_DIMENSION',
        },
        {
            test: query => {
                const isDataTypeFieldSelected = anyMetricSelectedCheck(query, ['data_type_fields']);
                const isMetricGroupFieldSelected =
                    anyMetricSelectedCheck(query, ['metric_group_fields']) ||
                    anyMetricSelectedCheck(query, ['cohort_metric_group_fields']);

                return isDataTypeFieldSelected || !isMetricGroupFieldSelected;
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_DATA_TYPE_FOR_METRIC_GROUP',
        },
        {
            test: query => {
                const isCohortPeriodSelected = anyMetricSelectedCheck(query, ['unified_cohort_periods']);
                const isCohortFieldsSelected = anyMetricSelectedCheck(query, ['cohort_metric_group_fields']);

                return !isCohortFieldsSelected || (isCohortPeriodSelected && isCohortFieldsSelected);
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_PERIOD_FOR_COHORT_UNIFIED_REPORT',
        },
    ],
    [REPORT_MODE.ALL]: [
        {
            test: query => {
                return anyMetricSelectedCheck(query, [
                    'metrics',
                    'discrepancy_metrics',
                    'cohort_metrics',
                    'modeled_skan_custom_events',
                    'metric_group_fields',
                    'skan_modeled_cohort_metrics',
                    'cohort_metric_group_fields',
                ]);
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_SELECT_METRIC',
        },
        {
            test: query => {
                const isCohortPeriodSelected = anyMetricSelectedCheck(query, ['cohort_periods']);
                const isCohortMetricSelected = anyMetricSelectedCheck(query, ['cohort_metrics']);

                return (
                    query.query_type === reportTypes.skanSummary ||
                    !isCohortMetricSelected ||
                    (isCohortMetricSelected && isCohortPeriodSelected)
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_PERIOD_FOR_COHORT',
        },
        {
            test: query => {
                return (
                    (anyMetricSelectedCheck(query, ['granularity_levels']) &&
                        anyMetricSelectedCheck(query, ['file_combiner_dimensions'])) ||
                    (!anyMetricSelectedCheck(query, ['granularity_levels']) &&
                        !anyMetricSelectedCheck(query, ['file_combiner_dimensions']))
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_GRANULARITY',
        },
        {
            test: query => {
                return highGranularityRulesCheck(query);
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_PUBLISHER',
        },
        {
            test: restrictionsGranularityCheck,
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_DIMENSIONS_GRANULARITY',
        },
    ],
    [REPORT_MODE.SKAN_SUMMARY]: [
        {
            test: query => {
                return window.is_unified === 'False' || !!query.skan_date_dimension_name;
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_MISSING_SKAN_DATE_TYPE',
        },
        {
            test: query => {
                return !(
                    (query.dimensions.includes('skan_unified_publisher_id') || isCountryFieldSelectedInQuery(query)) &&
                    (anyMetricSelectedCheck(query, ['modeled_skan_custom_events']) ||
                        anyMetricSelectedCheck(query, ['skan_modeled_cohort_metrics']) ||
                        query.metrics.includes('modeled_skan_revenue') ||
                        query.metrics.includes('modeled_skan_roi'))
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_MODELED_SKAN_METRICS',
        },
        {
            test: query => {
                const isModeledSkanCustomEventsSelected = anyMetricSelectedCheck(query, ['modeled_skan_custom_events']);
                const isCohortPeriodSelected = anyMetricSelectedCheck(query, ['cohort_periods']);

                return (
                    !isModeledSkanCustomEventsSelected || (isModeledSkanCustomEventsSelected && isCohortPeriodSelected)
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_PERIOD_FOR_SKAN_COHORT',
        },
    ],
    [REPORT_MODE.SKAN]: [
        {
            test: query => {
                return window.is_unified === 'False' || !!query.skan_date_dimension_name;
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_MISSING_SKAN_DATE_TYPE',
        },
        {
            test: query => {
                return !(
                    (query.dimensions.includes('skan_publisher_id') || isCountryFieldSelectedInQuery(query)) &&
                    query.metrics.includes('modeled_conversion_value_count')
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_MODELED_SKAN_METRICS_RAW_REPORT_TOO_GRANULAR',
        },
        {
            test: query => {
                return (
                    query.dimensions.includes('skan_unified_conversion_value') ||
                    !query.metrics.includes('modeled_conversion_value_count')
                );
            },
            error: 'STATIC.PAGES.REPORTS.VALIDATION_ERROR_MODELED_SKAN_METRICS_RAW_REPORT_NO_CONVERSION_VALUE',
        },
    ],
};

const validateQuery = (query, restrictions) => {
    let mode = REPORT_MODE.REGULAR;

    if (query.compare) {
        mode = REPORT_MODE.COMPARE;
    } else if (query.is_goals) {
        mode = REPORT_MODE.GOALS;
    } else if (query.is_admon_report) {
        mode = REPORT_MODE.ADMON;
    } else if (query.is_skan_summary) {
        mode = REPORT_MODE.SKAN_SUMMARY;
    } else if (query.is_unified_report) {
        mode = REPORT_MODE.UNIFIED_REPORT;
    } else if (query.is_skan) {
        mode = REPORT_MODE.SKAN;
    }

    const validations = validationsMap[REPORT_MODE.ALL].concat(validationsMap[mode]);

    for (const validation of validations) {
        const result = validation.test(query, restrictions);

        if (!result) {
            trackMixpanelEvent(query.query_type, 'Query Validation Error', {
                error_type: validation.error,
                query_mode: mode,
            });

            return {
                result: false,
                message: validation.error,
            };
        }
    }

    return {
        result: true,
    };
};

const reportRegexPatternMatcher = actionName => {
    return new RegExp(`${actionName.replace('{reportType}', '(.*)')}$`);
};

export const mapToCreativesFilters = reportsFilters => {
    return reportsFilters.map(({ dimension, operator, values }) => {
        return {
            selected_filter: { name: dimension },
            selected_operator: { name: operator, display_name: operator === 'in' ? 'Include' : 'Exclude' },
            selected_filter_values: values.map(value => {
                return { name: value, display_name: value };
            }),
        };
    });
};

/**
 * Return a new query based on the drilldown row data (@param nodeData) and the new drilldown selected dimension:
 * The new query consist of:
 *      filters: combine the report selected filters and add the current nodeData values as filters.
 *               in case a selected field is not filterable, the field will be added as "post process filter".
 *      dimensions: combine the report selected dimensions and add the new @param dimensionField.
 * in the drilldown dropdown
 * @param reportSelectedDimensions    an array of the current report selected dimensions
 * @param availableFilters            an array of dimensions that are filterable in BE
 * @param selectedFilters             an array representing
 * @param nodeData                    an object represents report grid row data.
 * @param dimensionField              the selected dimension in the drilldown dropdown
 * @returns serializedReportQuery
 */
export const buildDrilldownRedirectQuery = (
    reportSelectedDimensions,
    availableFilters,
    selectedFilters,
    nodeData,
    dimensionField
) => {
    const { href } = window.location;
    const search = href.substring(href.indexOf('?query'), href.length);
    const deserializedReportQuery = deserializeReportQuery(search);

    reportSelectedDimensions.forEach(dimension => {
        const isFilterable = !!availableFilters.find(availableFilter => availableFilter.name === dimension);
        let existFilterDimension = selectedFilters.find(filter => {
            return filter.field === dimension && filter.operator === 'in';
        });
        const filterToEdit = deserializedReportQuery.filters.find(filter => {
            return filter.dimension === dimension && filter.operator === 'in';
        });
        if (filterToEdit === undefined) {
            existFilterDimension = undefined;
        }

        if (existFilterDimension) {
            if (!existFilterDimension.tags.find(tag => tag === nodeData[dimension]) && filterToEdit !== undefined) {
                filterToEdit.values.push(nodeData[dimension]);
            }
        } else if (isFilterable) {
            deserializedReportQuery.filters = deserializedReportQuery.filters || [];
            deserializedReportQuery.filters.push({
                dimension,
                operator: 'in',
                values: [nodeData[dimension]],
            });
        } else {
            deserializedReportQuery.post_process_filters_ui = deserializedReportQuery.post_process_filters_ui || {};
            deserializedReportQuery.post_process_filters_ui[dimension] = {
                filter: nodeData[dimension],
                type: 'contains',
            };
        }
    });

    deserializedReportQuery.dimensions = deserializedReportQuery.dimensions || [];

    if (dimensionField.path === '/creatives') {
        deserializedReportQuery.dimensions = deserializedReportQuery.dimensions || [];
        deserializedReportQuery.dimensions.push(
            'creative_cluster',
            'creative_cluster_representative_type',
            'creative_cluster_members'
        );
        deserializedReportQuery.filters = mapToCreativesFilters(deserializedReportQuery.filters);
        deserializedReportQuery.creative_group_by = ['image'];
        deserializedReportQuery.display_alignment = true;
        deserializedReportQuery.api_version = 1;
        deserializedReportQuery.displayNonGraphical = true;
    } else if (dimensionField.values) {
        deserializedReportQuery[dimensionField.type].push(...dimensionField.values);
    } else {
        deserializedReportQuery[dimensionField.type] =
            dimensionField.type === 'time_breakdown'
                ? [dimensionField.name]
                : deserializedReportQuery[dimensionField.type].concat(dimensionField.name);
    }

    return serializeReportQuery(deserializedReportQuery);
};

export const getDrilldownDimensions = metadata => {
    return [
        {
            name: 'creatives',
            display_name: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ITEM_CREATIVES',
            path: '/creatives',
            type: 'dimensions',
        },
        {
            name: 'day',
            type: 'time_breakdown',
            display_name: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ITEM_DAY',
            standardAnalyticsVisible: true,
        },
        {
            name: 'country_field',
            type: 'dimensions',
            display_name: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ITEM_COUNTRY',
            standardAnalyticsVisible: true,
        },
        {
            name: 'os',
            type: 'dimensions',
            display_name: 'STATIC.OS',
            standardAnalyticsVisible: true,
        },
        {
            name: 'campaign',
            display_name: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ITEM_CAMPAIGN',
            values: ['unified_campaign_id', 'unified_campaign_name'],
            type: 'dimensions',
            standardAnalyticsVisible: true,
        },
        {
            name: 'sub_campaign',
            display_name: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ITEM_SUB_CAMPAIGN',
            values: ['sub_campaign_id', 'sub_campaign_name'],
            type: 'dimensions',
            standardAnalyticsVisible: true,
        },
        {
            name: 'publisher',
            display_name: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ITEM_PUBLISHER',
            values: ['publisher_id', 'publisher_site_id', 'publisher_name'],
            type: 'dimensions',
            disabled: !metadata.app || !metadata.source,
            tooltipMsg: 'STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_DISABLED_SELECTION',
        },
    ];
};

export const transparencyReportTypes = [reportTypes.mainReport, reportTypes.crossDevice];
export const fraudReportTypes = [reportTypes.fraudProtected, reportTypes.fraudRejected, reportTypes.fraudSuspicious];

export {
    expandMetadata,
    expandData,
    translateQueryToStore,
    validateQuery,
    expandRow,
    reportRegexPatternMatcher,
    defaultLastFilter,
    DEFAULT_APP_FILTER,
    DEFAULT_SOURCE_FILTER,
    DEFAULT_EMPTY_FILTER,
    DEFAULT_FILTER_NAMES,
    DEFAULT_ADMON_SOURCE_FILTER,
    defaultFilters,
    isObject,
};

export const DIMENSION_FIELD_NAMES = {
    APP: 'app',
    SOURCE: 'source',
    UAN: 'useradnetwork',
    OS: 'os',
    PLATFORM: 'platform',
    COUNTRY: 'country',
    ORIGINAL_CURRENCY: 'adn_original_currency',
    CAMPAIGN_NAME: 'unified_campaign_name',
    CAMPAIGN_ID: 'unified_campaign_id',
    SUB_CAMPAIGN_NAME: 'sub_campaign_name',
    SUB_CAMPAIGN_ID: 'sub_campaign_id',
    PUBLISHER_ID: 'publisher_id',
    PUBLISHER_SITE_ID: 'publisher_site_id',
    PUBLISHER_SITE_NAME: 'publisher_site_name',
    KEYWORD: 'keyword',
    ACCOUNT_ID: 'adn_account_id',
    ACCOUNT_NAME: 'adn_account_name',
};

export const TIME_BREAKDOWN_FIELD_NAMES = {
    ALL: 'all',
};

export const PERIOD_FIELD_NAMES = {
    PERIOD_7_DAYS: '7d',
};

export const DEFAULT_START_DATE = moment()
    .subtract(6, 'days')
    .format('YYYY-MM-DD');
export const DEFAULT_END_DATE = moment().format('YYYY-MM-DD');

export const DISABLE_REPORT_STATE = {
    NO_DATA_FOR_ORG: 'NO_DATA_FOR_ORG',
    NO_UANS_FOR_ORG: 'NO_UANS_FOR_ORG',
};

/**
 * Interpolated record - a record with one of INTERPOLATION_INDICATION_FILED_NAMES with a truthy value.
 * for example: a record that contains indication___publisher_estimated = true is a publisher interpolated record
 * the indication___ prefix means it shouldn't be shown and will be used only for indication
 *
 * @param nodeData {object} - an object represents report grid row data.
 * @param colId {string}
 * @returns {boolean}
 */
export const isInterpolatedRecord = (nodeData = {}, colId) => {
    let isInterpolated = false;

    INTERPOLATION_INDICATION_FILED_NAMES.forEach(interpolationField => {
        const interpolationRelevantColumns = INTERPOLATION_RELEVANT_COLUMNS[interpolationField];

        if (
            INTERPOLATION_AFFECTED_COLUMNS.includes(colId) &&
            nodeData[interpolationField] &&
            nodeData[interpolationField].toLowerCase() === 'true' &&
            !!Object.keys(nodeData).find(key => interpolationRelevantColumns.indexOf(key) >= 0)
        ) {
            isInterpolated = true;
        }
    });

    return isInterpolated;
};

export const getCellTooltipInfo = (cellValue, nodeData = {}, colId) => {
    let cellTooltipElement = '';
    const isInterpolated = isInterpolatedRecord(nodeData, colId);

    if (isInterpolated) {
        cellTooltipElement = `Estimated data - click <a href="https://support.singular.net/hc/en-us/articles/360051952171" target="_blank">here</a> for more details`;
    } else if (cellValue === 'N/A (Custom Dimension Range)') {
        cellTooltipElement = `This custom dimension is currently not supported for the entire date 
                                            range of your query. See <a href="/#/react/custom-dimensions" 
                                            target="_blank">Custom Dimensions</a> for more details.`;
    }

    return { cellTooltipElement, isInterpolatedRecord: isInterpolated };
};

export const isAdmonTransparencyCell = (node, column) => {
    let retVal = false;
    const nodeDataSources = node.data && node.data.sources;

    if (nodeDataSources) {
        retVal =
            nodeDataSources.column_source[column.colId] &&
            nodeDataSources.uans.find(uan => uan.is_admon && uan[column.colId] && uan[column.colId].value);
    }
    return retVal;
};

/**
 * it's here instead of translation cause translation file is loaded a bit after the table causing a bug
 * @param {string} sourceType  the original source type from BE
 * @returns {string} parsed sourceType
 */
export const getTransparencySourceTypeText = sourceType => {
    return sourceType === ADMON_DATA_AD_REVENUE_SOURCE ? 'REV.SOURCE' : sourceType.toUpperCase();
};

export const shouldHideEditButton = selected => {
    return (!selected.reportData || !selected.reportData.rowData) && !selected.showSpinner;
};

export const showFieldsAvailabilityForField = (
    showFieldsAvailabilityFeature,
    reportType,
    actualType,
    visible,
    name
) => {
    return (
        (showFieldsAvailabilityFeature &&
            [reportTypes.mainReport, reportTypes.pivot].includes(reportType) &&
            actualType === 'dimensions' &&
            visible &&
            !REPORTS_FIELDS_BLACKLIST.includes(name)) ||
        (![reportTypes.mainReport, reportTypes.pivot].includes(reportType) &&
            NON_REPORTS_FIELDS_WHITELIST.includes(name))
    );
};

export const setReportConfig = (basicConfig, type) => {
    // set topButtons actions
    const topButtonsIndex = basicConfig.header.rightComponents.findIndex(
        component => component.id === `topButtons_${type}`
    );
    basicConfig.header.rightComponents[topButtonsIndex].actions = [
        {
            actionName: 'onClick',
            action: id => {
                if (id === FIELDS_AVAILABILITY_BUTTON_ID) {
                    return setActionType(toggleFieldsAvailability('', true), type);
                } else {
                    return { type: '' };
                }
            },
        },
    ];
};

export const unenrichedButton = type => {
    return {
        id: 'unEnriched',
        componentClass: 'ReportUnenrichedButton',
        classType: 'container',
        props: {
            reportType: type,
        },
    };
};

export const defaultReportQueryParams = {
    is_dashboard: false,
    display_unenriched: false,
    display_admon_alignment: false,
    is_default: true,
    chart: false,
    is_goals: false,
    start_date: '',
    end_date: '',
    start_date_2: '',
    end_date_2: '',
    compare: false,
    dimensions: [],
    metrics: [],
    goals_metrics: [],
    goals_forecast_metrics: [],
    goal: {},
    discrepancy_metrics: [],
    cohort_metrics: [],
    skan_modeled_cohort_metrics: [],
    cohort_periods: ['14d'],
    skan_modeled_cohort_periods: ['7d'],
    unified_cohort_periods: [],
    filters: [],
    time_breakdown: ['day'],
    permutation_keys: [],
    valid_key_list_for_charts: [],
    valid_key_name_list_for_charts: [],
};

const createDateRangeText = (startDate, endDate) => {
    const daysDiff = endDate.diff(startDate, 'days') + 1;

    return `${startDate.format(DATE_TEXT_FORMAT)} - ${endDate.format(DATE_TEXT_FORMAT)} | (${daysDiff} day${
        daysDiff > 1 ? 's' : ''
    })`;
};

export const getDatesText = (dates, isCompare) => {
    const startDate = moment(dates.start_date);
    const endDate = moment(dates.end_date);

    const formattedDates = createDateRangeText(startDate, endDate);

    if (!isCompare) {
        return formattedDates;
    }

    const startDate2 = moment(dates.start_date_2);
    const endDate2 = moment(dates.end_date_2);

    const formattedDates2 = createDateRangeText(startDate2, endDate2);

    return `${formattedDates} <VS> ${formattedDates2}`;
};

export const listContains = (list, listElement) => {
    return list.some(arr => listEqual(arr, listElement));
};
