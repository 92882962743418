import * as Yup from 'yup';
import Info, { INFO_VALIDATION_SCHEMA } from './Info';
import ActivationRules, { ACTIVATION_RULES_VALIDATION_SCHEMA } from './ActivationRules';
import Interaction from './Interaction';
import CssEditor, { CSS_VALIDATION_SCHEMA } from './CssEditor';
import Content, { CONTENT_VALIDATION_SCHEMA } from './Content';

export const Sections = {
    INFO: 'INFO',
    ACTIVATION_RULES: 'ACTIVATION_RULES',
    INTERACTION: 'INTERACTION',
    CONTENT: 'CONTENT',
    CSS: 'CSS_EDITOR',
};

export const SectionToElement = {
    [Sections.INFO]: Info,
    [Sections.ACTIVATION_RULES]: ActivationRules,
    [Sections.INTERACTION]: Interaction,
    [Sections.CONTENT]: Content,
    [Sections.CSS]: CssEditor,
};

export const PageToSections = {
    INFO: [Sections.INFO, Sections.ACTIVATION_RULES, Sections.INTERACTION],
    DESIGN: [Sections.CONTENT, Sections.CSS],
    // SCHEDULE: [],
};

export const VALIDATION_SCHEMA = Yup.object().shape({
    ...INFO_VALIDATION_SCHEMA,
    ...CONTENT_VALIDATION_SCHEMA,
    ...ACTIVATION_RULES_VALIDATION_SCHEMA,
    ...CSS_VALIDATION_SCHEMA,
});
