import React from 'react';
import DataSourcesPage from 'dataSources/components/DataSourcesPage';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_DATA_TYPES } from '../utils';
import { getAdNetworks, getDataSourcesPage } from '../selector';
import { closeShelf, deleteUan, export2Phase, loginVerificationFinished, openShelf } from '../actions';
import DataDestinationsShelfEnv from './DataDestinationShelfEnv';
import { ETL_EVENT_PREFIX } from '../../utils/general';

export default function DataDestinationsPage() {
    const dispatch = useDispatch();
    const props = useSelector(state => getDataSourcesPage(state));
    const adnetworks = useSelector(state => getAdNetworks(state));
    return (
        <DataSourcesPage
            {...props}
            adnetworks={adnetworks}
            redirectPath="react/data-destinations"
            archivedToggleEnabled={false}
            translationPageKey={PAGE_DATA_TYPES.DATA_DESTINATIONS}
            emptyStateDataConnectors={[172084, 84257, 85181, 85180, 84776, 86536, 85311]}
            mixpanelPrefix={ETL_EVENT_PREFIX}
            export2Phase={uanId => dispatch(export2Phase(uanId))}
            onValidationFinished={uanId => dispatch(loginVerificationFinished(uanId))}
            openShelf={(adnetDisplayName, uanId) => dispatch(openShelf(adnetDisplayName, uanId))}
            onCloseShelf={() => dispatch(closeShelf())}
            onUanDelete={uanId => dispatch(deleteUan(uanId))}
            externalShelfElement={DataDestinationsShelfEnv}
        />
    );
}
