import React from 'react';
import PropTypes from 'prop-types';
import 'react-select/dist/react-select.css';
import XIcon from '../../../resources/svg/icon_remove_row.svg';

import Dropdown from '../../widgets/Dropdown';
import TagInput from '../../widgets/TagInput';

const Filters = (props) => {
    const { css } = props;
    const availableFilterDimensions = [
        { name: 'App', display_name: 'App' },
        { name: 'Campaign', display_name: 'Campaign' },
        { name: 'Network', display_name: 'Network' },
    ];
    const usedFilterDimensions = props.filters.map((filter) => filter.target);

    return (
        <div>
            <div className={css.FiltersList}>
                {props.filters.map((filter, i) => (
                    <div className={css.FilterBlock} key={`filter_${i}_${filter.target}`}>
                        <div className={`${css.FilterTarget} ${css.FilterItem}`}>
                            <Dropdown
                                items={availableFilterDimensions.filter(
                                    (filterDimension) => usedFilterDimensions.indexOf(filterDimension.name) === -1
                                )}
                                selected={{
                                    name: filter.target,
                                    display_name:
                                        filter.target === 'default'
                                            ? 'STATIC.PAGES.CUSTOM_DIMENSIONS.PLACEHOLDERS.FILTER_DIMENSION'
                                            : filter.target,
                                }}
                                onSelection={(target) => {
                                    props.changeFilter(i, 'target', target.name);
                                }}
                            />
                        </div>
                        <div className={`${css.FilterType} ${css.FilterItem}`}>
                            <Dropdown
                                items={[
                                    { name: 'Includes', display_name: 'Includes' },
                                    { name: 'Excludes', display_name: 'Excludes' },
                                ]}
                                selected={{ name: filter.type, display_name: filter.type }}
                                onSelection={(type) => {
                                    props.changeFilter(i, 'type', type.name);
                                }}
                            />
                        </div>
                        <div className={`${css.FilterTags} ${css.FilterItem}`}>
                            <TagInput
                                className={`${css.FilterTags} ${css.FilterItem}`}
                                suggestions={
                                    props.suggestions[filter.target.toLowerCase()]
                                        ? props.suggestions[filter.target.toLowerCase()].map((field) => field.label)
                                        : []
                                }
                                placeholder={
                                    availableFilterDimensions.map((d) => d.name).indexOf(filter.target) > -1
                                        ? `Enter ${filter.target.toLowerCase()} name`
                                        : 'Please select a dimension'
                                }
                                disabled={availableFilterDimensions.map((d) => d.name).indexOf(filter.target) === -1}
                                tags={filter.tags.map((tag) => ({ text: tag, id: tag }))}
                                onTagAdded={(tag) => {
                                    props.addFilterTag(i, tag);
                                }}
                                onTagRemoved={(tag) => {
                                    props.removeFilterTag(i, tag);
                                }}
                                containerStyle={{
                                    width: '100%',
                                }}
                            />
                        </div>
                        <XIcon
                            className={css.RemoveFilter}
                            style={{ fill: '#999999' }}
                            onClick={() => {
                                if (props.filters.length === 1 && i === 0) {
                                    if (availableFilterDimensions.map((d) => d.name).indexOf(filter.target) > -1) {
                                        props.clearFilterTags(i, filter.tags);
                                    }
                                } else {
                                    props.removeFilter(i);
                                }
                            }}
                        />
                    </div>
                ))}
            </div>
            <div className={css.AddFilter} onClick={props.addFilter}>
                <div className={css.AddFilterIcon}>
                    <div
                        style={{
                            backgroundColor: '#00A8FF',
                            borderRadius: '50%',
                            paddingLeft: '4px',
                            width: '24px',
                            height: '24px',
                            margin: '0',
                            color: '#fff',
                            fontSize: '24px',
                            fontWeight: '300',
                            lineHeight: '22px',
                            userSelect: 'none',
                        }}
                    >
                        +
                    </div>
                </div>
                Add Filter
            </div>
        </div>
    );
};

Filters.propTypes = {
    addFilter: PropTypes.func,
    css: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.arrayOf(PropTypes.any),
    suggestions: PropTypes.objectOf(PropTypes.any),
};

Filters.defaultProps = {
    addFilter: () => {},
    css: {},
    filters: [],
    suggestions: {},
};

export default Filters;
