import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Translate } from 'react-localize-redux';
import css from './SwitchField.css';

class SwitchField extends React.PureComponent {
    render() {
        const { label, checked, containerClass, onChange, disabled, type } = this.props;
        let sizeValues = {
            height: 21,
            width: 40,
            handleDiameter: 15,
        };
        if (type === 'small') {
            sizeValues = {
                height: 15,
                width: 28,
                handleDiameter: 11,
            };
        }
        return (
            <div className={containerClass}>
                <Switch
                    class
                    checked={checked}
                    onChange={onChange}
                    className={css.container}
                    onColor="#3a92e5"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    disabled={disabled}
                    {...sizeValues}
                />
                <div
                    onClick={() => {
                        onChange(!checked);
                    }}
                    className={css.label}
                >
                    <Translate id={label} />
                </div>
            </div>
        );
    }
}

SwitchField.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    containerClass: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['large', 'small']),
};

SwitchField.defaultProps = {
    label: '',
    containerClass: '',
    disabled: false,
    type: 'large',
};

export default SwitchField;
