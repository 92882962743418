import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Info from '../../../resources/svg/info.svg';
import Warning from '../../../resources/svg/icons/warning.svg';
import Label from '../Label';
import css from './Alert.css';

export const ALERT_TYPES = {
    INFO: 'info',
    WARNING: 'warning',
};

const iconsPerType = {
    info: <Info />,
    warning: <Warning />,
};

const Alert = ({ type, text, className, textClassName }) => {
    return (
        <div className={classNames(css.alertContainer, { [css.alertWarning]: type === 'warning' }, className)}>
            <div className={css.alertIcon}>{iconsPerType[type]}</div>
            <Label text={text} className={classNames(css.alertText, textClassName)} />
        </div>
    );
};

Alert.propTypes = {
    type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    textClassName: PropTypes.string,
};

Alert.defaultProps = {
    type: ALERT_TYPES.INFO,
    className: '',
    textClassName: '',
};
export default Alert;
