import API from '../services/api';

let instance = null;
const api = new API();
const ALERTS = 'api/v2/alerts';
const ORG_USERS = 'api/v2/alerts/org_users';
const SET_SYTSTEM_ALERT = 'api/v2/alerts/system_alerts';
const CUSTOM_ALERT = 'api/v2/alerts/custom_alerts';
const REPORT_TYPES = 'api/v2/alerts/report_types';
const METADATA = 'api/v2/alerts/metadata';
const URL_UAN_ALERTS = 'api/v2/alerts/data_connector_alerts';
const CAN_UAN_ALERTS = 'api/v2/alerts/can_data_connector_alerts';
const ALL_ORG_UAN_ALERTS = 'api/v2/alerts/all_org_data_connector_alerts';

export default class AlertsService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getReportTypes() {
        return api.getJson(REPORT_TYPES, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    getAlerts() {
        return api.getJson(ALERTS, {
            method: 'GET',
            credentials: 'include',
            params: {},
            disableBrowserCache: true,
        });
    }

    getUsers() {
        return api.getJson(ORG_USERS, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    getAlertsMetadata() {
        return api.getJson(METADATA, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    deleteAlert(params) {
        return api.getJson(CUSTOM_ALERT, {
            method: 'DELETE',
            params,
            disableBrowserCache: true,
        });
    }

    saveSystemAlert(params) {
        return api.basicRequest(SET_SYTSTEM_ALERT, 'POST', params, true);
    }

    saveCustomAlert(params) {
        return api.basicRequest(CUSTOM_ALERT, 'POST', params, true);
    }

    getDataConnectorAlerts(uanId) {
        return api.getJson(URL_UAN_ALERTS, {
            method: 'GET',
            params: { uanId },
            credentials: 'include',
        });
    }

    getCanDataConnectorAlerts() {
        return api.getJson(CAN_UAN_ALERTS, {
            method: 'GET',
            credentials: 'include',
        });
    }

    getAllOrgDataConnectorAlerts() {
        return api.getJson(ALL_ORG_UAN_ALERTS, {
            method: 'GET',
            credentials: 'include',
        });
    }

    saveDataConnectorAlerts(params) {
        return api.getJson(URL_UAN_ALERTS, {
            method: 'POST',
            params,
            credentials: 'include',
        });
    }
}
