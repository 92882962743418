import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Tooltip from 'components/widgets/Tooltip';
import { Label, SingularButton, TextField, RadioButtonsGroup, WarningMessage } from '../../../../components/widgets';
import css from './AdvancedPostbackSettings.css';
import { organizationOptionsShape, partnerShape, templateShape } from '../../types';

function AdvancedPostbackSettings({
    onAccept,
    onReject,
    eventName,
    postback,
    partner,
    isReadonly,
    orgOptions,
    template,
    translate,
    flexiblePostbackReadOnly,
}) {
    const [campaignRegex, setCampaignRegex] = useState(postback.campaignRegex);
    const [customByUser, setCustomByUser] = useState(postback.customByUser || false);
    const [postbackUri, setPostbackUri] = useState(postback.postbackUri);

    const supportCampaignRegex =
        orgOptions.trackerCampaignNameFilterSupported || orgOptions.advancedPostbackEditingSupported;
    const supportFlexiblePostback = orgOptions.flexiblePostbackSupported || orgOptions.advancedPostbackEditingSupported;

    return (
        <div className={css.container}>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.TITLE" data={{ eventName }} />
            </div>
            {supportCampaignRegex && (
                <TextField
                    placeholder="(none)"
                    value={postback.sendAll ? '' : campaignRegex || ''}
                    onChange={setCampaignRegex}
                    label="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.TRACKER_CAMPAIGN_NAME_FILTER"
                    labelTooltip="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.TRACKER_CAMPAIGN_NAME_FILTER_TOOLTIP"
                    labelClass={css.postbackField}
                    labelTextClass={css.text}
                    inputClassName={css.input}
                    disabled={postback.sendAll || isReadonly}
                />
            )}
            {supportFlexiblePostback && (
                <div className={css.postbackField}>
                    <div className={css.text}>
                        <Label
                            text="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.POSTBACK_URL"
                            type="h3"
                            tooltip="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.POSTBACK_URL_TOOLTIP"
                            className={css.postbackUrl}
                        />
                        <RadioButtonsGroup
                            disabled={flexiblePostbackReadOnly}
                            itemsClassName={css.radioButton}
                            radioItems={[
                                {
                                    label: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.DEFAULT_POSTBACK',
                                    tooltip: translate(
                                        'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.DEFAULT_POSTBACK_TOOLTIP',
                                        { partnerName: partner.displayName }
                                    ),
                                    checked: !customByUser,
                                    id: 'default-postback',
                                },
                                {
                                    label: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.MANUAL_POSTBACK',
                                    tooltip:
                                        'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.MANUAL_POSTBACK_TOOLTIP',
                                    checked: customByUser,
                                    id: 'manual-postback',
                                },
                            ]}
                            onChange={() => setCustomByUser(!customByUser)}
                        />
                    </div>
                    <div>
                        <Tooltip
                            interactive
                            titleTranslationKey="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.MANUAL_POSTBACK_HOVER"
                            disabled={!flexiblePostbackReadOnly}
                        >
                            <TextField
                                type="textarea"
                                value={!customByUser ? template.templateUri : postbackUri || template.templateUri}
                                containerClass={css.input}
                                inputClassName={css.postback}
                                disabled={flexiblePostbackReadOnly || !customByUser}
                                onChange={setPostbackUri}
                            />
                        </Tooltip>
                        <WarningMessage
                            className={css.note}
                            show={customByUser}
                            showIcon={false}
                            showTypeIcon
                            type="info"
                            message="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.POSTBACK_POP_UP.MANUAL_POSTBACK_NOTE"
                        />
                    </div>
                </div>
            )}
            {!flexiblePostbackReadOnly && (
                <div className={css.actionButtons}>
                    <SingularButton type="flat" text="STATIC.BUTTONS.CANCEL" onClick={onReject} />
                    <SingularButton
                        text="STATIC.BUTTONS.DONE"
                        onClick={() => onAccept(campaignRegex, customByUser, postbackUri)}
                        className={css.acceptButton}
                    />
                </div>
            )}
        </div>
    );
}

AdvancedPostbackSettings.propTypes = {
    onReject: PropTypes.func,
    onAccept: PropTypes.func,
    eventName: PropTypes.string,
    isReadonly: PropTypes.bool,
    postback: PropTypes.objectOf(PropTypes.any).isRequired,
    partner: partnerShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    template: templateShape.isRequired,
    translate: PropTypes.func.isRequired,
    flexiblePostbackReadOnly: PropTypes.bool,
};

AdvancedPostbackSettings.defaultProps = {
    onReject: () => {},
    onAccept: () => {},
    eventName: null,
    isReadonly: false,
    flexiblePostbackReadOnly: false,
};

export default withLocalize(AdvancedPostbackSettings);
