import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import { CODE_BLOCK_SNIPPETS_LANGUAGES } from './consts';
import css from './codeBlockHeader.css';
import ShowEyeIcon from '../../../resources/svg/icons/show_eye.svg';
import DarkModeIcon from '../../../resources/svg/icons/dark_mode.svg';
import CodeBlockTabs from './CodeBlockTabs';
import Icon, { IconSizes, IconVariants } from '../../foundations/Icon';

const CodeBlockHeader = ({
    codeLanguage,
    togglePlaceholders,
    toggleTheme,
    handleToggleTheme,
    handleTogglePlaceholders,
    handleCopyToClipboard,
    ShowHideButtonVisibility,
    ShowHideButtonTitle,
    placeholdersExist,
    customTitle,
    languageItems,
    handleOnLanguageChange,
    translate,
}) => {
    return (
        <div className={css.container}>
            <div className={classNames([css.codingLang, { [css.codingLangPadding]: !languageItems }])}>
                {languageItems?.length > 0 ? (
                    <CodeBlockTabs
                        key={languageItems?.[0]?.codeLanguage}
                        languageItems={languageItems}
                        darkTheme={toggleTheme}
                        handleOnLanguageChange={handleOnLanguageChange}
                        selectedLanguage={codeLanguage}
                    />
                ) : (
                    customTitle || codeLanguage || ''
                )}
            </div>
            <div className={css.rightSide}>
                <div className={css.item} onClick={handleToggleTheme}>
                    <DarkModeIcon className={css.icon} />
                    <span>
                        {toggleTheme
                            ? translate('STATIC.CODE_BLOCK.DARK_THEME')
                            : translate('STATIC.CODE_BLOCK.LIGHT_THEME')}
                    </span>
                </div>
                {ShowHideButtonVisibility && placeholdersExist && (
                    <div className={css.item} onClick={handleTogglePlaceholders}>
                        <ShowEyeIcon className={css.icon} />
                        <span>
                            {togglePlaceholders
                                ? translate('STATIC.CODE_BLOCK.SHOW_BUTTON')
                                : translate('STATIC.CODE_BLOCK.HIDE_BUTTON')}
                            {ShowHideButtonTitle}
                        </span>
                    </div>
                )}
                <div className={css.item} onClick={handleCopyToClipboard}>
                    <Icon name="copy" className={css.icon} size={IconSizes.X_SMALL} variant={IconVariants.ACTIVE} />
                    <span>{translate('STATIC.CODE_BLOCK.COPY_CODE_BUTTON')}</span>
                </div>
            </div>
        </div>
    );
};

export default withLocalize(CodeBlockHeader);

CodeBlockHeader.propTypes = {
    ShowHideButtonTitle: PropTypes.string,
    ShowHideButtonVisibility: PropTypes.bool,
    togglePlaceholders: PropTypes.bool,
    codeLanguage: PropTypes.oneOf(Object.values(CODE_BLOCK_SNIPPETS_LANGUAGES).map(i => i)).isRequired,
    toggleTheme: PropTypes.bool,
    handleToggleTheme: PropTypes.func,
    handleTogglePlaceholders: PropTypes.func,
    handleCopyToClipboard: PropTypes.func,
    placeholdersExist: PropTypes.bool,
    customTitle: PropTypes.string,
    languageItems: PropTypes.arrayOf(
        PropTypes.shape({
            codeLanguage: PropTypes.oneOf(Object.values(CODE_BLOCK_SNIPPETS_LANGUAGES).map(i => i)),
            displayTabTitle: PropTypes.string,
        })
    ),
    handleOnLanguageChange: PropTypes.func,
    translate: PropTypes.func,
};

CodeBlockHeader.defaultProps = {
    ShowHideButtonTitle: '',
    ShowHideButtonVisibility: true,
    handleToggleTheme: () => {},
    handleTogglePlaceholders: () => {},
    handleCopyToClipboard: () => {},
    togglePlaceholders: false,
    toggleTheme: true,
    placeholdersExist: false,
    customTitle: '',
    languageItems: undefined,
    handleOnLanguageChange: undefined,
    translate: () => {},
};
