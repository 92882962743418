import { connect } from 'react-redux';
import * as actions from 'customDimensions/actions';
import ReorderValuesDialog from '../components/reorderValuesDialog/ReorderValuesDialog';

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = dispatch => {
    return {
        onValueReordered: (id, values) => {
            dispatch(actions.onValuesReordered(id, values));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReorderValuesDialog);
