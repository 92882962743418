import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader, PageWrapper } from '../components/partials';
import { Label, Spinner } from '../components/widgets';
import NetworkGrid from './components/NetworkGrid';
import EditShelf from './components/EditShelf';
import css from './styles.pcss';

class AudienceDistributions extends React.Component {
    constructor(props) {
        super(props);
        this.onSearch = this._onSearch.bind(this);
        this.editStart = this._editStart.bind(this);
        this.editCancel = this._editCancel.bind(this);
        this.editConfirm = this._editConfirm.bind(this);
        this.toggleAdAccount = this._toggleAdAccount.bind(this);
        this.manualRun = this._manualRun.bind(this);
    }

    _onSearch(e) {
        this.props.onSearch(e.target.value);
    }

    _editStart(network) {
        this.props.onEditStart(network);
    }

    _editCancel() {
        this.props.onEditCancel(this.props.edit);
    }

    _editConfirm() {
        this.props.onEditConfirm(this.props.edit);
    }

    _toggleAdAccount(account, active) {
        this.props.onToggleAdAccount(this.props.edit.name, account, active);
    }

    _manualRun() {
        if (this.props.adminMode && this.props.edit.distribution.id) {
            if (
                window.confirm(
                    `Are you sure you want to manually run ${this.props.edit.display_name} for ${this.props.companyName}?`
                )
            ) {
                this.props.onManualRun(this.props.edit.distribution.id);
            }
        }
    }

    render() {
        const {
            editError,
            loading,
            networks,
            segments,
            search,
            companyLogo,
            edit,
            saving,
            onEditChange,
            onEditSegmentStart,
            onEditSegmentChange,
            onEditSegmentSave,
            onEditSegmentCancel,
            adminMode,
        } = this.props;
        if (loading) {
            return <Spinner show expanded />;
        }
        return (
            <PageWrapper>
                <PageHeader
                    text="STATIC.PAGE_HEADERS.AUDIENCE_DISTRIBUTIONS"
                    subText={{
                    faqLink: "https://support.singular.net/hc/en-us/articles/360025454492-Audiences-FAQ"
                    }}
                />
                <div className={css.pageDivider} />
                <NetworkGrid networks={networks} companyLogo={companyLogo} editStart={this.editStart} />
                <EditShelf
                    adminMode={adminMode}
                    manualRun={this.manualRun}
                    editCancel={this.editCancel}
                    editConfirm={this.editConfirm}
                    editChange={onEditChange}
                    toggleAdAccount={this.toggleAdAccount}
                    editError={editError}
                    saving={saving}
                    edit={edit}
                    segments={segments}
                    editSegmentStart={onEditSegmentStart}
                    editSegmentChange={onEditSegmentChange}
                    editSegmentSave={onEditSegmentSave}
                    editSegmentCancel={onEditSegmentCancel}
                />
            </PageWrapper>
        );
    }
}

AudienceDistributions.propTypes = {};

AudienceDistributions.defaultProps = {};

export default AudienceDistributions;
