import { SCROLL_IGNORE_LINE, DEFAULT_HEIGHT, LINE_HEIGHT } from './consts';

const formatCodeBlockText = ({ text = '', keys = {}, hideKeys = true } = {}) => {
    const regex = /\{\{(.*?)\}\}/g;
    const fallbackKeyLength = 3;

    return text.replace(regex, (_, key) => {
        const placeholder = keys?.[key] || '';

        if (hideKeys) {
            return '*'.repeat(keys?.[key]?.length || fallbackKeyLength);
        }

        return placeholder;
    });
};

const findLowestNumber = inputString => {
    const numbers = inputString
        .split(/[,-]/)
        .filter(val => val.trim() !== '')
        .map(Number);

    const lowestNumber = numbers?.length ? Math.min(...numbers) : 0;
    return String(lowestNumber);
};

const isInRange = (number, min, max) => {
    return number >= min && number <= max;
};

function formatHighlightsRows(rangesString) {
    if (!rangesString) return '';

    const ranges = rangesString.split(',');

    return ranges
        .map(range => {
            const [min, max] = range.split('-').map(n => Number(n));
            if (Number.isNaN(min)) return '';
            if (!max) return min;

            return Array.from({ length: max - min + 1 }, (_, index) => index + min).join(',');
        })
        .join(',');
}

const calcMaxLineScrollTo = (customHeightInput = '') => {
    const customHeight = String(customHeightInput)?.replace(/\D/g, '') || DEFAULT_HEIGHT;
    const maxLine = Number(customHeight) / LINE_HEIGHT;
    return Math.floor(maxLine) || SCROLL_IGNORE_LINE.MAX;
};

export { formatCodeBlockText, findLowestNumber, isInRange, formatHighlightsRows, calcMaxLineScrollTo };
