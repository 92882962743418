import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './EventsDisplay.css';

const DISABLED = [
    { label: 'Session', value: 'session', disabled: true },
    { label: 'Revenue', value: 'revenue', disabled: true },
];

const EventsDisplay = ({ events }) => {
    const [expanded, setExpanded] = useState(false);
    const allEvents = [...DISABLED, ...events];

    const visibleItems = expanded ? allEvents : allEvents.slice(0, 5);
    const hiddenCount = allEvents.length - 5 > 0 ? allEvents.length - 5 : 0;

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const renderTabs = tabItems => {
        return tabItems.map(item => (
            <div key={item.value} className={`${css.tab} ${item.disabled ? css.disabled : ''}`}>
                {item.label}
            </div>
        ));
    };

    return (
        <div className={css.eventsContainer}>
            <div className={css.tabsContainer}>
                {renderTabs(expanded ? allEvents : visibleItems)}
                {!expanded && hiddenCount > 0 && (
                    <div className={css.expandButton} onClick={toggleExpand}>
                        + {hiddenCount}
                    </div>
                )}
            </div>
        </div>
    );
};

EventsDisplay.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};

EventsDisplay.defaultProps = {
    events: [],
};

export default EventsDisplay;
