import { useEffect, useState } from 'react';
import { useLocalStorageState } from '../../../utils/customHooks';
import { SDK_PLANNING_PAGE_SECTIONS_STATE_LOCAL_STORAGE } from '../consts';
import { useSDKPlanningContext } from '../context/useSDKPlanningContext';

const useSdkPlanningLocalStorage = () => {
    const { selectedTargetPlatform, setSelectedTargetPlatform } = useSDKPlanningContext();
    const [sectionsList, setSectionsList] = useState([]);

    const [localStorageSdkPlanningPage, setLocalStorageSdkPlanningPage] = useLocalStorageState(
        SDK_PLANNING_PAGE_SECTIONS_STATE_LOCAL_STORAGE
    );

    // initialize local storage
    const initialize = flattedSectionsList => {
        setSectionsList(flattedSectionsList);

        let initializeSdkPlanningPosition = false;

        if (!localStorageSdkPlanningPage?.platform) {
            initializeSdkPlanningPosition = { platform: selectedTargetPlatform };
        }

        if (initializeSdkPlanningPosition && !localStorageSdkPlanningPage?.section) {
            initializeSdkPlanningPosition = {
                ...initializeSdkPlanningPosition,
                section: localStorageSdkPlanningPage?.section,
            };
        }

        if (initializeSdkPlanningPosition) {
            setLocalStorageSdkPlanningPage({ ...initializeSdkPlanningPosition });
        }
    };

    // listen to platform update
    const onChangePlatform = platform => {
        setLocalStorageSdkPlanningPage({ platform, section: '' });
    };

    return {
        localStorageSdkPlanningPage,
        setLocalStorageSdkPlanningPage,
        onChangePlatform,
        initialize,
    };
};

export default useSdkPlanningLocalStorage;
