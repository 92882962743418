import React from 'react';
import SingularButton from './SingularButton';

class ReportPresetButton extends React.Component {
    render() {
        return (
            <SingularButton
                type="secondary"
                style={{ marginRight: '10px' }}
                {...this.props}
            />
        )
    }
}

export default ReportPresetButton;
