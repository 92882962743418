import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts, HighchartsChart, ColumnSeries } from 'react-jsx-highcharts';
import { BENCHMARKS_GRAPH_TYPES, yearOverYearPlotOptions } from '../consts';
import {
    parseYearOverYearChartData,
    networkLabelsFormatter,
    calculateGradientColumnEdge,
    getMinYAxis,
    getMaxYAxis,
    getYearOverYearColorByValue,
    seriesFloatValueFormatter,
    syncChartsScrolls,
} from '../utils';
import ChartTooltip from './chartTooltip';
import ChartXAxis from './chartXAxis';
import ChartYAxis from './chartYAxis';
import Chart from './chart';

const YearOverYearChart = ({ chartData }) => {
    const { data, labels, logo_by_label: logoByLabel } = chartData;
    const { data: values, label } = parseYearOverYearChartData(data);
    const min = getMinYAxis(values);
    const max = getMaxYAxis(values);
    const [chartLoaded, setChartLoaded] = useState(false);

    return (
        <HighchartsChart
            plotOptions={yearOverYearPlotOptions}
            callback={() => {
                setTimeout(() => {
                    setChartLoaded(true);
                    syncChartsScrolls(BENCHMARKS_GRAPH_TYPES.YEAR_OVER_YEAR, BENCHMARKS_GRAPH_TYPES.SHARE_OF_VOICE);
                }, 0);
            }}
        >
            <Chart minWidth={values.length * 75} loaded={chartLoaded} marginBottom={100} />
            <ChartTooltip isTextHeader categories={labels} getColorByValue={getYearOverYearColorByValue} />
            <ChartXAxis
                id="year_over_year_x_axis"
                categories={labels}
                labelsFormat={null}
                formatter={networkLabelsFormatter.bind(this, logoByLabel)}
            />
            <ChartYAxis
                id="year_over_year_y_axis"
                min={min}
                max={max}
                plotLines={[
                    {
                        color: '#e1e1e1',
                        width: 1,
                        value: 0,
                        zIndex: 10,
                    },
                ]}
            >
                <ColumnSeries
                    formatter={seriesFloatValueFormatter}
                    key={label}
                    name="Change"
                    data={values.map((y) => {
                        return { y, color: calculateGradientColumnEdge(y, min, max) };
                    })}
                />
            </ChartYAxis>
        </HighchartsChart>
    );
};

YearOverYearChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
};

YearOverYearChart.defaultProps = {
    chartData: {},
};

export default withHighcharts(YearOverYearChart, Highcharts);
