import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './PageWrapper.css';

const PageWrapper = React.forwardRef(
    (
        {
            style,
            children,
            onMouseEnter,
            onMouseLeave,
            className,
            allowDropDownPageScrolling,
            disablePadding,
            dataTestId,
        },
        ref
    ) => {
        return (
            <div className={css.containerWrapper} data-testid={dataTestId}>
                <div
                    className={classNames(css.container, className, {
                        [css.containerWithPadding]: !disablePadding,
                        [css.containerWithDropDownScrolling]: allowDropDownPageScrolling,
                    })}
                    style={style}
                    ref={ref}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {children}
                </div>
            </div>
        );
    }
);

PageWrapper.propTypes = {
    children: PropTypes.any, // eslint-disable-line
    style: PropTypes.objectOf(PropTypes.any),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    className: PropTypes.string,
    allowDropDownPageScrolling: PropTypes.bool,
    disablePadding: PropTypes.bool,
    dataTestId: PropTypes.string,
};

PageWrapper.defaultProps = {
    children: null,
    style: {},
    onMouseEnter: null,
    onMouseLeave: null,
    className: null,
    allowDropDownPageScrolling: false,
    disablePadding: false,
    dataTestId: undefined,
};

export default PageWrapper;
