import DownloadIcon from '../../resources/icons/download.svg';

import { setActionType, dateChangedGlobal, exportTable } from '../../actions/reports';
import { reportDataSelectors } from '../../selectors/reports';
import { getFieldsForType } from '../../selectors/fields';
import { getShowDateSelection, getCreator, getReportName } from '../../selectors/anonymous';
import { getSecondDatePickerRanges, getDaysDiff } from '../../utils/regional';
import { COMPARE_MODE } from '../../components/pages/reports/consts';

// widgets
const Datepicker = 'Datepicker';
const TopButtons = 'TopButtons';
const Label = 'Label';
const WarningMessage = 'WarningMessage';

const type = 'anonymousReport';
const path = '/react/anonymous/:secretId';
const className = 'Anonymous';

export default {
    chart: {
        enabled: true,
        show: false,
        lines: 5,
    },
    table: {
        enabled: true,
        show: false,
    },
    type,
    path,
    className,
    fieldWarnings: {},
    header: {
        text: '',
        leftComponents: [
            {
                id: 'creatorLabel',
                componentClass: Label,
                props: {},
                selector: state => {
                    const creator = getCreator(state);
                    const reportName = getReportName(state);
                    const style = {
                        marginBottom: '5px',
                        display: 'block',
                    };
                    if (!creator) {
                        style.display = 'none';
                    }
                    return {
                        style,
                        text: `Report ${reportName} | Created by: ${creator}`,
                    };
                },
                actions: [],
            },
            {
                id: 'date',
                componentClass: Datepicker,
                props: {
                    id: 'headerDatepicker',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const showDatesSelection = getShowDateSelection(state);
                    const containerStyle = {
                        marginRight: '20px',
                        verticalAlign: 'bottom',
                    };
                    if (selected.selectedTab !== 'regular' || !showDatesSelection) {
                        containerStyle.display = 'none';
                    }
                    const startDate = selected.reportDates.start_date;
                    const endDate = selected.reportDates.end_date;
                    return {
                        startDate,
                        endDate,
                        containerStyle,
                        title:
                            selected.mode === COMPARE_MODE
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(startDate, endDate) },
                                  ]
                                : null,
                    };
                },
                actions: [
                    {
                        actionName: 'onApply',
                        action: data => {
                            return setActionType(dateChangedGlobal(type, data), type);
                        },
                    },
                ],
            },
            {
                id: 'vsLabel',
                componentClass: Label,
                props: {
                    text: 'vs',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const showDatesSelection = getShowDateSelection(state);
                    const style = {
                        marginRight: '20px',
                        marginBottom: '5px',
                    };
                    if (selected.selectedTab !== 'regular' || selected.mode !== COMPARE_MODE || !showDatesSelection) {
                        style.display = 'none';
                    }
                    return {
                        style,
                    };
                },
                actions: [],
            },
            {
                id: 'date2',
                componentClass: Datepicker,
                props: {
                    id: 'headerDatepicker2',
                    dynamic: true,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const showDatesSelection = getShowDateSelection(state);
                    const containerStyle = {
                        marginRight: '20px',
                        marginTop: '40px',
                        verticalAlign: 'bottom',
                    };
                    if (selected.selectedTab !== 'regular' || selected.mode !== COMPARE_MODE || !showDatesSelection) {
                        containerStyle.display = 'none';
                    }
                    const startDate = selected.reportDates.start_date_2;
                    const endDate = selected.reportDates.end_date_2;
                    let ranges = null;
                    if (selected.reportDates.start_date && selected.reportDates.end_date) {
                        ranges = getSecondDatePickerRanges(
                            selected.reportDates.start_date,
                            selected.reportDates.end_date
                        );
                    }
                    return {
                        ranges,
                        startDate,
                        endDate,
                        containerStyle,
                        title:
                            selected.mode === COMPARE_MODE
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PREVIOUS_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(startDate, endDate) },
                                  ]
                                : null,
                    };
                },
                actions: [
                    {
                        actionName: 'onApply',
                        action: data => {
                            const newData = {
                                start_date_2: data.start_date,
                                end_date_2: data.end_date,
                            };
                            return setActionType(dateChangedGlobal(type, newData), type);
                        },
                    },
                ],
            },
            {
                id: 'warning',
                componentClass: WarningMessage,
                props: {
                    show: false,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const goals = getFieldsForType('goals')(state);
                    let show = false;
                    let message = '';
                    const containerStyle = {
                        display: 'inline-flex',
                        padding: '6px',
                        marginLeft: '20px',
                    };
                    if (selected.mode === COMPARE_MODE) {
                        show =
                            getDaysDiff(selected.reportDates.start_date, selected.reportDates.end_date, 0) !==
                            getDaysDiff(selected.reportDates.start_date_2, selected.reportDates.end_date_2, 0);
                        message = 'STATIC.PAGES.REPORTS.COMPARE_WARNING';
                    }
                    if (selected.selectedTab === 'goal' && selected.selectedGoal) {
                        const selectedGoal = goals.find(cGoal => cGoal.name === selected.selectedGoal);
                        const endDiff = getDaysDiff(selectedGoal.end_date, new Date(), 0);
                        const startDiff = getDaysDiff(selectedGoal.start_date, new Date(), 0);
                        if (endDiff < 0) {
                            show = true;
                            message = 'STATIC.PAGES.REPORTS.GOAL_WARNING';
                            if (startDiff < 0) {
                                message = 'STATIC.PAGES.REPORTS.GOAL_WARNING_2';
                            }
                        }
                    }
                    return {
                        show,
                        message,
                        containerStyle,
                    };
                },
                actions: [],
            },
        ],
        rightComponents: [
            {
                id: 'topButtons',
                componentClass: TopButtons,
                props: {
                    buttons: [
                        {
                            id: 'export',
                            icon: DownloadIcon,
                            tooltip: 'STATIC.BUTTONS.DOWNLOAD_CSV',
                        },
                    ],
                    containerStyle: {},
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const {
                        table: { request = {} },
                    } = selected;
                    const buttonsProps = {
                        bookmark: {
                            disabled: request.is_goals,
                        },
                        sharedReports: {
                            disabled: request.is_goals || request.compare,
                        },
                    };
                    const bookmarkCreatedByUser =
                        !selected.bookmark.bookmark_creator |
                        (state.user.data.username === selected.bookmark.bookmark_creator);
                    return {
                        disabled: !(selected.table.enabled && selected.table.show),
                        bookmarkCreatedByUser: !!bookmarkCreatedByUser,
                        marked: {
                            bookmark: !!selected.bookmark.bookmark_id,
                        },
                        reportFormHasChanged: selected.reportFormHasChanged,
                        buttonsProps,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: id => {
                            if (id === 'export') {
                                return exportTable(type, id);
                            } else {
                                return { type: '' };
                            }
                        },
                    },
                ],
            },
        ],
    },
    shelf: [],
};
