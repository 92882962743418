import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts } from 'react-jsx-highcharts';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getCurrencySymbol } from 'utils/currencyUtils';
import { CURRENCY } from '../consts';

const TrendChart = ({ chartData, metricType, currency }) => {
    const { data } = chartData;
    const xAxisMaxValueIndex = data[0].data.length >= 7 ? -7 : 0;
    const series = data.map(seriesData => ({
        ...seriesData,
        cursor: 'pointer',
        pointPlacement: 'on',
        groupPadding: 0.35,
        pointPadding: 0.02,
        pointWidth: 100,
        minPointLength: 2,
    }));
    return (
        <HighchartsReact
            highcharts={Highstock}
            constructorType="stockChart"
            options={{
                series,
                credits: { enabled: false },
                xAxis: {
                    type: 'datetime',
                    max: data[0].data.at(xAxisMaxValueIndex)[0],
                },
                yAxis: {
                    opposite: false,
                    labels: metricType
                        ? { format: `{value}${metricType === CURRENCY ? '' : getCurrencySymbol(currency)}` }
                        : {},
                },
                navigator: {
                    xAxis: {
                        type: 'datetime',
                        max: data[0].data.at(xAxisMaxValueIndex)[0],
                    },
                },
                rangeSelector: {
                    selected: 2,
                },
                tooltip: {
                    valueDecimals: 2,
                },
            }}
        />
    );
};

TrendChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    metricType: PropTypes.string,
    currency: PropTypes.string,
};

TrendChart.defaultProps = {
    chartData: {},
    metricType: '',
    currency: 'USD',
};

export default withHighcharts(TrendChart, Highcharts);
