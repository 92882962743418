import React from 'react';
import PropTypes from 'prop-types';
import { ANDROID_NATIVE } from '../../../../consts';
import AppSiteUpdateForm from '../../AppSiteUpdateForm/AppSiteUpdateForm';
import { FIELDS_ITEMS_BY_PLATFORM } from '../../AppSiteUpdateForm/consts';
import css from '../../sectionsItems.css';
import { Label } from '../../../../../../components/widgets';

const ANDROID_DEEP_LINKING_TRANSLATE_KEY = 'STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.DEEP_LINKING';

const DeepLinkingAndroid = ({ appSites, isLoadingAppSites }) => {
    return (
        <div className={css.grayBack}>
            <div className={css.fauxStep}>
                <Label text={`${ANDROID_DEEP_LINKING_TRANSLATE_KEY}.FORM_TITLE`} type="sideLabel" />
                <AppSiteUpdateForm
                    appSites={appSites}
                    isLoadingAppSites={isLoadingAppSites}
                    targetPlatform={ANDROID_NATIVE}
                    formInputFields={FIELDS_ITEMS_BY_PLATFORM[ANDROID_NATIVE]}
                />
            </div>
        </div>
    );
};

DeepLinkingAndroid.propTypes = {
    appSites: PropTypes.arrayOf(PropTypes.shape({ longname: PropTypes.string, id: PropTypes.number })).isRequired,
    isLoadingAppSites: PropTypes.bool,
};

DeepLinkingAndroid.defaultProps = {
    isLoadingAppSites: true,
};
export default DeepLinkingAndroid;
