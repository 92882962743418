import React from 'react';
import PropTypes from 'prop-types';
import { WarningMessage } from 'components/widgets';

class AnimatedWarningMessage extends React.PureComponent {
    render() {
        const { message } = this.props;
        return (
            <WarningMessage
                type="info"
                containerStyle={{
                    marginBottom: 10,
                }}
                showIcon
                showTypeIcon
                {...message}
            />
        );
    }
}

AnimatedWarningMessage.propTypes = {
    message: PropTypes.objectOf(PropTypes.any),
    show: PropTypes.bool,
};

AnimatedWarningMessage.defaultProps = {
    message: {},
    show: false,
};

export default AnimatedWarningMessage;
