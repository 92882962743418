import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@mui/material';
import css from './BannerHeader.css';
import { SingularButton, TextField } from '../../components/widgets';
import { MAX_PRIORITY, MIN_PRIORITY } from '../consts';
import { bannerPriorityChanged } from '../actions';
import { useOutsideEventHandler } from '../../utils/customHooks';
import Chevron from '../../resources/icons/chevron.svg';
import { getSavingConfiguration } from '../selectors';

function PrioritizationPopover({ id, open, priority: propPriority, anchorEl, onClose }) {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const [priority, setPriority] = useState(propPriority);

    const savePriorityDisabled = priority < MIN_PRIORITY || priority > MAX_PRIORITY;
    const savingConfiguration = useSelector(state => getSavingConfiguration(state));

    useEffect(() => {
        if (!savingConfiguration) {
            onClose();
        }
    }, [savingConfiguration]);

    useEffect(() => {
        setPriority(propPriority);
    }, [propPriority]);

    const _setPriorityInput = newPriority => setPriority(newPriority);
    const _onPrioritySave = () => dispatch(bannerPriorityChanged(id, priority));

    const _onPriorityCancel = () => {
        setPriority(propPriority);
        onClose();
    };

    useOutsideEventHandler(wrapperRef, _onPriorityCancel);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
            <div ref={wrapperRef} className={css.priorityPopover}>
                <div className={css.priorityTitle}>Banner Order</div>
                <div className={css.priorityFieldContainer}>
                    <Chevron
                        className={classNames(css.chevron, css.top, { [css.disabled]: priority >= MAX_PRIORITY })}
                        onClick={() => _setPriorityInput(priority + 1)}
                    />
                    <TextField
                        value={priority}
                        type="number"
                        inputClassName={css.priorityField}
                        containerClass={css.priorityFieldContainer}
                        onChange={_setPriorityInput}
                    />
                    <Chevron
                        className={classNames(css.chevron, { [css.disabled]: priority <= MIN_PRIORITY })}
                        onClick={() => _setPriorityInput(priority - 1)}
                    />
                </div>
                <div className={css.priorityButtons}>
                    <SingularButton
                        onClick={_onPrioritySave}
                        disabled={savePriorityDisabled}
                        showSpinner={savingConfiguration}
                    >
                        Save
                    </SingularButton>
                    <SingularButton type="flat" onClick={_onPriorityCancel}>
                        Cancel
                    </SingularButton>
                </div>
            </div>
        </Popover>
    );
}

PrioritizationPopover.propTypes = {
    id: PropTypes.number.isRequired,
    open: PropTypes.bool,
    priority: PropTypes.number.isRequired,
    anchorEl: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func,
};

PrioritizationPopover.defaultProps = {
    open: false,
    anchorEl: null,
    onClose: () => {},
};

export default PrioritizationPopover;
