import PropTypes from 'prop-types';
import { OS } from '../utils/OS';

export const OrientationTypes = {
    LANDSCAPE: 'Landscape',
    PORTRAIT: 'Portrait',
    SQUARE: 'Square',
};

export const FormattedCreativeTypes = {
    image: 'Image',
    video: 'Video',
    carousel: 'GIF',
};

export const CreativeTypes = {
    IMAGE: FormattedCreativeTypes.image,
    VIDEO: FormattedCreativeTypes.video,
    GIF: FormattedCreativeTypes.carousel,
};

const CreativeMetrics = {
    custom_impressions: PropTypes.number,
    custom_clicks: PropTypes.number,
    custom_installs: PropTypes.number,
    adn_cost: PropTypes.number,
    ctr: PropTypes.number,
    cvr: PropTypes.number,
    ecpm: PropTypes.number,
    ipm: PropTypes.number,
    ecpi: PropTypes.number,
    roi_1d: PropTypes.number,
    roi_7d: PropTypes.number,
};

export const CreativeMultiValuedDimensionsType = {
    osList: PropTypes.arrayOf(PropTypes.oneOf(Object.values(OS))),
    assetNameList: PropTypes.arrayOf(PropTypes.string),
    creativeNameList: PropTypes.arrayOf(PropTypes.string),
    assetIdList: PropTypes.arrayOf(PropTypes.string),
    creativeIdList: PropTypes.arrayOf(PropTypes.string),
};

export const CreativeType = {
    url: PropTypes.string,
    type: PropTypes.oneOf(Object.values(CreativeTypes)),
    width: PropTypes.number,
    height: PropTypes.number,
    imageHash: PropTypes.string,
    metrics: PropTypes.shape(CreativeMetrics),
    multiValuedDimensions: PropTypes.shape(CreativeMultiValuedDimensionsType),
};

export const SelectedMetrics = {
    label: PropTypes.string,
    value: PropTypes.string,
};
