import { useCallback } from 'react';
import { useRequest } from '../utils/customHooks';
import CustomEvents from '../services/customEvents';

export const useFetchArchivedStandardEvents = (eventsLength, customEventsLoaded) => {
    const CustomEventsService = new CustomEvents();
    return useRequest(
        useCallback(CustomEventsService.getArchivedStandardEvents, [eventsLength]),
        () => {
            return customEventsLoaded;
        },
        () => {},
        eventsLength
    );
};

export const useRestoreStandardEvent = (shouldRun, eventId, onCleanup) => {
    const CustomEventsService = new CustomEvents();
    return useRequest(
        useCallback(CustomEventsService.restoreStandardEvent, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        {
            id: eventId,
        }
    );
};
