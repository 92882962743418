export const SET_HOMEPAGE_DATA = 'action.homepage.set.data';
export const SET_HOMEPAGE_COMPLETED_TASK = 'action.homepage.set.data.completed-task';
export const SAVE_HOMEPAGE_COMPLETED_TASK = 'action.homepage.save.data.completed-task';
export const SET_HOMEPAGE_ERROR = 'action.homepage.set.error';
export const AUDIT_SDK_ID = 'action.homepage.audit.sdk.id';
export const AUDIT_GENERAL_ID = 'action.homepage.audit.general.id';
export const AUDIT_SDK = 'action.homepage.audit.sdk';
export const AUDIT_GENERAL = 'action.homepage.audit.general';
export const RESET_VALIDATION_MESSAGE = 'action.homepage.reset.validation.message';
export const AUDIT_SDK_MESSAGE = 'action.homepage.audit.sdk.message';
export const SDK_AUDIT_LOADING = 'action.homepage.audit.sdk.loading';
export const GENERAL_AUDIT_LOADING = 'action.homepage.audit.general.loading';

export const setHomepageData = data => ({
    type: SET_HOMEPAGE_DATA,
    data,
});

export const saveCompletedTask = (completedTaskName, adminMarkedAsDone = false) => ({
    type: SAVE_HOMEPAGE_COMPLETED_TASK,
    completedTaskName,
    adminMarkedAsDone,
});

export const setCompletedTask = completedTaskName => ({
    type: SET_HOMEPAGE_COMPLETED_TASK,
    completedTaskName,
});

export const setHomepageError = () => ({
    type: SET_HOMEPAGE_ERROR,
});

export const auditSdkId = validationId => ({
    type: AUDIT_SDK_ID,
    validationId,
});

export const auditGeneralId = validationId => ({
    type: AUDIT_GENERAL_ID,
    validationId,
});

export const auditSdk = () => {
    return {
        type: AUDIT_SDK,
    };
};

export const auditGeneral = () => {
    return {
        type: AUDIT_GENERAL,
    };
};

export const auditMessage = message => {
    return {
        type: AUDIT_SDK_MESSAGE,
        message,
    };
};

export const resetValidationMessage = () => {
    return {
        type: RESET_VALIDATION_MESSAGE,
    };
};

export const sdkAuditLoading = isLoading => {
    return {
        type: SDK_AUDIT_LOADING,
        isLoading,
    };
};

export const generalAuditLoading = isLoading => {
    return {
        type: GENERAL_AUDIT_LOADING,
        isLoading,
    };
};
