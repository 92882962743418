import { useCallback } from 'react';
import { useRequest } from '../../../utils/customHooks';
import CampaignReportAPI from './service';

export const useFetchAllSources = () => {
    const campaignReportAPI = new CampaignReportAPI();
    return useRequest(
        useCallback(campaignReportAPI.getSources, []),
        () => {
            return true;
        },
        () => {}
    );
};

export const useFetchCampaigns = (source, startDate, endDate) => {
    const campaignReportAPI = new CampaignReportAPI();
    return useRequest(
        useCallback(campaignReportAPI.getCampaigns, []),
        () => {
            return !!source;
        },
        () => {},
        source,
        startDate,
        endDate
    );
};
