export const isFirstStep = activeStepIndex => {
    return activeStepIndex === 0;
};

export const isLastStep = (activeStepIndex, steps) => {
    return activeStepIndex === steps.length - 1;
};

export const getNextStepIndex = (activeStepIndex, steps) => {
    return Math.min(activeStepIndex + 1, steps.length - 1);
};

export const getPrevStepIndex = activeStepIndex => {
    return Math.max(activeStepIndex - 1, 0);
};

export const getLastStepIndex = steps => {
    return steps.length - 1;
};

export const isActiveStepDefined = activeStepIndex => {
    return activeStepIndex === 0 || !!activeStepIndex;
};
