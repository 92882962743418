export const UAN_LOGIN_VERIFICATION_FINISHED = 'action.datasource.login_verification.finished';
export const UPDATE_DATA_SOURCE_DATA = 'action.datasource.update.data';
export const UPDATE_IS_ETL = 'action.datasource.update.is_etl';
export const UPDATE_SINGLE_DATA_SOURCE_DATA = 'action.datasource.update.single_data';
export const DELETE_SINGLE_DATA_SOURCE_DATA = 'action.datasource.delete.single_data';
export const EXPORT_2PHASE = 'action.datasource.export.2phase';
export const UPDATE_2PHASE_KEYS = 'action.datasource.update.2phase_keys';
export const UPDATE_ADNETWORKS = 'action.datasource.update.adnetworks';
export const UPDATE_PARTNERS_IDS = 'action.datasource.update.partner_ids';
export const REDIRECT_TO_PC = 'action.datasource.redirect.pc';
export const UPDATE_UAN_ACTION = 'action.datasource.update.uan_action';
export const UPDATE_UAN_DATA = 'action.datasource.uan.data';
export const OPEN_SHELF = 'action.datasource.shelf.open';
export const CLOSE_SHELF = 'action.datasources.shelf.close';
export const SAVE_UAN_DATA = 'action.datasource.uan.save';
export const SAVE_UAN_DATA_WITH_ACCOUNTS = 'action.datasource.uan.accounts.save';
export const SAVE_ADWORDS_DATA = 'action.datasource.uan.adwords.save';
export const SAVE_ADWORDS_MCC_DATA = 'action.datasource.uan.adwords.mcc.save';
export const SAVE_UAN_SUCCEEDED = 'action.datasource.uan.save.succeeded';
export const API_REQUEST_FAILED = 'action.datasource.api.request.failed';
export const UPDATE_UAN_DESCRIPTION = 'action.datasource.update.uan.description';
export const SAVE_UAN_DISPLAY_ERROR = 'action.datasource.uan.save.display_error';
export const DELETE_UAN = 'action.datasources.uan.delete';
export const SET_UPDATED_UAN = 'action.datasources.uan.updated';
export const UAN_AUTH_CLICK = 'action.datasources.uan.auth.clicked';
export const SET_AUTH_POPUP_URL = 'action.datasources.update.auth.url';
export const VALIDATE_UAN_LOGIN = 'action.datasources.uan.validate';
export const UPDATE_UAN_ACCOUNTS = 'action.datasources.uan.accounts.update';
export const GET_UAN_ACCOUNTS = 'action.datasources.uan.accounts.get';
export const SEND_SCRAPE_JOB = 'actions.datasources.uan.send.scrape.job';
export const SEND_SCRAPE_JOB_STATUS_UPDATED = 'actions.datasources.uan.send.scrape.job.status.updated';
export const DELETE_USER_SLACK_AUTH = 'actions.datasources.delete.user.slack.auth';

export const loginVerificationFinished = uanId => ({
    type: UAN_LOGIN_VERIFICATION_FINISHED,
    uanId,
});

export const updateUans = data => ({
    type: UPDATE_DATA_SOURCE_DATA,
    data,
});

export const updateIsETL = isETL => ({
    type: UPDATE_IS_ETL,
    isETL,
});

export const updateSingleUan = data => ({
    type: UPDATE_SINGLE_DATA_SOURCE_DATA,
    data: {
        uan: data,
    },
});

export const deleteSingleUan = uanId => ({
    type: DELETE_SINGLE_DATA_SOURCE_DATA,
    data: { uanId },
});

export const export2Phase = uanId => ({
    type: EXPORT_2PHASE,
    uanId,
});

export const update2PhaseKeys = data => ({
    type: UPDATE_2PHASE_KEYS,
    data,
});

export const updateAdNetworks = data => ({
    type: UPDATE_ADNETWORKS,
    data,
});

export const updatePartnerIds = data => ({
    type: UPDATE_PARTNERS_IDS,
    data,
});

export const updateUanAction = ({ type, uan }) => ({
    type: UPDATE_UAN_ACTION,
    data: {
        type,
        uan,
    },
});
export const updateUanData = data => ({
    type: UPDATE_UAN_DATA,
    data,
});

export const openShelf = (adnetDisplayName, uanId) => ({
    type: OPEN_SHELF,
    adnetDisplayName,
    uanId,
});

export const saveUanData = (uanData, partnerSourceId) => ({
    type: SAVE_UAN_DATA,
    uanData,
    partnerSourceId,
});

export const saveUanDataWithAccounts = (uanData, uanId, partnerSourceId) => ({
    type: SAVE_UAN_DATA_WITH_ACCOUNTS,
    uanData,
    uanId,
    partnerSourceId,
});

export const saveAdwordsMccData = (uanId, mccClientId) => ({
    type: SAVE_ADWORDS_MCC_DATA,
    uanId,
    mccClientId,
});

export const saveAdwordsData = (uanData, uanId, mccClientId, partnerSourceId) => ({
    type: SAVE_ADWORDS_DATA,
    uanData,
    uanId,
    mccClientId,
    partnerSourceId,
});

export const uanSaveSucceeded = (adnetDisplayName, uanId) => ({
    type: SAVE_UAN_SUCCEEDED,
    adnetDisplayName,
    uanId,
});

export const updateUanDescription = (uanId, description) => ({
    type: UPDATE_UAN_DESCRIPTION,
    uanId,
    description,
});

export const updateSendScrapeJobStatus = sendScrapeJobStatus => ({
    type: SEND_SCRAPE_JOB_STATUS_UPDATED,
    sendScrapeJobStatus,
});

export const apiRequestFailed = error => ({
    type: API_REQUEST_FAILED,
    error,
});

export const uanSaveDisplayError = (error, adnetDisplayName, uanId) => ({
    type: SAVE_UAN_DISPLAY_ERROR,
    error,
    adnetDisplayName,
    uanId,
});

export const closeShelf = () => ({
    type: CLOSE_SHELF,
});

export const deleteUan = uanId => ({
    type: DELETE_UAN,
    uanId,
});

export const setUpdatedUan = uanId => ({
    type: SET_UPDATED_UAN,
    uanId,
});

export const redirectToPartnerConfiguration = partnerSourceId => ({
    type: REDIRECT_TO_PC,
    partnerSourceId,
});

export const getNetworkAuthUrl = (url, adnId, uanId) => ({
    type: UAN_AUTH_CLICK,
    url,
    adnId,
    uanId,
});

export const setAuthPopupUrl = url => ({
    type: SET_AUTH_POPUP_URL,
    url,
});

export const validateLogin = uanId => ({
    type: VALIDATE_UAN_LOGIN,
    uanId,
});

export const updateUanAccounts = accountsData => ({
    type: UPDATE_UAN_ACCOUNTS,
    accountsData,
});

export const getUanAccounts = (uanId, disableSaveAccounts) => ({
    type: GET_UAN_ACCOUNTS,
    uanId,
    disableSaveAccounts,
});

export const sendScrapeJob = uanData => ({
    type: SEND_SCRAPE_JOB,
    uanData,
});

export const deleteUserSlackAuth = () => ({
    type: DELETE_USER_SLACK_AUTH,
});
