import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { WizardFooter, SingularButton, Shelf } from '../../components/widgets';
import Markdown from '../../components/widgets/Markdown';

const NetworkInstructions = (props) => {
    const { instructions, open, onClose, networkName } = props;

    return (
        <Shelf
            open={open}
            headerText={`How to configure ${networkName}`}
            onClose={() => {
                onClose();
            }}
            shelfSize="medium"
        >
            <div style={{ padding: '20px 40px' }}>
                <Markdown
                    source={instructions}
                    customRenderers={['image', 'list', 'listItem']}
                    imageRendererStyle={{ maxWidth: '600px' }}
                    styles={{
                        listItemRendererStyle: { marginBottom: '20px' },
                        paragraphRendererStyle: { marginBottom: '20px' },
                        imageRendererStyle: { maxWidth: '600px' },
                    }}
                />
            </div>
            <WizardFooter
                buttons={[
                    <SingularButton
                        type="flat"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <Translate id="STATIC.BUTTONS.DONE" />
                    </SingularButton>,
                ]}
            />
        </Shelf>
    );
};

NetworkInstructions.propTypes = {
    instructions: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    networkName: PropTypes.string,
};

NetworkInstructions.defaultProps = {
    instructions: '',
    open: false,
    onClose: () => {},
    networkName: '',
};

export default NetworkInstructions;
