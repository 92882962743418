import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import DragDropIcon from '../../resources/icons/drag_drop.svg';

import css from './DragDropElement.css';

const DND_TYPE = 'dragDropElement';

const DragDropElement = ({ index, value, onDragOver }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: DND_TYPE,
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }
            onDragOver(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: DND_TYPE,
        item: () => {
            return { value, index };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            key={value}
            className={classNames(css.value, {
                [css.dragging]: isDragging,
            })}
        >
            <DragDropIcon className={css.dragDropIcon} />
            {value}
        </div>
    );
};

DragDropElement.propTypes = {
    value: PropTypes.string,
    onDragOver: PropTypes.func,
    index: PropTypes.number,
};

DragDropElement.defaultProps = {
    value: '',
};

export default DragDropElement;
