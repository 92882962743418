import React from 'react';
import PropTypes from 'prop-types';

import { buildLinksMap, EXPAND_ARROW, COLLAPSE_ARROW } from '../../../utils/trackingLinks';
import Network from './Network';
import MobileIcon from '../../../resources/png/icon_mobile.png';

const App = (props) => {
    // get app icon from the first app site
    const { css } = props;
    const appIconUrl = props.app.sites[0].icon || undefined;
    const useDefaultIcon =
        !appIconUrl ||
        appIconUrl === '' ||
        appIconUrl === 'https://cdn.singular.net/dashboard/icon/1dbacc8dc07551adf8fffa21d4dee93a79f37105' ||
        appIconUrl === 'https://cdn.singular.net/dashboard/icon/396fc6e74cac1190d4725ed29f6224274bd7cd3e';

    const linksMap = buildLinksMap(props.app);
    const networksCount = Object.keys(linksMap.networks).length;
    const campaignsCount = Array.from(new Set(props.app.links.map((link) => link.campaign_name))).length;

    if (networksCount === 0) {
        return <span />;
    }
    return (
        <div className={css.AppWrapper}>
            <div className={css.AppHeading} onClick={props.toggleExpandApp}>
                {useDefaultIcon ? (
                    <div className={css.AppIcon} style={{ backgroundColor: '#CCCCCC' }}>
                        <img
                            className={css.AppIcon}
                            src={MobileIcon}
                            style={{ width: '30px', height: '48px', marginTop: '6px' }}
                            alt={props.app.name}
                        />
                    </div>
                ) : (
                    <div className={css.AppIcon}>
                        <img className={css.AppIcon} src={appIconUrl} alt={props.app.name} />
                    </div>
                )}
                <div className={css.AppCollapseIcon}>{props.expanded ? COLLAPSE_ARROW : EXPAND_ARROW}</div>
                <div className={css.AppHeadingText}>
                    <div className={css.AppName}>{props.app.name}</div>
                    <div className={css.AppDetails}>
                        {`${networksCount} Ad Network${networksCount !== 1 ? 's' : ''}, ${campaignsCount} Campaign${
                            campaignsCount !== 1 ? 's' : ''
                        }`}
                    </div>
                </div>
            </div>
            {props.expanded && (
                <div className={css.AppBody}>
                    {Object.keys(linksMap.networks)
                        .sort()
                        .map((network) => {
                            return (
                                <Network
                                    css={css}
                                    network={network}
                                    linkType={linksMap.networks[network].type}
                                    campaigns={linksMap.networks[network].campaigns}
                                    expanded={props.expandedNetworks.indexOf(network) > -1}
                                    expandedCampaigns={props.expandedCampaigns}
                                    app_id={props.app.id}
                                    key={`app_${props.app.id}_network_${network}`}
                                    toggleExpand={props.toggleExpandNetwork}
                                    toggleExpandCampaign={props.toggleExpandCampaign}
                                    deleteClicked={props.deleteClicked}
                                    editCampaignName={props.editCampaignName}
                                    editClicked={props.editClicked}
                                    editingCampaign={props.editingCampaign}
                                    enabledCampaignEditing={props.enabledCampaignEditing}
                                />
                            );
                        })}
                </div>
            )}
        </div>
    );
};

App.propTypes = {
    app: PropTypes.objectOf(PropTypes.any),
    css: PropTypes.objectOf(PropTypes.any),
    editingCampaign: PropTypes.string,
    editCampaignName: PropTypes.func,
    editClicked: PropTypes.func,
    enabledCampaignEditing: PropTypes.bool,
    expanded: PropTypes.bool,
    expandedCampaigns: PropTypes.arrayOf(PropTypes.any),
    expandedNetworks: PropTypes.arrayOf(PropTypes.any),
    deleteClicked: PropTypes.func,
    toggleExpandApp: PropTypes.func,
    toggleExpandNetwork: PropTypes.func,
    toggleExpandCampaign: PropTypes.func,
};

App.defaultProps = {
    app: {},
    css: {},
    editingCampaign: '',
    editCampaignName: () => {},
    editClicked: () => {},
    enabledCampaignEditing: false,
    expanded: false,
    expandedCampaigns: [],
    expandedNetworks: [],
    deleteClicked: () => {},
    toggleExpandApp: () => {},
    toggleExpandNetwork: () => {},
    toggleExpandCampaign: () => {},
};

export default App;
