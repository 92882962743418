import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';
import { reportBase } from './reportsBase';

export default {
    type: reportTypes.fraudRejected,
    fields: reportBase.fields,
    visibleRule: rules[reportTypes.fraudRejected],
    dataSelector: {
        ...reportBase.dataSelector,
        chart: 'chart',
        tableDataFormatted: 'reportData',
        chartDataFormatted: 'chartData',
    },
    isFraud: true,
};
