import React from 'react';
import { AutoCompleteField } from './index';

const TableCellAutocomplete = ({
    id,
    value,
    label,
    options,
    className,
    disabled,
    rowIndex,
    placeholder,
    error,
    containerClass,
    errorClassName,
    onChange: onChangeProp,
}) => {
    return (
        <td className={className}>
            <AutoCompleteField
                controlled
                searchable
                label={label}
                value={value}
                disabled={disabled}
                isMulti={false}
                options={options(rowIndex)}
                placeholder={placeholder}
                containerClass={containerClass}
                errorClassName={errorClassName}
                error={error}
                onChange={option => onChangeProp(option.value, id, rowIndex)}
            />
        </td>
    );
};

TableCellAutocomplete.propTypes = {
    ...AutoCompleteField.propTypes,
};

TableCellAutocomplete.defaultProps = {
    ...AutoCompleteField.defaultProps,
};

export default TableCellAutocomplete;
