import React from 'react';

export default function exportLogs() {
    return (
        <div>
            <p>
                <strong>What:</strong><span> Download Singular tracker logs to see the raw, user-level data – ad clicks, app installs, in-app user events, and postbacks.</span>
            </p>
            <p>
                <strong>Why:</strong><span> Use the logs to further investigate data on the user level or troubleshoot unexpected results in your reports. This is a helpful place to go if you have a discrepancy with a network and need to review all the postbacks that were fired; or if you want to review all the click URLs Singular received.&nbsp;</span>
            </p>
            <p>
                <strong>Questions? </strong><span> Learn more in our guide to </span>
                <a href="https://support.singular.net/hc/en-us/articles/360001216992-Exporting-Attribution-Logs" target="_blank"> FAQ</a><span>.</span>
            </p>
        </div>
    );
}
