export const LEFT_TAG = '<';
export const RIGHT_TAG = '>';

export const START_MARKDOWN = key => `@@${key}@@`;
export const START_REGEX = new RegExp(START_MARKDOWN('(.*)'));
export const START_REGEX_GLOBAL = new RegExp(START_REGEX.source, 'g');
export const END_MARKDOWN = `&&&&`;
export const END_REGEX = new RegExp(END_MARKDOWN);
export const END_REGEX_GLOBAL = new RegExp(END_REGEX.source, 'g');

export const SDK_KEY_PLACEHOLDER = 'SDK_KEY';
export const SDK_TOKEN_PLACEHOLDER = 'SDK_TOKEN';

export const CODE_SNIPPETS = {
    CONFIG_THE_SDK: 'CONFIG_THE_SDK',
    EVENTS_CODE_SNIPPET: 'EVENTS_CODE_SNIPPET',
    REVENUE_IN_APP_PURCHASE_CODE_SNIPPET: 'REVENUE_IN_APP_PURCHASE_CODE_SNIPPET',
    REVENUE_NON_IN_APP_PURCHASE_CODE_SNIPPET: 'REVENUE_NON_IN_APP_PURCHASE_CODE_SNIPPET',
    REVENUE_WITH_PURCHASE_OBJECT_CODE_SNIPPET: 'REVENUE_WITH_PURCHASE_OBJECT_CODE_SNIPPET',
    REVENUE_WITHOUT_PURCHASE_OBJECT_CODE_SNIPPET: 'REVENUE_WITHOUT_PURCHASE_OBJECT_CODE_SNIPPET',
    AD_MONETIZATION_ADMOB: 'AD_MONETIZATION_ADMOB',
    AD_MONETIZATION_UNITY: 'AD_MONETIZATION_UNITY',
    AD_MONETIZATION_APPLOVIN: 'AD_MONETIZATION_APPLOVIN',
    AD_MONETIZATION_TRADPLUS: 'AD_MONETIZATION_TRADPLUS',
    AD_MONETIZATION_OTHER: 'AD_MONETIZATION_OTHER',
};
