import { all, call, fork, put, take, select, delay } from 'redux-saga/effects';
import * as selectors from 'customDimensions/selectors';
import * as api from 'customDimensions/service';
import * as actions from 'customDimensions/actions';
import { getDimensionsRelated } from 'customDimensions/service';
import { dimensionsRelatedLoaded } from 'customDimensions/actions';
import { setIsLoading } from '../appLoadingState/actions';

function* getDimensions() {
    const response = yield call(api.getDimensions, {});
    yield put(actions.dimensionsLoaded(response));
}

function* updateDimensionsRelated() {
    const response = yield call(api.getDimensionsRelated, {});
    yield put(actions.dimensionsRelatedLoaded(response));
}

function* getOperators() {
    const response = yield call(api.getOperators, {});
    yield put(actions.operatorsLoaded(response));
}

function* getCustomDimensions() {
    const response = yield call(api.getCustomDimensions, {});
    yield put(
        actions.customDimensionsLoaded(
            response.dimensions,
            response.maxDimensions,
            response.maxConditionsPerDimension,
            response.supportedMacroDimensions,
            response.v3Dimensions,
            response.tabsToShow
        )
    );
}

function* getConfiguration() {
    yield put(setIsLoading(true));

    const getConfigurationCalls = [call(getDimensions), call(getOperators), call(getCustomDimensions)];
    yield all(getConfigurationCalls);

    yield put(actions.customDimensionsPageLoaded());
    yield put(setIsLoading(false));
    yield updateDimensionsRelated();
}

function isDimensionValidForSaving(state, dimensionId) {
    const dimension = state.dimensionsMap[dimensionId];
    if (dimension.name.length === 0) {
        return false;
    }
    for (const valueId of dimension.values) {
        const value = state.valuesMap[valueId];
        if (value.value.length === 0) {
            return false;
        }
        const rules = [value.rule];
        while (rules.length > 0) {
            const rule = state.rulesMap[rules.pop()];
            if (rule.level > 0) {
                rules.push(...rule.inner);
            } else {
                for (const conditionId of rule.inner) {
                    const condition = state.conditionsMap[conditionId];
                    if (
                        condition.dimension === 'default' ||
                        condition.values.length === 0 ||
                        condition.values[0].length === 0
                    ) {
                        return false;
                    }
                    const availableFilterOptions = state.availableDimensions.find(
                        ({ name }) => name === condition.dimension
                    ).values;
                    if (availableFilterOptions) {
                        for (const conditionValue of condition.values) {
                            const foundValidTag = availableFilterOptions.find(({ name }) => name === conditionValue);
                            if (!foundValidTag) {
                                return false;
                            }
                        }
                    }
                }
            }
        }
    }
    return true;
}

function* save(action) {
    const { dimensionConfig } = yield select(selectors.getDimensionToConfigSelector(action.id));
    if (!isDimensionValidForSaving(dimensionConfig, action.id)) {
        yield put(actions.customDimensionSaveError(action.id));
    } else {
        const saveParams = {
            dimensionId: action.id,
            name: dimensionConfig.dimensionsMap[action.id].name,
            config: {
                valuesList: dimensionConfig.dimensionsMap[action.id].values,
                valuesMap: dimensionConfig.valuesMap,
                rulesMap: dimensionConfig.rulesMap,
                conditionsMap: dimensionConfig.conditionsMap,
                defaultValue: dimensionConfig.dimensionsMap[action.id].defaultValue,
                availableInTab: dimensionConfig.dimensionsMap[action.id].availableInTab,
            },
            reordered: action.reordered,
        };
        try {
            yield call(api.save, saveParams);
        } catch (error) {
            yield put(actions.customDimensionSaveError(action.id));
            return;
        }
        yield put(actions.customDimensionSaveDone(dimensionConfig, action.id));
        yield delay(3000);
        yield put(actions.customDimensionSaveStatusReset(action.id));
    }
}

function* deleteDimension() {
    const dimensionId = yield select(selectors.getDeleteDialogDimension);
    const dimensionBackups = yield select(selectors.getBackupDimensionList);
    if (dimensionBackups[dimensionId]) {
        yield call(api.deleteDimension, dimensionId);
    }
    yield put(actions.customDimensionRemoved());
}

function* handleDestroy() {
    yield put(actions.customDimensionsPageUnLoad());
}

// ---------------------------------/
//      WATCHERS
// ---------------------------------/

function* onload() {
    yield call(getConfiguration);
}

function* destroy() {
    yield call(handleDestroy);
}

function* watchSaveClicked() {
    while (true) {
        const action = yield take(actions.CUSTOMDIMENSION_DIMENSION_SAVE_START);
        yield fork(save, action);
    }
}

function* watchDeleteClicked() {
    while (true) {
        yield take(actions.CUSTOMDIMENSION_DIMENSION_REMOVE_SEND_TO_BACKEND);
        yield fork(deleteDimension, {});
    }
}

const watchers = [watchSaveClicked, watchDeleteClicked];

export { onload, watchers, isDimensionValidForSaving, destroy };
