import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts } from 'react-jsx-highcharts';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getCurrencySymbol } from 'utils/currencyUtils';
import { CURRENCY, colorByNetwork } from '../consts';
import { lineColors } from '../../utils/charts';

const StackAreaTrendChart = ({ chartData, metricType, currency }) => {
    const { data } = chartData;
    const series = data.map(seriesData => ({
        ...seriesData,
        cursor: 'pointer',
        pointPlacement: 'on',
        groupPadding: 0.35,
        pointPadding: 0.02,
        pointWidth: 100,
        minPointLength: 2,
    }));
    const seriesColor = data.map((seriesData, index) =>
        seriesData.name in colorByNetwork ? colorByNetwork[seriesData.name] : lineColors[index % lineColors.length]
    );
    return (
        <HighchartsReact
            highcharts={Highstock}
            constructorType="stockChart"
            options={{
                credits: { enabled: false },
                chart: {
                    type: 'area',
                    height: Math.max(series.length * 60, 400),
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                    },
                },
                series,
                colors: seriesColor,
                legend: {
                    enabled: true,
                },
                xAxis: {
                    type: 'datetime',
                    max: series[0].data.at(-7)[0],
                },
                yAxis: {
                    opposite: false,
                    labels: metricType
                        ? { format: `{value}${metricType === CURRENCY ? '' : getCurrencySymbol(currency)}` }
                        : {},
                },
                navigator: {
                    xAxis: {
                        type: 'datetime',
                        max: series[0].data.at(-7)[0],
                    },
                },
                rangeSelector: {
                    selected: 2,
                },
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: metricType === CURRENCY ? '' : getCurrencySymbol(currency),
                },
            }}
        />
    );
};

StackAreaTrendChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    metricType: PropTypes.string,
    currency: PropTypes.string,
};

StackAreaTrendChart.defaultProps = {
    chartData: {},
    metricType: '',
    currency: 'USD',
};

export default withHighcharts(StackAreaTrendChart, Highcharts);
