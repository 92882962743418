import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import { STATUS_NONE } from '../../../linkManagement/utils';

import css from './AppSummaryContent.css';
import verticalListCss from '../../../components/partials/VerticalList/VerticalList.css';
import { ModelDisplayNames, ModelKinds, RevenueModelTypes, RevenueTypeDisplayNames } from '../../consts';
import { APP_FIELD_OFF } from '../../appUtils';

export const ModelTypeChipNames = {
    [RevenueTypeDisplayNames[RevenueModelTypes.IAP]]: 'IAP Revenue',
    [RevenueTypeDisplayNames[RevenueModelTypes.ADMON]]: 'Admon',
    [ModelDisplayNames[ModelKinds.CONVERSION_EVENTS]]: 'Conversion',
    [APP_FIELD_OFF]: 'No Enabled',
};

function AppSummaryContent({ modelType, status }) {
    return (
        <div className={css.container}>
            <Chip
                icon={
                    <div
                        className={classNames(css.statusIndication, {
                            [css.live]: status !== STATUS_NONE,
                            [css.not_live]: status === STATUS_NONE,
                        })}
                    />
                }
                classes={{ label: css.chipLabel }}
                label={!modelType ? 'No Model' : `${ModelTypeChipNames[modelType] || modelType} Model`}
                variant="outlined"
                className={classNames(verticalListCss.colorWithParentItem, css.chip)}
            />
        </div>
    );
}

AppSummaryContent.propTypes = {
    modelType: PropTypes.string,
    status: PropTypes.string,
};

AppSummaryContent.defaultProps = {
    modelType: '',
    status: '',
};

export default withLocalize(AppSummaryContent);
