import { call, put, select, all } from 'redux-saga/effects';
import Logger from '../services/logger';
import AppsAPI from '../services/apps';
import { load, pageDestroy, reportInfo, updateUanEventsData } from './actions';
import { updateAttributionAppsData } from '../actions/apps';
import Events from '../services/events';

const logger = new Logger('[mta:saga]');

const appsApi = new AppsAPI();
const eventsApi = new Events();

function* onload() {
    try {
        yield put(load(true));

        const [appsResponse] = yield all([call(appsApi.getAttributionApps, false, true)]);
        const [uanEventsResponse] = yield all([call(eventsApi.getUanEvents, false, true)]);

        yield put(load(false));

        if (appsResponse && appsResponse.apps && appsResponse.apps.length) {
            yield put(updateAttributionAppsData(appsResponse));
        }

        if (uanEventsResponse) {
            yield put(updateUanEventsData(uanEventsResponse));
        }
    } catch (e) {
        logger.debug('Error while loading the page', e);
    }
}

function* destroy() {
    yield put(pageDestroy());
}

export { onload, destroy };
