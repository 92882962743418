import PropTypes from 'prop-types';
import React from 'react';
import shelfCss from './PartnerConfigurationShelf.css';
import { partnerShape } from '../types';
import PartnerParameters from './PartnerParameters';
import PartnerSetupInformation from './PartnerSetupInformation';

function PartnerSetupSection({ partner, platform, isReadonly }) {
    return (
        <div className={shelfCss.section}>
            <PartnerParameters partner={partner} platform={platform} isReadonly={isReadonly} />
            <PartnerSetupInformation partner={partner} platform={platform} />
        </div>
    );
}

PartnerSetupSection.propTypes = {
    partner: partnerShape.isRequired,
    platform: PropTypes.string,
    isReadonly: PropTypes.bool,
};

export default PartnerSetupSection;
