import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import OAuthLoginField from '../../components/partials/OAuthLoginField';
import { downloadOrExportReport, exportTable, setActionType } from '../../actions/reports';
import { reportTypes } from '../../utils/reports';
import css from '../../components/widgets/Menu.css';

const GSheetExport = ({ item, itemClass }) => {
    const { reportType } = item;
    const oauthLoginRef = useRef();
    const dispatch = useDispatch();

    function handleAuthPopupResponse(data, errorMsg) {
        if (data) {
            const token = data.refresh_token;
            if (reportType === reportTypes.mainReport) {
                dispatch(
                    setActionType(
                        downloadOrExportReport(reportType, reportType, 'top-bar', false, true, token),
                        reportType
                    )
                );
            } else {
                dispatch(exportTable(reportType, reportType, true, token));
            }

            return true;
        }
        return false;
    }

    const networkData = {
        id: 0,
        uan_id: 0,
        adn_id: 0,
        auth_type: 'google_sheets',
        display_name: '',
        name: 'gsheet',
        is_login_error: false,
        logo_path: '',
    };

    return (
        <li
            id={item.value}
            key={item.label}
            className={classNames(css.item, itemClass)}
            onMouseDown={() => {
                oauthLoginRef.current.oauthClick();
            }}
        >
            <item.icon className={item.iconStyle} />
            <Translate id={item.label} />
            {!!item.tag && (
                <sup className={css.sideBadge}>
                    <Translate id={item.tag} />
                </sup>
            )}
            <div style={{ display: 'none' }}>
                <OAuthLoginField
                    ref={oauthLoginRef}
                    networkData={networkData}
                    isValidated={false}
                    authPopupHandler={handleAuthPopupResponse}
                    usernameValue=""
                    onError={() => {}}
                />
            </div>
        </li>
    );
};

GSheetExport.propTypes = {
    item: PropTypes.objectOf({
        reportType: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string,
        iconStyle: PropTypes.string,
        tag: PropTypes.string,
        icon: PropTypes.string,
    }),
    itemClass: PropTypes.string,
};

GSheetExport.defaultProps = {
    item: {
        reportType: '',
        value: '',
        label: '',
        iconStyle: '',
        tag: '',
        icon: '',
    },
    itemClass: '',
};

export default GSheetExport;
