import { SHARED_REPORTS_EXPIRATION_DAYS_CHOICES } from 'components/reportsWidgets/consts';

export const getSharedReportsExpirationDaysOptions = () => {
    return SHARED_REPORTS_EXPIRATION_DAYS_CHOICES.map(choice => {
        return {
            name: choice,
            display_name: choice,
        };
    });
};

export const getFormattedDate = date => {
    return new Date(date)
        .toDateString()
        .split(' ')
        .slice(1)
        .join(' ');
};
