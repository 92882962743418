import React, { useEffect, useMemo, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { ListItemsContainer } from '../../../components/partials';
import css from './DocumentationPanel.css';
import { useSDKPlanningContext } from '../context/useSDKPlanningContext';
import { useSDKPageContext } from '../../context/useSDKPageContext';
import { sendMixpanelEventSdkIntegration } from '../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../consts';
import { getSectionsListItems } from '../sectionsWrapper/sectionsConfig';
import { getFlattenedItemKeyToItem } from '../../../components/partials/ListItemContainer/utils';
import useSdkPlanningLocalStorage from '../hooks/useSdkPlanningLocalStorage';
import { isObjectEmpty } from '../../../utils/general';

const DocumentationPanel = ({ translate }) => {
    const [activeItem, setActiveItem] = useState('');
    const { selectedTargetPlatform, sdkConfigForPlanning } = useSDKPlanningContext();
    const { showEmptyState } = useSDKPageContext();

    const listItems = useMemo(() => getSectionsListItems(selectedTargetPlatform, sdkConfigForPlanning), [
        selectedTargetPlatform,
        sdkConfigForPlanning,
    ]);

    const flattedListItems = useMemo(() => getFlattenedItemKeyToItem(listItems, true), [listItems]);

    const firstItemKey = useMemo(() => {
        const keys = Object.keys(flattedListItems);
        return keys.length > 0 ? keys[0] : '';
    }, [flattedListItems]);

    const { localStorageSdkPlanningPage, setLocalStorageSdkPlanningPage, initialize } = useSdkPlanningLocalStorage();

    const onClickNextButton = (listItem, activeItemId) => {
        setLocalStorageSdkPlanningPage({ platform: selectedTargetPlatform, section: activeItemId });
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.CTA_NEXT_BUTTON,
            activeItemId,
            selectedTargetPlatform,
            {
                current_page: `${listItem?.[activeItemId]?.itemNumber} ${translate(
                    listItem?.[activeItemId]?.itemTitle
                )}`,
            }
        );
    };
    const onClickBackButton = (listItem, activeItemId) => {
        setLocalStorageSdkPlanningPage({ platform: selectedTargetPlatform, section: activeItemId });
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.CTA_BACK_BUTTON,
            activeItemId,
            selectedTargetPlatform,
            {
                current_page: `${listItem?.[activeItemId]?.itemNumber} ${translate(
                    listItem?.[activeItemId]?.itemTitle
                )}`,
            }
        );
    };
    const onClickListItem = section => {
        setLocalStorageSdkPlanningPage({ platform: selectedTargetPlatform, section });
    };

    const validateSectionIsInPlatform = (section = '') => Object.prototype.hasOwnProperty.call(flattedListItems, section) ? section : firstItemKey;

    useEffect(() => {
        initialize(flattedListItems);
        if (isObjectEmpty(flattedListItems)) return;
        const hasPlatformChanged = selectedTargetPlatform !== localStorageSdkPlanningPage?.platform;

        setActiveItem(
            hasPlatformChanged ? firstItemKey : validateSectionIsInPlatform(localStorageSdkPlanningPage?.section)
        );
    }, [
        selectedTargetPlatform,
        flattedListItems,
        firstItemKey,
        localStorageSdkPlanningPage?.platform,
        localStorageSdkPlanningPage?.section,
    ]);

    return (
        <ListItemsContainer
            listItems={listItems}
            isExpandable
            pageContainerClassName={css.container}
            sectionContentClassName={css.content}
            sectionListClassName={css.list}
            isSectionsNavigationEnabled={!showEmptyState}
            disabledList={showEmptyState}
            disabledListDescription="STATIC.PAGES.SDK.SDK_PLANNING.EMPTY_STATE.LIST_HEADER"
            onClickNextButton={onClickNextButton}
            onClickBackButton={onClickBackButton}
            onClickListItem={onClickListItem}
            activeItem={activeItem}
        />
    );
};

DocumentationPanel.propTypes = {
    translate: PropTypes.func.isRequired,
};
export default withLocalize(DocumentationPanel);
