import API from '../services/api';

let instance = null;
const api = new API();

export const BANNERS_CONFIGURATION = 'api/banners/configuration';

export default class BannersService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    saveBanner(appSiteId, banner) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('app_site_id', appSiteId);

            for (const key in banner) {
                // Need to stringify in order to support passing arrays/objects in formData
                const value = banner[key] instanceof File ? banner[key] : JSON.stringify(banner[key]);
                formData.append(key, value);
            }

            api.getJson(BANNERS_CONFIGURATION, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    getBannersForApp(appSiteId) {
        return new Promise((resolve, reject) => {
            api.getJson(BANNERS_CONFIGURATION, {
                params: { app_site_id: appSiteId },
                method: 'GET',
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }

    deleteBanner(bannerId) {
        return new Promise((resolve, reject) => {
            api.getJson(BANNERS_CONFIGURATION, {
                params: { banner_id: bannerId },
                method: 'DELETE',
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch(reject);
        });
    }
}
