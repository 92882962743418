import { CODE_SNIPPETS } from '../consts';
import { KOTLIN } from '../../consts';

const getTradPlusCodeKotlinSnippet = () => {
    return `TradPlusSdk.setGlobalImpressionListener(
   object : GlobalImpressionManager.GlobalImpressionListener {
      override fun onImpressionSuccess(tpAdInfo: TPAdInfo?) {
         if (tpAdInfo == null) return
         val revenue = tpAdInfo.ecpm.toDouble() / 1000
         val data = SingularAdData(
            "TradPlus", 
            "USD", 
            revenue)
         Singular.adRevenue(data)
      }
})`;
};

const getOtherCodeKotlinSnippet = () => {
    return `val data = SingularAdData(
   "YOUR_AD_PLATFORM", 
   "CURRENCY_CODE", 
   9.90)

Singular.adRevenue(data)`;
};

const getUnityKotlinCodeSnippet = () => {
    return `val impressionData: ImpressionData? = null

impressionData?.let { data -
    val singularAdData = SingularAdData(
        "IronSource",
        "USD",
        data.revenue)
    Singular.adRevenue(singularAdData)
}`;
};

const getAdMobKotlinCodeSnippet = () => {
    return `var mRewardedAd: RewardedAd? = null

override fun onAdLoaded(rewardedAd: RewardedAd) {
   mRewardedAd = rewardedAd
   mRewardedAd?.setOnPaidEventListener(object : OnPaidEventListener {
      override fun onPaidEvent(adValue: AdValue) {
         val impressionData: AdValue = adValue
         val data = SingularAdData(
            "AdMob",
            impressionData.currencyCode,
            impressionData.valueMicros / 1000000.0)
         Singular.adRevenue(data)
      }
  })
}`;
};

const getApplovinKotlinCodeSnippet = () => {
    return `val message: AppLovinCommunicatorMessage? = null 
val adData: Bundle? = message?.messageData

adData?.let { bundle -
    val data = SingularAdData(
        "AppLovin",
        "USD",
        bundle.getDouble("revenue", 0.0)
    )
    Singular.adRevenue(data)
}`;
};

const kotlinAdMonetizationCodeSnippetsGetters = {
    [CODE_SNIPPETS.AD_MONETIZATION_ADMOB]: getAdMobKotlinCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN]: getApplovinKotlinCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_UNITY]: getUnityKotlinCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_OTHER]: getOtherCodeKotlinSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS]: getTradPlusCodeKotlinSnippet,
};

const getKotlinCodeSnippet = mediator => {
    const codeSnippetGetter = kotlinAdMonetizationCodeSnippetsGetters[mediator];
    return {
        markedCode: codeSnippetGetter(),
        codeLanguage: KOTLIN,
        placeholders: {},
        customTitle: KOTLIN,
    };
};

const getKotlinAdMonAdMobSnippet = () => getKotlinCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_ADMOB);
const getKotlinAdMonUnitySnippet = () => getKotlinCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_UNITY);
const getKotlinAdMonApplovinSnippet = () => getKotlinCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN);
const getKotlinAdMonOtherSnippet = () => getKotlinCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_OTHER);
const getKotlinAdMonTradPlusSnippet = () => getKotlinCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS);

export {
    getKotlinAdMonAdMobSnippet,
    getKotlinAdMonApplovinSnippet,
    getKotlinAdMonUnitySnippet,
    getKotlinAdMonOtherSnippet,
    getKotlinAdMonTradPlusSnippet,
};
