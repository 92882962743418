import { createSelector } from 'reselect';
import { getTranslate as getTranslateHelper, getActiveLanguage } from 'react-localize-redux';

const getLocale = state => state.localize;

const getTranslate = createSelector([getLocale], locale => {
    return getTranslateHelper(locale);
});

const getSelectedLanguage = createSelector([getLocale], locale => {
    return getActiveLanguage(locale).code;
});

export { getLocale, getTranslate, getSelectedLanguage };
