import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import MarkdownImageRenderer from './MarkdownImageRenderer';
import css from './Markdown.css';

class Markdown extends React.PureComponent {
    render() {
        const { source, customRenderers, styles } = this.props;
        const { imageRendererStyle, listItemRendererStyle } = styles;
        const rendererMapping = {
            image: (props) => <MarkdownImageRenderer {...props} style={imageRendererStyle} />,
            listItem: (props) => <li {...props} style={listItemRendererStyle} />,
            list: (props) => <ol {...props} />,
        };
        const renderers = {};
        customRenderers.forEach((renderer) => {
            renderers[renderer] = rendererMapping[renderer];
        });
        return (
            <div className={css.container}>
                <ReactMarkdown source={source} renderers={renderers} />
            </div>
        );
    }
}

Markdown.propTypes = {
    source: PropTypes.string,
    customRenderers: PropTypes.arrayOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.any),
};

Markdown.defaultProps = {
    source: '',
    customRenderers: [],
    styles: {},
};

export default Markdown;
