import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Table from '../../../components/widgets/Table';
import css from './sdkConfigurationStepPages.css';
import { TableWidgetMetadataShape } from '../../../global/widgetTypes';

const SDKConfigurationQuestionsTable = ({ tableMetadata, tableData, zebraTable, className }) => {
    return (
        <Table
            zebraTable={zebraTable}
            showInnerBorder={false}
            tableClass={classNames(css.table, className)}
            theadClass={css.theadClass}
            metadata={tableMetadata}
            data={tableData}
            rowHoverEnabled={false}
        />
    );
};

SDKConfigurationQuestionsTable.propTypes = {
    tableMetadata: TableWidgetMetadataShape.isRequired,
    tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
    zebraTable: PropTypes.bool,
    className: PropTypes.string,
};

SDKConfigurationQuestionsTable.defaultProps = {
    zebraTable: false,
    className: '',
};

export default SDKConfigurationQuestionsTable;
