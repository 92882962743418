import React from 'react';
import css from './content.css';
import Step from '../../../../../../components/widgets/Step';
import StepsList from '../../../../../../components/widgets/StepsList';
import CodeBlock from '../../../../../../components/partials/CodeBlock';
import { Label } from '../../../../../../components/widgets';
import { useSDKCodeSnippetContext } from '../../../../codeSnippets/context/useSDKCodeSnippetContext';
import ScreenshotStepThree from '../../../../../../resources/png/sdk-planning/swift-bridger.png';
import ScreenshotStepFive from '../../../../../../resources/png/sdk-planning/objective-c-bridger.png';
import { ADD_CODE_SNIPPET, COCOA_PODS, defaultConfigIOS, IMPORT_CODE_SNIPPET, INSTALL_CODE_SNIPPET } from './consts';
import { sendMixpanelEventSdkIntegration } from '../../../../utils';
import { IOS_NATIVE, SDK_INTEGRATION_SECTIONS } from '../../../../consts';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../../consts';

const { CODEBLOCK_THEME_TOGGLED, SHOW_KEYS, COPY_CODE } = SDK_INTEGRATION_MIXPANEL_EVENTS;
const { GET_SDK_FILES } = SDK_INTEGRATION_SECTIONS;

const CocoPods = () => {
    const { codeBlockDarkTheme, toggleCodeBlockTheme } = useSDKCodeSnippetContext();

    const handleSendMixpanelEvent = event => sendMixpanelEventSdkIntegration(event, GET_SDK_FILES, IOS_NATIVE);

    const handleToggleCodeBlockTheme = toggle => {
        toggleCodeBlockTheme(toggle);
        handleSendMixpanelEvent(CODEBLOCK_THEME_TOGGLED, {
            step: GET_SDK_FILES,
        });
    };

    return (
        <div className={css.container}>
            <StepsList>
                <Step
                    className={css.step}
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_1.TITLE"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...ADD_CODE_SNIPPET}
                        {...defaultConfigIOS}
                        customTitle={COCOA_PODS}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_2.TITLE">
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_2.TEXT"
                        className={css.doubleMargin}
                    />
                    <CodeBlock
                        {...INSTALL_CODE_SNIPPET}
                        {...defaultConfigIOS}
                        customTitle={COCOA_PODS}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_3.TITLE">
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_3.TEXT"
                        className={css.doubleMargin}
                    />
                    <img className={css.landscapeImage} src={ScreenshotStepThree} />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_4.TITLE"
                    titleClassName={css.doubleMargin}>
                    <CodeBlock
                        {...IMPORT_CODE_SNIPPET}
                        {...defaultConfigIOS}
                        customTitle={COCOA_PODS}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_5.TITLE">
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.COCOAPODS.STEP_5.TEXT"
                        className={css.doubleMargin}
                    />
                    <img className={css.landscapeImage} src={ScreenshotStepFive} />
                </Step>
            </StepsList>
        </div>
    );
};

export default CocoPods;
