import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withLocalize, Translate } from 'react-localize-redux';
import css from './style.css';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import SingularButton from '../../components/widgets/SingularButton';
import CheckMark from '../../resources/icons/check_mark.svg';
import {
    setPanelShownClasses,
    trackTrialStatusCloseClick,
    trackTrialStatusCTAClick,
    STATUS_PANEL_CLOSED_DATE,
    trackTrialCTAClick,
} from '../utils';

function TrialStatusPanel({ show, setShow, trialRealDaysLeft, userData }) {
    const panelContainerClass = 'trial-status-panel-container';
    const panelShownClass = 'trial-status-panel-shown';
    const panelContainer = document.getElementById(panelContainerClass);
    const [upgradeButtonClicked, setUpgradeButtonClicked] = useState(false);

    useEffect(() => {
        setPanelShownClasses(panelContainerClass, panelShownClass, show);
        if (show) {
            let innerPanelShownClass;
            if (upgradeButtonClicked) {
                innerPanelShownClass = 'trial-upgrade-clicked';
            } else if (trialRealDaysLeft <= 7) {
                innerPanelShownClass = 'trial-days-left-7';
            } else if (trialRealDaysLeft <= 15) {
                innerPanelShownClass = 'trial-days-left-15';
            } else {
                innerPanelShownClass = 'trial-days-left-default';
                setShow(false, trialRealDaysLeft);
            }
            setPanelShownClasses(panelContainerClass, innerPanelShownClass, show);
        }
    }, [show, trialRealDaysLeft, upgradeButtonClicked]);

    const closePanel = () => {
        localStorage.setItem(STATUS_PANEL_CLOSED_DATE, new Date().toDateString());
        setShow(false, trialRealDaysLeft);
    };

    const changePanelOnUpgrade = () => {
        // Use timeouts so that panel class will change before the panel is shown again
        setShow(false);
        setTimeout(() => {
            setPanelShownClasses(panelContainerClass, 'trial-upgrade-clicked', show);
            setUpgradeButtonClicked(true);
        }, 300);
        setTimeout(() => {
            setShow(true);
        }, 400);
    };

    const el = (
        <div>
            {!upgradeButtonClicked && (
                <span className={css.trialStatusPanel}>
                    <div className={css.label}>
                        <Translate id="STATIC.PAGES.TRIAL_STATUS_PANEL.PANEL_TEXT" />
                    </div>
                    <SingularButton
                        type="link"
                        className={css.upgradeButton}
                        text="STATIC.PAGES.TRIAL_STATUS_PANEL.PANEL_OPEN_DRIFT_BUTTON"
                        onClick={() => {
                            trackTrialCTAClick(
                                userData.email,
                                userData.first_name,
                                userData.last_name,
                                userData.company
                            );
                            changePanelOnUpgrade();
                            trackTrialStatusCTAClick(trialRealDaysLeft);
                        }}
                    />
                    <XIcon
                        className={css.XIcon}
                        onClick={event => {
                            event.stopPropagation();
                            trackTrialStatusCloseClick(trialRealDaysLeft);
                            closePanel();
                        }}
                    />
                </span>
            )}
            {upgradeButtonClicked && (
                <span className={css.trialStatusPanel}>
                    <CheckMark className={css.checkmark} />
                    <div className={css.thankYouLabel}>
                        <Translate id="STATIC.PAGES.TRIAL_STATUS_PANEL.PANEL_UPGRADED_TEXT" />
                    </div>
                </span>
            )}
        </div>
    );

    return panelContainer ? ReactDOM.createPortal(el, panelContainer) : null;
}

TrialStatusPanel.propTypes = {
    show: PropTypes.bool,
};

TrialStatusPanel.defaultProps = {
    show: false,
};

export default withLocalize(TrialStatusPanel);
