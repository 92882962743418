import { isUserTiEnabled } from '../../selectors/user';

const commonDefaults = {
    custom_dimensions: [],
    governanceDimensions: [],
    cohortMetrics: [],
    customEvents: [],
    goalsMetrics: [],
    goalsForecastMetrics: [],
    skanModeledMetrics: [],
    skanModeledCohortMetrics: [],
    skanModeledCohortPeriods: [],
    unifiedCohortPeriods: [],
    dataTypeFields: [],
    metricGroupFields: [],
    cohortMetricGroupFields: [],
    periods: [],
    sourceAttributionType: ['dynamic'],
    withDruidAppend: ['with_append'],
    druidAggregatedDataSourcesMode: ['not_aggregated'],
    crossDeviceDemoCohortType: ['device'],
    fileCombinerDimensions: [],
    granularityLevels: [],
    enrichmentDimensions: [],
    sourceDimensions: [],
    metadataDimensions: [],
    technicalSpecsDimensions: [],
};

const fieldPresets = [
    {
        id: 'network',
        text: 'STATIC.BUTTONS.NETWORK',
        fields: {
            ...commonDefaults,
            dimensions: ['app', 'source', 'os', 'adn_campaign', 'adn_original_currency'],
            metrics: ['adn_impressions', 'adn_cost', 'adn_original_cost'],
            discrepancyMetrics: ['adn_clicks', 'adn_installs'],
            timeBreakdowns: ['all'],
        },
        shouldShow: () => true,
    },
    {
        id: 'tracker',
        text: 'STATIC.BUTTONS.TRACKER',
        fields: {
            ...commonDefaults,
            dimensions: ['app', 'source', 'platform', 'tracker_source_name'],
            metrics: [],
            discrepancyMetrics: ['tracker_clicks', 'tracker_installs'],
            timeBreakdowns: ['all'],
        },
        shouldShow: () => true,
    },
    {
        id: 'ti',
        text: 'STATIC.BUTTONS.TI',
        fields: {
            ...commonDefaults,
            dimensions: ['druid_table_field', 'system_event_date'],
            metrics: [],
            discrepancyMetrics: ['tracker_installs'],
            cohortMetrics: ['revenue'],
            periods: ['1d', '7d', 'ltv'],
            timeBreakdowns: ['day'],
        },
        shouldShow: isUserTiEnabled,
    },
    {
        id: 'dpou',
        text: 'STATIC.BUTTONS.DPOU',
        fields: {
            ...commonDefaults,
            dimensions: ['event_type', 'source_attribution_type', 'data_pull_type'],
            metrics: [],
            discrepancyMetrics: ['tracker_installs'],
            cohortMetrics: ['revenue'],
            periods: ['1d', '7d', 'ltv'],
            timeBreakdowns: ['day'],
        },
        shouldShow: isUserTiEnabled,
    },
];

const ReportPresetButton = 'ReportPresetButton';

export const getFieldPresetButtons = reportType => {
    return fieldPresets.map(preset => {
        return {
            id: preset.id,
            componentClass: ReportPresetButton,
            classType: 'container',
            props: {
                preset,
                reportType,
            },
        };
    });
};
