import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import Drawer from '@mui/material/Drawer';
import XIcon from '../../resources/svg/close_icon.svg';
import css from './Shelf.css';
import SingularButton from './SingularButton';
import OverflowingText from './OverflowingText';

const DISABLED_PRIMARY_BUTTON_STYLE = {
    backgroundColor: '#CCC', // gray400
};

class Shelf extends React.Component {
    constructor(props) {
        super(props);
        this.handleCloseEvent = this._handleCloseEvent.bind(this);
    }

    _handleCloseEvent(event, closeType) {
        const { onClose, closeOnClickOutside } = this.props;
        closeOnClickOutside && onClose(closeType);
    }

    render() {
        const {
            headerText,
            headerData,
            headerImagePath,
            HeaderImage,
            headerImageClassName,
            headerClassName,
            footerNavigationButtons,
            shelfSize,
            open,
            imageStatusColor,
            onClose,
            enterAnimationDisabled,
            children,
            contentContainerClass,
            dataTestId,
        } = this.props;
        const shelfSizeClassName = css[`${shelfSize}Shelf`];
        return (
            <Drawer
                anchor="right"
                open={open}
                onClose={this.handleCloseEvent}
                classes={{
                    paperAnchorRight: classNames(css.shelfBody, shelfSizeClassName, {
                        [css.enterAnimationDisabled]: enterAnimationDisabled,
                    }),
                }}
                data-testid={dataTestId}
            >
                <header className={classNames(css.headerContainer, headerClassName)}>
                    {headerImagePath && (
                        <div className={css.headerImageContainer}>
                            <img src={headerImagePath} className={css.headerImage} />
                            {imageStatusColor && (
                                <span className={css.statusCircle} style={{ backgroundColor: imageStatusColor }} />
                            )}
                        </div>
                    )}
                    {HeaderImage && (
                        <div className={css.headerImage}>
                            <HeaderImage className={headerImageClassName} />
                        </div>
                    )}
                    <OverflowingText className={css.headerText}>
                        <Translate id={headerText} data={headerData} />
                    </OverflowingText>
                    <XIcon
                        className={css.closeButton}
                        onClick={() => {
                            onClose();
                        }}
                    />
                </header>
                <div className={classNames(css.contentContainer, contentContainerClass)}>{children}</div>
                {footerNavigationButtons.length > 0 && (
                    <footer className={css.footerContainer}>
                        <div>
                            {footerNavigationButtons.map(component => {
                                if (component.type === 'primaryButton') {
                                    return (
                                        <SingularButton
                                            key={component.text}
                                            type="primary"
                                            text={component.text}
                                            onClick={component.onClick}
                                            showSpinner={component.showSpinner}
                                            showV={component.showV}
                                            disabled={component.disabled}
                                            style={component.disabled ? DISABLED_PRIMARY_BUTTON_STYLE : null}
                                        />
                                    );
                                } else if (component.type === 'cancelButton') {
                                    return (
                                        <SingularButton
                                            key={component.text}
                                            type="flat"
                                            onClick={onClose}
                                            tabIndex={0}
                                            text="STATIC.BUTTONS.CANCEL"
                                            disabled={component.disabled}
                                        />
                                    );
                                }
                                return '';
                            })}
                        </div>
                    </footer>
                )}
            </Drawer>
        );
    }
}
Shelf.propTypes = {
    headerText: PropTypes.string,
    headerData: PropTypes.objectOf(PropTypes.any),
    headerImagePath: PropTypes.string,
    HeaderImage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    headerImageClassName: PropTypes.string,
    footerNavigationButtons: PropTypes.arrayOf(PropTypes.any),
    shelfSize: PropTypes.string,
    open: PropTypes.bool,
    imageStatusColor: PropTypes.string,
    closeOnClickOutside: PropTypes.bool,
    onClose: PropTypes.func,
    // For backwards compatibility this is set to true. setting this to false will enable the enter sliding animation
    enterAnimationDisabled: PropTypes.bool,
    children: PropTypes.node,
    contentContainerClass: PropTypes.string,
    headerClassName: PropTypes.string,
    dataTestId: PropTypes.string,
};

Shelf.defaultProps = {
    headerText: '',
    headerData: {},
    headerImagePath: '',
    footerNavigationButtons: [],
    shelfSize: 'large',
    open: false,
    imageStatusColor: '',
    closeOnClickOutside: true,
    children: null,
    onClose: () => {},
    enterAnimationDisabled: true,
    contentContainerClass: '',
    HeaderImage: undefined,
    headerImageClassName: '',
    headerClassName: '',
    dataTestId: undefined,
};
export default Shelf;
