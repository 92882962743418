import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { ColumnSeries, HighchartsChart, withHighcharts } from 'react-jsx-highcharts';
import { colorByNetwork, shareOfCostPlotOptions } from 'benchmarks/consts';
import { getEmptyCategoryIndexes, mapShareOfCostSeriesValues, seriesFloatValueFormatter } from 'benchmarks/utils';
import ChartTooltip from 'benchmarks/components/chartTooltip';
import ChartLegend from 'benchmarks/components/chartLegend';
import ChartXAxis from 'benchmarks/components/chartXAxis';
import ChartYAxis from 'benchmarks/components/chartYAxis';
import Chart from 'benchmarks/components/chart';

const NetworkShareChart = ({ chartData }) => {
    const { data, labels } = chartData;

    return (
        <HighchartsChart plotOptions={shareOfCostPlotOptions}>
            <ChartLegend reversed />
            <Chart type="bar" height={labels.length * 100} minWidth={200} marginLeft={125} />
            <ChartTooltip isTextHeader categories={labels} />
            <ChartXAxis
                id="network_share_x_axis"
                categories={labels}
                emptyCategoryIndexes={getEmptyCategoryIndexes(data)}
                labelsFormat={null}
                lineWidth={1}
            />
            <ChartYAxis id="share_of_voice_y_axis" labelsEnabled={false}>
                {data.map(({ data: values, label }) => {
                    return (
                        <ColumnSeries
                            formatter={seriesFloatValueFormatter}
                            key={label}
                            name={label}
                            data={mapShareOfCostSeriesValues(values)}
                            color={colorByNetwork[label]}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

NetworkShareChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
};

NetworkShareChart.defaultProps = {
    chartData: {},
};

export default withHighcharts(NetworkShareChart, Highcharts);
