import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Spinner.css';

export const SPINNER_DATA_TEST_ID = 'spinner';

function Spinner({ expanded, style, show, className }) {
    return (
        <div
            className={classNames(css.loader, className, { [css.expanded]: expanded }, { [css.visible]: show })}
            style={style}
            data-testid={SPINNER_DATA_TEST_ID}
        >
            {[...Array(5)].map((x, i) => (
                <div className={css.loader__bar} key={`loaderBar${i}`} />
            ))}
            <div className={css.loader__ball} />
        </div>
    );
}

Spinner.propTypes = {
    show: PropTypes.bool,
    expanded: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
};

Spinner.defaultProps = {
    show: false,
    expanded: false,
    style: {},
    className: '',
};

export default Spinner;
