import { ModelKinds, SLOTS_SIGNS } from '../../consts';
import { getBitLength, getEventSlotName, getMaxNumberOfBuckets } from '../../utils';

export const engagementModelBitsPerEvent = (eventsCount, maxAvailableSlots) => {
    // Distribute remaining-bits among event
    const bitsPerEvents = Math.floor(maxAvailableSlots / eventsCount);

    // For overflow computations - max with 1
    return bitsPerEvents || 1;
};

export const conversionEventsGetUsedSlots = eventIds => {
    return Array(eventIds.length)
        .fill(null)
        .map((_, index) => getEventSlotName(index));
};

const calculateEventOnlyUsedFunnelSlots = eventIds => {
    return Array(getBitLength(eventIds.length)).fill(SLOTS_SIGNS[ModelKinds.FUNNEL]);
};

const engagementGetUsedSlots = (eventIds, maxEventSlots) => {
    const bitsPerEvent = engagementModelBitsPerEvent(eventIds.length, maxEventSlots);

    return eventIds.flatMap((_, i) =>
        Array(bitsPerEvent)
            .fill()
            .map(() => getEventSlotName(i))
    );
};

const GET_USED_SLOTS_FUNCTION_PER_MODEL = {
    [ModelKinds.CONVERSION_EVENTS]: conversionEventsGetUsedSlots,
    [ModelKinds.FUNNEL]: calculateEventOnlyUsedFunnelSlots,
    [ModelKinds.ENGAGEMENT]: engagementGetUsedSlots,
};

export const getUsedSlotsByEventType = (eventsType, eventsIds, maxEventSlots) => {
    const usedSlotsFunction = GET_USED_SLOTS_FUNCTION_PER_MODEL[eventsType];
    return usedSlotsFunction ? usedSlotsFunction(eventsIds, maxEventSlots) : [];
};

export const getMaxSlotsData = (eventsType, maxSlots, eventIds) => {
    let label = `MAX_EVENTS`;
    let tooltip = 'MAX_EVENTS_TOOLTIP';
    let maxEventSlots = maxSlots;
    let bitsPerEvent;

    switch (eventsType) {
        case ModelKinds.ENGAGEMENT:
            label = `MAX_EVENT_COUNT`;
            tooltip = 'MAX_EVENT_COUNT_TOOLTIP';
            bitsPerEvent = engagementModelBitsPerEvent(eventIds.length || 1, maxSlots);
            maxEventSlots = 2 ** bitsPerEvent - 1;
            break;
        case ModelKinds.FUNNEL:
        case ModelKinds.CUSTOM_FUNNEL:
            maxEventSlots = getMaxNumberOfBuckets(maxSlots) - 1;
            break;
        default:
            break; // ModelKinds.CONVERSION_EVENTS
    }

    return {
        slots: maxEventSlots,
        label: `STATIC.PAGES.SKADNETWORK.${label}`,
        tooltip: `STATIC.PAGES.SKADNETWORK.${tooltip}`,
    };
};
