import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GET_PARTNERS, GET_CONFIGURED_PARTNERS, GET_ORGANIZATION_OPTIONS } from '../queries';
import { getAdnetworkDisplayName, getPartnersOptions, getUseNewShelfOption } from '../../utils/adNetworks';
import { Spinner } from '../../components/widgets';
import { EMPTY_STATE_TOP_PARTNERS } from '../emptyStateUtils';
import { PARTNER_CONFIGURATION_EVENT_PREFIX } from '../../utils/general';
import AdNetworksEmptyState from '../../components/widgets/AdNetworksEmptyState';
import { PageHeader, PageWrapper } from '../../components/partials';
import css from './Content.css';
import PartnerAppSitesSummaryFactory from './summary/PartnerAppSitesSummaryFactory';
import PartnersList from '../../components/partials/VerticalList/PartnersList';
import { route } from '../../utils/routerUtils';
import TopArea from './TopArea';
import { FilterOptions } from './Filters';
import { getOrganizationLogo, isUserAdNetwork } from '../../selectors/user';
import { OLD_PARTNER_CONFIG_URL, BACK_TO_FILTER_ROUTE_PARAM, BACK_TO_PARTNER_ROUTE_PARAM, FAQ_LINKS } from '../consts';
import DefaultOrgLogo from '../../../../dashboard/img/Singularlogo.png';
import PartnerConfigurationShelf from './shelf/PartnerConfigurationShelf';
import { useFetch } from '../../utils/customHooks';
import AppsAPI from '../../services/apps';
import { trackPartnerConfigMixpanelEvent } from './shelf/utils';

// The query has never been run before and the request is now pending
const FIRST_LOADING_NETWORK_STATUS = 1;

const appsApi = new AppsAPI();

export default function Content() {
    const organizationLogo = useSelector(state => getOrganizationLogo(state));
    const organizationOptionsResponse = useQuery(GET_ORGANIZATION_OPTIONS);

    const [selectedPartnerId, setSelectedPartnerId] = useState(null);
    const [editedPartnerId, setEditedPartnerId] = useState(null);
    const [editedSiteId, setEditedSiteId] = useState(null);
    const [editedAgencyId, setEditedAgencyId] = useState(null);
    const [isDuplicate, setIsDuplicate] = useState(null);
    const [filter, setFilter] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const isPartner = useSelector(state => isUserAdNetwork(state));

    const partnersResponse = useQuery(GET_PARTNERS);
    const configuredPartnersResponse = useQuery(GET_CONFIGURED_PARTNERS, { notifyOnNetworkStatusChange: true });
    const [appsResponse, getApps] = useFetch(appsApi.getAttributionApps);

    const { configuredPartners = [] } = configuredPartnersResponse.data || {};
    const { partners = [] } = partnersResponse.data || {};
    const partnersOptions = getPartnersOptions(partners);
    const formattedConfiguredPartners = configuredPartners.map(partner => ({
        ...partner,
        displayName: getAdnetworkDisplayName(partner.name, partner.displayName),
        options: partners.find(p => p.id === partner.id)?.options || {},
    }));

    const filteredConfiguredPartners =
        filter?.option !== FilterOptions.PARTNER
            ? formattedConfiguredPartners
            : formattedConfiguredPartners.filter(({ displayName }) => displayName.toLowerCase().includes(filter.text.toLowerCase()));

    const selectedPartner = filteredConfiguredPartners.find(({ id }) => id === selectedPartnerId);
    const editedPartner = partners.find(({ id }) => id === editedPartnerId);

    const onFilterChanged = ({ option, text }) => {
        const previousOption = filter?.option;
        const previousText = filter?.text;
        setFilter({ option, text });

        // Disable the selected partner so the auto selection will select the first partner after the filter.
        setSelectedPartnerId(null);

        // should not re-fetch when filter text is empty
        if (!previousText && !text) return;

        if (option === FilterOptions.APP) {
            configuredPartnersResponse.refetch({ appFilter: text });
        } else if (previousOption === FilterOptions.APP) {
            // if there was a filter by app but now there isn't, re-fetch the configured partners without the filter
            configuredPartnersResponse.refetch({ appFilter: null });
        }

        trackPartnerConfigMixpanelEvent('Filter', null, null, {
            type: option === FilterOptions.APP ? 'App' : 'Partner',
        });
    };

    // check if we got back from partner config angular page
    useEffect(async () => {
        trackPartnerConfigMixpanelEvent('Page Loaded');

        const backToFilter = JSON.parse(searchParams.get(BACK_TO_FILTER_ROUTE_PARAM));
        if (backToFilter) {
            onFilterChanged(backToFilter);
        }

        if (searchParams.has(BACK_TO_FILTER_ROUTE_PARAM)) {
            searchParams.delete(BACK_TO_FILTER_ROUTE_PARAM);
            setSearchParams(searchParams);
        }

        const partnerId = parseInt(searchParams.get('partner'), 10);
        if (!partnerId) {
            return;
        }

        // should re-fetch when configured partners are cached but a new partner was saved
        const isNewPartner = configuredPartners.every(partner => partner.id !== partnerId);
        if (!configuredPartnersResponse.loading && isNewPartner) {
            await configuredPartnersResponse.refetch();
        }

        setSelectedPartnerId(partnerId);

        const siteId = parseInt(searchParams.get('site'), 10);
        if (siteId) {
            const agencyId = searchParams.get('agencyId') || '';
            if (agencyId) {
                setEditedAgencyId(agencyId);
            }
            setEditedPartnerId(partnerId);
            setEditedSiteId(siteId);
        }
    }, []);

    // background prefetch apps
    useEffect(() => getApps(false, true), []);

    useEffect(() => {
        if (!selectedPartnerId) return;

        const partnerId = parseInt(searchParams.get('partner'), 10);
        const siteId = parseInt(searchParams.get('site'), 10);
        const agencyId = searchParams.get('agencyId') || '';

        if (partnerId !== selectedPartnerId || siteId !== editedSiteId || agencyId !== editedAgencyId) {
            setSearchParams({
                partner: selectedPartnerId,
                ...(editedSiteId ? { site: editedSiteId } : {}),
                ...(editedAgencyId ? { agencyId: editedAgencyId } : {}),
            });
        }
    }, [selectedPartnerId, editedSiteId]);

    const onRouteToOldPage = params => {
        route(OLD_PARTNER_CONFIG_URL, {
            ...params,
            [BACK_TO_PARTNER_ROUTE_PARAM]: selectedPartnerId,
            [BACK_TO_FILTER_ROUTE_PARAM]: JSON.stringify(filter),
        });
    };

    const onAppSiteForPartner = (partnerId, siteId = null, agencyId = null, duplicate = false) => {
        const partner = partners.find(({ id }) => id === partnerId);
        const useNewShelf = getUseNewShelfOption(organizationOptionsResponse.data, partner);

        if (useNewShelf) {
            setEditedAgencyId(agencyId);
            setEditedPartnerId(partnerId);
            setEditedSiteId(siteId);
            setIsDuplicate(duplicate);
        } else {
            onRouteToOldPage({
                source: partnerId,
                ...(siteId ? { site: siteId } : {}),
                ...(agencyId ? { agencyId } : {}),
            });
        }
    };

    // we don't want to show loading when re-fetching because of filters
    if (partnersResponse.loading || configuredPartnersResponse.networkStatus === FIRST_LOADING_NETWORK_STATUS) {
        return <Spinner show expanded />;
    }

    // we don't want to show empty state when there is no partners because of filters
    if (!configuredPartners.length && !filter && !isPartner && !editedPartner) {
        return (
            <PageWrapper>
                <PageHeader text="STATIC.PAGE_HEADERS.PARTNER_CONFIGURATION" subText={FAQ_LINKS} />
                <AdNetworksEmptyState
                    adNetworkOptions={partnersOptions}
                    emptyStateAdNetworks={EMPTY_STATE_TOP_PARTNERS}
                    translationPageKey="PARTNER_CONFIGURATION"
                    mixpanelPrefix={PARTNER_CONFIGURATION_EVENT_PREFIX}
                    onAdNetworkSelectionChange={({ id }) => onAppSiteForPartner(id)}
                    orgLogoPath={organizationLogo || DefaultOrgLogo}
                />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper className={css.container} allowDropDownPageScrolling disablePadding>
            <TopArea
                filter={filter}
                filterLoading={configuredPartnersResponse.loading}
                onFilterChanged={onFilterChanged}
                partnersOptions={partnersOptions}
                onAddSiteForPartner={onAppSiteForPartner}
            />
            <div className={css.mainArea}>
                <PartnersList
                    autoSelectItem
                    selectedPartner={selectedPartnerId}
                    partners={filteredConfiguredPartners}
                    className={css.leftArea}
                    onPartnerSelected={setSelectedPartnerId}
                    filter={filter?.option === FilterOptions.PARTNER ? filter.text : ''}
                />
                <div className={css.rightArea}>
                    {selectedPartner ? (
                        <PartnerAppSitesSummaryFactory
                            partner={selectedPartner}
                            filter={filter?.option === FilterOptions.APP ? filter.text : ''}
                            onAppSiteForPartner={onAppSiteForPartner}
                            onRouteToOldPage={onRouteToOldPage}
                        />
                    ) : null}
                    {editedPartner ? (
                        <PartnerConfigurationShelf
                            open
                            isDuplicate={isDuplicate}
                            partner={editedPartner}
                            siteId={editedSiteId}
                            agencyId={editedAgencyId}
                            apps={appsResponse.data?.apps}
                            loading={appsResponse.isLoading}
                            onClose={() => {
                                setEditedSiteId(null);
                                setEditedPartnerId(null);
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </PageWrapper>
    );
}
