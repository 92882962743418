import { connect } from 'react-redux';
import AddButton from '../../components/widgets/AddButton';
import { navigationButtonClicked } from '../../actions/navigation';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...ownProps,
        onClick: () => {
            dispatch(navigationButtonClicked(ownProps));
            if (typeof ownProps.onClick === 'function') {
                ownProps.onClick();
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddButton);
