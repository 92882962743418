import API from '../../../services/api';

let instance = null;
const api = new API();

const CAMPAIGN_REPORT_ENDPOINT_PREFIX = 'api/quick_report/';
const CAMPAIGN_REPORT_SOURCES_ENDPOINT = `${CAMPAIGN_REPORT_ENDPOINT_PREFIX}sources`;
const CAMPAIGN_REPORT_CAMPAIGNS_ENDPOINT = `${CAMPAIGN_REPORT_ENDPOINT_PREFIX}campaigns`;

export default class CampaignReportAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getSources() {
        return api.getJson(CAMPAIGN_REPORT_SOURCES_ENDPOINT, {
            method: 'GET',
            credentials: 'include',
            params: {},
        });
    }

    getCampaigns(source, startDate, endDate) {
        return api.getJson(CAMPAIGN_REPORT_CAMPAIGNS_ENDPOINT, {
            method: 'GET',
            credentials: 'include',
            params: {
                source,
                start_date: startDate,
                end_date: endDate,
            },
        });
    }
}
