import update from 'immutability-helper';
import {
    UPDATE_DATA_SOURCE_DATA,
    UPDATE_2PHASE_KEYS,
    UPDATE_ADNETWORKS,
    UPDATE_SINGLE_DATA_SOURCE_DATA,
    DELETE_SINGLE_DATA_SOURCE_DATA,
    UPDATE_UAN_ACTION,
    UPDATE_IS_ETL,
    UPDATE_UAN_DATA,
    OPEN_SHELF,
    CLOSE_SHELF,
    API_REQUEST_FAILED,
    SAVE_UAN_DISPLAY_ERROR,
    SAVE_UAN_DATA,
    SAVE_UAN_SUCCEEDED,
    SET_UPDATED_UAN,
    SET_AUTH_POPUP_URL,
    UPDATE_UAN_ACCOUNTS,
    SEND_SCRAPE_JOB_STATUS_UPDATED,
    UPDATE_PARTNERS_IDS,
    REDIRECT_TO_PC,
    UPDATE_UAN_DESCRIPTION,
} from './actions';

const initialState = {
    uans: [],
    export2phaseTaskKeys: {},
    adNetworks: [],
    partnersIds: new Set(),
};

const dataSourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DATA_SOURCE_DATA: {
            return update(state, {
                uans: {
                    $set: action.data.uans,
                },
            });
        }
        case UPDATE_IS_ETL: {
            return update(state, {
                isETL: {
                    $set: action.isETL,
                },
            });
        }
        case UPDATE_SINGLE_DATA_SOURCE_DATA: {
            const uanToUpdateIndex = state.uans.findIndex(
                uan => uan.uan_id.toString() === action.data.uan.uan_id.toString()
            );
            if (uanToUpdateIndex >= 0) {
                return update(state, {
                    uans: {
                        $splice: [[uanToUpdateIndex, 1]],
                        $push: [action.data.uan],
                    },
                    uanSaveErrorMessage: {
                        $set: '',
                    },
                });
            } else {
                return update(state, {
                    uans: {
                        $push: [action.data.uan],
                    },
                    uanSaveErrorMessage: {
                        $set: '',
                    },
                });
            }
        }
        case SET_UPDATED_UAN: {
            return update(state, {
                updatedUanId: {
                    $set: action.uanId,
                },
            });
        }
        case DELETE_SINGLE_DATA_SOURCE_DATA: {
            return update(state, {
                uans: {
                    $splice: [[state.uans.findIndex(uan => uan.uan_id.toString() === action.data.uanId.toString()), 1]],
                },
            });
        }
        case UPDATE_2PHASE_KEYS: {
            return update(state, {
                export2phaseTaskKeys: {
                    $merge: {
                        [action.data.uanId]: action.data.taskId,
                    },
                },
                export2phaseLatestTaskKey: {
                    $set: action.data.taskId,
                },
            });
        }
        case UPDATE_ADNETWORKS: {
            return update(state, {
                adNetworks: {
                    $set: action.data.adnetworks,
                },
            });
        }
        case UPDATE_PARTNERS_IDS: {
            return update(state, {
                partnersIds: {
                    $set: action.data,
                },
            });
        }
        case REDIRECT_TO_PC: {
            return update(state, {
                redirectToPartnerConfiguration: {
                    $set: action.partnerSourceId,
                },
            });
        }
        case UPDATE_UAN_ACTION: {
            return update(state, {
                uanAction: {
                    $set: {
                        type: action.data.type,
                        uan: action.data.uan,
                    },
                },
            });
        }
        case UPDATE_UAN_DATA: {
            return update(state, {
                selectedUanData: {
                    $set: action.data.uan_data,
                },
            });
        }
        case UPDATE_UAN_ACCOUNTS: {
            return update(state, {
                uanAccountsData: {
                    $set: action.accountsData,
                },
            });
        }
        case OPEN_SHELF: {
            return update(state, {
                shelf: {
                    $set: { open: true },
                },
                uanSaveSucceeded: {
                    $set: false,
                },
                apiRequestFailed: {
                    $set: false,
                },
                uanSaveErrorMessage: {
                    $set: '',
                },
                sendScrapeJobStatus: {
                    $set: {},
                },
                partnerSourceId: {
                    $set: null,
                },
            });
        }
        case CLOSE_SHELF: {
            return update(state, {
                shelf: {
                    $set: { open: action.value },
                },
                selectedUanData: {
                    $set: null,
                },
                uanAccountsData: {
                    $set: {},
                },
                authPopupUrl: {
                    $set: action.url,
                },
                manualScrapeSent: {
                    $set: action.manualScrapeSent,
                },
            });
        }
        case SAVE_UAN_DATA: {
            return update(state, {
                uanSaveErrorMessage: {
                    $set: '',
                },
                uanSaveSucceeded: {
                    $set: false,
                },
            });
        }
        case API_REQUEST_FAILED: {
            return update(state, {
                apiRequestFailed: {
                    $set: true,
                },
            });
        }
        case SAVE_UAN_SUCCEEDED: {
            return update(state, {
                uanSaveSucceeded: {
                    $set: true,
                },
            });
        }
        case UPDATE_UAN_DESCRIPTION: {
            const uanToUpdate = state.uans.find(uan => uan.uan_id.toString() === action.uanId.toString());
            uanToUpdate.sub_text_extra = action.description;
            return update(state, {
                uans: {
                    $set: state.uans,
                },
            });
        }
        case SEND_SCRAPE_JOB_STATUS_UPDATED: {
            return update(state, {
                sendScrapeJobStatus: {
                    $set: action.sendScrapeJobStatus,
                },
            });
        }
        case SAVE_UAN_DISPLAY_ERROR: {
            return update(state, {
                uanSaveErrorMessage: {
                    $set: action.error.value,
                },
            });
        }
        case SET_AUTH_POPUP_URL: {
            return update(state, {
                authPopupUrl: {
                    $set: action.url,
                },
            });
        }
        default:
            return state;
    }
};

export default dataSourcesReducer;
