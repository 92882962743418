import moment from 'moment';
import { all, call, put, select, takeEvery, delay } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import TeamManagementAPI from './service';
import {
    addMessageToGroup,
    DELETE_MEMBER_CLICKED,
    getOrgUsers,
    getOrgAgencies,
    saveMemberError,
    saveMemberSuccess,
    modifyInvitationState,
    RESEND_INVITATION,
    teamManagementLoaded,
    updateDataSourceData,
    userDisabled,
    userInviteRevoked,
    closeShelf,
    updateFeaturesList,
    WIZARD_FINISHED,
    CLOSE_SHELF_CALLED,
    MANAGE_PERMISSIONS_CALLED,
    managePermissions,
} from './actions';
import { updateFields } from '../actions/fields';
import { getTranslate } from '../selectors/locale';
import { managePermissionsData, getWizardFinished, getShelfState, getActiveMemberType } from './selectors';
import {
    getRoleDisplayName,
    getStateFromUserDetails,
    getUserDisplayName,
    MemberGroupTypes,
    MemberTypes,
} from './utils';
import { updateAppsData } from '../actions/apps';
import AppsAPI from '../services/apps';
import DataSourcesAPI from '../services/dataSources';
import FeaturesAPI from '../services/features';

const appsApi = new AppsAPI();
const dataSourcesApi = new DataSourcesAPI();
const sagas = new TeamManagementAPI();
const featuresApi = new FeaturesAPI();

function* updatePageData() {
    const [usersResp, agenciesResp, dataResp, appsResp, featuresList] = yield all([
        call(sagas.getOrgUsers),
        call(sagas.getOrgAgencies),
        call(dataSourcesApi.getBaseSources, {}),
        call(appsApi.getApps, true),
        call(featuresApi.getFeaturesList),
    ]);
    yield put(getOrgUsers(usersResp));
    yield put(getOrgAgencies(agenciesResp));
    yield put(updateAppsData(appsResp));
    yield put(updateDataSourceData(dataResp));
    yield put(updateFeaturesList(featuresList));
    yield put(updateFields([false, [], true]));
    yield put(teamManagementLoaded());
}

function* updateOrgUsers(disableBrowserCache) {
    const response = yield call(sagas.getOrgUsers, disableBrowserCache);
    yield put(getOrgUsers(response));
}

function* updateOrgAgencies() {
    const response = yield call(sagas.getOrgAgencies, {});
    yield put(getOrgAgencies(response));
}

function* handleDeleteUser(user) {
    let calculatedResult = false;
    let group = '';
    try {
        if (user.status === 'Member') {
            const { result } = yield call(sagas.disableUser, user.organization_user_id);
            if (result) {
                yield put(userDisabled(user));
                calculatedResult = true;
                group = 'active';
            }
        } else {
            const { result } = yield call(sagas.revokeInvitation, user.invite_id);
            if (result) {
                yield put(userInviteRevoked(user));
                calculatedResult = true;
                group = 'pending';
            }
        }
    } catch (e) {
        // TODO: Handle error?
        yield Promise.resolve();
    }
    if (calculatedResult) {
        const translate = yield select(getTranslate);
        yield put(
            addMessageToGroup(
                group,
                translate('STATIC.PAGES.TEAM_MEMBERS.USER_DELETED', { userName: getUserDisplayName(user) }),
                'info'
            )
        );
    }
}

function* handleDeleteAgency(agency) {
    try {
        yield call(sagas.deleteOrgAgency, agency.id);
        yield call(updateOrgAgencies);
    } catch (e) {
        yield put(saveMemberError(e.error));
    }
}

function* handleDeleteMemberClicked({ member }) {
    const activeMemberType = yield select(getActiveMemberType);

    if (activeMemberType === MemberTypes.AGENCY) {
        yield call(handleDeleteAgency, member);
    } else {
        yield call(handleDeleteUser, member);
    }
}

function* handleResendInvitation(action) {
    const { user } = action;
    try {
        yield put(modifyInvitationState(user, { inProgress: true, disabled: true }));
        const [{ result }, delayResult] = yield all([call(sagas.resendInvitation, user.invite_id), delay(3000)]);
        if (result) {
            // TODO: Handle success?
            const translate = yield select(getTranslate);
            yield put(
                addMessageToGroup(
                    MemberGroupTypes.pending,
                    translate('STATIC.PAGES.TEAM_MEMBERS.RE_INVITE_SENT', { userName: getUserDisplayName(user) }),
                    'info'
                )
            );
            yield put(
                modifyInvitationState(user, {
                    inProgress: false,
                    disabled: true,
                    last_re_invited_at: moment.utc() / 1000,
                })
            );
        }
    } catch (e) {
        // TODO: Handle error?
        yield Promise.resolve();
    }
}

function* handleCreateNewUser(role, registrationForm, recaptchaToken) {
    const { type: roleType } = role;
    try {
        const { result } = yield call(sagas.sendInvitation, { ...registrationForm, role, recaptchaToken });
        if (result) {
            const translate = yield select(getTranslate);
            yield put(saveMemberSuccess());
            yield put(
                addMessageToGroup(
                    MemberGroupTypes.pending,
                    translate('STATIC.PAGES.TEAM_MEMBERS.INVITE_INFO', {
                        userName: getUserDisplayName(registrationForm, {
                            first_name: 'firstName',
                            last_name: 'lastName',
                        }),
                        role: getRoleDisplayName(roleType),
                    }),
                    'info'
                )
            );
            yield delay(300);
            yield call(updateOrgUsers, true);
        }
    } catch (e) {
        // TODO: handle error ?
        yield put(saveMemberError(e.error));
    }
}

function* handleManageUserPermissions(editUserId, role, registrationForm) {
    try {
        const { result } = yield call(sagas.updateOrgUser, editUserId, role);
        if (result) {
            const translate = yield select(getTranslate);
            yield put(saveMemberSuccess());
            yield put(
                addMessageToGroup(
                    'active',
                    translate('STATIC.PAGES.TEAM_MEMBERS.MODIFIED_TYPE_INFO', {
                        userName: getUserDisplayName(registrationForm, { first_name: 'name', last_name: 'lastname' }),
                        role: getRoleDisplayName(role.type),
                    }),
                    'info'
                )
            );
            yield delay(300);
            yield call(updateOrgUsers);
        }
    } catch (e) {
        // handle error ?
        yield put(saveMemberError(e.error));
    }
}

function* handleManageAgencyPermissions({ id, name }, role) {
    try {
        yield call(sagas.updateOrgAgency, id, role);
        const translate = yield select(getTranslate);
        yield put(saveMemberSuccess());
        yield put(
            addMessageToGroup(
                MemberGroupTypes.active,
                translate('STATIC.PAGES.TEAM_MEMBERS.AGENCY_SAVED_MESSAGE', { agencyName: name }),
                'info'
            )
        );
        yield delay(300);
        yield call(updateOrgAgencies);
    } catch (e) {
        yield put(saveMemberError(e.error));
    }
}

function* handleWizardFinished(action) {
    const activeMemberType = yield select(getActiveMemberType);
    const { editMemberId, registrationForm, role } = yield select(getWizardFinished);

    if (activeMemberType === MemberTypes.AGENCY) {
        yield call(handleManageAgencyPermissions, registrationForm, role);
        return;
    }

    if (editMemberId) {
        yield call(handleManageUserPermissions, editMemberId, role, registrationForm);
    } else {
        const { recaptchaToken } = action;
        yield call(handleCreateNewUser, role, registrationForm, recaptchaToken);
    }
}

function* confirmChanges(newPermission, oldPermission) {
    const translate = yield select(getTranslate);
    let confirmed = true;
    if (!isEqual(newPermission, oldPermission)) {
        if (!confirm(translate('STATIC.PAGES.CUSTOM_EVENTS.UNSAVED_CHANGES_WARNING'))) {
            confirmed = false;
        }
    }
    return confirmed;
}

const closeTypes = ['backdropClick', 'escapeKeyDown'];

function* handleShelfClose(action) {
    const { closeType } = action;

    if (!closeType || !closeTypes.includes(closeType)) {
        yield put(closeShelf());
        return;
    }
    const shelfState = yield select(getShelfState);
    const result = yield call(confirmChanges, shelfState.currentState, shelfState.startState);
    if (result) {
        yield put(closeShelf());
    }
}

function* handleManagePermissionsCalled(action) {
    const { user } = action;
    const data = yield select(managePermissionsData);
    const partialState = getStateFromUserDetails(user, data);
    yield put(managePermissions(user, partialState));
}

// ---------------------------------/
//      WATCHERS
// ---------------------------------/

function* onload() {
    yield call(updatePageData);
}

function* watchDeleteUserClicked() {
    yield takeEvery(DELETE_MEMBER_CLICKED, handleDeleteMemberClicked);
}

function* watchResendPermissions() {
    yield takeEvery(RESEND_INVITATION, handleResendInvitation);
}

function* watchWizardFinished() {
    yield takeEvery(WIZARD_FINISHED, handleWizardFinished);
}

function* watchShelfClose() {
    yield takeEvery(CLOSE_SHELF_CALLED, handleShelfClose);
}

function* watchManagePermissionsCalled() {
    yield takeEvery(MANAGE_PERMISSIONS_CALLED, handleManagePermissionsCalled);
}

const watchers = [
    watchDeleteUserClicked,
    watchResendPermissions,
    watchWizardFinished,
    watchShelfClose,
    watchManagePermissionsCalled,
];

export { onload, watchers };
