import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AdminIcon from '../../../resources/svg/admin.svg';
import css from './AdminModeButton.css';
import { setAdminMode } from '../../../actions/user';
import { ANGULAR_ADMIN_MODE_CHANGED_EVENT } from './consts';

export default function AdminModeButton({ isAdminMode }) {
    const dispatch = useDispatch();

    const onClick = () => {
        const newAdminModeState = !isAdminMode;
        dispatch(setAdminMode(newAdminModeState));
        const event = new CustomEvent(ANGULAR_ADMIN_MODE_CHANGED_EVENT, { detail: { enabled: newAdminModeState } });
        window.dispatchEvent(event);
    };

    return (
        <div
            className={classNames(css.adminModeContainer, {
                [css.disabled]: !isAdminMode,
            })}
            onClick={onClick}
        >
            <AdminIcon
                className={classNames(css.adminModeIcon, {
                    [css.disabled]: !isAdminMode,
                })}
            />
            <span>Admin</span>
        </div>
    );
}

AdminModeButton.propTypes = {
    isAdminMode: PropTypes.bool,
};

AdminModeButton.defaultProps = {
    isAdminMode: false,
};
