import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import { Translate, withLocalize } from 'react-localize-redux';
import { Spinner, Shelf, WizardFooter, SingularButton } from '../../components/widgets';
import css from './SharingOptions.pcss';
import Label from "../../components/widgets/Label";
import SharingLink from "./SharingLink";
import EmptyState from '../../resources/svg/empty_state_happy.svg';
import WizardWarningMessage from '../../teamManagement/components/WizardWarningMessage';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => {
            return i * 50;
        },
    },
    exit: {
        y: 20,
        opacity: 0,
    },
});

class AudienceSegmentsSharingOptions extends React.Component {
    constructor(props) {
        super(props);

        this.shelfContainer = React.createRef();
    }

    render() {
        const { segment, links, onCloseSharingOptions } = this.props;
        return (
            <Shelf
                contentRef={(e) => {
                    this.contentRef = e;
                }}
                open={!!segment}
                headerText="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS"
                onClose={onCloseSharingOptions}
                shelfSize="medium"
            >
                {!segment || !links ? (
                    <Spinner show className={css.spinner}/>
                ) : (
                    <div className={css.container}>
                        {/*<WizardWarningMessage show={!!error} showIcon={false} message={error} type="error" />*/}
                        <div className={css.editContainer} ref={this.shelfContainer}>
                            <Label type="shelfHeader" text="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_SHELF_TITLE"/>

                            <div className={css.edit}>
                                <Label
                                    className={css.header}
                                    type="h2"
                                    text="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_SEGMENT_NAME"
                                    textReplace={{segmentName: segment.name, linkCount: links && links.length || 0}}
                                />
                                <SingularButton
                                    onClick={this.props.onCreateSharingLink}
                                    type="secondary"
                                    className={css.addButton}>
                                    <Translate id="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_SHELF_CREATE"/>
                                </SingularButton>

                                {!links || !links.length ? <div className={css.emptyShelfBody}>
                                        <EmptyState />
                                        <Translate id="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_SHELF_EMPTY"/>
                                        <a onClick={this.props.onCreateSharingLink}>
                                            <Translate id="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_SHELF_CREATE_EMPTY"/>
                                        </a>
                                    </div> :
                                    <PoseGroup animateOnMount flipMove={false}>
                                        {links.map((link) => (
                                            <AnimationItem key={link.externalId}>
                                                <SharingLink {...link}
                                                             onCopySharingLink={this.props.onCopySharingLink}
                                                             onRevokeSharingLink={this.props.onRevokeSharingLink}
                                                             onRenameSharingLink={this.props.onRenameSharingLink}/>
                                            </AnimationItem>
                                        ))}
                                    </PoseGroup>
                                }
                            </div>
                        </div>
                    </div>
                )}
                <WizardFooter
                    buttons={[
                        <SingularButton onClick={onCloseSharingOptions}>
                            <Translate id="STATIC.BUTTONS.DONE" />
                        </SingularButton>,
                    ]}
                />
            </Shelf>
        );
    }
}

AudienceSegmentsSharingOptions.propTypes = {
    segment: PropTypes.object,
    links: PropTypes.arrayOf(PropTypes.object),
    onCopySharingLink: PropTypes.func,
    onCreateSharingLink: PropTypes.func,
    onRevokeSharingLink: PropTypes.func,
    onRenameSharingLink: PropTypes.func,
    onCloseSharingOptions: PropTypes.func,
};

AudienceSegmentsSharingOptions.defaultProps = {
    segment: null,
    links: null,
    onCopySharingLink: () => {},
    onCreateSharingLink: () => {},
    onRevokeSharingLink: () => {},
    onRenameSharingLink: () => {},
    onCloseSharingOptions: () => {},
};

export default withLocalize(AudienceSegmentsSharingOptions);
