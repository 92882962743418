import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from '../components/partials';
import { Tabs } from '../components/widgets';
import { FAQ_LINK, INNER_PAGES, SDK_PLANNING } from './consts';
import SDKPlanningSelections from './sdkPlanning/components/SDKPlanningSelections';
import css from './SDKPageHeader.css';
import EmptyState from '../components/partials/EmptyState/EmptyState';
import { EmptyStateImageSrc } from '../components/partials/EmptyState/utils';
import { SDK_PLANNING_EMPTY_STATE_TASKS } from './sdkPlanning/consts';
import { useSDKPageContext } from './context/useSDKPageContext';
import useMixpanelEventOnPageLoad from './hooks/useMixpanelEventOnPageLoad';

const SDKPageHeader = ({ selectedPage, setSelectedPage }) => {
    const { showEmptyState, tasksStatus } = useSDKPageContext();

    useMixpanelEventOnPageLoad('', '', { empty_state: Boolean(showEmptyState) });

    return (
        <>
            <PageHeader
                text="STATIC.PAGES.SDK.HEADER"
                subText={{ faqLink: FAQ_LINK }}
                shouldAddPadding
                sticky
                stickyStyle={{ top: 0, paddingTop: 0 }}
            >
                <div className={css.container}>
                    <Tabs
                        containerClass={css.tabs}
                        selected={selectedPage}
                        onClick={setSelectedPage}
                        tabs={INNER_PAGES.map(type => {
                            return {
                                id: type,
                                label: `STATIC.PAGES.SDK.SUB_HEADERS.${type}`,
                            };
                        })}
                    />
                    {selectedPage === SDK_PLANNING ? (
                        <>
                            {showEmptyState && (
                                <div className={css.spacerEmptyState}>
                                    <EmptyState
                                        titleText="STATIC.PAGES.SDK.SDK_PLANNING.EMPTY_STATE.HEADER"
                                        subTitleText="STATIC.PAGES.SDK.SDK_PLANNING.EMPTY_STATE.SUB_HEADER"
                                        imageSrc={EmptyStateImageSrc.SINGULAR_LADY}
                                        tasksTodo={SDK_PLANNING_EMPTY_STATE_TASKS}
                                        tasksDone={tasksStatus}
                                    />
                                </div>
                            )}
                            <SDKPlanningSelections className={css.selectionsContainer} />
                        </>
                    ) : null}
                </div>
            </PageHeader>
        </>
    );
};

SDKPageHeader.propTypes = {
    selectedPage: PropTypes.string,
    setSelectedPage: PropTypes.func,
};

SDKPageHeader.defaultProps = {
    selectedPage: SDK_PLANNING,
    setSelectedPage: () => {},
};
export default SDKPageHeader;
