import {
    URL_PATTERN_OPERATOR_KEY,
    URL_PATTERN_VALUE_KEY,
    NO_LIMIT,
    PLATFORMS,
    DEVICE_TYPES,
    BannerSortedField,
} from './consts';

import { sortAlphabetically, sortNumeric } from '../utils/sortUtil';

export const getUrlPatternOperatorKey = index => `${URL_PATTERN_OPERATOR_KEY}${index}`;
export const getUrlPatternValueKey = index => `${URL_PATTERN_VALUE_KEY}${index}`;
export const getDeviceTypeKey = (platform, deviceType) => `${platform}-${deviceType}`;

export function getUrlPatternOperatorFromKey(key) {
    const index = key.substr(key.length - 1);
    return getUrlPatternOperatorKey(index);
}

export function getUrlPatternValueFromKey(key) {
    const index = key.substr(key.length - 1);
    return getUrlPatternValueKey(index);
}

export const EMPTY_URL_PATTERN_VALUE = { [getUrlPatternOperatorKey(0)]: null };

export const ALL_DEVICE_TYPES_PER_PLATFORM = PLATFORMS.reduce((prev, curr) => {
    return [...prev, ...DEVICE_TYPES.map(type => getDeviceTypeKey(curr, type))];
}, []);

const getUrlPatternFields = urlPatterns => {
    let urlPatternIndex = 0;
    const urlPatternFields = Object.entries(urlPatterns || {}).reduce((total, [operator, pattern]) => {
        const values = pattern ? pattern.split(',') : [];
        values.forEach(value => {
            if (value) {
                total[getUrlPatternOperatorKey(urlPatternIndex)] = operator;
                total[getUrlPatternValueKey(urlPatternIndex)] = value;
                urlPatternIndex++;
            }
        });

        return total;
    }, {});

    if (!Object.keys(urlPatternFields).length) {
        return {
            showOnAllPages: true,
            ...EMPTY_URL_PATTERN_VALUE,
        };
    }

    return urlPatternFields;
};

export const normalizeInitialValues = configuredBanner => {
    const { url_starts_with, url_ends_with, url_contains, url_not_contains, ...fields } = configuredBanner;

    return {
        ...fields,
        ...getUrlPatternFields({ url_starts_with, url_ends_with, url_contains, url_not_contains }),
    };
};

export const convertToServerBanner = values => {
    const { showOnAllPages, ...valuesToSave } = values;

    const configuredBanner = Object.keys(valuesToSave).reduce((total, key) => {
        // ignore fields
        if (key.startsWith(URL_PATTERN_VALUE_KEY)) {
            return total;
        }

        let currentValueToSave = valuesToSave[key];

        if (currentValueToSave === NO_LIMIT) {
            currentValueToSave = null;
        }

        if (key.startsWith(URL_PATTERN_OPERATOR_KEY)) {
            const urlPatternOperator = currentValueToSave;
            const urlPatternValue = valuesToSave[getUrlPatternValueFromKey(key)];

            if (showOnAllPages || !urlPatternOperator || !urlPatternValue) {
                return total;
            }

            if (!total[urlPatternOperator]) {
                total[urlPatternOperator] = urlPatternValue;
            } else {
                // The server expects a dict with values separated by , ({ 'url_contains': '1,2,3' })
                total[urlPatternOperator] = `${total[urlPatternOperator]},${urlPatternValue}`;
            }

            return total;
        }

        total[key] = currentValueToSave;
        return total;
    }, {});

    return configuredBanner;
};

export const filterBannersByName = (banners, text) => {
    if (!text) {
        return banners;
    }
    const lowerText = text.toLowerCase();
    return banners.filter(banner => banner.name.toLowerCase().includes(lowerText));
};

export const sortBanners = (banners, field, isDescending = false) => {
    const bannersCopy = [...banners];
    const fieldToSortFunc = {
        [BannerSortedField.PRIORITY]: sortNumeric,
        [BannerSortedField.NAME]: sortAlphabetically,
    };

    const sortFunc = fieldToSortFunc[field] || sortAlphabetically;
    bannersCopy.sort((a, b) => sortFunc(a[field], b[field], isDescending));

    return bannersCopy;
};

export const getMaxLengthErrMsg = (name, maxLength) => `${name} must be shorter than ${maxLength} characters`;
