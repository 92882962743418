import { COMPARE_MODE } from '../../components/pages/reports/consts';

export default {
    reports: (dim, adminModeEnabled, reportMode) => {
        return reportMode === COMPARE_MODE ? dim.is_comparison_default : dim.is_default;
    },
    pivot: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    creatives: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    anonymousReport: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    permissions: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    fraudProtected: (dim, adminModeEnabled, reportMode) => {
        if (dim.type === 'metric' || dim.type === 'dimension') {
            return dim.fraud_protected_default;
        } else {
            return dim.is_default;
        }
    },
    fraudSuspicious: (dim, adminModeEnabled, reportMode) => {
        if (dim.type === 'metric' || dim.type === 'dimension') {
            return dim.fraud_suspicious_default;
        } else {
            return dim.is_default;
        }
    },
    fraudRejected: (dim, adminModeEnabled, reportMode) => {
        if (dim.is_cohort) {
            return false;
        }
        if (dim.type === 'metric' || dim.type === 'dimension' || dim.actual_type === 'periods') {
            return !!dim.fraud_rejected_default;
        } else {
            return dim.is_default;
        }
    },
    adMonetization: (dim, adminModeEnabled) => {
        return dim.is_admon_default;
    },
    crossDevice: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    skan: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    skanSummary: (dim, adminModeEnabled, reportMode) => {
        return dim.is_default;
    },
    unifiedReport: (dim, adminModeEnabled) => {
        return typeof dim.is_unified_report_default === 'boolean' ? dim.is_unified_report_default : dim.is_default;
    },
    force: dim => {
        return dim.force_default;
    },
};
