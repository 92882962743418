import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Banner.css';
import SingularButton from './SingularButton';
import CloseIcon from '../../resources/svg/close_icon.svg';
import Label from './Label';

export const BANNER_ANCHOR_POSITIONS = ['topStart', 'bottomStart', 'bottomEnd', 'topEnd'];

const Banner = ({
    open,
    isLoading,
    label,
    subLabel,
    buttonText,
    buttonOnClick,
    header,
    onClose,
    anchor,
    showButtonV,
    leftIcon,
    className,
}) => {
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        setShouldShow(open);
    }, [open]);

    const handleClose = () => {
        onClose();
        setShouldShow(false);
    };

    return shouldShow ? (
        <div
            className={classNames(css.container, css[anchor], className, {
                [css.anchor]: anchor,
            })}
        >
            <div className={css.header}>
                <CloseIcon onClick={handleClose} className={css.closeIcon} />
                {header && <div className={css.headerContent}>{header}</div>}
            </div>
            <div className={css.content}>
                {leftIcon && <div className={css.leftIcon}>{leftIcon}</div>}
                <div className={css.bodyContainer}>
                    <div className={css.body}>
                        <Label className={css.label} text={label} />
                        <Label className={css.subLabel} text={subLabel} />
                    </div>
                    <SingularButton
                        text={buttonText}
                        type="secondary"
                        onClick={buttonOnClick}
                        level="level1"
                        showSpinner={isLoading}
                        showV={showButtonV}
                    />
                </div>
            </div>
        </div>
    ) : null;
};

Banner.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func.isRequired,
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClose: PropTypes.func,
    anchor: PropTypes.oneOf(BANNER_ANCHOR_POSITIONS),
    showButtonV: PropTypes.bool,
    leftIcon: PropTypes.element,
    className: PropTypes.string,
};
Banner.defaultProps = {
    open: true,
    isLoading: false,
    onClose: () => {},
    anchor: null,
    showButtonV: false,
};
export default Banner;
