import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../../components/widgets';
import DropdownField from '../../components/widgets/DropdownField';
import FileField from '../../components/widgets/FileField';

export const FIELD_TYPES = {
    TEXT: 'text',
    FILE: 'file',
    NUMBER: 'integer',
    DROPDOWN: 'dropdown',
};

export default function DataDestinationField({ type, value, label, secret, choices, className, onChange, ...props }) {
    switch (type) {
        case FIELD_TYPES.FILE: {
            const onChangeFile = file => {
                const reader = new FileReader();
                reader.onload = e => onChange(e.target.result);
                reader.readAsText(file);
            };

            return (
                <FileField
                    clearable
                    containerClass={className}
                    value={value}
                    label={label}
                    onChange={onChangeFile}
                    {...props}
                />
            );
        }
        case FIELD_TYPES.DROPDOWN: {
            return (
                <DropdownField
                    label={label}
                    selected={value}
                    containerClass={className}
                    items={Object.values(choices).map(item => ({ name: item.value, display_name: item.label }))}
                    onChange={onChange}
                    {...props}
                />
            );
        }
        default:
            return (
                <TextField
                    type={type === FIELD_TYPES.NUMBER ? 'number' : type}
                    label={label}
                    value={value}
                    password={!!secret}
                    containerClass={className}
                    onChange={onChange}
                    {...props}
                />
            );
    }
}

DataDestinationField.propTypes = {
    type: PropTypes.oneOf(Object.values(FIELD_TYPES)),
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    secret: PropTypes.bool,
    choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    className: PropTypes.string,
    onChange: PropTypes.func,
};

DataDestinationField.defaultProps = {
    type: FIELD_TYPES.TEXT,
    label: '',
    value: undefined,
    secret: false,
    choices: [],
    className: '',
    onChange: () => {},
};
