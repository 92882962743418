import {
    AVAILABILITY_PARAMETER_OPTION__DIMENSION,
    AVAILABILITY_PARAMETER_OPTION__SOURCE,
    AVAILABILITY_TYPES,
    REPORTS_FIELDS_BLACKLIST,
    NON_REPORTS_FIELDS_WHITELIST,
    HARDCODED_FIELDS,
} from './consts';
import { sortAlphabetically, sortBool } from '../utils/sortUtil';

export const shouldFetchAllFields = (fields = { dimensions: [] }) => {
    return fields.length === 0;
};

export const getOptions = (selectedAvailabilityParameter, sourcesOptions, dimensionsOptions) => {
    let options = [];
    let placeholder = '';

    if (selectedAvailabilityParameter === AVAILABILITY_PARAMETER_OPTION__DIMENSION.name) {
        options = dimensionsOptions;
        placeholder = 'STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.DIMENSIONS_SEARCH_PLACEHOLDER';
    } else if (selectedAvailabilityParameter === AVAILABILITY_PARAMETER_OPTION__SOURCE.name) {
        options = sourcesOptions;
        placeholder = 'STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.SOURCES_SEARCH_PLACEHOLDER';
    }
    return { options, placeholder };
};

export const parseFieldsAvailabilityResponse = (responseData, fields) => {
    const {
        availability_by_source: availabilityBySource,
        availability_by_dimension: availabilityByDimension,
        sources_dict: sourcesDict,
        isLoading,
        error,
    } = responseData;

    if (!isLoading && !availabilityBySource && !availabilityByDimension) {
        return {};
    }

    const dimensionsOptions = [];
    const fieldsDict = {};

    if (!isLoading && !error) {
        const sourcesOptions = Object.keys(availabilityBySource)
            .map(key => ({ value: key, label: sourcesDict[key].display_name }))
            .sort(({ label: label1 }, { label: label2 }) => sortAlphabetically(label1, label2));

        [...fields, ...HARDCODED_FIELDS]
            .sort(({ display_name: displayName1 }, { display_name: displayName2 }) => {
                return sortAlphabetically(displayName1, displayName2);
            })
            .forEach(field => {
                const { name, display_name: displayName, tooltip, visible } = field;

                if (
                    (visible && !REPORTS_FIELDS_BLACKLIST.includes(field.name)) ||
                    NON_REPORTS_FIELDS_WHITELIST.includes(field.name)
                ) {
                    fieldsDict[field.name] = field;
                    dimensionsOptions.push({ value: name, label: displayName, tooltip });
                }
            });

        return {
            sourcesOptions,
            dimensionsOptions,
            availabilityBySource,
            availabilityByDimension,
            sourcesDict,
            fieldsDict,
            isLoading,
        };
    }

    return { isLoading, error };
};

export const getAvailabilityDictByTableType = (availabilityDict = {}) => {
    const {
        campaign: networkAvailabilityStr = '',
        tracker: trackerAvailabilityStr = '',
        creative: creativeAvailabilityStr = '',
    } = availabilityDict;

    const networkAvailability = networkAvailabilityStr;
    const trackerAvailability = trackerAvailabilityStr;
    const creativeAvailability = creativeAvailabilityStr;

    return { networkAvailability, trackerAvailability, creativeAvailability };
};

export const sortByAvailability = (availabilityDict1, availabilityDict2, availabilityDictKey) => {
    const {
        networkAvailability: networkAvailability1,
        trackerAvailability: trackerAvailability1,
        creativeAvailability: creativeAvailability1,
    } = getAvailabilityDictByTableType(availabilityDict1);

    const {
        networkAvailability: networkAvailability2,
        trackerAvailability: trackerAvailability2,
        creativeAvailability: creativeAvailability2,
    } = getAvailabilityDictByTableType(availabilityDict2);

    const aggregatedNetworkAvailability1 = [...networkAvailability1, ...creativeAvailability1];
    const aggregatedNetworkAvailability2 = [...networkAvailability2, ...creativeAvailability2];

    const availableInNetwork1 = aggregatedNetworkAvailability1.includes(availabilityDictKey);
    const availableInNetwork1_calculated = aggregatedNetworkAvailability1.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.CALCULATED}`
    );
    const availableInNetwork1_mapped = aggregatedNetworkAvailability1.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.MAPPED}`
    );
    const availableInNetwork1_combined = aggregatedNetworkAvailability1.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.COMBINED}`
    );
    const availableInNetwork1_not_pulled = aggregatedNetworkAvailability1.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.NOT_PULLED}`
    );

    const availableInTracker1 = trackerAvailability1.includes(availabilityDictKey);

    const availableInNetwork2 = aggregatedNetworkAvailability2.includes(availabilityDictKey);
    const availableInNetwork2_calculated = aggregatedNetworkAvailability2.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.CALCULATED}`
    );
    const availableInNetwork2_mapped = aggregatedNetworkAvailability2.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.MAPPED}`
    );
    const availableInNetwork2_combined = aggregatedNetworkAvailability2.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.COMBINED}`
    );
    const availableInNetwork2_not_pulled = aggregatedNetworkAvailability2.includes(
        `${availabilityDictKey}__${AVAILABILITY_TYPES.NOT_PULLED}`
    );

    const availableInTracker2 = trackerAvailability2.includes(availabilityDictKey);

    return (
        sortBool(availableInNetwork1 && availableInTracker1, availableInNetwork2 && availableInTracker2) ||
        sortBool(
            availableInNetwork1_calculated && availableInTracker1,
            availableInNetwork2_calculated && availableInTracker2
        ) ||
        sortBool(
            availableInNetwork1_mapped && availableInTracker1,
            availableInNetwork2_mapped && availableInTracker2
        ) ||
        sortBool(
            availableInNetwork1_combined && availableInTracker1,
            availableInNetwork2_combined && availableInTracker2
        ) ||
        sortBool(availableInNetwork1, availableInNetwork2) ||
        sortBool(availableInNetwork1_calculated, availableInNetwork2_calculated) ||
        sortBool(availableInNetwork1_mapped, availableInNetwork2_mapped) ||
        sortBool(availableInTracker1, availableInTracker2) ||
        sortBool(
            availableInNetwork1_not_pulled && availableInTracker1,
            availableInNetwork2_not_pulled && availableInTracker2
        ) ||
        sortBool(availableInNetwork1_not_pulled, availableInNetwork2_not_pulled)
    );
};

export const getFieldInfoText = (visible, creativeVisible, skanVisible, skanSummaryVisible) => {
    let infoText;

    if (!visible) {
        if (skanVisible) {
            infoText = 'SKAdNetwork Raw Report';
        } else if (skanSummaryVisible) {
            infoText = 'SKAdNetwork Report';
        } else if (creativeVisible) {
            infoText = 'Creative Report';
        }
    }

    return infoText;
};
