import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import css from './page.css';
import { EmptyStateImageSrc, EmptyStateTasks } from '../components/partials/EmptyState/utils';
import {
    ANDI,
    ASID,
    ANDROID,
    ANDROID_KEY_SPACES,
    IDENTIFIER_PLACEHOLDER,
    ANDROID_ID_IDENTIFIER_PLACEHOLDER,
    IOS,
    IOS_KEY_SPACES,
    logDetailsConfig,
    SEARCH_CATEGORIES,
    VALIDATION_REGEXES,
    WEB,
    PLATFORM_NAMES_REGEX,
    AVAILABLE_KEYSPACES_REGEX,
} from './consts';
import { sortNumeric, sortDate } from '../utils/sortUtil';
import { PARSING_DATA_TYPES } from '../utils/consts';
import { OS, OSIcons } from '../utils/OS';

export const POLLING_EXPIRED_TIME = 60 * 60 * 1000; // hour in ms;

// This Enum defines the current user progress in starting using the testing console.
// The different states are:
// 1. Adding Apps and SDK Integration
// 2. Registering Device
// 3. Choosing the Device
// 4. No Events for the current device and appsite
// 5. Events are Flowing
export const TESTING_CONSOLE_STATES = {
    NEED_TO_ADD_APPS: 1,
    DEVICE_NOT_REGISTERED: 2,
    NEED_TO_CHOOSE_DEVICE: 3,
    NO_LOGS_FOR_CURRENT_DEVICE: 4,
    LOGS_RUNNING: 5,
};

export const defaultEmptyStateValue = {
    show_testing_console_empty_state: false,
    tasks: {
        [EmptyStateTasks.SDK_INTEGRATED]: false,
        [EmptyStateTasks.REGISTER_DEVICE]: false,
        [EmptyStateTasks.ADD_APP]: false,
    },
};

Object.freeze(defaultEmptyStateValue);

export const getIcon = platform => {
    const Icon = OSIcons[platform] || OSIcons[OS.IOS];
    return <Icon className={css.osIcon} />;
};

export const prepareDevicePresentation = device => {
    return {
        id: device.id,
        device_id: device.device_id,
        name: device.device_id,
        value: device.device_id,
        display_name: device.display_name || device.device_id,
        label: device.display_name || device.device_id,
        icon: getIcon(device.platform),
        keyspace: device.keyspace,
        platform: device.platform,
        subTitle: device.keyspace,
        editable: true,
        erasable: true,
        subLabel: device.device_id,
    };
};

export const organizeDevicesPresentation = devices => {
    return devices.map(prepareDevicePresentation).sort(({ id: id1 }, { id: id2 }) => {
        return sortNumeric(id1, id2, true);
    });
};

export const organizeAppsitePresentation = (appsites, platform) => {
    const getDuplicates = {};
    const appsitesOrganized = appsites
        .filter(appsite => !!appsite.longname)
        .filter(appsite => !platform || appsite.platform === platform)
        .map((appsite, idx) => {
            const appsiteKey = `${appsite.name}_${appsite.platform}`;
            if (appsiteKey in getDuplicates) {
                getDuplicates[appsiteKey].push(idx);
            } else {
                getDuplicates[appsiteKey] = [idx];
            }
            return {
                id: appsite.id,
                public_id: appsite.public_id,
                label: appsite.name,
                value: appsite.public_id,
                platform: appsite.platform,
                icon: <img className={css.appsiteIcon} src={appsite.icon} />,
            };
        });

    Object.keys(getDuplicates).forEach(key => {
        if (getDuplicates[key].length > 1) {
            getDuplicates[key].forEach(
                idx =>
                    (appsitesOrganized[
                        idx
                    ].label = `${appsitesOrganized[idx].label} ( ${appsitesOrganized[idx].public_id} )`)
            );
        }
    });

    return appsitesOrganized;
};

export const choseDevice = selectedDeviceModelIds => {
    return selectedDeviceModelIds?.length > 0;
};

export const dataExist = logs => {
    return logs.length;
};
export const validationSchema = Yup.object().shape({
    platform: Yup.string()
        .matches(PLATFORM_NAMES_REGEX, {
            message: 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.REQUIRED',
            excludeEmptyString: true,
        })
        .required('STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.REQUIRED'),
    keyspace: Yup.string()
        .matches(AVAILABLE_KEYSPACES_REGEX, {
            message: 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.REQUIRED',
            excludeEmptyString: true,
        })
        .required('STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.REQUIRED'),
    device_id: Yup.string()
        .matches(VALIDATION_REGEXES.deviceId, {
            message: 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.DEVICE_ID',
        })
        .required('STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.REQUIRED'),
    display_name: Yup.string()
        .matches(VALIDATION_REGEXES.notEmpty, {
            message: 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.EMPTY_NAME',
        })
        .matches(VALIDATION_REGEXES.atLeastThree, {
            message: 'STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.AT_LEAST_THREE',
        })
        .required('STATIC.PAGES.TESTING_CONSOLE.VALIDATIONS.REQUIRED'),
});

export const getIdentifierHint = selectedKeyspace => {
    if ([ASID, ANDI].includes(selectedKeyspace)) {
        return `Ex: ${ANDROID_ID_IDENTIFIER_PLACEHOLDER}`;
    } else if (ANDROID_KEY_SPACES.includes(selectedKeyspace)) {
        return `Ex: ${IDENTIFIER_PLACEHOLDER}`;
    } else if (IOS_KEY_SPACES.includes(selectedKeyspace)) {
        return `Ex: ${IDENTIFIER_PLACEHOLDER.toUpperCase()}`;
    } else {
        return '';
    }
};

export const getPlatformFromKeyspace = keyspace => {
    if (IOS_KEY_SPACES.includes(keyspace)) {
        return IOS;
    } else if (ANDROID_KEY_SPACES.includes(keyspace)) {
        return ANDROID;
    } else {
        return WEB;
    }
};

export const getInitialValues = device => {
    return {
        platform: device.platform,
        keyspace: device.keyspace,
        device_id: device.device_id,
        display_name: device.display_name,
    };
};

export const isNewLog = (logDateTime, newLogsDateTimeLimit) => {
    return moment(logDateTime).isAfter(moment(newLogsDateTimeLimit));
};

export const parseEventData = eventDataStr => {
    let parsedEventData = '';
    try {
        parsedEventData = eventDataStr ? JSON.stringify(JSON.parse(eventDataStr || '{}'), null, 2) : '';
    } catch (e) {
        console.error(`parsing failed. str=${eventDataStr}. Error:`, e);
    }

    return parsedEventData;
};

export const parseByType = (value, type) => {
    if (type === PARSING_DATA_TYPES.DATE) {
        return moment(value).format('MM/DD/YYYY HH:mm:ss');
    } else if (type === PARSING_DATA_TYPES.BOOL) {
        if (['false', 'true', 'False', 'True'].includes(value)) {
            return value.toLowerCase();
        } else {
            return window.isNaN(+value) ? (!!value).toString() : (!!+value).toString();
        }
    } else if (type === PARSING_DATA_TYPES.OBJECT) {
        return parseEventData(value);
    } else {
        return value;
    }
};

export const parseLogs = logs => {
    return logs.map(log => {
        const {
            datetime,
            device_platform: devicePlatform,
            device_id: deviceId,
            device_display_name: deviceDisplayName,
            bundle_id: bundleId,
            app_name: appName,
            app_icon: appIcon,
            internal_event_hash: internalEventHash,
            event_name: eventName,
            is_revenue_event: isRevenueEvent,
            is_first_session: isFirstSession,
        } = log;

        return {
            hash: internalEventHash,
            date: moment(datetime).format('MM/DD/YYYY'),
            time: moment(datetime).format('HH:mm'),
            name: eventName,
            datetime,
            devicePlatform,
            isRevenueEvent,
            isFirstSession,
            deviceDisplayName: deviceDisplayName || deviceId,
            bundleId,
            appName,
            appIcon,
            logDetails: logDetailsConfig
                .filter(({ key, platformRestricted, revenueOnlyField }) => {
                    return (
                        log.hasOwnProperty(key) &&
                        (platformRestricted ? platformRestricted.includes(devicePlatform) : true) &&
                        (revenueOnlyField ? isRevenueEvent : true)
                    );
                })
                .map(({ label, key, isAdminView, isCodeRendering, parsingType }) => {
                    return {
                        label,
                        value: parseByType(log[key], parsingType),
                        isAdminView,
                        isCodeRendering,
                    };
                }),
        };
    });
};

export const getTestingConsoleEmptyStateData = () => ({
    titleText: 'STATIC.EMPTY_STATE.TITLE',
    subTitleText: `STATIC.EMPTY_STATE.SUB_TITLE.SDK_CONSOLE`,
    imageSrc: EmptyStateImageSrc.SINGULAR_DUDE,
    tasksTodo: [
        {
            topic: EmptyStateTasks.ADD_APP,
            name: 'STATIC.EMPTY_STATE.TASKS.ADD_APP_TASK',
            goRedirect: '#/apps?new-app=1',
        },
        {
            topic: EmptyStateTasks.REGISTER_DEVICE,
            name: 'STATIC.EMPTY_STATE.TASKS.REGISTER_DEVICE_TASK',
            disabled: true,
        },
    ],
});

export const getFirstSessionLog = logs => {
    return logs.find(({ is_first_session: isFirstSession }) => isFirstSession);
};

export const search = (log, category, searchText) => log[category].toLowerCase().includes(searchText.toLowerCase());

export const getSelectedSearchOptionsObject = selectedSearchOptions => {
    return selectedSearchOptions.reduce((accum, option) => {
        return { ...accum, [option.type]: option.value };
    }, {});
};

export const getSelectedSearchTypes = selectedSearchOptions => {
    const selectedSearchOptionsObj = getSelectedSearchOptionsObject(selectedSearchOptions);

    return Object.keys(selectedSearchOptionsObj);
};

export const getSearchOptions = (logs, selectedSearchOptions) => {
    const selectedSearchTypes = getSelectedSearchTypes(selectedSearchOptions);
    const searchOptions = {};
    const searchCategoryOptions = {};

    logs.forEach(log => {
        SEARCH_CATEGORIES.forEach(({ value: categoryValue, label: categoryLabel }) => {
            searchOptions[categoryLabel] = searchOptions[categoryLabel] || { label: categoryLabel, options: [] };

            if (!searchCategoryOptions[log[categoryValue]]) {
                searchCategoryOptions[log[categoryValue]] = true;
                searchOptions[categoryLabel].options.push({
                    type: categoryValue,
                    device: log.deviceDisplayName,
                    label: log[categoryValue],
                    value: log[categoryValue],
                });
            }
        });
    });

    return Object.keys(searchOptions).map(key => ({
        label: key,
        options: searchOptions[key].options.filter(({ type }) => selectedSearchTypes.indexOf(type) < 0),
    }));
};

export const filterLogCallback = (log, isNew, newLogsDateTimeLimit, selectedSearchOptions) => {
    const { datetime } = log;
    const selectedSearchOptionsObj = getSelectedSearchOptionsObject(selectedSearchOptions);

    return (
        isNew === isNewLog(datetime, newLogsDateTimeLimit) &&
        SEARCH_CATEGORIES.reduce((isSearchMatch, { value: categoryValue }) => {
            return (
                isSearchMatch &&
                (!selectedSearchOptionsObj[categoryValue] ||
                    selectedSearchOptionsObj[categoryValue] === log[categoryValue])
            );
        }, true)
    );
};

export const filterLogs = (logs, isNew, newLogsDateTimeLimit, selectedSearchOptions) => {
    return logs
        .filter(log => filterLogCallback(log, isNew, newLogsDateTimeLimit, selectedSearchOptions))
        .sort(({ datetime: datetime1 }, { datetime: datetime2 }) => {
            return sortDate(datetime1, datetime2, true);
        });
};

/***
 * keep only search options that are relevant to the current selected devices.
 * @param {array} selectedSearchOptions
 * @param {array} selectedDevices
 * @returns {*[]} the filtered search options according to selected devices
 */
export const getSelectedSearchOptions = (selectedSearchOptions = [], selectedDevices = []) => {
    return selectedSearchOptions.filter(({ device: deviceDisplayName }) => {
        return selectedDevices.find(device => device.label === deviceDisplayName);
    });
};
