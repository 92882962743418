import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import LogsEmptyState from './LogsEmptyState';
import LogsTable from './LogsTable';
import { SingularButton, ThreeLoadingDots } from '../../components/widgets';
import playIcon from '../../resources/svg/play.svg';
import {
    filterLogs,
    getFirstSessionLog,
    getSearchOptions,
    getSelectedSearchOptions,
    TESTING_CONSOLE_STATES,
} from '../utils';
import css from './LogsWrapper.css';
import { DATA_TEST_ID_LOGS_WRAPPER, MIXPANEL_EVENT_DATA_LOADED, MIXPANEL_EVENT_PREFIX } from '../consts';
import { trackMixpanelEvent } from '../../utils/general';
import NewTagInput from '../../components/widgets/NewTagInput';

function LogsWrapper({
    pageProgressState,
    isLogsPolling,
    respawnPolling,
    isLogsLoading,
    isDeviceSelected,
    logs,
    selectedDevices,
    translate,
}) {
    const [selectedSearchOptions, setSelectedSearchOptions] = useState([]);
    const [newLogsTime, setNewLogsTime] = useState(new Date());
    const newLogs = filterLogs(logs, true, newLogsTime, selectedSearchOptions);
    const oldLogs = filterLogs(logs, false, newLogsTime, selectedSearchOptions);
    const searchOptions = getSearchOptions(logs, selectedSearchOptions);

    useEffect(() => {
        if (logs?.length) {
            const firstSessionLog = getFirstSessionLog(logs);
            trackMixpanelEvent(MIXPANEL_EVENT_PREFIX, MIXPANEL_EVENT_DATA_LOADED, {
                isFirstSessionReceived: !!firstSessionLog,
                firstSessionLog,
            });
        }
    }, [logs?.length]);

    useEffect(() => {
        setNewLogsTime(moment(new Date()));
    }, []);

    const onSearchOptionsChange = currentSelectedSearchOptions => {
        setSelectedSearchOptions(currentSelectedSearchOptions);
    };

    const renderLogsView = () => {
        return pageProgressState < TESTING_CONSOLE_STATES.LOGS_RUNNING ? (
            <LogsEmptyState pageProgressState={pageProgressState} />
        ) : (
            <>
                {newLogs?.length > 0 && <LogsTable logs={newLogs} showHeader />}
                {oldLogs?.length > 0 && (
                    <LogsTable
                        logs={oldLogs}
                        showHeader={!newLogs?.length}
                        subtitle="STATIC.PAGES.TESTING_CONSOLE.EVENT_WRAPPER.OLD_LOGS"
                    />
                )}
            </>
        );
    };

    return (
        <div className={css.wrapper} data-testid={DATA_TEST_ID_LOGS_WRAPPER}>
            <div className={css.emptyStateInstructionsWrapper}>
                <div className={css.tableHeader}>
                    <div className={css.searchButtonWrapper}>
                        <div className={css.filterLabel}>
                            <Translate id="STATIC.PAGES.TESTING_CONSOLE.FILTER_LABEL" />
                        </div>
                        <NewTagInput
                            placeholder="STATIC.PAGES.TESTING_CONSOLE.SEARCH_PLACEHOLDER"
                            tags={getSelectedSearchOptions(selectedSearchOptions, selectedDevices)}
                            options={searchOptions}
                            onChange={onSearchOptionsChange}
                            className={css.tagInputClass}
                            isClearable
                            disabled={!isDeviceSelected}
                        />
                    </div>
                    {isDeviceSelected && (
                        <div className={css.fetchLogsStatus}>
                            {isLogsLoading && (
                                <div className={css.loadingIcon}>
                                    <ThreeLoadingDots />
                                </div>
                            )}
                            <span className={classnames(css.booleanIcon, isLogsPolling ? css.green : css.yellow)} />
                            {isLogsPolling
                                ? translate('STATIC.PAGES.TESTING_CONSOLE.EVENT_WRAPPER.LOGS_STATUS.LIVE')
                                : translate('STATIC.PAGES.TESTING_CONSOLE.EVENT_WRAPPER.LOGS_STATUS.PAUSED')}
                            {!isLogsPolling && (
                                <SingularButton
                                    className={css.fetchLogsButton}
                                    Icon={playIcon}
                                    text="STATIC.PAGES.TESTING_CONSOLE.EVENT_WRAPPER.START_EVENTS"
                                    onClick={() => respawnPolling()}
                                />
                            )}
                        </div>
                    )}
                </div>
                {renderLogsView()}
            </div>
        </div>
    );
}

LogsWrapper.propTypes = {
    pageProgressState: PropTypes.number.isRequired,
    isLogsPolling: PropTypes.bool.isRequired,
    respawnPolling: PropTypes.func.isRequired,
    isDeviceSelected: PropTypes.bool.isRequired,
    isLogsLoading: PropTypes.bool.isRequired,
    logs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    selectedDevices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    translate: PropTypes.func,
};

LogsWrapper.defaultProps = {
    translate: () => {},
    selectedDevices: [],
};

export default withLocalize(LogsWrapper);
