import React from 'react';
import PropTypes from 'prop-types';
import css from './SelectedFields.css';
import CloseIcon from '../../../resources/svg/close_icon.svg';
import { getSelectedFieldsBySection } from './utils';

export default function SelectedFields({ selectedFields, onFieldRemoved }) {
    const selectedFieldsBySection = getSelectedFieldsBySection(selectedFields);

    return (
        <div className={css.selectedFields}>
            <div className={css.content}>
                {!Object.keys(selectedFieldsBySection).length && (
                    <div className={css.emptyState}>No dimensions nor metrics selected yet</div>
                )}
                {Object.entries(selectedFieldsBySection).map(([section, fields]) => (
                    <div key={section}>
                        <div className={css.title}>{`Selected ${section}`}</div>
                        {fields.map(field => (
                            <div className={css.itemContainer} key={field.name}>
                                <div className={css.item}>
                                    {field.display_name}
                                    <CloseIcon className={css.close} onClick={() => onFieldRemoved(field)} />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

SelectedFields.propTypes = {
    selectedFields: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    onFieldRemoved: PropTypes.func,
};

SelectedFields.defaultProps = {
    selectedFields: [],
    onFieldRemoved: () => {},
};
