import 'url-search-params-polyfill';
import rootSaga from '../sagas';
import {
    setAdminMode,
    updateUserData,
    openImpersonateDialog,
    openChangePasswordDialog,
    toggleTrialStatusPanel,
} from '../actions/user';
import { setAddAppEmptyState, updateOnboardingData } from '../actions/onboarding';
import User from '../services/user';
import { setCompletedTask, setHomepageData } from '../homepage/actions';
import HomepageService from '../homepage/service';
import { driftUrl } from './consts';
import { initMoment } from './regional';
import { setUrlSearchParams } from './url';

const userApi = new User();
const homepageService = new HomepageService();

const initMixpanel = userData => {
    const {
        real_username: realUsername,
        real_organization_id: realOrganizationId,
        real_organization_name: realOrganizationName,
        sfdc_account_id: SFDCAccountId,
        user_product: userProduct,
        is_midmarket: isMidmarket,
        is_self_serve_signup: isSelfServeSignup,
    } = userData;

    if ((realUsername && realUsername.endsWith('@singular.net')) || process.env.NODE_ENV === 'development') {
        window.mixpanel.disable();
    } else {
        window.mixpanel.identify(realUsername);
        window.mixpanel.register({
            email: realUsername,
            url: window.location.toString(),
            clean_url: [window.location.protocol, '//', window.location.host, window.location.pathname].join(''),
            host: window.location.host,
            org: realOrganizationId,
            active_organization: realOrganizationName,
            sfdc_account_id: SFDCAccountId,
            user_product: userProduct,
            is_midmarket: isMidmarket,
            is_self_serve_signup: isSelfServeSignup,
        });
    }
};

const openDrift = () => {
    const width = 500;
    const height = 500;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    return window.open(driftUrl, '', `width=${width},height=${height},top=${top},left=${left}`);
};

const initUser = store => {
    window.addEventListener('firstAppAdded', () => {
        store.dispatch(setAddAppEmptyState());
        store.dispatch(setCompletedTask('APPS'));
    });
    window.addEventListener('reactAdminModeChanged', e => {
        store.dispatch(setAdminMode(e.detail));
    });
    window.addEventListener('showImpersonateOrgModal', () => {
        store.dispatch(openImpersonateDialog());
    });
    window.addEventListener('showChangePasswordModal', () => {
        store.dispatch(openChangePasswordDialog());
    });
    window.addEventListener('showTrialStatusPanel', e => {
        const { show, trialRealDaysLeft, trialDaysLeftToDisplay } = e.detail;
        store.dispatch(toggleTrialStatusPanel(show, trialRealDaysLeft, trialDaysLeftToDisplay));
    });
    window.addEventListener('openDrift', openDrift);

    userApi
        .getCurrentUser()
        .then(data => {
            sessionStorage.setItem('x-organization', data.company); // Remove after migration to Python 3
            store.dispatch(updateUserData(data));
            if (typeof mixpanel === 'object') {
                initMixpanel(data);
            }
        })
        .catch(console.error);

    userApi
        .getCurrentOnboardingState()
        .then(data => {
            store.dispatch(updateOnboardingData(data));
        })
        .catch(console.error);
};

// get homepage data to show the onboarding progress bar in the navbar
const initHomepage = store => {
    homepageService
        .getHomepageData()
        .then(({ data }) => {
            store.dispatch(setHomepageData(data));
        })
        .catch(console.error);
};

const initDevMode = () => {
    if (process.env.NODE_ENV === 'development') {
        if (window.location.port === '6593') {
            window.addEventListener('routelessRedirect', data => {
                window.history.replaceState(
                    null,
                    null,
                    setUrlSearchParams(window.location.href, window.location.search, data.detail.url.slice(1))
                );
            });
        }
    }
};

let initiated = false;

export default (store, sagaMiddleware) => {
    const reactContainer = document.getElementById('reactContainer');

    if (!reactContainer) {
        return false;
    }

    if (initiated) {
        return false;
    }

    initMoment();
    initUser(store);
    initHomepage(store);
    initDevMode();
    sagaMiddleware.run(rootSaga);
    initiated = true;

    return true;
};
