import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GlassesIcon from '../../resources/svg/glasses.svg';
import css from '../styles.css';

const EmptyState = ({ show, emptyState, onClose }) => {
    const linkClick = () => {
        setTimeout(() => {
            onClose();
        }, 0);
    };

    return (
        <>
            <div className={classNames(css.emptyState, { [css.show]: show, [css.showEmptyState]: show && emptyState })}>
                <GlassesIcon className={classNames(css.emptyStateIcon, { [css.smallEmptyStateIcon]: emptyState })} />
            </div>
            {emptyState && (
                <div className={css.emptyStateText}>
                    No data available. Start by adding a data connector <a onClick={linkClick} href="#react/data-sources">here</a>.
                </div>
            )}
        </>
    );
};

export default EmptyState;

EmptyState.propTypes = {
    show: PropTypes.bool,
    emptyState: PropTypes.bool,
    onClose: PropTypes.func,
};

EmptyState.defaultProps = {
    show: false,
    emptyState: false,
    onClose: () => {},
};
