import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPage } from './selectors';
import {
    search,
    toggleStatus,
    editStart,
    editCancel,
    editSave,
    deleteStart,
    deleteConfirm,
    deleteCancel,
    validateSegment,
    download,
    openSharingOptions,
    changeSort,
} from './actions';
import AudienceSegments from './page';

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(
            {
                onSearch: search,
                onToggleStatus: toggleStatus,
                onEditStart: editStart,
                onEditCancel: editCancel,
                onDeleteStart: deleteStart,
                onDeleteConfirm: deleteConfirm,
                onDeleteCancel: deleteCancel,
                onDownload: download,
                onOpenSharingOptions: openSharingOptions,
                onChangeSort: changeSort,
            },
            dispatch
        ),
        onEditConfirm: (id, segment) => {
            dispatch(validateSegment(true));
            dispatch(editSave(id, segment));
        },
    };
};

export default connect(state => getPage(state), mapDispatchToProps)(AudienceSegments);
