import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import posed, { PoseGroup } from 'react-pose';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { getAdminModeEnabled } from '../../selectors/user';
import ExpandableLogsTableRow from './ExpandableLogsTableRow';
import Label from '../../components/widgets/Label';
import LogDetails from './LogDetails';
import SandWatchIcon from '../../resources/svg/sand_watch.svg';
import IconTableCell from './IconTableCell';
import { posedTableRowConfig } from '../consts';
import css from './LogsTable.css';
import OverflowingText from '../../components/widgets/OverflowingText';

const AnimatedRow = posed.tr(posedTableRowConfig);

function LogsTable({ logs, subtitle, showHeader }) {
    const isAdminMode = useSelector(state => getAdminModeEnabled(state));

    return (
        <div className={classNames(css.tableWrapper)}>
            <TableContainer>
                <Table>
                    {showHeader && (
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    <Label
                                        className={css.tableHeader}
                                        text="STATIC.PAGES.TESTING_CONSOLE.COLUMN_HEADERS.DEVICE"
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Label
                                        className={css.tableHeader}
                                        text="STATIC.PAGES.TESTING_CONSOLE.COLUMN_HEADERS.DATE"
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Label
                                        className={css.tableHeader}
                                        text="STATIC.PAGES.TESTING_CONSOLE.COLUMN_HEADERS.TIME"
                                    />
                                </TableCell>
                                <TableCell />
                                <TableCell align="left">
                                    <Label
                                        className={css.tableHeader}
                                        text="STATIC.PAGES.TESTING_CONSOLE.COLUMN_HEADERS.EVENT"
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Label
                                        className={css.tableHeader}
                                        text="STATIC.PAGES.TESTING_CONSOLE.COLUMN_HEADERS.APP"
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Label
                                        className={css.tableHeader}
                                        text="STATIC.PAGES.TESTING_CONSOLE.COLUMN_HEADERS.BUNDLE"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    {subtitle && logs?.length > 0 && (
                        <TableHead className={css.tableSubtitleHeader} sx={{ padding: 0 }}>
                            <TableRow>
                                <TableCell align="left" colSpan={8}>
                                    <div className={css.tableSubtitleHeader}>
                                        <SandWatchIcon className={css.sandWatchIcon} />
                                        <Label className={css.tableSubtitle} text={subtitle} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        <PoseGroup animateOnMount flipMove={false}>
                            {logs.map((log, i) => (
                                <ExpandableLogsTableRow
                                    component={AnimatedRow}
                                    key={`${log.hash}`}
                                    logUniqueId={log.hash}
                                    expandComponent={<LogDetails isAdminMode={isAdminMode} dataList={log.logDetails} />}
                                >
                                    <TableCell className={css.device}>
                                        <div className={css.overflowingTextWrapper}>
                                            <OverflowingText>{log.deviceDisplayName}</OverflowingText>
                                        </div>
                                    </TableCell>
                                    <TableCell width={100}>{log.date}</TableCell>
                                    <TableCell width={60}>{log.time}</TableCell>
                                    <IconTableCell log={log} />
                                    <TableCell className={css.eventName}>
                                        <div className={css.overflowingTextWrapper}>
                                            <OverflowingText>{log.name}</OverflowingText>
                                        </div>
                                    </TableCell>
                                    <TableCell className={css.appName}>
                                        {log.appIcon && <img className={css.appsiteIcon} src={log.appIcon} />}
                                        <div className={css.overflowingTextWrapper}>
                                            <OverflowingText className={css.overflowingText}>
                                                {log.appName}
                                            </OverflowingText>
                                        </div>
                                    </TableCell>
                                    <TableCell className={css.bundleId}>
                                        <div className={css.overflowingTextWrapper}>
                                            <OverflowingText className={css.overflowingText}>
                                                {log.bundleId}
                                            </OverflowingText>
                                        </div>
                                    </TableCell>
                                    {isAdminMode && (
                                        <TableCell align="left" scope="row">
                                            {log.hash}
                                        </TableCell>
                                    )}
                                </ExpandableLogsTableRow>
                            ))}
                        </PoseGroup>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

LogsTable.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    subtitle: PropTypes.string,
    showHeader: PropTypes.bool,
};

LogsTable.defaultProps = {
    subtitle: undefined,
    showHeader: false,
};

export default withLocalize(LogsTable);
