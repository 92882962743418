import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import css from './CollapsableRow.css';
import parentCss from '../PartnerConfigurationShelf.css';
import { ExpandablePanel } from '../../../../components/widgets';

const CollapsableHeader = ({ title, onClick, expanded }) => {
    return (
        <div onClick={onClick} className={`${css.collapsableContainer} ${expanded ? css.expanded : ''}`}>
            <div>
                <div className={parentCss.title}>{title}</div>
            </div>
            <div className={css.arrowArea}>
                <ArrowDownIcon className={css.overrideArrow} />
            </div>
        </div>
    );
};

CollapsableHeader.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    expanded: PropTypes.bool,
};

const CollapsableRow = ({ title, children, expanded, onClick }) => {
    return (
        <ExpandablePanel
            expanded={expanded}
            content={children}
            overrideClasses={{
                container: css.overrideContainer,
                contentArea: css.panelContent,
                headerContainer: css.panelHeader,
                headerContainerExpanded: css.panelHeaderExpanded,
                arrowArea: css.overrideOldArrowArea,
                externalHeaderArea: css.expandableOverrideExternal,
                arrow: css.overrideArrow,
            }}
            dataTestId="expandablePanel"
            header={<CollapsableHeader title={title} expanded={expanded} onClick={onClick} />}
            onExpandClick={onClick}
            animate
        />
    );
};

CollapsableRow.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    expanded: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element),
};

export default CollapsableRow;
