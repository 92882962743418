import uniqBy from 'lodash/uniqBy';
import difference from 'lodash/difference';
import moment from 'moment';
import { createSelector } from 'reselect';
import { getAppsList } from '../selectors/apps';
import { getFields, getFilters } from '../selectors/fields';
import { getTranslate } from '../selectors/locale';
import rules from '../selectors/reportsConfig/fieldVisibilityRules';
import { getAdminModeEnabled, getUserData, getUserPermissionDimensions } from '../selectors/user';
import { showCohortEvents, showConversionEvents } from '../customEvents/utils';
import {
    getRoleDisplayName,
    getUserDisplayName,
    ROLE_ORG_ADMIN,
    shelfSteps,
    wizardModes,
    initialFieldTypes,
    MemberTypes,
    AGENCY_PERMISSION_DIMENSIONS,
    getUserRole,
    MemberGroupTypes,
    RESTRICTED_ROLES,
    DELETABLE_GROUPS,
    REGISTRATION_STEPS,
    EXCLUDED_METRICS_SELECTION_PAGE,
} from './utils';

const getPageLoaded = state => state.teamManagement.pageLoaded;
const getUsers = state => state.teamManagement.orgUsers;
const getAgencies = state => state.teamManagement.orgAgencies;
const getMessages = state => state.teamManagement.messages;
const getMessagesDefs = state => state.teamManagement.messagesDefs;
const getShelf = state => state.teamManagement.shelf;
const getEditMemberId = state => state.teamManagement.editMemberId;
const getUserRegistrationForm = state => state.teamManagement.userRegistrationForm;
const getRoleSelectionForm = state => state.teamManagement.roleSelectionForm;
const getAgencySelectionForm = state => state.teamManagement.agencySelectionForm;
const getSelectedMetrics = state => state.teamManagement.selectedMetrics;
const getScreenSelectionForm = state => state.teamManagement.screenSelectionForm;
const getSaveMemberError = state => state.teamManagement.saveMemberError;
const getWizard = state => state.teamManagement.wizard;
const getUans = state => state.teamManagement.uans;
const getScreens = state => state.teamManagement.screens;
const getSelectedDimensionPermissionValues = state => state.teamManagement.dimensionFilters;
export const getActiveMemberType = state => state.teamManagement.activeMemberType;

const getFieldTypes = createSelector(
    [getUserData],
    ({ event_slot_analysis_types: eventSlotAnalysisTypes = [], feature_flags: featureFlags = [] }) => {
        const fieldTypes = [];

        if (showCohortEvents(eventSlotAnalysisTypes)) {
            fieldTypes.push({ type: 'customEvents', label: 'STATIC.CUSTOM_EVENTS' });
        }
        if (showConversionEvents(eventSlotAnalysisTypes)) {
            fieldTypes.push({ type: 'conversionEvents', label: 'STATIC.CONVERSION_EVENTS' });
        }
        if (featureFlags && featureFlags.includes('cross-device')) {
            fieldTypes.push({ type: 'crossDeviceMetrics', label: 'STATIC.CROSS_DEVICE_METRICS' });
            fieldTypes.push({ type: 'webMetrics', label: 'STATIC.WEB_METRICS' });
            fieldTypes.push({ type: 'appMetrics', label: 'STATIC.APP_METRICS' });
        }
        return initialFieldTypes.concat(fieldTypes);
    }
);

const _buildUserSeachString = user => {
    if (user.role && user.role.description) {
        return `${user.username};${user.first_name};${user.last_name};${user.email};${
            user.role.description
        };${getUserRole(user)}`.toLowerCase();
    }
    return `${user.username};${user.first_name};${user.last_name};${user.email};${getUserRole(user)}`.toLowerCase();
};

const organicSource = {
    name: 'organic',
    displayName: 'Organic',
    id: 'organic',
    icon: 'https://cdn.singular.net/dashboard/logo_path/0ac00edfda169d777c0b4290541adfc0a6ccdd41',
    adn_id: 434,
    display_name: 'Organic',
    logo_path: 'https://cdn.singular.net/dashboard/logo_path/0ac00edfda169d777c0b4290541adfc0a6ccdd41',
};

const getMemberTypes = createSelector([getUserData], userData => {
    const categories = [MemberTypes.USER];
    return userData.is_agencies_allowed ? [...categories, MemberTypes.AGENCY] : categories;
});

const getAgenciesByGroup = createSelector([getAgencies], agencies => {
    return {
        [MemberGroupTypes.active]: agencies.map(agency => ({
            id: agency.agency_id,
            name: agency.display_name,
            imgSrc: agency.company_logo,
            role: agency.role,
            deletable: true,
            searchText: agency.display_name.toLowerCase(),
        })),
    };
});

const getUserGroup = userStatus => {
    if (userStatus === 'Member') {
        return MemberGroupTypes.active;
    }

    if (userStatus === 'invite_sent' || userStatus === 'invite_opened') {
        return MemberGroupTypes.pending;
    }

    return MemberGroupTypes.inactive;
};

const getUsersByGroup = createSelector([getUsers, getTranslate], (users, translate) => {
    const groups = users.reduce(
        (total, user) => {
            const group = getUserGroup(user.status);
            const isReInviteLessThan24h =
                user.last_re_invited_at && moment().diff(moment(user.last_re_invited_at * 1000), 'hours') < 24;

            const newUser = {
                ...user,
                id: user.organization_user_id || user.username,
                name: getUserDisplayName(user),
                description: user.role && user.role.description ? user.role.description : '',
                roleText: getUserRole(user),
                searchText: _buildUserSeachString(user),
                deletable: DELETABLE_GROUPS.includes(group),
                disabled: isReInviteLessThan24h || user.disabled,
                disabledTootlip:
                    isReInviteLessThan24h || user.disabled
                        ? translate('STATIC.PAGES.TEAM_MEMBERS.REINVITE_DISABLED_TOOLTIP')
                        : '',
            };
            total[group].push(newUser);
            return total;
        },
        {
            [MemberGroupTypes.pending]: [],
            [MemberGroupTypes.active]: [],
            [MemberGroupTypes.inactive]: [],
        }
    );

    // sort pending list according to creation time.
    groups.pending.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return groups;
});

const getMembersByGroup = createSelector(
    [getUsersByGroup, getAgenciesByGroup, getActiveMemberType],
    (users, agencies, activeMemberType) => (activeMemberType === MemberTypes.USER ? users : agencies)
);

const getGroups = createSelector(
    [getMembersByGroup, getMessages, getMessagesDefs, getAdminModeEnabled, getActiveMemberType],
    (membersByGroup, messages, messagesDefs, adminModeEnabled, activeMemberType) => {
        return Object.keys(membersByGroup)
            .filter(group => {
                if (!adminModeEnabled && group === MemberGroupTypes.inactive) {
                    return false;
                }

                return membersByGroup[group].length;
            })
            .map(group => ({
                name: group,
                displayName: `STATIC.PAGES.TEAM_MEMBERS.GROUP_NAME_${group.toUpperCase()}_${activeMemberType}`,
                infoMessages: messages[group] ? messages[group].map(messageId => messagesDefs[messageId]) : [],
            }));
    }
);

export const getPermissionDimensions = createSelector(
    [
        getUserPermissionDimensions,
        getFilters,
        getSelectedDimensionPermissionValues,
        getAppsList,
        getUans,
        getActiveMemberType,
    ],
    (userPermissionDimensions, apiFilters, selectedValues, apps, uans, activeMemberType) => {
        const memberPermissionDimensions =
            activeMemberType === MemberTypes.AGENCY ? AGENCY_PERMISSION_DIMENSIONS : userPermissionDimensions;

        const permissionDimensions = {};
        memberPermissionDimensions.forEach(dimensionName => {
            // App values are stored by id and not name
            if (dimensionName === 'app') {
                permissionDimensions.app = {
                    name: 'app',
                    values: (apps || []).map(app => ({
                        name: app.id,
                        displayName: app.name,
                        icon: app.icon,
                    })),
                    selected: selectedValues.app || { values: [], addFuture: false },
                };
                return;
            }

            // Source values are produced from uans and not the source filter (one day it will if we fix the source
            // filter to only show properly source values)
            if (dimensionName === 'base_source') {
                permissionDimensions.base_source = {
                    name: 'base_source',
                    values: Object.values(uans).map(uan => ({
                        name: uan.name,
                        displayName: uan.display_name,
                        icon: uan.logo_path,
                    })),
                    selected: selectedValues.base_source || { values: [], addFuture: false },
                };
                return;
            }

            // All other dimensions we can filter on must have an API filter
            const filter = apiFilters.find(f => f.name === dimensionName);
            if (filter)
                permissionDimensions[dimensionName] = {
                    name: dimensionName,
                    displayName: filter.display_name,
                    values: filter.values.map(v => ({ name: v.name, displayName: v.display_name })),
                    selected: selectedValues[dimensionName] || { values: [], addFuture: false },
                };
        });
        return permissionDimensions;
    }
);

const getPermissionDimension = dimensionName => {
    return createSelector([getPermissionDimensions], permissionDimensions => {
        return permissionDimensions[dimensionName];
    });
};

const getShelfPages = createSelector(
    [getActiveMemberType, getPermissionDimensions, getRoleSelectionForm],
    (activeMemberType, permissionDimensions, roleSelectionForm) => {
        if (!RESTRICTED_ROLES.includes(roleSelectionForm.role)) {
            return [shelfSteps.roleSelection, shelfSteps.userDetails];
        }

        const permissionSteps = Object.values(permissionDimensions).map(dimension => {
            if (dimension.name === 'app') return shelfSteps.appSelection;
            if (dimension.name === 'base_source') return shelfSteps.sourceSelection;

            return dimension.name;
        });

        if (activeMemberType === MemberTypes.AGENCY) {
            return [shelfSteps.agencyDetails, ...permissionSteps, shelfSteps.review];
        }

        return [
            shelfSteps.roleSelection,
            shelfSteps.userDetails,
            ...permissionSteps,
            shelfSteps.metricsSelection,
            shelfSteps.screenSelection,
            shelfSteps.review,
        ];
    }
);

const getSummaryPages = createSelector([getShelfPages, getWizard], (shelfPages, wizard) => {
    return shelfPages.filter(
        page =>
            page !== shelfSteps.review &&
            // Dont show role selection in summary when we're creating a new user
            (wizard.mode === wizardModes.edit || page !== shelfSteps.roleSelection)
    );
});

const getWizardSteps = createSelector([getShelfPages, getWizard], (shelfPages, wizard) => {
    return wizard.mode === wizardModes.edit
        ? shelfPages.filter(step => !REGISTRATION_STEPS.includes(step))
        : shelfPages;
});

export const getEditedMember = createSelector([getEditMemberId, getMembersByGroup], (editMemberId, membersByGroup) => {
    if (!editMemberId) return null;

    const activeMembers = membersByGroup[MemberGroupTypes.active];
    const editedMember = activeMembers.find(member => member.id === editMemberId);

    return editedMember;
});

const getShelfFormatted = createSelector(
    [getShelf, getEditedMember, getRoleSelectionForm, getActiveMemberType],
    (shelf, editedMember, roleSelection, activeMemberType) => {
        const headerText = activeMemberType === MemberTypes.USER ? 'Invite New User' : 'Add Agency';

        return {
            ...shelf,
            headerText: editedMember ? `Edit ${editedMember.name}'s Permissions` : headerText,
            headerData: {
                roleType: getRoleDisplayName(roleSelection.role),
            },
        };
    }
);

export const getTeamManagementPage = createSelector(
    [getPageLoaded, getMembersByGroup, getGroups, getMemberTypes, getActiveMemberType, getUserData],
    (pageLoaded, membersByGroup, membersGroups, memberTypes, activeMemberType, userData) => {
        return {
            pageLoaded,
            membersByGroup,
            membersGroups,
            memberTypes,
            activeMemberType,
            watchingMemberId: userData.organization_user_id,
        };
    }
);

export const getTeamManagementShelf = createSelector(
    [getShelfFormatted, getRoleSelectionForm, getWizard, getWizardSteps, getPermissionDimensions],
    (shelf, roleForm, wizard, wizardSteps, permissionDimensions) => {
        return {
            shelf,
            mode: wizard.mode,
            role: roleForm.role,
            wizardSteps,
            editedMember: wizard.userData,
            permissionDimensions,
        };
    }
);

const getWizardOverrideButtons = createSelector([getWizard], wizard => {
    let ret = {};
    if (wizard.currentActionType === wizardModes.edit) {
        ret = {
            next: {
                text: 'Continue',
                onClickArgs: {
                    skipCompleted: true,
                },
            },
            back: {
                visible: false,
            },
        };
    }
    return ret;
});

export const getUserRegistrationFormPage = createSelector(
    [
        getUserData,
        getUserRegistrationForm,
        getSaveMemberError,
        getWizard,
        getWizardOverrideButtons,
        getTranslate,
        getActiveMemberType,
    ],
    (userData, userRegistrationForm, saveMemberError, wizard, wizardOverrideButtons, translate, activeMemberType) => {
        const finishButtonText = translate(
            wizard.mode === wizardModes.edit
                ? 'STATIC.PAGES.TEAM_MEMBERS.WIZARD_UPDATE_PERMISSIONS_BUTTON'
                : `STATIC.BUTTONS.${activeMemberType}_CREATE_NEW_MEMBER`
        );
        return {
            username: userData.username,
            userRegistrationForm,
            saveMemberError,
            mode: wizard.mode,
            wizardOverrideButtons,
            translate,
            finishButtonText,
        };
    }
);

export const getAppSelectionPage = createSelector(
    [getAppsList, getPermissionDimension('app'), getWizardOverrideButtons, getTranslate],
    (apps, permissionDimension, wizardOverrideButtons, translate) => {
        if (!permissionDimension) return;
        return {
            apps: apps
                .map(a => ({ ...a, selected: (permissionDimension.selected.values || []).includes(a.id) }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            wizardOverrideButtons,
            translate,
        };
    }
);

export const getSourceSelectionPage = createSelector(
    [getUans, getPermissionDimension('base_source'), getWizardOverrideButtons, getTranslate],
    (uans, permissionDimension, wizardOverrideButtons, translate) => {
        if (!permissionDimension) return;
        return {
            uans: [
                [
                    {
                        ...organicSource,
                        selected: (permissionDimension.selected.values || []).includes(organicSource.name),
                    },
                ],
                uniqBy(
                    Object.values(uans).map(u => ({
                        name: u.name,
                        displayName: u.display_name,
                        id: u.name,
                        icon: u.logo_path,
                        selected: (permissionDimension.selected.values || []).includes(u.name),
                    })),
                    'name'
                )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter(s => s.name !== organicSource.name),
            ],
            wizardOverrideButtons,
            translate,
        };
    }
);

export const getGenericPermissionDimensionPage = dimensionName => {
    return createSelector(
        [getPermissionDimension(dimensionName), getWizardOverrideButtons, getTranslate],
        (permissionDimension, wizardOverrideButtons, translate) => {
            return {
                permissionDimension,
                wizardOverrideButtons,
                translate,
            };
        }
    );
};

export const getRoleSelectionFormPage = createSelector(
    [getWizard, getRoleSelectionForm, getEditedMember, getUserData, getTranslate],
    (wizard, roleForm, editedMember, userData, translate) => {
        return {
            mode: wizard.mode,
            role: roleForm.role,
            editedMember,
            showRestrictedMode: userData.is_permissions_premium,
            translate,
        };
    }
);

export const getMetricsSelectionPage = createSelector(
    [getFields, getFieldTypes, getSelectedMetrics, getWizardOverrideButtons],
    (fields, displayedFields, selectedMetrics, wizardOverrideButtons) => {
        return {
            fields: displayedFields.map(field => ({
                ...field,
                values: fields[field.type]
                    .filter(
                        metric => rules.permissions(metric) && !EXCLUDED_METRICS_SELECTION_PAGE.includes(metric.name)
                    )
                    .map(metric => ({
                        ...metric,
                        selected: selectedMetrics.values.includes(metric.name),
                    })),
            })),
            wizardOverrideButtons,
        };
    }
);

const getScreensFormatted = createSelector(
    [getScreens, getSelectedMetrics, getMetricsSelectionPage, getTranslate],
    (screens, selectedMetrics, metrics, translate) => {
        const exportLogsTooltip = 'STATIC.PAGES.TEAM_MEMBERS.WIZARD_SCREENS_ENABLE_EXPORT_LOGS';
        const dataDestinationsTooltip = 'STATIC.PAGES.TEAM_MEMBERS.WIZARD_SCREENS_DISABLE_DATA_DESTINATIONS';
        const tooltip = {
            export_logs: exportLogsTooltip,
            'data-destinations': dataDestinationsTooltip,
        };
        return screens.map(screen => ({
            name: screen.name,
            displayName: screen.display_name,
            children: screen.features.map(feature => {
                const metricsCount = metrics.fields.map(section => section.values).flat().length;
                const selectedMetricsCount = selectedMetrics.values.length;
                const shouldDisablePage =
                    (feature.name === 'export_logs' &&
                        (metricsCount !== selectedMetricsCount || !selectedMetrics.addFuture)) ||
                    feature.name === 'data-destinations';
                const disabledTooltip = tooltip[feature.name];
                return {
                    name: feature.name,
                    displayName: feature.display_name,
                    warning: shouldDisablePage ? null : feature.warning,
                    disabled: shouldDisablePage,
                    disabledTooltip: disabledTooltip ? translate(disabledTooltip) : null,
                };
            }),
        }));
    }
);

export const getScreenSelectionPage = createSelector(
    [getScreensFormatted, getScreenSelectionForm, getWizardOverrideButtons],
    (screens, screenSelectionForm, wizardOverrideButtons) => {
        const screensCopy = screens.map(section => {
            const newScreens = section.children
                .filter(s => s.name !== 'team_management')
                .map(screen => ({
                    ...screen,
                    selected: screenSelectionForm.selectedScreens.includes(screen.name),
                }));
            return {
                ...section,
                children: newScreens,
            };
        });
        return {
            screens: screensCopy,
            selectedScreens: screenSelectionForm.selectedScreens.filter(s => s !== 'team_management'),
            wizardOverrideButtons,
        };
    }
);

const getScreensForSummary = createSelector(
    [getScreensFormatted, getScreenSelectionForm],
    (screens, { selectedScreens, addFuture }) => {
        const ret = [];
        screens.forEach(section => {
            const selectedScreensData = section.children.filter(screen => selectedScreens.includes(screen.name));
            if (selectedScreensData.length) {
                ret.push({
                    ...section,
                    children: selectedScreensData,
                });
            }
        });
        return {
            values: ret,
            addFuture,
            overall: screens.flatMap(x => x.children).length,
        };
    }
);

const getFieldsForSummary = createSelector(
    [getMetricsSelectionPage, getSelectedMetrics],
    ({ fields }, { addFuture }) => {
        const ret = [];
        let overall = 0;
        fields.forEach(fieldType => {
            const selectedFields = fieldType.values.filter(f => f.selected);
            overall += fieldType.values.length;

            if (selectedFields.length) {
                ret.push({
                    ...fieldType,
                    values: selectedFields,
                });
            }
        });
        return {
            values: ret,
            addFuture,
            overall,
        };
    }
);

export const getSummaryViewPage = createSelector(
    [
        getUserRegistrationForm,
        getAgencySelectionForm,
        getPermissionDimensions,
        getScreensForSummary,
        getFieldsForSummary,
        getWizard,
        getRoleSelectionForm,
        getSaveMemberError,
        getSummaryPages,
        getTranslate,
        getActiveMemberType,
    ],
    (
        registrationForm,
        agencySelectionForm,
        permissionDimensions,
        screens,
        fields,
        wizard,
        roleSelectionForm,
        saveMemberError,
        pages,
        translate,
        activeMemberType
    ) => {
        const finishButtonText = translate(
            wizard.mode === wizardModes.edit
                ? 'STATIC.PAGES.TEAM_MEMBERS.WIZARD_UPDATE_PERMISSIONS_BUTTON'
                : `STATIC.BUTTONS.${activeMemberType}_CREATE_NEW_MEMBER`
        );

        return {
            registrationForm,
            agencySelectionForm,
            permissionDimensions,
            screens,
            fields,
            wizard,
            roleSelectionForm,
            saveMemberError,
            pages,
            finishButtonText,
            allGroupsExpanded: activeMemberType === MemberTypes.AGENCY,
        };
    }
);

const wizardFinishedFilter = (values, addFuture) => {
    return values.filter(v => (addFuture && !v.selected) || (!addFuture && v.selected));
};

const buildFeatureFiltersFromForm = formData => {
    const { addFuture, values } = formData;
    return {
        access_new_features: addFuture,
        values: values.reduce((total, section) => {
            return total.concat(wizardFinishedFilter(section.children, addFuture).map(screen => screen.name));
        }, []),
    };
};

export const getWizardFinished = createSelector(
    [
        getEditMemberId,
        getUserRegistrationForm,
        getAgencySelectionForm,
        getRoleSelectionForm,
        getMetricsSelectionPage,
        getSelectedMetrics,
        getScreenSelectionPage,
        getScreenSelectionForm,
        getPermissionDimensions,
        getActiveMemberType,
    ],
    (
        editMemberId,
        registrationForm,
        agencySelectionForm,
        roleForm,
        metricsSelectionPage,
        selectedMetrics,
        screenSelectionPage,
        selectedScreens,
        permissionDimensions,
        activeMemberType
    ) => {
        return {
            registrationForm:
                activeMemberType === MemberTypes.USER
                    ? {
                          name: registrationForm.firstName.value,
                          lastname: registrationForm.lastName.value,
                          email: registrationForm.email.value,
                          description: registrationForm.description.value,
                      }
                    : {
                          ...agencySelectionForm,
                      },
            role: {
                type: roleForm.role,
                description: registrationForm.description.value,
                row: RESTRICTED_ROLES.includes(roleForm.role)
                    ? Object.values(permissionDimensions).map(permDim => ({
                          dimension: permDim.name,
                          values: !permDim.selected.addFuture
                              ? permDim.selected.values
                              : difference(
                                    permDim.values.map(x => x.name),
                                    permDim.selected.values
                                ),
                          operator: permDim.selected.addFuture ? 'not in' : 'in',
                      }))
                    : [],
                column: {
                    operator: selectedMetrics.addFuture ? 'not in' : 'in',
                    values: !selectedMetrics.addFuture
                        ? selectedMetrics.values
                        : difference(
                              getAllSubItems(metricsSelectionPage.fields, 'values').map(i => i.name),
                              selectedMetrics.values
                          ),
                },
                features: {
                    operator: selectedScreens.addFuture ? 'not in' : 'in',
                    access_new_features: selectedScreens.addFuture,
                    values: !selectedScreens.addFuture
                        ? selectedScreens.selectedScreens
                        : difference(
                              getAllSubItems(screenSelectionPage.screens, 'children').map(x => x.name),
                              selectedScreens.selectedScreens
                          ),
                },
            },
            editMemberId,
        };
    }
);

// TODO: This selector always returns a different state, thus we show the "confirm changes" message even when there are no changes.
export const getShelfState = createSelector(
    [getEditedMember, getUsers, getWizardFinished, getActiveMemberType],
    (editedMember, users, formData, activeMemberType) => {
        let startState = {
            registrationForm:
                activeMemberType === MemberTypes.USER ? { name: '', lastname: '', email: '' } : { id: '', name: '' },
            role: {
                type: ROLE_ORG_ADMIN,
                description: '',
                row: [],
                column: { operator: 'in', values: [] },
                features: { operator: 'in', values: [] },
            },
            editMemberId: null,
        };
        if (editedMember) {
            // TODO: Fix it when api is ready
            startState = {
                registrationForm:
                    activeMemberType === MemberTypes.USER
                        ? {
                              name: editedMember.first_name,
                              lastname: editedMember.last_name,
                              email: editedMember.email,
                          }
                        : {
                              id: editedMember.id,
                              name: editedMember.name,
                          },
                role: {
                    type: editedMember.role.type,
                    description: editedMember.role.description,
                    row: editedMember.role.row,
                    column: editedMember.role.column,
                    features: editedMember.role.features,
                },
                editMemberId: editedMember.id,
            };
        }
        return {
            startState,
            currentState: formData,
        };
    }
);

const getAllSubItems = (values, subItemKey) => {
    return values.reduce((total, parent) => {
        return total.concat(parent[subItemKey]);
    }, []);
};

export const managePermissionsData = createSelector(
    [getMetricsSelectionPage, getScreenSelectionPage, getPermissionDimensions],
    (metricsSelectionPage, screenSelectionPage, permissionDimensions) => {
        return {
            metrics: getAllSubItems(metricsSelectionPage.fields, 'values').map(i => i.name),
            screens: getAllSubItems(screenSelectionPage.screens, 'children').map(i => i.name),
            permissionDimensions,
        };
    }
);
