import React from 'react';
import PropTypes from 'prop-types';

const TableCellBoolean = ({ value, TrueIcon, FalseIcon, trueIconClass, falseIconClass, tdStyle }) => (
    <td style={{ textAlign: 'center', ...tdStyle }}>
        {value ? <TrueIcon className={trueIconClass} /> : <FalseIcon className={falseIconClass} />}
    </td>
);

TableCellBoolean.defaultProps = {
    TrueIcon: () => null,
    FalseIcon: () => null,
    tdStyle: {},
};

TableCellBoolean.propsTypes = {
    value: PropTypes.boolean,
    TrueIcon: PropTypes.component,
    FalseIcon: PropTypes.component,
    trueIconClass: PropTypes.string,
    falseIconClass: PropTypes.string,
};

export default TableCellBoolean;
