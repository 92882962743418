import { createSelector } from 'reselect';
import { getAttributionAppsList } from '../selectors/apps';
import { getAppIcon } from '../utils/apps';
import { getTranslate } from '../selectors/locale';
import { sortBanners } from './utils';
import { BannerSortedField } from './consts';

const getConfigurationPerApp = state => state.banners.configurationPerApp;
export const getLoading = state => state.banners.loading;
export const getLoadingConfigs = state => state.banners.loadingConfigs;
export const getSearching = state => state.banners.searching;
export const getAppsFilter = state => state.banners.appsFilter;
export const getSelectedAppId = state => state.banners.selectedAppId;
export const getSelectedAppSiteId = state => state.banners.selectedAppSiteId;
export const getShelf = state => state.banners.shelf;
export const getSavingConfiguration = state => state.banners.savingConfiguration;
export const getCountries = state => state.banners.countries;
export const getSavingError = state => state.banners.savingError;
export const getSavedNewBanner = state => state.banners.savedNewBanner;

const getAppItemData = (app, translate, config) => {
    // Loading threeDots
    // if (!config) {
    //     return null;
    // }

    return {
        content: ' ',
        sites: app.sites,
    };
};

const selectedAppConfigHelper = (configurationPerApp, selectedAppSiteId) => {
    if (!selectedAppSiteId) {
        return null;
    }

    const appConfig = configurationPerApp[selectedAppSiteId];

    return appConfig || null;
};

export const getSelectedAppConfig = createSelector(
    [getConfigurationPerApp, getSelectedAppSiteId],
    selectedAppConfigHelper
);

export const getSelectedAppLastConfig = createSelector([getSelectedAppId], selectedAppConfigHelper);

export const getApps = createSelector(
    [getAttributionAppsList, getSelectedAppId, getTranslate, getAppsFilter, getConfigurationPerApp],
    (apps, selectedAppId, translate, appsFilter, configurationPerApp) => {
        if (!apps) {
            return [];
        }
        return apps
            .filter(app => {
                const appSites = app ? app.sites || [] : [];
                const websites = appSites.filter(site => site.platform.toLowerCase() === 'web'); // && site.sdk_integrated);
                if (!websites.length) {
                    return false;
                }

                if (!appsFilter) {
                    return true;
                }

                const sitesString = websites.map(site => site.site_public_id).join();
                const searchString = `${app.name}${sitesString}`;
                return searchString.toLowerCase().includes(appsFilter.toLowerCase());
            })
            .map(app => {
                const { app_id: id, name, external_uuid } = app;
                const appItemData = getAppItemData(app, translate, configurationPerApp[id]);
                const selected = id === selectedAppId;
                const icon = getAppIcon(app);

                return { id, name, external_uuid, icon, selected, ...appItemData };
            });
    }
);

export const getBanners = createSelector([getSelectedAppConfig], appConfig => {
    const banners = appConfig ? appConfig.configuredBanners : [];
    return sortBanners(banners, BannerSortedField.PRIORITY);
});
