import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Spinner, Shelf, EmptyState, ShelfTabs } from '../../components/widgets';
import css from './Admin.css';
import VIcon from '../../resources/svg/icon_small_ok.svg';
import XIcon from '../../resources/svg/icon_small_not_ok.svg';

const distStyle = {
    disabled: {
        backgroundColor: '#fff',
    },
    summaryRoot: {
        minHeight: '40px',
        fontSize: '14px',
        padding: '0 16px',
    },
    summaryContent: {
        margin: 0,
    },
    summaryDisabled: {
        opacity: '1 !important',
    },
    summaryExpanded: {
        margin: '0 !important',
        minHeight: '40px !important',
        position: 'relative',
    },
    accordionRoot: {
        boxShadow: 'none',
    },
};

const AnimationItem = posed.div({
    notLoaded: {
        y: 20,
        opacity: 0,
    },
    loaded: {
        opacity: 1,
        y: 0,
    },
});

const AnimationContainer = posed.div({
    notLoaded: {
        opacity: 0,
        staggerChildren: 20,
    },
    loaded: {
        opacity: 1,
        staggerChildren: 20,
    },
});

class DistributionsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dTab: 0,
            loaded: false,
            expandedPanels: [],
        };
        this.dTabChange = this._dTabChange.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loaded: true,
            });
        });
    }

    _dTabChange(event, dTab) {
        if (dTab === this.state.dTab) {
            return;
        }
        this.setState({
            loaded: false,
        });
        setTimeout(() => {
            this.setState({
                dTab,
            });
            setTimeout(() => {
                this.setState({
                    loaded: true,
                });
            });
        }, 300);
    }

    onPanelChange(panelIndex, expanded) {
        const { expandedPanels } = this.state;
        const newState = [...expandedPanels];
        const index = expandedPanels.findIndex((panelId) => panelId === panelIndex);
        if (index !== -1) {
            newState.splice(index, 1);
        } else {
            newState.push(panelIndex);
        }
        this.setState({
            expandedPanels: newState,
        });
    }

    render() {
        const { admin, adminMode, classes } = this.props;
        const { dTab, loaded, expandedPanels } = this.state;
        const distribution = admin && admin.configured_distributions.length && admin.configured_distributions[dTab];
        if (!distribution) {
            return (
                <EmptyState
                    icon="happyPage"
                    style={{ marginTop: '100px' }}
                    header="No distributions found for this segment yet"
                />
            );
        }

        const tasks = distribution.task_runs
            ? distribution.task_runs
                  .map((task) => {
                      const taskCopy = { ...task };
                      if (taskCopy.time_end) {
                          taskCopy.timeEndTS = moment(taskCopy.time_end).unix();
                      }
                      if (taskCopy.time_start) {
                          taskCopy.timeStartTS = moment(taskCopy.time_start).unix();
                      }
                      return taskCopy;
                  })
                  .sort((t1, t2) => {
                      return t2.timeStartTS - t1.timeStartTS;
                  })
                  .slice(0, 10)
            : [];

        const panelDetailsMap = {
            time_start: {
                displayName: 'Started at',
                visible: true,
                modifier: value => moment(value).format('lll'),
                basis: '50%',
            },
            num_devices_total: {
                displayName: 'Number of devices',
                visible: true,
                modifier: value => (value === null ? 0 : value),
                basis: '50%',
            },
            time_end: {
                displayName: 'Ended at',
                visible: true,
                modifier: value => moment(value).format('lll'),
                basis: '50%',
            },
            duration: {
                displayName: 'Duration',
                visible: true,
                value: value => moment.utc((value.timeEndTS - value.timeStartTS) * 1000).format('HH:mm:ss'),
                basis: '50%',
            },
            ran_manually: {
                displayName: 'Manual run',
                visible: adminMode,
                modifier: value => (value ? 'True' : 'False'),
            },
            s3_export_key_current_full: {
                displayName: 'S3 export key current full',
                visible: adminMode,
            },
            s3_export_key_previous_full: {
                displayName: 'S3 export key previous full',
                visible: adminMode,
            },
            error_string: {
                display: 'Error description',
                visible: adminMode,
            },
        };

        const configuredDistributions = admin.configured_distributions;

        return (
            <div className={css.distributionsContainer}>
                <ShelfTabs
                    selectedTabId={dTab}
                    onChange={this.dTabChange}
                    tabs={configuredDistributions.map((cd) => ({
                        id: cd.network,
                        label: `${cd.network} ${cd.fb_account_id ? `(${cd.fb_account_id})` : ''}`,
                    }))}
                />
                <div className={css.container}>
                    <div className={css.dataRow}>
                        <div className={css.dataRowTitle}>Segment:</div>
                        <div className={css.dataRowValue}>{admin.name}</div>
                    </div>
                    <div className={css.dataRow}>
                        <div className={css.dataRowTitle}>Description:</div>
                        <div className={css.dataRowValue}>{admin.description}</div>
                    </div>
                    <div className={css.dataRow}>
                        <div className={css.dataRowTitle}>Network:</div>
                        <div className={css.dataRowValue}>{distribution.network}</div>
                    </div>
                    {distribution.fb_account_name && (
                        <div className={css.dataRow}>
                            <div className={css.dataRowTitle}>FB Account Name:</div>
                            <div className={css.dataRowValue}>{distribution.fb_account_name}</div>
                        </div>
                    )}
                    {distribution.fb_account_id && (
                        <div className={css.dataRow}>
                            <div className={css.dataRowTitle}>FB Account ID:</div>
                            <div className={css.dataRowValue}>{distribution.fb_account_id}</div>
                        </div>
                    )}
                    {distribution.type && (
                        <div className={css.dataRow}>
                            <div className={css.dataRowTitle}>Distribution Type:</div>
                            <div className={css.dataRowValue}>{distribution.type}</div>
                        </div>
                    )}
                    <div className={css.dataRow}>
                        <div className={css.dataRowTitle}>Currently Running:</div>
                        <div className={css.dataRowValue}>{distribution.running ? 'Yes' : 'No'}</div>
                    </div>
                    {adminMode && (
                        <div className={css.dataRow}>
                            <div className={css.dataRowTitle}>Latest Succesfull Distribution Based On:</div>
                            <div className={css.dataRowValue}>{distribution.latest_s3_export_full}</div>
                        </div>
                    )}
                    <div className={css.divider} />
                    <div className={`${css.dataRowTitle} ${css.bold}`} style={{ marginBottom: 10 }}>
                        Distribution Logs:
                    </div>
                    <AnimationContainer pose={loaded ? 'loaded' : 'notLoaded'}>
                        {tasks.map((tr, trIndex) => (
                            <AnimationItem
                                key={`task_${distribution.network}_${tr.timeStartTS}`}
                                className={css.taskContainer}
                            >
                                <Accordion
                                    classes={{ disabled: classes.disabled, root: classes.accordionRoot }}
                                    onChange={(e, expanded) => {
                                        this.onPanelChange(trIndex, expanded);
                                    }}
                                >
                                    <AccordionSummary
                                        classes={{
                                            disabled: classes.summaryDisabled,
                                            root: classes.summaryRoot,
                                            content: classes.summaryContent,
                                            expanded: classes.summaryExpanded,
                                        }}
                                    >
                                        <div
                                            className={`${css.panelContainer} ${
                                                expandedPanels.includes(trIndex) ? css.expanded : ''
                                            }`}
                                        >
                                            <div className={css.panelArrow} />
                                            <span className={`${css.panelCell} ${css.panelName}`}>
                                                {`Start time: ${moment(tr.time_start).format('lll')}`}
                                            </span>
                                            {tr.ran_manually && <span className={css.panelCell}>(Manual)</span>}
                                            <span className={`${css.panelCell} ${css.statusIcon}`}>
                                                {tr.succeeded ? <VIcon /> : <XIcon />}
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: css.adminTaskRun }}>
                                        <div className={css.panelSeparator} />
                                        <div className={css.panelDetailsHolder}>
                                            {Object.entries(panelDetailsMap)
                                                .filter(([k, v]) => v.visible)
                                                .map(([k, v]) => (
                                                    <div
                                                        className={`${css.dataRow} ${css.dataRowDetails}`}
                                                        key={`task_details_${k}`}
                                                        style={{
                                                            width: v.basis || '100%',
                                                        }}
                                                    >
                                                        {(k in tr || v.value) && (
                                                            <React.Fragment>
                                                                <div className={css.dataRowTitleSmall}>
                                                                    {v.displayName}
                                                                </div>
                                                                <div title={tr[k]} className={css.dataRowValue}>
                                                                    {`${
                                                                        v.value
                                                                            ? v.value(tr)
                                                                            : v.modifier
                                                                            ? v.modifier(tr[k])
                                                                            : tr[k]
                                                                    }`}
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </AnimationItem>
                        ))}
                    </AnimationContainer>
                </div>
            </div>
        );
    }
}

const StyledDistributionsTab = withStyles(distStyle)(DistributionsTab);

export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.tabChange = this._tabChange.bind(this);

        const { adminMode } = props;
        this.state = {
            tab: adminMode ? 0 : 2,
        };
    }

    _tabChange(event, tab) {
        this.setState({ tab });
    }

    render() {
        const { editCancel, adminId, admin, adminMode } = this.props;
        const { tab } = this.state;
        const useTab = adminMode ? tab : 2;
        return (
            <Shelf
                open={!!adminId}
                headerText={adminMode ? 'Admin Mode' : 'Segment Distribution History'}
                onClose={editCancel}
                shelfSize="medium"
            >
                {!admin ? (
                    <Spinner show className={css.spinner} />
                ) : (
                    <React.Fragment>
                        {adminMode ? (
                            <ShelfTabs
                                selectedTabId={useTab}
                                onChange={this.tabChange}
                                tabs={[
                                    {
                                        id: 'details',
                                        label: 'Details',
                                    },
                                    {
                                        id: 'export',
                                        label: 'Export',
                                    },
                                    {
                                        id: 'distributions',
                                        label: 'Distributions',
                                        disabled: admin.configured_distributions.length === 0,
                                    },
                                ]}
                                level={adminMode ? 2 : 1}
                            />
                        ) : null}
                        {useTab === 0 && (
                            <div className={css.container}>
                                <div>Name</div>
                                <div>
                                    <b>{admin.name}</b>
                                </div>
                                <div style={{ marginTop: '4px' }}>Aerospike ID</div>
                                <div>
                                    <b>{admin.aerospike_uuid}</b>
                                </div>
                                <div style={{ marginTop: '4px' }}>Latest S3 Export Full</div>
                                <div>
                                    <a href={admin.s3_full_url}>{admin.s3_full_name}</a>
                                </div>
                                <div style={{ marginTop: '4px' }}>Latest S3 Export Add</div>
                                <div>
                                    <a href={admin.s3_add_url}>{admin.s3_add_name}</a>
                                </div>
                                <div style={{ marginTop: '4px' }}>Latest S3 Export Remove</div>
                                <div>
                                    <a href={admin.s3_remove_url}>{admin.s3_remove_name}</a>
                                </div>
                            </div>
                        )}
                        {useTab === 1 && (
                            <div className={css.container}>
                                <div style={{ marginTop: '4px' }}>Aerospike Export JSON</div>
                                <pre>{admin.export}</pre>
                            </div>
                        )}
                        {useTab === 2 && <StyledDistributionsTab admin={admin} adminMode={adminMode} />}
                    </React.Fragment>
                )}
            </Shelf>
        );
    }
}
