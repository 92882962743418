import API from 'services/api';

let instance = null;
const api = new API();
const URL_ORG_USERS = '/api/organizations/self';
const URL_ORG_AGENCIES = '/api/agencies/self';
const UPDATE_ORG_USER = 'edit-user-permission';
const SEND_INVITATION = '/send-invite-to-organization-user';
const REVOKE_INVITATION = '/revoke-invite-to-organization-user';
const RESEND_INVITATION = '/resend-invite-to-organization-user';
const DISABLE_USER = '/api/deactivate_org_user';
const CHECK_EMAIL_EXISTS = '/check-organization-email-exists';

class TeamManagementAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getOrgUsers(disableBrowserCache) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORG_USERS, {
                method: 'GET',
                credentials: 'include',
                disableBrowserCache,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    exportTeamMembers() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORG_USERS, {
                method: 'GET',
                credentials: 'include',
                params: { export: 'true' },
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getOrgAgencies() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORG_AGENCIES, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateOrgUser(orgUserID, role) {
        return new Promise((resolve, reject) => {
            api.getJson(UPDATE_ORG_USER, {
                method: 'POST',
                credentials: 'include',
                body: {
                    org_user_id: orgUserID,
                    role,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateOrgAgency(agencyId, role) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORG_AGENCIES, {
                method: 'POST',
                credentials: 'include',
                body: {
                    agency_id: agencyId,
                    role,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    deleteOrgAgency(agencyId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORG_AGENCIES, {
                method: 'DELETE',
                credentials: 'include',
                params: { agency_id: agencyId },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    disableUser(orgUserId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${DISABLE_USER}/${orgUserId}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    sendInvitation(params) {
        return new Promise((resolve, reject) => {
            api.getJson(SEND_INVITATION, {
                method: 'POST',
                credentials: 'include',
                body: {
                    invite_params: params,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    resendInvitation(inviteId) {
        return new Promise((resolve, reject) => {
            api.getJson(RESEND_INVITATION, {
                method: 'POST',
                credentials: 'include',
                body: {
                    invite_uid: inviteId,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    revokeInvitation(inviteId) {
        return new Promise((resolve, reject) => {
            api.getJson(REVOKE_INVITATION, {
                method: 'POST',
                credentials: 'include',
                body: {
                    invite_uid: inviteId,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    checkUserExists(email) {
        return new Promise((resolve, reject) => {
            api.getJson(CHECK_EMAIL_EXISTS, {
                method: 'POST',
                credentials: 'include',
                body: { email },
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default TeamManagementAPI;
