import {
    COARSE_VALUE_LOW,
    COARSE_VALUE_MEDIUM,
    FINE_BUCKET_DEFAULT_SIZE,
    MIN_REVENUE_BUCKETS,
    ModelKinds,
    RevenueModelTypes,
    DECIMAL_PLACES,
    MAX_REVENUE_VALUE,
} from '../../../consts';
import css from '../../modelTypeForms/RevenueConversionModelForm.css';
import TableCellText from '../../../../components/widgets/TableCellText';
import { isObjectEmpty } from '../../../../utils/general';
import { getCoarseValueByIndex, calculateNonCustomBuckets, getNonAscendingBucketIndex } from '../../../utils';
import { getCoarseValueColumnDef, getConversionValueColumnDef } from '../ConversionModelShelfStep1CoarseTable';
import TableCellNumericRange from '../../../../components/widgets/TableCellNumericRange';

function isDeleteBucketDisabled(values) {
    const { buckets = [] } = values;
    return buckets.length + 1 <= MIN_REVENUE_BUCKETS;
}

function isUsingFlexibleBuckets(values, isSkan4 = false) {
    if (isSkan4) {
        return values.isCustomRevenue;
    } else {
        return values.buckets && typeof values.buckets !== 'string';
    }
}

function isAddRevenueBucketDisabled(values, errors, bucketOptions) {
    const { buckets } = values;
    const isFlexibleMode = isUsingFlexibleBuckets(values);

    return (
        !isFlexibleMode ||
        (!!errors.buckets && buckets.length !== 1) ||
        buckets.length === bucketOptions.length + 1 || // bucketOptions don't include the first bucket.
        buckets[buckets.length - 1] > MAX_REVENUE_VALUE ||
        getNonAscendingBucketIndex(buckets) !== null
    );
}

/**
 * Calculate the next bucket row coarse value as follows:
 * - copying the current index value to the next row added unless the row current row is not valid.
 * - if we are in the first row and the next value is not Low - will populate MEDIUM as next value.
 * @param {object} currenCustomMapping - the curren custom mapping
 * @param {number} index - the current index
 * @returns {object} newCustomMapping - the current customMapping with the addition of the new row.
 */
function calcCustomMappingWithNewRowAdded(currenCustomMapping, index) {
    const newCustomMapping = { ...currenCustomMapping };

    const currentCoarseValue = parseInt(newCustomMapping[index], 10);
    const nextCoarseValue = parseInt(newCustomMapping[index + 1], 10);

    if (!nextCoarseValue && nextCoarseValue !== 0) {
        newCustomMapping[index + 1] = currentCoarseValue.toString();
    } else if (nextCoarseValue < currentCoarseValue) {
        newCustomMapping[index + 1] = currentCoarseValue.toString();
        newCustomMapping[index + 2] = (nextCoarseValue + 1).toString();
    } else {
        newCustomMapping[index + 2] = nextCoarseValue.toString();
    }

    if (index === 0 && currenCustomMapping[1] !== COARSE_VALUE_LOW.toString()) {
        newCustomMapping[index + 1] = COARSE_VALUE_MEDIUM.toString();
    }

    return newCustomMapping;
}

const createAddRevenueBucketHandler = (values, setValues, isSkan4 = false) => {
    const { buckets, customMapping } = values;

    return index => {
        const newValues = { ...values, buckets: [...buckets, buckets[buckets.length - 1] + FINE_BUCKET_DEFAULT_SIZE] };

        if (isSkan4) {
            newValues.customMapping = calcCustomMappingWithNewRowAdded(customMapping, index);
        }

        setValues(newValues);
    };
};

const getConversionBucketColumnDef = (
    values,
    setValues,
    errors = {},
    bucketOptions = [],
    showRowActions = false,
    isMultiStep
) => {
    const isConversionValueColumn = isMultiStep && values.modelType !== ModelKinds.MIXED;

    return {
        displayName: isConversionValueColumn
            ? 'STATIC.PAGES.SKADNETWORK.CONVERSION_VALUE'
            : 'STATIC.PAGES.SKADNETWORK.BUCKET_NUMBER',
        className: css.tableHeader,
        headerAlign: 'center',
        headerTooltip: isConversionValueColumn ? 'STATIC.PAGES.SKADNETWORK.CONVERSION_BUCKET_COLUMN_TOOLTIP' : '',
        cellValues: ['isEditable', { propName: 'value', dataKey: 'bucketNumber' }],
        headerWidth: '115px',
        cellComponent: TableCellText,
        cellProps: {
            addDisabledTooltip: 'STATIC.PAGES.SKADNETWORK.ADD_REVENUE_BUCKET_DISABLED',
            getIsAddDisabled: () => isAddRevenueBucketDisabled(values, errors, bucketOptions),
            getIsDeleteDisabled: () => isDeleteBucketDisabled(values),
            onAdd: createAddRevenueBucketHandler(values, setValues),
            onDelete: () => {
                const { buckets } = values;
                setValues({
                    ...values,
                    buckets: buckets.slice(0, buckets.length - 1),
                });
            },
            showRowActions,
            tdStyle: {
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
            },
        },
    };
};

export const getTotalRevenueColumnDef = (values, setValues, isWide = false, isCustomTable = false) => ({
    displayName: 'STATIC.PAGES.SKADNETWORK.TOTAL_REVENUE',
    className: css.tableHeader,
    headerAlign: 'center',
    headerTooltip: !isCustomTable ? 'STATIC.PAGES.SKADNETWORK.TOTAL_REVENUE_COLUMN_TOOLTIP' : null,
    cellValues: ['from', 'to', 'isHidden', 'translationArgs', 'isEditable', 'index', 'error'],
    cellComponent: TableCellNumericRange,
    headerWidth: '190px',
    cellProps: {
        disableAutoScroll: isCustomTable,
        maxValue: MAX_REVENUE_VALUE,
        decimalPlaces: DECIMAL_PLACES,
        valueLabel: 'STATIC.PAGES.SKADNETWORK.REVENUE_VALUE_LABEL',
        getEmptyValueLabel: () => {
            return values.revenueType === RevenueModelTypes.ADMON
                ? 'STATIC.PAGES.SKADNETWORK.NO_IMPRESSIONS_LABEL'
                : 'STATIC.PAGES.SKADNETWORK.NO_PURCHASES_LABEL';
        },
        isCellEmpty: (index, from, to) => {
            return !isCustomTable && from === to && index === 0;
        },
        onValueChanged: (newValue, index) => {
            const { apsalarEventIds, buckets } = values;

            const currRevenueCellIndex = isCustomTable ? index - (apsalarEventIds?.length || 0) : index;

            const newBuckets = [...buckets];
            const newValueIndex = isCustomTable ? currRevenueCellIndex + 1 : currRevenueCellIndex;
            newBuckets[newValueIndex] = newValue;

            setValues({
                ...values,
                buckets: newBuckets,
            });
        },
        classNames: [css.tableHeader, isWide ? css.totalRevenueWide : ''],
    },
});

export function getFlexibleRevenueTableMetadata(values = {}, setValues = () => {}, errors = {}, bucketOptions = []) {
    return [
        getTotalRevenueColumnDef(values, setValues, false, false),
        getConversionBucketColumnDef(values, setValues, errors, bucketOptions, true),
    ];
}

export function getFlexibleRevenueTableData(
    values,
    isEditable = true,
    isSkan4 = false,
    errors = {},
    isMultiStep = false
) {
    const { buckets, unit, currency, numberOfRevenueBuckets } = values;
    const { customMapping: customMappingErrors = {}, buckets: bucketsErrors = {} } = errors;

    if (!buckets) {
        return [];
    }

    const useFlexibleBuckets = isUsingFlexibleBuckets(values, isSkan4);

    if (!useFlexibleBuckets && !unit) {
        return [];
    }

    const bucketData = [
        0,
        ...(useFlexibleBuckets
            ? buckets.map(value => (value ? Number(parseFloat(value).toFixed(DECIMAL_PLACES)) : value))
            : calculateNonCustomBuckets(isSkan4 ? numberOfRevenueBuckets : buckets, unit)),
    ];

    const translationArgs = {
        currency,
    };

    let mappedBucketData = bucketData.map((value, index) => {
        const from = value;
        const nextBucket = bucketData[index + 1];
        const to = typeof nextBucket === 'undefined' ? Infinity : nextBucket;

        return {
            index,
            bucketNumber: isMultiStep ? index : index + 1,
            from,
            to,
            translationArgs,
            error: bucketsErrors[index - 1]?.errorMsg,
            // One before last, and also specifically 'no purchases' when there are only two rows
            isEditable:
                useFlexibleBuckets &&
                index === bucketData.length - 2 &&
                (index !== 0 || bucketData.length === 2) &&
                isEditable,
        };
    });

    const { customMapping } = values;

    if (isMultiStep && !isObjectEmpty(customMapping)) {
        mappedBucketData = mappedBucketData.map(({ index, ...currentBucketData }) => ({
            ...currentBucketData,
            index,
            coarseValueDisabled: index === 0,
            coarseValue: getCoarseValueByIndex(customMapping, index),
            coarseValueError: customMappingErrors[index]?.errorCode,
            coarseValueWarning: customMappingErrors[index]?.warningCode,
        }));
    }

    return mappedBucketData;
}

export const getCustomMappingRevenueTableMetadata = (values, setValues, errors, bucketOptions, isMultiStep) => {
    const { isCustomRevenue, buckets, customMapping } = values;

    const actionsConfig = {
        getIsAddDisabled: () => isAddRevenueBucketDisabled(values, errors, bucketOptions),
        getIsDeleteDisabled: rowIndex => rowIndex <= 1,
        addDisabledTooltip: 'STATIC.PAGES.SKADNETWORK.ADD_REVENUE_BUCKET_DISABLED',
        onAdd: createAddRevenueBucketHandler(values, setValues, true),
        onDelete: index => {
            const newCustomMapping = { ...customMapping };
            newCustomMapping[index] = newCustomMapping[index + 1];
            delete newCustomMapping[index + 1];

            setValues({
                ...values,
                customMapping: newCustomMapping,
                buckets: buckets.slice(0, buckets.length - 1),
            });
        },
    };

    return [
        getConversionValueColumnDef(isMultiStep),
        getTotalRevenueColumnDef(values, setValues, true, false),
        ...(isMultiStep ? [getCoarseValueColumnDef(values, setValues, true, isCustomRevenue, actionsConfig)] : []),
    ];
};
