import { isCohortMetric } from '../../metricsUtils';

const UNKNOWN = 'N/A';

const SHORTEN_NUMBER_CONFIG = {
    1_000_000: 'M',
};

const getFixedNumber = number => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);

const formatBigNumber = (number, numberSize, suffix) => {
    return `${getFixedNumber(number / numberSize)}${suffix}`;
};

const formattedMetricNumericValue = (metricValue, isFullNumber) => {
    if (!metricValue) return UNKNOWN;
    if (isFullNumber) return Intl.NumberFormat().format(metricValue);

    for (const scalingFactor of Object.keys(SHORTEN_NUMBER_CONFIG)
        .sort()
        .reverse()) {
        const symbol = SHORTEN_NUMBER_CONFIG[scalingFactor];
        if (metricValue >= scalingFactor) return formatBigNumber(metricValue, scalingFactor, symbol);
    }

    return getFixedNumber(metricValue);
};

const formattedPercentageNumericValue = (metricValue, isFullNumber) => {
    if (!metricValue) return UNKNOWN;
    const percentageValue = metricValue * 100;
    return `${isFullNumber ? percentageValue : getFixedNumber(percentageValue)}%`;
};

export const getFormattingFunction = value =>
    isCohortMetric(value) ? formattedPercentageNumericValue : formattedMetricNumericValue;
