export const FETCH_META_DONE = 'FETCH_META_DONE';
export const FETCH_META_FAILED = 'FETCH_META_FAILED';
export const ERROR = 'ERROR';
export const TOGGLE_EXPAND_APP = 'TOGGLE_EXPAND_APP';
export const TOGGLE_EXPAND_NETWORK = 'TOGGLE_EXPAND_NETWORK';
export const TOGGLE_EXPAND_CAMPAIGN = 'TOGGLE_EXPAND_CAMPAIGN';
export const COLLAPSE_ALL = 'COLLAPSE_ALL';
export const EXPAND_ALL = 'EXPAND_ALL';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const ADD_FILTER_TAG = 'ADD_FILTER_TAG';
export const REMOVE_FILTER_TAG = 'REMOVE_FILTER_TAG';
export const CLEAR_FILTER_TAGS = 'CLEAR_FILTER_TAGS';
export const HANDLE_FILTER_SELECTOR = 'HANDLE_FILTER_SELECTOR';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const SEARCH_TRACKING_LINK = 'SEARCH_TRACKING_LINK';
export const DELETE_CAMPAIGN_CLICKED = 'DELETE_CAMPAIGN_CLICKED';
export const DELETE_CAMPAIGN_CONFIRMED = 'DELETE_CAMPAIGN_CONFIRMED';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAILED = 'DELETE_CAMPAIGN_FAILED';
export const DELETE_CAMPAIGN_DISMISSED = 'DELETE_CAMPAIGN_DISMISSED';
export const EDIT_CAMPAIGN_NAME_CLICKED = 'EDIT_CAMPAIGN_NAME_CLICKED';
export const EDIT_CAMPAIGN_NAME_CONFIRMED = 'EDIT_CAMPAIGN_NAME_CONFIRMED';
export const EDIT_CAMPAIGN_NAME_SUCCESS = 'EDIT_CAMPAIGN_NAME_SUCCESS';
export const EDIT_CAMPAIGN_NAME_FAILED = 'EDIT_CAMPAIGN_NAME_FAILED';

export function fetchMetaDone(response) {
    return {
        type: FETCH_META_DONE,
        apps: response.apps,
        trackingLinks: response.tracking_links,
        enabledCampaignEditing: response.enable_campaign_editing,
    };
}

export function fetchMetaFailed(error) {
    return {
        type: FETCH_META_FAILED,
        error,
    };
}

export function toggleExpandApp(app) {
    return {
        type: TOGGLE_EXPAND_APP,
        app,
    };
}

export function toggleExpandNetwork(app, network) {
    return {
        type: TOGGLE_EXPAND_NETWORK,
        app,
        network,
    };
}

export function toggleExpandCampaign(campaign) {
    return {
        type: TOGGLE_EXPAND_CAMPAIGN,
        campaign,
    };
}

export function collapseAll() {
    return {
        type: COLLAPSE_ALL,
    };
}

export function expandAll() {
    return {
        type: EXPAND_ALL,
    };
}

export function addFilter() {
    return {
        type: ADD_FILTER,
    };
}

export function removeFilter(index) {
    return {
        type: REMOVE_FILTER,
        index,
    };
}

export function changeFilter(index, key, data) {
    return {
        type: CHANGE_FILTER,
        key,
        index,
        data,
    };
}

export function addFilterTag(filterIndex, tag) {
    return {
        type: ADD_FILTER_TAG,
        filterIndex,
        tag,
    };
}

export function removeFilterTag(filterIndex, tag) {
    return {
        type: REMOVE_FILTER_TAG,
        filterIndex,
        tag,
    };
}

export function clearFilterTags(filterIndex, tags) {
    return {
        type: CLEAR_FILTER_TAGS,
        filterIndex,
        tags,
    };
}

export function handleFilterSelector(filterType) {
    return {
        type: HANDLE_FILTER_SELECTOR,
        filterType,
    };
}

export function updateSearch(search) {
    return {
        type: UPDATE_SEARCH,
        search,
    };
}

export function searchTrackingLink(shouldClear) {
    return {
        type: SEARCH_TRACKING_LINK,
        shouldClear: shouldClear === true,
    };
}

export function deleteCampaignClicked(campaign) {
    return {
        type: DELETE_CAMPAIGN_CLICKED,
        campaign,
    };
}

export function deleteCampaignDismissed() {
    return {
        type: DELETE_CAMPAIGN_DISMISSED,
    };
}

export function deleteCampaignConfirmed(campaign) {
    return {
        type: DELETE_CAMPAIGN_CONFIRMED,
        campaign,
    };
}

export function deleteCampaignSuccess(campaign) {
    return {
        type: DELETE_CAMPAIGN_SUCCESS,
        campaign,
    };
}

export function deleteCampaignFailed() {
    return {
        type: DELETE_CAMPAIGN_FAILED,
    };
}

export function editCampaignNameClicked(campaign) {
    return {
        type: EDIT_CAMPAIGN_NAME_CLICKED,
        campaign,
    };
}

export function editCampaignNameConfirmed(campaignID, campaignName, campaignData, appID) {
    return {
        type: EDIT_CAMPAIGN_NAME_CONFIRMED,
        campaignID,
        campaignName,
        campaignData,
        appID,
    };
}

export function editCampaignNameSuccess(campaignID, campaignName, response) {
    return {
        type: EDIT_CAMPAIGN_NAME_SUCCESS,
        campaignID,
        campaignName,
        response,
    };
}

export function editCampaignNameFailed() {
    return {
        type: EDIT_CAMPAIGN_NAME_FAILED,
    };
}
