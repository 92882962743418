export const MEDIATORS = {
    ADMOB: 'ADMOB',
    UNITY: 'UNITY',
    APPLOVIN: 'APPLOVIN',
    TRADPLUS: 'TRADPLUS',
    OTHER: 'OTHER',
};

export const MEDIATORS_DISPLAY_NAME = {
    [MEDIATORS.TRADPLUS]: 'TradPlus',
};

export const DEFAULT_MEDIATOR = MEDIATORS.ADMOB;
