import { connect } from 'react-redux';
import { Anonymous } from 'components/pages';
import { updateSecretId, updatePassword, hidePasswordModal, runAnonymousPage } from 'actions/anonymous';
import { getShowPasswordModal, getError } from 'selectors/anonymous';
import { parseSearchParams } from 'utils/url';

let reportType = '';

const mapStateToProps = (state, ownProps) => {
    if (ownProps.type !== reportType) {
        reportType = ownProps.type;
    }
    const showPasswordModal = getShowPasswordModal(state);
    const error = getError(state);
    return {
        reportType,
        showPasswordModal,
        error,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { secret_id: secretId } = parseSearchParams(window.location.search);

    if (secretId) {
        dispatch(updateSecretId(secretId));
    }

    return {
        onPasswordInput: password => {
            dispatch(updatePassword(password));
            dispatch(hidePasswordModal());
            dispatch(runAnonymousPage());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Anonymous);
