import { connect } from 'react-redux';
import { getCustomEventEditor } from 'customEvents/selectors';
import CustomEventEditor from 'customEvents/components/CustomEventEditor';
import {
    cancelClicked,
    toggleEventEditorClicked,
    uanEventRemoved,
    uanEventAdded,
    toggleEventCalculatedMetric,
    saveClicked,
    customEventNameChanged,
    editorUniqueToggle,
} from 'customEvents/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        ...getCustomEventEditor(ownProps.id)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onEventExpand: id => {
            dispatch(toggleEventEditorClicked(id));
        },
        onUANEventRemoved: (uanEvent, customEvent, uan) => {
            dispatch(uanEventRemoved(uanEvent, customEvent, uan));
        },
        onUANEventAdded: (uanEvent, customEvent, uan) => {
            dispatch(uanEventAdded(uanEvent, customEvent, uan));
        },
        onToggleCalculatedMetric: (...args) => {
            dispatch(toggleEventCalculatedMetric(...args));
        },
        onUniqueToggle: (...args) => {
            dispatch(editorUniqueToggle(...args));
        },
        onCancelClicked: () => {
            dispatch(cancelClicked());
        },
        onSaveClicked: (...args) => {
            dispatch(saveClicked(...args));
        },
        onEventNameChanged: (...args) => {
            dispatch(customEventNameChanged(...args));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEventEditor);
