import React from 'react';
import sha1 from 'js-sha1';
import { CohortMetricsMap, getCohortMetricAndPeriod, ALL_METRICS_OPTIONS } from './metricsUtils';
import { SERVER_DEFAULT_ICON_URL } from '../utils/apps';
import css from './page.css';
import { FormattedCreativeTypes } from './types';

const SHA_PREFIX = '1290726c5000cab87381bcd9f7b3f39a95586c32';
const CDN_URL_PREFIX = 'https://cdn.singular.net/dashboard/creatives/';
export const UNKNOWN_INDICATION = 'N/A';
export const DEFAULT_ICON_SRC = '/static/dashboard_react/src/resources/icons/default_app_icon.svg';

export const MULTI_VALUED_FIELDS = [
    {
        originalField: 'os',
        aggregatedField: 'osList',
    },
    {
        originalField: 'asset_name',
        aggregatedField: 'assetNameList',
    },
    {
        originalField: 'creative_name',
        aggregatedField: 'creativeNameList',
    },
    {
        originalField: 'asset_id',
        aggregatedField: 'assetIdList',
    },
    {
        originalField: 'creative_id',
        aggregatedField: 'creativeIdList',
    },
];

const getItemIcon = url => {
    const src = !url || url === SERVER_DEFAULT_ICON_URL ? DEFAULT_ICON_SRC : url;
    return <img className={css.autocompleteOptionIcon} src={src} alt={src} />;
};

export const convertObjectToOption = (item, iconUrlFieldName) => ({
    value: item.name,
    label: item.displayName,
    icon: getItemIcon(item[iconUrlFieldName]),
});

export const getOptionsList = (items, iconUrlFieldName) =>
    items.map(item => convertObjectToOption(item, iconUrlFieldName));

export const convertHashToCdnUrl = creativeHash => {
    const hash = sha1(`${SHA_PREFIX}${creativeHash}`);
    return `${CDN_URL_PREFIX}${hash}`;
};

const excludeUnknownsValues = (aggregatedDimensions, creativeImageHash, fieldName) => {
    aggregatedDimensions[creativeImageHash][fieldName] = [...aggregatedDimensions[creativeImageHash][fieldName]].filter(
        value => value !== UNKNOWN_INDICATION
    );
};

// Grouping the multivalued dimensions by creative
export const getAggregatedDimensionsByCreative = (creativesDimensions, multiValuedFields = MULTI_VALUED_FIELDS) => {
    const aggregatedDimensions = (creativesDimensions || []).reduce((acc, creative) => {
        const creativeAggregatedDimensions = acc[creative.creative_image_hash] || {};
        multiValuedFields.forEach(({ aggregatedField, originalField }) => {
            const creativeAggregatedDimensionField = creativeAggregatedDimensions[aggregatedField] || new Set();
            creativeAggregatedDimensionField.add(creative[originalField]);
            creativeAggregatedDimensions[aggregatedField] = creativeAggregatedDimensionField;
        });
        acc[creative.creative_image_hash] = creativeAggregatedDimensions;
        return acc;
    }, {});

    Object.keys(aggregatedDimensions).forEach(creativeImageHash => {
        multiValuedFields.forEach(({ aggregatedField }) => {
            excludeUnknownsValues(aggregatedDimensions, creativeImageHash, aggregatedField);
        });
    });
    return aggregatedDimensions;
};

const getCreativeMetricValue = (metric, creative) => {
    if (Object.values(CohortMetricsMap).find(({ value }) => value === metric)) {
        const [cohortMetric, cohortPeriod] = getCohortMetricAndPeriod(metric);
        return creative[cohortMetric]?.[cohortPeriod];
    }

    return creative[metric];
};

export const createCreativeFlatMetricsObject = (metrics, creative) =>
    metrics.reduce((acc, { value }) => {
        acc[value] = getCreativeMetricValue(value, creative) || 0;
        return acc;
    }, {});

// We want to filter out the creatives that don't have at least one of the selected metrics,
// and then map the creatives to the format that the grid expects, with joining the multivalued dimensions
export const formatCreatives = (creatives, metrics, creativesMultiValuedDimensions) => {
    if (!creatives) return [];

    const aggregatedDimensionsByCreative = creativesMultiValuedDimensions
        ? getAggregatedDimensionsByCreative(creativesMultiValuedDimensions)
        : {};

    const filteredCreatives = !metrics?.length
        ? creatives
        : creatives.filter(creative => metrics.some(metric => !!creative[metric.value]));

    return filteredCreatives.map(creative => ({
        height: parseInt(creative.creative_height, 10),
        width: parseInt(creative.creative_width, 10),
        type: FormattedCreativeTypes[creative.creative_type],
        metrics: createCreativeFlatMetricsObject(ALL_METRICS_OPTIONS, creative),
        imageHash: creative.creative_image_hash,
        url: convertHashToCdnUrl(creative.creative_image_hash),
        multiValuedDimensions: aggregatedDimensionsByCreative[creative.creative_image_hash] ?? {},
    }));
};
