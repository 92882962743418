export const ADDED_APP = 'action.onboarding.add.app.empty.state';
export const ADDED_DEVICE = 'action.onboarding.add.device.empty.state';
export const CLOSE_REPORTS_EMPTY_STATE = 'action.onboarding.set.close.reports.empty.state';
export const CLOSE_SDK_CONSOLE_EMPTY_STATE = 'action.onboarding.set.close.testing.console.empty.state';
export const CLOSE_SDK_KEYS_EMPTY_STATE = 'action.onboarding.set.close.sdk.keys.empty.state';
export const CLOSE_TESTING_CONSOLE_EMPTY_STATE = 'action.onboarding.set.close.sdk.empty.state';
export const COHORT_EVENT_ADDED = 'action.onboarding.set.cohort.event.added.empty.state';
export const UPDATE_ONBOARDING_DATA = 'action.onboarding.update.data';

export const setAddAppEmptyState = () => {
    return {
        type: ADDED_APP,
    };
};

export const onAddDevice = () => {
    return {
        type: ADDED_DEVICE,
    };
};

export const onCloseReportsEmptyState = () => {
    return {
        type: CLOSE_REPORTS_EMPTY_STATE,
    };
};

export const onCloseSDKConsoleEmptyState = () => {
    return {
        type: CLOSE_SDK_CONSOLE_EMPTY_STATE,
    };
};

export const onCloseSdkKeysEmptyState = () => {
    return {
        type: CLOSE_SDK_KEYS_EMPTY_STATE,
    };
};

export const onCloseTestingConsoleEmptyState = () => {
    return {
        type: CLOSE_TESTING_CONSOLE_EMPTY_STATE,
    };
};

export const onCohortEventAdded = () => {
    return {
        type: COHORT_EVENT_ADDED,
    };
};

export const updateOnboardingData = data => {
    return {
        type: UPDATE_ONBOARDING_DATA,
        data,
    };
};
