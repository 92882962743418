import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './TableCellLinkWithImg.css';
import FauxButton from './FauxButton';

const TableCellLinkWithImg = ({ value, subValue, onClick, row, buttonClassName }) => {
    const text = value;
    const Icon = row.icon;

    return (
        <td>
            <div className={css.container}>
                <Icon className={classNames(css.image, row.iconClassName ?? '')} />
                <FauxButton
                    className={classNames(css.labels, buttonClassName)}
                    onClick={() => {
                        onClick({ value, subValue, row });
                    }}
                >
                    {text}
                </FauxButton>
            </div>
        </td>
    );
};

TableCellLinkWithImg.propTypes = {
    value: PropTypes.string,
    subValue: PropTypes.string,
    onClick: PropTypes.func,
    row: PropTypes.shape({
        icon: PropTypes.element,
        iconClassName: PropTypes.string,
    }),
    buttonClassName: PropTypes.string,
};

TableCellLinkWithImg.defaultProps = {
    value: '',
    subValue: '',
    onClick: () => {},
    row: {},
    buttonClassName: '',
};

export default TableCellLinkWithImg;
