import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { localizeReducer } from 'react-localize-redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import customDimensions from 'customDimensions/reducer';
import teamManagement from 'teamManagement/reducer';
import reportConfigs from './reportsConfig';
import { createReducerForType } from './reports';
import fields from './fields';
import user from './user';
import onboarding from './onboarding';
import anonymous from './anonymous';
import createSmartLink from './createSmartLink';
import events from './events';
import apps from './apps';
import sources from './sources';
import webTrackingLinks from '../webTrackingLinks/reducers';
import trackingLinks from './trackingLinks';
import audienceSegments from '../audienceSegments/reducers';
import audienceDistributions from '../audienceDistributions/reducers';
import linkManagement from '../linkManagement/reducers';
import athenaOverRealtime from '../athenaOverRealtime/reducers';
import dataSources from '../dataSources/reducer';
import appLoadingState from '../appLoadingState/reducer';
import homepage from '../homepage/reducer';
import signup from '../signup/reducer';
import banners from '../banners/reducers';
import mta from '../mta/reducers';
import assistedInstalls from '../assistedInstalls/reducers';

const toCombine = {
    customDimensions,
    teamManagement,
    fields,
    user,
    onboarding,
    anonymous,
    routing,
    localize: localizeReducer,
    loadingBar,
    createSmartLink,
    events,
    apps,
    sources,
    webTrackingLinks,
    trackingLinks,
    audienceSegments,
    audienceDistributions,
    linkManagement,
    athenaOverRealtime,
    dataSources,
    appLoadingState,
    homepage,
    signup,
    banners,
    mta,
    assistedInstalls,
};

for (const config of reportConfigs) {
    toCombine[config.type] = createReducerForType(config.type);
}

const combined = combineReducers(toCombine);

export default combined;
