import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import css from './BannerHeader.css';
import { Label } from '../../components/widgets';
import ContextMenu from '../../components/widgets/ContextMenu';
import { archiveBannerClicked, editBannerClicked } from '../actions';
import PrioritizationPopover from './PrioritizationPopover';

function BannerHeader({ id, name, priority, className }) {
    const dispatch = useDispatch();
    const priorityRef = useRef(null);
    const [showPopover, setShowPopover] = useState(false);

    const menuItems = [
        {
            name: 'STATIC.PAGES.BANNERS.EDIT_BUTTON',
            onClick: () => dispatch(editBannerClicked(id)),
        },
        {
            name: 'STATIC.PAGES.BANNERS.ARCHIVE_BUTTON',
            onClick: () => dispatch(archiveBannerClicked(id)),
        },
    ];

    const _preventClickDefault = e => e.stopPropagation();
    const _actionMenuOnSelect = (item, propsVal) => item.onClick(propsVal);

    const _onPriorityClicked = () => setShowPopover(!showPopover);

    return (
        <div className={classNames(css.container, className)}>
            <div className={css.bannerPriority} ref={priorityRef} onClick={_onPriorityClicked}>
                <span>{priority}</span>
            </div>
            <PrioritizationPopover
                id={id}
                open={showPopover}
                anchorEl={priorityRef.current}
                priority={priority}
                onClose={() => setShowPopover(false)}
            />
            <Label className={css.bannerName} text={name} type="subtitle" />
            <div className={css.rightArea}>
                <div className={css.actionMenuContainer} onClick={_preventClickDefault}>
                    <ContextMenu className={css.actionMenuButton} onSelect={_actionMenuOnSelect} items={menuItems} />
                </div>
            </div>
        </div>
    );
}

BannerHeader.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    priority: PropTypes.number,
    className: PropTypes.string,
};

BannerHeader.defaultProps = {
    id: -1,
    name: '',
    status: '',
    priority: 1,
    className: '',
};

export default BannerHeader;
