import { createSelector } from 'reselect';
import { getAdminModeEnabled, getUserData } from '../selectors/user';

export const getAudienceDistributions = state => state.audienceDistributions;
export const getSegments = state => state.audienceDistributions.segments;
export const getEditingSegmentsIds = state => state.audienceDistributions.editingSegmentsIds;
export const getEditingSegmentsData = state => state.audienceDistributions.editingSegmentsData;
export const getEdit = state => state.audienceDistributions.edit;

const getSegmentEdit = (segment, config, edit) => {
    const segmentEdit = {
        segment_id: segment.id,
        uan_id: edit.distribution.id,
        config,
        fields: [],
    };
    segmentEdit.fields = edit.segment_fields.map(segmentField => {
        const segmentFieldCopy = { ...segmentField };
        if (segmentField.name === 'ad_account_id') {
            const accs = Object.values(edit.distribution.config.enabled_ad_accounts || {});
            segmentFieldCopy.choices = accs.map(acc => ({
                name: acc.account_id,
                display: `${acc.account_id}: ${acc.account_name}`,
            }));
        }
        return segmentFieldCopy;
    });

    return segmentEdit;
};

const getFormattedSegments = createSelector(
    [getSegments, getEditingSegmentsIds, getEditingSegmentsData, getEdit],
    (segments, editingSegmentIds, editingSegmentsData, edit) => {
        return segments
            ? segments.map(segment => {
                  const segmentCopy = { ...segment };
                  segmentCopy.open = editingSegmentIds.includes(segmentCopy.id);
                  segmentCopy.edit =
                      edit && editingSegmentsData[segment.id]
                          ? getSegmentEdit(segmentCopy, editingSegmentsData[segment.id], edit)
                          : null;

                  return segmentCopy;
              })
            : segments;
    }
);

export const getPage = createSelector(
    [getAudienceDistributions, getAdminModeEnabled, getUserData, getFormattedSegments],
    (state, adminMode, userData, segments) => {
        let filteredNetworks = state.networks;
        if (state.search) {
            const q = state.search.toLowerCase();
            filteredNetworks = filteredNetworks.filter(network => network.name.toLowerCase().includes(q));
        }
        return {
            ...state,
            networks: filteredNetworks,
            adminMode,
            companyName: userData.company_display_name,
            segments,
        };
    }
);
