import { connect } from 'react-redux';
import { ImpersonateOrgDialog } from '../components/partials';
import { getImpersonateDialog } from '../selectors/user';
import { closeImpersonateDialog, impersonateDialogStarted, impersonateOrg } from '../actions/user';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        ...getImpersonateDialog(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClose: () => {
            dispatch(closeImpersonateDialog());
        },
        onStart: () => {
            dispatch(impersonateDialogStarted());
        },
        onSelection: org => {
            dispatch(impersonateOrg(org));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateOrgDialog);
