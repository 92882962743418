import { connect } from 'react-redux';
import GoBackPanel from './goBackPanel';
import getCurrentPath from '../../selectors/routing';

const mapStateToProps = state => {
    return {
        currentPath: getCurrentPath(state),
    };
};

export default connect(mapStateToProps)(GoBackPanel);
