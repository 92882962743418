import PropTypes from 'prop-types';

export const conversionModelTypeShape = PropTypes.shape({
    postbackConversionValueType: PropTypes.string,
    postbackIndex: PropTypes.number,
    currency: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
    enabled: PropTypes.bool,
    revenueType: PropTypes.string,
    eventsType: PropTypes.string,
    revenueBucketSize: PropTypes.number,
    revenueBuckets: PropTypes.arrayOf(PropTypes.number),
    measurementPeriod: PropTypes.number,
    customMapping: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    conversionModelGroupId: PropTypes.number,
    isCustomRevenue: PropTypes.bool,
    typeDisplayName: PropTypes.string,
    revenueTypeDisplayName: PropTypes.string,
    eventsTypeDisplayName: PropTypes.string,
    modelEvents: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            isRevenue: PropTypes.bool,
            longname: PropTypes.string,
        })
    ),
});

export const decodingTableShape = PropTypes.objectOf(
    PropTypes.arrayOf(
        PropTypes.shape({
            event: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
        })
    )
);

export const conversionModelGroupShape = PropTypes.shape({
    conversionModels: PropTypes.arrayOf(conversionModelTypeShape),
    id: PropTypes.number,
    name: PropTypes.string,
    appId: PropTypes.number,
    siteId: PropTypes.number,
    isSkan4: PropTypes.bool,
});

export const modelErrorsShape = PropTypes.shape({
    modelType: PropTypes.objectOf(PropTypes.string),
    modelGroupName: PropTypes.objectOf(PropTypes.string),
    customMapping: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
    sdkEvents: PropTypes.objectOf(PropTypes.string),
    tableGeneralError: PropTypes.objectOf(PropTypes.string),
});

export const sdkEventsShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isRevenue: PropTypes.bool,
    longname: PropTypes.string,
    displayName: PropTypes.string,
});

export const typesListShape = PropTypes.shape({
    name: PropTypes.string,
    display_name: PropTypes.string,
    description: PropTypes.string,
    mixed_types: PropTypes.arrayOf(PropTypes.string),
});

export const optimizedModelsShape = PropTypes.shape({
    id: PropTypes.number,
    measurement_period: PropTypes.number,
    optimized_conversion_model: PropTypes.arrayOf(PropTypes.number),
    optimized_model_improvement: PropTypes.number,
    optimized_model_num_of_buckets: PropTypes.number,
    revenue_type: PropTypes.string,
});
