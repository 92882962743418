import { CohortMetricsMap, MetricsMap } from './metricsUtils';
import { convertHashToCdnUrl } from './creativesFormattingUtils';

const FILE_NAME_PREFIX = 'Creative Gallery';
const DATE_FORMAT_REGEX = /^\d+-\d+$/;
const UNKNOWN = 'N/A';

const CsvDimensionsMap = {
    IMAGE_HASH: {
        value: 'imageHash',
        label: 'Image Hash',
    },
    TYPE: {
        value: 'type',
        label: 'Type',
    },
    OS: {
        value: 'multiValuedDimensions.osList',
        label: 'OS',
    },
};

const buildFileName = (app, source, dateRange) =>
    `${FILE_NAME_PREFIX}-${app}-${source}-${dateRange.startDate}-${dateRange.endDate}.csv`;

const arrayCsvValue = value => `"${value?.join(', ')}"`;

const convertAttrGroups = (fields, creative) =>
    Object.values(fields).reduce((acc, { value, label }) => {
        const [topLevelKey, secondLevelKey] = value.split('.');
        const creativeValue = secondLevelKey ? creative[topLevelKey][secondLevelKey] : creative[topLevelKey];
        acc[label] = !creativeValue
            ? UNKNOWN
            : Array.isArray(creativeValue)
            ? arrayCsvValue(creativeValue)
            : `"${creativeValue}"`;
        return acc;
    }, {});

const buildCreativesCsvData = (creatives, creativeTagsByImageHash) =>
    creatives.map(creative => ({
        Url: convertHashToCdnUrl(creative.imageHash),
        ...convertAttrGroups(CsvDimensionsMap, creative),
        ...convertAttrGroups(MetricsMap, creative.metrics),
        ...convertAttrGroups(CohortMetricsMap, creative.metrics),
        ...(creativeTagsByImageHash[creative.imageHash] || {}),
    }));

export const aggregateCreativeAIDataByImageHash = creativesTags =>
    creativesTags.reduce((acc, { imageHash, dimensionName, tags }) => {
        acc[imageHash] = acc[imageHash] || {};
        const tagsString = tags.join(', ');
        acc[imageHash][dimensionName] = `${DATE_FORMAT_REGEX.test(tagsString) ? '=' : ''}"${tagsString}"`;
        return acc;
    }, {});

export const downloadCSV = (creativesRawData, creativesTags, app, source, dateRange) => {
    const creativeTagsByImageHash = aggregateCreativeAIDataByImageHash(creativesTags);
    const data = buildCreativesCsvData(creativesRawData, creativeTagsByImageHash);
    if (!data?.length) return;
    const csvString = [Object.keys(data[0]), ...data.map(item => Object.values(item))]
        .map(row => row.join(','))
        .join('\n');

    // Create a Blob from the CSV string
    const blob = new Blob(['\ufeff', csvString], { type: 'text/csv;charset=utf-8' });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = buildFileName(app, source, dateRange);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};
