import moment from 'moment/moment';
import Highcharts from 'highcharts';

import { X_AXIS_LABEL_MONTH_FORMAT, TITLE_LABEL_MONTH_FORMAT } from './consts';

export const monthLabelsFormatter = ({ value }) => {
    return moment(value).format(X_AXIS_LABEL_MONTH_FORMAT);
};

export const monthTitleFormatter = value => {
    return moment(value).format(TITLE_LABEL_MONTH_FORMAT);
};

export const dateLabelsFormatter = ({ value }) => {
    return Highcharts.dateFormat('%Y-%m-%d', value);
};

export const seriesFloatValueFormatter = value => {
    return value === 0 ? `${value}` : `${parseFloat(value).toFixed(2)}%`;
};

export const seriesRangeValueFormatter = currency => {
    return value => {
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency,
            maximumFractionDigits: 2,
        });
    };
};

export const mapSeriesValues = values => {
    return values.map(value => {
        return value ? value * 100 : value;
    });
};
