import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts, HighchartsChart, AreaSeries, useChart } from 'react-jsx-highcharts';
import css from '../styles.css';
import ChartTooltip from './chartTooltip';
import { colorByNetwork, overTimeChartPlotOptions } from '../consts';
import { dateLabelsFormatter, seriesFloatValueFormatter, mapSeriesValues } from '../utils';
import ChartLegend from './chartLegend';
import ChartXAxis from './chartXAxis';
import ChartYAxis from './chartYAxis';
import Chart from './chart';

const OverTimeChart = ({ chartData, key }) => {
    const [chartLoaded, setChartLoaded] = useState(false);
    const { data, labels } = chartData;
    const categories = labels.map((label) => {
        return new Date(label);
    });
    return (
        <HighchartsChart plotOptions={overTimeChartPlotOptions} callback={() => setChartLoaded(true)}>
            <ChartLegend />
            <Chart className="over_time_chart" loaded={chartLoaded} />
            <ChartTooltip categories={categories} />
            <ChartXAxis id={`${key}_x_axis`} categories={categories} showCrosshair formatter={dateLabelsFormatter} />
            <ChartYAxis id={`${key}_y_axis`} titleText={`<p class=${css.yAxisTitle}>Market<br/>Share</p>`}>
                {data.map(({ data: values, label }) => {
                    return (
                        <AreaSeries
                            formatter={seriesFloatValueFormatter}
                            key={label}
                            name={label}
                            color={colorByNetwork[label]}
                            data={mapSeriesValues(values)}
                            onLegendItemClick={() => {
                                return false;
                            }}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

OverTimeChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    key: PropTypes.string,
};

OverTimeChart.defaultProps = {
    chartData: {},
    key: 'over_time',
};

export default withHighcharts(OverTimeChart, Highcharts);
