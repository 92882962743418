import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NA_TEXT } from '../sdkKeys/consts';
import { getEmptyStateOrgData } from '../../selectors/onboarding';
import { EmptyStateTasks, isEmptyStateTasksDone } from '../../components/partials/EmptyState/utils';
import { SDK_PLANNING_EMPTY_STATE_TASKS } from '../sdkPlanning/consts';
import useGetSDKKeys from '../hooks/useGetSDKKeys';

const initialState = {
    userSdkKey: NA_TEXT,
    userSdkToken: NA_TEXT,
};

const SDKPageContext = createContext({ ...initialState });

const useSDKPageContext = () => useContext(SDKPageContext);

const SDKPageProvider = ({ children }) => {
    const emptyStateOrgData = useSelector(state => getEmptyStateOrgData(state));
    const tasksStatus = useMemo(() => emptyStateOrgData?.tasks || {}, [emptyStateOrgData]);

    const showEmptyState = !useMemo(
        () =>
            isEmptyStateTasksDone(SDK_PLANNING_EMPTY_STATE_TASKS, {
                ...tasksStatus,
                [EmptyStateTasks.SDK_INTEGRATED]: true,
            }),
        [tasksStatus]
    );

    const { sdk_key: userSdkKey = NA_TEXT, sdk_token: userSdkToken = NA_TEXT } = useGetSDKKeys();

    const { Provider } = SDKPageContext;

    return <Provider value={{ userSdkKey, userSdkToken, showEmptyState, tasksStatus }}>{children}</Provider>;
};

SDKPageProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SDKPageProvider, useSDKPageContext };
