import {
    BAR_CHART,
    COLUMN_CHART,
    CURRENCY_METRIC_TYPE,
    LINE_CHART,
    MULTI_CHART,
    PERCENTAGE_METRIC_TYPE,
    PIE_CHART,
    STACKED_CHART,
} from './consts';
import { calculatedMetricSumAggFunc, calculatedMetricValue } from '../../../utils/grids';
import { sortNumeric } from '../../../utils/sortUtil';

export const getMetricsFormat = reportColumns => {
    return reportColumns
        .filter(column => column.fieldType === 'metric')
        .map(metric => ({ name: metric.name, display_name: metric.displayName, displayFormat: metric.displayFormat }));
};

export const getMetricValueFormat = metric => {
    let formatString = '{value}';
    let valuePrefix = '';
    let valueSuffix = '';

    let valueDecimals = 0;
    const { displayFormat } = metric;
    if (displayFormat) {
        if (displayFormat.type === CURRENCY_METRIC_TYPE) {
            valuePrefix = (displayFormat.symbol || '').trim();
        } else if (displayFormat.type === PERCENTAGE_METRIC_TYPE) {
            valueSuffix = '%';
        }
        valueDecimals = displayFormat.precision || 0;
        formatString = `${valuePrefix}{value:.${valueDecimals}f}${valueSuffix}`;
    }
    return { valuePrefix, valueSuffix, valueDecimals, formatString };
};

const formatYAxisItem = (title, labelsFormat, yAxisIndex) => {
    return {
        labels: {
            format: labelsFormat,
            style: {},
        },
        title: {
            text: title,
            style: {},
        },
        // We want our Y axes to be divided equally between the left and right side
        opposite: Boolean(yAxisIndex % 2),
        softMin: 0,
    };
};

const getYAxis = metrics => {
    const yAxisList = [];
    const metricToYAxisMapping = new Map();

    metrics.forEach((metric, index) => {
        const { currentMetricFormat } = getMetricValueFormat(metric);
        yAxisList.push(formatYAxisItem(metric.display_name, currentMetricFormat, index));
        metricToYAxisMapping.set(metric.name, index);
    });

    return { yAxisList, metricToYAxisMapping };
};
const splitMetricsByTypes = metrics => {
    const metricTypesDict = new Map();
    metrics.forEach(metric => {
        const metricType = metric.displayFormat.type;
        if (!metricTypesDict.has(metricType)) {
            metricTypesDict.set(metricType, [metric]);
        } else {
            metricTypesDict.get(metricType).push(metric);
        }
    });
    return metricTypesDict;
};

const createYAxisListByMetricType = metricTypesDict => {
    const metricToYAxisMapping = new Map();
    const yAxisList = [];
    Array.from(metricTypesDict.keys()).forEach((key, index) => {
        const currMetrics = metricTypesDict.get(key);
        const { currentMetricFormat } = getMetricValueFormat(currMetrics[0]);
        currMetrics.forEach(metric => {
            metricToYAxisMapping.set(metric.name, index);
        });
        yAxisList.push({
            ...formatYAxisItem(currMetrics.map(metric => metric.display_name).join(' / '), currentMetricFormat, index),
        });
    });

    return { yAxisList, metricToYAxisMapping };
};

const getGroupedYAxis = metrics => {
    const metricTypesDict = splitMetricsByTypes(metrics);
    return createYAxisListByMetricType(metricTypesDict);
};

const getRowSeriesKey = (isPieChart, yAxis, row) => (isPieChart ? '' : yAxis.map(x => row[x.name]).join(' - '));

const getChartTypeForMultiChart = metricType => (metricType === PERCENTAGE_METRIC_TYPE ? LINE_CHART : COLUMN_CHART);

// multiple metrics && not multiple breakdowns - only one should exist

const isMultipleMetricsChart = (metrics, yAxis) => metrics.length > 1 && !yAxis.length;

export const isPieChart = chartType => chartType.name === PIE_CHART;

export const getChartDataSettings = (chartType, metrics, xAxis, yAxis, rowData) => {
    // refer this example for building the chart options: https://www.highcharts.com/demo/combo-multi-axes
    try {
        const categoryDict = new Map();
        let seriesList = [];

        const allowedCharts = [LINE_CHART, BAR_CHART, COLUMN_CHART, MULTI_CHART];
        const shouldGroupYAxis = allowedCharts.includes(chartType.name);
        const { yAxisList, metricToYAxisMapping } = shouldGroupYAxis ? getGroupedYAxis(metrics) : getYAxis(metrics);
        // Aggregate each row's value into the right category (X Axis) and series (Y Axis)
        metrics.forEach(metric => {
            const { valuePrefix, valueSuffix, valueDecimals } = getMetricValueFormat(metric);
            const metricType = metric.displayFormat.type;
            const seriesDict = new Map();

            rowData.forEach(row => {
                // Get or create category
                const rowCategoryName = xAxis.map(x => row[x.name]).join(' - ');
                if (!categoryDict.has(rowCategoryName)) {
                    categoryDict.set(rowCategoryName, { index: categoryDict.size });
                }

                const currentValue = row[metric.name];
                if (currentValue === 'N/A' || currentValue === 0) return;

                const rowCategory = categoryDict.get(rowCategoryName);

                // Get or create series
                const rowSeriesKey = getRowSeriesKey(isPieChart(chartType), yAxis, row);

                let currChartType;
                if (chartType.name === MULTI_CHART) {
                    currChartType = getChartTypeForMultiChart(metricType);
                } else if (chartType.name === STACKED_CHART) {
                    currChartType = COLUMN_CHART;
                } else {
                    currChartType = chartType.name;
                }

                if (!seriesDict.has(rowSeriesKey)) {
                    seriesDict.set(rowSeriesKey, {
                        name:
                            isMultipleMetricsChart(metrics, yAxis) || (!isPieChart(chartType) && rowSeriesKey === '')
                                ? metric.display_name
                                : rowSeriesKey,
                        data: [],
                        dataSorting: { enabled: false },
                        yAxis: metricToYAxisMapping.get(metric.name),
                        type: currChartType,
                        tooltip: {
                            valueSuffix,
                            valuePrefix,
                            valueDecimals,
                        },
                    });
                }
                // Get or create data entry in series
                const rowSeries = seriesDict.get(rowSeriesKey);
                if (rowCategory.index > rowSeries.data.length) {
                    // Fill the gaps with null
                    rowSeries.data = [
                        ...rowSeries.data,
                        ...new Array(rowCategory.index - rowSeries.data.length).fill(null),
                    ];
                }
                const dataArrayElement = rowSeries.data[rowCategory.index];
                if (dataArrayElement === undefined || dataArrayElement === null) {
                    rowSeries.data[rowCategory.index] = { name: rowCategoryName, y: currentValue };
                } else if (typeof currentValue === 'object') {
                    dataArrayElement.y = calculatedMetricSumAggFunc([dataArrayElement.y, currentValue]);
                } else {
                    dataArrayElement.y += currentValue;
                }
            });
            // Now that we've aggregated all the metrics, perform two last operations:
            // 1. If there are still calculated metrics that require the final calculation, perform it (e.g. CPC/CTR)
            // 2. Multiply by 100 for percentage type metrics
            seriesDict.forEach(series => {
                series.data.forEach(dataArrayElement => {
                    if (!dataArrayElement) return;

                    if (typeof dataArrayElement.y === 'object') {
                        dataArrayElement.y = calculatedMetricValue(dataArrayElement.y);
                    }

                    if (metric.displayFormat && metricType === PERCENTAGE_METRIC_TYPE) {
                        dataArrayElement.y *= 100;
                    }
                });
            });

            seriesList = [...seriesList, ...Array.from(seriesDict.values())];
        });

        // highcharts accepts arrays
        return [Array.from(categoryDict.keys()), seriesList, yAxisList];
    } catch (err) {
        console.log('error', err);
        return [[], []];
    }
};

export const getFilteredRowData = (rowsData = [], topXCount, metricForTopX) => {
    if (rowsData.length === 0 || !rowsData[0].hasOwnProperty(metricForTopX)) {
        return rowsData;
    } else {
        return rowsData
            .sort((rowData1, rowData2) => {
                return sortNumeric(rowData1[metricForTopX], rowData2[metricForTopX], true);
            })
            .slice(0, topXCount);
    }
};
