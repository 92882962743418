import { snakeCase } from 'lodash';
import API from '../services/api';

let instance = null;
const api = new API();

const GENERATE_MTA_REPORT_PATH = '/api/mta';

export default class MtaApi {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    generateReport(data) {
        const formData = {};
        Object.keys(data).forEach(key => {
            formData[snakeCase(key)] = data[key];
        });

        return new Promise((resolve, reject) => {
            api.getJson(GENERATE_MTA_REPORT_PATH, {
                params: formData,
                method: 'GET',
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status === 0) {
                        return resolve(response);
                    }
                    return reject(response);
                })
                .catch(reject);
        });
    }
}
