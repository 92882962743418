import React from 'react';
import PropTypes from 'prop-types';
import css from './FauxButton.css';

class FauxButton extends React.PureComponent {
    render() {
        const { children, onClick, containerStyle, disabled, className } = this.props;
        return (
            <button
                className={`${css.button} ${className || ''}`}
                style={containerStyle}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }
}

FauxButton.propTypes = {
    children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    onClick: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

FauxButton.defaultProps = {
    children: '',
    onClick: () => {},
    containerStyle: {},
    disabled: false,
    className: '',
};

export default FauxButton;
