import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { organizationOptionsShape, partnerShape } from '../types';
import GeoRestrictions from './GeoRestrictions';
import { GET_COUNTRIES } from '../../queries';
import { Spinner } from '../../../components/widgets';
import { getIsUserAdmin } from '../../../selectors/user';
import LDSFactory from './lds/LDSFactory';

function AdvancedSettings({ partner, isReadonly, orgOptions }) {
    const { geoRestrictionsSupported = false, ldsSupported = false } = orgOptions;
    const countriesResponse = useQuery(GET_COUNTRIES, { skip: !geoRestrictionsSupported });
    const isUserAdmin = useSelector(state => getIsUserAdmin(state));

    if (geoRestrictionsSupported && countriesResponse.loading) return <Spinner show expanded />;

    return (
        <>
            {ldsSupported && <LDSFactory partner={partner} isReadonly={isReadonly || isUserAdmin} />}
            {geoRestrictionsSupported && (
                <GeoRestrictions
                    partner={partner}
                    countries={countriesResponse.data?.countries || []}
                    isReadonly={isReadonly}
                />
            )}
        </>
    );
}

AdvancedSettings.propTypes = {
    partner: partnerShape.isRequired,
    orgOptions: organizationOptionsShape.isRequired,
    isReadonly: PropTypes.bool,
};

AdvancedSettings.defaultProps = {
    isReadonly: false,
};

export default AdvancedSettings;
