import { createSelector } from 'reselect';
import { sortAlphabetically, sortBool } from '../utils/sortUtil';
import {
    parseDate,
    LAST_DATA_IMPORT_COLUMN,
    LAST_DATA_DATE_COLUMN,
    ETL_AUTH_TYPE,
    ETL_FEATURE_FLAG,
    parseETLStatus,
} from './utils';
import { getAdminModeEnabled, getUserData } from '../selectors/user';
import { getIsLoading } from '../appLoadingState/selector';
import { CUSTOMER_TIERS } from '../utils/consts';
import { getAdNetworkOptions } from '../utils/adNetworks';

export const getOrgUans = state =>
    state.dataSources.uans.filter(uan => state.dataSources.isETL ^ (uan.data_type !== '5'));

export const getExport2phaseLatestTaskKey = state => state.dataSources.export2phaseLatestTaskKey;
export const getSelectedUanData = state => state.dataSources.selectedUanData;
export const getShelf = state => state.dataSources.shelf;
export const getUanSaveSucceeded = state => state.dataSources.uanSaveSucceeded;
export const redirectToPartnerConfiguration = state => state.dataSources.redirectToPartnerConfiguration;
export const getApiRequestFailed = state => state.dataSources.apiRequestFailed;
export const getUanSaveErrorMessage = state => state.dataSources.uanSaveErrorMessage;
export const getUpdatedUanId = state => state.dataSources.updatedUanId;
export const getAuthPopupUrl = state => state.dataSources.authPopupUrl;
export const getIsETL = state => state.dataSources.isETL;
export const getAdNetworks = state => {
    const adnetworks = state.dataSources.adNetworks;
    return Object.keys(adnetworks)
        .filter(key => state.dataSources.isETL ^ !adnetworks[key].is_destination)
        .reduce((obj, key) => {
            obj[key] = adnetworks[key];
            return obj;
        }, {});
};
export const getPartnersIds = state => state.dataSources.partnersIds;
export const getUanAction = state => state.dataSources.uanAction;
export const getUanAccounts = state => state.dataSources.uanAccountsData;
export const getSendScrapeJobStatus = state => state.dataSources.sendScrapeJobStatus;
export const getOrgHasAppsWithSDKEvent = state =>
    !!(
        state.apps.appsData.entities &&
        state.apps.appsData.entities.sites &&
        Object.values(state.apps.appsData.entities.sites).filter(site => site.latest_sdk_version).length
    );

export const getShelfData = createSelector(
    [
        getOrgUans,
        getUanSaveSucceeded,
        getUanSaveErrorMessage,
        getOrgHasAppsWithSDKEvent,
        getUpdatedUanId,
        getAuthPopupUrl,
        getUanAccounts,
        getSendScrapeJobStatus,
        redirectToPartnerConfiguration,
    ],
    (
        uansData,
        uanSaveSucceeded,
        uanSaveErrorMessage,
        orgHasAppsWithSDKEvent,
        updatedUanId,
        authPopupUrl,
        uanAccountsData,
        sendScrapeJobStatus,
        partnerSourceId
    ) => {
        return {
            uansData,
            showSavingSuccess: uanSaveSucceeded,
            uanSaveErrorMessage,
            orgHasAppsWithSDKEvent,
            updatedUanId,
            authPopupUrl,
            uanAccountsData,
            sendScrapeJobStatus,
            partnerSourceId,
        };
    }
);

export const getDataSourcesPage = createSelector(
    [
        getOrgUans,
        getUserData,
        getAdminModeEnabled,
        getExport2phaseLatestTaskKey,
        getIsLoading,
        getAdNetworks,
        getPartnersIds,
        getUanAction,
        getSelectedUanData,
        getShelf,
        getApiRequestFailed,
        getUpdatedUanId,
    ],
    (
        uansData,
        userData,
        adminMode,
        export2phaseLatestTaskKey,
        isLoadingUans,
        adNetworks,
        partnersIds,
        uanAction,
        selectedUanData,
        shelf,
        apiRequestFailed,
        updatedUanId
    ) => {
        return {
            uanAction,
            updatedUanId,
            isLoadingUans,
            export2phaseLatestTaskKey,
            selectedUanData,
            shelf,
            apiRequestFailed,
            adminMode,
            partnersIds,
            username: userData.username,
            timezone: userData.timezone,
            isUserSlackAuth: userData.is_slack_auth,
            showFieldsAvailabilityFeature: userData.is_fields_availability_enable,
            isStandardAnalytics: userData.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS,
            featureFlags: userData.feature_flags ? userData.feature_flags : [],
            etlStatusEnabled: userData.etl_status_enabled,
            isContinuousETL: userData.is_continuous_etl,
            adNetworkOptions: getAdNetworkOptions(adNetworks),
            uansData: uansData
                .map((uanData, i) => {
                    let { etlStatus, etlPopoverContent } = { etlStatus: null, etlPopoverContent: [] };
                    const shouldParseEtlStatus =
                        userData.feature_flags &&
                        userData.feature_flags.includes(ETL_FEATURE_FLAG) &&
                        uanData.auth_type !== ETL_AUTH_TYPE;
                    if (shouldParseEtlStatus) {
                        [etlStatus, etlPopoverContent] = parseETLStatus(uanData);
                    }

                    return {
                        key: `${uanData.uan_id}_${i}`,
                        isUanLoading: uanData.status === undefined,
                        displayName: uanData.display_name,
                        messages: (uanData.messages || []).map(msg => {
                            if (msg.text) {
                                const msgText = msg.text.replace(
                                    '[contact]',
                                    '<a target="_blank" href="mailto:support@singular.net">support@singular.net</a>'
                                );
                                return { ...msg, text: msgText };
                            }
                            return msg;
                        }),
                        status: uanData.status,
                        isActionRequired: uanData.is_action_required,
                        isDataDelay: uanData.is_data_delay,
                        imgSrc: uanData.img_src || uanData.imgSrc,
                        subText: uanData.sub_text,
                        subTextExtra: uanData.sub_text_extra,
                        etlTableName: uanData.etl_table_name,
                        lastDataDate: parseDate(uanData, LAST_DATA_DATE_COLUMN),
                        lastDataDateColor: uanData.is_data_delay ? '#d0021b' : undefined,
                        lastDataImportDate: parseDate(uanData, LAST_DATA_IMPORT_COLUMN, userData.timezone),
                        uanId: uanData.uan_id,
                        showCsvExport: uanData.show_csv_export,
                        showInArchive: uanData.show_in_archive,
                        dataType: uanData.data_type,
                        authType: uanData.auth_type,
                        is_ad_monetization: uanData.is_ad_monetization,
                        is_connector_frozen: uanData.is_connector_frozen,
                        is_login_error: uanData.is_login_error,
                        report_validation_error:
                            uanData.report_validation_error && Object.keys(uanData.report_validation_error).length > 0
                                ? uanData.report_validation_error
                                : null,
                        channel: uanData.channel,
                        warningMessage: uanData.warningMessage,
                        agencyName: uanData.agency_name,
                        adnetworkName: uanData.name,
                        canDataRefresh: uanData.can_data_refresh,
                        canDataAvailability: uanData.can_data_availability,
                        canDataConnectorAlerts: uanData.can_data_connector_alerts,
                        orgTimezone: uanData.organization_timezone,
                        earliestDataDate: uanData.earliest_data_date,
                        isSpecialOauthAuthorized: uanData.isSpecialOauthAuthorized,
                        showUsernameImg: uanData.data_type === '1' && uanData.sub_text,
                        etlPopoverHeader: uanData.etl_states
                            ? `${uanData.display_name} Data Destinations Breakdown (${uanData.etl_states.length})`
                            : null,
                        etlPopoverContent,
                        etlStatus,
                    };
                })
                .sort((uanData1, uanData2) => {
                    const { displayName: displayName1, isActionRequired: isActionRequired1 } = uanData1;
                    const { displayName: displayName2, isActionRequired: isActionRequired2 } = uanData2;

                    return (
                        sortBool(isActionRequired1, isActionRequired2) || sortAlphabetically(displayName1, displayName2)
                    );
                }),
        };
    }
);
