import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import Group2 from '../../resources/svg/loading_page/group2.svg';
import Group3 from '../../resources/svg/loading_page/group3.svg';
import Group4 from '../../resources/svg/loading_page/group4.svg';
import Group5 from '../../resources/svg/loading_page/group5.svg';
import Group6 from '../../resources/svg/loading_page/group6.svg';
import Group7 from '../../resources/svg/loading_page/group7.svg';
import Group8 from '../../resources/svg/loading_page/group8.svg';
import Group9 from '../../resources/svg/loading_page/group9.svg';
import Group10 from '../../resources/svg/loading_page/group10.svg';
import css from './CountUpLoader.css';

const CountUpLoader = ({ endTimePercentage, loadingDurationTimeSeconds, animationTransitionMilliseconds }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const imagesRef = useRef([
        <Group2 />,
        <Group3 />,
        <Group4 />,
        <Group5 />,
        <Group6 />,
        <Group7 />,
        <Group8 />,
        <Group9 />,
        <Group10 />,
    ]);

    useEffect(() => {
        setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % imagesRef.current.length);
        }, animationTransitionMilliseconds);
    });

    function Icons() {
        return imagesRef.current[currentIndex];
    }

    return (
        <div className={css.loader}>
            <div className={css.image}>
                <Icons />
            </div>
            <div className={css.circle}>
                <CountUp
                    className={css.countUp}
                    end={endTimePercentage}
                    duration={loadingDurationTimeSeconds}
                    suffix="%"
                />
            </div>
        </div>
    );
};

CountUpLoader.propTypes = {
    endTimePercentage: PropTypes.number,
    loadingDurationTimeSeconds: PropTypes.number,
    animationTransitionMilliseconds: PropTypes.number,
};

CountUpLoader.defaultProps = {
    endTimePercentage: 99,
    loadingDurationTimeSeconds: 40,
    animationTransitionMilliseconds: 1500,
};

export default CountUpLoader;
