import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import debounce from 'lodash/debounce';
import Spinner from '../components/widgets/Spinner';
import { PageWrapper, PageHeaderSlim } from '../components/partials';
import BenchmarksFilters from './components/benchmarksFilters';
import css from './styles.css';
import {
    BENCHMARKS_GRAPH_TYPES,
    COUNTRY_FILTER,
    CATEGORY_FILTER,
    PLATFORM_FILTER,
    APP_FILTER,
    TRACK_EVENT_FILTER_CHANGE,
    BENCHMARKS_PAGES,
    NON_EMPTY_FILTERS,
    TRACK_EVENT_PREFIX,
    TRACK_EVENT_PAGE_LOADED,
} from './consts';
import BenchmarksCharts from './components/benchmarksCharts';
import { useFetchShareOfVoiceVsMeChartsData, useStickyHeader } from './hooks';
import { calculateChartTitle, shouldEnableFiltersFuncByPage, validationErrorsFuncs } from './utils';
import WizardWarningMessage from '../teamManagement/components/WizardWarningMessage';
import { trackMixpanelEvent } from '../utils/general';

const charts = [BENCHMARKS_GRAPH_TYPES.SHARE_OF_COST];
let globalValidationErrors;

const BenchmarksMarketShareVsMe = () => {
    const ref = useRef(null);
    const [isFading, setFading] = useState(false);
    const [selectedFilterValues, setSelectedFilterValues] = useState({});
    const [enableFilters, setEnabledFilters] = useState(true);
    const {
        graphs: chartsData = {},
        name_by_label: nameByLabel,
        logo_by_name: logoByName,
        isLoading,
    } = useFetchShareOfVoiceVsMeChartsData(selectedFilterValues);

    useEffect(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_PAGE_LOADED);
    }, []);

    useStickyHeader(ref);

    const shareOfCostChartsData = chartsData[BENCHMARKS_GRAPH_TYPES.SHARE_OF_COST];

    /**
     * debouncing filters change to let the user change more than one filter before the charts refreshes
     * @type {*|debounced}
     */
    const onFiltersChange = useCallback(
        debounce((newSelectedValues) => {
            !globalValidationErrors && setSelectedFilterValues(newSelectedValues);
            setFading(false);
        }, 2000),
        []
    );
    const onChange = (newSelectedValues, validationErrors) => {
        globalValidationErrors = validationErrors;
        trackMixpanelEvent(TRACK_EVENT_PREFIX ,TRACK_EVENT_FILTER_CHANGE, { selectedValues: newSelectedValues });
        setEnabledFilters(shouldEnableFiltersFuncByPage[BENCHMARKS_PAGES.SHARE_OF_VOICE_VS_ME](newSelectedValues));

        if (!validationErrors) {
            setFading(true);
            onFiltersChange(newSelectedValues);
        }
    };
    const warningMsgDelay = 300;
    const pageWrapperStyle = {};
    const filtersStyle = {};

    if (!enableFilters) {
        filtersStyle.top = 34;
        pageWrapperStyle.paddingTop = '60px';
    }

    return (
        <Fragment>
            <WizardWarningMessage
                animatedElStyle={{ position: 'sticky', zIndex: 6 }}
                containerStyle={{
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    left: 0,
                    zIndex: 20,
                }}
                show={!enableFilters}
                message="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE_VS_ME.MAX_FILTER_VALUES_SELECTED"
                textReplace={{}}
                showIcon
                showTypeIcon
                textAlignCenter
                type="warning"
                delay={warningMsgDelay}
            />
            <PageWrapper style={pageWrapperStyle} className={css.pageWrapper} ref={ref}>
                <div className={css.headerWrapper}>
                    <PageHeaderSlim
                        text="STATIC.PAGE_HEADERS.BENCHMARKS_MARKET_SHARE_OF_VOICE_VS_ME"
                        subheaderText="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.SUBHEADER"
                        className={css.header}
                    />
                </div>
                <BenchmarksFilters
                    onChange={onChange}
                    enableFilters={enableFilters}
                    wrapperStyle={filtersStyle}
                    getValidationErrors={validationErrorsFuncs[NON_EMPTY_FILTERS]}
                    viewName={BENCHMARKS_PAGES.SHARE_OF_VOICE_VS_ME}
                >
                    <BenchmarksFilters.Apps optionsKey={APP_FILTER} isMulti />
                    <BenchmarksFilters.Categories optionsKey={CATEGORY_FILTER} isMulti />
                    <BenchmarksFilters.Countries optionsKey={COUNTRY_FILTER} isMulti />
                    <BenchmarksFilters.Platforms optionsKey={PLATFORM_FILTER} isMulti />
                </BenchmarksFilters>
                <Spinner style={{ top: '10vh' }} show={isLoading} expanded />
                {shareOfCostChartsData && (
                    <BenchmarksCharts isLoading={isLoading} isFading={isFading} charts={charts} showEmptyChartComponent>
                        {shareOfCostChartsData.map((chartData) => {
                            const title = calculateChartTitle(chartData, selectedFilterValues[APP_FILTER], nameByLabel);

                            return (
                                <BenchmarksCharts.ShareOfCostChart
                                    nameByLabel={nameByLabel}
                                    logoByName={logoByName}
                                    title={calculateChartTitle(
                                        chartData,
                                        selectedFilterValues[APP_FILTER],
                                        nameByLabel
                                    )}
                                    key={`${BENCHMARKS_GRAPH_TYPES.SHARE_OF_COST}${title}`}
                                    data={chartData}
                                    chartKey={`${BENCHMARKS_GRAPH_TYPES.SHARE_OF_COST}${title}`}
                                />
                            );
                        })}
                    </BenchmarksCharts>
                )}
            </PageWrapper>
        </Fragment>
    );
};

export default BenchmarksMarketShareVsMe;
