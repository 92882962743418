import moment from 'moment-timezone';
import update from 'immutability-helper';
import { faCircle as faCircleSolid } from '@fortawesome/fontawesome-free-solid';
import { faCircle as faCircleRegular } from '@fortawesome/fontawesome-free-regular';
import {
    LOAD,
    UNLOAD,
    SEARCH,
    TOGGLE_STATUS,
    EDIT_START,
    EDIT_START_DONE,
    EDIT_CANCEL,
    EDIT_SAVE_START,
    EDIT_SAVE_DONE,
    EDIT_DETAILS,
    DELETE_START,
    DELETE_CANCEL,
    DELETE_CONFIRM,
    ADD_FILTER,
    EDIT_FILTER,
    DELETE_FILTER,
    ADD_CONDITION,
    EDIT_CONDITION,
    DELETE_CONDITION,
    VALIDATE_SEGMENT,
    CHANGE_SORT,
    OPEN_SHARING_OPTIONS,
    CLOSE_SHARING_OPTIONS,
    SHARING_OPTIONS_LOADED,
} from './actions';
import { FILTER_TYPES } from './consts';
import { generateGuid } from '../global/utils';

const initialState = {
    loading: true,
    segments: null,
    search: '',
    sort: {
        col: 'modifiedUnixTS',
        ascending: false,
        type: 'number',
    },
    editId: null,
    edit: null,
    sharingSegmentId: null,
    sharingLinks: null,
    editValidations: false,
    editInvalidMsg: false,
    editDirty: false,
    deleteId: null,
    metadata: null,
    templates: null,
    saving: false,
    admin: null,
    adminId: null,
};

const isEventFilter = f => f.filter_type === FILTER_TYPES.EVENT;

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD: {
            const { segments, templates, activeSegmentsLimit } = action;
            segments.forEach(segment => {
                segment.modifiedStr = moment.tz(segment.modified, 'America/Los_Angeles').fromNow();
                segment.modifiedUnixTS = moment.tz(segment.modified, 'America/Los_Angeles').unix();
                segment.statusIcon =
                    segment.status === 'inactive' && segment.status_reason === 'automatic'
                        ? faCircleRegular
                        : faCircleSolid;
            });

            return update(state, {
                loading: { $set: false },
                segments: { $set: segments },
                templates: { $set: templates },
                activeSegmentsLimit: { $set: activeSegmentsLimit },
            });
        }
        case UNLOAD:
            return initialState;
        case SEARCH: {
            return update(state, {
                search: { $set: action.query },
            });
        }
        case TOGGLE_STATUS: {
            const segments = state.segments.slice(0);
            for (let i = 0; i < segments.length; i++) {
                if (segments[i].id === action.id) {
                    segments[i] = update(segments[i], {
                        status: { $set: action.status },
                    });
                }
            }
            return update(state, {
                segments: { $set: segments },
            });
        }
        case EDIT_START: {
            const { admin, id, duplicate } = action;
            const editId = duplicate ? -1 : id;
            if (admin) {
                return update(state, {
                    adminId: { $set: editId },
                });
            }
            return update(state, {
                editId: { $set: editId },
            });
        }
        case EDIT_START_DONE: {
            if (action.duplicate) {
                action.segment.name = '';
            }
            if (action.admin) {
                return update(state, {
                    admin: { $set: action.segment },
                });
            }
            return update(state, {
                metadata: { $set: action.metadata || state.metadata },
                edit: { $set: action.segment },
            });
        }
        case EDIT_CANCEL: {
            return update(state, {
                editId: { $set: null },
                editValidations: { $set: false },
                editInvalidMsg: { $set: false },
                edit: { $set: null },
                editDirty: { $set: false },
                admin: { $set: null },
                adminId: { $set: null },
            });
        }
        case EDIT_SAVE_START: {
            return update(state, {
                saving: { $set: true },
            });
        }
        case EDIT_SAVE_DONE: {
            const segments = state.segments.slice(0);
            const segment = update(state.edit, {
                id: { $set: action.id },
                modified: { $set: new Date() },
                modifiedStr: { $set: 'now' },
                status: { $set: action.status },
            });
            if (state.editId > 0) {
                for (let i = 0; i < segments.length; i++) {
                    if (segments[i].id === state.editId) {
                        segments.splice(i, 1);
                        break;
                    }
                }
            }

            segments.unshift(segment);
            return update(state, {
                segments: { $set: segments },
                saving: { $set: false },
                editId: { $set: null },
                editValidations: { $set: false },
                editInvalidMsg: { $set: false },
                edit: { $set: null },
                editDirty: { $set: false },
            });
        }
        case EDIT_DETAILS: {
            const { key, value } = action;
            let s = state;
            if (key === 'apps' && value.length < state.edit.apps.length) {
                // If we have event filters with events selected for a removed app, we need to update them
                const validAppValues = value.map(a => a.value);
                state.edit.filters.forEach((filter, index) => {
                    filter.conditions.forEach((condition, cIndex) => {
                        if (!condition.event) return;
                        const appValue = condition.event.value
                            .split('/')
                            .slice(0, 2)
                            .join('/');
                        if (validAppValues.indexOf(appValue) === -1) {
                            s = update(s, {
                                edit: {
                                    filters: {
                                        [index]: {
                                            conditions: {
                                                [cIndex]: {
                                                    event: { $set: null },
                                                },
                                            },
                                        },
                                    },
                                },
                            });
                        }
                    });
                });
            }
            return update(s, {
                edit: {
                    [key]: { $set: value },
                },
                editDirty: { $set: true },
            });
        }
        case DELETE_START: {
            return update(state, {
                deleteId: { $set: action.id },
            });
        }
        case DELETE_CANCEL: {
            return update(state, {
                deleteId: { $set: false },
            });
        }
        case DELETE_CONFIRM: {
            const segments = state.segments.slice(0);
            const i = segments.findIndex(s => s.id === action.id);
            segments.splice(i, 1);
            return update(state, {
                deleteId: { $set: false },
                segments: { $set: segments },
            });
        }
        case ADD_FILTER: {
            if (!action.filter.id) {
                action.filter.id = generateGuid();
                action.filter.new = true;
            }
            return update(state, {
                edit: {
                    filters: { $push: [action.filter] },
                },
                editDirty: { $set: true },
            });
        }
        case EDIT_FILTER: {
            const { index, key, value } = action;
            return update(state, {
                edit: {
                    filters: {
                        [index]: {
                            [key]: { $set: value },
                        },
                    },
                },
                editDirty: { $set: true },
            });
        }
        case DELETE_FILTER: {
            return update(state, {
                edit: {
                    filters: { $splice: [[action.index, 1]] },
                },
                editDirty: { $set: true },
            });
        }
        case ADD_CONDITION: {
            if (!action.condition.id) {
                action.condition.id = generateGuid();
            }
            return update(state, {
                edit: {
                    filters: {
                        [action.index]: {
                            conditions: { $push: [action.condition] },
                        },
                    },
                },
                editDirty: { $set: true },
            });
        }
        case EDIT_CONDITION: {
            const { index, cIndex, key, value } = action;

            // Fix bug where filter is a string, an audiences BE doesn't run it
            const floatValue = parseFloat(value);
            const newValue = isNaN(floatValue) ? value : floatValue;

            return update(state, {
                edit: {
                    filters: {
                        [index]: {
                            conditions: {
                                [cIndex]: {
                                    [key]: { $set: newValue },
                                },
                            },
                        },
                    },
                },
                editDirty: { $set: true },
            });
        }
        case DELETE_CONDITION: {
            return update(state, {
                edit: {
                    filters: {
                        [action.index]: {
                            conditions: {
                                $splice: [[action.cIndex, 1]],
                            },
                        },
                    },
                },
                editDirty: { $set: true },
            });
        }
        case VALIDATE_SEGMENT: {
            const { edit, editValidations } = state;
            if (!editValidations && !action.enableValidation) {
                return state;
            }

            let msg = false;
            if (!edit.name) {
                msg = 'Please enter a name for your audience';
            } else if (!edit.apps.length) {
                msg = 'Please select at least one app';
            }

            if (!msg) {
                for (let i = 0; i < edit.filters.length; i++) {
                    const filter = edit.filters[i];
                    const isEvent = isEventFilter(filter);
                    for (let j = 0; j < filter.conditions.length; j++) {
                        const condition = edit.filters[i].conditions[j];
                        if (isEvent && !condition.event) {
                            msg = 'Please select an event in your behavior filter';
                        } else if (condition.valueA === '' || (condition.op === 'bt' && condition.valueB === '')) {
                            msg = 'Please enter a value in your behavior filter';
                        }
                    }
                }
            }

            return update(state, {
                editValidations: { $set: true },
                editInvalidMsg: { $set: msg },
            });
        }
        case CHANGE_SORT: {
            return update(state, {
                sort: { $set: action.sort },
            });
        }
        case OPEN_SHARING_OPTIONS: {
            return update(state, {
                sharingSegmentId: { $set: action.id },
            });
        }
        case CLOSE_SHARING_OPTIONS: {
            return update(state, {
                sharingSegmentId: { $set: null },
                sharingLinks: { $set: null },
            });
        }
        case SHARING_OPTIONS_LOADED: {
            return update(state, {
                sharingLinks: { $set: action.links },
            });
        }
        default:
            return state;
    }
};
