import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { ActionButton } from '../widgets';
import css from './DeleteDialog.css';

class DeleteDialog extends React.PureComponent {
    render() {
        const { title, onAccept, onReject, translate, deleteType, textReplace, rejectText, acceptText } = this.props;
        return (
            <div className={css.container}>
                <div className={css.title}>{translate(title) || title}</div>
                <div>
                    <ActionButton text={rejectText} containerClass={css.cancelButton} onClick={onReject} />
                    <ActionButton
                        text={acceptText}
                        textReplace={{ deleteType: deleteType || textReplace }}
                        containerClass={css.deleteButton}
                        onClick={onAccept}
                    />
                </div>
            </div>
        );
    }
}

DeleteDialog.propTypes = {
    title: PropTypes.string,
    textReplace: PropTypes.string,
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
    translate: PropTypes.func,
    deleteType: PropTypes.string,
    rejectText: PropTypes.string,
    acceptText: PropTypes.string,
};

DeleteDialog.defaultProps = {
    title: '',
    textReplace: 'event',
    onAccept: () => {},
    onReject: () => {},
    translate: (s) => s,
    deleteType: '',
    rejectText: 'STATIC.PAGES.DELETE_MODAL.DELETE_NO',
    acceptText: 'STATIC.PAGES.DELETE_MODAL.DELETE_YES_REACT',
};

export default withLocalize(DeleteDialog);
