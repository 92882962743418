import { CUSTOMER_TIERS } from '../utils/consts';
import {
    CHECKOUT_URL,
    isAboutToExpire,
    isAboutToMaxCap,
    isCapMaxed,
    isExpired,
    isHighUsage,
    isLowUsage,
    isNoUsage,
    PACING_WARNING_MESSAGES,
    TIERS_SHOW_PLAN_PRICE,
    WARNING_TYPES,
} from './consts';
import { route } from '../utils/routerUtils';

export const getOrgPlanDetailsBadgeText = (tier = '') => {
    const parsedTier = tier.toUpperCase();

    if (parsedTier === CUSTOMER_TIERS.FREE) {
        return `STATIC.PAGES.ACCOUNT_PLAN.TIER.${parsedTier}_STARTER`;
    } else {
        return `STATIC.PAGES.ACCOUNT_PLAN.TIER.${parsedTier}`;
    }
};

export const shouldShowPlanPrice = (tier, isOrgAdmin) => {
    return isOrgAdmin && TIERS_SHOW_PLAN_PRICE.includes(tier);
};

const calculateFreeTierUsageVariables = (isPaying, percentUsage) => {
    if (isNoUsage(percentUsage)) {
        const type = WARNING_TYPES.SUCCESS;
        const message = isPaying ? PACING_WARNING_MESSAGES.usage : PACING_WARNING_MESSAGES.noUsage;
        return { type, message };
    }
    if (isLowUsage(percentUsage)) {
        return {
            type: WARNING_TYPES.SUCCESS,
            message: PACING_WARNING_MESSAGES.usage,
        };
    }
    if (isHighUsage(percentUsage)) {
        return {
            type: WARNING_TYPES.WARNING,
            message: PACING_WARNING_MESSAGES.usage,
        };
    }
    if (isAboutToMaxCap(percentUsage)) {
        return {
            type: WARNING_TYPES.ERROR,
            message: PACING_WARNING_MESSAGES.usage,
        };
    }
    if (isCapMaxed(percentUsage)) {
        return {
            type: WARNING_TYPES.ERROR,
            message: PACING_WARNING_MESSAGES.maxed,
        };
    }
    console.error(`${percentUsage} value is not cover in the range, setting defaults`);
    return {
        type: WARNING_TYPES.WARNING,
        message: PACING_WARNING_MESSAGES.usage,
    };
};

const calculateNonFreeTierUsageVariables = percentUsage => {
    if (isAboutToMaxCap(percentUsage)) {
        return {
            type: WARNING_TYPES.ERROR,
            message: PACING_WARNING_MESSAGES.usage,
        };
    } else {
        return {
            type: WARNING_TYPES.EXTERNAL_LINK,
            message: PACING_WARNING_MESSAGES.upgrade,
        };
    }
};

export const calculateWarningVariables = (isFreeTier, isPaying, expiresInDays, percentUsage) => {
    if (isExpired(expiresInDays)) {
        return {
            type: WARNING_TYPES.ERROR,
            message: PACING_WARNING_MESSAGES.expired,
        };
    }
    if (isAboutToExpire(expiresInDays)) {
        return {
            type: WARNING_TYPES.ERROR,
            message: PACING_WARNING_MESSAGES.expiring,
        };
    }
    if (isFreeTier) {
        return calculateFreeTierUsageVariables(isPaying, percentUsage);
    }
    return calculateNonFreeTierUsageVariables(percentUsage);
};

export const calculateColorByPercent = percent => {
    let colorCss = 'var(--attention)';
    if (percent < 50) {
        colorCss = 'var(--success1Color)';
    } else if (percent < 75) {
        colorCss = 'var(--warning1Color)';
    }
    return colorCss;
};

export const defaultActionOnProductSelected = product => {
    if (product?.product_id && product?.amount) {
        route(CHECKOUT_URL, { price_id: product.product_id, quantity: product.amount });
    }
};

export const canEnablePlanCardCta = (userTier, tier, upgradeRequestedTier, hasAccountPlan) => {
    if (upgradeRequestedTier) {
        return tier === CUSTOMER_TIERS.FREE && hasAccountPlan;
    }
    if (userTier === CUSTOMER_TIERS.FREE) {
        return hasAccountPlan;
    }
    if (userTier === CUSTOMER_TIERS.STANDARD_UNIFIED) {
        return tier === CUSTOMER_TIERS.ENTERPRISE;
    }
    return tier === userTier;
};

export const getOrganizationPlanStartDate = (tier, pacingStartDate, contractStartDate) => {
    if (tier === CUSTOMER_TIERS.FREE) {
        return contractStartDate;
    }
    return pacingStartDate || contractStartDate;
};
