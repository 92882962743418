import { END_REGEX, END_REGEX_GLOBAL, START_REGEX, START_REGEX_GLOBAL } from './consts';

/**
 * @param {Object} rowHighlightMapping keys are code sections,
 * values are Objects' array of line highlight: [{start: 1, end: 1}, {start: 4, end: 5}, {start: 7, end: 7}]
 * @return {Object} with keys of code sections and their line highlights string. Example: "1-1, 4-5, 7-7"
 * */
const extractLineHighlightsString = rowHighlightMapping => {
    const rowHighlightMappingStrings = {};
    Object.keys(rowHighlightMapping).forEach(key => {
        const highlightList = rowHighlightMapping[key];
        rowHighlightMappingStrings[key] = highlightList.map(({ start, end }) => `${start}-${end}`).join(', ');
    });

    return rowHighlightMappingStrings;
};

/**
 * @param {string} codeSnippet code snippet, including START and END markers
 * @return {Object} with keys of code sections and their line highlights string. Example: "1-1, 4-5, 7-7"
 * */
const extractLineHighlights = codeSnippet => {
    const rowHighlightMapping = {};
    const lines = codeSnippet.split('\n');
    let currentSection;
    lines.forEach((line, index) => {
        const startOfSection = line.match(START_REGEX);
        if (startOfSection && startOfSection[1]) {
            const sectionKey = startOfSection[1];

            if (rowHighlightMapping[sectionKey]) {
                rowHighlightMapping[sectionKey].push({ start: index + 1 });
            } else {
                rowHighlightMapping[sectionKey] = [{ start: index + 1 }];
            }

            currentSection = sectionKey;
        }

        const endOfSection = line.match(END_REGEX);

        if (endOfSection && endOfSection[0]) {
            rowHighlightMapping[currentSection][rowHighlightMapping[currentSection].length - 1].end = index + 1;
        }
    });
    return extractLineHighlightsString(rowHighlightMapping);
};

/**
 * @param {string} codeSnippet code snippet, including START and END markers
 * @return {string} code snippet without markers
 * */
const removeMarkers = codeSnippet => codeSnippet.replace(START_REGEX_GLOBAL, '').replace(END_REGEX_GLOBAL, '');

export { extractLineHighlights, removeMarkers };
