import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SWIFT } from '../../consts';
import { useSDKPlanningContext } from '../../context/useSDKPlanningContext';
import calculateCodeSnippets from '../codeGeneratorUtils';

const initialDisplayState = {
    codeBlockDarkTheme: true,
    activeCodeSnippet: 'DEFAULT',
    codeLanguage: SWIFT,
    setActiveCodeSnippet: () => {},
    setCodeLanguage: () => {},
    snippetsSwitcher: {},
};

const sdkCodeSnippetContext = createContext({ ...initialDisplayState });

const useSDKCodeSnippetContext = () => useContext(sdkCodeSnippetContext);

const SDKCodeSnippetProvider = ({ children }) => {
    const [codeSnippetDisplayState, setCodeSnippetDisplayState] = useState(initialDisplayState);
    const [hideCodeSnippetPlaceholders, setHideCodeSnippetPlaceholders] = useState(true);

    const { selectedTargetPlatform, sdkConfigForPlanning } = useSDKPlanningContext();
    const [codeSnippetsState, setCodeSnippetsState] = useState({ codeSnippets: {}, snippetsSwitcher: {} });

    const { Provider } = sdkCodeSnippetContext;

    const setActiveCodeSnippet = activeCodeSnippet => {
        setCodeSnippetDisplayState(prevState => {
            return {
                ...prevState,
                activeCodeSnippet,
            };
        });
    };

    const setCodeLanguage = codeLanguage => {
        setCodeSnippetDisplayState(prevState => {
            return {
                ...prevState,
                codeLanguage,
            };
        });
    };

    const toggleCodeBlockTheme = toggle => {
        setCodeSnippetDisplayState(prevState => {
            return {
                ...prevState,
                codeBlockDarkTheme: toggle,
            };
        });
    };

    useEffect(() => {
        const { codeSnippets = {}, snippetsSwitcher = {} } = calculateCodeSnippets(
            selectedTargetPlatform,
            sdkConfigForPlanning
        );
        setCodeSnippetsState({ codeSnippets, snippetsSwitcher });

        const initialActiveCodeSnippet = Object.keys(codeSnippets)[0];
        const initialCodeLanguage = Object.keys(codeSnippets[initialActiveCodeSnippet])[0];

        setActiveCodeSnippet(initialActiveCodeSnippet);
        setCodeLanguage(initialCodeLanguage);
    }, [selectedTargetPlatform, sdkConfigForPlanning]);

    return (
        <Provider
            value={{
                ...codeSnippetDisplayState,
                ...codeSnippetsState,
                setActiveCodeSnippet,
                setCodeLanguage,
                toggleCodeBlockTheme,
                setHideCodeSnippetPlaceholders,
                hideCodeSnippetPlaceholders,
            }}
        >
            {children}
        </Provider>
    );
};

SDKCodeSnippetProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SDKCodeSnippetProvider, useSDKCodeSnippetContext };
