import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './OverflowingText.css';

export default function OverflowingText({ children, className, hidePreview, ...otherProps }) {
    const elementRef = useRef(null);
    const elementClassName = classNames(css.overflowingText, className);

    const _addPreviewWhenOverflows = () => {
        if (hidePreview) return;
        const { offsetWidth, scrollWidth, textContent } = elementRef.current;

        if (offsetWidth >= scrollWidth) return;

        elementRef.current.setAttribute('title', textContent);
    };

    const _removePreview = () => elementRef.current.removeAttribute('title');

    return (
        <div
            ref={elementRef}
            className={elementClassName}
            onMouseEnter={_addPreviewWhenOverflows}
            onMouseLeave={_removePreview}
            {...otherProps}
        >
            {children}
        </div>
    );
}

OverflowingText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,
    hidePreview: PropTypes.bool,
};

OverflowingText.defaultProps = {
    className: '',
    hidePreview: false,
};
