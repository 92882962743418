import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from '../TestChannels.css';
import TrashIcon from '../../../../../resources/svg/trash.svg';
import { AutoCompleteField, ExpandablePanel } from '../../../../../components/widgets';
import collapseCss from './CollapsableNetwork.css';
import SegmentField from './SegmentField';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.TEST_CHANNELS';

const CollapseNetwork = ({
    testChannel,
    onCollapseToggle,
    onNetworkChange,
    onDistributionFieldChange,
    allNetworks,
    rowIndex,
    onDelete,
    showDelete,
    isInternalDistribution,
    distributionNetworks,
    disabled,
    expanded,
}) => {
    const onDeleteClick = event => {
        event.stopPropagation();
        onDelete();
    };

    const distributionFields = testChannel.name ? distributionNetworks[testChannel.name]?.segment_fields : [];
    const network = distributionNetworks[testChannel.name];

    return (
        <ExpandablePanel
            id={testChannel.name}
            key={testChannel.name}
            expanded={expanded}
            content={
                <div className={css.formContainer}>
                    {!isInternalDistribution && (
                        <>
                            <Translate id={`${translatePrefix}.NETWORK.DISPLAY_NAME`} />
                            <AutoCompleteField
                                containerClass={css.dropdownContainer}
                                options={allNetworks}
                                disabled={disabled}
                                isMulti={false}
                                searchable
                                placeholder={`${translatePrefix}.NETWORK.PLACEHOLDER`}
                                onChange={onNetworkChange}
                                value={network}
                            />
                        </>
                    )}
                    {distributionFields.length > 0 &&
                        distributionFields.map(field => (
                            <SegmentField
                                field={field}
                                disabled={disabled}
                                onChange={onDistributionFieldChange}
                                fieldValue={testChannel?.config?.[field.name]}
                            />
                        ))}
                </div>
            }
            header={
                <div className={css.closedHeaderContainer}>
                    <div>
                        {network?.logo_path && <img className={css.icon} src={network.logo_path} alt="" />}
                        {testChannel.name ? (
                            network.label
                        ) : (
                            <>
                                <Translate id={`${translatePrefix}.NETWORK.DISPLAY_NAME`} /> {rowIndex + 1}
                            </>
                        )}
                        {isInternalDistribution && <span className={css.closedHeaderSuffix}> - (Optional)</span>}
                    </div>
                    {showDelete && !disabled && <TrashIcon onClick={onDeleteClick} />}
                </div>
            }
            overrideClasses={{
                container: collapseCss.testChannelsContainerOverride, // overrideContainer
                headerContainer: collapseCss.testChannelsHeaderOverride, // panelHeader
                contentArea: collapseCss.testChannelsContentContainerOverride, // panelContent
                arrowArea: collapseCss.testChannelsArrowAreaOverride, // overrideOldArrowArea
                externalHeaderArea: collapseCss.externalHeaderAreaOverride, // expandableOverrideExternal
            }}
            onExpandClick={onCollapseToggle}
            animate
        />
    );
};

CollapseNetwork.propTypes = {
    isInternalDistribution: PropTypes.bool,
    testChannel: PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        expanded: PropTypes.bool,
        icon: PropTypes.string,
        config: PropTypes.any,
        relevantDistributions: PropTypes.shape({
            segment_fields: PropTypes.array,
        }),
    }),
    allNetworks: PropTypes.arrayOf({
        name: PropTypes.string,
        label: PropTypes.string,
    }),
    onNetworkChange: PropTypes.func,
    onCollapseToggle: PropTypes.func,
    onDelete: PropTypes.func,
    rowIndex: PropTypes.number,
    expanded: PropTypes.bool,
    showDelete: PropTypes.bool,
    onDistributionFieldChange: PropTypes.func,
    distributionNetworks: PropTypes.any,
    disabled: PropTypes.bool,
};

CollapseNetwork.defaultProps = {
    showDelete: true,
    disabled: false,
    isInternalDistribution: false,
};

export default CollapseNetwork;
