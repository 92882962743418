import faFile from '@fortawesome/fontawesome-free-regular/faFile';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import sortBy from 'lodash/sortBy';
import {
    getRoleDisplayName,
    ROLE_RESTRICTED,
    shelfSteps,
    wizardModes,
    RECAPTCHA_PUBLIC_KEY,
} from 'teamManagement/utils';
import { FauxButton } from 'components/widgets';
import Wizardable from 'components/widgets/Wizardable';
import WizardFooter from 'components/widgets/WizardFooter';
import ExpandableGroup from 'teamManagement/components/ExpandableGroup';
import css from 'teamManagement/components/SummaryView.css';
import WizardWarningMessage from 'teamManagement/components/WizardWarningMessage';
import ReCAPTCHA from 'react-google-recaptcha';
import OverflowingText from '../../components/widgets/OverflowingText';
import IconMobile from 'resources/svg/icon_mobile.svg';

class IconItem extends React.PureComponent {
    render() {
        const { icon, label, fallback } = this.props;
        return (
            <div className={css.iconItemContainer}>
                {!icon && fallback ? fallback : <img src={icon} alt="" className={css.iconItemIcon} />}
                <OverflowingText className={css.iconItemLabel}>
                    <Translate id={label} />
                </OverflowingText>
            </div>
        );
    }
}

IconItem.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    fallback: PropTypes.element,
};

IconItem.defaultProps = {
    icon: '',
    label: '',
    fallback: null,
};

class SummaryView extends Wizardable {
    constructor(props) {
        super(props);
        const { finishButtonText, wizard: { mode, userData }, pages, permissionDimensions } = props;

        // override the default button def for finish button
        // change the showSpinner value after user click, force render and call the default onFinish
        this.buttonDefs.finish.text = finishButtonText;
        this.buttonDefs.back.visible = !(mode === wizardModes.edit && userData.role.type === ROLE_RESTRICTED);
        const defaultOnFinish = this.buttonDefs.finish.onClick;
        this.buttonDefs.finish.onClick = async () => {
            this.buttonDefs.finish.showSpinner = true;
            const token = await this.recaptchaRef.current.executeAsync();
            this.setState({});
            defaultOnFinish(token);
        };

        this.recaptchaRef = React.createRef();

        const PAGES_DETAILS = {
            [shelfSteps.roleSelection]: this.userTypeData,
            [shelfSteps.userDetails]: this.userDetailsGroupData,
            [shelfSteps.agencyDetails]: this.agencyDetailsGroupData,
            [shelfSteps.appSelection]: this.appsGroupData,
            [shelfSteps.sourceSelection]: this.dataSourcesData,
            [shelfSteps.metricsSelection]: this.fieldsData,
            [shelfSteps.screenSelection]: this.screensData,
        };

        this.groups = pages.reduce((groups, page) => {
            let details;
            const getGroupDetails = PAGES_DETAILS[page];

            if (getGroupDetails) {
                details = getGroupDetails();
            } else {
                const dimension = permissionDimensions[page];
                details = this.dimensionFilterData(dimension);
            }

            return { ...groups, [page]: details };
        }, {});
    }

    onEditClicked(id) {
        const { onEditClicked } = this.props;
        onEditClicked({ id });
    }

    userDetailsGroupData = () => {
        const {
            registrationForm: { firstName, lastName, email, description },
        } = this.props;
        return {
            title: 'WIZARD_SUMMARY_USER_DETAILS_TITLE',
            content: (
                <div className={css.userDetailsArea}>
                    <div className={css.userDetailsItem}>
                        <div className={css.userDetailsLabel}>
                            <Translate id="STATIC.PAGES.TEAM_MEMBERS.WIZARD_SUMMARY_USER_DETAILS_NAME" />
                        </div>
                        <div>{firstName.value || lastName.value ? `${firstName.value} ${lastName.value}` : 'N/A'}</div>
                    </div>
                    <div className={css.userDetailsItem}>
                        <div className={css.userDetailsLabel}>
                            <Translate id="STATIC.PAGES.TEAM_MEMBERS.WIZARD_SUMMARY_USER_DETAILS_EMAIL" />
                        </div>
                        <div>{email.value}</div>
                    </div>
                    <div className={css.userDetailsItem}>
                        <div className={css.userDetailsLabel}>
                            <Translate id="STATIC.PAGES.TEAM_MEMBERS.WIZARD_SUMMARY_USER_DETAILS_DESCRIPTIONS" />
                        </div>
                        <div>{description ? description.value : 'No Description'}</div>
                    </div>
                </div>
            ),
            titleData: '',
            expanded: true,
        };
    };

    agencyDetailsGroupData = () => ({
        title: 'WIZARD_SUMMARY_AGENCY_DETAILS_TITLE',
        content: <div className={css.agencyText}>{this.props.agencySelectionForm.name}</div>,
        titleData: '',
        editText: '',
        expanded: true,
    });

    userTypeData = () => {
        const {
            roleSelectionForm: { role },
        } = this.props;
        return {
            title: 'WIZARD_SUMMARY_USER_TYPE',
            content: <div className={css.userTypeText}>{getRoleDisplayName(role)}</div>,
            titleData: '',
            editText: 'STATIC.PAGES.TEAM_MEMBERS.WIZARD_CHANGE_USER_TYPE',
            expanded: true,
        };
    };

    appsGroupData = () => {
        const { permissionDimensions } = this.props;

        const selectedValues = !permissionDimensions['app'].selected.values ? [] :
            permissionDimensions['app'].values.filter(
                (app) => permissionDimensions['app'].selected.values.includes(app.name)
            );

        return {
            title: 'WIZARD_SUMMARY_APPS_TITLE',
            itemType: 'apps',
            content: (
                <div className={css.groupContentHolder}>
                    {sortBy(selectedValues, (x) => x.displayName.toUpperCase()).map((app) => (
                        <div key={app.name} className={css.iconItemHolder}>
                            <IconItem
                                label={app.displayName}
                                icon={app.icon}
                                fallback={<IconMobile style={{ margin: 0 }} className={css.fallbackIcon} />}
                            />
                        </div>
                    ))}
                </div>
            ),
            titleData: {
                count: selectedValues.length,
                overall: permissionDimensions['app'].values.length,
            },
            addFuture: permissionDimensions['app'].selected.addFuture,
        };
    };

    dataSourcesData = () => {
        const { permissionDimensions } = this.props;

        const selectedValues = !permissionDimensions['base_source'].selected.values ? [] :
            permissionDimensions['base_source'].values.filter(
                (baseSource) => permissionDimensions['base_source'].selected.values.includes(baseSource.name)
            );

        const sortedValues = [
            ...selectedValues.filter((x) => x.name === 'organic'),
            ...sortBy(selectedValues.filter((x) => x.name !== 'organic'), (x) => x.displayName.toUpperCase()),
        ];

        return {
            title: 'WIZARD_SUMMARY_DS_TITLE',
            itemType: 'sources',
            content: (
                <div className={css.groupContentHolder}>
                    {sortedValues.map((source) => {
                        return (
                            <div key={source.name} className={css.iconItemHolder}>
                                <IconItem label={source.displayName} icon={source.icon}/>
                            </div>
                        );
                    })}
                </div>
            ),
            titleData: {
                count: selectedValues.length,
                overall: permissionDimensions['base_source'].values.length,
            },
            addFuture: permissionDimensions['base_source'].selected.addFuture,
        };
    }

    dimensionFilterData = (permissionDimension) => {
        const selectedValues = !permissionDimension.selected.values ? [] :
            permissionDimension.values.filter(
                (value) => permissionDimension.selected.values.includes(value.name)
            );

        return {
            title: 'WIZARD_SUMMARY_DIMENSION_FILTER_TITLE',
            itemType: 'values',
            content: (
                <div className={css.groupContentHolder}>
                    {selectedValues.map((value) => (
                        <div key={value.name} className={css.iconItemHolder}>
                            {value.displayName}
                        </div>
                    ))}
                </div>
            ),
            titleData: {
                dimension: permissionDimension.displayName,
                count: permissionDimension.selected.values.length,
                overall: permissionDimension.values.length,
            },
            addFuture: permissionDimension.selected.addFuture,
        };
    }

    screensData = () => {
        const {
            screens: { values, addFuture, overall },
        } = this.props;
        const selectedScreens = values.reduce((total, section) => total + section.children.length, 0);
        return {
            title: 'WIZARD_SUMMARY_SCREENS_TITLE',
            itemType: 'screens',
            content: (
                <div>
                    {values.map((section) => {
                        return (
                            <div key={section.name} className={css.sectionArea}>
                                <div className={css.section}>{section.displayName}</div>
                                {section.children.map((screen) => {
                                    return (
                                        <div key={screen.name} className={css.screen}>
                                            <FontAwesomeIcon icon={faFile} className={css.screenIcon} />
                                            <span>{screen.displayName}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            ),
            titleData: {
                count: selectedScreens,
                overall,
            },
            addFuture,
        };
    };

    fieldsData = () => {
        const {
            fields: { values, addFuture, overall },
        } = this.props;
        const selectedMetrics = values.reduce((total, fieldType) => total + fieldType.values.length, 0);
        return {
            title: 'WIZARD_SUMMARY_METRICS_TITLE',
            itemType: 'metrics',
            content: (
                <div>
                    {values.map((fieldType) => {
                        return (
                            <div key={fieldType.type} className={css.sectionArea}>
                                <div className={css.section}>
                                    <Translate id={fieldType.label} />
                                </div>
                                <div className={css.metricsList}>
                                    {fieldType.values.map((field) => field.display_name).join(', ')}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ),
            titleData: {
                count: selectedMetrics,
                overall,
            },
            addFuture,
        };
    };

    get isEditMode() {
        const {
            wizard: { mode },
        } = this.props;
        return mode === wizardModes.edit;
    }

    render() {
        const { saveMemberError, lastAction, allGroupsExpanded } = this.props;
        if (saveMemberError) {
            this.buttonDefs.finish.showSpinner = false;
        }
        return (
            <React.Fragment>
                <WizardWarningMessage
                    show={!!saveMemberError}
                    message={saveMemberError}
                    showIcon={false}
                    showTypeIcon
                    type="error"
                />
                <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                    {!this.isEditMode ? (
                        <div className={css.headline}>
                            <div className={css.preamble}>
                                <Translate id="STATIC.PAGES.TEAM_MEMBERS.WIZARD_SUMMARY_TITLE" />
                            </div>
                        </div>
                    ) : null}
                    <div className={css.contentArea}>
                        {Object.entries(this.groups).map(
                            ([
                                id,
                                {
                                    title,
                                    itemType,
                                    content,
                                    titleData,
                                    addFuture,
                                    expanded = false,
                                    editText = 'STATIC.BUTTONS.EDIT',
                                },
                            ]) => (
                                <div key={id} className={css.expandableGroupHolder}>
                                    <ExpandableGroup
                                        defaultExpanded={allGroupsExpanded || expanded}
                                        rightComponents={
                                            editText && (
                                                <FauxButton onClick={() => this.onEditClicked(id)}>
                                                    <Translate id={editText} />
                                                </FauxButton>
                                            )
                                        }
                                        title={
                                            <div>
                                                <Translate id={`STATIC.PAGES.TEAM_MEMBERS.${title}`} data={titleData} />
                                                {addFuture ? (
                                                    <span className={css.addFutureMessage}>
                                                        <Translate
                                                            id="STATIC.PAGES.TEAM_MEMBERS.FUTURE_ITEMS_WILL_BE_ADDED"
                                                            data={{ itemType }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        }
                                    >
                                        {content}
                                    </ExpandableGroup>
                                </div>
                            )
                        )}
                    </div>
                </this.AnimationItem>
                <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA_PUBLIC_KEY} />
                <WizardFooter buttons={this.getButtons()} />
            </React.Fragment>
        );
    }
}

SummaryView.propTypes = {
    permissionDimensions: PropTypes.objectOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                displayName: PropTypes.string.isRequired,
            })).isRequired,
            selected: PropTypes.shape({
                values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
                addFuture: PropTypes.bool,
            }).isRequired,
        })
    ),
    onEditClicked: PropTypes.func,
    saveMemberError: PropTypes.string,
    pages: PropTypes.arrayOf(PropTypes.string),
};

SummaryView.defaultProps = {
    permissionDimension: {
        values: [],
        selected: {
            values: [],
            addFuture: false,
        },
    },
    onEditClicked: () => {},
    saveMemberError: '',
    pages: [],
};

export default SummaryView;
