import rules from './fieldVisibilityRules';
import { reportBase, cohorts } from './reportsBase';

const TYPE = 'crossDevice';

export default {
    type: TYPE,
    fields: [
        ...reportBase.fields,
        ...cohorts.fields,
        'custom_dimensions',
        'xorgMetrics',
        'discrepancyMetrics',
        'xorgCohortMetrics',
        'fileCombinerDimensions',
        'granularityLevels',
        'enrichmentDimensions',
        'sourceDimensions',
        'metadataDimensions',
        'sourceAttributionType',
        'crossDeviceDemoCohortType',
        'appMetrics',
        'webMetrics',
        'crossDeviceMetrics',
        'crossDeviceCohortType',
    ],
    visibleRule: rules[TYPE],
    dataSelector: {
        ...reportBase.dataSelector,
        ...cohorts.dataSelector,
        custom_dimensions: 'custom_dimensions',
        fileCombinerDimensions: 'fileCombinerDimensions',
        granularityLevels: 'granularityLevels',
        enrichmentDimensions: 'enrichmentDimensions',
        sourceDimensions: 'sourceDimensions',
        metadataDimensions: 'metadataDimensions',
        xorgMetrics: 'xorgMetrics',
        discrepancyMetrics: 'discrepancyMetrics',
        xorgCohortMetrics: 'xorgCohortMetrics',
        sourceAttributionType: 'sourceAttributionType',
        selectedTab: 'selectedTab',
        transparencyDataFormatted: 'reportData',
        tableDataFormatted: 'reportData',
        chartDataFormatted: 'chartData',
        chart: 'chart',
        mode: 'mode',
        unenriched: 'unenriched',
        shouldDrilldown: 'shouldDrilldown',
        showTransparencyPopover: 'showTransparencyPopover',
        dimensionDrilldownCellId: 'dimensionDrilldownCellId',
        isClearSelectionOn: 'isClearSelectionOn',
        appMetrics: 'appMetrics',
        webMetrics: 'webMetrics',
        crossDeviceMetrics: 'crossDeviceMetrics',
        crossDeviceCohortType: 'crossDeviceCohortType',
    },
};
