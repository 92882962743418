import { connect } from 'react-redux';
import AppSelection from '../components/AppSelection';
import { getAppSelectionPage } from '../selectors';
import { addFutureChanged, changeAppSelection } from '../actions';

const mapStateToProps = (state, ownProps) => {
    return getAppSelectionPage(state);
};

const mapDispatchToProps = dispatch => {
    return {
        onChange: (ids, value) => dispatch(changeAppSelection(ids, value)),
        enableAddFuture: () => dispatch(addFutureChanged('app', true, true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AppSelection);
