import React, { useState } from 'react';
import { prettyPrintJson } from 'pretty-print-json';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { boolean, text } from '@storybook/addon-knobs';
import PropTypes from 'prop-types';
import Label from '../../components/widgets/Label';
import Toggle from '../../components/widgets/Toggle';
import css from './LogsTable.css';

function LogDetails({ dataList, isAdminMode }) {
    const [isJsonView, setIsJsonView] = useState(false);
    return (
        <TableCell align="left" colSpan={7}>
            <div className={css.innerLogDetails}>
                <div className={css.innerLogDetailsTable}>
                    {isJsonView && (
                        <pre
                            dangerouslySetInnerHTML={{
                                __html: prettyPrintJson.toHtml(dataList, { indent: 2 }),
                            }}
                        />
                    )}
                    {!isJsonView && (
                        <Table>
                            <TableBody>
                                {dataList
                                    .filter(({ isAdminView }) => isAdminMode || !isAdminView)
                                    .map(({ label, value, isCodeRendering }) => {
                                        return (
                                            <TableRow key={label}>
                                                <TableCell className={css.logJsonKey} padding="none" align="left">
                                                    {label}
                                                </TableCell>
                                                <TableCell className={css.logJsonValue} align="left">
                                                    {isCodeRendering && value && <pre>{value}</pre>}
                                                    {!isCodeRendering && value}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    )}
                </div>
                <div className={css.jsonToggleWrapper}>
                    <Label type="tableTd" text="Table" className={css.jsonToggleText} />
                    <Toggle
                        onToggle={e => {
                            setIsJsonView(e.target.checked);
                        }}
                        styleOverride={{ labelStyle: { color: '#515864' } }}
                        disabled={boolean('disabled', false)}
                        label={text('label', 'JSON')}
                    />
                </div>
            </div>
        </TableCell>
    );
}

LogDetails.propTypes = {
    dataList: PropTypes.arrayOf(PropTypes.any).isRequired,
    isAdminMode: PropTypes.bool.isRequired,
};

LogDetails.defaultProps = {};

export default LogDetails;
