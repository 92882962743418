import { MAX_EXPANDABLE_INDENT_ALLOWED, MAX_INDENT_ALLOWED } from './consts';
import { isObjectEmpty } from '../../../utils/general';

/**
 * Retrieves the item number description based on the given item number description and nested count.
 *
 * @param {number[]} curItemNumber - The description of the item number.
 * @param {number} nestedCount - describes how deep in the indentation the given number should be.
 * @return {number[]} if nestedCount > 0: The updated item number description else the given number description.
 */
export const getNextNestedItemNumber = (curItemNumber, nestedCount) => {
    if (!nestedCount) return curItemNumber;

    if (curItemNumber.length > nestedCount) {
        // if the current item was further nested from the next one, we need to cut it
        // for example: 5.1.2 -> 5.2
        curItemNumber.splice(nestedCount + 1);
    }

    if (curItemNumber[nestedCount]) {
        curItemNumber[nestedCount] += 1;
    } else {
        curItemNumber.push(1);
    }

    return curItemNumber;
};

export const validateNestedCount = (nestedCount, isExpandable) => {
    let isValid = true;

    if (nestedCount >= MAX_INDENT_ALLOWED) {
        isValid = false;
        console.error('nested items depth is not allowed. max is: ', MAX_INDENT_ALLOWED);
    } else if (nestedCount >= MAX_EXPANDABLE_INDENT_ALLOWED && isExpandable) {
        isValid = false;
        console.error('nested items depth is not allowed. max is: ', MAX_EXPANDABLE_INDENT_ALLOWED);
    }

    return isValid;
};

const innerGetItemKeyToItemMap = (
    itemsToContent,
    itemKey,
    item,
    nestedCount,
    prevItemNumber = [1],
    isExpandable,
    parentItemKey
) => {
    const curItemNumber = getNextNestedItemNumber(prevItemNumber, nestedCount);
    itemsToContent[itemKey] = { ...item, parentItemKey, nestedCount, itemNumber: curItemNumber.join('.') };
    const { nestedItems } = item;

    if (parentItemKey) {
        itemsToContent[itemKey].parentItemKey = parentItemKey;
    }
    delete itemsToContent[itemKey].nestedItems;

    if (nestedItems && validateNestedCount(nestedCount, isExpandable)) {
        Object.keys(nestedItems).forEach(nestedItemKey => {
            return innerGetItemKeyToItemMap(
                itemsToContent,
                nestedItemKey,
                nestedItems[nestedItemKey],
                nestedCount + 1,
                curItemNumber,
                isExpandable,
                itemKey
            );
        });
    }
};

export const getFlattenedItemKeyToItem = (listItems, isExpandable) => {
    const itemsToContent = {};
    Object.keys(listItems).forEach((key, index) => {
        innerGetItemKeyToItemMap(itemsToContent, key, listItems[key], 0, [index + 1], isExpandable);
    });
    return itemsToContent;
};

export const calculateCompletionStatus = (items, itemKey, flatListItemsObject, flatListItemsKeys, activeItemId) => {
    if (!items?.[itemKey]) return false;

    const item = items[itemKey];

    if (flatListItemsObject[itemKey]?.completed) return true;

    if (isObjectEmpty(item.nestedItems || {})) return flatListItemsObject[itemKey]?.completed || false;

    return Object.keys(item.nestedItems).every(nestedKey => {
        if (!flatListItemsObject[nestedKey]) return false;

        const nestedItemIndex = flatListItemsKeys.indexOf(nestedKey);
        const nestedItemAlreadyPassed =
            nestedItemIndex !== -1 && nestedItemIndex < flatListItemsKeys.indexOf(activeItemId);

        return (
            (nestedItemAlreadyPassed && !item.nestedItems[nestedKey].nestedItems) ||
            calculateCompletionStatus(items, nestedKey, flatListItemsObject, flatListItemsKeys, activeItemId)
        );
    });
};

export const calculateExpandedCategoryId = (
    listItems,
    flatListItemsObject,
    activeItemId,
    currentExpandedCategoryId
) => {
    let expandedCategoryId = null;

    const { parentItemKey } = flatListItemsObject[activeItemId] || {};
    const { nestedItems = {} } = listItems[activeItemId] || {};
    const hasNestedItems = !!Object.keys(nestedItems).length;

    if (parentItemKey) {
        expandedCategoryId = parentItemKey;
    } else if (hasNestedItems && activeItemId !== currentExpandedCategoryId) {
        expandedCategoryId = activeItemId;
    }

    return expandedCategoryId;
};
