import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'react-tippy/dist/tippy.css';
import Tooltip from './Tooltip';
import css from '../partials/Grid.css';
import TransparencyPopover from '../reportsWidgets/TransparencyPopover';
import DimensionDrilldownPopover from '../../containers/reportsWidgets/DimensionDrilldownPopover';
import DrilldownIcon from '../../resources/svg/report_drilldown.svg';
import withTranslate from '../../containers/widgets/withTranslate';
import {
    ADMON_ALIGNMENT_SOURCE_NAME,
    APP_FIELD,
    FACEBOOK_CENSORING_MESSAGE_TRANSLATE,
    TRANSPARENCY_COLUMN_LIST,
} from '../../utils/reportsConsts';
import { getCellTooltipInfo, getTransparencySourceTypeText, isAdmonTransparencyCell } from '../../utils/reports';
import { parseSafeHtml } from '../../utils/render';
import {
    formatCell,
    getColConfidenceIntervalName,
    isFacebookCensoredByParams,
    isSupportedApp,
} from '../../utils/grids';

class GridGeneralCellRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this._handleClick.bind(this);
        this.handleDrillDownIconClick = this._handleDrillDownIconClick.bind(this);
        this.handleRequestClose = this._handleRequestClose.bind(this);
        this.handleDrillDownRequestClose = this._handleDrillDownRequestClose.bind(this);
    }

    getCellId() {
        const { column, rowIndex } = this.props;
        return `${column.colId + rowIndex}`;
    }

    _handleClick() {
        const { showTransparencyPopover, onPopoverToggle, colDef } = this.props;
        // make sure to close previous popovers
        const cellId = this.getCellId();

        // Close existing popover
        if (cellId === showTransparencyPopover) {
            onPopoverToggle(cellId);
        } else {
            // Open via SetImmediate - to make sure rendered in correct position after changes (e.g. tool panel closes)
            const isAdmon = !TRANSPARENCY_COLUMN_LIST.includes(colDef.colId);
            window.mixpanel.track('Reports: Transparency Drilldown Click', { is_admon: isAdmon });
            setTimeout(() => onPopoverToggle(cellId));
        }
    }

    _handleRequestClose() {
        this.props.onPopoverToggle('');
    }

    _handleDrillDownRequestClose() {
        const { onDrilldownPopoverToggle } = this.props;

        setTimeout(() => {
            onDrilldownPopoverToggle('');
        }, 0);
    }

    _handleDrillDownIconClick() {
        const { dimensionDrilldownCellId, onDrilldownPopoverToggle } = this.props;
        const cellId = this.getCellId();

        onDrilldownPopoverToggle(cellId);
        cellId !== dimensionDrilldownCellId && window.mixpanel.track('Drilldown popper opened');
    }

    isAdmonAlignmentRow(node) {
        return node?.data?.source === ADMON_ALIGNMENT_SOURCE_NAME;
    }

    render() {
        const {
            node,
            valueFormatted,
            column,
            showTransparencyPopover,
            onPopoverToggle,
            eGridCell,
            colDef,
            dimensionDrilldownCellId,
            supportedAppNames,
            unsupportedAppTooltip,
            translate,
        } = this.props;

        let valueFormattedTranslated = null;

        if (isFacebookCensoredByParams(node) && valueFormatted === FACEBOOK_CENSORING_MESSAGE_TRANSLATE) {
            valueFormattedTranslated = translate(valueFormatted);
        }

        const cellId = this.getCellId();
        const showPopover = cellId === showTransparencyPopover;
        const showDrilldownPopover = cellId === dimensionDrilldownCellId;
        const cellTooltipInfo = getCellTooltipInfo(valueFormatted, node.data, column.colId);
        const { cellTooltipElement, isInterpolatedRecord } = cellTooltipInfo;

        const confidenceIntervalColName = getColConfidenceIntervalName(colDef);
        const { displayFormat } = colDef;
        const confidenceIntervalDisplayFormat = displayFormat ? { ...displayFormat } : null;

        const isUnsupportedAppValue = column.colId === APP_FIELD && !isSupportedApp(node, supportedAppNames);
        const cellTooltipText = isUnsupportedAppValue ? unsupportedAppTooltip : null;

        // Set default precision to the format for confidence interval.
        if (confidenceIntervalDisplayFormat?.type === 'number') {
            confidenceIntervalDisplayFormat.precision = 2;
        }

        const confidenceInterval = confidenceIntervalColName ? (
            <span className={css.tableMarginValue}>
                (± {formatCell(node.data[confidenceIntervalColName], confidenceIntervalDisplayFormat)})
            </span>
        ) : null;

        let toRender = '';

        if (
            (!isFacebookCensoredByParams(node) && TRANSPARENCY_COLUMN_LIST.indexOf(column.colId) >= 0) ||
            isAdmonTransparencyCell(node, column)
        ) {
            let sourceLabelTypeClass = '';
            let drilldownUans = null;
            let sourceType = '';

            if (node.data && node.data.sources && node.data.sources.column_source[column.colId]) {
                sourceType = node.data.sources.column_source[column.colId].source;
                drilldownUans = node.data.sources.uans;
                sourceLabelTypeClass = css[`${sourceType}SourceLabel`];
            }
            toRender = (
                <div className={css['elipsable-cell-value']}>
                    {(cellTooltipText || cellTooltipElement) && (
                        <div className={classNames(css.markCell, { [css.markCellGreen]: isInterpolatedRecord })} />
                    )}
                    {sourceType && (
                        <span
                            className={`${css.sourceLabel} ${sourceLabelTypeClass}`}
                            onClick={this.handleClick}
                            title=""
                        >
                            {getTransparencySourceTypeText(sourceType)}
                        </span>
                    )}
                    <span>{valueFormatted}</span>
                    {confidenceInterval}
                    {showPopover && drilldownUans && (
                        <TransparencyPopover
                            uansContent={drilldownUans}
                            column={column}
                            nodeData={node.data}
                            popupWidth={column.actualWidth}
                            onPopoverToggle={onPopoverToggle}
                            handleRequestClose={this.handleRequestClose}
                            showPopover={showPopover}
                            anchorEl={eGridCell}
                        />
                    )}
                </div>
            );
        } else {
            toRender = (
                <>
                    {(cellTooltipText || cellTooltipElement) && <div className={css.markCell} />}
                    <span
                        className={`${css['elipsable-cell-value']} ${
                            showDrilldownPopover ? css.showDrillDownIcon : ''
                        }`}
                    >
                        {colDef.showDimensionDrilldown && !this.isAdmonAlignmentRow(node) && (
                            <span title={translate('STATIC.PAGES.REPORTS.DIMENSION_DRILLDOWN_ICON_TOOLTIP')}>
                                <div onClick={this.handleDrillDownIconClick} className={css.drilldownIcon}>
                                    <DrilldownIcon />
                                </div>
                            </span>
                        )}
                        {showDrilldownPopover && (
                            <DimensionDrilldownPopover
                                column={column}
                                nodeData={node.data}
                                showPopover
                                anchorEl={eGridCell}
                                closePopover={this.handleDrillDownRequestClose}
                            />
                        )}
                        <span className={classNames({ [css.unsupportedCell]: isUnsupportedAppValue })}>
                            {valueFormattedTranslated || valueFormatted}
                        </span>
                        {confidenceInterval}
                    </span>
                </>
            );
        }

        if (cellTooltipElement || cellTooltipText?.text) {
            const tooltipTitle = cellTooltipElement ? (
                <span dangerouslySetInnerHTML={{ __html: parseSafeHtml(cellTooltipElement) }} />
            ) : (
                translate(cellTooltipText.text, cellTooltipText.params)
            );

            toRender = (
                <Tooltip interactive delay={250} title={tooltipTitle} contentClass={css.cellTooltip}>
                    {toRender}
                </Tooltip>
            );
        }

        return toRender;
    }
}

GridGeneralCellRenderer.propTypes = {
    node: PropTypes.objectOf(PropTypes.any),
    valueFormatted: PropTypes.string,
    column: PropTypes.objectOf(PropTypes.any),
    showTransparencyPopover: PropTypes.string,
    onPopoverToggle: PropTypes.func,
    eGridCell: PropTypes.objectOf(PropTypes.any),
    rowIndex: PropTypes.number,
    colDef: PropTypes.objectOf(PropTypes.any),
    dimensionDrilldownCellId: PropTypes.string,
    onDrilldownPopoverToggle: PropTypes.func,
    supportedAppNames: PropTypes.arrayOf(PropTypes.string),
    unsupportedAppTooltip: PropTypes.shape({ text: PropTypes.string, params: PropTypes.objectOf(PropTypes.any) }),
    translate: PropTypes.func,
};

GridGeneralCellRenderer.defaultProps = {
    node: {},
    valueFormatted: '',
    column: {},
    showTransparencyPopover: '',
    onPopoverToggle: () => {},
    onDrilldownPopoverToggle: () => {},
    eGridCell: {},
    rowIndex: -1,
    colDef: {},
    supportedAppNames: null,
    unsupportedAppTooltip: {},
    translate: () => {},
};

export default withTranslate(GridGeneralCellRenderer);
