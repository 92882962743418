import React from 'react';

export default function reportingIntegrationsIntro() {
    return (
        <div>
            <p>
                <span>Singular offers you various solutions for pulling data. If you are looking for a programmatic solution you can set up a Reporting API and/or BI Postbacks. The Reporting API will provide you the same data that you can access via the Reports Page. Meanwhile setting up BI Postbacks will fire postbacks for both installs and in-app events. </span>
            </p>
        </div>
    );
}
