import { CreativeFilters } from './creativesFiltersUtils';
import { FormattedCreativeTypes } from './types';
import { MULTI_VALUED_FIELDS } from './creativesFormattingUtils';
import { CREATIVES_COHORT_PERIODS_OPTIONS } from './metricsUtils';

export const getCreativesDefaultParams = (dateRange, app, source, allMetrics) => {
    const { metrics, cohortMetrics } = allMetrics;
    return {
        api_version: 1,
        dimensions: ['creative_image_hash'],
        cohort_metrics: cohortMetrics,
        cohort_periods: cohortMetrics?.length ? CREATIVES_COHORT_PERIODS_OPTIONS : [],
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        metrics,
        show_creative_type: true,
        filters: [
            {
                dimension: CreativeFilters.TYPE,
                operator: 'in',
                values: Object.keys(FormattedCreativeTypes),
            },
            { dimension: CreativeFilters.APP, operator: 'in', values: [app] },
            { dimension: CreativeFilters.SOURCE, operator: 'in', values: [source] },
        ],
        is_creative_reporting_query: true,
        is_creative_gallery_query: true,
        displayNonGraphical: false,
        time_breakdown: ['all'],
        fuzzy: false,
        is_default: false,
        flags: [],
        display_alignment: false,
    };
};
export const getMainCreativesQueryParams = (dateRange, app, source, allMetrics) => ({
    ...getCreativesDefaultParams(dateRange, app, source, allMetrics),
    technical_specs_dimensions: ['creative_width', 'creative_height', 'creative_type'],
});

export const getMultiValuedDimensionsQueryParams = (dateRange, app, source, allMetrics) => {
    const params = getCreativesDefaultParams(dateRange, app, source, allMetrics);
    params.dimensions = params.dimensions.concat(MULTI_VALUED_FIELDS.map(({ originalField }) => originalField));
    return params;
};
