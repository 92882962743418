import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Search extends Component {
    constructor(props) {
        super(props);
    }

    adjustHeightOfTextarea() {
        const element = document.getElementById('searchTrackingLinkTextArea');
        if (element.scrollHeight > 100) {
            element.style.height = '5px';
            element.style.height = `${element.scrollHeight + 10}px`;
        } else {
            element.style.height = '100px';
        }
    }

    render() {
        const { props } = this;
        const { css } = props;
        return (
            <div className={css.SearchContainer}>
                <textarea
                    id="searchTrackingLinkTextArea"
                    className={css.SearchTextBox}
                    value={props.search}
                    onChange={(event) => {
                        this.adjustHeightOfTextarea();
                        if (event.target.value === '') {
                            // clear the search text & clear the filter
                            props.onSubmit(true);
                        } else {
                            props.onChange(event.target.value);
                        }
                    }}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            props.onSubmit();
                            event.preventDefault();
                        } else {
                            this.adjustHeightOfTextarea();
                        }
                    }}
                    placeholder="Paste a tracking link URL and click Search"
                />
                <button
                    disabled={props.search === undefined || props.search.length === 0}
                    onClick={props.onSubmit}
                    className={css.SearchButton}
                >
                    Search
                </button>
            </div>
        );
    }
}

Search.propTypes = {
    css: PropTypes.objectOf(PropTypes.any),
    search: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

Search.defaultProps = {
    css: {},
    search: '',
    onChange: () => {},
    onSubmit: () => {},
};
export default Search;
