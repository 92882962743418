import update from 'immutability-helper';
import { normalize } from 'normalizr';
import { UPDATE_SOURCES_DATA } from '../actions/sources';
import { attributionSourceSchema } from '../utils/sources';
import { UPDATE_SOURCES_CONFIGURATION_DATA, UPDATE_SINGULAR_LINKS_SOURCES_DATA } from '../linkManagement/actions';

const initialState = {
    sourcesData: [],
    sourceConfigurations: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SOURCES_DATA: {
            const normalized = normalize(action.data.sources, attributionSourceSchema);
            return update(state, {
                sourcesData: {
                    $set: normalized,
                },
            });
        }
        case UPDATE_SOURCES_CONFIGURATION_DATA: {
            return update(state, {
                sourceConfigurations: {
                    [`${action.sourceId}_${action.appSiteId}`]: {
                        $set: action.data,
                    },
                },
            });
        }
        case UPDATE_SINGULAR_LINKS_SOURCES_DATA: {
            const normalized = normalize(action.data.sources, attributionSourceSchema);
            return update(state, {
                sourcesSingularLinksData: {
                    $set: normalized,
                },
            });
        }
        default:
            return state;
    }
};
