import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chart, useChart } from 'react-jsx-highcharts';
import { adjustChartSize } from '../utils';

const BenchmarksChart = ({ minWidth, loaded, marginBottom, marginLeft, className, type, height, inverted, grouping }) => {
    const chartObject = useChart().object;
    const scrollablePlotArea = minWidth ? { scrollablePlotArea: { minWidth } } : {};

    useEffect(() => {
        window.addEventListener('sidebarToggled', () => {
            adjustChartSize(chartObject, 'reflow');
        });

        if (loaded) {
            adjustChartSize(chartObject, 'redraw');
        }

        return () => window.removeEventListener('sidebarToggled', () => {
            adjustChartSize(chartObject, 'reflow');
        });

    }, [loaded]);

    return (
        <Chart
            type={type}
            className={className}
            marginLeft={marginLeft}
            marginRight={55}
            marginBottom={marginBottom}
            style={{ fontFamily: 'open-sans' }}
            height={height}
            inverted={inverted}
            grouping={grouping}
            {...scrollablePlotArea}
        />
    );
};

BenchmarksChart.propTypes = {
    minWidth: PropTypes.number,
    loaded: PropTypes.bool,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    className: PropTypes.string,
    type: PropTypes.string,
    height: PropTypes.number,
    inverted: PropTypes.bool,
    grouping: PropTypes.bool,
};

BenchmarksChart.defaultProps = {
    minWidth: undefined,
    loaded: false,
    marginBottom: undefined,
    marginLeft: 55,
    className: undefined,
    type: undefined,
    height: undefined,
    inverted: undefined,
    grouping: undefined,
};

export default BenchmarksChart;
