import React from 'react';
import PropTypes from 'prop-types';
import css from './GoogleSignInButton.css';
import GoogleRegularIcon from '../../resources/svg/google_g_logo.svg';

const GoogleSignInButton = ({ onButtonClick }) => {
    return (
        <button type="button" className={css.button} onClick={() => onButtonClick()}>
            <GoogleRegularIcon className={css.googleIcon} />
            <div className={css.btnText}>Sign in with Google</div>
        </button>
    );
};

GoogleSignInButton.propTypes = {
    onButtonClick: PropTypes.func,
};

GoogleSignInButton.defaultProps = {
    onButtonClick: () => {},
};

export default GoogleSignInButton;
