import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Tag.css';
import OverflowingText from './OverflowingText';

const Tag = ({ value, color, className }) => {
    return (
        <OverflowingText className={classNames(css.tag, className)} style={{ '--dynamicColor': color }}>
            {value}
        </OverflowingText>
    );
};

Tag.propTypes = {
    value: PropTypes.string.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
};

Tag.defaultProps = {
    color: null,
};

export default Tag;
