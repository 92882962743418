import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import PivotChart from '../components/partials/PivotChart';
import css from './dashboard.css';
import { renameTimebreakdownDimension } from './utils';
import { useFetchTableData } from './hooks';
import { ACTION_TYPES } from '../components/partials/ChartWidgetWrapper/consts';
import ChartWidgetWrapper from '../components/partials/ChartWidgetWrapper';
import { CHART_TYPES } from '../components/partials/PivotChart/consts';
import { DataTypes } from './widgetDataTypes';
import { UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME } from '../components/pages/reports/consts';

function PivotChartWidget({
    dashboardId,
    dispatchWidgetVersions,
    dispatchWidgetsLoading,
    forceRefreshCounter,
    widget,
    globalFilters,
    height,
    onMenuClick,
    onCloneClick,
    onDeleteClick,
    startDate,
    endDate,
    updatingGraphQL,
    allowedActions,
    displayUnenriched,
}) {
    const [loading, tableData, error] = useFetchTableData(
        dashboardId,
        widget.gridParams.i,
        widget.query,
        globalFilters,
        startDate,
        endDate,
        null,
        null,
        'pivot',
        dispatchWidgetVersions,
        dispatchWidgetsLoading,
        false,
        widget.dataType,
        displayUnenriched,
        updatingGraphQL,
        forceRefreshCounter
    );
    const allowedActionsForPivotChart = allowedActions.filter(action => action !== ACTION_TYPES.EXPORT);

    const reportColumns = useMemo(() => {
        if (loading || !tableData || !tableData.columnDefs) {
            return null;
        }

        const columns = tableData.columnDefs.map(x => {
            if (widget.dataType === DataTypes.UNIFIED && UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME[x.unifiedReportType]) {
                x.headerName = `${UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME[x.unifiedReportType]} - ${x.headerName}`;
            }
            return {
                name: x.field,
                fieldType: x.fieldType,
                displayName: x.headerName,
                displayFormat: x.displayFormat,
                unifiedReportType: x.unifiedReportType,
            };
        });

        return columns;
    }, [loading, tableData]);

    const pivotChartSettings = useMemo(() => {
        if (!reportColumns) {
            return null;
        }

        const xAxis = renameTimebreakdownDimension(
            widget.type !== 'pie' ? widget.options.rows : widget.query.dimensions,
            widget.dataType
        ).filter(x => reportColumns.find(c => c.name === x));
        const yAxis = renameTimebreakdownDimension(widget.options.columns, widget.dataType).filter(x =>
            reportColumns.find(c => c.name === x)
        );
        return { chartType: widget.type, selectedMetric: widget.query.metrics, xAxis, yAxis };
    }, [reportColumns, widget.type, widget.query.metrics, widget.query.dimensions, widget.options]);

    const noData = tableData == null || tableData.rowData == null || tableData.rowData.length === 0;
    return (
        <ChartWidgetWrapper
            widgetTitle={widget.name}
            widgetType={widget.type}
            widgetDataType={widget.dataType}
            showSpinner={loading}
            onMenuClick={onMenuClick}
            onDeleteClick={onDeleteClick}
            onCloneClick={onCloneClick}
            showNoData={!loading && !error && noData}
            showError={!loading && !!error}
            allowedActions={allowedActionsForPivotChart}
        >
            {!loading && !error && !noData && (
                <PivotChart
                    reportId={tableData.reportId}
                    rowData={tableData.rowData}
                    reportColumns={reportColumns}
                    showControls={false}
                    disableHiding
                    height={height - 20} // 20 = pivotChartWidget top(10)+bottom(10) padding
                    containerClass={css.pivotChartWidget}
                    settings={pivotChartSettings}
                    controlled
                    metricForTopX={widget.metricForTopX}
                    widgetName={widget.name}
                />
            )}
        </ChartWidgetWrapper>
    );
}

PivotChartWidget.propTypes = {
    dispatchWidgetVersions: PropTypes.func.isRequired,
    dispatchWidgetsLoading: PropTypes.func.isRequired,
    dashboardId: PropTypes.string.isRequired,
    widget: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        type: PropTypes.oneOf(CHART_TYPES.map(x => x.name)).isRequired,
        query: PropTypes.shape({
            dimensions: PropTypes.arrayOf(PropTypes.string),
            metrics: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        options: PropTypes.shape({
            rows: PropTypes.arrayOf(PropTypes.string),
            columns: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        metricForTopX: PropTypes.string,
    }).isRequired,
    globalFilters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            operator: PropTypes.oneOf(['in', 'not in']).isRequired,
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        })
    ),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    onMenuClick: PropTypes.func,
    onCloneClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    updatingGraphQL: PropTypes.bool,
    allowedActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTION_TYPES))),
    displayUnenriched: PropTypes.bool,
};

PivotChartWidget.defaultProps = {
    allowedActions: Object.values(ACTION_TYPES).filter(action => action !== ACTION_TYPES.EXPORT),
    globalFilters: [],
    onMenuClick: () => {},
    onCloneClick: () => {},
    onDeleteClick: () => {},
    updatingGraphQL: false,
    displayUnenriched: false,
};

export default memo(PivotChartWidget);
