import update from 'immutability-helper';
import {
    ADDED_APP,
    ADDED_DEVICE,
    CLOSE_REPORTS_EMPTY_STATE,
    CLOSE_SDK_CONSOLE_EMPTY_STATE,
    CLOSE_SDK_KEYS_EMPTY_STATE,
    CLOSE_TESTING_CONSOLE_EMPTY_STATE,
    COHORT_EVENT_ADDED,
    UPDATE_ONBOARDING_DATA,
} from '../actions/onboarding';

const initialState = {
    data: {},
};

const onboarding = (state = initialState, action) => {
    switch (action.type) {
        case ADDED_APP: {
            return update(state, {
                data: {
                    empty_state_data: {
                        tasks: {
                            add_app: {
                                $set: true,
                            },
                        },
                    },
                },
            });
        }
        case ADDED_DEVICE: {
            return update(state, {
                data: {
                    empty_state_data: {
                        tasks: {
                            added_device: {
                                $set: true,
                            },
                        },
                    },
                },
            });
        }
        case CLOSE_REPORTS_EMPTY_STATE: {
            return update(state, {
                data: {
                    empty_state_data: {
                        show_empty_state_reports: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case CLOSE_SDK_CONSOLE_EMPTY_STATE: {
            return update(state, {
                data: {
                    empty_state_data: {
                        show_sdk_console_empty_state: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case CLOSE_SDK_KEYS_EMPTY_STATE: {
            return update(state, {
                data: {
                    empty_state_data: {
                        show_sdk_keys_empty_state: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case CLOSE_TESTING_CONSOLE_EMPTY_STATE: {
            return update(state, {
                data: {
                    empty_state_data: {
                        show_testing_console_empty_state: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case COHORT_EVENT_ADDED: {
            return update(state, {
                data: {
                    empty_state_data: {
                        tasks: {
                            added_cohort_events: {
                                $set: true,
                            },
                        },
                    },
                },
            });
        }
        case UPDATE_ONBOARDING_DATA: {
            return update(state, {
                data: {
                    empty_state_data: {
                        $set: action.data,
                    },
                },
            });
        }
        default:
            return state;
    }
};

export default onboarding;
