import React from 'react';
import PropTypes from 'prop-types';

const MIN_UNDERLINE_LENGTH = 40;

const Underline = ({ textLength, className }) => {
    const underlineLength = textLength < MIN_UNDERLINE_LENGTH ? MIN_UNDERLINE_LENGTH : textLength;

    const outerWidth = `${underlineLength * 1.26}`;
    const innerWidth = `${underlineLength * 1.25}`;

    return (
        <svg
            width={outerWidth}
            height="13"
            viewBox={`0 0 ${outerWidth} 13`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d={`M1.5 12C10.4227 6.24907 40.1146 -3.52752 ${innerWidth} 3.3736`}
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    );
};

Underline.propTypes = {
    textLength: PropTypes.number.isRequired,
    className: PropTypes.string,
};

Underline.defaultProps = {
    className: '',
};

export default Underline;
