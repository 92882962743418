import moment from 'moment';
import debounce from 'lodash/debounce';
import {
    ColorByValueSign,
    CATEGORY_FILTER,
    COUNTRY_FILTER,
    PLATFORM_FILTER,
    DEAFULT_FILTER_ALL,
    X_AXIS_LABEL_DATE_FORMAT,
    TRACK_EVENT_PREFIX,
    TRACK_EVENT_PAGE_SCROLLED,
    APP_FILTER,
    BENCHMARKS_PAGES,
    NON_EMPTY_FILTERS,
    BENCHMARKS_PERCENTILES_GRAPHS,
    PERCENTILES_GRAPH_TH_LABELS,
} from './consts';
import { trackMixpanelEvent } from '../utils/general';
import { METRIC_FILTER } from '../mediaMixModeling/consts';

export const getEmptyCategoryIndexes = data => {
    const emptyIndexes = {};

    data.forEach(row => {
        row.data.forEach((value, i) => {
            emptyIndexes[i] = emptyIndexes[i] === undefined ? value === 0 : emptyIndexes[i] && value === 0;
        });
    });

    return Object.keys(emptyIndexes)
        .filter(key => emptyIndexes[key])
        .map(value => parseInt(value, 10));
};

export const calculateVerticalLabel = (verticalName = '') => {
    return verticalName.toLowerCase() === DEAFULT_FILTER_ALL.toLowerCase() ? 'all verticals' : verticalName;
};

export const getShareOfCostCategories = (labels, nameByLabel) => {
    return labels.map(label => {
        if (label.type === APP_FILTER) {
            return nameByLabel[label.value];
        } else {
            return calculateVerticalLabel(label.value);
        }
    });
};

export const getYearOverYearColorByValue = value => {
    if (value >= 0) {
        return ColorByValueSign.Positive;
    } else {
        return ColorByValueSign.Negative;
    }
};

export const dateLabelsFormatter = ({ value }) => {
    return moment(value).format(X_AXIS_LABEL_DATE_FORMAT);
};

export const networkLabelsFormatter = (logoByLabel, { value }) => {
    return `<div class="network-xaxis-label-container">
                                <div class="network-xaxis-logo-container">
                                    <img src=${logoByLabel[value] ||
                                        '/static/dashboard/img/icons/no_logo.svg'} class="network-xaxis-logo" />
                                </div>
                                <div class="network-xaxis-label-text">${value}</div>   
                            </div>`;
};

export const appLabelsFormatter = (logoByName, { value }) => {
    return `<div class="app-xaxis-label-container">
                                <div class="app-xaxis-logo-container">
                                    ${
                                        logoByName[value]
                                            ? `<img src=${logoByName[value]} class="app-xaxis-logo" />`
                                            : ''
                                    }
                                </div>
                                <div class="app-xaxis-label-text">${value}</div>   
                            </div>`;
};

export const calculateGradientColumnEdge = (value, min, max) => {
    const color = getYearOverYearColorByValue(value);
    const white = '#FFFFFF';
    const colorStopMax = (value - max) / value;
    const colorStopMin = min / value;
    const gradientHeight = 0.05;
    const gradientColor = {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops:
            value < 0
                ? [
                      [colorStopMin - gradientHeight, color],
                      [colorStopMin, white],
                  ]
                : [
                      [colorStopMax, white],
                      [colorStopMax + gradientHeight, color],
                  ],
    };

    return (value > 0 && value > max) || (value < 0 && value < min) ? gradientColor : color;
};

export const getAverage = data => {
    const sum = data.reduce((accum, value) => {
        return accum + value;
    }, 0);

    return sum / data.length;
};

export const getStandardDeviation = values => {
    const avg = getAverage(values);

    const squareDiffs = values.map(value => {
        const diff = value - avg;
        return diff * diff;
    });

    const avgSquareDiff = getAverage(squareDiffs);

    return Math.sqrt(avgSquareDiff);
};

export const getMinYAxis = values => {
    const filteredValues = values.filter(value => value < 0);
    const avg = getAverage(filteredValues);
    const standardDeviation = getStandardDeviation(filteredValues);

    return Math.min(avg + standardDeviation * 2, -5);
};

export const getMaxYAxis = values => {
    const filteredValues = values.filter(value => value > 0);
    const avg = getAverage(filteredValues);
    const standardDeviation = getStandardDeviation(filteredValues);

    return Math.max(avg + standardDeviation * 2, 5);
};

export const getFormattedOption = ({ label, value }) => {
    return { label, value };
};

export const sortFormattedOptionCallback = option => {
    return option === DEAFULT_FILTER_ALL ? -1 : 0;
};

export const getAppFilterOptions = (
    rawOptions = [],
    { addEmptyOption = false } = {},
    { includeAllOption = true } = {}
) => {
    const options = rawOptions.sort(sortFormattedOptionCallback).map(getFormattedOption);

    if (addEmptyOption) {
        options.unshift({ label: '', value: null });
    }

    return options;
};

export const getFilterOptions = (
    rawOptions = [],
    { addEmptyOption = false } = {},
    { includeAllOption = true } = {}
) => {
    const options = rawOptions.sort(sortFormattedOptionCallback).map(getFormattedOption);

    if (includeAllOption) {
        options.unshift({ label: 'All', value: DEAFULT_FILTER_ALL });
    }

    return options;
};

export const getPlatformFilterOptions = (rawOptions = []) => {
    return [{ label: 'All verticals', value: DEAFULT_FILTER_ALL }].concat(
        rawOptions.sort(sortFormattedOptionCallback).map(getFormattedOption)
    );
};

export const getOnlyRegionOptions = (rawCountryOptions = []) => {
    return rawCountryOptions
        .filter(option => {
            return option.type === 'region';
        })
        .map(getFormattedOption);
};

export const getOnlyCountryOptions = (rawCountryOptions = []) => {
    return rawCountryOptions
        .filter(option => {
            return option.type === 'country';
        })
        .map(({ label, value }) => {
            return { label, value, imgSrc: `static/dashboard/img/flags/${value}.gif` };
        });
};

export const getCountryFilterOptions = (
    rawCountryOptions = [],
    { addEmptyOption = false } = {},
    { includeAllOption = true } = {}
) => {
    const options = [
        {
            label: 'Regions',
            options: getOnlyRegionOptions(rawCountryOptions),
        },
        {
            label: 'Countries',
            options: getOnlyCountryOptions(rawCountryOptions),
        },
    ];
    if (includeAllOption) {
        options.unshift({ label: 'All', value: DEAFULT_FILTER_ALL });
    }
    return options;
};

export const calculatePercentilesNoDataCount = chartsData => {
    let noDataCount = 0;

    if (chartsData) {
        noDataCount = BENCHMARKS_PERCENTILES_GRAPHS.length - Object.keys(chartsData).length;
    }

    return noDataCount;
};

export const parsePercentilePercentageValue = (value, includeDecimalPoints) => {
    let parsedValue = parseFloat(value * 100);

    parsedValue = parsedValue.toFixed(includeDecimalPoints ? 2 : 0);

    return parsedValue;
};

export const parsePercentileValue = (value, includeDecimalPoints) => {
    let parsedValue = parseFloat(Math.round(value * 100) / 100);

    parsedValue = parsedValue.toFixed(includeDecimalPoints ? 2 : 0);

    return parsedValue;
};

export const seriesFloatValueFormatter = value => {
    return value === 0 ? `${value}%` : `${parseFloat(value).toFixed(2)}%`;
};

export const seriesRangeValueFormatter = (value, includeDecimalPoints = true) => {
    return `${parsePercentileValue(value, includeDecimalPoints)}$`;
};

export const seriesRangeValueFormatterNoSymbol = (value, includeDecimalPoints = true) => {
    return `${parsePercentileValue(value, includeDecimalPoints)}`;
};

export const seriesRangePercentageValueFormatter = (value, includeDecimalPoints = true) => {
    return `${parsePercentilePercentageValue(value, includeDecimalPoints)}%`;
};

export const mapSeriesValues = values => {
    return values.map(value => {
        return value ? value * 100 : value;
    });
};

export const mapShareOfCostSeriesValues = values => {
    return values.map(value => {
        if (value === 0) {
            return null;
        } else {
            return value * 100;
        }
    });
};

export const parseYearOverYearChartData = chartData => {
    return {
        data: mapSeriesValues(chartData[0].data),
        label: chartData[0].label,
    };
};

export const filterOptionsParserByFilterType = {
    [COUNTRY_FILTER]: getCountryFilterOptions,
    [PLATFORM_FILTER]: getFilterOptions,
    [CATEGORY_FILTER]: getPlatformFilterOptions,
    [APP_FILTER]: getAppFilterOptions,
    [METRIC_FILTER]: x => x,
};

/**
 * This is a hack to solve a highcharts open issue with active scrollablePlotArea: yAxis labels hidden below the overlay box when updating the chart
 * link to issue: https://github.com/highcharts/highcharts/issues/8862
 * @param chartObject
 * @param funcName
 */

export const adjustChartSize = (chartObject, funcName) => {
    setTimeout(() => {
        if (chartObject && chartObject.options) {
            chartObject[funcName]();
        }
    }, 500);
};

export const syncChartsScrolls = (chartId1, chartId2) => {
    const chart1ScrollDiv = document.querySelector(`#${chartId1} .highcharts-scrolling`);

    if (chart1ScrollDiv) {
        chart1ScrollDiv.onscroll = event => {
            const chart2ScrollDiv = document.querySelector(`#${chartId2} .highcharts-scrolling`);
            if (chart2ScrollDiv) {
                chart2ScrollDiv.scrollLeft = event.target.scrollLeft;
            }
        };
    }
};

export const trackScrollEvent = debounce(() => {
    trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_PAGE_SCROLLED);
}, 3000);

export const onPageScroll = (event, ref) => {
    trackScrollEvent();
    if (ref.current) {
        if (event.target.scrollTop > 131) {
            if (!ref.current.classList.contains('stickyPageWrapper')) {
                ref.current.classList.add('stickyPageWrapper');
            }
        } else {
            ref.current.classList.remove('stickyPageWrapper');
        }
    }
};

export const resetPageScrollState = ref => {
    ref.current.classList.remove('stickyPageWrapper');
};

export const findFilterValue = (options = [], selectedFilterValue, isMulti) => {
    const flattenOptions = options.reduce((accum, option) => {
        return accum.concat(option.options ? option.options : [option]);
    }, []);

    if (isMulti) {
        return flattenOptions.filter(option => {
            return selectedFilterValue && selectedFilterValue.includes(option.value);
        });
    } else {
        return flattenOptions.find(option => {
            return selectedFilterValue && selectedFilterValue.includes(option.value);
        });
    }
};

export const getFormattedSelectedValue = selected => {
    if (Array.isArray(selected)) {
        return selected.map(selectedItem => selectedItem.value);
    } else if (selected) {
        return [selected.value];
    } else {
        return [DEAFULT_FILTER_ALL];
    }
};

export const getEmptyCategoryRange = emptyCategoryIndex => {
    return { from: emptyCategoryIndex - 0.3, to: emptyCategoryIndex + 0.3 };
};

export const calculateChartTitle = (chartData, appLabelList, appNameByLabel) => {
    const { platform, country, vertical } = chartData;

    const verticalName = calculateVerticalLabel(vertical);
    const appsTitleSection = `${
        appLabelList.length > 1 ? `${appLabelList.length} apps` : appNameByLabel[appLabelList]
    }`;
    let countryAndPlatformTitleSection = `${country} and ${platform}`;

    if (
        country.toLowerCase() === DEAFULT_FILTER_ALL.toLowerCase() &&
        platform.toLowerCase() === DEAFULT_FILTER_ALL.toLowerCase()
    ) {
        countryAndPlatformTitleSection = 'all countries and platforms';
    } else if (country.toLowerCase() === DEAFULT_FILTER_ALL.toLowerCase()) {
        countryAndPlatformTitleSection = `all countries and ${platform}`;
    } else if (platform.toLowerCase() === DEAFULT_FILTER_ALL.toLowerCase()) {
        countryAndPlatformTitleSection = `${country} and all platforms`;
    }

    return `${appsTitleSection} vs ${verticalName} in ${countryAndPlatformTitleSection} (Covers last 60 days)`;
};

export const validateNonEmptyFilters = selectedFilterValues => {
    return Object.keys(selectedFilterValues).reduce((accum, key) => {
        if (!(selectedFilterValues[key] && selectedFilterValues[key].length > 0)) {
            return { ...(accum || {}), [key]: 'Please select a value' };
        } else {
            return accum;
        }
    }, null);
};

export const shouldEnableFiltersFuncByPage = {
    [BENCHMARKS_PAGES.SHARE_OF_VOICE_VS_ME]: selectedFilterValues => {
        return selectedFilterValues.vertical.length < 10;
    },
};

export const validationErrorsFuncs = {
    [NON_EMPTY_FILTERS]: validateNonEmptyFilters,
};

export const onFeedbackBtnClick = () => {
    window.Intercom && window.Intercom('show');
};

export const getPercentileCategories = percentileName => {
    const percentileLabel = PERCENTILES_GRAPH_TH_LABELS[percentileName];

    return {
        percentile_25_75_range: `25% - 75% ${percentileLabel} Range`,
        userDataMedian: `Your ${percentileLabel}`,
        networkDataMedian: 'Median',
    };
};

export const networkHasData = (networkName, chartData) => {
    return !!BENCHMARKS_PERCENTILES_GRAPHS.find(graphType => {
        const { data = {} } = chartData[graphType] || {};
        return data[networkName];
    });
};
