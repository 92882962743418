import LinearIcon from '../resources/png/mta/linear.png';
import FirstTouchIcon from '../resources/png/mta/first-touch.png';
import LastTouchIcon from '../resources/png/mta/last-touch.png';
import TimeDecayIcon from '../resources/png/mta/time-decay.png';
import UModelIcon from '../resources/png/mta/u-model.png';

export function _getMtaMaxDate() {
    const today = new Date();
    return today.toISOString().substring(0, 10);
}

export const MTA_EVENT_PREFIX = 'MTA';

export const models = [
    {
        id: 'linear',
        name: 'linear',
        display_name: 'Linear',
        description:
            'Splits the credit for a conversion equally between all the interactions the customer had in the conversion path.',
        image: LinearIcon,
    },
    {
        id: 'first_touch',
        name: 'first_touch',
        display_name: 'First Touch',
        description:
            'Gives 100% of the credit to the first interaction (marketing event) with the user in the conversion path.',
        image: FirstTouchIcon,
    },
    {
        id: 'last_touch',
        name: 'last_touch',
        display_name: 'Last Touch',
        description: 'Gives 100% of the credit to the last click (marketing event) before the conversion event.',
        image: LastTouchIcon,
    },
    {
        id: 'u',
        name: 'u',
        display_name: 'U Model',
        description:
            'Splits the credit for a conversion event between a user’s first interaction and the last interaction before the conversion (40% each).  The remaining 20% spreads out between any other interactions in the middle.',
        image: UModelIcon,
    },
    {
        id: 'time_decay',
        name: 'time_decay',
        display_name: 'Time Decay',
        description:
            'Gives credit to all the channels that led to the conversion event, with credit reducing (decaying) the further back in time the channel was interacted with.',
        image: TimeDecayIcon,
    },
];
