import moment from 'moment';
import enTranslation from '../../../dashboard/js/locale/en';

const breakDownMap = {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
};

const normalizeTimeBreakDown = tb => {
    return breakDownMap[tb];
};

const datePickerTimes = {
    'STATIC.BUTTONS.DATEPICKER.TODAY': [moment(), moment()],
    'STATIC.BUTTONS.DATEPICKER.YESTERDAY': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'STATIC.BUTTONS.DATEPICKER.LAST_7_DAYS': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
    'STATIC.BUTTONS.DATEPICKER.LAST_30_DAYS': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
    'STATIC.BUTTONS.DATEPICKER.THIS_MONTH': [
        moment().startOf('month'),
        moment().date() === 1 ? moment().startOf('month') : moment().subtract(1, 'days'),
    ],
    'STATIC.BUTTONS.DATEPICKER.LAST_MONTH': [
        moment()
            .subtract(1, 'month')
            .startOf('month'),
        moment()
            .subtract(1, 'month')
            .endOf('month'),
    ],
    'STATIC.BUTTONS.DATEPICKER.THIS_QUARTER': [
        moment().startOf('quarter'),
        moment().date() === 1 ? moment().startOf('month') : moment().subtract(1, 'days'),
    ],
};

const getSecondDatePickerRanges = (firstStartDate, firstEndDate) => {
    const firstPeriodLength = moment(firstEndDate).diff(moment(firstStartDate), 'days');
    const endDate = moment(firstStartDate).subtract(1, 'days');
    const startDate = moment(endDate).subtract(firstPeriodLength, 'days');
    const endDateLastYear = moment(firstEndDate).subtract(1, 'years');
    const startDateLastYear = moment(firstStartDate).subtract(1, 'years');

    return {
        'STATIC.BUTTONS.DATEPICKER.PREVIOUS_PERIOD': [startDate, endDate],
        'STATIC.BUTTONS.DATEPICKER.SAME_PERIOD_LAST_YEAR': [startDateLastYear, endDateLastYear],
    };
};

const getDefaultDates = () => {
    return {
        start_date: moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    };
};

const getDefaultCompareDates = (startDate, endDate) => {
    const secondDatePicker = getSecondDatePickerRanges(startDate, endDate);
    const secondDatepickerKeys = Object.keys(secondDatePicker);
    return {
        start_date_2: secondDatePicker[secondDatepickerKeys[0]][0].format('YYYY-MM-DD'),
        end_date_2: secondDatePicker[secondDatepickerKeys[0]][1].format('YYYY-MM-DD'),
    };
};

const getDaysDiff = (startDate, endDate, fix = 1) => {
    return moment(endDate).diff(startDate, 'days') + fix;
};

const isFullSpanPeriod = (breakdown, startDate, endDate) => {
    const mStartDate = moment(startDate);
    const mEndDate = moment(endDate);
    const startOfPeriod = mStartDate.clone().startOf(breakdown);
    const endOfPeriod = mEndDate.clone().endOf(breakdown);
    const periodSpan = startOfPeriod.diff(endOfPeriod, 'days');
    const inputSpan = mStartDate.diff(mEndDate, 'days');
    return inputSpan === periodSpan;
};

const initMoment = () => {
    moment.updateLocale('en', {
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd, D MMMM YYYY HH:mm',
        },
        calendar: {
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            nextWeek: 'dddd [at] LT',
            lastDay: '[Yesterday at] LT',
            lastWeek: '[Last] dddd [at] LT',
            sameElse: 'L',
        },
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'a few seconds',
            m: 'a minute',
            mm: '%d minutes',
            h: 'an hour',
            hh: '%d hours',
            d: 'a day',
            dd: '%d days',
            M: 'a month',
            MM: '%d months',
            y: 'a year',
            yy: '%d years',
        },
        ordinalParse: /\d{1,2}(st|nd|rd|th)/,
        ordinal(number) {
            const b = number % 10;
            const output =
                ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
            return number + output;
        },
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // The week that contains Jan 4th is the first week of the year.
        },
    });
};

const timePeriods = enTranslation.STATIC.BUTTONS.DATEPICKER;

const enTimePeriodsDates = {
    [timePeriods.TODAY]: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    [timePeriods.YESTERDAY]: [
        moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
        moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
    ],
    [timePeriods.LAST_7_DAYS]: [
        moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD'),
        moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
    ],
    [timePeriods.LAST_30_DAYS]: [
        moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
        moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
    ],
    [timePeriods.THIS_MONTH]: [
        moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
        (moment().date() === 1 ? moment().startOf('month') : moment().subtract(1, 'days')).format('YYYY-MM-DD'),
    ],
    [timePeriods.LAST_MONTH]: [
        moment()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
        moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD'),
    ],
    [timePeriods.THIS_QUARTER]: [
        moment()
            .startOf('quarter')
            .format('YYYY-MM-DD'),
        (moment().date() === 1 ? moment().startOf('month') : moment().subtract(1, 'days')).format('YYYY-MM-DD'),
    ],
};

const getTimePeriodByDates = (startDate, endDate) => {
    let timePeriod = timePeriods.CUSTOM;
    Object.keys(enTimePeriodsDates).forEach(timePeriodKey => {
        if (JSON.stringify(enTimePeriodsDates[timePeriodKey]) === JSON.stringify([startDate, endDate])) {
            timePeriod = timePeriodKey;
        }
    });
    return timePeriod;
};

export {
    normalizeTimeBreakDown,
    datePickerTimes,
    getSecondDatePickerRanges,
    getDaysDiff,
    getDefaultCompareDates,
    getDefaultDates,
    isFullSpanPeriod,
    initMoment,
    getTimePeriodByDates,
};
