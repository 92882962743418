import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Shelf, Spinner, WarningMessage } from 'components/widgets';
import { FieldGroup, RunReportButton } from 'containers/widgets';
import {
    GeneralPopup,
    Grid,
    LineChart,
    LoadingPlaceholder,
    PageHeader,
    PageWrapper,
    PopupTypes,
} from 'components/partials';
import ReportsNoData from 'components/partials/ReportsNoData';
import Checkbox from 'components/widgets/Checkbox';
import css from './Reports.css';
import fieldGroupCss from '../widgets/FieldGroup.css';
import { REPORTS_EVENT_PREFIX, trackMixpanelEvent, trackShowVideoMixpanelEvent } from '../../utils/general';
import PivotChart from '../partials/PivotChart';
import GlassesIcon from '../../resources/svg/glasses.svg';
import ChartIcon from '../../resources/svg/chart.svg';
import ChevronIcon from '../../resources/icons/chevron.svg';
import FieldsAvailability from '../../fieldsAvailability/containers/fieldsAvailabilityContainer';
import { AVAILABILITY_PARAMETER_OPTION__DIMENSION } from '../../fieldsAvailability/consts';
import EditShelf from './reports/EditShelf';
import ReportsEmptyState from './reports/ReportsEmptyState';
import { PAGE_WRAPPER_NO_PADDING_STYLE } from '../../utils/consts';
import FiltersSector from '../partials/FiltersSector';
import { reportTypes } from '../../utils/reports';
import Banner from '../widgets/Banner';
import ReportBannerIcon from '../../resources/svg/reportBannerIcon.svg';
import { insertUserBannerCloseClicks, shouldDisplayBanner } from './reports/utils';
import User from '../../reducers/user';

let hoverStartTime = null;
const mapStateToProps = state => ({
    user: state.user,
});

const onUnifiedBannerRedirectButtonClicked = () => {
    trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Unified banner redirect button had been clicked', {});
    window.location.href = '#/react/unified_report';
};

const onUnifiedBannerCloseButtonClicked = () => {
    trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Unified banner close button had been clicked', {});
    insertUserBannerCloseClicks();
};

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = { reportFormHasChanged: true };
    }

    componentDidUpdate(prevProps) {
        const { table: currentTable, onFiltersExpand } = this.props;
        const { table: previousTable } = prevProps;

        if (currentTable.show !== previousTable.show) {
            if (currentTable.show) {
                onFiltersExpand(false);
            }
        }
    }

    // generates the jsx based on the appropriate reportConfig
    _getComponent(item, index) {
        const {
            label,
            type,
            addButton,
            group,
            field,
            bigLabel,
            bigLabelTooltip,
            permissionKey,
            isShowAdmin,
            checkbox,
            renderChildrenOnly,
        } = item;

        const {
            onFieldSet,
            permissionKeys,
            isSlimMode,
            customFieldGroupLabels,
            fieldGroupsToHide,
            onFieldsAvailabilityClick,
            showFieldsAvailabilityFeature,
            adminMode,
            selectedSources,
            selectedFields,
            reportWarning,
        } = this.props;

        if (permissionKey && !permissionKeys.includes(permissionKey) && (!adminMode || !isShowAdmin)) {
            return null;
        }

        if (type === 'divider') {
            return <div className={css.divider} />;
        }

        if (type === 'dividerBorder') {
            return <div className={fieldGroupCss.borderDivider} />;
        }

        if (type === 'run') {
            return (
                <div className={css.fieldGroupHolder}>
                    <RunReportButton reportType={this.props.reportType} />
                </div>
            );
        }

        if (type === 'checkbox') {
            const currValue = this.props[field];
            return (
                <FieldGroup
                    label={bigLabel}
                    labelTooltip={bigLabelTooltip}
                    reportType={this.props.reportType}
                    renderChildrenOnly={renderChildrenOnly}
                    type={field}
                >
                    <Checkbox
                        style={{ marginBottom: 0, marginTop: 5 }}
                        checked={currValue}
                        onChange={() => onFieldSet(field, !currValue)}
                        label={label}
                    />
                </FieldGroup>
            );
        }

        if (
            fieldGroupsToHide[type] ||
            (group.type !== 'filter' &&
                (!this.props[type] || (!this.props[type].length && (!addButton || !addButton.show || isSlimMode))))
        ) {
            return null;
        }

        return (
            <FieldGroup
                bottomComponent={
                    shouldDisplayBanner(index, this.props.user, this.props.reportType) ? (
                        <Banner
                            buttonOnClick={onUnifiedBannerRedirectButtonClicked}
                            leftIcon={<ReportBannerIcon />}
                            buttonText="STATIC.PAGES.REPORTS.UNIFIED_BANNER.BUTTON"
                            label="STATIC.PAGES.REPORTS.UNIFIED_BANNER.HEADER"
                            subLabel="STATIC.PAGES.REPORTS.UNIFIED_BANNER.SUB_HEADER"
                            className={css.unifiedBanner}
                            onClose={onUnifiedBannerCloseButtonClicked}
                        />
                    ) : null
                }
                label={customFieldGroupLabels[type] || label}
                labelTooltip={bigLabelTooltip}
                fields={this.props[type]}
                selectedSources={selectedSources}
                dataTypeFields={selectedFields.dataTypeFields}
                fieldGroupWarning={group.data?.shouldShowFieldGroupWarning && reportWarning.text}
                fieldGroupWarningParameters={reportWarning.params}
                onFieldClick={(_field, value) => {
                    this.props.onFieldClick(_field, value);
                    if (checkbox && _field.name !== checkbox.visibleOnParentValue) {
                        onFieldSet(checkbox.field, false);
                    }
                }}
                showFieldsAvailabilityFeature={showFieldsAvailabilityFeature}
                onFieldsAvailabilityClick={onFieldsAvailabilityClick}
                renderChildrenOnly={renderChildrenOnly}
                addButton={addButton}
                type={type}
                group={group}
                reportType={this.props.reportType}
            >
                {checkbox &&
                    this.props[type].find(_field => {
                        return _field.checked && _field.name === checkbox.visibleOnParentValue;
                    }) && (
                        <Checkbox
                            style={{ marginBottom: 0, marginTop: 5 }}
                            checked={this.props[checkbox.field]}
                            onChange={() => onFieldSet(checkbox.field, !this.props[checkbox.field])}
                            label={checkbox.label}
                        />
                    )}
            </FieldGroup>
        );
    }

    _getMigrationDatesMessage(reportData) {
        // Info message about TI and DPOU migration dates (admin-only)
        const messages = [];

        if (reportData.tiAttributionMigrationDate) {
            messages.push(`TI Migration Date: ${reportData.tiAttributionMigrationDate}`);
        }
        if (reportData.dpouMigrationDates && Object.keys(reportData.dpouMigrationDates).length > 0) {
            messages.push(
                `DPOU Migration Dates: ${Object.entries(reportData.dpouMigrationDates)
                    .map(([logType, migDate]) => `${logType}=${migDate}`)
                    .join(', ')}`
            );
        }
        if (reportData.snowflakeMigrationTime) {
            messages.push(`Snowflake Migration Time (UTC): ${reportData.snowflakeMigrationTime}`);
        }

        return messages.join('<br>');
    }

    render() {
        const {
            shelf,
            header,
            showSpinner,
            chart,
            table,
            chartData,
            reportData,
            reportType,
            showTransparencyPopover,
            dimensionDrilldownCellId,
            onToggleChart,
            showReportMeta,
            onDrilldownPopoverToggle,
            showHeader,
            validationError,
            pivotTable,
            showPivotCharts,
            activeBookmarkName,
            openFieldsAvailability,
            fieldsAvailabilityFieldName,
            onFieldsAvailabilityClick,
            adminMode,
            showPopupDialog,
            popupDialogParams,
            emptyStateOrgData,
            onCloseReportsEmptyState,
            isEditShelfOpen,
            showFieldsSelection,
            isShelfReporting,
        } = this.props;

        const { reportFormHasChanged } = this.state;
        const showFields = showFieldsSelection && !isShelfReporting;
        const timeBreakdown = (chart.request && chart.request.time_breakdown[0]) || '';
        const pageWrapperStyle =
            !isShelfReporting && validationError ? { paddingTop: '35px' } : PAGE_WRAPPER_NO_PADDING_STYLE;

        const tiDpouMigrationDatesMessage = this._getMigrationDatesMessage(reportData);
        const shouldShowEmptyState = showFieldsSelection && isShelfReporting;

        const onVideoClick = () => {
            trackShowVideoMixpanelEvent({ watchedFrom: 'Reports' });
            this.props.changePopupDialog(true, {
                videoLink: 'https://vimeo.com/showcase/8962880/embed?autoplay=1',
                type: PopupTypes.VIDEO,
            });
        };

        const onPopupDialogClosed = () => {
            this.props.changePopupDialog(false, {});
        };

        const closeFieldsAvailabilityShelf = () => {
            onFieldsAvailabilityClick('', false);
        };

        const isWarning = validationError && !isShelfReporting;
        const isChartEnabled = chart.enabled && !table.request?.compare;

        return (
            <Fragment>
                {isWarning ? (
                    <WarningMessage
                        containerStyle={{
                            width: '100%',
                            position: 'fixed',
                            zIndex: 'calc(var(--stickyZIndex) + 3)',
                        }}
                        type="error"
                        showIcon={false}
                        message={validationError}
                    />
                ) : null}
                {showPopupDialog && (
                    <GeneralPopup
                        {...popupDialogParams}
                        open={showPopupDialog}
                        type={popupDialogParams.type}
                        onReject={onPopupDialogClosed}
                    />
                )}
                <PageWrapper style={pageWrapperStyle} disablePadding>
                    {showHeader && (
                        <PageHeader
                            {...header}
                            additionalText={activeBookmarkName}
                            onVideoClick={!header.subText?.hideVideo && onVideoClick}
                            key={reportType}
                            sticky
                            emptyStateOrgData={emptyStateOrgData}
                            onCloseEmptyState={onCloseReportsEmptyState}
                            showEmptyState={emptyStateOrgData.show_empty_state_reports}
                            stickyShadow={showFields}
                            reportFormHasChanged={reportFormHasChanged}
                            shouldAddPadding
                            stickyStyle={{
                                paddingTop: isWarning ? '35px' : '20px',
                                paddingBottom: isWarning ? '0' : '10px',
                            }}
                        >
                            {!isShelfReporting && <FiltersSector reportType={reportType} />}
                        </PageHeader>
                    )}
                    {showFields && (
                        <div className={css.shelfContainer}>
                            {shelf.map((configItem, index) => {
                                const item = this._getComponent(configItem, index);
                                return (
                                    item && (
                                        <div
                                            key={`${configItem.type}_${index}`}
                                            className={css.fieldGroupHolder}
                                            style={{ ...configItem.group?.data?.fieldGroupHolderStyle }}
                                        >
                                            {item}
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    )}
                    {shouldShowEmptyState && (
                        <div className={css.shelfContainer} style={{ marginTop: '30px' }}>
                            <ReportsEmptyState reportType={reportType} />
                        </div>
                    )}
                    {showSpinner && <Spinner style={{ marginTop: '250px' }} show />}
                    {!shouldShowEmptyState && !showSpinner && table.show && (
                        <div className={css.dataContainer}>
                            {isChartEnabled && (
                                <ReactCSSTransitionGroup
                                    transitionName={{
                                        enter: css.smoothOpenAnimationEnter,
                                        enterActive: css.smoothOpenAnimationEnterActive,
                                        leave: css.smoothOpenAnimationLeave,
                                        leaveActive: css.smoothOpenAnimationLeaveActive,
                                    }}
                                    transitionEnterTimeout={400}
                                    transitionLeaveTimeout={50}
                                >
                                    {chart.slowLoading ? (
                                        <LoadingPlaceholder
                                            key="loader"
                                            loadingText="STATIC.PAGES.REPORTS.CHART_CONTAINS_A_LOT_OF_DATA"
                                            readyText="STATIC.PAGES.REPORTS.YOUR_CHART_IS_READY"
                                            buttonText="STATIC.BUTTONS.SHOW_CHART"
                                            state={
                                                chartData && chartData.metrics.length
                                                    ? LoadingPlaceholder.READY
                                                    : LoadingPlaceholder.LOADING
                                            }
                                            onReadyClick={this.props.onToggleOffSlowLoadingChart}
                                            className={css.loadingContainer}
                                        />
                                    ) : null}
                                    {!showSpinner && !chart.slowLoading && table.show && (
                                        <div
                                            className={css.hideElementDivider}
                                            style={{
                                                marginBottom: '20px',
                                                marginTop: '20px',
                                            }}
                                        >
                                            <span
                                                onClick={() => {
                                                    onToggleChart(!chart.show);
                                                    trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Toggle chart display', {
                                                        hide: chart.show,
                                                    });
                                                }}
                                            >
                                                <ChartIcon className={css.collapsedIcon} />
                                                <div className={css.collapsedText}>
                                                    <Translate
                                                        id={`STATIC.BUTTONS.${
                                                            chart.show ? 'HIDE_CHART' : 'SHOW_CHART'
                                                        }`}
                                                    />
                                                </div>
                                                <ChevronIcon
                                                    className={classNames(css.chevronIcon, {
                                                        [`${css.expanded}`]: chart.show,
                                                        [`${css.collapsed}`]: !chart.show,
                                                    })}
                                                />
                                            </span>
                                        </div>
                                    )}
                                    {!chart.slowLoading && chart.show ? (
                                        <LineChart
                                            spinnerStyle={{ zIndex: 1 }}
                                            numOfTableRows={
                                                reportData && reportData.rowData ? reportData.rowData.length : 0
                                            }
                                            key="chart"
                                            chartData={chartData}
                                            timeBreakdown={timeBreakdown}
                                            chart={chart}
                                            onLinesChanged={this.props.onChartLinesChanged}
                                            onChartLegendToggle={this.props.onChartLegendToggle}
                                            onMetricSelected={this.props.onChartMetricSelected}
                                            onChartLoaded={this.props.onChartLoaded}
                                            onMouseEnter={() => {
                                                hoverStartTime = new Date();
                                            }}
                                            onMouseLeave={() => {
                                                const hoverTime = new Date() - hoverStartTime;
                                                if (hoverTime > 5000) {
                                                    trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Hover on Chart', {
                                                        page: reportType,
                                                        hoverTime,
                                                    });
                                                }
                                            }}
                                        />
                                    ) : null}
                                </ReactCSSTransitionGroup>
                            )}
                            {// For reports that have no charts (e.g. pivots) we still want to show an indicator saying there's
                            // no data available.
                            // To handle that, we use the 'ReportsNoData' component (for reports that have charts, the
                            // decision whether there's data or not depends on the chart data, which is why I left this logic
                            // inside the LineChart component)
                            !isChartEnabled &&
                                !showSpinner &&
                                reportData.columnDefs &&
                                reportData.rowData &&
                                reportData.rowData.length === 0 && <ReportsNoData />}
                            {showPivotCharts &&
                                table.show &&
                                reportType === reportTypes.pivot &&
                                reportData.reportId &&
                                reportData.rowData &&
                                reportData.rowData.length > 0 && (
                                    <PivotChart
                                        reportId={reportData.reportId}
                                        rowData={reportData.rowData}
                                        reportColumns={reportData.columnDefs.map(x => ({
                                            name: x.field,
                                            fieldType: x.fieldType,
                                            displayName: x.headerName,
                                            displayFormat: x.displayFormat,
                                        }))}
                                        showControls={adminMode}
                                        settings={pivotTable.chart}
                                        onSettingsChange={chartSettings => {
                                            this.props.onPivotChartChange(chartSettings);
                                            this.props.onUpdateQueryURL();
                                        }}
                                    />
                                )}
                            {table.show && (
                                <>
                                    {showReportMeta && reportData && (
                                        <WarningMessage
                                            type="info"
                                            showIcon={false}
                                            show
                                            message={`report_id: ${reportData.reportId}. Report creation time: ${reportData.cacheTimestamp}`}
                                            containerStyle={{
                                                marginTop: '50px',
                                            }}
                                        />
                                    )}
                                    {showReportMeta && reportData && (
                                        <WarningMessage
                                            type="info"
                                            showIcon={false}
                                            show
                                            message={`Org using Calendric Cohort: ${reportData.useCalendricCohort}`}
                                            className={css.innerInfoMessages}
                                        />
                                    )}
                                    {showReportMeta && reportData && tiDpouMigrationDatesMessage && (
                                        <WarningMessage
                                            type="info"
                                            showIcon={false}
                                            show
                                            message={tiDpouMigrationDatesMessage}
                                            className={css.innerInfoMessages}
                                        />
                                    )}
                                    {reportData && reportData.customer && (
                                        <WarningMessage
                                            type="info"
                                            showIcon={false}
                                            show
                                            message="STATIC.PAGES.REPORTS.PARTNER_TIMEZONE_INFO"
                                            textReplace={{
                                                customer: reportData.customer,
                                                attributionTimezone: reportData.attributionTimezone,
                                            }}
                                            containerStyle={{
                                                marginTop: '50px',
                                                marginBottom: '10px',
                                            }}
                                        />
                                    )}
                                    <Grid
                                        onTableFilterChanged={this.props.onFilterChanged}
                                        onReady={this.props.onGridReady}
                                        id={`report_${reportType}`}
                                        key={`report_${reportType}`}
                                        scrollOnlyOnPageBottom
                                        showTransparencyPopover={showTransparencyPopover}
                                        dimensionDrilldownCellId={dimensionDrilldownCellId}
                                        onDrilldownPopoverToggle={onDrilldownPopoverToggle}
                                        onPopoverToggle={this.props.onPopoverToggle}
                                        reportType={reportType}
                                        {...reportData} // TODO: this is ugly as reportData is controlled by a ton of other places, and someone may blindly override other props of Grid without knowing
                                        pivotMode={
                                            reportType === reportTypes.pivot ||
                                            (reportType === 'anonymousReport' && Object.keys(pivotTable).length > 0)
                                        }
                                        pivotTable={pivotTable}
                                        onPinColumn={this.props.onPinColumn}
                                        onMoveColumn={this.props.onMoveColumn}
                                        onResetTable={this.props.onResetTable}
                                        onToggleColumn={this.props.onToggleColumn}
                                        onFreezeColumn={this.props.onFreezeColumn}
                                        onPivotModeToggle={this.props.onPivotModeToggle}
                                        onPivotRowGroupChanged={this.props.onPivotRowGroupChanged}
                                        onPivotColumnsChanged={this.props.onPivotColumnsChanged}
                                        onPivotValuesChanged={this.props.onPivotValuesChanged}
                                        onUpdateQueryURL={this.props.onUpdateQueryURL}
                                        className={
                                            !chart.slowLoading && chart.show
                                                ? css.gridContainer
                                                : css.gridContainerWhenNoChart
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )}
                    <Shelf
                        open={openFieldsAvailability}
                        shelfSize="medium"
                        headerText="STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.HEADER_TEXT"
                        HeaderImage={GlassesIcon}
                        headerImageClassName={css.fieldsAvailabilityShelfIcon}
                        closeOnClickOutside
                        onClose={closeFieldsAvailabilityShelf}
                    >
                        <FieldsAvailability
                            availabilityParameter={AVAILABILITY_PARAMETER_OPTION__DIMENSION.name}
                            fieldName={fieldsAvailabilityFieldName}
                            onClose={closeFieldsAvailabilityShelf}
                        />
                    </Shelf>
                    <EditShelf open={isEditShelfOpen} reportType={reportType} />
                </PageWrapper>
            </Fragment>
        );
    }
}

Reports.propTypes = {
    shelf: PropTypes.arrayOf(PropTypes.any),
    onFieldClick: PropTypes.func,
    onFieldSet: PropTypes.func,
    onRunQuery: PropTypes.func,
    onChartLinesChanged: PropTypes.func,
    onChartLegendToggle: PropTypes.func,
    onChartMetricSelected: PropTypes.func,
    onChartLoaded: PropTypes.func,
    onGridReady: PropTypes.func,
    onFilterChanged: PropTypes.func,
    onToggleOffSlowLoadingChart: PropTypes.func,
    onPopoverToggle: PropTypes.func,
    reportType: PropTypes.string,
    showFieldsSelection: PropTypes.bool,
    showSpinner: PropTypes.bool,
    header: PropTypes.objectOf(PropTypes.any),
    chartData: PropTypes.objectOf(PropTypes.any),
    reportData: PropTypes.objectOf(PropTypes.any),
    chart: PropTypes.objectOf(PropTypes.any),
    table: PropTypes.objectOf(PropTypes.any),
    validationError: PropTypes.string,
    showTransparencyPopover: PropTypes.string,
    reportDates: PropTypes.objectOf(PropTypes.any),
    onPinColumn: PropTypes.func,
    onMoveColumn: PropTypes.func,
    onResetTable: PropTypes.func,
    onToggleColumn: PropTypes.func,
    onFreezeColumn: PropTypes.func,
    onToggleChart: PropTypes.func,
    showReportMeta: PropTypes.bool,
    onPivotModeToggle: PropTypes.func,
    onPivotRowGroupChanged: PropTypes.func,
    onPivotColumnsChanged: PropTypes.func,
    onPivotValuesChanged: PropTypes.func,
    onPivotChartChange: PropTypes.func,
    onUpdateQueryURL: PropTypes.func,
    translate: PropTypes.func,
    pivotTable: PropTypes.objectOf(PropTypes.any),
    permissionKeys: PropTypes.arrayOf(PropTypes.string),
    isSlimMode: PropTypes.bool,
    customFieldGroupLabels: PropTypes.objectOf(PropTypes.any),
    fieldGroupsToHide: PropTypes.objectOf(PropTypes.any),
    onFieldsAvailabilityClick: PropTypes.func,
    showFieldsAvailabilityFeature: PropTypes.bool,
    adminMode: PropTypes.bool,
    selectedSources: PropTypes.arrayOf(PropTypes.any),
    selectedFields: PropTypes.objectOf(PropTypes.any),
    reportWarning: PropTypes.shape({
        text: PropTypes.string,
        params: PropTypes.objectOf(PropTypes.any),
    }),
    changePopupDialog: PropTypes.func,
    showPopupDialog: PropTypes.bool,
    popupDialogParams: PropTypes.objectOf(PropTypes.any),
    emptyStateOrgData: PropTypes.objectOf(PropTypes.any),
    onCloseReportsEmptyState: PropTypes.func,
    isEditShelfOpen: PropTypes.bool,
    isShelfReporting: PropTypes.bool,
    onFiltersExpand: PropTypes.func,
    user: PropTypes.objectOf(User),
};

Reports.defaultProps = {
    shelf: [],
    onFieldClick: () => {},
    onFieldSet: () => {},
    onRunQuery: () => {},
    onChartLinesChanged: () => {},
    onChartLegendToggle: () => {},
    onChartMetricSelected: () => {},
    onChartLoaded: () => {},
    onFilterChanged: () => {},
    onGridReady: () => {},
    onToggleOffSlowLoadingChart: () => {},
    onPopoverToggle: () => {},
    onPinColumn: () => {},
    onMoveColumn: () => {},
    onResetTable: () => {},
    onToggleColumn: () => {},
    onFreezeColumn: () => {},
    onToggleChart: () => {},
    translate: () => {},
    onPivotModeToggle: () => {},
    onPivotRowGroupChanged: () => {},
    onPivotColumnsChanged: () => {},
    onPivotValuesChanged: () => {},
    onPivotChartChange: () => {},
    onUpdateQueryURL: () => {},
    reportType: 'reports',
    showFieldsSelection: false,
    showSpinner: false,
    header: {},
    chartData: {},
    chart: {
        enabled: false,
        show: false,
    },
    reportData: {},
    table: {
        enabled: false,
        show: false,
    },
    validationError: '',
    showTransparencyPopover: '',
    reportDates: {},
    showReportMeta: false,
    pivotTable: {},
    permissionKeys: [],
    isSlimMode: undefined,
    customFieldGroupLabels: {},
    fieldGroupsToHide: {},
    onFieldsAvailabilityClick: () => {},
    showFieldsAvailabilityFeature: false,
    adminMode: false,
    selectedSources: [],
    selectedFields: {},
    reportWarning: {},
    changePopupDialog: null,
    showPopupDialog: false,
    popupDialogParams: {},
    emptyStateOrgData: {},
    onCloseReportsEmptyState: () => {},
    isEditShelfOpen: false,
    isShelfReporting: false,
    onFiltersExpand: () => {},
};

export default connect(mapStateToProps)(Reports);
