import React from 'react';

export default function SdkIntegrationIntro() {
    return (
        <div>
            <p>
                <span>While tracking and measuring marketing performance, Singular's attribution service uses postbacks to report installs and events back to the ad networks, for their own measurement.</span>
            </p>
            <p>
                <span>You can configure postbacks to be sent to your BI as well. In the Partner Configuration page, look for “Internal BI”, and configure each app to send postbacks to the desired end-points. See our </span><a
                href="https://developers.singular.net/docs/partner-postbacks" target="_blank">Internal BI Postbacks guide</a><span
            >.</span>
            </p>
        </div>
);
}
