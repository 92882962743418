import { JAVA } from '../../consts';
import { CODE_SNIPPETS } from '../consts';

const getAdMobJavaCodeSnippet = () => {
    return `RewardedAd mRewardedAd;

@Override
public void onAdLoaded(@NonNull RewardedAd rewardedAd) {
   mRewardedAd = rewardedAd;
   mRewardedAd.setOnPaidEventListener(new OnPaidEventListener() {
      @Override
      public void onPaidEvent(AdValue adValue) {
         AdValue impressionData = adValue;
         SingularAdData data = new SingularAdData(
            "AdMob", 
            impressionData.getCurrencyCode(),
            impressionData.getValueMicros()/1000000.0);
         Singular.adRevenue(data);
      }
   });
}`;
};

const getApplovinJavaCodeSnippet = () => {
    return `AppLovinCommunicatorMessage message;
Bundle adData = message.getMessageData();
SingularAdData data = new SingularAdData(
   "AppLovin", 
   "USD", 
   adData.getDouble("revenue"));
Singular.adRevenue(data);`;
};

const getUnityJavaCodeSnippet = () => {
    return `ImpressionData impressionData;
SingularAdData data = new SingularAdData(
   "IronSource", 
   "USD", 
   impressionData.getRevenue());
Singular.adRevenue(data);`;
};

const getOtherCodeJavaSnippet = () => {
    return `SingularAdData data = new SingularAdData(
   "YOUR_AD_PLATFORM", 
   "CURRENCY_CODE", 
   9.90);

Singular.adRevenue(data);`;
};

const getTradPlusCodeJavaSnippet = () => {
    return `TradPlusSdk.setGlobalImpressionListener(
   new GlobalImpressionManager.GlobalImpressionListener() {

   @Override
   public void onImpressionSuccess(TPAdInfo tpAdInfo) {
      if(tpAdInfo == null) return;
      double revenue = Double.parseDouble(tpAdInfo.ecpm) / 1000;
      SingularAdData data = new SingularAdData(
         "TradPlus", 
         "USD", 
         revenue);
      Singular.adRevenue(data);
   }
});`;
};

const javaAdMonetizationCodeSnippetsGetters = {
    [CODE_SNIPPETS.AD_MONETIZATION_ADMOB]: getAdMobJavaCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN]: getApplovinJavaCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_UNITY]: getUnityJavaCodeSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_OTHER]: getOtherCodeJavaSnippet,
    [CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS]: getTradPlusCodeJavaSnippet,
};

const getJavaCodeSnippet = mediator => {
    const codeSnippetGetter = javaAdMonetizationCodeSnippetsGetters[mediator];
    return {
        markedCode: codeSnippetGetter(),
        codeLanguage: JAVA,
        placeholders: {},
        customTitle: JAVA,
    };
};

const getJavaAdMonAdMobSnippet = () => getJavaCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_ADMOB);
const getJavaAdMonUnitySnippet = () => getJavaCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_UNITY);
const getJavaAdMonApplovinSnippet = () => getJavaCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_APPLOVIN);
const getJavaAdMonOtherSnippet = () => getJavaCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_OTHER);
const getJavaAdMonTradPlusSnippet = () => getJavaCodeSnippet(CODE_SNIPPETS.AD_MONETIZATION_TRADPLUS);

export {
    getJavaAdMonAdMobSnippet,
    getJavaAdMonApplovinSnippet,
    getJavaAdMonUnitySnippet,
    getJavaAdMonOtherSnippet,
    getJavaAdMonTradPlusSnippet,
};
