import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import css from './PageHeader.css';
import Label from '../../widgets/Label';

const PageHeaderSlim = ({ text, subheaderText, className, style }) => {
    return (
        <div className={classNames(css.container, className)} style={style}>
            <div className={css.text}>
                <Translate id={text} />
            </div>
            {subheaderText && (
                <div className={css.subHeader}>
                    <Label type="p" text={subheaderText} />
                </div>
            )}
        </div>
    );
};

PageHeaderSlim.propTypes = {
    text: PropTypes.string,
    subheaderText: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
};

PageHeaderSlim.defaultProps = {
    text: '',
    subheaderText: undefined,
    className: '',
    style: undefined,
};

export default PageHeaderSlim;
