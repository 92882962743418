import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Chevron from '../../resources/icons/chevron.svg';
import { useLocalStorageState } from '../../utils/customHooks';
import css from './LogsTable.css';

const ExpandableLogsTableRow = ({ children, expandComponent, logUniqueId, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = useLocalStorageState(logUniqueId, false);
    return (
        <>
            <TableRow {...otherProps} onClick={() => setIsExpanded(!isExpanded)}>
                {children}
                <TableCell padding="none" align="right">
                    <IconButton>
                        {isExpanded ? (
                            <Chevron className={css.collapseIcon} />
                        ) : (
                            <Chevron className={css.expandIcon} />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            {isExpanded && <TableRow className={css.expandedRow}>{expandComponent}</TableRow>}
        </>
    );
};

ExpandableLogsTableRow.propTypes = {
    children: PropTypes.node.isRequired,
    expandComponent: PropTypes.node.isRequired,
    logUniqueId: PropTypes.string.isRequired,
};

export default ExpandableLogsTableRow;
