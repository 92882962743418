import React from 'react';
import PropTypes from 'prop-types';
import ListItemButton from '@mui/material/ListItem';
import classNames from 'classnames';
import { CHECKED_ICON_TEST_ID, NESTED_TEST_ID, NUMBER_TEST_ID, UNCHECKED_ICON_TEST_ID } from '../consts';
import css from './ListItem.css';
import { Label } from '../../../widgets';
import CheckedIcon from '../../../../resources/svg/checked_with_circle_green.svg';
import UnCheckedIcon from '../../../../resources/svg/unchecked_gray400.svg';
import Chevron from '../../../../resources/icons/chevron.svg';

const ListItem = ({
    itemData,
    isStepPassed,
    itemKey,
    handleOnClick,
    showCheckedIcon,
    isActive,
    isExpandable,
    isExpanded,
}) => {
    const {
        itemNumber,
        completed = false,
        badgeText = '',
        nestedCount = 0,
        disabled = false,
        itemTitle = undefined,
    } = itemData;

    const ItemNumberTitle = () => (
        <span className={css.number} data-testid={`${NUMBER_TEST_ID}-${itemKey}`}>
            {itemNumber}
        </span>
    );

    const renderIcon = () => {
        const hasNestedItems = !!nestedCount;
        const isCompleted = completed || (isStepPassed && !isExpandable);

        if (showCheckedIcon) {
            if (isCompleted) {
                return (
                    <>
                        <CheckedIcon className={css.circleIcon} data-testid={`${CHECKED_ICON_TEST_ID}-${itemKey}`} />
                        {hasNestedItems && <ItemNumberTitle />}
                    </>
                );
            } else if (!hasNestedItems) {
                return (
                    <span
                        className={`${css.circleIcon} ${css.numberCircle}`}
                        data-testid={`${NUMBER_TEST_ID}-${itemKey}`}
                    >
                        {itemNumber}
                    </span>
                );
            } else {
                return (
                    <>
                        <UnCheckedIcon
                            className={css.circleIcon}
                            data-testid={`${UNCHECKED_ICON_TEST_ID}-${itemKey}`}
                        />
                        <ItemNumberTitle />
                    </>
                );
            }
        } else {
            return (
                <span
                    className={classNames({
                        [css.circleIcon]: true,
                        [css.numberCircle]: !hasNestedItems,
                        [css.number]: hasNestedItems,
                    })}
                    data-testid={`${NUMBER_TEST_ID}-${itemKey}`}
                >
                    {itemNumber}
                </span>
            );
        }
    };

    return (
        <ListItemButton
            className={classNames(css.listItem, {
                [css.selectedListItem]: isActive,
                [css.disabledListItem]: disabled,
            })}
            onClick={() => handleOnClick(itemKey, disabled)}
            disabled={disabled}
            data-testid={`list-item-${itemKey}`}
            key={itemKey}
        >
            <div
                className={classNames(css.listItemText, {
                    [css[`indent${showCheckedIcon ? 0 : nestedCount}`]]: !!nestedCount,
                })}
                data-testid={nestedCount ? `${NESTED_TEST_ID}-${nestedCount}` : ''}
            >
                {renderIcon()}
                <Label text={itemTitle || itemKey} type="p" className={css.listItemTextParagraph} />
            </div>
            <span className={css.listItemSuffix}>
                {!!badgeText && (
                    <span className={css.badgeLayout}>
                        <Label className={css.badgeText} text={badgeText}>
                            {badgeText}
                        </Label>
                    </span>
                )}
                {isExpandable && <Chevron className={isExpanded ? css.collapseIcon : css.expandIcon} />}
            </span>
        </ListItemButton>
    );
};

ListItem.defaultProps = {
    isExpandable: false,
    isExpanded: false,
    isStepPassed: false,
};

ListItem.propTypes = {
    itemData: PropTypes.object.isRequired,
    itemKey: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isStepPassed: PropTypes.bool,
    handleOnClick: PropTypes.func.isRequired,
    showCheckedIcon: PropTypes.bool.isRequired,
    isExpandable: PropTypes.bool,
    isExpanded: PropTypes.bool,
};

export default ListItem;
