import { useCallback } from 'react';
import { useRequest } from 'utils/customHooks';
import { trackMixpanelEvent } from 'utils/general';
import {
    TRACK_EVENT_PREFIX,
    SYSTEM_ALERTS_SAVED,
    CUSTOM_ALERT_SAVED,
    DELETE_ALERT,
    DC_ALERTS_TRACK_EVENT_PREFIX,
    DC_ALERTS_SHELF_SAVED,
} from 'alerts/consts';
import AlertsService from './service';

const AlertsAPI = new AlertsService();

export const useFetchReportTypes = () => {
    return useRequest(
        useCallback(AlertsAPI.getReportTypes, []),
        () => {
            return true;
        },
        () => {}
    );
};

export const useFetchAlertsMetadata = () => {
    return useRequest(
        useCallback(AlertsAPI.getAlertsMetadata, []),
        () => {
            return true;
        },
        () => {}
    );
};

export const useDeleteAlert = (shouldRun, alertId, onCleanup) => {
    const params = { alert_id: alertId };
    if (shouldRun) {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, DELETE_ALERT, params);
    }
    return useRequest(
        useCallback(AlertsAPI.deleteAlert, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        params
    );
};

export const useFetchAlerts = shouldPullAlerts => {
    return useRequest(
        useCallback(AlertsAPI.getAlerts, [shouldPullAlerts]),
        () => {
            return shouldPullAlerts;
        },
        () => {},
        shouldPullAlerts
    );
};

export const useSaveSystemAlerts = (
    shouldRun,
    loginEmails,
    loginSendToAdmins,
    dataDelayEmails,
    dataDelaySendToAdmins,
    onCleanup
) => {
    const params = {
        data_connector_login_alert: {
            emails: loginEmails,
            send_to_admins: loginSendToAdmins,
        },
        data_delay_alert: {
            emails: dataDelayEmails,
            send_to_admins: dataDelaySendToAdmins,
        },
    };
    if (shouldRun) {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, SYSTEM_ALERTS_SAVED, params);
    }
    return useRequest(useCallback(AlertsAPI.saveSystemAlert, [shouldRun]), () => shouldRun, onCleanup, params);
};

export const useSaveCustomAlerts = (
    shouldRunQuery,
    onCleanup,
    alertId,
    reportType,
    condition,
    threshold,
    timePeriod,
    breakdownDimensions,
    filters,
    reportName,
    alertEmails,
    sendToAdmins
) => {
    const params = {
        report_type: reportType,
        condition,
        threshold,
        time_period: timePeriod,
        breakdown_dimensions: breakdownDimensions,
        filters,
        name: reportName,
        emails: alertEmails,
        send_to_admins: sendToAdmins,
    };
    if (alertId !== -1) {
        params.id = alertId;
    }
    if (shouldRunQuery) {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, CUSTOM_ALERT_SAVED, params);
    }
    return useRequest(
        useCallback(AlertsAPI.saveCustomAlert, [shouldRunQuery]),
        () => {
            return shouldRunQuery;
        },
        onCleanup,
        params
    );
};

export const useFetchOrgUsers = () => {
    return useRequest(useCallback(AlertsAPI.getUsers, []), () => {
        return true;
    });
};

export const useFetchDataConnectorAlerts = (uanId, shouldLoad, onCleanup) => {
    return useRequest(
        useCallback(AlertsAPI.getDataConnectorAlerts, [shouldLoad]),
        () => {
            return !!uanId && shouldLoad;
        },
        onCleanup,
        uanId
    );
};

export const useFetchCanDataConnectorAlerts = (shouldLoad, setShouldLoad) => {
    return useRequest(
        useCallback(AlertsAPI.getCanDataConnectorAlerts, [shouldLoad]),
        () => {
            return shouldLoad;
        },
        () => {
            setShouldLoad(false);
        }
    );
};

export const useFetchAllOrgDataConnectorAlerts = (shouldLoad, onCleanup) => {
    return useRequest(
        useCallback(AlertsAPI.getAllOrgDataConnectorAlerts, []),
        () => {
            return shouldLoad;
        },
        onCleanup,
        shouldLoad
    );
};

export const useSaveDataConnectorAlerts = (
    shouldRun,
    uanId,
    loginEmails,
    loginSendToAdmins,
    dataDelayEmails,
    dataDelaySendToAdmins,
    onCleanup
) => {
    const params = {
        uanId,
        login_alert_recipients: {
            emails: loginEmails,
            send_to_admins: loginSendToAdmins,
        },
        data_delay_alert_recipients: {
            emails: dataDelayEmails,
            send_to_admins: dataDelaySendToAdmins,
        },
    };
    if (shouldRun) {
        trackMixpanelEvent(DC_ALERTS_TRACK_EVENT_PREFIX, DC_ALERTS_SHELF_SAVED, params);
    }
    return useRequest(
        useCallback(AlertsAPI.saveDataConnectorAlerts, [shouldRun]),
        () => {
            return shouldRun;
        },
        onCleanup,
        params,
        shouldRun
    );
};
