import React from 'react';
import moment from 'moment';

const REPORT_DAYS = 7;

export default function runCreativeReport() {
    const endDate = moment(new Date()).format('YYYY-MM-DD');
    const startDate = moment(new Date() - 1000 * 60 * 60 * 24 * REPORT_DAYS).format('YYYY-MM-DD');
    return (
        <div>
            <p>
                <strong>What: </strong><span> Run a report that shows your actual creative assets, including images and videos, next to their performance metrics.</span>
            </p>
            <p>
                <strong>Try these sample reports:</strong>
            </p>
            <ul>
                <li>
                    <a
                        href={`https://app.singular.net/#/creatives?query=%7B%22is_dashboard%22:false,%22api_version%22:1,%22is_default%22:false,%22show_creative_type%22:true,%22creative_group_by%22:%5B%22image%22%5D,%22display_alignment%22:false,%22displayNonGraphical%22:true,%22fuzzy%22:true,%22start_date%22:%22${startDate}%22,%22end_date%22:%22${endDate}%22,%22dimensions%22:%5B%22creative_type%22,%22app%22,%22source%22,%22creative_cluster%22,%22creative_cluster_representative_type%22,%22creative_cluster_members%22%5D,%22source_dimensions%22:%5B%5D,%22metrics%22:%5B%22adn_cost%22%5D,%22discrepancy_metrics%22:%5B%5D,%22cohort_metrics%22:%5B%22roi%22%5D,%22cohort_periods%22:%5B%223d%22%5D,%22filters%22:%5B%5D,%22time_breakdown%22:%5B%22all%22%5D%7D`}
                        target="_blank">
                        ROI per creative</a>
                </li>
                <li>
                    <a
                        href={`https://app.singular.net/#/creatives?query=%7B%22is_dashboard%22:false,%22api_version%22:1,%22is_default%22:false,%22show_creative_type%22:true,%22creative_group_by%22:%5B%22image%22%5D,%22display_alignment%22:false,%22displayNonGraphical%22:true,%22fuzzy%22:true,%22start_date%22:%22${startDate}%22,%22end_date%22:%22${endDate}%22,%22dimensions%22:%5B%22creative_type%22,%22app%22,%22source%22,%22creative_cluster%22,%22creative_cluster_representative_type%22,%22creative_cluster_members%22%5D,%22source_dimensions%22:%5B%5D,%22metrics%22:%5B%22ctr%22%5D,%22discrepancy_metrics%22:%5B%5D,%22cohort_metrics%22:%5B%5D,%22cohort_periods%22:%5B%227d%22%5D,%22filters%22:%5B%5D,%22time_breakdown%22:%5B%22all%22%5D%7D`}
                        target="_blank">
                        Creative CTR</a>
                    <span>&nbsp;</span>
                </li>
            </ul>
            <p>
                <strong>Tip: </strong><span> Singular automatically recognizes identical assets across media sources and campaigns. But if you have similar assets with tiny differences, you may want to turn on “Creative Clustering” to treat them as one. To go one step further, tag your assets to create groupings and compare their performance against each other (see </span><strong>Add
                Creative Tags</strong><span>).</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/115001862443-Creative-Reporting"
                   target="_blank">Learn more in the Creative Reporting Guide</a>
            </p>
        </div>
    );
}
