import { all, call, fork, put, take } from 'redux-saga/effects';
import { CHANGE_PASSWORD, changePasswordSucceeded, changePasswordFailed } from '../actions/user';
import { User } from '../services';

const UserAPI = new User();

function* handleSavePassword(action) {
    const { oldPassword, newPassword } = action;
    const response = yield call(UserAPI.savePassword, { oldPassword, newPassword });
    if (response.status !== 0) {
        yield put(changePasswordFailed(response.value));
    } else {
        yield put(changePasswordSucceeded());
    }
}

function* watchSavePassword() {
    while (true) {
        const action = yield take(CHANGE_PASSWORD);
        yield fork(handleSavePassword, action);
    }
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

function* watchUserActions() {
    yield all([fork(watchSavePassword)]);
}

export { watchUserActions };
