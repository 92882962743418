import React from 'react';

export default function DataConnectorsTaskContentBody() {
    return (
        <div>
            <p>
                <strong>What: </strong><span> Tailor your reporting to your needs by defining new dimensions.</span>
            </p>
            <p>
                <span>For example, create a “Channel” dimension where the possible values are "Social", "Search", "Display", and "Retargeting". Or create a “UA manager” dimension where the values are names of team members.</span>
            </p>
            <p>
                <strong>Why: </strong><span> This lets you accomplish a lot of complex analysis right inside the Singular platform – without having to export the data.</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/208578986" target="_blank">See the Custom Dimensions Guide</a><span
            >.</span>
            </p>
        </div>
    );
}
