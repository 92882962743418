import { useEffect, useMemo, useState } from 'react';
import { useFetchGetApps } from './useFetchGetApps';

const useFetchGetAppSites = () => {
    const [apps, setApps] = useState([]);
    const { apps: respApps = [], isLoading } = useFetchGetApps();

    useEffect(() => {
        if (respApps.length) {
            setApps(respApps);
        }
    }, [respApps]);

    const updatedSites = useMemo(() => {
        return apps.reduce((sites, app) => {
            const { sites: appSites, id } = app;
            const sitesWithAppId = appSites.map(site => ({ ...site, appId: id }));
            return [...sitesWithAppId, ...sites];
        }, []);
    }, [apps]);

    return { appSites: updatedSites, isLoading };
};

const useFetchAppSitesByPlatform = platform => {
    const { appSites, isLoading } = useFetchGetAppSites();
    return { appSites: appSites.filter(site => site.platform === platform), isLoading };
};

export { useFetchGetAppSites, useFetchAppSitesByPlatform };
