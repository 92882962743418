import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { EmptyState, Label, Menu, Shelf, Spinner } from '../components/widgets';
import EmptyStateForm from '../components/partials/EmptyState/EmptyState';
import TopArea from './components/TopArea';
import { GeneralPopup, PageWrapper, PopupTypes } from '../components/partials';
import WarningMessage from '../teamManagement/components/WizardWarningMessage';
import css from './page.css';
import ConversionModelShelf from './components/skan4/ConversionModelShelf';
import { sortApps } from './utils';
import {
    ModelsApiContext,
    useFetchAppModelSummary,
    useFetchApps,
    useFetchConfiguration,
    useFetchOrganizationMissingPartnersEvents,
} from './hooks';
import { getAdminModeEnabled, getUserCurrency } from '../selectors/user';
import { getEmptyStateOrgData } from '../selectors/onboarding';
import { getParsedConversionModelGroups, getParsedSdkEvents } from './conversionModelGroup/utils';
import ConversionModelGroupsList from './conversionModelGroup/components/ConversionModelGroupsList';
import { SKAN_3, SKAN_4, SKAN_MODELS_EMPTY_STATE_TASKS } from './consts';
import { EmptyStateImageSrc } from '../components/partials/EmptyState/utils';
import SkanAppsList from './components/SkanAppsList';
import { getApp, getSkanApps } from './appUtils';
import AppSummaryContent from './components/appSummaryContent/AppSummaryContent';
import TagsSection from './components/tagsSection/TagsSection';
import { STATUS_WARNING } from '../linkManagement/utils';

const doesAppHaveWarnings = (app, missingPartnersEvents) =>
    app.status === STATUS_WARNING || app.notification?.type === STATUS_WARNING || !!missingPartnersEvents[app.id];

function SKAdNetwork({ translate }) {
    const userCurrency = useSelector(getUserCurrency) || {};
    const isAdminMode = useSelector(getAdminModeEnabled);
    const emptyStateOrgData = useSelector(state => getEmptyStateOrgData(state));

    const [selectedAppId, setSelectedAppId] = useState();
    const [isFetchModelsNeeded, setIsFetchModelsNeeded] = useState(true);
    const [skanShelfOpen, setSkanShelfOpen] = useState(false);
    const [isSkan3Mode, setIsSkan3Mode] = useState(false);
    const [isUpgradeMode, setIsUpgradeMode] = useState(false);
    const [selectedModelGroupId, setSelectedModelGroupId] = useState(null);
    const [selectedModelGroup, setSelectedModelGroup] = useState(null);
    const [conversionModelGroups, setConversionModelGroups] = useState([]);
    const [conversionModelIdToName, setConversionModelIdToName] = useState({});
    const [expandOptimizedModelsGroup, setExpandOptimizedModelsGroup] = useState(false);
    const [notification, setNotification] = useState({});
    const [skanApps, setSkanApps] = useState([]);
    const [appsFilter, setAppsFilter] = useState('');

    const refetchModelsData = () => {
        setIsFetchModelsNeeded(true);
    };

    const onRefetchModelsCleanup = () => {
        setIsFetchModelsNeeded(false);
    };

    const { value = {}, isLoading: isLoadingModelsConfig } = useFetchConfiguration(
        selectedAppId,
        isFetchModelsNeeded,
        onRefetchModelsCleanup
    );

    const {
        configurations: configurationsPerApp = {},
        isLoading: isLoadingAppsConfigurations,
    } = useFetchAppModelSummary();

    const { apps = {}, isLoading: isLoadingApps } = useFetchApps();

    const missingPartnersEvents = useFetchOrganizationMissingPartnersEvents();

    const { sdkEvents: sdkEventsResult, conversionModelGroups: conversionModelGroupsResult, appConfig } = value;

    const doesAppExist = useMemo(() => !!skanApps.length || !!appsFilter, [skanApps, appsFilter]);

    const isLoading = useMemo(() => isLoadingAppsConfigurations !== false || isLoadingApps !== false, [
        isLoadingAppsConfigurations,
        isLoadingApps,
    ]);
    const isLoadingModels = useMemo(
        () =>
            (isLoadingModelsConfig !== false && selectedAppId?.toString() !== appConfig?.appId.toString()) ||
            !selectedAppId,
        [isLoadingModelsConfig, selectedAppId, appConfig]
    );

    const parsedSdkEvent = useMemo(() => {
        return getParsedSdkEvents(sdkEventsResult || {});
    }, [sdkEventsResult]);

    useEffect(() => {
        setConversionModelGroups([]);
    }, [selectedAppId]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const newSkanApps = getSkanApps(apps, configurationsPerApp, appsFilter);
        setSkanApps(newSkanApps);
    }, [apps, isLoading, appsFilter]);

    useEffect(() => {
        if (!selectedAppId) {
            return;
        }

        const newSkanApps = skanApps.map(app => {
            const { id, notification: appNotification } = app;

            if (id === selectedAppId) {
                setNotification(appNotification);
            }

            return { ...app, selected: id === selectedAppId };
        });

        setSkanApps(newSkanApps);
        refetchModelsData();
    }, [selectedAppId]);

    useEffect(() => {
        const groupMapping = conversionModelGroups.reduce(
            (mapping, currentGroup) => ({
                ...mapping,
                [currentGroup.id]: currentGroup.name,
            }),
            {}
        );
        setConversionModelIdToName(groupMapping);
    }, [conversionModelGroups]);

    useEffect(() => {
        const selectedGroup = selectedModelGroupId
            ? conversionModelGroups.find(({ id }) => id === selectedModelGroupId)
            : null;

        setSelectedModelGroup(selectedGroup);

        if (selectedGroup?.conversionModels.length === 1) {
            setIsSkan3Mode(true);
        }
    }, [conversionModelGroups, selectedModelGroupId]);

    useEffect(() => {
        const parsedConversionModelGroups = getParsedConversionModelGroups(
            conversionModelGroupsResult || [],
            parsedSdkEvent || {}
        );
        parsedConversionModelGroups.sort(group => (group.conversionModels.some(model => model.enabled) ? -1 : 1));
        setConversionModelGroups(parsedConversionModelGroups);
    }, [conversionModelGroupsResult, parsedSdkEvent]);

    useEffect(() => {
        setSelectedAppId(null);
    }, [appsFilter]);

    useEffect(() => {
        if (!appConfig || !selectedAppId) return;

        setSkanApps(
            skanApps.map(app => {
                const appId = app.id;
                const isSelectedApp = appId === selectedAppId;
                const relevantApp = isSelectedApp ? getApp(app, appConfig) : app;

                return { ...relevantApp, id: appId, selected: isSelectedApp };
            })
        );
    }, [appConfig]);

    const optimizedModels = useMemo(() => {
        return configurationsPerApp[selectedAppId]?.optimizedModels || [];
    }, [configurationsPerApp, selectedAppId]);

    const handleAddModelClickedSkan4 = () => {
        setSkanShelfOpen(true);
    };

    const handleAddModelClickedSkan3 = () => {
        setSkanShelfOpen(true);
        setIsSkan3Mode(true);
    };

    const handleCloseShelf = () => {
        setSkanShelfOpen(false);
        setIsSkan3Mode(false);
        setIsUpgradeMode(false);
        setSelectedModelGroupId(null);
        setExpandOptimizedModelsGroup(false);
    };

    const handleSaveClick = success => {
        if (success) {
            handleCloseShelf();
            refetchModelsData();
        }
    };

    const onModelGroupEditClick = (modelGroupId, isExpandOptimizedModelsGroup) => {
        if (!modelGroupId) return;

        setSelectedModelGroupId(modelGroupId);
        setExpandOptimizedModelsGroup(isExpandOptimizedModelsGroup);
        setSkanShelfOpen(true);
    };

    const handleUpgradeToSkan4Click = modelGroupId => {
        setSelectedModelGroupId(modelGroupId);
        setSkanShelfOpen(true);
        setIsUpgradeMode(true);
    };

    const appsWithContent = useMemo(
        () =>
            skanApps.map(currApp => ({
                ...currApp,
                content: <AppSummaryContent modelType={currApp.modelType} status={currApp.status} />,
                rightSection: (
                    <TagsSection
                        skanPlusStatus={currApp.skanPlusStatus}
                        hasWarning={currApp.modelType ? doesAppHaveWarnings(currApp, missingPartnersEvents) : false}
                    />
                ),
            })),
        [skanApps, missingPartnersEvents]
    );

    const selectedAppMissingEvents = useMemo(() => {
        if (!selectedAppId || !missingPartnersEvents) return [];
        return missingPartnersEvents[selectedAppId] || [];
    }, [missingPartnersEvents, selectedAppId]);

    if (isLoading) {
        return <Spinner show expanded />;
    }

    return (
        <PageWrapper className={css.container} allowDropDownPageScrolling disablePadding>
            <ModelsApiContext.Provider
                value={{
                    onModelGroupEditClick,
                    handleUpgradeToSkan4Click,
                    optimizedModels,
                    expandOptimizedModelsGroup,
                    isAdminMode,
                    appConfig,
                    selectedAppMissingEvents,
                }}
            >
                {doesAppExist && (
                    <div className={css.topArea}>
                        <TopArea
                            onInputChanged={setAppsFilter}
                            search={appsFilter}
                            filterPlaceholder="STATIC.PAGES.SKADNETWORK.APP_SEARCH_PLACEHOLDER"
                            style={{ width: '390px', marginLeft: '8px' }}
                        />
                    </div>
                )}
                <div className={css.mainArea}>
                    <SkanAppsList
                        skanApps={appsWithContent}
                        appsFilter={appsFilter}
                        onAppSelected={setSelectedAppId}
                        sortApps={sortApps}
                    />
                    <div className={css.rightArea}>
                        <WarningMessage show={!!notification?.message} duration={300} {...notification} />
                        <div className={css.rightAreaTop}>
                            <div className={css.mainText}>
                                <Label text="STATIC.PAGES.SKADNETWORK.CONVERSION_MODELS_HEADER" type="h2" />
                                <Label
                                    text="STATIC.PAGES.SKADNETWORK.CONVERSION_MODELS_SUB_HEADER"
                                    type="shelfHeader"
                                />
                            </div>
                            {doesAppExist && !isLoadingModels && (
                                <Menu
                                    buttonLabel="STATIC.PAGES.SKADNETWORK.ADD_MODEL"
                                    disabled={!selectedAppId}
                                    primary={false}
                                    items={[
                                        {
                                            value: SKAN_4,
                                            label: 'STATIC.PAGES.SKADNETWORK.ADD_MODEL_SKAN_4',
                                            onClick: handleAddModelClickedSkan4,
                                        },
                                        {
                                            value: SKAN_3,
                                            label: 'STATIC.PAGES.SKADNETWORK.ADD_MODEL_SKAN_3',
                                            onClick: handleAddModelClickedSkan3,
                                            tag: 'Legacy',
                                        },
                                    ]}
                                />
                            )}
                        </div>
                        <div className={css.rightAreaMain}>
                            {!doesAppExist ? (
                                <EmptyStateForm
                                    titleText="STATIC.PAGES.SKADNETWORK.EMPTY_STATE.HEADER"
                                    subTitleText="STATIC.PAGES.SKADNETWORK.EMPTY_STATE.SUB_HEADER"
                                    imageSrc={EmptyStateImageSrc.SINGULAR_DUDE}
                                    tasksTodo={SKAN_MODELS_EMPTY_STATE_TASKS}
                                    tasksDone={emptyStateOrgData?.tasks || {}}
                                />
                            ) : isLoadingModels ? (
                                <Spinner show style={{ marginTop: 120 }} />
                            ) : !conversionModelGroups.length ? (
                                <EmptyState
                                    style={{ marginTop: 120, textAlign: 'center' }}
                                    icon="happyPage"
                                    header="STATIC.PAGES.SKADNETWORK.EMPTY_STATE_HEADER"
                                    subHeader="STATIC.PAGES.SKADNETWORK.EMPTY_STATE_SUBHEADER"
                                />
                            ) : (
                                <ConversionModelGroupsList
                                    conversionModelGroups={conversionModelGroups}
                                    setConversionModelGroups={setConversionModelGroups}
                                    refetchModelsData={refetchModelsData}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Shelf
                    open={skanShelfOpen}
                    type="create"
                    headerText={
                        translate('STATIC.PAGES.SKADNETWORK.SHELF_HEADER') +
                        (selectedModelGroup ? ` - ${selectedModelGroup.name}` : '')
                    }
                    shelfSize="medium"
                    onClose={handleCloseShelf}
                    enterAnimationDisabled={false}
                >
                    <ConversionModelShelf
                        sdkEvents={parsedSdkEvent}
                        conversionModelGroup={selectedModelGroup}
                        appId={selectedAppId}
                        selectedAppId={selectedAppId}
                        optimizedModels={optimizedModels}
                        onCancel={handleCloseShelf}
                        onSave={handleSaveClick}
                        isAdminMode={isAdminMode}
                        currencySymbol={userCurrency.symbol}
                        isSkan3Mode={isSkan3Mode}
                        isUpgradeMode={isUpgradeMode}
                        conversionModelIdToName={conversionModelIdToName}
                    />
                </Shelf>
                <GeneralPopup
                    open={false}
                    type={PopupTypes.WARNING_WITH_CANCEL}
                    title="STATIC.PAGES.SKADNETWORK.TOGGLE_OFF_MODEL_MODAL.TITLE"
                    text="STATIC.PAGES.SKADNETWORK.TOGGLE_OFF_MODEL_MODAL.TEXT"
                    acceptText="STATIC.PAGES.SKADNETWORK.TOGGLE_OFF_MODEL_MODAL.ACCEPT_BUTTON_TEXT"
                    rejectText="STATIC.PAGES.SKADNETWORK.TOGGLE_OFF_MODEL_MODAL.REJECT_BUTTON_TEXT"
                    onAccept={() => {}}
                    onReject={() => {}}
                />
            </ModelsApiContext.Provider>
        </PageWrapper>
    );
}

SKAdNetwork.propTypes = {
    loading: PropTypes.bool,
    loadingConfigs: PropTypes.bool,
    translate: PropTypes.func,
};

SKAdNetwork.defaultProps = {
    loading: true,
    loadingConfigs: true,
    translate: () => {},
};

export default withLocalize(SKAdNetwork);
