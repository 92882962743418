import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { withHighcharts, HighchartsChart, ColumnSeries } from 'react-jsx-highcharts';
import { labelByMetric, colorByMetric, shareOfVoicePlotOptions, BENCHMARKS_GRAPH_TYPES } from '../consts';
import { networkLabelsFormatter, seriesFloatValueFormatter, mapSeriesValues, syncChartsScrolls } from '../utils';
import ChartTooltip from './chartTooltip';
import ChartLegend from './chartLegend';
import ChartXAxis from './chartXAxis';
import ChartYAxis from './chartYAxis';
import Chart from './chart';

let visibleLegendCount = 2;

const ShareOfVoiceChart = ({ chartData }) => {
    const { data, labels, logo_by_label: logoByLabel } = chartData;
    const [chartLoaded, setChartLoaded] = useState(false);

    return (
        <HighchartsChart
            plotOptions={shareOfVoicePlotOptions}
            callback={() => {
                visibleLegendCount = 2;

                setTimeout(() => {
                    setChartLoaded(true);
                    syncChartsScrolls(BENCHMARKS_GRAPH_TYPES.SHARE_OF_VOICE, BENCHMARKS_GRAPH_TYPES.YEAR_OVER_YEAR);
                }, 0);
            }}
        >
            <ChartLegend />
            <Chart minWidth={labels.length * 75} loaded={chartLoaded} marginBottom={100} />
            <ChartTooltip isTextHeader categories={labels} />
            <ChartXAxis
                id="share_of_voice_x_axis"
                categories={labels}
                labelsFormat={null}
                lineWidth={1}
                formatter={networkLabelsFormatter.bind(this, logoByLabel)}
            />
            <ChartYAxis id="share_of_voice_y_axis" endOnTick>
                {data.map(({ data: values, label }) => {
                    return (
                        <ColumnSeries
                            formatter={seriesFloatValueFormatter}
                            key={label}
                            name={labelByMetric[label]}
                            data={mapSeriesValues(values)}
                            color={colorByMetric[label]}
                            onLegendItemClick={({ target }) => {
                                if (visibleLegendCount === 1 && target.visible) {
                                    return false;
                                } else {
                                    visibleLegendCount = target.visible
                                        ? visibleLegendCount - 1
                                        : visibleLegendCount + 1;
                                }

                                return true;
                            }}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

ShareOfVoiceChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    onLoad: PropTypes.func,
};

ShareOfVoiceChart.defaultProps = {
    chartData: {},
    onLoad: undefined,
};

export default withHighcharts(ShareOfVoiceChart, Highcharts);
