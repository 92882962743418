import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './ConversionModelFormTitle.css';

function ConversionModelFormTitle({ displayName, description }) {
    return (
        <div className={css.modelDescriptionBox}>
            <div className={css.modelDescriptionHeader}>
                <div className={css.typeDescriptionHeader}>{displayName}:</div>
            </div>
            {!description ? null : (
                <div className={css.typeDescription}>
                    <Translate id={description} />
                </div>
            )}
        </div>
    );
}

ConversionModelFormTitle.propTypes = {
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string,
};

ConversionModelFormTitle.defaultProps = {
    description: null,
};

export default ConversionModelFormTitle;
