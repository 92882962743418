export const UPDATE_DATA_SOURCE_DATA = 'action.teammanagement.datasources.update';
export const TEAMMANAGEMENT_PAGE_LOADED = 'action.teammanagement.page.loaded';
export const ORG_USERS_LOADED = 'action.teammanagement.org.users.loaded';
export const ORG_AGENCIES_LOADED = 'action.teammanagement.org.agencies.loaded';
export const DELETE_MEMBER_CLICKED = 'action.teammanagement.delete.member.clicked';
export const DELETE_USER_INVITE_REVOKED = 'action.teammanagement.delete.user.invite.revoked';
export const DELETE_USER_DISABLED = 'action.teammanagement.delete.user.disabled';
export const SEND_INVITATION = 'action.teammanagement.send.invitation';
export const RESEND_INVITATION = 'action.teammanagement.resend.invitation';
export const MANAGE_PERMISSIONS_CALLED = 'action.teammanagement.manage.permissions.called';
export const MANAGE_PERMISSIONS = 'action.teammanagement.manage.permissions';
export const ADD_MESSAGE_TO_GROUP = 'action.teammanagement.add.message.to.group';
export const CLEAN_MESSAGES = 'action.teammanagement.clean.messages';
export const CREATE_NEW_MEMBER = 'action.teammanagement.create.new.member';
export const WIZARD_FINISHED = 'action.teammanagement.wizard.finished';
export const WIZARD_ACTION_TYPE_CHANGED = 'action.teammanagement.wizard.actiontype.changed';
export const CLOSE_SHELF = 'action.teammanagement.close.shelf';
export const CLOSE_SHELF_CALLED = 'action.teammanagement.close.shelf.called';
export const UPDATE_USER_REGISTRATION_FORM = 'action.teammanagement.update.user.registration.form';
export const UPDATE_AGENCY_SELECTION_FORM = 'action.teammanagement.update.agency.selection.form';
export const UPDATE_ROLE_SELECTION_FORM = 'action.teammanagement.update.role.selection.form';
export const UPDATE_FEATURES_LIST = 'action.teammanagement.update.features.list.form';
export const SAVE_MEMBER_ERROR = 'action.teammanagement.save.member.error';
export const SAVE_MEMBER_SUCCESS = 'action.teammanagement.save.member.success';
export const MODIFY_INVITATION_STATE = 'action.teammanagement.modify.invitation.state';
export const APP_SELECTION_CHANGE = 'action.teammanagement.appselection.select';
export const DATA_SOURCE_SELECTION_CHANGE = 'action.teammanagement.dsselection.select';
export const DATA_SOURCE_SELECTION_CHANGE_ALL = 'action.teammanagement.dsselection.change.all';
export const DIMENSION_FILTER_SELECTION_CHANGE = 'action.teammanagement.dimension_filter.set';
export const METRIC_SELECTION_CHANGE = 'action.teammanagement.metricselection.select';
export const SCREENS_SELECTION_TOGGLE = 'action.teammanagement.screens.selection.toggle';
export const ADD_FUTURE_CHANGED = 'action.teammanagement.add.future.changed';
export const ACTIVE_MEMBER_TYPE_CHANGED = 'action.teammanagement.update.active_member_type';

export const teamManagementLoaded = data => ({
    type: TEAMMANAGEMENT_PAGE_LOADED,
    data,
});

export const getOrgUsers = orgUsers => ({
    type: ORG_USERS_LOADED,
    orgUsers,
});

export const getOrgAgencies = orgAgencies => ({
    type: ORG_AGENCIES_LOADED,
    orgAgencies,
});

export const deleteMemberClicked = member => ({
    type: DELETE_MEMBER_CLICKED,
    member,
});

export const userInviteRevoked = user => ({
    type: DELETE_USER_INVITE_REVOKED,
    user,
});

export const userDisabled = user => ({
    type: DELETE_USER_DISABLED,
    user,
});

export const sendInvitation = user => ({
    type: SEND_INVITATION,
    user,
});

export const resendInvitation = user => ({
    type: RESEND_INVITATION,
    user,
});

export const modifyInvitationState = (user, userState) => {
    return {
        type: MODIFY_INVITATION_STATE,
        user,
        userState,
    };
};

export const managePermissions = (user, partialState) => ({
    type: MANAGE_PERMISSIONS,
    user,
    partialState,
});

export const managePermissionsCalled = user => ({
    type: MANAGE_PERMISSIONS_CALLED,
    user,
});

export const cleanMessages = () => ({
    type: CLEAN_MESSAGES,
});

export const addMessageToGroup = (group, message, messageType) => ({
    type: ADD_MESSAGE_TO_GROUP,
    group,
    message,
    messageType,
});

export const createNewMember = () => ({
    type: CREATE_NEW_MEMBER,
});

export const closeShelf = () => ({
    type: CLOSE_SHELF,
});

export const closeShelfCalled = closeType => ({
    type: CLOSE_SHELF_CALLED,
    closeType,
});

export const updateUserRegistrationForm = fields => ({
    type: UPDATE_USER_REGISTRATION_FORM,
    fields,
});

export const updateAgencySelectionForm = agency => ({
    type: UPDATE_AGENCY_SELECTION_FORM,
    agency,
});

export const updateRoleSelectionForm = role => ({
    type: UPDATE_ROLE_SELECTION_FORM,
    role,
});

export const wizardFinished = recaptchaToken => ({
    type: WIZARD_FINISHED,
    recaptchaToken,
});

export const saveMemberError = error => ({
    type: SAVE_MEMBER_ERROR,
    error,
});

export const saveMemberSuccess = () => ({
    type: SAVE_MEMBER_SUCCESS,
});

export const updateDataSourceData = data => ({
    type: UPDATE_DATA_SOURCE_DATA,
    data,
});

export const changeAppSelection = (appIDs, value) => ({
    type: APP_SELECTION_CHANGE,
    appIDs,
    value,
});

export const changeDataSourceSelection = (name, value) => ({
    type: DATA_SOURCE_SELECTION_CHANGE,
    name,
    value,
});

export const changeAllDataSourceSelection = (allSources, value) => ({
    type: DATA_SOURCE_SELECTION_CHANGE_ALL,
    allSources,
    value,
});

export const setDimensionFilter = (name, values) => ({
    type: DIMENSION_FILTER_SELECTION_CHANGE,
    name,
    values,
});

export const changeMetricSelection = (metricID, value) => ({
    type: METRIC_SELECTION_CHANGE,
    metricID,
    value,
});

export const wizardActionTypeChanged = actionType => ({
    type: WIZARD_ACTION_TYPE_CHANGED,
    actionType,
});

export const addFutureChanged = (form, dimensionFilter, value) => ({
    type: ADD_FUTURE_CHANGED,
    form,
    dimensionFilter,
    value,
});

export const toggleScreens = (screenIds, value) => ({
    type: SCREENS_SELECTION_TOGGLE,
    screenIds,
    value,
});

export const updateFeaturesList = featuresList => ({
    type: UPDATE_FEATURES_LIST,
    featuresList,
});

export const activeMemberTypeChange = activeMemberType => ({
    type: ACTIVE_MEMBER_TYPE_CHANGED,
    activeMemberType,
});
