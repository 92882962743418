import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik-2';
import { Spinner } from '../../../components/widgets';
import css from './WizardContainer.css';
import { getPrevStepIndex } from '../../../utils/wizard';
import WizardEditor from './WizardEditor';
import { TEST_WIZARD_STEPS, TEST_WIZARD_STEPS_NESTED } from './consts';

import { useFetchDistributionData } from '../../hooks';

const WizardContainer = ({ onClose }) => {
    const { submitForm, setFieldValue } = useFormikContext();
    const { isLoading, data: distributionData } = useFetchDistributionData();
    const [activeStepIndex, setActiveStepIndex] = useState(0);

    const onNextClick = useCallback(() => {
        setActiveStepIndex(currentStep => {
            if (currentStep === TEST_WIZARD_STEPS.length - 1) return currentStep;
            return Math.min(currentStep + 1, TEST_WIZARD_STEPS.length);
        });
    }, []);

    const onPrevClick = useCallback(() => {
        setActiveStepIndex(currentStep => getPrevStepIndex(currentStep));
    }, []);

    useEffect(() => {
        if (activeStepIndex >= TEST_WIZARD_STEPS.length) {
            submitForm().then();
        }
    }, [activeStepIndex, submitForm]);

    const onStepClick = useCallback(stepKey => {
        setActiveStepIndex(() => {
            const newStep = TEST_WIZARD_STEPS.indexOf(stepKey);
            return newStep < 0 ? TEST_WIZARD_STEPS_NESTED[stepKey] : newStep;
        });
    }, []);

    const onDraftClick = useCallback(() => {
        setFieldValue('isDraft', true).then();
        submitForm().then();
    }, []);

    return (
        <>
            {isLoading ? (
                <Spinner show expanded />
            ) : (
                <div className={css.content}>
                    <div className={css.contentContainer}>
                        <WizardEditor
                            distributionsData={distributionData}
                            activeStepIndex={activeStepIndex}
                            onPrevClick={onPrevClick}
                            onStepClick={onStepClick}
                            onClose={onClose}
                            onNextClick={onNextClick}
                            onDraftClick={onDraftClick}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

WizardContainer.propTypes = {
    onClose: PropTypes.func.isRequired,
};

WizardContainer.defaultProps = {};

export default WizardContainer;
