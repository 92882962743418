import React, { Fragment } from 'react';
import { withLocalize } from 'react-localize-redux';
import { WhitePageHeader } from '../components/partials';
import css from './page.css';

const CreativeExplore = () => {
    return (
        <Fragment>
            <div className={css.pageHeaderWrapper}>
                <WhitePageHeader
                    text="STATIC.PAGES.CREATIVE_EXPLORE.PAGE_NAME"
                    className={css.pageHeader}
                    style={{ paddingLeft: '40px', height: '58px' }}
                    textStyle={{ fontWeight: '600', fontSize: '24px' }}
                />
            </div>
        </Fragment>
    );
};

CreativeExplore.propTypes = {};

CreativeExplore.defaultProps = {};

export default withLocalize(CreativeExplore);
