import { gql } from '@apollo/client';

export const FIELD_FRAGMENT = gql`
    fragment Field on FieldNode {
        guid
        name
        kind
        values {
            code
            value
        }
        default {
            code
            value
        }
        optional
        hidden
    }
`;

export const SCHEMA_FRAGMENT = gql`
    fragment Schema on SchemaNode {
        id
        name
        type
        pattern
        fields {
            ...Field
        }
    }
    ${FIELD_FRAGMENT}
`;

export const READ_SCHEMAS = gql`
    {
        schemas {
            ...Schema
        }
    }
    ${SCHEMA_FRAGMENT}
`;

export const CODE_FRAGMENT = gql`
    fragment Code on CodeNode {
        id
        name
        governanceId
        fields
        creatorEmail
        created
    }
`;

export const READ_CODES = gql`
    query codes($schemaId: SpecialID) {
        codes(schemaId: $schemaId) {
            ...Code
        }
    }
    ${CODE_FRAGMENT}
`;

export const CREATE_SCHEMA = gql`
    mutation createSchema($name: String!, $pattern: String!, $fields: [FieldNodeInput!], $type: String!) {
        createSchema(name: $name, pattern: $pattern, fields: $fields, type: $type) {
            ...Schema
        }
    }
    ${SCHEMA_FRAGMENT}
`;

export const UPDATE_SCHEMA = gql`
    mutation updateSchema($id: SpecialID!, $name: String!, $pattern: String!, $fields: [FieldNodeInput!]) {
        updateSchema(id: $id, name: $name, pattern: $pattern, fields: $fields) {
            ...Schema
        }
    }
    ${SCHEMA_FRAGMENT}
`;

export const DELETE_SCHEMA = gql`
    mutation deleteSchema($id: SpecialID!) {
        deleteSchema(id: $id) {
            ok
        }
    }
`;

export const GENERATE_CODE = gql`
    mutation generateCode($schemaId: SpecialID!, $fields: [CodeFieldInput!]) {
        generateCode(schemaId: $schemaId, fields: $fields) {
            ...Code
        }
    }
    ${CODE_FRAGMENT}
`;

export const UPDATE_CODES = gql`
    mutation updateCodes($codes: [CodeUpdateInput!]) {
        updateCodes(codes: $codes) {
            ...Code
        }
    }
    ${CODE_FRAGMENT}
`;
