import { call, put, takeLatest, select, all, delay } from 'redux-saga/effects';
import {
    updateIsETL,
    updateUans,
    update2PhaseKeys,
    updateAdNetworks,
    updateSingleUan,
    deleteSingleUan,
    updateUanAction,
    updateUanData,
    closeShelf,
    apiRequestFailed,
    uanSaveSucceeded,
    uanSaveDisplayError,
    setUpdatedUan,
    setAuthPopupUrl,
    updateUanAccounts,
    updateSendScrapeJobStatus,
    EXPORT_2PHASE,
    UAN_LOGIN_VERIFICATION_FINISHED,
    OPEN_SHELF,
    SAVE_UAN_DATA,
    SAVE_UAN_DATA_WITH_ACCOUNTS,
    SAVE_ADWORDS_MCC_DATA,
    SAVE_ADWORDS_DATA,
    DELETE_UAN,
    UAN_AUTH_CLICK,
    VALIDATE_UAN_LOGIN,
    GET_UAN_ACCOUNTS,
    SEND_SCRAPE_JOB,
    DELETE_USER_SLACK_AUTH,
    updatePartnerIds,
    redirectToPartnerConfiguration,
    updateUanDescription,
} from './actions';

import { updateAppsData } from '../actions/apps';
import { setIsUserSlackAuth } from '../actions/user';
import { getIsETL } from './selector';
import getCurrentPath from '../selectors/routing';
import { parseSearchParams } from '../utils/url';
import { setIsLoading } from '../appLoadingState/actions';
import DataSourcesService from './service';
import AppsService from '../services/apps';
import {
    MODAL_ACTION_TYPE_LOGIN_VERIFICATION_FINISHED,
    MODAL_ACTION_TYPE_DELETE,
    DATA_SOURCES_STATUSES,
    MANUAL_SCRAPE_SUBSTATUS,
} from './utils';
import { getTranslate } from '../selectors/locale';
import { updateAllFields } from '../sagas/fields';
import { DATA_CONNECTORS_EVENT_PREFIX, ETL_EVENT_PREFIX, trackMixpanelEvent } from '../utils/general';

const appsApi = new AppsService();
const dataSourcesService = new DataSourcesService();

export function* updatePageDataPeriodically() {
    const currentPath = yield select(getCurrentPath);
    const { pathname } = currentPath;
    const isETL = pathname === '/react/data-destinations';
    let getUansCall;
    if (isETL) {
        getUansCall = call(dataSourcesService.getEtlUanStates);
    } else {
        getUansCall = call(dataSourcesService.getOrgUans, null, true, false);
    }

    const [dataResp, partners] = yield all([getUansCall, call(dataSourcesService.getConfiguredSources)]);

    if (partners) {
        const partnerIds = new Set(
            partners.adnetworks.filter(partner => !partner.is_configured).map(partner => partner.id)
        );
        yield put(updatePartnerIds(partnerIds));
    }

    yield put(updateUans(dataResp));
}

export function* updatePageData(LoginVerificationFinishedAction) {
    const currentPath = yield select(getCurrentPath);
    const { search, pathname } = currentPath;
    const isETL = pathname === '/react/data-destinations';
    const isPreviousETL = yield select(getIsETL);
    yield put(updateIsETL(isETL));
    let { uan_id: uanId, modal_action: modalAction } = parseSearchParams(search);

    if (LoginVerificationFinishedAction) {
        uanId = LoginVerificationFinishedAction.uanId;
        modalAction = MODAL_ACTION_TYPE_LOGIN_VERIFICATION_FINISHED;
    }

    let uanUpdated;

    if (isPreviousETL !== isETL) {
        yield put(setIsLoading(true));

        let uanListResp, uanStateListResp, adNetworksResponse, uanList, archivedUans;
        if (isETL) {
            [uanListResp, adNetworksResponse] = yield all([
                call(dataSourcesService.getEtlUanStates),
                call(dataSourcesService.getAdNetworks, isETL),
            ]);
            uanList = uanListResp;
        } else {
            [uanListResp, uanStateListResp, adNetworksResponse] = yield all([
                call(dataSourcesService.getUanList, null, false),
                call(dataSourcesService.getOrgUans, null, false, true),
                call(dataSourcesService.getAdNetworks, isETL),
            ]);
            const nonArchivedUanIds = uanStateListResp.uans.map(uan => uan.uan_id);
            archivedUans = uanListResp.uans.filter(uan => !nonArchivedUanIds.includes(uan.uan_id));
            uanList = { uans: [...uanStateListResp.uans, ...archivedUans] };
        }
        let { uans } = uanList;

        yield put(updateUans(uanList, !isETL));
        yield put(updateAdNetworks(adNetworksResponse));
        yield put(setIsLoading(false));

        let fullUans = null;

        if (!isETL) {
            const archivedUanIds = archivedUans.map(uan => uan.uan_id);
            if (archivedUanIds && archivedUanIds.length > 0) {
                const uanStateListRespArchived = yield call(dataSourcesService.getOrgUans, archivedUanIds, false);
                const fullDataResp = {
                    uans: [...uanStateListResp.uans, ...uanStateListRespArchived.uans],
                };
                yield put(updateUans(fullDataResp));
                fullUans = fullDataResp.uans;
            }
        }

        uans = uans || fullUans;

        const appsResp = yield call(appsApi.getApps);
        yield put(updateAppsData(appsResp));

        const hasSingularTracker = !!uans.find(uan => uan.display_name === 'Singular Tracker');
        const pageUpdateMixpanelParams = {
            has_data_delays: Boolean(uans.find(uan => uan.is_data_delay === true)),
            has_validation_error: Boolean(
                uans.find(uan => uan.report_validation_error && Object.keys(uan.report_validation_error).length > 0)
            ),
            singular_tracker_exists: hasSingularTracker,
            has_singular_tracker_delay:
                hasSingularTracker &&
                uans.find(uan => uan.display_name === 'Singular Tracker').status === DATA_SOURCES_STATUSES.DATA_DELAY,
        };

        trackMixpanelEvent(
            isETL ? ETL_EVENT_PREFIX : DATA_CONNECTORS_EVENT_PREFIX,
            'Page Updated',
            pageUpdateMixpanelParams,
            true
        );

        if (modalAction !== MODAL_ACTION_TYPE_DELETE && uanId) {
            if (uans.length > 0) {
                yield put(updateSingleUan(uans[0]));

                uanUpdated = uans.find(uan => uan.uan_id.toString() === uanId.toString());
            }
        }
    } else if (modalAction !== MODAL_ACTION_TYPE_DELETE && uanId) {
        const dataResp = yield call(dataSourcesService.getOrgUans, [uanId]);
        const { uans } = dataResp;

        if (uans.length > 0) {
            yield put(updateSingleUan(uans[0]));

            uanUpdated = uans.find(uan => uan.uan_id.toString() === uanId.toString());
        }
    } else if (modalAction === MODAL_ACTION_TYPE_DELETE && uanId) {
        const dataResp = yield call(dataSourcesService.getOrgUans, [uanId]);
        const { uans } = dataResp;

        uanUpdated = uans.find(uan => uan.uan_id.toString() === uanId);

        yield put(deleteSingleUan(uanId));
    }

    yield put(updateUanAction({ type: modalAction, uan: uanUpdated }));
    yield delay(8000);
    yield put(updateUanAction({ type: undefined, uan: undefined }));
}

export function* runExport2Phase({ uanId }) {
    const dataResp = yield call(dataSourcesService.export2Phase, uanId);
    yield put(update2PhaseKeys({ taskId: dataResp.task_id, uanId }));
}

export function* onload() {
    yield call(updatePageData);
    yield call(updateAllFields);
    yield delay(1000 * 60 * 2);
    while (true) {
        yield call(updatePageDataPeriodically);
        yield delay(1000 * 60 * 5);
    }
}

export function* handleError(error, uanId = null) {
    const singularSideErrorIds = [1, 2, 3, 4, 5];

    if (singularSideErrorIds.includes(error.substatus) || !error.substatus) {
        yield put(apiRequestFailed());
        yield put(closeShelf());
    } else {
        yield put(uanSaveDisplayError(error, null, uanId));
    }

    console.log(error);
}

export function* getUanAccounts({ uanId, disableSaveAccounts }) {
    try {
        const uanAccountsResponse = yield call(dataSourcesService.getAccounts, uanId, disableSaveAccounts);
        yield put(updateUanAccounts(uanAccountsResponse.value));
    } catch (e) {
        yield put(apiRequestFailed());
        console.log(e);
    }
}

export function* updateScrapeJobSentStatus(status) {
    // use put so that call is completed before delay() is called, which causes result of this method (e.g. show warning message) to be delayed as well
    yield put(updateSendScrapeJobStatus(status));
}

export function* sendScrapeJob({ uanData }) {
    try {
        const resp = yield call(dataSourcesService.sendScrapeJob, uanData.uan_id);
        if (resp.status === 0) {
            if (resp.substatus === MANUAL_SCRAPE_SUBSTATUS.SUCCESS) {
                yield put(
                    updateSingleUan({
                        ...uanData,
                        messages: [{ text: 'PULLING_NEW_DATA', translate_needed: true }],
                        status: DATA_SOURCES_STATUSES.PENDING,
                        report_validation_error: {},
                    })
                );
            }
            yield call(updateScrapeJobSentStatus, { subStatus: resp.substatus, value: resp.value });

            if (resp.substatus === MANUAL_SCRAPE_SUBSTATUS.SUCCESS) {
                // close shelf (after delay) and highlight uan in table for 8 seconds
                yield delay(5000);
                yield put(closeShelf());
                yield put(setUpdatedUan(uanData.uan_id));
                yield delay(8000);
                yield put(setUpdatedUan(null));
            }
            window.mixpanel.track('validator_manual_rescrape_clicked', {
                ...uanData,
                sent_success: Boolean(resp.substatus === MANUAL_SCRAPE_SUBSTATUS.SUCCESS),
                time_limit_exceeded: Boolean(resp.substatus === MANUAL_SCRAPE_SUBSTATUS.TIME_LIMIT_EXCEEDED),
            });
        }
    } catch (e) {
        yield put(apiRequestFailed());
        console.log(e);
    }
}

export function* getUanData({ uanId }) {
    if (!uanId) {
        return;
    }

    try {
        const dataResp = yield call(dataSourcesService.getUanObject, uanId);
        yield put(updateUanData(dataResp));
        const uanData = dataResp.uan_data;

        if (uanData.name === 'adwords' && !uanData.client_id) {
            return;
        }

        if (uanData.supports_accounts_selection === true) {
            yield call(getUanAccounts, { uanId, disableSaveAccounts: true });
        }
    } catch (error) {
        yield put(apiRequestFailed());
    }
}

export function* validateSavedUan({ uanId, partnerSourceId }) {
    try {
        const dataResp = yield call(dataSourcesService.validateUan, uanId);
        const uanState = dataResp.uans[0];
        yield put(updateSingleUan(dataResp.uans[0]));
        // highlight new uan
        yield put(setUpdatedUan(uanState.uan_id));

        if (uanState.is_login_error) {
            const translate = yield select(getTranslate);
            const errorMessage = translate('STATIC.PAGES.DATA_SOURCES.SHELF.TOP_MESSAGES.LOGIN_ERROR');
            yield put(uanSaveDisplayError({ value: errorMessage }, uanState.display_name, uanState.uan_id));
        } else {
            yield put(uanSaveSucceeded(uanState.display_name, uanState.uan_id));

            if (partnerSourceId) {
                yield put(redirectToPartnerConfiguration(partnerSourceId));
                yield put(redirectToPartnerConfiguration(null));
                return;
            }

            yield delay(2000);
            yield put(closeShelf());
        }
        yield delay(8000);
        // hide uan highlight
        yield put(setUpdatedUan(null));
    } catch (error) {
        yield call(handleError, error, uanId);
    }
}

export function* saveUanData({ uanData, partnerSourceId }) {
    try {
        const savedResp = yield call(dataSourcesService.saveUanData, uanData);
        if (savedResp?.prevent_login) {
            const uanState = Object.values(savedResp)[0];
            yield put(updateUanDescription(uanState.uan_id, uanState.description));
            yield put(uanSaveSucceeded(uanState.display_name, uanState.uan_id));
            yield put(closeShelf());
        } else {
            const savedUanId = Object.keys(savedResp)[0];
            yield call(validateSavedUan, { uanId: savedUanId, partnerSourceId });
        }
    } catch (error) {
        yield call(handleError, error);
    }
}

export function* saveUanWithAccounts({ uanData, uanId, partnerSourceId }) {
    try {
        const savedResp = yield call(dataSourcesService.saveUanWithAccounts, uanData, uanId);
        const savedUanId = Object.keys(savedResp.value)[0];
        yield call(validateSavedUan, { uanId: savedUanId, partnerSourceId });
    } catch (error) {
        yield put(apiRequestFailed());
        yield put(closeShelf());
        console.log(error);
    }
}

export function* saveAdwordsMccData({ uanId, mccClientId }) {
    try {
        yield call(dataSourcesService.saveAdwordsData, mccClientId, uanId);
    } catch (error) {
        yield call(handleError, error);
    }
}

export function* saveAdwordsData({ uanData, uanId, mccClientId, partnerSourceId }) {
    try {
        yield call(dataSourcesService.saveAdwordsData, mccClientId, uanId, uanData.description);
        const savedResp = yield call(dataSourcesService.saveUanWithAccounts, uanData, uanId);
        const savedUanId = Object.keys(savedResp.value)[0];
        yield call(validateSavedUan, { uanId: savedUanId, partnerSourceId });
    } catch (error) {
        yield call(handleError, error);
    }
}

export function* deleteUan({ uanId }) {
    try {
        yield call(dataSourcesService.deleteUan, uanId);
        yield put(deleteSingleUan(uanId));
    } catch (error) {
        yield put(apiRequestFailed());
    }
}

export function* getNetworkAuthUrl({ url, adnId, uanId }) {
    try {
        const state = window.btoa(JSON.stringify([adnId, uanId]));
        const response = yield call(dataSourcesService.getNetworkAuthUrl, url, state);
        yield put(setAuthPopupUrl(response.value.url));
    } catch (error) {
        yield put(apiRequestFailed());
    }
}

export function* deleteUserSlackAuth() {
    try {
        yield put(setIsUserSlackAuth(false));
        yield call(dataSourcesService.deleteUserSlackAuth);
    } catch (error) {
        yield put(setIsUserSlackAuth(true));
        yield put(apiRequestFailed());
    }
}

export function* watchExport2Phase() {
    yield takeLatest(EXPORT_2PHASE, runExport2Phase);
}

export function* watchUanLoginVerificationFinished() {
    yield takeLatest(UAN_LOGIN_VERIFICATION_FINISHED, updatePageData);
}

export function* watchEditUan() {
    yield takeLatest(OPEN_SHELF, getUanData);
}

export function* watchSaveUan() {
    yield takeLatest(SAVE_UAN_DATA, saveUanData);
}

export function* watchSaveUanWithAccounts() {
    yield takeLatest(SAVE_UAN_DATA_WITH_ACCOUNTS, saveUanWithAccounts);
}

export function* watchSaveMccAdwords() {
    yield takeLatest(SAVE_ADWORDS_MCC_DATA, saveAdwordsMccData);
}

export function* watchSaveAdwords() {
    yield takeLatest(SAVE_ADWORDS_DATA, saveAdwordsData);
}

export function* watchDeleteUan() {
    yield takeLatest(DELETE_UAN, deleteUan);
}

export function* watchAuthUan() {
    yield takeLatest(UAN_AUTH_CLICK, getNetworkAuthUrl);
}

export function* watchValidateLogin() {
    yield takeLatest(VALIDATE_UAN_LOGIN, validateSavedUan);
}

export function* watchGetUanAccounts() {
    yield takeLatest(GET_UAN_ACCOUNTS, getUanAccounts);
}

export function* watchSendScrapeJob() {
    yield takeLatest(SEND_SCRAPE_JOB, sendScrapeJob);
}

export function* watchDeleteUserSlackAuth() {
    yield takeLatest(DELETE_USER_SLACK_AUTH, deleteUserSlackAuth);
}

export const watchers = [
    watchExport2Phase,
    watchUanLoginVerificationFinished,
    watchEditUan,
    watchSaveUan,
    watchSaveUanWithAccounts,
    watchSaveMccAdwords,
    watchSaveAdwords,
    watchDeleteUan,
    watchAuthUan,
    watchValidateLogin,
    watchGetUanAccounts,
    watchSendScrapeJob,
    watchDeleteUserSlackAuth,
];
