import React, { memo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Provider, useStore } from 'react-redux';
import css from './Grid.css';
import SelectedChecboxIcon from '../../resources/svg/checkbox_selected.svg';
import UnselectedChecboxIcon from '../../resources/svg/checkbox_unselected.svg';
import FreezeColumnIcon from '../../resources/svg/columnFreezeIcon.svg';
import PinColumnIcon from '../../resources/svg/columnPinIcon.svg';
import ResetColumnsIcon from '../../resources/svg/columnsResetIcon.svg';
import TotalsCell from '../widgets/TotalsCell';
import GridCustomHeader from '../widgets/GridCustomHeader';
import GridService from '../../services/grid';
import { autoGroupColumnDef } from '../../utils/grids';
import GridGeneralCell from '../../containers/widgets/GridGeneralCell';
import { getConfigForType } from '../../selectors/reports';
import GridCustomColumnsToolPanel from '../widgets/GridCustomColumnsToolPanel';

const COLUMN_TYPES = {
    METRIC: 'metric',
    DIMENSION: 'dimension',
};

const menuIconsStyle = {
    width: '18px',
    height: '100%',
};

// Wrap a component in a provider with a store prop for the two injected custom cells to the grid.
function ProviderWrapper(store, component, additionalProps = {}) {
    return props => {
        return <Provider store={store}>{React.createElement(component, { ...props, ...additionalProps })}</Provider>;
    };
}

class Grid extends React.Component {
    constructor(props) {
        super(props);

        const { onTableFilterChanged, pivotMode, columnDefs, store } = this.props;
        this.isScrolling = false;
        this.state = {
            rows: null,
            visible: false,
            isInitialState: true,
            filterModel: {},
        };

        this.debouncedOnTableFilterChange = debounce((...args) => onTableFilterChanged(...args), 1000);

        this.gridColumnDefs = pivotMode
            ? columnDefs
            : columnDefs.map(cell => ({
                  ...cell,
                  cellRendererFramework: ProviderWrapper(store, GridGeneralCell, { reportType: props.reportType }),
              }));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { columnDefs, id } = this.props;
        if (columnDefs.length && !nextProps.columnDefs.length) {
            this.gridService.destroy(id);
        }
    }

    componentDidUpdate(prevProps) {
        const { filterModel } = this.state;
        const { pivotMode, pivotTable } = this.props;

        if (
            this.gridApi &&
            Object.keys(this.gridApi.getFilterModel()).length === 0 &&
            Object.keys(filterModel).length > 0
        ) {
            try {
                this.gridApi.setFilterModel(filterModel);
            } catch (e) {
                console.error('Got invalid column filter');
            }
        }

        if (prevProps.pivotMode !== pivotMode || (pivotMode && !_.isEqual(prevProps.pivotTable, pivotTable))) {
            this.applyPivotTableSettings();
        }
    }

    componentWillUnmount() {
        const { id, disableScrollingHooks } = this.props;

        if (this.gridService) {
            this.gridService.destroy(id);
        }
        if (!disableScrollingHooks) {
            if (this._agGridFloatingMenu) {
                this._agGridFloatingMenu.removeEventListener('wheel', this._scroller);
            }
            if (this._agGridHeader) {
                this._agGridHeader.removeEventListener('wheel', this._scroller);
            }

            window.document.removeEventListener('wheel', this._pageScroller);
        }
    }

    freezeColumns = params => {
        const gridColumns = params.columnApi.getAllGridColumns();
        const columnToFreeze = this._getRealColumn(params.column).colDef.field;
        gridColumns.some(gridColumn => {
            if (gridColumn.pinned !== 'right') {
                params.columnApi.setColumnPinned(gridColumn.colId, 'left');
            }
            return gridColumn.colId === columnToFreeze;
        });
    };

    pinMetricColumns = (params, direction) => {
        const gridColumns = params.columnApi.getAllGridColumns();
        const columnToPinDef = params.column.colDef;

        if (direction === 'left') {
            gridColumns.forEach(gridColumn => {
                if (gridColumn.colDef.type === COLUMN_TYPES.DIMENSION) {
                    params.columnApi.setColumnPinned(gridColumn.colDef.field, direction);
                }
            });
        }
        params.columnApi.setColumnPinned(columnToPinDef.field, direction);
    };

    _scroller = e => {
        const { isPageBottom } = this.state;
        const { deltaX, deltaY } = e;
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            this._agGridBody.scrollLeft += e.deltaX;
        } else if (isPageBottom) {
            this._agGridBody.scrollTop += e.deltaY;
        }
    };

    _pageScroller = e => {
        this._hideGridPopovers();
        // When zooming the page, the LHS of the equation will be float, while the RHS will be rounded.
        // The const here is to give an approximation when the page bottom has been reached
        const ZOOM_RENDER_OFFSET_ROUNDING_ERROR = 2;
        if (e.deltaY > 0) {
            this.setState({
                isPageBottom:
                    window.innerHeight + window.scrollY + ZOOM_RENDER_OFFSET_ROUNDING_ERROR >=
                    document.body.offsetHeight,
            });
        }
    };

    onScrollEvent = params => {
        if (params.direction === 'vertical') {
            const { pivotMode } = this.props;
            const floatingTopClassList = this.gridContainer.querySelector(
                pivotMode ? '.ag-header-viewport' : '.ag-floating-top'
            ).classList;
            if (this._agGridBody.scrollTop === 0) {
                floatingTopClassList.remove('table-scroll-shadow');
            } else {
                floatingTopClassList.add('table-scroll-shadow');
            }
        }
    };

    _hideGridPopovers = () => {
        const {
            showTransparencyPopover,
            dimensionDrilldownCellId,
            onPopoverToggle,
            onDrilldownPopoverToggle,
        } = this.props;

        if ((showTransparencyPopover || dimensionDrilldownCellId) && !this.isScrolling) {
            this.isScrolling = true;
            setTimeout(() => {
                this.isScrolling = false;
                onPopoverToggle('');
                onDrilldownPopoverToggle('');
            }, 0);
        }
    };

    _intersectFieldsWithColumnDefs = fields => {
        const { columnDefs } = this.props;
        return fields ? fields.filter(x => columnDefs.some(cd => cd.field === x)) : [];
    };

    onGridReady = params => {
        const { onReady, id, sortModel, sortKey } = this.props;

        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridService = new GridService(id, this.gridApi, this.columnApi);
        this._setTotalsRow();
        this.gridApi.hideOverlay();
        this.setState({ visible: true });
        this._headerScrollPatch();

        this.updateRowCount();
        this.applyPivotTableSettings();

        this.sortModel = sortModel.length > 0 ? sortModel : [{ colId: sortKey, sort: 'desc' }];
        this.gridApi.setSortModel(this.sortModel);
        this.resizeColsAppropriately();

        setTimeout(() => {
            this.setState({ isInitialState: true });
            onReady();
        }, 0);
    };

    applyPivotTableSettings = () => {
        const { pivotMode, pivotTable } = this.props;

        if (!this.columnApi || !pivotMode) {
            return;
        }

        const defaultSettings = this._getDefaultPivotTable();
        const settings = pivotTable && Object.keys(pivotTable).length > 0 ? pivotTable : defaultSettings;
        Object.keys(defaultSettings).forEach(key => {
            if (settings[key] === undefined) {
                settings[key] = defaultSettings[key];
            }
        });
        this.columnApi.setRowGroupColumns(this._intersectFieldsWithColumnDefs(settings.rowGroup));
        this.columnApi.setPivotColumns(this._intersectFieldsWithColumnDefs(settings.columns));
        this.columnApi.setValueColumns(this._intersectFieldsWithColumnDefs(settings.values));
    };

    _getDefaultPivotTable = () => {
        const { columnDefs } = this.props;
        return {
            rowGroup: columnDefs.filter(colDef => colDef.type === COLUMN_TYPES.DIMENSION).map(col => col.field),
            values: columnDefs.filter(colDef => colDef.type === COLUMN_TYPES.METRIC).map(col => col.field),
            columns: [],
        };
    };

    updateRowCount = () => {
        const { rows } = this.state;
        if (rows !== this.gridApi.rowModel.rowsToDisplay.length) {
            this.setState({ rows: this.gridApi.rowModel.rowsToDisplay.length });
        }
    };

    ifPinnedMetricExists = () => {
        const { columnDefs } = this.props;

        const metricsColumnNames = columnDefs
            .filter(colDef => colDef.type === COLUMN_TYPES.METRIC)
            .map(col => col.field);
        const pinnedColumnNames = this.columnApi
            .getAllGridColumns()
            .filter(colDef => colDef.pinned === 'left')
            .map(col => col.colId);
        const pinnedMetrics = metricsColumnNames.filter(value => pinnedColumnNames.indexOf(value) !== -1);

        return pinnedMetrics.length > 0;
    };

    resizeColsAppropriately = () => {
        const { pivotMode } = this.props;
        const gridWrapper = document.getElementById('grid_wrapper');

        if (!this.columnApi || !gridWrapper) {
            return;
        }

        if (pivotMode) {
            this.columnApi.autoSizeAllColumns();
        } else {
            this.columnApi.autoSizeColumns();
        }
    };

    _headerScrollPatch = () => {
        const { disableScrollingHooks } = this.props;
        if (!this.gridContainer) {
            return;
        }
        this._agGridHeader = this.gridContainer.querySelector('.ag-header-viewport');
        this._agGridFloatingMenu = this.gridContainer.querySelector('.ag-floating-top-viewport');
        this._agGridBody = this.gridContainer.querySelector('.ag-body-viewport');

        if (!disableScrollingHooks) {
            [this._agGridFloatingMenu, this._agGridHeader].forEach(el => {
                el.addEventListener('wheel', this._scroller);
            });
            window.document.addEventListener('wheel', this._pageScroller);
        }
    };

    _setTotalsRow = () => {
        const { rowData, totalsData } = this.props;
        // Adding the pinned row (totals) only if there's no filter active
        if (this.gridApi && Object.keys(totalsData).length > 0) {
            setTimeout(
                () =>
                    this.gridApi.setPinnedTopRowData(
                        !rowData.length || this.columnApi.isPivotMode() ? [] : [totalsData]
                    ),
                0
            );
        }
    };

    onFilterChanged = () => {
        const { columnDefs } = this.props;
        const { filterModel } = this.state;
        const filterApiModel = this.gridApi.getFilterModel();

        if (!isEqual(filterApiModel, filterModel)) {
            this.setState({ filterModel: filterApiModel });
            this.updateRowCount();
            this._setTotalsRow();
            const model = Object.entries(filterApiModel).map(([field, { type, filter }]) => ({ field, filter, type }));
            this.debouncedOnTableFilterChange(model, columnDefs);
            this.setGridInitialState(false);
        }
    };

    onSortChanged = () => {
        if (!isEqual(this.gridApi.getSortModel(), this.sortModel)) {
            this.setGridInitialState(false);
        }
    };

    onColumnResized = event => {
        if (event.finished && event.source === 'uiColumnDragged') {
            this.setGridInitialState(false);
        }
    };

    onColumnsVisibilityChanged = ({ visible, column = {} }) => {
        const { onToggleColumn } = this.props;

        this.resizeColsAppropriately();

        // triggers on init with empty column :(
        if (Object.keys(column).length > 0) {
            onToggleColumn({ visible, column: column.colId });
        }
    };

    onRowClicked = e => {
        // Close tool panel on table body click
        if (this.gridApi) {
            this.gridApi.closeToolPanel();
        }
    };

    onColumnPivotModeChanged = e => {
        const { onPivotModeToggle, onUpdateQueryURL } = this.props;

        // This will remove / restore the totals row according to the pivot mode
        this._setTotalsRow();

        onPivotModeToggle(e.columnApi.isPivotMode());
        onUpdateQueryURL();

        this.resizeColsAppropriately();
    };

    _copyMinWidthToAutoColumns = () => {
        this.columnApi.getAllGridColumns().forEach(col => {
            if (col.colId.startsWith('ag-Grid-AutoColumn-')) {
                const origColName = col.colId.slice('ag-Grid-AutoColumn-'.length);
                const origCol = this.columnApi.getColumn(origColName);
                col.minWidth = Math.min(origCol.minWidth, 800);
            }
        });
    };

    onColumnRowGroupChanged = e => {
        const { onPivotRowGroupChanged } = this.props;
        const rowGroupColumns = e.columnApi.getRowGroupColumns();
        const rowGroupColumnIds = rowGroupColumns.map(column => column.getColId());
        onPivotRowGroupChanged(rowGroupColumnIds);

        this.onPivotSettingsChange(true);
    };

    _getMetricsColumns = () => {
        return this.columnApi.getValueColumns().map(column => column.getColId());
    };

    onColumnValueChanged = () => {
        const { onPivotValuesChanged, pivotMode } = this.props;

        if (!pivotMode) {
            return;
        }
        onPivotValuesChanged(this._getMetricsColumns());
        this.onPivotSettingsChange(true);
    };

    onDragStopped = () => {
        const { onPivotValuesChanged, pivotMode, onColumnDropped } = this.props;

        if (this.state.columnMoved) {
            this.setState({
                columnMoved: false,
            });
            const columnState = this.columnApi.getColumnState();
            const columnOrder = columnState.map(col => col.colId);
            onColumnDropped({ columnOrder });
        }

        if (!pivotMode) {
            return;
        }

        onPivotValuesChanged(this._getMetricsColumns());
        this.onPivotSettingsChange(false);
    };

    onColumnPivotChanged = e => {
        const { onPivotColumnsChanged } = this.props;

        const pivotColumns = e.columnApi.getPivotColumns();
        const pivotColumnIds = pivotColumns.map(column => column.getColId());
        onPivotColumnsChanged(pivotColumnIds);

        this.onPivotSettingsChange(true);
    };

    onPivotSettingsChange = resize => {
        const { onUpdateQueryURL } = this.props;

        onUpdateQueryURL();

        if (this.gridApi) {
            if (this.columnApi) {
                this._copyMinWidthToAutoColumns();
            }

            if (resize !== false) {
                this.resizeColsAppropriately();
            }
            this.updateRowCount();
        }
    };

    _getContainerHeight = (rows, totalsData, pivotMode) => {
        if (typeof rows === 'undefined') {
            return this._expectedHeightBasedOnWindow();
        }

        const numberOfRows = rows + (Object.keys(totalsData).length > 0 ? 1 : 0);
        const expectedHeightBasedOnRows =
            40 + // Header Height
            40 + // Filter Height
            13 + // borders
            numberOfRows * 40;
        const res = Math.min(expectedHeightBasedOnRows, this._expectedHeightBasedOnWindow());

        return pivotMode ? Math.max(500, res) : Math.max(300, res); // Pivot gets a minimum of 500 because of the sidebar
    };

    _expectedHeightBasedOnWindow = () => {
        return window.innerHeight - 220;
    };

    resetTableConfig = () => {
        const { isInitialState } = this.state;
        const { pivotMode } = this.props;

        if (this.columnApi && !isInitialState) {
            this.gridApi.setFilterModel({});
            this.columnApi.resetColumnState();

            if (pivotMode) {
                this.columnApi.addValueColumns(
                    this.columnApi.getAllColumns().filter(x => x.colDef.type !== 'dimension')
                );
                this.columnApi.addRowGroupColumns(
                    this.columnApi.getAllColumns().filter(x => x.colDef.type === 'dimension')
                );
            }

            this.gridApi.sizeColumnsToFit();

            setTimeout(() => {
                this.gridApi.setSortModel(this.sortModel);
                this.setGridInitialState(true);
            }, 0);
        }
    };

    setGridInitialState = isInitial => {
        const { onPopoverToggle } = this.props;
        const { isInitialState } = this.state;
        if (isInitial !== isInitialState) {
            this.setState({ isInitialState: isInitial });
        }
        onPopoverToggle('');
    };

    postProcessPopup = params => {
        if (params.type !== 'columnMenu') return;

        const { style } = params.ePopup;

        // Fix column menu to open under menu icon
        style.top = _offsetPixelicMeasurementProperty(style.top, 3);
        style.left = _offsetPixelicMeasurementProperty(style.left, 30);
    };

    _getRealColumn = column => {
        return column.colDef.showRowGroup ? column.columnApi.getColumn(column.colDef.showRowGroup) : column;
    };

    getMainMenuItems = params => {
        let menuItems;
        const realCol = this._getRealColumn(params.column);
        const freezeColumnMenuOption = {
            name: 'Freeze Column',
            icon: renderToStaticMarkup(<FreezeColumnIcon />),
            action() {
                const frozenColumn = realCol.colId;
                const frozenColumnsCount =
                    params.columnApi.getAllColumns().findIndex(c => c.colId === frozenColumn) - 1;
                params.context.props.onFreezeColumn({ frozenColumn, frozenColumnsCount });
                params.context.freezeColumns(params);
            },
        };
        const resetColumns = {
            name: 'Reset Columns',
            icon: renderToStaticMarkup(<ResetColumnsIcon />),
            action() {
                params.context.props.onResetTable();
                params.context.resetTableConfig();
            },
        };

        const defaultMenuItems = [freezeColumnMenuOption, resetColumns];

        // UGLY UGLY HACK. in pivot mode coldef.type of dimensions are undefined.
        // Solving https://jira.singular.net/browse/ANATE-626
        if (realCol.colDef.type !== COLUMN_TYPES.METRIC) {
            const pinOptions = ['pinLeft'];
            if (!this.ifPinnedMetricExists()) {
                pinOptions.push('clearPinned');
            }
            const pinDimensionMenuOption = {
                name: 'Pin Column',
                icon: renderToStaticMarkup(<PinColumnIcon />),
                subMenu: pinOptions,
            };
            menuItems = [pinDimensionMenuOption, ...defaultMenuItems];
        } else if (realCol.colDef.type === COLUMN_TYPES.METRIC) {
            const pinMetricMenuOption = {
                name: 'Pin Column',
                icon: renderToStaticMarkup(<PinColumnIcon />),
                subMenu: [
                    {
                        name: 'Pin Left',
                        checked: params.column.pinned && params.column.lastLeftPinned,
                        action() {
                            params.context.props.onPinColumn({
                                name: params.column.colId,
                                direction: 'left',
                            });

                            params.context.pinMetricColumns(params, 'left');
                            params.context.props.onPinColumn({ direction: 'left', column: params.column.colId });
                        },
                    },
                    {
                        name: 'Pin Right',
                        checked: params.column.pinned && params.column.firstRightPinned,
                        action() {
                            params.context.props.onPinColumn({
                                name: params.column.colId,
                                direction: 'right',
                            });

                            params.context.pinMetricColumns(params, 'right');
                            params.context.props.onPinColumn({ direction: 'right', column: params.column.colId });
                        },
                    },
                    {
                        name: 'Clear Pin',
                        checked: !params.column.pinned,
                        action() {
                            params.context.props.onPinColumn({
                                name: params.column.colId,
                                direction: 'right',
                            });

                            params.context.pinMetricColumns(params, null);
                            params.context.props.onPinColumn({ direction: 'clear', column: params.column.colId });
                        },
                    },
                ],
            };

            menuItems = [pinMetricMenuOption, ...defaultMenuItems];
        }
        return menuItems;
    };

    onColumnMoved = params => {
        const { onMoveColumn } = this.props;
        if (params.column) {
            const columnState = this.columnApi.getColumnState();
            const columnOrder = columnState.map(col => col.colId);
            this.setState({
                columnMoved: true,
            });
            // Will be null if reset table has been clicked
            onMoveColumn({ column: params.column.colId, columnOrder });
        }
    };

    onDisplayedColumnsChanged = () => {
        this.setGridInitialState(false);
    };

    exportToCSV = () => {
        const { exportParams, setExportParams, rowData } = this.props;

        if (this.gridApi != null) {
            this.gridApi.setRowData(rowData);
            this.gridApi.exportDataAsCsv(exportParams);
            setExportParams(null);
        }
    };

    render() {
        const {
            rowData,
            columnTypes,
            totalsData,
            scrollOnlyOnPageBottom,
            reportType,
            pivotMode,
            className,
            children,
            heightOverride,
            hideResetTable,
            hidePivotHeaders,
            hideColumnsSideBar,
            disableScrollingHooks,
            store,
            exportParams,
        } = this.props;

        const { visible, isPageBottom, rows, isInitialState } = this.state;
        const { gridColumnDefs } = this;

        const checkboxChecked = renderToStaticMarkup(<SelectedChecboxIcon style={menuIconsStyle} />);
        const checkboxUnchecked = renderToStaticMarkup(<UnselectedChecboxIcon style={menuIconsStyle} />);

        const sideBar = { toolPanels: [] };

        if (!hideColumnsSideBar) {
            const columnsPanel = {
                id: 'columns',
                labelDefault: pivotMode ? 'Values' : 'Columns',
                labelKey: 'columns',
            };

            const reportConfig = getConfigForType(reportType);

            if (reportConfig?.customColumnsToolPanelConfig) {
                columnsPanel.toolPanelFramework = GridCustomColumnsToolPanel;
                columnsPanel.toolPanelParams = reportConfig.customColumnsToolPanelConfig;
            } else {
                columnsPanel.toolPanel = 'agColumnsToolPanel';
                columnsPanel.toolPanelParams = {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true,
                };
            }

            sideBar.toolPanels.push(columnsPanel);
        }

        if (pivotMode) {
            sideBar.toolPanels.push({
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                toolPanel: 'agFiltersToolPanel',
            });
        }

        const frameworkComponents = { agColumnHeader: GridCustomHeader };
        if (!pivotMode) {
            frameworkComponents.totalsCell = ProviderWrapper(store, TotalsCell(reportType));
        }

        const containerHeight = heightOverride || this._getContainerHeight(rows, totalsData, pivotMode);

        if (exportParams != null) {
            this.exportToCSV();
        }

        return (
            <div
                id="grid_wrapper"
                style={{
                    width: '100%',
                    display: rowData && rowData.length ? 'block' : 'none', // Hide if no data
                    opacity: visible ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out', // 76px -> 60px for top bar + 16px margin-bottom
                }}
                className={className}
            >
                {!hideResetTable && (
                    <div className={css.resetTableLabel} onClick={this.resetTableConfig}>
                        <span className={isInitialState ? css.disabled : ''}>
                            <ResetColumnsIcon />
                            <span>Reset Table</span>
                        </span>
                    </div>
                )}
                {children}
                <div
                    style={{
                        height: `${containerHeight}px`,
                    }}
                    className={classNames(
                        'ag-theme-material',
                        'gridContainer',
                        css.gridContainer,
                        { [css.lock]: !isPageBottom && scrollOnlyOnPageBottom },
                        { [css.hasPivot]: this.columnApi && this.columnApi.getPivotColumns().length > 0 }
                    )}
                    ref={el => {
                        this.gridContainer = el;
                    }}
                >
                    <AgGridReact
                        columnDefs={gridColumnDefs}
                        rowData={rowData}
                        autoGroupColumnDef={autoGroupColumnDef}
                        context={this}
                        floatingFilter={!pivotMode}
                        sortingOrder={['desc', 'asc', null]}
                        enableSorting
                        enableColResize
                        suppressDragLeaveHidesColumns
                        suppressAggFuncInHeader
                        groupHideOpenParents
                        groupDefaultExpanded={-1}
                        suppressContextMenu
                        sideBar={sideBar}
                        columnTypes={columnTypes}
                        pinnedTopRowData={Object.keys(totalsData).length > 0 ? [totalsData] : undefined}
                        pivotMode={pivotMode}
                        rowGroupPanelShow={pivotMode && !hidePivotHeaders ? 'always' : 'never'}
                        pivotPanelShow={pivotMode && !hidePivotHeaders ? 'always' : 'never'}
                        onGridReady={this.onGridReady}
                        onFilterChanged={this.onFilterChanged}
                        onSortChanged={this.onSortChanged}
                        onBodyScroll={!disableScrollingHooks ? this.onScrollEvent : undefined}
                        onRowClicked={this.onRowClicked}
                        onColumnResized={this.onColumnResized}
                        onColumnVisible={this.onColumnsVisibilityChanged}
                        onDisplayedColumnsChanged={this.onDisplayedColumnsChanged}
                        onToolPanelVisibleChanged={this.onColumnsVisibilityChanged}
                        onColumnMoved={this.onColumnMoved}
                        onColumnPivotModeChanged={this.onColumnPivotModeChanged}
                        onColumnRowGroupChanged={this.onColumnRowGroupChanged}
                        onColumnValueChanged={this.onColumnValueChanged}
                        onColumnPivotChanged={this.onColumnPivotChanged}
                        onDragStopped={this.onDragStopped}
                        postProcessPopup={this.postProcessPopup}
                        getMainMenuItems={this.getMainMenuItems}
                        icons={{
                            checkboxChecked,
                            checkboxUnchecked,
                        }}
                        frameworkComponents={frameworkComponents}
                        headerHeight={50}
                        floatingFiltersHeight={40}
                        rowHeight={40}
                        suppressPropertyNamesCheck
                    />
                </div>
            </div>
        );
    }
}

Grid.propTypes = {
    columnDefs: PropTypes.arrayOf(PropTypes.object),
    rowData: PropTypes.arrayOf(PropTypes.object),
    totalsData: PropTypes.objectOf(PropTypes.any),
    scrollOnlyOnPageBottom: PropTypes.bool,
    onTableFilterChanged: PropTypes.func,
    onPopoverToggle: PropTypes.func,
    onReady: PropTypes.func,
    sortKey: PropTypes.string,
    sortModel: PropTypes.arrayOf(PropTypes.any),
    columnTypes: PropTypes.objectOf(PropTypes.any),
    id: PropTypes.string,
    children: PropTypes.element,
    showTransparencyPopover: PropTypes.string,
    reportType: PropTypes.string,
    pivotMode: PropTypes.bool,
    pivotTable: PropTypes.objectOf(PropTypes.any),
    onPinColumn: PropTypes.func,
    onMoveColumn: PropTypes.func,
    onColumnDropped: PropTypes.func,
    onResetTable: PropTypes.func,
    onToggleColumn: PropTypes.func,
    onFreezeColumn: PropTypes.func,
    onPivotModeToggle: PropTypes.func,
    onPivotRowGroupChanged: PropTypes.func,
    onPivotColumnsChanged: PropTypes.func,
    onPivotValuesChanged: PropTypes.func,
    onUpdateQueryURL: PropTypes.func,
    className: PropTypes.string,
    heightOverride: PropTypes.number,
    hideResetTable: PropTypes.bool,
    hidePivotHeaders: PropTypes.bool,
    hideColumnsSideBar: PropTypes.bool,
    disableScrollingHooks: PropTypes.bool,
    store: PropTypes.objectOf(PropTypes.any),
    exportParams: PropTypes.objectOf(PropTypes.any),
    setExportParams: PropTypes.func,
};

Grid.defaultProps = {
    columnDefs: [],
    rowData: [],
    totalsData: {},
    scrollOnlyOnPageBottom: false,
    onTableFilterChanged: () => {},
    onPopoverToggle: () => {},
    onDrilldownPopoverToggle: () => {},
    onReady: () => {},
    sortKey: '',
    sortModel: [],
    columnTypes: {},
    id: 'generic_grid',
    children: null,
    reportType: null,
    showTransparencyPopover: '',
    pivotMode: false,
    pivotTable: {},
    onPinColumn: () => {},
    onMoveColumn: () => {},
    onColumnDropped: () => {},
    onResetTable: () => {},
    onToggleColumn: () => {},
    onFreezeColumn: () => {},
    onPivotModeToggle: () => {},
    onPivotRowGroupChanged: () => {},
    onPivotColumnsChanged: () => {},
    onPivotValuesChanged: () => {},
    onUpdateQueryURL: () => {},
    className: '',
    heightOverride: null,
    hideResetTable: false,
    hidePivotHeaders: false,
    hideColumnsSideBar: false,
    disableScrollingHooks: false,
    store: {},
    exportParams: null,
    setExportParams: () => {},
};

function GridWrapper(props) {
    const store = useStore();
    return React.createElement(Grid, {
        ...props,
        store,
    });
}

export default memo(GridWrapper);

function _offsetPixelicMeasurementProperty(valueString, offset) {
    const value = valueString.substring(0, valueString.indexOf('px'));
    const newValue = parseFloat(value) + offset;

    return `${newValue}px`;
}
