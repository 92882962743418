import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import css from './ShelfGroup.css';
import SkanPlusIcon from '../../resources/svg/skan_plus/skan.svg';
import SkanPlusWhiteIcon from '../../resources/svg/skan_plus/skan-white.svg';
import ChevronDown from '../../resources/icons/chevron.svg';

function ShelfGroup({
    label,
    labelData,
    children,
    expanded,
    disabled,
    className,
    blockStyle,
    isSkanPlus,
    labelContainer,
    contentContainer,
}) {
    const [isExpanded, setExpanded] = useState(expanded);

    const groupRef = useRef(null);
    useEffect(() => {
        if (isExpanded && groupRef.current) {
            groupRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [isExpanded]);

    const skanPlusExtendedClass = {
        [css.skanPlusCollapsed]: isSkanPlus && !isExpanded,
        [css.skanPlusExpanded]: isSkanPlus && isExpanded,
    };

    return (
        <div className={className} ref={groupRef}>
            <div
                className={classNames(css.container, {
                    ...skanPlusExtendedClass,
                    [css.blockStyle]: blockStyle,
                    [css.collapsed]: !isExpanded,
                })}
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    setExpanded(!isExpanded);
                }}
                role="button"
                tabIndex={0}
                disabled={disabled}
            >
                <div className={labelContainer}>
                    <div className={css.skanPlusIcon}>
                        {isSkanPlus && (isExpanded ? <SkanPlusWhiteIcon /> : <SkanPlusIcon />)}
                    </div>
                    <Translate id={label} data={labelData} />
                </div>
                <div
                    className={classNames(css.arrowArea, {
                        [css.skanPlus]: isSkanPlus,
                        [css.blockStyle]: blockStyle,
                    })}
                >
                    <ChevronDown className={classNames(css.arrow, skanPlusExtendedClass)} />
                </div>
            </div>
            {isExpanded ? <div className={contentContainer || css.content}>{children}</div> : null}
        </div>
    );
}

ShelfGroup.propTypes = {
    label: PropTypes.string,
    labelData: PropTypes.objectOf(PropTypes.number),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    expanded: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    blockStyle: PropTypes.bool,
    isSkanPlus: PropTypes.bool,
    labelContainer: PropTypes.string,
    contentContainer: PropTypes.string,
};

ShelfGroup.defaultProps = {
    label: '',
    labelData: {},
    children: null,
    expanded: false,
    disabled: false,
    className: null,
    blockStyle: false,
    isSkanPlus: false,
    labelContainer: '',
    contentContainer: '',
};

export default ShelfGroup;
