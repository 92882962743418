import { extractLineHighlights, removeMarkers } from './markersUtils';
import { CODE_SNIPPETS_GETTERS, CODE_SNIPPETS_SECTIONS_SWITCHER } from './codeSnippetsGeneratorMapping/mapConfig';
import { SELECT_MEDIATION_PLATFORM } from '../../../onboardingGuide/components/sdkConfiguration/consts';
import { SDK_INTEGRATION_SECTIONS } from '../consts';
import { getInfoSectionKey, getMediatorParsedName } from '../utils';
import { DEFAULT_MEDIATOR } from '../components/sections/AdMonetization/consts';

/**
 * @param {function} codeSnippetsGetter a function that returns an Object of the shape: {markedCode, codeLanguage, placeholders, customTitle}
 * @param {Object} sdkConfigurationForPlanning with sections keys and boolean values
 * @return {Object} of shape:
 * {
 *   codeSnippetAttributes: for the codeblock component,
 *   rowHighlighterMapper: with keys of code sections and their line highlights string. Example: "1-1, 4-5, 7-7"
 * }
 * */
const getCodeSnippetsAttributes = (codeSnippetsGetter, sdkConfigurationForPlanning) => {
    const { markedCode, codeLanguage, placeholders, customTitle } = codeSnippetsGetter(sdkConfigurationForPlanning);

    const code = removeMarkers(markedCode);

    return {
        codeSnippetAttributes: {
            initialCode: code,
            codeLanguage,
            customTitle,
            placeholders,
        },
        rowHighlighterMapper: extractLineHighlights(markedCode),
    };
};

export const getAdMonCodeSnippet = mediator => {
    return `AD_MONETIZATION_${getMediatorParsedName(mediator)}`;
};

/**
 * @param {string} targetPlatform from TARGET_PLATFORMS_OPTIONS
 * @param {Object} sdkConfigurationForPlanning with sections keys and boolean values
 * @return {Object} with keys of sections names to snippet name
 * */
const getCodeSnippetsSwitcher = (targetPlatform, sdkConfigurationForPlanning) => {
    const codeSnippetSwitcher = CODE_SNIPPETS_SECTIONS_SWITCHER[targetPlatform];

    if (
        sdkConfigurationForPlanning[targetPlatform] &&
        sdkConfigurationForPlanning[targetPlatform][SDK_INTEGRATION_SECTIONS.AD_MONETIZATION]
    ) {
        const preSelectedMediator =
            sdkConfigurationForPlanning[targetPlatform][getInfoSectionKey(SDK_INTEGRATION_SECTIONS.AD_MONETIZATION)];
        const adMonSnippet = getAdMonCodeSnippet(preSelectedMediator);

        codeSnippetSwitcher[SDK_INTEGRATION_SECTIONS.AD_MONETIZATION] = adMonSnippet;
    }
    return codeSnippetSwitcher;
};

const getCodeSnippetGetters = targetPlatform => {
    return CODE_SNIPPETS_GETTERS[targetPlatform];
};

/**
 * @param {string} targetPlatform from TARGET_PLATFORMS_OPTIONS
 * @param {Object} sdkConfigurationForPlanning with sections keys and boolean values
 * @return {Object} of the shape: {codeSnippets: {snippetName: [gettersByLanguage...]}, snippetsSwitcher}
 * */
const calculateCodeSnippets = (targetPlatform, sdkConfigurationForPlanning) => {
    const codeSnippetGetters = getCodeSnippetGetters(targetPlatform);

    const codeSnippets = {};

    Object.keys(codeSnippetGetters).forEach(codeSnippetName => {
        const snippetsGettersByCodeLanguage = codeSnippetGetters[codeSnippetName];

        snippetsGettersByCodeLanguage.forEach(codeLanguageGetter => {
            const { codeSnippetAttributes, rowHighlighterMapper } = getCodeSnippetsAttributes(
                codeLanguageGetter,
                sdkConfigurationForPlanning
            );

            const { codeLanguage } = codeSnippetAttributes;

            codeSnippets[codeSnippetName] = {
                ...codeSnippets[codeSnippetName],
                [codeLanguage]: {
                    codeSnippetAttributes,
                    rowHighlighterMapper,
                },
            };
        });
    });

    return { codeSnippets, snippetsSwitcher: getCodeSnippetsSwitcher(targetPlatform, sdkConfigurationForPlanning) };
};

export default calculateCodeSnippets;
