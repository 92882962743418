import { FIELD_SECTION, FIELD_GROUP_HOLDER_DEFAULT_STYLE } from 'components/pages/reports/consts';

const PERMISSION_KEY_SKAN_EVENTS_POSTBACK_PERIODS = 'display_skan_events_postback_periods';

export const shelfPeriods = {
    type: 'periods',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        data: {
            buttonStyle: {
                width: '90px',
            },
        },
    },
    label: 'STATIC.COHORT_PERIODS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.COHORT_PERIODS',
    section: FIELD_SECTION.COHORT_PERIODS,
};

export const shelfIOSPeriods = {
    type: 'unifiedCohortPeriods',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        data: {
            buttonStyle: {
                width: '90px',
                backgroundColor: 'var(--gray100)',
            },
            fieldGroupHolderStyle: {
                ...FIELD_GROUP_HOLDER_DEFAULT_STYLE,
                borderTop: 'none',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                paddingBottom: '10px',
            },
        },
    },
    label: 'STATIC.COHORT_PERIODS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.UNIFIED_COHORT_PERIODS',
    section: FIELD_SECTION.COHORT_PERIODS,
};

export const shelfSkanPeriods = {
    ...shelfPeriods,
    group: { ...shelfPeriods.group, bottomBorder: true },
    label: 'STATIC.SKAN_COHORT_PERIODS',
    permissionKey: PERMISSION_KEY_SKAN_EVENTS_POSTBACK_PERIODS,
};

export const shelfSkanModeledCohortPeriods = {
    type: 'skanModeledCohortPeriods',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        bottomBorder: true,
        data: {
            buttonStyle: {
                width: '90px',
            },
        },
    },
    label: 'STATIC.MODELED_SKAN_COHORT_PERIODS',
};
