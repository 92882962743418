import { useCallback } from 'react';
import AppsService from '../../services/apps';
import { useRequest } from '../../utils/customHooks';

const useFetchGetApps = () => {
    const appsApi = new AppsService();
    return useRequest(
        useCallback(appsApi.getApps, []),
        () => true,
        () => {},
        true
    );
};

export { useFetchGetApps };
