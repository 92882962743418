import update from 'immutability-helper';
import {
    FETCHED_FIELDS,
    UPDATE_ALL_FIELDS,
    UPDATED_DIMENSIONS,
    UPDATED_FIELD,
    UPDATED_FILTERS,
} from '../actions/fields';
import { UPDATE_BOOKMARK_DATA } from '../actions/reports';
import { reportRegexPatternMatcher } from '../utils/reports';

const initialState = {
    dimensions: [],
    custom_dimensions: [],
    governanceDimensions: [],
    filters: {},
    kpis: [],
    metrics: [],
    appMetrics: [],
    webMetrics: [],
    utmParamsFields: [],
    crossDeviceMetrics: [],
    xorgMetrics: [],
    discrepancyMetrics: [],
    skanModeledMetrics: [],
    skanModeledCohortMetrics: [],
    skanModeledCohortPeriods: [],
    unifiedCohortPeriods: [],
    customEvents: [],
    conversionEvents: [],
    skanModeledCustomEvents: [],
    cohortMetrics: [],
    cohortPeriods: [],
    dataTypeFields: [],
    metricGroupFields: [],
    cohortMetricGroupFields: [],
    skanCustomEvents: [],
    xorgCohortMetrics: [],
    goalsMetrics: [],
    goalsForecastMetrics: [],
    socialEngagementMetrics: [],
    periods: [],
    dates: {},
    timeBreakdowns: [
        { name: 'day', display_name: 'Day', actual_type: 'timeBreakdowns' },
        { name: 'week', display_name: 'Week', actual_type: 'timeBreakdowns' },
        { name: 'month', display_name: 'Month', actual_type: 'timeBreakdowns' },
        { name: 'all', display_name: 'All Time', actual_type: 'timeBreakdowns', is_default: true },
    ],
    confidenceIntervalFlag: [
        {
            name: 'true',
            display_name: 'True',
            actual_type: 'confidenceIntervalFlag',
            is_default: true,
        },
        {
            name: 'false',
            display_name: 'False',
            actual_type: 'confidenceIntervalFlag',
        },
    ],
    sourceAttributionType: [
        {
            name: 'first',
            display_name: 'First',
            actual_type: 'sourceAttributionType',
            visible: false,
            feature_flag_visible: 'source_attribution_type',
        },
        {
            name: 'dynamic',
            display_name: 'Dynamic',
            actual_type: 'sourceAttributionType',
            visible: false,
            feature_flag_visible: 'source_attribution_type',
            is_default: true,
            force_default: true,
        },
        {
            name: '__ALL__',
            display_name: 'Both(dups)',
            actual_type: 'sourceAttributionType',
            only_admin_visible: true,
            visible: false,
            feature_flag_visible: 'source_attribution_type',
        },
    ],
    withDruidAppend: [
        {
            name: 'with_append',
            display_name: 'With Append',
            actual_type: 'withDruidAppend',
            only_admin_visible: true,
            visible: true,
            is_default: true,
            force_default: true,
        },
        {
            name: 'no_append',
            display_name: 'Without',
            actual_type: 'withDruidAppend',
            only_admin_visible: true,
            visible: true,
        },
        {
            name: 'both',
            display_name: 'All (with dups)',
            actual_type: 'withDruidAppend',
            only_admin_visible: true,
            visible: true,
        },
    ],
    druidAggregatedDataSourcesMode: [
        {
            name: 'not_aggregated',
            display_name: 'Not Aggregated',
            actual_type: 'druidAggregatedDataSourcesMode',
            only_admin_visible: true,
            visible: true,
            is_default: true,
            force_default: true,
        },
        {
            name: 'aggregated',
            display_name: 'Aggregated',
            actual_type: 'druidAggregatedDataSourcesMode',
            only_admin_visible: true,
            visible: true,
        },
    ],
    crossDeviceCohortType: [
        {
            name: 'device',
            display_name: 'Device',
            actual_type: 'crossDeviceCohortType',
            visible: true,
            is_default: true,
        },
        {
            name: 'user',
            display_name: 'User',
            actual_type: 'crossDeviceCohortType',
            visible: true,
        },
    ],
    crossDeviceDemoCohortType: [
        {
            name: 'device',
            display_name: 'Device',
            actual_type: 'crossDeviceDemoCohortType',
            visible: false,
            feature_flag_visible: 'cross_device_demo_cohort_type',
            is_default: true,
        },
        {
            name: 'user',
            display_name: 'User',
            actual_type: 'crossDeviceDemoCohortType',
            visible: false,
            feature_flag_visible: 'cross_device_demo_cohort_type',
        },
    ],
    tagGroups: [],
    fileCombinerDimensions: [],
    granularityLevels: [],
    enrichmentDimensions: [],
    sourceDimensions: [],
    metadataDimensions: [],
    initiated: false,
    bookmarks: [],
    adMonDAUMetrics: [],
};

const fields = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ALL_FIELDS: {
            const { fieldsList } = action;

            const updatePayload = [
                'dimensions',
                'governanceDimensions',
                'custom_dimensions',
                'sourceDimensions',
                'fileCombinerDimensions',
                'granularityLevels',
                'enrichmentDimensions',
                'skanConversionDimensions',
                'skan_redownloads_dimensions',
                'skan_validated_dimensions',
                'metadataDimensions',
                'metrics',
                'appMetrics',
                'webMetrics',
                'utmParamsFields',
                'dataTypeFields',
                'metricGroupFields',
                'cohortMetricGroupFields',
                'skanCustomEvents',
                'crossDeviceMetrics',
                'discrepancyMetrics',
                'goalsMetrics',
                'goalsForecastMetrics',
                'cohortMetrics',
                'xorgCohortMetrics',
                'skanModeledMetrics',
                'skanModeledCohortMetrics',
                'skanModeledCohortPeriods',
                'unifiedCohortPeriods',
                'customEvents',
                'conversionEvents',
                'skanModeledCustomEvents',
                'periods',
                'adMonDAUMetrics',
                'skanDateDimensionName',
                'socialEngagementMetrics',
            ].reduce(
                (acc, fieldName) => ({
                    ...acc,
                    ...{
                        [fieldName]: {
                            $set: fieldsList[fieldName].map(field => {
                                field.actual_type = fieldName;
                                return field;
                            }),
                        },
                    },
                }),
                {}
            );

            return update(state, {
                ...updatePayload,
                filterOperators: {
                    $set: fieldsList.filterOperators.map(dim => {
                        dim.actual_type = 'filterOperators';
                        return dim;
                    }),
                },
            });
        }
        case UPDATED_DIMENSIONS:
            return update(state, {
                dimensions: {
                    $set: action.dimensions,
                },
            });
        case UPDATED_FILTERS:
            return update(state, {
                filters: {
                    $set: action.filters,
                },
            });
        case FETCHED_FIELDS: {
            return update(state, {
                initiated: {
                    $set: true,
                },
            });
        }
        case UPDATED_FIELD: {
            let setValue = action.data;

            if (typeof setValue === 'object' && setValue instanceof Array) {
                setValue = action.data.map(dim => {
                    dim.actual_type = action.fieldType;
                    return dim;
                });
            }

            return update(state, {
                [action.fieldType]: {
                    $set: setValue,
                },
            });
        }
        case (action.type.match(reportRegexPatternMatcher(UPDATE_BOOKMARK_DATA)) || {}).input: {
            const {
                data: { bookmark_id: bookmarkId = '', bookmark },
            } = action;

            if (!bookmarkId || !bookmark) {
                return state;
            }

            const bookmarkIndex = state.bookmarks.findIndex(b => b.uuid === bookmarkId);
            let toUpdate = { $push: [bookmark] };

            if (bookmarkIndex !== -1) {
                toUpdate = {
                    $splice: [[bookmarkIndex, 1, bookmark]],
                };
            }

            return update(state, {
                bookmarks: toUpdate,
            });
        }
        default:
            return state;
    }
};

export default fields;
