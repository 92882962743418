import PropTypes from 'prop-types';

export const ParameterTypes = {
    ACCOUNT: 'Account',
    APPLICATION: 'Application',
    POSTBACK: 'Postback',
};

export const ParameterDisplayTypes = {
    TEXT: 'text',
    DROPDOWN: 'dropdown',
    CHECKBOX: 'checkbox',
};

export const PostbackWindowStartingPoint = {
    UNLIMITED: 'Unlimited',
    INSTALL: 'Install',
    TOUCHPOINT: 'TouchPoint',
};

export const SupportTypes = {
    MANDATORY: 'mandatory',
    NEVER: 'never',
    OPTIONAL: 'optional',
    RECOMMENDED: 'recommended',
};

export const TemplateTypes = {
    INSTALL: 'Install',
    REVENUE: 'Revenue Event',
    CUSTOM: 'Custom Event',
    FRAUD: 'Fraud',
};

export const templateShape = PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TemplateTypes)),
    platform: PropTypes.string,
    clickWindow: PropTypes.number,
    installWindow: PropTypes.number,
    parameters: PropTypes.arrayOf(PropTypes.number),
    templateUri: PropTypes.string,
});

export const eventShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayName: PropTypes.string,
    revenueEvents: PropTypes.bool,
});

export const partnerShape = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    displayName: PropTypes.string,
    logoPath: PropTypes.string,
    sendAllInstalls: PropTypes.oneOf(Object.values(SupportTypes)),
    sendAllEvents: PropTypes.oneOf(Object.values(SupportTypes)),
    viewthroughSupport: PropTypes.oneOf(Object.values(SupportTypes)),
    installSupport: PropTypes.oneOf(Object.values(SupportTypes)),
    reengagementSupport: PropTypes.oneOf(Object.values(SupportTypes)),
    fraudPostbackSupport: PropTypes.oneOf(Object.values(SupportTypes)),
    description: PropTypes.string,
    instructions: PropTypes.string,
    guideLink: PropTypes.string,
    postbackTemplates: PropTypes.arrayOf(templateShape),
    parameters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            type: PropTypes.oneOf(Object.values(ParameterTypes)),
            description: PropTypes.string,
            label: PropTypes.string,
            predefinedValues: PropTypes.arrayOf(PropTypes.string),
            required: PropTypes.bool,
            regex: PropTypes.string,
        })
    ),
});

export const siteShape = PropTypes.shape({
    site_id: PropTypes.number,
    fullName: PropTypes.string,
    site_icon: PropTypes.string,
    platform: PropTypes.string,
    longname: PropTypes.string,
    app_inactivity_window: PropTypes.number,
});

export const countryShape = PropTypes.shape({
    isoCode: PropTypes.string,
    displayName: PropTypes.string,
});

export const organizationOptionsShape = PropTypes.shape({
    isAdmin: PropTypes.bool,
    ldsSupported: PropTypes.bool,
    geoRestrictionsSupported: PropTypes.bool,
    trackerCampaignNameFilterSupported: PropTypes.bool,
    advancedPostbackEditingSupported: PropTypes.bool,
    flexiblePostbackSupported: PropTypes.bool,
    editAgencyConfigurationSupported: PropTypes.bool,
    sansFlexiblePostbackReadOnlyMode: PropTypes.bool,
    zeroInactivityWindowSupported: PropTypes.bool,
    newPartnerConfigShelf: PropTypes.bool,
    newTiktokPartnerConfigShelf: PropTypes.bool,
    newSnapchatPartnerConfigShelf: PropTypes.bool,
});
