import React from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import {
    CONTENT_FOOTER_TRANSLATE_KEY,
    CONTENT_TRANSLATE_KEY,
    LEARN_MORE_SECTIONS_LINKS,
    MORE_INFO_CONTENT_TRANSLATE_KEY,
} from './consts';
import { TARGET_PLATFORM_SHORT_NAME } from '../../consts';
import SDKSectionsWrapper from '../SdkSectionsWrapper/SDKSectionsWrapper';
import { Label } from '../../../../components/widgets';
import FooterHelpCenterLink from './FooterHelpCenterLink';
import { getSectionTranslateID, onHelpCenterLinkClick, translateExists } from '../../sectionsWrapper/utils';
import css from './GeneralSDKPlanningSection.css';
import { useSDKPlanningContext } from '../../context/useSDKPlanningContext';
import InfoIcon from '../../../../resources/svg/info.svg';
import useMixpanelEventOnPageLoad from '../../../hooks/useMixpanelEventOnPageLoad';

const GeneralSDKPlanningSection = ({ translate, sectionKey }) => {
    const { selectedTargetPlatform } = useSDKPlanningContext();
    const platform = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];
    const contentTranslateID = getSectionTranslateID(sectionKey, CONTENT_TRANSLATE_KEY, platform);
    const moreInfoContentTranslateID = getSectionTranslateID(sectionKey, MORE_INFO_CONTENT_TRANSLATE_KEY, platform);
    const contentFooterTranslateID = getSectionTranslateID(sectionKey, CONTENT_FOOTER_TRANSLATE_KEY, platform);

    useMixpanelEventOnPageLoad(sectionKey, selectedTargetPlatform);

    return (
        <SDKSectionsWrapper sectionKey={sectionKey}>
            {translateExists(translate, contentTranslateID) && (
                <Label type="p" text={contentTranslateID} className={css.labelContainer} />
            )}
            {translateExists(translate, moreInfoContentTranslateID) && (
                <div className={css.moreInfoContent}>
                    <div className={css.infoIcon}>
                        <InfoIcon />
                    </div>
                    <Label type="p" text={moreInfoContentTranslateID} className={css.labelContainer} />
                </div>
            )}
            {translateExists(translate, contentFooterTranslateID) && (
                <FooterHelpCenterLink
                    text={contentFooterTranslateID}
                    helpCenterLink={LEARN_MORE_SECTIONS_LINKS[sectionKey]}
                    onHelpCenterLinkClick={url => onHelpCenterLinkClick(url, sectionKey, selectedTargetPlatform)}
                />
            )}
        </SDKSectionsWrapper>
    );
};

GeneralSDKPlanningSection.propTypes = {
    sectionKey: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
};

export default withLocalize(GeneralSDKPlanningSection);
