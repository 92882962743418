import React from 'react';
import Checkbox from 'components/widgets/Checkbox';
import css from 'teamManagement/components/PicturizedMultiselect.css';

const renderEivar = ({ icon, name, selected, id, displayName }, fallback, onChange) => (
    <div
        key={id}
        className={`${css.eivar} ${selected && css.selected}`}
        onClick={() => onChange(id, !selected)}
        role="button"
        tabIndex={0}
    >
        {icon || !fallback ? <img src={icon} className={css.logo} alt="" /> : null}
        {!icon && fallback ? fallback : null}
        <div className={css.name}>{displayName || name}</div>
        <Checkbox className={css.checkbox} id={`checkbox_app_${id}`} checked={selected} />
    </div>
);

export default ({ eivarim, onChange, containerStyle, fallback = null }) => (
    <div style={containerStyle}>
        {eivarim &&
            eivarim.map((eivar, idx, arr) => {
                if (Array.isArray(eivar)) {
                    return eivar.length ? (
                        <React.Fragment key={idx}>
                            {eivar.map((e) => renderEivar(e, fallback, onChange))}
                            {idx < arr.length - 1 && <div className={css.separator} />}
                        </React.Fragment>
                    ) : null;
                } else {
                    return renderEivar(eivar, fallback, onChange);
                }
            })}
    </div>
);
