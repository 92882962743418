import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import QRCode from 'qrcode.react';
import css from './QRCodeWidget.css';

const DOWNLOAD_SIZE = 2000;

function QRCodeWidget({ label, downloadPNG, downloadSVG, value, size }) {
    const [pngDownloadRef, setPNGDownloadRef] = useState(null);
    const [svgDownloadRef, setSVGDownloadRef] = useState(null);

    const onPNGDownloadClick = useCallback(() => {
        const canvas = document.getElementById('png');
        pngDownloadRef.href = canvas.toDataURL();
        pngDownloadRef.download = 'QRCode.png';
    }, [pngDownloadRef]);

    const onSVGDownloadClick = useCallback(() => {
        const svg = document.getElementById('svg');
        const svgXML = new XMLSerializer().serializeToString(svg);
        const dataUrl = `data:image/svg,${encodeURIComponent(svgXML)}`;
        svgDownloadRef.href = dataUrl;
        svgDownloadRef.download = 'QRCode.svg';
    }, [svgDownloadRef]);

    return (
        <div className={css.qrCodeContainer}>
            <div className={css.qrCodeTopArea}>
                <div className={css.qrCodeLabel}>
                    <Translate id={label} />
                </div>
                {value && (
                    <>
                        <a
                            className={css.downloadLink}
                            ref={setPNGDownloadRef}
                            onClick={onPNGDownloadClick}
                        >
                            <Translate id={downloadPNG} />
                        </a>
                        <a
                            className={css.downloadLink}
                            ref={setSVGDownloadRef}
                            onClick={onSVGDownloadClick}
                        >
                            <Translate id={downloadSVG} />
                        </a>
                    </>
                )}
            </div>
            <div className={css.qrCodeBox}>
                <div className={css.qrCodeInnerBox}>
                    {value && <QRCode value={value} size={size} renderAs="canvas" />}
                </div>
                <div hidden>{value && <QRCode id="png" includeMargin={true} value={value} size={DOWNLOAD_SIZE} renderAs="canvas" />}</div>
                <div hidden>{value && <QRCode id="svg" includeMargin={true} value={value} size={DOWNLOAD_SIZE} renderAs="svg" />}</div>
            </div>
        </div>
    );
}

QRCodeWidget.propTypes = {
    label: PropTypes.string,
    downloadPNG: PropTypes.string,
    downloadSVG: PropTypes.string,
    value: PropTypes.string,
    size: PropTypes.number,
    onDownloadClicked: PropTypes.func,
};

QRCodeWidget.defaultProps = {
    label: '',
    downloadPNG: 'DOWNLOAD_PNG',
    downloadSVG: 'DOWNLOAD_SVG',
    value: '',
    size: 115,
    onDownloadClicked: () => {},
};

export default QRCodeWidget;
