import React from 'react';

class WizardPage extends React.Component {
    render() {
        const { children, buttons } = this.props;
        const childrenUse = Array.isArray(children) ? children : [children];
        return (
            <div style={{ flex: 1, overflow: 'auto', paddingBottom: 95 }}>
                {childrenUse.map((child, index) => {
                    let ret = [child, buttons];
                    if (typeof child === 'object') {
                        ret = <child.type key={`wizardPage_child_${index}`} {...this.props} />;
                    }
                    return ret;
                })}
            </div>
        );
    }
}

export default WizardPage;
