import { connect } from 'react-redux';
import DataSourcesPage from './components/DataSourcesPage';
import { getDataSourcesPage, getAdNetworks } from './selector';
import {
    export2Phase,
    loginVerificationFinished,
    openShelf,
    closeShelf,
    deleteUan,
    deleteUserSlackAuth,
} from './actions';

const mapStateToProps = state => {
    return { ...getDataSourcesPage(state), adnetworks: getAdNetworks(state) };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    export2Phase: uanId => dispatch(export2Phase(uanId)),
    onValidationFinished: uanId => dispatch(loginVerificationFinished(uanId)),
    openShelf: (adnetDisplayName, uanId) => dispatch(openShelf(adnetDisplayName, uanId)),
    onCloseShelf: () => dispatch(closeShelf()),
    onUanDelete: uanId => dispatch(deleteUan(uanId)),
    onDeleteUserSlackAuth: () => dispatch(deleteUserSlackAuth()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSourcesPage);
