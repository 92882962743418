import moment from 'moment';

export const QUICK_REPORTS_TYPES = {
    CAMPAIGN: 'campaign',
};

export const CAMPAIGN_REPORT_MIXPANEL_PREFIX = 'Campaign Report';
export const SELECT_DATE_RANGE_MIXPANEL_EVENT_NAME = 'Selected date filter';

export const MIN_DATE_PICKER_DATE = moment().subtract(1, 'Q');
