import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import { Translate, withLocalize } from 'react-localize-redux';
import { Spinner, WarningMessage, Menu, Shelf, WizardFooter, SingularButton } from '../../components/widgets';
import PlusCircleIcon from '../../resources/svg/add.svg';
import { FILTER_TYPES } from '../consts';
import Details from './Details';
import Settings from './Settings';
import Filter from './Filter';
import css from './Edit.pcss';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => {
            return i * 50;
        },
    },
    exit: {
        y: 20,
        opacity: 0,
    },
});

function moveToView(element, parent, container) {
    const footerHeight = 84;
    // Get container properties
    const cTop = container.scrollTop;
    const cBottom = cTop + container.clientHeight - footerHeight;

    // Get element properties
    const eTop = element.offsetTop;
    const pTop = parent.offsetTop;
    const totalTop = eTop + pTop;
    const eBottom = totalTop + element.clientHeight;

    const elementVisible = totalTop >= cTop && eBottom <= cBottom;
    // Check if in view
    if (!elementVisible) {
        container.scrollTop += eBottom - cBottom + footerHeight;
    }
}

class AudienceSegmentsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.addFilter = this._addFilter.bind(this);
        this.addCondition = this._addCondition.bind(this);
        this.editConfirm = this._editConfirm.bind(this);
        this.editDetails = this._editDetails.bind(this);
        this.addFilter = this._addFilter.bind(this);
        this.editFilter = this._editFilter.bind(this);
        this.deleteFilter = this._deleteFilter.bind(this);
        this.addCondition = this._addCondition.bind(this);
        this.editCondition = this._editCondition.bind(this);
        this.deleteCondition = this._deleteCondition.bind(this);
        this.editCancel = this._editCancel.bind(this);

        this.buttons = {
            cancel: {
                text: 'Cancel',
                disabled: false,
                type: 'flat',
            },
            finish: {
                text: 'Finish',
                type: 'primary',
            },
        };

        this.editContainer = React.createRef();
    }

    _editConfirm() {
        this.props.onEditConfirm(this.props.editId, this.props.edit);
    }

    _addFilter(filterType) {
        this.props.onAddFilter(filterType);
        window.setTimeout(() => {
            this.editContainer.current.scrollTop = this.editContainer.current.scrollHeight;
        }, 0);
    }

    _editFilter(index, key, value) {
        this.props.onEditFilter(index, key, value);
    }

    _deleteFilter(index) {
        this.props.onDeleteFilter(index);
    }

    _editCondition(index, cIndex, key, value) {
        this.props.onEditCondition(index, cIndex, key, value);
    }

    _deleteCondition(index, cIndex) {
        this.props.onDeleteCondition(index, cIndex);
    }

    _addCondition(index) {
        setTimeout(() => {
            const filterRef = this[`filter_${index}`];
            const lastCondition = filterRef.container.current.querySelector(`.${css.condition}:last-child`);
            moveToView(lastCondition, filterRef.container.current, this.editContainer.current);
        }, 50);
        const filterType = this.props.edit.filters[index].filter_type;
        this.props.onAddCondition(index, filterType);
    }

    _editDetails(key, value) {
        this.props.onEditDetails(key, value);
    }

    _editCancel() {
        const { editDirty, translate, onEditCancel, editId } = this.props;
        if (editDirty) {
            if (!confirm(translate('STATIC.PAGES.AUDIENCES.UNSAVED_MESSAGE'))) {
                return;
            }
        }
        onEditCancel(editId);
    }

    render() {
        const { editId, edit, editInvalidMsg, editDirty, metadata, saving } = this.props;
        let conditions = 0;
        return (
            <Shelf
                contentRef={(e) => {
                    this.contentRef = e;
                }}
                open={!!editId}
                headerText={
                    editId === -1 ? 'STATIC.PAGES.AUDIENCES.NEW_SEGMENT' : 'STATIC.PAGES.AUDIENCES.EDIT_SEGMENT'
                }
                onClose={this.editCancel}
                shelfSize="medium"
            >
                {!edit || saving ? (
                    <Spinner show expanded />
                ) : (
                    <div className={css.container}>
                        <WarningMessage
                            show={!metadata && !editInvalidMsg}
                            showIcon={false}
                            message="STATIC.PAGES.AUDIENCES.EDIT_INVALID_WARNING"
                        />
                        <WarningMessage
                            show={!!editInvalidMsg}
                            message={editInvalidMsg || ''}
                            showIcon={false}
                            type="error"
                        />
                        <div className={css.editContainer} ref={this.editContainer}>
                            <div className={css.edit}>
                                <div className={css.line} />
                                <div>
                                    <div className={css.dot} />
                                    <Details
                                        edit={edit}
                                        invalid={!!editInvalidMsg}
                                        metadata={metadata}
                                        onChange={this.editDetails}
                                    />
                                    <Settings
                                        edit={edit}
                                        invalid={!!editInvalidMsg}
                                        metadata={metadata}
                                        onChange={this.editDetails}
                                    />
                                    <PoseGroup animateOnMount flipMove={false}>
                                        {edit.filters &&
                                            edit.filters.map((filter, i) => (
                                                <AnimationItem
                                                    key={`filter_${filter.filter_type}_${filter.id}`}
                                                    i={conditions++}
                                                >
                                                    <Filter
                                                        index={i}
                                                        filter={filter}
                                                        metadata={metadata}
                                                        editFilter={this.editFilter}
                                                        deleteFilter={this.deleteFilter}
                                                        editCondition={this.editCondition}
                                                        addCondition={this.addCondition}
                                                        deleteCondition={this.deleteCondition}
                                                        apps={edit.apps}
                                                        invalid={!!editInvalidMsg}
                                                        ref={(el) => {
                                                            this[`filter_${i}`] = el;
                                                        }}
                                                    />
                                                </AnimationItem>
                                            ))}
                                    </PoseGroup>
                                    <div className={css.addFilter}>
                                        <div className={`${css.dot} ${css.bottomDot}`} />
                                        <div className={css.dottedLine} />
                                        <div className={css.addDot}>
                                            <PlusCircleIcon style={{ width: 17 }} className={css.addDotIcon} />
                                        </div>
                                        <Menu
                                            containerClass={css.addFilterDropdown}
                                            buttonClass={css.addFilterDropdownButton}
                                            buttonLabel="Add a Filter"
                                            buttonIconStyle={{
                                                height: '10px',
                                                marginLeft: '4px',
                                                marginTop: '1px',
                                            }}
                                            items={Object.keys(FILTER_TYPES).map((f) => ({
                                                value: FILTER_TYPES[f],
                                                label: `${FILTER_TYPES[f].charAt(0).toUpperCase() +
                                                    FILTER_TYPES[f].substr(1)} Filter`,
                                                onClick: () => {
                                                    this.addFilter(FILTER_TYPES[f]);
                                                },
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <WizardFooter
                    buttons={[
                        <SingularButton {...this.buttons.cancel} onClick={this.editCancel}>
                            <Translate id="STATIC.BUTTONS.CANCEL" />
                        </SingularButton>,
                        <SingularButton
                            {...this.buttons.finish}
                            onClick={this.editConfirm}
                            disabled={!editDirty || !!editInvalidMsg}
                        >
                            <Translate id={editId === -1 ? 'STATIC.BUTTONS.SAVE' : 'STATIC.BUTTONS.UPDATE'} />
                        </SingularButton>,
                    ]}
                />
            </Shelf>
        );
    }
}

AudienceSegmentsEdit.propTypes = {
    editId: PropTypes.number,
    edit: PropTypes.objectOf(PropTypes.any),
    editInvalidMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    editDirty: PropTypes.bool,
    saving: PropTypes.bool,
    metadata: PropTypes.objectOf(PropTypes.any),
    onEditConfirm: PropTypes.func,
    onEditCancel: PropTypes.func,
    onEditDetails: PropTypes.func,
    onAddFilter: PropTypes.func,
    onEditFilter: PropTypes.func,
    onDeleteFilter: PropTypes.func,
    onAddCondition: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDeleteCondition: PropTypes.func,
};
AudienceSegmentsEdit.defaultProps = {
    editId: null,
    edit: null,
    editInvalidMsg: false,
    editDirty: false,
    saving: false,
    metadata: {},
    onEditConfirm: () => {},
    onEditCancel: () => {},
    onEditDetails: () => {},
    onEditFilter: () => {},
    onDeleteFilter: () => {},
    onAddCondition: () => {},
    onEditCondition: () => {},
    onDeleteCondition: () => {},
    onAddFilter: () => {},
};

export default withLocalize(AudienceSegmentsEdit);
