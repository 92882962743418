import API from '../services/api';

let instance = null;
const api = new API();
const URL_TRACKING_LINKS_DISCOVER = '/api/web-tracking/tracking-links/discover';
const URL_TRACKING_LINKS_GENERATE_LINK = '/api/web-tracking/tracking-links/generate';

export class WebTrackingLinks {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    trackingLinksDiscover() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_TRACKING_LINKS_DISCOVER, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    trackingLinksPartnerDiscover(adNetworkId, language = 'en') {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_TRACKING_LINKS_DISCOVER}/${adNetworkId}`, {
                method: 'GET',
                credentials: 'include',
                params: { language },
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    trackingLinksGenerateLink(targetId, adNetworkId, url, attributes, landingPage, reEnabled) {
        console.log(`trackingLinksGenerateLink: ${JSON.stringify([adNetworkId, url, attributes, reEnabled])}`);
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_TRACKING_LINKS_GENERATE_LINK}/${targetId}/${adNetworkId}`, {
                method: 'POST',
                body: {
                    url,
                    attributes,
                    landingPage,
                    reEnabled,
                },
                credentials: 'include',
            })
                .then(response => {
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default WebTrackingLinks;
