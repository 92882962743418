import { EmptyStateImageSrc, EmptyStateTasks } from '../components/partials/EmptyState/utils';
import Note from '../resources/svg/light_bulb.svg';

export const EMPTY_STATE_TOP_PARTNERS = [345, 124, 109475, 596, 3902, 353, 97172, 412, 1361];

export const shouldShowEmptyState = ({ tasks }) => {
    return !(
        tasks[EmptyStateTasks.ADD_APP] &&
        tasks[EmptyStateTasks.SDK_INTEGRATED] &&
        tasks[EmptyStateTasks.PROD_APPS_WITH_SUPPORTED_ATTRIBUTION_PLATFORM_EXISTS]
    );
};

const getEmptyStateTasks = tasks => {
    const hasSdkIntegratedApp = tasks[EmptyStateTasks.ADD_APP] && tasks[EmptyStateTasks.SDK_INTEGRATED];

    if (!hasSdkIntegratedApp) {
        return [
            {
                topic: EmptyStateTasks.ADD_APP,
                name: 'STATIC.EMPTY_STATE.TASKS.ADD_APP_TASK',
                goRedirect: '#/apps?new-app=1',
            },
            {
                topic: EmptyStateTasks.SDK_INTEGRATED,
                name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK',
                goRedirect: '#/react/sdk_integration',
            },
            {
                name: 'STATIC.EMPTY_STATE.TASKS.PARTNER_CONFIGURATION_DESCRIPTION',
                disabled: true,
            },
        ];
    }

    if (!tasks[EmptyStateTasks.SUPPORTED_ATTRIBUTION_APP_EXISTS]) {
        return [
            {
                topic: EmptyStateTasks.SUPPORTED_ATTRIBUTION_APP_EXISTS,
                name: 'STATIC.EMPTY_STATE.TASKS.SUPPORTED_ATTRIBUTION_APP_EXISTS_TASK',
                goRedirect: '#/apps?new-app=1',
                icon: Note,
            },
        ];
    }

    if (!tasks[EmptyStateTasks.PROD_APPS_EXISTS]) {
        return [
            {
                topic: EmptyStateTasks.PROD_APPS_EXISTS,
                name: 'STATIC.EMPTY_STATE.TASKS.PROD_APPS_EXISTS_TASK',
                goRedirect: '#/apps?new-app=1',
                icon: Note,
            },
        ];
    }

    if (!tasks[EmptyStateTasks.PROD_APPS_WITH_SUPPORTED_ATTRIBUTION_PLATFORM_EXISTS]) {
        return [
            {
                topic: EmptyStateTasks.PROD_APPS_WITH_SUPPORTED_ATTRIBUTION_PLATFORM_EXISTS,
                name: 'STATIC.EMPTY_STATE.TASKS.PROD_APPS_WITH_SUPPORTED_ATTRIBUTION_PLATFORM_EXISTS_TASK',
                goRedirect: '#/apps?new-app=1',
                icon: Note,
            },
        ];
    }
    return [];
};

export const getPartnerConfigurationEmptyStateData = ({ tasks }) => ({
    titleText: 'STATIC.EMPTY_STATE.TITLE_PARTNER_CONFIGURATION',
    subTitleText: `STATIC.EMPTY_STATE.SUB_TITLE.PARTNER_CONFIGURATION`,
    imageSrc: EmptyStateImageSrc.SINGULAR_DUDE,
    tasks: getEmptyStateTasks(tasks),
});
