import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import {
    androidstudio as darkModeTheme,
    CodeBlock as ReactCodeBlocks,
    tomorrow as lightModeTheme,
} from 'react-code-blocks';
import classnames from 'classnames';
import { CODE_BLOCK_SNIPPETS_LANGUAGES, HIDE_PLACEHOLDERS, SHOW_PLACEHOLDERS } from './consts';
import CodeBlockHeader from './CodeBlockHeader';
import Snackbar from '../../widgets/Snackbar/Snackbar';
import css from './codeBlock.css';
import { findLowestNumber, formatCodeBlockText, formatHighlightsRows } from './utils';

const CodeBlock = ({
    initialCode,
    placeholders,
    codeLanguage,
    rowsHighlight,
    customStyle,
    showLineNumbers,
    hidePlaceholders,
    showHeader,
    darkTheme,
    translate,
    ShowHideButtonVisibility,
    ShowHideButtonTitle,
    onCodeBlockThemeToggle,
    customTitle,
    disableScrollToLine,
    containerClassName,
    languageItems,
    handleOnLanguageChange,
    onPlaceholdersVisibilityChange,
    onCopyToClipboard,
}) => {
    const codeBlockRef = useRef(null);
    const [toggleTheme, setToggleTheme] = useState(() => darkTheme);
    const [togglePlaceholders, setTogglePlaceholders] = useState(() => hidePlaceholders);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const code = useMemo(
        () => ({
            [SHOW_PLACEHOLDERS]: formatCodeBlockText({ text: initialCode, keys: placeholders, hideKeys: false }),
            [HIDE_PLACEHOLDERS]: formatCodeBlockText({ text: initialCode, keys: placeholders, hideKeys: true }),
        }),
        [initialCode, placeholders]
    );

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(code[SHOW_PLACEHOLDERS]);
        setShowSnackbar(true);
        onCopyToClipboard && onCopyToClipboard();
    };

    const handleToggleTheme = () => {
        const toggle = !toggleTheme;
        onCodeBlockThemeToggle && onCodeBlockThemeToggle(toggle);
        setToggleTheme(toggle);
    };

    const handleTogglePlaceholders = () => {
        const visibility = !togglePlaceholders;
        setTogglePlaceholders(visibility);
        onPlaceholdersVisibilityChange && onPlaceholdersVisibilityChange(visibility);
    };

    const isPlaceholdersExist = () => {
        return Boolean(Object.keys(placeholders)?.length);
    };

    const scrollToLine = useCallback((lineToScroll = null) => {
        try {
            if (!lineToScroll) {
                return;
            }

            const codeblockElement = codeBlockRef.current.querySelector('span');
            const scrollThreshold = codeblockElement.offsetHeight / 2;
            const lines = codeBlockRef.current.querySelectorAll('.react-syntax-highlighter-line-number') || null;
            let linesPixelsHeightSum = 0;

            if (lines?.length && codeBlockRef.current) {
                const lineElement = Array.from(lines).find(el => {
                    linesPixelsHeightSum += el.offsetHeight;
                    return el.textContent === lineToScroll;
                });
                if (lineElement) {
                    const top = linesPixelsHeightSum > scrollThreshold ? linesPixelsHeightSum : 0;
                    codeblockElement.scrollTo({
                        top,
                        behavior: 'smooth',
                    });
                }
            }
        } catch (e) {
            console.error('scrollToLine Error:', e.message);
        }
    }, []);

    useEffect(() => {
        if (disableScrollToLine) return;
        scrollToLine(findLowestNumber(rowsHighlight));
    }, [rowsHighlight]);

    return !initialCode ? null : (
        <div className={classnames([css.container, containerClassName])}>
            {showHeader && (
                <CodeBlockHeader
                    codeLanguage={codeLanguage}
                    togglePlaceholders={togglePlaceholders}
                    toggleTheme={darkTheme ?? toggleTheme}
                    handleToggleTheme={handleToggleTheme}
                    handleTogglePlaceholders={handleTogglePlaceholders}
                    handleCopyToClipboard={handleCopyToClipboard}
                    ShowHideButtonVisibility={ShowHideButtonVisibility}
                    ShowHideButtonTitle={ShowHideButtonTitle}
                    placeholdersExist={isPlaceholdersExist()}
                    customTitle={customTitle}
                    languageItems={languageItems}
                    handleOnLanguageChange={handleOnLanguageChange}
                />
            )}
            <span ref={codeBlockRef} className={css.codeBlockWrapper}>
                <ReactCodeBlocks
                    text={code[togglePlaceholders ? HIDE_PLACEHOLDERS : SHOW_PLACEHOLDERS]}
                    language={codeLanguage}
                    customStyle={customStyle || {}}
                    theme={darkTheme ?? toggleTheme ? darkModeTheme : lightModeTheme}
                    highlight={formatHighlightsRows(rowsHighlight)}
                    showLineNumbers={showLineNumbers}
                />
            </span>
            {showSnackbar && (
                <Snackbar
                    autoHideDuration={3000}
                    onClose={() => setShowSnackbar(false)}
                    open
                    title={translate('STATIC.TOASTS.CODEBLOCK_COPY_TO_CLIPBOARD')}
                    type="success"
                    message=""
                />
            )}
        </div>
    );
};

export default withLocalize(CodeBlock);

CodeBlock.propTypes = {
    ShowHideButtonTitle: PropTypes.string,
    ShowHideButtonVisibility: PropTypes.bool,
    initialCode: PropTypes.string,
    placeholders: PropTypes.object,
    hidePlaceholders: PropTypes.bool,
    customStyle: PropTypes.objectOf(PropTypes.any),
    codeLanguage: PropTypes.oneOf(Object.values(CODE_BLOCK_SNIPPETS_LANGUAGES).map(i => i)).isRequired,
    showLineNumbers: PropTypes.bool,
    rowsHighlight: PropTypes.string,
    darkTheme: PropTypes.bool,
    showHeader: PropTypes.bool,
    translate: PropTypes.func,
    onCodeBlockThemeToggle: PropTypes.func,
    customTitle: PropTypes.string,
    disableScrollToLine: PropTypes.bool,
    containerClassName: PropTypes.string,
    languageItems: PropTypes.arrayOf(
        PropTypes.shape({
            codeLanguage: PropTypes.oneOf(Object.values(CODE_BLOCK_SNIPPETS_LANGUAGES).map(i => i)),
            displayTabTitle: PropTypes.string,
        })
    ),
    handleOnLanguageChange: PropTypes.func,
    onPlaceholdersVisibilityChange: PropTypes.func,
    onCopyToClipboard: PropTypes.func,
};

CodeBlock.defaultProps = {
    ShowHideButtonTitle: '',
    ShowHideButtonVisibility: true,
    initialCode: undefined,
    placeholders: {},
    customStyle: {},
    showLineNumbers: false,
    rowsHighlight: '',
    darkTheme: null,
    showHeader: true,
    hidePlaceholders: true,
    translate: () => {},
    onCodeBlockThemeToggle: undefined,
    customTitle: '',
    disableScrollToLine: false,
    containerClassName: '',
    languageItems: undefined,
    handleOnLanguageChange: undefined,
    onPlaceholdersVisibilityChange: undefined,
    onCopyToClipboard: undefined,
};
