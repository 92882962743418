export const CUSTOMDIMENSIONS_PAGE_LOADED = 'action.events.customdimensions.page.loaded';
export const CUSTOMDIMENSIONS_PAGE_UNLOAD = 'action.events.customdimensions.page.unload';
export const CUSTOMDIMENSION_CONDITION_ADDED = 'action.events.customdimension.condition.added';
export const CUSTOMDIMENSION_CONDITION_REMOVED = 'action.events.customdimension.condition.removed';
export const CUSTOMDIMENSION_CONDITION_VALUE_CHANGED = 'action.events.customdimension.condition.value.changed';
export const CUSTOMDIMENSION_CONDITION_TAG_ADDED = 'action.events.customdimension.condition.tag.added';
export const CUSTOMDIMENSION_CONDITION_TAG_REMOVED = 'action.events.customdimension.condition.tag.removed';
export const CUSTOMDIMENSION_CONDITION_DIMENSION_CHANGED = 'action.events.customdimension.condition.dimension.changed';
export const CUSTOMDIMENSION_CONDITION_OPERATOR_CHANGED = 'action.events.customdimension.condition.operator.changed';
export const CUSTOMDIMENSION_DIMENSIONS_LOADED = 'action.events.customdimension.dimensions.loaded';
export const CUSTOMDIMENSION_DIMENSIONS_RELATED_LOADED = 'action.events.customdimension.dimensions.related.loaded';
export const CUSTOMDIMENSION_OPERATORS_LOADED = 'action.events.customdimension.operators.loaded';
export const CUSTOM_DIMENSIONS_LOADED = 'action.events.customdimension.customdimensions.loaded';
export const CUSTOMDIMENSION_RULE_ADDED = 'action.events.customdimension.rule.added';
export const CUSTOMDIMENSION_RULE_REMOVED = 'action.events.customdimension.rule.removed';
export const CUSTOMDIMENSION_VALUE_ADDED = 'action.events.customdimension.value.added';
export const CUSTOMDIMENSION_VALUE_REMOVED = 'action.events.customdimension.value.removed';
export const CUSTOMDIMENSION_VALUE_NAME_CHANGED = 'action.events.customdimension.value.value.changed';
export const CUSTOMDIMENSION_VALUES_REORDERED = 'action.events.customdimension.values.reordered';
export const CUSTOMDIMENSION_DIMENSION_EXPANSION_TOGGLE = 'action.events.customdimension.dimension.expansion.toggle';
export const CUSTOMDIMENSION_DIMENSION_NAME_CHANGED = 'action.events.customdimension.dimension.name.changed';
export const CUSTOMDIMENSION_DEFAULT_VALUE_CHANGED = 'action.events.customdimension.defaultvalue.changed';
export const CUSTOMDIMENSION_DIMENSION_ADDED = 'action.events.customdimension.dimension.added';
export const CUSTOMDIMENSION_DIMENSION_REMOVE_SEND_TO_BACKEND = 'action.events.customdimension.dimension.remove.send';
export const CUSTOMDIMENSION_DIMENSION_REMOVED = 'action.events.customdimension.dimension.remove.succeeded';
export const CUSTOMDIMENSION_DIMENSION_REMOVE_ATTEMPTED = 'action.events.customdimension.dimension.remove.attempted';
export const CUSTOMDIMENSION_DIMENSION_REMOVE_REJECTED = 'action.events.customdimension.dimension.remove.rejected';
export const CUSTOMDIMENSION_DIMENSION_UNDO_CHANGES = 'action.events.customdimension.dimension.changes.revert';
export const CUSTOMDIMENSION_DIMENSION_SAVE_START = 'action.events.customdimension.dimension.changes.save.start';
export const CUSTOMDIMENSION_DIMENSION_SAVE_ERROR = 'action.events.customdimension.dimension.changes.save.error';
export const CUSTOMDIMENSION_DIMENSION_SAVE_DONE = 'action.events.customdimension.dimension.changes.save.done';
export const CUSTOMDIMENSION_DIMENSION_SAVE_STATUS_RESET =
    'action.events.customdimension.dimension.changes.save.reset_status';
export const CUSTOMDIMENSION_FILTER_CHANGED = 'action.events.customdimension.filter.changed';
export const CUSTOMDIMENSION_TAB_SWITCH = 'action.events.customdimension.tab.switch';

export const customDimensionsPageLoaded = () => {
    return {
        type: CUSTOMDIMENSIONS_PAGE_LOADED,
    };
};

export const customDimensionsPageUnLoad = () => {
    return {
        type: CUSTOMDIMENSIONS_PAGE_UNLOAD,
    };
};

export const conditionAdded = parentId => {
    return {
        type: CUSTOMDIMENSION_CONDITION_ADDED,
        parentId,
    };
};

export const tabSwitch = id => ({
    type: CUSTOMDIMENSION_TAB_SWITCH,
    id,
});

export const conditionRemoved = id => {
    return {
        type: CUSTOMDIMENSION_CONDITION_REMOVED,
        id,
    };
};

export const conditionTagAdded = (id, value) => {
    return {
        type: CUSTOMDIMENSION_CONDITION_TAG_ADDED,
        id,
        value,
    };
};

export const conditionTagRemoved = (id, value) => {
    return {
        type: CUSTOMDIMENSION_CONDITION_TAG_REMOVED,
        id,
        value,
    };
};

export const conditionValueChanged = (id, value) => {
    return {
        type: CUSTOMDIMENSION_CONDITION_VALUE_CHANGED,
        id,
        value,
    };
};

export const conditionDimensionChanged = (id, value) => {
    return {
        type: CUSTOMDIMENSION_CONDITION_DIMENSION_CHANGED,
        id,
        value,
    };
};

export const conditionOperatorChanged = (id, value) => {
    return {
        type: CUSTOMDIMENSION_CONDITION_OPERATOR_CHANGED,
        id,
        value,
    };
};

export const dimensionsLoaded = dimensions => {
    return {
        type: CUSTOMDIMENSION_DIMENSIONS_LOADED,
        dimensions,
    };
};

export const dimensionsRelatedLoaded = dimensionsRelated => {
    return {
        type: CUSTOMDIMENSION_DIMENSIONS_RELATED_LOADED,
        dimensionsRelated,
    };
};

export const operatorsLoaded = operators => {
    return {
        type: CUSTOMDIMENSION_OPERATORS_LOADED,
        operators,
    };
};

export const customDimensionsLoaded = (
    dimensions,
    maxDimensions,
    maxConditionsPerDimension,
    supportedMacroDimensions,
    v3Dimensions,
    tabsToShow
) => {
    return {
        type: CUSTOM_DIMENSIONS_LOADED,
        dimensions,
        maxDimensions,
        maxConditionsPerDimension,
        supportedMacroDimensions,
        v3Dimensions,
        tabsToShow,
    };
};

export const ruleAdded = parentId => {
    return {
        type: CUSTOMDIMENSION_RULE_ADDED,
        parentId,
    };
};

export const ruleRemoved = id => {
    return {
        type: CUSTOMDIMENSION_RULE_REMOVED,
        id,
    };
};

export const valueAdded = dimensionId => {
    return {
        type: CUSTOMDIMENSION_VALUE_ADDED,
        dimensionId,
    };
};

export const valueRemoved = id => {
    return {
        type: CUSTOMDIMENSION_VALUE_REMOVED,
        id,
    };
};

export const valueNameChanged = (id, value) => {
    return {
        type: CUSTOMDIMENSION_VALUE_NAME_CHANGED,
        id,
        value,
    };
};

export const dimensionExpansionToggle = id => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_EXPANSION_TOGGLE,
        id,
    };
};

export const dimensionNameChanged = (id, value) => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_NAME_CHANGED,
        id,
        value,
    };
};

export const defaultValueChanged = (id, value) => {
    return {
        type: CUSTOMDIMENSION_DEFAULT_VALUE_CHANGED,
        id,
        value,
    };
};

export const customDimensionAdded = () => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_ADDED,
    };
};

export const customDimensionRemoved = () => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_REMOVED,
    };
};

export const customDimensionRemoveSend = () => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_REMOVE_SEND_TO_BACKEND,
    };
};

export const customDimensionRemoveAttempted = id => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_REMOVE_ATTEMPTED,
        id,
    };
};

export const customDimensionRemoveRejected = () => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_REMOVE_REJECTED,
    };
};

export const customDimensionUndoChanges = id => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_UNDO_CHANGES,
        id,
    };
};

export const customDimensionSave = (id, reordered) => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_SAVE_START,
        id,
        reordered,
    };
};

export const customDimensionSaveError = id => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_SAVE_ERROR,
        id,
    };
};

export const customDimensionSaveDone = (config, id) => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_SAVE_DONE,
        config,
        id,
    };
};

export const customDimensionSaveStatusReset = id => {
    return {
        type: CUSTOMDIMENSION_DIMENSION_SAVE_STATUS_RESET,
        id,
    };
};

export const filterChanged = value => {
    return {
        type: CUSTOMDIMENSION_FILTER_CHANGED,
        value,
    };
};

export const onValuesReordered = (id, values) => {
    return {
        type: CUSTOMDIMENSION_VALUES_REORDERED,
        id,
        values,
    };
};
