import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { EmptyState } from '../../widgets';
import css from './ListItemsContainer.css';
import {
    calculateCompletionStatus,
    calculateExpandedCategoryId,
    getFlattenedItemKeyToItem,
    validateNestedCount,
} from './utils';
import Tooltip from '../../widgets/Tooltip';
import ListItem from './components/ListItem';
import NavigationHeader from './components/NavigationHeader';

const ListItemsContainer = React.forwardRef(
    (
        {
            title,
            subTitle,
            showCheckedIcon,
            listItems,
            pageContainerClassName,
            sectionContentClassName,
            sectionListClassName,
            isSectionsNavigationEnabled,
            disabledList,
            disabledListDescription,
            onClickNextButton,
            onClickBackButton,
            activeItem,
            isExpandable,
            onClickListItem,
            sectionContentContainerClassName,
        },
        ref
    ) => {
        const flatListItemsObject = useMemo(() => getFlattenedItemKeyToItem(listItems, isExpandable), [
            listItems,
            isExpandable,
        ]);
        const flatListItemsKeys = useMemo(() => Object.keys(flatListItemsObject), [flatListItemsObject]);

        const [activeItemId, setActiveItemId] = useState(flatListItemsKeys.length ? flatListItemsKeys[0] : null);
        const [expandedCategoryId, setExpandedCategoryId] = useState(
            calculateExpandedCategoryId(listItems, flatListItemsObject, activeItemId, null)
        );

        const activeItemIndex = useMemo(() => flatListItemsKeys.indexOf(activeItemId), [
            flatListItemsKeys,
            activeItemId,
        ]);

        const handleOnClick = useCallback(
            (id, disabled) => {
                if (!disabled) {
                    if (isExpandable) {
                        setExpandedCategoryId(
                            calculateExpandedCategoryId(listItems, flatListItemsObject, id, expandedCategoryId)
                        );
                    }

                    const clickedItem = flatListItemsObject[id];
                    if (!clickedItem.content && listItems[id] && listItems[id].nestedItems) {
                        const { nestedItems } = listItems[id];
                        const nestedItemKeys = Object.keys(nestedItems);

                        if (nestedItemKeys.length > 0) {
                            for (let i = 0; i < nestedItemKeys.length; i++) {
                                const nestedItemKey = nestedItemKeys[i];
                                const nestedItem = flatListItemsObject[nestedItemKey];

                                if (!nestedItem.disabled) {
                                    setActiveItemId(nestedItemKey);
                                    onClickListItem(nestedItemKey);

                                    if (isExpandable) {
                                        setExpandedCategoryId(id);
                                    }
                                    return;
                                }
                            }
                        }
                    }

                    setActiveItemId(id);
                    onClickListItem(id);
                }
            },
            [listItems, isExpandable, expandedCategoryId]
        );

        const handleOnClickNext = useCallback(() => {
            let calculatedActiveItemId;
            for (let i = activeItemIndex + 1; i < flatListItemsKeys.length; i++) {
                const nextItem = flatListItemsObject[flatListItemsKeys[i]];
                if (!nextItem.disabled) {
                    calculatedActiveItemId = flatListItemsKeys[i];
                    setActiveItemId(calculatedActiveItemId);
                    onClickNextButton && onClickNextButton(flatListItemsObject, flatListItemsKeys[i]);
                    break;
                }
            }

            if (isExpandable) {
                setExpandedCategoryId(
                    calculateExpandedCategoryId(
                        listItems,
                        flatListItemsObject,
                        calculatedActiveItemId,
                        expandedCategoryId
                    )
                );
            }
        }, [
            activeItemIndex,
            flatListItemsKeys,
            flatListItemsObject,
            onClickNextButton,
            isExpandable,
            listItems,
            expandedCategoryId,
        ]);

        const handleOnClickBack = useCallback(() => {
            let calculatedActiveItemId;

            for (let i = activeItemIndex - 1; i >= 0; i--) {
                const prevItem = flatListItemsObject[flatListItemsKeys[i]];
                if (!prevItem.disabled) {
                    calculatedActiveItemId = flatListItemsKeys[i];
                    setActiveItemId(calculatedActiveItemId);
                    onClickBackButton && onClickBackButton(flatListItemsObject, flatListItemsKeys[i]);
                    break;
                }
            }

            if (isExpandable) {
                setExpandedCategoryId(
                    calculateExpandedCategoryId(
                        listItems,
                        flatListItemsObject,
                        calculatedActiveItemId,
                        expandedCategoryId
                    )
                );
            }
        }, [
            activeItemIndex,
            flatListItemsKeys,
            flatListItemsObject,
            onClickBackButton,
            isExpandable,
            listItems,
            expandedCategoryId,
        ]);

        const generateListItemsMenu = useCallback(
            (items, nestedCount = 0) => {
                return (
                    <List
                        component="div"
                        disablePadding
                        className={classNames(css.sectionList, sectionListClassName, { [css.nested]: nestedCount > 0 })}
                    >
                        {Object.keys(items).map(itemKey => {
                            if (!flatListItemsObject[itemKey]) {
                                return null;
                            }

                            const { disabled = false, tooltipContent = undefined } = flatListItemsObject[itemKey];
                            const { nestedItems = {} } = items[itemKey] || {};
                            const hasNestedItems = nestedItems && Object.keys(nestedItems).length > 0;
                            const isActiveItem = activeItemId === itemKey && !disabledList;
                            const isExpandedCategory = expandedCategoryId === itemKey && !disabledList;

                            const itemIndex = flatListItemsKeys.indexOf(itemKey);
                            const isStepPassed =
                                itemIndex !== -1 && activeItemId && itemIndex < flatListItemsKeys.indexOf(activeItemId);

                            const isCompleted = calculateCompletionStatus(
                                items,
                                itemKey,
                                flatListItemsObject,
                                flatListItemsKeys,
                                activeItemId
                            );

                            const listItem = (
                                <>
                                    <MemoizedListItem
                                        isExpandable={isExpandable && hasNestedItems}
                                        isExpanded={isExpandedCategory}
                                        isActive={isActiveItem}
                                        itemData={{
                                            ...flatListItemsObject[itemKey],
                                            disabled: disabledList || disabled,
                                            completed: isCompleted,
                                            nestedCount,
                                        }}
                                        isStepPassed={isStepPassed}
                                        handleOnClick={handleOnClick}
                                        showCheckedIcon={showCheckedIcon}
                                        itemKey={itemKey}
                                        key={itemKey}
                                    />
                                    {hasNestedItems && validateNestedCount(nestedCount, isExpandable) && (
                                        <Collapse in={isExpandedCategory || !isExpandable}>
                                            {generateListItemsMenu(nestedItems, nestedCount + 1)}
                                        </Collapse>
                                    )}
                                </>
                            );

                            return !disabled || disabledList ? (
                                <React.Fragment key={itemKey}>{listItem}</React.Fragment>
                            ) : (
                                <Tooltip
                                    titleTranslationKey={tooltipContent}
                                    contentClass={css.tooltip}
                                    position="right-start"
                                    interactive
                                    key={`tooltip-${itemKey}`}
                                >
                                    {listItem}
                                </Tooltip>
                            );
                        })}
                    </List>
                );
            },
            [
                flatListItemsObject,
                flatListItemsKeys,
                activeItemId,
                expandedCategoryId,
                disabledList,
                isExpandable,
                sectionListClassName,
                showCheckedIcon,
                handleOnClick,
            ]
        );

        const MemoizedListItem = useMemo(() => memo(ListItem), []);

        const MemoizedNavigationHeader = useMemo(() => {
            if (!isSectionsNavigationEnabled) return null;

            return (
                <NavigationHeader
                    itemsCount={flatListItemsKeys.length}
                    activeItemIndex={activeItemIndex}
                    handleOnClickBack={handleOnClickBack}
                    handleOnClickNext={handleOnClickNext}
                    itemTitle={flatListItemsObject[activeItemId]?.itemTitle}
                />
            );
        }, [
            isSectionsNavigationEnabled,
            flatListItemsKeys.length,
            activeItemIndex,
            handleOnClickBack,
            handleOnClickNext,
            flatListItemsObject,
            activeItemId,
        ]);

        const sectionContent = useMemo(() => {
            if (disabledList) return <EmptyState icon="happyPage" header={disabledListDescription} />;

            if (!activeItemId || !flatListItemsObject[activeItemId]) return null;

            return flatListItemsObject[activeItemId].content ?? <EmptyState icon="noChartData" />
        }, [disabledList, disabledListDescription, activeItemId, flatListItemsObject]);

        const menuItems = useMemo(() => {
            if (!flatListItemsKeys.length) return null;
            return generateListItemsMenu(listItems);
        }, [flatListItemsKeys, generateListItemsMenu, listItems]);

        useEffect(() => {
            if (activeItem) {
                setActiveItemId(activeItem);

                if (isExpandable && showCheckedIcon) {
                    const newExpandedCategory = calculateExpandedCategoryId(
                        listItems,
                        flatListItemsObject,
                        activeItem,
                        expandedCategoryId
                    );
                    setExpandedCategoryId(newExpandedCategory);
                }
            } else if (flatListItemsKeys.length && (!activeItemId || !flatListItemsObject[activeItemId])) {
                setActiveItemId(flatListItemsKeys[0]);
            }
        }, [
            listItems,
            activeItem,
            isExpandable,
            flatListItemsObject,
            flatListItemsKeys,
            showCheckedIcon,
            activeItemId,
            expandedCategoryId,
        ]);

        if (activeItemIndex === -1) return null;

        return (
            <div className={classNames(css.pageContainer, pageContainerClassName)}>
                {!!title && (
                    <div className={css.pageTitle}>
                        <Translate id={title} />
                    </div>
                )}
                {!!title && subTitle && <div className={css.pageTitleBorder} />}
                {subTitle && (
                    <div className={css.pageSubTitle}>
                        {typeof subTitle === 'string' ? <Translate id={subTitle} /> : subTitle}
                    </div>
                )}
                {!!flatListItemsKeys.length && (
                    <div className={css.sectionContainer}>
                        {menuItems}
                        <div
                            className={classNames(
                                css.sectionContent,
                                {
                                    [css.contentWithoutNavigation]: !isSectionsNavigationEnabled,
                                    [css.contentWithNavigation]: isSectionsNavigationEnabled,
                                },
                                sectionContentClassName
                            )}
                            ref={ref}
                        >
                            {MemoizedNavigationHeader}
                            <div className={classNames(css.sectionContentContainer, sectionContentContainerClassName)}>
                                {sectionContent}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

ListItemsContainer.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.any,
    showCheckedIcon: PropTypes.bool,
    listItems: PropTypes.objectOf(PropTypes.any).isRequired,
    pageContainerClassName: PropTypes.string,
    sectionContentClassName: PropTypes.string,
    sectionContentContainerClassName: PropTypes.string,
    sectionListClassName: PropTypes.string,
    isSectionsNavigationEnabled: PropTypes.bool,
    disabledList: PropTypes.bool,
    disabledListDescription: PropTypes.string,
    onClickNextButton: PropTypes.func,
    onClickBackButton: PropTypes.func,
    isExpandable: PropTypes.bool,
    onClickListItem: PropTypes.func,
};

ListItemsContainer.defaultProps = {
    title: '',
    subTitle: null,
    showCheckedIcon: false,
    pageContainerClassName: '',
    sectionContentClassName: '',
    sectionContentContainerClassName: '',
    sectionListClassName: '',
    isSectionsNavigationEnabled: false,
    disabledList: false,
    disabledListDescription: '',
    onClickNextButton: undefined,
    onClickBackButton: undefined,
    isExpandable: false,
    onClickListItem: () => {},
};

export default ListItemsContainer;
