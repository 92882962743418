import { useCallback } from 'react';
import { useFetch } from 'benchmarks/hooks';
import MmmAPI from './service';

export const useFetchMMMMetadata = viewName => {
    const _MmmAPI = new MmmAPI();

    return useFetch(useCallback(_MmmAPI.getMMMMetadata, viewName), () => true, viewName);
};

export const useFetchMMMData = selectedFilterValues => {
    const _MmmAPI = new MmmAPI();

    return useFetch(
        useCallback(_MmmAPI.getMMMData, [selectedFilterValues]),
        () =>
            selectedFilterValues &&
            Object.keys(selectedFilterValues).length > 0 &&
            selectedFilterValues.app.length > 0 &&
            selectedFilterValues.country.length > 0 &&
            selectedFilterValues.platform.length > 0,
        selectedFilterValues
    );
};
