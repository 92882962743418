import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranlslate from '../../containers/widgets/withTranslate';
import { CHECKOUT_ENABLED_TIERS } from '../consts';
import css from './PlanBanner.css';
// className={classNames(css.KeyInput, css.monospaceFont)}
const PlanBanner = ({ plan, translate }) => {
    return (
        <div className={classNames(css.planBanner, css[plan.toLowerCase()])}>
            <span className={css.planTitle}>{translate(`STATIC.PAGES.CHECKOUT.PLAN_${plan}_TITLE`)}</span>
            <span className={css.planText}>{translate(`STATIC.PAGES.CHECKOUT.PLAN_${plan}_TEXT`)}</span>
        </div>
    );
};
PlanBanner.propTypes = {
    plan: PropTypes.oneOf(CHECKOUT_ENABLED_TIERS).isRequired,
    translate: PropTypes.func.isRequired,
};
export default withTranlslate(PlanBanner);
