import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ResizeSensor extends PureComponent {
    constructor(props) {
        super(props);
        this._handleResize = this._handleResize.bind(this);
        this.iframe = React.createRef();
    }

    componentDidMount() {
        const { onResize } = this.props;
        this.iframe.current.contentWindow.addEventListener('resize', this._handleResize);

        // Send the first resize event upon mount
        const { clientHeight, clientWidth } = this.iframe.current;
        onResize({ height: clientHeight, width: clientWidth });
    }

    componentWillUnmount() {
        this.iframe.current.contentWindow.removeEventListener('resize', this._handleResize);
    }

    _handleResize() {
        const { onResize } = this.props;
        window.requestAnimationFrame(() => {
            const { clientHeight, clientWidth } = this.iframe.current;
            onResize({ height: clientHeight, width: clientWidth });
        });
    }

    render() {
        return (
            <iframe
                ref={this.iframe}
                style={{
                    height: '100%',
                    width: '100%',
                    border: 'none',
                    background: 'transparent',
                    position: 'absolute',
                    zIndex: -1,
                    top: 0,
                    left: 0,
                }}
            />
        );
    }
}

ResizeSensor.propTypes = {
    onResize: PropTypes.func,
};
ResizeSensor.defaultProps = {
    onResize: () => {},
};

export default ResizeSensor;
