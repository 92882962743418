export const LOAD = 'action.banners.load';
export const DESTROY = 'action.banners.destroy';
export const APP_FILTER_CHANGED = 'action.banners.apps.filter.changed';
export const APP_SELECTED_FIRST = 'action.banners.app.selected.first';
export const APP_SITE_CHANGED = 'action.banners.app.site.changed';
export const BANNERS_FOR_APP = 'action.banners.for.app';
export const ADD_BANNER_CLICKED = 'action.banners.add.banner.clicked';
export const CLOSE_SHELF = 'action.banners.close.shelf';
export const OPEN_SHELF_EDIT_MODE = 'action.banners.open.shelf.edit.mode';
export const BANNER_PRIORITY_CHANGED = 'action.banners.priority.changed';
export const SAVING_CONFIGURATION = 'action.banners.saving.configuration';
export const SAVING_CONFIGURATION_FAILED = 'action.banners.saving.configuration.failed';

// triggers sagas watchers
export const APP_SELECTED = 'action.banners.app.selected';
export const EDIT_BANNER_CLICKED = 'action.banners.edit.banner.clicked';
export const CLOSE_SHELF_CLICKED = 'action.banners.close.clicked.shelf';
export const ARCHIVE_BANNER_CLICKED = 'action.banners.archive.banner.clicked';
export const SAVE_SHELF_CLICKED = 'action.banners.save.shelf.clicked';

export const load = (loading, countries) => {
    return {
        type: LOAD,
        loading,
        countries,
    };
};

export const pageDestroy = () => {
    return {
        type: DESTROY,
    };
};

export const changeAppsFilter = value => {
    return {
        type: APP_FILTER_CHANGED,
        value,
    };
};

export const selectApp = appId => {
    return {
        type: APP_SELECTED,
        appId,
    };
};

export const selectAppSiteChanged = appSiteId => {
    return {
        type: APP_SITE_CHANGED,
        appSiteId,
    };
};

export const selectAppFirst = appId => {
    return {
        type: APP_SELECTED_FIRST,
        appId,
    };
};

export const updateBannersForAppSite = (appSiteId, configuredBanners) => {
    return {
        type: BANNERS_FOR_APP,
        appSiteId,
        configuredBanners,
    };
};

export const addBannerClicked = () => {
    return {
        type: ADD_BANNER_CLICKED,
    };
};

export const closeShelfClicked = isFormDirty => {
    return {
        type: CLOSE_SHELF_CLICKED,
        isFormDirty,
    };
};

export const closeShelf = () => {
    return {
        type: CLOSE_SHELF,
    };
};

export const saveShelfClicked = configuredBanner => {
    return {
        type: SAVE_SHELF_CLICKED,
        configuredBanner,
    };
};

export const openShelfEditMode = configuredBanner => {
    return {
        type: OPEN_SHELF_EDIT_MODE,
        configuredBanner,
    };
};

export const archiveBannerClicked = id => {
    return {
        type: ARCHIVE_BANNER_CLICKED,
        id,
    };
};

export const editBannerClicked = id => {
    return {
        type: EDIT_BANNER_CLICKED,
        id,
    };
};

export const bannerPriorityChanged = (id, priority) => {
    return {
        type: BANNER_PRIORITY_CHANGED,
        id,
        priority,
    };
};
