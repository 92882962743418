import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import css from './apiBox.pcss';
import { SingularButton } from '../../components/widgets';

const INPUT_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
};

function ApiBox({ apiKey }) {
    const [inputType, setInputType] = useState(INPUT_TYPES.PASSWORD);
    const [buttonText, setButtonText] = useState('STATIC.PAGES.API.REPORTING_API_KEY.SHOW_KEY');
    const [showKey, setShowKey] = useState(false);
    const inputElem = useRef();
    const HIDING_KEY_TIMEOUT_INTERVAL = 10000; // 10 seconds
    const HIDING_COPY_TEXT_TIMEOUT_INTERVAL = 3000; // 3 seconds

    const hideKey = () => {
        setTimeout(() => {
            setInputType(INPUT_TYPES.PASSWORD);
            setButtonText('STATIC.PAGES.API.REPORTING_API_KEY.SHOW_KEY');
            setShowKey(false);
        }, HIDING_KEY_TIMEOUT_INTERVAL);
    };

    const changeInputType = () => {
        if (showKey) {
            // copy the text to clipboard
            const getKey = inputElem.current.value;
            navigator.clipboard.writeText(getKey).then(() => {
                setButtonText('STATIC.PAGES.API.REPORTING_API_KEY.COPIED');
            });

            setTimeout(() => {
                setButtonText('STATIC.PAGES.API.REPORTING_API_KEY.COPY_KEY');
                hideKey();
            }, HIDING_COPY_TEXT_TIMEOUT_INTERVAL);
        } else {
            // set the key to be visible
            setShowKey(true);
            setInputType(INPUT_TYPES.TEXT);
            setButtonText('STATIC.PAGES.API.REPORTING_API_KEY.COPY_KEY');
            hideKey();
        }
    };

    return (
        <div className={css.ApiContainer}>
            <Translate id="STATIC.PAGES.API.REPORTING_API_KEY.HIDDEN_KEY_DESC" />

            <div className={css.KeyContainer}>
                <span className={css.monospaceFont}>
                    <Translate id="STATIC.PAGES.API.REPORTING_API_KEY.KEY" />
                </span>
                <input
                    className={classNames(css.KeyInput, css.monospaceFont)}
                    ref={inputElem}
                    type={inputType}
                    value={apiKey}
                    readOnly
                />
                <SingularButton type="primary" level="level1" style={{ marginLeft: '12px' }} onClick={changeInputType}>
                    <Translate id={buttonText} />
                </SingularButton>
            </div>
        </div>
    );
}

ApiBox.propTypes = {
    apiKey: PropTypes.string,
};

ApiBox.defaultProps = {
    apiKey: '',
};

export default withLocalize(ApiBox);
