import React, { useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import { AutoCompleteFieldEnhanced, FormField } from '../../../../utils/components/formUtils';
import css from './Interaction.css';
import { getAttributionAppsList } from '../../../../selectors/apps';
import { useAsync } from '../../../../utils/customHooks';
import { Spinner } from '../../../../components/widgets';
import LinkManagementService from '../../../../linkManagement/service';
import { OS, OSIcons } from '../../../../utils/OS';

const supportedOS = [OS.IOS, OS.ANDROID];
const getRedirectKey = os => `${os.toLowerCase()}_redirect`;
const getDeeplinkKey = os => `${os.toLowerCase()}_deeplink`;
const getDeferredDeeplinkKey = os => `${os.toLowerCase()}_deferred_deeplink`;

const LinkManagementAPI = new LinkManagementService();

export default function Interaction() {
    const { values, initialValues, setValues } = useFormikContext();

    const [linksResponse, run] = useAsync();
    const targetApp = values.target_app_id || initialValues.target_app_id;
    const previousTargetApp = useRef(null);

    const apps = useSelector(state => getAttributionAppsList(state));

    useEffect(async () => {
        if (targetApp) {
            await run(LinkManagementAPI.getSuggestionsForApp(targetApp));
        }
    }, [targetApp, run]);

    useEffect(() => {
        if (previousTargetApp.current !== targetApp) {
            const previousTargetAppValue = previousTargetApp.current;
            previousTargetApp.current = targetApp;

            if (!previousTargetAppValue) {
                return;
            }

            // We should reset the link settings if the target app changed
            const copyValues = { ...values };
            supportedOS.forEach(os => {
                delete copyValues[getRedirectKey(os)];
                delete copyValues[getDeeplinkKey(os)];
                delete copyValues[getDeferredDeeplinkKey(os)];
            });

            setValues({ ...copyValues });
        }
    }, [targetApp, values, setValues]);

    const appsOptions = apps.map(({ app_id, name }) => ({ value: app_id, label: name }));
    const selectedApp = appsOptions.find(({ value }) => value === targetApp);

    const suggestions = linksResponse.data ? linksResponse.data.suggestions : {};
    const showLoading = targetApp && (!Object.entries(linksResponse).length || linksResponse.isLoading);

    return (
        <div className={css.interactionContainer}>
            <FormField>
                <AutoCompleteFieldEnhanced
                    name="target_app_id"
                    containerClass={css.appField}
                    options={appsOptions}
                    label="STATIC.PAGES.BANNERS.BANNER_SHELF.APP_LABEL"
                    mandatory
                    searchable
                    clearable
                    isMulti={false}
                    value={selectedApp}
                    debounceTime={700}
                />
            </FormField>
            {showLoading ? (
                <Spinner show />
            ) : (
                <Fragment>
                    {supportedOS.map(os => {
                        const IconElement = OSIcons[os];
                        return (
                            <div key={os}>
                                <div className={css.osTitle}>
                                    <IconElement className={css.osIcon} />
                                    <Translate id={`STATIC.PLATFORMS.${os.toUpperCase()}`} />
                                </div>
                                <LinkSettings
                                    os={os.toLowerCase()}
                                    values={values}
                                    redirectKey={getRedirectKey(os)}
                                    deeplinkKey={getDeeplinkKey(os)}
                                    deferredDeeplinkKey={getDeferredDeeplinkKey(os)}
                                    suggestions={suggestions}
                                />
                            </div>
                        );
                    })}
                </Fragment>
            )}
        </div>
    );
}

Interaction.propTypes = {
    values: PropTypes.objectOf(PropTypes.any),
    initialValues: PropTypes.objectOf(PropTypes.any),
    setValues: PropTypes.func,
};

Interaction.defaultProps = {
    values: {},
    initialValues: {},
    setValues: () => {},
};

function LinkSettings({ values, redirectKey, deeplinkKey, deferredDeeplinkKey, suggestions }) {
    const redirectOptions = (suggestions[redirectKey] || []).map(value => ({ value, label: value }));
    const deeplinkOptions = (suggestions[deeplinkKey] || []).map(value => ({ value, label: value }));
    const deferredDeeplinkOptions = (suggestions[deferredDeeplinkKey] || []).map(value => ({ value, label: value }));

    const selectedRedirect = redirectOptions.find(({ value }) => value === values[redirectKey]);
    const selectedDeeplink = deeplinkOptions.find(({ value }) => value === values[deeplinkKey]);
    const selectedDDL = deferredDeeplinkOptions.find(({ value }) => value === values[deferredDeeplinkKey]);

    return (
        <div className={css.linkSettings}>
            <FormField className={css.formField}>
                <AutoCompleteFieldEnhanced
                    name={redirectKey}
                    options={redirectOptions}
                    label="STATIC.PAGES.MANAGE_LINKS.REDIRECT_LABEL"
                    placeholder="STATIC.PAGES.MANAGE_LINKS.AUTO_COMPLETE_PLACEHOLDER"
                    searchable
                    clearable
                    isMulti={false}
                    value={selectedRedirect || values[redirectKey]}
                    disabled={!values.target_app_id}
                    debounceTime={700}
                />
            </FormField>
            <FormField className={css.formField}>
                <AutoCompleteFieldEnhanced
                    name={deeplinkKey}
                    options={deeplinkOptions}
                    label="STATIC.PAGES.MANAGE_LINKS.DEEPLINK_LABEL"
                    placeholder="STATIC.PAGES.MANAGE_LINKS.SITE_DEEPLINK_PLACEHOLDER"
                    searchable
                    clearable
                    isMulti={false}
                    value={selectedDeeplink || values[deeplinkKey]}
                    disabled={!values.target_app_id}
                    debounceTime={700}
                />
            </FormField>
            <FormField className={css.formField}>
                <AutoCompleteFieldEnhanced
                    name={deferredDeeplinkKey}
                    options={deferredDeeplinkOptions}
                    label="STATIC.PAGES.MANAGE_LINKS.DEFERRED_DEEPLINK_LABEL"
                    placeholder="STATIC.PAGES.MANAGE_LINKS.DDL_PLACEHOLDER"
                    searchable
                    clearable
                    isMulti={false}
                    value={selectedDDL || values[deferredDeeplinkKey]}
                    disabled={!values.target_app_id}
                    debounceTime={700}
                />
            </FormField>
        </div>
    );
}

LinkSettings.propTypes = {
    redirectKey: PropTypes.string,
    deeplinkKey: PropTypes.string,
    deferredDeeplinkKey: PropTypes.string,
    values: PropTypes.objectOf(PropTypes.any),
    suggestions: PropTypes.objectOf(PropTypes.any),
};

LinkSettings.defaultProps = {
    redirectKey: '',
    deeplinkKey: '',
    deferredDeeplinkKey: '',
    values: {},
    suggestions: {},
};
