import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ClearIconButton from '../../../resources/icons/eraser.svg';
import css from './ClearShelfButton.css';
import { clearFieldSelection, setActionType } from '../../../actions/reports';

export default function ClearShelfButton({ reportType, onClearShelf }) {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setActionType(clearFieldSelection(), reportType));
        onClearShelf();
    };

    return (
        <div className={css.clearShelfContainer} onClick={onClick}>
            <ClearIconButton className={css.clearIcon} />
            <span>Clear All</span>
        </div>
    );
}

ClearShelfButton.propTypes = {
    reportType: PropTypes.string,
    onClearShelf: PropTypes.func,
};

ClearShelfButton.defaultProps = {
    reportType: '',
    onClearShelf: () => {},
};
