import React from 'react';
import PropTypes from 'prop-types';

class MarkdownImageRenderer extends React.PureComponent {
    render() {
        const { src, altText, title, style } = this.props;
        return (
            <div>
                <img src={src} style={style} title={title} alt={altText} />
            </div>
        );
    }
}

MarkdownImageRenderer.propTypes = {
    src: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
};

MarkdownImageRenderer.defaultProps = {
    src: '',
    altText: '',
    title: '',
    style: {},
};

export default MarkdownImageRenderer;
