import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Checkbox.css';
import QuestionMark from './QuestionMark';

const STATE_UN_CHECKED = 0;
const STATE_CHECKED = 2;

function Checkbox(props) {
    const {
        label,
        id,
        checked,
        disabled,
        className,
        indeterminate,
        threeStatesEnabled,
        labelPosition,
        style,
        size,
        checkmarkStyle,
        labelClass,
        errorLabelClass,
        error,
        onChange,
        labelTooltip,
    } = props;

    const nextState = () => {
        if (!threeStatesEnabled) {
            return !checked;
        }

        return indeterminate || !checked ? STATE_CHECKED : STATE_UN_CHECKED;
    };

    const innerOnChange = () => {
        if (disabled) {
            return;
        }
        onChange(nextState());
    };

    return (
        <label
            className={`${css.container}
                        ${disabled ? css.disabled : ''}
                        ${className}
                        ${indeterminate ? css.indeterminate : ''}
                        ${labelPosition === 'left' ? css.reverse : ''}
                        ${css[size]}`}
            htmlFor={`checkbox_${id}`}
            onClick={innerOnChange}
            style={style}
        >
            <input type="checkbox" name={`checkbox_${id}`} checked={checked} onChange={() => {}} disabled={disabled} />
            <span className={classNames(css.checkmark, { [css.error]: error })} style={checkmarkStyle} />
            {label ? (
                <span
                    className={classNames(
                        css.label,
                        labelClass,
                        { [css.errorLabel]: error },
                        { [errorLabelClass]: errorLabelClass && error }
                    )}
                >
                    {label}
                </span>
            ) : null}
            {labelTooltip ? <QuestionMark message={labelTooltip} /> : null}
        </label>
    );
}

Checkbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    id: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    indeterminate: PropTypes.bool,
    threeStatesEnabled: PropTypes.bool,
    labelPosition: PropTypes.oneOf(['left', 'right']),
    style: PropTypes.objectOf(PropTypes.any),
    size: PropTypes.oneOf(['small', 'medium']),
    checkmarkStyle: PropTypes.objectOf(PropTypes.any),
    labelClass: PropTypes.string,
    errorLabelClass: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    labelTooltip: PropTypes.string,
};

Checkbox.defaultProps = {
    label: '',
    id: '',
    checked: false,
    disabled: false,
    className: '',
    indeterminate: false,
    threeStatesEnabled: false,
    labelPosition: 'right',
    style: {},
    size: 'medium',
    checkmarkStyle: {},
    labelClass: '',
    errorLabelClass: '',
    error: false,
    onChange: () => {},
    labelTooltip: null,
};

export default Checkbox;
