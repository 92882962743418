import { ROLE_ORG_ADMIN } from 'teamManagement/utils';
import { DEFAULT_METRIC } from 'alerts/consts';
import { validateEmail } from 'signup/utils';

export const getUserEmails = (users, filterAdminEmails = false) => {
    if (users === undefined) return [];
    const filteredUseres = users.filter(user => {
        return !user.invite_id && (!filterAdminEmails || user.role_type !== ROLE_ORG_ADMIN);
    });
    return filteredUseres.map(user => user.email);
};

export const getMetricSymbol = (metric, selectedCondition = null) => {
    if (
        selectedCondition &&
        selectedCondition.display_format &&
        selectedCondition.display_format.type === 'percentage'
    ) {
        return ' % ';
    }
    if (!metric || metric.name === DEFAULT_METRIC.name) {
        return ' ';
    }
    if (metric.display_format.type === 'currency') {
        return metric.display_format.symbol;
    }
    if (metric.display_format.type === 'percentage') {
        return ' % ';
    }
    return ' ';
};

export const getItemsWithDividers = (itemTypes, metadataItems) => {
    const items = [];
    itemTypes.forEach(itemType => {
        const currentItems = [];
        metadataItems.forEach(item => {
            if (item.type === itemType.name) {
                currentItems.push(item);
            }
        });
        if (currentItems.length > 0) {
            items.push(itemType);
            Array.prototype.push.apply(items, currentItems);
        }
    });
    return items;
};

export const isInvalidEmail = emails => {
    if (emails) {
        return emails.filter(email => validateEmail(email) !== '').length;
    } else {
        return false;
    }
};
