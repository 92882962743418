import { useState, useCallback, useEffect } from 'react';
import BenchmarksAPI from './service';
import { onPageScroll, resetPageScrollState } from './utils';
import { TRACK_EVENT_PAGE_LOADED, TRACK_EVENT_PREFIX } from './consts';
import { trackMixpanelEvent } from '../utils/general';

export const useFetch = (fetchFunction, validateRequest = () => true, ...args) => {
    const [response, setResponse] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                setResponse(await fetchFunction(...args));
                setIsLoading(false);
                setError(null);
            } catch (responseError) {
                setError(responseError);
                setIsLoading(false);
                setResponse(null);
            }
        };
        validateRequest() && fetchData();
    }, args);
    return { ...response, error, isLoading };
};

export const useFetchBenchmarksMarketShareOfVoiceData = selectedFilterValues => {
    const benchmarksAPI = new BenchmarksAPI();

    return useFetch(
        useCallback(benchmarksAPI.getBenchmarksMarketShareOfVoiceData, [selectedFilterValues]),
        () => selectedFilterValues && Object.keys(selectedFilterValues).length > 0,
        selectedFilterValues
    );
};

export const useFetchShareOfVoiceVsMeChartsData = selectedFilterValues => {
    const benchmarksAPI = new BenchmarksAPI();

    return useFetch(
        useCallback(benchmarksAPI.getBenchmarksShareOfVoiceVsMeData, [selectedFilterValues]),
        () => selectedFilterValues && Object.keys(selectedFilterValues).length > 0,
        selectedFilterValues
    );
};

export const useFetchBenchmarksNetworkOptimizations = selectedFilterValues => {
    const benchmarksAPI = new BenchmarksAPI();

    return useFetch(
        useCallback(benchmarksAPI.getBenchmarksNetworkOptimizations, [selectedFilterValues]),
        () => {
            return true;
        },
        selectedFilterValues
    );
};

export const useFetchBenchmarksMetadata = viewName => {
    const benchmarksAPI = new BenchmarksAPI();

    return useFetch(useCallback(benchmarksAPI.getBenchmarksMetadata, viewName), () => true, viewName);
};

export const useStickyHeader = ref => {
    useEffect(() => {
        const reactContainer = document.querySelector('#reactContainer');

        const onScroll = event => onPageScroll(event, ref);

        reactContainer.addEventListener('scroll', onScroll);

        return () => {
            reactContainer.removeEventListener('scroll', onScroll);
            resetPageScrollState(ref);
        };
    }, []);
};
