import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Translate } from 'react-localize-redux';

import PropTypes from 'prop-types';
import css from './CreativeAITags.css';
import { CreativeType } from '../../types';
import { GET_CREATIVE_TAGS } from '../../queries';
import { Table, TableCell, WarningMessage } from '../../../components/widgets';
import TableCellTags from '../../../components/widgets/TableCellTags';
import { DimensionCategoryToColor } from '../../dimensionsUtils';
import SearchTextField from '../SearchTextField/SearchTextField';
import { AIDimensionsContext } from '../../consts';

export const SKELETONS = Array(4).fill({});
export const NO_AI_DIMENSIONS = 'STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.NO_AI_DIMENSIONS';
export const NO_AI_TAGS = 'STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.NO_AI_TAGS';
export const NO_FILTERED_AI_TAGS = 'STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.NO_FILTERED_AI_TAGS';
export const SEARCH_TAGS_PLACEHOLDER = 'STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.SEARCH_TAGS_PLACEHOLDER';
export const DIMENSION_NAME_CELL_TEST_ID = 'dimension-name-cell';

const getTagsTableMetadata = isLoading => [
    {
        name: 'dimensionName',
        displayName: 'Dimensions',
        cellComponent: TableCell,
        cellProps: {
            className: css.dimensionNameTableCell,
            dataTestId: DIMENSION_NAME_CELL_TEST_ID,
            isLoading,
            loaderClass: css.cellLoader,
        },
    },
    {
        name: 'tags',
        displayName: 'Values',
        cellComponent: TableCellTags,
        cellValues: [
            {
                propName: 'color',
                dataKey: 'category',
                transformation: category => DimensionCategoryToColor[category],
            },
        ],
        cellProps: {
            isLoading,
            loaderClass: css.cellLoader,
            tagClassName: css.tag,
        },
    },
];

const CreativeAITags = ({ app, imageHash }) => {
    const { creativesTags } = useContext(AIDimensionsContext);
    const creativeFetchedTags = creativesTags?.filter(creativeTag => creativeTag.imageHash === imageHash);

    const [search, setSearch] = useState('');
    const { loading: isLoading, data: creativeTagsResponse } = useQuery(GET_CREATIVE_TAGS, {
        variables: {
            appId: app,
            imageHashes: [imageHash],
        },
        skip: !!creativeFetchedTags,
    });

    const creativeAITags = creativeFetchedTags || creativeTagsResponse?.creativeTags?.tags || [];

    // This can happen if we have not run a processing for the creative yet
    const noTagsData = !creativeAITags.some(aiDimension => !!aiDimension.tags.length);

    const filteredCreativeAITags = creativeAITags
        .map(aiDimension => {
            const searchLower = search.toLowerCase();
            const matchesDimension = aiDimension.dimensionName.toLowerCase().includes(searchLower);
            if (matchesDimension) return aiDimension;

            const filteredTags = aiDimension.tags.filter(tag => tag.toLowerCase().includes(searchLower));
            if (filteredTags.length === 0) return null;

            return { ...aiDimension, tags: filteredTags };
        })
        .filter(Boolean);

    const showWarningMessage = !isLoading && (!creativeAITags || !filteredCreativeAITags.length || noTagsData);

    return (
        <div className={css.creativeTags}>
            <div className={css.tagsTitle}>
                <Translate id="STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.ALL_CREATIVE_TAGS" />
            </div>
            <SearchTextField
                placeholder={SEARCH_TAGS_PLACEHOLDER}
                clearable
                search={search}
                onSearchChange={setSearch}
                iconClassName={css.searchIcon}
            />
            <div className={css.tagsTableContainer}>
                <Table
                    tableClass={css.tagsTable}
                    metadata={getTagsTableMetadata(isLoading)}
                    data={isLoading ? SKELETONS : showWarningMessage ? [] : filteredCreativeAITags}
                    theadClass={css.tagsTableHeader}
                    thClass={css.tagsTableHeaderCell}
                    trClass={css.tagsTableRowCell}
                    zebraTable={false}
                />
                {showWarningMessage && (
                    <div className={css.messageContainer}>
                        {!creativeAITags?.length ? (
                            <WarningMessage
                                showIcon={false}
                                className={css.warningMessage}
                                message={NO_AI_DIMENSIONS}
                            />
                        ) : (
                            <Translate
                                id={noTagsData ? NO_AI_TAGS : !filteredCreativeAITags.length ? NO_FILTERED_AI_TAGS : ''}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

CreativeAITags.propTypes = {
    app: PropTypes.string.isRequired,
    imageHash: CreativeType.imageHash.isRequired,
};

CreativeAITags.defaultProps = {};

export default CreativeAITags;
