import moment from 'moment-timezone';
import {
    DIMENSION_FIELD_NAMES,
    TIME_BREAKDOWN_FIELD_NAMES,
    PERIOD_FIELD_NAMES,
    DEFAULT_END_DATE,
} from '../utils/reports';
import { serializeReportQueryEx } from '../utils/url';
import { parseSafeHtml } from '../utils/render';
import {
    MESSAGE_ONCLICK_SUFFIX,
    MESSAGE_ONCLICK_INNER_PREFIX,
    MESSAGE_ONCLICK_INNER_SUFFIX,
    MESSAGE_ONCLICK_ACTION,
    METRIC_FIELD_NAMES,
} from '../utils/consts';
import { DA_MONTHS_BACK, DA_DATE_FORMAT_OPTIONS, DA_DATE_TIME_FORMAT, DA_EMPTY_LAST_UPDATE } from './consts';
import CircleUser from '../resources/svg/icons/circle-user.svg';
import CheckIcon from '../resources/icons/check_mark.svg';
import SheetIcon from '../resources/svg/icons/google_sheet.svg';
import { ETL_EVENT_PREFIX, trackMixpanelEvent } from '../utils/general';
import googleSheetCss from './components/GoogleSheetShelf.css';

export const LAST_DATA_MORE_THAN_X_DAYS = 'More than 30 days ago';
export const MIXPANEL_PREFIX = 'Data Connectors';

export const LAST_DATA_IMPORT_COLUMN = 'last_data_import_date';
export const LAST_DATA_DATE_COLUMN = 'last_data_date';

export const ETL_POPOVER_DESTINATION_NAME_FONT = '600 12px open-sans';
export const ETL_POPOVER_TEXT_NAME_FONT = '400 12px open-sans';

const DATA_IS_EMPTY_MSG = 'data is currently empty';

export const ETL_AUTH_TYPE = 'etl';
export const ETL_FEATURE_FLAG = 'singular_etl';

export const SECRET_PLACEHOLDER = '*'.repeat(10);

export const PAGE_DATA_TYPES = {
    DATA_SOURCES: 'DATA_SOURCES',
    DATA_DESTINATIONS: 'DATA_DESTINATIONS',
};
export const AUTH_TYPES = {
    regular: 'regular',
    oAuth: 'oauth',
    mailru: 'mailru',
    yahoo: 'yahoo',
    microsoft: 'microsoft',
    facebook: 'facebook',
    google: 'google',
    snapchat: 'snapchat',
    twitter: 'twitter',
    etl: 'etl',
    google_sheets: 'google_sheets',
    gdrive: 'gdrive',
    mail: 'mail',
    s3: 's3',
};
export const AUTH_TYPES_DISPLAY_NAMES_WITH_ARTICLES = {
    gdrive: 'a Gdrive',
    mail: 'an Email',
    s3: 'an S3',
};
export const FILE_BASED_AUTH_TYPES = [AUTH_TYPES.mail, AUTH_TYPES.s3, AUTH_TYPES.gdrive];

export const MANUAL_SCRAPE_SUBSTATUS = {
    UNDEFINED: -1,
    SUCCESS: 0,
    TIME_LIMIT_EXCEEDED: 26,
};

// IMPORTANT NOTE: this list must be equivalent to the list by the same name in data_source.py, updating here requires updating there
export const NETWORKS_WITH_ACCOUNTS_NAMES = [
    'facebook',
    'adwords',
    'twitter',
    'searchads360',
    'tiktok',
    'snapchat',
    'applesearchads',
    'kakao',
    'kuaishou',
    'oceanengine',
    'vidazoo_publisher',
    'linkedin',
];

export const NETWORKS_WITH_ACCOUNT_MAPPING = ['facebook', 'twitter', 'searchads360'];

export const NO_WINDOW_OPENER_AUTH_TYPES = [AUTH_TYPES.facebook];

export const SPECIAL_OAUTH_NETWORKS = [
    AUTH_TYPES.facebook,
    AUTH_TYPES.google,
    AUTH_TYPES.twitter,
    AUTH_TYPES.mailru,
    AUTH_TYPES.yahoo,
    AUTH_TYPES.microsoft,
    AUTH_TYPES.snapchat,
];

export const OAUTH_NETWORKS = [AUTH_TYPES.oAuth, ...SPECIAL_OAUTH_NETWORKS];

export const NETWORKS_AUTH_URL_MAPPING = {
    facebook: '/fbauthorize',
    google: '/adwordsauthorize',
    twitter: '/twitterauthorize',
    mailru: '/mytargetauthorize?username=',
    microsoft: '/bingauthorize',
    yandex: '/yandexauthorize',
    snapchat: '/snapchatauthorize',
    linkedin: '/linkedinauthorize',
    yahoo: '/yahooauthorize',
    quora: '/quoraauthorize',
    tiktok: '/bytedanceauthorize',
    toutiao: '/toutiaoauthorize',
    pinterest: '/pinterestauthorize',
    adform: '/adformauthorize',
    reddit: '/redditauthorize',
    indeed: '/indeedauthorize',
    yahoo_japan: '/yahoo_japanauthorize',
    oceanengine: '/oceanengineauthorize',
    applesearchads: '/applesearchadsauthorize',
    tencent: '/tencentauthorize',
    kakao: '/kakaoauthorize',
    criteo: '/criteoauthorize',
    huawei: '/huaweiauthorize',
    yandexdirect: '/yandexauthorize',
    huawei_publisher: '/huaweiauthorize',
    gsheet: '/etlgoogleauthorize',
    applovin: '/applovinauthorize',
};

export const UAN_DATA_TYPES = {
    ADVERTISER_STATS: '1',
    PUBLISHER_STATS: '2',
};

export const DATA_SOURCES_STATUSES = {
    ACTIVE: '0',
    INACTIVE: '1',
    PENDING: '2',
    FIRST_SCRAPE_STAGE_SCRAPING: '2.1',
    FIRST_SCRAPE_STAGE_DATA_SOURCE_PIPELINE: '2.2',
    FIRST_SCRAPE_STAGE_SOURCE_PIPELINE: '2.3',
    FIRST_SCRAPE_STAGE_DRUID_LOAD: '2.4',
    NOT_IMPLEMENTED: '3',
    ACTION_REQUIRED: '4',
    DATA_DELAY: '5',
};

export const STATUSES_COLOR_MAP = {
    [DATA_SOURCES_STATUSES.ACTIVE]: '#5cb85c',
    [DATA_SOURCES_STATUSES.PENDING]: '#3a92e5',
    [DATA_SOURCES_STATUSES.INACTIVE]: '#e64d5f',
    [DATA_SOURCES_STATUSES.NOT_IMPLEMENTED]: '#a4a4a5',
    [DATA_SOURCES_STATUSES.ACTION_REQUIRED]: '#e64d5f',
    [DATA_SOURCES_STATUSES.DATA_DELAY]: '#ffaa20',
};

export const ETLS_STATES = {
    UP_TO_DATE: '0',
    ALL_IN_PROGRESS: '1',
    SOME_IN_PROGRESS: '2',
    NOT_EXIST: '3',
    NO_STATE: '4',
    NOT_ALL_ACTIVE: '5',
    NOT_SUPPORTED: '6',
};

export const SINGLE_ETL_STATUS = {
    UP_TO_DATE: '0',
    IN_PROGRESS: '1',
    NOT_ACTIVE: '2',
};

export const SINGLE_ETL_STATUS_COLOR_MAP = {
    [SINGLE_ETL_STATUS.UP_TO_DATE]: 'var(--success1Color)',
    [SINGLE_ETL_STATUS.IN_PROGRESS]: 'var(--blue300)',
    [SINGLE_ETL_STATUS.NOT_ACTIVE]: 'var(--warning1Color)',
};

export const SINGLE_ETL_STATUS_TEXT_MAP = {
    [SINGLE_ETL_STATUS.UP_TO_DATE]: 'Up to date',
    [SINGLE_ETL_STATUS.IN_PROGRESS]: 'In progress',
    [SINGLE_ETL_STATUS.NOT_ACTIVE]: 'Not active',
};

export const DATA_DATE_FORMATS = {
    last_data_date: 'MMM D, YYYY',
    last_data_import_date: 'MMM D, YYYY HH:mm A',
};

export const MODAL_ACTION_TYPE_NEW = 'new';
export const MODAL_ACTION_TYPE_UPDATE = 'update';
export const MODAL_ACTION_TYPE_DELETE = 'delete';
export const MODAL_ACTION_TYPE_LOGIN_VERIFICATION_FINISHED = 'login_verification_finished';

export const UAN_ACTION_MSG_BY_TYPE = {
    [MODAL_ACTION_TYPE_NEW]: 'STATIC.PAGES.DATA_SOURCES.NEW_UAN_ACTION',
    [MODAL_ACTION_TYPE_UPDATE]: 'STATIC.PAGES.DATA_SOURCES.UPDATE_UAN_ACTION',
    [MODAL_ACTION_TYPE_DELETE]: 'STATIC.PAGES.DATA_SOURCES.DELETE_UAN_ACTION',
    [MODAL_ACTION_TYPE_LOGIN_VERIFICATION_FINISHED]: 'STATIC.PAGES.DATA_SOURCES.UPDATE_UAN_AFTER_VERIFICATION_ACTION',
};

export const searchDataSources = (searchText, uansData = [], showArchivedUans, isAdminMode) => {
    return uansData
        .filter(uan => {
            return (
                ((showArchivedUans && uan.showInArchive) || !uan.showInArchive) &&
                (uan.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
                    (uan.subText && uan.subText.toLowerCase().includes(searchText.toLowerCase())) ||
                    (uan.subTextExtra && uan.subTextExtra.toLowerCase().includes(searchText.toLowerCase())) ||
                    (uan.agencyName && uan.agencyName.toLowerCase().includes(searchText.toLowerCase())))
            );
        })
        .map(uan => {
            // in admin mode - show the uan id
            uan.displayName =
                isAdminMode && !uan.displayName.includes(uan.uanId)
                    ? `${uan.displayName} - ${uan.uanId}`
                    : uan.displayName;
            uan.subText =
                isAdminMode && uan.etlTableName && !uan.subText.includes(uan.etlTableName)
                    ? `${uan.subText} (${uan.etlTableName})`
                    : uan.subText;
            return uan;
        });
};

export const buildTestReportRedirectUrl = (uanId, isStandardAnalytics) => {
    const startDate = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    const query = {
        dimensions: [
            DIMENSION_FIELD_NAMES.SOURCE,
            DIMENSION_FIELD_NAMES.OS,
            DIMENSION_FIELD_NAMES.CAMPAIGN_NAME,
            DIMENSION_FIELD_NAMES.ACCOUNT_NAME,
            DIMENSION_FIELD_NAMES.CAMPAIGN_ID,
            DIMENSION_FIELD_NAMES.SUB_CAMPAIGN_ID,
            DIMENSION_FIELD_NAMES.SUB_CAMPAIGN_NAME,
        ],
        metrics: [METRIC_FIELD_NAMES.COST],
        filters: [
            {
                dimension: DIMENSION_FIELD_NAMES.UAN,
                operator: 'in',
                values: [uanId],
            },
        ],
        time_breakdown: [TIME_BREAKDOWN_FIELD_NAMES.ALL],
        start_date: startDate,
        start_date_2: startDate,
        end_date: DEFAULT_END_DATE,
        end_date_2: DEFAULT_END_DATE,
        cohort_periods: [PERIOD_FIELD_NAMES.PERIOD_7_DAYS],
    };

    if (!isStandardAnalytics) {
        query.dimensions.push(DIMENSION_FIELD_NAMES.APP);
        query.dimensions.push(DIMENSION_FIELD_NAMES.PLATFORM);
        query.dimensions.push(DIMENSION_FIELD_NAMES.ORIGINAL_CURRENCY);
        query.metrics.push(METRIC_FIELD_NAMES.ADN_ORIGINAL_COST);
    }

    const redirectPageName = 'react/reports';
    const { href, hash } = window.location;
    const redirectHref = href.substring(0, href.indexOf(hash) + 1);

    return `${redirectHref}/${redirectPageName}?${serializeReportQueryEx(query)}`;
};

export const parseDate = (uanData, uanDataKey, timeZone) => {
    const date = uanData[uanDataKey];
    const dateFormat = DATA_DATE_FORMATS[uanDataKey];
    const shouldBeHidden = (uanData.hide_columns || []).indexOf(uanDataKey) >= 0;

    let parsedDate;

    if (shouldBeHidden) {
        parsedDate = '';
    } else if (date && timeZone) {
        parsedDate = moment(date)
            .tz(timeZone)
            .format(dateFormat);
    } else if (uanData.is_last_data_more_than_x_days && uanDataKey === LAST_DATA_DATE_COLUMN) {
        parsedDate = LAST_DATA_MORE_THAN_X_DAYS;
    } else if (date) {
        parsedDate = moment(date).format(dateFormat);
    }

    return parsedDate;
};

export const isSingularTrackerUan = displayName => displayName === 'Singular Tracker';

export const shouldShowExport2Phase = ({ showCsvExport, displayName }) => {
    return !!showCsvExport && !isSingularTrackerUan(displayName);
};

export const shouldShowRunTestReport = uanData => {
    return uanData.dataType === UAN_DATA_TYPES.ADVERTISER_STATS && !isSingularTrackerUan(uanData.displayName);
};

export const shouldShowDeleteButton = uanData => {
    return !uanData.is_connector_frozen && !isSingularTrackerUan(uanData.displayName);
};

export const shouldShowFieldsAvailability = (uanData, showFieldsAvailabilityFeature) => {
    return (
        showFieldsAvailabilityFeature &&
        uanData.dataType !== UAN_DATA_TYPES.PUBLISHER_STATS &&
        ![AUTH_TYPES.etl, AUTH_TYPES.google_sheets].includes(uanData.authType) &&
        !isSingularTrackerUan(uanData.displayName)
    );
};

export const setSingleETLStatus = etl => {
    etl.state = SINGLE_ETL_STATUS.UP_TO_DATE;
    if (etl.status.toString() !== DATA_SOURCES_STATUSES.ACTIVE) {
        etl.state = SINGLE_ETL_STATUS.NOT_ACTIVE;
    } else if (etl.is_last_data_export_in_progress) {
        etl.state = SINGLE_ETL_STATUS.IN_PROGRESS;
    }
};

export const parseETLStatus = uanData => {
    let etlTotalState = ETLS_STATES.UP_TO_DATE;
    const uanActiveEtlStates = [];

    if (!uanData.etl_states_feature_supported) {
        etlTotalState = ETLS_STATES.NOT_SUPPORTED;
    } else if (!uanData.etl_states || !uanData.etl_states.length) {
        etlTotalState = ETLS_STATES.NOT_EXIST;
    } else if (uanData.status.toString() !== DATA_SOURCES_STATUSES.ACTIVE) {
        etlTotalState = ETLS_STATES.NO_STATE;
    }

    for (const msg of uanData.messages || []) {
        if (msg.text.includes(DATA_IS_EMPTY_MSG)) {
            etlTotalState = ETLS_STATES.NO_STATE;
            break;
        }
    }

    if (etlTotalState === ETLS_STATES.UP_TO_DATE) {
        let inProgressEtlCount = 0;
        let etlNotAllActive = false;
        for (const etl of uanData.etl_states) {
            setSingleETLStatus(etl);
            if (!etl.last_data_import_date) {
                continue;
            }
            if (etl.is_last_data_export_in_progress) {
                inProgressEtlCount++;
            }
            if (etl.status.toString() !== DATA_SOURCES_STATUSES.ACTIVE) {
                etlNotAllActive = true;
            }
            uanActiveEtlStates.push(etl);
        }
        if (!uanActiveEtlStates.length) {
            etlTotalState = ETLS_STATES.NO_STATE;
        } else if (etlNotAllActive) {
            etlTotalState = ETLS_STATES.NOT_ALL_ACTIVE;
        } else if (inProgressEtlCount > 0 && inProgressEtlCount === uanActiveEtlStates.length) {
            etlTotalState = ETLS_STATES.ALL_IN_PROGRESS;
        } else if (inProgressEtlCount) {
            etlTotalState = ETLS_STATES.SOME_IN_PROGRESS;
        }
    }

    return [etlTotalState, uanActiveEtlStates];
};

export const parseMetadata = (metadata, timezone, sortedColName, isSortAscending, searchText, headerTooltips) => {
    return metadata.map(metadataItem => {
        const headerTooltip = headerTooltips[metadataItem.name];

        if (headerTooltip) {
            metadataItem.headerTooltip = headerTooltip.replace(
                '{timezone}',
                timezone ? `GMT${moment.tz(timezone).format('ZZ')}` : 'your organization timezone'
            );
        }

        if (metadataItem.name === 'displayName') {
            metadataItem.cellProps.searchText = searchText;
        }

        if (metadataItem.showHeaderArrow && metadataItem.name === sortedColName) {
            metadataItem.headerArrow = isSortAscending ? 'down' : 'up';
        } else {
            metadataItem.headerArrow = undefined;
        }

        return metadataItem;
    });
};

export const translateMessage = (row, translate) => {
    if (row.messages) {
        const resultMessage = row.messages.reduce((finalMessage, msg) => {
            let currMessageText = '';
            if (msg.translate_needed) {
                currMessageText = translate(`STATIC.PAGES.DATA_SOURCES.TABLE.MESSAGES.${msg.text}`);
                if (currMessageText.props && currMessageText.props.dangerouslySetInnerHTML) {
                    currMessageText = parseSafeHtml(currMessageText.props.dangerouslySetInnerHTML.__html);
                }
            } else {
                currMessageText = msg.text;
            }
            let currParsedMsg = msg.bold ? `<strong>${currMessageText}</strong>` : currMessageText;
            currParsedMsg = msg.open_shelf
                ? `${MESSAGE_ONCLICK_INNER_PREFIX}${MESSAGE_ONCLICK_ACTION.OPEN_SHELF}${MESSAGE_ONCLICK_INNER_SUFFIX}${currParsedMsg}${MESSAGE_ONCLICK_SUFFIX}`
                : currParsedMsg;
            return finalMessage + currParsedMsg;
        }, '');
        return { ...row, message: resultMessage };
    }
    return row;
};

export const usesAccounts = uan => {
    return (
        uan.authType === AUTH_TYPES.twitter ||
        (uan.authType === AUTH_TYPES.facebook && !uan.is_ad_monetization) ||
        (uan.authType === AUTH_TYPES.google && !uan.is_ad_monetization)
    );
};

export const getAccountSourceMappingList = accountSourceMapResponse => {
    return !accountSourceMapResponse.value
        ? []
        : accountSourceMapResponse.value.map(source => {
              return { display_name: source.name, name: source.id };
          });
};

export const parseAccountsData = accountsData => {
    return accountsData.map(row => {
        const selected = {
            name: row.source.id,
            display_name: row.source.name,
        };
        if (row.multiple_account_in_uans) {
            row.disabled = true;
            row.subText = 'Associated with another user';
            row.imgComponent = CircleUser;
        }

        return { ...row, selected };
    });
};

export const getMonthsList = earliestDataDate => {
    const monthsList = [];
    const date = new Date(new Date().setDate(1));
    const minDate = new Date(
        Math.max(new Date(earliestDataDate).setDate(1), new Date(date).setMonth(date.getMonth() - DA_MONTHS_BACK))
    );
    while (date >= minDate) {
        const dateVal = `${date.getMonth() + 1}, ${date.getFullYear()}`;
        const dateDisplay = date.toLocaleDateString('en-US', DA_DATE_FORMAT_OPTIONS);
        monthsList.push({ name: dateVal, display_name: dateDisplay });
        date.setMonth(date.getMonth() - 1);
    }
    return monthsList;
};

export const parseMonthData = (monthData, timezone) => {
    return monthData.map(row => {
        return {
            date: row.date,
            lastUpdate: row.lastUpdate
                ? moment(row.lastUpdate * 1000)
                      .tz(timezone)
                      .format(DA_DATE_TIME_FORMAT)
                : DA_EMPTY_LAST_UPDATE,
            nextUpdate:
                typeof row.nextUpdate === 'string'
                    ? row.nextUpdate
                    : moment(row.nextUpdate * 1000)
                          .tz(timezone)
                          .format(DA_DATE_TIME_FORMAT),
            message: row.message,
        };
    });
};

export const getNormalizedDestinationFields = (dataDestination, isEditMode) => {
    if (!dataDestination) {
        return [];
    }

    const { extra_modal_fields: fields, extra_modal_field_values: editedFieldValues } = dataDestination;

    return (fields || []).map(({ option: name, display_name: label, default: defaultValue, secret, ...field }) => ({
        name,
        label,
        secret,
        defaultValue,
        // Passwords are not being passed from the server
        value: isEditMode && secret ? SECRET_PLACEHOLDER : editedFieldValues?.[name],
        ...field,
    }));
};

const SHEET_URL_TEMPLATE = 'https://docs.google.com/spreadsheets/d/';
const GSHEET_ID = 0;
const GSHEET_CREATED_AT = 1;
const GSHEET_NAME = 2;
const GSHEET_STOPPED_AT = 3;

export const parseGSheetsInfo = (sheets, createdCheckMark) => {
    const nameCount = {};
    const data = [];
    const sheetsToShow = sheets || [];
    const sheetsLength = sheetsToShow.length;

    if (sheetsLength === 0) {
        return data;
    }
    for (let rowIdx = 0; rowIdx < sheetsLength; rowIdx++) {
        const row = sheetsToShow[rowIdx];
        const rowData = {
            sheetId: row[GSHEET_ID],
            created: row[GSHEET_CREATED_AT],
            sheetName: row[GSHEET_NAME],
            stoppedAt: row[GSHEET_STOPPED_AT] || '',
        };
        const { sheetId, created, sheetName, stoppedAt } = rowData;
        nameCount[rowData.sheetName] = (nameCount[sheetName] || 0) + 1;
        const lastSheetCreated = rowIdx === sheetsLength - 1 && createdCheckMark;
        data.push({
            sheetLink: `${SHEET_URL_TEMPLATE}${sheetId}`,
            sheetName: `${rowData.sheetName} #${nameCount[sheetName]}`,
            status: !stoppedAt ? DATA_SOURCES_STATUSES.ACTIVE : 'paused',
            icon: lastSheetCreated ? CheckIcon : SheetIcon,
            iconClassName: lastSheetCreated ? googleSheetCss.checkmark : '',
            created,
            stoppedAt,
            sheetId,
        });
    }
    return data;
};

export const saveDataDestination = async (
    DataSourcesAPI,
    schemaField,
    schemaFieldValue,
    customFields,
    extraFields,
    newUanDetails
) => {
    try {
        extraFields[schemaField.name] = schemaFieldValue;

        if (Object.values(customFields).length) {
            const { schema } = await DataSourcesAPI.createCustomSchema(
                newUanDetails.adn_id,
                schemaFieldValue,
                customFields
            );
            extraFields[schemaField.name] = schema;
        }

        const response = await DataSourcesAPI.saveUanData({
            extra_modal_field_values: extraFields,
            ...newUanDetails,
        });

        return Object.values(response)[0].uan_id;
    } catch {
        return null;
    }
};

export const reportDestinationToMixpanel = (
    eventName,
    schemaField,
    schemaFieldValue,
    customFields,
    isEditMode,
    extraProps = {}
) => {
    const params = {
        schemaName: schemaField.choices.find(({ value }) => value === schemaFieldValue).label,
        customFields: Object.values(customFields),
        newDestination: !isEditMode,
        ...extraProps,
    };

    trackMixpanelEvent(ETL_EVENT_PREFIX, eventName, params, true);
};
