import React from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './webAttributionSetUp.css';
import Accordion from '../../../components/widgets/Accordion/Accordion';
import { Label } from '../../../components/widgets';

function WebAttributionSetUp({ translate }) {
    return (
        <>
            <div className={css.contentSection}>
                {translate('STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.WEB_SDK_INTEGRATION.INTEGRATION_STEPS')}
            </div>
            <Label
                text="STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.WEB_SDK_INTEGRATION.FAQ.TITLE"
                className={css.faqTitle}
            />
            <Accordion
                items={[...Array(3).keys()].map(key => ({
                    title: `STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.WEB_SDK_INTEGRATION.FAQ.QUESTION_${key + 1}`,
                    content: `STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.WEB_SDK_INTEGRATION.FAQ.ANSWER_${key + 1}`,
                }))}
                className={css.faqAccordion}
            />
            <div className={css.contentSection}>
                {translate('STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.WEB_SDK_INTEGRATION.LEARN_MORE')}
            </div>
        </>
    );
}

WebAttributionSetUp.propTypes = {
    translate: PropTypes.func.isRequired,
};

export default withLocalize(WebAttributionSetUp);
