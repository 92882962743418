import moment from 'moment';
import { METRIC_CARDS_TIME_PERIODS, NUMBER_SIGNS } from './consts';
import { getDateDiffObject } from './dateUtils';
import { roundWithPercision } from './numbers';
import { METRIC_CARDS_ORDER, METRICS_CARDS_DATA } from '../customDashboards/consts';
import { changeColumnCalculateValue, customMetricValueComparator, simpleValueComparator } from './grids';

export const parseCompareFactor = compareFactor => {
    let compareFactorName = NUMBER_SIGNS.ZERO;
    let compareFactorSign = '';

    if (compareFactor > 0) {
        compareFactorName = NUMBER_SIGNS.POSITIVE;
        compareFactorSign = '+';
    } else if (compareFactor < 0) {
        compareFactorName = NUMBER_SIGNS.NEGATIVE;
    }

    return { compareFactorName, compareFactorSign };
};

export const parseDateDiffStr = (startDate, endDate) => {
    let dateDiffStr = METRIC_CARDS_TIME_PERIODS.LAST_PERIOD;
    const yesterday = moment().subtract(1, 'days');
    const { weeks, months } = getDateDiffObject(startDate, endDate);

    if (moment(endDate).diff(yesterday, 'days') <= 1) {
        if (weeks === 1) {
            dateDiffStr = METRIC_CARDS_TIME_PERIODS.LAST_WEEK;
        } else if (roundWithPercision(months, 0.2) === 1) {
            dateDiffStr = METRIC_CARDS_TIME_PERIODS.LAST_MONTH;
        }
    }

    return dateDiffStr;
};

export const parseChangeStr = (startDate, endDate, compare, changeValue) => {
    const dateDiffStr = parseDateDiffStr(startDate, endDate);
    const changePercentageLabel = `STATIC.PAGES.QUICK_REPORTS.METRIC_CARDS.TIME_PERIOD_${dateDiffStr}`;

    return {
        changePercentage: `${compare.compareFactorSign}${roundWithPercision(changeValue * 100, 2)}%`,
        changePercentageLabel,
    };
};

export const parseMetricCards = (isLoading, tableData, startDate, endDate) => {
    const { columnDefs, rowData } = tableData || {};
    let row1Data = null;
    let row2Data = null;

    if (!isLoading) {
        row1Data = rowData?.length > 0 ? rowData[0] : null;
        row2Data = rowData?.length > 1 ? rowData[1] : null;
    }

    return METRIC_CARDS_ORDER.map(metricName => {
        const { displayName, cohortPeriod } = METRICS_CARDS_DATA[metricName];
        const cohortPeriodStr = cohortPeriod ? `__${cohortPeriod}` : '';

        let period2MetricData = '';
        let colDef;
        let period1MetricData = 'N/A';
        let compare = { compareFactorName: '', compareFactorSign: '' };
        let changeStr = {
            changePercentage: '',
            changePercentageLabel: '',
        };
        let changeValue = 0;

        if (!isLoading && row1Data) {
            period1MetricData = row1Data[`${metricName}__period1${cohortPeriodStr}`];
            colDef = columnDefs.find(def => def.colId === `${metricName}__period1${cohortPeriodStr}`) || {};

            if (row2Data) {
                period2MetricData = row2Data[`${metricName}__period2${cohortPeriodStr}`];
                const changeColDef =
                    columnDefs.find(def => def.colId === `${metricName}__change${cohortPeriodStr}`) || {};
                const compareFunction = period1MetricData.operator
                    ? customMetricValueComparator
                    : simpleValueComparator;
                compare = parseCompareFactor(compareFunction(period1MetricData, period2MetricData));

                changeValue = changeColumnCalculateValue([period1MetricData, period2MetricData], {
                    name: changeColDef.colId,
                    metric_as_object: period1MetricData && !!period1MetricData.operator,
                });

                changeStr = changeValue ? parseChangeStr(startDate, endDate, compare, changeValue) : changeStr;
            }
        }

        const { changePercentage, changePercentageLabel } = changeStr;

        return {
            displayName: cohortPeriod ? `${displayName} ${cohortPeriod}` : displayName,
            changePercentage,
            changePercentageLabel,
            metricValue: (colDef?.valueFormatter({ value: period1MetricData }) || '').replace(' ', ''),
            noData: !row1Data && !row2Data,
            compareFactorName: compare.compareFactorName,
            period2MetricData: (colDef?.valueFormatter({ value: period2MetricData }) || '').replace(' ', ''),
        };
    });
};
