import { useCallback } from 'react';
import { useFetch } from '../benchmarks/hooks';
import AudienceABTestingService from './service';
import { normalizeDistributionsAndNetworks } from './components/Wizard/Steps/wizardUtils';

const fetchDistributionCallback = async () => {
    const audienceAbTestingAPI = new AudienceABTestingService();
    const { value } = await audienceAbTestingAPI.getDistributions();
    const { networks } = value;
    const { allNetworks, internalDistributions, distributionNetworks } = normalizeDistributionsAndNetworks(networks);
    return {
        data: { allNetworks, internalDistributions, distributionNetworks },
    };
};

export const useFetchDistributionData = () => {
    const memoizedCallback = useCallback(fetchDistributionCallback, []); // No need for `distributionData` here
    return useFetch(memoizedCallback, () => true);
};
