import { EVENT_TYPES } from '../utils/consts';
import css from './page.css';

export const DATA_TEST_ID_EVENTS = 'events-page';
export const DATA_TEST_ID_CUSTOM_EVENT_EDITOR_WITH_EVENT_TYPE = 'custom-event-editor-with-event-type';
export const DATA_TEST_ID_EVENT_SECTION_TITLE = 'event-section-title';
export const DATA_TEST_ID_CUSTOM_EVENT_EMPTY_STATE = 'custom-event-empty-state';
export const DATA_TEST_ID_STANDARD_EVENT_EMPTY_STATE = 'standard-event-empty-state';
export const DATA_TEST_ID_ARCHIVED_DROP_DOWN_BODY = 'archived-drop-down-body';

export const EVENT_TYPE_BORDER_CLASSES = {
    [EVENT_TYPES.STANDARD]: css.standardEventItemBorder,
    [EVENT_TYPES.CUSTOM]: css.customEventItemBorder,
    [EVENT_TYPES.BUILT_IN]: css.builtInEventItemBorder,
};
export const EVENT_TYPES_TOOLTIP_TEXT = Object.freeze({
    [EVENT_TYPES.STANDARD]: 'STATIC.PAGES.CUSTOM_EVENTS.STANDARD_SECTION_TOOLTIP',
    [EVENT_TYPES.CUSTOM]: 'STATIC.PAGES.CUSTOM_EVENTS.CUSTOM_SECTION_TOOLTIP',
    [EVENT_TYPES.BUILT_IN]: 'STATIC.PAGES.CUSTOM_EVENTS.BUILT_IN_SECTION_TOOLTIP',
});
export const ARCHIVED_EVENT_LIST_PLACEHOLDER = {
    name: 'default',
    display_name: 'STATIC.PAGES.CUSTOM_EVENTS.RESTORE_DROPDOWN_PLACEHOLDER',
};
export const STANDARD_EVENTS_MIX_PANEL_PREFIX = 'Standard Events';
export const EVENT_TYPES_TITLES = Object.freeze({
    [EVENT_TYPES.STANDARD]: 'STATIC.PAGES.CUSTOM_EVENTS.STANDARD_EVENTS_SECTION_CONT',
    [EVENT_TYPES.CUSTOM]: 'STATIC.PAGES.CUSTOM_EVENTS.CUSTOM_EVENTS_SECTION_CONT',
    [EVENT_TYPES.BUILT_IN]: 'STATIC.PAGES.CUSTOM_EVENTS.BUILT_IN_SECTION_CONT',
});
