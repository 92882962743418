import { connect } from 'react-redux';
import { getWarningForType, shouldShowDownloadReport } from '../../selectors/reports';
import { FieldGroup } from '../../components/widgets';
import { setActionType, downloadOrExportReport } from '../../actions/reports';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        warnings: getWarningForType(ownProps.reportType, ownProps.type)(state),
        showDownloadReport: shouldShowDownloadReport(ownProps.reportType, ownProps.type)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...ownProps,
        onDownloadReport: () => {
            return dispatch(
                setActionType(
                    downloadOrExportReport(ownProps.reportType, ownProps.reportType, 'popover', true),
                    ownProps.reportType
                )
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldGroup);
