import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Autocomplete, Box, InputAdornment, Popper, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import css from './MuiAutoComplete.css';
import OverflowingText from './OverflowingText';
import Chevron from '../../resources/icons/chevron.svg';
import Remove from '../../resources/icons/cross.svg';
import { useOutsideEventHandler } from '../../utils/customHooks';

export const REMOVE_TITLE = 'Remove';

const MemoizedOption = React.memo(({ option, getIcon, ...props }) => {
    return (
        <Box {...props} key={option.value}>
            {option.icon ? getIcon(option) : null}
            <OverflowingText>{option.label}</OverflowingText>
        </Box>
    );
});

MemoizedOption.propTypes = {
    option: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    getIcon: PropTypes.func.isRequired,
};

const MuiAutoComplete = ({
    label,
    value,
    className,
    translate,
    options,
    onChange: onChangeProp,
    disabled,
    multiple,
    loading,
    removable,
    onRemove,
    limitTags,
    dataTestId,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const selectedOption = useMemo(() => options.find(option => option.value === value) || {}, [options, value]);
    const getIcon = option => <img className={css.icon} src={option.icon} alt={option.icon} />;
    const icon = <CheckBoxOutlineBlankIcon className={css.icon} />;
    const checkedIcon = <CheckBoxIcon className={css.icon} />;
    const onClose = () => setIsOpen(false);
    const popperRef = useRef();
    const textFieldRef = useRef();
    const isShowIndicatorSeparator = !removable && !loading;

    const onOpenClick = e => {
        if (disabled || loading) return;
        e.stopPropagation();
        setIsOpen(prev => !prev);
    };

    const getInputProps = params => {
        const baseProps = {
            ...params.InputProps,
            endAdornment: (
                <div className={css.endAdornment}>
                    {isShowIndicatorSeparator && <div className={css.indicatorSeparator} />}
                    {loading && <CircularProgress color="primary" size={16} />}
                    <div className={classNames(css.open, { [css.disabled]: disabled })} onClick={onOpenClick}>
                        <Chevron
                            className={classNames(css.icon, { [css.rotated]: isOpen, [css.disabled]: disabled })}
                        />
                    </div>
                    {removable && (
                        <div className={classNames(css.remove, { [css.disabled]: disabled })} onClick={onRemove}>
                            <Remove title={REMOVE_TITLE} className={css.icon} />
                        </div>
                    )}
                </div>
            ),
        };

        if (dataTestId) {
            baseProps['data-testid'] = dataTestId;
        }

        if (multiple) {
            return baseProps;
        }

        return {
            ...baseProps,
            startAdornment: selectedOption.icon ? (
                <InputAdornment position="start">{getIcon(selectedOption)}</InputAdornment>
            ) : null,
        };
    };

    const onChange = (event, newValue) => {
        if (multiple) {
            onChangeProp(newValue.map(item => item.value));
        } else {
            onChangeProp(newValue?.value || '');
        }
    };

    const renderTags = (val, getProps) => {
        return val.map((option, index) => (
            <Chip
                {...getProps({ index })}
                key={option.value}
                label={<OverflowingText className={css.chip}>{option.label}</OverflowingText>}
                size="small"
            />
        ));
    };

    useOutsideEventHandler([popperRef, textFieldRef], onClose);

    return (
        <Autocomplete
            open={isOpen}
            className={className}
            classes={{
                option: css.option,
                noOptions: css.noOptions,
                loading: css.loadingOptions,
            }}
            options={options}
            value={value}
            multiple={multiple}
            limitTags={limitTags}
            renderTags={multiple ? renderTags : undefined}
            disableCloseOnSelect={multiple}
            getOptionLabel={option => option?.label || ''}
            onChange={onChange}
            onOpen={() => setIsOpen(true)}
            onClose={onClose}
            size="small"
            disabled={disabled}
            loading={loading}
            PopperComponent={props => <Popper {...props} ref={popperRef} placement="bottom" />}
            renderInput={params => (
                <TextField
                    {...params}
                    ref={textFieldRef}
                    label={<OverflowingText>{translate(label)}</OverflowingText>}
                    InputProps={getInputProps(params)}
                    InputLabelProps={{ ...params.InputLabelProps, className: css.inputLabel }}
                    sx={{
                        '& .MuiInputBase-root': {
                            fontSize: '14px',
                            borderRadius: '3px',
                            padding: '6px !important',
                            paddingRight: `${isShowIndicatorSeparator ? '40' : '35'}px !important`, // keeps space for chevron
                            ...(removable ? { paddingRight: '70px !important' } : {}), // keeps space for remove
                            '& .MuiAutocomplete-input ': {
                                padding: '4px 4px 4px 8px !important',
                                minWidth: '0px !important',
                            },
                            '&.Mui-focused fieldset, &:hover:not(.Mui-focused):not(.Mui-disabled) fieldset': {
                                borderColor: 'var(--blue300)',
                            },
                            '&.Mui-disabled, &.Mui-disabled > *:disabled': {
                                backgroundColor: 'var(--bodyBackgroundColor) !important',
                            },
                        },
                    }}
                />
            )}
            renderOption={(props, option, state) => {
                if (multiple) {
                    return (
                        <li {...props}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={state.selected} />
                            {option.label}
                        </li>
                    );
                }

                return <MemoizedOption {...props} option={option} getIcon={getIcon} />;
            }}
        />
    );
};

MuiAutoComplete.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    translate: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            icon: PropTypes.string,
        })
    ),
    value: PropTypes.oneOfType([
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    removable: PropTypes.bool,
    onRemove: PropTypes.func,
    limitTags: PropTypes.number,
    dataTestId: PropTypes.string,
};

MuiAutoComplete.defaultProps = {
    label: '',
    className: '',
    options: [],
    onChange: () => {},
    disabled: false,
    loading: false,
    multiple: false,
    removable: false,
    onRemove: () => {},
    limitTags: undefined,
    dataTestId: undefined,
};

export default withLocalize(MuiAutoComplete);
