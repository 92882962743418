import API from '../services/api';

let instance = null;
const api = new API();

export const EXPORT_ALL_URL = 'api/attribution/postbacks';

export default class PartnerConfigService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    exportAll() {
        return new Promise((resolve, reject) => {
            api.getJson(EXPORT_ALL_URL, {
                method: 'POST',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status && response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}
