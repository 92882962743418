import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './DataShelf.css';
import DataRefresh from './DataRefresh';
import DataAvailability from './DataAvailability';
import Tabs from '../../components/widgets/Tabs';
import DataRefreshIcon from '../../resources/svg/data-refresh.svg';
import DataAvailabilityIcon from '../../resources/svg/data-availability.svg';
import tabsCss from '../../components/widgets/Tabs.css';

const DATA_AVAILABILITY_TAB = 'dataAvailabilityTab';
const DATA_REFRESH_TAB = 'dataRefreshTab';

function DataShelf({
    uanId,
    uanName,
    uanStatus,
    channel,
    onClose,
    translate,
    dataRefreshOpen,
    canDataRefresh,
    earliestDataDate,
    timezone,
}) {
    const initialTab = dataRefreshOpen ? DATA_REFRESH_TAB : DATA_AVAILABILITY_TAB;
    const [dataRefreshTabState, setDataRefreshTabState] = useState({});
    const [dataAvailabilityTabState, setDataAvailabilityTabState] = useState({});
    const [selectedTab, setSelectedTab] = useState(initialTab);

    return (
        <div className={css.page}>
            {canDataRefresh && (
                <Tabs
                    containerClass={tabsCss.shelfTabs}
                    selected={selectedTab}
                    onClick={tabId => setSelectedTab(tabId)}
                    tabs={[
                        {
                            id: 'dataAvailabilityTab',
                            label: translate('STATIC.PAGES.DATA_SOURCES.SHELF.DATA_SHELF.DATA_AVAILABILITY_TAB'),
                            icon: DataAvailabilityIcon,
                        },
                        {
                            id: 'dataRefreshTab',
                            label: translate(`STATIC.PAGES.DATA_SOURCES.SHELF.DATA_SHELF.DATA_REFRESH_TAB`),
                            icon: DataRefreshIcon,
                            disabled: !canDataRefresh,
                        },
                    ]}
                />
            )}
            {selectedTab === DATA_AVAILABILITY_TAB && (
                <DataAvailability
                    onClose={onClose}
                    uanId={uanId}
                    innerState={dataAvailabilityTabState}
                    setInnerState={setDataAvailabilityTabState}
                    earliestDataDate={earliestDataDate}
                    timezone={timezone}
                    channel={channel}
                    uanName={uanName}
                />
            )}
            {selectedTab === DATA_REFRESH_TAB && (
                <DataRefresh
                    translate={translate}
                    onClose={onClose}
                    uanId={uanId}
                    uanName={uanName}
                    uanStatus={uanStatus}
                    innerState={dataRefreshTabState}
                    setInnerState={setDataRefreshTabState}
                />
            )}
        </div>
    );
}

DataShelf.propTypes = {
    uanId: PropTypes.number,
    onClose: PropTypes.func,
    uanName: PropTypes.string,
    channel: PropTypes.string,
    uanStatus: PropTypes.string,
    translate: PropTypes.func,
    dataRefreshOpen: PropTypes.bool,
    canDataRefresh: PropTypes.bool,
    earliestDataDate: PropTypes.string,
    timezone: PropTypes.string,
};

DataShelf.defaultProps = {
    uanId: null,
    onClose: () => {},
    translate: txt => txt,
    uanName: '',
    channel: PropTypes.string,
    uanStatus: '',
    dataRefreshOpen: false,
    canDataRefresh: false,
    earliestDataDate: null,
    timezone: '',
};

export default DataShelf;
