import { reportTypes } from 'utils/reports';
import {
    getBaseConfig,
    shelfCrossDeviceAppMetrics,
    shelfCohortMetrics,
    shelfCrossDeviceCohortType,
    shelfCrossDeviceMetrics,
    shelfCrossDeviceWebMetrics,
    shelfCustomDimensions,
    shelfCustomEvents,
    shelfDimensions,
    shelfMetrics,
    shelfTimeBreakdowns,
} from './baseReportConfig';
import { shelfPeriods } from './shelfPeriods';

const type = reportTypes.crossDevice;
const path = '/react/cross-device';

const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.CROSS_DEVICE');

export default {
    ...basicConfig,
    shelf: [
        shelfDimensions,
        shelfCustomDimensions,
        shelfMetrics,
        shelfCrossDeviceAppMetrics,
        shelfCrossDeviceWebMetrics,
        shelfCrossDeviceMetrics,
        shelfCohortMetrics,
        shelfCustomEvents,
        shelfPeriods,
        shelfTimeBreakdowns,
        shelfCrossDeviceCohortType,
    ],
    path,
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/360041518132-Cross-Device-Attribution-FAQ',
        },
    },
    table: {
        ...basicConfig.table,
        drilldownConfig: (state, { requestParams }) => {
            return requestParams.dimensions.includes('source');
        },
    },
};
