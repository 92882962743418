import { ANDROID_NATIVE, IOS_NATIVE } from './sdkPlanning/consts';

export const SDK_PLANNING = 'SDK_PLANNING';
const SDK_KEYS = 'SDK_KEYS';

export const INNER_PAGES = [SDK_PLANNING, SDK_KEYS];

export const FAQ_LINK =
    'https://support.singular.net/hc/en-us/articles/360037640172-Getting-Started-with-the-Singular-SDK-S2S';

export const SDK_INTEGRATION_MIXPANEL_EVENTS = {
    COPY_CODE: 'Copy code',
    SHOW_KEYS: 'Show keys',
    CODEBLOCK_THEME_TOGGLED: 'CodeBlock theme toggled',
    CODE_LANGUAGE_CHANGED: 'Code language changed',
    PAGE_LOAD: 'page load',
    CTA_NEXT_BUTTON: 'CTA - Next Button',
    CTA_BACK_BUTTON: 'CTA - Back Button',
    TARGET_PLATFORM_SELECTED: 'target platform selected',
    DEEP_LINKING_SAVE: 'DeepLinking save',
    GO_TO_TESTING_CONSOLE: 'go to testing console',
    DEEP_LINKING_MORE_BUTTON_CLICKED: 'DeepLinking More button clicked',
    DEEP_LINKING_APPLE: 'DeepLinking Read Apple instructions link clicked',
    LEARN_MORE: 'Learn More',
    ACCORDION_ITEM_CLICK: 'Accordion Click',
};

export const SDK_INTEGRATION_MIXPANEL_PLATFORMS = {
    [IOS_NATIVE]: 'IOS Native',
    [ANDROID_NATIVE]: 'Android Native',
};
