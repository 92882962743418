import React from 'react';
import ShelfGroup from 'components/widgets/ShelfGroup';
import css from 'alerts/components/CustomAlertsShelf.css';
import { Translate } from 'react-localize-redux';
import TagInput from 'components/widgets/TagInput';
import { DEFAULT_CONDITION } from 'alerts/consts';
import PropTypes from 'prop-types';

function BreakdownGroup({
    translate,
    reportType,
    dimensions,
    selectedBreakdownDimensions,
    onBreakdownChanged,
    mandatory_breakdown_dimensions: mandatoryBreakdownDimensions,
}) {
    const onTagAdded = (tag) => {
        const tagInfo = dimensions.filter((dimension) => dimension.display_name === tag)[0];
        onBreakdownChanged([...selectedBreakdownDimensions, tagInfo]);
    };
    const onTagRemoved = (tag) => {
        const filteredSelectedBreakdowns = selectedBreakdownDimensions.filter(
            (breakdown) => breakdown.display_name !== tag
        );
        onBreakdownChanged(filteredSelectedBreakdowns);
    };

    const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        return selectOptions.map((dimension) => dimension.display_name).includes(inputValue);
    };

    const tagsDimensions = selectedBreakdownDimensions.map((dimension) => ({
        label: dimension.display_name,
        value: dimension.display_name,
        deleteEnabled: !mandatoryBreakdownDimensions.includes(dimension.name),
    }));

    const breakdownSuggestions = dimensions
        ? dimensions.map((dimension) => dimension.display_name || DEFAULT_CONDITION)
        : null;

    return (
        <ShelfGroup
            expanded
            className={css.shelfGroup}
            contentContainer={css.shelfGroupContent}
            label={`${translate(translate('STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.BREAKDOWN'))} (${
                tagsDimensions.length
            })`}
            labelContainer={css.shelfGroupLabelContainer}
        >
            <div className={css.sectionHeader}>
                <div className={css.shelfGroupInfo}>
                    <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.BREAKDOWN_INFO_PART_1" />
                    {reportType.name !== 'TRACKER' && (
                        <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.BREAKDOWN_INFO_PART_2" />
                    )}
                </div>
            </div>
            <TagInput
                tags={tagsDimensions}
                containerStyle={{ width: '612px' }}
                creatable
                suggestions={breakdownSuggestions}
                onTagAdded={onTagAdded}
                onTagRemoved={onTagRemoved}
                isValidNewOption={isValidNewOption}
                placeholder="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.SELECT_DIMENSIONS"
                virtualScrolling
            />
        </ShelfGroup>
    );
}

BreakdownGroup.propTypes = {
    translate: () => {},
    reportType: PropTypes.objectOf(PropTypes.string),
    dimensions: PropTypes.arrayOf(PropTypes.object),
    selectedBreakdownDimensions: PropTypes.arrayOf(PropTypes.object),
    onBreakdownChanged: PropTypes.func,
    mandatory_breakdown_dimensions: PropTypes.arrayOf(PropTypes.string),
};

BreakdownGroup.defaultProps = {
    translate: PropTypes.func,
    reportType: '',
    dimensions: [],
    selectedBreakdownDimensions: [],
    onBreakdownChanged: () => {},
    mandatory_breakdown_dimensions: [],
};

export default BreakdownGroup;
