import React from 'react';
import { ColumnRangeSeries } from 'react-jsx-highcharts';

const Percentile = ({name, type, lowPoint, highPoint, maxRangeValue, color, formatter, pointWidth, borderRadius}) => {
    return (
        <ColumnRangeSeries
            name={name}
            formatter={formatter}
            pointWidth={pointWidth}
            borderRadius={borderRadius}
            data={[
                {
                    x: 0,
                    low: lowPoint,
                    // this is just a combina to represent a simple line in case the type is marker
                    high: type === 'marker' ? (lowPoint + maxRangeValue / 80) : highPoint,
                    color,
                    type,
                },
            ]}
        />
    );
};

export default Percentile;
