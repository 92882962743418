import { fork } from 'redux-saga/effects';

let instance;

class Cancelable {
    constructor() {
        if (!instance) {
            instance = this;
            instance.cancelables = [];
        }
        return instance;
    }

    cancelAll() {
        instance.cancelables.filter(c => c.result() === undefined).forEach(c => c.cancel());
        instance.cancelables = [];
    }

    wrap(func) {
        return function* cancelableInner(...args) {
            const forked = yield fork(func, ...args);
            instance.cancelables.push(forked);
        };
    }
}

export default Cancelable;
