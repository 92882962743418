export const LOAD = 'action.mta.load';
export const DESTROY = 'action.mta.destroy';
export const MTA_LOADING = 'action.multi.touch.attribution.loading';
export const MTA = 'action.multi.touch.attribution';
export const MTA_ID = 'action.multi.touch.attribution.id';
export const MTA_MESSAGE = 'action.multi.touch.attribution.message';
export const UPDATE_UAN_EVENTS_DATA = 'action.uan.events.update.data';

// triggers sagas watchers
export const DOWNLOAD_REPORT_CLICKED = 'action.mta.download.report.clicked';
export const REPORT_INFO_UPDATED = 'action.mta.report.info';

export const load = loading => {
    return {
        type: LOAD,
        loading,
    };
};

export const pageDestroy = () => {
    return {
        type: DESTROY,
    };
};

export const updateUanEventsData = data => {
    return {
        type: UPDATE_UAN_EVENTS_DATA,
        data,
    };
};

export const reportInfo = info => {
    return {
        type: REPORT_INFO_UPDATED,
        reportInfo: info,
    };
};
