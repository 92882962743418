import React from 'react';
import PropTypes from 'prop-types';
import css from './header.css';
import Dropdown from '../../../../../components/widgets/Dropdown';
import { Label } from '../../../../../components/widgets';
import { getSectionTranslateID } from '../../../sectionsWrapper/utils';
import { SDK_INTEGRATION_SECTIONS } from '../../../consts';

const { GET_SDK_FILES } = SDK_INTEGRATION_SECTIONS;

const Header = ({ selection, handleSelection, dropdownItems, platform }) => {
    const dropdownTitleTranslateID = getSectionTranslateID(GET_SDK_FILES, 'HEADER.DROPDOWN_TITLE', platform);
    return (
        <div className={css.container}>
            <Label className={css.dropdownLabel} type="sideLabel" text={dropdownTitleTranslateID} />
            <Dropdown
                containerClass={css.dropdown}
                selectedContainer={css.dropdown}
                items={dropdownItems}
                selected={dropdownItems.find(item => item.name === selection)}
                onSelection={({ name }) => {
                    handleSelection(name);
                }}
                disabled={false}
            />
        </div>
    );
};

Header.propTypes = {
    handleSelection: PropTypes.func,
    selection: PropTypes.string,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
    platform: PropTypes.string.isRequired,
};

Header.defaultProps = {
    handleSelection: () => {},
    selection: '',
};

export default Header;
