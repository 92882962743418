import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';

import css from './CreativeAsset.css';
import FallbackIcon from '../../../resources/icons/creatives/creative_fallback.svg';
import { CreativeType, CreativeTypes } from '../../types';
import VideoAsset from './VideoAsset';
import ImageAsset from './ImageAsset';

export const FALLBACK_TEXT = 'STATIC.PAGES.TAGS_VISIBILITY.UNABLE_TO_LOAD_PREVIEW';
export const GRAPHIC_CONTAINER_TEST_ID = 'graphicContainer';

const BrokenAsset = React.memo(() => (
    <div className={css.creativeFallback}>
        <FallbackIcon />
        <Translate id={FALLBACK_TEXT} />
    </div>
));

const CreativeAsset = React.forwardRef(
    (
        {
            type,
            url,
            onLoad,
            onError: onErrorProp,
            controls,
            aspectRatio,
            className,
            showCreativeTypeIndication,
            muted,
        },
        ref
    ) => {
        const [broken, setBroken] = useState(false);

        const onError = () => {
            setBroken(true);
            onErrorProp && onErrorProp();
        };

        const getAssetElement = () => {
            if (broken) return <BrokenAsset />;

            if (type === CreativeTypes.VIDEO)
                return (
                    <VideoAsset
                        url={url}
                        onLoad={onLoad}
                        onError={onError}
                        ref={ref}
                        controls={controls}
                        showCreativeTypeIndication={showCreativeTypeIndication}
                        muted={muted}
                    />
                );
            return (
                <ImageAsset
                    showCreativeTypeIndication={showCreativeTypeIndication}
                    type={type}
                    url={url}
                    onLoad={onLoad}
                    onError={onError}
                    ref={ref}
                />
            );
        };

        return (
            <div
                className={classNames(css.creativeView, className)}
                data-testid={GRAPHIC_CONTAINER_TEST_ID}
                style={{ aspectRatio }}
            >
                {getAssetElement()}
            </div>
        );
    }
);

CreativeAsset.propTypes = {
    style: PropTypes.shape({ aspectRatio: PropTypes.number }),
    type: CreativeType.type,
    url: CreativeType.url,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    controls: PropTypes.bool,
    aspectRatio: PropTypes.number,
    className: PropTypes.string,
    showCreativeTypeIndication: PropTypes.bool,
    muted: PropTypes.bool,
};

CreativeAsset.defaultProps = {
    controls: false,
    showCreativeTypeIndication: false,
    muted: true,
};

export default CreativeAsset;
