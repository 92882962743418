import rules from './fieldVisibilityRules';
import { reportTypes } from '../../utils/reports';
import { reportBase, cohorts } from './reportsBase';

export default {
    type: reportTypes.fraudSuspicious,
    fields: [...reportBase.fields, ...cohorts.fields],
    visibleRule: rules[reportTypes.fraudSuspicious],
    dataSelector: {
        ...reportBase.dataSelector,
        ...cohorts.dataSelector,
        chart: 'chart',
        tableDataFormatted: 'reportData',
        chartDataFormatted: 'chartData',
    },
    isFraud: true,
};
