import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import update from 'immutability-helper';
import { Tooltip } from 'react-tippy';
import PlusIcon from '../../resources/svg/icon_add_row.svg';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import css from './LinkParams.css';
import { TextField, Label, AutoCompleteField } from '../../components/widgets';
import { defaultExtraParamsRow, FORBIDDEN_EXTRA_PARAMS_VALUES } from '../utils';
import { generateGuid } from '../../global/utils';

class LinkParams extends React.Component {
    constructor(props) {
        super(props);
        this.handleFieldSelection = this._handleFieldSelection.bind(this);
        this.handleValueChanged = this._handleValueChanged.bind(this);
        this.handleRemoveRow = this._onRemoveRow.bind(this);
        this.handleAddRow = this._onAddRow.bind(this);
        this.fieldValueErrorMessage = this._fieldValueErrorMessage.bind(this);
    }

    _handleFieldSelection(selected, index) {
        const { rows, onUpdate } = this.props;
        let toUpdate = selected;
        if (selected === null || !selected.label) {
            toUpdate = { name: 'default', value: '' };
        }
        const updatedRows = update(rows, {
            [index]: {
                field: {
                    $set: toUpdate,
                },
            },
        });
        onUpdate(updatedRows);
    }

    _handleValueChanged(newValue, index) {
        const { rows, onUpdate } = this.props;
        const updatedRows = update(rows, {
            [index]: {
                value: {
                    $set: newValue,
                },
            },
        });
        onUpdate(updatedRows);
    }

    _onRemoveRow(index) {
        let updatedRows;
        const { rows, onUpdate } = this.props;
        if (rows.length === 1 && index === 0) {
            updatedRows = [{ ...defaultExtraParamsRow, key: generateGuid() }];
        } else {
            updatedRows = update(rows, {
                $splice: [[index, 1]],
            });
        }
        onUpdate(updatedRows);
    }

    _onAddRow() {
        const { rows, onUpdate } = this.props;
        const updatedRows = update(rows, {
            $push: [{ ...defaultExtraParamsRow, key: generateGuid() }],
        });
        onUpdate(updatedRows);
    }

    _fieldValueErrorMessage(fieldName, fieldsCount) {
        if (FORBIDDEN_EXTRA_PARAMS_VALUES.includes(fieldName)) {
            return 'Invalid parameter name';
        }
        if (fieldsCount[fieldName] && fieldsCount[fieldName] > 1) {
            return 'Duplicate parameter';
        }

        const { fields } = this.props;
        const fieldDefinition = fields.find(field => field.value === fieldName);
        if (fieldDefinition && fieldDefinition.isSourceExtraParam) {
            return 'Parameter is already defined by partner.';
        }

        return '';
    }

    render() {
        const { fields, rows, translate, label } = this.props;
        const rowsFieldNames = rows.filter(row => row.field && row.field.value).map(row => row.field.value);
        const filteredFields = fields.filter(field => {
            return !rowsFieldNames.includes(field.value);
        });
        const allRowsContainsValue = rows.every(row => row.value);
        const fieldsCount = rows.reduce((total, row) => {
            if (!total[row.field.value]) {
                total[row.field.value] = 0;
            }
            total[row.field.value] += 1;
            return total;
        }, {});
        return (
            <div className={css.container}>
                {label ? <Label type="shelfHeader" text={label} /> : null}
                {rows.map((row, index) => {
                    let val = row.field.value || '';
                    let fieldDesc = null;
                    const fieldIndex = fields.findIndex(field => field.value === val);
                    if (fieldIndex !== -1) {
                        val = fields[fieldIndex];
                        fieldDesc = fields[fieldIndex];
                    }
                    let valuePlaceHolder = translate('STATIC.PAGES.MANAGE_LINKS.TEXTFIELD_PLACEHOLDER');
                    let valueTooltip = 'Custom Parameter';
                    if (fieldDesc && fieldDesc.displayName) {
                        if (fieldDesc.displayName) {
                            valueTooltip = fieldDesc.displayName;
                            valuePlaceHolder = fieldDesc.displayName;
                        }
                        if (fieldDesc.description) {
                            valueTooltip = (
                                <div className={css.valueTooltip}>
                                    <div className={css.fieldDisplayName}>{valueTooltip}</div>
                                    {fieldDesc.description}
                                </div>
                            );
                        }
                    }
                    return (
                        <div
                            className={`${css.rowContainer}`}
                            // Note - using a non-unique key here will cause deletion of rows to behave strangely on render
                            key={row.key ? row.key : `${row.field.name}`}
                        >
                            <div style={{ display: 'flex', flex: 5 }}>
                                <AutoCompleteField
                                    placeholder="STATIC.PAGES.MANAGE_LINKS.AUTO_COMPLETE_SHORT_PLACEHOLDER"
                                    error={this.fieldValueErrorMessage(row.field.value, fieldsCount)}
                                    controlled
                                    searchable
                                    value={val}
                                    selectOptions={{
                                        isClearable: true,
                                    }}
                                    disabled={row.required}
                                    options={filteredFields}
                                    isMulti={false}
                                    containerStyle={{
                                        width: '100%',
                                    }}
                                    onChange={field => {
                                        this.handleFieldSelection(field, index);
                                    }}
                                    onInputChange={value => {
                                        this.handleFieldSelection({ label: value, value }, index);
                                    }}
                                />
                            </div>
                            <div className={css.valuesContainer}>
                                <Tooltip
                                    position="top"
                                    inertia
                                    animation="scale"
                                    distance={10}
                                    theme="light"
                                    size="big"
                                    html={valueTooltip}
                                    disabled={(row.field && row.field.name === 'default') || !row.value}
                                >
                                    <TextField
                                        value={row.value || ''}
                                        onChange={value => {
                                            this.handleValueChanged(value, index);
                                        }}
                                        disabled={row.field && row.field.name === 'default'}
                                        placeholder={valuePlaceHolder}
                                        debounce={300}
                                        error={row.required && !row.value ? 'Required field' : ''}
                                    />
                                </Tooltip>
                            </div>
                            <XIcon
                                className={`${css.xIcon} ${row.required ? css.disabled : ''}`}
                                onClick={
                                    row.required
                                        ? undefined
                                        : () => {
                                              this.handleRemoveRow(index);
                                          }
                                }
                            />
                            {index === rows.length - 1 ? (
                                <div
                                    className={`${css.plusIconContainer} ${!allRowsContainsValue ? css.disabled : ''}`}
                                    onClick={!allRowsContainsValue ? undefined : this.handleAddRow}
                                >
                                    <PlusIcon className={css.plusIcon} />
                                </div>
                            ) : (
                                <div className={`${css.plusIconContainer} ${css.plusIcon} ${css.disabled}`} />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

LinkParams.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    onUpdate: PropTypes.func,
    translate: PropTypes.func,
    label: PropTypes.string,
};

LinkParams.defaultProps = {
    fields: [],
    rows: [],
    onUpdate: () => null,
    translate: () => {},
    label: '',
};

export default withLocalize(LinkParams);
