import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize, Translate } from 'react-localize-redux';
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

import css from './MuiSelect.css';

const MuiSelect = ({ value, onChange, options, label, translate, disabled }) => {
    const labelId = `${label}-label`;

    return (
        <FormControl size="small">
            <InputLabel id={labelId} className={css.label}>
                <Translate id={label} />
            </InputLabel>
            <Select
                disabled={disabled}
                labelId={labelId}
                value={value}
                onChange={event => {
                    onChange(event.target.value);
                }}
                className={css.select}
                label={translate(label)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '45px !important',

                        '&.Mui-focused fieldset': {
                            borderColor: 'var(--blue300)',
                        },
                        '&:hover:not(.Mui-focused) fieldset': {
                            borderColor: 'var(--gray700)',
                        },
                    },
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value} className={css.option}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

MuiSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ),
    label: PropTypes.string,
    translate: PropTypes.func,
    disabled: PropTypes.bool,
};

MuiSelect.defaultProps = {
    value: '',
    label: '',
    options: [],
    onChange: () => {},
    translate: () => {},
};

export default withLocalize(MuiSelect);
