import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useMutation, useQuery } from '@apollo/client';
import DefaultIcon from '../../../resources/svg/icon_partner.svg';
import VerticalList from './VerticalList';
import { sortAlphabetically } from '../../../utils/sortUtil';
import { GET_USER_FAVOURITE_PARTNERS, UPDATE_USER_FAVOURITE_PARTNER } from './queries';
import { trackMixpanelEvent } from '../../../utils/general';
import css from './PartnerList.css';

export default function PartnersList({
    partners,
    selectedPartner,
    filter,
    className,
    onPartnerSelected,
    sortPartners,
    autoSelectItem,
}) {
    const favouritePartnersResponse = useQuery(GET_USER_FAVOURITE_PARTNERS);
    const [updateUserFavouritePartner, updateUserFavouritePartnerResponse] = useMutation(UPDATE_USER_FAVOURITE_PARTNER);

    const onFavouritePartnerClicked = (e, isFavourite, id, name) => {
        e.stopPropagation();
        trackMixpanelEvent('Partners', isFavourite ? 'Unstar Partner' : 'Star Partner', { PartnerName: name });
        updateUserFavouritePartner({ variables: { partnerId: id, favourite: !isFavourite } });
    };

    useEffect(() => {
        const { called, loading, data } = updateUserFavouritePartnerResponse;

        if (!called || loading) {
            return;
        }

        if (data?.updateUserFavouritePartner?.ok) {
            favouritePartnersResponse.refetch();
        }

        updateUserFavouritePartnerResponse.reset();
    }, [updateUserFavouritePartnerResponse]);

    const formatContent = (linksCount, appsCount, appId) => (
        <div key={appId} className={css.appSummaryContent}>
            App sites: {appsCount} <br />
            Links: {linksCount || 'Unavailable'}
        </div>
    );

    const formatLastUpdate = lastModified => {
        return `Last update ${moment(lastModified).format('MMM D, YYYY')}`;
    };

    const formattedPartners = partners.map(partner => ({
        ...partner,
        name: partner.displayName,
        content: formatContent(partner.linksCount, partner.appSitesCount),
        lastUpdate: formatLastUpdate(partner.lastModified),
    }));

    const { data, loading: loadingFavourites } = favouritePartnersResponse;
    const { favouritePartners = null } = data || {};

    if (loadingFavourites) return null;

    return (
        <VerticalList
            selectedItem={selectedPartner}
            items={formattedPartners}
            filter={filter}
            className={className}
            onItemSelected={onPartnerSelected}
            sortItems={sortPartners}
            defaultIconElement={DefaultIcon}
            enableFavourites={favouritePartnersResponse.data !== undefined}
            favouriteItems={favouritePartners}
            onFavouriteItemClicked={onFavouritePartnerClicked}
            autoSelectItem={autoSelectItem}
            favouriteTooltip="STATIC.TOOLTIPS.MARK_FAVOURITE_PARTNER"
            disableAnimation
        />
    );
}

PartnersList.propTypes = {
    partners: PropTypes.arrayOf(PropTypes.any),
    selectedPartner: PropTypes.number,
    filter: PropTypes.string,
    className: PropTypes.string,
    onPartnerSelected: PropTypes.func,
    sortPartners: PropTypes.func,
    autoSelectItem: PropTypes.bool,
};

PartnersList.defaultProps = {
    partners: [],
    selectedPartner: null,
    filter: '',
    className: '',
    onPartnerSelected: () => {},
    sortPartners: partners => partners.sort((a, b) => sortAlphabetically(a.name, b.name, false, false)),
    autoSelectItem: false,
};
