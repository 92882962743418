import PropTypes from 'prop-types';
import React from 'react';
import flatten from 'lodash/flatten';
import AddFutureCheckbox from 'teamManagement/containers/AddFutureCheckbox';
import IconMobile from 'resources/svg/icon_mobile.svg';
import AutoComplete from 'components/widgets/AutoComplete';
import FauxButton from 'components/widgets/FauxButton';
import Wizardable from 'components/widgets/Wizardable';
import WizardFooter from 'components/widgets/WizardFooter';
import PicturizedMultiSelect from 'teamManagement/components/PicturizedMultiselect';
import css from 'teamManagement/components/SourceSelection.css';

class SourceSelection extends Wizardable {
    constructor(props) {
        super(props);
        this.state = {
            sourceFilter: '',
        };
        this.selectAll = this.selectAll.bind(this);
        this.onSearchChanged = this.onSearchChanged.bind(this);
        this._textPredicate = this._textPredicate.bind(this);
        this.buttonOverrides = props.wizardOverrideButtons;
    }

    get flattenedUans() {
        const { uans } = this.props;
        let ret = uans;
        if (Array.isArray(ret)) {
            ret = flatten(uans);
        }
        return ret;
    }

    selectAll(value) {
        const { onAllChange, enableAddFuture } = this.props;
        onAllChange(
            this.flattenedUans.map(uanData => uanData.id),
            value
        );
        enableAddFuture();
    }

    onSearchChanged(val) {
        this.setState({ sourceFilter: val });
    }

    _textPredicate(source) {
        const { sourceFilter } = this.state;
        return source.displayName.toLocaleLowerCase().includes(sourceFilter.toLocaleLowerCase().trim());
    }

    validate() {
        const { translate } = this.props;
        const isValid = this.flattenedUans.filter((a) => a.selected).length;

        const tooltipButtons = ['next'];
        this.setTooltip(
            tooltipButtons,
            isValid
                ? null
                : translate('STATIC.PAGES.TEAM_MEMBERS.SELECT_AT_LEAST_ONE_TOOLTIP', {
                      itemType: 'data source',
                  })
        );

        return isValid;
    }

    _filterUANs(uans) {
        if (Array.isArray(uans)) {
            return uans.map((group) => {
                return group.filter(this._textPredicate);
            });
        }
        return uans.filter(this._textPredicate);
    }

    render() {
        const { uans, onChange, lastAction } = this.props;
        const { sourceFilter } = this.state;
        const selected = this.flattenedUans.filter((a) => a.selected).length;
        const total = this.flattenedUans.length;
        return (
            <React.Fragment>
                <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                    <div>
                        <div className={css.headline}>
                            <div className={css.preamble}>
                                <div>Select data sources to be available for this user</div>
                                <div className={css.faqLine}>
                                    For more information{' '}
                                    <a
                                        href="https://singular.zendesk.com/hc/en-us/articles/360020835952-Team-Management-FAQ"
                                        target="_blank"
                                    >
                                        read the FAQ
                                    </a>
                                    .
                                </div>
                            </div>
                            <div className={css.selectorContainer}>
                                <div className={css.counter}>{`${selected} of ${total} selected`}</div>
                                <div className={css.vSeperator} />
                                <FauxButton
                                    onClick={() => this.selectAll(selected !== total)}
                                    containerStyle={{ padding: 0 }}
                                >
                                    {selected !== total ? 'Select' : 'Deselect'} All
                                </FauxButton>
                            </div>
                        </div>
                        <AutoComplete
                            placeholder="Type to search a source"
                            containerStyle={{
                                flexBasis: '960px',
                                marginRight: '20px',
                                marginBottom: '30px',
                                width: '344px',
                            }}
                            selectOptions={{
                                options: [],
                                noOptionsMessage: () => null,
                                isSearchable: true,
                                inputValue: sourceFilter,
                            }}
                            onInputChange={this.onSearchChanged}
                            controlledValue={sourceFilter}
                        />
                    </div>

                    <PicturizedMultiSelect
                        containerStyle={{ flexShrink: 1, overflow: 'scroll' }}
                        eivarim={this._filterUANs(uans)}
                        fallback={<IconMobile className={css.fallbackIcon} />}
                        onChange={onChange}
                    />
                </this.AnimationItem>
                <WizardFooter
                    buttons={this.getButtons()}
                    leftComponents={<AddFutureCheckbox itemType="sources" form="base_source" dimensionFilter />}
                />
            </React.Fragment>
        );
    }
}

SourceSelection.propTypes = {
    wizardOverrideButtons: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onAllChange: PropTypes.func.isRequired,
    enableAddFuture: PropTypes.func.isRequired,
};

SourceSelection.defaultProps = {
    wizardOverrideButtons: {},
    translate: (x) => x,
};

export default SourceSelection;
