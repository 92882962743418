export const capitalize = s => {
    if (!s) return '';

    if (typeof s !== 'string') throw `cannot perform capitalize on type ${typeof s}`;

    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toSnakecase = string =>
    string.replace(/\.?([A-Z])/g, (x, y) => {
        return `_${y.toLowerCase()}`;
    });

export const toCamelCase = string => string.replace(/(_\w)/g, m => m[1].toUpperCase());
