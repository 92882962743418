import React from 'react';

import { useQuery } from '@apollo/client';
import GovernanceEmptyState from 'governance/schemas/GovernanceEmptyState';
import Schema from './schema';
import { computePattern, getLocalSchemaId } from '../utils';

import client from '../../apollo';
import { READ_SCHEMAS } from '../queries';

let reloadOnNextRender = false;

export function setReloadOnNextRender() {
    reloadOnNextRender = true;
}

export function addSchema(schemaType) {
    const newSchema = {
        __typename: 'SchemaNode',
        id: `local${getLocalSchemaId()}`,
        type: schemaType,
        name: 'New Schema',
        fields: [
            {
                __typename: 'FieldNode',
                guid: null,
                name: 'New Field',
                kind: 'autocomplete',
                values: [],
                default: [],
                optional: false,
                hidden: false,
            },
        ],
    };
    newSchema.pattern = computePattern(newSchema.fields);
    const { schemas } = client.readQuery({ query: READ_SCHEMAS });
    client.writeQuery({
        query: READ_SCHEMAS,
        data: {
            schemas: [newSchema, ...schemas],
        },
    });
}

function SchemasTable({ filter }) {
    const { loading, error, data, refetch } = useQuery(READ_SCHEMAS, { notifyOnNetworkStatusChange: true });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (reloadOnNextRender) {
        refetch();
        reloadOnNextRender = false;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (data.schemas.length === 0) {
        return <GovernanceEmptyState />;
    }

    return data.schemas
        .filter(x => x.name.toLowerCase().includes(filter.toLowerCase()))
        .map(schema => <Schema key={schema.id} schema={schema} />);
}

export default SchemasTable;
