import React from 'react';
import { Translate } from 'react-localize-redux';
import KeyAndSecretContainer from './components/KeyAndSecretContainer';
import css from './SDKKeys.css';

const SDKKeys = () => {
    return (
        <div className={css.pageContainer}>
            <div className={css.pageTitle}>
                <Translate id="STATIC.PAGES.SDK.SDK_KEYS.TITLE" />
            </div>
            <div className={css.pageSubTitle}>
                <KeyAndSecretContainer />
            </div>
        </div>
    );
};

export default SDKKeys;
