const deserializeReportQuery = (search, forceValues) => {
    const urlParams = new URLSearchParams(search);

    return { ...JSON.parse(urlParams.get('query')), ...forceValues };
};

const serializeReportQuery = (query, postProcessFiltersUI, columnOrder) => {
    const urlParam = new URLSearchParams();
    const queryToStringify = postProcessFiltersUI ? { ...query, post_process_filters_ui: postProcessFiltersUI } : query;

    if (columnOrder) {
        queryToStringify.column_order = columnOrder;
    }

    urlParam.set('query', JSON.stringify(queryToStringify));
    return urlParam.toString();
};

const serializeReportQueryEx = (query, postProcessFiltersUI) => {
    const queryToStringify = postProcessFiltersUI ? { ...query, post_process_filters_ui: postProcessFiltersUI } : query;

    return `query=${JSON.stringify(queryToStringify).replace(/%/g, '')}`;
};

const getImpersonationUserName = url => {
    const matched = url.match(/\?user=(.+?)(?:$|#)/);
    return matched ? matched[1] : null;
};

const setUrlSearchParams = (url, search, params) => {
    let tmpUrl = url;
    if (search) {
        tmpUrl = url.replace(search, '&&TMP_AUTH&&');
    }
    tmpUrl = tmpUrl.split('?')[0];
    return `${tmpUrl}?${params}`.replace('&&TMP_AUTH&&', search);
};

const parseSearchParams = queryString => {
    const queryStringBody = queryString.substring(queryString.indexOf('?') + 1, queryString.length);
    const queriesArr = queryStringBody.split('&');

    return queriesArr.reduce((searchParams, query) => {
        const querySplitted = query.split('=');

        if (querySplitted.length === 2) {
            searchParams[querySplitted[0]] = querySplitted[1];
        }

        return searchParams;
    }, {});
};

export const serializeSearchParams = searchParamsObjects => {
    return Object.keys(searchParamsObjects)
        .map(key => `${key}=${searchParamsObjects[key]}`)
        .join('&');
};

export const searchParamsWithReportQuery = (search = '') => {
    return search.includes('query');
};

export function getImpersonationFromQuery(paramKey = 'user') {
    const reg = new RegExp(`${paramKey}=([^&]+)`);
    const impersonationQueryParam = reg.exec(window.location.search);
    if (impersonationQueryParam === null) {
        return null;
    }
    return impersonationQueryParam[1];
}

export {
    deserializeReportQuery,
    serializeReportQuery,
    serializeReportQueryEx,
    setUrlSearchParams,
    getImpersonationUserName,
    parseSearchParams,
};
