import { gql } from '@apollo/client';

export const WIDGET_FRAGMENT = gql`
    fragment Widget on WidgetNode {
        name
        type
        dataType
        query {
            dimensions
            metrics
            dataTypeFields
            skanDateDimensionName
            filters {
                field
                operator
                values
            }
        }
        options {
            rows
            columns
            zAxis
        }
        heatMapOptions {
            heatColumn
            isHeatColumnCustomMetric
        }
        gridParams {
            i
            x
            y
            h
            w
            minH
            minW
        }
        metricForTopX
    }
`;

export const DASHBOARD_FRAGMENT = gql`
    fragment Dashboard on DashboardNode {
        id
        name
        shared
        created
        isPreconfigured
        isCreator
        startDate
        endDate
        timePeriod
        widgets {
            ...Widget
        }
    }
    ${WIDGET_FRAGMENT}
`;

export const READ_DASHBOARDS = gql`
    query dashboards($id: SpecialID) {
        dashboards(id: $id) {
            ...Dashboard
        }
    }
    ${DASHBOARD_FRAGMENT}
`;

export const READ_QUICK_REPORT = gql`
    query quickReports($type: ReportType!) {
        quickReport(reportType: $type) {
            ...Dashboard
        }
    }
    ${DASHBOARD_FRAGMENT}
`;
export const CREATE_DASHBOARD = gql`
    mutation createDashboard($name: String!, $shared: Boolean!, $startDate: Date!, $endDate: Date!) {
        createDashboard(name: $name, shared: $shared, startDate: $startDate, endDate: $endDate) {
            ...Dashboard
        }
    }
    ${DASHBOARD_FRAGMENT}
`;

export const CLONE_DASHBOARD = gql`
    mutation cloneDashboard(
        $id: SpecialID!
        $name: String!
        $shared: Boolean!
        $startDate: Date!
        $endDate: Date!
        $timePeriod: String!
    ) {
        cloneDashboard(
            id: $id
            name: $name
            shared: $shared
            startDate: $startDate
            endDate: $endDate
            timePeriod: $timePeriod
        ) {
            ...Dashboard
        }
    }
    ${DASHBOARD_FRAGMENT}
`;

export const UPDATE_DASHBOARD = gql`
    mutation updateDashboard(
        $id: SpecialID!
        $name: String!
        $shared: Boolean!
        $widgets: [WidgetNodeInput!]
        $startDate: Date!
        $endDate: Date!
        $timePeriod: String!
    ) {
        updateDashboard(
            id: $id
            name: $name
            shared: $shared
            widgets: $widgets
            startDate: $startDate
            endDate: $endDate
            timePeriod: $timePeriod
        ) {
            ...Dashboard
        }
    }
    ${DASHBOARD_FRAGMENT}
`;

export const DELETE_DASHBOARD = gql`
    mutation deleteDashboard($id: SpecialID!) {
        deleteDashboard(id: $id) {
            ok
        }
    }
`;
