import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCSS from '../../components/widgets/Table.css';
import css from '../styles.css';
import { sortAlphabetically } from '../../utils/sortUtil';
import FieldsAvailabilityTableHead from './fieldsAvailabilityTableHead';
import FieldsAvailabilityTableBody from './fieldsAvailabillityTableBody';
import { getFieldInfoText, sortByAvailability } from '../utils';

const DimensionsAvailabilityTable = ({ sourceName, availabilityByDimension, dimensionsDict, showTableHead }) => {
    return (
        <table className={classNames(TableCSS.table, css.table)}>
            {showTableHead && <FieldsAvailabilityTableHead headerTitles={['Dimensions', 'Network', 'Tracker']} />}
            <tbody>
                {Object.keys(dimensionsDict)
                    .sort((dim1, dim2) => {
                        return (
                            sortByAvailability(availabilityByDimension[dim1], availabilityByDimension[dim2], sourceName) ||
                            sortAlphabetically(dimensionsDict[dim1].display_name, dimensionsDict[dim2].display_name));
                    })
                    .map((dim) => {
                        const {
                            display_name: displayName,
                            visible,
                            skan_visible: skanVisible,
                            skan_summary_visible: skanSummaryVisible,
                            creative_visible: creativeVisible
                        } = dimensionsDict[dim] || {};

                        return (
                            <FieldsAvailabilityTableBody
                                key={displayName}
                                infoText={getFieldInfoText(visible, creativeVisible, skanVisible, skanSummaryVisible)}
                                availabilityDictKey={sourceName}
                                availabilityDict={availabilityByDimension[dim] || {}}
                                displayName={displayName}
                            />
                        );
                    })}
            </tbody>
        </table>
    );
};

DimensionsAvailabilityTable.propTypes = {
    availabilityByDimension: PropTypes.objectOf(PropTypes.any).isRequired,
    sourceName: PropTypes.string.isRequired,
};

DimensionsAvailabilityTable.defaultProps = {};

export default DimensionsAvailabilityTable;
