import React, { useState } from 'react';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import SingularButton from '../../../components/widgets/SingularButton';
import { HIDE_INPUT_TEXT } from '../consts';
import css from './KeyAndSecretContainer.css';
import { useSDKPageContext } from '../../context/useSDKPageContext';

const KeyAndSecretContainer = () => {
    const { userSdkKey, userSdkToken } = useSDKPageContext();
    const [sdkKey, setSdkKey] = useState(HIDE_INPUT_TEXT);
    const [sdkToken, setSdkToken] = useState(HIDE_INPUT_TEXT);
    const [showSecretKeys, setShowSecretKeys] = useState(false);

    const onButtonClick = () => {
        if (showSecretKeys) {
            setSdkKey(HIDE_INPUT_TEXT);
            setSdkToken(HIDE_INPUT_TEXT);
        } else {
            setSdkKey(userSdkKey);
            setSdkToken(userSdkToken);
        }
        setShowSecretKeys(!showSecretKeys);
    };

    return (
        <>
            <Translate id="STATIC.PAGES.SDK.SDK_KEYS.SUB_TITLE" />
            <div className={css.keyAndTokenContent}>
                <div className={css.keyAndTokenText}>
                    <div className={css.inputBox}>
                        <div className={css.inputText}>SDK Key</div>
                        <span className={classNames({ [css.disableSelect]: !showSecretKeys })}>{sdkKey}</span>
                    </div>
                    <div className={css.inputBox}>
                        <div className={css.inputText}>SDK Secret</div>
                        <span className={classNames({ [css.disableSelect]: !showSecretKeys })}>{sdkToken}</span>
                    </div>
                </div>
                <div className={css.keyAndTokenButtonContainer}>
                    <SingularButton
                        className={css.keyAndTokenButton}
                        onClick={onButtonClick}
                        text={
                            showSecretKeys
                                ? 'STATIC.PAGES.SDK.SDK_KEYS.HIDE_KEYS'
                                : 'STATIC.PAGES.SDK.SDK_KEYS.SHOW_KEYS'
                        }
                        type="primary"
                    />
                </div>
            </div>
        </>
    );
};

export default KeyAndSecretContainer;
