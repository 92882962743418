import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import pick from 'lodash/pick';
import { getUserData } from 'selectors/user';
import { getIsLoading } from '../appLoadingState/selector';

export const REPORTS_TAB = 'reports';
export const getBackupDimensionList = state => {
    return state.customDimensions.dimensionsBackup;
};
const getDimensionsMap = state => {
    return state.customDimensions.dimensionsMap;
};
const getDimensionFilter = state => {
    return state.customDimensions.dimensionFilter;
};
const getMaxDimensions = state => {
    return state.customDimensions.maxDimensions;
};
const getTabsToShow = state => {
    return state.customDimensions.tabsToShow;
};
export const getDeleteDialogDimension = state => {
    return state.customDimensions.deleteDialogOn;
};
const getUnfilteredDimensionsList = state => {
    return state.customDimensions.dimensionsList;
};
export const getTab = state => {
    return state.customDimensions.tab;
};
const getDimensionValues = state => {
    return state.customDimensions.valuesMap;
};
const getDimensionRules = state => {
    return state.customDimensions.rulesMap;
};
const hashCode = s => {
    return String(s)
        .split('')
        .reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0);
};

const getDimensionConfigFromValueId = valueId => {
    return state => {
        return state.customDimensions.valuesMap[valueId]
            ? state.customDimensions.dimensionsMap[state.customDimensions.valuesMap[valueId].dimensionId]
            : null;
    };
};
const getDimensionConditions = state => {
    return state.customDimensions.conditionsMap;
};

const getAvailableDimensions = state =>
    state.customDimensions.availableDimensions.filter(dimension => dimension.tabs.includes(state.customDimensions.tab));

const getAvailableOperators = state => {
    return state.customDimensions.availableOperators;
};

const getRuleConfig = (rules, conditions, ruleId) => {
    const { level, op, inner, parentId } = rules[ruleId];
    let innerActual = [];
    if (level === 0) {
        innerActual = inner.map(conditionId => {
            const { dimension, operator, values } = conditions[conditionId];
            return {
                dimension,
                operator,
                values,
                id: conditionId,
                parentId: ruleId,
                hash:
                    hashCode(dimension) + hashCode(operator) + values.map(x => hashCode(x)).reduce((x, y) => x + y, 0),
            };
        });
    } else {
        innerActual = inner.map(innerRuleId => {
            return getRuleConfig(rules, conditions, innerRuleId);
        });
    }
    return {
        id: ruleId,
        level,
        op,
        inner: innerActual,
        parentId,
        hash: innerActual.map(x => x.hash).reduce((x, y) => x + y, hashCode(ruleId)),
    };
};

const getDimensionConfig = dimensionId => {
    return globalState => {
        const state = globalState.customDimensions;
        const config = { dimensionsMap: { [dimensionId]: state.dimensionsMap[dimensionId] } };
        config.valuesMap = pick(state.valuesMap, state.dimensionsMap[dimensionId].values);
        config.rulesMap = {};
        config.conditionsMap = {};
        const rulesStack = state.dimensionsMap[dimensionId].values?.map(x => state.valuesMap[x].rule);
        while (rulesStack?.length) {
            const ruleId = rulesStack.pop();
            const rule = state.rulesMap[ruleId];
            config.rulesMap[ruleId] = rule;
            if (rule.level > 0) {
                rulesStack.push(...rule.inner);
            } else {
                config.conditionsMap = { ...config.conditionsMap, ...pick(state.conditionsMap, rule.inner) };
            }
        }
        config.availableDimensions = state.availableDimensions;
        return config;
    };
};

const getDimensionRelatedObjects = globalState => {
    const deleteDialogDimension = getDeleteDialogDimension(globalState);
    return deleteDialogDimension && globalState?.customDimensions?.customDimensionsRelated[deleteDialogDimension];
};

// const getDimensionsList = (state) => { return state.customDimensions.dimensionsList; };
const getDimensionsList = createSelector(
    [getTab, getUnfilteredDimensionsList, getDimensionsMap],
    (tab, unfilteredDimensionsList, dimensionsMap) => {
        return unfilteredDimensionsList.filter(d => dimensionsMap[d].availableInTab === tab);
    }
);

const getTotalDimensions = createSelector([getUnfilteredDimensionsList], unfilteredDimensionsList => {
    return unfilteredDimensionsList.length;
});

export const getCustomDimensionsPage = createSelector(
    [
        getIsLoading,
        getDimensionsList,
        getBackupDimensionList,
        getDimensionFilter,
        getDimensionsMap,
        getDeleteDialogDimension,
        getMaxDimensions,
        getTab,
        getUnfilteredDimensionsList,
        getTabsToShow,
        getTotalDimensions,
        getDimensionRelatedObjects,
    ],
    (
        isLoading,
        dimensionsList,
        backupDimensionsDict,
        dimensionFilter,
        dimensionsMap,
        deleteDialogDimension,
        maxDimensions,
        tab,
        unfiltered,
        tabsToShow,
        totalDimensions,
        dimensionRelatedObjects
    ) => {
        const notNullBackups = Object.keys(backupDimensionsDict).filter(x => backupDimensionsDict[x] !== null);
        return {
            pageLoaded: !isLoading,
            dimensionsList: dimensionsList.filter(
                x => dimensionsMap[x].name.toLowerCase().indexOf(dimensionFilter.toLowerCase()) > -1
            ),
            unsavedCustomDimension: unfiltered.length !== notNullBackups.length,
            filterText: dimensionFilter,
            showDeleteDialog: !!deleteDialogDimension,
            deleteDialogDimension,
            maxDimensions,
            totalDimensions,
            tabsToShow,
            dimensionRelatedObjects,
            tab,
        };
    }
);

export const getDimensionToConfigSelector = dimensionId => {
    return createSelector([getDimensionConfig(dimensionId)], dimensionConfig => {
        return {
            dimensionConfig,
        };
    });
};

export const createCustomDimensionValueSelector = id => {
    return createSelector(
        [
            getDimensionConfigFromValueId(id),
            getDimensionValues,
            getDimensionRules,
            getDimensionConditions,
            getAvailableDimensions,
            getAvailableOperators,
            getTab,
        ],
        (dimensionConfig, dimensionValues, rules, conditions, availableDimensions, availableOperators, tab) => {
            if (dimensionValues[id]) {
                return {
                    id,
                    value: dimensionValues[id].value,
                    rule: getRuleConfig(rules, conditions, dimensionValues[id].rule),
                    availableDimensions,
                    availableOperators,
                    saveStatus: dimensionConfig.saveStatus,
                    tab,
                };
            }
            return {
                id,
                value: null,
                rule: null,
                availableDimensions,
                availableOperators,
                saveStatus: null,
                tab,
            };
        }
    );
};

const getDimension = dimensionId => {
    return state => {
        return state.customDimensions.dimensionsMap[dimensionId];
    };
};

const getMaxConditionsPerDimension = state => {
    return state.customDimensions.maxConditionsPerDimension;
};

const getSupportedMacroDimensions = state => {
    return state.customDimensions.supportedMacroDimensions.filter(dimension =>
        dimension.tabs.includes(state.customDimensions.tab)
    );
};

const getV3Dimensions = state => {
    return state.customDimensions.v3Dimensions;
};

const getRuleConditionsNumber = (rules, ruleId) => {
    const { level, inner } = rules[ruleId];
    let count = 0;
    if (level === 0) {
        count += inner.length;
    } else {
        count += inner
            .map(innerRuleId => {
                return getRuleConditionsNumber(rules, innerRuleId);
            })
            .reduce((x, y) => x + y);
    }
    return count;
};

const getV3DimensionInUse = (conditionsMap, v3Dimensions) => {
    const v3DimensionsInUse = Object.values(conditionsMap).find(condition => {
        return v3Dimensions[condition.dimension];
    });
    if (v3DimensionsInUse) return v3Dimensions[v3DimensionsInUse.dimension];
    else return null;
};

export const createCustomDimensionSelector = dimensionId => {
    return createSelector(
        [
            getDimension(dimensionId),
            getDimensionConfig(dimensionId),
            getDimensionValues,
            getDimensionRules,
            getDimensionConditions,
            getMaxConditionsPerDimension,
            getSupportedMacroDimensions,
            getV3Dimensions,
            getTab,
            getUserData,
        ],
        (
            dimension,
            dimensionConfig,
            dimensionValues,
            rules,
            conditions,
            maxConditionsPerDimension,
            supportedMacroDimensions,
            v3Dimensions,
            tab,
            userData
        ) => {
            const { name, defaultValue, expanded } = dimension;
            const v3DimensionInUse = getV3DimensionInUse(dimensionConfig.conditionsMap, v3Dimensions);
            const showCalendarIcon = moment(userData.organization_created) < moment('2019-12-01'); // we've reindexed the data until November 2019 for organization created after December 2019
            return {
                id: dimensionId,
                name,
                v3DimensionInUse,
                defaultValue,
                expanded,
                maxConditionsPerDimension,
                valueIds: dimension.values,
                values:
                    dimension.values?.map(valueId => {
                        const value = dimensionValues[valueId];
                        return value.value;
                    }) || [],
                numberOfConditions: !dimension.values?.length
                    ? 0
                    : dimension.values
                          .map(valueId => getRuleConditionsNumber(rules, dimensionValues[valueId].rule))
                          .reduce((x, y) => x + y),
                saveStatus: dimension.saveStatus,
                supportedMacroDimensions,
                tab,
                showCalendarIcon,
            };
        }
    );
};
