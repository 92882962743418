import { connect } from 'react-redux';
import WebTrackingLinks from './page';
import * as actions from './actions';
import { getMyState } from './selectors';

const mapStateToProps = state => {
    return getMyState(state);
};

const mapDispatchToProps = dispatch => {
    return {
        updateTarget: selection => dispatch(actions.updateTarget(selection)),
        updateGeneralField: (field, key, selection) => dispatch(actions.updateGeneralField(field, key, selection)),
        updateAttribute: (attribute, value) => dispatch(actions.updateAttribute(attribute, value)),
        partnerDiscover: partnerId => dispatch(actions.partnerDiscover(partnerId)),
        generateLink: (url, attributes, targetId, partnerId, landingPage, reEnabled) =>
            dispatch(actions.generateLink(url, attributes, targetId, partnerId, landingPage, reEnabled)),
        toggleInstructionsShelf: () => dispatch(actions.toggleInstructionsShelf()),
        onReToggleChanged: () => dispatch(actions.onReToggleChanged()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebTrackingLinks);
