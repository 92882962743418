import React from 'react';
import { SDK_INTEGRATION_SECTIONS } from '../consts';
import DeepLinking from '../components/sections/DeepLinking';
import AndroidManifestSettings from '../components/sections/android/AndroidManifestSettings/AndroidManifestSettings';
import GetSDKFiles from '../components/sections/GetSDKFiles/GetSDKFiles';
import Events from '../components/sections/events/Events';
import Revenue from '../components/sections/revenue/Revenue';
import TestRevenue from '../components/sections/testRevenue/TestRevenue';
import TestingPage from '../components/sections/TestingPage';
import AdMonetization from '../components/sections/AdMonetization/AdMonetization';

const {
    TEST_A_SESSION,
    TEST_EVENTS,
    EVENTS,
    DEEP_LINKING,
    GET_SDK_FILES,
    ANDROID_MANIFEST_SETTINGS,
    AD_MONETIZATION,
    REVENUE,
    TEST_REVENUE,
} = SDK_INTEGRATION_SECTIONS;

const CUSTOM_SECTIONS = {
    [DEEP_LINKING]: <DeepLinking />,
    [GET_SDK_FILES]: <GetSDKFiles />,
    [EVENTS]: <Events />,
    [ANDROID_MANIFEST_SETTINGS]: <AndroidManifestSettings />,
    [TEST_A_SESSION]: <TestingPage sectionKey={TEST_A_SESSION} />,
    [TEST_EVENTS]: <TestingPage sectionKey={TEST_EVENTS} />,
    [REVENUE]: <Revenue />,
    [TEST_REVENUE]: <TestRevenue />,
    [AD_MONETIZATION]: <AdMonetization />,
};

export default CUSTOM_SECTIONS;
