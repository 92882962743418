import { SECTIONS_TRANSLATE_KEY } from '../components/sections/consts';
import { sendMixpanelEventSdkIntegration } from '../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../consts';

const translateExists = (translateFunction, translateID) => translateFunction(translateID) !== translateID;

const getSectionTranslateID = (sectionKey, translateKey, platform) => {
    return platform
        ? `${SECTIONS_TRANSLATE_KEY}.${platform}.${sectionKey}.${translateKey}`
        : `${SECTIONS_TRANSLATE_KEY}.${sectionKey}.${translateKey}`;
};

const formatItem = (item, disabled = false) => {
    return {
        content: item.component,
        itemTitle: item.itemTitle,
        ...(disabled && {
            disabled,
            tooltipContent: 'STATIC.PAGES.SDK.SDK_PLANNING.DISABLED_TOOLTIP_MESSAGE',
        }),
    };
};

const formatItemWithChildren = (item, disabled, itemsToDisable) => ({
    ...formatItem(item, disabled),
    nestedItems: Object.fromEntries(
        Object.entries(item.nestedItems).map(([key, nestedItem]) => [
            key,
            formatItem(nestedItem, itemsToDisable.includes(key)),
        ])
    ),
});

const calculateSectionKeysToDisable = (platform, sdkConfigurationForPlanning) => {
    if (Object.keys(sdkConfigurationForPlanning).length > 0) {
        const sdkConfigurationOfPlatform = sdkConfigurationForPlanning[platform];
        return Object.keys(sdkConfigurationOfPlatform).filter(question => !sdkConfigurationOfPlatform[question]);
    } else {
        return [];
    }
};

const onHelpCenterLinkClick = (url, sectionKey, platform) => {
    if (!url) return;
    sendMixpanelEventSdkIntegration(SDK_INTEGRATION_MIXPANEL_EVENTS.LEARN_MORE, sectionKey, platform, {
        'Help Center url': url,
    });
};

export {
    calculateSectionKeysToDisable,
    getSectionTranslateID,
    formatItemWithChildren,
    formatItem,
    translateExists,
    onHelpCenterLinkClick,
};
