import { createSelector } from 'reselect';
import { getFields } from '../selectors/fields';
import fieldVisibilityRules from '../selectors/reportsConfig/fieldVisibilityRules';
import { getAdminModeEnabled, getUserData } from '../selectors/user';
import { sortAlphabetically } from '../utils/sortUtil';
import { NON_REPORTS_FIELDS_WHITELIST } from './consts';

const FIELD_TYPE_WHITELIST = ['dimensions'];

export const getVisibleFields = createSelector(
    [getFields, getAdminModeEnabled, getUserData],
    (fields, adminModeEnabled, userData) => {
        const {
            reports: reportsVisibilityRules,
            skan: skanVisibilityRules,
            skanSummary: skanSummaryVisibilityRules,
        } = fieldVisibilityRules;
        return Object.keys(fields)
            .filter(key => {
                return FIELD_TYPE_WHITELIST.includes(key);
            })
            .reduce((accum, key) => {
                let filteredFields = [];

                if (fields[key].filter) {
                    filteredFields = fields[key].filter(field => {
                        return (
                            reportsVisibilityRules(field, false, userData, false) ||
                            skanVisibilityRules(field, false, userData, false) ||
                            skanSummaryVisibilityRules(field, false, userData, false) ||
                            NON_REPORTS_FIELDS_WHITELIST.includes(field.name)
                        );
                    });
                }

                return accum.concat(filteredFields);
            }, [])
            .sort(({ display_name: displayName1 }, { display_name: displayName2 }) =>
                sortAlphabetically(displayName1, displayName2)
            );
    }
);
