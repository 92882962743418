import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import OptimizedModel from '../../../../../dashboard/img/optimized_skan_model.svg';
import UnoptimizedModel from '../../../../../dashboard/img/unoptimized_skan_model.svg';
import WarningModel from '../../../../../dashboard/img/warning_skan_model.svg';
import css from './TagsSection.css';
import verticalListCss from '../../../components/partials/VerticalList/VerticalList.css';
import { SKAN_PLUS_STATUSES } from '../../utils';
import Tooltip from '../../../components/widgets/Tooltip';

const SKAN_PLUS_STATUSES_TOOLTIPS = {
    [SKAN_PLUS_STATUSES.OPTIMIZED_MODEL]: 'OPTIMIZED_MODEL',
    [SKAN_PLUS_STATUSES.UNOPTIMIZED_MODEL]: 'UNOPTIMIZED_MODEL',
};

function TagsSection({ skanPlusStatus, hasWarning }) {
    const icon = useMemo(
        () => (skanPlusStatus === SKAN_PLUS_STATUSES.OPTIMIZED_MODEL ? <OptimizedModel /> : <UnoptimizedModel />),
        [skanPlusStatus]
    );

    return (
        <div className={css.container}>
            {skanPlusStatus !== SKAN_PLUS_STATUSES.NO_OPTIMIZATION_MODEL && (
                <Tooltip
                    titleTranslationKey={`STATIC.PAGES.SKADNETWORK.SKAN_PLUS_CHIP_TOOLTIP.${SKAN_PLUS_STATUSES_TOOLTIPS[skanPlusStatus]}`}
                    position="top-start"
                >
                    <Chip
                        className={classNames(css.chip, css.skanChip, css[skanPlusStatus], {
                            [verticalListCss.colorWithParentItem]:
                                skanPlusStatus === SKAN_PLUS_STATUSES.OPTIMIZED_MODEL,
                        })}
                        icon={icon}
                        label="SKAN+"
                        variant="outlined"
                    />
                </Tooltip>
            )}
            {hasWarning && (
                <Chip
                    className={classNames(css.chip, css.warningChip)}
                    icon={<WarningModel />}
                    label="Warning"
                    variant="outlined"
                />
            )}
        </div>
    );
}

TagsSection.propTypes = {
    skanPlusStatus: PropTypes.string,
    hasWarning: PropTypes.bool,
};

TagsSection.defaultProps = {
    skanPlusStatus: '',
    hasWarning: false,
};

export default withLocalize(TagsSection);
