import { PARSING_DATA_TYPES } from '../utils/consts';
import { CODE_BLOCK_SNIPPETS_LANGUAGES } from '../components/partials/CodeBlock/consts';
import { EmptyStateTasks } from '../components/partials/EmptyState/utils';
import AddApp from '../resources/svg/icons/app_plus.svg';
import AddDevice from '../resources/svg/icons/device_plus.svg';

export const EMPTY_OPTIONS = [];

export const IOS = 'iOS';
export const ANDROID = 'Android';
export const WEB = 'Web';
export const PC = 'PC';
export const XBOX = 'Xbox';
export const PLAYSTATION = 'Playstation';
export const NINTENDO = 'Nintendo';
export const METAQUEST = 'MetaQuest';
export const CTV = 'CTV';
export const VALID_PLATFORMS = [IOS, ANDROID, WEB, PC, XBOX, PLAYSTATION, NINTENDO, METAQUEST, CTV];

export const IDFV = 'IDFV';
export const IDFA = 'IDFA';
export const AIFA = 'AIFA';
export const ANDI = 'ANDI';
export const OAID = 'OAID';
export const AMID = 'AMID';
export const ASID = 'ASID';
export const SDID = 'SDID';

export const KEYSPACE_TO_PLATFORM = {
    [IDFV]: IOS,
    [IDFA]: IOS,
    [AIFA]: ANDROID,
    [ANDI]: ANDROID,
    [OAID]: ANDROID,
    [AMID]: ANDROID,
    [ASID]: ANDROID,
    [SDID]: WEB,
};

export const DEVICE_INITIAL_STATE = {
    name: 'default',
    display_name: 'Select a device',
    editable: false,
};

export const APPSITE_INITIAL_STATE = '';

export const DEVICE_TYPES_KEYSPACES = [
    { name: IDFV, display_name: 'IDFV', platform: IOS },
    { name: IDFA, display_name: 'IDFA', platform: IOS },
    { name: AIFA, display_name: 'Google Advertising ID (GAID or AIFA)', platform: ANDROID },
    { name: ANDI, display_name: 'Android ID (AID)', platform: ANDROID },
    { name: OAID, display_name: 'Open Advertising ID', platform: ANDROID },
    { name: AMID, display_name: 'Amazon Advertising ID', platform: ANDROID },
    { name: ASID, display_name: 'App Set ID', platform: ANDROID },
    { name: SDID, display_name: 'Singular Device ID', platform: WEB },
    { name: SDID, display_name: 'Singular Device ID', platform: IOS },
    { name: SDID, display_name: 'Singular Device ID', platform: ANDROID },
    { name: SDID, display_name: 'Singular Device ID', platform: PC },
    { name: SDID, display_name: 'Singular Device ID', platform: XBOX },
    { name: SDID, display_name: 'Singular Device ID', platform: PLAYSTATION },
    { name: SDID, display_name: 'Singular Device ID', platform: NINTENDO },
    { name: SDID, display_name: 'Singular Device ID', platform: METAQUEST },
    { name: SDID, display_name: 'Singular Device ID', platform: CTV },
];

export const DEVICE_TYPE_KEYSPACES_BY_PLATFORM = DEVICE_TYPES_KEYSPACES.reduce((mapping, keyspace) => {
    if (mapping[keyspace.platform]) {
        mapping[keyspace.platform].push(keyspace);
    } else {
        mapping[keyspace.platform] = [keyspace];
    }
    return mapping;
}, {});

const AVAILABLE_KEYSPACES = [...new Set(DEVICE_TYPES_KEYSPACES.map(type => type.name))];

export const AVAILABLE_PLATFORMS = Object.keys(DEVICE_TYPE_KEYSPACES_BY_PLATFORM).map(key => {
    return {
        name: key,
        display_name: key,
    };
});

export const PLATFORM_NAMES_REGEX = new RegExp(Object.keys(DEVICE_TYPE_KEYSPACES_BY_PLATFORM).join('|'));
export const AVAILABLE_KEYSPACES_REGEX = new RegExp(AVAILABLE_KEYSPACES.join('|'));

export const EMPTY_STATE_BOXES_TASKS = {
    [EmptyStateTasks.ADD_APP]: {
        translateKey: 'ADD_APP',
        icon: AddApp,
    },
    [EmptyStateTasks.REGISTER_DEVICE]: {
        translateKey: 'ADD_DEVICE',
        icon: AddDevice,
    },
};

export const DEVICE_TYPES_KEYSPACES_SHOW_DESCRIPTION = [IDFA, IDFV, AIFA, ANDI, OAID, SDID];

export const DEVICE_TYPES_KEYSPACES_INSTRUCTIONS_CODE_SNIPPETS = {
    IDFA: {
        items: [
            {
                lang: CODE_BLOCK_SNIPPETS_LANGUAGES.SWIFT,
                code: `//Example in Swift \nprint("IDFA", ASIdentifierManager.shared().advertisingIdentifier.uuidString)`,
            },
            {
                lang: CODE_BLOCK_SNIPPETS_LANGUAGES.OBJECTIVEC,
                code: `//Example in Objective-C \nNSString *IDFA = [[[ASIdentifierManager sharedManager] advertisingIdentifier] UUIDString];\nNSLog(@"IDFA: %@", IDFA);`,
            },
        ],
    },
    IDFV: {
        items: [
            {
                lang: CODE_BLOCK_SNIPPETS_LANGUAGES.SWIFT,
                code: `//Example in Swift\nprint("IDFV", UIDevice.current.identifierForVendor!.uuidString)`,
            },
            {
                lang: CODE_BLOCK_SNIPPETS_LANGUAGES.OBJECTIVEC,
                code: `//Example in Objective-C\nNSString *IDFV = [[[UIDevice currentDevice] identifierForVendor] UUIDString];\nNSLog(@"IDFV: %@", IDFV);`,
            },
        ],
    },
    AIFA: {
        items: [
            {
                lang: CODE_BLOCK_SNIPPETS_LANGUAGES.JAVASCRIPT,
                code: `SingularConfig config = new SingularConfig(SdkKey, SdkSecret)\n\t.withLoggingEnabled()\n\t.withLogLevel(1);`,
            },
        ],
    },
    SDID: {
        items: [
            {
                lang: CODE_BLOCK_SNIPPETS_LANGUAGES.JAVASCRIPT,
                code: `singularSdk.getSingularDeviceId();`,
            },
        ],
    },
};

export const OPERATOR_OPTIONS = DEVICE_TYPES_KEYSPACES.map(keyspace => {
    return { ...keyspace, display_name: `${keyspace.platform} - ${keyspace.display_name}` };
});

export const IOS_KEY_SPACES = DEVICE_TYPES_KEYSPACES.filter(keyspace => keyspace.platform === IOS).map(keyspace => {
    return keyspace.name;
});
export const ANDROID_KEY_SPACES = DEVICE_TYPES_KEYSPACES.filter(keyspace => keyspace.platform === ANDROID).map(
    keyspace => {
        return keyspace.name;
    }
);
export const IDENTIFIER_PLACEHOLDER = 'cdda000e-fb0c-00ad-1234-0123d123c123';
export const ANDROID_ID_IDENTIFIER_PLACEHOLDER = 'cde2a00efb0c0012';

export const VALIDATION_REGEXES = {
    deviceId: /^[0-9a-f/-]{16,40}$/i,
    notEmpty: /^.*\S+.*$/,
    atLeastThree: /\s*(?:\S\s*){3,}$/,
};

export const posedTableRowConfig = {
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            y: {
                duration: 500,
            },
        },
    },
    exit: {
        opacity: 0,
    },
};

export const logDetailsConfig = [
    { label: 'Device ID', key: 'device_id' },
    { label: 'Timestamp', key: 'datetime', parsingType: PARSING_DATA_TYPES.DATE },
    { label: 'Platform', key: 'device_platform' },
    { label: 'Event Name', key: 'event_name' },
    { label: 'IP Address', key: 'device_ip' },
    { label: 'Deeplink URL', key: 'session_deeplink_url' },
    { label: 'Deferred Deeplink URL', key: 'session_deferred_deeplink_url' },
    { label: 'Opened by Deep link', key: 'session_deeplink_url', parsingType: PARSING_DATA_TYPES.BOOL },
    {
        label: 'Deferred Deep Link Available',
        key: 'device_deferred_deep_link_enabled',
        parsingType: PARSING_DATA_TYPES.BOOL,
    },
    { label: 'Brand', key: 'device_brand' },
    { label: 'Custom User ID', key: 'device_custom_user_id' },
    { label: 'OS Version', key: 'device_os_version' },
    {
        label: 'Deferred DeepLink Enabled',
        key: 'device_deferred_deep_link_enabled',
        parsingType: PARSING_DATA_TYPES.BOOL,
    },
    { label: 'SDK', key: 'app_sdk_version' },
    { label: 'Application Version', key: 'app_version' },
    { label: 'Do not track', key: 'device_do_not_track', parsingType: PARSING_DATA_TYPES.BOOL },
    {
        label: 'Is Receipt Included',
        key: 'is_receipt_included',
        parsingType: PARSING_DATA_TYPES.BOOL,
        revenueOnlyField: true,
    },
    { label: 'Revenue Amount', key: 'event_revenue_amount', revenueOnlyField: true },
    { label: 'Revenue Currency', key: 'event_revenue_currency', revenueOnlyField: true },
    { label: 'Apple Search Ads Token', key: 'apple_attribution_token', platformRestricted: [IOS] },
    { label: 'SKAN Enabled', key: 'is_skan_enabled', parsingType: PARSING_DATA_TYPES.BOOL, platformRestricted: [IOS] },
    { label: 'ATT status', key: 'att_authorization_status' },
    { label: 'APNS Token', key: 'device_apns_token' },
    { label: 'GCM Token', key: 'device_gcm_token' },
    { label: 'FCM Token', key: 'device_fcm_token' },
    { label: 'Request Hash', key: 'request_hash', isAdminView: true },
    { label: 'Internal Event Hash', key: 'internal_event_hash', isAdminView: true },
    { label: 'Lag', key: 'request_lag', isAdminView: true },
    { label: 'Session ID', key: 'session_id', isAdminView: true },
    {
        label: 'Install Referrer Exists',
        key: 'is_install_referrer_exists',
        parsingType: PARSING_DATA_TYPES.BOOL,
        platformRestricted: [ANDROID],
    },
    {
        label: 'Event Data',
        key: 'event_data',
        parsingType: PARSING_DATA_TYPES.OBJECT,
        isCodeRendering: true,
    },
];

export const SEARCH_CATEGORIES = [
    { label: 'Device', value: 'deviceDisplayName' },
    { label: 'Date', value: 'date' },
    { label: 'Event', value: 'name' },
    { label: 'App', value: 'appName' },
    { label: 'Bundle ID', value: 'bundleId' },
];

export const DEVICE_SELECTION_LIMIT = 15;

export const MIXPANEL_EVENT_PREFIX = 'Testing Console';
export const MIXPANEL_EVENT_ADDED_DEVICE = 'Added Device';
export const MIXPANEL_EVENT_DELETED_DEVICE = 'Deleted Device';
export const MIXPANEL_EVENT_FILTERED_DEVICE = 'Filtered Device';
export const MIXPANEL_EVENT_DATA_LOADED = 'Data Loaded';

export const DATA_TEST_ID_TESTING_CONSOLE = 'testing-console';
export const DATA_TEST_ID_LOGS_WRAPPER = 'logs-wrapper';
export const DATA_TEST_ID_NO_DATA_MSG = 'no-data-msg';
export const DATA_TEST_ID_TABS = 'testing-console-tabs';
export const DATA_TEST_ID_DEVICES = 'testing-console-devices-selection';

export const TIMEOUT_SHOW_FAQ = 1000 * 10;

const EVENT_WAS_FIRED = 'EVENT_WAS_FIRED';
const DEVICE_ID_ADDED = 'DEVICE_ID_ADDED';
const DATA_IS_STILL_MISSING = 'DATA_IS_STILL_MISSING';
const TESTING_IOS = 'TESTING_IOS';
const NO_ATT_PROMPT = 'NO_ATT_PROMPT';

const FAQ_ITEMS_KEYS = [EVENT_WAS_FIRED, DEVICE_ID_ADDED, DATA_IS_STILL_MISSING, TESTING_IOS, NO_ATT_PROMPT];
export const FAQ_ITEMS = FAQ_ITEMS_KEYS.map(key => ({
    title: `STATIC.PAGES.TESTING_CONSOLE.EMPTY_STATE.FAQ.${key}.TITLE`,
    content: `STATIC.PAGES.TESTING_CONSOLE.EMPTY_STATE.FAQ.${key}.CONTENT`,
}));
