let instance = null;

class GridService {
    constructor(id, gridApi, columnApi) {
        if (!instance) {
            instance = this;
            instance.gridMap = {};
        }
        if (id && gridApi && columnApi) {
            instance.gridMap[id] = {
                gridApi,
                columnApi,
            };
        }
        return instance;
    }

    gridApi(id) {
        return this.gridMap[id] ? this.gridMap[id].gridApi : null;
    }

    columnApi(id) {
        return this.gridMap[id] ? this.gridMap[id].columnApi : null;
    }

    destroy(id) {
        if (this.gridMap[id]) {
            this.gridMap[id].gridApi.destroy();
            delete this.gridMap[id];
        }
    }
}

export default GridService;
