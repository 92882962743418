import { schema } from 'normalizr';

export const SERVER_DEFAULT_ICON_URL =
    'https://cdn.singular.net/dashboard/icon/1dbacc8dc07551adf8fffa21d4dee93a79f37105';

const formatAppsResponse = (response, filterIntegrated = true) => {
    const rawApps = response.apps;
    const result = {
        apps: rawApps
            .map(a => {
                a.sites = filterIntegrated ? a.sites.filter(s => s.is_integrated) : a.sites;
                return a;
            })
            .filter(d => d.sites.length),
    };

    result.apps.forEach(app => {
        // list all unique platform names
        app.platforms = [...new Set(app.sites.map(s => s.platform))].map(platform => ({ name: platform }));

        // list all destinations, grouped by platform
        app.download_destinations = {};
        app.deeplink_destinations = {};

        app.platforms.forEach(platform => {
            app.download_destinations[platform.name] = [];
            app.deeplink_destinations[platform.name] = [];
        });

        app.sites.forEach(site => {
            const { platform } = site;
            site.fullName = `${app.name} (${site.site_public_id}) ${platform}`;

            site.download_destinations = [];
            site.deeplink_destinations = [];
            site.universal_ios_destinations = [];

            site.destinations.forEach(destination => {
                // augment the destinations with their site_id's for convenience
                destination.site_id = site.site_id;
                destination.expanded_destination = `${site.site_public_id} - ${destination.destination_url}`;

                if (destination.is_deeplink) {
                    app.deeplink_destinations[platform].push(destination);

                    // group destinations under site object as well
                    site.deeplink_destinations.push(destination);
                } else if (destination.is_ios_universal_link) {
                    // group destinations under site object as well
                    site.universal_ios_destinations.push(destination);
                } else {
                    app.download_destinations[platform].push(destination);

                    // group destinations under site object as well
                    site.download_destinations.push(destination);
                }
            });
        });
    });

    return result;
};

const getAppIcon = app => {
    const sitesWithNonDefaultIcon = app.sites.filter(
        site => site?.site_icon && site.site_icon !== SERVER_DEFAULT_ICON_URL
    );
    return sitesWithNonDefaultIcon && sitesWithNonDefaultIcon.length > 0 ? sitesWithNonDefaultIcon[0].site_icon : '';
};

// Define a site schema
const attributionSiteSchema = new schema.Entity('sites', {}, { idAttribute: 'site_id' });

// Define your article
const attributionApp = new schema.Entity(
    'apps',
    {
        sites: [attributionSiteSchema],
    },
    {
        idAttribute: 'app_id',
    }
);
const attributionAppsSchema = new schema.Array(attributionApp);

const site = new schema.Entity('sites', {}, { idAttribute: 'id' });
const app = new schema.Entity(
    'apps',
    {
        sites: [site],
    },
    {
        idAttribute: 'id',
    }
);
const appsSchema = new schema.Array(app);

export { formatAppsResponse, attributionAppsSchema, attributionSiteSchema, appsSchema, getAppIcon };
