import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import debounce from 'lodash/debounce';
import css from 'teamManagement/components/UserRegistrationForm.css';
import { WizardFooter } from 'components/widgets';
import WizardWarningMessage from 'teamManagement/components/WizardWarningMessage';
import Wizardable from 'components/widgets/Wizardable';
import { wizardModes } from 'teamManagement/utils';
import TeamManagementAPI from 'teamManagement/service';
import { RECAPTCHA_PUBLIC_KEY } from '../utils';

class FormElement extends React.Component {
    constructor(props) {
        super(props);
        this.onInputChanged = this._onInputChanged.bind(this);
        this.onBlur = this._onBlur.bind(this);
        this.inputRef = React.createRef();
    }

    _onInputChanged() {
        const { onInputChanged, fieldId } = this.props;
        onInputChanged(fieldId, this.inputRef.current.value);
    }

    _onBlur() {
        const { onBlur, fieldId } = this.props;
        onBlur(fieldId, this.inputRef.current.value);
    }

    render() {
        const {
            label,
            placeholder,
            className,
            optionalText,
            value,
            valid,
            dirty,
            disabled,
            errorMessage,
            maxLength,
        } = this.props;
        return (
            <div className={`${css.formElementContainer} ${className} ${!valid && dirty ? css.invalid : ''}`}>
                <div className={css.formElementLabel}>
                    {label} {optionalText ? `(${optionalText})` : ''}
                </div>
                <input
                    disabled={disabled}
                    value={value}
                    ref={this.inputRef}
                    type="text"
                    placeholder={placeholder}
                    className={css.formElementInput}
                    onChange={this.onInputChanged}
                    onBlur={this.onBlur}
                    maxLength={maxLength}
                />
                {dirty && !valid && errorMessage ? (
                    <div className={css.formElementErrorMessage}>{errorMessage}</div>
                ) : null}
            </div>
        );
    }
}

FormElement.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    optionalText: PropTypes.string,
    fieldId: PropTypes.string,
    onInputChanged: PropTypes.func,
    onBlur: PropTypes.func,
    dirty: PropTypes.bool,
    disabled: PropTypes.bool,
    valid: PropTypes.bool,
    value: PropTypes.string,
    errorMessage: PropTypes.string,
    maxLength: PropTypes.number,
};

FormElement.defaultProps = {
    label: '',
    placeholder: '',
    className: '',
    optionalText: '',
    fieldId: '',
    onInputChanged: () => {},
    onBlur: () => {},
    dirty: false,
    disabled: false,
    valid: true,
    value: '',
    errorMessage: '',
    maxLength: 100,
};

class UserRegistrationForm extends Wizardable {
    constructor(props) {
        super(props);
        this.state = {
            showExternalUserWarning: false,
            showExistingEmailWarning: false,
            errorMsg: '',
            firstName: {
                value: '',
                fieldId: 'firstName',
                label: 'First Name',
                optionalText: 'optional',
                className: css.nameItem,
                valid: true,
                maxLength: 20,
                ...props.userRegistrationForm.firstName,
            },
            lastName: {
                value: '',
                fieldId: 'lastName',
                label: 'Last Name',
                optionalText: 'optional',
                className: css.nameItem,
                valid: true,
                maxLength: 20,
                ...props.userRegistrationForm.lastName,
            },
            email: {
                value: '',
                fieldId: 'email',
                label: 'Email',
                className: css.formRow,
                valid: false,
                validations: [
                    (fieldId, value, forceDirty) => {
                        const valid = this._isValidEmail(value);
                        this.setState({
                            [fieldId]: {
                                ...this.state[fieldId],
                                valid,
                                dirty: this.state[fieldId].dirty || (!!value && valid) || forceDirty,
                                errorMessage: valid ? '' : value ? 'Invalid Email' : 'This field is mandatory',
                            },
                            showExternalUserWarning: valid && this._isEmailHasDifferentDomain(value),
                        });
                        return valid;
                    },
                    (fieldId, value, forceDirty) => {
                        if (props.mode !== wizardModes.edit) {
                            this._assertEmailExists(value, fieldId, forceDirty, this.state);
                        }
                        return true;
                    },
                ],
                ...props.userRegistrationForm.email,
            },
            description: {
                value: '',
                fieldId: 'description',
                label: 'Description',
                optionalText: 'optional, will appear next to the user’s name',
                placeholder: 'For example: Level 3 Access',
                className: css.formRow,
                valid: true,
                maxLength: 30,
                ...props.userRegistrationForm.description,
            },
        };

        this.onFieldChanged = this._onFieldChanged.bind(this);
        this.onFieldBlur = this._onFieldBlur.bind(this);
        this.getButtons = this._getButtons.bind(this);
        this.onFieldsUpdated = debounce(
            () => {
                this.props.onFieldsUpdated(this._getFieldsObject());
            },
            500,
            { trailing: true }
        );
        this.runValidations = debounce(
            (...args) => {
                this._runValidations(...args);
            },
            500,
            { trailing: true }
        );

        // override the default button def for finish button
        // change the showSpinner value after user click, force render and call the default onFinish
        this.buttonDefs.finish.text = props.finishButtonText;
        const defaultOnFinish = this.buttonDefs.finish.onClick;
        this.buttonDefs.finish.onClick = async () => {
            this.buttonDefs.finish.showSpinner = true;
            const token = await this.recaptchaRef.current.executeAsync();
            this.setState({});
            defaultOnFinish(token);
        };
        this.buttonOverrides = props.wizardOverrideButtons;
        this.timeouts = [];

        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        Object.keys(this.state).forEach((fieldId) => {
            if (this.state[fieldId].validations) {
                this.runValidations(fieldId, this.state[fieldId].value);
            }
        });
    }

    componentWillUnmount() {
        // clearing all timed out functions when component gets unmounted
        this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
        });
        this.runValidations.cancel();
        this.onFieldsUpdated.cancel();
    }

    _isValidEmail(email) {
        return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email);
    }

    _assertEmailExists(value, fieldId, forceDirty, prevState) {
        new TeamManagementAPI().checkUserExists(value).then((response) => {
            this.setState({
                [fieldId]: {
                    ...prevState[fieldId],
                    value,
                    dirty: prevState[fieldId].dirty || (!!value && response.exists) || forceDirty,
                },
                showExistingEmailWarning: response.exists,
                errorMsg: response.msg,
            });
        });
    }

    _isEmailHasDifferentDomain(email) {
        const { username } = this.props;
        const domainStartIndex = username.indexOf('@');
        const domainRegex = new RegExp(`^[A-Za-z0-9._%+-]+${username.substring(domainStartIndex)}$`);

        return !domainRegex.test(email);
    }

    _onFieldChanged(fieldId, value) {
        this.setState({
            [fieldId]: {
                ...this.state[fieldId],
                value,
            },
        });
        const fieldItem = this.state[fieldId];
        if (fieldItem.validations && fieldItem.validations.length) {
            this.timeouts.push(
                setTimeout(() => {
                    this.runValidations(fieldId, value);
                })
            );
        }
        this.onFieldsUpdated();
    }

    _getFieldsObject() {
        const fields = ['firstName', 'lastName', 'email', 'description'];
        return Object.keys(this.state)
            .filter((key) => fields.includes(key))
            .reduce((total, key) => {
                total[key] = {
                    value: this.state[key].value,
                    dirty: this.state[key].dirty,
                    valid: this.state[key].valid,
                };
                return total;
            }, {});
    }

    _onFieldBlur(fieldId, value) {
        this.runValidations(fieldId, value, true);
    }

    _runValidations(fieldId, value, forceDirty = false) {
        const { validations } = this.state[fieldId];
        if (validations) {
            for (const validation of validations) {
                const valid = validation(fieldId, value, forceDirty);
                if (!valid) {
                    break;
                }
            }
        }
    }

    validate() {
        const { translate } = this.props;
        const fields = ['email'];
        const isValid = fields
            .map((fieldId) => {
                const fieldItem = this.state[fieldId];
                return fieldItem.valid;
            })
            .every((i) => i);

        const tooltipButtons = ['next', 'finish'];
        this.setTooltip(tooltipButtons, isValid ? null : translate('STATIC.PAGES.TEAM_MEMBERS.FILL_FIELDS_TOOLTIP'));

        return isValid;
    }

    render() {
        const { firstName, lastName, email, description, reason, showExternalUserWarning , showExistingEmailWarning, errorMsg} = this.state;
        const { saveMemberError, mode, lastAction } = this.props;
        if (saveMemberError) {
            this.buttonDefs.finish.showSpinner = false;
        }
        return (
            <React.Fragment>
                <WizardWarningMessage
                    show={showExternalUserWarning && !saveMemberError}
                    message="STATIC.PAGES.TEAM_MEMBERS.EXTERNAL_USER_WARNING"
                    delay={300}
                />
                <WizardWarningMessage
                    show={showExistingEmailWarning}
                    message={errorMsg}
                    delay={300}
                    type="error"
                />
                <WizardWarningMessage
                    show={!!saveMemberError}
                    message={saveMemberError}
                    showIcon={false}
                    showTypeIcon
                    type="error"
                    delay={300}
                />
                <div className={css.container}>
                    <this.AnimationItem initialPose={lastAction} pose="visible">
                        <FormElement
                            disabled={mode === wizardModes.edit}
                            onInputChanged={this.onFieldChanged}
                            onBlur={this.onFieldBlur}
                            {...email}
                        />
                        <div className={`${css.namesContainer} ${css.formRow}`}>
                            <FormElement
                                onInputChanged={this.onFieldChanged}
                                disabled={mode === wizardModes.edit}
                                {...firstName}
                            />
                            <FormElement
                                disabled={mode === wizardModes.edit}
                                onInputChanged={this.onFieldChanged}
                                {...lastName}
                            />
                        </div>
                        <FormElement
                            onInputChanged={this.onFieldChanged}
                            {...description}
                        />
                    </this.AnimationItem>
                    <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA_PUBLIC_KEY} />
                    <WizardFooter buttons={this.getButtons()} />
                </div>
            </React.Fragment>
        );
    }
}

UserRegistrationForm.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.element),
    username: PropTypes.string,
    mode: PropTypes.string,
};

UserRegistrationForm.defaultProps = {
    buttons: [],
    username: '',
    mode: '',
};

export default UserRegistrationForm;
