import React from 'react';
import PropTypes from 'prop-types';
import SingularButton from './SingularButton';
import Tooltip from './Tooltip';

function RunReportButton({ disableReportState, style, ...restProps }) {
    const actionButton = (
        <SingularButton
            type="primary"
            level="level1"
            text="STATIC.BUTTONS.RUN"
            style={{
                marginRight: '5px',
                verticalAlign: 'bottom',
                ...style,
            }}
            {...restProps}
        />
    );

    if (disableReportState) {
        return (
            <Tooltip
                titleTranslationKey={`STATIC.PAGES.REPORTS.DISABLE_RUN_TOOLTIP_${disableReportState}`}
                style={{ display: 'inline-flex' }}
                interactive
            >
                {actionButton}
            </Tooltip>
        );
    } else {
        return actionButton;
    }
}

RunReportButton.propTypes = {
    disableReportState: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
};

RunReportButton.defaultProps = {
    disableReportState: undefined,
    style: {},
};

export default RunReportButton;
