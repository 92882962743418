import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './SortingPanel.css';
import { capitalize } from '../../utils/strings';
import Chevron from '../../resources/icons/chevron.svg';
import { BannerSortedField } from '../consts';

function SortingPanel({ onSortingChanged }) {
    const [sortOptions, setSortOptions] = useState({ field: 'priority', isDescending: false });
    const { field, isDescending } = sortOptions;
    const panelItems = Object.values(BannerSortedField);

    const onUpdateSortOptions = clickedField => {
        const newSortOptions = {
            field: clickedField,
            isDescending: clickedField === sortOptions.field && !sortOptions.isDescending,
        };

        setSortOptions(newSortOptions);
        onSortingChanged(newSortOptions.field, newSortOptions.isDescending);
    };

    return (
        <div className={classNames(css.sortingPanel)}>
            {panelItems.map(item => (
                <div
                    key={item}
                    className={classNames(css[item], css.sortingField, { [css.active]: field === item })}
                    onClick={() => onUpdateSortOptions(item)}
                >
                    {capitalize(item)}
                    <Chevron className={classNames(css.arrow, { [css.arrowDesc]: isDescending && field === item })} />
                </div>
            ))}
        </div>
    );
}

SortingPanel.propTypes = {
    onSortingChanged: PropTypes.func,
};

SortingPanel.defaultProps = {
    onSortingChanged: () => {},
};

export default SortingPanel;
