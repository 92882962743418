import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik-2';
import { Translate, withLocalize } from 'react-localize-redux';
import { useQuery } from '@apollo/client';
import { sortAutoCompleteOptions } from '../../../utils/components/formUtils';
import css from './AppAutocomplete.css';
import { partnerShape, siteShape, templateShape } from '../types';
import { AutoCompleteField } from '../../../components/widgets';
import { GET_PARTNER_CONFIGURATIONS_FOR_PARTNER } from '../../queries';

const DEFAULT_APP_ICON = 'https://cdn.singular.net/dashboard/icon/1dbacc8dc07551adf8fffa21d4dee93a79f37105';

function AppAutocomplete({ label, disabled, partner, sites, postbackTemplates, translate, onChange }) {
    const { values, initialValues, touched, errors } = useFormikContext();
    const partnerConfigurationsResponse = useQuery(GET_PARTNER_CONFIGURATIONS_FOR_PARTNER, {
        variables: { partnerId: partner.id },
    });

    const platformsWithTemplateSet = new Set(postbackTemplates.map(template => template.platform));
    const { partnerConfigurations = [] } = partnerConfigurationsResponse.data || {};
    const configuredSites = partnerConfigurations.map(config => config.siteId);

    const getSiteOptionExtra = site => {
        if (configuredSites.includes(site.site_id)) {
            return translate('STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.CONFIGURATION_ALREADY_EXISTS');
        } else if (!platformsWithTemplateSet.has(site.platform)) {
            return translate('STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.PLATFORM_NOT_SUPPORTED');
        } else {
            return null;
        }
    };

    const sitesOptions = sites
        .map(site => ({
            value: site.site_id,
            label: site.fullName,
            icon: <img className={css.siteOptionIcon} src={site.site_icon || DEFAULT_APP_ICON} />,
            className: platformsWithTemplateSet.has(site.platform) ? '' : css.siteOptionDisabled,
            extra: getSiteOptionExtra(site),
        }))
        .sort(sortAutoCompleteOptions);

    const selectedSite = values.site || initialValues.site;
    const selectedSiteOption = sitesOptions.find(({ value }) => value === selectedSite);

    if (!selectedSiteOption && disabled) {
        return (
            <div className={css.siteNotFound}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.APP_NOT_FOUND" />
            </div>
        );
    }

    return (
        <AutoCompleteField
            label={label}
            disabled={disabled}
            searchable
            isMulti={false}
            options={sitesOptions}
            value={selectedSiteOption}
            placeholder="STATIC.PLACEHOLDERS.SELECT_APP"
            containerClass={css.siteField}
            innerContainerClass={css.sitesAutocomplete}
            error={touched.site && errors.site}
            onChange={onChange}
            selectOptions={{ usePortal: true }}
        />
    );
}

AppAutocomplete.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    partner: partnerShape.isRequired,
    sites: PropTypes.arrayOf(siteShape),
    postbackTemplates: PropTypes.arrayOf(templateShape),
    translate: PropTypes.func,
    onChange: PropTypes.func,
};

AppAutocomplete.defaultProps = {
    label: '',
    disabled: false,
    sites: [],
    postbackTemplates: [],
    translate: () => {},
    onChange: () => {},
};

export default withLocalize(AppAutocomplete);
