import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './AddButton.css';

class AddButton extends React.Component {
    constructor(props) {
        super(props);
        this._handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick();
    }

    render() {
        const { label, containerStyle, disabled, containerClass } = this.props;
        return (
            <button
                className={`${css.container} ${containerClass}`}
                onClick={this._handleClick}
                style={containerStyle}
                disabled={disabled}
            >
                <Translate id={label} />
            </button>
        );
    }
}

AddButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
};

AddButton.defaultProps = {
    label: '',
    onClick: () => {},
    disabled: false,
    containerStyle: {},
    containerClass: '',
};

export default AddButton;
