import moment from 'moment-timezone';
import {RECAPTCHA_SIGNUP_SITE_KEY, RECAPTCHA_TEST_PUBLIC_KEY} from "./consts";

// First Page Validations

export const FULL_NAME_FIELD = 'fullName';
export const EMAIL_FIELD = 'email';
export const PASSWORD_FIELD = 'password';

// Second Page Validations
export const CURRENCY = 'currency';
export const TIMEZONE = 'timezone';
export const COUNTRY = 'country';
export const USER_ROLE = 'userRole';
export const COMPANY_NAME = 'companyName';
export const HAS_MOBILE_APP = 'hasMobileApp';
export const COMPANY_STAGE = 'companyStage';
export const IS_AGENCY = 'isAgency';
export const RECAPTCHA_TOKEN = 'recaptchaToken';

export const COVID_UTM_CAMPAIGN = 'covid19';

// Response Status
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';

export const objectives = [
    'STATIC.PAGES.SIGNUP.SIGNUP_COPY_OBJECTIVE_1',
    'STATIC.PAGES.SIGNUP.SIGNUP_COPY_OBJECTIVE_2',
    'STATIC.PAGES.SIGNUP.SIGNUP_COPY_OBJECTIVE_3',
    'STATIC.PAGES.SIGNUP.SIGNUP_COPY_OBJECTIVE_4',
    'STATIC.PAGES.SIGNUP.SIGNUP_COPY_OBJECTIVE_5',
    'STATIC.PAGES.SIGNUP.SIGNUP_COPY_OBJECTIVE_6',
];

export const validateFullName = fullName => {
    let errorMessage = '';
    const firstName = fullName.substring(0, fullName.indexOf(' '));
    const lastName = fullName.substring(fullName.indexOf(' ') + 1, fullName.length);

    if (!(firstName.length && firstName.length <= 30 && lastName.length && lastName.length <= 30)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_FULL_NAME';
    } else if (!new RegExp('^([a-zA-Z-]+)\\s+([a-zA-Z-\\s]+){1,}$').test(fullName)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_FULL_NAME_NON_ENGLISH';
    }

    return errorMessage;
};

export const validateEmail = (email, allBlackListEmails) => {
    let errorMessage = '';
    const emailRegex = new RegExp(
        '^([a-zA-Z0-9_.+\\-]+)@((([a-zA-Z0-9][a-zA-Z0-9\\-]+)?[a-zA-Z0-9]+\\.)+[a-zA-Z]{2,25})$'
    );
    const emailDomain = email.split('@')[1];
    if (!emailRegex.test(email)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_EMAIL_FORMAT';
    } else if (allBlackListEmails && allBlackListEmails.includes(emailDomain)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.ERROR_EMAIL_IN_BLACK_LIST';
    }

    return errorMessage;
};

export const validatePassword = (password = '') => {
    let errorMessage = '';

    if (password.length < 6 || password.length > 60) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_PASSWORD_LENGTH';
    } else if (!RegExp('[a-z]').test(password)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_PASSWORD_LOWER_CASE';
    } else if (!RegExp('[A-Z]').test(password)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_PASSWORD_UPPER_CASE';
    } else if (!RegExp('[0-9]').test(password)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_PASSWORD_NUMBER';
    } else if (!RegExp('[!@#$%^?~<>\\]\\[&*(){}/]').test(password)) {
        errorMessage = 'STATIC.PAGES.SIGNUP.INVALID_FIELD_PASSWORD_SPECIAL_CHAR';
    }

    return errorMessage;
};

// First Page Validations
export const getFormValidations = (fullName, email, password, allBlackListEmails) => {
    return {
        [FULL_NAME_FIELD]: validateFullName(fullName),
        [EMAIL_FIELD]: validateEmail(email, allBlackListEmails),
        [PASSWORD_FIELD]: validatePassword(password),
    };
};

//   Second Page Validations
export const getSecondFormValidations = (
    currency,
    timezone,
    companyName,
    userRole,
    hasMobileApp,
    companyStage,
    isAgency,
    country,
    reCaptchaToken,
    signupWithGoogle
) => {
    return {
        [CURRENCY]: currency ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_CURRENCY',
        [TIMEZONE]: timezone ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_TIMEZONE',
        [COMPANY_NAME]: companyName ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_COMPANY_NAME',
        [USER_ROLE]: userRole ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_USER_ROLE',
        [HAS_MOBILE_APP]: hasMobileApp ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_IS_APP',
        [COMPANY_STAGE]: companyStage ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_COMPANY_STAGE',
        [IS_AGENCY]: isAgency ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_IS_AGENCY',
        [COUNTRY]: country ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_COUNTRY',
        [RECAPTCHA_TOKEN]:
            reCaptchaToken || signupWithGoogle ? '' : 'STATIC.PAGES.SIGNUP.INVALID_FIELD_RECAPTCHA_TOKEN',
    };
};

export const isValidForm = validations => {
    return Object.keys(validations).reduce((accum, key) => {
        return !validations[key] && accum;
    }, true);
};

export const getInvalidFields = validations => {
    return Object.keys(validations)
        .filter(key => {
            return validations[key];
        })
        .map(key => {
            return key;
        });
};

export const movePopularCurrenciesUpFront = (fullList, upFrontElementList) => {
    fullList.sort((x, y) => {
        if (upFrontElementList.includes(x.name)) {
            return -1;
        } else if (upFrontElementList.includes(y.name)) {
            return 1;
        } else {
            return 0;
        }
    });
};

export const ERROR_CODES = {
    EMAIL_ERROR: 'ERROR_EMAIL_IN_BLACK_LIST',
    TRIAL_EXPIRED_ERROR: 'ERROR_TRIAL_HAS_ENDED',
    USER_EXIST_ERROR: 'ERROR_EMAIL_ALREADY_IN_USE',
    ERROR_EMAIL_REJECTED: 'ERROR_EMAIL_REJECTED',
    ERROR_ORG_EXISTS: 'ERROR_ORG_EXISTS',
    ERROR_EMAIL_DOMAIN_ALREADY_IN_USE: 'ERROR_EMAIL_DOMAIN_ALREADY_IN_USE',
};

export const getUtmParams = () => {
    const { utmSource = '', utmCampaign = '', utmMedium = '', utmAdgroup = '', utmContent = '', utmTerm = '' } = window;
    return {
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_adgroup: utmAdgroup,
        utm_content: utmContent,
        utm_term: utmTerm,
    };
};

export const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isTimezoneMatchingCountry = (timezone, countryCode) => {
    try {
        const countryTimeZones = moment.tz.zonesForCountry(countryCode);
        return countryTimeZones.includes(timezone);
    } catch (error) {
        return false;
    }
};

export const getRecaptchaSignupSiteKey = () => {
    const element = document.getElementById('recaptcha-signup-site-key');
    if (element) {
        try {
            return JSON.parse(element.textContent);
        } catch (error) {
            console.error('Failed to parse django context:', error);
        }
    }
    return RECAPTCHA_SIGNUP_SITE_KEY;
};
