import update from 'immutability-helper';
import {
    AUDIT_GENERAL_ID,
    AUDIT_SDK_ID,
    AUDIT_SDK_MESSAGE,
    GENERAL_AUDIT_LOADING,
    RESET_VALIDATION_MESSAGE,
    SDK_AUDIT_LOADING,
    SET_HOMEPAGE_COMPLETED_TASK,
    SET_HOMEPAGE_DATA,
    SET_HOMEPAGE_ERROR,
} from './actions';

const initialState = {
    isfirstLoad: true,
    uans: [],
    isLoading: false,
    error: false,
    completedTasks: {},
    lockedTasks: {},
    apsalarEnabled: null,
    sdkKey: null,
    apiKey: null,
    isEtlEnabled: null,
    sdkConfigAnswersPlatforms: [],
};

export default function homepageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_HOMEPAGE_COMPLETED_TASK:
            return update(state, {
                completedTasks: {
                    [action.completedTaskName]: { $set: true },
                },
            });
        case SET_HOMEPAGE_DATA: {
            return update(state, {
                isfirstLoad: {
                    $set: false,
                },
                uans: {
                    $set: action.data.uans,
                },
                appCount: {
                    $set: action.data.app_count,
                },
                completedTasks: {
                    $set: action.data.completed_tasks,
                },
                lockedTasks: {
                    $set: action.data.locked_tasks,
                },
                apsalarEnabled: {
                    $set: action.data.apsalar_enabled,
                },
                sdkKey: {
                    $set: action.data.sdk_key,
                },
                sdkToken: {
                    $set: action.data.sdk_token,
                },
                isEtlEnabled: {
                    $set: action.data.is_etl_feature_enabled,
                },
                isAdMonEnabled: {
                    $set: action.data.is_admon_enabled,
                },
                sdkConfigAnswers: {
                    $set: action.data.sdk_config_answers,
                },
                sdkConfigAnswersPlatforms: {
                    $set: action.data.sdk_config_answers_platforms,
                },
            });
        }
        case SET_HOMEPAGE_ERROR: {
            return update(state, {
                error: {
                    $set: true,
                },
            });
        }
        case AUDIT_SDK_ID: {
            return update(state, {
                validationId: { $set: action.validationId },
                validationType: { $set: 'sdk' },
            });
        }
        case AUDIT_GENERAL_ID: {
            return update(state, {
                validationId: { $set: action.validationId },
                validationType: { $set: 'general' },
            });
        }
        case AUDIT_SDK_MESSAGE: {
            return update(state, {
                validationMessage: { $set: action.message },
            });
        }
        case RESET_VALIDATION_MESSAGE: {
            return update(state, {
                validationMessage: { $set: '' },
            });
        }
        case SDK_AUDIT_LOADING: {
            return update(state, {
                isSdkAuditLoading: { $set: action.isLoading },
            });
        }
        case GENERAL_AUDIT_LOADING: {
            return update(state, {
                isGeneralAuditLoading: { $set: action.isLoading },
            });
        }
        default:
            return state;
    }
}
