import Reports from './reports';
import API from './api';

const api = new API();

const URL_REPORTING_NEW_DATA = '/api/get_new_data';

let instance = null;

const defaultQueryParams = {
    is_dashboard: false,
    display_unenriched: false,
    is_default: true,
    chart: false,
    is_goals: false,
    start_date: '',
    end_date: '',
    start_date_2: '',
    end_date_2: '',
    compare: false,
    dimensions: [],
    metrics: [],
    goals_metrics: [],
    goals_forecast_metrics: [],
    goal: {},
    discrepancy_metrics: [],
    cohort_metrics: [],
    cohort_periods: [],
    filters: [],
    time_breakdown: [],
    permutation_keys: [],
    valid_key_list_for_charts: [],
    valid_key_name_list_for_charts: [],
};

class AdMonetization {
    constructor() {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    runQuery(params, options) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_REPORTING_NEW_DATA, {
                method: 'POST',
                credentials: 'include',
                body: {
                    ...defaultQueryParams,
                    ...params,
                },
                cache: false,
                unique: true,
                ...options,
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject(Reports.ERROR_RUN_QUERY);
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default AdMonetization;
