import { reportTypes } from '../../utils/reports';
import {
    getBaseConfig,
    getEmptyStateConfig,
    shelfCohortMetrics,
    shelfConfidenceIntervalFlag,
    shelfDateFieldName,
    shelfDimensions,
    shelfMetrics,
    shelfSkanCustomEvents,
    shelfSkanDiscrepancyMetrics,
    shelfSkanModeledCohortMetrics,
    shelfSkanModeledCustomEvents,
    shelfSkanModeledMetrics,
    shelfSkanRedownloadsDimensions,
    shelfSkanValidatedDimensions,
    shelfTimeBreakdowns,
    shelfWithDruidAppend,
} from './baseReportConfig';
import { shelfSkanPeriods, shelfSkanModeledCohortPeriods } from './shelfPeriods';

const type = reportTypes.skanSummary;
const path = '/react/skadnetwork_summary_report';
const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.SKADNETWORK_SUMMARY');

const shelf = [
    shelfDateFieldName,
    shelfDimensions,
    shelfCohortMetrics,
    shelfMetrics,
    shelfSkanDiscrepancyMetrics,
    shelfSkanCustomEvents,
    shelfSkanRedownloadsDimensions,
    shelfSkanValidatedDimensions,
    shelfTimeBreakdowns,
    shelfWithDruidAppend,
];

const SHELF_MODELED_SECTIONS = [
    shelfSkanModeledMetrics,
    shelfSkanModeledCohortMetrics,
    shelfSkanModeledCohortPeriods,
    shelfSkanModeledCustomEvents,
    shelfSkanPeriods,
    shelfConfidenceIntervalFlag,
];

if (window.is_unified === 'True') {
    shelf.splice.apply(
        shelf,
        [shelf.findIndex(x => x === shelfSkanRedownloadsDimensions), 0].concat(SHELF_MODELED_SECTIONS)
    );
}
export default {
    ...basicConfig,
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/4408232000795',
            hideVideo: true,
        },
        emptyStateHeaderData: getEmptyStateConfig('SKAN_SUMMARY'),
    },
    fieldWarnings: {},
    shelf,
    path,
    chart: {
        enabled: false,
        show: false,
    },
};
