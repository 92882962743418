import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Dropdown from '../widgets/Dropdown';
import { generateArrayOfSize } from '../../global/utils';
import { TOP_X_COUNT_MAX_VALUE } from './PivotChart/consts';
import css from './TopXChartDataFilter.css';
import { trackMixpanelEvent } from '../../utils/general';
import { CAMPAIGN_REPORT_MIXPANEL_PREFIX } from '../../quickReports/consts';

const TopxChartDataFilter = ({ topXCount, rowDataLength, onChange, widgetName }) => (
    <div className={css.topXContainer}>
        <span className={css.topXLabel}>
            <Translate id="STATIC.SHOW_CHART_TOP_ROWS3" />
        </span>
        <Dropdown
            containerClass={css.topXDropdown}
            popperClass={css.topXDropdown}
            selected={{ name: topXCount, display_name: topXCount }}
            onSelection={s => {
                onChange(s.name);
                trackMixpanelEvent(CAMPAIGN_REPORT_MIXPANEL_PREFIX, 'Selected top X value', {
                    selected_value: s.name,
                    widget_name: widgetName,
                });
            }}
            items={generateArrayOfSize(Math.min(TOP_X_COUNT_MAX_VALUE, rowDataLength)).map(i => {
                    return { name: i + 1, display_name: i + 1 };
            })}
        />
    </div>
);

TopxChartDataFilter.propTypes = {
    topXCount: PropTypes.number.isRequired,
    rowDataLength: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    widgetName: PropTypes.string.isRequired,
};

export default TopxChartDataFilter;
