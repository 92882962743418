import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik-2';
import { Translate } from 'react-localize-redux';
import css from './BannerShelf.css';
import WizardFooter from '../../../components/widgets/WizardFooter';
import { Shelf, SingularButton, Tabs } from '../../../components/widgets';
import WarningMessage, { MessageTypes } from '../../../teamManagement/components/WizardWarningMessage';
import { closeShelfClicked, saveShelfClicked } from '../../actions';
import { getSavingConfiguration, getSavingError, getShelf } from '../../selectors';
import { convertToServerBanner } from '../../utils';
import BannerForm from './BannerForm';
import { PageToSections, VALIDATION_SCHEMA } from './shelfSections';
import tabsCss from '../../../components/widgets/Tabs.css';

export default function BannerShelf() {
    const dispatch = useDispatch();
    const shelf = useSelector(state => getShelf(state));
    const savingError = useSelector(state => getSavingError(state));
    const isSavingConfiguration = useSelector(state => getSavingConfiguration(state));

    const [isFormDirty, setFormDirty] = useState(false);
    const [activePage, setActivePage] = useState(Object.keys(PageToSections)[0]);

    useEffect(() => {
        if (shelf.open) return;

        const pages = Object.keys(PageToSections);
        const page = pages.length ? pages[0] : null;
        setActivePage(page);
    }, [shelf]);

    const _onClose = () => dispatch(closeShelfClicked(isFormDirty));

    const _onSave = async values => {
        const configuredBanner = convertToServerBanner(values);
        dispatch(saveShelfClicked(configuredBanner));
    };

    const { initialValues, ...shelfProps } = shelf;

    return (
        <Shelf
            {...shelfProps}
            shelfSize="xlarge"
            headerText="STATIC.PAGES.BANNERS.SHELF_HEADER"
            onClose={_onClose}
            enterAnimationDisabled={false}
            activePage={activePage}
        >
            <WarningMessage
                type={MessageTypes.ERROR}
                show={!!savingError}
                duration={1000}
                message="STATIC.PAGES.BANNERS.SAVING_FAILURE_MESSAGE"
            />
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        actions.setSubmitting(false);
                    }, 1000);
                }}
                validationSchema={VALIDATION_SCHEMA}
            >
                {({ values, setValues, errors, touched, setTouched }) => {
                    const hasErrors = !!Object.keys(errors).length;
                    setTimeout(() => setFormDirty(!!Object.keys(touched).length), 0);

                    return (
                        <div className={css.bannerShelf}>
                            <Tabs
                                containerClass={tabsCss.shelfTabs}
                                tabClass={css.bannerTab}
                                selected={activePage}
                                onClick={setActivePage}
                                tabs={Object.keys(PageToSections).map(page => ({
                                    id: page,
                                    label: `STATIC.PAGES.BANNERS.TAB_LABEL_${page}`,
                                }))}
                            />
                            <BannerForm
                                values={values}
                                initialValues={initialValues}
                                setValues={setValues}
                                errors={errors}
                                touched={touched}
                                setTouched={setTouched}
                                sections={PageToSections[activePage]}
                            />
                            <WizardFooter
                                buttons={[
                                    <SingularButton type="flat" onClick={_onClose}>
                                        <Translate id="STATIC.BUTTONS.CANCEL" />
                                    </SingularButton>,
                                    <SingularButton
                                        disabledDark={hasErrors}
                                        onClick={() => _onSave(values)}
                                        showSpinner={isSavingConfiguration}
                                    >
                                        <Translate id="STATIC.PAGES.BANNERS.SHELF_SAVE_BUTTON" />
                                    </SingularButton>,
                                ]}
                            />
                        </div>
                    );
                }}
            </Formik>
        </Shelf>
    );
}
