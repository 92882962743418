import React from 'react';
import { PageHeader as BasePageHeader } from '../../components/partials';
import { FAQ_LINKS } from '../consts';
import { partnerShape } from './types';
import { PARTNER_CONFIGURATION_EVENT_PREFIX } from '../../utils/general';
import { getAdnetworkDisplayName } from '../../utils/adNetworks';

export default function PageHeader({ partner }) {
    return (
        <BasePageHeader
            subText={FAQ_LINKS}
            imgSrc={partner.icon}
            text={getAdnetworkDisplayName(partner.name, partner.displayName)}
            additionalText="STATIC.PAGE_HEADERS.PARTNER_CONFIGURATION"
            mixpanelEventPrefix={PARTNER_CONFIGURATION_EVENT_PREFIX}
        />
    );
}

PageHeader.propTypes = {
    partner: partnerShape.isRequired,
};
