import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';
import { AppContainer } from 'react-hot-loader';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { trackMixpanelEvent, formatSectionPaths, isPathAllowed, PATHS_NOT_IN_NAVIGATION_MENU } from 'utils/general';
import { MIXPANEL_EVENT_PAGE_LOADED } from 'utils/consts';
import ReportsContainer from 'containers/reports';
import * as Containers from './containers';
import * as Pages from './components/pages';
import reportsConfig from './reducers/reportsConfig';
import Main from './components/Main';
import client from './apollo';
import pageConfigs from './config';
import Spinner from './components/widgets/Spinner';
import browserHistory from './hashHistory';
import theme from './global/muiTheme';
import { useFetchNavigation } from './sidenav/hooks';

const basicRoutes = pageConfigs
    .filter(pageConfig => pageConfig.container || pageConfig.componentName || pageConfig.containerName)
    .map(pageConfig => {
        const component =
            pageConfig.container ||
            (pageConfig.componentName ? Pages[pageConfig.componentName] : Containers[pageConfig.containerName]);
        if (!component) {
            throw new Error(`undefined component: ${pageConfig.componentName || pageConfig.containerName}`);
        }
        return {
            type: pageConfig.type || '',
            path: pageConfig.path,
            mixpanelComponentName: pageConfig.mixpanelComponentName,
            component,
        };
    })
    .concat(
        reportsConfig.map(({ type, path, className }) => {
            return {
                type,
                path,
                component: Containers[className] || ReportsContainer,
            };
        })
    )
    .concat(
        pageConfigs
            .filter(pageConfig => pageConfig.lazy)
            .map(pageConfig => {
                return {
                    type: pageConfig.type || '',
                    path: pageConfig.path,
                    component: pageConfig.lazy,
                };
            })
    );

function SingularRouter({ store }) {
    const location = useLocation();
    const [routes, setRoutes] = useState(basicRoutes);
    const shouldHideSidenav = useMemo(() => {
        const hideSidenavPaths = ['signup', 'anonymous', 'loading_page', 'plan-upgrade'];

        return hideSidenavPaths.some(path => location.pathname.includes(path)) || window.hideSideBar;
    }, [location]);

    const { value: sections } = useFetchNavigation(() => !shouldHideSidenav);

    /**
     * Filter routes based on the sections fetched from the API.
     * Helps to avoid flickering when a user is trying to visit a non allowed page.
     */
    useEffect(() => {
        if (sections) {
            const formattedSections = formatSectionPaths(sections).concat(PATHS_NOT_IN_NAVIGATION_MENU);
            const filteredRoutes = basicRoutes.filter(({ path }) => {
                return isPathAllowed(formattedSections, path);
            });
            setRoutes(filteredRoutes);
        }
    }, [sections]);

    useEffect(() => {
        const history = syncHistoryWithStore(browserHistory, store);
        const path = `${location.pathname}/${location.search}`;
        history.replace(path);

        routes.forEach(({ component, mixpanelComponentName, path: componentPath }) => {
            if (component.locationHandler) {
                browserHistory.listen(component.locationHandler);
            }
            if (mixpanelComponentName) {
                browserHistory.listen(({ pathname }) => {
                    if (pathname === componentPath) {
                        trackMixpanelEvent(mixpanelComponentName, MIXPANEL_EVENT_PAGE_LOADED);
                    }
                });
            }
        });
    }, []);

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <>
                    <AppContainer>
                        <Main sections={sections} shouldHideSidenav={shouldHideSidenav}>
                            <Suspense fallback={<Spinner style={{ marginTop: '300px' }} show />}>
                                <Routes>
                                    {routes.map(({ type, path, component }) => (
                                        <Route
                                            key={`route_${type}`}
                                            path={path}
                                            element={React.createElement(component, { type })}
                                        />
                                    ))}
                                </Routes>
                            </Suspense>
                        </Main>
                    </AppContainer>
                    <ToastContainer />
                </>
            </ThemeProvider>
        </ApolloProvider>
    );
}

SingularRouter.propTypes = {
    store: PropTypes.objectOf(PropTypes.any),
};

SingularRouter.defaultProps = {
    store: {},
};

export default SingularRouter;
