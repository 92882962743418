import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { AutoComplete } from '../../components/widgets';

class AdNetworkSelection extends Component {
    render() {
        const { translate, options, onChange, containerStyle, controlledValue, placeHolder, onInputChange, onBlur, setFocus } = this.props;

        return (
            <AutoComplete
                placeholder={placeHolder}
                selectOptions={{
                    options,
                }}
                onChange={onChange}
                onBlur={onBlur}
                searchable
                containerStyle={{
                    width: '300px',
                    ...containerStyle,
                }}
                onInputChange={onInputChange}
                controlledValue={controlledValue}
                setFocus={setFocus}
            />
        );
    }
}

AdNetworkSelection.propsTypes = {
    translate: PropTypes.func,
    adNetworkOptions: PropTypes.array,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
    onInputChange: PropTypes.func,
    setFocus: PropTypes.bool,
};

AdNetworkSelection.defaultProps = {
    setFocus: false,
    translate: () => {},
    containerStyle: {},
    onInputChange: () => {},
};

export { AdNetworkSelection };
export default withLocalize(AdNetworkSelection);
