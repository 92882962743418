import { connect } from 'react-redux';
import { getAnonymousId, getAnonymousList, getReportDates } from '../../selectors/reports';
import { deleteReport, generateAnonymousLink, generateAnonymousList, closeShelf } from '../../actions/anonymous';
import { SharedReportsShelf } from '../../components/reportsWidgets';

let getAnonymousIdForType;
let getAnonymousListForType;
let getAnonymousReportDatesForType;

const mapStateToProps = (state, ownProps) => {
    getAnonymousIdForType = getAnonymousId(ownProps.reportType);
    getAnonymousListForType = getAnonymousList(ownProps.reportType);
    getAnonymousReportDatesForType = getReportDates(ownProps.reportType);

    return {
        sharedReportId: getAnonymousIdForType(state),
        sharedReportList: getAnonymousListForType(state),
        reportDates: getAnonymousReportDatesForType(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onGenerateLinkClick: (name, password, expirationDays, flexibleDates) => {
            dispatch(generateAnonymousLink(name, password, expirationDays, flexibleDates, ownProps.reportType));
        },
        onGenerateList: () => {
            dispatch(generateAnonymousList(ownProps.reportType));
        },
        onDeleteReport: secretId => {
            dispatch(deleteReport(secretId, ownProps.reportType));
        },
        onCloseShelf: () => {
            dispatch(closeShelf(ownProps.reportType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedReportsShelf);
