import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from 'react-localize-redux';
import css from './DashboardsEmptyState.css';
import { uuidv4 } from '../governance/utils';

import { AutoComplete, EmptyStateBox } from '../components/widgets';
import { DEFAULT_WIDGET_DATA_TYPE, DEFAULT_WIDGET_TYPE } from './utils';
import PivotIcon from '../resources/svg/widgets/pivot.svg';
import BarChartIcon from '../resources/svg/widgets/bar.svg';
import PieChartIcon from '../resources/svg/widgets/pie.svg';

export function getEmptyWidget(type) {
    return {
        __typename: 'WidgetNode',
        name: '',
        type: type || DEFAULT_WIDGET_TYPE,
        dataType: DEFAULT_WIDGET_DATA_TYPE,
        query: {
            __typename: 'WidgetQueryNode',
            dimensions: [],
            metrics: [],
            filters: [],
            dataTypeFields: [],
            skanDateDimensionName: '',
        },
        options: {
            __typename: 'WidgetOptions',
            rows: [],
            columns: [],
        },
        gridParams: {
            __typename: 'WidgetGridParams',
            i: `new.widget.${uuidv4()}`,
            x: 0,
            y: 0,
            w: 2,
            h: 4,
            minW: 2,
            minH: 3,
        },
    };
}

const EMPTY_STATE_BOXES = [
    {
        title: 'Pivot Table',
        Icon: PivotIcon,
        type: 'pivot',
    },
    {
        title: 'Bar Chart',
        Icon: BarChartIcon,
        type: 'column',
    },
    {
        title: 'Pie Chart',
        Icon: PieChartIcon,
        type: 'pie',
    },
];

function DashboardsEmptyState({ setEditedWidget, widgetTypeOptions }) {
    return (
        <div className={css.emptyState}>
            <div className={css.text}>
                <Translate id="STATIC.PAGES.DASHBOARD.EMPTY_STATE_GET_STARTED" />
            </div>

            <AutoComplete
                isCreatable={false}
                placeholder="STATIC.PAGES.DASHBOARD.SELECT_WIDGET_PLACEHOLDER"
                containerStyle={{ width: 620, marginBottom: 40 }}
                onChange={option => {
                    setEditedWidget(getEmptyWidget(option.value));
                }}
                selectOptions={{
                    isSearchable: false,
                    options: widgetTypeOptions,
                }}
            />

            <div className={css.text}>
                <Translate id="STATIC.PAGES.DASHBOARD.EMPTY_STATE_TOP_WIDGETS" />
            </div>

            <div className={css.emptyStateBoxesContainer}>
                {EMPTY_STATE_BOXES.map(({ title, Icon, type }) => (
                    <EmptyStateBox
                        key={type}
                        title={title}
                        Icon={Icon}
                        onClick={() => {
                            setEditedWidget(getEmptyWidget(type));
                        }}
                    />
                ))}
            </div>
        </div>
    );
}

DashboardsEmptyState.propTypes = {
    setEditedWidget: PropTypes.func.isRequired,
    widgetTypeOptions: PropTypes.arrayOf(PropTypes.object),
};

DashboardsEmptyState.defaultProps = {
    widgetTypeOptions: [],
};

export default DashboardsEmptyState;
