import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik-2';
import { partnerShape, siteShape, templateShape } from '../types';
import AppAutocomplete from './AppAutocomplete';

function AppSelection({ partner, disabled, sites, postbackTemplates, onChange: onChangeProp }) {
    const { values, setValues, setTouched } = useFormikContext();

    const onChange = ({ value }) => {
        if (values.site === value) return;

        setValues({ site: value }); // clear form values
        setTouched({ site: true });

        onChangeProp(value);
    };

    return (
        <AppAutocomplete
            label="STATIC.APP"
            partner={partner}
            disabled={disabled}
            sites={sites}
            postbackTemplates={postbackTemplates}
            onChange={onChange}
        />
    );
}

AppSelection.propTypes = {
    partner: partnerShape.isRequired,
    disabled: PropTypes.bool,
    sites: PropTypes.arrayOf(siteShape),
    postbackTemplates: PropTypes.arrayOf(templateShape),
    onChange: PropTypes.func,
};

AppSelection.defaultProps = {
    disabled: false,
    sites: [],
    postbackTemplates: [],
    onChange: () => {},
};

export default AppSelection;
