import IosIcon from '../resources/icons/os/ios.svg';
import AndroidIcon from '../resources/icons/os/android.svg';
import WebIcon from '../resources/icons/os/web.svg';
import PlaystationIcon from '../resources/icons/os/playstation.svg';
import XboxIcon from '../resources/icons/os/xbox.svg';
import MetaQuestIcon from '../resources/icons/os/metaquest.svg';
import NintendoIcon from '../resources/icons/os/nintendo.svg';
import PcIcon from '../resources/icons/os/pc.svg';
import CtvIcon from '../resources/icons/os/ctv.svg';
import PcConsole from '../resources/icons/os/pc_console.svg';
import Other from '../resources/icons/os/other.svg';

export const OS = {
    ANDROID: 'Android',
    IOS: 'iOS',
    WEB: 'Web',
    PLAYSTATION: 'Playstation',
    XBOX: 'Xbox',
    META_QUEST: 'MetaQuest',
    NINTENDO: 'Nintendo',
    PC: 'PC',
    CTV: 'CTV',
    PC_CONSOLE: 'PcConsole',
    DESKTOP: 'Desktop',
    OTHER: 'Other',
};

export const OSIcons = {
    [OS.IOS]: IosIcon,
    [OS.ANDROID]: AndroidIcon,
    [OS.WEB]: WebIcon,
    [OS.PLAYSTATION]: PlaystationIcon,
    [OS.XBOX]: XboxIcon,
    [OS.META_QUEST]: MetaQuestIcon,
    [OS.NINTENDO]: NintendoIcon,
    [OS.PC]: PcIcon,
    [OS.CTV]: CtvIcon,
    [OS.PC_CONSOLE]: PcConsole,
    [OS.DESKTOP]: PcIcon,
    [OS.OTHER]: Other,
};
