/*
 * We created this function in order to keep a fixed amount of digits after the decimal points without rounding the original value.
 * The original solution we had for this issue used `Math.pow()` but it had floating precision issues which gave bad results.
 * (Original solution can be found here: https://stackoverflow.com/questions/36862015/tofixed-method-without-rounding-to-five-digit)
 *
 * The current solution use string matching in order to match a certain amount of digits.
 * (Inspired by: https://stackoverflow.com/questions/4187146/truncate-number-to-two-decimal-places-without-rounding)
 */

export const getFlooredFixed = (value, decimalPlaces) => {
    const valueString = value.toString();
    if (valueString.includes('e')) {
        // For example: (0.0000009).toString() = 9e-7
        return '0';
    }

    const matchDecimalRE = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
    return valueString.match(matchDecimalRE)[0];
};

export const roundWithPercision = (value, precision = 0.5) => {
    return Math.round(value / precision) * precision;
};
