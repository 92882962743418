import {
    chartColor1,
    chartColor2,
    chartColor3,
    chartColor4,
    chartColor5,
    chartColor6,
    chartColor7,
    chartColor8,
    chartColor9,
    chartColor10,
} from '../utils/charts';

export const COUNTRY_FILTER = 'country';
export const CATEGORY_FILTER = 'vertical';
export const PLATFORM_FILTER = 'platform';
export const APP_FILTER = 'app';

export const BENCHMARKS_PAGES = {
    MARKET_SHARE_OF_VOICE: 'market_share_of_voice',
    SHARE_OF_VOICE_VS_ME: 'market_share_of_voice_vs_me',
    NETWORK_OPTIMIZATIONS: 'network_optimizations',
};

export const BENCHMARKS_GRAPH_TYPES = {
    SHARE_OF_VOICE: 'share_of_voice',
    COST_OVER_TIME: 'historical_share_of_cost',
    INSTALLS_OVER_TIME: 'historical_share_of_installs',
    ORGANIC_VS_PAID: 'organic_vs_paid',
    YEAR_OVER_YEAR: 'year_over_year_change',
    SHARE_OF_COST: 'app_share_of_cost',
    CPM_PERCENTILES: 'cpm_percentiles',
    CPI_PERCENTILES: 'cpi_percentiles',
    CVR_PERCENTILES: 'cvr_percentiles',
    CTR_PERCENTILES: 'ctr_percentiles',
    IPM_PERCENTILES: 'ipm_percentiles',
    PERCENTILES: 'percentiles',
};

export const BENCHMARKS_PERCENTILES_GRAPHS = [
    BENCHMARKS_GRAPH_TYPES.CPI_PERCENTILES,
    BENCHMARKS_GRAPH_TYPES.CPM_PERCENTILES,
    BENCHMARKS_GRAPH_TYPES.IPM_PERCENTILES,
    BENCHMARKS_GRAPH_TYPES.CTR_PERCENTILES,
    BENCHMARKS_GRAPH_TYPES.CVR_PERCENTILES,
];

export const PERCENTILES_GRAPH_TH_LABELS = {
    [BENCHMARKS_GRAPH_TYPES.CPI_PERCENTILES]: 'eCPI',
    [BENCHMARKS_GRAPH_TYPES.CPM_PERCENTILES]: 'CPM',
    [BENCHMARKS_GRAPH_TYPES.IPM_PERCENTILES]: 'IPM',
    [BENCHMARKS_GRAPH_TYPES.CTR_PERCENTILES]: 'CTR',
    [BENCHMARKS_GRAPH_TYPES.CVR_PERCENTILES]: 'CVR',
};

export const PERCENTILES_GRAPH_DEFAULT_LIMITS = {
    [BENCHMARKS_GRAPH_TYPES.CPI_PERCENTILES]: 10,
    [BENCHMARKS_GRAPH_TYPES.CPM_PERCENTILES]: 10,
    [BENCHMARKS_GRAPH_TYPES.IPM_PERCENTILES]: 10,
    [BENCHMARKS_GRAPH_TYPES.CTR_PERCENTILES]: 1,
    [BENCHMARKS_GRAPH_TYPES.CVR_PERCENTILES]: 1,
};

export const NON_EMPTY_FILTERS = 'non_empty_filters_validation_func';

export const TOOLTIP_DATE_FORMAT = 'MMMM YY';
export const X_AXIS_LABEL_DATE_FORMAT = 'MMM YY';

export const ColorByValueSign = {
    Positive: '#17A38F',
    Negative: '#f37773',
};

export const colorByNetwork = {
    Facebook: chartColor1,
    AdWords: chartColor2,
    'Apple Search Ads': chartColor3,
    AdColony: chartColor4,
    Aarki: chartColor5,
    Vungle: chartColor6,
    Liftoff: chartColor7,
    ironSource: chartColor8,
    'Unity Ads': chartColor9,
    Other: chartColor10,
};

export const colorByType = {
    Organic: '#4479A9',
    Paid: '#0BB5D2',
};

export const colorByMetric = {
    non_custom_cost: '#4479A9',
    installs: '#0BB5D2',
};

export const labelByMetric = {
    non_custom_cost: 'Percent of total cost',
    installs: 'Percent of total installs',
};

export const DEAFULT_FILTER_ALL = 'ALL';

export const networkOptimizationsPlotOptions = {
    columnrange: {
        borderWidth: 0,
        grouping: false,
        tooltip: {
            shared: true,
        },
    },
};

export const overTimeChartPlotOptions = {
    area: {
        stacking: 'percent',
        marker: {
            enabled: false,
            radius: 0,
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
    },
    series: {
        cursor: 'pointer',
        pointPlacement: 'on',
    },
};

export const shareOfCostPlotOptions = {
    series: {
        cursor: 'pointer',
        pointWidth: 50,
    },
    column: {
        stacking: 'percent',
        dataLabels: {
            formatter() {
                const highchartsPlotContainer = document.querySelector('.highcharts-plot-background');
                if (highchartsPlotContainer) {
                    const containerWidth = highchartsPlotContainer.getBoundingClientRect().width;
                    return (this.y * containerWidth) / 100 > 55 ? `${parseFloat(this.y).toFixed(2)}%` : '';
                } else {
                    return '';
                }
            },
            enabled: true,
            style: {
                color: '#ffffff',
                fontSize: '12px',
                fontWeight: 600,
                textOutline: 'none',
            },
        },
    },
};

export const shareOfVoicePlotOptions = {
    series: {
        cursor: 'pointer',
        groupPadding: 0.35,
        pointPadding: 0.01,
        pointWidth: 10,
        minPointLength: 2,
    },
};

export const yearOverYearPlotOptions = {
    series: {
        cursor: 'pointer',
        groupPadding: 0.35,
        pointPadding: 0.01,
        pointWidth: 40,
        minPointLength: 2,
    },
};

export const organicVsPaidPlotOptions = {
    column: {
        stacking: 'percent',
    },
    series: {
        cursor: 'pointer',
        pointWidth: 40,
        states: {
            inactive: {
                opacity: 1,
            },
        },
    },
};

export const TRACK_EVENT_PREFIX = 'Benchmarks: Current Market Share';
export const TRACK_EVENT_PAGE_LOADED = 'Page Loaded';
export const TRACK_EVENT_PAGE_SCROLLED = 'Scrolled';
export const TRACK_EVENT_HOVER_ON_CHART = 'Hover on Chart';
export const TRACK_EVENT_FILTER_CHANGE = 'Selector Change';
export const CHART_HOVER_TIME_TO_TRACK = 5000;
