import { connect } from 'react-redux';
import ReportPresetButton from '../../components/widgets/ReportPresetButton';
import { reportsFieldClickedComplete, setActionType } from '../../actions/reports';
import { getAdminModeEnabled } from '../../selectors/user';

const mapStateToProps = (state, ownProps) => {
    return {
        text: ownProps.preset.text,
        hide: !(getAdminModeEnabled(state) && ownProps.preset.shouldShow(state)),
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...ownProps,
        onClick: () =>
            dispatch(setActionType(reportsFieldClickedComplete(ownProps.preset.fields), ownProps.reportType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPresetButton);
