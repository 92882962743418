import React, { useEffect, useState } from 'react';
import { getUuid } from './utils';
import { useFetchSignupActivation } from './hooks';
import LoadingPageBody from '../components/widgets/LoadingPageBody';

const LoadingPage = () => {
    const [warningMsg, setWarningMsg] = useState('');

    const uuid = getUuid();
    const { error, isLoading, ...resp } = useFetchSignupActivation(uuid);

    useEffect(() => {
        if (!isLoading) {
            resp.url ? (window.location.href = resp.url) : setWarningMsg(resp.message);
        }
    }, [isLoading]);

    return <LoadingPageBody warningMsg={warningMsg} />;
};

export default LoadingPage;
