import React from 'react';

export default function InviteUsers() {
    return (
        <div>
            <p>
                <strong>What: </strong><span> Invite other people from your organization to use your Singular account.</span>
            </p>
            <p>
                <strong>Why: </strong><span> You probably want to bring in your developers so they can implement the Singular SDK. Go ahead and invite your designers to run creative reports, or your finance team for cost analysis.&nbsp;&nbsp;</span>
            </p>
            <p>
                <strong>Tip: </strong><span> You can add users as “Standard” or “Admin”. A Standard user has access to all the data. An Admin (like yourself) has access to all the data + can invite other users and edit their permissions.&nbsp;</span>
            </p>
            <p>
                <span>Singular’s premium customers can also create “Restricted” users with customized permissions. Restricted users can be given access to only some of Singular’s tools or only part of the organization’s data.</span>
            </p>
        </div>
    );
}
