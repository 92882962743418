import {
    PLATFORM_MAPPING_FOR_SDK_CONFIGURATION,
    SDK_CONFIG_QUESTIONS_DATA_MAPPING,
    SDK_CONFIG_QUESTIONS_MAPPING,
    SDK_CONFIG_QUESTIONS_METADATA_WITH_CHOICES,
    SDK_PLANNING_EVENT_PREFIX,
} from './consts';
import { trackMixpanelEvent } from '../../utils/general';
import { SDK_INTEGRATION_MIXPANEL_PLATFORMS } from '../consts';

export const getInfoSectionKey = section => `${section}_INFO`;

export const getSDKConfigurationAnswersBySections = sdkConfigResponse => {
    const answers = sdkConfigResponse?.answers || {};
    const parsedSDKConfig = {};
    for (const platform in answers) {
        const platformKey = PLATFORM_MAPPING_FOR_SDK_CONFIGURATION[platform];
        if (platformKey) {
            const questionsMappingOfPlatform = SDK_CONFIG_QUESTIONS_MAPPING[platformKey];
            const questionsMappingInfoOfPlatform = SDK_CONFIG_QUESTIONS_DATA_MAPPING[platformKey];
            const sdkConfigOfPlatform = {};

            for (const question in answers[platform]) {
                const sectionsRelatedToQuestion = questionsMappingOfPlatform[question];
                if (sectionsRelatedToQuestion) {
                    sectionsRelatedToQuestion.forEach(section => {
                        sdkConfigOfPlatform[section] = answers[platform][question];
                    });
                }

                const sectionRelatedToInfoQuestion = questionsMappingInfoOfPlatform[question];
                if (sectionRelatedToInfoQuestion) {
                    sdkConfigOfPlatform[getInfoSectionKey(sectionRelatedToInfoQuestion)] = answers[platform][question];
                }
            }

            parsedSDKConfig[platformKey] = sdkConfigOfPlatform;
        }
    }

    return parsedSDKConfig;
};

export const getSDKConfigChoicesMetadata = sdkConfigResponse => {
    const { metadata = {} } = sdkConfigResponse;
    return SDK_CONFIG_QUESTIONS_METADATA_WITH_CHOICES.reduce((acc, currQuestion) => {
        acc[currQuestion] = metadata[currQuestion]?.choices;
        return acc;
    }, {});
};

export const sendMixpanelEventSdkIntegration = (event, step, platform, extraParams = {}) => {
    if (!event) return;

    trackMixpanelEvent(SDK_PLANNING_EVENT_PREFIX, event, {
        step,
        platform: SDK_INTEGRATION_MIXPANEL_PLATFORMS[platform],
        ...extraParams,
    });
};

export const getMediatorParsedName = displayName => displayName.split(' ')[0].toUpperCase();
