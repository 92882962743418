import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Label, Spinner } from '../components/widgets';
import { getLoading } from './selectors';
import { PageWrapper } from '../components/partials';
import css from './page.css';
import MtaForm from './MtaForm';
import ModelDescripton from './ModelDescription';
import { MTA_EVENT_PREFIX } from './consts';
import { trackMixpanelEvent } from '../utils/general';

function MTA() {
    const loading = useSelector(state => getLoading(state));
    const [model, setModel] = useState();

    useEffect(() => {
        trackMixpanelEvent(MTA_EVENT_PREFIX, 'Page Loaded', {});
    }, []);

    const onModelSelected = selectedModel => {
        setModel(selectedModel);
    };

    if (loading) {
        return <Spinner show expanded />;
    }

    return (
        <PageWrapper className={css.mtaContainer} allowDropDownPageScrolling disablePadding>
            <Label text="STATIC.PAGES.MTA.PAGE_HEADER" type="h1" className={css.pageHeader} />
            <div className={css.formContainer}>
                <MtaForm onModelSelected={onModelSelected} />
                <ModelDescripton modelName={model} />
            </div>
        </PageWrapper>
    );
}

export default withLocalize(MTA);
