import API from '../services/api';

let instance = null;
const api = new API();
const URL_SEGMENTS = '/api/audiences/segments';
const URL_SEGMENT = '/api/audiences/segment/';
const URL_SEGMENT_EDIT = '/api/audiences/segment/edit/';
const URL_SEGMENT_SHARING = '/api/audiences/segment/share/';
const SEGMENT_SHARING_BASE = 'audience-segments.singular.net';

export default class AudienceSegmentsService {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getSegments() {
        return api.basicRequest(URL_SEGMENTS);
    }

    toggleActive(id) {
        return api.basicRequest(URL_SEGMENT + id, 'POST');
    }

    delete(id) {
        return api.basicRequest(URL_SEGMENT + id, 'DELETE');
    }

    download(id) {
        return api.basicRequest(URL_SEGMENT + id);
    }

    editSegment(id, template = '', fetchMetadata = false, admin = false) {
        return api.basicRequest(
            `${URL_SEGMENT_EDIT}${id}?template=${template}&metadata=${fetchMetadata}${admin ? '&extra=true' : ''}`
        );
    }

    save(id, segment) {
        return api.basicRequest(`${URL_SEGMENT_EDIT}${id}`, 'POST', segment);
    }

    getSharingOptions(id) {
        return api.basicRequest(`${URL_SEGMENT_SHARING}${id}`);
    }

    createSharingLink(id) {
        return api.basicRequest(`${URL_SEGMENT_SHARING}${id}`, 'POST');
    }

    updateSharingLink(id, link_id, update) {
        return api.basicRequest(`${URL_SEGMENT_SHARING}${id}/${link_id}/edit`, 'POST', update);
    }

    getSharingLinkUrl(id, link_id) {
        const { hostname, host, protocol } = document.location;
        const downloadHost = hostname !== 'app.singular.net' ? host : SEGMENT_SHARING_BASE;

        return `${protocol}//${downloadHost}${URL_SEGMENT_SHARING}${id}/${link_id}`;
    }
}
