const fonts = 'open-sans, Helvetica Neue, Helvetica, Arial, sans-serif';
const ROUTE_PREFIX = '/react';

function generateArrayOfSize(size) {
    const array = [];
    for (let i = 0; i < size; i++) {
        array.push(i);
    }

    return array;
}

function generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

function generateGuidShort() {
    return generateGuid().replace(/-/g, () => {
        return '';
    });
}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

function downloadFileResponse({ contents, filename }, isExcel) {
    let blob;

    if (isExcel) {
        blob = new Blob([s2ab(atob(contents))], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    } else {
        blob = new Blob(['\ufeff', contents], { type: 'text/csv;charset=utf-8' });
    }

    // Adding a BOM char before csv data to force excel to read ascii chars.
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}

const tick = () => new Promise(resolve => requestIdleCallback(resolve));

const getKeyByValue = (dict, value) => {
    const indexOfValue = Object.values(dict).indexOf(value);
    return Object.keys(dict)[indexOfValue];
};

export {
    fonts,
    generateGuid,
    generateGuidShort,
    ROUTE_PREFIX,
    tick,
    downloadFileResponse,
    generateArrayOfSize,
    getKeyByValue,
};
