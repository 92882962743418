import './wdyr';

// forces the babel to analyze this code
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import React from 'react';
import { render } from 'react-dom';
import { LicenseManager } from 'ag-grid-enterprise/main';
import { HashRouter } from 'react-router-dom';
import browserHistory from './hashHistory';
import GeneralErrorDialogContainer from './appLoadingState/generalErrorDialogContainer';
import GoBackPanel from './topPanels/goBackPanel/goBackPanelContainer';
import TrialStatusPanel from './topPanels/trialStatusPanel/trialStatusPanelContainer';
import Router from './router';
import reducers from './reducers';
import init from './utils/init';
import { BACK_PANEL_CONTAINER_CSS_CLASS } from './topPanels/consts';

LicenseManager.setLicenseKey(
    'Singular.net_Singular_1Devs6_SaaS_4_January_2019__MTU0NjU2MDAwMDAwMA==8c969172b1941786e54fecb2c79ab63f'
);

// middleware for the redux devtools
const composeEnhancers = composeWithDevTools;
const sagaMiddleware = createSagaMiddleware({
    onError: error => {
        console.log(error);
        if (window.Sentry) {
            window.Sentry.captureException(error);
        }
    },
});
const rMiddleware = routerMiddleware(browserHistory);
const middleWares = [sagaMiddleware, rMiddleware];
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleWares)));

const loggedInUser = window.impersonationUsername || window.realUsername;
const goBackContainer = document.getElementById(BACK_PANEL_CONTAINER_CSS_CLASS);
const shouldShowGoBackPanel = !!loggedInUser && !!goBackContainer;

const renderApp = RootComponent => {
    return render(
        <Provider store={store}>
            <LocalizeProvider store={store}>
                <GeneralErrorDialogContainer />
                {shouldShowGoBackPanel && <GoBackPanel goBackContainer={goBackContainer} />}
                <TrialStatusPanel />
                <HashRouter>
                    <RootComponent store={store} />
                </HashRouter>
            </LocalizeProvider>
        </Provider>,
        document.getElementById('reactContainer')
    );
};

const initApp = () => {
    return init(store, sagaMiddleware);
};

if (initApp()) {
    renderApp(Router);
}

if (module.hot) {
    // hot-module's gonna intercept the files connected (via imports) to the changed files.
    // in case there's no rule defined for any of the files -> FULL RELOAD

    // In case a change was made, connected to the router
    // (which is connect, in turn, to every component) -> reload the components
    module.hot.accept(['./router.jsx', './utils/init.js'], () => {
        const NextRoot = require('./router').default; // eslint-disable-line global-require
        renderApp(NextRoot);
    });

    // In case a change was made, connected to the reducers -> reload the reducers, and hot replace it.
    module.hot.accept('./reducers', () => {
        const newReducers = require('./reducers').default; // eslint-disable-line global-require
        store.replaceReducer(newReducers);
    });
}

window.addEventListener('reactContainerReady', () => {
    if (initApp()) {
        renderApp(Router);
    }
});
