export const AVAILABILITY_PARAMETER_OPTION__DIMENSION = { name: 'dimension', display_name: 'Dimension' };
export const AVAILABILITY_PARAMETER_OPTION__SOURCE = { name: 'source', display_name: 'Source' };

export const availabilityParameterOptions = [
    AVAILABILITY_PARAMETER_OPTION__SOURCE,
    AVAILABILITY_PARAMETER_OPTION__DIMENSION,
];

export const NON_REPORTS_FIELDS_WHITELIST = [
    'campaign_id',
    'creative_name',
    'creative_id',
    'asset_name',
    'asset_id',
    'creative_image',
    'creative_text',
    'skan_campaign_id',
    'skan_publisher_id',
    'skan_version',
    'skan_view_through',
];
export const REPORTS_FIELDS_BLACKLIST = ['source'];
export const HARDCODED_FIELDS = [
    { name: 'creative_image', display_name: 'Creative Image', creative_visible: true },
    { name: 'creative_text', display_name: 'Creative Text', creative_visible: true },
];

export const AVAILABILITY_TYPES = {
    MAPPED: 'interpolated',
    CALCULATED: 'calculated',
    COMBINED: 'combined',
    NOT_PULLED: 'not_pulled',
};

export const FIELDS_AVAILABILITY_MIXPANEL_PREFIX = 'Fields Availability';

export const MAX_SOURCES_TO_DISPLAY_ON_TOOLTIP = 2;
