import { connect } from 'react-redux';
import { getSummaryViewPage } from 'teamManagement/selectors';
import SummaryView from 'teamManagement/components/SummaryView';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        ...getSummaryViewPage(state),
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SummaryView);
