export const SET_ADMIN_MODE = 'action.user.set.admin.mode';
export const UPDATE_USER_DATA = 'action.user.update.data';
export const SET_API_KEY = 'action.user.set.api.key';
export const SET_ORGANIZATIONS = 'action.user.set.organizations';
export const OPEN_IMPERSONATE_DIALOG = 'action.user.open.impersonate.dialog';
export const CLOSE_IMPERSONATE_DIALOG = 'action.user.close.impersonate.dialog';
export const IMPERSONATE_DIALOG_STARTED = 'action.user.impersonate.dialog.started';
export const TOGGLE_IMPERSONATE_DIALOG_LOADING = 'action.user.impersonate.dialog.loading';
export const OPEN_CHANGE_PASSWORD_DIALOG = 'action.user.open.change_password.dialog';
export const CLOSE_CHANGE_PASSWORD_DIALOG = 'action.user.close.change_password.dialog';
export const CHANGE_PASSWORD = 'action.user.save.change_password';
export const CHANGE_PASSWORD_FAILED = 'action.user.save.change_password.failed';
export const CHANGE_PASSWORD_SUCCEEDED = 'action.user.save.change_password.succeeded';
export const IMPERSONATE_ORG = 'action.user.impersonate.org';
export const TOGGLE_TRIAL_STATUS_PANEL = 'action.user.trial-status-panel.show';
export const SET_IS_USER_SLACK_AUTH = 'action.user.set.is.user.slack.auth';

export const setAdminMode = enabled => {
    return {
        type: SET_ADMIN_MODE,
        enabled,
    };
};

export const updateUserData = data => {
    return {
        type: UPDATE_USER_DATA,
        data,
    };
};

export const setApiKey = api_key => {
    return {
        type: SET_API_KEY,
        api_key,
    };
};

export const setOrganizations = organizations => {
    return {
        type: SET_ORGANIZATIONS,
        organizations,
    };
};

export const openImpersonateDialog = () => {
    return {
        type: OPEN_IMPERSONATE_DIALOG,
    };
};

export const closeImpersonateDialog = () => {
    return {
        type: CLOSE_IMPERSONATE_DIALOG,
    };
};

export const impersonateDialogStarted = () => {
    return {
        type: IMPERSONATE_DIALOG_STARTED,
    };
};

export const toggleImpersonateDialogLoading = loading => {
    return {
        type: TOGGLE_IMPERSONATE_DIALOG_LOADING,
        loading,
    };
};

export const openChangePasswordDialog = () => {
    return {
        type: OPEN_CHANGE_PASSWORD_DIALOG,
    };
};

export const closeChangePasswordDialog = () => {
    return {
        type: CLOSE_CHANGE_PASSWORD_DIALOG,
    };
};

export const changePassword = (oldPassword, newPassword) => {
    return {
        type: CHANGE_PASSWORD,
        oldPassword,
        newPassword,
    };
};

export const changePasswordFailed = error => {
    return {
        type: CHANGE_PASSWORD_FAILED,
        error,
    };
};

export const changePasswordSucceeded = () => {
    return {
        type: CHANGE_PASSWORD_SUCCEEDED,
    };
};

export const impersonateOrg = org => {
    return {
        type: IMPERSONATE_ORG,
        org,
    };
};

export const toggleTrialStatusPanel = (show, trialRealDaysLeft, trialDaysLeftToDisplay) => {
    return {
        type: TOGGLE_TRIAL_STATUS_PANEL,
        show,
        trialRealDaysLeft,
        trialDaysLeftToDisplay,
    };
};

export const setIsUserSlackAuth = isUserSlackAuth => {
    return {
        type: SET_IS_USER_SLACK_AUTH,
        isUserSlackAuth,
    };
};
