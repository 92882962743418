import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import css from './HoverButtonGroup.css';

class HoverButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.setHovered = this._setHovered.bind(this);
        this.unsetHovered = this._unsetHovered.bind(this);
        this.state = {
            hoveredIndex: null,
        };
    }

    _setHovered(index) {
        this.setState({
            hoveredIndex: index,
        });
    }

    _unsetHovered(index) {
        const { hoveredIndex } = this.state;
        if (hoveredIndex === index) {
            this.setState({
                hoveredIndex: null,
            });
        }
    }

    render() {
        const { onClick, options, icon, className } = this.props;
        const { hoveredIndex } = this.state;
        const colors = options.map(opt => opt.color || 'black');
        return (
            <div className={`${css.container} ${className || ''}`}>
                {options.map(({ text }, index) => (
                    <div key={text} style={{ display: 'inline-flex' }}>
                        <div
                            onClick={() => onClick(index)}
                            onKeyPress={() => onClick(index)}
                            role="button"
                            tabIndex={0}
                            onMouseEnter={() => this.setHovered(index)}
                            onMouseLeave={() => this.unsetHovered(index)}
                            className={`${css.option} ${hoveredIndex === index && css.hovered}`}
                            style={{
                                color: hoveredIndex === index && colors[index],
                            }}
                        >
                            {text}
                        </div>
                        <span style={{ display: index < options.length - 1 ? '' : 'none', marginRight: 4 }}>/</span>
                    </div>
                ))}
                {options.length === 1 ? (
                    <div
                        style={{
                            color: hoveredIndex !== null && colors[hoveredIndex],
                            fill: hoveredIndex !== null && colors[hoveredIndex],
                            cursor: 'pointer',
                            display: icon ? '' : 'none',
                        }}
                        onMouseEnter={() => this.setHovered(0)}
                        onMouseLeave={() => this.unsetHovered(0)}
                        className={css.iconContainer}
                        onClick={() => onClick(0)}
                        onKeyPress={() => onClick(0)}
                        role="button"
                        tabIndex={0}
                    >
                        {icon}
                    </div>
                ) : (
                    <div
                        style={{
                            color: hoveredIndex !== null && colors[hoveredIndex],
                            fill: hoveredIndex !== null && colors[hoveredIndex],
                            display: icon ? '' : 'none',
                        }}
                        className={css.iconContainer}
                    >
                        {icon}
                    </div>
                )}
            </div>
        );
    }
}

HoverButtonGroup.propTypes = {
    onClick: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            color: PropTypes.string,
            icon: PropTypes.element,
        })
    ),
    icon: PropTypes.element,
    className: PropTypes.string,
};

HoverButtonGroup.defaultProps = {
    onClick: () => null,
    options: [],
    icon: null,
    className: null,
};

export default withLocalize(HoverButtonGroup);
