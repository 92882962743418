import API from './api';
import { formatAppsResponse } from '../utils/apps';

let instance = null;
const api = new API();
const URL_ATTRIBUTION_APPS = 'api/attribution/apps';
const URL_APPS = 'api/apps/apps';
const URL_FAVOURITE_APPS = 'api/users/self/apps/favourites';
export const URL_APP_SITE = 'api/apps/site/';

class Apps {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getAttributionApps(
        simplify = false,
        includeWeb = false,
        includeUanEvents = false,
        filterByPermissions = true,
        filterIntegrated = true
    ) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ATTRIBUTION_APPS, {
                method: 'GET',
                credentials: 'include',
                disableBrowserCache: true,
                params: {
                    simplify,
                    include_web: includeWeb,
                    include_uan_events: includeUanEvents,
                    filter_by_permissions: filterByPermissions,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(formatAppsResponse(response.value, filterIntegrated));
                })
                .catch(reject);
        });
    }

    getApps(productionSitesOnly) {
        return new Promise((resolve, reject) => {
            let params;

            if (productionSitesOnly) {
                params = {
                    development_statuses_whitelist: ['IN PRODUCTION'],
                };
            }

            api.getJson(URL_APPS, {
                method: 'GET',
                credentials: 'include',
                params,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateFavouriteApp(favourite, appId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_FAVOURITE_APPS}`, {
                method: 'POST',
                credentials: 'include',
                body: {
                    appId,
                    favourite,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    getFavouriteApps() {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_FAVOURITE_APPS}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }

    updateSite(appId, site) {
        return new Promise((resolve, reject) => {
            const { id } = site;
            if (!id) {
                reject(new Error('Site ID is required'));
            }
            api.getJson(`${URL_APP_SITE}${id}`, {
                body: { app_id: appId, site },
                method: 'POST',
                disableBrowserCache: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(response.value);
                    }
                    return resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default Apps;
