import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { AutoCompleteField } from '../../../../components/widgets';
import css from './EventsType.css';
import Tooltip from '../../../../components/widgets/Tooltip';
import XIcon from '../../../../resources/svg/icon_remove_row.svg';
import PlusIcon from '../../../../resources/svg/icon_add_row.svg';
import { getEventSlotName, getSdkEventsValues, scrollIntoViewIfNeeded } from '../../../utils';
import { EVENTS_DISABLED_REASON } from '../../../consts';
import { modelErrorsShape, sdkEventsShape } from '../types';

function SkanEventsGroup({ sdkEvents, maxEvents, eventIds, setEventIds, errors }) {
    const eventsContainerRef = useRef();
    const eventsElements = [];
    const [eventsAmount, setEventsAmount] = useState(1);
    const [eventOptions, setEventsOptions] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState();

    const formatSdkEvent = sdkEvent => {
        return {
            label: sdkEvent?.displayName,
            value: sdkEvent?.id,
        };
    };

    useEffect(() => {
        const target = eventsContainerRef.current;
        target && scrollIntoViewIfNeeded(target);
    }, [eventsAmount]);

    useEffect(() => {
        setEventsOptions(
            getSdkEventsValues(sdkEvents)
                .map(formatSdkEvent)
                .filter(eventOption => !eventIds.includes(eventOption.value))
        );

        setEventsAmount(Math.max(eventIds.length, eventsAmount));

        setSelectedEvents(
            eventIds.map(eventId => {
                return formatSdkEvent(sdkEvents[eventId]);
            })
        );
    }, [eventIds]);

    const handleSelectEvent = (index, selectedEvent) => {
        const currEventIds = [...eventIds];
        const eventValue = selectedEvent.value;

        if (index < currEventIds.length) {
            currEventIds[index] = eventValue;
        } else {
            currEventIds.push(eventValue);
        }

        setEventIds(currEventIds);
    };

    const handleAddEvent = () => {
        setEventsAmount(eventsAmount + 1);
    };

    const handleDeleteEvent = () => {
        const newEventsAmount = eventsAmount - 1;
        const currEventIds = [...eventIds];
        setEventIds(currEventIds.slice(0, newEventsAmount));
        setEventsAmount(eventsAmount - 1);
    };

    const getEventAddDisabledReason = () => {
        if (eventsAmount > eventIds.length) {
            return EVENTS_DISABLED_REASON.CURRENT_EMPTY;
        }

        if (!eventOptions.length) {
            return EVENTS_DISABLED_REASON.NO_OPTIONS;
        }

        if (maxEvents && eventsAmount >= maxEvents) {
            return EVENTS_DISABLED_REASON.MAX_BITS_REACHED;
        }

        return null;
    };

    const addDisableReason = getEventAddDisabledReason();
    const isAddDisabled = addDisableReason != null;
    const isDeleteDisabled = eventsAmount === 1;

    const plusIconTooltipProps = { disabled: !isAddDisabled };

    if (addDisableReason) {
        plusIconTooltipProps.titleTranslationKey = `STATIC.PAGES.SKADNETWORK.EVENT_ADD_DISABLED_REASONS.${addDisableReason}`;
    }

    for (let i = 0; i < eventsAmount && selectedEvents; i++) {
        const isLastEvent = i === eventsAmount - 1;

        eventsElements.push(
            <div className={css.eventFieldContainer} key={`event-${i}`}>
                <AutoCompleteField
                    name={i}
                    label="STATIC.PAGES.SKADNETWORK.EVENT_NUMBER_LABEL"
                    labelTranslateArgs={{ i: i + 1 }}
                    isMulti={false}
                    options={eventOptions}
                    value={selectedEvents[i]}
                    onChange={selectedEvent => {
                        handleSelectEvent(i, selectedEvent);
                    }}
                    badgeText={getEventSlotName(i)}
                    badgeClass={css.modelSlotsClass}
                    error={errors.sdkEvents?.errorMsg}
                    disabled={maxEvents < i + 1}
                    labelTooltip={maxEvents < i + 1 ? 'STATIC.PAGES.SKADNETWORK.MAX_EVENTS_EXCEEDED' : ''}
                />
                <div className={classNames(css.eventIconsContainer, { [css.hidden]: !isLastEvent })}>
                    <Tooltip
                        disabled={!isDeleteDisabled}
                        titleTranslationKey="STATIC.PAGES.SKADNETWORK.FIRST_EVENT_DELETE_DISABLED"
                    >
                        <XIcon
                            className={classNames(css.xIcon, { [css.disabled]: isDeleteDisabled })}
                            onClick={() => {
                                !isDeleteDisabled && handleDeleteEvent();
                            }}
                        />
                    </Tooltip>
                    <div
                        className={classNames(css.plusIconContainer, { [css.disabled]: isAddDisabled })}
                        onClick={() => {
                            !isAddDisabled && handleAddEvent();
                        }}
                    >
                        <Tooltip {...plusIconTooltipProps}>
                            <PlusIcon className={classNames(css.plusIcon)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={css.eventsContainer}>
            <div ref={eventsContainerRef}>{eventsElements}</div>
        </div>
    );
}

SkanEventsGroup.propTypes = {
    sdkEvents: PropTypes.objectOf(sdkEventsShape).isRequired,
    errors: modelErrorsShape,
    maxEvents: PropTypes.number,
    eventIds: PropTypes.arrayOf(PropTypes.number),
    setEventIds: PropTypes.func,
};

SkanEventsGroup.defaultProps = {
    errors: {},
    maxEvents: null,
    eventIds: [],
    setEventIds: () => {},
};

export default SkanEventsGroup;
