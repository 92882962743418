import { useMemo } from 'react';
import { getSDKConfigChoicesMetadata, getSDKConfigurationAnswersBySections } from '../utils';
import { useGetSDKConfig } from '../../../onboardingGuide/hooks';

const useSDKConfigForPlanning = () => {
    const { isLoading, value = {} } = useGetSDKConfig();
    const sdkConfigForPlanning = useMemo(() => getSDKConfigurationAnswersBySections(value), [value]);
    const sdkConfigChoicesMetadata = useMemo(() => getSDKConfigChoicesMetadata(value), [value]);
    return { isLoading, sdkConfigForPlanning, sdkConfigChoicesMetadata };
};

export default useSDKConfigForPlanning;
