import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import Search from '../../../resources/icons/search.svg';
import XIcon from '../../../resources/icons/cross.svg';

import css from './SearchTextField.css';

export const CLEAR_TITLE = 'clearButton';
export const SEARCH_PLACEHOLDER = 'STATIC.PLACEHOLDERS.SEARCH';

const SearchTextField = ({ search, onSearchChange, clearable, label, placeholder, iconClassName, translate }) => {
    return (
        <TextField
            size="small"
            fullWidth
            value={search}
            placeholder={placeholder ? translate(placeholder) : null}
            label={label ? translate(label) : null}
            onChange={event => onSearchChange(event.target.value)}
            className={css.dimensionSearchInput}
            InputLabelProps={{
                className: css.label,
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search className={classNames(css.dimensionSearchInputIcon, iconClassName)} />
                    </InputAdornment>
                ),
                endAdornment: clearable && (
                    <InputAdornment position="end">
                        <XIcon
                            title={CLEAR_TITLE}
                            onClick={() => {
                                onSearchChange('');
                            }}
                            className={css.clearIcon}
                        />
                    </InputAdornment>
                ),
            }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    paddingLeft: '8px',
                    fontSize: '14px',
                },
            }}
        />
    );
};

SearchTextField.propTypes = {
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    clearable: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    iconClassName: PropTypes.string,
    translate: PropTypes.func,
};

SearchTextField.defaultProps = {
    clearable: false,
    placeholder: SEARCH_PLACEHOLDER,
};

export default withLocalize(SearchTextField);
