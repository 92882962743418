import PropTypes from 'prop-types';
import React from 'react';
import AutoComplete from '../../components/widgets/AutoComplete';
import FauxButton from '../../components/widgets/FauxButton';
import Wizardable from '../../components/widgets/Wizardable';
import WizardFooter from '../../components/widgets/WizardFooter';
import Checkbox from '../../components/widgets/Checkbox';
import css from './GenericDimensionSelection.css';
import AddFutureCheckbox from "../containers/AddFutureCheckbox";
import {Translate} from "react-localize-redux";

class GenericDimensionSelection extends Wizardable {
    constructor(props) {
        super(props);
        this.state = {
            valueFilter: '',
        };
        this.buttonOverrides = props.wizardOverrideButtons;
    }

    selectAll = (shouldSelectAll) => {
        const { permissionDimension: { values }, setDimensionFilterValues, enableAddFuture } = this.props;
        setDimensionFilterValues(shouldSelectAll ? values.map((x) => x.name) : []);
        enableAddFuture();
    }

    onSearchChanged = (val) => {
        this.setState({ valueFilter: val });
    }

    _textPredicate = (value) => {
        const { valueFilter } = this.state;
        return value.displayName.toLocaleLowerCase().includes(valueFilter.toLocaleLowerCase().trim());
    }

    validate() {
        const { translate, permissionDimension } = this.props;
        const isValid = permissionDimension.selected && permissionDimension.selected.values.length > 0;

        const tooltipButtons = ['next'];
        this.setTooltip(
            tooltipButtons,
            isValid
                ? null
                : translate('STATIC.PAGES.TEAM_MEMBERS.SELECT_AT_LEAST_ONE_TOOLTIP', {
                      itemType: permissionDimension.displayName,
                  })
        );

        return isValid;
    }

    onChange = (name, selected) => {
        const { setDimensionFilterValues, permissionDimension } = this.props;
        const selectedValues = permissionDimension.selected && permissionDimension.selected.values || [];
        if (!selected && selectedValues.includes(name)) {
            setDimensionFilterValues(selectedValues.filter((x) => x !== name));
        } else if (selected && !selectedValues.includes(name)) {
            setDimensionFilterValues([...selectedValues, name]);
        }
    }

    render() {
        const { permissionDimension: { name, displayName, values, selected }, lastAction } = this.props;
        const { valueFilter } = this.state;
        const selectedValues = selected.values || [];
        return <React.Fragment>
            <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                <div>
                    <div className={css.headline}>
                        <div className={css.preamble}>
                            <Translate id={`STATIC.PAGES.TEAM_MEMBERS.WIZARD_STEP_DIMENSION_FILTER_TITLE`}
                                       data={{ dimension: displayName }} />
                        </div>
                        <div className={css.selectorContainer}>
                            <div className={css.counter}>{`${selectedValues.length} of ${values.length} selected`}</div>
                            <div className={css.vSeperator}/>
                            <FauxButton
                                onClick={() => this.selectAll(selectedValues.length !== values.length)}
                                containerStyle={{padding: 0}}
                            >
                                {selectedValues.length !== values.length ? 'Select' : 'Deselect'} All
                            </FauxButton>
                        </div>
                    </div>
                    <AutoComplete
                        placeholder={`Type to search a ${displayName}`}
                        containerStyle={{
                            flexBasis: '960px',
                            marginRight: '20px',
                            marginBottom: '30px',
                            width: '344px',
                        }}
                        selectOptions={{
                            options: [],
                            noOptionsMessage: () => null,
                            isSearchable: true,
                            inputValue: valueFilter,
                        }}
                        onInputChange={this.onSearchChanged}
                        controlledValue={valueFilter}
                    />
                </div>

                <div className={css.valuesList}>
                    {values.filter(this._textPredicate).map((value) => {
                        const isSelected = selectedValues.includes(value.name);
                        return (
                            <div key={value.name}
                                 className={`${css.value} ${isSelected && css.selectedValue}`}
                                 onClick={() => this.onChange(value.name, !isSelected)}
                                 role="button"
                                 tabIndex={0}>
                                {' '}
                                <div className={css.valueName}>{value.displayName}</div>
                                <Checkbox className={css.checkbox} id={`checkbox_${name}_${value.name}`}
                                          checked={isSelected}/>
                            </div>
                        );
                    })}
                </div>
            </this.AnimationItem>
            <WizardFooter
                buttons={this.getButtons()}
                leftComponents={<AddFutureCheckbox itemType={`${displayName} values`} form={name} dimensionFilter/>}
            />
        </React.Fragment>;
    }
}

GenericDimensionSelection.propTypes = {
    permissionDimension: PropTypes.shape({
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            displayName: PropTypes.string.isRequired,
        })).isRequired,
        selected: PropTypes.shape({
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
            addFuture: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    wizardOverrideButtons: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
};

GenericDimensionSelection.defaultProps = {
    wizardOverrideButtons: {},
    translate: (x) => x,
};

export default GenericDimensionSelection;
