import React from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from '../style.css';
import HandIcon from '../../resources/svg/hand.svg';
import SingularButton from '../../components/widgets/SingularButton';
import Label from '../../components/widgets/Label';
import Checked from '../../resources/svg/v_icon.svg';
import SingularLogoIcon from '../../resources/svg/singular_flat_logo_white.svg';
import EmailIcon from '../../resources/svg/email.svg';

export default function ThankYou({ onResendClick, isLoading, emailSent, email }) {
    return (
        <div className={css.signupThankYou}>
            <div className={css.signupThankYouInner}>
                <SingularLogoIcon className={css.singularIcon} />
                <div className={css.signupThankYouTitle}>
                    <Translate id="STATIC.PAGES.SIGNUP.THANK_YOU_PAGE_TITLE_TEXT" />
                </div>
                <div className={classNames(css.signupThankYouSubtitle)}>
                    <EmailIcon className={css.emailIcon} />
                    <Translate id="STATIC.PAGES.SIGNUP.THANK_YOU_PAGE_BODY_TEXT" />
                </div>
                {email && (
                    <div className={classNames(css.signupThankYouResendLink, 'showInNonMobile')}>
                        <Label text="STATIC.PAGES.SIGNUP.RESEND_TEXT" type="p" />
                        {emailSent && (
                            <div className={css.emailSent}>
                                <Translate id="STATIC.PAGES.SIGNUP.EMAIL_SENT_TEXT" />
                                <Checked className={css.emailSentIcon} />
                            </div>
                        )}
                        {!emailSent && (
                            <SingularButton
                                className={css.resendButton}
                                type="link"
                                text="STATIC.PAGES.SIGNUP.RESEND_LINK_TEXT"
                                showSpinner={isLoading}
                                onClick={() => {
                                    onResendClick(email);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className={css.signupThankYouFooter}>
                <HandIcon className={css.thankYouImg} />
            </div>
            {email && (
                <div className={classNames(css.resendMobile, 'showInMobile')}>
                    <SingularButton
                        className={css.resendButtonMobile}
                        type="primary"
                        level="level1"
                        text="STATIC.PAGES.SIGNUP.RESEND_LINK_TEXT"
                        showSpinner={isLoading}
                        onClick={() => {
                            onResendClick(email);
                        }}
                        textClassName={css.resendButtonText}
                        showV={emailSent}
                    />
                </div>
            )}
        </div>
    );
}

ThankYou.propTypes = {
    onResendClick: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    emailSent: PropTypes.bool,
};

ThankYou.defaultProps = {
    isLoading: false,
    emailSent: false,
};
