import { reportTypes } from '../../utils/reports';
import {
    getBaseConfig,
    shelfAdMonDAUMetrics,
    shelfCustomDimensions,
    shelfDimensions,
    shelfEnrichmentDimensions,
    shelfMetrics,
    shelfTimeBreakdowns,
    unenrichedToggle,
} from './baseReportConfig';

import { reportDataSelectors } from '../../selectors/reports';
import { setActionType, setUnenriched } from '../../actions/reports';
import { getAdminModeEnabled } from '../../selectors/user';
import { TOUR_LINK_IDS } from '../../utils/consts';

const ActionButton = 'ActionButton';

const type = reportTypes.adMonetization;
const path = '/react/admonetization';
const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.AD_MONETIZATION');

export default {
    ...basicConfig,
    fieldWarnings: {
        ...basicConfig.fieldWarnings,
        arpdau: {
            msg: 'STATIC.PAGES.REPORTS.ADMON_DAU_WARNING',
        },
        dau: {
            msg: 'STATIC.PAGES.REPORTS.ADMON_DAU_WARNING',
        },
    },
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/360022067552-Setting-Up-Ad-Monetization-Reporting',
            tourId: TOUR_LINK_IDS.adMonetization,
        },
        rightComponents: [
            ...basicConfig.header.rightComponents,
            {
                id: 'unEnriched',
                componentClass: ActionButton,
                props: {
                    text: 'STATIC.BUTTONS.UNENRICHED',
                    primary: false,
                },
                selector: state => {
                    const adminMode = getAdminModeEnabled(state);
                    const selected = reportDataSelectors[type](state);
                    return {
                        containerStyle: {
                            display: adminMode ? 'inline-block' : 'none',
                            backgroundColor: selected.unenriched ? '#FFFFFF' : '#00acaf',
                            borderColor: selected.unenriched ? '#00acaf' : '#FFFFFF',
                            color: selected.unenriched ? '#00acaf' : '#FFFFFF',
                            marginRight: '10px',
                        },
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => setActionType(setUnenriched(), type),
                    },
                ],
            },
        ],
    },
    shelf: [
        shelfDimensions,
        shelfCustomDimensions,
        shelfEnrichmentDimensions,
        shelfMetrics,
        shelfAdMonDAUMetrics,
        shelfTimeBreakdowns,
        unenrichedToggle,
    ],
    path,
};
