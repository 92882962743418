import React from 'react';
import ColumnSchema from '../../../resources/svg/dashboards/schemas/column.svg';
import BarSchema from '../../../resources/svg/dashboards/schemas/bar.svg';
import StackedSchema from '../../../resources/svg/dashboards/schemas/stacked.svg';
import LineSchema from '../../../resources/svg/dashboards/schemas/line.svg';
import PieSchema from '../../../resources/svg/dashboards/schemas/pie.svg';
import PivotSchema from '../../../resources/svg/dashboards/schemas/pivot.svg';
import TableSchema from '../../../resources/svg/dashboards/schemas/table.svg';
import HeatMapSchema from '../../../resources/svg/dashboards/schemas/heatmap.svg';
import MetricCardsEmptyState from '../../../customDashboards/metricCardsEmptyState';

export const WIDGET_TYPE_TO_SCHEMA = {
    column: <ColumnSchema />,
    bar: <BarSchema />,
    stacked: <StackedSchema />,
    line: <LineSchema />,
    pie: <PieSchema />,
    pivot: <PivotSchema />,
    table: <TableSchema />,
    multiChart: <ColumnSchema />,
    variwide: <ColumnSchema />,
    bubble: <LineSchema />,
    heatMapTable: <HeatMapSchema />,
};

export const ACTION_TYPES = {
    TOGGLE_MENU: 'toggle_menu',
    CLONE: 'clone',
    DELETE: 'delete',
    EXPORT: 'export',
};

export const WIDGET_TYPE_TO_EMPTY_STATE_COMPONENT = {
    metricCards: MetricCardsEmptyState,
};