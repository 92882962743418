import API from '../services/api';

let instance = null;
const api = new API();

const URL_ORGANIZATION_IMPERSONATE = 'api/organizations/impersonate';

export default class PartnersAPI {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    getOrganizationsList() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORGANIZATION_IMPERSONATE, {
                method: 'GET',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject();
                        return;
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    impersonateOrg(orgId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_ORGANIZATION_IMPERSONATE, {
                method: 'POST',
                credentials: 'include',
                unique: true,
                body: {
                    org_id: orgId,
                },
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject();
                        return;
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }
}
