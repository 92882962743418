import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../styles.css';

const FieldsAvailabilityTableHead = ({ headerTitles }) => {
    return (
        <thead>
            <tr>
                {headerTitles.map(title => {
                    return (
                        <th key={title}>
                            <div className={css.tableHeaderContainer}>
                                <div className={css.tableHeader}>{title}</div>
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

FieldsAvailabilityTableHead.propTypes = {
    headerTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FieldsAvailabilityTableHead;
