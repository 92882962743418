import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import css from './TableCellImgLabelWithAgency.css';
import AgencyIcon from '../../../../dashboard/img/briefcase.svg';
import TableCellImgLabel from './TableCellImgLabel';

const TableCellImgLabelWithAgency = ({ searchText, agencyName, ...props }) => {
    const subTextElement = !agencyName ? null : (
        <div key={agencyName} className={css.container}>
            <AgencyIcon className={css.agencyIcon} />
            <Highlighter
                highlightClassName={css.highlighted}
                searchWords={[searchText]}
                textToHighlight={agencyName || ''}
                autoEscape
                className={css.ellipsis}
            />
        </div>
    );

    return <TableCellImgLabel searchText={searchText} subTextElements={[subTextElement]} {...props} />;
};

TableCellImgLabelWithAgency.propsTypes = {
    searchText: PropTypes.string,
    externalOrgName: PropTypes.string,
    imgStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    imgSrc: PropTypes.string,
    searchWords: PropTypes.string,
    markCell: PropTypes.bool,
    bottomMarkCell: PropTypes.bool,
    useDefaultEmptyImg: PropTypes.bool,
    ImgComponent: PropTypes.object,
    reverseOrder: PropTypes.bool,
    labelsClassName: PropTypes.object,
};

TableCellImgLabelWithAgency.defaultProps = {
    useDefaultEmptyImg: true,
    reverseOrder: false,
    labelsStyle: {},
};

export default TableCellImgLabelWithAgency;
