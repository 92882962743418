import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import pluralize from 'pluralize';
import { getAppsListAutoSuggestions, getAttributionAppsList } from 'selectors/apps';
import { getAdminModeEnabled, getUserData } from 'selectors/user';
import { getEmptyStateOrgData } from 'selectors/onboarding';
import { getFeaturePermissions } from 'selectors/permissions';
import {
    getCustomEventWarnings,
    getEventsWarningMessage,
    getTotalUanEvents,
    getUansExtendedList,
    MAX_EVENTS_DEFAULT,
    isMatchByGroupType,
    getEventsCapacity,
    formatAppsListForSuggestions,
} from '../utils/events';
import { EVENT_SLOT_GROUP_TYPE } from '../utils/consts';
import {
    parseCalculatedMetricsOptions,
    getCustomEventsGroupTitle,
    isNewEvent,
    NEW_EVENT_ID,
    getEventSlotGroupTypes,
    getEventSlotGroupType,
    isOrgUsingSkanReportingFlag,
    splitEventsByTypes,
} from './utils';

const getCustomEvents = state => {
    return state.events.customEvents;
};

const getCustomEventsLoaded = state => {
    return state.events.customEventsLoaded;
};

const getCustomEventsRelatedReports = state => {
    return state.events.customEventsRelatedReports;
};

const getCustomEventsMap = state => {
    return state.events.customEventsMap;
};
const getUanEvents = state => {
    return state.events.uanEvents;
};
const getPublicIDs = state => {
    return state.events.publicIDs;
};
const getCalculatedMetricsOptions = state => {
    return state.events.calculatedMetricsOptions;
};
const getExpandedEvents = state => {
    return state.events.expandedEvents;
};
const getSuggestionsPerUanId = state => {
    return state.events.suggestions;
};
const getBackupEvent = state => {
    return state.events.backupEvent;
};
const getSaving = state => {
    return state.events.saving;
};
const getSaveError = state => {
    return state.events.saveError;
};
const getEditOptions = state => {
    return state.events.editOptions;
};
const getSelectedAppId = state => {
    return state.events.selectedAppId;
};
const getActiveGroupType = state => {
    return state.events.activeGroupType;
};
const getPageLoaded = state => {
    return state.events.pageLoaded;
};

const getAutoCompletePlaceholder = (allAppsChecked, appsList) => {
    return allAppsChecked
        ? `${appsList.length} ${pluralize('Apps', appsList.length)}`
        : 'STATIC.PAGES.CUSTOM_EVENTS.APP_SELECTION_AUTOCOMPLETE_PLACEHOLDER';
};

const isAllAppsChecked = editOptions => editOptions.find(option => option.id === 'all').checked;

const getCustomEventsPerApp = (events, appsList, selectedAppId) => {
    if (!selectedAppId) {
        return [];
    }
    const selectedApp = appsList.find(app => app.app_id === selectedAppId);
    const allowedSiteIds = selectedApp.sites.map(site => site.site_public_id);
    return events.filter(customEvent => {
        if (!isNewEvent(customEvent) && !customEvent.apps.length) {
            return false;
        }
        const configuredSites = customEvent.apps.map(app => app.public_id);
        const intersect = intersection(configuredSites, allowedSiteIds);
        return !!intersect.length || isNewEvent(customEvent);
    });
};

/*
    The Standard Events layout should be active for Standard-Unified orgs and only for global cohort events.
 */
export const isStandardEventsLayoutActive = (activeGroupType, isOrgEnterprise, allAppsChecked) => {
    return (
        !isOrgEnterprise &&
        [EVENT_SLOT_GROUP_TYPE.BASIC, EVENT_SLOT_GROUP_TYPE.COHORT].includes(activeGroupType) &&
        allAppsChecked
    );
};

/*
    We want to hide the top right add button in two cases:
    1. The SKAN events tab.
    2. Standard Events layout is active.
*/
export const shouldHideAddButton = (activeGroupType, standardEventsLayoutActive) => {
    return activeGroupType === EVENT_SLOT_GROUP_TYPE.SKAN || standardEventsLayoutActive;
};

/*
    For Standard Unified customers only, we want to show the cohort events view in separate sections.
    The sections are separated by event types (standard, built-in and custom).
 */
const getEventSections = (events, standardEventsLayoutActive) => {
    return standardEventsLayoutActive ? splitEventsByTypes(events) : undefined;
};

const getSitePublicIds = createSelector(
    [getSelectedAppId, getAttributionAppsList, getEditOptions],
    (selectedAppId, appsList, editOptions) => {
        const allAppsChecked = isAllAppsChecked(editOptions);
        if (allAppsChecked) {
            return [];
        }
        const selectedApp = appsList.find(app => app.app_id === selectedAppId);
        return selectedApp.sites.map(site => ({ public_id: site.site_public_id, display_name: site.site_public_id }));
    }
);

const getEmptyStateMessage = createSelector(
    [getUanEvents, getSelectedAppId, getEditOptions, getAttributionAppsList, getActiveGroupType],
    (uans, selectedAppId, editOptions, appsList, activeGroupType) => {
        const allAppsChecked = isAllAppsChecked(editOptions);
        const uanIds = Object.keys(uans).filter(uanId => {
            return isMatchByGroupType(uans[uanId], activeGroupType);
        });

        const uanEventsCount = uanIds.reduce((total, uanId) => {
            return total + uans[uanId].events.length;
        }, 0);

        if (!uanIds.length || !uanEventsCount) {
            return {
                header: 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.NO_UAN_EVENTS',
                subHeader: 'STATIC.PAGES.CUSTOM_EVENTS.EVENT_DATA_NOTE',
                mainButtonDisabled: true,
            };
        }

        if (!allAppsChecked && selectedAppId) {
            return {
                header: 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.NO_CUSTOM_EVENTS_FOR_APP',
                subHeader: `(${appsList.find(app => app.app_id === selectedAppId).name})`,
                headerTextReplace: {},
                mainButtonDisabled: false,
            };
        }

        if (!allAppsChecked && !selectedAppId && activeGroupType !== EVENT_SLOT_GROUP_TYPE.CONVERSION) {
            return {
                header: 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.NO_APP_SELECTED',
                subHeader: '',
                mainButtonDisabled: true,
                icon: 'happyPage',
            };
        }

        if (activeGroupType === EVENT_SLOT_GROUP_TYPE.SKAN) {
            return {
                header: 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.NO_SKAN_CUSTOM_EVENTS',
                subHeader: '',
                mainButtonDisabled: false,
                icon: 'happyPage',
            };
        }

        return {
            header: 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.NO_CUSTOM_EVENTS',
            subHeader: '',
            mainButtonDisabled: false,
        };
    }
);

const getCustomEventsPage = createSelector(
    [
        getCustomEvents,
        getCustomEventsRelatedReports,
        getCustomEventsMap,
        getExpandedEvents,
        getEditOptions,
        getAppsListAutoSuggestions,
        getAttributionAppsList,
        getSelectedAppId,
        getPageLoaded,
        getEmptyStateMessage,
        getUserData,
        getActiveGroupType,
        getEmptyStateOrgData,
        getCustomEventsLoaded,
    ],
    (
        customEvents,
        customEventsRelatedReports,
        customEventsMap,
        expandedEvents,
        editOptions,
        appsListForSuggestions,
        appsList,
        selectedAppId,
        pageLoaded,
        emptyState,
        userData,
        activeGroupType,
        emptyStateData,
        customEventsLoaded
    ) => {
        const allAppsChecked = isAllAppsChecked(editOptions);
        let events = customEvents.map(eventId => {
            return customEventsMap[eventId];
        });

        const allEventSlots = events;

        if (activeGroupType) {
            events = events.filter(event => {
                return getEventSlotGroupType(event) === activeGroupType || isNewEvent(event);
            });
        }

        const maxEvents = userData.max_event_slots || MAX_EVENTS_DEFAULT;
        let globalEvents = events.filter(event => !event.apps.length);

        if (allAppsChecked || activeGroupType === EVENT_SLOT_GROUP_TYPE.CONVERSION) {
            events = globalEvents;
            globalEvents = [];
        } else {
            events = getCustomEventsPerApp(events, appsList, selectedAppId);
            globalEvents = globalEvents.filter(event => !isNewEvent(event));
        }

        const eventSlotsCapacity = getEventsCapacity(maxEvents, allEventSlots, appsList, allAppsChecked, selectedAppId);
        const maxEventsReached =
            activeGroupType !== EVENT_SLOT_GROUP_TYPE.CONVERSION && eventSlotsCapacity / maxEvents >= 1;

        const warningMessage =
            pageLoaded &&
            activeGroupType !== EVENT_SLOT_GROUP_TYPE.SKAN &&
            activeGroupType !== EVENT_SLOT_GROUP_TYPE.CONVERSION
                ? getEventsWarningMessage(allAppsChecked, eventSlotsCapacity, maxEvents)
                : '';

        const sortedEventSlotAnalysisType = (userData.event_slot_analysis_types || []).sort(type => {
            return type === EVENT_SLOT_GROUP_TYPE.COHORT ? -1 : 0;
        });
        const isOrgUsingSkanReporting = isOrgUsingSkanReportingFlag(userData.feature_flags);

        const isOrgEnterprise = userData.organization_tier === 'ENTERPRISE';

        const standardEventsLayoutActive = isStandardEventsLayoutActive(
            activeGroupType,
            isOrgEnterprise,
            allAppsChecked
        );

        const hideAddButton = shouldHideAddButton(activeGroupType, standardEventsLayoutActive);

        const eventSections = getEventSections(events, standardEventsLayoutActive);

        const formattedAppsSuggestions = formatAppsListForSuggestions(appsListForSuggestions, allEventSlots, appsList);
        return {
            customEvents: events,
            globalEvents,
            customEventsRelatedReports,
            newButtonDisabled:
                !pageLoaded ||
                expandedEvents.includes(NEW_EVENT_ID) || // current edited event is a new event
                emptyState.mainButtonDisabled || // empty state indicates the button should be disabled (no uan events)
                (!allAppsChecked && !selectedAppId && activeGroupType !== EVENT_SLOT_GROUP_TYPE.CONVERSION), // specific app events selection but no app was selected yet
            editOptions,
            appsList: allAppsChecked ? [] : formattedAppsSuggestions,
            autoCompleteConfig: {
                disabled: allAppsChecked,
                placeholder: getAutoCompletePlaceholder(allAppsChecked, appsListForSuggestions),
            },
            pageLoaded,
            emptyState,
            warningMessage,
            maxEvents,
            maxAppEvents: eventSlotsCapacity,
            customEventsGroupTitle: getCustomEventsGroupTitle(allAppsChecked, activeGroupType),
            activeGroupType,
            allAppsChecked,
            eventSlotGroupTypes: getEventSlotGroupTypes(
                sortedEventSlotAnalysisType,
                userData.is_unified,
                isOrgUsingSkanReporting
            ),
            emptyStateData,
            customEventsLoaded,
            eventSections,
            hideAddButton,
            maxEventsReached,
            standardEventsLayoutActive,
            selectedAppId,
        };
    }
);

const getCustomEvent = eventId => {
    return createSelector([getCustomEventsMap], customEventsMap => {
        return customEventsMap[eventId];
    });
};

const getUansListForCustomEvent = eventId => {
    const getCustomEventSelector = getCustomEvent(eventId);
    return createSelector(
        [
            getUanEvents,
            getSuggestionsPerUanId,
            getCustomEventSelector,
            getAttributionAppsList,
            getSelectedAppId,
            getEditOptions,
            getActiveGroupType,
        ],
        (uanEvents, suggestionsPerUanId, customEvent, appsList, selectedAppId, editOptions, activeGroupType) => {
            const allAppsChecked = isAllAppsChecked(editOptions);
            let selectedApp = null;
            if (!allAppsChecked && selectedAppId) {
                selectedApp = appsList.find(app => app.app_id === selectedAppId);
            }
            return getUansExtendedList(
                uanEvents,
                suggestionsPerUanId,
                customEvent,
                selectedApp,
                appsList,
                activeGroupType
            );
        }
    );
};

const getCustomEventEditor = eventId => {
    const getCustomEventSelector = getCustomEvent(eventId);
    const getUansListSelector = getUansListForCustomEvent(eventId);
    return createSelector(
        [
            getCustomEventSelector,
            getCalculatedMetricsOptions,
            getExpandedEvents,
            getBackupEvent,
            getSaving,
            getSaveError,
            getUansListSelector,
            getAdminModeEnabled,
            getActiveGroupType,
            getFeaturePermissions,
        ],
        (
            customEvent,
            calculatedMetricsOptionsStore,
            expandedEvents,
            backupEvent,
            saving,
            saveError,
            uans,
            adminModeEnabled,
            activeGroupType,
            permissionData
        ) => {
            const retEvent = {
                ...customEvent,
                expanded: expandedEvents.includes(customEvent.id),
                tags: {},
            };

            Object.keys(retEvent.events_by_uan).forEach(uanId => {
                const currUan = uans.find(uan => uan.uan_id.toString() === uanId);

                if (!currUan) {
                    return;
                }

                const currUanEvents = flatten(
                    currUan.events.map(event => {
                        if (event.options) {
                            return event.options.map(ie => ie.id);
                        }
                        return event.id;
                    })
                );

                retEvent.tags[uanId] = retEvent.events_by_uan[uanId]
                    .filter(eventItem => {
                        return currUanEvents.includes(eventItem.id);
                    })
                    .map(tag => {
                        // Allow tags delete only for conversion tab.
                        const allowDelete = customEvent.analysis_type === EVENT_SLOT_GROUP_TYPE.CONVERSION;
                        return { ...tag, deleteEnabled: tag.deleteEnabled || allowDelete };
                    });
            });
            const warnings = getCustomEventWarnings(retEvent, backupEvent);
            const totalUanEvents = getTotalUanEvents(customEvent, 'is_unique');
            const uniqueToggleDisabled = !!totalUanEvents.length;
            // save should be disabled in case no uan events selected / saving / custom event values haven't changed
            const saveDisabled =
                !totalUanEvents.length || saving || JSON.stringify(customEvent) === JSON.stringify(backupEvent);

            const readOnly = permissionData.permission === permissionData.typesMap.read;

            return {
                customEvent: retEvent,
                uans,
                calculatedMetricsOptions: parseCalculatedMetricsOptions(
                    calculatedMetricsOptionsStore,
                    retEvent,
                    activeGroupType
                ),
                warnings,
                saving,
                saveError,
                uniqueToggleDisabled,
                showSlotNumber: adminModeEnabled,
                saveDisabled,
                activeGroupType,
                permissionData,
                readOnly,
            };
        }
    );
};

const getToggleEditorClickedData = createSelector(
    [getBackupEvent, getCustomEventsMap, getExpandedEvents],
    (backupEvent, customEventsMap, expandedEvents) => {
        return {
            backupEvent,
            expandedEvents: expandedEvents.map(eventId => customEventsMap[eventId]),
        };
    }
);

export {
    getCustomEvents,
    getUanEvents,
    getPublicIDs,
    getCalculatedMetricsOptions,
    getCustomEventsPage,
    getCustomEventEditor,
    getSitePublicIds,
    getToggleEditorClickedData,
    getCustomEventsMap,
    getPageLoaded,
    getActiveGroupType,
    getBackupEvent,
};
