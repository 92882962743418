import { connect } from 'react-redux';
import GeneralErrorDialog from './generalErrorDialog';
import { clearGeneralError } from './actions';
import { getGeneralError, getIsLoading } from './selector';

const mapStateToProps = state => {
    return {
        errorMsg: getGeneralError(state),
        isLoading: getIsLoading(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearErrorMsg: () => {
            dispatch(clearGeneralError());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralErrorDialog);
