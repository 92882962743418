import React from 'react';

export default function AppsTaskContentBody() {
    return (
        <div>
            <p>
                <strong>What:</strong><span> Add tags to your creative assets to describe and segment them.</span>
            </p>
            <p>
                <strong>Why:</strong><span> With tagging you can evaluate not just different creative assets, but different </span><em><span
            >types</span></em><span> or </span><em><span
            >categories</span></em><span> of creatives. For example, tag your creative images by the product they feature (e.g. “shoes” vs “bags”), and see which category has better performance metrics. Or tag creatives by their size, color, seasonal theme, or illustration style, to find the most effective features. You can add multiple tags to the same creative.</span>
            </p>
            <p>
                <strong>Questions? </strong>
                <a href="https://support.singular.net/hc/en-us/articles/115003236386-Introduction-to-Creative-Tagging" target="_blank">Learn more about Creative Tagging</a><span
            >.</span>
            </p>
        </div>
    );
}
