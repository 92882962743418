import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Label, HelpMenu } from '../../../widgets';
import css from './StickyPageHeader.css';

const StickyPageHeader = ({ title, helpMenuContent, children }) => {
    return (
        <>
            <div className={classnames(css.titleContainer, { [css.onlyTitleContainer]: !children })}>
                <Label text={title} type="header" />
                <HelpMenu {...helpMenuContent} />
            </div>
            {children && <div className={css.stickyChildrenContainer}>{children}</div>}
        </>
    );
};

StickyPageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    helpMenuContent: PropTypes.shape({ faqLink: PropTypes.string }).isRequired,
    children: PropTypes.element,
};

StickyPageHeader.defaultProps = {
    children: null,
};

export default StickyPageHeader;
