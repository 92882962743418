import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Tooltip from './Tooltip';
import css from './Tabs.css';
import { tabsShape } from './types';

function Tabs({
    tabs,
    selected,
    containerStyle,
    dividerStyle,
    onClick,
    containerClass,
    tabClass,
    activeTabClass,
    tabLabelContainerClass,
    dataTestId,
}) {
    const handleClick = (e, tabId) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(tabId);
    };

    const getTab = tab => {
        const tabElement = (
            <button
                type="button"
                className={classNames(tabClass, css.tab, {
                    [css.disabled]: tab.disable,
                    [css.activeTab]: tab.id === selected,
                    [activeTabClass]: activeTabClass && tab.id === selected,
                })}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(e, tab.id);
                }}
                key={tab.id}
                disabled={tab.disable}
            >
                <div style={tab.style} className={classNames(tabLabelContainerClass, css.tabLabelContainer)}>
                    {tab.icon && <tab.icon className={css.tabIcon} />}
                    <div className={classNames(css.tabLabel, tab.tabLabelClass)}>
                        <Translate id={tab.label} data={tab.labelData} />
                    </div>
                </div>
            </button>
        );

        return tab.tooltipText ? (
            <Tooltip key={tab.id} className={css.toolTip} titleTranslationKey={tab.tooltipText}>
                {tabElement}
            </Tooltip>
        ) : (
            tabElement
        );
    };

    return (
        tabs.length > 0 && (
            <div
                className={classNames(css.container, containerClass)}
                style={{
                    ...containerStyle,
                }}
                data-testid={dataTestId}
            >
                {tabs.map(getTab)}
                <div className={css.divider} style={dividerStyle} />
            </div>
        )
    );
}

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape(tabsShape)),
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    containerClass: PropTypes.string,
    tabClass: PropTypes.string,
    activeTabClass: PropTypes.string,
    tabLabelContainerClass: PropTypes.string,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    dividerStyle: PropTypes.objectOf(PropTypes.any),
    dataTestId: PropTypes.string,
};

Tabs.defaultProps = {
    tabs: [],
    selected: 'regular',
    onClick: () => {},
    containerClass: '',
    tabClass: '',
    activeTabClass: '',
    tabLabelContainerClass: '',
    containerStyle: {},
    dividerStyle: {},
    dataTestId: undefined,
};

export default Tabs;
