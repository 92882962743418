import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/widgets/Label';
import css from './SkanTimersRadioButtons.css';
import { QuestionMark } from '../../../components/widgets';
import RadioButtonsGroup from '../../../components/widgets/RadioButtonsGroup';
import {
    CREATE_MODE,
    DEFAULT_MEASUREMENT_PERIOD,
    EDIT_MODE,
    SKAN4_TIMERS_MEASUREMENT_PERIOD,
    SKAN_COMPATIBILITY_MODE,
} from '../../consts';
import GeneralPopup, { PopupTypes } from '../../../components/partials/GeneralPopup';

const skan4Timers = {
    id: 0,
    label: 'STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.SKAN_4',
    description: 'STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.SKAN_4_DESCRIPTION',
    measurementPeriod: SKAN4_TIMERS_MEASUREMENT_PERIOD,
    compatibilityMode: SKAN_COMPATIBILITY_MODE.SKAN_4,
};

const skan3Compatible = {
    id: 1,
    label: 'STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.SKAN_3',
    description: 'STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.SKAN_3_DESCRIPTION',
    measurementPeriod: DEFAULT_MEASUREMENT_PERIOD,
    compatibilityMode: SKAN_COMPATIBILITY_MODE.SKAN_3,
};

function SkanTimersRadioButtons({ onChange, measurementPeriod, mode, compatibilityMode }) {
    const originalMeasurementPeriod = useRef(measurementPeriod);

    const [skanTimersItems, setSkanTimersItems] = useState([skan4Timers, skan3Compatible]);
    const [showTimersPopup, setShowTimersPopup] = useState(false);

    useEffect(() => {
        setSkanTimersItems(
            skanTimersItems.map(item => {
                return { ...item, checked: item.compatibilityMode === compatibilityMode };
            })
        );
    }, [measurementPeriod, compatibilityMode]);

    const handleRadioButtonClicked = checkedItem => {
        const { measurementPeriod: itemMeasurementPeriod, compatibilityMode: itemCompatibilityMode } = checkedItem;

        if (
            mode === EDIT_MODE &&
            itemMeasurementPeriod === SKAN4_TIMERS_MEASUREMENT_PERIOD &&
            itemMeasurementPeriod !== originalMeasurementPeriod.current
        ) {
            setShowTimersPopup(true);
        } else {
            onChange({
                measurementPeriod: itemMeasurementPeriod,
                compatibilityMode: itemCompatibilityMode,
            });
        }
    };

    const handleOnAcceptTransitionToSkan4Timers = () => {
        onChange({
            measurementPeriod: SKAN4_TIMERS_MEASUREMENT_PERIOD,
            compatibilityMode: SKAN_COMPATIBILITY_MODE.SKAN_4,
        });
        setShowTimersPopup(false);
    };

    const handleOnRejectTransitionToSkan4Timers = () => {
        setShowTimersPopup(false);
    };

    return (
        <div className={css.skanTimersTransitionBox}>
            <div className={css.skanTimersTransitionTitleBox}>
                <Label
                    text="STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.TITLE"
                    type="fieldLabel"
                    className={css.skanTimersTextTitle}
                />
                <QuestionMark
                    iconClassName={css.questionMark}
                    message="STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.TITLE_TOOLTIP"
                />
            </div>
            <RadioButtonsGroup
                radioItems={skanTimersItems}
                onChange={handleRadioButtonClicked}
                itemsClassName={css.skanTimersItemTitle}
                descriptionClassName={css.skanTimersItemDescription}
            />
            <GeneralPopup
                open={showTimersPopup}
                type={PopupTypes.WARNING_WITH_CANCEL}
                text="STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.POPUP.MESSAGE"
                acceptText="STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.SKAN_4_TIMERS.POPUP.ACCEPT_BUTTON_TEXT"
                onAccept={handleOnAcceptTransitionToSkan4Timers}
                onReject={handleOnRejectTransitionToSkan4Timers}
            />
        </div>
    );
}

SkanTimersRadioButtons.propTypes = {
    onChange: PropTypes.func,
    mode: PropTypes.string,
    measurementPeriod: PropTypes.oneOf([SKAN4_TIMERS_MEASUREMENT_PERIOD, DEFAULT_MEASUREMENT_PERIOD]),
    compatibilityMode: PropTypes.oneOf([
        SKAN_COMPATIBILITY_MODE.SKAN_4,
        SKAN_COMPATIBILITY_MODE.SKAN_3,
        SKAN_COMPATIBILITY_MODE.SKAN_3_ONLY,
    ]),
};

SkanTimersRadioButtons.defaultProps = {
    mode: CREATE_MODE,
    onChange: () => {},
    measurementPeriod: SKAN4_TIMERS_MEASUREMENT_PERIOD,
    compatibilityMode: null,
};

export default SkanTimersRadioButtons;
