import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { capitalize } from 'utils/general';
import css from './sdkSectionsWrapper.css';
import CodeBlock from '../../../../components/partials/CodeBlock';
import { useSDKCodeSnippetContext } from '../../codeSnippets/context/useSDKCodeSnippetContext';
import { SDK_KEY_PLACEHOLDER, SDK_TOKEN_PLACEHOLDER } from '../../codeSnippets/consts';
import { useSDKPageContext } from '../../../context/useSDKPageContext';
import { sendMixpanelEventSdkIntegration } from '../../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../consts';
import { useSDKPlanningContext } from '../../context/useSDKPlanningContext';

const defaultConfig = {
    showLineNumbers: true,
    ShowHideButtonTitle: 'Keys',
    showHeader: true,
    darkTheme: true,
    customStyle: {
        overflow: 'scroll',
        height: '600px',
    },
};

const { COPY_CODE, CODEBLOCK_THEME_TOGGLED, SHOW_KEYS, CODE_LANGUAGE_CHANGED } = SDK_INTEGRATION_MIXPANEL_EVENTS;

const SDKSectionsWrapper = ({ children, sectionKey }) => {
    const { selectedTargetPlatform } = useSDKPlanningContext();
    const { userSdkKey, userSdkToken } = useSDKPageContext();
    const {
        codeBlockDarkTheme,
        toggleCodeBlockTheme,
        codeSnippets,
        snippetsSwitcher,
        activeCodeSnippet,
        setActiveCodeSnippet,
        codeLanguage,
        setHideCodeSnippetPlaceholders,
        hideCodeSnippetPlaceholders,
        setCodeLanguage,
    } = useSDKCodeSnippetContext();

    const currentActiveCodeSnippet = codeSnippets[activeCodeSnippet];

    const [codeSnippetAttributes, setCodeSnippetAttributes] = useState(
        currentActiveCodeSnippet?.[codeLanguage].codeSnippetAttributes
    );
    const [rowHighlighterMapper, setRowHighlighterMapper] = useState(
        currentActiveCodeSnippet?.[codeLanguage].rowHighlighterMapper
    );

    const languageItems = useMemo(() => {
        if (Object.keys(currentActiveCodeSnippet || {}).length === 1) {
            return undefined;
        } else {
            return Object.keys(currentActiveCodeSnippet || {}).map(language => {
                return { codeLanguage: language, displayTabTitle: capitalize(language) };
            });
        }
    }, [codeSnippets, activeCodeSnippet]);

    const onLanguageChange = codeLanguageItem => {
        setCodeLanguage(codeLanguageItem.codeLanguage);
        sendMixpanelEventSdkIntegration(CODE_LANGUAGE_CHANGED, sectionKey, selectedTargetPlatform, {
            language: codeLanguageItem.codeLanguage,
        });
    };

    const onPlaceholdersVisibilityChange = visibility => {
        setHideCodeSnippetPlaceholders(visibility);
        sendMixpanelEventSdkIntegration(SHOW_KEYS, sectionKey, selectedTargetPlatform);
    };

    const handleToggleCodeBlockTheme = toggle => {
        toggleCodeBlockTheme(toggle);
        sendMixpanelEventSdkIntegration(CODEBLOCK_THEME_TOGGLED, sectionKey, selectedTargetPlatform);
    };

    useEffect(() => {
        const {
            rowHighlighterMapper: contextRowHighlighterMapper,
            codeSnippetAttributes: contextCodeSnippetAttributes,
        } = codeSnippets[activeCodeSnippet]?.[codeLanguage] || {};

        const { placeholders = {} } = contextCodeSnippetAttributes || {};

        const placeholdersToUpdate = { ...placeholders };
        if (placeholdersToUpdate && placeholdersToUpdate.hasOwnProperty(SDK_KEY_PLACEHOLDER)) {
            placeholdersToUpdate[SDK_KEY_PLACEHOLDER] = userSdkKey;
        }
        if (placeholdersToUpdate && placeholdersToUpdate.hasOwnProperty(SDK_TOKEN_PLACEHOLDER)) {
            placeholdersToUpdate[SDK_TOKEN_PLACEHOLDER] = userSdkToken;
        }

        setCodeSnippetAttributes({ ...contextCodeSnippetAttributes, placeholders: placeholdersToUpdate });
        setRowHighlighterMapper({ ...contextRowHighlighterMapper });
    }, [codeSnippets, userSdkToken, userSdkKey, activeCodeSnippet, codeLanguage]);

    useEffect(() => {
        if (!sectionKey) return;

        const switcher = snippetsSwitcher[sectionKey];
        if (!switcher || switcher === activeCodeSnippet) {
            return;
        }

        setActiveCodeSnippet(switcher);
    }, [sectionKey]);

    return (
        <div className={codeSnippetAttributes ? css.container : null}>
            <div
                className={classNames(codeSnippetAttributes ? [css.leftColumn, css.narrowLeftColumn] : css.leftColumn)}
            >
                {children && <div>{children}</div>}
            </div>
            {codeSnippetAttributes && (
                <div className={css.rightColumn}>
                    <CodeBlock
                        {...codeSnippetAttributes}
                        rowsHighlight={rowHighlighterMapper[sectionKey]}
                        {...defaultConfig}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        languageItems={languageItems}
                        handleOnLanguageChange={languageItems ? onLanguageChange : undefined}
                        hidePlaceholders={hideCodeSnippetPlaceholders}
                        onPlaceholdersVisibilityChange={onPlaceholdersVisibilityChange}
                        onCopyToClipboard={() =>
                            sendMixpanelEventSdkIntegration(COPY_CODE, sectionKey, selectedTargetPlatform)
                        }
                    />
                </div>
            )}
        </div>
    );
};

SDKSectionsWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    sectionKey: PropTypes.string,
};

SDKSectionsWrapper.defaultProps = {
    sectionKey: undefined,
};
export default SDKSectionsWrapper;
