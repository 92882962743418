import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { FETCH_ALL_FIELDS, UPDATE_FIELDS } from '../actions/fields';
import eventsSaga from './events';

import { updateAllFields, updateFieldsHandler } from './fields';
import { watchBookmarks } from './bookmarks';
import { watchUserActions } from './user';
import Cancelable from './Cancelable';
import * as pageSagas from './pages';
import pageConfigs from '../config';
import { setGeneralError } from '../appLoadingState/actions';

const cancelable = new Cancelable();

/** *************************** Subroutines ************************************/

let currentPage = null;

function* destroyPage(action) {
    if (currentPage) {
        if (action.payload.pathname !== currentPage.pathname) {
            cancelable.cancelAll();
        }
        const lastPageConfig = pageConfigs.find(pageConfig => pageConfig.path === currentPage.pathname);
        if (lastPageConfig && pageSagas[lastPageConfig.saga] && pageSagas[lastPageConfig.saga].destroy) {
            yield call(pageSagas[lastPageConfig.saga].destroy, currentPage);
        }
    }
}

function* pageChanges(action) {
    yield fork(destroyPage, action);
    currentPage = action.payload;
    const { pathname } = action.payload;
    const currPageConfig = pageConfigs.find(pageConfig => pageConfig.path === pathname);
    if (currPageConfig && pageSagas[currPageConfig.saga] && pageSagas[currPageConfig.saga].onload) {
        try {
            yield call(pageSagas[currPageConfig.saga].onload, action);
        } catch (e) {
            console.log(e);
            yield put(setGeneralError());
        }
    }
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/
/******************************************************************************/

function* watchFetchAllFields() {
    yield takeEvery(FETCH_ALL_FIELDS, updateAllFields);
}

function* watchUpdateFieldsInfo() {
    while (true) {
        const action = yield take(UPDATE_FIELDS);
        yield fork(updateFieldsHandler, ...action.args);
    }
}

function* watchPageChanges() {
    while (true) {
        const action = yield take(LOCATION_CHANGE);
        yield fork(pageChanges, action);
    }
}

function* root() {
    const watchers = [
        fork(watchPageChanges),
        fork(watchUpdateFieldsInfo),
        fork(eventsSaga),
        fork(watchBookmarks),
        fork(watchUserActions),
        fork(watchFetchAllFields),
    ];
    for (const page in pageSagas) {
        const pageSaga = pageSagas[page];
        if (pageSaga.watchers) {
            Array.prototype.push.apply(
                watchers,
                pageSaga.watchers.map(watcher => fork(watcher))
            );
        }
    }
    yield all(watchers);
}

export default root;
