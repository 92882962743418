import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import css from './TimePeriod.css';
import Datepicker from '../../widgets/Datepicker';
import { reportDataSelectors } from '../../../selectors/reports';
import { getUserData } from '../../../selectors/user';
import { getDaysDiff, getSecondDatePickerRanges } from '../../../utils/regional';
import { dateChangedGlobal, modeSelectedGeneric, setActionType } from '../../../actions/reports';
import WarningMessage from '../../widgets/WarningMessage';
import { COMPARE_MODE, REGULAR_MODE } from './consts';

export default function TimePeriod({ reportType }) {
    const [isCompare, setIsCompare] = useState(null);
    const [compareRanges, setCompareRanges] = useState();
    const [label, setLabel] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startDate2, setStartDate2] = useState();
    const [endDate2, setEndDate2] = useState();
    const [title, setTitle] = useState();
    const [compareTitle, setCompareTitle] = useState();
    const [showCompareWarning, setShowCompareWarning] = useState(false);
    const selected = useSelector(state => reportDataSelectors[reportType](state));
    const userData = useSelector(state => getUserData(state));

    const dispatch = useDispatch();

    const onApply = ({ label: chosenLabel, ...data }) => {
        setLabel(chosenLabel);
        dispatch(setActionType(dateChangedGlobal(reportType, data), reportType));
    };

    const onCompareApply = ({ label: chosenLabel, ...data }) => {
        const newData = {
            start_date_2: data.start_date,
            end_date_2: data.end_date,
        };
        dispatch(setActionType(dateChangedGlobal(reportType, newData), reportType));
    };

    const onClickCompare = () => {
        dispatch(modeSelectedGeneric(reportType, isCompare ? REGULAR_MODE : COMPARE_MODE));
        setIsCompare(!isCompare);
    };

    const containerStyle = {
        marginRight: 10,
    };

    useEffect(() => {
        if (selected) {
            const { reportDates } = selected;
            setStartDate(reportDates.start_date);
            setEndDate(reportDates.end_date);
            setStartDate2(reportDates.start_date_2);
            setEndDate2(reportDates.end_date_2);
            isCompare === null && setIsCompare(selected.mode === COMPARE_MODE);
        }
    }, [selected]);

    useEffect(() => {
        if (startDate && endDate && isCompare) {
            setCompareRanges(getSecondDatePickerRanges(startDate, endDate));
        }
    }, [startDate, endDate, isCompare]);

    useEffect(() => {
        if (startDate && endDate && startDate2 && endDate2) {
            setShowCompareWarning(
                isCompare && getDaysDiff(startDate, endDate, 0) !== getDaysDiff(startDate2, endDate2, 0)
            );
            setTitle(
                isCompare
                    ? ['STATIC.PAGES.REPORTS.DATEPICKER_PERIOD_TITLE', { daysPeriod: getDaysDiff(startDate, endDate) }]
                    : null
            );
            setCompareTitle(
                isCompare
                    ? [
                          'STATIC.PAGES.REPORTS.DATEPICKER_PREVIOUS_PERIOD_TITLE',
                          { daysPeriod: getDaysDiff(startDate2, endDate2) },
                      ]
                    : null
            );
        }
    }, [startDate, endDate, startDate2, endDate2, isCompare]);

    return (
        <div>
            <div
                className={classNames(css.timePeriodContainer, {
                    [css.warningMessage]: showCompareWarning,
                })}
            >
                <Datepicker
                    id="shelfDatepicker"
                    startDate={startDate}
                    endDate={endDate}
                    dateLimit={userData.report_date_limit?.max_limit}
                    containerStyle={containerStyle}
                    title={title}
                    buttonContainerClass={css.buttonContainer}
                    onApply={onApply}
                    label={isCompare ? '' : label}
                />
                {isCompare && (
                    <>
                        <span className={css.compareLabel}>VS</span>
                        <Datepicker
                            id="shelfDatepicker2"
                            ranges={compareRanges}
                            startDate={startDate2}
                            endDate={endDate2}
                            dateLimit={userData.report_date_limit?.max_limit}
                            containerStyle={containerStyle}
                            title={compareTitle}
                            buttonContainerClass={css.buttonContainer}
                            onApply={onCompareApply}
                        />
                    </>
                )}
                {isCompare ? (
                    <div className={css.compare} onClick={onClickCompare}>
                        <Translate id="STATIC.BUTTONS.REMOVE" />
                    </div>
                ) : (
                    <div className={css.compare} onClick={onClickCompare}>
                        + <Translate id="STATIC.BUTTONS.COMPARE_TWO_PERIODS" />
                    </div>
                )}
            </div>
            {showCompareWarning && (
                <WarningMessage
                    className={css.compareWarningMessage}
                    showIcon={false}
                    message="STATIC.PAGES.REPORTS.COMPARE_WARNING"
                />
            )}
        </div>
    );
}

TimePeriod.propTypes = {
    reportType: PropTypes.string,
};

TimePeriod.defaultProps = {
    reportType: '',
};
