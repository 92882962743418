import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import MuiSelect from './MuiSelect';

// since we are using menuPortalTargets to document body
// we need to set the style here and not under singularSelect ReactSelectOverrides.css
const menuStyle = {
    marginTop: 0,
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '0 0 2px 2px',
    borderTop: 'none',
    border: '1px solid #d3d3d3',
    zIndex: '99',
};

// we are using react-select at multiple places and want to do the below adjustments for all of those places
const Select = forwardRef((props, ref) => {
    const { styles, usePortal, useMaterialUI, ...otherProps } = props;

    const selectProps = {
        ...otherProps,
        // this fix situations when other components overlaps the options list (eg. sticky header)
        // it also makes the options list look better
        ...(usePortal ? { menuPortalTarget: document.body } : {}),
        styles: {
            ...styles,
            menu: base => ({ ...base, ...menuStyle }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menuList: base => ({ ...base, padding: 0 }),
        },
    };

    return useMaterialUI ? <MuiSelect {...selectProps} /> : <ReactSelect ref={ref} {...selectProps} />;
});

export default Select;

Select.propTypes = {
    styles: PropTypes.objectOf(PropTypes.any),
    usePortal: PropTypes.bool,
    useMaterialUI: PropTypes.bool,
};

Select.defaultProps = {
    styles: {},
    usePortal: false,
    useMaterialUI: false,
};
