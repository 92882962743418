import { OS, OSIcons } from '../../../utils/OS';

export const SDK_CONFIGURATION_PLATFORMS_STEP = 'SDK_CONFIGURATION_PLATFORMS_STEP';
export const SDK_CONFIGURATION_OTHER_SDKS_STEP = 'SDK_CONFIGURATION_OTHER_SDKS_STEP';
export const SDK_CONFIGURATION_PRIVACY_STEP = 'SDK_CONFIGURATION_PRIVACY_STEP';
export const SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP = 'SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP';
export const SDK_CONFIGURATION_MONETIZATION_STEP = 'SDK_CONFIGURATION_MONETIZATION_STEP';

export const SDK_CONFIGURATION_STEPS = [
    SDK_CONFIGURATION_PLATFORMS_STEP,
    SDK_CONFIGURATION_OTHER_SDKS_STEP,
    SDK_CONFIGURATION_PRIVACY_STEP,
    SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP,
    SDK_CONFIGURATION_MONETIZATION_STEP,
];

export const SDK_CONFIGURATION_PLATFORMS_STEP_INDEX = SDK_CONFIGURATION_STEPS.indexOf(SDK_CONFIGURATION_PLATFORMS_STEP);

export const NO_CATEGORY = 'NO_CATEGORY';
export const PLATFORM_CATEGORY = 'PLATFORM_CATEGORY';
export const OTHER_SDK_CATEGORY = 'OTHER_SDK_CATEGORY';
export const USER_PRIVACY_CATEGORY = 'USER_PRIVACY_CATEGORY';
export const CAMPAIGN_TYPES_CATEGORY = 'CAMPAIGN_TYPES_CATEGORY';
export const NETWORK_SPECIFIC_CATEGORY = 'NETWORK_SPECIFIC_CATEGORY';
export const APP_MONETIZATION_CATEGORY = 'APP_MONETIZATION_CATEGORY';
export const SINGULAR_TRACKING_LINKS_CATEGORY = 'SINGULAR_TRACKING_LINKS_CATEGORY';
export const SINGULAR_FEATURES_USAGE_CATEGORY = 'SINGULAR_FEATURES_USAGE_CATEGORY';

export const YOUR_PLATFORMS = 'YOUR_PLATFORMS';
export const IS_USING_OTHER_MMP = 'IS_USING_OTHER_MMP';
export const IS_USING_FIREBASE_SDK = 'IS_USING_FIREBASE_SDK';
export const IS_USING_META_SDK = 'IS_USING_META_SDK';
export const IS_TARGETING_CHILDREN = 'IS_TARGETING_CHILDREN';
export const IS_SHOWING_ATT_PROMPT = 'IS_SHOWING_ATT_PROMPT';
export const IS_RUNNING_SKAN_CAMPAIGNS = 'IS_RUNNING_SKAN_CAMPAIGNS';
export const IS_USING_DEEP_LINKING_TRACKING_LINKS = 'IS_USING_DEEP_LINKING_TRACKING_LINKS';
export const IS_RUNNING_RE_TARGETING = 'IS_RUNNING_RE_TARGETING';
export const IS_USING_TRACKING_LINKS_FOR_EMAIL_CAMPAIGNS = 'IS_USING_TRACKING_LINKS_FOR_EMAIL_CAMPAIGNS';
export const IS_USING_TRACKING_LINKS_FOR_SMS_CAMPAIGNS = 'IS_USING_TRACKING_LINKS_FOR_SMS_CAMPAIGNS';
export const IS_RUNNING_INFLUENCER_CAMPAIGNS = 'IS_RUNNING_INFLUENCER_CAMPAIGNS';
export const IS_RUNNING_APPLE_SEARCH_ADS_CAMPAIGNS = 'IS_RUNNING_APPLE_SEARCH_ADS_CAMPAIGNS';
export const IS_RUNNING_FACEBOOK_CAMPAIGNS = 'IS_RUNNING_FACEBOOK_CAMPAIGNS';
export const IS_RUNNING_GOOGLE_ADS_CAMPAIGNS = 'IS_RUNNING_GOOGLE_ADS_CAMPAIGNS';
export const IS_RUNNING_NETWORKS_WITH_TRACKING_LINKS = 'IS_RUNNING_NETWORKS_WITH_TRACKING_LINKS';
export const IS_APP_MONETIZING_SUBSCRIPTION_BASED = 'IS_APP_MONETIZING_SUBSCRIPTION_BASED';
export const IS_APP_MONETIZING_IN_APP_PURCHASE_BASED = 'IS_APP_MONETIZING_IN_APP_PURCHASE_BASED';
export const IS_AD_MONETIZATION_BASED = 'IS_AD_MONETIZATION_BASED';
export const SELECT_MEDIATION_PLATFORM = 'SELECT_MEDIATION_PLATFORM';
export const IS_USING_BI_POSTBACKS = 'IS_USING_BI_POSTBACKS';
export const IMPORT_DATA_FROM_CURRENT_MMP = 'IMPORT_DATA_FROM_CURRENT_MMP';

export const DROPDOWN_SELECTION_DATA_KEY = 'DropdownSelection';
export const IOS_DROPDOWN_SELECTION_DATA_KEY = 'iOSDropdownSelection';
export const ANDROID_DROPDOWN_SELECTION_DATA_KEY = 'AndroidDropdownSelection';
export const WEB_DROPDOWN_SELECTION_DATA_KEY = 'WebDropdownSelection';
export const PC_DROPDOWN_SELECTION_DATA_KEY = 'PCDropdownSelection';
export const QUESTION_ID_DATA_KEY = 'questionID';
export const QUESTION_ID_DATA_KEY_CHECKBOX = 'questionIDCheckbox';
export const DROPDOWN_OPTIONS_DATA_KEY = 'dropdownOptions';
export const ANSWER_CHOICES_DATA_KEY = 'answerChoices';
export const IS_PARENT_QUESTION_KEY = 'isParentQuestion';
export const ANSWER_TYPE_DATA_KEY = 'answerType';

export const QUESTIONS_METADATA = {
    [SDK_CONFIGURATION_OTHER_SDKS_STEP]: {
        [NO_CATEGORY]: [IS_USING_FIREBASE_SDK, IS_USING_META_SDK, IS_USING_OTHER_MMP, IMPORT_DATA_FROM_CURRENT_MMP],
    },
    [SDK_CONFIGURATION_PRIVACY_STEP]: {
        [NO_CATEGORY]: [IS_TARGETING_CHILDREN, IS_SHOWING_ATT_PROMPT],
    },
    [SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP]: {
        [CAMPAIGN_TYPES_CATEGORY]: [IS_RUNNING_SKAN_CAMPAIGNS, IS_RUNNING_RE_TARGETING],
        [SINGULAR_TRACKING_LINKS_CATEGORY]: [
            IS_USING_TRACKING_LINKS_FOR_EMAIL_CAMPAIGNS,
            IS_USING_TRACKING_LINKS_FOR_SMS_CAMPAIGNS,
            IS_RUNNING_INFLUENCER_CAMPAIGNS,
        ],
        [SINGULAR_FEATURES_USAGE_CATEGORY]: [IS_USING_DEEP_LINKING_TRACKING_LINKS, IS_USING_BI_POSTBACKS],
        [NETWORK_SPECIFIC_CATEGORY]: [
            IS_RUNNING_APPLE_SEARCH_ADS_CAMPAIGNS,
            IS_RUNNING_FACEBOOK_CAMPAIGNS,
            IS_RUNNING_GOOGLE_ADS_CAMPAIGNS,
            IS_RUNNING_NETWORKS_WITH_TRACKING_LINKS,
        ],
    },
    [SDK_CONFIGURATION_MONETIZATION_STEP]: {
        [APP_MONETIZATION_CATEGORY]: [
            IS_APP_MONETIZING_SUBSCRIPTION_BASED,
            IS_APP_MONETIZING_IN_APP_PURCHASE_BASED,
            IS_AD_MONETIZATION_BASED,
            SELECT_MEDIATION_PLATFORM,
        ],
    },
};

export const FREE_TIER_BLACKLIST_QUESTIONS = [IMPORT_DATA_FROM_CURRENT_MMP];

export const IOS_SPECIFIC_QUESTIONS = [
    IS_RUNNING_SKAN_CAMPAIGNS,
    IS_SHOWING_ATT_PROMPT,
    IS_RUNNING_APPLE_SEARCH_ADS_CAMPAIGNS,
];

export const SUMMARY_QUESTIONS_METADATA = {
    [PLATFORM_CATEGORY]: [YOUR_PLATFORMS],
    [OTHER_SDK_CATEGORY]: QUESTIONS_METADATA[SDK_CONFIGURATION_OTHER_SDKS_STEP][NO_CATEGORY],
    [USER_PRIVACY_CATEGORY]: QUESTIONS_METADATA[SDK_CONFIGURATION_PRIVACY_STEP][NO_CATEGORY],
    ...QUESTIONS_METADATA[SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP],
    ...QUESTIONS_METADATA[SDK_CONFIGURATION_MONETIZATION_STEP],
};

export const QUESTIONS_DEPENDENCIES = {
    [IMPORT_DATA_FROM_CURRENT_MMP]: IS_USING_OTHER_MMP,
    [SELECT_MEDIATION_PLATFORM]: IS_AD_MONETIZATION_BASED,
};

export const PARENT_QUESTIONS = Object.keys(QUESTIONS_DEPENDENCIES).reduce((accum, key) => {
    return { ...accum, [`${QUESTIONS_DEPENDENCIES[key]}`]: key };
}, {});

export const QUESTIONS_TOOLTIPS = {
    [IS_SHOWING_ATT_PROMPT]: 'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.IS_SHOWING_ATT_PROMPT_TOOLTIP',
    [IS_TARGETING_CHILDREN]: 'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.IS_TARGETING_CHILDREN_TOOLTIP',
    [IS_RUNNING_INFLUENCER_CAMPAIGNS]:
        'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.IS_RUNNING_INFLUENCER_CAMPAIGNS_TOOLTIP',
    [IS_USING_DEEP_LINKING_TRACKING_LINKS]:
        'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.IS_USING_DEEP_LINKING_TRACKING_LINKS_TOOLTIP',
    [IS_RUNNING_RE_TARGETING]: 'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.IS_RUNNING_RE_TARGETING_TOOLTIP',
    [APP_MONETIZATION_CATEGORY]: 'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.APP_MONETIZATION_CATEGORY_TOOLTIP',
    [IS_RUNNING_NETWORKS_WITH_TRACKING_LINKS]:
        'STATIC.PAGES.SDK_CONFIGURATION.QUESTIONS.IS_RUNNING_NETWORKS_WITH_TRACKING_LINKS_TOOLTIP',
};

export const PLATFORMS_CONFIG = {
    [OS.IOS]: {
        Icon: OSIcons[OS.IOS],
        name: OS.IOS,
        dropdownSelectionDataKey: IOS_DROPDOWN_SELECTION_DATA_KEY,
    },
    [OS.ANDROID]: {
        Icon: OSIcons[OS.ANDROID],
        name: OS.ANDROID,
        dropdownSelectionDataKey: ANDROID_DROPDOWN_SELECTION_DATA_KEY,
    },
    [OS.PC]: {
        Icon: OSIcons[OS.PC],
        name: OS.PC,
        dropdownSelectionDataKey: PC_DROPDOWN_SELECTION_DATA_KEY,
    },
    [OS.WEB]: {
        Icon: OSIcons[OS.WEB],
        name: OS.WEB,
        dropdownSelectionDataKey: WEB_DROPDOWN_SELECTION_DATA_KEY,
    },
};

export const ALL_PLATFORMS = Object.keys(PLATFORMS_CONFIG);

export const LOCAL_STORAGE_SDK_CONFIG_ANSWERS_KEY = `sdk_config_answers`;
export const LOCAL_STORAGE_SDK_CONFIG_PLATFORMS_KEY = `sdk_config_platforms`;
export const LOCAL_STORAGE_ACTIVE_INDEX_KEY = `sdk_config_active_step_index`;
export const SUBHEADER_TEXT_TABLE_METADATA = {
    name: 'subHeaderText',
    subHeader: true,
    cellProps: {
        colSpan: 3,
        tdStyle: {
            fontSize: '14px',
            fontWeight: 600,
            color: 'var(--gray800)',
            backgroundColor: 'var(--gray100)',
            padding: '7px 10px',
        },
    },
};

export const QUESTION_TEXT_TABLE_METADATA = {
    name: 'questionText',
    displayName: '',
    cellValues: [
        { propName: 'tooltipText', dataKey: 'tooltipText' },
        { propName: 'tdStyle', dataKey: 'tdStyle' },
    ],
};

export const QUESTION_TEXT_SUMMARY_TABLE_METADATA = {
    ...QUESTION_TEXT_TABLE_METADATA,
    cellProps: {
        tdStyle: {
            width: 695,
            backgroundColor: 'var(--white)',
            padding: '3px 30px',
        },
    },
};

//* mixpanel consts *//
export const SDK_CONFIGURATION_MIXPANEL_PREFIX = 'Plan Your Onboarding';

// params
export const IS_EDITED_MIXPANEL_PARAM = 'Edited';
export const PLATFORMS_ANSWER_MIXPANEL_PARAM = 'Platforms';
export const SKIP_ACCEPT_MIXPANEL_PARAM = 'Accept';
export const STEP_MIXPANEL_PARAM = 'Step';

// general buttons - event names
export const SKIP_MIXPANEL_EVENT_NAME = 'Skip Clicked';
export const EDIT_MIXPANEL_EVENT_NAME = 'Edit Clicked';
export const BACK_MIXPANEL_EVENT_NAME = 'Back Clicked';
