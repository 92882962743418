import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import css from './VerticalWizard.css';
import Label from './Label';

function VerticalWizard({ className, sections }) {
    const [activeSection, setActiveSection] = useState(null);
    const ActiveSectionElement = activeSection?.element;

    useEffect(() => {
        setActiveSection(sections?.[0]);
    }, [sections]);

    return (
        <div className={classNames(css.container, className)}>
            <div className={css.navBar}>
                <div className={css.line} />
                <div>
                    {sections.map((section, i) => (
                        <div
                            key={section.name}
                            className={classNames(css.navBarSection, { [css.active]: section === activeSection })}
                            onClick={() => setActiveSection(section)}
                        >
                            <div className={css.navBarNumber}>{i + 1}</div>
                            <div>
                                <Translate id={section.displayName} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {ActiveSectionElement ? (
                <div className={css.activeSection}>
                    <Label className={css.title} text={activeSection.displayName} type="shelfHeader" />
                    <ActiveSectionElement />
                </div>
            ) : null}
        </div>
    );
}

VerticalWizard.propTypes = {
    className: PropTypes.string,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            displayName: PropTypes.string,
            element: PropTypes.func,
        })
    ),
};

VerticalWizard.defaultProps = {
    className: '',
    sections: [],
};

export default VerticalWizard;
