import moment from 'moment';

export const WIZARD_TEST_DETAILS = 'TEST_DETAILS';
export const WIZARD_TEST_CHANNELS = 'TEST_CHANNELS';
export const WIZARD_TEST_PARAMETERS = 'TEST_PARAMETERS';
export const WIZARD_INTERNAL_DISTRIBUTION = 'INTERNAL_DISTRIBUTION';
export const WIZARD_OVERVIEW = 'OVERVIEW';
export const WIZARD_EVENTS = 'EVENTS';
export const WIZARD_DISTRIBUTION = 'DISTRIBUTION';

export const TEST_WIZARD_STEPS = [
    WIZARD_TEST_DETAILS,
    WIZARD_TEST_CHANNELS,
    WIZARD_TEST_PARAMETERS,
    WIZARD_INTERNAL_DISTRIBUTION,
    WIZARD_EVENTS,
    WIZARD_OVERVIEW,
];

export const TEST_WIZARD_STEPS_NESTED = {
    [WIZARD_DISTRIBUTION]: TEST_WIZARD_STEPS.indexOf(WIZARD_TEST_CHANNELS),
};

const DATE_RANGE_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_START_DATE = moment().format(DATE_RANGE_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
    .add(2, 'years')
    .format(DATE_RANGE_DATE_FORMAT);

export const EMPTY_TEST_CHANNEL = {
    name: '',
    label: 'Select a network',
    value: 80,
    expanded: true,
};

export const TEST_CHANNELS_LIMIT = 2;
export const EVENTS_LIMIT = 13;

export const DISTRIBUTION_CHANNEL_INIT_VALUE = 45;

export const DISABLED_EVENTS = Object.freeze([
    {
        value: '__SESSION__',
        label: 'Session',
        deleteEnabled: false,
    },
    {
        value: '__REVENUE__',
        label: 'Revenue',
        deleteEnabled: false,
    },
]);

export const FILTERED_EVENTS_NAMES = ['__SESSION__', '__SESSION__*', '__REVENUE__', '__REVENUE__*'];

export const EMPTY_TEST_SKELETON = {
    testName: '',
    description: '',
    appsite: null,
    segment: null,
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    isTestOngoing: false,
    isDraft: false,
    testChannels: [EMPTY_TEST_CHANNEL],
    internalDistribution: null,
    events: [],
};

export const INTERNAL_S3_KEY = 'internal_s3';
