class Logger {
    constructor(prefix = '') {
        this.prefix = prefix;
        ['error', 'log', 'warn', 'debug'].forEach(prop => {
            this[prop] = (...args) => {
                if (process.env.NODE_ENV === 'production') {
                    return;
                }
                console[prop].call(null, this.prefix, ...args);
            };
        });
    }
}

export default Logger;
