import React, { useMemo, useState, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import 'react-select/dist/react-select.css';
import classNames from 'classnames';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import css from './NewTagInput.css';
import TagInputSuggestion from './TagInputSuggestion';
import SelectMenuList from './SelectMenuList';
import SelectGroup from './SelectGroup';
import Select from './Select';

const ControlComponent = ({ selectProps, ...restProps }) => {
    const { headerIcon, headerText, isDisabled } = selectProps;
    return (
        <div className={css.tagInputControl}>
            {headerText && (
                <span className={classNames(css.headerContainer, { [css.isDisabled]: isDisabled })}>
                    <span className={css.headerIcon}>{headerIcon} </span>
                    <span className={css.headerText}>
                        <Translate id={headerText} />
                    </span>
                    <div className={css.verticalLine} />
                </span>
            )}
            <components.Control {...restProps} />
        </div>
    );
};

ControlComponent.propTypes = {
    selectProps: PropTypes.shape({
        headerIcon: PropTypes.element,
        headerText: PropTypes.string,
        isDisabled: PropTypes.string,
    }).isRequired,
};

function Tag({ data, removeProps, selectProps }) {
    const showDelete = data.deleteEnabled === true || data.deleteEnabled === undefined;

    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const title = showDelete ? '' : selectProps.tagLockAlt || '';

    return (
        <div className={css.tagContainer} title={title} onMouseDown={onMouseDown}>
            {data.icon && <div className={css.icon}>{data.icon}</div>}
            {data.subTitle && <div className={css.subTitle}>{data.subTitle}</div>}
            <div className={classNames(css.tagLabel, selectProps.tagLabelClass)}>{data.label}</div>
            {showDelete && (
                <div className={css.tagX} role="button" {...removeProps} tabIndex={0}>
                    <XIcon className={css.clearIcon} />
                </div>
            )}
            {!showDelete && <FontAwesomeIcon className={css.tagLock} icon={faLock} />}
        </div>
    );
}

Tag.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
    removeProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

function DraggableTag(props) {
    const { data, selectProps } = props;
    const SortableTag = SortableElement(Tag);
    return <SortableTag index={selectProps.value.findIndex((x) => x.value === data.value)} {...props} />;
}

DraggableTag.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

const DEFAULT_PLACEHOLDER = 'STATIC.PLACEHOLDERS.SELECT_FILTER';

function NewTagInput({
    tags,
    options,
    disabled,
    containerStyle,
    placeholder,
    tagLockAlt,
    error,
    creatable,
    openMenuOnClick,
    sortable,
    onChange,
    virtualScrolling,
    className,
    isMulti,
    isClearable,
    dropdownIndicatorStyles,
    placeholderClass,
    isSearchable,
    separator,
    tagLabelClass,
    headerIcon,
    headerText,
    onEditClick,
    onDeleteClick,
    hideSelectedOptions,
    tagsLimit,
    tagsLimitMessage,
    noOptionsMessage,
    dataTestId,
}) {
    const SelectClass = useMemo(() => {
        const selectClass = !creatable ? Select : Creatable;
        return sortable ? SortableContainer(selectClass) : selectClass;
    }, [creatable, sortable]);

    const sortableOptions = useMemo(() => {
        if (!sortable) {
            return {};
        }

        return {
            axis: 'xy',
            distance: 4,
            onSortEnd: ({ oldIndex, newIndex }) => {
                const newTags = [...tags];
                newTags.splice(newIndex, 0, newTags.splice(oldIndex, 1)[0]);
                onChange(newTags);
            },
            shouldCancelStart: () => tags.length <= 1,
            getHelperDimensions: ({ node }) => node.getBoundingClientRect(),
            helperClass: css.sortableHelper,
        };
    }, [tags, sortable, onChange]);

    const componentsOverride = { Option: TagInputSuggestion };
    const styles = { dropdownIndicator: dropdownIndicatorStyles };

    if (isMulti) {
        componentsOverride.MultiValue = sortable ? DraggableTag : Tag;
    }

    if (headerText) {
        componentsOverride.Control = ControlComponent;
    }

    if (virtualScrolling) {
        componentsOverride.MenuList = SelectMenuList;
        componentsOverride.Group = SelectGroup;
    }
    if (!separator) {
        componentsOverride.IndicatorSeparator = null;
    }

    // So component is always searchable - especially in single value mode
    const [input, _setInput] = useState('');
    const setInput = useCallback(val => _setInput(val), []);

    const onFocus = useCallback(() => {
        if (!isMulti && tags.length) {
            setInput(tags[0].label);
        }
    }, [isMulti, tags]);

    const filteredOptionsByInput = useMemo(() => {
        return {};
    }, [options]);

    const getFilteredOptionsCallback = (option, selectInput, result, currentGroup) => {
        const { label, subLabel = '' } = option;
        const optionValue = label.toLowerCase();
        const optionSubLabelValue = subLabel.toLowerCase();
        const labelSearchResult = optionValue.includes(selectInput.toLowerCase());
        const subLabelSearchResult = optionSubLabelValue.includes(selectInput.toLowerCase());

        if (labelSearchResult || subLabelSearchResult) {
            result.add(optionValue);

            if (currentGroup) {
                result.add(currentGroup);
            }
        }
    };

    // The function is getting an input value to filter by,
    // and return a set of all options and groups that includes the input value.
    const getFilteredOptions = selectInput => {
        const result = new Set();
        let currentGroup = null;

        options.forEach(option => {
            if (option.isDisabled) {
                currentGroup = option.label.toLowerCase();
                return;
            }
            if (option.options) {
                const groupOptions = option.options;
                groupOptions.forEach(groupOption => {
                    getFilteredOptionsCallback(groupOption, selectInput, result, currentGroup)
                });
            }

            getFilteredOptionsCallback(option, selectInput, result, currentGroup);
        });

        return result;
    };

    // A custom filter method for react-select.
    const filterOptions = (option, selectInput) => {
        if (!selectInput) {
            return true;
        }

        if (!filteredOptionsByInput[selectInput]) {
            filteredOptionsByInput[selectInput] = getFilteredOptions(selectInput);
        }

        return filteredOptionsByInput[selectInput].has(option.label.toLowerCase());
    };

    return (
        <div
            className={`${className || ''} ${css.container} ${disabled ? css.disabled : ''} fs-ignore-rage-clicks`}
            style={{ ...containerStyle }}
        >
            <SelectClass
                dataTestId={dataTestId}
                options={!tagsLimit || tags.length < tagsLimit ? options : []}
                noOptionsMessage={() => {
                    return tags.length === tagsLimit ? tagsLimitMessage : noOptionsMessage;
                }}
                {...sortableOptions}
                hideSelectedOptions={hideSelectedOptions}
                isClearable={isClearable}
                isDisabled={disabled}
                isMulti={isMulti}
                onChange={onChange}
                className={classNames(css.singularSelect, placeholderClass, error && css.error)}
                escapeClearsValue={false}
                classNamePrefix="Select"
                components={componentsOverride}
                tagLockAlt={tagLockAlt}
                isSearchable={isSearchable}
                openMenuOnClick={openMenuOnClick}
                styles={styles}
                // Allow for single valued input to always be searchable
                // https://stackoverflow.com/questions/64298547/dont-clear-input-on-select-using-react-select
                placeholder={
                    isMulti || !tags || placeholder !== DEFAULT_PLACEHOLDER ? (
                        <Translate id={placeholder} />
                    ) : (
                        tags.label
                    )
                }
                value={tags}
                inputValue={isMulti ? undefined : input} // allows you continue where you left off
                onInputChange={isMulti ? undefined : setInput} // allows for actually typing
                onFocus={isMulti ? undefined : onFocus}
                blurInputOnSelect={!isMulti} // actually allows for ^^ to work
                filterOption={filterOptions}
                tagLabelClass={tagLabelClass}
                headerIcon={headerIcon}
                headerText={headerText}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
            />
            {error && (
                <div className={css.errorMessage}>
                    <Translate id={error} />
                </div>
            )}
        </div>
    );
}

NewTagInput.propTypes = {
    onChange: PropTypes.func,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    tagLockAlt: PropTypes.string,
    error: PropTypes.string,
    virtualScrolling: PropTypes.bool,
    creatable: PropTypes.bool,
    openMenuOnClick: PropTypes.bool,
    sortable: PropTypes.bool,
    isMulti: PropTypes.bool,
    dropdownIndicatorStyles: PropTypes.func,
    placeholderClass: PropTypes.string,
    isSearchable: PropTypes.bool,
    separator: PropTypes.bool,
    isClearable: PropTypes.bool,
    tagLabelClass: PropTypes.string,
    headerIcon: PropTypes.element,
    headerText: PropTypes.string,
    onEditClick: PropTypes.func,
    hideSelectedOptions: PropTypes.bool,
    tagsLimit: PropTypes.number,
    tagsLimitMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    dataTestId: PropTypes.string,
};

NewTagInput.defaultProps = {
    onChange: () => {},
    tags: [],
    options: [],
    disabled: false,
    placeholder: DEFAULT_PLACEHOLDER,
    containerStyle: {},
    className: '',
    tagLockAlt: '',
    error: null,
    virtualScrolling: false, // Note: virtual scrolling will not work properly when there are grouped items.
    creatable: false,
    openMenuOnClick: true,
    sortable: false,
    isMulti: true,
    dropdownIndicatorStyles: () => {},
    placeholderClass: '',
    isClearable: false,
    isSearchable: true,
    separator: true,
    tagLabelClass: undefined,
    headerIcon: undefined,
    headerText: undefined,
    onEditClick: undefined,
    hideSelectedOptions: undefined,
    tagsLimit: undefined,
    tagsLimitMessage: "You've reached the maximum",
    noOptionsMessage: undefined,
    dataTestId: undefined,
};

export default NewTagInput;
export const TAG_INPUT_VALUE_ACTIONS = {
    SELECT_OPTION: 'select-option',
    DESELECT_OPTION: 'deselect-option',
}