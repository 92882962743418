import { connect } from 'react-redux';
import { CopyArea } from '../../components/widgets';
import { copyLinkClicked } from '../actions';

const mapDispatchToProps = dispatch => {
    return {
        onCopyClicked: (...args) => {
            dispatch(copyLinkClicked(...args));
        },
    };
};

export default connect((state, props) => props, mapDispatchToProps)(CopyArea);
