import React from 'react';
import * as Papa from 'papaparse';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faCaretRight from '@fortawesome/fontawesome-free-solid/faCaretRight';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';

export function applyFilter(filter, apps, trackingLinks) {
    if (!filter.tags || filter.tags.length === 0) {
        return { filteredApps: apps, filteredTrackingLinks: trackingLinks };
    }
    // mapping of filter key to equivalent key in tracking links json
    const filterKeyToJsonMapping = {
        Campaign: 'campaign_name',
        Network: 'partner',
    };

    let filteredApps = [];
    let filteredTrackingLinks = [];
    let i;
    if (filter.target === 'App') {
        // filter the apps list first
        filteredApps = apps.filter(app => {
            if (filter.type === 'Excludes') {
                // Exclude-Filter behaves as an "AND" (exclude matches to tag1 AND tag2)
                for (i = 0; i < filter.tags.length; i++) {
                    const tag = filter.tags[i];
                    if (app.name.toLowerCase().indexOf(tag.toLowerCase()) > -1) {
                        return false;
                    }
                }
                return true;
            } else {
                // Include-Filter behaves as an "OR" (include matches to tag1 OR tag2)
                for (i = 0; i < filter.tags.length; i++) {
                    const tag = filter.tags[i];
                    if (app.name.toLowerCase().indexOf(tag.toLowerCase()) > -1) {
                        return true;
                    }
                }
                return false;
            }
        });

        // rebuild the trackinglinks list
        filteredApps.forEach(app => {
            filteredTrackingLinks = filteredTrackingLinks.concat(app.links);
        });
    } else {
        // filter tracking links list first
        filteredTrackingLinks = trackingLinks.filter(link => {
            if (filter.type === 'Excludes') {
                for (i = 0; i < filter.tags.length; i++) {
                    const tag = filter.tags[i];
                    if (link[filterKeyToJsonMapping[filter.target]].toLowerCase().indexOf(tag.toLowerCase()) > -1) {
                        return false;
                    }
                }
                return true;
            } else {
                for (i = 0; i < filter.tags.length; i++) {
                    const tag = filter.tags[i];
                    if (link[filterKeyToJsonMapping[filter.target]].toLowerCase().indexOf(tag.toLowerCase()) > -1) {
                        return true;
                    }
                }
                return false;
            }
        });

        // rebuild the apps list
        filteredApps = apps.map(app => {
            const sitesLongnames = app.sites.map(site => site.longname);
            const links = filteredTrackingLinks.filter(link => sitesLongnames.indexOf(link.longname) > -1);
            return Object.assign({}, app, { links });
        });
    }

    return { filteredApps, filteredTrackingLinks };
}

export function applyAllFilters(filters, apps, trackingLinks) {
    if (!filters || filters.length === 0) {
        return { filteredApps: apps, filteredTrackingLinks: trackingLinks };
    }

    let filteredApps = apps;
    let filteredTrackingLinks = trackingLinks;

    filters.forEach(f => {
        const filteredData = applyFilter(f, filteredApps, filteredTrackingLinks);
        ({ filteredApps, filteredTrackingLinks } = filteredData);
    });
    return { filteredApps, filteredTrackingLinks };
}

export function buildLinksMap(app) {
    const linksMap = { networks: {} };
    // Map tracking links:
    //  Network
    //      Campaign
    //          iOS
    //              tracking tag
    //          Android
    //              tracking tag

    app.links.forEach(link => {
        if (!(link.partner in linksMap.networks)) {
            linksMap.networks[link.partner] = { campaigns: {} };
        }
        if (!(link.campaign_id in linksMap.networks[link.partner].campaigns)) {
            linksMap.networks[link.partner].campaigns[link.campaign_id] = { platforms: {} };
            linksMap.networks[link.partner].campaigns[link.campaign_id].campaign_name = link.campaign_name;
        }
        if (!(link.platform in linksMap.networks[link.partner].campaigns[link.campaign_id])) {
            linksMap.networks[link.partner].campaigns[link.campaign_id].platforms[link.platform] = {};
        }
        linksMap.networks[link.partner].campaigns[link.campaign_id].platforms[link.platform] = link;
        linksMap.networks[link.partner].type = link.link_type;
    });

    return linksMap;
}

export function downloadCSV(filteredTrackingLinks) {
    const csvConfig = [
        { name: 'Ad Network', key: 'partner' },
        { name: 'App Longname', key: 'longname' },
        { name: 'Campaign Name', key: 'campaign_name' },
        { name: 'Platform', key: 'platform' },
        { name: 'Click-through URL', key: 'click_url' },
        { name: 'View-through URL', key: 'impression_url' },
        { name: 'Type', key: 'link_action' },
        { name: 'Link Source', key: 'link_source' },
        { name: 'App Store URL', key: 'appstore' },
        { name: 'Deeplink URL', key: 'deeplink' },
        { name: 'Deferred Deeplink URL', key: 'deferred_deeplink' },
        { name: 'Universal Link URL', key: 'universal_link' },
        { name: 'Fallback URL', key: 'web_fallback' },
    ];

    const trackingLinksArray = filteredTrackingLinks.map(link => {
        if (!link.destination) {
            return link;
        } else {
            const unnestedLink = Object.assign({}, link);
            unnestedLink.appstore = link.destination.appstore;
            unnestedLink.deeplink = link.destination.deeplink;
            unnestedLink.deferred_deeplink = link.destination.deferred_deeplink;
            unnestedLink.universal_link = link.destination.universal_link;
            unnestedLink.web_fallback = link.destination.web_fallback;
            return unnestedLink;
        }
    });

    const columns = csvConfig.map(c => c.name);

    const exportData = trackingLinksArray.map(row => {
        return csvConfig.map(c => {
            return row[c.key] || '';
        });
    });

    const csv = Papa.unparse({ fields: columns, data: exportData });
    const blob = new Blob([csv], { type: 'text/csv' });
    const filename = 'Tracking Links.csv';
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 100);
}

export const EXPAND_ARROW = (
    <FontAwesomeIcon icon={faCaretRight} className="fa fa-fw fa-caret-right ExpandCollapseIcon" />
);
export const COLLAPSE_ARROW = (
    <FontAwesomeIcon icon={faCaretDown} className="fa fa-fw fa-caret-down ExpandCollapseIcon" />
);
