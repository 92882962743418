import { connect } from 'react-redux';
import { toggleScreens } from 'teamManagement/actions';
import ScreenSelection from 'teamManagement/components/ScreenSelection';
import { getScreenSelectionPage } from 'teamManagement/selectors';

const mapStateToProps = (state, ownProps) => {
    return getScreenSelectionPage(state);
};

const mapDispatchToProps = dispatch => ({
    toggleScreens: (...args) => {
        dispatch(toggleScreens(...args));
    },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ScreenSelection);
