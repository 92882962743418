import React from 'react';
import {
    ANDROID_NATIVE,
    IOS_NATIVE,
    SDK_INTEGRATION_SECTIONS,
} from '../consts';
import { calculateSectionKeysToDisable, formatItem, formatItemWithChildren } from './utils';
import CUSTOM_SECTIONS from './customSectionsConfig';
import GeneralSDKPlanningSection from '../components/sections/GeneralSDKPlanningSection';
import { SECTIONS_TITLES_TRANSLATE_KEY } from '../components/sections/consts';

const {
    ATT_PROMPT,
    SKAN_ENABLED,
    CUSTOM_USER_ID,
    GET_SDK_FILES,
    HANDLING_DEEPLINKS,
    IMPORT_LIBRARY,
    INITIALIZATION,
    USER_ACTIVITY,
    CREATE_A_CONFIGURATION_OBJECT,
    INITIATE_THE_CODE,
    CONFIGURE_THE_SDK,
    TEST_A_SESSION,
    DEEP_LINKING,
    ANDROID_MANIFEST_SETTINGS,
    EVENTS,
    ADD_EVENTS,
    TEST_EVENTS,
    REVENUE,
    REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT,
    REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT,
    REVENUE_ANDROID_WITH_PURCHASE_RECEIPT,
    REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT,
    TEST_REVENUE,
    AD_MONETIZATION,
} = SDK_INTEGRATION_SECTIONS;

function getSectionConfig(itemKey, nestedItems = {}) {
    return {
        component: CUSTOM_SECTIONS?.[itemKey] || <GeneralSDKPlanningSection sectionKey={itemKey} />,
        itemTitle: `${SECTIONS_TITLES_TRANSLATE_KEY}.${itemKey}`,
        nestedItems,
    };
}

const SECTIONS_TREE = {
    [IOS_NATIVE]: {
        [GET_SDK_FILES]: getSectionConfig(GET_SDK_FILES),
        [CONFIGURE_THE_SDK]: getSectionConfig(CONFIGURE_THE_SDK, {
            [IMPORT_LIBRARY]: getSectionConfig(IMPORT_LIBRARY),
            [CREATE_A_CONFIGURATION_OBJECT]: getSectionConfig(CREATE_A_CONFIGURATION_OBJECT),
            [ATT_PROMPT]: getSectionConfig(ATT_PROMPT),
            [SKAN_ENABLED]: getSectionConfig(SKAN_ENABLED),
            [DEEP_LINKING]: getSectionConfig(DEEP_LINKING),
            [CUSTOM_USER_ID]: getSectionConfig(CUSTOM_USER_ID),
        }),
        [INITIATE_THE_CODE]: getSectionConfig(INITIATE_THE_CODE, {
            [INITIALIZATION]: getSectionConfig(INITIALIZATION),
            [USER_ACTIVITY]: getSectionConfig(USER_ACTIVITY),
            [HANDLING_DEEPLINKS]: getSectionConfig(HANDLING_DEEPLINKS),
        }),
        [TEST_A_SESSION]: getSectionConfig(TEST_A_SESSION),
        [EVENTS]: getSectionConfig(EVENTS, {
            [ADD_EVENTS]: getSectionConfig(ADD_EVENTS),
            [TEST_EVENTS]: getSectionConfig(TEST_EVENTS),
        }),
        [REVENUE]: getSectionConfig(REVENUE, {
            [REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT]: getSectionConfig(REVENUE_IOS_IN_APP_PURCHASE_WITH_RECEIPT),
            [REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT]: getSectionConfig(
                REVENUE_IOS_NON_IN_APP_PURCHASE_WITHOUT_RECEIPT
            ),
            [TEST_REVENUE]: getSectionConfig(TEST_REVENUE),
        }),
        [AD_MONETIZATION]: getSectionConfig(AD_MONETIZATION),
    },
    [ANDROID_NATIVE]: {
        [GET_SDK_FILES]: getSectionConfig(GET_SDK_FILES),
        [ANDROID_MANIFEST_SETTINGS]: getSectionConfig(ANDROID_MANIFEST_SETTINGS),
        [CONFIGURE_THE_SDK]: getSectionConfig(CONFIGURE_THE_SDK, {
            [IMPORT_LIBRARY]: getSectionConfig(IMPORT_LIBRARY),
            [CREATE_A_CONFIGURATION_OBJECT]: getSectionConfig(CREATE_A_CONFIGURATION_OBJECT),
            [DEEP_LINKING]: getSectionConfig(DEEP_LINKING),
            [CUSTOM_USER_ID]: getSectionConfig(CUSTOM_USER_ID),
        }),
        [INITIATE_THE_CODE]: getSectionConfig(INITIATE_THE_CODE),
        [TEST_A_SESSION]: getSectionConfig(TEST_A_SESSION),
        [EVENTS]: getSectionConfig(EVENTS, {
            [ADD_EVENTS]: getSectionConfig(ADD_EVENTS),
            [TEST_EVENTS]: getSectionConfig(TEST_EVENTS),
        }),
        [REVENUE]: getSectionConfig(REVENUE, {
            [REVENUE_ANDROID_WITH_PURCHASE_RECEIPT]: getSectionConfig(REVENUE_ANDROID_WITH_PURCHASE_RECEIPT),
            [REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT]: getSectionConfig(REVENUE_ANDROID_WITHOUT_PURCHASE_RECEIPT),
            [TEST_REVENUE]: getSectionConfig(TEST_REVENUE),
        }),
        [AD_MONETIZATION]: getSectionConfig(AD_MONETIZATION),
    },
};

const getSectionsListItems = (platform, sdkConfigurationForPlanning) => {
    const rawItems = SECTIONS_TREE[platform] || {};
    const itemsToDisable = calculateSectionKeysToDisable(platform, sdkConfigurationForPlanning);

    return Object.fromEntries(
        Object.entries(rawItems).map(([key, item]) => [
            key,
            item.hasOwnProperty('nestedItems')
                ? formatItemWithChildren(item, itemsToDisable.includes(key), itemsToDisable)
                : formatItem(item, itemsToDisable.includes(key)),
        ])
    );
};

export { SECTIONS_TREE, getSectionsListItems };
