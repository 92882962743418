import React from 'react';

class Default extends React.PureComponent {
    render() {
        return <div />;
    }
}

Default.propTypes = {};

Default.defaultProps = {};

export default Default;
