import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { HighchartsChart, LineSeries, withHighcharts } from 'react-jsx-highcharts';
import { getCurrencySymbol } from 'utils/currencyUtils';
import ChartTooltip from 'benchmarks/components/chartTooltip';
import ChartLegend from 'benchmarks/components/chartLegend';
import ChartXAxis from 'benchmarks/components/chartXAxis';
import ChartYAxis from 'benchmarks/components/chartYAxis';
import Chart from 'benchmarks/components/chart';
import { mapSeriesValues, seriesFloatValueFormatter, seriesRangeValueFormatter, monthLabelsFormatter } from '../utils';
import { overTimeChartPlotOptions, colorByNetwork, CURRENCY, dashStyles } from '../consts';

const OverTimeChart = ({ chartData, key, metricType, currency, zeroCostChannels }) => {
    const [chartLoaded, setChartLoaded] = useState(false);
    const { data, labels } = chartData;
    const categories = labels.map(label => {
        return new Date(label);
    });

    return (
        <HighchartsChart
            constructorType="stockChart"
            plotOptions={overTimeChartPlotOptions}
            callback={() => setChartLoaded(true)}
        >
            <ChartLegend />
            <Chart className="over_time_chart" loaded={chartLoaded} minWidth={labels.length * 40} marginLeft={80} />
            <ChartTooltip categories={categories} />
            <ChartXAxis id={`${key}_x_axis`} categories={categories} showCrosshair formatter={monthLabelsFormatter} />
            <ChartYAxis
                id={`${key}_y_axis`}
                endOnTick
                labelSuffix={metricType === CURRENCY ? getCurrencySymbol(currency) : '%'}
            >
                {data.map(({ data: values, label }) => {
                    return (
                        <LineSeries
                            formatter={
                                metricType === CURRENCY
                                    ? seriesRangeValueFormatter(currency)
                                    : seriesFloatValueFormatter
                            }
                            key={label}
                            name={label}
                            zoneAxis="x"
                            zones={labels.map(x => ({
                                dashStyle:
                                    zeroCostChannels[label] && zeroCostChannels[label].includes(labels.indexOf(x))
                                        ? dashStyles.shortDash
                                        : dashStyles.solid,
                                value: labels.indexOf(x) + 1,
                            }))}
                            color={colorByNetwork[label]}
                            data={metricType === CURRENCY ? values : mapSeriesValues(values)}
                            onLegendItemClick={() => {
                                return true;
                            }}
                        />
                    );
                })}
            </ChartYAxis>
        </HighchartsChart>
    );
};

OverTimeChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    key: PropTypes.string,
    metricType: PropTypes.string,
    currency: PropTypes.string,
    zeroCostChannels: PropTypes.objectOf(PropTypes.any),
};

OverTimeChart.defaultProps = {
    chartData: {},
    key: 'over_time',
    metricType: '',
    currency: 'USD',
    zeroCostChannels: {},
};

export default withHighcharts(OverTimeChart, Highcharts);
