import Reports from './reports';

const reportsAPI = new Reports();
let instance = null;

const defaultQueryParams = {
    chart: true,
};

class Charts {
    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    runQuery(params) {
        return reportsAPI.runQuery({
            ...defaultQueryParams,
            ...params,
        });
    }
}

export default Charts;
