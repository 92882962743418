import React, { useState } from 'react';
import { useFormikContext } from 'formik-2';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './PartnerConfigurationShelf.css';
import geoRestrictionsCss from './GeoRestrictions.css';
import { countryShape, partnerShape } from '../types';
import { AutoCompleteField, DropdownField } from '../../../components/widgets';
import { sortAutoCompleteOptions } from '../../../utils/components/formUtils';

export const RestrictionsOptions = {
    INCLUDE: 'INCLUDE',
    EXCLUDE: 'EXCLUDE',
};

function GeoRestrictions({ partner, countries, isReadonly }) {
    const { values, setValues } = useFormikContext();
    const { geoRestrictions } = values;
    const [selectedRestrictionType, setSelectedRestrictionType] = useState(
        geoRestrictions?.allowedCountries?.length ? RestrictionsOptions.INCLUDE : RestrictionsOptions.EXCLUDE
    );

    const restrictionsOptions = Object.values(RestrictionsOptions).map(field => ({
        name: field,
        display_name: `STATIC.BUTTONS.${field}`,
    }));

    const countriesOptions = countries
        .map(country => ({
            value: country.isoCode,
            label: country.displayName,
        }))
        .sort(sortAutoCompleteOptions);

    const getSelectedCountries = () => {
        const selectedCountries = geoRestrictions?.excludedCountries?.length
            ? geoRestrictions.excludedCountries
            : geoRestrictions?.allowedCountries || [];
        return countriesOptions.filter(country => selectedCountries.includes(country.value));
    };

    const onChange = (userSelectedCountries = null, restrictionType = null) => {
        const newCountries = userSelectedCountries || getSelectedCountries();
        const newRestrictionType = restrictionType || selectedRestrictionType;
        if (restrictionType) setSelectedRestrictionType(restrictionType);

        if (!newCountries.length) {
            setValues({ ...values, geoRestrictions: null });
            return;
        }

        const countriesValues = newCountries.map(country => country.value);

        setValues({
            ...values,
            geoRestrictions: {
                excludedCountries: newRestrictionType === RestrictionsOptions.EXCLUDE ? countriesValues : [],
                allowedCountries: newRestrictionType === RestrictionsOptions.INCLUDE ? countriesValues : [],
            },
        });
    };

    return (
        <div className={css.section}>
            <div className={css.title}>
                <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.GEO_RESTRICTIONS" />
            </div>
            <div className={css.subtitle}>
                <Translate
                    id="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.GEO_RESTRICTIONS_SUB_TITLE"
                    data={{ partnerName: partner.displayName }}
                />
            </div>
            <div className={geoRestrictionsCss.geoRestrictionsContainer}>
                <DropdownField
                    labelClassName={geoRestrictionsCss.label}
                    label="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.GEO_RESTRICTIONS_LABEL"
                    labelTooltip="STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.GEO_RESTRICTIONS_TOOLTIP"
                    items={restrictionsOptions}
                    selected={selectedRestrictionType}
                    disabled={isReadonly}
                    onChange={restrictionType => onChange(null, restrictionType)}
                    bodyClassName={geoRestrictionsCss.filterInput}
                    dropdownContainerClassName={geoRestrictionsCss.filterInput}
                    containerClass={geoRestrictionsCss.filterSection}
                    dropdownSelectedClassName={geoRestrictionsCss.filterInputButton}
                />
                <AutoCompleteField
                    containerClass={geoRestrictionsCss.countriesSelection}
                    searchable
                    placeholder={
                        isReadonly
                            ? ''
                            : 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ADVANCED_SETTINGS.COUNTRIES_INPUT_PLACEHOLDER'
                    }
                    selectOptions={{ usePortal: true }}
                    options={countriesOptions}
                    value={getSelectedCountries()}
                    onChange={userCountries => onChange(userCountries)}
                    onInputChange={userCountries => onChange(userCountries)}
                    disabled={isReadonly}
                />
            </div>
        </div>
    );
}

GeoRestrictions.propTypes = {
    partner: partnerShape.isRequired,
    countries: PropTypes.arrayOf(countryShape).isRequired,
    isReadonly: PropTypes.bool,
};

GeoRestrictions.defaultProps = {
    isReadonly: false,
};

export default GeoRestrictions;
